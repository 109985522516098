import React from "react";
import Modal from "react-modal";
import TextInput from "../controls/text-input";
import Button from "../controls/button";
import { validateEmail } from "../validate";
import SVGIcon, { icons } from "../svg-icon";
import Checkbox from "../controls/checkbox";
import ChecklistTooltip from "../component/checklist-tooltip";
import {
  length,
  number,
  upper,
  secuentialNumber,
  lower,
} from "../string-constants";
import { connect } from "react-redux";
import {
  validateEmailDuplicated,
  createNewAccount,
  loginUser,
} from "../../redux/reducers/register.reducer";
import { handleWSError } from "../utils";
import { setSessionCompany } from "../../redux/reducers/global-catalog.reducer";

Modal.setAppElement("#root");

class RegisterDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.emailInvitation || "",
      confirmEmail: props.emailInvitation || "",
      password: "",
      confirmPassword: "",

      pwdType: true,
      confirmPwdType: true,

      emailFocused: false,
      confirmEmailFocused: false,
      passwordFocused: false,
      confirmPasswordFocused: false,

      termsCheck: false,

      emailDuplicated: false,
    };
  }

  onClose = () => {
    this.props.closeAction();
    this.setState({
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",

      pwdType: true,
      confirmPwdType: true,

      emailFocused: false,
      confirmEmailFocused: false,
      passwordFocused: false,
      confirmPasswordFocused: false,

      termsCheck: false,
    });
  };

  getErrorEmail = () => {
    if (this.state.emailFocused && this.state.email === "") {
      return ["Oops... requerimos este campo"];
    }

    if (this.state.emailFocused && !validateEmail(this.state.email)) {
      return ["Oops... el correo no es valido"];
    }

    if (this.state.emailFocused && this.state.emailDuplicated) {
      return [`Oops... el usuario ${this.state.email}  ya existe`];
    }

    return [];
  };

  getErrorConfirmEmail = () => {
    if (this.state.confirmEmailFocused && this.state.confirmEmail === "") {
      return ["Oops... requerimos este campo"];
    }

    if (
      this.state.confirmEmailFocused &&
      this.state.email !== this.state.confirmEmail
    ) {
      return ["Oops... tu correo no coincide"];
    }

    return [];
  };

  getErrorPasswordEmail = () => {
    if (this.state.passwordFocused && this.state.password === "") {
      return ["Oops... requerimos este campo"];
    }

    if (this.state.passwordFocused && !this.isPasswordValid()) {
      return ["Oops... el password no es valido"];
    }

    return [];
  };

  getErrorConfirmPasswordEmail = () => {
    if (
      this.state.confirmPasswordFocused &&
      this.state.confirmPassword === ""
    ) {
      return ["Oops... requerimos este campo"];
    }

    if (
      this.state.confirmPasswordFocused &&
      this.state.confirmPassword !== this.state.password
    ) {
      return ["Oops... tu password no coincide"];
    }

    return [];
  };

  isPasswordValid = () => {
    const { password } = this.state;
    return (
      length.test(password) &&
      number.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      !secuentialNumber.test(password)
    );
  };

  isValidFields = () => {
    if (this.props.emailRegister !== '') {
      return (
        this.state.password !== "" &&
        this.state.confirmPassword !== "" &&
        this.state.confirmPassword === this.state.password &&
        this.state.termsCheck
      );
    }

    return (
      this.state.email !== "" &&
      validateEmail(this.state.email) &&
      this.state.confirmEmail !== "" &&
      validateEmail(this.state.confirmEmail) &&
      this.state.confirmEmail === this.state.email &&
      this.state.password !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.confirmPassword === this.state.password &&
      !this.state.emailDuplicated &&
      this.state.termsCheck
    );
  };

  isEmailValidated = () => {
    if (this.props.emailRegister !== '') {
      return true;
    }

    return this.props.emailInvitation && this.props.emailInvitation !== ""
      ? true
      : false;
  };

  createAccount = () => { 
    const { email, password } = this.state;

    this.props
      .createAccount({
        username: this.props.emailRegister !== '' ? this.props.emailRegister : email,
        password,
        password_confirmation: password,
        email_validation: String(this.isEmailValidated()),
      })
      .then(() => {
        if (this.props.account.error === null) {
          const { email, password } = this.state;
          this.props.loginUser({ username: this.props.emailRegister !== '' ? this.props.emailRegister : email, password }).then(() => {
            this.onClose();

            if (this.props.user.error === null) {
              let object = this.props.user.data;
              if (object.company) {
                if (object.company.length === 1) {
                  object.company = object.company[0];
                  window.sessionStorage.setItem(
                    "orbinetwork_session",
                    JSON.stringify(object)
                  );
                } else if (object.company.length > 1) {
                  object.listCompanies = object.company;
                  object.company = {
                    id: 0,
                  };
                  window.sessionStorage.setItem(
                    "orbinetwork_session",
                    JSON.stringify(object)
                  );
                } else {
                  object.company = {
                    id: 0,
                  };
                  window.sessionStorage.setItem(
                    "orbinetwork_session",
                    JSON.stringify(object)
                  );
                }
              }

              if (this.props.closure) this.props.closure();
            }
          });
        } else {
          this.onClose();

          const message = handleWSError(this.props.error);
          this.props.showError(message);
        }
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog login-dialog"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>

        {
          this.props.hasInvitation 
          ? (<div className="form">
              {this.props.emailRegister !== '' ?
              <TextInput
                type="text"
                label="Correo electrónico"
                name="email"
                className="full"
                onChange={(event) => {}}
                disabled={true}
                value={this.props.emailRegister}
              /> :
              this.props.emailInvitation && this.props.emailInvitation !== "" ? (
                <React.Fragment>
                  <TextInput
                    type="text"
                    label="Correo electrónico"
                    name="email"
                    className="full"
                    errors={this.getErrorEmail()}
                    onChange={(event) => {
                      const {
                        target: { value: email = "" },
                      } = event;
                      this.setState({
                        email,
                        emailFocused: false,
                        emailDuplicated: false,
                      });
                    }}
                    disabled={true}
                    value={this.state.email}
                    handleBlur={() => {
                      if (this.state.email !== "") {
                        this.props.validateEmail(this.state.email).then(() => {
                          if (this.props.email.error === null) {
                            this.setState({ emailFocused: true });
                          } else {
                            this.setState({
                              emailFocused: true,
                              emailDuplicated: true,
                            });
                          }
                        });
                      } else {
                        this.setState({ emailFocused: true });
                      }
                    }}
                    suffix={{
                      element:
                        this.state.email !== "" &&
                        validateEmail(this.state.email) ? (
                          <SVGIcon name={icons.checkMark} fill="#AFC8FF" />
                        ) : (
                          <div />
                        ),
                      className: "",
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TextInput
                    type="text"
                    label="Ingresa tu correo electrónico"
                    name="email"
                    className="full"
                    errors={this.getErrorEmail()}
                    onChange={(event) => {
                      const {
                        target: { value: email = "" },
                      } = event;
                      this.setState({
                        email: email.toLowerCase(),
                        emailFocused: false,
                        emailDuplicated: false,
                      });
                    }}
                    value={this.state.email}
                    handleBlur={() => {
                      if (this.state.email !== "") {
                        this.props.validateEmail(this.state.email).then(() => {
                          if (this.props.email.error === null) {
                            this.setState({ emailFocused: true });
                          } else {
                            this.setState({
                              emailFocused: true,
                              emailDuplicated: true,
                            });
                          }
                        });
                      } else {
                        this.setState({ emailFocused: true });
                      }
                    }}
                    suffix={{
                      element:
                        this.state.email !== "" &&
                        validateEmail(this.state.email) ? (
                          <SVGIcon name={icons.checkMark} fill="#AFC8FF" />
                        ) : (
                          <div />
                        ),
                      className: "",
                    }}
                  />

                  <TextInput
                    type="text"
                    label="Confirma tu correo eletrónico"
                    name="confirmEmail"
                    className="full"
                    errors={this.getErrorConfirmEmail()}
                    onChange={(event) => {
                      const {
                        target: { value: confirmEmail = "" },
                      } = event;
                      this.setState({ confirmEmail: confirmEmail.toLowerCase(), confirmEmailFocused: false });
                    }}
                    value={this.state.confirmEmail}
                    handleBlur={() => this.setState({ confirmEmailFocused: true })}
                    suffix={{
                      element:
                        this.state.confirmEmail !== "" &&
                        this.state.email === this.state.confirmEmail ? (
                          <SVGIcon name={icons.checkMark} fill="#AFC8FF" />
                        ) : (
                          <div />
                        ),
                      className: "",
                    }}
                  />
                </React.Fragment>
              )}

              <div style={{ display: "flex" }}>
                <TextInput
                  type={this.state.pwdType ? "password" : "text"}
                  label="Ingresa tu contraseña"
                  name="password"
                  className="half"
                  errors={this.getErrorPasswordEmail()}
                  dataFor="orbi-tooltip-password"
                  dataTip={""}
                  onChange={(event) => {
                    const {
                      target: { value: password = "" },
                    } = event;
                    this.setState({ password, passwordFocused: false });
                  }}
                  value={this.state.password}
                  handleBlur={() => this.setState({ passwordFocused: true })}
                  suffix={{
                    element: (
                      <div
                        onClick={() =>
                          this.setState({ pwdType: !this.state.pwdType })
                        }
                      >
                        <SVGIcon
                          name={icons.passwordToggle}
                          fill={this.state.pwdType ? "#AFC8FF" : "rgb(30,70,233)"}
                        />
                      </div>
                    ),
                    className: "",
                  }}
                />

                <ChecklistTooltip
                  tooltipId="orbi-tooltip-password"
                  className="custom-tooltip"
                  event="focusin"
                  eventOff="focusout"
                  title="Tu contraseña debe contener:"
                  place="bottom"
                  valueToValidate={this.state.password}
                  validations={[
                    {
                      valid: (value) => {
                        return length.test(value);
                      },
                      textToShow: "Minimo 8 caracteres",
                    },
                    {
                      valid: (value) => {
                        return number.test(value);
                      },
                      textToShow: "Un número (0-9)",
                    },
                    {
                      valid: (value) => {
                        return upper.test(value);
                      },
                      textToShow: "Una mayúscula (A-Z)",
                    },
                    {
                      valid: (value) => {
                        return lower.test(value);
                      },
                      textToShow: "Una minúscula",
                    },
                    {
                      valid: (value) => {
                        return !secuentialNumber.test(value);
                      },
                      textToShow: "No deben ser 3 o más números consecutivos",
                    },
                  ]}
                />
              </div>
              <TextInput
                type={this.state.confirmPwdType ? "password" : "text"}
                label="Confirma tu contraseña"
                name="confirmPassword"
                className="half"
                errors={this.getErrorConfirmPasswordEmail()}
                onChange={(event) => {
                  const {
                    target: { value: confirmPassword = "" },
                  } = event;
                  this.setState({
                    confirmPassword,
                    confirmPasswordFocused: false,
                  });
                }}
                value={this.state.confirmPassword}
                handleBlur={() => this.setState({ confirmPasswordFocused: true })}
                suffix={{
                  element: (
                    <div
                      onClick={() =>
                        this.setState({
                          confirmPwdType: !this.state.confirmPwdType,
                        })
                      }
                    >
                      <SVGIcon
                        name={icons.passwordToggle}
                        fill={
                          this.state.confirmPwdType ? "#AFC8FF" : "rgb(30,70,233)"
                        }
                      />
                    </div>
                  ),
                  className: "",
                }}
                handleEnter={() => {
                  if (this.isValidFields() && !this.props.account.isFetching)
                    this.createAccount();
                }}
              />
              <div style={{marginTop: "20px"}}>
                <span
                  className="textNewAccount"
                  onClick={() => this.props.changeToLogin()}
                >
                  Ya tengo cuenta, ir a Iniciar sesión
                </span>
              </div>

              <div className="button-container">
                <div className="terms-conditions-container">
                  <Checkbox
                    name="termsCheck"
                    onChange={() =>
                      this.setState({ termsCheck: !this.state.termsCheck })
                    }
                    item={{
                      id: 1,
                      description: "",
                      active: this.state.termsCheck,
                    }}
                  />

                  <div style={{ width: "10px" }} />

                  <div>
                    Aceptas los{" "}
                    <b
                      className="link"
                      onClick={() => {
                        window.open("/termsConditions", "-");
                      }}
                    >
                      {" "}
                      Terminos y condiciones
                    </b>{" "}
                    para crear tu cuenta
                  </div>
                </div>

                <Button
                  text={
                    this.props.account.isFetching
                      ? "Cargando..."
                      : "Crear tu cuenta"
                  }
                  type={"primary"}
                  onClick={() => this.createAccount()}
                  disabled={!this.isValidFields() || this.props.account.isFetching}
                />
              </div>
            </div>)
          : <div className="form">
              <div>
                Solo podrás crear una cuenta si recibiste una invitación.
                Si no tienes una invitación y te gustaría recibir una por favor deja tus datos y pronto nos pondremos en contacto contigo.
              </div>
              <div className="support-form">
                <iframe 
                  src="https://leads-capturer.futuresimple.com/render/721002aa9c08fb96d8f8dd0c46c3a901/embedded#https%3A%2F%2Fcartaportemasterclass.com%2F"
                  style={{
                    width: "100%",
                    height: "300px",
                    border: "0px"
                  }}
                ></iframe>
              </div>
            </div>
        }

        

        {/**/}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: {
      data: state.register.emailValidation.data,
      error: state.register.emailValidation.error,
    },
    account: {
      data: state.register.account.data,
      error: state.register.account.error,
      isFetching: state.register.account.isFetching,
    },
    user: {
      isFetching: state.register.login.isFetching,
      data: state.register.login.data,
      error: state.register.login.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateEmail: (email) => dispatch(validateEmailDuplicated(email)),
    createAccount: (user) => dispatch(createNewAccount(user)),
    loginUser: (data) => dispatch(loginUser(data)),
    setSessionCompany: (data) => dispatch(setSessionCompany(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDialog);
