import { cloneDeep } from "lodash";
import React from "react"
import { connect } from "react-redux"
import { SERVER } from "../redux/api/api-urls";
import { getEntityItems, readEntities } from "../redux/api";
import { Scroll } from "../shared/animation";
import FilterBar from "../shared/component/filter/filter-bar";
import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import DownloadImage from "../shared/icons/routes/downloadIcon";
import Loader from "../shared/icons/tat/loader";
import { View } from "../shared/styled/view";
import Axios from "axios";
import OptionSelectorSwitch from "../shared/controls/option-selector-switch";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";
import styled from "styled-components";
import { isPremiumSubscriptionCo } from "../shared/utils";

const today = new Date();
const yesterday = new Date(today.setDate(today.getDate() - 1));

const dateToString = (date) => {
  let dateStr = date.getFullYear();
  dateStr += "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  dateStr += "-" + (date.getDate() < 10 ? "0" : "") + (date.getDate());
  return dateStr;
}

const treeGroups = [
  {
    name: "Linea",
    id: "fleet_provider"
  },
  {
    name: "Instalaciones",
    id: "facilities"
  },
  {
    name: "Ruta",
    id: "ruta"
  },
  {
    name: "Camión",
    id: "economic_number"
  },
  {
    name: "Tabla",
    id: "table"
  },
]

class AlertsReportView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active_alerts: 0,
      searchStr: "",
      filterDate: [yesterday],
      filterFacilities: [],
      // filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      // filterBaseTags: [],
      // filterCO: [],
      filterAlerts: [],
      filterLevels: [],
      filterLT: [],
      
      buttonFilterName: "Aplicar",

      monthInfo: {},
      dayInfo: {},

      selectedMonth: (yesterday).getMonth(),
      monthMode: 'total',

      yearMode: 'total',

      graphStartDate: "",
      graphEndDate: "",

      showParetoTipo: false,

      
      showParetoArbol1: false,
      showParetoArbol2: false,
      showParetoArbol3: false,
      showParetoArbol4: false,
      showParetoArbol5: false,
      showParetoArbol6: false,
      
      arbol1Criterio: 0,
      arbol1Filtros: {},
      arbol1Grupo: "",
      arbol1Loading: false,
      
      arbol2Criterio: 0,
      arbol2Filtros: {},
      arbol2Grupo: "",
      arbol2Loading: false,
      
      arbol3Criterio: 0,
      arbol3Filtros: {},
      arbol3Grupo: "",
      arbol3Loading: false,
      
      arbol4Criterio: 0,
      arbol4Filtros: {},
      arbol4Grupo: "",
      arbol4Loading: false,
      
      arbol5Criterio: 0,
      arbol5Filtros: {},
      arbol5Grupo: "",
      arbol5Loading: false,

      arbol6Criterio: 0,
      arbol6Filtros: {},
      arbol6Grupo: "",
      arbol6Loading: false,

      isFreeSubscription: false
    }
    this.apiLoaded = false;
    this.paretoApiLoaded = false;
    this.factor = 1;
  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });
    this.props.loadCompanyFacilities({
      company_id: this.props.companyId,
      view: "grouped",
    });
    this.props.loadCompanyTags(this.props.companyId);
    this.props.loadCompanyAlerts({
      company_id: this.props.companyId
    });

    this.loadApi().then(value => {
      this.loadParetoApi().then(value2 => {
        this.paretoApiLoaded = value2;
        this.loadGlobal();
        // if(this.props.isEmail) this.loadDesglose();
      });
      this.apiLoaded = value;
    });
    this.props.setNavbarAction("alerts", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("alerts");
  }

  loadApi = () => {
    return new Promise((resolve) => {
      if (this.apiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/highcharts.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/highcharts.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  loadParetoApi = () => {
    return new Promise((resolve) => {
      if (this.paretoApiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/modules/pareto.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/modules/pareto.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  //WS
  loadGlobal = () => {
    this.props.loadAlertsGlobal({
      company_id: this.props.companyId,
      start_date: "".concat(this.state.filterDate[0].getFullYear(),'-01-01'),
      end_date: dateToString(this.state.filterDate[0]),
      // from_facility: "",
      // to_facility: "",
      fleet_provider: this.state.filterLT,
      // cargo_owner: this.state.filterCO,
      // uen: "",
      // drv: "",
      // route: "",
      // action: "",
      // tags: this.state.filterTags.concat(this.state.filterTags2),
      order_tags: this.state.filterTags2,
      trip_tags: this.state.filterTags,
      // base_tags: this.state.filterBaseTags,
      facilities: this.state.filterFacilities,
      alerts: this.state.filterAlerts,
      level: this.state.filterLevels,
      // transport_class: this.state.filterTransportClass,
      is_active: this.state.active_alerts,
    }, {
      onSuccess: () => {
        this.graphDaily();
        this.graphYear();
        // this.graphMonthly();
        // this.renderCanvas();
        this.setBars();
      }
    })
  }
  
  loadDesgloseTipo = () => {
    this.props.loadAlertsDesgloseTipo({
      company_id: this.props.companyId,
      start_date: this.state.graphStartDate,
      end_date: this.state.graphEndDate,
      // from_facility: "",
      // to_facility: "",
      fleet_provider: this.state.filterLT,
      // cargo_owner: this.state.filterCO,
      // uen: "",
      // drv: "",
      // route: "",
      // action: "",
      // tags: this.state.filterTags.concat(this.state.filterTags2),
      order_tags: this.state.filterTags2,
      trip_tags: this.state.filterTags,
      // base_tags: this.state.filterBaseTags,
      facilities: this.state.filterFacilities,
      alerts: this.state.filterAlerts,
      level: this.state.filterLevels,
      // transport_class: this.state.filterTransportClass,
      is_active: this.state.active_alerts,
    }, {
      onSuccess: () => {
        this.graphPareto();
      }
    });
    Scroll.go(document.getElementById('paretoTipoChart'), document.getElementById('pageContent'));
  }

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    this.hideArbol();
    this.setState({ [name]: value, showParetoTipo: false }, () => this.loadGlobal());
    
  };
  
  // loadArbol = (level = 0) => {
  //   if(level){
  //     let group = "";
  //     switch(this.state["arbol".concat(level, "Grupo")]){
  //       case "ruta":
  //         group= "route";
  //         break;
  //       case "uen":
  //         group= "uen";
  //         break;
  //       case "agencia":
  //         group= "to_facility";
  //         break;
  //       case "linea":
  //         group= "fleet_provider";
  //         break;
  //       default:
  //     }
  //     this.props.loadTATCriterio({
  //       group: group,
  //       company_id: this.props.companyId,
  //       start_date: this.state.graphStartDate,
  //       end_date: this.state.graphEndDate,
  //       // from_facility: this.state["arbol".concat(level, "Filtros")]["origen"] || "",
  //       // to_facility: this.state["arbol".concat(level, "Filtros")]["agencia"] || "",
  //       fleet_provider: this.state["arbol".concat(level, "Filtros")]["linea"] || this.state.filterLT,
  //       // cargo_owner: this.state.filterCO,
  //       uen: this.state["arbol".concat(level, "Filtros")]["uen"] || "",
  //       drv: this.state["arbol".concat(level, "Filtros")]["drv"] || "",
  //       route: this.state["arbol".concat(level, "Filtros")]["ruta"] || "",
  //       action: "",
  //       // tags: this.state.filterTags.concat(this.state.filterTags2),
  //       order_tags: this.state.filterTags2,
  //       trip_tags: this.state.filterTags,
  //       // base_tags: this.state.filterBaseTags,
  //       facilities: this.state.filterFacilities,
  //       alerts: this.state.filterAlerts,
  //       level: this.state.filterLevels
  //       // transport_class: this.state.filterTransportClass,
  //     }, {
  //       alias: "arbol".concat(level),
  //       onSuccess: () => {this.graphArbol(level);}
  //     })
  //     Scroll.go(document.getElementById('paretoRutaArbol'.concat(level)), document.getElementById('pageContent'));
  //   }
  // }

  // //Arboles
  // hideTrees = () => {
  //   this.setState({
  //     showParetoArbol1: false,
  //     showParetoArbol2: false,
  //     showParetoArbol3: false,
  //     showParetoArbol4: false,
  //     showParetoArbol5: false,
  //     showParetoArbol6: false,
  //   })
  // }

  // getArbolFilters = (level = 0) => {
  //   if(!level) return []
  //   let temp = {};
  //   this.state["arbol".concat(level, "Orden")].forEach(o => {
  //     if((Object.keys(temp)).length){
  //       temp = temp[o];
  //     } else {
  //       temp = arboles[o];
  //     }
  //   })
  //   const self = this;
  //   let f = []
  //   if((Object.keys(temp)).length > 1){
  //     for(let i in temp){
  //       f.push(
  //         <li className={"pantalla ".concat(
  //           self.state.selectedParetoMode,
  //           i === this.state["arbol".concat(level, "Grupo")] ? " selected" : "",
  //         )}
  //           onClick={() => {
  //             this.setState({["arbol".concat(level, "Grupo")]: i}, () => this.loadArbol(level));
  //           }}  
  //         >
  //           {
  //             i.includes('tabla') 
  //             ? "Alertas"
  //             : i === "drv" 
  //               ? "DRVs"
  //               : i === "uen"
  //                 ? "UENs"
  //                 : i.charAt(0).toUpperCase() + i.slice(1)
  //           }
  //           <div></div>
  //         </li>
  //       );
  //     }
  //   }
  //   return f;
  // }

  // graphArbol = (level) => {
  //   // debugger;
  //   if(!this.props["alertsArbol".concat(level)]["criterio"]) return null;
  //   if(this.state["arbol".concat(level, "Grupo")].includes("tabla")){
  //     document.getElementById("paretoRutaArbol".concat(level, "Chart")).innerHTML = "";
  //     // this.renderTable(level);
  //     return null;
  //   } 
  //   const self = this;
  //   let width = null;
  //   let data = JSON.parse(JSON.stringify(DefaultChart));
  //   data.chart.backgroundColor = 'transparent';
  //   data.chart.width = width;
  //   data.chart.height = 550;
  //   // delete data.plotOptions.series.dataLabels.formatter;
  //   // data.legend = { enabled: false };
  //   data.tooltip = {
  //     shared: true,
  //     // valueDecimals: 2,
  //     formatter: function () {
  //       return this.points.reduce(function (s, point) {
  //         return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': <b>' + (/* point.point.pct ? point.point.pct : */ point.y.toFixed(2)) + '</b>';
  //       }, '<b>' + this.x + '</b>');
  //     },
  //   };

  //   data.legend = {
  //     itemStyle: {
  //       color: '#fff',
  //       fontSize: '15px'
  //     }
  //   };

  //   data.xAxis = {
  //     categories: [],
  //     crosshair: true,
  //     lineColor: '#303349',
  //     tickLength: 0,
  //     labels: {
  //           style: {
  //               color: 'white',
  //               fontSize: '15px'
  //           }
  //       }
  //   };

  //   data.yAxis[0].labels = {
  //     enabled: false,
  //   };
  //   data.yAxis[0].endOnTick = false;
  //   data.yAxis[0].gridLineWidth = 0;
  //   data.yAxis[0].maxPadding = 0.15;

  //   data.yAxis[0].stackLabels.enabled = true;
  //   data.yAxis[0].stackLabels.rotation = 0;
  //   data.yAxis[0].stackLabels.x = 0;
  //   data.yAxis[0].stackLabels.y = -3;
  //   data.yAxis[0].stackLabels.formatter = function(){
  //     if(this.total > 1000){
  //     return (this.total/1000).toFixed(1);
  //     }
  //     else{
  //       return this.total;
  //     }
  //   }

  //   data.series = [];
  //     data.series.push({
  //       type: 'pareto',
  //       name: '% Acum.',
  //       yAxis: 1,
  //       zIndex: 10,
  //       baseSeries: 4,
  //       color: '#008CFF',
  //       dataLabels: {
  //         enabled: false,
  //       }
  //     });
  //     data.series.push(cloneDeep(DefaultChart.series[0]));
  //     data.series.push(cloneDeep(DefaultChart.series[1]));
  //     data.series.push(cloneDeep(DefaultChart.series[2]));
  //     data.series.push({
  //       name: 'Total',
  //       data: [],
  //       stack: 0,
  //       color: '#00ffff',
  //       showInLegend: false,
  //       visible: false
  //     });
  //     data.series[1].data = [];
  //     data.series[2].data = [];
  //     data.series[3].data = [];
  //     data.series[1].dataLabels = [{
  //       align: 'center',
  //       x: 0,
  //       y: 0,
  //       rotation: 0,
  //       color: 'white',
  //       style: {
  //         fontWeight: 'normal',
  //         fontSize: '9px',
  //         textOutline: 0
  //       },
  //       formatter: function(){
  //         if(this.percentage === 0){
  //           return '';
  //         }
  //         return this.percentage.toFixed(0)  + ' %';
  //       }
  //     }]
  //     data.series[2].dataLabels = [{
  //       align: 'center',
  //       x: 0,
  //       y: 0,
  //       rotation: 0,
  //       color: 'black',
  //       style: {
  //         fontWeight: 'normal',
  //         fontSize: '9px',
  //         textOutline: 0
  //       },
  //       formatter: function(){
  //         if(this.percentage === 0){
  //           return '';
  //         }
  //         return this.percentage.toFixed(0)  + ' %';
  //       }
  //     }]
  //     data.series[3].dataLabels = [{
  //       align: 'center',
  //       x: 0,
  //       y: 0,
  //       rotation: 0,
  //       color: 'black',
  //       style: {
  //         fontWeight: 'normal',
  //         fontSize: '9px',
  //         textOutline: 0
  //       },
  //       formatter: function(){
  //         if(this.percentage === 0){
  //           return '';
  //         }
  //         return this.percentage.toFixed(0)  + ' %';
  //       }
  //     }]
  //     data.series[1].point = {
  //       events: {
  //         click: function () {
  //           // let filtros = [];
  //           // for(let i in self.state["arbol".concat(level, "Filtros")]) {
  //           //   filtros[i] = self.state["arbol".concat(level, "Filtros")][i];
  //           // }
  //           // filtros[self.state["arbol".concat(level, "Grupo")]] = this.name;
  //           // let orden = [];
  //           // self.state["arbol".concat(level, "Orden")].forEach(x => orden.push(x));
  //           // orden.push(self.state["arbol".concat(level, "Grupo")]);
  //           // let nivel = level + 1;
  //           // let temp = {};
  //           // orden.forEach(o => {
  //           //   if((Object.keys(temp)).length){
  //           //     temp = temp[o];
  //           //   } else {
  //           //     temp = arboles[o];
  //           //   }
  //           // })
  //           // let grupo = Object.keys(temp)[0];
  //           // if(grupo.includes("tabla")){
  //           //   return null;
  //           // }
  //           // // debugger;
  //           // self.setState({
  //           //   ["showParetoArbol".concat(nivel)]: true,
  //           //   ["arbol".concat(nivel, "Criterio")]: this.category,
  //           //   ["arbol".concat(nivel,"Filtros")]: filtros,
  //           //   ["arbol".concat(nivel, "Grupo")]: grupo,
  //           //   ["arbol".concat(nivel, "Orden")]: orden
  //           // }, () => self.loadArbol(nivel));
  //         }
  //       }
  //     };
  //     data.series[2].point = data.series[1].point;
  //     data.series[3].point = data.series[1].point;

  //   // let zoomData = [];

  //   // let zoomGroup = JSON.parse(JSON.stringify(data));
  //   // if(self.state.selectedParetoDisplayMode === 'sum'){
  //   //   zoomGroup.series[0] = {
  //   //     type: 'pareto',
  //   //     name: '% Acum.',
  //   //     yAxis: 1,
  //   //     zIndex: 10,
  //   //     data: [],
  //   //     color: '#008CFF',
  //   //     dataLabels:{
  //   //       enabled: false
  //   //     }
  //   //   };
  //   // }
  //   // zoomGroup.series[1].dataLabels = [{
  //   //   rotation: 270,
  //   //   verticalAlign: 'top',
  //   //   y: -25,
  //   //   style: {
  //   //     color: '#FFFFFF',
  //   //     textOutline: '0px',
  //   //     fontSize: '14px',
  //   //   },
  //   //   formatter: function(){
  //   //     let localstring = (this.y/1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   //     return (self.state.selectedParetoDisplayMode === 'sum' ? localstring : (this.y/1).toFixed(1));
  //   //   },
  //   //   enabled: true
  //   // }];
    
  //   // let zoomIndex = 1;
  //   // let aux = 0;

  //   // let total = 0;

  //   // let filtrotemp = this.props["tatArbol".concat(level)]["criterio"];

  //   // filtrotemp.forEach(x =>{
  //   //   let tempsum = (x[valorIndex] - x[benchmark]) * x.count_trips;
  //   //   total += (tempsum < 0 ? 0 : tempsum);
  //   // });
  //   // let pctacum = 0;

  //   for (let iTAT = 0; iTAT < this.props["tatArbol".concat(level)]["criterio"].length; iTAT++){
  //     let agencia = this.props["tatArbol".concat(level)]["criterio"][iTAT];
        
  //     if(agencia.avg_tat_real === 0){
  //       continue;
  //     }
  //     data.xAxis.categories.push(agencia.description);
  //     // zoomGroup.xAxis.categories.push(agencia.description);
  //     let lastDato = (self.state.selectedParetoDisplayMode === 'sum' ? Math.round((agencia[valorIndex] - agencia[benchmark])*agencia.count_trips) : (self.state.selectedParetoDisplayMode === 'desv' ? (agencia[valorIndex] - agencia[benchmark]) : agencia[valorIndex]));
  //     data.series[1].data.push({y: parseInt((lastDato/60).toFixed(1)), name: agencia.id});
  //     data.series[3].data.push({y: 100, pct: agencia.count_trips});
  //     // zoomGroup.series[1].data.push({y: parseInt((lastDato/60).toFixed(1)), name: agencia.id});
  //     // zoomGroup.series[3].data.push({y: 100, pct: agencia.count_trips});
  //     if(lastDato > 0){
  //       data.series[2].data.push(lastDato/**agencia.count_trips*/);
  //       // pctacum += (100*(lastDato/**agencia.count_trips*/)/total);
  //       // zoomGroup.series[0].data.push((pctacum.toFixed(2))/1);
  //     }
  //     // zoomIndex++;
  //     // if(zoomIndex===11){
  //     //   zoomIndex = 1;
  //     //   zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
  //     //   zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
  //     //   zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
  //     //   zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
  //     //   zoomData[aux].series[1].point = data.series[1].point;
  //     //   zoomData[aux].tooltip = data.tooltip;
  //     //   zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
  //     //   if(aux < 2){
  //     //     if(aux < 1){
  //     //       zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
  //     //     }
  //     //     zoomData[aux].series[1].dataLabels[0].rotation = 0;
  //     //   }else{
  //     //     zoomData[aux].series[1].dataLabels[0].rotation = 270;
  //     //   }
  //     //   aux++;
  //     // }
  //   }
  //   // zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
  //   // zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
  //   // zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
  //   // zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
  //   // zoomData[aux].series[1].point = data.series[1].point;
  //   // zoomData[aux].tooltip = data.tooltip;
  //   // zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
  //   // if(aux < 2){
  //   //   if(aux < 1){
  //   //     zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
  //   //   }
  //   //   zoomData[aux].series[1].dataLabels[0].rotation = 0;
  //   // }else{
  //   //   zoomData[aux].series[1].dataLabels[0].rotation = 270;
  //   // }

  //   if(data.xAxis.categories.length > 20){
  //     data.chart.width = 70 * data.xAxis.categories.length;
  //     // data.chart.height = 500;
  //     data.plotOptions.series.pointWidth = 35;
  //   }

  //   this.rutaChart = window.Highcharts.chart("paretoRutaArbol".concat(level, "Chart"), data, function(chart) { // on complete
  //     let min = chart.yAxis[0].min/chart.yAxis[0].max;
  //     min = min * chart.yAxis[1].max;
  //     chart.yAxis[1].update({ min: min });
  //   });
    
  //   // this.LTCharttest.reflow();
  //   document.getElementById("paretoRutaArbol".concat(level, "Chart")).style.overflow = 'auto';
  // }

  //DOWNLOAD
  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  openDocument = (type) => {
    let downloadUrl = this.getUrl(type);
    Axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Alertas.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  }

  getUrl = (type) => {
    switch(type){
      case "global": //alertas por tipo
        return `${SERVER}/api/v2/companies/${this.props.companyId}/dashboard_alerts/?view=desglose_alertas&action=download&start_date=${this.state.graphStartDate}&end_date=${this.state.graphEndDate}&facilities=${this.state.filterFacilities}&order_tags=${this.state.filterTags2}&trip_tags=${this.state.filterTags}&alerts=${this.state.filterAlerts}&level=${this.state.filterLevels}&fleet_provider=${this.state.filterLT}`;
      // case 1:
      // case 2:
      // case 3:
      // case 4:
      // case 5: //arboles
      //   let group = "";
      //   switch(this.state["arbol".concat(type, "Grupo")]){
      //     case "ruta":
      //       group= "route";
      //       break;
      //     case "uen":
      //       group= "uen";
      //       break;
      //     case "agencia":
      //       group= "to_facility";
      //       break;
      //     case "linea":
      //       group= "fleet_provider";
      //       break;
      //     default:
      //   }
      //   return `${SERVER}/api/v2/companies/${this.props.companyId}/tat/?group=${group}&view=criterio&action=${"download"}&start_date=${this.state.graphStartDate}&end_date=${this.state.graphEndDate}&from_facility=${this.state["arbol".concat(type, "Filtros")]["origen"] || ""}&to_facility=${this.state["arbol".concat(type, "Filtros")]["agencia"] || ""}&fleet_provider=${this.state["arbol".concat(type, "Filtros")]["linea"] || this.state.filterLT}&cargo_owner=${this.state.filterCO}&uen=${this.state["arbol".concat(type, "Filtros")]["uen"] || ""}&drv=${this.state["arbol".concat(type, "Filtros")]["drv"] || ""}&route=${this.state["arbol".concat(type, "Filtros")]["ruta"] || ""}&order_tags=${this.state.filterTags2}&trip_tags=${this.state.filterTags}&base_tags=${this.state.filterBaseTags}&facilities=${this.state.filterFacilities}&transport_class=${this.state.filterTransportClass}`
      default:
        return ""
    }
  }

  //Filters
  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  }

  handleFilterChange = (e) => {
    if(e.target.name === "filterDate"){
      this.setState({ [e.target.name]: e.target.value }/* , this.renderCanvas */);
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.setState({ buttonFilterName: "Aplicar" });
  }

  handleTreeFilterChange = (e, level) => {
    this.setState({ ["arbol".concat(level, "Filtros")]: {...this.state["arbol".concat(level, "Filtros")], facilities_tags: e.target.value} });
  }

  handleFilter = () => {
    // this.loadUsers()
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ /* showParetoRutas: false, */ buttonFilterName: "Restablecer" }, () => {
        this.loadGlobal();
        // this.hideTrees();
      })
    } else {
      this.setState({ /* showParetoRutas: false, */ buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      filterDate: [yesterday],
      filterFacilities: [],
      // filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      filterAlerts: [],
      filterLevels: [],
      // filterBaseTags: [],
      filterLT: [],
      // filterCO: [],
    }, () => {
      this.loadGlobal();
      // this.hideTrees();
    });
  }

  filterContents = () => {
    // if(this.props.companyType === 1)
    //   return [
    //     {
    //       title: "Fecha",
    //       // items: this.props.companyFacilities,
    //       name: "filterDate",
    //       listType: FILTER_LIST_TYPES.dateFixed,
    //     },
    //     {
    //       title: "Instalación",
    //       items: this.props.LTFacilities,
    //       name: "filterFacilities",
    //       listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    //     },
    //     {
    //       title: "Base",
    //       items: this.props.baseTags.map(oT => ({id: oT.id, description: oT.title})),
    //       name: "filterBaseTags",
    //       listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    //     },
    //     {
    //       title: "Clase de expedición",
    //       items: this.props.transportClassesByTag,
    //       name: "filterTransportClass",
    //       listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    //     },
    //     {
    //       title: "Clientes",
    //       items: this.props.networkCompanies,
    //       name: "filterCO",
    //       listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    //     },
    //   ];
    return [
      {
        title: "Fecha",
        // items: this.props.companyFacilities,
        name: "filterDate",
        listType: FILTER_LIST_TYPES.dateFixed,
      },
      {
        title: "Instalación",
        items: this.props.companyFacilities,
        name: "filterFacilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta",
        items: this.props.tripTags.map(oT => ({id: oT.id, description: oT.title})),
        name: "filterTags",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta 1",
        items: this.props.orderTags.map(oT => ({id: oT.id, description: oT.title})),
        name: "filterTags2",
        listType: FILTER_LIST_TYPES.checkExclusive,
      },
      // {
      //   title: "Clase de expedición",
      //   items: this.props.transportClassesByTag,
      //   name: "filterTransportClass",
      //   listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      // },
      {
        title: "Alertas",
        items: this.props.companyAlerts,
        name: "filterAlerts",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Nivel",
        items: [
          {
            id: 1,
            description: "1"
          },
          {
            id: 2,
            description: "2"
          },
          {
            id: 3,
            description: "3"
          },
          {
            id: 4,
            description: "4"
          },
          {
            id: 5,
            description: "5"
          },
        ],
        name: "filterLevels",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Linea Transportista",
        items: this.props.networkCompanies,
        name: "filterLT",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
    ]
  }

  getSelectedItems = () => {
    // if(this.props.companyType === 1){
    //   return {
    //     filterDate: [this.state.filterDate[0]],
    //     filterFacilities: this.state.filterFacilities,
    //     filterBaseTags: this.state.filterBaseTags,
    //     filterTransportClass: this.state.filterTransportClass,
    //     filterCO: this.state.filterCO,
    //   }
    // } else {
      return {
        filterDate: [this.state.filterDate[0]],
        filterFacilities: this.state.filterFacilities,
        filterTags2: this.state.filterTags2,
        filterTags: this.state.filterTags,
        // filterTransportClass: this.state.filterTransportClass,
        filterAlerts: this.state.filterAlerts,
        filterLevels: this.state.filterLevels,
        filterLT: this.state.filterLT,
      }
    // }
  }

  //StatsMonth
  getMonthStatsTitle = () => {
    return ("".concat(months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  //StatsDay
  getDayStatsTitle = () => {
    return ("".concat(this.state.filterDate[0].getDate(), " ", months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  graphDaily = () => {
    let month = this.state.selectedMonth + 1 < 10 ? '0'.concat(this.state.selectedMonth + 1) : this.state.selectedMonth + 1;
    const monthData = (this.props.alertsGlobal['dtd'] || []).filter(d => d.description.includes(''.concat(this.state.filterDate[0].getFullYear(), '-', month)));
    // debugger;
    if(monthData.length){
      const self = this;
      let data = JSON.parse(JSON.stringify(DefaultChart));
      data.chart.height = 550;
      data.chart.backgroundColor = 'transparent';
      data.chart.events = {
        click: function (event) {
          let hoverPoint = this.hoverPoint;
          // debugger;
          let date = new Date();
          date.setDate(hoverPoint.category)
          date.setMonth(self.state.selectedMonth);
          date.setFullYear(self.state.filterDate[0].getFullYear());
          self.setState({
            graphStartDate: dateToString(date),
            graphEndDate: dateToString(date),
            showParetoTipo: true,
          }, self.loadDesgloseTipo)
        }
      }

      data.legend = {
        itemStyle: {
          color: '#fff',
          fontSize: '15px'
        }
      };

      data.xAxis = {
        categories: [],
        crosshair: true,
        lineColor: '#303349',
        tickLength: 0,
        labels: {
              style: {
                  color: 'white',
                  fontSize: '15px'
              }
          }
      };
      data.yAxis = data.yAxis[0];
      data.yAxis.stackLabels = {
        enabled: true,
        rotation: 0,
        x: 0,
        y: -5,
        formatter: function(){
          if (this.total === 0){
            return null;
          }
          // else if(this.total > 1000){
          //   return (this.total/1000).toFixed(1);
          // }
          else{
            return (self.state.monthMode === 'total' ? (this.total/1000).toFixed(1) : this.total.toFixed(1));
          }
        },
        style: {
          color: '#FFFFFF',
          textOutline: '0px',
          fontSize: '14px',
        }
      };
      data.yAxis.labels = {enabled: false};
  
      data.series = [];
      data.series.push(cloneDeep(DefaultChart.series[0]));
      data.series.push(cloneDeep(DefaultChart.series[1]));
      data.series.push(cloneDeep(DefaultChart.series[2]));
      data.series[0].data = [];
      data.series[1].data = [];
      data.series[2].data = [];
      data.series[0].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'white',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.monthMode === 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[1].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.monthMode == 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[2].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.monthMode == 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[0].point = {
        events: {
          click: function () {
            // self.loadDate(dateDictionary[this.category]);
            let date = new Date();
            date.setDate(this.category)
            date.setMonth(self.state.selectedMonth);
            date.setFullYear(self.state.filterDate[0].getFullYear());
            self.setState({
              graphStartDate: dateToString(date),
              graphEndDate: dateToString(date),
              showParetoTipo: true,
            }, self.loadDesgloseTipo)
          }
        }
      };
      data.series[1].point = data.series[0].point;
      data.series[2].point = data.series[0].point;
  
      // var monthData = {
      //   total: 0,
      //   alto: 0,
      //   bajo: 0,
      //   medio: 0
      // }
  
      for (let iDay = 0; iDay < monthData.length; iDay++){
        let fecha = monthData[iDay].description.split('-');
        let label = Number(fecha[2].substring(0,2));
        data.xAxis.categories.push(label);
  
        // dateDictionary[label] = new Date();
        // dateDictionary[label].setMonth(Number(fecha[1]) - 1);
        // dateDictionary[label].setDate(Number(fecha[2]));
        // dateDictionary[label].setFullYear(Number(fecha[0]));
  
        // Dashboard.Percent(monthData[iDay]);
  
        if(monthData[iDay].folios_alto === 0){
          monthData[iDay].folios_alto = 1;
        }
        if(monthData[iDay].folios_medio === 0){
          monthData[iDay].folios_medio = 1;
        }
        if(monthData[iDay].folios_bajo === 0){
          monthData[iDay].folios_bajo = 1;
        }
        data.series[0].data.push({ y:(self.state.monthMode === 'total' ? monthData[iDay].alertas_alto : (monthData[iDay].alertas_alto/monthData[iDay].folios_alto) )/*, pct: monthData[iDay].pctAlto*/ });
        data.series[1].data.push({ y:(self.state.monthMode === 'total' ? monthData[iDay].alertas_medio : (monthData[iDay].alertas_medio/monthData[iDay].folios_medio) )/*, pct: monthData[iDay].pctMedio*/ });
        data.series[2].data.push({ y:(self.state.monthMode === 'total' ? monthData[iDay].alertas_bajo : (monthData[iDay].alertas_bajo/monthData[iDay].folios_bajo) )/*, pct: monthData[iDay].pctBajo*/ });
      }
      
      // this.monthChart = Highcharts.chart(this.UI.month.chart, data);
      // debugger;
      window.Highcharts.chart('monthChart', data);
    }
    // debugger;
  }

  graphYear = () => {
    // let month = this.state.selectedMonth + 1 < 10 ? '0'.concat(this.state.selectedMonth + 1) : this.state.selectedMonth + 1;/
    // const monthData = (this.props.alertsGlobal['dtd'] || []).filter(d => d.description.includes(''.concat(this.state.filterDate[0].getFullYear(), '-', month)));
    const yearData = (this.props.alertsGlobal['year'] || [])[0];
    const monthData = (this.props.alertsGlobal['mtm'] || [])
    // debugger;
    // if(monthData.length){
      const self = this;
      let data = JSON.parse(JSON.stringify(DefaultChart));
      data.chart.height = 550;
      data.chart.backgroundColor = 'transparent';

      data.chart.events = {
        click: function (event) {
          let hoverPoint = this.hoverPoint;
          // debugger;
          let startDate = 0;
          let endDate = 0;
          // debugger;
          if(Object.keys(months_index).includes(hoverPoint.category)){
            startDate = new Date(self.state.filterDate[0].getFullYear(), months_index[hoverPoint.category], 1);
            if(startDate.getMonth() === self.state.filterDate[0].getMonth()){
              endDate = self.state.filterDate[0];
            }else{
              endDate = new Date(self.state.filterDate[0].getFullYear(), months_index[hoverPoint.category]+1, 0);
            }
          } else{
            startDate = new Date(self.state.filterDate[0].getFullYear(), 0, 1);
            endDate = new Date(self.state.filterDate[0].getFullYear(), 11, 31);
          }
          self.setState({
            graphStartDate: dateToString(startDate),
            graphEndDate: dateToString(endDate),
            showParetoTipo: true,
          }, self.loadDesgloseTipo)
        }
      }

      data.tooltip = {
        shared: true,
        // valueDecimals: 2,
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': <b>' + (point.point.pct ? point.point.pct : point.y) + '</b>';
          }, '<b>' + this.x + '</b>');
        },
      };

      data.legend = {
        itemStyle: {
          color: '#fff',
          fontSize: '15px'
        }
      };

      data.xAxis = {
        categories: [],
        crosshair: true,
        lineColor: '#303349',
        tickLength: 0,
        labels: {
              style: {
                  color: 'white',
                  fontSize: '15px'
              }
          }
      };
      data.yAxis = data.yAxis[0];
      data.yAxis.stackLabels = {
        enabled: true,
        rotation: 0,
        x: 0,
        y: -5,
        formatter: function(){
          let sumatoria = (this.x === 0 && self.state.yearMode === 'total' ? this.total/self.factor : this.total);
          if (sumatoria === 0){
            return null;
          }
          // else if(sumatoria > 1000){
          //   return (sumatoria/1000).toFixed(1);
          // }
          else{
            return (self.state.yearMode === 'total' ? (sumatoria/1000).toFixed(1) : sumatoria.toFixed(1));
          }
        },
        style: {
          color: '#FFFFFF',
          textOutline: '0px',
          fontSize: '14px',
        }
      };
      data.yAxis.labels = {enabled: false};
  
      data.series = [];
      data.series.push(cloneDeep(DefaultChart.series[0]));
      data.series.push(cloneDeep(DefaultChart.series[1]));
      data.series.push(cloneDeep(DefaultChart.series[2]));
      data.series[0].data = [];
      data.series[1].data = [];
      data.series[2].data = [];
      data.series[0].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'white',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.yearMode === 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[1].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.yearMode == 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[2].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return (self.state.yearMode == 'total' ? this.percentage.toFixed(0)  + ' %' : this.y.toFixed(1));
        }
      }]
      data.series[0].point = {
        events: {
          click: function () {
            // self.loadDate(dateDictionary[this.category]);
            let startDate = 0;
            let endDate = 0;
            // debugger;
            if(Object.keys(months_index).includes(this.category)){
              startDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category], 1);
              if(startDate.getMonth() === self.state.filterDate[0].getMonth()){
                endDate = self.state.filterDate[0];
              }else{
                endDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category]+1, 0);
              }
            } else{
              startDate = new Date(self.state.filterDate[0].getFullYear(), 0, 1);
              endDate = new Date(self.state.filterDate[0].getFullYear(), 11, 31);
            }
            self.setState({
              graphStartDate: dateToString(startDate),
              graphEndDate: dateToString(endDate),
              showParetoTipo: true,
            }, self.loadDesgloseTipo)
          }
        }
      };
      data.series[1].point = data.series[0].point;
      data.series[2].point = data.series[0].point;
  
      //YEAR 
      let fecha = yearData.description.split('-');
      let label = Number(fecha[0]);
      data.xAxis.categories.push(label);
      data.series[0].data.push({ y:0, pct: 0 });
      data.series[1].data.push({ y:0, pct: 0 });
      data.series[2].data.push({ y:0, pct: 0 });
      //YEAR

      let mayor = 0;
      // let monthEnd = 12;//this.state.currentDate.getMo-nth();
      // let monthStart = 0;//Math.max(monthEnd - 2, 0);
  
      monthData.forEach((m, i) => {
        label = months_abbr[i];
        data.xAxis.categories.push(label);

        if(m.folios_alto === 0){
          m.folios_alto = 1;
        }
        if(m.folios_medio === 0){
          m.folios_medio = 1;
        }
        if(m.folios_bajo === 0){
          m.folios_bajo = 1;
        }
        let temp = m.alertas_alto + m.alertas_medio + m.alertas_bajo;
        mayor = (temp > mayor ? (temp*1) : mayor);
  
        data.series[0].data.push({ y:(self.state.yearMode === 'total' ? m.alertas_alto : (m.alertas_alto/m.folios_alto).toFixed(2)/1) });
        data.series[1].data.push({ y:(self.state.yearMode === 'total' ? m.alertas_medio : (m.alertas_medio/m.folios_medio).toFixed(2)/1) });
        data.series[2].data.push({ y:(self.state.yearMode === 'total' ? m.alertas_bajo : (m.alertas_bajo/m.folios_bajo).toFixed(2)/1) });
      })
      
      self.factor = ((yearData.alertas_alto + yearData.alertas_medio + yearData.alertas_bajo) !== 0 ? mayor/(yearData.alertas_alto + yearData.alertas_medio + yearData.alertas_bajo) : 1);
      data.series[0].data[0] = { y:(self.state.yearMode === 'total' ? (yearData.alertas_alto * self.factor) : (yearData.alertas_alto/yearData.folios_alto).toFixed(2)/1), pct: self.state.yearMode === 'total' ? yearData.alertas_alto : (yearData.alertas_alto/yearData.folios_alto).toFixed(2)/1 };
      data.series[1].data[0] = { y:(self.state.yearMode === 'total' ? (yearData.alertas_medio * self.factor) : (yearData.alertas_medio/yearData.folios_medio).toFixed(2)/1), pct: (self.state.yearMode === 'total' ? (yearData.alertas_medio) : (yearData.alertas_medio/yearData.folios_medio).toFixed(2)/1) };
      data.series[2].data[0] = { y:(self.state.yearMode === 'total' ? (yearData.alertas_bajo * self.factor) : (yearData.alertas_bajo/yearData.folios_bajo).toFixed(2)/1), pct: (self.state.yearMode === 'total' ? (yearData.alertas_bajo) : (yearData.alertas_bajo/yearData.folios_bajo).toFixed(2)/1) };
      // for (let iDay = 0; iDay < monthData.length; iDay++){
      //   let fecha = monthData[iDay].description.split('-');
      //   let label = Number(fecha[2].substring(0,2));
      //   data.xAxis.categories.push(label);
  
      //   // dateDictionary[label] = new Date();
      //   // dateDictionary[label].setMonth(Number(fecha[1]) - 1);
      //   // dateDictionary[label].setDate(Number(fecha[2]));
      //   // dateDictionary[label].setFullYear(Number(fecha[0]));
  
      //   // Dashboard.Percent(monthData[iDay]);
  
      //   if(monthData[iDay].folios_alto === 0){
      //     monthData[iDay].folios_alto = 1;
      //   }
      //   if(monthData[iDay].folios_medio === 0){
      //     monthData[iDay].folios_medio = 1;
      //   }
      //   if(monthData[iDay].folios_bajo === 0){
      //     monthData[iDay].folios_bajo = 1;
      //   }
      //   data.series[0].data.push({ y:(self.state.yearMode === 'total' ? monthData[iDay].alertas_alto : (monthData[iDay].alertas_alto/monthData[iDay].folios_alto) )/*, pct: monthData[iDay].pctAlto*/ });
      //   data.series[1].data.push({ y:(self.state.yearMode === 'total' ? monthData[iDay].alertas_medio : (monthData[iDay].alertas_medio/monthData[iDay].folios_medio) )/*, pct: monthData[iDay].pctMedio*/ });
      //   data.series[2].data.push({ y:(self.state.yearMode === 'total' ? monthData[iDay].alertas_bajo : (monthData[iDay].alertas_bajo/monthData[iDay].folios_bajo) )/*, pct: monthData[iDay].pctBajo*/ });
      // }
      
      // this.monthChart = Highcharts.chart(this.UI.month.chart, data);
      // debugger;
      // debugger;
      window.Highcharts.chart('yearChart', data);
    // }
    // debugger;
  }

  setBars = () => {
    if(this.props.alertsGlobal['mtm']){
      let month = this.state.filterDate[0].getMonth();
      let monthInfo = this.props.alertsGlobal['mtm'][month];
      if(monthInfo){
        if(monthInfo.total_alertas){
          this.setState({
            monthInfo: {
              total: monthInfo.total_alertas,
              high: monthInfo.alertas_alto,
              highPct: Math.floor((monthInfo.alertas_alto / monthInfo.total_alertas) * 100),
              mid: monthInfo.alertas_medio,
              midPct: Math.floor((monthInfo.alertas_medio / monthInfo.total_alertas) * 100),
              low: monthInfo.alertas_bajo,
              lowPct: Math.floor((monthInfo.alertas_bajo / monthInfo.total_alertas) * 100),
            }
          })
        } else {
          this.setState({
            monthInfo: {
              total: monthInfo.total_alertas,
              high: monthInfo.alertas_alto,
              highPct: 33,
              mid: monthInfo.alertas_medio,
              midPct: 33,
              low: monthInfo.alertas_bajo,
              lowPct: 33,
            }
          })
        }
      } else {
        this.setState({monthInfo: {}});
      }
    }
    if(this.props.alertsGlobal['dtd']){
      let month = this.state.filterDate[0].getMonth() < 10 ? '0'.concat(this.state.filterDate[0].getMonth()) : this.state.filterDate[0].getMonth();
      let day = this.state.filterDate[0].getDate() < 10 ? '0'.concat(this.state.filterDate[0].getDate()) : this.state.filterDate[0].getDate();
      let date = "".concat(this.state.filterDate[0].getFullYear(), '-',  month, '-', day, ' 00:00:00+00:00');
      // debugger;
      let dayInfo = this.props.alertsGlobal['dtd'].find(d => d.id === date);
      if(dayInfo){
        if(dayInfo.total_alertas){
          this.setState({
            dayInfo: {
              total: dayInfo.total_alertas,
              high: dayInfo.alertas_alto,
              highPct: Math.floor((dayInfo.alertas_alto / dayInfo.total_alertas) * 100),
              mid: dayInfo.alertas_medio,
              midPct: Math.floor((dayInfo.alertas_medio / dayInfo.total_alertas) * 100),
              low: dayInfo.alertas_bajo,
              lowPct: Math.floor((dayInfo.alertas_bajo / dayInfo.total_alertas) * 100),
            }
          })
        } else {
          this.setState({
            dayInfo: {
              total: dayInfo.total_alertas,
              high: dayInfo.alertas_alto,
              highPct: 33,
              mid: dayInfo.alertas_medio,
              midPct: 33,
              low: dayInfo.alertas_bajo,
              lowPct: 33,
            }
          })
        }
      } else {
        this.setState({dayInfo: {}});
      }
    }
  }

  //Pareto tipo alertas

  graphPareto = () => {
    // let month = this.state.selectedMonth + 1 < 10 ? '0'.concat(this.state.selectedMonth + 1) : this.state.selectedMonth + 1;/
    // const monthData = (this.props.alertsGlobal['dtd'] || []).filter(d => d.description.includes(''.concat(this.state.filterDate[0].getFullYear(), '-', month)));
    // const yearData = (this.props.alertsGlobal['year'] || [])[0];
    const alertsData = (this.props.alertsDesgloseTipo || []).sort((a,b) => {
      if(a.total_alertas > b.total_alertas){
        return -1
      } else if(a.total_alertas < b.total_alertas){
        return 1
      } else {
        return 0
      }
    });

    // debugger;
    // if(monthData.length){
      const self = this;
      let data = JSON.parse(JSON.stringify(DefaultChart));
      data.chart.height = 550;
      data.chart.backgroundColor = 'transparent';

      data.chart.events = {
        click: function (event) {
          let hoverPoint = this.hoverPoint;
          // debugger;
          let filtros = {alerts: (self.props.alertsDesgloseTipo.find(a => a.description === hoverPoint.category) || {id: 0}).id};
          // filtros["alerts"] = (self.props.alertsDesgloseTipo.find(a => a.description === hoverPoint.category) || {id: 0}).id;
          self.setState({
            arbol1Filtros: filtros,
            showParetoArbol1: true,
            arbol1Criterio: hoverPoint.category,
            arbol1Grupo: treeGroups[0].id
          }, () => {self.loadArbolData(1)})
        }
      }

      data.tooltip = {
        shared: true,
        // valueDecimals: 2,
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': <b>' + (/* point.point.pct ? point.point.pct : */ point.y.toFixed(2)) + '</b>';
          }, '<b>' + this.x + '</b>');
        },
      };

      data.legend = {
        itemStyle: {
          color: '#fff',
          fontSize: '15px'
        }
      };

      data.xAxis = {
        categories: [],
        crosshair: true,
        lineColor: '#303349',
        tickLength: 0,
        labels: {
              style: {
                  color: 'white',
                  fontSize: '15px'
              }
          }
      };
      // data.yAxis = data.yAxis;
      // data.yAxis.stackLabels = {
      //   enabled: true,
      //   rotation: 0,
      //   x: 0,
      //   y: -5,
      //   formatter: function(){
      //     if(this.total > 1000){
      //     return (this.total/1000).toFixed(1);
      //     }
      //     else{
      //       return this.total;
      //     }
      //   },
      //   style: {
      //     color: '#FFFFFF',
      //     textOutline: '0px',
      //     fontSize: '14px',
      //   }
      // };
      // data.yAxis.labels = {enabled: false};

      data.yAxis[0].labels = {
        enabled: false,
      };
      data.yAxis[0].endOnTick = false;
      data.yAxis[0].gridLineWidth = 0;
      data.yAxis[0].maxPadding = 0.15;
  
      data.yAxis[0].stackLabels.enabled = true;
      data.yAxis[0].stackLabels.rotation = 0;
      data.yAxis[0].stackLabels.x = 0;
      data.yAxis[0].stackLabels.y = -3;
      data.yAxis[0].stackLabels.formatter = function(){
        if(this.total > 1000){
        return (this.total/1000).toFixed(1);
        }
        else{
          return this.total;
        }
      }
  
      data.series = [];
      data.series.push({
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 4,
        color: '#008CFF',
        dataLabels: {
          enabled: false,
        }
      });
      data.series.push(cloneDeep(DefaultChart.series[0]));
      data.series.push(cloneDeep(DefaultChart.series[1]));
      data.series.push(cloneDeep(DefaultChart.series[2]));
      data.series.push({
        name: 'Total',
        data: [],
        stack: 0,
        color: '#00ffff',
        showInLegend: false,
        visible: false
      });
      data.series[1].data = [];
      data.series[2].data = [];
      data.series[3].data = [];
      data.series[1].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'white',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[2].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[3].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[1].point = {
        events: {
          click: function () {
            // this.category;
            let filtros = {alerts: (self.props.alertsDesgloseTipo.find(a => a.description === this.category) || {id: 0}).id};
            // filtros["alerts"] = (self.props.alertsDesgloseTipo.find(a => a.description === this.category) || {id: 0}).id;
            self.setState({
              arbol1Filtros: filtros,
              showParetoArbol1: true,
              arbol1Criterio: this.category,
              arbol1Grupo: treeGroups[0].id
            }, () => {self.loadArbolData(1)})
          }
        }
      };
      data.series[2].point = data.series[1].point;
      data.series[3].point = data.series[1].point;

      alertsData.forEach(alert => {
        let label = alert.description;
        data.xAxis.categories.push(label);

        data.series[1].data.push({ y: alert.alertas_alto});
        data.series[2].data.push({ y: alert.alertas_medio});
        data.series[3].data.push({ y: alert.alertas_bajo});
        data.series[4].data.push({ y: alert.total_alertas})
      });
      // debugger;
      window.Highcharts.chart('paretoTipoChart', data);
    // }
  }

  getParetoDate = () => {
    if(this.state.graphStartDate === "" || this.state.graphEndDate === "")
      return ""
    let date1 = this.state.graphStartDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphStartDate.split('-')[1])-1]);
    let date2 = this.state.graphEndDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphEndDate.split('-')[1])-1]);
    if(date1 === date2)
      return date1;
    return date1.concat(' - ', date2);
  }

  /*
      arbol6Criterio: 0,
      arbol6Filtros: [],
      arbol6Grupo: "",
      arbol6Orden: [],
  */
  //Arboles
  loadArbolData = (level) => {
    document.getElementById("paretoArbol".concat(level, "Chart")).innerHTML = "";
    this.setState({["arbol".concat(level, "Loading")]: true});
    if(this.state["arbol".concat(level, "Grupo")] !== "facilities"){
      this.props.loadAlertsCriterio({
        company_id: this.props.companyId,
        start_date: this.state.graphStartDate,
        end_date: this.state.graphEndDate,
        is_active: this.state.active_alerts,
        group: this.state["arbol".concat(level, "Grupo")],
        facilities: this.state["arbol".concat(level, "Filtros")]["facilities"] || this.state.filterFacilities,
        order_tags: this.state.filterTags2,
        trip_tags: this.state.filterTags,
        alerts: this.state["arbol".concat(level, "Filtros")]["alerts"] || this.state.filterAlerts,
        level: this.state.filterLevels,
        fleet_provider: this.state["arbol".concat(level, "Filtros")]["fleet_provider"] || this.state.filterLT,
        ruta: this.state["arbol".concat(level, "Filtros")]["ruta"] ||  [],
        facilities_tags: this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []
      }, {
        alias: "arbol".concat(level),
        onSuccess: () => {this.setState({["arbol".concat(level, "Loading")]: false}); this.graphArbol(level)}
      })
    } else {
      if(!(this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []).length) {
        this.setState({
          ["arbol".concat(level, "Filtros")]: {...this.state["arbol".concat(level, "Filtros")], facilities_tags: this.props.companyFacilities.map(f => f.id)}
        }, () => {
          this.props.loadAlertsCriterio({
            company_id: this.props.companyId,
            start_date: this.state.graphStartDate,
            end_date: this.state.graphEndDate,
            is_active: this.state.active_alerts,
            group: "facilities_tags",
            facilities: this.state["arbol".concat(level, "Filtros")]["facilities"] || this.state.filterFacilities,
            order_tags: this.state.filterTags2,
            trip_tags: this.state.filterTags,
            alerts: this.state["arbol".concat(level, "Filtros")]["alerts"] || this.state.filterAlerts,
            level: this.state.filterLevels,
            fleet_provider: this.state["arbol".concat(level, "Filtros")]["fleet_provider"] || this.state.filterLT,
            ruta: this.state["arbol".concat(level, "Filtros")]["ruta"] ||  [],
            facilities_tags: this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []
          }, {
            alias: "arbol".concat(level),
            onSuccess: () => {this.setState({["arbol".concat(level, "Loading")]: false}); this.graphArbol(level)}
          })
        })
      } else if((this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []).length > 1){
        this.props.loadAlertsCriterio({
          company_id: this.props.companyId,
          start_date: this.state.graphStartDate,
          end_date: this.state.graphEndDate,
          is_active: this.state.active_alerts,
          group: "facilities_tags",
          facilities: this.state["arbol".concat(level, "Filtros")]["facilities"] || this.state.filterFacilities,
          order_tags: this.state.filterTags2,
          trip_tags: this.state.filterTags,
          alerts: this.state["arbol".concat(level, "Filtros")]["alerts"] || this.state.filterAlerts,
          level: this.state.filterLevels,
          fleet_provider: this.state["arbol".concat(level, "Filtros")]["fleet_provider"] || this.state.filterLT,
          ruta: this.state["arbol".concat(level, "Filtros")]["ruta"] ||  [],
          facilities_tags: this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []
        }, {
          alias: "arbol".concat(level),
          onSuccess: () => {this.setState({["arbol".concat(level, "Loading")]: false}); this.graphArbol(level)}
        })
      } else {
        let facilitiesIDs = this.props.companyFacilities
          .find(fT => fT.id.toString() === (this.state["arbol".concat(level, "Filtros")]["facilities_tags"])[0].toString()).items.map(i => i.id);
        this.props.loadAlertsCriterio({
          company_id: this.props.companyId,
          start_date: this.state.graphStartDate,
          end_date: this.state.graphEndDate,
          is_active: this.state.active_alerts,
          group: "facilities",
          facilities: facilitiesIDs,
          order_tags: this.state.filterTags2,
          trip_tags: this.state.filterTags,
          alerts: this.state["arbol".concat(level, "Filtros")]["alerts"] || this.state.filterAlerts,
          level: this.state.filterLevels,
          fleet_provider: this.state["arbol".concat(level, "Filtros")]["fleet_provider"] || this.state.filterLT,
          ruta: this.state["arbol".concat(level, "Filtros")]["ruta"] ||  [],
          facilities_tags: this.state["arbol".concat(level, "Filtros")]["facilities_tags"] ||  []
        }, {
          alias: "arbol".concat(level),
          onSuccess: () => {this.setState({["arbol".concat(level, "Loading")]: false}); this.graphArbol(level)}
        })
      }
    }
    
    Scroll.go(document.getElementById('paretoArbol'.concat(level)), document.getElementById('pageContent'));
  }

  graphArbol = (level) => {
    if(!this.props["arbol".concat(level)]["criteria"]) return null;
    if(this.state["arbol".concat(level, "Grupo")] === "table"){
      document.getElementById("paretoArbol".concat(level, "Chart")).innerHTML = "";
      // this.renderTable(level);
      return null;
    } 

    const self = this;
      let data = JSON.parse(JSON.stringify(DefaultChart));
      data.chart.height = 550;
      data.chart.backgroundColor = 'transparent';

      data.chart.events = {
        click: function (event) {
          let hoverPoint = this.hoverPoint;
          // debugger;
          if(self.state["arbol".concat(level, "Grupo")] === "economic_number") return null;
          let group = (self.state["arbol".concat(level, "Grupo")] === 'facilities' && self.state["arbol".concat(level, "Filtros")]["facilities_tags"].length > 1) ? 'facilities_tags' : self.state["arbol".concat(level, "Grupo")];
          let filtros = {...self.state["arbol".concat(level, "Filtros")], [group]: hoverPoint.extra};
          if(group === "facilities"){
            filtros.facilities_tags = "";
          }
          // // filtros["alerts"] = (self.props.alertsDesgloseTipo.find(a => a.description === hoverPoint.category) || {id: 0}).id;
          // debugger;
          let takenGroups = [];
          for(let i = 1; i <= level; i++){
            takenGroups.push(self.state["arbol".concat(i, "Grupo")])
          }
          let nextGroup = treeGroups.filter(tG => !takenGroups.includes(tG.id));
          // debugger;
          let nextLevel = level + 1;
          self.setState({
            ["arbol".concat(nextLevel, "Filtros")]: filtros,
            ["showParetoArbol".concat(nextLevel)]: true,
            ["arbol".concat(nextLevel,"Criterio")]: hoverPoint.category,
            ["arbol".concat(nextLevel,"Grupo")]: nextGroup[0].id,
          }, () => {self.loadArbolData(nextLevel)})
        }
      }

      data.tooltip = {
        shared: true,
        // valueDecimals: 2,
        formatter: function () {
          return this.points.reduce(function (s, point) {
            return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': <b>' + (/* point.point.pct ? point.point.pct : */ point.y.toFixed(2)) + '</b>';
          }, '<b>' + this.x + '</b>');
        },
      };

      data.legend = {
        itemStyle: {
          color: '#fff',
          fontSize: '15px'
        }
      };

      data.xAxis = {
        categories: [],
        crosshair: true,
        lineColor: '#303349',
        tickLength: 0,
        labels: {
              style: {
                  color: 'white',
                  fontSize: '15px'
              }
          }
      };
      // data.yAxis = data.yAxis;
      // data.yAxis.stackLabels = {
      //   enabled: true,
      //   rotation: 0,
      //   x: 0,
      //   y: -5,
      //   formatter: function(){
      //     if(this.total > 1000){
      //     return (this.total/1000).toFixed(1);
      //     }
      //     else{
      //       return this.total;
      //     }
      //   },
      //   style: {
      //     color: '#FFFFFF',
      //     textOutline: '0px',
      //     fontSize: '14px',
      //   }
      // };
      // data.yAxis.labels = {enabled: false};

      data.yAxis[0].labels = {
        enabled: false,
      };
      data.yAxis[0].endOnTick = false;
      data.yAxis[0].gridLineWidth = 0;
      data.yAxis[0].maxPadding = 0.15;
  
      data.yAxis[0].stackLabels.enabled = true;
      data.yAxis[0].stackLabels.rotation = 0;
      data.yAxis[0].stackLabels.x = 0;
      data.yAxis[0].stackLabels.y = -3;
      data.yAxis[0].stackLabels.formatter = function(){
        if(this.total > 1000){
        return (this.total/1000).toFixed(1);
        }
        else{
          return this.total;
        }
      }
  
      data.series = [];
      data.series.push({
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 4,
        color: '#008CFF',
        dataLabels: {
          enabled: false,
        }
      });
      data.series.push(cloneDeep(DefaultChart.series[0]));
      data.series.push(cloneDeep(DefaultChart.series[1]));
      data.series.push(cloneDeep(DefaultChart.series[2]));
      data.series.push({
        name: 'Total',
        data: [],
        stack: 0,
        color: '#00ffff',
        showInLegend: false,
        visible: false
      });
      data.series[1].data = [];
      data.series[2].data = [];
      data.series[3].data = [];
      data.series[1].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'white',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[2].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[3].dataLabels = [{
        align: 'center',
        x: 0,
        y: 0,
        rotation: 0,
        color: 'black',
        style: {
          fontWeight: 'normal',
          fontSize: '9px',
          textOutline: 0
        },
        formatter: function(){
          if(this.percentage === 0){
            return '';
          }
          return this.percentage.toFixed(0)  + ' %';
        }
      }]
      data.series[1].point = {
        events: {
          click: function () {
            if(self.state["arbol".concat(level, "Grupo")] === "economic_number") return null;
            let group = (self.state["arbol".concat(level, "Grupo")] === 'facilities' && self.state["arbol".concat(level, "Filtros")]["facilities_tags"].length > 1) ? 'facilities_tags' : self.state["arbol".concat(level, "Grupo")];
            let filtros = {...self.state["arbol".concat(level, "Filtros")], [group]: this.extra};
            if(group === "facilities"){
              filtros.facilities_tags = "";
            }
            // // filtros["alerts"] = (self.props.alertsDesgloseTipo.find(a => a.description === this.category) || {id: 0}).id;
            // debugger;
            let takenGroups = [];
            for(let i = 1; i <= level; i++){
              takenGroups.push(self.state["arbol".concat(i, "Grupo")])
            }
            let nextGroup = treeGroups.filter(tG => !takenGroups.includes(tG.id));
            // debugger;
            let nextLevel = level + 1;
            self.setState({
              ["arbol".concat(nextLevel, "Filtros")]: filtros,
              ["showParetoArbol".concat(nextLevel)]: true,
              ["arbol".concat(nextLevel,"Criterio")]: this.category,
              ["arbol".concat(nextLevel,"Grupo")]: nextGroup[0].id,
            }, () => {self.loadArbolData(nextLevel)})
          }
        }
      };
      data.series[2].point = data.series[1].point;
      data.series[3].point = data.series[1].point;

      let alertsData = this.props["arbol".concat(level)]["criteria"];

      alertsData = alertsData.sort((a,b) => b.total_alertas - a.total_alertas );

      alertsData.forEach(alert => {
        let label = alert["descripción"];
        data.xAxis.categories.push(label);

        data.series[1].data.push({ y: alert.alertas_alto, extra: alert.id});
        data.series[2].data.push({ y: alert.alertas_medio, extra: alert.id});
        data.series[3].data.push({ y: alert.alertas_bajo, extra: alert.id});
        data.series[4].data.push({ y: alert.total_alertas, extra: alert.id});
      });
      // debugger;
      window.Highcharts.chart('paretoArbol'.concat(level, 'Chart'), data);

  }

  renderTable = (level) => {
    let table = (
      <div className="pareto-table">
        <div className="table-header" style={{marginRight: (this.props["arbol".concat(level)]["viajes"] || []).length >= 9 ? "16px" : ""}}>
          {
            <>
              <div className="Ruta">Origen</div>
              <div className="Ruta">Destino</div>
              <div className="Signatura">Signatura</div>
              <div className="F-Ida">Folio Ida</div>
              <div className="F-Vuelta">Folio Vuelta</div>
              <div className="Linea">Línea</div>
              <div className="Camion">Camión Económico</div>
              <div className="Cita-small">Cita Carga</div>
              <div className="Cita-small">Cita Desc.</div>
              {/* <div className="Meta">TAT Meta</div>
              <div className="Real">TAT Real</div> */}
              {/* <div className="Desv">Nivel</div> */}
            </>
          }
        </div>
        <div class="list">
          {
            (this.props["arbol".concat(level)]["viajes"] || []).map(v => {
              let row = <div className="trip">
                {
                  <>
                    <div class="Ruta">{v["Origen (Nombre)"] || '-'}</div>
                    <div class="Ruta">{v["Destino (Nombre)"] || '-'}</div>
                    <div class="Signatura">{v["Signatura"]}</div>
                    <div class="F-Ida">{v["Folio Ida"]}</div>
                    <div class="F-Vuelta">{v["Folio Vuelta"]}</div>
                    <div class="Linea">{v["Transportista Alias"]}</div>
                    <div class="Camion">{v["Económico"]}</div>
                    <div className="Cita-small">{v["Primer cita de carga ida"] ? (new Date(v["Primer cita de carga ida"])).toLocaleString("es-MX") : '-'}</div>
                    <div className="Cita-small">{v["Primer cita de descarga ida"] ? (new Date(v["Primer cita de descarga ida"])).toLocaleString("es-MX") : '-'}</div>
                    {/* <div class="Meta">{((v.tat_benchmark)/60).toFixed(2)}</div>
                    <div class="Real">{((v.tat_real)/60).toFixed(2)}</div> */}
                    {/* <div class="Desv" >{v[""]}</div> */}
                  </>
                }
              </div>
              return row;
            })
          }
        </div>
      </div>
    )
    return table;
  }

  getArbolGroups = (level) => {
    let takenGroups = [];
    for(let i = 1; i < level; i++){
      takenGroups.push(this.state["arbol".concat(i, "Grupo")])
    }
    return treeGroups.filter(tG => !takenGroups.includes(tG.id)).map(f => (
      <li   
        className={"pantalla ".concat(
          f.id === this.state["arbol".concat(level, "Grupo")] ? " selected" : "",
        )}
        onClick={() => {
          this.hideArbol(level);
          this.setState({["arbol".concat(level, "Grupo")]: f.id}, () => {if(f.id !== "table")this.loadArbolData(level)});
        }}  
      >
        {
          f.id.includes('table') 
          ? "Alertas"
          : f.name
        }
        <div></div>
      </li>
    ))
    
  }

  hideArbol = (level=0) => {
    let treesToHide = {};
    for(let i = level+1; i <= 6; i++){
      treesToHide["showParetoArbol".concat(i)] = false;
    }
    this.setState({...treesToHide});
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return(
      <View className="alerts-view">
        <div className="alerts-header">
          <FilterBar
            searchAction={this.handleTextSearch}
            filterAction={this.handleFilter}
            searchStr={this.state.searchStr}
            onChange={this.handleFilterChange}
            searchButtonText={this.state.buttonFilterName}
            content={this.filterContents()}
            textSearch= {false}
            selectedItems={this.getSelectedItems()}
          />
          <div
            style={{
              // width: "555px",
              marginLeft: "50%",
              marginTop: "20px",
              transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={this.state.active_alerts}
              name="active_alerts"
              onChange={this.handleChange}
              items={[
                { description: "Alertas activas", value: 1 },
                { description: "Todas", value: 0 },
              ]}
            />
          </div>
        </div>
        <div className="alerts-content">
          <div className="dashboard-stats-shared half">
            <div className="dashboard-panel stats-panel">
              <div className="panel-title">
                {/* {this.getDayStatsTitle()} */}
                {this.getMonthStatsTitle()}
              </div>
              <div className="panel-content">
                <div className="total">
                  {this.state.monthInfo.total || '-'}
                </div>
                <div className="progress-bar">
                  <div className="bar">
                    <div className="baja" var="bar.baja"  style={{width: "".concat(this.state.monthInfo.lowPct , '%')}}></div>
                    <div className="media" var="bar.media"  style={{width: "".concat(this.state.monthInfo.midPct, '%')}}></div>
                    <div className="alta" var="bar.alta"  style={{width: "".concat(this.state.monthInfo.highPct, '%')}}></div>
                  </div>
                  <div className="mark baja" style={{left: "".concat(this.state.monthInfo.lowPct, '%')}}></div>
                  <div className="mark media" style={{left: "".concat(this.state.monthInfo.lowPct + this.state.monthInfo.midPct, '%')}}></div>
                  <div className="label baja" style={{left: "".concat(this.state.monthInfo.lowPct/2, '%')}}>
                    {this.state.monthInfo.low}
                    <div className="pct">{"".concat(this.state.monthInfo.lowPct || '-' , '%')}</div>
                  </div>
                  <div className="label media" style={{left: "".concat(this.state.monthInfo.lowPct + this.state.monthInfo.midPct/2, '%')}}>
                    {this.state.monthInfo.mid}
                    <div className="pct">{"".concat(this.state.monthInfo.midPct || '-' , '%')}</div>
                  </div>
                  <div className="label alta" style={{left: "".concat(this.state.monthInfo.lowPct + this.state.monthInfo.midPct + this.state.monthInfo.highPct/2, '%')}}>
                    {this.state.monthInfo.high}
                    <div className="pct">{"".concat(this.state.monthInfo.highPct || '-' , '%')}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-panel stats-panel">
              <div className="panel-title">
                {this.getDayStatsTitle()}
              </div>
              <div className="panel-content">
                <div className="total">
                  {this.state.dayInfo.total || '-'}
                </div>
                <div className="progress-bar">
                  <div className="bar">
                    <div className="baja" var="bar.baja"  style={{width: "".concat(this.state.dayInfo.lowPct , '%')}}></div>
                    <div className="media" var="bar.media"  style={{width: "".concat(this.state.dayInfo.midPct, '%')}}></div>
                    <div className="alta" var="bar.alta"  style={{width: "".concat(this.state.dayInfo.highPct, '%')}}></div>
                  </div>
                  <div className="mark baja" style={{left: "".concat(this.state.dayInfo.lowPct, '%')}}></div>
                  <div className="mark media" style={{left: "".concat(this.state.dayInfo.lowPct + this.state.dayInfo.midPct, '%')}}></div>
                  <div className="label baja" style={{left: "".concat(this.state.dayInfo.lowPct/2, '%')}}>
                    {this.state.dayInfo.low}
                    <div className="pct">{"".concat((this.state.dayInfo.low && this.state.dayInfo.lowPct) || '-' , '%')}</div>
                  </div>
                  <div className="label media" style={{left: "".concat(this.state.dayInfo.lowPct + this.state.dayInfo.midPct/2, '%')}}>
                    {this.state.dayInfo.mid}
                    <div className="pct">{"".concat((this.state.dayInfo.mid && this.state.dayInfo.midPct) || '-' , '%')}</div>
                  </div>
                  <div className="label alta" style={{left: "".concat(this.state.dayInfo.lowPct + this.state.dayInfo.midPct + this.state.dayInfo.highPct/2, '%')}}>
                    {this.state.dayInfo.high}
                    <div className="pct">{"".concat((this.state.dayInfo.high && this.state.dayInfo.highPct) || '-' , '%')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="dashboard-panel dashboard-alerts-month">
            <div className="panel-title">{this.state.monthMode === 'total' ? 'Alertas Diarias (Miles)' : 'Alertas por Folio Diarias'}</div>
            <ul className="filtros">
              <div className="desviacion">
                <li className={"desviacion".concat(this.state.monthMode === "total" ? " selected ": "")} onClick={() => this.setState({monthMode: "total"}, this.graphDaily)}>No. Alertas<div></div></li>
                <li className={"desviacion".concat(this.state.monthMode === "promedio" ? " selected ": "")} onClick={() => this.setState({monthMode: "promedio"}, this.graphDaily)}>Alertas/Folio<div></div></li>
              </div>
            </ul>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              <ul style={{marginTop: "20px"}}>
                {
                  months_abbr.map((m, i) => {
                    let current = new Date();
                    return <li
                      className={(this.state.selectedMonth === i ? 'selected' : '').concat(i <= current.getMonth() ? '' : ' disabled')}
                      onClick={() => {
                        this.setState({selectedMonth: i}, this.graphDaily);
                      }}
                    >{m}</li>
                  })
                }
              </ul>
              <div className="charts">
                <div className="month" >
                  {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                  <div id="monthChart"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-panel dashboard-alerts-month">
            <div className="panel-title">{this.state.yearMode === 'total' ? 'Alertas Mensuales (Miles)' : 'Alertas por Folio Mensuales'}</div>
            <ul className="filtros">
              <div className="desviacion">
                <li className={"desviacion".concat(this.state.yearMode === "total" ? " selected ": "")} onClick={() => this.setState({yearMode: "total"}, this.graphYear)}>No. Alertas<div></div></li>
                <li className={"desviacion".concat(this.state.yearMode === "promedio" ? " selected ": "")} onClick={() => this.setState({yearMode: "promedio"}, this.graphYear)}>Alertas/Folio<div></div></li>
              </div>
            </ul>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              
              <div className="charts">
                <div className="month" >
                  {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                  <div id="yearChart"></div>
                </div>
              </div>
            </div>
          </div>

          <div id="paretoTipoPanel" className="dashboard-panel " style={{display: this.state.showParetoTipo ? "" : "none"}}>
            <div className="panel-title etapas">
              <b >Pareto de alertas </b>
              <span >
                {this.getParetoDate()}
              </span>
              <b> (miles)</b>
            </div>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoTipo" >
                  <b className="loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoTipoChart"></div>
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument("global")}}
            />
          </div>
        
          <div id="paretoArbol1" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol1 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol1Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(1)
                }
                {
                  this.state.arbol1Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(1)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 1)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol1Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol1Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol1Chart"  style={{display: this.state.arbol1Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol1Grupo.includes("table") 
                    && this.renderTable(1)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>

          <div id="paretoArbol2" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol2 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol2Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(2)
                }
                {
                  this.state.arbol2Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(2)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 2)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol2Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol2Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol2Chart"  style={{display: this.state.arbol2Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol2Grupo.includes("table") 
                    && this.renderTable(2)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>

          <div id="paretoArbol3" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol3 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol3Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(3)
                }
                {
                  this.state.arbol3Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(3)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 3)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol3Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol3Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol3Chart"  style={{display: this.state.arbol3Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol3Grupo.includes("table") 
                    && this.renderTable(3)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>

          <div id="paretoArbol4" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol4 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol4Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(4)
                }
                {
                  this.state.arbol4Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(4)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 4)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol4Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol4Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol4Chart"  style={{display: this.state.arbol4Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol4Grupo.includes("table") 
                    && this.renderTable(4)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>

          <div id="paretoArbol5" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol5 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol5Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(5)
                }
                {
                  this.state.arbol5Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(5)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 5)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol5Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol5Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol5Chart"  style={{display: this.state.arbol5Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol5Grupo.includes("table") 
                    && this.renderTable(5)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>

          <div id="paretoArbol6" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol6 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol6Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolGroups(6)
                }
                {
                  this.state.arbol6Grupo.includes("facilities")
                  && <FilterBar
                        searchAction={() => {}}
                        filterAction={() => this.loadArbolData(6)}
                        searchStr={""}
                        onChange={(e) => this.handleTreeFilterChange(e, 6)}
                        searchButtonText={"Buscar"}
                        content={[
                          {
                            title: "Instalaciónes",
                            items: this.props.companyFacilities.map(f => ({...f, items: []})),
                            name: "filterFacilities",
                            listType: FILTER_LIST_TYPES.checkListTextSearchAll,
                          }
                        ]}
                        textSearch= {false}
                        selectedItems={{
                          filterFacilities: this.state.arbol6Filtros["facilities_tags"] || [],
                        }}
                      />
                }
                
              </div>
            </ul>
            <div var="content" className="panel-content arbol">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: this.state.arbol6Loading ? "" : "none"}}><Loader /></b>
                  <div id="paretoArbol6Chart"  style={{display: this.state.arbol6Grupo.includes("table") ? "none" : ""}}></div>
                  {
                    this.state.arbol6Grupo.includes("table") 
                    && this.renderTable(6)
                  }
                </div>
              </div>
            </div>
            {/* <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            /> */}
          </div>
        </div>
      </View>
    )
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = state => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company?.type?.id || 0;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const orderTags = companyTags.filter(tag => tag.type === 7);
  const tripTags = companyTags.filter(tag => tag.type === 9);
  const companyAlerts = (getEntityItems(state, "COMPANIES.ALERTS.CATALOG") || []).map(a => ({id: a.id, description: a.name}));
  // const baseTags = companyTags.filter(tag => tag.type === 3);
  const companyFacilities = getEntityItems(
      state,
      "COMPANIES.FACILITIES.FILTERS"
    ).map((f) => ({
      description: f.title,
      id: f.id,
      items: f.facilities.map((ff) => ({
        id: ff.id,
        description: `${ff.name} - ${ff.code} - ${ff.alias}`,
      })),
    }));
  const networkCompanies = getEntityItems(state, "COMPANIES.NETWORK").map(c => {
    return {
      id: c.fleet_provider.fleet_provider_id,
      description: (c.fleet_provider.orbi_id || "").concat(
        ' - ',
        c.fleet_provider.alias || "",
        ' - ',
        c.fleet_provider.code || "",
      )
    }
  })

  const alertsGlobal = getEntityItems(state, "COMPANIES.ALERTS.DASHBOARD.GLOBAL");
  const alertsDesgloseTipo = getEntityItems(state, "COMPANIES.ALERTS.DASHBOARD.DESGLOSEALERTAS")["alerts"] || [];
  const arbol1 = getEntityItems(state, "arbol1");
  const arbol2 = getEntityItems(state, "arbol2");
  const arbol3 = getEntityItems(state, "arbol3");
  const arbol4 = getEntityItems(state, "arbol4");
  const arbol5 = getEntityItems(state, "arbol5");
  const arbol6 = getEntityItems(state, "arbol6");
  return {
    companyId,
    companyType,
    orderTags,
    tripTags,
    // baseTags,
    companyAlerts,
    companyFacilities,
    networkCompanies,

    alertsGlobal,
    alertsDesgloseTipo,
    arbol1,
    arbol2,
    arbol3,
    arbol4,
    arbol5,
    arbol6,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES.FILTERS", params, opts)),
    loadCompanyAlerts: (params, opts) =>
      dispatch(readEntities("COMPANIES.ALERTS.CATALOG", params, opts)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadAlertsGlobal: (params, opts) =>
      dispatch(readEntities("COMPANIES.ALERTS.DASHBOARD.GLOBAL", params, opts)),
    loadAlertsDesgloseTipo: (params, opts) =>
      dispatch(readEntities("COMPANIES.ALERTS.DASHBOARD.DESGLOSEALERTAS", params, opts)),
    loadAlertsCriterio: (params, opts) =>
      dispatch(readEntities("COMPANIES.ALERTS.DASHBOARD.CRITERIO", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsReportView);

const months_index = {
  "Ene": 0,
  "Feb": 1,
  "Mar": 2,
  "Abr": 3,
  "May": 4,
  "Jun": 5,
  "Jul": 6,
  "Ago": 7,
  "Sep": 8,
  "Oct": 9,
  "Nov": 10,
  "Dic": 11,
}
const months_abbr = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
];
const months_full = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const DefaultChart = {
  chart: {
      renderTo: 'container',
      type: 'column',
      height: 500,
  },
  title: {
      text: ''
  },
  tooltip: {
    shared: true,
    valueDecimals: 2
  },
  credits: {
    enabled: false
  },
  xAxis: {
      categories: [
          'Overpriced',
      ],
      crosshair: true,
      lineColor: '#303349',
      tickLength: 0,
      labels: {
        style: {
          color: 'white'
        }
      }
  },
  yAxis: [{
      title: {
          text: ''
      },
      stackLabels: {
        enabled: true,
        // rotation: 270,
        // x: 4,
        // y: -27,
        formatter: 1,
        style: {
          color: '#FFFFFF',
          textOutline: '0px',
          fontSize: '14px',
        }
      },
      opposite: true,
      maxPadding: 0.1,
      lineColor: '#303349',
      gridLineColor: '#303349'
  }, {
      title: {
          text: ''
      },
      minPadding: 0,
      maxPadding: 0,
      max: 100,
      min: 0,
      endOnTick: false,
      opposite: false,

      lineColor: '#303349',
      gridLineColor: '#303349'
  }],
  plotOptions: {
      series: {
          stacking: 'normal',
          dataLabels: {
              crop: false,
              enabled: true,
              inside: true
          }
      }
  },
  series: [
  {
    name: 'Alto',
    data: [100],
    stack: 0,
    color: '#FE2F56',
    borderWidth: 0,
    dataLabels: [{
      align: 'top',
      rotation: 270,
      x: 0,
      y: 5,
      color: 'white'
    }]
  }, {
    name: 'Medio',
    data: [2000],
    stack: 0,
    color: '#FF9145',
    borderWidth: 0,
    dataLabels: [{
      align: 'top',
      format: '{y}%',
      rotation: 270,
      x: 0,
      y: 5,
      color: 'black'
    }]
  }, {
    name: 'Bajo',
    data: [200],
    stack: 0,
    color: '#FFE358',
    borderWidth: 0,
    dataLabels: [{
      align: 'top',
      format: '{y}%',
      rotation: 270,
      x: 0,
      y: 5,
      color: 'black'
    }]
  }, {
    name: 'Alto',
    data: [200],
    stack: 0,
    color: '#FFE358',
    borderWidth: 0,
    dataLabels: [{
      align: 'top',
      format: '{y}%',
      rotation: 270,
      x: 0,
      y: 5,
      color: 'black'
    }]
  }]
}

const arboles = {
  "4": {//Viaje no iniciado
    "origen": {
      "linea": {
        "ruta": {
          "tabla4": false
        },
        "tabla4": false
      },
      "ruta": {
        "tabla4": false
      },
      "tabla4": false
    },
    "linea": {
      "origen": {
        "tabla5": false
      },
      "ruta": {
        "tabla5": false
      },
      "camion": false,
      "tabla5": false
    },
    "ruta": {
      "linea": {
        "camion": false,
        "tabla6": false
      },
      "camion": false,
      "tabla6": false
    }
  },
  "11": {//Desconexion
    "linea": {
      "ruta": {
        "tabla7": false
      },
      "camion": false,
      "tabla7": false
    },
    "ruta": {
      "linea": {
        "camion": false,
        "tabla8": false
      },
      "camion": false,
      "tabla8": false
    }
  },
  //Transito excedido
  "6": {//Retraso a cita
    "planta": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "vidriera": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "drv": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "uen": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "agencia": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "autoservicio": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "cross Dock": {
      "linea": {
        "ruta": {
          "tabla11": false
        },
        "tabla11": false
      },
      "ruta": {
        "tabla11": false
      },
      "tabla11": false
    },
    "linea": {
      "ruta": {
        "tabla12": false
      },
      "camion": false,
      "tabla12": false
    },
    "ruta": {
      "linea": {
        "camion": false,
        "tabla13": false
      },
      "camion": false,
      "tabla13": false
    },
  },
  //Incumplimiento de cita
  "10": {//Estadia vs cita
    "planta": false,
    "vidriera": false,
    "drv": {
      "uen": {
        "agencia": {
          "linea": {
            "ruta": {
              "tabla24": false
            },
            "tabla24": false
          },
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        }
      },
      "agencia": {
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      }
    },
    "uen": {
      "agencia": {
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      }
    },
    "agencia": {
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      },
      "tabla24": false
    },
    "autoservicio": false,
    "cross Dock": false,
  },
  "9": {//ATCT
    "planta": false,
    "vidriera": false,
    "drv": {
      "uen": {
        "agencia": {
          "linea": {
            "ruta": {
              "tabla24": false
            },
            "tabla24": false
          },
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        }
      },
      "agencia": {
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      }
    },
    "uen": {
      "agencia": {
        "linea": {
          "ruta": {
            "tabla24": false
          },
          "tabla24": false
        },
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      }
    },
    "agencia": {
      "linea": {
        "ruta": {
          "tabla24": false
        },
        "tabla24": false
      },
      "ruta": {
        "tabla24": false
      },
      "tabla24": false
    },
    "autoservicio": false,
    "cross Dock": false,
  }
}

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;