import React from "react";
import CaretDown from "orbi_v2_frontend_components/lib/Icons/CaretDown";

const ColumnOrdenator = ({ descendent }) => {
  return (
    <CaretDown
      width="13px"
      style={{
        marginLeft: "6px",
        transform: descendent ? "rotate(360deg)" : "rotate(180deg)",
        transition: " 0.2s ease all",
      }}
    />
  );
};

export default ColumnOrdenator;
