import React from "react";
import { connect } from "react-redux";
import { View, ViewTitle } from "../../../shared/styled/view";
import SubscriptionInfoView from "./subscription-info-view";
import SubscriptionShoppingCarView from "./subscription-shopping-car-view";
import { getEntityItems, partialEntity, readEntities, readEntity } from "../../../redux/api";
import { loadSession } from "../../../redux/reducers/global-catalog.reducer";
import { withRouter } from "react-router-dom";
import GenericDialog from "../../../shared/dialog/generic-modal";
import styled from "styled-components";
import BillingDataView from "../buy-orbibucks/billing-data-view";
import ConfirmDialog from "../../../shared/dialog/confirm-dialog";
import { isArray } from 'lodash';
import SelectInput from "../../../shared/controls/select-input";
import { validateEmails } from "../../../shared/utils";

class SubscriptionView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      planAdded: null,
      buySuccess: false,

      billingActivated: '2',
      billUse: 0,
      paymentMethod: '15',

      info: {
        rfc: '',
        razon_social: '',
        postal_code: '',
        regimen: 0,
        email_billing: ''
      },

      rfc: '',
      razon_social: '',
      postal_code: '',
      regimen: 0,
      email_billing: '',

      showEditModal: false
    };
  }

  componentDidMount() {
    this.props.loadWalletInfo({company_id: this.props.companyId});
    this.props.loadSubscriptionCatalog();
    this.props.loadFormaDePago();
    this.props.loadUsoCfdi();
    this.props.loadTaxRegime();

    this.props.getInvoiceData({
      company_id: this.props.companyId,
    }, {
      alias: "get_invoice_data",
      onSuccess: (response) => {
        this.setInvoiceData(response);
      }
    });
  }

  setInvoiceData = (response) => {
    try {
      const { data } = response;

      this.state.billUse = data.bill_use;

      this.state.rfc = data.rfc;
      this.state.razon_social = data.razon_social;
      this.state.email_billing = data.email;
      this.state.postal_code = data.postal_code;
      this.state.regimen = data.regimen_fiscal;

      this.state.info.rfc = data.rfc;
      this.state.info.razon_social = data.razon_social;
      this.state.info.email_billing = data.email;
      this.state.info.postal_code = data.postal_code;
      this.state.info.regimen = data.regimen_fiscal;

      this.setState({});
    } catch(error) {
      console.log('Error en acceder a la info de invoice_data', error);
    }
  }

  buildJsonPlans = () => {

    return({
      plans: [
        {
          id: 1,
          name: 'Public PPV',
          services_activated: [1,3,6],
          free: true,
          prices_description: [
            '$0.10 orbibucks por orbipeople',
            '$0.10 orbibucks por viaje LTL',
            '$1 orbibucks por remolque en viajes FTL Terrestre',
            '$3 orbibucks por remolque en viajes FFCC',
            '$5 orbibucks por remolque en viajes FCL Marítimo'
          ]
        },
        {
          id: 2,
          name: 'Ilimitada terrestre',
          services_activated: [1,2,3,4,5,6,7],
          free: false,
          price_plan_description: 'Anual $990 USD/mes',
          price_plan: 11880,
          prices_description: [
            '$0.10 orbibucks por orbipeople',
            '$0.10 orbibucks por viaje LTL',
            '$1 orbibucks por remolque en viajes FTL Terrestre',
            '$3 orbibucks por remolque en viajes FFCC',
            '$5 orbibucks por remolque en viajes FCL Marítimo'
          ]
        }
      ],
      services: [
        {
          id: 1,
          description: 'Lorem ipsum dolor sit amet'
        },
        {
          id: 2,
          description: 'Consectetur adipiscing elit'
        },
        {
          id: 3,
          description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
        },
        {
          id: 4,
          description: 'Ut enim ad minim veniam'
        },
        {
          id: 5,
          description: 'Ullamco laboris nisi ut aliquip ex ea commodo consequat'
        },
        {
          id: 6,
          description: 'Duis aute irure dolor in reprehenderit in voluptate'
        },
        {
          id: 7,
          description: 'Velit esse cillum dolore eu fugiat nulla pariatur'
        }
      ]
    });

  }

  changeEditable = (items) => {
    if (this.state.planAdded !== null) {
      this.state.planAdded = items;
      this.setState({});
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value});
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== null && value !== undefined && !isNaN(value)) {
      this.setState({ [name]: value});
    }
  }

  buildJsonToSend = () => {
    return({
      company_id: this.props.companyId,
      rfc: this.state.rfc,
      razon_social: this.state.razon_social,
      postal_code: this.state.postal_code,
      email: validateEmails(this.state.email_billing),
      regimen_fiscal: this.state.regimen,
      bill_use: this.state.billUse,
      payment_method: this.state.paymentMethod
    });
  }

  updateInvoiceData = () => {
    this.props.updateInvoiceData(
      this.buildJsonToSend(), 
      {
        onSuccess: (response) => {
          this.state.showEditModal = false;
          this.setInvoiceData(response);
        }
      });
  }

  getInfoToShow = () => {
    let info = this.state.info;

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${info.regimen}` )

    if (tax_regime && tax_regime.length > 0) {
      info.regimen_fiscal = tax_regime[0].description;
    } else {
      info.regimen_fiscal = '';
    }

    return info;
  }

  getErrorsToBuy = () => {
    let errors = "";

    if (this.state.billingActivated === '1') {
      errors += String(this.state.billUse) === '0' ? "<b>Seleccione el uso de la factura<b/><br/>" : '';

      errors += String(this.state.rfc).length === 0 ? "<b>Ingresa el rfc<b/><br/>" : '';
      errors += String(this.state.razon_social).length === 0 ? "<b>Ingresa la razón social<b/><br/>" : '';
      errors += String(this.state.postal_code).length === 0 ? "<b>Ingresa el código postal<b/><br/>" : '';
      errors += String(this.state.regimen) === '0' ? "<b>Seleccione el regimen fiscal<b/><br/>" : '';
      errors += String(this.state.email_billing).length === 0 ? "<b>Ingresa algun correo electrónico<b/><br/>" : '';
    }

    if (errors !== "") {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`
    }

    return errors;
  }

  render() {
    return(
      <View>
        <ViewTitle>
          <div style={{flex: "1"}}>
            Cambiar suscripción
          </div>
        </ViewTitle>

        <ContainerView>

          <SubscriptionInfoView
            plansInfo={this.buildJsonPlans()}
            currentPlan={this.props.planInfo}
            addPlan={ (plan) => this.setState({planAdded: [{
              id: plan.id,
              quantity: 1,
              title: `Membresía anual ${plan.name}`,
              unit_price: plan.price_plan,
              description: plan.price_plan_description,
              iva: 10
            }]}) }
          />

          <SubscriptionShoppingCarView
            itemsToBuy={ this.state.planAdded === null ? null : this.state.planAdded }
            deleteItem={this.changeEditable}
            editable={false}
            errors={this.getErrorsToBuy()}
            buyAction={() => this.setState({buySuccess: true})}
            changeEditable={this.changeEditable}
            content={
              <BillingDataView
                company={this.props.company}
                info={this.getInfoToShow()}
                editAction={() => { this.setState({showEditModal: true}) }}
                billingActivated={this.state.billingActivated}
                onChange={this.handleChange}
                formaDePago={this.props.formaDePago}
                usoCfdi={this.props.usoCfdi}
                billUse={this.state.billUse}
                paymentMethod={this.state.paymentMethod}
              />
            }
          />

        </ContainerView>

        <GenericDialog
          open={this.state.buySuccess}
          title={this.state.planAdded !== null ? `Membresía anual ${this.state.planAdded.name}` : ''}
          message={'Se realizó con éxito la compra'}
          acceptAction={() => { this.props.history.goBack(); }}
          closeAction={() => { this.props.history.goBack(); }}
        />

        <ConfirmDialog
          open={this.state.showEditModal}
          isAcceptLoading={this.props.isLoadingInvoiceData}
          title="Datos de facturación"
          class='no-bottom-margin-2'
          contentObject={
            <ModalContainerView>
              <ModalNameFieldView>
                <ModalFieldView>{'RFC:'}</ModalFieldView>
                <ModalFieldView>{'Razón social:'}</ModalFieldView>
                <ModalFieldView>{'Código Postal:'}</ModalFieldView>
                <ModalFieldView>{'Regimen fiscal:'}</ModalFieldView>
                <ModalFieldView>{'Correos electronicos:'}</ModalFieldView>
              </ModalNameFieldView>

              <ModalInputFieldView>

                <ModalFieldView>
                  <TextInput
                    name="rfc"
                    type="text"
                    value={this.state.rfc}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="razon_social"
                    type="text"
                    value={this.state.razon_social}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="postal_code"
                    type="text"
                    value={this.state.postal_code}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <SelectInput
                    label='Régimen Fiscal:'
                    value={parseInt(this.state.regimen)}
                    name='regimen_fiscal'
                    items={this.props.taxRegimes}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

                <ModalFieldView>
                  <TextInput
                    name="email_billing"
                    type="text"
                    value={this.state.email_billing}
                    onChange={this.handleInputChange}
                  />
                </ModalFieldView>

              </ModalInputFieldView>
            </ModalContainerView>
          }
          acceptText="Guardar"
          showCancel={true}
          cancelText="Cerrar"
          cancelButtonType="secondary"
          closeAction={() => {

            this.state.rfc = this.state.info.rfc;
            this.state.razon_social = this.state.info.razon_social;
            this.state.email_billing = this.state.info.email_billing;
            this.state.postal_code = this.state.info.postal_code;
            this.state.regimen = this.state.info.regimen;

            this.setState({showEditModal: false })
          }}
          acceptAction={() => {
            this.updateInvoiceData();
          }}
        />

      </View>
    );
  }

} 

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;

  const formaDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.FORMADEPAGO");
  const usoCfdi = getEntityItems(state, "COMPANIES.SAT.CFDI.USOCFDI");
  const walletInfo = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.WALLET");
  const subscriptionCatalog = getEntityItems(state, "COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION") || [];
  const taxRegimes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.REGIMENFISCAL").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion)}));
  const isLoadingInvoiceData = state?.api['COMPANIES.INVOICEDATA']?.status?.isFetching || false;

  let planInfo = subscriptionCatalog.filter( item => {
    return (walletInfo && walletInfo.length > 0 && walletInfo[0].subscription === item.id)
  });

  return {
    isLoadingInvoiceData,
    company: state.globalCatalog.session.company,
    companyId,
    walletInfo,
    subscriptionCatalog,
    planInfo: planInfo.length > 0 ? planInfo[0] : null,
    isCargoOwner: `${state.globalCatalog.session.company.type.id}` !== '1',
    formaDePago: (isArray(formaDePago) ? formaDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    usoCfdi: (isArray(usoCfdi) ? usoCfdi : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    taxRegimes
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    loadWalletInfo: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.WALLET", params, opt)),
    loadSubscriptionCatalog: (params, opt) => dispatch(readEntities("COMPANIES.SUSCRIPTIONS.CATALOGSUBSCRIPTION", params, opt)),
    loadFormaDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.FORMADEPAGO", {}, {prefix: 'orbisat.'})),
    loadUsoCfdi: () => dispatch(readEntities("COMPANIES.SAT.CFDI.USOCFDI", {}, {prefix: 'orbisat.'})),
    loadTaxRegime:  () => dispatch(readEntities("COMPANIES.SAT.CATALOGS.REGIMENFISCAL", {}, {prefix: 'orbisat.'})),
    updateInvoiceData: (params, opt) => dispatch(partialEntity("COMPANIES.INVOICEDATA", params, opt)),
    getInvoiceData: (params, opt) => dispatch(readEntity("COMPANIES.INVOICEDATA", params, opt))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionView));

////////// style

const ContainerView = styled.div`
  display: flex; 
  justify-content: center;
`;

const ModalContainerView = styled.div`
  display: flex;
`;

const ModalNameFieldView = styled.div`
  width: 30%;
`;

const ModalFieldView = styled.div`
  height: 60px;
  width: auto;
  margin-top: 1px;
  color: #252E47;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ModalInputFieldView = styled.div`
  width: 70%;
`;

const TextInput = styled.input`
  background: white;
  border: none;
  width: 100%;
  height: 30px;
  color: black;
  font-size: 14px;
  border: 2px solid #AFAFB0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;