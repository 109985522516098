import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';

Modal.setAppElement('#root');

class ImageDialog extends React.Component { 

  onClose = () => {
    this.props.closeAction();
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName={`dialog generic-dialog-image`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.props.message}</div>
        <div
          style={{
            backgroundColor: 'grey',
            height: 450,
            width: 600,
            marginLeft: 50,
            marginTop: -10,
            borderRadius: 8,
            background: `url(${this.props.image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        >
        </div>
        <div className="actions">
          <Button
            settings={{
              text: this.props.acceptText,
              onClick: this.props.acceptAction
            }}
          />
        </div>
      </Modal>
    );   
  }

}

export default ImageDialog;