import React from "react";
const Marker = (props) => {
  return (
    <svg
      viewBox="350 192 40 40"
      width="20px"
      height="20px"
      fill="currentColor"
      {...props}
    >
      <path d="M371.817146,229.844154 C371.751239,229.937604 371.669792,230.019056 371.576347,230.08497 C371.125034,230.403315 370.501103,230.295524 370.182757,229.84421 L370.182769,229.844202 C367.688704,226.308407 365.924432,223.724755 364.889954,222.093248 C360.296651,214.849006 358,209.48459 358,206 C358,198.820298 363.820298,193 371,193 C378.179702,193 384,198.820298 384,206 C384,209.478486 381.711388,214.830218 377.134163,222.055196 C376.096156,223.69365 374.323817,226.28997 371.817146,229.844154 Z M371,213 C375.418278,213 379,209.418278 379,205 C379,200.581722 375.418278,197 371,197 C366.581722,197 363,200.581722 363,205 C363,209.418278 366.581722,213 371,213 Z M371,210 C368.238576,210 366,207.761424 366,205 C366,202.238576 368.238576,200 371,200 C373.761424,200 376,202.238576 376,205 C376,207.761424 373.761424,210 371,210 Z" />
    </svg>
  );
};
export default Marker;
