import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import TextInput from "../controls/text-input";
import { connect } from "react-redux";
import Tag from "../controls/tag";
import PrevSwitch from "../controls/prev-switch";
import ExceedPlus from "../controls/exceed-plus";
import styled from "styled-components";
import Loader from "../icons/tat/loader";

class EditTagsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propsTags: props.tags,
      tagTitle: "",
      chosenColor: DEFAULT_COLOR,
      tagPermissions: [],
      error: ""
    };
  }
  saveButtonSettings = {
    text: "Aplicar",
    onClick: () => {
      this.onAccept();
    },
  };
  cancelButtonSettings = {
    text: "Cancelar",
    type: "primary outline",
    onClick: () => {
      this.onClose();
    },
  };
  onAccept = () => {
    this.props.acceptAction(
      {
        title: this.state.tagTitle,
        color: this.state.chosenColor,
        permissions: this.state.tagPermissions,
      },
      this.props.tags,
      this.onClose
    );
    // this.onClose();
  };
  onClose = () => {
    if(!this.props.loading){
      this.setState({ chosenColor: 0, tagTitle: "", tagPermissions: [], error:  "" });
      this.props.closeAction();
    }
  };
  validateTitle = (value) => {
    let tagsToValidate = this.props.tagsToValidate ?? [];

    for (var i = 0;  i < tagsToValidate.length; i++) {
      if (value === tagsToValidate[i].title) {
        return true;
      }
    }

    return false;
  }
  editTitle = (e) => {

    if (this.props.tags.length > 0) {

      if (this.props.tags.length == 1) {
        let ownTag = this.props.tags[0].title;

        if (this.validateTitle(e.target.value) && ownTag !== e.target.value) {
          this.setState({ error: 'La etiqueta de base ya existe', tagTitle: e.target.value });
        } else {
          this.setState({ tagTitle: e.target.value, error: '' });
        }

      } else {
        this.setState({ tagTitle: e.target.value, error: '' });
      }

    } else {
      if (this.validateTitle(e.target.value )) {
        this.setState({ error: 'La etiqueta de base ya existe', tagTitle: e.target.value });
      } else {
        this.setState({ tagTitle: e.target.value, error: '' });
      }
    }
  };
  selectColor = (colorId) => {
    this.setState({ chosenColor: colorId });
  };
  setPermission = (rol_permission_id, permissionType, value, permissionId) => {
    // debugger
    let oldPermissions = [];
    if (this.props.tags.length) {
      oldPermissions = this.state.tagPermissions.map((p) => {
        if (p.id === rol_permission_id) {
          p[permissionType] = value;
          return p;
        }
        return p;
      });
    } else {
      if (this.state.tagPermissions.map((p) => p.id).includes(permissionId)) {
        oldPermissions = this.state.tagPermissions.map((p) => {
          if (p.id === permissionId) {
            p[permissionType] = value;
            return p;
          }
          return p;
        });
      } else {
        let permission = {
          id: permissionId,
          p_create: false,
          p_read: false,
          p_update: false,
          p_delete: false,
        };
        permission[permissionType] = value;
        oldPermissions = this.state.tagPermissions.concat([permission]);
      }
    }
    this.setState({ tagPermissions: oldPermissions });
  };

  validRowPermission = (row) => {
    if (Object.values(row).length) {
      if (
        row.f_create === "i" &&
        row.f_read === "i" &&
        row.f_update === "i" &&
        row.f_delete === "i"
      ) {
        return false;
      }
    }
    return true;
  };

  validPermission = (row, field) => {
    if (row[field] === "i") {
      return "✓";
    }
    return "";
  };

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog tags-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">
          {this.props.tags.length ? "Editar" : "Crear"}
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">
          {/* <p className="modal-title">
            Estás {this.props.tags.length ? "editando" : "creando"}{" "}
            {this.props.tags.length > 1 ? "las etiquetas" : "la etiqueta"}:
          </p> */}
          <div>
            {this.props.tags.length > 1 ? (
              this.props.tags.map((tag) => {
                return (
                  <Tag
                    id={tag.id}
                    title={tag.title}
                    color={tag.color}
                    parentBackground="light"
                  />
                );
              })
            ) : (
              <TextInput
                label=""
                value={this.state.tagTitle}
                onChange={this.editTitle}
              />
            )}
          </div>

          { 
            this.state.error ===  '' ? <div></div> : 
            <div style={{color: 'red', fontSize: 13, marginTop: -15, height: 17, marginLeft: 3}}>
              {this.state.error}
            </div>
          }

          <p className="modal-title">
            Elige un color para{" "}
            {this.props.tags.length > 1 ? "estas etiquetas" : "esta etiqueta"}:
          </p>
          <div className="color-row">
            {this.props.colorCatalog.map((color) => {
              return (
                <div
                  key={color.id}
                  className={"color-bubble ".concat(
                    this.state.chosenColor === color.value ? "selected" : ""
                  )}
                  style={{ backgroundColor: "#".concat(color.value) }}
                  onClick={() => this.selectColor(color.value)}
                ></div>
              );
            })}
          </div>
          {this.props.category === "roles" && this.props.tags.length <= 1 ? (
            <>
              <div className="roles-title">Permisos:</div>
              <div className="roles-list">
                <div className="roles-list-header">
                  <div className="permission-header"></div>
                  <div className="switch-header">Crear</div>
                  <div className="switch-header">Leer</div>
                  <div className="switch-header">Editar</div>
                  <div className="switch-header">Borrar</div>
                </div>
                <div className="roles-list-rows">
                  {this.props.permissions.map((permission) => (
                    <React.Fragment>
                      {this.validRowPermission(permission) ? (
                        <div className="permission-row">
                          <div className="permission-title">
                            {permission.permission.length > 39 ? (
                              <ExceedPlus
                                maxVisibleItems={0}
                                name={permission.permission}
                                replaceText="..."
                                face={permission.permission.substring(0, 40)}
                                tooltipOpts={{
                                  delayHide: 20,
                                  clickable: false,
                                  place: "top",
                                }}
                              >
                                <SpanStyled>{permission.permission}</SpanStyled>
                              </ExceedPlus>
                            ) : (
                              permission.permission
                            )}
                          </div>
                          <div className="switch-row-container">
                            {permission.f_create === "m" ? (
                              <PrevSwitch
                                handleClick={(value) =>
                                  this.setPermission(
                                    (
                                      this.state.tagPermissions.find(
                                        (p) =>
                                          p.module_permission === permission.id
                                      ) || {}
                                    ).id,
                                    "p_create",
                                    value,
                                    permission.id
                                  )
                                }
                                checked={
                                  (
                                    this.state.tagPermissions.find(
                                      (p) =>
                                        p.module_permission === permission.id
                                    ) || {}
                                  ).p_create
                                }
                              />
                            ) : (
                              this.validPermission(permission, "f_create")
                            )}
                          </div>
                          <div className="switch-row-container">
                            {permission.f_read === "m" ? (
                              <PrevSwitch
                                handleClick={(value) =>
                                  this.setPermission(
                                    (
                                      this.state.tagPermissions.find(
                                        (p) =>
                                          p.module_permission === permission.id
                                      ) || {}
                                    ).id,
                                    "p_read",
                                    value,
                                    permission.id
                                  )
                                }
                                checked={
                                  (
                                    this.state.tagPermissions.find(
                                      (p) =>
                                        p.module_permission === permission.id
                                    ) || {}
                                  ).p_read
                                }
                              />
                            ) : (
                              this.validPermission(permission, "f_read")
                            )}
                          </div>
                          <div className="switch-row-container">
                            {permission.f_update === "m" ? (
                              <PrevSwitch
                                handleClick={(value) =>
                                  this.setPermission(
                                    (
                                      this.state.tagPermissions.find(
                                        (p) =>
                                          p.module_permission === permission.id
                                      ) || {}
                                    ).id,
                                    "p_update",
                                    value,
                                    permission.id
                                  )
                                }
                                checked={
                                  (
                                    this.state.tagPermissions.find(
                                      (p) =>
                                        p.module_permission === permission.id
                                    ) || {}
                                  ).p_update
                                }
                              />
                            ) : (
                              this.validPermission(permission, "f_update")
                            )}
                          </div>
                          <div className="switch-row-container">
                            {permission.f_delete === "m" ? (
                              <PrevSwitch
                                handleClick={(value) =>
                                  this.setPermission(
                                    (
                                      this.state.tagPermissions.find(
                                        (p) =>
                                          p.module_permission === permission.id
                                      ) || {}
                                    ).id,
                                    "p_delete",
                                    value,
                                    permission.id
                                  )
                                }
                                checked={
                                  (
                                    this.state.tagPermissions.find(
                                      (p) =>
                                        p.module_permission === permission.id
                                    ) || {}
                                  ).p_delete
                                }
                              />
                            ) : (
                              this.validPermission(permission, "f_delete")
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="actions">
          <Button 
            settings={this.cancelButtonSettings} 
            disabled={this.props.loading}
          />
          <Button
            text={this.props.loading ? <Loader /> : "Aplicar"}
            onClick={this.onAccept}
            disabled={(!this.state.tagTitle || this.props.loading || this.state.error !== '')}
          />
        </div>
      </Modal>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.tags !== prevState.propsTags) {
      if (nextProps.tags.length) {
        return {
          propsTags: nextProps.tags,
          chosenColor: nextProps.tags[0].color,
          tagTitle: nextProps.tags[0].title,
          tagPermissions: nextProps.tags[0].permissions || [],
        };
      }
      return {
        propsTags: nextProps.tags,
        chosenColor: DEFAULT_COLOR,
        tagTitle: "",
        tagPermissions: [],
      };
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    colorCatalog: state.globalCatalog.colorCatalog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(EditTagsModal);

EditTagsModal.defaultProps = {
  open: false,
  tags: [],
  permissions: [],
  closeAction: () => {
    console.log("Not yet implemented");
  },
  acceptAction: () => {
    console.log("Not yet implemented");
  },
};

const DEFAULT_COLOR = "3D77F7";

const SpanStyled = styled.span`
  width: 50%;
  overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
  color: #20293d;
  font-size: 16px;
`;
