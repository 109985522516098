import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadSession } from "../redux/reducers/global-catalog.reducer";
import { loginUser } from "../redux/reducers/register.reducer";
import TATView from "./tat-view";

class TATLogin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const password = urlParams.get("password");
    const company_id = urlParams.get("company_id");
    if(email && password && company_id){
      this.props.loginUser({ username: email, password }).then(response => {
  
        if (this.props.error !== null) {
          // const message = handleWSError(this.props.error);
          // this.props.showError(message);
        } else {
          let object = this.props.data;
          if (object.company) {
            // debugger;
            if (object.company.length === 1) {
              object.company = object.company[0];
              window.sessionStorage.setItem(
                "orbinetwork_session",
                JSON.stringify(object)
              );
              // this.props.history.push("/company/reports/tat");
              this.props.loadSession();
              this.setState({loaded: true});
            } else if (object.company.length > 1) {
              // object.listCompanies = object.company;
              object.company = object.company.find(c => c.id.toString() === company_id.toString());
              if(object.company){
                window.sessionStorage.setItem(
                  "orbinetwork_session",
                  JSON.stringify(object)
                );
                // this.props.history.push("/company/reports/tat");
                this.props.loadSession();
                this.setState({loaded: true});
              }
            } else {
              // object.company = {
              //   id: 0
              // };
              // window.sessionStorage.setItem(
              //   "orbinetwork_session",
              //   JSON.stringify(object)
              // );
              // this.props.history.push("/home");
            }
          }
        }
      });
    }
  }

  render() {
    return (<div>
      {
        this.state.loaded 
        ? <TATView isEmail={true}/>
        : ""
      }
    </div>)
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.register.login.isFetching,
    data: state.register.login.data,
    error: state.register.login.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadSession: () => dispatch(loadSession()),
    loginUser: data => dispatch(loginUser(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TATLogin)
);