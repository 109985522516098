import React from "react";
import FleetDetailSubSection from "./fleet-detail-subsection";
import FleetDetailTitleSubSection from "./fleet-detail-title-subsection";

class FleetInfoHeaderSubSection extends React.Component {
  constructor() {
    super();

    this.state = {
      isCollapsed: true,
      isHovered: false
    };
  }
  render() {
    const { operators, vehicles, trailers } = this.props.data;

    return (
      <div className="fleet-container">
        <div className="fleet-container fleet-column">
          <FleetDetailTitleSubSection
            isDataCollapsed={this.state.isCollapsed}
          />
        </div>
        <div className="fleet-container fleet-column">
          <FleetDetailSubSection
            isDataCollapsed={this.state.isCollapsed}
            data={operators}
          />
        </div>

        <div className="fleet-container fleet-column">
          <FleetDetailSubSection
            isDataCollapsed={this.state.isCollapsed}
            data={vehicles}
          />
        </div>

        <div className="fleet-container fleet-column">
          <FleetDetailSubSection
            isDataCollapsed={this.state.isCollapsed}
            data={trailers}
          />
        </div>
        <div
          className="fleet-container collapse-btn"
          onClick={() => {
            this.setState({
              isCollapsed: !this.state.isCollapsed
            });
          }}
          style={{
            cursor: 'pointer'
          }}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          <img
            alt=""
            src="/images/select-arrow.svg"
            width={this.state.isHovered ? "23px" : "19px"}
            height={this.state.isHovered ? "29px" : "25px"}
            style={{
              transform: this.state.isCollapsed
                ? "rotate(0deg)"
                : "rotate(180deg)"
            }}
          ></img>
        </div>
      </div>
    );
  }
}

export default FleetInfoHeaderSubSection;
