import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

class Checkbox extends Component {
  HandleChange = (e) => {
    this.props.onChange(e);
  };

  render() {
    return (
      <label
        className={"checkbox-container ".concat(this.props.className || "")}
      >
        {
          (this.props.disabled && this.props.disableMessage) &&
          <ReactTooltip
            id={`tooltip-${this.props.item.description}`}
            className='eta-tooltip-content'
            place='right'
          >
            {this.props.disableMessage}
          </ReactTooltip>
        }

        {this.props.item.style ? (
          <h3 className="caption" style={{ margin: "-5px 0px 0px 10px" }}>
            {this.props.item.description}
          </h3>
        ) : (

          this.props.disabled ? 
          <div 
            style={{ color: 'grey' }}
            data-tip
            data-for={`tooltip-${this.props.item.description}`}
          >
            {this.props.item.description}
          </div>
          :
          <div>
            {this.props.item.description}
          </div>
        )}
        <input
          type="checkbox"
          value={this.props.item.id}
          name={this.props.name}
          checked={this.props.item.active || this.props.active}
          onChange={this.HandleChange}
          key={this.props.item.id}
          disabled={this.props.disabled}
        />
        {
          this.props.disabled ? <div/> : <span className="checkmark"></span>
        }
      </label>
    );
  }
}
export default Checkbox;

Checkbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  item: PropTypes.shape({
    description: PropTypes.string,
    active: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: "",
  active: false,
  disabled: false,
  // onChange: () => { console.log('Not implemented yet!') },
  // item: { description: '', active: false, id: 0 },
  // name: '',
};
