import React from "react";
const Equipment = (props) => {
  return (
    <svg
      width="23px"
      height="20px"
      stroke="#AFC8FF"
      fill="#AFC8FF"
      viewBox="-1 0 23 13"
      {...props}
    >
      <g>
        <g id="Group-2">
          <g id="Group">
            <polygon
              id="Rectangle"
              strokeWidth="1.5"
              // fill="#AFC8FF"
              points="0 0 20.3414221 0 20.3414221 8 14.5 8 0 8"
            />
            <ellipse
              id="Oval"
              strokeWidth="1.5"
              cx="1.27133888"
              cy="10.3068182"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              strokeWidth="1.5"
              cx="6.35669441"
              cy="10.3068182"
              rx="1.27133888"
              ry="1.32954545"
            />
            <line
              x1="16.5137556"
              y1="9.14772727"
              x2="16.5"
              y2="11.5"
              id="Line"
              strokeLinecap="square"
            />
          </g>
        </g>
        <path
          d="M16.5,10.9718253 L15.8830952,12 L17.1169048,12 L16.5,10.9718253 Z"
          id="Triangle"
        />
      </g>
    </svg>
  );
};

export default Equipment;
