import React from "react";
// import type { TripCard } from "../types";
// import {
// Equipment,
//   TruckDoubleEquipment,
// } from "../../../shared/icons/assemble";
import Weight from "../../../shared/icons/traffic/weight";
import Volume from "../../../shared/icons/traffic/volume";
import Space from "../../../shared/icons/traffic/space";

const ticket1 = {
  id: 12,
  number: "asdfga",
  origin: {
    companyLogo: "/images/factory-bckgrnd.png",
    location: "VER, MX",
    timedate: "20:23 / 2 Jul 19",
  },
  destination: {
    companyLogo: "/images/factory-bckgrnd.png",
    location: "GDL, MX",
    timedate: "12:00 / 3 Jul 19",
  },
  level: 3,
  weight: 20,
  volume: 1203,
  capacity: 120.0,
  weightIcon: <Weight />,
  volumeIcon: <Volume />,
  capacityIcon: <Space />,
  tags: [
    {
      id: 12,
      description: "Etiqueta 12",
    },
    {
      id: 21,
      description: "Etiqueta 21",
    },
  ],
};

// const class1 = {
//   id: 123,
//   code: "CL1",
//   icon: <TruckDoubleEquipment />,
// };

const assemble1 = {
  vehicle: { id: 1, number: "12345/plt123" },
  operators: [
    { id: 1, name: "Juan Pérez" },
    { id: 2, name: "Pedro Pablo" },
  ],
  equipments: [
    { id: 1, number: "54321/equi123" },
    { id: 2, number: "34521/equi543" },
  ],
};

const courier1 = {
  logo: "/images/factory-bckgrnd.png",
  name: "Transportes del norte del sur",
  assemble: assemble1,
};

const trip1 = {
  trip_id: 1,
  order_id: 1,
  code: "123456",
  appointment_loading_date: "2020-06-09T07:00",
  appointment_unload_date: "2020-06-09T07:00",
  level: 2,
  status: 1,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip2 = {
  trip_id: 2,
  order_id: 2,
  code: "75623834",
  appointment_loading_date: "2020-06-09T08:00",
  appointment_unload_date: "2020-06-09T08:00",
  level: 3,
  status: 3,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip3 = {
  trip_id: 3,
  order_id: 3,
  code: "75623834",
  appointment_loading_date: "2020-06-09T09:00",
  appointment_unload_date: "2020-06-09T09:00",
  level: 3,
  status: 5,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip4 = {
  trip_id: 4,
  order_id: 4,
  code: "75623834",
  appointment_loading_date: "2020-06-09T10:00",
  appointment_unload_date: "2020-06-09T10:00",
  level: 3,
  status: 7,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip5 = {
  trip_id: 5,
  order_id: 5,
  code: "75623834",
  appointment_loading_date: "2020-06-09T11:00",
  appointment_unload_date: "2020-06-09T11:00",
  level: 3,
  status: 9,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip6 = {
  trip_id: 6,
  order_id: 6,
  code: "75623834",
  appointment_loading_date: "2020-06-09T12:00",
  appointment_unload_date: "2020-06-09T12:00",
  level: 3,
  status: 11,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};

const trip7 = {
  trip_id: 7,
  order_id: 7,
  code: "75623834",
  appointment_loading_date: "2020-06-09T13:00",
  appointment_unload_date: "2020-06-09T13:00",
  level: 3,
  status: 12,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip8 = {
  trip_id: 8,
  order_id: 8,
  code: "75623834",
  appointment_loading_date: "2020-06-09T14:00",
  appointment_unload_date: "2020-06-09T14:00",
  level: 3,
  status: 13,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip9 = {
  trip_id: 9,
  order_id: 9,
  code: "75623834",
  appointment_loading_date: "2020-06-09T15:00",
  appointment_unload_date: "2020-06-09T15:00",
  level: 3,
  status: 14,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip10 = {
  trip_id: 10,
  order_id: 10,
  code: "75623834",
  appointment_loading_date: "2020-06-09T16:00",
  appointment_unload_date: "2020-06-09T16:00",
  level: 3,
  status: 15,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip11 = {
  trip_id: 11,
  order_id: 11,
  code: "75623834",
  appointment_loading_date: "2020-06-09T17:00",
  appointment_unload_date: "2020-06-09T17:00",
  level: 3,
  status: 16,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip12 = {
  trip_id: 12,
  order_id: 12,
  code: "75623834",
  appointment_loading_date: "2020-06-09T18:00",
  appointment_unload_date: "2020-06-09T18:00",
  level: 3,
  status: 17,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
const trip13 = {
  trip_id: 13,
  order_id: 13,
  code: "75623834",
  appointment_loading_date: "2020-06-09T19:00",
  appointment_unload_date: "2020-06-09T19:00",
  level: 3,
  status: 18,
  transport_class: 76,
  courier: courier1,
  tickets: [ticket1],
};
export const ORDERS = [
  trip1,
  trip2,
  trip3,
  trip4,
  trip5,
  trip6,
  trip7,
  trip8,
  trip9,
  trip10,
  trip11,
  trip12,
  trip13,
];
