// @flow
import React from "react";
import RadioButtons from "../../../shared/controls/radio";
import { Flex } from "../../../shared/styled/flex";
import ExceedPlus from "../../../shared/controls/exceed-plus";
import Checkbox from "../../../shared/controls/checkbox";

type Props = {
  headers: Array<any>,
  step?: number,
  onlyOne: boolean,
  data: {
    active: boolean,
    assembled: boolean,
    id: number,
    number: String,
    plates: String,
    tags: Array<any>,
    from: string,
    to: string,
    status: any,
    selected: boolean,
    class: number,
  },
  onChange: Function,
};

const AssembleTableDataRow = ({
  headers,
  data,
  step,
  onChange,
  onlyOne,
}: Props) =>
  step === 1 ? (
    <VehicleRow
      data={data}
      headers={headers}
      onChange={onChange}
      onlyOne={onlyOne}
    />
  ) : (
    <EquipmentRow
      data={data}
      headers={headers}
      onChange={onChange}
      onlyOne={onlyOne}
    />
  );

export default AssembleTableDataRow;

const VehicleRow = ({ data, headers, onChange, onlyOne }: Props) => {
  return (
    <Flex style={{ alignItems: "center", marginLeft: "20px" }}>
      <div style={{ width: headers[0].width }}>
        {/* {!data.isOnTrip ? */} <RadioButtons
          value={data.number}
          name="vehiclesData"
          onChange={() =>
            onChange({
              target: {
                name: "vehiclesData",
                value: data,
              },
            })
          }
          id={data.id}
          checked={data.selected || false}
        />{/*  : data.number} */}
      </div>
      <div style={{ width: headers[1].width, textAlign: "center" }}>
        {data.plates}
      </div>
      <div style={{ width: headers[2].width, textAlign: "center", color: data.assembled || data.isOnTrip ? 'red' : 'initial' }}>
        {data.assembled ? 'Ensamblado' : 'Libre'} {data.isOnTrip ? " con viaje" : ""}
      </div>
      <Flex
        justifyContent="center"
        style={{ width: headers[3].width, textAlign: "center" }}
      >
        <ExceedPlus name={data.id}>{data.tags}</ExceedPlus>
      </Flex>
      <div style={{ width: headers[4].width, textAlign: "center" }}>
        {new Date(data.from).toLocaleString("es-MX")}
      </div>{" "}
      <div style={{ width: headers[5].width, textAlign: "center" }}>
        {typeof data.to === "string"
          ? data.to
          : data.to.toLocaleString("es-MX")}
      </div>
      <div style={{ width: headers[6].width, textAlign: "center" }}>
        {
          <div style={{ display: "flex", alignItems: "center" }}>
            <img width="24px" src={data.status.ICON} alt="" />
            <span style={{ color: data.status.COLOR }}>
              {data.status.CAPTION}
            </span>
          </div>
        }
      </div>
    </Flex>
  );
};

const EquipmentRow = ({ data, headers, onChange, onlyOne }: Props) => {
  return (
    <Flex style={{ alignItems: "center", marginLeft: "20px" }}>
      <div style={{ width: headers[0].width }}>
        {!data.isOnTrip ? onlyOne ? (
          <RadioButtons
            value={data.number}
            name="equipmentsData"
            onChange={() =>
              onChange({
                target: {
                  name: "equipmentsData",
                  value: data,
                },
              })
            }
            id={data.id}
            checked={data.selected || false}
          />
        ) : (
          <Checkbox
            onChange={(e) =>
              onChange({
                target: {
                  name: "equipmentsData",
                  value: data,
                  checked: e.target.checked,
                },
              })
            }
            item={{
              description: data.number,
              active: data.selected,
              id: data.id,
            }}
            name="equipmentsData"
          />
        ) : data.number}
      </div>
      <div style={{ width: headers[1].width, textAlign: "center" }}>
        {data.plates}
      </div>
      <div style={{ width: headers[2].width, textAlign: "center", color: data.assembled || data.isOnTrip ? 'red' : 'initial' }}>
        {data.assembled ? 'Ensamblado' : 'Libre'} {data.isOnTrip ? " con viaje" : ""}
      </div>
      <Flex
        justifyContent="center"
        style={{ width: headers[3].width, textAlign: "center" }}
      >
        <ExceedPlus name={data.id}>{data.tags}</ExceedPlus>
      </Flex>
      <div style={{ width: headers[4].width, textAlign: "center" }}>
        {new Date(data.from).toLocaleString("es-MX")}
      </div>{" "}
      <div style={{ width: headers[5].width, textAlign: "center" }}>
        {typeof data.to === "string"
          ? data.to
          : data.to.toLocaleString("es-MX")}
      </div>
      <div style={{ width: headers[6].width, textAlign: "center" }}>
        {
          <div style={{ display: "flex", alignItems: "center" }}>
            <img width="24px" src={data.status.ICON} alt="" />
            <span style={{ color: data.status.COLOR }}>
              {data.status.CAPTION}
            </span>
          </div>
        }
      </div>
    </Flex>
  );
};
