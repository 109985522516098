import React from "react";
const WifiIcon = (props) => {
  return (
    <svg 
      width="49px" 
      height="36px" 
      viewBox="0 0 49 36" 
      // version="1.1" 
      // xmlns="http://www.w3.org/2000/svg" 
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="#B1F06C"
      transform="translate(9.000000, 7.000000)"
      stroke-width="1"
      {...props}
    >
      {/* <g id="wifi" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-4" transform="translate(9.000000, 7.000000)" fill="#B1F06C"> */}
          <path d="M13.0231013,17.0237209 C11.6589662,18.3894878 11.6589662,20.6108687 13.0231013,21.9764703 C13.7051894,22.6577214 14.6023148,23 15.4993991,23 C16.3964834,23 17.2920476,22.6577544 17.9756969,21.9764703 C19.3414344,20.6107034 19.3414344,18.3893225 17.9756969,17.0237209 C16.6115618,15.6579539 14.3887977,15.6595657 13.022937,17.0237209 L13.0231013,17.0237209 Z" id="Fill-1"></path>
          <path d="M14.5259516,8.00175199 C11.0530556,8.00175199 7.77345853,9.5514357 5.52878521,12.2556593 C4.73196746,13.2137753 4.84862286,14.6501637 5.7913267,15.4619585 C6.21046864,15.8238104 6.72327293,16 7.23301147,16 C7.86710909,16 8.49814096,15.7246801 8.93874309,15.1944883 C10.335896,13.5158019 12.3717451,12.5529737 14.5274333,12.5529737 C16.6615434,12.5529737 18.6819066,13.4985236 20.0744609,15.1457146 C20.8789823,16.0975475 22.2883982,16.2045194 23.2234102,15.3848508 C24.1584221,14.5651823 24.2643475,13.1287736 23.4583167,12.176981 C21.2152156,9.52128942 17.9588475,8 14.5244305,8 L14.5259516,8.00175199 Z" id="Fill-2"></path>
          <path d="M30.4012725,6.2971729 C26.5790185,2.29629401 21.1555348,0 15.521432,0 C9.85915664,0 4.41749975,2.31437294 0.59199194,6.34998342 C-0.249651522,7.23689181 -0.186084843,8.61466822 0.733040925,9.42779607 C1.16392143,9.81026374 1.70951558,10 2.25510974,10 C2.86427057,10 3.47493923,9.76208667 3.91823944,9.29380382 C6.89266005,6.15577948 11.1208759,4.35775467 15.5229279,4.35775467 C19.901569,4.35775467 24.1175238,6.142095 27.0887304,9.25294313 C27.9350163,10.1368448 29.3594734,10.191043 30.2754764,9.37641567 C31.188424,8.55877773 31.2473085,7.18100132 30.402574,6.29709966 L30.4012725,6.2971729 Z" id="Fill-3"></path>
        {/* </g>
      </g> */}
    </svg>
  );
};

export default WifiIcon;