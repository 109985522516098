import React from "react";
const TruckEquipment = (props) => {
  return (
    <svg
      viewBox="-1 0 34 12"
      width="34px"
      height="20px"
      fill="#AFC8FF"
      stroke="#AFC8FF"
      {...props}
    >
      <g>
        <g id="Group-58">
          <path
            d="M19.3398389,11.9166667 C18.043045,11.9166667 17.7222222,10.8219411 17.7222222,9.77777778 C17.7222222,8.73361445 18.043045,8.13541667 19.3398389,8.13541667 C20.3622219,8.13541667 21.2319896,8.66154616 21.5543078,9.39588936 L24.0351623,9.39583333 L24.0359449,2.46354167 C24.0359449,2.11548721 24.3863647,1.83333333 24.8186293,1.83333333 L27.8942912,1.83333333 C28.6793701,1.83333333 29.4125059,2.14925892 29.8479894,2.67522827 L32.251118,5.57768507 C32.5082572,5.88825332 32.6454728,6.2531585 32.6454728,6.62641514 L32.6454728,10.0260417 C32.6454728,10.3740961 32.295053,10.65625 31.8627884,10.65625 L30.1638111,10.65625 L30.1605315,10.6636715 C29.8356055,11.3941062 28.9682762,11.9166667 27.9493667,11.9166667 C26.9304572,11.9166667 26.0631279,11.3941062 25.7382019,10.6636715 L25.7349223,10.65625 L21.5540339,10.6568176 C21.231497,11.3908351 20.3619324,11.9166667 19.3398389,11.9166667 Z M27.9493667,9.39583333 C27.5171021,9.39583333 27.1666823,9.67798725 27.1666823,10.0260417 C27.1666823,10.3740961 27.5171021,10.65625 27.9493667,10.65625 C28.3816313,10.65625 28.732051,10.3740961 28.732051,10.0260417 C28.732051,9.67798725 28.3816313,9.39583333 27.9493667,9.39583333 Z M19.3398389,9.39583333 C18.9075742,9.39583333 18.5571545,9.67798725 18.5571545,10.0260417 C18.5571545,10.3740961 18.9075742,10.65625 19.3398389,10.65625 C19.7721035,10.65625 20.1225232,10.3740961 20.1225232,10.0260417 C20.1225232,9.67798725 19.7721035,9.39583333 19.3398389,9.39583333 Z M30.2974197,10.0260417 L30.2952659,9.94432375 L30.2927236,9.91449479 L30.2974197,10.0260417 Z M25.6169673,10.2459844 L25.6083578,10.1703594 L25.6152204,10.2330001 L25.6169673,10.2459844 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <rect
            id="Rectangle"
            strokeWidth="1.5"
            x="0.417293705"
            y="0"
            width="22.0993228"
            height="7.33333333"
          />
          <ellipse
            id="Oval"
            strokeWidth="1.5"
            cx="14.689773"
            cy="9.625"
            rx="1.38120767"
            ry="1.375"
          />
          <ellipse
            id="Oval"
            strokeWidth="1.5"
            cx="1.79850138"
            cy="9.625"
            rx="1.38120767"
            ry="1.375"
          />
          <ellipse
            id="Oval"
            strokeWidth="1.5"
            cx="7.16434663"
            cy="9.625"
            rx="1.22222222"
            ry="1.21672909"
          />
        </g>
        <path
          d="M27.0643939,3 L27.0643939,4.8030303 L28.094697,4.8030303 L29.125,4.8030303 L29.125,4.28787879 C28.964237,4.03561827 28.8436647,3.85818129 28.7632832,3.75556786 C28.3687039,3.25185595 27.9741247,3 27.5795455,3 C27.0643939,3 26.8926768,3 27.0643939,3 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <ellipse
          id="Oval"
          strokeWidth="1.5"
          cx="19.5555556"
          cy="9.77228464"
          rx="1.22222222"
          ry="1.21672909"
        />
        <ellipse
          id="Oval"
          strokeWidth="1.5"
          cx="28.1111111"
          cy="9.77228464"
          rx="1.22222222"
          ry="1.21672909"
        />
      </g>
    </svg>
  );
};

export default TruckEquipment;
