import * as React from "react";
import styled from "styled-components";
import Caret from "../../shared/component/caret";

class CollapsableParentContainer extends React.Component {
  state = {
    collapsed: true
  }

  static defaultProps = {
    childrenElements: null
  }

  render() {
    return (
      <div className={this.props.className}>
        <div style={{display: "flex"}}>
          {
            this.props.childrenElements &&
            <CaretContainer
              onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            >
              <Caret up={this.state.collapsed} fill="#AFC8FF" />
            </CaretContainer>
          }
          {
            this.props.children
          }
        </div>
        {
          (!this.state.collapsed && this.props.childrenElements) &&
          <div className={this.props.childrenClassName} style={{paddingLeft: "3%"}}>{this.props.childrenElements}</div>
        }
      </div>
    )
  }
}

export default CollapsableParentContainer;

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
  width: 3%;
`;