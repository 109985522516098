// @flow
import * as React from "react";
import SVGIcon from "../../svg-icon";
import { icons } from "../../svg-icon";
import TextInput from "../../controls/text-input";
import Button from "../../controls/button";

type Props = {
  // filtered: boolean,
  add: boolean,
  filterAction: Function,
  onChange: Function,
  searchStr: string,
  searchAction: Function,
  className: string,
  content: {
    title: string,
    items: {
      id: Number,
      description: string,
      items?: { id: Number, description: string }[],
    }[],
    name: string,
    listType: number,
  }[],
  selectedItems: { name: string, value: string }[],
  searchOnly: boolean,
};
type State = {|
  collapsed: boolean,
    isOpen: boolean,
      current: string,
|};

class FilterFavorites extends React.Component<Props, State> {
  static defaultProps = {
    // filtered: false,
    add: false,
    searchStr: "",
    className: "",
    buttonFilterName: "Guardar",
    content: [],
    selectedItems: {},
    searchAction: () => { },
    filterAction: () => { },
    onChange: () => { },
    onSearch: () => { },
    searchOnly: false,
    multiText: false,
  };
  state = {
    collapsed: true,
    isOpen: false,
    current: "",
  };
  filterBarNode: any;

  handleOutsideClick = (e: any) => {
    if (!this.filterBarNode.contains(e.target))
      this.setState({ collapsed: true, isOpen: false });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  handleInput = (e: any) => { };
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { collapsed } = this.state;
    const { add, searchStr, searchAction, className } = this.props;
    return (
      <div className={"filter-bar-compact ".concat(className)}>
        <div
          ref={(node) => (this.filterBarNode = node)}
          style={{
            display: "flex",
            width: "fit-content",
            // alignItems: "center",
            position: "relative",
          }}
        >
          <div
            className="filter-content-fav"
            style={{
              height: "auto",
              width: "auto",
              display: collapsed ? "none" : "block",
              backgroundColor: "#FFFFFF",
              padding: "0px 25px 25px 25px",
              boxShadow:
                "0 2px 10px 1px rgba(0,0,0,0.5), 0 1px 2px 0 rgba(0,0,0,0.5)",
              borderRadius: "12px",
              position: "absolute",
              zIndex: "1000",
              right: "100%",
            }}
          >
            {
              add ?
                <>
                  <div className="fav">
                    <h6 style={{ color: "#657795", margin: "20px 0px 0px 0px", }}>Preferencias</h6>
                    {
                      [{ id: 1, name: "C1" }, { id: 2, name: "C2" }].map((p) =>
                        <div className="preferences">
                          <p style={{ float: 'left', width: '50%', color: "#000000", fontSize: "0.50em", margin: "0px", padding: "0px 0px 0px 30px" }}>
                            {p.name}
                          </p>
                        </div>)
                    }
                    <TextInput
                      value={searchStr}
                      label={(this.props.searchPlaceholder) ? this.props.searchPlaceholder : "Nueva preferencia"}
                      name="searchStr"
                      onChange={searchAction}
                    />
                  </div>
                  <Button
                    text={this.props.buttonFilterName}
                    //   type={this.props.buttonFilterName === "Guardar" ? "primary" : "primary outline"}
                    className=""
                    onClick={() => {
                      this.props.filterAction();
                      this.props.onSearch();
                    }}
                  />
                </> :
                <>
                  <div className="fav" style={{marginBottom: "20px"}}>
                    <h6 style={{ color: "#657795", margin: "20px 0px 0px 0px", }}>Preferencias</h6>
                    {
                      [{ id: 1, name: "C1" }, { id: 2, name: "C2" }].map((p) =>
                        <div className="preferences">
                          <p style={{ float: 'left', width: '50%', color: "#000000", fontSize: "0.50em", margin: "0px", padding: "0px 0px 0px 30px" }}>
                            {p.name}
                          </p>
                          <SVGIcon
                            style={{ float: 'right', width: '50%', margin: "15px" }}
                            name={icons.trash}
                            fill="#b0cdfb"
                            height="25px"
                            width="25px"
                            onClick={() => this.setState({ collapsed: !collapsed })}
                          />
                        </div>)
                    }
                  </div>
                  <Button
                    text={"Aplicar"}
                    //   type={this.props.buttonFilterName === "Guardar" ? "primary" : "primary outline"}
                    className=""
                    onClick={() => {
                      this.props.filterAction();
                      this.props.onSearch();
                    }}
                  />
                </>
            }
          </div>
          <img style={{height: "30px", width: "25px", padding: "10px", margin: "0px 5px 0px 10px"}}
            className={`${
              this.state.origin === 0 ||
              this.state.destination === 0 ||
              this.state.rowEditMode
                ? "disabled"
                : ""
            }`}
            src={add ? "/images/ic-fav-add.svg" : "/images/ic-fav.svg"}
            alt=""
            onClick={() => this.setState({ collapsed: !collapsed })}
          />
        </div>
      </div>
    );
  }
}
export default FilterFavorites;
