// @flow
import * as React from "react";
import Tag from "../../controls/tag";
import TextInput from "../../controls/text-input";
import Checkbox from "../../controls/checkbox"; 
import { normalizeStr } from "../../utils";
import ReactDatePicker from "react-datepicker";
import { isArray } from 'lodash';
import * as moment from "moment";

type Props = {};
// type State = { isOpen: boolean, current: string, searchText: string };
export const FILTER_LIST_TYPES = {
  chips: 1,
  checkList: 2,
  checkListTextSearch: 3,
  checkListAll: 4,
  checkListTextSearchAll: 5,
  checkExclusive: 6,
  dateRange: 7,
  dateRangeFixed: 8,
  date: 9,
  dateFixed: 10, //Sin opcion de limpiar filtro
  checkListTextSearchSingle: 11,
};

class FilterBarContent extends React.Component<Props> {

  state = {
    indexHovered: -1
  }

  //   state = {
  //     isOpen: false,
  //     current: "",
  //     searchText: ""
  //   };
  getSelectedNumber = (c) => {
    if(c.listType === 8 || c.listType === 7){
      let items = (this.props.selectedItems[c.name] || [])
      // debugger;
      items = items.filter((value, i, a) => { 
        // debugger;
        return (value && (i === 0 || value.getTime() !== a[0].getTime()))
      });
      // debugger;
      return items.length;
    }
    return (this.props.selectedItems[c.name] || []).length
  }
  render() {
    return this.props.content.map((c, index) => (
      <div
        className={"".concat(
          "list",
          c.name === this.props.current && this.props.isOpen ? " open" : "",
          (this.props.selectedItems[c.name] || []).length > 0 ? " filtered" : ""
        )}
        onClick={(e) => this.manageView(e, c.name)}
        key={c.name}
        style={{
          cursor: 'pointer'
        }}
        onMouseEnter={() => this.setState({ indexHovered: index })}
        onMouseLeave={() => this.setState({ indexHovered: -1})}
      >
        {/* <div className={''.concat(this.props.selectedItems[c.name] || []).length > 0 ? 'filtered' : ''}> */}
        <div className="title">
          <div
            style={{
              display:
                (this.props.selectedItems[c.name] || []).length > 0
                  ? "block"
                  : "none",
            }}
          >
            {this.getSelectedNumber(c)
              .toString()
              .concat("\u00a0\u00a0")}
          </div>
          {c.title}
        </div>
        {/* </div> */}
        <div className="caret">
          <img 
            width={this.state.indexHovered === index ? "21px" : "16px"}
            height={this.state.indexHovered === index ? "15px" : "10px"}
            alt="" 
            src="/images/caret.svg" />
        </div>
        {
          c.name === this.props.current && this.props.isOpen
          ? <div 
            className={"content".concat(c.listType === 7 || c.listType === 8 || c.listType === 9 || c.listType === 10 ? " date" : "")} 
            style={ (c.listType === 7 || c.listType === 8 || c.listType === 9 || c.listType === 10) && c.time ? {width: 480, left: -200} : {}}
            onClick={(e) => e.stopPropagation()}>
              {this.getListType(c)}
            </div>
          : ''
        }
        
      </div>
    ));
  }

  handleChange = (name, value) => {
    if (name.target) this.props.handleChange(name);
    else this.props.handleChange({ target: { name, value } });
  };

  getListType = (c) => {
    switch (c.listType) {
      case FILTER_LIST_TYPES.chips:
        return this.getChipsList(c.items, c.name);
      case FILTER_LIST_TYPES.checkList:
        return this.getCheckList(c.items, c.name);//au
      case FILTER_LIST_TYPES.checkListTextSearch: 
        return this.getCheckList(c.items, c.name, true);
      case FILTER_LIST_TYPES.checkListTextSearchSingle: 
        return this.getCheckList(c.items, c.name, true, false, true);
      case FILTER_LIST_TYPES.checkListAll:
        return this.getCheckList(c.items, c.name, false, true);
      case FILTER_LIST_TYPES.checkListTextSearchAll:
        return this.getCheckList(c.items, c.name, true, true);
      case FILTER_LIST_TYPES.checkExclusive:
        return this.getCheckList(c.items, c.name, true, false, true);
      case FILTER_LIST_TYPES.date:
        return this.getDatePicker(c.name, true, false, c.time);
      case FILTER_LIST_TYPES.dateFixed:
        return this.getDatePicker(c.name, false, false, c.time);
      case FILTER_LIST_TYPES.dateRange:
        return this.getDatePicker(c.name, true, true, c.time);
      case FILTER_LIST_TYPES.dateRangeFixed:
        return this.getDatePicker(c.name, false, true, c.time);
      default:
        return <React.Fragment />;
    }
  };

  getDatePicker = (name, clear = true, range = true, time = false) => {
    // debugger;
    if (time) {

      let [start, end] = (this.props.selectedItems[name]) ? this.props.selectedItems[name] : [null, null];

      return (
        <div className="items" style={{maxHeight: 340}}>
          {
            clear &&
            <div className="deselector">
              <span onClick={() => this.deselectAll({ target: { name } })}>
                Limpiar filtro
              </span>
            </div>
          }
          <ReactDatePicker
            onChange={dates => {
              if (end === null || end === undefined){

                let newDates = new Date(dates.getTime());

                newDates.setHours(23);
                newDates.setMinutes(59);

                dates.setHours(0);
                dates.setMinutes(0);

                this.props.onChange({ target: { name: name, value: [dates, newDates] } });
              } else{

                if (end.getFullYear() === dates.getFullYear() && end.getMonth() === dates.getMonth() && end.getDate() === dates.getDate()) {

                  let startHours = dates.getHours();
                  let endHours = end.getHours();
                  let startMinutes = dates.getMinutes();
                  let endMinutes = end.getMinutes();
  
                  if ((startMinutes + (startHours * 60)) >= (endMinutes + (endHours * 60))) {
                    this.props.onChange({ target: { name: name, value: [dates, null] } });
                  } else {
                    this.props.onChange({ target: { name: name, value: [dates, end] } });
                  }
  
                } else if (moment(dates).isAfter(end)) {
                  this.props.onChange({ target: { name: name, value: [dates, null] } });
                } else {
                  this.props.onChange({ target: { name: name, value: [dates, end] } });
                }

              }
            }}
            selected={start}
            startDate={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
            endDate={(this.props.selectedItems[name] && (this.props.selectedItems[name][1])) || null}
            selectsRange={false}
            inline
            showTimeInput
          />
          <ReactDatePicker
            onChange={dates => {
              if (start === null || start === undefined) {
                start = new Date();
              }

              if (start.getFullYear() === dates.getFullYear() && start.getMonth() === dates.getMonth() && start.getDate() === dates.getDate()) {

                let startHours = start.getHours();
                let endHours = dates.getHours();
                let startMinutes = start.getMinutes();
                let endMinutes = dates.getMinutes();

                if ((startMinutes + (startHours * 60)) >= (endMinutes + (endHours * 60))) {
                  dates.setHours(23);
                  dates.setMinutes(59);

                  this.props.onChange({ target: { name: name, value: [start, dates] } });
                } else {
                  this.props.onChange({ target: { name: name, value: [start, dates] } });
                }

              } else if (moment(dates).isAfter(start)) {
                this.props.onChange({ target: { name: name, value: [start, dates] } });
              } else {
                this.props.onChange({ target: { name: name, value: [dates, null] } });
              }

            }}
            selected={end}
            startDate={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
            endDate={(this.props.selectedItems[name] && (this.props.selectedItems[name][1])) || null}
            selectsRange={false}
            inline
            showTimeInput
          />
        </div>
      )

    } else {
      return (
        <div className="items">
          {
            clear &&
            <div className="deselector">
              <span onClick={() => this.deselectAll({ target: { name } })}>
                Limpiar filtro
              </span>
            </div>
          }
          <ReactDatePicker
            onChange={dates => {
              if(range){
                let [start, end] = dates;
                // if(end) {
                //   end = new Date(end.setMinutes(1))
                // }
                this.props.onChange({ target: { name: name, value: [start, end] } })
              } else {
                // debugger;
                this.props.onChange({ target: { name: name, value: [dates] } })
              }
            }}
            selected={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
            startDate={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
            endDate={(this.props.selectedItems[name] && (this.props.selectedItems[name][1])) || null}
            selectsRange={range}
            inline
          />
        </div>
      );
    }

  }

  getChipsList = (items, name) => {
    return (
      <React.Fragment>
        <div className="deselector">
          <span onClick={() => this.deselectAll({ target: { name } })}>
            Limpiar filtro
          </span>
        </div>
        <div className="search-list">
          <TextInput
            value={this.props.searchText}
            label="Buscar"
            name="searchText"
            onChange={this.handleChange}
          />
        </div>
        <div className="items chips">
          {this.handleFilterListSearch(
            items,
            "title",
            this.props.searchText
          ).map((i) => (
            <Tag
              key={i.id}
              id={i.id}
              color={i.color || "3D77F7"}
              title={i.title}
              isFull={
                (this.props.selectedItems[name] || []).find(
                  (s) => s.toString() === i.id.toString()
                )
                  ? true
                  : false
              }
              parentBackground={"light"}
              onClick={(e) =>
                this.handleSelectedItemsChange({
                  target: {
                    name: name,
                    value: e.target.id,
                  },
                })
              }
            />
          ))}
        </div>
      </React.Fragment>
    );
  };

  deselectAll = (e) => {
    this.props.onChange({ target: { name: e.target.name, value: [] } });
  };

  selectAll = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props.content
        .find((c) => c.name === e.target.name)
        .items
        .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
        .forEach((fe) => {
          if ((fe.items || []).length > 0) {
            fe.items
            .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
            .forEach((ch) => {
              if (!st.includes(ch.id)) st.push(ch.id);
            });
          } else {
            st.push(fe.id);
          }
        });
    }
    this.props.onChange({ target: { name: e.target.name, value: st } });
  };

  handleSelectedItemsChange = (e, items, single = false) => {
    
    if (items !== undefined && items.length > 0 && single) {
      return;
    }

    if(single){
      let st=[];
      if(e.target.checked) {
        st.push(e.target.value.toString())
      }
      this.props.onChange({ target: { name: e.target.name, value: st } });
    } else {
      let st =
        (this.props.selectedItems[e.target.name] || []).map((m) =>
          m.toString()
        ) || [];
      if ((items || {}).length > 0) {
        items.forEach((item) => {
          let val = item.id.toString();
          if (!item.isDisabled || false) {
            st = st.filter((a) => a !== val);
            if (e.target.checked) {
              st = st.concat(val);
            }
          }
        });
      } else {
        if (st.includes(e.target.value.toString())) {
          st = st.filter((a) => a.toString() !== e.target.value.toString());
        } else {
          st = st.concat(e.target.value.toString());
        }
      }
      this.props.onChange({ target: { name: e.target.name, value: st } });
    }
  };

  manageView = (e, name) => {
    if (name === this.props.current) {
      this.handleChange("isOpen", !this.props.isOpen);
      this.handleChange("searchText", "");
    } else if (name === "") {
      this.handleChange("current", name);
      this.handleChange("isOpen", false);
      this.handleChange("searchText", "");
    } else {
      this.handleChange("current", name);
      this.handleChange("isOpen", true);
      this.handleChange("searchText", "");
    }
  };

  handleFilterListSearch = (items, field, value = "") => {
    if(!value.length > 0) return items;

    let result = [];
    items.forEach(i => {
      if(normalizeStr(i[field] || "").includes(normalizeStr(value))){
        result.push(i)
      } else {
        if((i.items || []).length){
          let matches = i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)));
          if(matches.length){
            result.push({
              ...i,
              items: matches
            })
          } else {
            
          }
        }
      }
    })
    return result;
    // return value.length > 0
    //   // ? items
    //   //     .filter(i => {

    //   //     })
    //   ? items
    //       .map((i) => {
    //         if(normalizeStr(i[field] || "").includes(normalizeStr(value))){
    //           return i
    //         } else {
    //           if((i.items || []).length){
    //             let matches = i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)));
    //             if(matches.length){
    //               return {
    //                 ...i,
    //                 items: matches
    //               }
    //             } else {
    //               return null
    //             }
    //           }
    //         }
    //         (i.items || []).length &&
    //         !normalizeStr(i[field] || "").includes(normalizeStr(value))
    //           ? {
    //               ...i,
    //               // items: this.handleFilterListSearch(
    //               //   i.items,
    //               //   "description",
    //               //   this.props.searchText
    //               // ),
    //               items: i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)))
    //             }
    //           : i
    //       })
    //       // .filter(
    //       //   (i) =>
    //       //     normalizeStr(i[field] || "").includes(normalizeStr(value)) ||
    //       //     (i.items || []).length
    //       // )
    //   : items;
  };

  getCheckList = (items, name, search = false, all = false, single = false) => {
    return (
      <React.Fragment>
        <div className="deselector">
          <span onClick={() => this.deselectAll({ target: { name } })}>
            Limpiar filtro
          </span>
        </div>
        {search ? this.getTextSearch() : <React.Fragment />}
        {all ? this.getSelectAll(name) : <React.Fragment />}
        {this.checkList(items, name, single)} 
      </React.Fragment>
    );
  };
  checkList = (items, name, single) => {
    let counter = -9999;
    return (
      <div className="items checks">
        {
          // (items || []).map(i => {
          this.handleFilterListSearch(
            items || [],
            "description",
            this.props.searchText
          ).map((i) => {
            let l = [];
            l.push(
              <Checkbox
                disabled={i.isDisabled || false}
                disableMessage={i.disableMessage}
                key={counter++}
                onChange={(e) => this.handleSelectedItemsChange(e, i.items, single)}
                item={{
                  id: i.id,
                  description: i.description,
                  active: (this.props.selectedItems[name] || []).find(
                    (si) => si.toString() === i.id.toString()
                  )
                    ? true
                    : false,
                }}
                name={name}
                className={(i.items || []).length ? "bold" : ""}
              />
            );
            if ((i.items || []).length) {
              // this.handleFilterListSearch(i.items, 'description', this.state.searchText).forEach(ci =>
              i.items.forEach((ci) =>
                l.push(
                  <Checkbox 
                    disableMessage={ci.disableMessage}
                    disabled={ci.isDisabled || false}
                    key={counter++}
                    className={"sub-item"}
                    onChange={(e) => this.handleSelectedItemsChange(e, single, single)}
                    item={{
                      id: ci.id,
                      description: ci.description,
                      active: (this.props.selectedItems[name] || []).find(
                        (si) => si.toString() === ci.id.toString()
                      )
                        ? true
                        : false,
                    }}
                    name={name}
                  />
                )
              );
              l.push(<div className="separator" key={counter++} />);
              let full = true;
              i.items.filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }).forEach((fe) =>
                (this.props.selectedItems[name] || []).find(
                  (si) => si.toString() === fe.id.toString()
                ) && full
                  ? (full = true)
                  : (full = false)
              );
              l[0].props.item.active = full;
            }
            return l;
          })
        }
      </div>
    );
  };

  getTextSearch = () => {
    return (
      <div className="search-list">
        <TextInput
          value={this.props.searchText}
          label="Buscar"
          name="searchText"
          onChange={this.handleChange}
        />
      </div>
    );
  };

  isAllSelected = (name) => {
    let totalItems = (((this.props.content
      .find((c) => c.name === name) || {})
      .items || [])
      .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
      .some((i) => i.items)
      ? [...new Set(this.props.content
          .find((c) => c.name === name).items
          .map((i) => i.items.filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }).flat())
          .flat().map(i => i.id))]
      : [...new Set(((this.props.content.find((c) => c.name === name) || {}).items || []).filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }))]
          );
    // debugger;
    return (this.props.selectedItems[name] || []).length > 0 &&
    (this.props.selectedItems[name] || []).length === totalItems.length;
  }

  getSelectAll = (name) => {
    return (
      <React.Fragment>
        {" "}
        <Checkbox
          onChange={(e) => this.selectAll(e)}
          item={{
            id: 0,
            description: "Todos",
            active: this.isAllSelected(name)
              
          }}
          name={name}
          className="bold"
        />
        <div className="separator" />
      </React.Fragment>
    );
  };
}

export default FilterBarContent;
