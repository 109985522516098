//@flow
import * as React from "react";
import RowD from "../../../shared/component/row-d";
import RadioInput from "../../../shared/controls/radio-input";
import styled from "styled-components";
// import ReactTooltip from "react-tooltip";
// import Tag from "../../../shared/controls/tag";
import { FILES_SERVER } from "../../../redux/api";

const ProviderAvailabilityRow = (props) => {
  return (
    <React.Fragment>
      <RowD {...props}>
        <RadioInput
          title=""
          onClick={props.onChange}
          selected={props.selectedProvider}
          value={props.id}
          name="selectedProvider"
        />
        <React.Fragment>
          <Logo
            src={
              props.fleet_provider.logo
                ? FILES_SERVER.concat(props.fleet_provider.logo)
                : "/images/interrogacion.svg"
            }
            alt=""
          />
          <Text>{props.fleet_provider.alias || props.fleet_provider.corporation_name}</Text>
        </React.Fragment>

        <Text>{props.fleet_provider.orbi_id}</Text>
        <Text>''</Text>
        <Text>''</Text>
      </RowD>
    </React.Fragment>
  );
};

export default ProviderAvailabilityRow;

const Logo = styled.img`
  width: 27px;
  height: 27px;
  margin-right: 10px;
  border-radius: 50%;
`;

const Text = styled.span`
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
`;
