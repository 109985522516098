//@flow
import * as React from "react";
import { ViewTitle, View } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import type { RouterHistory } from "react-router-dom";
import { AutoPageControls } from "../assemble-shipment/styled";
import Checkbox from "../../shared/controls/checkbox";
import SelectInput from "../../shared/controls/select-input";
import * as Utils from "./utils";
import * as Types from "./types";
import { AssembleGrid } from "../assemble/assemble-grid";
import { normalizeStr, groupFacilities, getTimeZoneInfo } from "../../shared/utils";
import { connect } from "react-redux";
import {
  readEntities,
  readCatalog,
  cancelRequest,
  readEntity,
  partialEntity,
} from "../../redux/api/actions";
import { getEntityItems, getEntityArgs } from "../../redux/api";
import { getAssembles } from "../assemble/assemble-utils";
import { getOrderDetailShipment } from "../shipment/detail/utils";
import ShipmentDetailDialog from "orbi_v2_frontend_components/lib/git/components/ShipmentDetail/ShipmentDetailDialog";
import { Marker } from "../../shared/icons/traffic";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { listNetworkApprovedInvitations } from "../../redux/reducers/company-reducer";
import AvailabilityModal from "./availability-modal";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";

// type StateToProps = {|
//   tripStatuses: any,
//   orderStatuses: any,
//   loading: boolean,
//   companyId: number,
//   facility: Types.DetailFacility,
//   companyTags: any[],
// |};

type State = {
  +lapse: number,
  +gridConfig: Types.GridConfig,
  +filterParams: Types.FilterParams,
  +textSearch: string,
  +currentAssembleId: number,
  +currentTimeline: any,
  +autopage: boolean,
  +lapse: 5000 | 10000 | 20000,
  +timer: any,
  +showTripDetailModal: boolean,
};
type Props = {
  history: RouterHistory,
};


let typingTimer;

class TrafficView extends React.Component<Props, State> {
  state = {
    gridConfig: Utils.defaultGridConfig,
    filterParams: Utils.DefaultFilters,
    buttonFilterName: "Aplicar",
    textSearch: "",
    currentAssembleId: 0,
    currentAssemble: 0,
    currentResources: [],
    selectedFacility: 0,
    currentAvailabilityDate: null,
    currentAvailabilityInserted: null,
    currentTimeline: {},
    lapse: 5000,
    autopage: false,
    timer: undefined,
    showTripDetailModal: false,
    showAvailabilityModal: false,
    sendEmailConfirmation: true,
    searchStr: "",
    // searchStr: "12273119",
  };

  componentDidMount() {
    // this.props.loadTransportClasses(this.props.companyId);
    this.props.loadTransportClassesFilter({ company_id: this.props.companyId });
    this.props.listFleets({
      company_id: this.props.companyId
    })
    this.props.loadFacilities({
      company_id: this.props.companyId
    });
    // this.loadAssemblies();
    this.props.loadMotives(
      { company_id: this.props.companyId, type: 1 },
      { alias: "vehicleMotives" }
    );
    this.props.loadMotives(
      { company_id: this.props.companyId, type: 2 },
      { alias: "equipmentMotives" }
    );
    this.props.loadMotives(
      { company_id: this.props.companyId, type: 3 },
      { alias: "operatorMotives" }
    );

    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });

    this.props.loadCompanyTransportClasses({
      company_id: this.props.companyId,
    });

    this.props.loadTagsTripCatalog({ company_id: this.props.companyId });
    this.props.loadTagsOrderCatalog({ company_id: this.props.companyId });
    this.props.loadCompanyFacilities({ company_id: this.props.companyId });

    this.props.setNavbarAction("traffic-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("traffic-control");
  }

  loadAssembliesTemp = (time = 300) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      () => {
        this.loadAssemblies();
      }, time);
  }

  loadAssemblies = () => {
    const { filterParams } = this.state;
    const params: Types.loadAssembliesParams = {
      company_id: this.props.companyId,
      search: this.state.searchStr,
      previous_assemblies: (this.props.fleetAssemblies || []).map(fA => fA.id),
      ...filterParams,
    };
    this.props.loadAssemblies(params, {
      args: { ...filterParams },
    });

    if (this.state.timer) clearTimeout(this.state.timer);
    if (this.state.autopage) {
      var timer = setTimeout(() => {
        const { limit, offset } = this.state.filterParams;

        const newOffset =
          offset * limit > this.props.assembliesCatalogCounter ? 1 : offset + 1;
        this.setState(
          { filterParams: { ...filterParams, offset: newOffset } },
          () => this.loadAssemblies()
        );
      }, this.state.lapse);
      this.setState({ timer });
    }
  };

  getLevelFilter = () => {
    return [
      {
        id: "1",
        description: "1",
      },
      {
        id: "2",
        description: "2",
      },
      {
        id: "3",
        description: "3",
      },
      {
        id: "4",
        description: "4",
      },
      {
        id: "5",
        description: "5",
      },
    ];
  };

  getSelectedFilters = () => {
    const {
      status,
      transport_classes,
      fleet_providers,
      priority,
      trip_tags,
      order_tags,
      from_facilities,
      to_facilities,
    } = this.state.filterParams;
    return {
      status,
      transport_classes,
      fleet_providers,
      priority,
      trip_tags,
      order_tags,
      from_facilities,
      to_facilities,
    };
  };

  render() {
    return (
      <View className="traffic-view">
        <ViewTitle>
          {"Control de tráfico"}
          <FilterBarCompact
            searchStr={this.state.searchStr}
            searchAction={this.handleChange}
            content={Utils.TrafficFilters(
              this.props.tripStatuses,
              this.props.networkCompanies,
              // this.props.companyTransportClasses,
              this.props.companyTransportClassesFilter,
              this.props.companyTagsTrip,
              this.props.companyTagsOrder,
              this.props.facilitiesByTag,
              this.getLevelFilter()
            )}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState(
                  {
                    buttonFilterName: "Restablecer"
                  },
                  () => { this.loadAssemblies(); }
                );
              } else {
                this.setState(
                  {
                    filterParams: Utils.DefaultFilters,
                    buttonFilterName: "Aplicar"
                  },
                  () => { this.loadAssemblies(); }
                );
              }
            }}
            onChange={(e) => {
              this.setState({
                filterParams: {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                },
                buttonFilterName: "Aplicar"
              });
            }}
            buttonFilterName={this.state.buttonFilterName}
            selectedItems={this.getSelectedFilters()}
          />
        </ViewTitle>
        <div style={{ display: "flex", backgroundColor: "black" }}>
          <AutoPageControls>{this.getAutoPagecontrols()}</AutoPageControls>
        </div>
        <AssembleGrid {...this.getAssembleGridProps()} />
        {this.state.showTripDetailModal ? (
          <ShipmentDetailDialog
            show={this.state.showTripDetailModal}
            {...this.state.currentOrder}
            tickets={this.state.currentOrder.tickets.map((t) => ({
              ...t,
              detailButton: (
                <Marker
                  fill="#3E4E6C"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    this.props.history.push(
                      `/company/controls/shipment/orderdetail/${this.state.currentOrder.id}?facilityId=${this.props.facilityId}`
                    );
                  }}
                />
              ),
            }))}
            action={[]}
            onClose={() =>
              this.manageDetailModal({
                target: {
                  name: "close",
                  value: undefined,
                },
              })
            }
          />
        ) : null}
        {
          this.state.showAvailabilityModal &&
          <AvailabilityModal 
            show={this.state.showAvailabilityModal}
            onChange={this.manageAvailabilityModal}
            data={{
              resources: this.state.currentResources,
              inserted: this.state.currentAvailabilityInserted,
              sendEmailConfirmation: this.state.sendEmailConfirmation
            }}
            facilities={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.facilities || [])}
            date={this.state.currentAvailabilityDate}
            facility={this.state.selectedFacility}
            isLoading={this.props.isLoadingAvailabilityEdition}
          />
        }
        <PaginatorControlled
          limit={this.state.filterParams.limit}
          offset={this.state.filterParams.offset}
          onChange={(e) =>
            this.setState(
              {
                filterParams: {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                },
              },
              () => {
                // debugger;
                // this.loadAssemblies();
                this.loadAssembliesTemp();
              }
            )
          }
          itemCount={this.props.assembliesCatalogCounter}
        />
      </View>
    );
  }

  manageDetailModal = () => {};
  getDetailTimelineActions = (timeline) => {
    return timeline;
  };
  getAssembleActions = () => {};

  getAutoPagecontrols = () => {
    return (
      <React.Fragment>
        <Checkbox
          onChange={(e) => {
            this.setState({ autopage: !this.state.autopage }, () =>
              // this.loadAssemblies()
              this.loadAssembliesTemp()
            );
          }}
          item={{
            description: "Autopaginado",
            active: this.state.autopage,
            id: 0,
          }}
          name="autopage"
          active={this.state.autopage}
        />
        <SelectInput
          label=""
          name="limit"
          value={this.state.filterParams.limit}
          onChange={(e) =>
            this.setState(
              {
                filterParams: {
                  ...this.state.filterParams,
                  limit: e.target.value,
                },
              },
              () => /* this.loadAssemblies() */this.loadAssembliesTemp()
            )
          }
          items={[
            {
              id: 10,
              description: "10",
            },
            {
              id: 25,
              description: "25",
            },
          ]}
        />
        <SelectInput
          label=""
          name="lapse"
          value={this.state.lapse}
          onChange={(e) => {
            // debugger;
            this.setState(
              {
                lapse: e.target.value,
              },
              () =>/*  this.loadAssemblies() */this.loadAssembliesTemp()
            )
          }}
          items={[
            {
              id: 5000,
              description: "5 seg.",
            },
            {
              id: 10000,
              description: "10 seg.",
            },
            {
              id: 30000,
              description: "30 seg.",
            },
          ]}
        />
      </React.Fragment>
    );
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "gridConfigSelect":
        this.setState(
          {
            gridConfig: {
              columnQuantity: parseInt(value)*4,
              columnRangeType: value.includes("H") ? "HOUR" : "DAY",
              columnSections: 4,
              startDatetime: value.includes("H") ? Utils.dateRemoveHours(Utils.hour, parseInt(value)) : Utils.dateRemoveHours(Utils.today, parseInt(value)*24),
              className: "",
              extended: true,
              scrollable: true,
            },
          },
          () => {
            this.handleChange({
              target: {
                name: "gridDateChange",
                value: 0,
              },
            });
          }
        );
        break;
      case "gridDateChange":
        let c = { ...this.state.gridConfig };
        let f = { ...this.state.filterParams };
        c.startDatetime =
          c.columnRangeType === "DAY"
            ? Utils.dateAddDays(c.startDatetime, e.target.value)
            : Utils.dateAddHours(c.startDatetime, e.target.value);
        f.start_date = c.startDatetime.toISOString();
        f.end_date =
          c.columnRangeType === "DAY"
            ? Utils.dateAddDays(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString()
            : Utils.dateAddHours(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString();
        this.setState({ gridConfig: c });
        // const { cancelRequest } = this.props;
        this.setState({ filterParams: f }, () => {
          this.props.cancelRequest("COMPANIES.TRAFFIC");
          // this.props.cancelRequest("COMPANIES.FLEETCATALOG.COUNTERS");
          this.loadAssemblies();
        });
        break;

      case "searchStr":
        this.setState({ [name]: value }, () => this.loadAssembliesTemp(700));
        break;

      default:
        this.setState({ [name]: value });
    }
  };

  getAssembleGridProps = () => ({
    gridConfigChange: this.handleChange,
    dateChange: this.handleChange,
    gridConfig: this.state.gridConfig,
    counters: {
      operators: this.props.fleetCatalogCounters.unassembled_operators,
      vehicles: this.props.fleetCatalogCounters.unassembled_vehicles,
      equipments: this.props.fleetCatalogCounters.unassembled_equipments,
    },
    rows: this.props.fleetAssemblies
      .filter((assemble: Assemble) =>
        assemble.resources.find(
          (resource: Resource) =>
            normalizeStr(resource.longDescription).includes(
              normalizeStr(this.state.textSearch)
            ) && resource.assembled
        )
      )
      .map((assemble) => ({
        ...assemble,
        actions: assemble.detail.find((detail) =>
          detail.timeLine.find((timeline) => timeline.assembled) ? true : false
        )
          ? this.getAssembleActions(assemble)
          : undefined,

        detail: assemble.detail.map((detail) => ({
          ...detail,
          timeLine: detail.timeLine.map((timeline) =>
            this.getDetailTimelineActions(timeline)
          ),
        })),
        // availability: assemble.availability 
        //   ? {
        //       ...assemble.availability,
        //       // item: React.cloneElement(assemble.availability.item, {
        //         onClick: () => {
        //           this.manageAvailabilityModal({
        //             target: { 
        //               name: "open", 
        //               value: {
        //                 id: assemble.id, 
        //                 facility: assemble.availability.availability_facility.id,
        //                 availability: assemble.availability.availability,
        //                 inserted: assemble.availability.inserted,
        //                 resources: assemble.resources
        //               } 
        //             },
        //           });
        //         }
        //       // })
        //     }
        //   : null,
        layerItems: assemble.layerItems.map((li) => ({
          ...li,
          item: React.cloneElement(li.item, {
            fullCard:
              this.state.gridConfig.columnRangeType !== "DAY" &&
              parseInt(li.width) > 3,
            microCard: parseInt(li.width) < 5,
            onClick: () => {
              this.manageDetailModal({
                target: { name: "open", value: { ...li, tripId: li.id } },
              });
            },
          }),
        })),
      })),
    summary: undefined,
  });

  manageAvailabilityModal = (e) => {
    const { name, value } = e.target;
    switch(name){
      case "sendEmailConfirmation":
        this.setState({
          sendEmailConfirmation: value
        });
        break;
      case "open":
        this.setState({
          showAvailabilityModal: true,
          currentAssemble: value.id,
          currentResources: value.resources,
          selectedFacility: value.facility,
          currentAvailabilityDate: value.availability,
          currentAvailabilityInserted: value.inserted,
          sendEmailConfirmation: true
        })
        break;
      case "facilitySelect":
        // debugger;
        this.setState({selectedFacility: value})
        break;
      case "currentAvailabilityDate":
        // debugger;
        this.setState({currentAvailabilityDate: value})
        break;
      case "saveAvailability":
        let date = new Date(this.state.currentAvailabilityDate);
        date = date.toISOString();
        debugger;
        this.props.editAvailability({
          company_id: this.props.companyId,
          fleet_id: (this.props.companyFleets[0] || {}).id || 0,
          assembly_id: this.state.currentAssemble,
          availability_date: date,
          availability_facility: this.state.selectedFacility,
          send_email: this.state.sendEmailConfirmation,
          timezone: getTimeZoneInfo()
        }, {
          onSuccess: () => {
            this.setState({
              showAvailabilityModal: false,
              currentAssemble: 0,
              currentResources: [],
              selectedFacility: 0,
              currentAvailabilityDate: null,
              currentAvailabilityInserted: null,
            });
            this.loadAssemblies();
          }
        })
        break;
      case "close":
      case "cancel":
        this.setState({
          showAvailabilityModal: false,
          currentAssemble: 0,
          currentResources: [],
          selectedFacility: 0,
          currentAvailabilityDate: null,
          currentAvailabilityInserted: null,
        })
        break;
      default:
    }
  }

  manageDetailModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        this.props.loadTrip(
          {
            company_id: this.props.companyId,
            trip_id: value.tripId,
            view: "control",
          },
          {
            onSuccess: (trip) => {
              this.setState({
                showTripDetailModal: true,
                currentTrip: value,
                currentOrder: getOrderDetailShipment(trip, {
                  transportClasses: this.props.transportClasses,
                  tags: this.props.companyTags,
                  statusId: value.statusId,
                  facilityId: this.props.facilityId,
                }),
                card: value,
              });
            },
          }
        );
        break;
      case "close":
      case "cancel":
        this.setState({
          showTripDetailModal: false,
          // showCancelAppointmentModal: false,
          // showCancelTripModal: false,
          // showCancelBookingModal: false,
          // showBookingModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;

      default:
    }
  };
}

const mapStateToProps = (state) => {
  const isLoadingAvailabilityEdition = state.api["COMPANIES.TRAFFIC.AVAILABILITY"]?.status?.isFetching || false;
  const fleetVehicleTypesClasses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.VEHICLETYPES"
  ).map((vt) => ({
    ...vt,
    classes: getEntityItems(state, "COMPANIES.CATALOGS.FLEETCLASSES").filter(
      (fc) => fc.type === vt.id
    ),
    icon:
      Utils.VEHICLE_TYPE_ICON_SELECTOR[
        vt.abbreviation || (vt.description === "Dolly" ? "D" : undefined)
      ],
  }));
  const motives = {
    "1": getEntityItems(state, "vehicleMotives"),
    "2": getEntityItems(state, "equipmentMotives"),
    "3": getEntityItems(state, "operatorMotives"),
  };
  const fleetAssembliesArgs = getEntityArgs(state, "COMPANIES.TRAFFIC");
  const fleetCatalogCounters = state.api["COMPANIES.TRAFFIC"]?.count || 0;
  const fleetAssemblies = getAssembles(
    getEntityItems(state, "COMPANIES.TRAFFIC"),
    Date.parse(fleetAssembliesArgs.start_date),
    Date.parse(fleetAssembliesArgs.end_date),
    fleetVehicleTypesClasses,
    motives
  );
  const companyFleets = getEntityItems(state, "COMPANIES.FLEETS");
  const companyId = state.globalCatalog.session.company.id;
  const assembliesCatalogCounter = state.api["COMPANIES.TRAFFIC"]?.count || 0;

  const tripStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.TRIPSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");

  const companyTransportClasses = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS"
  ).map((f) => ({ description: f.code, id: f.id }));

  const companyTransportClassesFilter = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.transport_classes.map((tc) => ({
      id: tc.id,
      description: tc.code,
    })),
  }));

  const companyTagsTrip = getEntityItems(state, "tagsTrips");
  const companyTagsOrder = getEntityItems(state, "tagsOrder");
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");

  const facilitiesByTag = groupFacilities(companyFacilities);

  let ownCompanyId = state.globalCatalog.session.company.id;
  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;

  let oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;

  let networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        let id = companyProviders.find(
          (provider) => provider.id === network.id
        );
        if (network.from_companies.id === ownCompanyId) {
          if (network.to_companies.type === oppositeCompanyType) {
            result.push({
              id: String(id?.fleet_provider?.fleet_provider_id),

              description: network.to_companies.orbi_id.concat(
                " - ",
                network.to_companies_alias,
                " - ",
                network.to_companies_code
              ),
            });
          }
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              // id: network.id,
              id: String(id?.fleet_provider?.fleet_provider_id),
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
            });
        }
        return result;
      },
      []
    );

  let facilities = ((getEntityItems(state, "COMPANIES.ORDERS.FACILITIES") || [])["facilities"] || [])
      .map(f => ({
        label: `${f.name} - ${f.code} - ${f.alias}`,
        value: f.id
      }))
  return {
    isLoadingAvailabilityEdition,
    companyFleets,
    assembliesCatalogCounter,
    fleetCatalogCounters,
    fleetAssemblies,
    companyId,
    tripStatuses,
    companyProviders,
    companyTransportClasses,
    companyTransportClassesFilter,
    companyTagsTrip,
    companyTagsOrder,
    companyFacilities,
    networkCompanies,
    facilitiesByTag,
    facilities
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.VEHICLETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.FLEETCLASSES"));
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  dispatch(readEntities("COMPANIES.CATALOGS.OPERATORLICENSETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));

  const loadTagsTripCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "9" },
        { args: { ...params }, ...opts, alias: "tagsTrips" }
      )
    );

  const loadTagsOrderCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "7" },
        { args: { ...params }, ...opts, alias: "tagsOrder" }
      )
    );

  dispatch(listNetworkApprovedInvitations("", ""));

  return {
    loadFacilities: (params, opt) =>
      dispatch(readEntities("COMPANIES.ORDERS.FACILITIES", params, opt)),
    editAvailability: (params, opt) =>
      dispatch(partialEntity("COMPANIES.TRAFFIC.AVAILABILITY", params, opt)),
    loadMotives: (
      params: { company_id: number, type: 1 | 2 | 3 },
      opts: ApiOptions
    ) =>
      dispatch(readCatalog("COMPANIES.FLEETS.CATALOG.MOTIVES", params, opts)),
    loadTransportClasses: (company_id, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id }, opts)),
    loadTransportClassesFilter: (params, opts) =>
      dispatch(
        readEntities(
          "COMPANIES.TRANSPORTCLASS.FILTERS",
          { ...params, view: "grouped" },
          opts
        )
      ),
    loadAssemblies: (params, opts) =>
      dispatch(readCatalog("COMPANIES.TRAFFIC", params, opts)),
    listFleets: (params, opts) =>
      dispatch(readEntities("COMPANIES.FLEETS", params, opts)),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    loadTrip: (
      params: { company_id: number, trip_id: number },
      opts: ApiOptions
    ) => dispatch(readEntity("COMPANIES.TRIPS", { ...params }, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyTransportClasses: (params, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", params, opts)),
    loadTagsTripCatalog,
    loadTagsOrderCatalog,
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrafficView);
