import React from "react";
import Tag from "../controls/tag";
import ExceedPlus from "../controls/exceed-plus";
import { getUrlsEnv } from "../backend-api";
import ReactTooltip from "react-tooltip";
import { Flex, flexOpts } from "../styled/flex";
import { FILES_SERVER } from "../../redux/api/api-urls";
import UserInfo from "./user-info";
import { getLastSession, LOG_FORMAT, LOCK_FORMAT } from "../utils";
import SubStringText from "./substring-text";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
import { Fragment } from "react";
import styled from 'styled-components';
import Loader from '../icons/tat/loader';

class FleetRow extends React.Component {
  // toggleSelected = () => {
  //     this.props.selectItem(this.props.itemInfo.id, !this.props.isSelected);
  // }

  componentDidMount() {
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  handlePageContentScroll = (e) => {
    ReactTooltip.hide();
  }

  getValidationIcon = (type) => {
    switch (type) {
      case "Tarjeta de circulación":
      case "Licencia": //
        return "/images/credential.svg";
      case "Póliza de seguro":
        return "/images/shield.svg";
      case "Autorización expresa":
        return "/images/x2Sign.svg";
      case "Certificado de condición físico - mecánico":
      case "Certificado de condición físico-mecánico":
        return "/images/wrench.svg";
      case "Dictamen de baja emisión de contaminantes":
        return "/images/pollution-icon.svg";
      case "Seguridad Social": //
        return "/images/medRecords.svg";
      case "Constancia de aptitud psico-física": //
        return "/images/heart.svg";
      default:
        return "/images/help.png";
    }
  };
  getValidationValueIcon = (value) => {
    switch (String(value)) {
      case "1":
        return (
          <div className="pending">
            <img alt="" src="/images/info_pendiente.svg"></img>
          </div>
        );
      // return (<div className="pending">&#65281;</div>);
      case "2":
        return (
          <div className="validated">
            <img alt="" src="/images/oval green.svg"></img>
          </div>
        );
      // return (<div className="validated">&#10004;</div>);
      case "3":
        return (
          <div className="rejected">
            <img alt="" src="/images/oval red.svg"></img>
          </div>
        );
      // return (<div className="rejected">&#10799;</div>);
      case "4":
        return (
          <div className="nodata">
            <img alt="" src="/images/interrogacion.svg"></img>
          </div>
        );
      // return (<div className="nodata">&#9866;</div>);
      case "5":
        return (
          <div className="defeated">
            <img alt="" src="/images/interrogacion_red.svg"></img>
          </div>
        );
      // return (<div className="defeated">&#9866;</div>);
      default:
        return "";
    }
  };
  handleClick = () => {
    this.props.onClick({
      target: { name: this.props.id, value: !this.props.isSelected },
    });
  };
  getAvatarUrl = (route, isCompany) => {
    if (route) {
      return getUrlsEnv().files.concat(route);
    }
    if (isCompany) {
      return COMPANY_IMAGE;
    }
    switch (this.props.itemType.id || 0) {
      case 1:
        return VEHICLE_IMAGE;
      case 2:
        return EQUIPMENT_IMAGE;
      case 3:
        return OPERATOR_IMAGE;
      default:
        return DEFAULT_IMAGE;
    }
  };

  showEditSanction = () => {
    return this.props.addSanction !== null && this.props.editSanction !== null && this.props.delSanction !== null;
  }

  render() {
    let cont = 1;
    const companyType = this.props.companyType || 0;
    let nextToLock = [];
    let locked = [];
    if(companyType === 1 && this.props.itemInfo.sanctions !== null){
      nextToLock = this.props.itemInfo.sanctions.filter((s) => !s.locked)
      locked = this.props.itemInfo.sanctions.filter((s) => s.locked)
      // console.log(this.props.itemInfo.name);
      // console.log("nextToLock ", nextToLock);
      // console.log("locked ", locked);
      // console.log("///////////////////////////////////////////////");
    }
    return (
      <div
        className={"row fleet-row ".concat(
          this.props
            .className /* this.props.selectionModeOn ? ' selection-on' : '', this.props.isSelected ? ' selected' : '' */
        )}
        onClick={this.handleClick}
      >
        <div className={"overlay"} /*  onClick={this.toggleSelected} */>
          <div className="checkbox"></div>
        </div>
        <div className="avatars" style={{padding: 0}}>
          <img
            alt=""
            src={`${this.getAvatarUrl(
              this.props.itemInfo.avatar
            )}`}
            style={{ backgroundColor: "#FFF", position: 'absolute', top: 20, left: 20}}
          />
          <img
            alt=""
            src={`${this.getAvatarUrl(
              this.props.itemInfo.company_avatar,
              -1
            )}`}
            className="company-logo"
            style={{ backgroundColor: "#1F8EFA" }}
          />
        </div>
        <div className="credentials">
          {this.props.itemType.id === 3 ? (
            <SubStringText
              label={this.props.itemInfo.name}
              labelTag={"p"}
              labelSize={30}
              labelClass={"id"}
              tooltipClass={"tooltip-class-" + this.props.itemInfo.name}
            />
          ) : (
            // <p className="id">{this.props.itemInfo.name}</p>
            <>
              <p className="id">
                {this.props.itemInfo.economic_number}{" "}
                {this.props.itemInfo.plates}
              </p>
              <p className="description">
                {this.props.itemInfo.fleet_data_type}
              </p>
            </>
          )}
          <p className="company-name">{this.props.itemInfo.company_name}</p>
        </div>
        <div className="normativity">
          {this.props.itemInfo.validations.map((validation) => {
            return (
              (validation.isConsidered || validation.isConsidered === "") && (
                <React.Fragment>
                  <div
                    key={validation.type.concat(" ", cont++)}
                    className="validation-icon-container"
                    data-tip=""
                    data-for={`normativity-tooltip-${
                      this.props.itemInfo.id
                    }-${validation.type.concat(" ", cont)}`}
                  >
                    <React.Fragment>
                      <img
                        src={this.getValidationIcon(validation.type)}
                        alt=""
                      />
                      {this.getValidationValueIcon(validation.value)}
                    </React.Fragment>
                  </div>
                  <ReactTooltip
                    id={`normativity-tooltip-${
                      this.props.itemInfo.id
                    }-${validation.type.concat(" ", cont)}`}
                    place="bottom"
                    type="light"
                    effect="solid"
                    delayHide={250}
                    clickable={true}
                    className="cardToolTip"
                    getContent={() => {
                      let data = this.getContent(validation);
                      if (data.length) {
                        return <div className="cardToltipContent">{data}</div>;
                      } else {
                        return false;
                      }
                    }}
                    // style={{maxWidth: "380px", fontSize: "16px"}}
                    //   event="focusin"
                    //   eventOff="focusout"
                  />
                </React.Fragment>
              )
            );
          })}
        </div>
        <div className={"validation ".concat(this.props.itemInfo.validated)}>
          {this.props.itemInfo.validated}
        </div>
        {
          //style ={{width: this.props.itemType.id === 3 ? "15%" : "20%" }}
          (this.props.companyType === 1) && <div className="tags"> 
            <ExceedPlus maxVisibleItems={3} name={"tags ".concat(this.props.id)}>
              {this.props.itemInfo.tags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    title={tag.title}
                    color={tag.color}
                  />
                );
              })}
            </ExceedPlus>
          </div>
        }
        
        {
          (this.props.itemType.id === 3) ? <div className="rfc">
            {this.props.taxInfo !== '' ? <div style={{backgroundColor: '#05c985', borderRadius: '50%', height: 17, width: 17}} className="indicator approved">&#10004;</div> : ''}
          </div> : ''
        }
        
        {this.props.itemType.id === 3 ?
          companyType === 1 ? 
            <div className="operativeStatus">
              <div className={"validation "} style={(nextToLock.length > 0 || locked.length > 0) ? {padding: "16px"} : {}}>
                { locked.length === 0 && <span style={{ color: "#05c985" }}>Activo</span> }
                { nextToLock.length > 0 && 
                  <Fragment>
                    <Flex
                      style={{justifyContent: "center"}}
                      direction={flexOpts.direction.column}
                      data-tip
                      data-for={"tooltip-shared-icon".concat(this.props.itemInfo.id).concat('next')}
                    >
                      <div className={"share-text"}>
                        Próxima Capacitación
                      </div>
                    </Flex>
                    <ReactTooltip
                      className="truck-tooltip"
                      id={"tooltip-shared-icon".concat(this.props.itemInfo.id).concat('next')}
                      clickable={true}
                      delayHide={600}
                      delayShow={200}
                      delayUpdate={500}
                      place={"bottom"}
                      border={true}
                      type={"light"}
                      effect="solid"
                    >
                      <Flex direction={flexOpts.direction.column}>
                        {nextToLock?.map((cmp) => (
                          <Flex>
                            <img
                              alt=""
                              style={{
                                borderRadius: "20px",
                                width: "40px",
                                height: "40px",
                                margin: "5px",
                              }}
                              src={FILES_SERVER.concat(
                                cmp?.cargo_owner?.logo ?? ''
                              )}
                            />
                            <Flex
                              direction={flexOpts.direction.column}
                              alignItems={flexOpts.alignItems.flexStart}
                            >
                              <span>
                                <b>{cmp.cargo_owner ? cmp.cargo_owner.corporation_name || cmp.cargo_owner.alias || "" : ''}</b>
                              </span>
                              <br/>
                              <span className=''>
                                {(getLastSession(cmp.locked_from, LOCK_FORMAT) + " - " + getLastSession(cmp.locked_to, LOCK_FORMAT))}
                              </span>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    </ReactTooltip>
                  </Fragment> 
                }
                { locked.length > 0 && 
                  <Fragment>
                    <Flex 
                      style={{justifyContent: "center"}}
                      data-tip
                      data-for={"tooltip-shared-icon".concat(this.props.itemInfo.id).concat('locked')}
                    >
                      <div className={"share-text"}>
                        <span className='' style={{color: "#ff4949"}}>En capacitación</span>
                      </div>
                    </Flex>
                    <ReactTooltip
                      className="truck-tooltip"
                      id={"tooltip-shared-icon".concat(this.props.itemInfo.id).concat('locked')}
                      clickable={true}
                      delayHide={600}
                      delayShow={200}
                      delayUpdate={500}
                      place={"bottom"}
                      border={true}
                      type={"light"}
                      effect="solid"
                    >
                      <Flex direction={flexOpts.direction.column}>
                        {locked?.map((cmp) => (
                          <Flex>
                            <img
                              alt=""
                              style={{
                                borderRadius: "20px",
                                width: "40px",
                                height: "40px",
                                margin: "5px",
                              }}
                              src={FILES_SERVER.concat(
                                cmp?.cargo_owner?.logo ?? ''
                              )}
                            />
                            <Flex
                              direction={flexOpts.direction.column}
                              alignItems={flexOpts.alignItems.flexStart}
                            >
                              <span>
                                <b>{cmp.cargo_owner ? cmp.cargo_owner.corporation_name || cmp.cargo_owner.alias || "" : ''}</b>
                              </span>
                              <br/>
                              <span className=''>
                                {(getLastSession(cmp.locked_from, LOCK_FORMAT) + " - " + getLastSession(cmp.locked_to, LOCK_FORMAT))}
                              </span>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    </ReactTooltip>
                  </Fragment> 
                }
              </div>
            </div> : 
            companyType === 2 ? 
              <div className="operativeStatus">
                <div className={"validation "} style={(this.props.itemInfo.sanctions !== null && !this.props.itemInfo.sanctions.locked) ? {padding: "16px"} : {}}>
                    <span style={{color: (this.props.itemInfo.sanctions == null || !this.props.itemInfo.sanctions.locked) ? "#05c985" : "#ff4949"}}>
                      {(this.props.itemInfo.sanctions == null || !this.props.itemInfo.sanctions.locked) ? "Activo" : "En capacitación"}
                    </span>
                    {(this.props.itemInfo.sanctions !== null && this.props.itemInfo.sanctions.locked) && <br/>}
                    {(this.props.itemInfo.sanctions !== null && this.props.itemInfo.sanctions.locked) && 
                      <span className=''>
                        {(getLastSession(this.props.itemInfo.sanctions.locked_from, LOCK_FORMAT) + " - " + getLastSession(this.props.itemInfo.sanctions.locked_to, LOCK_FORMAT))}
                      </span>}
                    {(this.props.itemInfo.sanctions !== null && !this.props.itemInfo.sanctions.locked) && 
                        <Fragment>
                          <br/>
                          <span className=''>Próxima Capacitación:</span>
                          <br/>
                          <span className=''>
                              { getLastSession(this.props.itemInfo.sanctions.locked_from, LOCK_FORMAT) + " - " + getLastSession(this.props.itemInfo.sanctions.locked_to, LOCK_FORMAT) }
                          </span>
                      </Fragment>
                    }
                </div>
              </div> : 
            <Fragment/> :
          <Fragment/>
        }

        {
          (
            this.props.itemType.id === 3 &&
            companyType === 2 &&
            this.showEditSanction()
          ) &&
          <div
            style={{
              display: 'flex',
              width: 30
            }}
          >
            {
              this.props.addSanction !== null && this.props.editSanction !== null && this.props.delSanction !== null ?
              <ActionsTricolon
                tooltipPlacement={"left"}
                actions={this.mapActions({
                  add: (e) => this.props.addSanction(e),
                  edit: (e) => this.props.editSanction(e),
                  del: (e) => this.props.delSanction(e),
                })
              }/> : <Fragment/>
            }
          </div>

        }

        {
          <div className="gps">
            <div className="content">
              { 
                (this.props.itemType.id !== 3) ? <span
                  className={"gps-badge".concat(
                    this.props.itemInfo.hasGps ? " active" : ""
                  )}
                >
                  GPS
                </span> : '' 
              }
              {this.props.itemInfo.shared && this.props.itemInfo.shared > 0 ? (
              <div style={{display: 'block'}}>
              {/* <React.Fragment> */}
                <div 
                  data-tip
                  data-for={"tooltip-shared-icon".concat(this.props.itemInfo.id)}
                  onMouseEnter={(e) => {
                    ReactTooltip.hide();
                    ReactTooltip.show(e.currentTarget);
                  }}
                >
                  <Flex>
                    <div className={"share-text"}>
                      {this.props.itemInfo.shared}
                    </div>
                    <div className={"share-icon"}>
                      <img src="/images/share.svg" alt="" />
                    </div>
                  </Flex>
                </div>
                <ReactTooltip
                  className="truck-tooltip"
                  id={"tooltip-shared-icon".concat(this.props.itemInfo.id)}
                  delayHide={20}
                  delayShow={20}
                  delayUpdate={200}
                  place={"left"}
                  border={true}
                  type={"light"}
                  effect="solid"
                  clickable={true}
                  event={'click'}
                  eventOff={'click'}
                >
                  <div>
                    {this.getTooltipContent()}
                  </div>
                </ReactTooltip>
              {/* </React.Fragment> */}
              </div>
            ) : (
              ""
            )}
          </div>
          </div>
        }

      </div>
    );
  }

  mapActions = (
    actionMethods: {
      edit: () => void,
      add: () => void,
      del: () => void,
    }
  ) => {
    let actions = [];

    if(this.props.itemInfo.sanctions == null && this.props.addSanction !== null){
      actions.push(
        {
          type: "extra",
          // className: "secondary",
          description: "Bloquear operador",
          action: () => actionMethods.add(),
        }
      )
    } else {
      if(this.props.editSanction !== null){
        actions.push(
          {
            type: "extra",
            // className: "secondary",
            description: "Editar bloqueo operativo",
            action: () => actionMethods.edit(),
          }
        )
      }
      if(this.props.delSanction !== null){
        actions.push(
          {
            type: "extra",
            // className: "secondary",
            description: "Eliminar bloqueo operativo",
            action: () => actionMethods.del(),
          },
        )
      }
    }
    return actions;
  };

  getTooltipContent = () => {
    return(
      <Flex 
        direction={flexOpts.direction.column}
      >
        {this.props.itemInfo.network?.map((cmp) => (
          <Flex>
            <img
              alt=""
              style={{
                borderRadius: "20px",
                width: "40px",
                height: "40px",
                margin: "5px",
              }}
              src={FILES_SERVER.concat(
                cmp.logo || this.props.itemInfo.company_avatar
              )}
            />
            <Flex
              direction={flexOpts.direction.column}
              alignItems={flexOpts.alignItems.flexStart}
            >
              <span>
                <b>{cmp.corporation_name || cmp.name || ""}</b>
              </span>
              <span>{cmp.alias || ""}</span>
              <span>{cmp.code || ""}</span>
              
              {
                (this.props.companyType === 1) &&
                <div 
                  id='unshare-operator-action'
                  style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
                  onClick={(e) => {
                    if (this.props.unshareItem) {
                      this.props.unshareItem({
                        company_alias: cmp.alias || '',
                        company_id: cmp.id || '',
                        fleet_provider_id: cmp.fleet_provider_id || '',
                        data_id: this.props.itemType.id === 3 ? this.props.itemInfo.fleet_data_id || 0 : this.props.itemInfo.fleet_catalog_id || 0 // cuando es operador se retorna el id del fleet_data y si es vehiculo o equipo se retorna el id del catalogo
                      })
                    }

                    e.stopPropagation();
                    ReactTooltip.hide();
                  }}
                >Dejar de compartir</div>
              }

            </Flex>
            {/* <Flex>
              {
                this.props.isLoadingUnshare ? <Loader width="16px" height="16px" circleColor={'black'}/> :
                <LinkText
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (this.props.unshare) {
                      if (this.props.itemType.id === 3) {

                        this.props.unshare({
                          fleet_data_id: this.props.itemInfo.fleet_data_id,
                          company: cmp.id,
                          fleet_id: this.props.itemInfo.fleetId
                        });

                      } else {

                        this.props.unshare({
                          fleet_data_id: this.props.itemInfo.fleet_data_id,
                          company: cmp.id,
                          fleet_id: this.props.itemInfo.fleetId
                        });

                      }
                    }
                  }}
                >
                  dejar de compartir
                </LinkText>
              }
            </Flex> */}
          </Flex>
        ))}
      </Flex>
    );
  }

  getContent = (validation) => {
    const {
      itemInfo: { shared = 0 },
    } = this.props;

    let content = (this.props.itemInfo.content || []).filter(
      (item) =>
        item.object_id &&
        item.object_id.type &&
        item.object_id.type.id === validation.id
    );

    let companyAdded = [];
    // eslint-disable-next-line
    return content.map((item, index) => {
      const { company = { orbi_id: "", logo: "" } } = item;

      if (shared === -1) {
        if (index === 0) {
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} - ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${item.comment} - ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      } else {
        if (!companyAdded.includes(company.orbi_id)) {
          companyAdded.push(company.orbi_id);
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} - ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${item.comment} - ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      }
    });
  };
}

export default FleetRow;

FleetRow.defaultProps = {
  itemInfo: {
    id: "T-380",
    plates: "657-EF-8",
    model: "T600",
    brand: "Kenworth",
    year: "2005",
    company_name: "Transporte Mextravel",
    avatar: "/images/help.png",
    company_avatar: "/images/help.png",
    hasGps: false,
    validated: 0,
    validations: [],
    tags: [
      { tag: { title: "Etiqueta 1" } },
      { tag: { title: "Etiqueta 2" } },
      { tag: { title: "Etiqueta 3" } },
    ],
  },
  itemType: { id: 0, description: "" },
  onClick: () => {
    console.log("Not yet implemented");
  },
};

// const VALIDATED_MESSAGE = {
//   Rechazado: "Rechazado",
//   Pendiente: "Pendiente",
//   Validado: "Validado",
//   Incompleto: "Incompleto",
//   "Sin documento": "Sin documento",
// };

const DEFAULT_IMAGE = "/images/truck.svg";
const VEHICLE_IMAGE = "/images/truck.svg";
const EQUIPMENT_IMAGE = "/images/remolque.svg";
const OPERATOR_IMAGE = "/images/Artboard.svg";
const COMPANY_IMAGE = "/images/modal-factory-icon.svg";


export const LinkText = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: black;
  font-family: Overpass !important;
  font-size: 15px;
  height: 16px;
`