import React, { Component } from "react";
import { ClassesHeader } from "../style";

class RouteClassesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { config } = this.props;
    return (
      <React.Fragment>
        <ClassesHeader>
          {config.map((item, key) => {
            return (
              <div style={{ width: item.width }} key={key}>
                {item.headerText}
              </div>
            );
          })}
        </ClassesHeader>
      </React.Fragment>
    );
  }
}

export default RouteClassesHeader;

RouteClassesHeader.defaultProps = {};
