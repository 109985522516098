import React from "react";
import { connect } from "react-redux";
import { createEntity, deleteEntity, getEntityItems, partialEntity, readEntities } from "../../redux/api";
import { loadCompanyUsers } from "../../redux/reducers/company-reducer";
import { groupFacilities } from "../../shared/utils";
import CompanyAlertRow from "./company-alert-row";

class AlertListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInviteModal: false,
      alerts: []
    };
  }

  componentDidMount() {
    if(this.props.companyType === 1){
      this.props.loadCompanyTags({ company_id: this.props.companyId });
    } else {
      this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    }
    this.props.listAlertCatalog({
      company_id: this.props.companyId
    }, {
      onSuccess: async result => {
        await asyncForEach(result, async (alert) => {
          if(this.props.companyType === 1){
            this.props.listAlertBaseGroups({
              company_id: this.props.companyId,
              alert_id: alert.id
            }, {
              onSuccess: r => {
                this.setState({
                  alerts: this.state.alerts.concat([{
                    ...alert,
                    groups: r.map(g => ({
                      ...g, 
                      edit: false, 
                      group_detail: g.group_detail.map(gD => ({
                        ...gD,
                        user_detail: {
                          roles: gD.user_detail.roles || [],
                          users: gD.user_detail.users.concat((gD.user_detail.roles || []).map(rol => ((this.props.companyUsers || []).find(cU => cU.id === rol) || {items: []}).items.map(i => i.id)).flat())
                        }
                      }))
                    })),
                    originalGroups: r.map(g => g.id)
                  }])
                })
              },
              onError: () => {
                this.setState({
                  alerts: this.state.alerts.concat([{
                    ...alert,
                    groups: [],
                    originalGroups: []
                  }])
                })
              }
            })
          } else {
            this.props.listAlertGroups({
              company_id: this.props.companyId,
              alert_id: alert.id
            }, {
              onSuccess: r => {
                this.setState({
                  alerts: this.state.alerts.concat([{
                    ...alert,
                    groups: r.map(g => ({
                      ...g, 
                      edit: false, 
                      group_detail: g.group_detail.map(gD => ({
                        ...gD,
                        user_detail: {
                          roles: gD.user_detail.roles,
                          users: gD.user_detail.users.concat(gD.user_detail.roles.map(rol => ((this.props.companyUsers || []).find(cU => cU.id === rol) || {items: []}).items.map(i => i.id)).flat())
                        }
                      }))
                    })),
                    originalGroups: r.map(g => g.id)
                  }])
                })
              }
            })
          }
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {
          this.state.alerts.sort((a, b) => a.id - b.id).map((alert, i) => (
            <CompanyAlertRow 
              key={'alert-'.concat(i)}
              alert={alert}
              onAlertDataChange={this.handleAlertDataChange}
              onAlertGroupDataChange={this.handleAlertGroupDataChange}
              facilities={this.props.facilitiesByTag}
              baseTags={this.props.baseTags}
              takenFacilities={(alert.groups || []).map(g => g.facilities || []).flat()}
              takenBases={(alert.groups || []).map(g => g.bases || []).flat()}
              users={this.props.companyUsers}
              companyType={this.props.companyType}
            />
          ))
        }
      </React.Fragment>
    );
  }

  handleAlertDataChange = (e, type, alertId) => {
    let alerts = [];
    let alertIndex = 0;
    switch(type) {
      case "active":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        if(this.props.companyType === 1){
          this.props.updateAlertBase({
            company_id: this.props.companyId,
            alert_id: alertId,
            is_active: !alerts[alertIndex].is_active
          }, {
            onSuccess: r => {
              alerts[alertIndex].is_active = !alerts[alertIndex].is_active;
              this.setState({alerts});
            }
          })
        } else {
          this.props.updateAlert({
            company_id: this.props.companyId,
            alert_id: alertId,
            is_active: !alerts[alertIndex].is_active
          }, {
            onSuccess: r => {
              alerts[alertIndex].is_active = !alerts[alertIndex].is_active;
              this.setState({alerts});
            }
          })
        }
        break;
      default:
    }
  }

  handleAlertGroupDataChange = (e, type, alertId, groupId, level) => {
    let alerts = [];
    let alertIndex = 0;
    let groupIndex = 0;
    let levelIndex = 0;
    switch(type) {
      case "add":
        alerts = this.state.alerts;
        let index = alerts.findIndex(a => a.id === alertId);
        // debugger;
        let levels = [];
        for(let i = 1; i <= alerts[index].levels; i ++) {
          levels.push({
            alerts: alerts[index].id,
            duration: 0,
            measurement: 1,
            emails: [],
            is_active: false,
            level: i,
            user_detail: {roles: [], users: []}
          })
        }
        alerts[index].groups.push({
          facilities: [],
          bases: [],
          id: (Math.max(0, ...alerts[index].groups.map(g => g.id))) + 1,
          tags: [],
          group_detail: levels,
          edit: true,
        });
        this.setState({alerts});
        break;
      case "unit": 
        if(e.target.value[0]){
          alerts = this.state.alerts;
          alertIndex = alerts.findIndex(a => a.id === alertId);
          groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
          levelIndex = alerts[alertIndex].groups[groupIndex].group_detail.findIndex(a => a.level === level);
          alerts[alertIndex].groups[groupIndex].group_detail[levelIndex].measurement = e.target.value[0] || 1;
          this.setState({alerts});
        }
        break;
      case "user":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        levelIndex = alerts[alertIndex].groups[groupIndex].group_detail.findIndex(a => a.level === level);
        alerts[alertIndex].groups[groupIndex].group_detail[levelIndex].user_detail.users = e.target.value;
        this.setState({alerts});
        break;
      case "duration":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        levelIndex = alerts[alertIndex].groups[groupIndex].group_detail.findIndex(a => a.level === level);
        alerts[alertIndex].groups[groupIndex].group_detail[levelIndex].duration = e.target.value;
        this.setState({alerts});
        break;
      case "active" :
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        levelIndex = alerts[alertIndex].groups[groupIndex].group_detail.findIndex(a => a.level === level);
        alerts[alertIndex].groups[groupIndex].group_detail[levelIndex].is_active = !alerts[alertIndex].groups[groupIndex].group_detail[levelIndex].is_active;
        this.setState({alerts});
        break;
      case "facility":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        alerts[alertIndex].groups[groupIndex].facilities = e.target.value;
        this.setState({alerts});
        break;
      case "base":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        alerts[alertIndex].groups[groupIndex].bases = e.target.value;
        this.setState({alerts});
        break;
      case "edit":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        alerts[alertIndex].groups[groupIndex].edit = !alerts[alertIndex].groups[groupIndex].edit;
        this.setState({alerts});
        break;
      case "delete":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        if(alerts[alertIndex].originalGroups.includes(groupId)){
          if(this.props.companyType === 1){
            this.props.deleteAlertBaseGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_id: groupId
            }, {
              onSuccess: () => {
                alerts[alertIndex].groups = alerts[alertIndex].groups.filter(g => g.id !== groupId);
                // debugger;
                this.setState({alerts});
              }
            })
          } else {
            this.props.deleteAlertGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_id: groupId
            }, {
              onSuccess: () => {
                alerts[alertIndex].groups = alerts[alertIndex].groups.filter(g => g.id !== groupId);
                // debugger;
                this.setState({alerts});
              }
            })
          }
        } else {
          alerts[alertIndex].groups = alerts[alertIndex].groups.filter(g => g.id !== groupId);
          this.setState({alerts});
        }
        break;
      case "save":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        groupIndex = alerts[alertIndex].groups.findIndex(a => a.id === groupId);
        if(alerts[alertIndex].originalGroups.includes(groupId)){
          if(this.props.companyType === 1){
            this.props.deleteAlertBaseGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_id: groupId
            }, {
              onSuccess: () => {
                this.props.createAlertBaseGroup({
                  company_id: this.props.companyId,
                  alert_id: alertId,
                  group_detail: {
                    bases: alerts[alertIndex].groups[groupIndex].bases || [],
                    // tags: [],
                    alert_detail: alerts[alertIndex].groups[groupIndex].group_detail.map(level => ({
                      ...level,
                      users_detail: {
                        roles: [],
                        users: level.user_detail.users
                      }
                    }))
                  }
                }, {
                  onSuccess: (r) => {
                    alerts[alertIndex].groups[groupIndex].id = r.id;
                    alerts[alertIndex].originalGroups = alerts[alertIndex].originalGroups.concat(r.id);
                    this.setState({alerts});
                  }
                })
              }
            })
          } else {
            this.props.deleteAlertGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_id: groupId
            }, {
              onSuccess: () => {
                this.props.createAlertGroup({
                  company_id: this.props.companyId,
                  alert_id: alertId,
                  group_detail: {
                    facilities: alerts[alertIndex].groups[groupIndex].facilities || [],
                    tags: [],
                    alert_detail: alerts[alertIndex].groups[groupIndex].group_detail.map(level => ({
                      ...level,
                      users_detail: {
                        roles: [],
                        users: level.user_detail.users
                      }
                    }))
                  }
                }, {
                  onSuccess: (r) => {
                    alerts[alertIndex].groups[groupIndex].id = r.id;
                    alerts[alertIndex].originalGroups = alerts[alertIndex].originalGroups.concat(r.id);
                    this.setState({alerts});
                  }
                })
              }
            })
          }
        } else {
          if(this.props.companyType === 1){
            this.props.createAlertBaseGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_detail: {
                bases: alerts[alertIndex].groups[groupIndex].bases || [],
                // tags: [],
                alert_detail: alerts[alertIndex].groups[groupIndex].group_detail.map(level => ({
                  ...level,
                  users_detail: {
                    roles: [],
                    users: level.user_detail.users
                  }
                }))
              }
            }, {
              onSuccess: (r) => {
                alerts[alertIndex].groups[groupIndex].id = r.id;
                alerts[alertIndex].originalGroups = alerts[alertIndex].originalGroups.concat(r.id);
                this.setState({alerts});
              }
            })
          } else {
            this.props.createAlertGroup({
              company_id: this.props.companyId,
              alert_id: alertId,
              group_detail: {
                facilities: alerts[alertIndex].groups[groupIndex].facilities || [],
                tags: [],
                alert_detail: alerts[alertIndex].groups[groupIndex].group_detail.map(level => ({
                  ...level,
                  users_detail: {
                    roles: [],
                    users: level.user_detail.users
                  }
                }))
              }
            }, {
              onSuccess: (r) => {
                alerts[alertIndex].groups[groupIndex].id = r.id;
                alerts[alertIndex].originalGroups = alerts[alertIndex].originalGroups.concat(r.id);
                this.setState({alerts});
              }
            })
          }
        }
        alerts[alertIndex].groups[groupIndex].edit = !alerts[alertIndex].groups[groupIndex].edit;
        this.setState({alerts});
        break;
      case "cancel":
        alerts = this.state.alerts;
        alertIndex = alerts.findIndex(a => a.id === alertId);
        if(this.props.companyType === 1){
          this.props.listAlertBaseGroups({
            company_id: this.props.companyId,
            alert_id: alertId
          }, {
            onSuccess: r => {
              alerts[alertIndex].groups = r.map(g => ({
                ...g, 
                edit: false, 
                group_detail: g.group_detail.map(gD => ({
                  ...gD,
                  user_detail: {
                    roles: gD.user_detail.roles || [],
                    users: gD.user_detail.users.concat((gD.user_detail.roles || []).map(rol => ((this.props.companyUsers || []).find(cU => cU.id === rol) || {items: []}).items.map(i => i.id)).flat())
                  }
                }))
              }));
              alerts[alertIndex].originalGroups =  r.map(g => g.id);
              this.setState({alerts});
            }
          });
        } else {
          this.props.listAlertGroups({
            company_id: this.props.companyId,
            alert_id: alertId
          }, {
            onSuccess: r => {
              alerts[alertIndex].groups = r.map(g => ({
                ...g, 
                edit: false, 
                group_detail: g.group_detail.map(gD => ({
                  ...gD,
                  user_detail: {
                    roles: gD.user_detail.roles || [],
                    users: gD.user_detail.users.concat((gD.user_detail.roles || []).map(rol => ((this.props.companyUsers || []).find(cU => cU.id === rol) || {items: []}).items.map(i => i.id)).flat())
                  }
                }))
              }));
              alerts[alertIndex].originalGroups =  r.map(g => g.id);
              this.setState({alerts});
            }
          });
        }
        break;
      default:
    }
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const mapStateToProps = state => {
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const baseTags = companyTags.filter(t => t.type === 3);

  let usersByTag = [
    { id: 9999999999, description: "Sin rol", items: [] },
  ];
  if(state.companyReducer.companyUsers.data && Array.isArray(state.companyReducer.companyUsers.data)){
    (state.companyReducer.companyUsers.data || []).forEach((u) => {
      if(u.role){
        let tagIndex = usersByTag.map((uT) => uT.id).indexOf(u.role);
          if (tagIndex !== -1) {
            usersByTag[tagIndex].items.push({
              id: u.users,
              description: u.username,
            });
          } else {
            let newTag = {
              id: u.role_detail.id,
              description: u.role_detail.description,
              items: [
                {
                  id: u.users,
                  description: u.username,
                },
              ],
            };
            usersByTag.push(newTag);
          }
      } else {
        usersByTag[0].items.push({
          id: u.users,
          description: u.username,
        });
      }
    });
    
    usersByTag = usersByTag.map((tag) => {
      let sortedItems = tag.items.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return { ...tag, items: sortedItems };
    });
    
    let tagless = usersByTag.shift();
    
    usersByTag = usersByTag.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    
    usersByTag.push(tagless);
  
    // return usersByTag;
  }
  
  return {
    facilitiesByTag,
    companyUsers: usersByTag,
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type?.id || 0, //1-lt, 2-co
    baseTags,
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  return {
    loadCompanyTags: (params, opts) =>
      dispatch(readEntities("COMPANIES.TAGS", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    listAlertCatalog: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.ALERTS.CATALOG",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    updateAlert: (params = {}, opts = {}) =>
      dispatch(
        partialEntity(
          "COMPANIES.ALERTS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    updateAlertBase: (params = {}, opts = {}) =>
      dispatch(
        partialEntity(
          "COMPANIES.ALERTS.BASE",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    listAlertGroups: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.ALERTS.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createAlertGroup: (params = {}, opts = {}) =>
      dispatch(
        createEntity(
          "COMPANIES.ALERTS.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    deleteAlertGroup: (params = {}, opts = {}) =>
      dispatch(
        deleteEntity(
          "COMPANIES.ALERTS.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),

    
    listAlertBaseGroups: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.ALERTS.BASE.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createAlertBaseGroup: (params = {}, opts = {}) =>
      dispatch(
        createEntity(
          "COMPANIES.ALERTS.BASE.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    deleteAlertBaseGroup: (params = {}, opts = {}) =>
      dispatch(
        deleteEntity(
          "COMPANIES.ALERTS.BASE.GROUPS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(AlertListView);
