import React, { Component } from 'react';
import PropTypes from 'prop-types';


export const STATUS_CLEAR = 'clear';
export const STATUS_FULL = 'full';
export const STATUS_MID = ' mid';

class Chip extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    active: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    prefix: PropTypes.shape({
      element: PropTypes.element,
      className: PropTypes.string
    }),
    suffix: PropTypes.shape({
      element: PropTypes.element,
      className: PropTypes.string
    })
  }
  static defaultProps = {
    active: false,
    className: '',
    prefix: {
      element: <React.Fragment />,
      className: ''
    },
    suffix: {
      element: <React.Fragment />,
      className: ''
    }
  }

  handleClick = (event) => {
    this.props.onClick({
      target: {
        name: this.props.id,
        value: this.props.active,
        contenttype: this.props.contenttype,
        key: this.props.id
      }
    });
  }

  render() {
    return (
      <div
        key={this.props.id}
        className={'chip'.concat(' ', this.props.className, ' ', this.props.active ? STATUS_FULL : STATUS_CLEAR, ' ', this.props.isMid ? STATUS_MID : '')}
        onClick={this.handleClick}
      >
        {React.cloneElement(this.props.prefix.element, { className: 'suffix' })}
        {this.props.description}
        {React.cloneElement(this.props.suffix.element, { className: 'suffix' })}
      </div>
    );
  }
}

export default Chip;