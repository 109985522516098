import { ORDERS } from "../../control/shipment/detail/dummy-orders";

const ACTIONS = {
  SET_STATUS: {
    TYPE: "SET_STATUS",
    action: (tripId, newStatus) => ({
      type: ACTIONS.SET_STATUS.TYPE,
      payload: { tripId, newStatus },
    }),
  },
  SET_APPOINTMENT_DATE: {
    TYPE: "SET_APPOINTMENT",
    action: (tripId: number, newAppointmentDate: Date) => ({
      type: ACTIONS.SET_APPOINTMENT_DATE.TYPE,
      payload: { tripId, newAppointmentDate },
    }),
  },
};

const initialState = {
  trips: {
    items: ORDERS,
    status: { isFetching: false, error: undefined },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_STATUS":
      const filteredStatus = state.trips.items.filter(
        (trip) => trip.trip_id !== action.payload.tripId
      );
      const currentStatus = state.trips.items.find(
        (trip) => trip.trip_id === action.payload.tripId
      );
      currentStatus.status = action.payload.newStatus;
      return {
        ...state,
        trips: {
          items: filteredStatus.concat([currentStatus]),
          status: {
            isFetching: false,
            error: null,
          },
          cancel: undefined,
        },
      };
    case "SET_APPOINTMENT":
      const filteredAppointment = state.trips.items.filter(
        (trip) => trip.trip_id !== action.payload.tripId
      );
      const currentAppointment = state.trips.items.find(
        (trip) => trip.trip_id === action.payload.tripId
      );
      // console.log("current", currentAppointment);
      currentAppointment.appointment_loading_date = action.payload.newAppointmentDate.toISOString();
      currentAppointment.appointment_unload_date = action.payload.newAppointmentDate.toISOString();
      return {
        ...state,
        trips: {
          items: filteredAppointment.concat([currentAppointment]),
          status: {
            isFetching: false,
            error: null,
          },
          cancel: undefined,
        },
      };
    default:
      return state;
  }
}

export const setStatus = (tripId, newStatus) => {
  return function (dispatch) {
    dispatch(ACTIONS.SET_STATUS.action(tripId, newStatus));
    return "OK";
  };
};
export const setAppointmentDate = (
  tripId: number,
  newAppointmentDate: Date
) => {
  return function (dispatch) {
    dispatch(ACTIONS.SET_APPOINTMENT_DATE.action(tripId, newAppointmentDate));
    return "OK";
  };
};
