import React from 'react';

class Resume extends React.Component {
  render() {
    let cont = 1;
    return (
      <div>
        <p>Le hemos enviado las invitaciones a <b>{this.props.tags.filter(m => m.sent).length} persona(s),</b><b style={{ color: 'green' }}> {this.props.tags.filter(m => !m.sent).length}</b> ya se les habia enviado invitación:</p>
        <div className="result-container">
          {this.props.tags.map((tag) => {
            return (
              <p key={cont++} style={{ "margin": "0px", color: tag.sent ? 'inherit' : 'green' }}>{tag.email || ''}</p>
            )
          })}
        </div>
      </div>
    )
  }


}

export default Resume;