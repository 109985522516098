import React, { Component } from "react";
import { connect } from "react-redux";

import Panel from "../../shared/component/panel";
import SelectInput from "../../shared/controls/select-input";
import TextInput from "../../shared/controls/text-input";
import ChipContainer from "../../shared/component/chip-container";
import ColorSelector from "../../shared/controls/color-selector";
import FleetSpecificationVehicle, {
  units,
} from "../../shared/component/fleet/fleet-specification-vehicle";
import Table from "../../shared/component/table";
import NormativityTableItem, {
  DocumentTypes,
} from "../../shared/component/fleet/normativity-table-item";
import SVGIcon, { icons } from "../../shared/svg-icon";
import {
  validators,
  validateEmail,
  validateLettersAndNumbers,
  removeSpecialCharacters,
} from "../../shared/validate";
import Button from "../../shared/controls/button";

import {
  loadFleetClasses,
  createFleetData,
  loadFleets,
  loadVehicleTypes,
  loadNormativityTypes,
  createVehicleData,
  createNormativityData,
  postUploadImage,
  loadHints,
  createHint,
  loadGpsProvider,
  createGPSProvider,
  addTagToFleet,
  loadDriveAxleSuspension,
  loadSteeringAxleSuspension,
  loadFleetData,
  loadVehicles,
  loadTagsForFleetData,
  loadNormativityForFleetData,
  loadNormativityImagesForFleetData,
  deleteNormativityImagesForFleetData,
  updateFleetData,
  updateVehicleData,
  updateNormativityData,
  deleteTagInFleetData,
  postValidateEconomicNumber,
  postValidateNiv,
  postValidateGpsIdentifier,
  postValidateEngineNumber,
  validatePlates,
} from "../../redux/reducers/fleet-reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import {
  loadSession,
  addToast,
  setFleetCatalogTabSeleted,
} from "../../redux/reducers/global-catalog.reducer";
import ReactTooltip from "react-tooltip";
import ErrorDialog from "../../shared/dialog/error-dialog";
import { withRouter } from "react-router-dom";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { updateDataNormativityStatus } from "../../redux/reducers/fleet-reducer";
import RadioGroup from "../../shared/component/radio-group";
import { handleWSErrorWithKeys } from "../../shared/utils";
import { createEntity, getEntityItems, partialEntity, readEntities } from "../../redux/api";

// TODO: add validations to forms

const MAXSIZE = 12;

class FleetVehicleView extends Component {
  constructor(props) {
    super(props);

    this.loaded = false;
    this.confirmDeleteAction = () => {};
    this.fetchCounter = 0;
    this.responseCounter = 0;
    this.state = {
      validatingFields: false,
      allowNullNorm: false,
      // validForm: false,
      modeEdit: false,
      showError: false,

      dataId: null,

      dupliedEconomic: false,
      dupliedVIN: false,
      dupliedMotor: false,
      dupliedGps: false,
      dupliedPlates: false,

      ownEconomic: null,
      ownVIN: null,
      ownMotor: null,
      ownGps: null,
      ownPlates: null,

      status: 1,

      // General data
      fleetClassId: 0,
      // classId: 0,
      economic: "",
      brand: "",
      model: "",
      // serial: '',
      year: new Date().getFullYear().toString(),
      plates: "",
      VIN: "",
      color: "",
      fleetTags: [],
      // Specification
      unitSystem: units.systems[0],
      height: "",
      width: "",
      length: "",
      weight: "",
      // singleMaxLoad: "",
      axis: "",
      tires: "",
      tractionCapacity: "",
      tractionCapacityUnit: 1,

      motorSerial: "", //vehiculo
      // doubleMaxLoad: "", // vehiculo
      electronicMotor: 0, //vehiculo
      torque: "", //vehiculo
      torqueUnit: 1,
      suspension: 0, //vehiculo
      directionalSuspension: 0, //vehiculo
      auxBrake: 0, //vehiculo
      abs: 0, //vehiculo
      hp: "",
      showFilesMessageError: false,
      showFilesSizeMessageError: false,

      assurance_company: '',

      // Normativity

      card: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      assurance: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      authorization: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      certificate: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },
      pollution: {
        id: 0,
        expedition: "",
        expiration: "",
        number: "",
        files: [],
        isConsidered: true,
        changeConsidered: false,
      },

      // GPS-provider
      GPSIdentifier: "",
      GPSIdentifierLast: "",
      GPSProvider: "",
      GPSProviderContactName: "",
      GPSProviderContactEmail: "",
      GPSProviderContactPhone: "",

      showRegisterError: false,
      onFocused: false,
      showConfirmDialog: false,
      fileIdToRemove: 0,
      normativityIdToRemove: 0,

      cardExpeditionDate: "",

      assuranceExpeditionDate: "",
      assuranceExpirationDate: "",

      authorizationExpeditionDate: "",
      authorizationExpirationDate: "",

      certificateExpeditionDate: "",
      certificateExpirationDate: "",

      pollutionExpeditionDate: "",
      pollutionExpirationDate: "",
      tagsData: [],
      normativityUpdated: false,

      permissions: [
        {
          sct: 0,
          number: ''
        }
      ]
    };
  }

  onClickBack = () => {
    this.props.history.goBack();
  };

  setVehicleData = () => {
    if (this.props.fleetData && this.props.vehicleData) {
      let brand = "";
      let gpsProvider = "";
      let fleetClass = {};
      let tires = 0;
      let axles = 0;

      if(this.props.hints.length === 0){
        this.props.loadHint().then((response) => {
          if(response !== null && response.data !== null){
            response.data.forEach((item) => {
              if (`${item.id}` === `${this.props.fleetData.brand}`) {
                brand = item.description;
                this.setState({
                  brand: brand
                });
              }
            });
          } else {
            this.props.history.goBack();
          }
        });
      } else {
        this.props.hints.forEach((item) => {
          if (`${item.id}` === `${this.props.fleetData.brand}`) {
            brand = item.description;
          }
        });
      }

      this.props.gpsProvider.forEach((item) => {
        if (`${item.id}` === `${this.props.fleetData.gps_provider}`) {
          gpsProvider = item.name;
        }
      });

      this.props.relatedArray.forEach((item) => {
        if (
          item.classID === `${this.props.fleetData.fleet_class}` &&
          item.typeID === `${this.props.vehicleData.type}`
        ) {
          fleetClass = item;
        }
      });

      this.props.fleetClass.forEach((item) => {
        if (`${item.id}` === `${this.props.fleetData.fleet_class}`) {
          axles = item.axles;
          tires = item.tires;
        }
      });

      this.setState({
        brand: brand,
        GPSProvider: gpsProvider,
        fleetClassId: `${fleetClass.id ? fleetClass.id : ""}`,
        tires: `${tires}`,
        axis: `${axles}`,
      });
    }
  };

  componentDidMount() {
    this.props.setFleetTab("1");
    this.props.loadPermissions({},{prefix: 'orbisat.'});

    if(this.props.country && this.props.country !== 'México'){
      this.setState({allowNullNorm: true})
    }

    this.unblock = this.props.history.block((targetLocation) => {
      if (this.state.normativityUpdated) {
        this.setState({ saveExit: true });
        this.props
          .updateDataNormativityStatus(
            this.props.fleet ? this.props.fleet.company : "",
            this.props.fleet ? this.props.fleet.id : "",
            this.props.fleetData.id
          )
          .then((r) => {
            this.setState({ saveExit: false, normativityUpdated: false });
            this.props.history.goBack();
          });
      }
      return !this.state.normativityUpdated;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  componentDidUpdate() {
    if (
      this.fetchCounter > 0 &&
      this.fetchCounter === this.responseCounter &&
      this.props.toast === null
    ) {
      this.props.showToast({
        text: this.state.modeEdit
          ? "Se actualizó con exito el vehículo"
          : "Se registró con exito el vehículo",
      });

      if (this.state.modeEdit) {
        this.props.history.go(-2); 
      } else {
        this.props.history.goBack(); 
      }

    }

    // if (this.validateForm() !== this.state.validForm)
    //   this.setState({ validForm: !this.state.validForm });

    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.vehicleId &&
      this.props.match.params.vehicleId > 0 &&
      this.props.fleet &&
      !this.loaded
    ) {
      this.loaded = true;

      this.props
        .loadFleetData(
          parseInt(this.props.match.params.vehicleId),
          this.props.fleet.id
        )
        .then(() => {

          this.setState(
            {
              // General data
              modeEdit: true,
              fleetClassId: `${this.props.fleetData.fleetClassId}`,
              economic: this.props.fleetData.economic_number,
              abs: this.props.fleetData.abs ? "1" : "2",
              model: this.props.fleetData.model
                ? this.props.fleetData.model
                : "",
              year: this.props.fleetData.year,
              plates: this.props.fleetData.plates,
              VIN: this.props.fleetData.niv,
              color: this.props.fleetData.color,

              status: this.props.fleetData.status.id || 1,
              // Specification
              unitSystem:
                `${this.props.fleetData.traction_axles_unit}` === "1"
                  ? "METRIC"
                  : "USA",
              height: `${this.props.fleetData.height}`,
              width: `${this.props.fleetData.width}`,
              length: `${this.props.fleetData.length}`,
              weight: `${this.props.fleetData.weight}`,
              // singleMaxLoad: `${this.props.fleetData.load}`,
              axis: this.props.fleetData.axes,
              tires: this.props.fleetData.tires,
              tractionCapacity: this.props.fleetData.traction_axles_capacity,
              tractionCapacityUnit: this.props.fleetData
                .traction_axles_capacity_unit,

              GPSIdentifier: this.props.fleetData.gps_identifier || "",
              GPSIdentifierLast: this.props.fleetData.gps_identifier || "",
              GPSProviderContactName: this.props.fleetData.gps_contact || "",
              GPSProviderContactEmail: this.props.fleetData.gps_email || "",
              GPSProviderContactPhone: this.props.fleetData.gps_telephone || "",

              ownEconomic: this.props.fleetData.economic_number || null,
              ownVIN: this.props.fleetData.niv || null,
              ownGps: this.props.fleetData.gps_identifier || null,
              ownPlates: this.props.fleetData.plates || null,
            },
            () => {
              this.setVehicleData();
            }
          );
        });

      this.props
        .loadVehicles(
          parseInt(this.props.match.params.vehicleId),
          this.props.fleet.id
        )
        .then(() => {

          var permissionsToShow = [];
          var permissionsTemp = this.props.vehicleData.extra_data || [];

          if (permissionsTemp.length > 0) {

            for (var i = 0; i < permissionsTemp.length; i++) {
              permissionsToShow = permissionsToShow.concat({
                sct: parseInt(permissionsTemp[i].id || '0'),
                number: permissionsTemp[i].number || '0',
              });
            }

          } else {
            permissionsToShow = [
              {
                sct: 0,
                number: ''
              }
            ];
          }

          this.setState(
            {
              permissions: permissionsToShow,
              motorSerial: this.props.vehicleData.engine_number
                ? this.props.vehicleData.engine_number
                : "",
              // doubleMaxLoad: `${
              //   this.props.vehicleData.double_load
              //     ? this.props.vehicleData.double_load
              //     : ""
              // }`,
              electronicMotor: this.props.vehicleData.electric_motor
                ? "1"
                : "2",
              torque: this.props.vehicleData.torque
                ? `${this.props.vehicleData.torque}`
                : 0,
              directionalSuspension: this.props.vehicleData
                .steering_axle_suspension
                ? `${this.props.vehicleData.steering_axle_suspension}`
                : 0,
              suspension: this.props.vehicleData.drive_axle_suspension
                ? `${this.props.vehicleData.drive_axle_suspension}`
                : 0,
              auxBrake: this.props.vehicleData.auxiliary_braking_system
                ? "1"
                : "2",
              hp: this.props.vehicleData.hp
                ? `${this.props.vehicleData.hp}`
                : "",

              ownMotor: this.props.vehicleData.engine_number || null,
              torqueUnit: this.props.vehicleData.torque_unit || 1,
            },
            () => {
              this.setVehicleData();
            }
          );
        });

      this.props
        .loadTagsForFleetData(
          parseInt(this.props.match.params.vehicleId),
          this.props.fleet.id
        )
        .then(() => {
          let tagsToShow = [];

          this.props.tagsData.forEach((item) => {
            tagsToShow.push(item.tags);
          });

          this.setState({
            fleetTags: tagsToShow,
            tagsData: this.props.tagsData,
          });
        });

      this.props
        .loadNormativityForFleetData(
          parseInt(this.props.match.params.vehicleId),
          this.props.fleet.id
        )
        .then(() => {
          let card = {};
          let assurance = {};
          let authorization = {};
          let certificate = {};
          let pollution = {};

          this.generateNormativity().forEach((item, index) => {
            this.props.normativityData.forEach((normItem) => {
              if (`${item.id}` === `${normItem.type}`) {
                switch (index) {
                  case 0:
                    card = {
                      expedition: normItem.expedition || "",
                      expiration: normItem.expiration || "",
                      number: normItem.code || "",
                      id: normItem.id || "",
                      files: [],
                      isConsidered: normItem.is_considered,
                    };

                    break;

                  case 1:
                    assurance = {
                      expedition: normItem.expedition || "",
                      expiration: normItem.expiration || "",
                      number: normItem.code || "",
                      id: normItem.id || "",
                      files: [],
                      isConsidered: normItem.is_considered,
                    };

                    this.setState({assurance_company: normItem.data?.assurance_company || ''});

                    break;

                  case 2:
                    authorization = {
                      expedition: normItem.expedition || "",
                      expiration: normItem.expiration || "",
                      number: normItem.code || "",
                      id: normItem.id || "",
                      files: [],
                      isConsidered: normItem.is_considered,
                    };

                    break;

                  case 3:
                    certificate = {
                      expedition: normItem.expedition || "",
                      expiration: normItem.expiration || "",
                      number: normItem.code || "",
                      id: normItem.id || "",
                      files: [],
                      isConsidered: normItem.is_considered,
                    };

                    break;

                  case 4:
                    pollution = {
                      expedition: normItem.expedition || "",
                      expiration: normItem.expiration || "",
                      number: normItem.code || "",
                      id: normItem.id,
                      files: [],
                      isConsidered: normItem.is_considered,
                    };

                    break;

                  default:
                    break;
                }
              }
            });
          });

          this.setState(
            {
              card,
              assurance,
              authorization,
              certificate,
              pollution,
            },
            () => {
              this.props
                .loadNormativityImagesForFleetData(
                  parseInt(this.props.match.params.vehicleId),
                  this.props.fleet.id,
                  card.id
                )
                .then((response) => {
                  let filesToUload = [];

                  if (Array.isArray(response.response)) {
                    response.response.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });
                  }

                  this.setState({
                    cardExpeditionDate: this.state.card.expedition,
                    card: Object.assign(this.state.card, {
                      files: filesToUload,
                    }),
                  });
                });

              this.props
                .loadNormativityImagesForFleetData(
                  parseInt(this.props.match.params.vehicleId),
                  this.props.fleet.id,
                  assurance.id
                )
                .then((response) => {
                  let filesToUload = [];

                  if (Array.isArray(response.response)) {
                    response.response.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });
                  }

                  this.setState({
                    assuranceExpeditionDate: this.state.assurance.expedition,
                    assuranceExpirationDate: this.state.assurance.expiration,
                    assurance: Object.assign(this.state.assurance, {
                      files: filesToUload,
                    }),
                  });
                });

              this.props
                .loadNormativityImagesForFleetData(
                  parseInt(this.props.match.params.vehicleId),
                  this.props.fleet.id,
                  authorization.id
                )
                .then((response) => {
                  let filesToUload = [];

                  if (Array.isArray(response.response)) {
                    response.response.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });
                  }

                  this.setState({
                    authorizationExpeditionDate: this.state.authorization
                      .expedition,
                    authorizationExpirationDate: this.state.authorization
                      .expiration,
                    authorization: Object.assign(this.state.authorization, {
                      files: filesToUload,
                    }),
                  });
                });

              this.props
                .loadNormativityImagesForFleetData(
                  parseInt(this.props.match.params.vehicleId),
                  this.props.fleet.id,
                  certificate.id
                )
                .then((response) => {
                  let filesToUload = [];

                  if (Array.isArray(response.response)) {
                    response.response.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });
                  }

                  this.setState({
                    certificateExpeditionDate: this.state.certificate
                      .expedition,
                    certificateExpirationDate: this.state.certificate
                      .expiration,
                    certificate: Object.assign(this.state.certificate, {
                      files: filesToUload,
                    }),
                  });
                });

              this.props
                .loadNormativityImagesForFleetData(
                  parseInt(this.props.match.params.vehicleId),
                  this.props.fleet.id,
                  pollution.id
                )
                .then((response) => {
                  let filesToUload = [];

                  if (Array.isArray(response.response)) {
                    response.response.forEach((item) => {
                      filesToUload.push({
                        name: item.original_name + " (" + item.file_size + ")",
                        idNormativityImage: item.id,
                      });
                    });
                  }

                  this.setState({
                    pollutionExpeditionDate: this.state.pollution.expedition,
                    pollutionExpirationDate: this.state.pollution.expiration,
                    pollution: Object.assign(this.state.pollution, {
                      files: filesToUload,
                    }),
                  });
                });
            }
          );
        });
    }
  }

  actionCloseDialog = () => {
    this.setState({
      showRegisterError: false,
      showConfirmDialog: false,
      fileIdToRemove: null,
      openDialog: false,
      showError: false,
      showFilesMessageError: false,
      showFilesSizeMessageError: false,
    });
  };

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  getErrors = () => {
    let errors = "";

    if (!this.validateForm()) {
      errors = "Por favor completa los siguientes campos:<br/>";
    }

    errors +=
      String(this.state.fleetClassId).length === 0
        ? `<b>Selecciona un Tipo/Clase de vehiculo<b/><br/>`
        : "";
    errors +=
      this.state.economic.length === 0
        ? `<b>Ingresa número económico<b/><br/>`
        : "";
    errors +=
      this.state.brand.length === 0 ? `<b>Ingresa la marca<b/><br/>` : "";
    errors += this.state.year.length === 0 ? `<b>Ingresa el año<b/><br/>` : "";
    errors +=
      this.state.model.length === 0 ? `<b>Ingresa el modelo<b/><br/>` : "";
    errors +=
      this.state.plates.length === 0 ? `<b>Ingresa la placa<b/><br/>` : "";
    errors += this.state.VIN.length === 0 ? `<b>Ingresa el NIV<b/><br/>` : "";
    errors +=
      this.state.motorSerial.length === 0
        ? `<b>Ingresa el número de motor<b/><br/>`
        : "";
    errors += !this.validateTagData()
      ? "<b>Selecciona una etiqueta<b/><br/>"
      : "";
    errors +=
      this.state.electronicMotor === 0
        ? `<b>Selecciona el motor electrico<b/><br/>`
        : "";
    errors +=
      this.state.color.length === 0 ? `<b>Selecciona el color<b/><br/>` : "";
    errors +=
      this.state.torque.length === 0 ? `<b>Ingresa el torque<b/><br/>` : "";
    errors +=
      this.state.tractionCapacity.length === 0
        ? `<b>Ingresa la capacidad de los ejes de tracción<b/><br/>`
        : "";
    errors +=
      this.state.height.length === 0 ? `<b>Ingresa la altura<b/><br/>` : "";
    errors +=
      this.state.weight.length === 0 ? `<b>Ingresa el peso<b/><br/>` : "";
    errors += this.state.length.length === 0 ? `<b>Ingresa largo<b/><br/>` : "";
    errors +=
      this.state.width.length === 0 ? `<b>Ingresa la anchura<b/><br/>` : "";
    // errors +=
    //   this.state.singleMaxLoad.length === 0
    //     ? `<b>Ingresa Carga MAX. Sencillo<b/><br/>`
    //     : "";
    // errors +=
    //   this.state.doubleMaxLoad.length === 0
    //     ? `<b>Ingresa Carga MAX. Doble<b/><br/>`
    //     : "";
    errors +=
      this.state.directionalSuspension === 0
        ? `<b>Selecciona la suspensión eje direccional<b/><br/>`
        : "";
    errors +=
      this.state.suspension === 0
        ? `<b>Slecciona la suspensión de motriz <b/><br/>`
        : "";
    errors +=
      this.state.auxBreak === 0
        ? `<b>Selecciona si tiene freno auxiliar<b/><br/>`
        : "";
    errors +=
      this.state.abs === 0 ? `<b>Selecciona si tiene frenos abs<b/><br/>` : "";
    errors +=
      this.state.hp.length === 0
        ? `<b>Ingresa los caballos de fuerza<b/><br/>`
        : "";
    errors += !this.isNormativityValid(1)
      ? "<b>Sube un nuevo archivo en Tarjeta de circulación<b/><br/>"
      : "";
    errors += !this.isNormativityValid(2)
      ? "<b>Sube un nuevo archivo en Póliza de seguro<b/><br/>"
      : "";
    if(!this.state.allowNullNorm){
      errors += !this.state.assurance_company
        ? "<b>Ingresa el nombre de la aseguradora<b/><br/>"
        : "";
    }
    errors += !this.isNormativityValid(3)
      ? "<b>Sube un nuevo archivo en Autorización expresa<b/><br/>"
      : "";
    errors += !this.isNormativityValid(4)
      ? "<b>Sube un nuevo archivo en Certificado de condición físico-mecánico<b/><br/>"
      : "";
    errors += !this.isNormativityValid(5)
      ? "<b>Sube un nuevo archivo en Dictamen de baja emisión de contaminantes<b/><br/>"
      : "";

    if(!this.state.allowNullNorm){
      if(this.state.card.isConsidered){
        errors += !this.state.card.expedition ? `<b>Ingresa la fecha de expedición de Tarjeta de circulación<b/><br/>` : ''
        // errors += this.state.card.expiration.length === 0 ? `<b>Ingresa la fecha de expiración de Tarjeta de circulación<b/><br/>` : ''
        errors += this.state.card.number.length === 0 ? `<b>Ingresa el número de documento de Tarjeta de circulación<b/><br/>` : ''
        errors += this.state.card.files.length === 0 ? `<b>Agrega por lo menos un documento de Tarjeta de circulación<b/><br/>` : ''
      }
      if(this.state.assurance.isConsidered){
        errors += !this.state.assurance.expedition ? `<b>Ingresa la fecha de expedición de tu póliza<b/><br/>` : ''
        errors += !this.state.assurance.expiration ? `<b>Ingresa la fecha de expiración de tu póliza<b/><br/>` : ''
        errors += this.state.assurance.number.length === 0 ? `<b>Ingresa el número de documento de tu póliza<b/><br/>` : ''
        errors += this.state.assurance.files.length === 0 ? `<b>Agrega por lo menos un documento de tu póliza<b/><br/>` : ''
      }
      if(this.state.authorization.isConsidered){
        errors += !(this.state.authorization || '').expedition ? `<b>Ingresa la fecha de expedición de tu autorización<b/><br/>` : ''
        errors += (this.state.authorization.expiration || '').length === 0 ? `<b>Ingresa la fecha de expiración de tu autorización<b/><br/>` : ''
        errors += (this.state.authorization.number || '').length === 0 ? `<b>Ingresa el número de documento de tu autorización<b/><br/>` : ''
        errors += (this.state.authorization.files || []).length === 0 ? `<b>Agrega por lo menos un documento de tu autorización<b/><br/>` : ''
      }
      if(this.state.certificate.isConsidered){
        errors += !this.state.certificate.expedition ? `<b>Ingresa la fecha de expedición de tu certificado<b/><br/>` : ''
        errors += this.state.certificate.expiration.length === 0 ? `<b>Ingresa la fecha de expiración de tu certificado<b/><br/>` : ''
        errors += this.state.certificate.number.length === 0 ? `<b>Ingresa el número de documento de tu certificado<b/><br/>` : ''
        errors += this.state.certificate.files.length === 0 ? `<b>Agrega por lo menos un documento de tu certificado<b/><br/>` : ''
      }
      if(this.state.pollution.isConsidered){
        errors += !this.state.pollution.expedition ? `<b>Ingresa la fecha de expedición de tu dictamen<b/><br/>` : ''
        errors += this.state.pollution.expiration.length === 0 ? `<b>Ingresa la fecha de expiración de tu dictamen<b/><br/>` : ''
        errors += this.state.pollution.number.length === 0 ? `<b>Ingresa el número de documento de tu dictamen<b/><br/>` : ''
        errors += this.state.pollution.files.length === 0 ? `<b>Agrega por lo menos un documento de tu dictamen<b/><br/>` : ''
      }
    }

    // errors += this.state.GPSIdentifier.length === 0 ? `<b>Ingresa el identificador GPS<b/><br/>` : ''
    // errors += this.state.GPSProvider.length === 0 ? `<b>Ingresa el Proveedor GPS<b/><br/>` : ''
    // errors += this.state.GPSProviderContactEmail.length === 0 ? `<b>Ingresa el email de contacto de proveedor GPS<b/><br/>` : ''

    // if (
    //   this.state.GPSProviderContactEmail.length > 0 &&
    //   !validateEmail(this.state.GPSProviderContactEmail)
    // ) {
    //   errors += `<b>"${this.state.GPSProviderContactEmail}"<b/> no es una correo válido<br/>`;
    // }

    // errors += this.state.GPSProviderContactName.length === 0 ? `<b>Ingresa el nombre del contacto de proveedor GPS<b/><br/>` : ''
    // errors += this.state.GPSProviderContactPhone.length === 0 ? `<b>Ingresa el teléfono del contacto de proveedor GPS<b/><br/>` : ''

    errors += this.state.dupliedEconomic
      ? `<b>El N° económico "${this.state.economic}" ya existe<b/><br/>`
      : "";
    errors += this.state.dupliedVIN
      ? `<b>El NIV "${this.state.VIN}" ya existe<b/><br/>`
      : "";
    errors += this.state.dupliedMotor
      ? `<b>La N° motor "${this.state.motorSerial}" ya existe<b/><br/>`
      : "";
    // errors += this.state.dupliedGps
    //   ? `<b>El identificador GPS "${this.state.GPSIdentifier}" ya existe<b/><br/>`
    //   : "";
    errors += this.state.dupliedPlates
      ? `<b>Las placas "${this.state.plates}" ya existen<b/><br/>`
      : "";

    if (!this.isPermissionsValid()) {
      errors += `<b>Verifica que el permiso SCT y el número de permiso esten correctos<b/><br/>`
    }

    return errors;
  };

  isPermissionsValid = () => {
    var isValid = true;

    if (this.state.permissions.length === 1) {
      if ((this.state.permissions[0].sct !== 0 && this.state.permissions[0].number === '') || this.state.permissions[0].sct === 0 && this.state.permissions[0].number !== '') {
        isValid = false;
      }
    } else {
      for(var i = 0; i < this.state.permissions.length; i++) {
        if (this.state.permissions[i].sct === 0 || this.state.permissions[i].number === '') {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  validateForm = () => {
    if (
      this.isPermissionsValid() &&
      String(this.state.fleetClassId).length > 0 &&
      this.state.economic.length > 0 &&
      this.state.brand.length > 0 &&
      parseInt(this.state.year) > 0 &&
      this.state.VIN.length > 0 &&
      this.state.motorSerial.length > 0 &&
      this.state.color.length > 0 &&
      this.state.electronicMotor !== 0 &&
      this.state.model.length > 0 &&
      this.state.torque.length > 0 &&
      parseInt(this.state.tractionCapacity) > 0 &&
      this.state.height.length > 0 &&
      this.state.weight.length > 0 &&
      this.state.length.length > 0 &&
      this.state.width.length > 0 &&
      // this.state.singleMaxLoad.length > 0 &&
      // this.state.doubleMaxLoad.length > 0 &&
      this.state.directionalSuspension !== 0 &&
      this.state.suspension !== 0 &&
      this.state.auxBrake !== 0 &&
      this.state.abs !== 0 &&
      this.state.hp.length > 0 &&
      this.state.plates.length > 0 &&
      this.state.plates.length <= 10 &&
      ///Normativity
      (
        this.state.allowNullNorm || (
          this.state.assurance_company &&
          (
            !this.state.card.isConsidered || (
              this.state.card.expedition && this.state.card.expedition.length > 0
              && this.state.card.number && this.state.card.number.length > 0
              && Array.isArray(this.state.card.files) && this.state.card.files.length > 0
            )
          ) 
          && (
            !this.state.assurance.isConsidered || (
              this.state.assurance.expedition && this.state.assurance.expedition.length > 0
              && this.state.assurance.expiration && this.state.assurance.expiration.length > 0
              && this.state.assurance.number && this.state.assurance.number.length > 0
              && Array.isArray(this.state.assurance.files) && this.state.assurance.files.length > 0
            )
          ) 
          && (
            !this.state.authorization.isConsidered || (
              this.state.authorization.expedition && this.state.authorization.expedition.length > 0
              && this.state.authorization.expiration && this.state.authorization.expiration.length > 0
              && this.state.authorization.number && this.state.authorization.number.length > 0
              && Array.isArray(this.state.authorization.files) && this.state.authorization.files.length > 0
            )
          ) 
          && (
            !this.state.certificate.isConsidered || (
              this.state.certificate.expedition && this.state.certificate.expedition.length > 0
              && this.state.certificate.expiration && this.state.certificate.expiration.length > 0
              && this.state.certificate.number && this.state.certificate.number.length > 0
              && Array.isArray(this.state.certificate.files) && this.state.certificate.files.length > 0
            )
          )
          && (
            !this.state.pollution.isConsidered || (
              this.state.pollution.expedition && this.state.pollution.expedition.length > 0
              && this.state.pollution.expiration && this.state.pollution.expiration.length > 0
              && this.state.pollution.number && this.state.pollution.number.length > 0
              && Array.isArray(this.state.pollution.files) && this.state.pollution.files.length > 0
            )
          ) 
        )
      ) &&
      /*&& this.state.card.expedition && this.state.card.expedition.length > 0
      && this.state.card.expiration && this.state.card.expiration.length > 0
      && this.state.card.number && this.state.card.number.length > 0
      && Array.isArray(this.state.card.files) && this.state.card.files.length > 0
      && this.state.assurance.expedition && this.state.assurance.expedition.length > 0
      && this.state.assurance.expiration && this.state.assurance.expiration.length > 0
      && this.state.assurance.number && this.state.assurance.number.length > 0
      && Array.isArray(this.state.assurance.files) && this.state.assurance.files.length > 0
      && this.state.authorization.expedition && this.state.authorization.expedition.length > 0
      && this.state.authorization.expiration && this.state.authorization.expiration.length > 0
      && this.state.authorization.number && this.state.authorization.number.length > 0
      && Array.isArray(this.state.authorization.files) && this.state.authorization.files.length > 0
      && this.state.certificate.expedition && this.state.certificate.expedition.length > 0
      && this.state.certificate.expiration && this.state.certificate.expiration.length > 0
      && this.state.certificate.number && this.state.certificate.number.length > 0
      && Array.isArray(this.state.certificate.files) && this.state.certificate.files.length > 0
      && this.state.pollution.expedition && this.state.pollution.expedition.length > 0
      && this.state.pollution.expiration && this.state.pollution.expiration.length > 0
      && this.state.pollution.number && this.state.pollution.number.length > 0
      && Array.isArray(this.state.pollution.files) && this.state.pollution.files.length > 0*/
      /*&& this.state.GPSIdentifier.length > 0
      && this.state.GPSProvider.length > 0
      && this.state.GPSProviderContactEmail.length > 0 && this.validateEmail(this.state.GPSProviderContactEmail)
      && this.state.GPSProviderContactName.length > 0
      && this.state.GPSProviderContactPhone.length > 0*/
      // ((this.state.GPSProviderContactEmail.length !== 0 &&
      //   validateEmail(this.state.GPSProviderContactEmail)) ||
      //   this.state.GPSProviderContactEmail.length === 0) &&
      !this.state.dupliedEconomic &&
      !this.state.dupliedVIN &&
      !this.state.dupliedMotor &&
      !this.state.dupliedGps &&
      !this.state.dupliedPlates &&
      this.isNormativityValid(1) &&
      this.isNormativityValid(2) &&
      this.isNormativityValid(3) &&
      this.isNormativityValid(4) &&
      this.isNormativityValid(5) &&
      this.validateTagData()
    )
      return true;
    else return false;
  };

  isNormativityValid = (norm) => {
    if (!this.state.modeEdit || this.state.allowNullNorm) return true;

    switch (norm) {
      case 1:
        if (
          this.state.cardExpeditionDate !== this.state.card.expedition &&
          Array.isArray(this.state.card.files)
        ) {
          let newFiles = false;

          this.state.card.files.forEach((file) => {
            if (file.idNormativityImage === undefined) {
              newFiles = true;
            }
          });

          return newFiles;
        }

        return true;

      case 2:
        if (
          this.state.assuranceExpeditionDate !==
            this.state.assurance.expedition ||
          this.state.assuranceExpirationDate !== this.state.assurance.expiration
        ) {
          if (Array.isArray(this.state.assurance.files)) {
            let newFiles = false;

            this.state.assurance.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      case 3:
        if ((
          this.state.authorizationExpeditionDate !==
            this.state.authorization.expedition ||
          this.state.authorizationExpirationDate !==
            this.state.authorization.expiration ) && this.state.authorization.isConsidered
        ) {
          if (Array.isArray(this.state.authorization.files)) {
            let newFiles = false;

            this.state.authorization.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      case 4:
        if (
          this.state.certificateExpeditionDate !==
            this.state.certificate.expedition ||
          this.state.certificateExpirationDate !==
            this.state.certificate.expiration
        ) {
          if (Array.isArray(this.state.certificate.files)) {
            let newFiles = false;

            this.state.certificate.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      case 5:
        if (
          this.state.pollutionExpeditionDate !==
            this.state.pollution.expedition ||
          this.state.pollutionExpirationDate !== this.state.pollution.expiration
        ) {
          if (Array.isArray(this.state.pollution.files)) {
            let newFiles = false;

            this.state.pollution.files.forEach((file) => {
              if (file.idNormativityImage === undefined) {
                newFiles = true;
              }
            });

            return newFiles;
          }
        }

        return true;

      default:
        return true;
    }
  };

  handleInput = (e) => {
    let property = e.target.name;
    let value = e.target.value;

    if (
      property === "card" ||
      property === "assurance" ||
      property === "authorization" ||
      property === "certificate" ||
      property === "pollution"
    ) {
      if (
        value.files.filter((item) => {
          return item.size / 1024 / 1024 > MAXSIZE;
        }).length
      ) {
        this.setState({ showFilesSizeMessageError: true });

        e.target.value.files.pop();
      } else {
        this.setState({ [property]: value });
      }
    }

    if (property === "plates" || property === "economic") {
      if (!validateLettersAndNumbers(value)) {
        this.setState({
          [property]: String(removeSpecialCharacters(value)).toUpperCase(),
        });
      }
    } else {
      this.setState({ [property]: value });
    }
  };

  handleInputCheckbox = (e, name) => {
    let property = name;
    let value = e.target.checked;
    let stateData = this.state[property];
    stateData.isConsidered = !value;
    stateData.changeConsidered = true;

    this.setState({ [property]: stateData });
  };

  handleValidate = (response, msg, field) => {
    switch (response.type) {
      case "FLEET_DATA_VALIDATIONS_ECONOMIC_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_NIV_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_SUCCESS":
      case "FLEET_DATA_VALIDATIONS_PLATES_SUCCESS":
        this.setDupliedField(field, false);
        break;
      case "FLEET_DATA_VALIDATIONS_ECONOMIC_ERROR":
      case "FLEET_DATA_VALIDATIONS_NIV_ERROR":
      case "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_ERROR":
      case "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_ERROR":
      case "FLEET_DATA_VALIDATIONS_PLATES_ERROR":
        this.setDupliedField(field, true);
        // if(response.error.error){
        //   this.setState({ showError: true, msgError: `${ msg } ya existe` })
        // } else {
        //   this.setState({ showError: true, msgError: "Comprueba los datos" });
        // }
        break;
      default:
        this.setDupliedField(field, true);
        // this.setState({ showError: true, msgError: "Falló la conexión" })
        break;
    }
  };

  setDupliedField = (field, boolean) => {
    switch (field) {
      case "economic":
        this.setState({ dupliedEconomic: boolean });
        break;
      case "VIN":
        this.setState({ dupliedVIN: boolean });
        break;
      case "motor":
        this.setState({ dupliedMotor: boolean });
        break;
      case "gps":
        this.setState({ dupliedGps: boolean });
        break;
      case "plates":
        this.setState({ dupliedPlates: boolean });
        break;
      default:
    }
  };

  fleetTagsToChips = () => {
    return this.props.fleetTagsCatalog.map((tag) => ({
      id: tag.id,
      title: tag.description,
      color: tag.color,
      isFull: this.state.fleetTags.find((ft) => ft === tag.id) ? true : false,
    }));
  };

  handleTagChange = (e) => {
    let st = this.state.fleetTags.filter((ft) => ft !== e.target.id);
    if (!e.target.value) {
      this.handleInput({ target: { name: "fleetTags", value: [e.target.id] } });
      if (this.state.modeEdit) {
        const fleetDataId = parseInt(this.props.match.params.vehicleId);

        this.state.tagsData.forEach((item) => {
          this.props.deleteTagInFleetData(
            parseInt(fleetDataId),
            this.props.fleet.id,
            item.id
          );
        });

        this.props
          .addTag(this.props.fleet ? this.props.fleet.id : "", fleetDataId, {
            data: fleetDataId,
            tags: e.target.id,
          })
          .then((fleet) => {
            let tagsData = this.state.tagsData.filter((tag) => {
              return tag.tags !== e.target.id;
            });

            tagsData.push(fleet.response);
            tagsData = tagsData.filter((item) => {
              return item.tags === e.target.id;
            });

            this.setState({
              tagsData: tagsData,
            });
          });
      }
    } else {
      if (this.state.modeEdit) {
        let tagToDelete = null;

        if (this.state.tagsData.length > 1) {
          this.state.tagsData.forEach((item) => {
            if (`${item.tags}` === `${e.target.id}`) tagToDelete = item.id;
          });

          if (tagToDelete) {
            this.props.deleteTagInFleetData(
              parseInt(this.props.match.params.vehicleId),
              this.props.fleet.id,
              tagToDelete
            );
          }
          this.handleInput({ target: { name: "fleetTags", value: st } });
        }
      } else {
        this.handleInput({ target: { name: "fleetTags", value: st } });
      }
    }
  };

  filterClassesByTypeId = () => {
    if (!this.state.fleetClassId) return [];
    let classes = this.props.classes.filter(
      (cl) => cl.typeId.toString() === this.state.fleetClassId
    );
    return classes.length ? classes : [{ id: 0, description: "No aplican" }];
  };

  handleSaveFleetData = () => {

    if (this.fetchCounter > 0 || !this.validateForm()) { return; } 
    
    let hintFound = null;
    // debugger;
    this.props.hints.forEach((item) => {
      if (
        String(item.description).toUpperCase() ===
        String(this.state.brand).toUpperCase()
      ) {
        hintFound = item;
        return;
      }
    });

    if (hintFound === null) {
      this.props.createHint(this.state.brand, 1).then((response) => {
        // debugger;
        if (response.data && response.data.type === "FLEET_HINTS_POST_ERROR") {
          // debugger;
          // console.log("ERROR", response);
          response.data.error.then(e => {
            let error = (Object.values(e)).flat().map(err => <div>{err}</div>);
            this.setState(
              {
                showError: true,
                msgError: error,
                showRegisterError: false,
              }
            );
          });
        } else {
          this.validateGPSProvider(response.data);
        }
      });
    } else {
      this.validateGPSProvider(hintFound);
    }
  };

  validateGPSProvider = (hint) => {
    let gpsProvider = null;

    // this.props.gpsProvider.forEach((item) => {
    //   if (
    //     String(item.name).toUpperCase() ===
    //     String(this.state.GPSProvider).toUpperCase()
    //   ) {
    //     gpsProvider = item;
    //     return;
    //   }
    // });

    // if (gpsProvider === null && this.state.GPSProvider !== "") {
    //   this.props
    //     .createNewGPSProvider(
    //       this.state.GPSProvider,
    //       this.state.GPSProviderContactEmail,
    //       this.state.GPSProviderContactPhone
    //     )
    //     .then((response) => {
    //       if (
    //         response.data &&
    //         response.data.type === "FLEET_GPS_PROVIDER_POST_ERROR"
    //       ) {
    //         // debugger;
    //         // console.log("error");
    //         response.data.error.then(e => {
    //           let error = (Object.values(e)).flat().map(err => <div>{err}</div>);
    //           this.setState(
    //             {
    //               showError: true,
    //               msgError: error,
    //               showRegisterError: false,
    //             }
    //           );
    //         })
    //       } else {
    //         if (this.state.modeEdit) {
    //           this.updateFleetData(hint, response.data);
    //         } else {
    //           this.saveFleetData(hint, response.data);
    //         }
    //       }
    //     });
    // } else {
      if (this.state.modeEdit) {
        this.updateFleetData(hint, gpsProvider);
      } else {
        this.saveFleetData(hint, gpsProvider);
      }
    //}
  };

  /////////////////
  ///////   Update Data
  /////////////////

  updateFleetData = (hint, gpsProvider) => {
    const { id } = this.props.fleetData;

    const fleetClassId = this.state.fleetClassId;
    const typeClassIds = this.props.relatedArray.filter((item) => {
      return `${item.id}` === `${fleetClassId}`;
    });

    const permissionssToSend = this.state.permissions.filter(item => {
      return parseInt(item.sct || '0') !== 0
    }).map(item => {
      return {
        id: parseInt(item.sct || '0'),
        number: item.number || ''
      };
    });

    this.fetchCounter += 1;
    let dataJson = {
      model: this.state.model,
      niv: this.state.VIN,
      economic_number: this.state.economic,
      year: this.state.year,
      color: this.state.color,
      plates: this.state.plates,
      height: this.state.height,
      length: this.state.length,
      width: this.state.width,
      weight: this.state.weight,
      traction_axles_capacity: this.state.tractionCapacity,
      traction_axles_capacity_unit: this.state.tractionCapacityUnit,
      traction_axles_unit: this.state.unitSystem === "METRIC" ? 0 : 1,
      abs: this.state.abs === "1",
      status: this.state.status,
      type: 1,
      brand: hint.id,
      fleet_class: typeClassIds.length > 0 && typeClassIds[0].classID
        ? typeClassIds[0].classID
        : "",
      gps_identifier: this.state.GPSIdentifier.length > 0 ? this.state.GPSIdentifier : null,
      gps_provider: gpsProvider !== null && gpsProvider.id !== 0 ? gpsProvider.id : null,
      gps_contact: this.state.GPSProviderContactName.length > 0 ? this.state.GPSProviderContactName : null,
      gps_email: this.state.GPSProviderContactEmail.length > 0 ? this.state.GPSProviderContactEmail : null,
      gps_telephone: this.state.GPSProviderContactPhone.length > 0 ? this.state.GPSProviderContactPhone : null,
      extra: {
        engine_number: this.state.motorSerial,
        electric_motor: this.state.electronicMotor === "1",
        torque: this.state.torque,
        torque_unit: this.state.torqueUnit,
        drive_axle_suspension: this.state.suspension,
        steering_axle_suspension: this.state.directionalSuspension,
        auxiliary_braking_system: this.state.auxBrake === "1",
        hp: this.state.hp,
        type: typeClassIds.length > 0 && typeClassIds[0].typeID
          ? typeClassIds[0].typeID
          : "",
      },
      tag_list: this.state.fleetTags.map(t => ({tags: t})),
      normativity_list: this.generateNormativity().map((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        let extraData = {};
        if(norm.description.includes('seguro')){
          extraData.assurance_company = this.state.assurance_company;
        }
        return {
          code: normData.number ? normData.number : '',
          type: norm.id,
          is_considered: normData.isConsidered ?? false,
          expedition: normData.expedition && normData.expedition !== null ? normData.expedition : null,
          expiration: normData.expiration && normData.expiration !== null ? normData.expiration : null,
          data: extraData
        }
      }),
    };

    if (permissionssToSend.length > 0) {
      dataJson.permissions = permissionssToSend
    }

    let newData = {
      data: JSON.stringify(dataJson),
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
      data_id: id || 0,
    }
    this.generateNormativity().forEach((norm, i) => {
      let normData = this.state[this.getNormPropName(i)] || {};
      normData.files.filter(f => !f.idNormativityImage).forEach((f, fI) => {
        if (f.idNormativityImage === undefined) {
          newData[''.concat(norm.id, '_', fI + 1)] = f;
        }
      })
    });

    this.props.updateFleetDataV2(
      newData, {
      isForm: true,
      onSuccess: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      },
      onError: (e) => {

        let errors = e.response?.data?.errors || [];
        var errorsToShow = [];

        if (errors.length > 0) {
          if (typeof errors[0] === 'string') {
            errorsToShow = errors;
          } else {
            errorsToShow = errors[0];
          }
        } else {
          errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
        }

        this.setState(
          {
            showError: true,
            msgError: handleWSErrorWithKeys(errorsToShow),
            showRegisterError: false,
          }, () => {
            this.props.loadGps();
            this.props.loadHint();
            this.fetchCounter = 0;
            this.responseCounter = 0;
            this.forceUpdate();
          }
        )
      }
    });

    // let newData = {
    //   model: this.state.model,
    //   niv: this.state.VIN,
    //   economic_number: this.state.economic,
    //   year: this.state.year,
    //   color: this.state.color,
    //   plates: this.state.plates,
    //   height: this.state.height,
    //   length: this.state.length,
    //   width: this.state.width,
    //   weight: this.state.weight,
    //   // load: this.state.singleMaxLoad,
    //   axles: this.state.axis,
    //   tires: this.state.tires,
    //   traction_axles_capacity: this.state.tractionCapacity,
    //   traction_axles_capacity_unit: this.state.tractionCapacityUnit,
    //   traction_axles_unit: this.state.unitSystem === "METRIC" ? 0 : 1,
    //   abs: this.state.abs === "1",
    //   // gps_identifier: this.state.GPSIdentifier,
    //   // gps_provider: gpsProvider.id,
    //   // gps_contact: this.state.GPSProviderContactName,
    //   // gps_email: this.state.GPSProviderContactEmail,
    //   // gps_telephone: this.state.GPSProviderContactPhone,
    //   status: this.state.status,
    //   type: 1,
    //   fleet: this.props.fleet ? this.props.fleet.id : "",
    //   brand: hint.id,
    //   fleet_class:
    //     typeClassIds.length > 0 && typeClassIds[0].classID
    //       ? typeClassIds[0].classID
    //       : "",
    // };

    // this.state.GPSIdentifier.length > 0
    //   ? (newData.gps_identifier = this.state.GPSIdentifier)
    //   : console.log("");
    // gpsProvider !== null && gpsProvider.id !== 0
    //   ? (newData.gps_provider = gpsProvider.id)
    //   : console.log("");
    // this.state.GPSProviderContactName.length > 0
    //   ? (newData.gps_contact = this.state.GPSProviderContactName)
    //   : console.log("");
    // this.state.GPSProviderContactEmail.length > 0
    //   ? (newData.gps_email = this.state.GPSProviderContactEmail)
    //   : console.log("");
    // this.state.GPSProviderContactPhone.length > 0
    //   ? (newData.gps_telephone = this.state.GPSProviderContactPhone)
    //   : console.log("");

    // this.props.normativityData.forEach((item) => {
    //   this.fetchCounter += 1;
    //   this.forceUpdate();

    //   switch (`${item.id}`) {
    //     case `${this.state.card.id}`:
    //       this.updateNormativity(item, this.state.card);
    //       break;
    //     case `${this.state.assurance.id}`:
    //       this.updateNormativity(item, this.state.assurance);
    //       break;
    //     case `${this.state.certificate.id}`:
    //       this.updateNormativity(item, this.state.certificate);
    //       break;
    //     case `${this.state.pollution.id}`:
    //       this.updateNormativity(item, this.state.pollution);
    //       break;
    //     case `${this.state.authorization.id}`:
    //       this.updateNormativity(item, this.state.authorization);
    //       break;
    //     default:
    //       break;
    //   }
    // });

    // this.fetchCounter += 1;

    // this.props
    //   .updateFleetData(this.props.fleet ? this.props.fleet.id : "", id, newData)
    //   .then((response) => {
    //     this.responseCounter += 1;
    //     this.forceUpdate();
    //   });

    // if (this.state.dataId !== null || this.props.vehicleData.id === undefined) {
    //   this.saveVehicleData(typeClassIds);
    // } else {
    //   this.updateVehicle(typeClassIds);
    // }
  };

  // updateVehicle = (typeClassIds) => {
  //   const { id = "0" } = this.props.fleetData;

  //   let newVehicleData = {
  //     engine_number: this.state.motorSerial,
  //     // double_load: this.state.doubleMaxLoad,
  //     electric_motor: this.state.electronicMotor === "1",
  //     torque: this.state.torque,
  //     torque_unit: this.state.torqueUnit,
  //     drive_axle_suspension: this.state.suspension,
  //     steering_axle_suspension: this.state.directionalSuspension,
  //     auxiliary_braking_system: this.state.auxBrake === "1",
  //     data: id,
  //     hp: this.state.hp,
  //     type:
  //       typeClassIds.length > 0 && typeClassIds[0].typeID
  //         ? typeClassIds[0].typeID
  //         : "",
  //   };

  //   this.fetchCounter += 1;

  //   this.props
  //     .updateVehicleData(
  //       this.props.fleet ? this.props.fleet.id : "",
  //       id,
  //       this.props.vehicleData ? this.props.vehicleData.id : "",
  //       newVehicleData
  //     )
  //     .then((response) => {
  //       this.responseCounter += 1;
  //       this.forceUpdate();
  //     });
  // };

  // updateNormativity = (currentNormativity, dataToSave) => {
  //   const { id = "0" } = this.props.fleetData;

  //   let newNormativityData = {
  //     code: dataToSave.number ? dataToSave.number : "",
  //     fleet_data: id,
  //     type: currentNormativity.type,
  //     is_considered: dataToSave.isConsidered,
  //   };

  //   // console.log(newNormativityData);

  //   dataToSave.expedition && dataToSave.expedition !== null
  //     ? (newNormativityData.expedition = dataToSave.expedition)
  //     : console.log("");
  //   dataToSave.expiration && dataToSave.expiration !== null
  //     ? (newNormativityData.expiration = dataToSave.expiration)
  //     : console.log("");

  //   if (newNormativityData.code !== "" || dataToSave.changeConsidered) {
  //     this.props
  //       .updateNormativityData(
  //         this.props.fleet ? this.props.fleet.id : "",
  //         id,
  //         currentNormativity.id,
  //         newNormativityData
  //       )
  //       .then((response) => {
  //         this.setState({ normativityUpdated: true });
  //         this.responseCounter += 1;

  //         dataToSave.files.forEach((file) => {
  //           if (file.idNormativityImage === undefined) {
  //             this.fetchCounter += 1;
  //             this.updateFileInNormativity(
  //               { response: { id: currentNormativity.id } },
  //               this.props.fleet ? this.props.fleet.id : "",
  //               id,
  //               file
  //             );
  //           }
  //         });
  //       });
  //   } else {
  //     this.setState({ normativityUpdated: true });
  //     this.responseCounter += 1;
  //   }
  // };

  /////////////////
  ///////   Create Data
  /////////////////

  getNormPropName = (index) => {
    switch (index) {
      case 0:
        return "card";
      case 1:
        return "assurance";
      case 2:
        return "authorization";
      case 3:
        return "certificate";
      case 4:
        return "pollution";
      default:
        return ""
    }
  }

  saveFleetData = (hint, gpsProvider) => {
    const id = this.state.fleetClassId;
    const typeClassIds = this.props.relatedArray.filter((item) => {
      return `${item.id}` === `${id}`;
    });

    // let newData = {
    //   model: this.state.model,
    //   niv: this.state.VIN,
    //   economic_number: this.state.economic,
    //   year: this.state.year,
    //   color: this.state.color,
    //   plates: this.state.plates,
    //   height: this.state.height,
    //   length: this.state.length,
    //   width: this.state.width,
    //   weight: this.state.weight,
    //   // load: this.state.singleMaxLoad,
    //   axles: this.state.axis, //!!!
    //   tires: this.state.tires, //!!!!
    //   traction_axles_capacity: this.state.tractionCapacity,
    //   traction_axles_capacity_unit: this.state.tractionCapacityUnit,
    //   traction_axles_unit: this.state.unitSystem === "METRIC" ? 0 : 1,
    //   abs: this.state.abs === "1",
    //   // gps_identifier: this.state.GPSIdentifier,
    //   // gps_provider: gpsProvider.id,
    //   // gps_contact: this.state.GPSProviderContactName,
    //   // gps_email: this.state.GPSProviderContactEmail,
    //   // gps_telephone: this.state.GPSProviderContactPhone,
    //   status: this.state.status,
    //   type: 1,
    //   fleet: this.props.fleet ? this.props.fleet.id : "",
    //   brand: hint.id,
    //   fleet_class:
    //     typeClassIds.length > 0 && typeClassIds[0].classID
    //       ? typeClassIds[0].classID
    //       : "",
    // };

    this.fetchCounter += 1;

    const permissionssToSend = this.state.permissions.filter(item => {
      return parseInt(item.sct || '0') !== 0
    }).map(item => {
      return {
        id: parseInt(item.sct || '0'),
        number: item.number || ''
      };
    });

    let dataJson = {
      model: this.state.model,
      niv: this.state.VIN,
      economic_number: this.state.economic,
      year: this.state.year,
      color: this.state.color,
      plates: this.state.plates,
      height: this.state.height,
      length: this.state.length,
      width: this.state.width,
      weight: this.state.weight,
      traction_axles_capacity: this.state.tractionCapacity,
      traction_axles_capacity_unit: this.state.tractionCapacityUnit,
      traction_axles_unit: this.state.unitSystem === "METRIC" ? 0 : 1,
      abs: this.state.abs === "1",
      status: this.state.status,
      type: 1,
      brand: hint.id,
      fleet_class: typeClassIds.length > 0 && typeClassIds[0].classID
        ? typeClassIds[0].classID
        : "",
      gps_identifier: this.state.GPSIdentifier.length > 0 ? this.state.GPSIdentifier : null,
      gps_provider: gpsProvider !== null && gpsProvider.id !== 0 ? gpsProvider.id : null,
      gps_contact: this.state.GPSProviderContactName.length > 0 ? this.state.GPSProviderContactName : null,
      gps_email: this.state.GPSProviderContactEmail.length > 0 ? this.state.GPSProviderContactEmail : null,
      gps_telephone: this.state.GPSProviderContactPhone.length > 0 ? this.state.GPSProviderContactPhone : null,
      extra: {
        engine_number: this.state.motorSerial,
        electric_motor: this.state.electronicMotor === "1",
        torque: this.state.torque,
        torque_unit: this.state.torqueUnit,
        drive_axle_suspension: this.state.suspension,
        steering_axle_suspension: this.state.directionalSuspension,
        auxiliary_braking_system: this.state.auxBrake === "1",
        hp: this.state.hp,
        type: typeClassIds.length > 0 && typeClassIds[0].typeID
          ? typeClassIds[0].typeID
          : "",
      },
      tag_list: this.state.fleetTags.map(t => ({tags: t})),
      normativity_list: this.generateNormativity().map((norm, i) => {
        let normData = this.state[this.getNormPropName(i)] || {};
        let extraData = {};
        if(norm.description.includes('seguro')){
          extraData.assurance_company = this.state.assurance_company;
        }
        return {
          code: normData.number ? normData.number : '',
          type: norm.id,
          is_considered: normData.isConsidered,
          expedition: normData.expedition && normData.expedition !== null ? normData.expedition : null,
          expiration: normData.expiration && normData.expiration !== null ? normData.expiration : null,
          data: extraData
        }
      }),
    };

    if (permissionssToSend.length > 0) {
      dataJson.permissions = permissionssToSend
    }

    let newData = {
      data: JSON.stringify(dataJson),
      company_id: this.props.fleet?.company || 0,
      fleet_id: this.props.fleet?.id || 0,
    }
    this.generateNormativity().forEach((norm, i) => {
      let normData = this.state[this.getNormPropName(i)] || {};
      normData.files.forEach((f, fI) => {
        newData[''.concat(norm.id, '_', fI + 1)] = f;
      })
    });
    this.props.createFleetDataV2(
      newData, {
      isForm: true,
      onSuccess: () => {
        this.responseCounter += 1;
        this.forceUpdate();
      },
      onError: (e) => {

        let errors = e.response?.data?.errors || [];
        var errorsToShow = [];

        if (errors.length > 0) {
          if (typeof errors[0] === 'string') {
            errorsToShow = errors;
          } else {
            errorsToShow = errors[0];
          }
        } else {
          errorsToShow = ['Ocurrió un error al guardar, verifica los datos'];
        }

        this.setState(
          {
            showError: true,
            msgError: handleWSErrorWithKeys(errorsToShow),
            showRegisterError: false,
          }, () => {
            this.props.loadGps();
            this.props.loadHint();
            this.fetchCounter = 0;
            this.responseCounter = 0;
            this.forceUpdate();
          }
        )
      }
    });
    // this.props
    //   .createFleetData(this.props.fleet ? this.props.fleet.id : "", newData)
    //   .then((response) => {
    //     let error = null;
    //     switch (response.type) {
    //       case "FLEET_DATA_POST_SUCCESS":
    //         this.saveTags();
    //         this.saveVehicleData(typeClassIds);
    //         this.generateNormativity().forEach((item, index) => {
    //           switch (index) {
    //             case 0:
    //               this.saveNormativityData(item.id, this.state.card);
    //               break;
    //             case 1:
    //               this.saveNormativityData(item.id, this.state.assurance);
    //               break;
    //             case 2:
    //               this.saveNormativityData(item.id, this.state.authorization);
    //               break;
    //             case 3:
    //               this.saveNormativityData(item.id, this.state.certificate);
    //               break;
    //             case 4:
    //               this.saveNormativityData(item.id, this.state.pollution);
    //               break;
    //             default:
    //               break;
    //           }
    //         });
    //         this.responseCounter += 1;
    //         this.forceUpdate();
    //         break;
    //       case "FLEET_DATA_POST_ERROR":
    //         error = "Ocurrió un error al registrar, verifica los datos";

    //         if (response.error) {
    //           if (response.error.niv) {
    //             error = `El NIV "${this.state.VIN}" ya existe`;
    //           } else if (String(response.error).includes("economic_number")) {
    //             error = `El N° económico "${this.state.economic}" ya existe`;
    //           } else if (String(response.error).includes("gps_identifier")) {
    //             error = `El Identificador GPS "${this.state.GPSIdentifier}" ya existe`;
    //           }
    //         }

    //         break;
    //       default:
    //         error = "Ocurrió un error al registrar, verifica los datos";
    //         break;
    //     }

    //     if (error !== null) {
    //       this.setState(
    //         {
    //           showError: true,
    //           msgError: error,
    //           showRegisterError: false,
    //         },
    //         () => {
    //           this.props.loadGps();
    //           this.props.loadHint();
    //           this.fetchCounter = 0;
    //           this.responseCounter = 0;
    //           this.forceUpdate();
    //         }
    //       );
    //     }
    //   });
  };

  // saveTags = () => {
  //   const { id = "0" } = this.props.fleetData;

  //   this.state.fleetTags.forEach((item) => {
  //     this.props.addTag(this.props.fleet ? this.props.fleet.id : "", id, {
  //       data: id,
  //       tags: item,
  //     });
  //   });
  // };

  // saveNormativityData = (normativityType, dataToSave) => {
  //   const { id = "0" } = this.props.fleetData;

  //   let newNormativityData = {
  //     code: dataToSave.number ? dataToSave.number : "",
  //     fleet_data: id,
  //     type: normativityType,
  //     is_considered: dataToSave.isConsidered,
  //   };

  //   //console.log("fecha", dataToSave.expedition);

  //   dataToSave.expedition && dataToSave.expedition !== null
  //     ? (newNormativityData.expedition = dataToSave.expedition)
  //     : console.log("");
  //   dataToSave.expiration && dataToSave.expiration !== null
  //     ? (newNormativityData.expiration = dataToSave.expiration)
  //     : console.log("");

  //   this.fetchCounter += 1;

  //   this.props
  //     .createNormativityData(
  //       this.props.fleet ? this.props.fleet.id : "",
  //       id,
  //       newNormativityData
  //     )
  //     .then((response) => {
  //       this.setState({ normativityUpdated: true });
  //       this.responseCounter += 1;
  //       this.forceUpdate();

  //       if (
  //         response &&
  //         response.response &&
  //         response.response.type === "FLEET_NORMATIVITY_POST_ERROR"
  //       ) {
  //         this.setState(
  //           {
  //             showError: true,
  //             msgError: "test",
  //             showRegisterError: false,
  //           },
  //           () => {
  //             this.props.loadGps();
  //             this.props.loadHint();
  //             this.responseCounter = 0;
  //             this.fetchCounter = 0;
  //             this.forceUpdate();
  //           }
  //         );
  //       } else {
  //         dataToSave.files.forEach((file) => {
  //           this.fetchCounter += 1;
  //           this.updateFileInNormativity(
  //             response,
  //             this.props.fleet ? this.props.fleet.id : "",
  //             id,
  //             file
  //           );
  //         });
  //       }
  //     });
  // };

  // updateFileInNormativity = (normativityData, fleetId, fleetDataId, file) => {
  //   const {
  //     response: { id = "0" },
  //   } = normativityData;

  //   this.props
  //     .uploadImageToNormativity(fleetId, fleetDataId, id, file)
  //     .then((response) => {
  //       this.setState({ normativityUpdated: true });
  //       this.responseCounter += 1;
  //       this.forceUpdate();
  //     });
  // };

  // saveVehicleData = (typeClassIds) => {
  //   const { id = "0" } = this.props.fleetData;

  //   let newVehicleData = {
  //     engine_number: this.state.motorSerial,
  //     // double_load: this.state.doubleMaxLoad,
  //     electric_motor: this.state.electronicMotor === "1",
  //     torque: this.state.torque,
  //     torque_unit: this.state.torqueUnit,
  //     drive_axle_suspension: this.state.suspension,
  //     steering_axle_suspension: this.state.directionalSuspension,
  //     auxiliary_braking_system: this.state.auxBrake === "1",
  //     data: id,
  //     hp: this.state.hp,
  //     type:
  //       typeClassIds.length > 0 && typeClassIds[0].typeID
  //         ? typeClassIds[0].typeID
  //         : "",
  //   };

  //   this.fetchCounter += 1;

  //   this.props
  //     .createVehicleData(
  //       this.props.fleet ? this.props.fleet.id : "",
  //       id,
  //       newVehicleData
  //     )
  //     .then((response) => {
  //       let error = null;
  //       switch (response.type) {
  //         case "FLEET_VEHICLE_TYPE_POST_SUCCESS":
  //           this.responseCounter += 1;
  //           this.forceUpdate();
  //           break;
  //         case "FLEET_VEHICLE_TYPE_POST_ERROR":
  //           error = "Ocurrió un error al registrar, verifica los datos";

  //           if (response.error) {
  //             if (response.error.engine_number) {
  //               error = `El número de motor "${this.state.motorSerial}" ya existe`;
  //             }
  //           }

  //           break;
  //         default:
  //           error = "Ocurrió un error al registrar, verifica los datos";
  //           break;
  //       }

  //       if (error !== null) {
  //         this.setState(
  //           {
  //             showError: true,
  //             msgError: error,
  //             modeEdit: true,
  //             dataId: id,
  //           },
  //           () => {
  //             this.fetchCounter = 0;
  //             this.responseCounter = 0;

  //             this.forceUpdate();
  //           }
  //         );
  //       }
  //     });
  // };

  setClassInfo = (id) => {
    const info = this.props.relatedArray.filter((item) => {
      return `${item.id}` === `${id}`;
    });
    const classInfo = this.props.fleetClass.filter((item) => {
      return info.length > 0 ? info[0].classID === `${item.id}` : false;
    });

    if (classInfo.length > 0) {
      this.setState({
        tires: classInfo[0].tires,
        axis: classInfo[0].axles,
      });
    }
  };

  generateNormativity = () => {
    let results = [];

    this.props.normativityType
      .filter((item) => {
        return `${item.type}` === "1";
      })
      .forEach((item, index) => {
        let newData = {
          id: item.id,
          description: item.description,
          documentType: DocumentTypes.file,
          label: "N° ",
          name: namesNormativity[index],
          considered: item.is_considered_displayed ?? false,
        };

        results.push(newData);
      });

    return results;
  };

  deleteImageFromNormativity = (e, obj) => {
    const filesToUpdate = obj.files;
    const filesToCompare = e.target.value;
    let fileToRemove = null;

    if (filesToCompare.length > 0) {
      const differents = filesToUpdate.filter(
        ({ idNormativityImage: id1 }) =>
          !filesToCompare.some(({ idNormativityImage: id2 }) => id2 === id1)
      );

      if (differents.length > 0) {
        fileToRemove = differents[0];
      }
    } else {
      if (filesToUpdate.length > 0) fileToRemove = filesToUpdate[0];
    }

    if (fileToRemove && fileToRemove.idNormativityImage) {
      this.setState({
        fileIdToRemove: fileToRemove.idNormativityImage,
        normativityIdToRemove: obj.id,
        showConfirmDialog: true,
      });
    }
  };

  deleteImage = () => {
    this.setState({ normativityUpdated: true });
    this.props.deleteNormativityImagesForFleetData(
      parseInt(this.props.match.params.vehicleId),
      this.props.fleet.id,
      this.state.normativityIdToRemove,
      this.state.fileIdToRemove
    );
  };

  savingDataText = () => {
    if (this.state.modeEdit) {
      return this.fetchCounter > 0 ? "Guardando..." : "Guardar";
    } else {
      return this.fetchCounter > 0 ? "Registrando..." : "Registrar";
    }
  };

  validateTagData = () => {
    if (this.state.fleetTags.length === 0) {
      return false;
    }
    return true;
  };

  validIdGps = () => {
    if (this.state.GPSIdentifierLast.length > 0 && this.state.modeEdit) {
      return true;
    }
    return false;
  };

  validateValue = (val1, val2, index) => {
    if (index === '0') {
      return (val1 !== 0 && val2 === '') || (val1 === 0 && val2 !== '');
    } else {
      return (val1 === 0 || val2 === '');
    }
  }

  setPermissions = () => { 

    var itemsToShow = [];

    for (var i = 0; i < this.state.permissions.length; i++) {
      var permissionItem = [...this.state.permissions][i];

      var itemToAdd = (
        <div className="specification-data" key={i}>
          <SelectInput
            key={`${i}-selector`}
            label="Permiso SCT"
            name={`${i}`}
            value={permissionItem.sct}
            className="item"
            validate={ this.validateValue(permissionItem.sct, permissionItem.number, `${i}`) ? [validators.required] : []}
            onChange={(e) => {
              var permisionToUpdate = this.state.permissions[parseInt(e.target.name)];
              permisionToUpdate.sct = e.target.value;
              this.setState({});
            }}
            items={this.props.permissions}
            onFocused={this.state.onFocused}
          />
          <TextInput
            key={`${i}-text`}
            label="Número de permiso"
            name={`${i}`}
            value={permissionItem.number}
            className="item"
            validate={ this.validateValue(permissionItem.sct, permissionItem.number, `${i}`) ? [validators.required] : []}
            onChange={(e) => {
              var permisionToUpdate = this.state.permissions[parseInt(e.target.name)];
              permisionToUpdate.number = e.target.value.replace(/ /g, '');
              this.setState({});
            }}
            onFocused={this.state.onFocused}
          />
          {
            i === 0 ? 
            <div style={{display: 'flex'}}>
              {
                (permissionItem.number !== "" || permissionItem.sct !== 0) ? 
                <div style={{display: 'flex', alignItems: 'center', paddingTop: '20px', marginLeft: '10px'}}>
                  <img
                    key={`${i}-delete`}
                    alt={`${i}`}
                    src='/images/delete_icon.png'
                    style={{ cursor: 'pointer', height: '17px', width: '15px'}}
                    onClick={(e) => {
                      
                      if (this.state.permissions.length > 1) {
                        this.state.permissions.splice(0, 1);
                      } else {
                        this.state.permissions[0].number = '';
                        this.state.permissions[0].sct = 0;
                      }

                      this.setState({});
                    }}
                  />
                </div>
                :
                <div></div>
              }

              <div
                style={{color: 'white', fontSize: '12', display: 'flex', alignItems: 'center', marginLeft: '10px', cursor: 'pointer', paddingTop: '20px'}}
                onClick={() => {
                  this.state.permissions = this.state.permissions.concat({
                    sct: 0,
                    number: ''
                  });
                  this.setState({});
                }}
              > + Añadir otro permiso </div>
            </div> 
            :
            <div style={{display: 'flex', alignItems: 'center', paddingTop: '20px', marginLeft: '10px'}}>
              <img
                key={`${i}-delete`}
                alt={`${i}`}
                src='/images/delete_icon.png'
                style={{ cursor: 'pointer', height: '17px', width: '15px'}}
                onClick={(e) => {
                  this.state.permissions.splice(parseInt(e.currentTarget.alt), 1);
                  this.setState({});
                }}
              />
            </div>
          }
        </div>
      );

      itemsToShow = itemsToShow.concat(itemToAdd);
    }

    return(
      <div>
        {itemsToShow}
      </div>
    );

  }

  validateUniqueFields = () => {
    
    this.setState({
      validatingFields: true
    }); 

    if (String(this.state.ownPlates) !== String(this.state.plates)) {
      this.props.validatePlates(this.props.fleet.id, this.state.plates).then((platesResponse) => {

        if (platesResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `Las placas "${this.state.plates}" estan en uso`
          });
        } else {
          this.validateNIVField();
        }

      });
    } else {
      this.validateNIVField();
    }

  }

  validateNIVField = () => {

    if (String(this.state.ownVIN) !== String(this.state.VIN)) {

      this.props.postValidateNiv(this.props.fleet.id, this.state.VIN).then((vinResponse) => {

        if (vinResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `El NIV "${this.state.VIN}" esta en uso`
          });
        } else {
          this.validateEconomicField();
        }

      });

    } else {
      this.validateEconomicField();
    }

  }

  validateEconomicField = () => {

    if (String(this.state.ownEconomic) !== String(this.state.economic)) {

      this.props.postValidateEconomicNumber(this.props.fleet.id, this.state.economic).then((vinResponse) => {

        if (vinResponse.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `El N economico "${this.state.economic}" esta en uso`
          });
        } else {
          this.validateEngineNumber();
        }

      });

    } else {
      this.validateEngineNumber();
    }

  }

  validateEngineNumber = () => {

    if (String(this.state.ownMotor) !== String(this.state.motorSerial)) {
      this.props.postValidateEngineNumber(this.props.fleet.id, this.state.motorSerial).then((response) => {

        if (response.type.includes('ERROR')) {
          this.setState({
            validatingFields: false,
            showError: true,
            msgError: `El N° Motor "${this.state.motorSerial}" esta en uso`
          });

        } else {
          this.setState({
            validatingFields: false,
          });
          setTimeout(this.handleSaveFleetData , 200);
        }

      });
    } else {
      this.setState({
        validatingFields: false,
      });
      setTimeout(this.handleSaveFleetData , 200);
    }

  }

  render() {
    return (
      <div className="fleet-vehicle-view view">
        <div className="title">
          {this.props.match &&
          this.props.match.params &&
          this.props.match.params.vehicleId
            ? "Información de vehículo"
            : "Registro de vehículo"}
        </div>
        <Panel title="Datos Generales" number="1.0">
          <div className="general-data  text-without-arrow">
            <SelectInput
              label="Tipo/Clase de vehiculo *"
              name="fleetClassId"
              value={this.state.fleetClassId}
              className="item"
              onChange={(e) => {
                this.handleInput(e);
                this.setClassInfo(e.target.value);
              }}
              items={this.props.relatedArray}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
            <TextInput
              validate={[validators.required]}
              label="N° económico *"
              name="economic"
              value={this.state.economic}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedEconomic)
                  this.setState({ dupliedEconomic: false });
                this.handleInput(e);
              }}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedEconomic
                  ? [`El N° económico "${this.state.economic}" esta en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownEconomic) !== String(e.target.value)) {
                  this.props
                    .postValidateEconomicNumber(
                      this.props.fleet.id,
                      e.target.value
                    )
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `El N° económico "${this.state.economic}"`,
                        "economic"
                      );
                    });
                }
              }}
            />
            <TextInput
              label="Marca *"
              name="brand"
              value={this.state.brand}
              className="item"
              onChange={this.handleInput}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />

            <TextInput
              label="Modelo *"
              name="model"
              value={this.state.model}
              className="item"
              onChange={this.handleInput}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
            {/* <TextInput
              label='Serie'
              name='serial'
              value={this.state.serial}
              className='item'
              onChange={this.handleInput}
            /> */}
            <TextInput
              type="number"
              label="Año *"
              name="year"
              value={this.state.year}
              className="item"
              onChange={this.handleInput}
              numeric={true}
              length={4}
              suffix={{
                element: <SVGIcon fill="#AFC8FF" name={icons.calendar} />,
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
            <TextInput
              label="Placas *"
              name="plates"
              value={this.state.plates}
              className="item"
              // onChange={this.handleInput}
              onChange={(e) => {
                if (this.state.dupliedPlates)
                  this.setState({ dupliedPlates: false });
                this.handleInput(e);
              }}
              length={10}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedPlates
                  ? [`Las placas "${this.state.plates}" estan en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownPlates) !== String(e.target.value)) {
                  this.props
                    .validatePlates(this.props.fleet.id, e.target.value)
                    .then((response) => {
                      console.log("PlACAS: ", response);
                      this.handleValidate(
                        response,
                        `Las placas "${this.state.plates}"`,
                        "plates"
                      );
                    });
                }
              }}
            />
            <TextInput
              label="NIV (Número de identificación vehicular) *"
              name="VIN"
              value={this.state.VIN}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedVIN) this.setState({ dupliedVIN: false });
                this.handleInput(e);
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedVIN
                  ? [`El NIV "${this.state.VIN}" esta en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownVIN) !== String(e.target.value)) {
                  this.props
                    .postValidateNiv(this.props.fleet.id, e.target.value)
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `El NIV "${this.state.VIN}"`,
                        "VIN"
                      );
                    });
                }
              }}
            />
            <TextInput
              label="N° Motor *"
              name="motorSerial"
              value={this.state.motorSerial}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedMotor)
                  this.setState({ dupliedMotor: false });
                this.handleInput(e);
              }}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              errors={
                this.state.dupliedMotor
                  ? [`El N° Motor "${this.state.motorSerial}" esta en uso`]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownMotor) !== String(e.target.value)) {
                  this.props
                    .postValidateEngineNumber(
                      this.props.fleet.id,
                      e.target.value
                    )
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `El N° Motor "${this.state.motorSerial}"`,
                        "motor"
                      );
                    });
                }
              }}
            />
            <ColorSelector
              label="Color *"
              name="color"
              value={this.state.color}
              className="item"
              onChange={this.handleInput}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
          </div>
          <div className="fleet-tags">
            <ChipContainer
              items={this.fleetTagsToChips()}
              onChange={this.handleTagChange}
              title="Etiquetas"
              name="fleetTags"
            />
          </div>
        </Panel>
        <Panel title="Especificaciones técnicas y mecánicas" number="2.0">
          <div className="specification">
            <FleetSpecificationVehicle
              title="Especificaciones:"
              unitSystem={this.state.unitSystem}
              height={this.state.height}
              weight={this.state.weight}
              length={this.state.length}
              width={this.state.width}
              // singleMaxLoad={this.state.singleMaxLoad}
              // doubleMaxLoad={this.state.doubleMaxLoad}
              onChange={this.handleInput}
            />

            {this.setPermissions()}

            <div className="specification-data">
              <SelectInput
                label="Motor electrónico *"
                name="electronicMotor"
                value={this.state.electronicMotor}
                className="item"
                onChange={this.handleInput}
                items={[
                  { id: 1, description: "Si" },
                  { id: 2, description: "No" },
                ]}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />
              <TextInput
                label="Torque *"
                name="torque"
                value={this.state.torque}
                className="item"
                onChange={this.handleInput}
                validate={[validators.required]}
                numeric={true}
                length={5}
                onFocused={this.state.onFocused}
                suffix={{
                  element: (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "5px",
                      }}
                    >
                      <RadioGroup
                        items={[
                          {
                            value: "1",
                            label: "lb-ft",
                          },
                          {
                            value: "2",
                            label: "N-m",
                          },
                        ]}
                        radioName={"torqueUnit"}
                        defaultValue={this.state.torqueUnit}
                        onClickHandler={this.handleInput}
                      />
                    </div>
                  ),
                }}
              />
              <TextInput
                label="No. de Ejes"
                name="axis"
                value={this.state.axis}
                className="item"
                onChange={this.handleInput}
                naturalNumber={true}
                disabled={true}
              />
              <TextInput
                label="No. de Llantas"
                name="tires"
                value={this.state.tires}
                className="item"
                onChange={this.handleInput}
                naturalNumber={true}
                disabled={true}
              />
              <TextInput
                label="Capacidad de los ejes de tracción *"
                name="tractionCapacity"
                value={this.state.tractionCapacity}
                className="item"
                onChange={this.handleInput}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
                numeric={true}
                suffix={{
                  element: (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "5px",
                      }}
                    >
                      <RadioGroup
                        items={[
                          {
                            value: "1",
                            label: "lb-ft",
                          },
                          {
                            value: "2",
                            label: "N-m",
                          },
                        ]}
                        radioName={"tractionCapacityUnit"}
                        defaultValue={this.state.tractionCapacityUnit}
                        onClickHandler={this.handleInput}
                      />
                    </div>
                  ),
                }}
              />
              <SelectInput
                label="Suspensión de motriz *"
                name="suspension"
                value={this.state.suspension}
                className="item"
                onChange={this.handleInput}
                items={this.props.driveAxleSuspension.map((item) => {
                  return { id: item.id, description: item.description };
                })}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />
              <SelectInput
                label="Suspensión eje direccional *"
                name="directionalSuspension"
                value={this.state.directionalSuspension}
                className="item"
                onChange={this.handleInput}
                items={this.props.steeringAxleSuspension.map((item) => {
                  return { id: item.id, description: item.description };
                })}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />
              <SelectInput
                label="Freno auxiliar *"
                name="auxBrake"
                value={this.state.auxBrake}
                className="item"
                onChange={this.handleInput}
                items={[
                  { id: 1, description: "Si" },
                  { id: 2, description: "No" },
                ]}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />
              <SelectInput
                label="Sistema anti-bloqueo de frenos *"
                name="abs"
                value={this.state.abs}
                className="item"
                onChange={this.handleInput}
                items={[
                  { id: 1, description: "Si" },
                  { id: 2, description: "No" },
                ]}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />
              <TextInput
                label="Caballos de fuerza *"
                name="hp"
                value={this.state.hp}
                className="item"
                onChange={this.handleInput}
                naturalNumber={true}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
                suffix={{
                  element: (
                    <i
                      style={{
                        color: "#9ba1ac",
                        width: "50px",
                      }}
                    >
                      {units["horsePower"][this.state.unitSystem]}
                    </i>
                  ),
                }}
              />
            </div>
          </div>
        </Panel>

        {
          !this.state.allowNullNorm ? 
          <Panel title="Normatividad" number="3.0">
            <Table
              headerContent={normativityHeaderContent}
              className="normativity-table"
            >
              {this.generateNormativity().map((ni) => (
                <NormativityTableItem
                  className="row"
                  id={ni.id}
                  extraInfo={this.state.plates}
                  description={ni.description}
                  documentType={ni.documentType}
                  key={ni.id}
                  name={ni.name}
                  value={this.state[ni.name]}
                  considered={ni.considered}
                  extraField={ni.description.includes('seguro') 
                    ? (
                      <TextInput
                        label="Nombre de aseguradora*"
                        name="assurance_company"
                        value={this.state.assurance_company}
                        className="item inline-input"
                        onChange={this.handleInput}
                        validate={[validators.required]}
                        onFocused={this.state.onFocused}
                      />) 
                    : null}
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  showLimitFiles={() =>
                    this.setState({ showFilesMessageError: true })
                  }
                  handleInputCheckbox={this.handleInputCheckbox}
                  confirmAction={
                    this.state.modeEdit
                      ? (e, action) => {
                          this.confirmDeleteAction = action;

                          switch (e.target.name) {
                            case "card":
                              this.deleteImageFromNormativity(e, this.state.card);
                              break;

                            case "assurance":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.assurance
                              );
                              break;

                            case "certificate":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.certificate
                              );
                              break;

                            case "pollution":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.pollution
                              );
                              break;

                            case "authorization":
                              this.deleteImageFromNormativity(
                                e,
                                this.state.authorization
                              );
                              break;

                            default:
                              break;
                          }
                        }
                      : null
                  }
                />
              ))}
            </Table>
          </Panel> : <div/>
        }

        {/* <Panel title="Proveedor GPS" number="4.0">
          <div className="gps-provider-data">
            <TextInput
              label="Identificador GPS"
              name="GPSIdentifier"
              value={this.state.GPSIdentifier}
              className="item"
              onChange={(e) => {
                if (this.state.dupliedGps) this.setState({ dupliedGps: false });
                this.handleInput(e);
              }}
              // validate={[validators.required]}
              // onFocused={this.state.onFocused}
              errors={
                this.state.dupliedGps
                  ? [
                      `El Identificador GPS "${this.state.GPSIdentifier}" esta en uso`,
                    ]
                  : []
              }
              handleBlur={(e) => {
                if (String(this.state.ownGps) !== String(e.target.value)) {
                  this.props
                    .postValidateGpsIdentifier(
                      this.props.fleet.id,
                      e.target.value
                    )
                    .then((response) => {
                      this.handleValidate(
                        response,
                        `El Identificador GPS "${this.state.GPSIdentifier}"`,
                        "gps"
                      );
                    });
                }
              }}
              //disabled={this.validIdGps()}
            /> 
            <TextInput
              label="Proveedor GPS"
              name="GPSProvider"
              value={this.state.GPSProvider}
              className="item"
              onChange={(e) => {
                this.handleInput(e);

                this.props.gpsProvider.forEach((item) => {
                  if (item.name === e.target.value) {
                    const emailValue = item.email ? item.email : "";
                    const telephoneValue = item.telephone ? item.telephone : "";

                    this.setState({
                      GPSProviderContactEmail:
                        this.state.GPSProviderContactEmail === ""
                          ? emailValue
                          : this.state.GPSProviderContactEmail,
                      GPSProviderContactPhone:
                        this.state.GPSProviderContactPhone === ""
                          ? telephoneValue
                          : this.state.GPSProviderContactPhone,
                    });
                  }
                });
              }}
              // onFocused={this.state.onFocused}
              // validate={[validators.required]}
            />
            <TextInput
              label="Nombre del contacto Proveedor GPS"
              name="GPSProviderContactName"
              value={this.state.GPSProviderContactName}
              className="item"
              onChange={this.handleInput}
              // validate={[validators.required]}
              // onFocused={this.state.onFocused}
            />
            <TextInput
              label="E-mail de contacto Proveedor GPS"
              name="GPSProviderContactEmail"
              value={this.state.GPSProviderContactEmail}
              className="item"
              onChange={this.handleInput}
              // validate={[validators.required]}
              // onFocused={this.state.onFocused}
            />
            <TextInput
              label="Teléfono de contacto Proveedor GPS"
              name="GPSProviderContactPhone"
              value={this.state.GPSProviderContactPhone}
              className="item"
              numeric={true}
              onChange={this.handleInput}
              // validate={[validators.required]}
              // onFocused={this.state.onFocused}
            />
          </div>
        </Panel>*/}
        <div className="actions">
          <Button
            settings={{
              type: "outline primary white",
              text: "Cancelar",
              onClick: this.onClickBack,
            }}
          />

          <Button
            settings={{
              type: "primary",
              disabled: this.fetchCounter > 0 || !this.validateForm() || this.state.validatingFields,
              text: this.savingDataText(),
              onClick: () => {
                this.validateUniqueFields();
              }
            }}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <ErrorDialog
          open={this.state.showRegisterError}
          message={
            "Ocurrió un error al registrar tus datos, actualiza los datos"
          }
          acceptText="OK"
          acceptAction={() => {
            this.props.history.goBack();
            this.actionCloseDialog();
          }}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showFilesSizeMessageError}
          message={"El archivo que intenta subir sobrepasa los 12 MB."}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showFilesMessageError}
          message={"El numero de archivos permitido es de 3"}
          acceptText="Aceptar"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ConfirmDialog
          open={this.state.showConfirmDialog}
          title="Eliminar imagen"
          message={"¿Estás seguro que deseas eliminar la imagen?"}
          //contentObject={<div>{this.props.tagList.filter(t => this.state.selectedTags.includes(t.id)).map(tag => <Tag id={tag.id} title={tag.title} color={tag.color} />)}</div>}
          acceptText="Eliminar"
          class="delete-tags"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={this.actionCloseDialog}
          acceptAction={() => {
            this.actionCloseDialog();
            this.confirmDeleteAction();
            this.deleteImage();
          }}
        />
        <ConfirmDialog
          open={this.state.saveExit || false}
          title="Actualizando estatus de normatividad"
          disabledAccept={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const country = state.globalCatalog.session.company.country__description;
  const permissions = (getEntityItems(state, "COMPANIES.SAT.PERMISSIONS") || []).map( item => {
    return {
      id: item.id,
      description: item.descripcion
    };
  });
  const types = Array.isArray(state.fleetReducer.vehicleType.data)
    ? state.fleetReducer.vehicleType.data
    : [];
  const classes = Array.isArray(state.fleetReducer.fleetClass.data)
    ? state.fleetReducer.fleetClass.data
    : [];

  let relatedArray = [];

  if (types.length > 0 && classes.length > 0) {
    relatedArray = joinRelatedArrays(types, classes, "type");
  }

  const fleets = state.fleetReducer.fleets.data;

  return {
    permissions,
    country,
    toast: state.globalCatalog.toast,
    fleetData: state.fleetReducer.fleetData.data,
    vehicleData:
      Array.isArray(state.fleetReducer.vehicles.data) &&
      state.fleetReducer.vehicles.data.length > 0
        ? state.fleetReducer.vehicles.data[0]
        : {},
    tagsData: state.fleetReducer.tags.data,
    normativityData: state.fleetReducer.normativities.data,
    normativityDataImages: state.fleetReducer.normativityImages.data,
    steeringAxleSuspension: Array.isArray(
      state.fleetReducer.steeringAxleSuspension.data
    )
      ? state.fleetReducer.steeringAxleSuspension.data
      : [],
    driveAxleSuspension: Array.isArray(
      state.fleetReducer.driveAxleSuspension.data
    )
      ? state.fleetReducer.driveAxleSuspension.data
      : [],
    gpsProvider: Array.isArray(state.fleetReducer.gpsProvider.data)
      ? state.fleetReducer.gpsProvider.data
      : [],
    hints: Array.isArray(state.fleetReducer.hints.data)
      ? state.fleetReducer.hints.data
      : [],
    normativityType: Array.isArray(state.fleetReducer.normativityType.data)
      ? state.fleetReducer.normativityType.data
      : [],
    fleet: Array.isArray(fleets) && fleets.length > 0 ? fleets[0] : null,
    relatedArray: relatedArray,
    equipmentTypes: Array.isArray(state.fleetReducer.equipmentType.data)
      ? state.fleetReducer.equipmentType.data
      : [],
    vehicleType: types,
    fleetClass: classes,
    fleetTagsCatalog: Array.isArray(state.tags.tags)
      ? state.tags.tags
          .filter(
            (tag) =>
              tag.type ===
              (
                (Array.isArray(state.tags.tagTypes.data)
                  ? state.tags.tagTypes.data
                  : []
                ).find(
                  (type) => type.description.toLowerCase() === "flotilla"
                ) || {}
              ).id
          )
          .map((tag) => ({
            id: tag.id,
            description: tag.title,
            color: tag.color,
          }))
      : [],
  };
};

const namesNormativity = [
  "card",
  "assurance",
  "authorization",
  "certificate",
  "pollution",
];

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadFleetClasses());
  dispatch(loadTags());
  dispatch(loadFleets());
  dispatch(loadVehicleTypes());
  dispatch(loadNormativityTypes());
  dispatch(loadHints());
  dispatch(loadGpsProvider());
  dispatch(loadDriveAxleSuspension());
  dispatch(loadSteeringAxleSuspension());

  return {
    loadPermissions: (params, opt) => dispatch( readEntities("COMPANIES.SAT.PERMISSIONS", params, opt) ),
    showToast: (payLoad) => dispatch(addToast(payLoad)),
    //Create
    createFleetDataV2: (params, opt) => 
      dispatch(createEntity("COMPANIES.FLEETS.DATA.VEHICLES", params, opt)),
    createFleetData: (fleetId, fleetDataObj) =>
      dispatch(createFleetData(fleetId, fleetDataObj)),
    loadFleetData: (fleetDataId, fleetId) =>
      dispatch(loadFleetData(fleetDataId, fleetId)),
    loadVehicles: (fleetDataId, fleetId) =>
      dispatch(loadVehicles(fleetDataId, fleetId)),
    loadTagsForFleetData: (fleetDataId, fleetId) =>
      dispatch(loadTagsForFleetData(fleetDataId, fleetId)),
    loadNormativityForFleetData: (fleetDataId, fleetId) =>
      dispatch(loadNormativityForFleetData(fleetDataId, fleetId)),
    loadNormativityImagesForFleetData: (fleetDataId, fleetId, normId) =>
      dispatch(loadNormativityImagesForFleetData(fleetDataId, fleetId, normId)),
    deleteNormativityImagesForFleetData: (
      fleetDataId,
      fleetId,
      normId,
      imageId
    ) =>
      dispatch(
        deleteNormativityImagesForFleetData(
          fleetDataId,
          fleetId,
          normId,
          imageId
        )
      ),
    updateNormativityData: (fleetId, fleetDataId, normId, normativityData) =>
      dispatch(
        updateNormativityData(fleetId, fleetDataId, normId, normativityData)
      ),
    deleteTagInFleetData: (fleetDataId, fleetId, tagId) =>
      dispatch(deleteTagInFleetData(fleetDataId, fleetId, tagId)),
    //Update
    updateFleetDataV2: (params, opt) => 
      dispatch(partialEntity("COMPANIES.FLEETS.DATA.VEHICLES", params, opt)),
    updateFleetData: (fleetId, fleetDataId, fleetDataObj) =>
      dispatch(updateFleetData(fleetId, fleetDataId, fleetDataObj)),
    updateVehicleData: (fleetId, fleetDataId, vehicleId, vehicleData) =>
      dispatch(updateVehicleData(fleetId, fleetDataId, vehicleId, vehicleData)),

    createVehicleData: (fleetId, fleetDataId, vehicleData) =>
      dispatch(createVehicleData(fleetId, fleetDataId, vehicleData)),
    createNormativityData: (fleetId, fleetDataId, normativityData) =>
      dispatch(createNormativityData(fleetId, fleetDataId, normativityData)),
    uploadImageToNormativity: (fleetId, fleetDataId, normativityId, file) =>
      dispatch(postUploadImage(fleetId, fleetDataId, normativityId, file)),
    createHint: (description, type) => dispatch(createHint(description, type)),
    createNewGPSProvider: (name, email, telephone) =>
      dispatch(createGPSProvider(name, email, telephone)),
    loadGps: () => dispatch(loadGpsProvider()),
    loadHint: () => dispatch(loadHints()),
    addTag: (fleetId, fleetDataId, tagDataObj) =>
      dispatch(addTagToFleet(fleetId, fleetDataId, tagDataObj)),

    postValidateEconomicNumber: (fleetId, economic_number) =>
      dispatch(postValidateEconomicNumber(fleetId, economic_number)),
    postValidateNiv: (fleetId, niv) => dispatch(postValidateNiv(fleetId, niv)),
    postValidateGpsIdentifier: (fleetId, gps_identifier) =>
      dispatch(postValidateGpsIdentifier(fleetId, gps_identifier)),
    postValidateEngineNumber: (fleetId, engine_number) =>
      dispatch(postValidateEngineNumber(fleetId, engine_number)),
    setFleetTab: (payload) => dispatch(setFleetCatalogTabSeleted(payload)),
    validatePlates: (fleetId, plates) =>
      dispatch(validatePlates(fleetId, plates)),
    updateDataNormativityStatus: (companyId, fleetId, dataId) =>
      dispatch(updateDataNormativityStatus(companyId, fleetId, dataId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FleetVehicleView)
);

const normativityHeaderContent = [
  { text: "Nombre documento", width: "30%" },
  { text: "Documento", width: "15%" },
  { text: "Número del documento", width: "auto" },
  { text: "Fecha de expedición", width: "12%" },
  { text: "Fecha de expiración", width: "12%" },
];

const joinRelatedArrays = (arr1 = [], arr2 = [], relatedField) => {
  let count = -99999;
  let x = [];
  arr1.forEach((a1) => {
    let t = arr2.filter((a2) => a1.id === a2[relatedField]);

    let typeID = `${a1.id}`;

    if (t.length && `${a1.type}` === "1")
      t.forEach((a2) =>
        x.push({
          id: count++,
          description: a1.description.concat(" - ", a2.description),
          classID: `${a2.id}`,
          typeID,
        })
      );
  });
  return x;
};
