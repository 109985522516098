import { BACKEND, getApiUrl, patchApiUrl } from '../../shared/backend-api';

export const RESEND_EMAIL_VALIDATION = 'RESEND_EMAIL_VALIDATION';
export const RECEIVE_RESPONSE = 'RECEIVE_RESPONSE';
export const ERROR_RESEND_EMAIL = 'ERROR_RESEND_EMAIL';

export const EDIT_EMAIL = 'EDIT_EMAIL';
export const EDIT_EMAIL_RESPONSE = 'EDIT_EMAIL_RESPONSE';
export const ERROR_EDIT_EMAIL = 'ERROR_EDIT_EMAIL';

const resendEmailValidation = () => ({
    type: RESEND_EMAIL_VALIDATION
});

const receiveResponse = json => ({
    type: RECEIVE_RESPONSE,
    data: json
})

const errorResendEmail = error => ({
    type: ERROR_RESEND_EMAIL,
    error: error
})

const editEmail = params => ({
    type: EDIT_EMAIL,
    params: params
});

const editEmailResponse = json => ({
    type: EDIT_EMAIL_RESPONSE,
    data: json
})

const errorEditEmail = error => ({
    type: ERROR_EDIT_EMAIL,
    error: error
})

const initialState = {
    error: null,
    isFetching: false,
    data: null,
    editEmailError: null,
    editEmailResponse: null,
    isEditEmailFetching: false
};

export default function(state = initialState, action) {

    switch (action.type) {

        case EDIT_EMAIL:

            return {
                ...state,
                isEditEmailFetching: true,
                editEmailError: null
            };

        case EDIT_EMAIL_RESPONSE:

            return {
                ...state,
                isEditEmailFetching: false,
                editEmailError: null,
                editEmailResponse: action.data
            };

        case RESEND_EMAIL_VALIDATION:

            return {
                ...state,
                error: null,
                isFetching: true
            };

        case ERROR_EDIT_EMAIL:
        case ERROR_RESEND_EMAIL:

            return {
                ...state,
                error: 'Ocurrió un error',
                editEmailError: 'Ocurrió un error',
                isFetching: false,
                isEditEmailFetching: false
            };

        case RECEIVE_RESPONSE:

            return {
                ...state,
                error: null,
                isFetching: false,
                data: action.data
            };

        default: 

            return state;

    }

}

export function postResendEmailValidation() {

    return function (dispatch) {
      dispatch(resendEmailValidation())
      return getApiUrl(BACKEND.PUBLIC.RESEND_EMAIL_VALIDATION, {})
        .then(
          response => response.json(),
          error => dispatch(errorResendEmail(error))
        )
        .then(data => dispatch(receiveResponse(data)))
    }
}

export function patchEditEmail(params) {

    return function (dispatch) {
      dispatch(editEmail(params))

      return patchApiUrl(BACKEND.PUBLIC.USERS_V2 + `${params.id}/`, params)
        .then(response => response.json())
        .then(response => dispatch(editEmailResponse(response)))
        .catch(error => error.then(e => dispatch(errorEditEmail(e))))

    }
}