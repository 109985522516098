import styled from "styled-components";

export const Column = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: rgb(44, 53, 75);
  // background-color: #37435f;
  text-align: center;
  margin: 2px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  white-space: normal;
  //   min-width: 200px;
`;
