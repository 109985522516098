
import { ArrowDown, ArrowUp, ArrowUpDown } from "orbi_v2_frontend_components/lib/Icons";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { getEntityItems, readEntities } from "../../redux/api";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import { TruckDoubleEquipment, TruckEquipment } from "../../shared/icons/assemble";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { ViewTitle, View } from "../../shared/styled/view";
import CalendarSelectorRibbon from "../shipment/styled/calendar-selector-ribbon";
import { getRibbonWeeklyDates } from "../shipment/utils";
import WeekPicker from "../shipment/week-picker";
import AppointmentsGrid from "./appointments-grid";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import { cancelMultipleRequests, readEntity } from '../../redux/api/actions';
import { getTimeZone, normalizeStr } from "../../shared/utils";
import { 
  mapResponseToSpaces,
  mapSpaceTags,
  mapSpaceAppointments,
  mapSpaceWindows,
  getOrderDetail,
  mapSpaceAppointmentsConflicts,
} from '../shipment/detail/utils';
import { cloneDeep } from "lodash";
import { TrafficSpacesGrid2 } from "orbi_v2_frontend_components";
import SelectInput from "../../shared/controls/select-input";
import styled from "styled-components";
import AppointmentSummaryModal from "../shipment/detail/AppointmentSummaryModal";

const scrollRef = createRef()

class AssembleShipmentAppointments extends React.Component {
  state = {
    currentDate: new Date(),
    selectedDateId: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .split("T")[0],
    selectedDate: new Date(),
    filterFacilities: [],
    filterFacilitiesTemp: [],
    filterFacilitiesReset: [],
    filterLevel: [],
    filterStatus: [],
    filterClasses: [],
    filterProviders: [],
    facilityTimes: {},
    buttonFilterName: "Aplicar",
    searchStr: "",
    begin: 1,
    end: 2300,
    hourSelectedFormat: null,
    hourSelected: null,
    maxElementsToShow: 50,
    spacesItems: [],
    spaces: [],
    spacesCount: 0,
    summaryHourSelected: null,
    summaryHourSelectedFormat: null,
  }

  componentDidMount() {
    window.onbeforeunload = function() {
      this.props.cancelMultipleRequests();
      return true;
    }.bind(this);

    this.props.loadTripStatus();
    this.props.setNavbarAction("assemblies-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let urlCargoOwner = urlParams.get('cargo_owner');
    // debugger;
    if(urlCargoOwner){
      let selectedItem = (this.props.filterInfo || []).find(co => co.id.toString() === urlCargoOwner)
      if(selectedItem){
        let facilities = selectedItem.facilities.map(f => f.id);
        this.setState({filterFacilities: facilities, filterFacilitiesTemp: facilities, filterFacilitiesReset: facilities}, () => this.loadOrders());
      }
    } else {
      let urlFacilities = urlParams.get('facilities');
      if(urlFacilities) urlFacilities = urlFacilities.split(",")
      // debugger;
      if(urlFacilities){
        // let urlFacilities = this.props.urlFacilities.split(",");
        this.setState({filterFacilities: urlFacilities, filterFacilitiesTemp: urlFacilities, filterFacilitiesReset: urlFacilities}, () => this.loadOrders());
      } else{
        // this.loadOrders();
      }
    }
    this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    this.props.loadCompanyTagsForTR({ company_id: this.props.companyId });
    this.props.loadTransportClasses(this.props.companyId);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("assemblies-control");
    this.props.cancelMultipleRequests();
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.facilities !== this.props.facilities && this.props.facilities.length) {
      this.getFacilityTime()
    }

    if(prevProps.filterInfo !== this.props.filterInfo){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let urlCargoOwner = urlParams.get('cargo_owner');
      // debugger;
      if(urlCargoOwner){
        let selectedItem = (this.props.filterInfo || []).find(co => co.id.toString() === urlCargoOwner)
        if(selectedItem){
          let facilities = selectedItem.facilities.map(f => f.id);
          this.setState({filterFacilities: facilities, filterFacilitiesTemp: facilities, filterFacilitiesReset: facilities }, () => this.loadOrders());
        }
      }
    }
  }

  
  formatTimeZone = (tZ) => {
    if (tZ.length) {
      tZ = tZ.slice(tZ.indexOf("/") + 1);
      tZ = tZ.trim();
      
      return tZ;
    }
    return "America/Mexico_City";
  }

  getFacilityTime = () => {
    let facilityTimes = {};
    // debugger;
    if(this.props.urlFacilities && this.props.urlFacilities.length){
      this.props.urlFacilities.forEach(uF => {
        let tz = (this.props.facilities.find(cF => cF.id.toString() === uF.toString()) || {}).time_zone || "";
        if(tz.length) {
          let format_zone_json = { timeZone: this.formatTimeZone(tz), hour: "numeric", minute: "numeric"};
          let dateString = new Date().toLocaleString("en-US", format_zone_json);
          // this.setState({ ["facility-time-".concat(uF)]: dateString });
          facilityTimes[uF] = dateString;
        }
      })
      this.setState({facilityTimes});
      setTimeout(() => this.getFacilityTime(), 60000);
    }
    return "";
  };

  updateSpaces = () => {
    this.state.spaces = newFormatSpaces(
      this.state.spacesItems, 
      this.props.companyTags, 
      this.props.transportClasses, 
      this.props.companyFacilities
    );

    this.setState({});
  }

  loadOrders = () => {
    this.props.cancelMultipleRequests();

    this.state.spacesItems = [];
    this.state.spaces = [];
    this.state.spacesCount = 0;

    if (this.state.filterFacilities.length > 0) {

      this.state.filterFacilities.forEach((uF, i) => {
        this.props.loadOrders({
          company_id: this.props.companyId,
          facility_id: uF,
          view: 'all',
          date: this.state.selectedDateId,
          status: '',
          fleet_providers: '',
          transport_classes: (this.state.filterClasses || []).join(","),
          trip_tags: '',
          order_tags: '',
          facilities: '',
          priority: this.state.filterLevel,
          category: '',
          dashboard: 'dates',
          timezone: getTimeZone(),
          isMultipleCancels: true
        }, {
          onSuccess: (response) => {
            this.state.spacesCount = this.state.spacesCount + 1;

            if (response && response.length) {
              this.state.spacesItems = this.state.spacesItems.concat(response);
            }

            if (this.state.spacesCount === this.state.filterFacilities.length) {
              this.updateSpaces();
            }

          }
        });
        
      });

    } else {

      this.props.loadOrders({
        company_id: this.props.companyId,
        facility_id: this.props.facilityId,
        view: 'all',
        date: this.state.selectedDateId,
        status: '',
        fleet_providers: '',
        transport_classes: (this.state.filterClasses || []).join(","),
        trip_tags: '',
        order_tags: '',
        facilities: '',
        priority: this.state.filterLevel,
        category: '',
        dashboard: 'dates',
        timezone: getTimeZone(),
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.state.spacesCount = this.state.spacesCount + 1;

          if (response && response.length) {
            this.state.spacesItems = this.state.spacesItems.concat(response);
          }

          if (this.state.spacesCount === this.state.filterFacilities.length) {
            this.updateSpaces();
          }

        }
      });

    }
  }

  
  handleChange = (e) => {
    const { name, value } = e.target;
    // const { facilitiesByTagArgs } = this.props;
    switch (name) {
      case "calendar":
        this.setState(
          {
            selectedDate: value,
            selectedDateId: value.toISOString().split("T")[0],//loadFacilities
            currentDate: value,
          },
          () => this.loadOrders()
        );
        break;
      // case "maneuver":
      //   this.setState({ [name]: value }, () =>
      //     this.loadFacilities()
      //   );
      //   break;
      default:
        this.setState({ [name]: value });
    }
  };

  handleChangeDay = (e) => {
    const { currentDate } = this.state;
    // const { loadFacilities } = this.props;
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      }, () => this.loadOrders());
      // loadFacilities(d.toISOString().split("T")[0]);
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        },
        () => this.loadOrders()
      );
    }
  };

  getFilterContent = () => {
    let statusCondensed = this.props.tripStatus.filter(s => !s.description.toLowerCase().includes("reservación confirmada"));
    let combined = this.props.tripStatus.filter(s => s.description.toLowerCase().includes("reservación confirmada")).map(s => s.id);
    statusCondensed.push({id: combined.join(), description: "Reservación confirmada"});
    // let networkCompanies = this.props.filterInfo.map(fI => ({id: fI.id, description: fI.orbi_id || fI.corporation_name}));
    // let level = [
    //   {
    //     id: "1",
    //     description: "1",
    //   },
    //   {
    //     id: "2",
    //     description: "2",
    //   },
    //   {
    //     id: "3",
    //     description: "3",
    //   },
    //   {
    //     id: "4",
    //     description: "4",
    //   },
    //   {
    //     id: "5",
    //     description: "5",
    //   },
    // ];
    // let transportClasses = this.props.filterInfo.map(company => ({
    //   id: company.id, 
    //   description: company.orbi_id || company.corporation_name,
    //   items: company.transport_classes.map(tC => ({
    //     id: tC.id,
    //     description: tC.code
    //   }))
    // }))
    return [
      // {
      //   title: "Estatus",
      //   items: statusCondensed,
      //   name: "filterStatus",
      //   listType: 5,
      // },
      // {
      //   title: "Cliente",
      //   items: networkCompanies,
      //   name: "filterProviders",
      //   listType: 5,
      // },
      // {
      //   title: "Clase",
      //   items: transportClasses,
      //   name: "filterClasses",
      //   listType: 5,
      // },
      // {
      //   title: "Nivel de importancia",
      //   items: level,
      //   name: "filterLevel",
      //   listType: 5,
      // },
      {
        title: "Instalaciones",
        items: this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "filterFacilitiesTemp",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll
      }
    ]
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'calendar') {
      this.setState(
        {
          selectedDate: value,
          selectedDateId: value.toISOString().split("T")[0],
          currentDate: value,
        },
        () => this.loadOrders()
      );
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          // this.loadAssembliesTemp(700);
        }
      );
    }
  }

  handleScroll = (e) => {
    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const validation = ((e.target.clientHeight + 2) > scrollTop) && ((e.target.clientHeight - 2) < scrollTop) &&  `${e.target.className}`.includes('TrafficSpacesGrid');

    if (validation && (this.state.spaces || []).length > this.state.maxElementsToShow) {
      scrollRef.current.scrollTo(0, e.target.scrollTop - 150);
      this.setState({maxElementsToShow: this.state.maxElementsToShow + 30});
    }
  }

  getScrollWidth = () => {
    let begin = Number(this.state.begin) === 1 ? 0 : Number(this.state.begin);
    let diff = Number(this.state.end) - begin;
    if(diff <= 300) return '100%';
    if(diff === 400) return '1250px';
    if(diff === 500) return '1500px';
    if(diff === 600) return '1750px';
    return '2000px';
  }

  getGeneralSummaryData = () => {

    if (this.props.loading) {
      return({
        scheduled: 0,
        free: 0,
      });
    }

    let testSpaces = cloneDeep(this.state.spaces);

    let beginHour = parseInt(this.state.begin);
    let endHour = parseInt(this.state.end);

    if (beginHour === 1) {
      beginHour = 0;
    }

    let spacesWithin = testSpaces.filter(space => {
        return (space.windows || [])
        .some((w, i) => {
          return (beginHour <= w.begin) && endHour >= w.begin
        })
      })
    let appointments = 0;
    let freeSpaces = 0;
    let conflicts = 0;
    let appointmentsList = [];

    spacesWithin.forEach(s => {
      let appointmentsWithin = (s.appointments || []).filter(app => endHour >= app.begin && beginHour <= app.begin);
      let conflictsWithin = (s.conflicts || []).filter(conf => endHour >= conf.begin && beginHour<= conf.begin);
      appointments += appointmentsWithin.length;
      conflicts += conflictsWithin.length;
      appointmentsList = appointmentsList.concat(appointmentsWithin).concat(conflictsWithin);

      if(!appointmentsWithin.length && !conflictsWithin.length) {
        freeSpaces++;
      }
    })

    return {
      scheduled: appointments,
      free: freeSpaces,
    }
  }

  getMaxSpaces = (spaces_to_show) => {
    return (spaces_to_show || []).slice(0, this.state.maxElementsToShow);
  }

  getSpaces = () => {
    return (this.state.spaces || []).map((item, index) => {
      item.space_number = index + 1;
      return item
    });
  }

  render () {
    let spaces_to_show = this.getSpaces();
    let summaryData = this.getGeneralSummaryData();

    return (
      <View>
        <ViewTitle className="margin-title">
          <div style={{flex: "1"}}>
          Control de embarques
          </div>
          <FilterBarCompact 
            searchStr={this.state.searchStr}
            searchAction={this.handleChange}
            content={this.getFilterContent()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState(
                  {
                    buttonFilterName: "Restablecer",
                    filterFacilities: this.state.filterFacilitiesTemp
                  },
                  () => { this.loadOrders(); }
                );
              } else {
                this.setState(
                  {
                    // filterParams: Utils.DefaultFilters,
                    filterFacilities: this.state.filterFacilitiesReset,
                    filterFacilitiesTemp: this.state.filterFacilitiesReset,
                    buttonFilterName: "Aplicar"
                  },
                  () => { this.loadOrders(); }
                );
              }
            }}
            onChange={(e) => {
              this.setState({
                [e.target.name]: e.target.value,
                buttonFilterName: "Aplicar"
              });
            }}
            buttonFilterName={this.state.buttonFilterName}
            selectedItems={{
              filterFacilitiesTemp: this.state.filterFacilitiesTemp || [],
              // filterLevel: this.state.filterLevel,
              // filterStatus: this.state.filterStatus,
              // filterClasses: this.state.filterClasses,
              // filterProviders: this.state.filterProviders,
            }}
          />
        </ViewTitle>
        <TabSelectorRibbon
          onChange={this.props.onChange}
          bgColor="#212A3C"
          selectedUnderlineColor="#3D77F7"
          // openDocument={this.openDocument}
          // getUrl={this.getUrl}
          items={[
            { id: "orders", description: "ÓRDENES" },
            { id: "list", description: "VIAJES" },
            { id: "grid", description: "TRÁFICO" },
            { id: "appointments", description: "CITAS" },
          ]}
          activeId={this.props.viewMode}
        />
        
        <Flex
          justifyContent={flexOpts.justifyContent.center}
          alignItems={flexOpts.alignItems.center}
          style={{ backgroundColor: "#212A3C" }}
        >
          <div style={{ width: "auto", minWidth: "20%" }} />
          <CalendarSelectorRibbon
            bgColor="#212A3C"
            selectedUnderlineColor="#E9643F"
            // className="profile-calendar-ribbon"
            onChange={this.handleChangeDay}
            items={getRibbonWeeklyDates(this.state.currentDate, "WEEKDAY")}
            activeId={this.state.selectedDateId}
            isCalendar={true}
          />
          <WeekPicker
            locale="es"
            name="calendar"
            date={this.state.selectedDate}
            onChange={this.handleChange}
          />
        </Flex>

        <Flex
          style={{ backgroundColor: "#212A3C", alignItems: "center", color: "#fff" }}
        >
          <div style={{width: 130, marginRight: 8}}>
            <SelectInput
              label='Inicio:'
              name="begin"
              items={HOURS.filter(h => h.id <= parseInt(this.state.end))}
              value={parseInt(this.state.begin)}
              onChange={this.handleChange}
            />
          </div>

          <div style={{width: 130}}>
            <SelectInput 
              label='Fin:'
              name="end"
              items={HOURS.filter(h => h.id >= parseInt(this.state.begin)).map((h, i, a) => {
                if(i < a.length - 1){
                  return {
                    id: h.id,
                    description: a[i + 1].description
                  }
                } else {
                  return {
                    id: h.id,
                    description: "12:00 AM"
                  }
                }
              })}
              value={parseInt(this.state.end)}
              onChange={this.handleChange}
            />
          </div>

          <div style={{width: 20}}/>

          <SpaceDescription>
            <div>Espacios libres</div>
            <div>{summaryData.free}</div>
          </SpaceDescription>

          <div style={{width: 20}}/>

          <SpaceDescription>
            <div>Espacios Ocupados</div>
            <div>{summaryData.scheduled}</div>
          </SpaceDescription>

        </Flex>
        {
          this.props.loading || this.state.spaces.length === 0 ? (
            <div
              style={{
                backgroundColor: "#212A3C",
                height: "300px",
                textAlign: "center",
                opacity: "0.57",
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "bold",
                letterSpacing: "0",
                lineHeight: "37px",
                paddingTop: "30px",
              }}
            >
              <span>
                {this.props.loading || this.state.spacesCount !== this.state.filterFacilities.length
                  ? "Cargando ..."
                  : "No hay viajes en este momento"}
              </span>
              <br />
              <img alt="" src="/images/empty-travels.png"></img>
            </div>
          ) : (
            <TrafficSpacesGrid2
              isLoading={this.props.loading}
              hideSortButtons={this.props.loading}
              verticalScrollRef={scrollRef}
              onScroll={this.handleScroll}
              scrollWidth={this.getScrollWidth()}
              begin={Number(this.state.begin) === 1 ? 0 : Number(this.state.begin)}
              end={Number(this.state.end)}
              selectedTime={this.state.hourSelectedFormat}
              selectedTimeNumberFormat={this.state.hourSelected}
              dateValidation={
                Date.parse(this.state.selectedDate.toDateString()) -
                Date.parse(new Date().toDateString())
              }
              onHourClick={this.onHourClick}
              onSummaryClick={this.onSummaryClick}
              urlFacilities={this.state.filterFacilities}
              companyFacilities={this.state.filterFacilities && this.state.filterFacilities.map(uF => {
                let selectedFacility = (this.props.facilities || []).find(f => f.id.toString() === uF.toString()) || {};
                return {
                  id: selectedFacility.id,
                  name: (selectedFacility.alias || "").concat(' - ', selectedFacility.code, ' - ', selectedFacility.name),
                  time: `   Hora local: ${this.state.facilityTimes["".concat(selectedFacility.id)] || '-'}`
                }
              })}
              spaces={this.filterSpaces(spaces_to_show).map((space, index) => ({ 
                ...space,
                windows: space.windows.filter(w => w.end > Number(this.state.begin) && w.begin < (Number(this.state.end) + 100)).map((wnd) => ({
                  ...wnd,
                  begin: wnd.begin < this.state.begin ? Number(this.state.begin) === 1 ? 0 : Number(this.state.begin) : wnd.begin,
                  end: wnd.end > (Number(this.state.end) + 100) ? Number(this.state.end) + 100 : wnd.end,
                  realBegin: (wnd.first_day !== undefined) ? (wnd.first_day ? wnd.begin : (wnd.begin - 1)) : wnd.begin,
                  realEnd: (wnd.first_day !== undefined) ? (wnd.first_day ? (wnd.end + 1) : wnd.end) : wnd.end,
                  dropOnWindow: (droppedOn, item) => {},
                })),
                appointments: space.appointments.filter(w => w.end > Number(this.state.begin) && w.begin < Number(this.state.end) + 100).map((app) => ({
                  ...app,
                  begin: app.begin < this.state.begin ? Number(this.state.begin) === 1 ? 0 : Number(this.state.begin) : app.begin,
                  end: app.end > (Number(this.state.end) + 100) ? (Number(this.state.end) + 100) : app.end,
                  onDrop: (droppedOn, item) => {},
                  onClick: () => { },
                  searched: null,
                  // (
                  //   Object.values(this.props.filter).some(f => f.length)
                  //     ? (
                  //         this.props.search !== "" 
                  //         ? (!app.shadow && normalizeStr(app.descriptionText).includes(normalizeStr(this.props.search)))
                  //         : !app.shadow
                  //       )
                  //     : (normalizeStr(app.descriptionText).includes(
                  //       normalizeStr(this.props.search)))
                  // ),
                  searchTerm: this.props.search ? true : false
                })),
                conflicts: space.conflicts.filter(w => w.end > this.state.begin && w.begin < Number(this.state.end) + 100).map((app) => ({
                  ...app,
                  // childClick: this.childClick,
                  onClick: () => { },
                  childClick: () => {},
                  onDrop: (droppedOn, item) =>{ },
                  orders: app.orders.map((order) => ({
                    ...order,
                    searched: null,
                    // (
                    //   Object.values(this.props.filter).some(f => f.length)
                    //     ? (
                    //         this.props.search !== "" 
                    //         ? (!order.shadow && normalizeStr(order.codeText).includes(normalizeStr(this.props.search)))
                    //         : !order.shadow
                    //       )
                    //     : (normalizeStr(order.codeText).includes(
                    //       normalizeStr(this.props.search)))
                    // ),
                    searchTerm: this.props.search ? true : false
                  })),
                })),
              }))}
            />
          )
        }
        {/* <AppointmentsGrid 
          begin={0}
          end={2300}
          searchStr={this.state.searchStr}
          facilities={this.state.filterFacilities.length && this.state.filterFacilities.map(uF => {
            let selectedFacility = (this.props.facilities || []).find(f => f.id.toString() === uF.toString()) || {};
            return {
              id: selectedFacility.id,
              name: (selectedFacility.alias || "").concat(' - ', selectedFacility.code, ' - ', selectedFacility.name),
              time: `   Hora local: ${this.state.facilityTimes["".concat(selectedFacility.id)] || '-'}`
            }
          })}
          spaces={this.props.spacesInfo}
        /> */}
        {
          this.state.summaryHourSelected !== null && (
            <AppointmentSummaryModal
              show={this.state.summaryHourSelected !== null}
              hourName={this.state.summaryHourSelectedFormat}
              onClose={this.closeSummaryModal}
              onAccept={this.redirectSummaryModal}
              data={this.getSummaryModalData()}
            />
          )
        }
      </View>
    )
  }

  closeSummaryModal = () => {
    this.setState({
      summaryHourSelected: null,
      summaryHourSelectedFormat: null
    })
  }

  redirectSummaryModal = () => {
    this.closeSummaryModal();
    this.props.onChange({
      target: {
        attributes: {
          name: {
            value: "orders",
          }
        }
      }
    })
  }

  getSummaryModalData = () => {
    if(this.state.summaryHourSelected === null) return {};


    let testSpaces = cloneDeep(this.state.spaces);

    let spacesWithin = testSpaces.filter(space => {
        return (space.windows || [])
        .some((w, i) => {
          // return (this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected < w.end;
          return (this.state.summaryHourSelected <= w.begin) && (this.state.summaryHourSelected + 59) >= w.begin
        })
      })
    let appointments = 0;
    let freeSpaces = 0;
    let conflicts = 0;
    let appointmentsList = [];
    let windowsPerHour = {};

    spacesWithin.forEach(s => {
      // let appointmentsWithin = (s.appointments || []).filter(app => (this.state.summaryHourSelected + 59) >= app.begin && this.state.summaryHourSelected < app.end);
      // let conflictsWithin = (s.conflicts || []).filter(conf => (this.state.summaryHourSelected + 59) >= conf.begin && this.state.summaryHourSelected < conf.end);
      
      let appointmentsWithin = (s.appointments || []).filter(app => (this.state.summaryHourSelected + 59) >= app.begin && this.state.summaryHourSelected <= app.begin);
      let conflictsWithin = (s.conflicts || []).filter(conf => (this.state.summaryHourSelected + 59) >= conf.begin && this.state.summaryHourSelected <= conf.begin);
      appointments += appointmentsWithin.length;
      conflicts += conflictsWithin.length;
      appointmentsList = appointmentsList.concat(appointmentsWithin).concat(conflictsWithin);

      if(!appointmentsWithin.length && !conflictsWithin.length) {
        freeSpaces++;

        (s.windows || []).forEach((w) => {
          // if((this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected < w.end) {
          if((this.state.summaryHourSelected + 59) >= w.begin && this.state.summaryHourSelected <= w.begin) {
            if(windowsPerHour[w.begin]){
              windowsPerHour[w.begin]++
            } else {
              windowsPerHour[w.begin] = 1;
            }
          }
        })
      }
    })

    let appointmentsPerHour = {};
    appointmentsList.forEach(app => {
      if(appointmentsPerHour[app.begin]){
        appointmentsPerHour[app.begin]++
      } else {
        appointmentsPerHour[app.begin] = 1;
      }
    });
    return {
      scheduled: appointments,
      free: freeSpaces,
      conflicts: conflicts,
      appointmentsList,
      appointmentsPerHour: Object.entries(appointmentsPerHour).map(e => ({count: e[1], time: this.formatTime(e[0])})),
      windowsPerHour: Object.entries(windowsPerHour).map(e => ({count: e[1], time: this.formatTime(e[0])})),
    }
  }

  formatTime = value => {
    const hour = value / 100
    const stringValue = value.toString();

    if (hour < 1) {
      if(value < 10){
        return `00:0${stringValue.charAt(0)}`
      }
      return `00:${stringValue.charAt(0)}${stringValue.charAt(1) ||
        '0'}`
    }
    
    if (hour < 10) {
      return `0${stringValue.charAt(0)}:${stringValue.charAt(1) ||
        '0'}${stringValue.charAt(2) || '0'}`
    }
  
    return `${stringValue.charAt(0)}${stringValue.charAt(1)}:${stringValue.charAt(
      2
    )}${stringValue.charAt(3)}`
  }

  onHourClick = (e) => {
    const value = e.target?.value || '';
    const parsedValue = value ? Number(value.replace(':', '')) : null;
    this.setState({
      hourSelected: parsedValue ===  this.state.hourSelected ? null : parsedValue, 
      hourSelectedFormat: parsedValue ===  this.state.hourSelected ? null : value
    }, () => {
      scrollRef.current.scrollTo(0, 0);
    })
  }

  onSummaryClick = (e) => {
    const value = e.target?.value || '';
    const parsedValue = value ? Number(value.replace(':', '')) : null;
    this.setState({
      summaryHourSelected: parsedValue ===  this.state.summaryHourSelected ? null : parsedValue, 
      summaryHourSelectedFormat: parsedValue ===  this.state.summaryHourSelected ? null : value
    })
  }

  filterSpaces = (spaces) => {
    if(this.state.hourSelected === null || !spaces.length) return this.getMaxSpaces(spaces);


    let testSpaces = cloneDeep(spaces);
    let spacesStartWithin = []; //ventanas que inician durante la hora seleccionada
    let spacesCovering = []; //ventanas que inician antes y terminan despues
    let spacesEndWithin = []; //ventanas que inician antes y terminan durante

    // let spacesAppointments = [];
    // let emptySpaces = [];
    let spacesOutside = [];

    while(testSpaces.length){
      let space = testSpaces.shift();
      let condition = (space.windows || [])
        .some((w, i) => {
          let cond = (this.state.hourSelected + 59) >= w.begin && this.state.hourSelected < w.end;
          if(cond) space.filteredWindowIndex = i;
          return cond;
        });
      if(condition){
        // spacesWithin.push(space);
        // let condition1 = (space.conflicts || []).some(c => (this.state.hourSelected + 59) >= c.begin && this.state.hourSelected < c.end);
        // let condition2 = (space.appointments || []).some(c => (this.state.hourSelected + 59) >= c.begin && this.state.hourSelected < c.end);
        // if(condition1 || condition2) spacesAppointments.push(space);
        // else emptySpaces.push(space);

        if(
          (space.windows || [])
          .some((w, i)=> {
            let cond = w.begin >= this.state.hourSelected && w.begin <= (this.state.hourSelected + 59);
            if(cond) space.filteredWindowIndex = i;
            return cond;
          })
        ){
          spacesStartWithin.push(space);
        } else if(
          (space.windows || [])
          .some((w, i) => {
            let cond = w.begin < this.state.hourSelected && w.end > (this.state.hourSelected + 100);
            if(cond) space.filteredWindowIndex = i;
            return cond;
          })
        ){
          spacesCovering.push(space);
        } else {
          spacesEndWithin.push(space);
        }
      } 
      else spacesOutside.push(space);
    }

    spacesStartWithin.forEach((s, i, a) => {
      let appointmentsWithin = (s.appointments || []).filter(app => (this.state.hourSelected + 59) >= app.begin && this.state.hourSelected < app.end);
      appointmentsWithin = appointmentsWithin.concat((s.conflicts || []).filter(conf => (this.state.hourSelected + 59) >= conf.begin && this.state.hourSelected < conf.end))
      appointmentsWithin.sort((aA, aB) => aA.begin - aB.begin);
      a[i].appointmentsWithin = appointmentsWithin;
    })

    spacesStartWithin.sort((a, b) => {
      if(a.windows[a.filteredWindowIndex].begin < b.windows[b.filteredWindowIndex].begin){
        return -1
      } else if (a.windows[a.filteredWindowIndex].begin > b.windows[b.filteredWindowIndex].begin){
        return 1
      }
      
      if(a.appointmentsWithin.length && !b.appointmentsWithin.length){
        return -1
      } else if(!a.appointmentsWithin.length && b.appointmentsWithin.length){
        return 1
      } else if(!a.appointmentsWithin.length && !b.appointmentsWithin.length){
        return 0
      }

      return a.appointmentsWithin[0].begin - b.appointmentsWithin[0].begin;
    });
    spacesCovering.sort((a, b) => {
      return a.windows[a.filteredWindowIndex].begin - b.windows[b.filteredWindowIndex].begin
    });
    spacesEndWithin.sort((a, b) => {
      return a.windows[a.filteredWindowIndex].end - b.windows[b.filteredWindowIndex].end
    });
    let temp_spaces = spacesStartWithin.concat(spacesCovering).concat(spacesEndWithin).concat(spacesOutside);
    return this.getMaxSpaces(temp_spaces);
  }

}

const mapStateToProps = (state) => {

  const loading =
    state.api["currentFacility"]?.status?.isFetching ||
    state.api["COMPANIES.FACILITIES.SPACES"]?.status?.isFetching ||
    state.api["companiesTagsTR"]?.status?.isFetching ||
    state.api["COMPANIES.TAGS.DATES"]?.status?.isFetching ||
    state.api["spacesResponse"]?.status?.isFetching;

  const companyId = state.globalCatalog.session.company.id;

  const transportClasses = getEntityItems(state,"COMPANIES.TRANSPORTCLASS") || [];
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES") || [];
  const companyTags = getEntityItems(state, "companiesTagsTR") || [];
  // const spaces = newFormatSpaces(getEntityItems(state, "spacesResponse"), companyTags, transportClasses, companyFacilities);

  // const spacesInfo = formatSpaces(getEntityItems(state, "COMPANIES.SHIPMENTS.LT.APPOINTMENTS"));
  const tripStatus = getEntityItems(state,"COMPANIES.CATALOGS.TRIPSTATUS");

  return {
    companyId,
    // spacesInfo,
    tripStatus,
    loading,
    companyTags,
    transportClasses,
    companyFacilities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // loadFilters: (params, opt) => 
    //   dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opt)),
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadTripStatus: () => dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS")),
    loadShipmentAppointments: (params, opt) => dispatch(readEntities("COMPANIES.SHIPMENTS.LT.APPOINTMENTS", params, opt)),
    loadOrders: ( params, opts ) => dispatch( readEntities("COMPANIES.FACILITIES.ORDERSCONTROL", params, { ...opts, alias: "spacesResponse" })),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadCompanyFacilities: (params, opts) => dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    loadCompanyTagsForTR: (params, opts) => dispatch(readEntities("COMPANIES.TAGS.DATES",params,{...opts, alias: "companiesTagsTR",})),
    loadTransportClasses: (company_id, opts) => dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id }, opts)),
  }
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(AssembleShipmentAppointments);

const newFormatSpaces = (spacesResponse = [], companyTags = [], transportClasses = [], companyFacilities = []) => {

  let spacesTemp = spacesResponse.map((space, index) => ({
    ...mapResponseToSpaces(space, index),
    tags: mapSpaceTags(space, companyTags),
    windows: mapSpaceWindows(space),
    appointments: mapSpaceAppointments(
      space.load_orders,
      space.unload_orders,
      transportClasses,
      companyTags,
      mapSpaceWindows(space),
      () => {},
      index,
      space.space_id,
      space.facility_id,
      ((companyFacilities.find(cF => cF.id.toString() === space.facility_id.toString()) || {}).time_zone_description || "")
    ),
    conflicts: mapSpaceAppointmentsConflicts(
      space.conflicts,
      transportClasses,
      companyTags,
      () => {},
      index,
      space.space_id,
      space.facility_id,
      space.windows
    ),
  }));

  let spaces = cloneDeep(spacesTemp).map( space => {

    let spaceTemp = space;

    let conflictsFilter = spaceTemp.conflicts.filter(conf => conf.begin !== conf.end).map(item => {
      if (item.begin > item.end || item.end > 2359) {
        item.end = 2359;
      }
      return item;
    });

    let appointmentsFilter = spaceTemp.appointments.map(item => {
      if (item.begin > item.end || item.end > 2359) {
        item.end = 2359;
      }
      return item;
    });

    spaceTemp.conflicts = conflictsFilter;

    spaceTemp.appointments = spaceTemp.appointments.filter(item => {
      return conflictsFilter.filter(item2 => item.begin >= item2.begin && item.end <= item2.end).length === 0;
    });

    let appointmentsTemp = appointmentsFilter.concat(conflictsFilter);

    // se obtienen las citas que no esten en una ventana
    spaceTemp.windows.forEach( wnd => {

      let appointsTemp = appointmentsTemp;

      appointmentsTemp.forEach((ap, apIndex) => {
        if (ap.begin >= wnd.begin && ap.end <= wnd.end) {
          appointsTemp = appointsTemp.filter(item => item.id !== ap.id)
        }
      });

      appointmentsTemp = appointsTemp;

    });

    //se marcan con error las citas
    spaceTemp.appointments = spaceTemp.appointments.map(app => {
      if ((appointmentsTemp.filter(item => item.id === app.id)).length > 0) {
        app.error = true;
      }

      return app;
    });

    if (appointmentsTemp && appointmentsTemp.length && appointmentsTemp.length === 0) {
      return spaceTemp;
    }
    
    let windowsTemp = spaceTemp.windows;
    let lastWindow = windowsTemp.length > 0 ? cloneDeep(windowsTemp[windowsTemp.length - 1]) : null;

    //Se agregan las ventanas faltantes
    if (lastWindow != null) {

      appointmentsTemp.forEach(app => {
        lastWindow.begin = app.begin;
        lastWindow.end = app.end;
        lastWindow.error = true;

        windowsTemp = windowsTemp.concat(cloneDeep(lastWindow));
      });

      spaceTemp.windows = windowsTemp;

    }

    //Se validan las ventanas restantes
    windowsTemp.sort((a, b) => a.begin - b.begin);
    let windowsTemp2 = [];

    cloneDeep(windowsTemp).forEach(item => {
      if (windowsTemp2.filter(item2 => item2.begin === item.begin && item2.end === item.end).length === 0) {
        windowsTemp2.push(item);
      }
    });

    //let windowsTemp2 = cloneDeep(windowsTemp);
    let windsToDelete = [];

    windowsTemp2.forEach( (wnd, index, source) => {

      // if (wnd.error && source[index - 1] && source[index - 1].end > wnd.begin) {

      //   windowsTemp2[index].begin = source[index - 1].begin;
      //   windsToDelete = windsToDelete.concat(index - 1);

      // } else 
      if (wnd.error && source[index - 1] && source[index + 1]) {

        if (source[index - 1].end > wnd.begin) {
          windowsTemp2[index - 1].end = wnd.begin;
        }

        if (source[index + 1].begin < wnd.end) {
          windowsTemp2[index + 1].begin = wnd.end;
        }
        
      } else if (wnd.error && source[index + 1] && source[index + 1].begin < wnd.end) {

        windowsTemp2[index].end = source[index + 1].begin;

      } else if (wnd.error && source[index - 1] && source[index - 1].end > wnd.begin) {

        windowsTemp2[index - 1].end = wnd.begin;

      }

    });

    // let windowsTemp3 = [];

    // windowsTemp2.forEach((wnd, index) => {
    //   if (!windsToDelete.some( item => item === index)) {
    //     windowsTemp3 = windowsTemp3.concat(wnd);
    //   }
    // });

    spaceTemp.windows = windowsTemp2.filter( wnd => !(wnd.begin === 0 && wnd.end === 0));

    return spaceTemp;

  });

  return spaces;

}

const formatSpaces = (spaces = []) => {
  if(!spaces.length) return [];
  return spaces.map((space, i) => {
    // let spaceRangeStart = 
    return {
      facility: space.facility,
      loadManeuver: space.operation === "load" || space.operation === "loadunload",
      loadManeuverIcon: <ArrowUp width="10px" color="white"></ArrowUp>,
      unloadManeuver: space.operation.includes("unload"),
      unloadManeuverIcon: <ArrowDown width="10px" color="white"></ArrowDown>,
      unloadLoadManeuver: space.operation === "loadunload",
      unloadLoadManeuverIcon: <ArrowUpDown width="10px" color="white"></ArrowUpDown>,
      spaceIndex: i + 1,
      id: space.id,
      tag: space.tag,
      range: [
        0,
        2300
      ],
      windows: space.ranges.map(r => {
        let start = r[0].split(':');
        let end = r[1].split(':');
        return {
          begin: parseInt(start[0]) * 100 + parseInt(start[1]),
          end: parseInt(end[0]) * 100 + parseInt(end[1])
        }
      }),
      conflicts: space.dates.map(d => {
        let begin = d[0].split(':');
        let end = d[1].split(':');
        begin = parseInt(begin[0]) * 100 + parseInt(begin[1]);
        end = parseInt(end[0]) * 100 + parseInt(end[1])
        return {
          begin: begin,
          end: end,
          orders: d[2].map(o => {
            let start = [0,0];
            let finish = [0,0];
            if(o.operation === "load"){
              start = o.local_appointment_loading_date.split('T')[1].split(':');
              finish= o.local_end_appointment_loading_date.split('T')[1].split(':');
            } else {
              start = o.local_appointment_unload_date.split('T')[1].split(':');
              finish= o.local_end_appointment_unload_date.split('T')[1].split(':');
            }
            const {color, fontColor} = getOrderStatus(o.status, o.operation, o.order_containers);
            start = parseInt(start[0]) * 100 + parseInt(start[1]);
            finish = parseInt(finish[0]) * 100 + parseInt(finish[1])
            return {
              begin: start > end ? begin : start,
              end: finish < begin ? end : finish,
              color: color,
              fontColor: fontColor,
              searched: true,
              code: o.code,
              opIcon: o.operation === "load" ? <ArrowUp width="10px" color={fontColor}></ArrowUp> : <ArrowDown width="8px" color={fontColor}></ArrowDown>,
              expClass: o.trip__transport_class__tag,//'1234',
              level: o.priority,//1,
              fleet_provider: o.secondary_company,
              icon: o.trip__transport_class__equipment_quantity < 2 ? <TruckEquipment
                  height={"8px"}
                  fill="currentColor"
                  stroke="currentColor"
                /> : <TruckDoubleEquipment
                height={"8px"}
                fill="currentColor"
                stroke="currentColor"
              />,
              plates: o.plates,//12345
              shared: (o.shared || []).map(s => {
                const colorInfo = getOrderStatus(o.status, o.operation, o.order_containers);
                return {
                  fontColor: colorInfo.fontColor,
                  color: colorInfo.color,
                  code: s.code,
                  opIcon:  s.operation === "load" ? <ArrowUp width="10px" color={colorInfo.fontColor}></ArrowUp> : <ArrowDown width="8px" color={colorInfo.fontColor}></ArrowDown>,
                  expClass: s.trip__transport_class__tag,
                  level: s.priority,
                }
              })
            }
          }),
        }
      })
    }
  })
}

const getOrderStatus = (statusId, type, containers, alert, contabilization) => {
  switch (statusId) {
    case 1:
      return { // cb1717
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Pendiente",
        // actions: {
        //   primary: { caption: "Reservar", newStatus: 5 },
        //   secondary: { caption: "Cerrar" },
        //   extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        // },
        visible: false,                   
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 10:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Sin reservación",
        // actions: {
        //   primary: { caption: "Reservar", newStatus: 5 },
        //   secondary: { caption: "Cerrar" },
        //   extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        // },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 4:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Reservación rechazada",
        // actions: {
        //   primary: { caption: "Reservar", newStatus: 5 },
        //   secondary: { caption: "Cerrar" },
        //   extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        // },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 3:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Reservación pendiente",
        // actions: {
        //   primary: { caption: "Cancelar reservación", newStatus: 1 },
        //   // secondary: { caption: "Cambiar cita" },
        //   extra: [
        //     { caption: "Cancelar viaje", newStatus: 18 },
        //     { caption: "*Confirma Reservación", newStatus: 7 },
        //     { caption: "*Rechaza Reservación", newStatus: 3 },
        //   ],
        // },
        visible: true,
        reserved: true,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };

    case 2:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Confirmado",
        // actions: {
        //   primary: { caption: "Cancelar reservación", newStatus: 1 },
        //   // secondary: { caption: "Cambiar cita" },
        //   extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        // },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 5:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Reservación confirmada sin asignar",
        // actions: {
        //   primary: { caption: "Cancelar reservación" },
        //   secondary: { caption: "Asignar cita" },
        //   extra: [{ caption: "Cancelar viaje" }],
        // },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 6:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Reservación confirmada asignada",
        // actions: {
        //   primary: { caption: "Cancelar reservación" },
        //   secondary: { caption: "Asignar cita" },
        //   extra: [{ caption: "Cancelar viaje" }],
        // },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 7:
      let color = getColor("#82e4c2", statusId, containers, type, contabilization);

      return {
        color: alert ? "#cb1717" : color,
        description: "Viaje en proceso",
        // actions: {
        //   primary: { caption: "Cancelar reservación", newStatus: 1 },
        //   // secondary: { caption: "Cambiar cita" },
        //   extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        // },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 8:
      return {
        color: alert ? "#cb1717" : "#408051",
        description: "Viaje completado",
        // actions: { primary: { caption: "Cerrar" } },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 11:
      return {
        color: alert ? "#cb1717" : "#408051",
        description: "Viaje finalizado sin datos",
        // actions: { },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    default:
      return {
        color: "transparent",
        description: "default",
        actions: {},
      };
  }
}

const getColor = (color, status, containers = [], type, contabilization) => {
  let containersLength = (containers).length;
  if (!containers.length) return color;
  switch (type) {
    case "load":
      switch (status) {
        case 7:
          if (containersLength === 1) {
            if (containers[0].status === 2) {
              return contabilization ? "#006fa3" : "#86e3c2";
            } else if (
              containers[0].status >= 3 &&
              containers[0].status <= 6
            ) {
              return "#05C985";
            } else if (containers[0].status >= 7) {
              return "#336741";
            }
          } else {
            if (containers[0].status === 2 && containers[1].status === 2) {
              return contabilization ? "#006fa3" : "#86e3c2";
            } else if (
              containers[0].status >= 3 &&
              containers[0].status <= 6 &&
              containers[1].status >= 3 &&
              containers[1].status <= 6
            ) {
              return "#05C985";
            } else if (
              containers[0].status >= 7 &&
              containers[1].status >= 7
            ) {
              return "#336741";
            }
          }
          return color;

        default:
          return color;
      }

    default:
      switch (status) {
        case 7:
          if (containersLength === 1) {
            if (containers[0].status >= 2 && containers[0].status <= 10) {
              return contabilization ? "#006fa3" : "#86e3c2";
            } else if (
              containers[0].status >= 11 &&
              containers[0].status <= 14
            ) {
              return "#05C985";
            } else if (containers[0].status === 15) {
              return "#336741";
            }
          } else {
            if (
              containers[0].status >= 2 &&
              containers[0].status <= 10 &&
              containers[1].status >= 2 &&
              containers[1].status <= 10
            ) {
              return contabilization ? "#006fa3" : "#86e3c2";
            } else if (
              containers[0].status >= 11 &&
              containers[0].status <= 14 &&
              containers[1].status >= 11 &&
              containers[1].status <= 14
            ) {
              return "#05C985";
            } else if (
              containers[0].status === 15 &&
              containers[1].status === 15
            ) {
              return "#336741";
            }
          }
          return color;

        default:
          return color;
      }
  }
  
};

const HOURS = [
  {
    id: 1,
    description: "00:00 AM"
  },
  {
    id: 100,
    description: "01:00 AM"
  },
  {
    id: 200,
    description: "02:00 AM"
  },
  {
    id: 300,
    description: "03:00 AM"
  },
  {
    id: 400,
    description: "04:00 AM"
  },
  {
    id: 500,
    description: "05:00 AM"
  },
  {
    id: 600,
    description: "06:00 AM"
  },
  {
    id: 700,
    description: "07:00 AM"
  },
  {
    id: 800,
    description: "08:00 AM"
  },
  {
    id: 900,
    description: "09:00 AM"
  },
  {
    id: 1000,
    description: "10:00 AM"
  },
  {
    id: 1100,
    description: "11:00 AM"
  },
  {
    id: 1200,
    description: "12:00 PM"
  },
  {
    id: 1300,
    description: "01:00 PM"
  },
  {
    id: 1400,
    description: "02:00 PM"
  },
  {
    id: 1500,
    description: "03:00 PM"
  },
  {
    id: 1600,
    description: "04:00 PM"
  },
  {
    id: 1700,
    description: "05:00 PM"
  },
  {
    id: 1800,
    description: "06:00 PM"
  },
  {
    id: 1900,
    description: "07:00 PM"
  },
  {
    id: 2000,
    description: "08:00 PM"
  },
  {
    id: 2100,
    description: "09:00 PM"
  },
  {
    id: 2200,
    description: "10:00 PM"
  },
  {
    id: 2300,
    description: "11:00 PM"
  },
]

const SPACES = [
  {
    loadManeuver: true,
    loadManeuverIcon: <ArrowUp width="10px" color="white"></ArrowUp>,
    unloadManeuver: true,
    unloadManeuverIcon: <ArrowDown width="10px" color="white"></ArrowDown>,
    unloadLoadManeuver: true,
    unloadLoadManeuverIcon: <ArrowUpDown width="10px" color="white"></ArrowUpDown>,
    spaceIndex: 1,
    id: 1100,
    tag: {
      color: '3D77F7',
      title: 'CERVEZA'
    },
    range: [
      0,
      2300
    ],
    windows: [
      {
        begin: 150,
        end: 900
      }
    ],
    conflicts: [
      {
        begin: 400,
        end: 500,
        orders: [
          {
            begin: 400,
            end: 500,
            color: "white",
            fontColor: '#6197F5',
            searched: true,
            code: '1234',
            opIcon: <ArrowDown width="8px" color={'#6197F5'}></ArrowDown>,
            expClass: 'X2',//'1234',
            level: 1,//1,
            fleet_provider: 'aldafa',
            icon: <TruckDoubleEquipment
                height={"8px"}
                fill="currentColor"
                stroke="currentColor"
              />,
            plates: '12345',//12345
            shared: [
              {
                fontColor: '#6197F5',
                color: "white",
                code: '1234',
                opIcon: <ArrowDown width="8px" color={'#6197F5'}></ArrowDown>,
                expClass: 'X2',
                level: 1
              }
            ]
          }
        ]
      },
      {
        begin: 630,
        end: 830,
        orders: [
          {
            begin: 630,
            end: 720,
            color: "white",
            fontColor: '#6197F5',
            searched: true,
            code: '1234',
            opIcon: <ArrowDown width="8px" color={'#6197F5'}></ArrowDown>,
            expClass: 'X2',//'1234',
            level: 1,//1,
            fleet_provider: 'aldafa',
            icon: <TruckDoubleEquipment
                height={"8px"}
                fill="currentColor"
                stroke="currentColor"
              />,
            plates: '12345',//12345
            shared: null
          },
          {
            begin: 700,
            end: 830,
            color: "white",
            fontColor: '#6197F5',
            searched: true,
            code: '1234',
            opIcon: <ArrowDown width="8px" color={'#6197F5'}></ArrowDown>,
            expClass: 'X2',//'1234',
            level: 1,//1,
            fleet_provider: 'aldafa',
            icon: <TruckDoubleEquipment
                height={"8px"}
                fill="currentColor"
                stroke="currentColor"
              />,
            plates: '12345',//12345
            shared: null
          }
        ]
      }
    ]
  }
]

const SpaceDescription = styled.div`
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 2px white;
  border-radius: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
`