import {
  Unitary,
  Truck,
  Equipment,
  // License,
  Dolly,
} from "../../shared/icons/assemble";

export const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
export const hour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours()
);

export const dateAddDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const dateAddHours = (date, hours) => {
  date.setHours(date.getHours() + hours);
  return date;
};

export const dateRemoveHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
};

export const defaultGridConfig: Types.GridConfig = {
  columnQuantity: 48,
  // columnQuantity: 96,
  columnRangeType: "HOUR",
  columnSections: 4,
  // startDatetime: hour,
  startDatetime: dateRemoveHours(
    new Date(hour),
     12
    // 24*2
  ),
  className: "",
  extended: true,
  scrollable: true,
};

export const DefaultFilters: Types.loadAssembliesParams = {
  limit: 10,
  offset: 1,
  // view: "shortdetail",
  status: "",
  transport_classes: "",
  fleet_providers: "",
  priority: "",
  trip_tags: "",
  order_tags: "",
  from_facilities: "",
  to_facilities: "",
  // operator_status: [],
  // operator_license_type: [],
  // equipment_status: [],
  // equipment_class: [],
  // equipment_type: [],
  // vehicle_status: [],
  // vehicle_class: [],
  // vehicle_type: [],
  // vehicle_tags: [],
  // equipment_tags: [],
  // operator_tags: [],
  // start_date: hour.toISOString(),
  // end_date: dateAddHours(
  //   new Date(hour),
  //   defaultGridConfig.columnQuantity
  // ).toISOString(),
  start_date: dateRemoveHours(
      new Date(hour),
      12// defaultGridConfig.columnQuantity
    ).toISOString(),
  end_date: dateAddHours(
      new Date(hour),
      // defaultGridConfig.columnQuantity * 3
      36
    ).toISOString(),
};

export const VEHICLE_TYPE_ICON_SELECTOR = {
  C: Unitary,
  T: Truck,
  S: Equipment,
  R: Equipment,
  D: Dolly,
  undefined: Unitary,
};

export const TrafficFilters = (
  status: any[] = [],
  providers: any[] = [],
  transportClasses: any[] = [],
  tagsTrip: any[] = [],
  tagsOrder: any[] = [],
  facilities: any[] = [],
  level: any[] = []
) => [
  {
    title: "Estatus",
    items: status,
    name: "status",
    listType: 5,
  },
  {
    title: "Transportista",
    items: providers,
    name: "fleet_providers",
    listType: 5,
  },
  {
    title: "Clase",
    items: transportClasses,
    name: "transport_classes",
    listType: 5,
  },
  {
    title: "Etiqueta Viaje",
    items: tagsTrip,
    name: "trip_tags",
    listType: 1,
  },
  {
    title: "Etiqueta Orden",
    items: tagsOrder,
    name: "order_tags",
    listType: 1,
  },
  {
    title: "Origen",
    items: facilities,
    name: "from_facilities",
    listType: 5,
  },
  {
    title: "Destino",
    items: facilities,
    name: "to_facilities",
    listType: 5,
  },
  {
    title: "Nivel de importancia",
    items: level,
    name: "priority",
    listType: 5,
  },
];
