import Axios from "axios";
import React from "react";
import { connect } from 'react-redux';
import { getEntityItems, readEntities } from "../redux/api";
import { SERVER } from "../redux/api/api-urls";
import FilterBar from "../shared/component/filter/filter-bar";
import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import { View, ViewTitle } from "../shared/styled/view";
import { getTimeZone, groupFacilities, isPremiumSubscriptionCo } from "../shared/utils";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";
import styled from "styled-components";

class EquipmentsTrafficReportView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filterDate: [],
      filterDateType: [],
      filterFacilities: [],
      isFreeSubscription: false
    }
  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.props.loadFacility({
      company_id: this.props.companyId
    }, {
      onSuccess: (result) => {
        this.setState({loading: false, filterFacilities: result.map(f => f.id)})
      }
    });
    this.props.setNavbarAction("trailers-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("trailers-report");
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  getUrl = () => {
    if(this.props.companyType === 1){
      return `${SERVER}/api/v2/companies/${this.props.companyId}/trips/?download=true&type=container&timezone=${getTimeZone()}&view=controls&appointment=${this.state.filterDate[0] ? "".concat(this.state.filterDate[0].toISOString(), ",", this.state.filterDate[1] ? this.state.filterDate[1].toISOString() : this.state.filterDate[0].toISOString()) : ""}&appointment_type=${(this.state.filterDateType || []).join(",") || "1,2,3"}`;
    }
    return `${SERVER}/api/v2/companies/${this.props.companyId}/facilities/${this.props.firstFacility}/trips_control/?download=true&type=container&timezone=${getTimeZone()}&view=all&appointment=${this.state.filterDate[0] ? "".concat(this.state.filterDate[0].toISOString(), ",", this.state.filterDate[1] ? this.state.filterDate[1].toISOString() : this.state.filterDate[0].toISOString()) : ""}&appointment_type=${(this.state.filterDateType  || []).join(",") || "1,2,3"}&multifacilities=${(this.state.filterFacilities || []).join(",")}`;
    
  };

  handleFilter = () => {
    let url = this.getUrl();
    this.setState({loading: true});
    Axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Trafico_remolques.xlsx");
      document.body.appendChild(link);
      link.click();
      this.setState({loading: false});
    });
  }

  filterContents = () => {
    if(this.props.companyType === 1){
      return [
        {
          title: "Fecha",
          name: "filterDate",
          listType: FILTER_LIST_TYPES.dateRangeFixed,
        },
        {
          title: "Tipo fecha",
          items: [
            {
              id: "1",
              description: "Carga"
            },
            {
              id: "2",
              description: "Descarga"
            },
            {
              id: "3",
              description: "Programa"
            },
            {
              id: "4",
              description: "Programa y cita de carga"
            },
          ],
          name: "filterDateType",
          listType: 5,
        }
      ]
    }
    return [
      {
        title: "Instalación",
        items: this.props.facilitiesByTag,
        name: "filterFacilities",
        listType: 5,
      },
      {
        title: "Fecha",
        name: "filterDate",
        listType: FILTER_LIST_TYPES.dateRangeFixed,
      },
      {
        title: "Tipo fecha",
        items: [
          {
            id: "1",
            description: "Carga"
          },
          {
            id: "2",
            description: "Descarga"
          },
          {
            id: "3",
            description: "Programa"
          },
          {
            id: "4",
            description: "Programa y cita de carga"
          },
        ],
        name: "filterDateType",
        listType: 5,
      }
    ]
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return (
      <View>
        <ViewTitle>
          Reporte de remolques
        </ViewTitle>
        <div className="tat-header">
          <FilterBar
            // searchAction={this.handleTextSearch}
            filterAction={this.handleFilter}
            // searchStr={this.state.searchStr}
            onChange={this.handleFilterChange}
            content={this.filterContents()}
            textSearch= {false}
            searchButtonText="Descargar"
            disableButton={this.state.loading}
            selectedItems={{
              filterDate: this.state.filterDate,
              filterDateType: this.state.filterDateType,
              filterFacilities: this.state.filterFacilities
            }}
          />
        </div>
      </View>
    )
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company.type?.id;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);
  const firstFacility = companyFacilities[0]?.id || 0;
  return {
    companyId,
    companyType,
    facilitiesByTag,
    firstFacility,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFacility: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentsTrafficReportView);

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;