import React from "react";
const Space = (props) => {
  return (
    <svg
      viewBox="0 0 13 15"
      width="20px"
      height="20px"
      fill="currentColor"
      {...props}
    >
      <path d="M0.0124798772,4.35825 L6.18248341,7.90545 L6.18248341,15 L0.0124798772,11.4528 L0.0124798772,4.35825 Z M13.3810486,4.35825 L13.3810486,11.4528 L7.21104556,15 L7.21104556,7.90545 L13.3810486,4.35825 Z M6.69674926,-4.4408921e-16 L12.969639,3.5472 L6.69674926,7.0944 L0.526746186,3.5472 L6.69674926,-4.4408921e-16 Z"></path>
    </svg>
  );
};
export default Space;
