import React, { createRef }  from "react";
import TitleArrow from '../../../control/gps/title-arrow';
import TextInput from "../../controls/text-input";
import Loader from '../../icons/tat/loader';
import Checkbox from '../../controls/checkbox';
import { normalizeStr } from "../../utils";
import * as moment from "moment";
import CopyIcon from "../../icons/copy-icon";
// import { cloneDeep } from "lodash";
import Modal from "react-modal";

const scrollRef = createRef();
const tooltipRef = createRef();
const headerRef = createRef();
const secondaryScrollRef = createRef();

const ALPHABETIC_DATA_TYPE = 'alphabetic';
const NUMBER_DATA_TYPE = 'number';
const DATE_DATA_TYPE = 'date';
const BOOLEAN_TYPE = 'boolean';
const MAX_ELEMENTS_TO_SHOW = 20;

class TableSortable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      rowOpened: '',
      items: props?.items || [],
      itemsToShow: props?.items || [],
      topPosition: 0,
      rightPosition: 0,
      filtersToApply: [],
      maxElementsToShow: MAX_ELEMENTS_TO_SHOW,
      showArrayData: false,
      arrayData: [],
      arrayTitle: '',
      hoveredIndex: -1
    }
    
    this.refInputs = Array((props.columns || []).length).fill().map(i=> React.createRef());   
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if((prevProps.columns || []).length !== (this.props.columns || []).length) {
      this.refInputs = Array((this.props.columns || []).length).fill().map(i=> React.createRef());  
      this.forceUpdate();
    }
  }

  handleClickOutside = (event) => {
    if (tooltipRef && tooltipRef.current && !tooltipRef.current.contains(event.target) && !this.refInputs.some(rI => rI.current && rI.current.contains(event.target)) /* && !headerRef.current.contains(event.target) */) {
      setTimeout(function() {
        this.setState({
          rowOpened: ''
        });
      }.bind(this), 50);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { items = [] } = nextProps;
    if (nextProps && (items.length !== prevState.items.length || (items.length && items[0].id !== prevState.items[0].id))) {
      //console.log('update');
      // debugger;
      nextProps.updateShownItems && nextProps.updateShownItems(items);
      return({items, itemsToShow: items.slice(0, MAX_ELEMENTS_TO_SHOW), maxElementsToShow: MAX_ELEMENTS_TO_SHOW});
    }
  }

  isValueHidden = (key) => {
    let itemsToHide = this.props.itemsToHide || [];
    return itemsToHide.find( item  => {
      return item === key;
    });
  }

  getHeaderRow = () => {
    const columns = this.props.columns || [];
    const currentScroll = this.props.secondaryScrollRef ? this.props.secondaryScrollRef : scrollRef;
    return(
      <div ref={headerRef} className='header'
        style={this.props.embedded ? {borderRadius:'0px'} : {}}
      >
        {
          columns.map( (item, index) => {

            if (this.isValueHidden(item.key || '')) {
              return <div key={index}/>
            }

            return(
              <div className={'space'} key={index} style={item.minWidth ? { minWidth: `${item.minWidth}px`} : {}}>
                <div  ref={this.refInputs[index]}  style={{position: "relative"}}>
                  <TitleArrow
                    text={item.title || ''}
                    isOpen={this.state.rowOpened === (item.key || '')}
                    showArrow={item.hideArrow || item.isArray || item.isObject ? false : true}
                    onMouseEnter={() => this.setState({ hoveredIndex: index })}
                    onMouseLeave={() => this.setState({ hoveredIndex: -1})}
                    isHovered={this.state.hoveredIndex === index}
                    onClick={(value) => {
                      if(item.hideArrow || item.isArray || item.isObject) return;
                      // debugger;
                      if (this.refInputs[index].current !== undefined && this.refInputs[index].current !== null) {
                        const { offsetTop, offsetLeft, offsetWidth } = this.refInputs[index].current
                        const { innerWidth: width } = window;
                        
                        let rightPosition = offsetLeft + offsetWidth - 20 - (currentScroll?.current?.scrollLeft || 0);

                        if ((rightPosition + 280) > width) {
                          rightPosition = width - 300
                        }

                        this.setState({
                          rowOpened: value ? (item.key || '') : '',
                          topPosition: offsetTop + 30,
                          rightPosition
                        });
                      }

                    }}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  getRow = (data, key) => {

    const columns = this.props.columns || [];

    return(
      <div className='row' key={key} onClick={() => { 
        this.props.didSelectRow(data);
       }}>
         {
           this.props.selectionModeOn &&
           <div className={"selection-layer".concat((this.props.selectedItems || []).includes(data.id) ? ' selected' : '')}>
             <div className="checkbox"></div>
           </div>
         }
        <div className='row-content'>
          {
            columns.map( (item, index) => {

              if (this.isValueHidden(item.key || '')) {
                return <div key={index}/>
              }
              
              let dataToPresent = '';
              
              if (typeof data[item.key] === "boolean") {
                dataToPresent = `${data[item.key]}`;
              } else {
                dataToPresent = data[item.key];
              }
              
              return(
                <div className='space' key={index} style={item.minWidth ? { minWidth: `${item.minWidth}px`} : {}}>
                  { item.isArray 
                    ? <span 
                        style={{cursor: "pointer", fontWeight: "bold", textDecoration: "underline"}}
                        onClick={() => {this.setState({showArrayData: true, arrayData: dataToPresent, arrayTitle: item.title})}}
                      >Ver datos</span>
                    :
                      item.isObject
                      ? <span 
                          style={{cursor: "pointer", fontWeight: "bold", textDecoration: "underline"}}
                          onClick={() => {this.setState({showArrayData: true, arrayData: [dataToPresent], arrayTitle: item.title})}}
                        >Ver datos</span>
                      : dataToPresent === '' ? '-' : dataToPresent 
                  }
                  {
                    item.allowCopy && dataToPresent !== ''
                    ? <CopyIcon className="copy-icon" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(dataToPresent);}}/>
                      
                    : ''
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    );

  }

  getContent = (dataToShow) => {
    let specWidth = (this.props.columns || []).filter(c => c.minWidth).map(c => c.minWidth);
    let width;
    if(specWidth.length){
      width = ((this.props.columns || []).length - specWidth.length) * 170 + specWidth.reduce((accum, curr) => accum + curr + 20);
    } else {
      width = (this.props.columns || []).length * 170;
    }
    return(
      <>
        <div 
          className='rows-container' onScroll={this.handleScroll}
          style={{
            height: this.props.embedded ? '400px' : '450px'
          }}
        >
          {
            dataToShow.map( (data, dataIndex) => {
              return this.getRow(data, dataIndex)
            }) 
          }
        </div>
        {
          <div 
            className='scroll'
            ref={this.props.secondaryScrollRef ? this.props.secondaryScrollRef : scrollRef}
            onScroll={e => {

              const r = document.getElementsByClassName('header')
              const r2 = document.getElementsByClassName('row')
              let currentScrollRef = this.props.secondaryScrollRef ? this.props.secondaryScrollRef : scrollRef;
              if(currentScrollRef.current){

                Array.from(r2).forEach( a => (a.scrollLeft = currentScrollRef.current.scrollLeft))
                Array.from(r).forEach( a => (a.scrollLeft = currentScrollRef.current.scrollLeft))
              }

              if (this.state.rowOpened !== '') {
                this.setState({rowOpened: ''});
              }

            }}
          >
            <div style={{width: `${width}px`, height: 1, minHeight: 1}}/>
          </div>
        }
      </>
    );
  }

  getNoData = () => {
    return(
      <div className='no-data'>No hay información disponible</div>
    );
  }

  presentLoader = () => {
    return(
      <div style={{height: 90, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}>
        {'Cargando'}
        <div style={{width: 8}}/>
        <Loader />
      </div>
    );
  }

  /////////////////// Text functions

  getTextSearch = () => {
    return (
      <div className="search-list">
        <TextInput
          value={this.getSearchText()}
          label="Buscar"
          name="searchText"
          onChange={(input) => {
            this.modifyFilters(this.state.rowOpened, '', '', '', input?.target?.value || '')
          }}
        />
      </div>
    );
  };

  getSearchText = () => {
    return this.getFilterToModify(this.state.rowOpened).data?.search || '';
  }

  getValues = () => {
    return this.getFilterToModify(this.state.rowOpened).data?.values || [];
  }

  getFilterToModify = (key) => {
    let filterData = null;
    let index = 0;

    for (let i = 0; i < this.state.filtersToApply.length; i++) {
      if (this.state.filtersToApply[i].key === key)  {
        filterData = this.state.filtersToApply[i];
        index = i;
      }
    }

    return {data: filterData, index};
  }

  getValuesToFilter = () => {

    let items = this.getFilterData()?.filter?.items || [];
    let itemsToReturn = [];

    if (this.getFilterData()?.filter === undefined && this.state.rowOpened !== '') {
      let dataType = this.getDataType(this.state.rowOpened);
      if (dataType !== null && (dataType === ALPHABETIC_DATA_TYPE || dataType === NUMBER_DATA_TYPE || dataType === BOOLEAN_TYPE)) {
        items = this.transformItemsToFilter(this.state.rowOpened);
      }
    }

    for (let i = 0; i < items.length; i++) {
      if ((items[i].items || []).length > 0) {
        itemsToReturn = [...itemsToReturn, ...items[i].items];
      } else {
        itemsToReturn = itemsToReturn.concat(items[i]);
      }
    }

    return itemsToReturn;

  }

  resetOrders = (callback) => {
    let tempFilters = this.state.filtersToApply;

    for (let i = 0; i < tempFilters.length; i++) {
      tempFilters[i].order = null;
    }

    this.setState({
      maxElementsToShow: MAX_ELEMENTS_TO_SHOW,
      filtersToApply: tempFilters
    }, () => {
      callback();
    });
  }

  clearFilter = (key) => {
    let tempFilters = this.state.filtersToApply.filter( (item) => {
      return item.key !== key
    });

    this.setState({
      filtersToApply: tempFilters
    }, () => {
      this.applyFilters();
    });
  }

  modifyFilters = (key, values, order, type, search) => {
    let filterData = this.getFilterToModify(key);

    if (filterData.data === null) {

      this.setState({
        filtersToApply: this.state.filtersToApply.concat({
          key,
          values,
          order,
          type,
          search,
          itemsToFilter: this.getValuesToFilter()
        })
      }, () => {
        this.applyFilters();
      })

    } else {

      let filterToModify = filterData.data;

      if (values !== null) {
        filterToModify.values = values;
      }

      if (type !== null) {
        filterToModify.type = type;
      }

      if (order !== null) {
        filterToModify.order = order;
      }

      if (search !== null) {
        filterToModify.search = search;
      }

      let tempFiltersToApply = this.state.filtersToApply;
      tempFiltersToApply[filterData.index] = filterToModify;

      this.setState({
        filtersToApply: tempFiltersToApply
      }, () => {
        this.applyFilters();
      });

    }

  }

  getDataType = (key) => {

    if (this.state.items.length > 0) {

      let valueToReturn = null;

      for (let i = 0; i < this.state.items.length; i++) {

        if (valueToReturn === null) {
          let data = this.state.items[i];
          let value = data[key];

          if (typeof value === "boolean") {
            valueToReturn = BOOLEAN_TYPE;
          } else if (value !== '' && isFinite(value)) {
            valueToReturn = NUMBER_DATA_TYPE;
          } else if (value !== '' && moment(value).isValid()) {
            valueToReturn = DATE_DATA_TYPE;
          } else if (value !== '') {
            valueToReturn = ALPHABETIC_DATA_TYPE;
          } 
        } else {
          break;
        }

      }

      return valueToReturn;

    } else {
      return null;
    }

  }

  getCheckbox = (data, filterItems) => {

    // alphabetic
    // number
    // date
    // none

    let type = data?.filter?.type || '';
    let key = data?.key || '';
    let order = this.getFilterToModify(key).data?.order || '';
    let items = data?.filter?.items || [];

    if (data?.filter === undefined) {
      type = this.getDataType(key) || '';
    }

    if (type === ALPHABETIC_DATA_TYPE) {
      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'high', type, null));
          }}>
            <img alt="" src={order === 'high' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Ordenar A - Z
          </div>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'low', type, null));
          }}>
            <img alt="" src={order === 'low' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Ordenar Z - A
          </div>
        </div>
      );
    } else if (type === NUMBER_DATA_TYPE) {
      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'high', type, null));
          }}>
            <img alt="" src={order === 'high' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Menor a mayor
          </div>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'low', type, null));
          }}>
            <img alt="" src={order === 'low' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Mayor a menor
          </div>
        </div>
      );
    } else if (type === DATE_DATA_TYPE) {
      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'high', type, null));
          }}>
            <img alt="" src={order === 'high' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Fecha (Menor a mayor)
          </div>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'low', type, null));
          }}>
            <img alt="" src={order === 'low' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Fecha (Mayor a menor)
          </div>
        </div>
      );
    } else if (type === BOOLEAN_TYPE) {
      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'high', type, null));
          }}>
            <img alt="" src={order === 'high' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            True
          </div>
          <div className='checkbox' onClick={() => {
            this.resetOrders(() => this.modifyFilters(key, null, 'low', type, null));
          }}>
            <img alt="" src={order === 'low' ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            False
          </div>
        </div>
      );
    } else {
      return (
        <div/>
      )
    }

  }

  getFilterData = () => {
    let data = null;
    const rows = this.props.columns || [];

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].key === this.state.rowOpened) {
        data = rows[i];
      }
    }
    
    return data;
  }

  handleFilterListSearch = (items, field, value = '') => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field] || '').includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    'description',
                    this.getSearchText()
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field] || '').includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };

  handleSelectedItemsChange = (e, items, single = false) => {
    if(single){
      let st=[];
      if(e.target.checked) {
        st.push(e.target.value.toString())
      }
      this.modifyFilters(this.state.rowOpened, st, null, null, null);
    } else {
      let st =
        (this.getValues() || []).map((m) =>
          m.toString()
        ) || [];
      if ((items || {}).length > 0) {
        items.forEach((item) => {
          let val = item.id.toString();
          st = st.filter((a) => a !== val);
          if (e.target.checked) {
            st = st.concat(val);
          }
        });
      } else {
        if (st.includes(e.target.value.toString())) {
          st = st.filter((a) => a.toString() !== e.target.value.toString());
        } else {
          st = st.concat(e.target.value.toString());
        }
      }
      this.modifyFilters(this.state.rowOpened, st, null, null, null);
    }
  };

  checkList = (data) => {

    let items = data?.filter?.items || [];
    let name = data?.filter?.name || '';
    let single = false;
    let counter = -9999;

    if (data?.filter === undefined && this.state.rowOpened !== '') {
      let dataType = this.getDataType(this.state.rowOpened);
      if (dataType !== null && (dataType === ALPHABETIC_DATA_TYPE || dataType === NUMBER_DATA_TYPE || dataType === BOOLEAN_TYPE)) {
        items = this.transformItemsToFilter(this.state.rowOpened);
      }
    }

    return (
      <div className='items checks'>
        {
          this.handleFilterListSearch(
            items || [],
            'description',
            this.getSearchText()
          ).map((i) => {
            let l = [];
            l.push(
              <Checkbox
                key={'item-'.concat(i.description)}
                onChange={(e) => this.handleSelectedItemsChange(e, i.items, single)}
                item={{
                  id: i.id,
                  description: `${i.description}`,
                  active: (this.getValues() || []).find(
                    (si) => (si ?? '').toString() === (i?.id ?? '').toString()
                  )
                    ? true
                    : false,
                }}
                name={name}
                className={(i.items || []).length ? 'bold' : ''}
              />
            );
            if ((i.items || []).length) {
              i.items.forEach((ci) =>
                l.push(
                  <Checkbox
                    key={'subitem-'.concat(ci.description)}
                    className={'sub-item'}
                    onChange={(e) => this.handleSelectedItemsChange(e, single)}
                    item={{
                      id: ci.id,
                      description: `${ci.description}`,
                      active: (this.getValues() || []).find(
                        (si) => si.toString() === ci.id.toString()
                      )
                        ? true
                        : false,
                    }}
                    name={name}
                  />
                )
              );
              l.push(<div className='separator' key={counter++} />);
              let full = true;
              i.items.forEach((fe) =>
                (this.getValues() || []).find(
                  (si) => si.toString() === fe.id.toString()
                ) && full
                  ? (full = true)
                  : (full = false)
              );
              l[0].props.item.active = full;
            }
            return l;
          })
        }
      </div>
    );
  };

  applyFilters = () => {
    let tempItems = [...this.state.items];

    for (let k = 0; k < this.state.filtersToApply.length; k++) {
      let filter = this.state.filtersToApply[k];

      if (filter.order !== null && filter.order !== '') {
        if (filter.type === BOOLEAN_TYPE) {
          tempItems.sort( (item1, item2) => {
            let value1 = item1[filter.key];
            let value2 = item2[filter.key];
            if (filter.order === 'high' ) {
              return value1 === value2 ? 0 : value1 ? -1 : 1;
            } else {
              return value1 === value2 ? 0 : value1 ? 1 : -1;
            }
          })
        } else if (filter.type === ALPHABETIC_DATA_TYPE || filter.type === NUMBER_DATA_TYPE) {
          tempItems.sort( (item1, item2) => {
            let value1 = `${item1[filter.key]}`;
            let value2 = `${item2[filter.key]}`;

            if (item1[filter.key] === null || item1[filter.key] === undefined || item1[filter.key] === '') {
              return 1;
            }

            if (item2[filter.key] === null || item2[filter.key] === undefined || item2[filter.key] === '') {
              return -1;
            }

            return filter.order === 'high' ? this.getStringValidation(value1, value2) : this.getStringValidation(value2, value1)
          })
        } else if (filter.type === DATE_DATA_TYPE) {
          tempItems.sort( (item1, item2) => {
            let value1 = `${item1[filter.key]}`;
            let value2 = `${item2[filter.key]}`;
            return filter.order === 'high' ? this.getDatesValidation(value1, value2) : this.getDatesValidation(value2, value1)
          })
        }
      }
      // debugger;
      if (filter.values !== null && filter.values.length > 0 && filter.itemsToFilter !== null && filter.itemsToFilter.length > 0) {
        let valuesToValidate = [];

        for (let i = 0; i < filter.values.length; i++) {
          for (let j = 0; j < filter.itemsToFilter.length; j++) {
            if (filter.values[i] === `${filter.itemsToFilter[j].value}`) {
              valuesToValidate = valuesToValidate.concat(filter.itemsToFilter[j].value);
            }
          }
        }


        tempItems = tempItems.filter((item) => {
          let value = item[filter.key];
          return valuesToValidate.find( (filterItem) => {
            return filterItem === value;
          });         
        });
        
      }

    }

    this.props.updateShownItems && this.props.updateShownItems(tempItems);
    // console.log("itemsTOSHOW", [...tempItems]);
    this.setState({
      itemsToShow: tempItems.slice(0, this.state.maxElementsToShow)
    }, () => {
      this.updateScrollRef();
    });
  }

  handleScroll = (e) => {
    // let bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    let bottom = (e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight + 1;
    let className = e?.target?.className || ''
    
    if (bottom && className === 'rows-container') {
      this.setState({
        maxElementsToShow: this.state.maxElementsToShow + MAX_ELEMENTS_TO_SHOW
      }, () => {
        this.applyFilters();
      });
    }
  }

  updateScrollRef = () => {
    const r = document.getElementsByClassName('header')
    const r2 = document.getElementsByClassName('row')
    let currentScrollRef = this.props.secondaryScrollRef ? this.props.secondaryScrollRef : scrollRef;
    Array.from(r2).forEach( a => (a.scrollLeft = currentScrollRef?.current?.scrollLeft || 0))
    Array.from(r).forEach( a => (a.scrollLeft = currentScrollRef?.current?.scrollLeft || 0))
  }

  transformItemsToFilter = (key) => {
    let filterToReturn = [];

    for (let i = 0; i < this.state.items.length; i++) {

      let valueToSet = this.state.items[i][key];

      if (valueToSet !== '' && valueToSet !== null && !filterToReturn.find((item) => { return valueToSet === item.value })) {
        filterToReturn = filterToReturn.concat(
          {
            id: valueToSet,
            description: `${valueToSet}`,
            value: valueToSet,
          }
        );
      }

    }

    return filterToReturn;
  }

  getStringValidation = (dataString1, dataString2) => {
    let data1 = (dataString1 !== null && dataString1 !== undefined) ? (dataString1 || '').toLowerCase() : '';
    let data2 = (dataString2 !== null && dataString2 !== undefined) ? (dataString2 || '').toLowerCase() : '';
    let res = data1.localeCompare(data2);
    // debugger;
    return res;
  }

  getDatesValidation = (date1, date2) => {
    let data1 = (date1 !== null && date1 !== undefined) ? date1 : '';
    let data2 = (date2 !== null && date2 !== undefined) ? date2 : '';
    
    if (data1 !== '' && data2 !== '') {
      return new Date(data1).getTime() - new Date(data2).getTime()
    } else {
      return true
    }
  }

  getArrayTable = () => {
    let columns = Object.keys(this.state.arrayData[0]).map(key => ({key: key, title: key, hideArrow: true}));
    return <TableSortable 
      isLoading={false}
      itemsToHide={[]}
      didSelectRow={() => {}}
      columns={columns}
      items={this.state.arrayData}
      secondaryScrollRef={secondaryScrollRef}
    />
  }

  render() {
    let tempArray = this.state.itemsToShow || [];
    let isLoading = this.props?.isLoading || false;

    let data = this.getFilterData();
    let type = data?.filter?.type || '';
    let items = data?.filter?.items || [];

    if (data?.filter === undefined && this.state.rowOpened !== '') {
      type = this.getDataType(this.state.rowOpened) || '';
      if (type !== null && (type === ALPHABETIC_DATA_TYPE || type === NUMBER_DATA_TYPE)) {
        items = this.state.items;
      }
    }

    return(
      <div 
        className={`table-sortable ${this.state.rowOpened !== '' ? 'open' : ''}`} 
        style={{
          marginTop: this.props.embedded ? '0px' : '20px',
          borderRadius: this.props.embedded ? '0px' : '20px',
        }}
      >
        { this.getHeaderRow() }
        {
          (this.state.rowOpened !== '' && type !== '' ) &&
          <div ref={tooltipRef} className='content' onClick={(e) => e.stopPropagation()} style={{top: this.state.topPosition, left: this.state.rightPosition}}>
            <div className="deselector">
              <span onClick={() => {
                this.clearFilter(data.key || '');
              }}>
                Limpiar filtro
              </span>
            </div>
            {(items.length > 0 && type !== BOOLEAN_TYPE) && this.getTextSearch()}
            {data.hideSort ? '' : this.getCheckbox(data, items)}

            {
              (type !== '' && items.length > 0 && !data.hideSort) &&
              <div className='separator' style={{marginTop: 15, marginBottom: 15}}/>
            }

            { type !== BOOLEAN_TYPE && this.checkList(data)}
          </div>
        }
        { isLoading ? this.presentLoader() : tempArray.length === 0 ? this.getNoData() : this.getContent(tempArray) }
        <Modal
          isOpen={this.state.showArrayData}
          portalClassName="dialog sortable-table-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">{this.state.arrayTitle}</div>
          <div
            className="close"
            onClick={() => { this.setState({ showArrayData: false, arrayData: [], arrayTitle: '' })}}
          ></div>
          <div className="message">
            {
              // [this.state.arrayData]
              this.state.arrayData.length
              ? this.getArrayTable()
              : 'No hay datos'
            }
          </div>
        </Modal>
      </div>
    );
  }

}

export default TableSortable;
