import React from "react";
const Dolly = (props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 18 9"
      fill="#AFC8FF"
      //   stroke="#AFC8FF"
      transform="scale(1,-1)"
      {...props}
    >
      <path d="M0.755434676,4.39182502 C0.755434676,4.62018633 0.779548499,4.84305441 0.82619491,5.05807503 L0,5.05807503 L0,8.54745154 L15,8.52674707 L15,7.27674707 L16.875,7.27674707 L16.875,6.02674707 L15,6.02674707 L15.0286121,5.05807503 L13.2851351,5.05807503 C13.3317815,4.84305441 13.3562906,4.62018633 13.3562906,4.39182502 C13.3562906,2.64615582 11.9280408,1.22929553 10.1689176,1.22929553 C8.64539841,1.22929553 7.37013297,2.29145028 7.05586265,3.70988007 C6.74119703,2.29145028 5.46593159,1.22929553 3.94280767,1.22929553 C2.18368454,1.22929553 0.755434676,2.64615582 0.755434676,4.39182502 Z M5.42114695,4.34645503 C5.42114695,5.1691938 4.75329036,5.83727044 3.93033154,5.83727044 C3.10737273,5.83727044 2.43951613,5.1691938 2.43951613,4.34645503 C2.43951613,3.52371625 3.10737273,2.85563962 3.93033154,2.85563962 C4.75329036,2.85563962 5.42114695,3.52371625 5.42114695,4.34645503 Z M11.6554659,4.34645503 C11.6554659,5.1691938 10.9876094,5.83727044 10.1646505,5.83727044 C9.34169172,5.83727044 8.67383513,5.1691938 8.67383513,4.34645503 C8.67383513,3.52371625 9.34169172,2.85563962 10.1646505,2.85563962 C10.9876094,2.85563962 11.6554659,3.52371625 11.6554659,4.34645503 Z"></path>
    </svg>
  );
};

export default Dolly;
