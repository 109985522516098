import React from "react";
const CheckCircleIcon = (props) => {
  return (
    <svg
      width="20px" 
      height="20px" 
      viewBox="0 0 48 48" 
      xmlns="http://www.w3.org/2000/svg" 
      fill="#6dc36d"
      {...props}
    >
      <circle cx="24" cy="24" r="21" />
      <path d="M21.39,31A2,2,0,0,1,20,30.4l-5.39-5.53a2,2,0,0,1,2.87-2.79l4,4.12,9.18-8.65a2,2,0,0,1,2.74,2.91l-10.61,10A2,2,0,0,1,21.39,31Z" fill="#fff"/>
    </svg>
  );
};

export default CheckCircleIcon;