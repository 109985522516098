import React from "react";
import FleetDetail from "./fleet-detail";
import SVGIcon, { icons } from "../../svg-icon";
import FleetDetailTitle from "./fleet-detail-title";

class FleetInfoHeader extends React.Component {
  constructor() {
    super();

    this.state = {
      isCollapsed: true
    };
  }
  render() {
    const { operators, vehicles, trailers } = this.props.data;
    const { showData } = this.props;
    
    var isShowData = (showData !== null) ? showData : true;

    return (
      <div className="fleet-container">
        <div className="fleet-container fleet-column">
          {
            isShowData ? 
            <FleetDetailTitle isDataCollapsed={this.state.isCollapsed} />
            :
            <div/>
          }
        </div>

        <div className="fleet-container fleet-column">
          <SVGIcon
            name={icons.user}
            fill="#AFC8FF"
            width="26px"
            height="24px"
            viewBox="0 0 26 26"
          />
          <div style={{ width: "5px", height: "10px" }} />
          Operadores

          {
            isShowData ? 
            <FleetDetail
              isDataCollapsed={this.state.isCollapsed}
              data={operators}
            /> 
            : 
            <div/>
          }
        </div>

        <div className="fleet-container fleet-column">
          <SVGIcon
            name={icons.truck}
            fill="#AFC8FF"
            width="36px"
            height="26px"
            viewBox="0 0 18 18"
          />
          <div style={{ width: "8px", height: "10px" }} />
          Vehículos
          {
            isShowData ? 
            <FleetDetail
              isDataCollapsed={this.state.isCollapsed}
              data={vehicles}
            />
            :
            <div/>
          }
        </div>

        <div className="fleet-container fleet-column">
          <SVGIcon
            name={icons.equipment}
            fill="#AFC8FF"
            width="26px"
            height="26px"
          />
          <div style={{ width: "8px", height: "10px" }} />
          Remolques
          {
            isShowData ? 
            <FleetDetail
              isDataCollapsed={this.state.isCollapsed}
              data={trailers}
            />
            :
            <div/>
          }
        </div>
        
        {
            isShowData ? 
            <div
              className="fleet-container collapse-btn"
              onClick={() => {
                this.setState({
                  isCollapsed: !this.state.isCollapsed
                });
              }}
            >
              <img
                alt=""
                src="/images/select-arrow.svg"
                style={{
                  transform: this.state.isCollapsed
                    ? "rotate(0deg)"
                    : "rotate(180deg)"
                }}
              ></img>
            </div>
            :
            <div/>
        }
      </div>
    );
  }
}

export default FleetInfoHeader;
