import React from "react";
const XMLIcon = (props) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="20.000000pt" 
      height="20.000000pt" 
      viewBox="0 0 76.000000 100.000000"
      fill="#DCDCDC"
      {...props} 
    >
      <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      stroke="none">
        <path d="M32 984 l-22 -15 0 -469 0 -469 22 -15 c34 -24 662 -24 696 0 22 15
        22 16 22 340 l0 324 -138 0 c-181 0 -172 -9 -172 178 l0 142 -193 0 c-158 0
        -197 -3 -215 -16z m426 -420 c12 -4 22 -14 22 -23 0 -17 -115 -420 -122 -426
        -2 -3 -21 1 -42 7 -44 14 -47 -14 31 256 59 201 58 200 111 186z m-191 -106
        c32 -30 29 -41 -17 -81 l-41 -35 41 -32 c22 -17 40 -37 40 -43 0 -13 -40 -57
        -53 -57 -14 0 -137 122 -137 136 0 11 128 133 139 134 3 0 15 -10 28 -22z
        m337 -47 l69 -70 -69 -65 c-38 -36 -74 -65 -81 -66 -6 0 -21 13 -32 28 l-20
        29 40 39 40 39 -42 37 -43 37 29 31 c16 16 31 30 35 30 3 0 37 -31 74 -69z"/>
        <path d="M510 875 l0 -125 120 0 c92 0 120 3 120 13 0 13 -221 237 -233 237
        -4 0 -7 -56 -7 -125z"/>
      </g>
    </svg>
    // <svg 
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 115.28 122.88"
    //   fill="#DCDCDC"
    //   width="50"
    //   height="50"
    //   {...props}
    // >
    //   <g>
    //     <path class="st0" fill-rule="evenodd" clip-rule="evenodd" d="M25.38,57h64.88V37.34H69.59c-2.17,0-5.19-1.17-6.62-2.6c-1.43-1.43-2.3-4.01-2.3-6.17V7.64l0,0H8.15 c-0.18,0-0.32,0.09-0.41,0.18C7.59,7.92,7.55,8.05,7.55,8.24v106.45c0,0.14,0.09,0.32,0.18,0.41c0.09,0.14,0.28,0.18,0.41,0.18 c22.78,0,58.09,0,81.51,0c0.18,0,0.17-0.09,0.27-0.18c0.14-0.09,0.33-0.28,0.33-0.41v-11.16H25.38c-4.14,0-7.56-3.4-7.56-7.56 V64.55C17.82,60.4,21.22,57,25.38,57L25.38,57z M29.98,68.76h7.76l4.03,7l3.92-7h7.66l-7.07,11.02l7.74,11.73h-7.91l-4.47-7.31 l-4.5,7.31h-7.85l7.85-11.86L29.98,68.76L29.98,68.76z M55.72,68.76H65l3.53,13.85l3.54-13.85h9.23v22.76h-5.75V74.17l-4.44,17.35 H65.9l-4.43-17.35v17.35h-5.75V68.76L55.72,68.76z M85.31,68.76h7.03v17.16h11v5.59H85.31V68.76L85.31,68.76z M97.79,57h9.93 c4.16,0,7.56,3.41,7.56,7.56v31.42c0,4.15-3.41,7.56-7.56,7.56h-9.93v13.55c0,1.61-0.65,3.04-1.7,4.1c-1.06,1.06-2.49,1.7-4.1,1.7 c-29.44,0-56.59,0-86.18,0c-1.61,0-3.04-0.64-4.1-1.7c-1.06-1.06-1.7-2.49-1.7-4.1V5.85c0-1.61,0.65-3.04,1.7-4.1 c1.06-1.06,2.53-1.7,4.1-1.7h58.72C64.66,0,64.8,0,64.94,0c0.64,0,1.29,0.28,1.75,0.69h0.09c0.09,0.05,0.14,0.09,0.23,0.18 l29.99,30.36c0.51,0.51,0.88,1.2,0.88,1.98c0,0.23-0.05,0.41-0.09,0.65V57L97.79,57z M67.52,27.97V8.94l21.43,21.7H70.19 c-0.74,0-1.38-0.32-1.89-0.78C67.84,29.4,67.52,28.71,67.52,27.97L67.52,27.97z"/>
    //   </g>
    // </svg>
  );
};
export default XMLIcon;