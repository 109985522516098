import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

class SubStringText extends React.Component {
  getCompanyName = (label) => {
    let text =
      String(label).length > this.props.labelSize
        ? String(label).substring(0, this.props.labelSize) + "... "
        : label || "";

    return {
      isLarge: String(label).length > this.props.labelSize ? true : false,
      text: text,
    };
  };

  render() {
    return this.getCompanyName(this.props.label).isLarge ? (
      <React.Fragment>
        {React.createElement(
          this.props.labelTag,
          {
            style: this.props.labelStyle,
            className: this.props.labelClass,
            "data-tip": true,
            "data-for": this.props.tooltipClass,
          },
          this.getCompanyName(this.props.label).text
        )}
        <ReactTooltip
          id={this.props.tooltipClass}
          place={this.props.tooltipPosition}
          type={"light"}
          effect="solid"
        >
          {this.props.label}
        </ReactTooltip>
      </React.Fragment>
    ) : (
      React.createElement(
        this.props.labelTag,
        {
          style: this.props.labelStyle,
          className: this.props.labelClass,
        },
        this.props.label
      )
    );
  }
}

SubStringText.propTypes = {
  label: PropTypes.string,
  labelTag: PropTypes.string,
  labelSize: PropTypes.number,
  labelStyle: PropTypes.object,
  labelClass: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

SubStringText.defaultProps = {
  labelTag: "h2",
  labelSize: 10,
  labelStyle: {},
  labelClass: "",
  tooltipClass: "tooltip-class",
  tooltipPosition: "bottom",
};

export default SubStringText;
