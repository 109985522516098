import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ShipmentBoardRow extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    // onSelect: PropTypes.func,
    // selectionMode: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    className: '',
    defaultClass: '',
    onClick: () => { console.log('Not implemented yet!'); },
    selected: false
  }

  handleClick = (e) => {
    this.props.onClick({ target: { name: this.props.id, id: e.target.id, value: !this.props.selected } });
  }

  render() {
    return (
      <div key={this.props.id} className={''.concat('shipment-row', ' ', this.props.defaultClass, ' ', this.props.className)} onClick={this.handleClick}>
        <div className='overlay'>
          <div className="checkbox"></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default ShipmentBoardRow;