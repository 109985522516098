//@flow

const ACTIONS = {
  SET_ACTION: {
    TYPE: "SET_ACTION",
    ACTION: (name: string, config: any) => ({
      type: ACTIONS.SET_ACTION.TYPE,
      config,
      name,
    }),
  },
  REMOVE_ACTION: {
    TYPE: "REMOVE_ACTION",
    ACTION: (name: string) => ({
      type: ACTIONS.REMOVE_ACTION.TYPE,
      name,
    }),
  },
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTION":
      return {
        ...state,
        [action.name]: action.config,
      };
    case "REMOVE_ACTION":
      delete state[action.name];

      return {
        ...state,
      };
    default:
      return state;
  }
};

export const setNavbarAction = (name: string, config: any) => {
  return function(dispatch: any) {
    dispatch(ACTIONS.SET_ACTION.ACTION(name, config));
  };
};
export const removeNavbarAction = (name: string) => {
  return function(dispatch: any) {
    dispatch(ACTIONS.REMOVE_ACTION.ACTION(name));
  };
};
