import React from 'react';
import Modal from 'react-modal';
import TextInput from '../controls/text-input';
import Button from '../controls/button';
import SVGIcon, { icons } from '../svg-icon';
import ChecklistTooltip from '../component/checklist-tooltip';
import { length, number, upper, secuentialNumber, lower } from '../string-constants';
import { connect } from 'react-redux';
import { resetNewPassword } from '../../redux/reducers/register.reducer';
import { handleWSError } from '../utils';

Modal.setAppElement('#root');

class ResetNewPasswordDialog extends React.Component {

  constructor() {
    super();

    this.state = {
      password: '',
      confirmPassword: '',

      pwdType: true,
      confirmPwdType: true,

      passwordFocused: false,
      confirmPasswordFocused: false,

      genericError: ''
    }

  }

  onClose = () => {
    this.props.closeAction();
    this.setState({
      password: '',
      confirmPassword: '',

      pwdType: true,
      confirmPwdType: true,

      passwordFocused: false,
      confirmPasswordFocused: false,

      termsCheck: false
    })
  }

  getErrorPasswordEmail = () => {

    if (this.state.passwordFocused && this.state.password === '') {
      return ['Oops... requerimos este campo'];
    }

    if (this.state.passwordFocused && !this.isPasswordValid()) {
      return ['Oops... el password no es valido'];
    }

    return [];

  }

  getErrorConfirmPasswordEmail = () => {

    if (this.state.confirmPasswordFocused && this.state.confirmPassword === '') {
      return ['Oops... requerimos este campo'];
    }

    if (this.state.confirmPasswordFocused && this.state.confirmPassword !== this.state.password) {
      return ['Oops... tu password no coincide'];
    }

    return [];

  }

  isPasswordValid = () => {
    const { password } = this.state;
    return (length.test(password) && number.test(password) && upper.test(password) && lower.test(password) && !secuentialNumber.test(password))
  }

  isValidFields = () => {
    return (
      this.state.password !== '' && 
      this.state.confirmPassword !== '' && 
      this.state.confirmPassword === this.state.password) &&
      this.isPasswordValid()
  }

  createAccount = () => {
    
    const { password } = this.state;

    this.props.resetNewPassword({ password, repeat_password: password, token: this.props.token }).then( () => {

      if (this.props.resetPassword.error === null) {

        this.onClose();

        this.setState({
          genericError: '',
        }, () => {
          if (this.props.closure)
            this.props.closure();
        });

      } else {

        const message = handleWSError(this.props.resetPassword.error);
        this.setState({
          genericError: message,
          confirmPassword: '',
          password: ''
        }, () => {
          this.password.setFocus();
        });
        
      }

    });

  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog login-dialog"
        overlayClassName="overlay"
        className="content"
        >

        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>

        {
          (this.state.genericError !== '') ?
          <div
            style={{
              color: 'red',
              marginLeft: 63,
              fontSize: 15,
              marginRight: 63

            }}
          >{this.state.genericError}</div>: <div/>
        }

        <div className='form'>

          <TextInput
            ref={(input) => { this.password = input; }} 
            type={this.state.pwdType ? 'password' : 'text'}
            label='Ingresa tu nueva contraseña'
            name='password'
            className="full"
            errors={this.getErrorPasswordEmail()}
            dataFor="orbi-tooltip-password"
            dataTip={''}
            onChange={event => {
              const { target: { value: password = '' } } = event;
              this.setState({ password, passwordFocused: false });
            }}
            value={this.state.password}
            handleBlur={  () => this.setState({passwordFocused: true})}
            suffix={{
              element:
                <div onClick={() => this.setState({pwdType: !this.state.pwdType})}>
                  <SVGIcon name={icons.passwordToggle} fill={ this.state.pwdType ? '#AFC8FF' : 'rgb(30,70,233)' } />
                </div>,
              className: ''
            }}
          />

          <ChecklistTooltip
            tooltipId='orbi-tooltip-password'
            className='custom-tooltip'
            event='focusin'
            eventOff='focusout'
            title='Tu contraseña debe contener:'
            valueToValidate={this.state.password}
            validations={[
                { valid: (value) => { return length.test(value) }, textToShow: 'Minimo 8 caracteres' },
                { valid: (value) => { return number.test(value) }, textToShow: 'Un número (0-9)' },
                { valid: (value) => { return upper.test(value) }, textToShow: 'Una mayúscula (A-Z)' },
                { valid: (value) => { return lower.test(value) }, textToShow: 'Una minúscula' },
                { valid: (value) => { return !secuentialNumber.test(value) }, textToShow: 'No deben ser 3 o más números consecutivos' }
            ]}        
          />

          <TextInput
            type={this.state.confirmPwdType ? 'password' : 'text'}
            label='Confirma tu contraseña'
            name='confirmPassword'
            className="full"
            errors={this.getErrorConfirmPasswordEmail()}
            onChange={event => {
              const { target: { value: confirmPassword = '' } } = event;
              this.setState({ confirmPassword, confirmPasswordFocused: false });
            }}
            value={this.state.confirmPassword}
            handleBlur={  () => this.setState({confirmPasswordFocused: true})}
            suffix={{
              element:
                <div onClick={() => this.setState({confirmPwdType: !this.state.confirmPwdType})}>
                  <SVGIcon name={icons.passwordToggle} fill={ this.state.confirmPwdType ? '#AFC8FF' : 'rgb(30,70,233)' } />
                </div>,
              className: ''
            }}
            handleEnter={ () => {
              if (this.isValidFields() && !this.props.resetPassword.isFetching) 
                this.createAccount();
            }}
          />

          <div className='button-container'>

            <div/>

            <div className='recover-password'> 
              <Button
                text={ this.props.resetPassword.isFetching ? 'Cargando...' : 'Actualizar contraseña'}
                type={'primary'}
                onClick = { () => this.createAccount() }
                disabled = { !this.isValidFields() || this.props.resetPassword.isFetching }
              />
            </div>
          </div>

        </div>

      </Modal>
    );   
  }

}

const mapStateToProps = state => {
  return {
    resetPassword: {
      isFetching: state.register.resetNewPassword.isFetching,
      data: state.register.resetNewPassword.data,
      error: state.register.resetNewPassword.error
    }
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    resetNewPassword: (data) => dispatch(resetNewPassword(data))
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetNewPasswordDialog);