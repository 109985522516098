//@flow
import React from "react";
import ReactDOM from "react-dom";
import { View, ViewTitle } from "../../shared/styled/view";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import {
  cancelRequest,
  readEntities,
  getEntityItems,
  createEntity,
  deleteEntity,
  partialEntity,
} from "../../redux/api";
import { MapController } from "../../shared/component/map-container";
import FilterBar from "../../shared/component/filter/filter-bar";
// import { listFacilities } from "../../redux/reducers/facility-reducer";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import Button from "../../shared/controls/button";
import Modal from "react-modal";
import TextInput from "../../shared/controls/text-input";
import SelectInput from "../../shared/controls/select-input";
import { listExpeditionClasses } from "../../redux/reducers/company-reducer";
import SelectInputStyled from "../../shared/controls/select-input-styled";
import Operator from "../../shared/icons/assemble/operator";
import Equipment from "../../shared/icons/assemble/equipment";
import Truck from "../../shared/icons/assemble/truck";
import { getUrlsEnv } from "../../shared/backend-api";
import TruckEquipment from "../../shared/icons/assemble/truck-equipment";
import Unitary from "../../shared/icons/assemble/unitary";
import { normalizeStr, groupFacilities } from "../../shared/utils";
import { TruckDoubleEquipment } from "../../shared/icons/assemble";
import WifiIcon from "../../shared/icons/gps/wifi-icon";
import CellphoneIcon from "../../shared/icons/gps/cellphone-icon";
import TruckMinIcon from "../../shared/icons/gps/truck-min-icon";
import EquipmentMinIcon from "../../shared/icons/gps/equipment-min-icon";
import TextExceedPlus from "../../shared/controls/text-exceed-plus";
import ReactTooltip from "react-tooltip";
import SVGIcon, { icons } from "../../shared/svg-icon";
import Axios from "axios";
import {
  loadTimeZones,
} from '../../redux/reducers/global-catalog.reducer';
import UnitsTableView from "./units-table.view";
import axios from "axios";
import { SERVER } from "../../redux/api/api-urls";
import moment from 'moment';
import { TimeLine } from "../../shared/animation";
import Loader from "../../shared/icons/tat/loader";
import { isArray } from 'lodash';


type StateToProps = {|
  companyId: number,
|};

type DispatchToProps = {||};

type OwnProps = {|
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {};

const STAND_BY_MODE = 0;
// const SELECT_TYPE_MODE = 1;
const CREATE_MODE = 2;
const EDIT_MODE = 3;

const tabs = [
  { id: "3", description: "UNIDADES" },
  { id: "1", description: "VIAJES" },
  { id: "2", description: "ZONAS" },
];

const color = "#7ED321";

const colors = {
  1: "#FE8F9D",
  2: "#FBA61A",
  3: "#A659E9",
  4: "#777777",
  5: "#50C4E3",
};
const GEOFENCE_TYPES = {
  1: {
    name: "PROXIMITY",
    description: "Proximidad",
    id: 1,
    color: "#55B740",
  },
  2: {
    name: "GATE",
    description: "Entradas",
    id: 2,
    color: "#5EB5FF",
  },
  3: {
    name: "VALIDATION",
    description: "Validaciones",
    id: 3,
    color: "#FFF845",
  },
};

const { files } = getUrlsEnv();

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const MONTHS = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
]

class GPSView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      geofences: [],
      currentGeofence: {},
      mode: STAND_BY_MODE,
      showDialog: false,

      trucks: [],
      markers: [],

      tabsItem: tabs[0].id,

      searchStr: [],
      emails: [],
      inFacility: [],
      originDestination: [],
      unitStatus: [],
      baseId: [],
      orderTag: [],
      tripTag: [],

      showDeleteZoneModal: false,
      showCreateZoneModal: false,
      showCreateZoneDetailModal: false,
      selectedZoneType: 0,
      polygon: null,
      selectedZoneName: "",
      selectedTimeZone: 0,
      selectedZoneDetails: [],
      selectedZoneId: 0,
      originalDetails: [],

      maxDetailId: 0,

      openTrip: 0,
      openFacility: 0,
      markerFacilities: [],
      facilityPoligons: [],
      visibleFacilities: true,
      pol_timezones: [],

      selectedStartDate: null,
      selectedEndDate: null,
      platesSelected: '',
      searchPlates: '',
      isConsultingLoading: false,
      isResetingLoading: false,
      isPaintingRouteLoading: false,
      routeEvents: [],
      routeLocations: [],

      isPlaying: false,
      routeDistance: 0,
      speed: 80000,
      routePoints: [],

      buttonFilterName: "Aplicar",

      showProgressLoader: false,
      showEmptyModal: false

    };
    this.dialog = "";
    this.mapController = new MapController();
    this.apiLoaded = false;
    this.markerCluster = null;
    this.hours = this.setHours();
    this.routeTimeline = null;
    this.assembly = '';
    this.assemblyLoaded = false;
    this.trucksCalled = false;
  }

  idCounter = -999999;

  timeFormat(x){
    if (x<10) return '0'+x
    else return x
  };  

  setHours(){
    let hour_list=[]
    let y, i
    
    hour_list.push(
      {
        id: 0,
        description: "",
      }
    )

    for (i=0; i<24; i++){
      for(y=0; y<60;){
        hour_list.push(
          {
            id: ''+i+'.'+y,
            description: ''+this.timeFormat(i)+':'+this.timeFormat(y)+' Hrs'
          }
        )
        y+=5
      }
    }

    return hour_list
  }

  componentDidUpdate(nextPrevs) {

    if (nextPrevs && this.props.trucksByFacility && this.props.trucksByFacility.length > 0 && this.assembly !== '') {

      var items = [];

      for(var i = 0; i < this.props.trucksByFacility.length; i++) {
        items = items.concat(this.props.trucksByFacility[i].items);
      }

      let searchStr = normalizeStr(this.assembly);

      let newItems = items.filter(
        (truck) =>
          (truck.trips.some(
            (trip) =>
              normalizeStr(trip.code).includes(searchStr) ||
              normalizeStr(trip.fleet_provider.orbi_id).includes(searchStr) ||
              normalizeStr(trip.fleet_provider.alias).includes(searchStr) ||
              trip.trip_orders.some((order) =>
                normalizeStr(order.code).includes(searchStr)
              )
          ) ||
            Object.keys(truck.vehicles).some((veKey) =>
              truck.vehicles[veKey].some(
                (ve) =>
                  normalizeStr(ve.economic_number).includes(searchStr) ||
                  normalizeStr(ve.plates).includes(searchStr)
              )
            ) ||
            Object.keys(truck.equipments).some((veKey) =>
              truck.equipments[veKey].some(
                (ve) =>
                  normalizeStr(ve.economic_number).includes(searchStr) ||
                  normalizeStr(ve.plates).includes(searchStr)
              )
            ) ||
            Object.keys(truck.operators).some((veKey) =>
              truck.operators[veKey].some(
                (ve) =>
                  normalizeStr(ve.first_name).includes(searchStr) ||
                  normalizeStr(ve.last_name).includes(searchStr)
              )
            ))
      )

      //var newItems = 0;

      if (newItems.length > 0 && newItems[0].location.length && !this.assemblyLoaded) {
        this.assemblyLoaded = true;
        this.showTruckInfo(newItems[0]);
        this.centerTruck(newItems[0].location);  
      }

    }

  }

  componentDidMount() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let urlSearch = urlParams.get('search');

    if (urlSearch !== null && urlSearch !== undefined) {
      
      this.assembly = urlSearch || '';
      this.setState({
        searchStr: [urlSearch]
      });

    }

    this.props.loadCompanyTags(this.props.companyId);
    // if(this.props.ownCompanyType === 1){
    //   this.props.loadTransportCompanyFilters(this.props.companyId)
    // }
    let self = this;
    this.mapController.loadMap(null, null, null, "roadmap", false);
    this.mapController.isReadySubject.subscribe((ready) => {
      if (ready) {
        window.google.maps.event.addListener(
          this.mapController.map,
          "click",
          function(event) {
            if(self.state.mode !== EDIT_MODE) self.mapController.hideInfoWindow();
          }
        );
        this.loadCluster();
        this.loadZones();
        this.loadTrucks();

        //this.loadFacilities();
        //
      }
    });
  }

  loadApi = () => {
    return new Promise((resolve) => {
      if (this.apiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes(
          "https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js"
        )
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  loadCluster = () => {
    this.loadApi().then((value) => {
      this.apiLoaded = value;
      this.markerCluster = new window.MarkerClusterer(
        this.mapController.map,
        [],
        {
          maxZoom: 17,
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        }
      );
    });
  };

  drawMarkers = (result = this.props.trucks) => {
    if (this.markerCluster) this.markerCluster.clearMarkers();
    this.state.trucks.forEach((truck) => {
      truck.setMap(null);
    });
    this.setState({
      trucks: [],
    });

    let locations = [];

    let trucks = result.filter(truck => {
      return truck.location.length && 
        (!this.state.searchStr.length || 
          this.state.searchStr.some(strSearch => {
          return truck.trips.some(trip => {
              return normalizeStr(trip.code).includes(normalizeStr(strSearch)) ||
                normalizeStr(trip.fleet_provider.orbi_id).includes(normalizeStr(strSearch)) ||
                normalizeStr(trip.fleet_provider.alias).includes(normalizeStr(strSearch)) ||
                trip.trip_orders.some((order) =>
                  normalizeStr(order.code).includes(normalizeStr(strSearch))
                )
            }) ||
            Object.keys(truck.vehicles).some((veKey) =>
              truck.vehicles[veKey].some(
                (ve) =>
                  normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                  normalizeStr(ve.plates).includes(normalizeStr(strSearch))
              )
            ) ||
            Object.keys(truck.equipments).some((veKey) =>
              truck.equipments[veKey].some(
                (ve) =>
                  normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                  normalizeStr(ve.plates).includes(normalizeStr(strSearch))
              )
            ) ||
            Object.keys(truck.operators).some((veKey) =>
              truck.operators[veKey].some(
                (ve) =>
                  normalizeStr(ve.first_name).includes(normalizeStr(strSearch)) ||
                  normalizeStr(ve.last_name).includes(normalizeStr(strSearch))
              )
            )
        }))
    }).map((truck) => {
      // debugger;
      // let marker = this.mapController.createMarker();
      let latLng = {
        lat: truck.location[0],
        lng: truck.location[1],
      };
      locations.push(latLng);
      let markerIcon = {};
      if ((Object.keys(truck.equipments) || []).length > 1) {
        markerIcon.icon = "/images/icon_fill_truck_doble_dark.svg";
        markerIcon.width = 50;
        markerIcon.height = 15;
      } else {
        markerIcon.icon = "/images/truck.svg";
        markerIcon.width = 30;
        markerIcon.height = 20;
      }
      let marker = this.mapController.setMarkerImage(
        markerIcon.icon,
        latLng,
        {
          width: markerIcon.width,
          height: markerIcon.height,
        }
      );
      // marker.setIcon((Object.keys(truck.equipments) || []).length > 1 ? "/images/icon_fill_truck_doble_dark.svg" : "/images/truck.svg");

      marker.setMap(this.mapController.map);
      // let latLng = {
      //   lat: truck.location[0],
      //   lng: truck.location[1],
      // };
      // debugger;
      // this.mapController.setMarker(latLng, marker);
      marker.addListener("click", () => {
        // console.log("click", truck);
        this.showTruckInfo(truck);
      });

      return marker;
    })

    if (this.markerCluster) {
      this.markerCluster.addMarkers(trucks);

      let locationData = this.getDrawersToMakeZoom(locations);

      if (locationData.one !== null) {
        this.mapController.setCenterMap(locationData.one);
        this.mapController.map.setZoom(16);
      } else if (locationData.multiple !== null) { 
        this.mapController.setCenterMapMultiple(locationData.multiple.lat_min, locationData.multiple.lng_min, locationData.multiple.lat_max, locationData.multiple.lng_max);
      }
    }
    this.setState({
      trucks,
      showEmptyModal: trucks.length === 0
    });
  };

  drawEventLocations = (events) => {

    this.state.routeEvents.forEach(e => {
      e.setMap(null);
    });

    var locations = [];

    for (var key in events) {
      if (typeof key === 'string' || key instanceof String) {

        let point = key.split(',');
        let eventArray = events[key];

        if (point.length > 1) {

          let marker = this.mapController.setMarkerImage(
            "/images/circleRed.png",
            {lat: point[0], lng: point[1]},
            {
              width: 12,
              height: 12
            }
          );
          marker.setMap(this.mapController.map);
          marker.addListener("click", () => {
            this.showEventInfo({lat: point[0], lng: point[1]}, eventArray);
          });

          locations = locations.concat(marker);

        }

      }
    }

    this.setState({routeEvents: locations});
    
  }

  getDeviceDescription = (id) => {
    var description = '';

    if (this.props.devices && this.props.devices.length) {
      for (var i = 0; i < this.props.devices.length; i++) {
        if (this.props.devices[i].id === id) {
          description = this.props.devices[i].description;
        }
      }
    }

    return description;

  }

  showEventInfo = (positionData, data) => {

    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;

    const position = new window.google.maps.LatLng(
      positionData.lat,
      positionData.lng
    );
    this.mapController.showInfoWindow(content, position, 0, -15, () =>
      this.showEventInfoContent(data)
    );
  }

  showEventInfoContent = (e) => {
    
    let infoWindow = document.getElementById("infowindowitem");
    ReactDOM.render(
      <div className="event-tooltip-content" style={{paddingLeft: 20, paddingRight: 20, maxHeight: 200, overflowY: 'scroll'}}>
        <br/>
        {
          e.map( (item) => {
            return <><b>{`  ${item.order__code}  -  Contenedor ${item.code}  -  ${item.description}  -  ${this.getDeviceDescription(item.device)}  `}</b><br/><b>{`${item.fleet_operator1__operator__user__first_name} ${item.fleet_operator1__operator__user__last_name}  -  ${item.fleet_operator1__operator__user__username}  -  ${item.fleet_vehicle__economic_number}  - ${item.fleet_vehicle__plates}`}</b><br/> <b>{`Hora de inserción: ${moment(item.inserted).format('DD/MMM HH:mm')}  -  Hora de envio: ${moment(item.sent).format('DD/MMM HH:mm')}`}</b>  <br/><br/></>    
          })
        }
      </div>,
      infoWindow
    );

  }

  formatDate = (date) => {
    if(!date){
      return "-"
    }

    let d = new Date(date);

    let mm = d.getMonth();
    let dd = this.fillZero(d.getDate());
    let MM = this.fillZero(d.getMinutes());
    let hh = this.fillZero(d.getHours());

    return dd.toString().concat('/', MONTHS[mm], " ", hh, ":", MM);
  }

  fillZero = (time) => {
    return time < 10 ? '0'.concat(time.toString()) : time;
  }

  drawRouteLocations = async (locations) => {  

    return new Promise( (resolve, reject) => {

      if(this.routeLine){
        this.routeLine.setMap(null);
      }
  
      this.state.routeLocations.forEach(e => {
        e.setMap(null);
      });

      var points = [];

      for (var i = 0; i < locations.length; i++) {
        const { location = '' } = locations[i];

        if (typeof location === 'string' || location instanceof String) {
          let point = location.split(',');

          if (point.length > 1) {
            points = points.concat({lat: parseFloat(point[0]), lng: parseFloat(point[1])});
          }

        }
      }

      let routeDistance = 0;
        points.forEach((point, i, pointArray) => {
          if(i > 0){
            routeDistance += Math.sqrt(
              Math.pow(
                point.lat - pointArray[i - 1].lat,
                2
              ) +
                Math.pow(
                  point.lng - pointArray[i - 1].lng,
                  2
                )
            );
          }
        })

      this.routeLine = new window.google.maps.Polyline({
        path: points,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 1.0,
        strokeWeight: 2
      });

      this.routeLine.setMap(this.mapController.map);

      var markerLocations = [];

      for (var j = 0; j < locations.length; j++) {
        const { location = '', inserted, device, model, os, ram_used, speed, version, battery, imei } = locations[j];

        if (typeof location === 'string' || location instanceof String) {
          let point = location.split(',');

          if (point.length > 1) {

            let latLong = {lat: parseFloat(point[0]), lng: parseFloat(point[1])};

            let marker = this.mapController.setMarkerImage(
              "/images/circle.png",
              latLong,
              {
                width: 7,
                height: 7
              }
            );
            marker.setMap(this.mapController.map);
            marker.addListener("click", () => {
              this.showLocationInfo(latLong, {inserted, device, model, os, ram_used, speed, version, battery, imei });
            });

            markerLocations = markerLocations.concat(marker);
          }

        }
      }

      this.setState({routeLocations: markerLocations, routePoints: points, routeDistance}, () => { 
        resolve("Promise resolved successfully");
      });  

    });  
    
  }

  showLocationInfo = (positionData, data) => {
    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;

    const position = new window.google.maps.LatLng(
      positionData.lat,
      positionData.lng
    );

    this.mapController.showInfoWindow(content, position, 0, -15, () => 
      this.showLocationInfoContent(data)
    );
  }

  showLocationInfoContent = (e) => {
    
    let infoWindow = document.getElementById("infowindowitem");
    ReactDOM.render(
      <div className="event-tooltip-content" style={{paddingLeft: 20, paddingRight: 20}}>
        <br/>
        {
          this.getLocationInfoView(e)
        }
        <br/>
      </div>,
      infoWindow
    );

  }

  getLocationInfoView = (data) => {

    var dataToShow = [];

    for ( var key in data ) {

      if (key === 'inserted' && data[key] !== null && data[key] !== undefined) {
        dataToShow = dataToShow.concat(<> <b>{`${key}: ${moment(data[key]).format('DD/MMM HH:mm')}`}</b> <br/></>);
      } else if (key === 'device') {
        dataToShow = dataToShow.concat(<> <b>{`${key}: ${this.getDeviceDescription(data[key])}`}</b> <br/></>);
      } else {
        dataToShow = dataToShow.concat(<> <b>{`${key}: ${data[key]}`}</b> <br/></>);
      }

    }

    return dataToShow;

  }

  getPlates = () => {
    var platesToReturn = '';

    for (var i = 0; i < this.props.plates.length; i++) {
      const { plates = '', id } = this.props.plates[i];

      if (this.state.platesSelected.length && this.state.platesSelected.length > 0 && this.state.platesSelected[0]=== id) {
        platesToReturn = plates;
      }

    }

    return platesToReturn;
  }

  isFilterModified = () => {
    return (isArray(this.state.inFacility) && this.state.inFacility.length > 0) ||
    (isArray(this.state.originDestination) && this.state.originDestination.length > 0) ||
    (isArray(this.state.unitStatus) && this.state.unitStatus.length > 0) ||
    (isArray(this.state.orderTag) && this.state.orderTag.length > 0) ||
    (isArray(this.state.tripTag) && this.state.tripTag.length > 0) || 
    (isArray(this.state.searchStr) && this.state.searchStr.length > 0)
  }

  loadTrucks = () => {
    
    let start = new Date(this.state.selectedStartDate);
    let end = new Date(this.state.selectedEndDate);

    this.setState({ showEmptyModal: false })
    
    if (this.isFilterModified()) {
      this.trucksCalled = true;
      
      this.setState({ showProgressLoader: true })

      this.props.cancelRequest("COMPANIES.GPS");

      this.props.loadTruckLocations(
        this.props.companyId,
        {
          in_facilities: this.state.inFacility,
          from_to: this.state.originDestination,
          from_facilities: [],
          to_facilities: [],
          status: this.state.unitStatus,
          base: this.state.baseId,
          trip_tags: this.state.tripTag,
          order_tags: this.state.orderTag,
          transport_classes: [],
          fleet_providers: [],
          startDate: (this.state.selectedStartDate !== null && this.state.selectedStartDate !== undefined) ? start.toISOString() : '',
          endDate: (this.state.selectedEndDate !== null && this.state.selectedEndDate !== undefined) ? end.toISOString() : '',
          plates: this.getPlates()
        },
        {
          onSuccess: (result) => {
            const { data = [] } = result;
            this.drawMarkers(data);
  
            this.setState({
              isConsultingLoading: false,
              isResetingLoading: false,
              showProgressLoader: false,
              showEmptyModal: data.length === 0
            }, () => {
              if (this.platesTooltip) {
                ReactTooltip.hide();
              }
            });
          },
          onError: (e) => {
            console.log(e);
            console.log("////////////////////////////////////////////////////////////****");
            this.setState({
              isConsultingLoading: false,
              isResetingLoading: false,
              showProgressLoader: false,
              showEmptyModal: false
            });
          },
        }
      );
    } else {
      this.trucksCalled = false;
    }

    this.loadFacilities();
  };

  loadTruckEvents = () => {

    let start = new Date(this.state.selectedStartDate);
    let end = new Date(this.state.selectedEndDate);

    this.props.loadTruckLocationsEvents(
      this.props.companyId,
      {
        in_facilities: this.state.inFacility,
        from_to: this.state.originDestination,
        from_facilities: [],
        to_facilities: [],
        status: this.state.unitStatus,
        base: this.state.baseId,
        trip_tags: this.state.tripTag,
        order_tags: this.state.orderTag,
        transport_classes: [],
        fleet_providers: [],
        startDate: (this.state.selectedStartDate !== null && this.state.selectedStartDate !== undefined) ? start.toISOString() : '',
        endDate: (this.state.selectedEndDate !== null && this.state.selectedEndDate !== undefined) ? end.toISOString() : '',
        plates: this.getPlates()
      },
      {
        onSuccess: (result) => {
          this.drawEventLocations(result);

          this.routeTimeline = new TimeLine(20000, this.updateRouteAnimation);
        }
      }
    );

  }

  loadTruckLocations = async () => {

    let start = new Date(this.state.selectedStartDate);
    let end = new Date(this.state.selectedEndDate);

    this.setState({
      isPaintingRouteLoading: true
    });

    this.props.loadTruckLocationsRoute(
      this.props.companyId,
      {
        in_facilities: this.state.inFacility,
        from_to: this.state.originDestination,
        from_facilities: [],
        to_facilities: [],
        status: this.state.unitStatus,
        base: this.state.baseId,
        trip_tags: this.state.tripTag,
        order_tags: this.state.orderTag,
        transport_classes: [],
        fleet_providers: [],
        startDate: (this.state.selectedStartDate !== null && this.state.selectedStartDate !== undefined) ? start.toISOString() : '',
        endDate: (this.state.selectedEndDate !== null && this.state.selectedEndDate !== undefined) ? end.toISOString() : '',
        plates: this.getPlates()
      },
      {
        onSuccess: async (result) => {
          await this.drawRouteLocations(result).then( (value) => {
            setTimeout(function() {
              this.setState({
                isPaintingRouteLoading: false
              });
            }.bind(this), 100);
          });
        }
      }
    );

  }

  toggleShowFacilities = () => {
    // let visibleFacilities = true;
    this.state.markerFacilities.forEach(mF => {
      // if(mF.getMap() === this.mapController.map){
      if(this.state.visibleFacilities){
        // mF.setMap(null);
        mF.setVisible(false);
        // visibleFacilities = false;
      } else {
        // mF.setMap(this.mapController.map);
        mF.setVisible(true);
      }
    })

    this.setState({visibleFacilities: !this.state.visibleFacilities});
  }

  isFacilityFound = (idFacility) => {

    const facilities = this.state.originDestination.concat(this.state.inFacility);

    if (facilities.length == 0) return true;
 
    return facilities.find( (value) => {
      return `${idFacility}` === `${value}`;
    }) !== undefined;

  }

  getCoordinatesToMakeZoom = () => {

    const tempFacilities = this.state.originDestination.concat(this.state.inFacility);

    const facilities = tempFacilities.filter(function(elem, pos) {
      return tempFacilities.indexOf(elem) == pos;
    });

    var locationsToReturn = [];

    var lat_min = 0;
    var lat_max = 0;
    var lng_min = 0;
    var lng_max = 0;

    for (var i = 0; i < this.props.gps_facilities.length; i++) {
      const facility = this.props.gps_facilities[i];

      if (facilities.find( (value) => {
        return `${facility.id}` === `${value}`;
      }) !== undefined) {

        if (lat_min === 0) {
          lat_min = facility.location[0];
          lat_max = facility.location[0];

          lng_min = facility.location[1];
          lng_max = facility.location[1];
        } else {

          if (lat_min > facility.location[0]) {
            lat_min = facility.location[0];
          } 
          
          if (lat_max < facility.location[0]) {
            lat_max = facility.location[0];
          }

          if (lng_min > facility.location[1]) {
            lng_min = facility.location[1];
          } 
          
          if (lng_max < facility.location[1]) {
            lng_max = facility.location[1];
          }

        }

        locationsToReturn = locationsToReturn.concat({
          lat: facility.location[0],
          lng: facility.location[1],
        });
      }
      
    }

    if (locationsToReturn.length === 1) {
      return {
        one: locationsToReturn[0],
        multiple: null
      };
    } else if (locationsToReturn.length > 1) {

      return {
        one: null,
        multiple: { lat_min, lng_min, lat_max, lng_max}
      }
    
    } else {

      return {
        one: null,
        multiple: null
      }

    }
  }

  getDrawersToMakeZoom = (locations) => {

    var locationsToReturn = [];

    var lat_min = 0;
    var lat_max = 0;
    var lng_min = 0;
    var lng_max = 0;

    for (var i = 0; i < locations.length; i++) {
      const facility = locations[i];

      if (lat_min === 0) {
        lat_min = facility.lat;
        lat_max = facility.lat;

        lng_min = facility.lng;
        lng_max = facility.lng;
      } else {

        if (lat_min > facility.lat) {
          lat_min = facility.lat;
        } 
        
        if (lat_max < facility.lat) {
          lat_max = facility.lat;
        }

        if (lng_min > facility.lng) {
          lng_min = facility.lng;
        } 
        
        if (lng_max < facility.lng) {
          lng_max = facility.lng;
        }

      }

      locationsToReturn = locationsToReturn.concat({
        lat: facility.lat,
        lng: facility.lng,
      });
      
    }

    if (locationsToReturn.length === 1) {
      return {
        one: locationsToReturn[0],
        multiple: null
      };
    } else if (locationsToReturn.length > 1) {

      return {
        one: null,
        multiple: { lat_min, lng_min, lat_max, lng_max}
      }
    
    } else {

      return {
        one: null,
        multiple: null
      }

    }
  }

  presentFaility = (facilityId) => {

    var latLng = null;

    for (var i = 0; i < this.props.gps_facilities.length; i++) {

      if (`${facilityId}` === `${this.props.gps_facilities[i].id}`) {
        latLng = {
          lat: this.props.gps_facilities[i].location[0],
          lng: this.props.gps_facilities[i].location[1],
        };
      }

    }

    if (latLng !== null) {
      this.mapController.setCenterMap(latLng);
      this.mapController.map.setZoom(16);
    }

  }

  loadFacilities = () => {
    // this.setState
    this.props[this.props.ownCompanyType === 1 ? "loadTransportCompanyFilters" : "loadCompanyFacilities"](this.props.companyId, {
      onSuccess: (result) => {
        
        // debugger;;
        this.state.markerFacilities.forEach((truck) => {
          truck.setMap(null);
        });
        this.state.facilityPoligons.forEach((truck) => {
          truck.setMap(null);
        });
        this.setState({
          markerFacilities: [],
          facilityPoligons: []
        });

        let markerFacilities = [];
        let facilityPoligons = [];

        const locationData = this.getCoordinatesToMakeZoom();

        if (locationData.one !== null) {
          this.mapController.setCenterMap(locationData.one);
          this.mapController.map.setZoom(16);
        } else if (locationData.multiple !== null) { 
          this.mapController.setCenterMapMultiple(locationData.multiple.lat_min, locationData.multiple.lng_min, locationData.multiple.lat_max, locationData.multiple.lng_max);
        }

        this.props.gps_facilities.forEach((facility) => {

          if (this.isFacilityFound(facility.id)) {
             // debugger;
            let latLng = {
              lat: facility.location[0],
              lng: facility.location[1],
            };
            let marker = this.mapController.setMarkerImage(
              `${files}${
                (
                  this.props.facility_avatars.find(
                    (a) => a.id === facility.avatar
                  ) || { image: "images/avatar/icon_factory.svg" }
                ).image
              }`,
              latLng,
              {
                width: 20,
                height: 20,
              }
            );
            marker.setMap(this.mapController.map);
            markerFacilities.push(marker);

            facility.geofences.forEach((gF) => {
              // debugger;
              let coord = gF.poligon[0];
              coord.pop();
              let pol = this.mapController.createPolygon(
                coord.map((g) => ({ lat: g[0], lng: g[1] })),
                // color
                (GEOFENCE_TYPES[gF.type] || { color: color }).color
              );
              pol.setVisible(true);
              facilityPoligons.push(pol);

              pol.setMap(this.mapController.map);
            });
            // debugger;

          }

        });

        this.setState({markerFacilities, facilityPoligons})
      },
    });
  };

  loadZones = () => {
    this.state.geofences.forEach((gf) => {
      gf.polygon.setMap(null);
    });
    this.setState({
      geofences: [],
    });
    this.props.loadCompanyMapZones(this.props.companyId, {
      onSuccess: (result) => {
        let zones = getZones(result);
        this.setState(
          {
            geofences: zones.map((item) => {
              item.polygon.pop();
              let pol = this.mapController.createPolygon(
                item.polygon.map((g) => ({ lat: g[0], lng: g[1] })),
                // color
                colors[item.zone_type]
              );
              // item.clickListener = window.google.maps.event.addListener(
              //   pol,
              //   "click",
              //   () => {
              //     this.handleEditGeofence(pol);
              //   }
              // );
              return buildGeofence(item, pol);
            }),
          },
          this.showGeoFences
        );
      },
    });
  };

  showCreateZoneModal = (show) => {
    if (!show) {
      this.setState({ selectedZoneType: 0, mode: STAND_BY_MODE });
    }
    this.setState({ showCreateZoneModal: show });
  };

  showCreateZoneDetailModal = (show) => {
    if (!show) {
      let polygon = this.state.polygon;
      if (polygon) {
        this.mapController.removePolygon(polygon);
      }
      this.setState({
        selectedZoneType: 0,
        polygon: null,
        selectedZoneName: "",
        selectedTimeZone:0,
        selectedZoneDetails: [],
        maxDetailId: 0,
        mode: STAND_BY_MODE,
        selectedZoneId: 0,
        originalDetails: [],
      });
    }
    this.setState({ showCreateZoneDetailModal: show });
  };

  showGeoFences = () => {
    this.state.geofences.forEach((gf) => {
      gf.polygon.setVisible(true);
      gf.polygon.setMap(this.mapController.map);
    });
  };

  showTruckInfo = (truck) => {
    // debugger;
    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;

    const position = new window.google.maps.LatLng(
      truck.location[0],
      truck.location[1]
    );
    this.mapController.showInfoWindow(content, position, 0, -15, () =>
      this.showTruckInfoActions(truck)
    );
  };

  showTruckInfoActions = (truck) => {
    let infoWindow = document.getElementById("infowindowitem");

    // let self = this;
    ReactDOM.render(
      <div className="gps-tooltip-content">
        <div className="gps-tooltip-title">
          <div className="avatar">
            <img
              alt=""
              src={
                truck.contract && truck.contract.fleet_provider
                  ? `${files}${truck.contract.fleet_provider &&
                      truck.contract.fleet_provider.logo}`
                  : truck.trips.length
                  ? `${files}${truck.trips[0].fleet_provider.logo}`
                  : ""
              }
              onError={(e)=>{e.target.onerror = null; e.target.src="/images/factory-bckgrnd.png"}}
            />
          </div>
          <div className="description">
            <div className="company">
              {truck.contract && truck.contract.fleet_provider
                ? truck.contract.fleet_provider.alias || 
                    truck.contract.fleet_provider.orbi_id
                  : truck.trips.length
                ? truck.trips[0].fleet_provider.alias ||
                  truck.trips[0].fleet_provider.orbi_id
                : ""}
            </div>
            <div className="status">
              {truck.trips.length ? truck.trips[0].status.description : ""}
            </div>
          </div>
          <div className="type">
            <div
              className={"icon ".concat(
                (Object.keys(truck.equipments) || []).length > 1 ? "full" : ""
              )}
            >
              {(Object.keys(truck.equipments) || []).length > 1 ? (
                <TruckDoubleEquipment
                  fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                  stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                />
              ) : (
                <TruckEquipment
                  fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                  stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                />
              )}
            </div>
            <div className="description">
              {(Object.keys(truck.equipments) || []).length > 1
                ? "Full"
                : "Sencillo"}
            </div>
          </div>
        </div>
        <div className="gps-tooltip-header">
          <div className="vehicle-info">
            <div className="icon">
              <Truck />
            </div>
            <div className="description">
              <TextExceedPlus
                name={"vehicle-".concat(truck.id)}
                tooltipClassName="vehicle-tooltip"
                tooltipPosition="left"
              >
                {Object.keys(truck.vehicles).length
                  ? [
                      Object.keys(truck.vehicles)
                        .map((key) => {
                          return truck.vehicles[key]
                            .map((ve) => {
                              return (ve.plates || "").concat(
                                "/",
                                ve.economic_number
                              );
                            })
                            .join(", ");
                        })
                        .join(", "),
                    ]
                  : ["-"]}
              </TextExceedPlus>
            </div>
            <div className="gps-icon">
              <WifiIcon
                // viewBox="0 0 24 20"
                height={"20px"}
                width="24px"
                fill={this.getColorForStatus( this.getConnection(truck, 1))}
              />
              <CellphoneIcon
                height={"20px"}
                width="24px"
                fill="#979da8"
                className="cellphone"
              />
              <EquipmentMinIcon
                height={"20px"}
                width="24px"
                fill="#979da8"
                className="equipment"
              />
              <WifiIcon
                // viewBox="0 0 24 20"
                height={"20px"}
                width="24px"
                fill={this.getColorForStatus( this.getConnection(truck, 2))}
              />
              <TruckMinIcon height={"20px"} width="24px" fill="#979da8" />
            </div>
          </div>
          <div className="equipment-info">
            <div className="icon">
              <Equipment />
            </div>
            <div className="description">
              <TextExceedPlus
                name={"equipment-".concat(truck.id)}
                tooltipClassName="equipment-tooltip"
                tooltipPosition="left"
              >
                {Object.keys(truck.equipments).length
                  ? [
                      Object.keys(truck.equipments)
                        .map((key) => {
                          return truck.equipments[key]
                            .map((ve) => {
                              return (ve.plates || "").concat(
                                "/",
                                ve.economic_number
                              );
                            })
                            .join(", ");
                        })
                        .join(", "),
                    ]
                  : ["-"]}
              </TextExceedPlus>
            </div>
          </div>
          <div className="driver-info">
            <div className="icon">
              <Operator />
            </div>
            <div className="description">
              <TextExceedPlus
                name={"operator-".concat(truck.id)}
                tooltipClassName="operator-tooltip"
                tooltipPosition="left"
              >
                {Object.keys(truck.operators).length
                  ? [
                      Object.keys(truck.operators)
                        .map((key) => {
                          return truck.operators[key]
                            .map((ve) => {
                              return (ve.first_name || "").concat(
                                " ",
                                ve.last_name
                              );
                            })
                            .join(", ");
                        })
                        .join(", "),
                    ]
                  : ["-"]}
              </TextExceedPlus>
            </div>
          </div>
        </div>
        <div className="gps-tooltip-trips">
          {truck.trips
            .map((trip) => {
              return trip.trip_orders.map((order) => {
                return (
                  <div
                    className={"trip-row".concat(
                      this.getOrderClass(order.code)
                    )}
                  >
                    <div
                      className="trip-header"
                      onClick={() => this.toggleOrder(order.code, truck)}
                    >
                      <div className="icon">
                        <img alt="" src="/images/order_ticket_icon.svg" />
                      </div>
                      <div className="description">Folio: {order.code}</div>
                      <div className="toggle-icon">
                        <div>
                          {order.code === this.state.openTrip ? "-" : "+"}
                        </div>
                      </div>
                    </div>
                    <div className="trip-info">
                      <div>
                        <div>
                          <span>O:</span>{" "}
                          {
                            (
                              this.props.gps_facilities.find(
                                (f) => f.id === order.from_facility
                              ) || {}
                            ).name
                          }
                        </div>
                        <div>{order.appointment_loading_date}</div>
                      </div>
                      <div>
                        <div>
                          <span>D:</span>{" "}
                          {
                            (
                              this.props.gps_facilities.find(
                                (f) => f.id === order.to_facility
                              ) || {}
                            ).name
                          }
                        </div>
                        <div>{order.appointment_unload_date}</div>
                      </div>
                    </div>
                    <div className="trip-time">
                      <div>{order.status?.description}</div>
                      <div>
                        {/* order.events.length
                          ? (
                              (new Date(
                                order.events[order.events.length - 1].end_date
                              ).getTime() -
                                new Date(
                                  order.events[0].start_date
                                ).getTime()) /
                              3600000
                            )
                              .toString()
                              .concat(" Hrs")
                          : "-" */}
                          {
                            trip.trip_elapsed_time
                          }
                      </div>
                    </div>
                  </div>
                );
              });
            })
            .flat()}
        </div>
      </div>,
      infoWindow
    );
  };

  toggleOrder = (code, truck) => {
    let newCode = code === this.state.openTrip ? 0 : code;
    this.setState({ openTrip: newCode }, () =>
      this.showTruckInfoActions(truck)
    );
  };

  getOrderClass = (code) => {
    // debugger
    return code === this.state.openTrip ? " selected" : "";
  };

  centerTruck = (coord) => {
    let latLng = {
      lat: coord[0],
      lng: coord[1],
    };

    this.mapController.setCenterMap(latLng);
    this.mapController.map.setZoom(16);
  };

  //FILTERS
  handleFilter = () => {
    //this.loadContracts();
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ buttonFilterName: "Restablecer" }, () => this.loadTrucks() );
    } else {
      this.setState({ buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handleEmailInput = (e) => {
    if (e) {
      switch (e.target.name) {
        case "inviteTags":
          let mails = e.target.value
            // .filter((it) => !it.processed)
            .map((i) => ({
              email: i.email,
              avatar: "hide",
              avatarType: "",
              userId: null,
              // processed: true,
            }));
          this.setState({
            resetPaginator: true, 
            emails: mails, 
            searchStr: e.target.value.map(e => e.email)
          }, this.loadTrucks);
          break;
        case "deleteTags":
          this.setState({
            resetPaginator: true, 
            emails: [].concat(e.target.value), 
            searchStr: e.target.value.map(e => e.email) 
          }, this.loadTrucks);
          break;
        default:
      }
    }
  };

  handlerClean = () => {
    this.setState({
      inFacility: [],
      originDestination: [],
      unitStatus: [],
      baseId: [],
      orderTag: [],
      tripTag: [],
      emails: [],
    }, () => this.loadTrucks() );
  }

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  filterContents = () => {
    if(this.props.ownCompanyType === 1){
      return [
        {
          title: "En instalación",
          items: this.props.facilitiesByTag,
          name: "inFacility",
          listType: FILTER_LIST_TYPES.checkListTextSearch,
        },
        {
          title: "Origen / Destino",
          items: this.props.facilitiesByTag,
          name: "originDestination",
          listType: FILTER_LIST_TYPES.checkListTextSearch,
        },
        {
          title: "Estatus",
          items: this.props.container_status,
          name: "unitStatus",
          listType: FILTER_LIST_TYPES.checkListTextSearch,
        },
        {
          title: "Base",
          items: (this.props.lt_filters.bases || []).map(b => ({id: b.id, description: b.title})),
          name: "baseId",
          listType: FILTER_LIST_TYPES.checkListTextSearch,
        }
      ]
    }
    return [
      {
        title: "En instalación",
        items: this.props.facilitiesByTag,
        name: "inFacility",
        listType: FILTER_LIST_TYPES.checkListTextSearch,
      },
      {
        title: "Origen / Destino",
        items: this.props.facilitiesByTag,
        name: "originDestination",
        listType: FILTER_LIST_TYPES.checkListTextSearch,
      },
      {
        title: "Estatus",
        items: this.props.container_status,
        name: "unitStatus",
        listType: FILTER_LIST_TYPES.checkListTextSearch,
      },
      {
        title: "Operación",
        items: this.props.orderTags,
        name: "orderTag",
        listType: FILTER_LIST_TYPES.chips,
      },
      {
        title: "Viaje",
        items: this.props.tripTags,
        name: "tripTag",
        listType: FILTER_LIST_TYPES.chips,
      },
    ];
  };

  handleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  switchTab = (e) => {
    if (e.target.attributes["name"])
      this.setState({ tabsItem: e.target.attributes.name.value });
  };

  handleEditZone = (polygon) => {
    this.handleGeofenceEditCancel();
     this.state.geofences.forEach((gf, i) => {
      if (gf.polygon === polygon) {
        let path = polygon.getPath();
        let p = new window.google.maps.Polygon({
          map: this.mapController.map,
          paths: path,
          fillColor: colors[gf.type],
          fillOpacity: 0.2,
          strokeColor: colors[gf.type],
          strokeWeight: 2,
          editable: true
        });
        let geofences = this.state.geofences;
        geofences[i] = {
          ...geofences[i],
          polygon: p,
        }
        this.setState({
          selectedZoneName: geofences[i].description,
          selectedZoneType: geofences[i].type,
          selectedZoneId: geofences[i].id,
          polygon: p,
          geofences,
          mode: EDIT_MODE,
          collapsed: false
        }, this.showGeoFences);
        polygon.setVisible(false);

        let position = new window.google.maps.LatLng(0, -180);
        p.getPath().forEach(
          x => (position = x.lat() > position.lat() ? x : position)
        );
        let latLng = {
          lat: position.lat(),
          lng: position.lng(),
        };
        this.mapController.setCenterMap(latLng);
        this.mapController.map.setZoom(14);
        const content = `<div id='infowindowitem' class='info-window-confirm-item' ></div>`;
        this.mapController.showInfoWindow(content, position, 0, -50, () =>
          this.editShapeInfowindowActions(gf.description)
        );
      }
    });
  };

  editShapeInfowindowActions = (name = "") => {
    let infoWindow = document.getElementById("infowindowitem");
    ReactDOM.render(
      this.geofenceNameTooltip(
        name,
        this.handleGeofenceEditCancel,
        this.handleEditGeofenceSave
      ),
      infoWindow
    );
  }

  handleEditGeofenceSave = () => {
    
    let polygonPath = this.state.polygon.getPath();
    let polygonPathArray = polygonPath.getArray();
    polygonPathArray.push(polygonPathArray[0]);
    let geo_data = polygonPathArray
      .reduce(
        (result, item, index) =>
          result.concat(index ? "," : "", item.lat(), " ", item.lng()),
        "POLYGON (("
      )
      .concat("))");
    
    this.props.editMapZone({
      company_id: this.props.companyId,
      zone_id: this.state.selectedZoneId,
      polygon: geo_data,
    }, {
      onSuccess: () => {
        let polygon = this.state.polygon;
        if (polygon) {
          this.mapController.removePolygon(polygon);
        }
        this.mapController.hideInfoWindow();
        this.loadZones();
        this.setState({
          mode: STAND_BY_MODE,
          polygon: null
        });
      }
    })
  }

  handleGeofenceEditCancel = () => {
    this.mapController.hideInfoWindow();
    if(this.state.polygon){
      this.state.polygon.setEditable(false);
      this.state.polygon.setVisible(false);
      this.state.polygon.setMap(null);
    }
    this.setState({
      mode: STAND_BY_MODE,
      polygon: null
    });
    this.showGeoFences();
  }

  geofenceNameTooltip = (
    name,
    onCancel,
    onSave,
  ) => {
    return (
      <div className="tooltip-content">
        <div className="title dark">
          Editando: {name}
        </div>
        {/* <TextInput
          name="description"
          value={name}
          label="Ingresa el nombre de tu geocerca"
          onChange={onChange}
        /> */}
        <div className="actions">
          <button className="primary outline small dark" onClick={onCancel}>
            Cancelar
          </button>
          <button
            className={"primary small ".concat(name ? "" : "disabled")}
            onClick={onSave}
          >
            Guardar
          </button>
        </div>
      </div>
    );
  };

  handleEditZoneDetails = (zone) => {

    this.setState({pol_timezones_detail:this.props.timeZones})
    let pol_timezone = this.props.timeZones.find(z => z.id===zone.time_zone).id
    this.setState({
      selectedZoneId: zone.id,
      selectedZoneName: zone.description,
      selectedTimeZone: pol_timezone,
      mode: EDIT_MODE,
    })
    this.props.loadZoneDetail({
      company_id: this.props.companyId,
      zone_id: zone.id,
    }, {
      onSuccess: (d) => {
        let filtered_d = d
          .filter(detail => detail.map_zone === this.state.selectedZoneId)
          .map(fD => ({
            id: fD.id,
            // selectedZoneClass: fD.vehicles,
            selectedZoneClass: fD.vehicles?  fD.vehicles.map(c => c.transport_class.toString()): null,
            selectedZoneHourFrom: fD.hour_from.toString().concat('.', fD.minute_from),
            selectedZoneHourTo: fD.hour_to.toString().concat('.', fD.minute_to),
            selectedZoneDays: fD.week_day,
          }));
        this.setState({
          selectedZoneDetails: Array.from(filtered_d),
          originalDetails: cloneDeep(filtered_d),
          maxDetailId: (Math.max(0, ...filtered_d.map(fD => fD.id).flat())) + 1
        })
        this.showCreateZoneDetailModal(true);
      }
    })
    
  }
  saveEditZoneDetails = () => {
    const origIds = this.state.originalDetails.map(d => d.id);
    const currentIds = this.state.selectedZoneDetails.map(d => d.id);
    let newDetails = this.state.selectedZoneDetails.filter(d => !origIds.includes(d.id));
    let removedDetails = this.state.originalDetails.filter(d => !currentIds.includes(d.id));
    let modifiedDetails = [];
    this.state.selectedZoneDetails.filter(d => origIds.includes(d.id)).forEach(d => {
      const origDetail = this.state.originalDetails.find(o => o.id === d.id);
      if(d.selectedZoneDays.length > 1){
        let newDays = d.selectedZoneDays.map(zD => zD.day).filter(day => !origDetail.selectedZoneDays.map(zD => zD.day).includes(day));
        newDetails.push({
          ...d,
          selectedZoneDays: newDays
        })
      }
      origDetail.selectedZoneDays.forEach(zD => {
        if(!d.selectedZoneDays.map(sZD => sZD.day).includes(zD.day)){
          removedDetails.push({id: zD.id})
        } else {
          if(
            origDetail.selectedZoneHourFrom !== d.selectedZoneHourFrom
            || origDetail.selectedZoneHourTo !== d.selectedZoneHourTo
            || origDetail.selectedZoneClass.toString() !== d.selectedZoneClass.toString()
          ) {
            modifiedDetails.push({...d, id: zD.id});
          }
        }
      })
    });
    newDetails.forEach((detail) => {
      detail.selectedZoneDays.forEach((day) => {
        let times = {};
        if (detail.selectedZoneHourFrom.length) {
          times.hour_from = detail.selectedZoneHourFrom.split(".")[0];
          times.minute_from = detail.selectedZoneHourFrom.split(".")[1];
        }
        if (detail.selectedZoneHourTo.length) {
          times.hour_to = detail.selectedZoneHourTo.split(".")[0];
          times.minute_to = detail.selectedZoneHourTo.split(".")[1];
        }
        this.props.createMapZoneDetail(
          this.props.companyId,
          this.state.selectedZoneId,
          day.day,
          times,
          detail.selectedZoneClass,
          {}
        );
      });
    });

    modifiedDetails.forEach((detail) => {
      let times = {};
      if (detail.selectedZoneHourFrom.length) {
        times.hour_from = detail.selectedZoneHourFrom.split(".")[0];
        times.minute_from = detail.selectedZoneHourFrom.split(".")[1];
      }
      if (detail.selectedZoneHourTo.length) {
        times.hour_to = detail.selectedZoneHourTo.split(".")[0];
        times.minute_to = detail.selectedZoneHourTo.split(".")[1];
      }
      this.props.editZoneDetail({
        company_id: this.props.companyId,
        zone_id: this.state.selectedZoneId,
        detail_id: detail.id,
        ...times,
        vehicles: detail.selectedZoneClass
      });
    });

    removedDetails.forEach((detail) => {
      detail.id.forEach(dId => {
        this.props.deleteZoneDetail({
          company_id: this.props.companyId,
          zone_id: this.state.selectedZoneId,
          detail_id: dId,
        })
      })
    });

    this.props.editMapZone({
      company_id: this.props.companyId,
      zone_id: this.state.selectedZoneId,
      description: this.state.selectedZoneName,
      time_zone: this.state.selectedTimeZone,
    }, {
      onSuccess: this.loadZones
    });

    this.showCreateZoneDetailModal(false);
  }

  getUrl = () => {

    const getQueryString = (queryParams) => 
      Object.keys(queryParams).reduce(
        (string, currentKey) => `${string}${currentKey}=${queryParams[currentKey]}&`,
        '?'
      ).slice(0, -1);

    let start = new Date(this.state.selectedStartDate);
    let end = new Date(this.state.selectedEndDate);

    const params = {
      in_facilities: this.state.inFacility,
      from_to: this.state.originDestination,
      from_facilities: [],
      to_facilities: [],
      status: this.state.unitStatus,
      base: this.state.baseId,
      trip_tags: this.state.tripTag,
      order_tags: this.state.orderTag,
      transport_classes: [],
      fleet_providers: [],
      startDate: (this.state.selectedStartDate !== null && this.state.selectedStartDate !== undefined) ? start.toISOString() : '',
      endDate: (this.state.selectedEndDate !== null && this.state.selectedEndDate !== undefined) ? end.toISOString() : '',
      plates: this.getPlates()
    };

    return `${SERVER}/api/v2/companies/${this.props.companyId}/gps/${getQueryString(params)}&action=download`;
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  openDocument = (url) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Limits.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  getColorForStatus = (connection) => {

    if (connection !== null) {
      const { status = 3 } = connection;
   
      if (status === 1) {
        return "#B1F06C";
      } else if (status === 2) {
        return "orange"
      }
  
    }
  
    return "#70889E";
  }

  getConnection = (truck, device) => {
    const { connection_status = [] } = truck;
  
    if (connection_status.length > 0) {
      for (var i = 0; i < connection_status.length; i++) {
        if (connection_status[i].device === device) {
          return connection_status[i];
        }
      }
    }
  
    return null;
  }

  getDetailRows = () => {
    switch (this.state.tabsItem) {
      case "3": 

        var items = [];

        for(var i = 0; i < this.props.trucksByFacility.length; i++) {
          items = items.concat(this.props.trucksByFacility[i].items);
        }

        let newItems = items.filter((truck) => {
          return (
            truck.location.length && 
          (!this.state.searchStr.length || 
            this.state.searchStr.some(strSearch => {
            return truck.trips.some(trip => {
                return normalizeStr(trip.code).includes(normalizeStr(strSearch)) ||
                  normalizeStr(trip.fleet_provider.orbi_id).includes(normalizeStr(strSearch)) ||
                  normalizeStr(trip.fleet_provider.alias).includes(normalizeStr(strSearch)) ||
                  trip.trip_orders.some((order) =>
                    normalizeStr(order.code).includes(normalizeStr(strSearch))
                  )
              }) ||
              Object.keys(truck.vehicles).some((veKey) =>
                truck.vehicles[veKey].some(
                  (ve) =>
                    normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                    normalizeStr(ve.plates).includes(normalizeStr(strSearch))
                )
              ) ||
              Object.keys(truck.equipments).some((veKey) =>
                truck.equipments[veKey].some(
                  (ve) =>
                    normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                    normalizeStr(ve.plates).includes(normalizeStr(strSearch))
                )
              ) ||
              Object.keys(truck.operators).some((veKey) =>
                truck.operators[veKey].some(
                  (ve) =>
                    normalizeStr(ve.first_name).includes(normalizeStr(strSearch)) ||
                    normalizeStr(ve.last_name).includes(normalizeStr(strSearch))
                )
              )
          })))
        })

        let filteredItems = newItems.filter(function(elem, pos) {
            return newItems.indexOf(elem) === pos;
        });

        return (
          <UnitsTableView
            items={filteredItems}
            resume={this.props.resumeGps}
            selectTruck={ (item) => {
              if (item.location.length) {
                this.showTruckInfo(item);
                this.centerTruck(item.location);
              }
            }}
            downloadDocument={ () => {
              const urlToDownload = this.getUrl();
              this.openDocument(urlToDownload);
            }}
          />
        );
      case "1":
        return this.props.trucksByFacility.map((facility) => {
          let items = facility.items.filter((truck) => {
            return (
              truck.location.length && 
            (!this.state.searchStr.length || 
              this.state.searchStr.some(strSearch => {
              return truck.trips.some(trip => {
                  return normalizeStr(trip.code).includes(normalizeStr(strSearch)) ||
                    normalizeStr(trip.fleet_provider.orbi_id).includes(normalizeStr(strSearch)) ||
                    normalizeStr(trip.fleet_provider.alias).includes(normalizeStr(strSearch)) ||
                    trip.trip_orders.some((order) =>
                      normalizeStr(order.code).includes(normalizeStr(strSearch))
                    )
                }) ||
                Object.keys(truck.vehicles).some((veKey) =>
                  truck.vehicles[veKey].some(
                    (ve) =>
                      normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                      normalizeStr(ve.plates).includes(normalizeStr(strSearch))
                  )
                ) ||
                Object.keys(truck.equipments).some((veKey) =>
                  truck.equipments[veKey].some(
                    (ve) =>
                      normalizeStr(ve.economic_number).includes(normalizeStr(strSearch)) ||
                      normalizeStr(ve.plates).includes(normalizeStr(strSearch))
                  )
                ) ||
                Object.keys(truck.operators).some((veKey) =>
                  truck.operators[veKey].some(
                    (ve) =>
                      normalizeStr(ve.first_name).includes(normalizeStr(strSearch)) ||
                      normalizeStr(ve.last_name).includes(normalizeStr(strSearch))
                  )
                )
            })))
          });
          return items.length ? (
            <div
              className={"facility-row".concat(
                facility.id === this.state.openFacility ? " selected" : ""
              )}
            >
              <div className="header" 
                onClick={() => {
                  // console.log(facility);
                  this.presentFaility(facility.id);
                  this.setState({
                    openFacility:
                      facility.id === this.state.openFacility
                        ? 0
                        : facility.id,
                  });
                }}
              >
                <div className="description">{facility.description}</div>
                <div className="icon">
                  <Unitary />
                </div>
                <div className="counter">{items.length}</div>
                <div className="toggle-icon">
                  <img alt="" src="/images/caret.svg" />
                </div>
              </div>
              <div className="facility-trucks">
                {items.map((truck) => {
                  return (
                    <div
                      className={"truck-row".concat(
                        truck.location.length ? " clickable" : ""
                      )}
                      onClick={() => {
                        if (truck.location.length) {
                          this.showTruckInfo(truck);
                          this.centerTruck(truck.location);
                        }
                      }}
                    >
                      <div className="company-logo">
                        <img
                          alt=""
                          src={
                            truck.contract && truck.contract.fleet_provider
                              ? `${files}${truck.contract.fleet_provider &&
                                  truck.contract.fleet_provider.logo}`
                              : truck.trips.length
                              ? `${files}${truck.trips[0].fleet_provider.logo}`
                              : ""
                          }
                          onError={(e)=>{e.target.onerror = null; e.target.src="/images/factory-bckgrnd.png"}}
                          data-tip={truck.contract && truck.contract.fleet_provider
                            ? truck.contract.fleet_provider.alias || 
                                truck.contract.fleet_provider.orbi_id
                              : truck.trips.length
                            ? truck.trips[0].fleet_provider.alias ||
                              truck.trips[0].fleet_provider.orbi_id
                            : ""}
                          data-for={"orbi-tooltip-logo"}
                        />
                      </div>
                      <div className="truck-icon">
                        {(Object.keys(truck.equipments) || []).length > 1 ? (
                          <TruckDoubleEquipment
                            fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                            stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                          />
                        ) : (
                          <TruckEquipment
                            fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                            stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                          />
                        )}
                      </div>
                      <div className="truck-description">
                        {Object.keys(truck.vehicles).length
                          ? Object.keys(truck.vehicles)
                              .map((key) => {
                                return truck.vehicles[key]
                                  .map((ve) => {
                                    return ve.economic_number;
                                  })
                                  .join(", ");
                              })
                              .join(", ")
                          : "-"}
                      </div>
                      <div className="gps-icon">
                        <WifiIcon
                          // viewBox="0 0 24 20"
                          height={"20px"}
                          width="24px"
                          fill={this.getColorForStatus( this.getConnection(truck, 1))}
                        />
                        <CellphoneIcon
                          height={"20px"}
                          width="24px"
                          fill="#FFF"
                          className="cellphone"
                        />
                        <EquipmentMinIcon
                          height={"20px"}
                          width="24px"
                          fill="#FFF"
                          className="equipment"
                        />
                        <WifiIcon
                          // viewBox="0 0 24 20"
                          height={"20px"}
                          width="24px"
                          fill={this.getColorForStatus( this.getConnection(truck, 2))}
                        />
                        <TruckMinIcon
                          height={"20px"}
                          width="24px"
                          fill="#FFF"
                        />
                      </div>

                      <ReactTooltip
                        id={"orbi-tooltip-logo"}
                        place="left"
                        type="light"
                        effect="solid"
                        html={true}
                        event="mouseover"
                        eventOff="mouseleave"
                      />

                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          );
        });
      case "2":
        return this.props.zone_types.map((zType) => {
          return (
            <div className="zone-type">
              {zType.description}:
              <div className="map-zones-rows">
                {this.state.geofences
                  .filter((z) => z.type === zType.id)
                  .map((zone) => {
                    return (
                      <div className="map-zone-row">
                        <div className={"color type-".concat(zone.type)}></div>
                        <div 
                          className="name" 
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            try {
                              let point = zone.polygon.getPath().getArray()[0];
                          
                              this.mapController.setCenterMap({ lat: point.lat(), lng: point.lng()});
                              this.mapController.map.setZoom(14);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          {zone.description}
                        </div>
                        <div className="delete">
                          <img
                            alt=""
                            src="/images/bin.png"
                            onClick={() => {
                              this.setState({
                                selectedZoneId: zone.id,
                                selectedZoneName: zone.description,
                                showDeleteZoneModal: true,
                              })
                            }}
                          />
                        </div>
                        <div className="edit">
                          <img
                            alt=""
                            src="/images/edit-transparent.svg"
                            data-tip="custom show"
                            data-event="click focus"
                            data-for={"edit-zone-tooltip-".concat(zone.id)}
                          />
                        </div>
                        <ReactTooltip
                          globalEventOff="click"
                          id={"edit-zone-tooltip-".concat(zone.id)}
                          clickable={true}
                          place="left"
                          type="light"
                          // className="map-quick-filter-tooltip"
                        >
                          <div style={{cursor: "pointer"}} onClick={() => this.handleEditZone(zone.polygon)}>
                            Editar forma
                          </div>
                          <div style={{cursor: "pointer"}} onClick={() => this.handleEditZoneDetails(zone)}>
                            Editar detalles
                          </div>
                        </ReactTooltip>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        });
      default:
        return <></>;
    }
  };

  setZoneType = (zone_id) => {
    this.setState({ selectedZoneType: zone_id });
  };

  setcurrentGeofence = (polygon) => {
    this.setState({ polygon: polygon });
  };

  handleZoneTypeSelection = () => {
    this.setState({ showCreateZoneModal: false });
    this.drawNewZone();
  };

  handleAddDetail = () => {
    let newDetail = this.state.selectedZoneDetails;
    newDetail.push({
      // id: newDetail.length ? newDetail[newDetail.length - 1].id + 1 : 1,
      id: this.state.maxDetailId,
      selectedZoneClass: [],
      selectedZoneHourFrom: "",
      selectedZoneHourTo: "",
      selectedZoneDays: [],
    });
    this.setState({ selectedZoneDetails: newDetail, maxDetailId: this.state.maxDetailId + 1 });
  };

  handleCreateZone = () => {
    // debugger
    let polygonPathArray = this.state.polygon.getPath();
    polygonPathArray = polygonPathArray.getArray();
    polygonPathArray.push(polygonPathArray[0]);
    let geo_data = polygonPathArray
      .reduce(
        (result, item, index) =>
          result.concat(index ? "," : "", item.lat(), " ", item.lng()),
        "POLYGON (("
      )
      .concat("))");
    this.props.createMapZone(
      this.props.companyId,
      this.state.selectedZoneName,
      this.state.selectedZoneType,
      geo_data,
      parseInt(this.state.selectedTimeZone),
      {
        onSuccess: (result) => {
          this.loadZones();
          this.state.selectedZoneDetails.forEach((detail) => {
            detail.selectedZoneDays.forEach((day) => {
              let times = {};
              if (detail.selectedZoneHourFrom.length) {
                times.hour_from = detail.selectedZoneHourFrom.split(".")[0];
                times.minute_from = detail.selectedZoneHourFrom.split(".")[1];
              }
              if (detail.selectedZoneHourTo.length) {
                times.hour_to = detail.selectedZoneHourTo.split(".")[0];
                times.minute_to = detail.selectedZoneHourTo.split(".")[1];
              }
              this.props.createMapZoneDetail(
                this.props.companyId,
                result.id,
                day.day,
                times,
                detail.selectedZoneClass,
                {}
              );
            });
          });
          this.showCreateZoneDetailModal(false);
        },
      }
    );
  };

  handleDeleteZone = () => {
    let filtered = this.state.geofences.filter((gf) => {
      if (gf.id !== this.state.selectedZoneId) {
        return gf;
      } else {
        gf.polygon.setMap(null);
        return null;
      }
    });
    this.setState({
      geofences: [...filtered],
      showDeleteZoneModal: false,
    });
    this.props.deleteMapZone(this.props.companyId, this.state.selectedZoneId, {
      onSuccess: this.loadZones,
    });
  };

  drawNewZone = () => {
    if (this.state.selectedZoneType) {
      this.setState({ mode: CREATE_MODE });
      this.mapController.drawPolygon(
        // this.state.currentGeofence.GF_TYPE.color,
        // color,
        colors[this.state.selectedZoneType],
        this.onCompletePolygon
      );
    }
  };

  onCompletePolygon = (polygon) => {
    this.setcurrentGeofence(polygon);
    polygon.setEditable(true);

    let polygonPathArray = polygon.getPath();
    polygonPathArray = polygonPathArray.getArray();

    let url_timezone = "https://maps.googleapis.com/maps/api/timezone/json?location="
    let timestamp = "&timestamp=".concat(+(Math.round((new Date().getTime())/1000)).toString())
    let key = "&key=AIzaSyCnd2kziemN0LAbXP8SjbwqXx-fsm9Xofo"
    
    let geo_data = polygonPathArray
      .map(item =>[url_timezone.concat(item.lat()).concat(',').concat(item.lng()).concat(timestamp).concat(key)]
    ) 

    this.setState({pol_timezones_detail:[]})

    geo_data.forEach(api_call =>{
      Axios.get(api_call).then(response => {
        if (response.data.timeZoneId){
          let timezone = this.props.timeZones.find(t => t.description.split('/  ')[1]===response.data.timeZoneId);
          if (timezone){
            if (!this.state.pol_timezones_detail.find(t => t.id ===timezone.id))
              this.setState({pol_timezones_detail:this.state.pol_timezones_detail.concat([timezone])})
          }
        }
      })
    });

    this.showCreateZoneDetailModal(true);
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNewExemptionInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDetailInput = (detail, e) => {
    // debugger;
    detail[e.target.name] = e.target.value;
    let details = Array.from(this.state.selectedZoneDetails);
    let i = details.findIndex((d) => d.id === detail.id);
    details[i] = detail
    this.setState({ selectedZoneDetails: details });
  };

  handleSelectDay = (detail, dayId) => {
    let details = Array.from(this.state.selectedZoneDetails);
    let i = details.findIndex((d) => d.id === detail.id);
    // debugger;
    if (detail.selectedZoneDays.map(zD => zD.day).includes(dayId)) {
      details[i].selectedZoneDays = details[i].selectedZoneDays.filter(
        (day) => day.day !== dayId
      );
    } else {
      details[i].selectedZoneDays.push({day: dayId});
    }

    this.setState({ selectedZoneDetails: details });
  };

  deleteDetail = (detailId) => {
    let details = this.state.selectedZoneDetails.filter(
      (d) => d.id !== detailId
    );
    this.setState({ selectedZoneDetails: details });
  };

  handleTimeZoneChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  getDatePickerTooltip = () => {
    return (
      <div
        className="tooltip-content trips"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="title">HISTÓRICO DE UBICACIONES</div>
        <div className="body">

          <div className={'date-container'}>

            <SelectInputStyled
              selectedItems={this.state.platesSelected}
              items={this.props.plates}
              onChange={this.handleChange}
              className={""}
              name={"platesSelected"}
              label={"Placas"}
              multiple={false}
            />

          </div>

          <div className={'date-container'}>

          <TextInput
            type="date"
            format="dd/MMM/yyyy HH:mm"
            minDate={null}
            maxDate={this.state.selectedEndDate || null}
            value={this.state.selectedStartDate}
            dateAdjust={false}
            label="Fecha de inicio"
            name="selectedStartDate"
            onChange={this.handleNewExemptionInput}
            suffix={{
              element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
            }}
            showTimeSelect={true}
            customHeader={false}
            timeValueFormat={"US_HOURLY_FORMAT"}
            selectsStart={true}
            startDate={this.state.selectedStartDate}
            endDate={this.state.selectedEndDate}
          />
            
          </div>

          <div className={'date-container'}>

          <TextInput
            type="date"
            format="dd/MMM/yyyy HH:mm"
            minDate={this.state.selectedStartDate || today}
            dateAdjust={false}
            timeValidationEnable={true}
            maxDate={null}
            value={this.state.selectedEndDate}
            label="Fecha de fin"
            name="selectedEndDate"
            onChange={this.handleNewExemptionInput}
            suffix={{
              element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
            }}
            showTimeSelect={true}
            timeValueFormat={"US_HOURLY_FORMAT"}
            selectsEnd={true}
            startDate={this.state.selectedStartDate}
            endDate={this.state.selectedEndDate}
            disabled={!this.state.selectedStartDate}
          />

          </div>

          <div className={'buttons-container'}>

            {
              (this.state.isResetingLoading === true) ? <div style={{width: '150px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}> Cargando... </div> :
              <Button 
                text={"Restablecer"}
                type={"primary outline dark"}
                onClick={() => this.setState({
                    selectedStartDate: null,
                    selectedEndDate: null,
                    platesSelected: ''
                  }, () => {
                    
                    this.mapController.hideInfoWindow();
                    this.loadTrucks();

                    if(this.routeLine){
                      this.routeLine.setMap(null);
                    }

                    this.state.routeLocations.forEach(e => {
                      e.setMap(null);
                    });

                    this.state.routeEvents.forEach(e => {
                      e.setMap(null);
                    });

                    this.setState({
                      isResetingLoading: true,
                      routeLocations: [],
                      routeEvents: []
                    });

                  })
                }
              />
            }

            {
              (this.state.isConsultingLoading === true) ? <div style={{width: '150px', alignItems: 'center', display: 'flex', justifyContent: 'center'}}> Cargando... </div> :
              <Button
                text={"Consultar"}
                type={"primary"}
                disabled={ (this.state.selectedStartDate === null || this.state.selectedEndDate === null || this.state.platesSelected === '') ? true : false }
                onClick={() => {
                  this.setState({
                    isConsultingLoading: true
                  });
                  setTimeout(function() {
                    this.setState({
                      platesSelected: ['0']
                    });
                  }.bind(this), 1000);
                  this.loadTrucks();
                  this.loadTruckEvents();
                  this.loadTruckLocations();
                }}
              />
            }

          </div>

        </div>
      </div>
    );
  }

  togglePlay = () => {
    if(this.state.isPlaying) {
      this.pauseRouteAnimation();
    } else {
      this.drawRouteAnimation();
    }

    this.setState({isPlaying: !this.state.isPlaying});
  }
  
  drawRouteAnimation = () => {
    this.routeTimeline.duration = Math.min(
      this.state.speed,//Math.round(this.state.routeDistance * 50000),
      this.state.speed
    );
      // debugger;
    if (this.routeTimeline.status !== "PAUSE") {
      // this.routeTimeline.position = 0;
      this.routeTimeline.setPosition(0);
      this.routeTimeline.direction = 1;
    }

    this.routeTimeline.play();
  }

  pauseRouteAnimation = () => {
    // debugger;
    this.routeTimeline.pause();
  };

  updateRouteAnimation = () => {
    let positions = this.state.routePoints;
    let length = 0;
    let drawLength =
      this.state.routeDistance *
      this.routeTimeline.getTime(0, this.routeTimeline.duration);
    let points = [];
    let addToMap = 0;
    // console.log(positions);
    // debugger;
    // return;
    for (let iRoute = 0; iRoute < positions.length - 1; iRoute++) {
      let latLng = new window.google.maps.LatLng(
        positions[iRoute].lat,
        positions[iRoute].lng
      );
      points.push(latLng);

      if (iRoute > 0) {
        let distance = Math.sqrt(
          Math.pow(positions[iRoute].lat - positions[iRoute - 1].lat, 2) +
            Math.pow(positions[iRoute].lng - positions[iRoute - 1].lng, 2)
        );
        if (length + distance > drawLength) {
          points.pop();

          let diff = drawLength - length;
          let angle = Math.atan2(
            positions[iRoute].lat - positions[iRoute - 1].lat,
            positions[iRoute].lng - positions[iRoute - 1].lng
          );
          let lat = Number(positions[iRoute - 1].lat) + diff * Math.sin(angle);
          let long = Number(positions[iRoute - 1].lng) + diff * Math.cos(angle);

          let latLng = new window.google.maps.LatLng(lat, long);
          points.push(latLng);
          /**/
          break;
        }
        length += distance;
      }

      addToMap = iRoute;
    }

    for (let iRoute = 0; iRoute < positions.length - 1; iRoute++) {
      let map = iRoute <= addToMap ? this.mapController.map : null;
      if (this.state.routeLocations[iRoute].getMap() !== map) {
        this.state.routeLocations[iRoute].setMap(map);
      }
    }

    //routeDetailPanel239796
    this.routeLine.setPath(points);
    console.log(this.routeTimeline.duration, this.routeTimeline.getPosition());
    if (this.routeTimeline.duration === this.routeTimeline.getPosition()) {
      // debugger;
      this.setState({isPlaying: !this.state.isPlaying});
    }
  };

  render() {
    return (
      <View className={"gps-view".concat(this.state.mode === EDIT_MODE ? " editing" : "")}>
        <ViewTitle>GPS</ViewTitle>
        <div className="panel">
          <div className="filters">
            <FilterBar
              filterAction={this.handleFilter}
              disabledInputText={this.state.showProgressLoader}
              searchStr={this.state.emails}
              searchAction={this.handleEmailInput}
              multiText={true}
              selectedItems={{
                inFacility: this.state.inFacility,
                originDestination: this.state.originDestination,
                unitStatus: this.state.unitStatus,
                baseId: this.state.baseId,
                orderTag: this.state.orderTag,
                tripTag: this.state.tripTag,
              }}
              onChange={this.filtersChange}
              searchButtonText={this.state.buttonFilterName}
              content={this.filterContents()}
            />
          </div>
          <div
            className={"data-display".concat(
              this.state.collapsed ? " collapsed" : ""
            )}
          >
            <div className="map-area">
              {this.mapController.getMapContainer({ className: "map-view" })}
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'black', opacity: 0.7, position: 'absolute', top: 0, left: 0, visibility: !this.state.isPaintingRouteLoading ? 'hidden' : 'initial'}}>
                <Loader />
              </div>
              {
                (this.state.routeLocations.length > 0) &&

                <div style={{position: 'absolute', bottom: 10, left: 80}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <button id="play-button" type="button" className="play-button" onClick={this.togglePlay}>
                        {this.state.isPlaying ? "Pausa" : "Play"}
                      </button>
                      <button type="button" className="play-button" style={this.state.speed === 50000 ? {fontWeight: "bold", border: "2px solid"} : {}} onClick={() => {this.setState({speed: 50000})}}>
                        {"1x"}
                      </button>
                    </div>
                    <button type="button" className="play-button" style={this.state.speed === 80000 ? {fontWeight: "bold", border: "2px solid", marginTop: 42} : {marginTop: 42}} onClick={() => {this.setState({speed: 80000})}}>
                      {"3x"}
                    </button>
                    <button type="button" className="play-button" style={this.state.speed === 110000 ? {fontWeight: "bold", border: "2px solid", marginTop: 42} : {marginTop: 42}} onClick={() => {this.setState({speed: 110000})}}>
                      {"5x"}
                    </button>
                    <button type="button" className="play-button" style={this.state.speed === 160000 ? {fontWeight: "bold", border: "2px solid", marginTop: 42} : {marginTop: 42}} onClick={() => {this.setState({speed: 160000})}}>
                      {"10x"}
                    </button>
                  </div>
                </div>
              }
              <div
                className="map-quick-filters date-filter"
                data-tip="custom show"
                data-event="click focus"
                data-for={"date-filter-tooltip"}
              >
                <img alt="" src="/images/tab-clock_icon.svg" />
              </div>
              <ReactTooltip
                ref={ ref => { this.platesTooltip = ref }}
                globalEventOff="click"
                id={"date-filter-tooltip"}
                clickable={true}
                place="right"
                className="map-quick-filter-tooltip"
                afterShow={() => {
                  if (this.zonesTooltip) { this.zonesTooltip.hideTooltip();}
                  if (this.geofenceTooltip) { this.geofenceTooltip.hideTooltip();}
                  if (this.tripsTooltip) { this.tripsTooltip.hideTooltip();}
                }}
              >
                { this.getDatePickerTooltip() }
              </ReactTooltip>
              <div
                className="map-quick-filters zones"
                data-tip="custom show"
                data-event="click focus"
                data-for={"zone-filter-tooltip"}
              >
                <img alt="" src="/images/tab_zone_icon.svg" />
              </div>
              <ReactTooltip
                ref={ ref => { this.zonesTooltip = ref }}
                globalEventOff="click"
                id={"zone-filter-tooltip"}
                clickable={true}
                place="right"
                className="map-quick-filter-tooltip"
                afterShow={() => {
                  if (this.platesTooltip) { this.platesTooltip.hideTooltip();}
                  if (this.geofenceTooltip) { this.geofenceTooltip.hideTooltip();}
                  if (this.tripsTooltip) { this.tripsTooltip.hideTooltip();}
                }}
              >
                <div
                  className="tooltip-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="title">ZONAS</div>
                  <div className="body">
                    {this.props.zone_types.map((type) => {
                      return (
                        <div key={type.id} className="tooltip-row">
                          <div
                            className="hide-button"
                            onClick={() => console.log(type)}
                          >
                            <SVGIcon
                              name={icons.passwordToggle}
                              fill={true ? "#AFC8FF" : "rgb(30,70,233)"}
                            />
                          </div>
                          <div
                            className="color"
                            style={{ backgroundColor: colors[type.id] }}
                          ></div>
                          <div className="description">{type.description}</div>
                          <div className="counter">
                            {
                              this.props.zones.filter(
                                (z) => z.zone_type === type.id
                              ).length
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ReactTooltip>
              <div
                className="map-quick-filters trips"
                data-tip="custom show"
                data-event="click focus"
                data-for={"trip-filter-tooltip"}
              >
                <img alt="" src="/images/tab_trip_icon.svg" />
              </div>
              <ReactTooltip
                ref={ ref => { this.tripsTooltip = ref }}
                globalEventOff="click"
                id={"trip-filter-tooltip"}
                clickable={true}
                place="right"
                className="map-quick-filter-tooltip trips"
                afterShow={() => {
                  if (this.zonesTooltip) { this.zonesTooltip.hideTooltip();}
                  if (this.geofenceTooltip) { this.geofenceTooltip.hideTooltip();}
                  if (this.platesTooltip) { this.platesTooltip.hideTooltip();}
                }}
              >
                <div
                  className="tooltip-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="title">VIAJES ACTIVOS</div>
                  <div className="body">
                    {this.props.container_status.map((status) => {
                      return (
                        <div key={status.id} className="tooltip-row">
                          <div
                            className="hide-button"
                            onClick={() => console.log(status)}
                          >
                            <SVGIcon
                              name={icons.passwordToggle}
                              fill={true ? "#AFC8FF" : "rgb(30,70,233)"}
                            />
                          </div>
                          <div className="description">
                            {status.description}
                          </div>
                          <div className="counter">
                            {
                              this.props.trucks
                                .map((truck) =>
                                  Object.keys(truck.equipments).map((eqId) =>
                                    truck.equipments[eqId].filter(
                                      (eq) => eq.status === status.id
                                    )
                                  )
                                )
                                .flat(2).length
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ReactTooltip>
              <div
                className="map-quick-filters facilities"
                data-tip="custom show"
                data-event="click focus"
                data-for={"facility-filter-tooltip"}
              >
                <img alt="" src="/images/tab_facility_icon.svg" />
              </div>
              <ReactTooltip
                ref={ ref => { this.geofenceTooltip = ref }}
                globalEventOff="click"
                id={"facility-filter-tooltip"}
                clickable={true}
                place="right"
                className="map-quick-filter-tooltip"
                afterShow={() => {
                  if (this.zonesTooltip) { this.zonesTooltip.hideTooltip();}
                  if (this.platesTooltip) { this.platesTooltip.hideTooltip();}
                  if (this.tripsTooltip) { this.tripsTooltip.hideTooltip();}
                }}
              >
                <div
                  className="tooltip-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="title">GEOCERCAS INSTALACIÓN</div>
                  <div className="body">
                    {
                      <div  className="tooltip-row">
                        <div
                          className="hide-button"
                          onClick={() => this.toggleShowFacilities()}
                        >
                          <SVGIcon
                            name={icons.passwordToggle}
                            fill={this.state.visibleFacilities ? "#AFC8FF" : "rgb(30,70,233)"}
                          />
                        </div>
                        <div style={{marginLeft: "3%"}} className="description">
                          {"Instalaciones"}
                        </div>
                        <div className="counter">
                          {
                            this.props.gps_facilities.length
                          }
                        </div>
                      </div>
                    }
                    {Object.keys(GEOFENCE_TYPES).map((type) => {
                      return (
                        <div key={type} className="tooltip-row">
                          <div
                            className="hide-button"
                            onClick={() => console.log(GEOFENCE_TYPES[type])}
                          >
                            <SVGIcon
                              name={icons.passwordToggle}
                              fill={true ? "#AFC8FF" : "rgb(30,70,233)"}
                            />
                          </div>
                          <div
                            className="color"
                            style={{
                              backgroundColor: GEOFENCE_TYPES[type].color,
                            }}
                          ></div>
                          <div className="description">
                            {GEOFENCE_TYPES[type].description}
                          </div>
                          <div className="counter">
                            {
                              this.props.gps_facilities
                                .map((facility) =>
                                  facility.geofences.filter(
                                    (gF) => gF.type.toString() === type
                                  )
                                )
                                .flat().length
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ReactTooltip>
              <React.Fragment>
                {
                  (this.isFilterModified() && this.trucksCalled) || this.trucksCalled ? 
                      this.state.showEmptyModal ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'white', opacity: 0.7, position: 'absolute', top: 0, left: 0, fontWeight: 'bold', fontSize: 22.0, backdropFilter: 'blur(3px)'}}>
                          {'No hay resultados, prueba con otra busqueda'}
                        </div> 
                    : <div/>
                    : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'white', opacity: 0.7, position: 'absolute', top: 0, left: 0, fontWeight: 'bold', fontSize: 22.0, backdropFilter: 'blur(3px)'}}>
                        {'Selecciona los filtros para visualizar las unidades de la(s) instalación(es)'}
                      </div>
                }
                { this.state.showProgressLoader &&
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'white', opacity: 0.7, position: 'absolute', top: 0, left: 0}}>
                    <div className="loader">
                      <div className="progress-loader"></div>
                      <p className="title">Cargando...</p>
                    </div>
                  </div>
                }
                
              </React.Fragment>
            </div>
            <div className="collapser" onClick={this.handleCollapse}></div>
            <div className="side-bar">
              {this.state.collapsed ? (
                <div className="tabs">
                  <div
                    onClick={() =>
                      this.setState({ collapsed: false, tabsItem: "3" })
                    }
                  >
                    <img alt="" src="/images/tab_trip_icon.svg" />
                  </div>
                  <div
                    onClick={() =>
                      this.setState({ collapsed: false, tabsItem: "1" })
                    }
                  >
                    <img alt="" src="/images/tab_factory_icon.svg" />
                  </div>
                  <div
                    onClick={() =>
                      this.setState({ collapsed: false, tabsItem: "2" })
                    }
                  >
                    <img
                      className="zone"
                      alt=""
                      src="/images/tab_zone_icon.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="detail-area">
                  <TabSelectorRibbon
                    items={tabs}
                    onChange={this.switchTab}
                    activeId={this.state.tabsItem}
                    className="tabs-ribbon"
                  />
                  <div
                    className={"detail-content ".concat(
                      this.state.tabsItem === "2" ? "geofences" : ""
                    )}
                  >
                    {this.getDetailRows()}
                  </div>
                  {this.state.tabsItem === "2" && (
                    <div className="actions-row">
                      <Button
                        text={"Crear Zona"}
                        type={"primary"}
                        className={"create-zone-button"}
                        onClick={() => {
                          this.showCreateZoneModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.showDeleteZoneModal}
          portalClassName="dialog create-zone-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Eliminar zona</div>
          <div
            className="close"
            onClick={() => this.setState({ showDeleteZoneModal: false })}
          ></div>
          <div className="message">
            ¿Estás seguro que deseas eliminar la zona <b>{this.state.selectedZoneName}</b>?
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.setState({ showDeleteZoneModal: false })}
            />
            <Button
              text="Confirmar"
              type="primary"
              onClick={this.handleDeleteZone}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showCreateZoneModal}
          portalClassName="dialog create-zone-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Crear zona</div>
          <div
            className="close"
            onClick={() => this.showCreateZoneModal(false)}
          ></div>
          <div className="message">
            {this.props.zone_types.map((type) => {
              return (
                <label
                  key={type.id}
                  onClick={() => this.setZoneType(type.id)}
                  className="radio-container"
                >
                  {type.description}
                  <input
                    readOnly
                    type="radio"
                    name="role"
                    value={type.id}
                    checked={this.state.selectedZoneType === type.id}
                  />
                  <span className="checkmark check"></span>
                </label>
              );
            })}
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showCreateZoneModal(false)}
            />
            <Button
              text="Asignar"
              type="primary"
              disabled={!this.state.selectedZoneType}
              onClick={this.handleZoneTypeSelection}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showCreateZoneDetailModal}
          portalClassName="dialog create-zone-detail-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">{this.state.mode === EDIT_MODE ? "Editar Zona" : "Crear zona"}</div>
          <div
            className="close"
            onClick={() => this.showCreateZoneDetailModal(false)}
          ></div>
          <div className="message">
            <div className="name-input">
              <TextInput
                label="Ingresa el nombre de tu geocerca"
                name="selectedZoneName"
                value={this.state.selectedZoneName}
                className="item"
                onChange={this.handleInput}
              />
              <SelectInput
                label='Zona horaria *'
                className="full"
                name="selectedTimeZone"
                items={this.state.pol_timezones_detail}
                value={this.state.selectedTimeZone}
                onChange={this.handleTimeZoneChange}
              />
            </div>
            <div className="zone-details">
              {this.state.selectedZoneDetails.map((d) => {
                return (
                  <div className="detail">
                    <div className="hour-input">
                      {"De "}
                      <SelectInput
                        label=""
                        className="full"
                        name="selectedZoneHourFrom"
                        items={this.hours}
                        value={d.selectedZoneHourFrom}
                        onChange={(e) => this.handleDetailInput(d, e)}
                      />
                      {" A "}
                      <SelectInput
                        label=""
                        className="full"
                        name="selectedZoneHourTo"
                        items={this.hours}
                        value={d.selectedZoneHourTo}
                        onChange={(e) => this.handleDetailInput(d, e)}
                      />
                    </div>
                    <div className="bottom-inputs">
                      <div className="class-input">
                        <SelectInputStyled
                          selectedItems={d.selectedZoneClass}
                          items={this.props.classesByTag || []}
                          onChange={(e) => this.handleDetailInput(d, e)}
                          className={""}
                          name={"selectedZoneClass"}
                          label={"Clases"}
                          multiple={true}
                        />
                      </div>
                      <div className="day-input">
                        {days.map((day) => {
                          return (
                            <div
                              key={day.id}
                              className={"day-picker ".concat(
                                d.selectedZoneDays.map(zD => zD.day).includes(day.id)
                                  ? "selected"
                                  : ""
                              )}
                              onClick={() => this.handleSelectDay(d, day.id)}
                            >
                              {day.description}
                            </div>
                          );
                        })}
                      </div>
                      <div className="delete">
                        <img
                          alt=""
                          src="/images/bin.png"
                          onClick={() => this.deleteDetail(d.id)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-button" onClick={this.handleAddDetail}>
              Agregar nuevo
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showCreateZoneDetailModal(false)}
            />
            <Button
              text="Guardar"
              type="primary"
              disabled={
                !this.state.selectedZoneName || !this.state.selectedTimeZone  ||
                this.state.selectedZoneDetails.some(
                  (d) =>
                    !d.selectedZoneDays.length ||
                    d.selectedZoneHourFrom === "0" ||
                    d.selectedZoneHourTo === "0"
                )
              }
              onClick={this.state.mode === EDIT_MODE ? this.saveEditZoneDetails : this.handleCreateZone}
            />
          </div>
        </Modal>
      </View>
    );
  }
}

const getZones = (rawZones) => {
  if (!rawZones.length) return [];

  let zones = rawZones.map((z) => {
    let coord = z.polygon.split("((");
    coord = coord[1].slice(0, -2);
    coord = coord.split(", ");
    coord = coord.map((c) => c.split(" ").map((s) => parseFloat(s)));
    return {
      ...z,
      polygon: coord,
    };
  });
  return zones;
};

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const orderTags = companyTags.filter((tag) => tag.type === 7);
  const tripTags = companyTags.filter((tag) => tag.type === 9);
  const zone_types = getEntityItems(
    state,
    "COMPANIES.CATALOGS.GPSZONETYPES"
  ).filter(
    (type) => !type.company_type || type.company_type === ownCompanyType
  );
  const devices = getEntityItems(state,"COMPANIES.CATALOGS.DEVICES");
  const container_status = getEntityItems(
    state,
    "COMPANIES.CATALOGS.CONTAINERSTATUS"
  );
  let zones = getZones(getEntityItems(state, "COMPANIES.GPS.ZONES"));
  let truckData = getEntityItems(state, "COMPANIES.GPS");
  let truckDataEvents = getEntityItems(state, "COMPANIES.GPS.EVENTS");
  let truckDataLocations = getEntityItems(state, "COMPANIES.GPS.LOCATIONS");
  let trucks = (truckData.data !== null && truckData.data !== undefined) ? truckData.data : [];
  let resumeGps = (truckData.resume !== null && truckData.resume !== undefined) ? truckData.resume : {};
  let vehicles = (truckData.vehicles !== null && truckData.vehicles !== undefined) ? truckData.vehicles : [];

  let plates = vehicles.map( (item, index) => {
    if (item !== null && item !== undefined && item.length > 1) {
      return { id: `${index}`, description: `${item[0]} - ${item[1]}`, plates: `${item[0]}`, economicNumber: `${item[1]}` }
    } else {
      return null
    }
  });

  // const facilities = state.facilityReducer.facilities.data;
  let gps_facilities = getEntityItems(state, "COMPANIES.GPS.FACILITIES");
  const facility_avatars = getEntityItems(
    state,
    "COMPANIES.CATALOGS.FACILITYAVATAR"
  );
  // const zone_details = getEntityItems(state, "COMPANIES.GPS.ZONES.DETAIL");
  
  const lt_filters = getEntityItems(state, "COMPANIES.GPS.TRANSPORTFILTERS");

  if(ownCompanyType === 1) {
    trucks = trucks.map(t => {
      t.contract = t.contract && {...t.contract, fleet_provider: t.contract.cargo_owner};
      t.trips = t.trips.map(trip => ({...trip, fleet_provider: trip.cargo_owner}))
      return t;
    })
    gps_facilities = ((lt_filters || {facilities: []}).facilities || []).map(f => ({...f, location: f.location[0], geofences: f.geofences.map(g => ({...g, poligon: g.polygon}))}));
  }

  let trucksByFacility = [
    { id: 9999999999, description: "Sin facility", items: [] },
  ];
  trucks.forEach((truck) => {
    if (truck.contract && truck.contract.facility) {
      let facilityIndex = trucksByFacility
        .map((fT) => fT.id)
        .indexOf(truck.contract.facility.id);
      if (facilityIndex !== -1) {
        trucksByFacility[facilityIndex].items.push(truck);
      } else {
        let newFacility = {
          id: truck.contract.facility.id,
          description: truck.contract.facility.name,
          items: [truck],
        };
        trucksByFacility.push(newFacility);
      }
    }
    truck.trips.forEach((trip) => {
      trip.trip_orders.forEach((order) => {
        let facilityIndex = 0;
        facilityIndex = trucksByFacility
          .map((fT) => fT.id)
          .indexOf(order.from_facility);
        if (facilityIndex !== -1) {
          if (
            !trucksByFacility[facilityIndex].items
              .map((i) => i.id)
              .includes(truck.id)
          )
            trucksByFacility[facilityIndex].items.push(truck);
        } else {
          let newFacility = {
            id: order.from_facility,
            description: (
              (gps_facilities || []).find((f) => f.id === order.from_facility) || {
                name: "-",
              }
            ).name,
            items: [truck],
          };
          trucksByFacility.push(newFacility);
        }

        // if(!(truck.contract && truck.contract.facility) || truck.contract.facility.id !== order.to_facility){
        facilityIndex = trucksByFacility
          .map((fT) => fT.id)
          .indexOf(order.to_facility);
        if (facilityIndex !== -1) {
          if (
            !trucksByFacility[facilityIndex].items
              .map((i) => i.id)
              .includes(truck.id)
          )
            trucksByFacility[facilityIndex].items.push(truck);
        } else {
          let newFacility = {
            id: order.to_facility,
            description: (
              (gps_facilities || []).find((f) => f.id === order.to_facility) || {
                name: "-",
              }
            ).name,
            items: [truck],
          };
          trucksByFacility.push(newFacility);
        }
        // }
      });
    });
  });
  trucksByFacility = trucksByFacility
    .filter((f) => f.items.length)
    .sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });

  // let facilitiesByTag = groupFacilities(state.facilityReducer.facilities.data);
  let facilitiesByTag = groupFacilities(gps_facilities.map(f => ({...f, tags: (f.tags || []).map(t => ({...t, id: t.id_tag}))})));

  let classesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (state.companyReducer.companyExpeditionClasses.data || []).forEach((c) => {
    if ((c.tags || []).length) {
      c.tags.forEach((t) => {
        let tagIndex = classesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          classesByTag[tagIndex].items.push({
            id: c.id,
            description: c.code,
          });
        } else {
          let newTag = {
            id: t.id,
            description: (
              (companyTags || []).find((tag) => tag.id === t.id) || {
                title: "-",
              }
            ).title,
            items: [
              {
                id: c.id,
                description: c.code,
              },
            ],
          };
          classesByTag.push(newTag);
        }
      });
    } else {
      classesByTag[0].items.push({
        id: c.id,
        description: c.code,
      });
    }
  });
  classesByTag = classesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  let taglessClasses = classesByTag.shift();
  classesByTag = classesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  classesByTag.push(taglessClasses);

  return {
    companyId,
    ownCompanyType,
    facilitiesByTag,
    // facilities,
    // zone_details,
    lt_filters,
    companyTags,
    orderTags,
    tripTags,
    zone_types,
    container_status,
    classesByTag,
    zones,
    trucks,
    trucksByFacility,
    gps_facilities,
    facility_avatars,
    timeZones: state.globalCatalog.timeZones.items,
    resumeGps,
    plates,
    truckDataEvents,
    truckDataLocations,
    devices
  };
};

const mapDispatchToProps = (dispatch) => {
  // dispatch(listFacilities());
  dispatch(listExpeditionClasses());
  dispatch(loadTimeZones());
  dispatch(readEntities("COMPANIES.CATALOGS.GPSZONETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.CONTAINERSTATUS"));
  dispatch(readEntities("COMPANIES.CATALOGS.FACILITYAVATAR"));
  dispatch(readEntities("COMPANIES.CATALOGS.DEVICES"));
  return {
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    loadTransportCompanyFilters: (company_id, opt) => 
      dispatch(readEntities("COMPANIES.GPS.TRANSPORTFILTERS", { company_id }, opt)),
    loadCompanyFacilities: (company_id, opt) =>
      dispatch(readEntities("COMPANIES.GPS.FACILITIES", { company_id }, opt)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadTruckLocations: (company_id, filters, opt) =>
      dispatch(readEntities("COMPANIES.GPS", { company_id, ...filters }, opt)),
    loadTruckLocationsEvents: (company_id, filters, opt) =>
      dispatch(readEntities("COMPANIES.GPS.EVENTS", { company_id, ...filters }, opt)),
    loadTruckLocationsRoute: (company_id, filters, opt) =>
      dispatch(readEntities("COMPANIES.GPS.LOCATIONS", { company_id, ...filters }, opt)),
    loadCompanyMapZones: (company_id, opt) =>
      dispatch(readEntities("COMPANIES.GPS.ZONES", { company_id }, opt)),
    createMapZone: (company_id, description, zone_type, polygon, time_zone, opt) =>
      dispatch(
        createEntity(
          "COMPANIES.GPS.ZONES",
          { company_id, description, company: company_id, zone_type, polygon, time_zone },
          opt
        )
      ),
    editMapZone: (params, opt = {}) => 
      dispatch(
        partialEntity(
          "COMPANIES.GPS.ZONES",
          params,
          {
            ...opt,
            strategy: "IGNORE"
          }
        )
      ),
    deleteMapZone: (company_id, zone_id, opt) =>
      dispatch(
        deleteEntity("COMPANIES.GPS.ZONES", { company_id, zone_id }, opt)
      ),
    loadZoneDetail: (params, opt) => 
      dispatch(readEntities("COMPANIES.GPS.ZONES.DETAIL", params, opt)),
    editZoneDetail: (params, opt) => 
      dispatch(partialEntity("COMPANIES.GPS.ZONES.DETAIL", params, opt)),
    deleteZoneDetail: (params, opt) => 
      dispatch(deleteEntity("COMPANIES.GPS.ZONES.DETAIL", params, opt)),
    createMapZoneDetail: (
      company_id,
      zone_id,
      week_day,
      times,
      map_zone_class,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.GPS.ZONES.DETAIL",
          {
            company_id,
            zone_id,
            map_zone: zone_id,
            week_day,
            vehicles: map_zone_class,
            ...times,
          },
          opt
        )
      ),
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(GPSView);

const buildGeofence = (gf, pol) => ({
  id: gf.id || 0,
  description: gf.description || "",
  type: gf.zone_type || 0,
  geo_data: "",
  polygon: pol,
  clickListener: gf.clickListener || undefined,
  time_zone: gf.time_zone || 0
});

const days = [
  {
    id: 1,
    description: "L",
  },
  {
    id: 2,
    description: "M",
  },
  {
    id: 3,
    description: "M",
  },
  {
    id: 4,
    description: "J",
  },
  {
    id: 5,
    description: "V",
  },
  {
    id: 6,
    description: "S",
  },
  {
    id: 7,
    description: "D",
  },
];

// const hours = [
//   {
//     id: 0,
//     description: "",
//     // disabled: true
//   },
//   {
//     id: "0.0",
//     description: "00:00 Hrs",
//   },
//   {
//     id: "0.30",
//     description: "00:30 Hrs",
//   },
//   {
//     id: "1.0",
//     description: "01:00 Hrs",
//   },
//   {
//     id: "1.30",
//     description: "01:30 Hrs",
//   },
//   {
//     id: "2.0",
//     description: "02:00 Hrs",
//   },
//   {
//     id: "2.30",
//     description: "02:30 Hrs",
//   },
//   {
//     id: "3.0",
//     description: "03:00 Hrs",
//   },
//   {
//     id: "3.30",
//     description: "03:30 Hrs",
//   },
//   {
//     id: "4.0",
//     description: "04:00 Hrs",
//   },
//   {
//     id: "4.30",
//     description: "04:30 Hrs",
//   },
//   {
//     id: "5.0",
//     description: "05:00 Hrs",
//   },
//   {
//     id: "5.30",
//     description: "05:30 Hrs",
//   },
//   {
//     id: "6.0",
//     description: "06:00 Hrs",
//   },
//   {
//     id: "6.30",
//     description: "06:30 Hrs",
//   },
//   {
//     id: "7.0",
//     description: "07:00 Hrs",
//   },
//   {
//     id: "7.30",
//     description: "07:30 Hrs",
//   },
//   {
//     id: "8.0",
//     description: "08:00 Hrs",
//   },
//   {
//     id: "8.30",
//     description: "08:30 Hrs",
//   },
//   {
//     id: "9.0",
//     description: "09:00 Hrs",
//   },
//   {
//     id: "9.30",
//     description: "09:30 Hrs",
//   },
//   {
//     id: "10.0",
//     description: "10:00 Hrs",
//   },
//   {
//     id: "10.30",
//     description: "10:30 Hrs",
//   },
//   {
//     id: "11.0",
//     description: "11:00 Hrs",
//   },
//   {
//     id: "11.30",
//     description: "11:30 Hrs",
//   },
//   {
//     id: "12.0",
//     description: "12:00 Hrs",
//   },
//   {
//     id: "12.30",
//     description: "12:30 Hrs",
//   },
//   {
//     id: "13.0",
//     description: "13:00 Hrs",
//   },
//   {
//     id: "13.30",
//     description: "13:30 Hrs",
//   },
//   {
//     id: "14.0",
//     description: "14:00 Hrs",
//   },
//   {
//     id: "14.30",
//     description: "14:30 Hrs",
//   },
//   {
//     id: "15.0",
//     description: "15:00 Hrs",
//   },
//   {
//     id: "15.30",
//     description: "15:30 Hrs",
//   },
//   {
//     id: "16.0",
//     description: "16:00 Hrs",
//   },
//   {
//     id: "16.30",
//     description: "16:30 Hrs",
//   },
//   {
//     id: "17.0",
//     description: "17:00 Hrs",
//   },
//   {
//     id: "17.30",
//     description: "17:30 Hrs",
//   },
//   {
//     id: "18.0",
//     description: "18:00 Hrs",
//   },
//   {
//     id: "18.30",
//     description: "18:30 Hrs",
//   },
//   {
//     id: "19.0",
//     description: "19:00 Hrs",
//   },
//   {
//     id: "19.30",
//     description: "19:30 Hrs",
//   },
//   {
//     id: "20.0",
//     description: "20:00 Hrs",
//   },
//   {
//     id: "20.30",
//     description: "20:30 Hrs",
//   },
//   {
//     id: "21.0",
//     description: "21:00 Hrs",
//   },
//   {
//     id: "21.30",
//     description: "21:30 Hrs",
//   },
//   {
//     id: "22.0",
//     description: "22:00 Hrs",
//   },
//   {
//     id: "22.30",
//     description: "22:30 Hrs",
//   },
//   {
//     id: "23.0",
//     description: "23:00 Hrs",
//   },
//   {
//     id: "23.30",
//     description: "23:30 Hrs",
//   },
// ];
