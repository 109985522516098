// @flow
import * as React from "react";
import type { RouterHistory } from "react-router-dom";

import { SummaryPanel } from "../shipment/styled/summary-panel";
import { FacilityRow } from "../shipment/styled/facility-row";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { Column } from "../shipment/styled/column";
import CalendarSelectorRibbon from "../shipment/styled/calendar-selector-ribbon";

import * as Types from "../shipment/types";

import Tag from "../../shared/controls/tag";
import * as SharedTypes from "../../shared/types";
import { getRibbonWeekDates } from "./utils";
import { CollapsibleFacilityHeader } from "./styled/collapsible-header";
import Modal from "react-modal";
import Button from "../../shared/controls/button";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import WeekPicker from "../shipment/week-picker";
import ValidatePerms from "../../shared/component/validate-perms";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";

type State = {
  currentDate: Date,
  selectedDate: Date,
  selectedDateId: string,
};

type OwnProps = {
  facilitiesByTag: Array<Types.FacilitiesByTag>,
  loadFacilities: (dateString: string) => void,
};

type Props = {
  ...OwnProps,
  history: RouterHistory,
};

const today = /* new Date ( */ new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const validationActions = [
  {
    description: "validar estos cambios",
    action: "validateChanges",
  },
  {
    description: "rechazar estos cambios",
    action: "cancelValidateChanges",
  },
  {
    description: "aplicar estos cambios",
    action: "applyChanges",
  },
  {
    description: "rechazar estos cambios",
    action: "cancelApplyChanges",
  },
];

class FacilitySummaryList extends React.Component<Props, State> {
  state = {
    currentDate: new Date(),
    selectedDate: new Date(),
    selectedDateId: new Date().toISOString().split("T")[0],

    selectedLimitDate: "",
    selectedFacilityName: "",
    selectedFacilityId: 0,
    selectedFacilityLimits: [],

    selectedDays: [],

    validationAction: 0,

    showEditLimitModal: false,
    showConfirmChangesModal: false,
    showReplicatePopup: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.facilityLimits !== prevProps.facilityLimits) {
      this.setState({ selectedFacilityLimits: this.getLimits() });
    }
  }

  getLimits = () => {
    // debugger;
    return this.props.facilityLimits.map((operation) => {
      return {
        operation_id: operation.operation.id,
        limit_id: operation.limit_id,
        limit: operation.limited_capacity,
        capacity: operation.calculated_capacity,
      };
    });
  };

  showReplicatePopup = (show) => {
    this.setState({ showReplicatePopup: show });
  };

  showReplicatePopupCancel = (show) => {
    if (!show) {
      this.setState({ selectedDays: [] });
    }
    this.setState({ showReplicatePopup: show });
  };

  showEditLimitModal = (show) => {
    if (!show) {
      this.setState({
        selectedLimitDate: "",
        selectedDays: [],
        selectedFacilityName: "",
        selectedFacilityId: 0,
        selectedFacilityLimits: [],
        showReplicatePopup: false,
      });
    }
    this.setState({ showEditLimitModal: show });
  };

  showConfirmChangesModal = (show) => {
    if (!show) {
      this.setState({
        selectedFacilityId: 0,
        selectedFacilityLimits: [],
      });
    }
    this.setState({ showConfirmChangesModal: show });
  };

  isToday = (dateString) => {
    if(!dateString)
      return false
    let offset = today.getTimezoneOffset();
    let adjustedDate = new Date(dateString);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + offset);
    return (
      adjustedDate.getDate() === today.getDate()
      && adjustedDate.getMonth() === today.getMonth()
      && adjustedDate.getFullYear() === today.getFullYear()
    );
  };

  isPastDay = (dateString) => {
    if(!dateString)
      return false
    let offset = today.getTimezoneOffset();
    let adjustedDate = new Date(dateString);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + offset);
    return (
      adjustedDate.getTime() <= today.getTime()
    );
  }; 

  adjustedPickerDate = (dateString) => {
    if(!dateString)
      return false
    let offset = today.getTimezoneOffset();
    let adjustedDate = new Date(dateString);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + offset + 60);
    // adjustedDate.setDate(adjustedDate.getDate() + 1);
    // debugger
    return (adjustedDate);
  }; 

  handleCapacityClick = (facility, date) => {
    if(!this.isToday(date) && !this.isPastDay(date)){
      this.props.loadFacilityLimits(facility.id, date);
      this.setState({
        selectedLimitDate: date,
        selectedFacilityName: facility.title,
        selectedFacilityId: facility.id,
      });
      this.showEditLimitModal(true);
    }
  };

  handleLimitInput = (e) => {
    // debugger
    this.setState({
      selectedFacilityLimits: this.state.selectedFacilityLimits.map((limit) => {
        if (limit.operation_id.toString() === e.target.name)
          limit.limit =
            parseInt(e.target.value) > parseInt(limit.capacity)
              ? limit.limit
              : e.target.value;
        return limit;
      }),
    });
  };

  editLimits = () => {

    this.state.selectedFacilityLimits
      .filter((l) => l.limit !== null && l.limit !== 0)
      .forEach((limit) => {
        if (!limit.limit_id)
          this.props.postFacilityLimit(
            this.state.selectedFacilityId,
            this.state.selectedLimitDate,
            limit.operation_id,
            limit.limit
          );
        else
          this.props.updateFacilityLimit(
            this.state.selectedFacilityId,
            limit.limit_id,
            limit.limit
          );
      });

      this.state.selectedDays.forEach(eachDay => {
      this.state.selectedFacilityLimits
        .filter((l) => l.limit !== null && l.limit !== 0)
        .forEach((limit) => {
          if (!limit.limit_id)
            this.props.postFacilityLimit(
              this.state.selectedFacilityId,
              eachDay.toISOString().split("T")[0],
              limit.operation_id,
              limit.limit
            );
          else
            this.props.updateFacilityLimit(
              this.state.selectedFacilityId,
              limit.limit_id,
              limit.limit
            );
        })      
      });
  

    this.showEditLimitModal(false);
  };

  validateChanges = () => {
    this.state.selectedFacilityLimits.forEach((limit) => {
      this.props.validateFacilityLimit(
        this.state.selectedFacilityId,
        limit,
        "true"
      );
    });
    this.showConfirmChangesModal(false);
  };

  cancelValidateChanges = () => {
    this.state.selectedFacilityLimits.forEach((limit) => {
      this.props.validateFacilityLimit(
        this.state.selectedFacilityId,
        limit,
        "false"
      );
    });
    this.showConfirmChangesModal(false);
  };

  applyChanges = () => {
    this.state.selectedFacilityLimits.forEach((limit) => {
      this.props.applyFacilityLimit(
        this.state.selectedFacilityId,
        limit,
        "true"
      );
    });
    this.showConfirmChangesModal(false);
  };

  cancelApplyChanges = () => {
    this.state.selectedFacilityLimits.forEach((limit) => {
      this.props.applyFacilityLimit(
        this.state.selectedFacilityId,
        limit,
        "false"
      );
    });
    this.showConfirmChangesModal(false);
  };

  handleDayClick = (day, { selected }) => {
    // debugger
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "calendar":
        this.setState(
          {
            selectedDate: value,
            selectedDateId: value.toISOString().split("T")[0],
            currentDate: value,
          },
          () => this.props.loadFacilities(value.toISOString().split("T")[0])
        );
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  getResponsePerm = (perm, type) => {
    return validateObjPerms(
      [
        {
          id: String(perm),
          perm: String(type),
        },
      ],
      true
    ) === true
      ? true
      : false;
  };

  render() {
    const { tagsByFacility } = this.props;
    const weekDays = getRibbonWeekDates(this.state.currentDate, "WEEKDAY");
    weekDays.pop();
    weekDays.shift();
    const today_picker = new Date()
    today_picker.setDate(today_picker.getDate() + 1)
    return (
      <SummaryPanel>
        <div className="facilities-date-selection">
          <CalendarSelectorRibbon
            bgColor="#212A3C"
            selectedUnderlineColor="#212A3C"
            className="facilities-calendar-ribbon"
            onChange={this.handleChangeDay}
            items={getRibbonWeekDates(this.state.currentDate, "WEEKDAY")}
            showSelected={false}
            activeId={this.state.selectedDateId}
          />
          <WeekPicker
            dateFormat="'Semana' w"
            name="calendar"
            date={this.state.selectedDate}
            onChange={this.handleChange}
          />
        </div>
        <div style={{ margin: "25px" }}>
          <div style={{ position: "relative" }}>
            <Flex
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              direction={flexOpts.direction.reverseRow}
            >
              <Flex
                style={{
                  width: "85%",
                  height: "100%",
                }}
                justifyContent={flexOpts.justifyContent.spaceEvenly}
              >
                <Column>Promedio</Column>
                {weekDays.map((column) => (
                  <Column>{column.description}</Column>
                ))}
                <Column>Validación</Column>
                <Column>Aplicación</Column>
              </Flex>
            </Flex>
            <div style={{ position: "relative", paddingTop: "60px" }}>
              {tagsByFacility.map((tag) => (
                <CollapsibleFacilityHeader
                  maxExpandedHeight="300px"
                  headStyle={{ overflow: "hidden" }}
                  contentStyle={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    maxHeight: "260px",
                  }}
                  collapsedHeight="40px"
                  // title={<Tag {...tag} />}
                  title={
                    <div
                      style={{
                        width: "14%",
                        color: "#fff",
                        fontWeight: "bold",
                        paddingRight: "15px",
                        boxSizing: "border-box"
                      }}
                    >
                      {tag.title}
                    </div>
                  }
                  caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                  initialStateCollapsed={false}
                  headerContent={
                    <div
                      style={{
                        width: "85%",
                        display: "flex",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7].map((column) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          {Math.floor(
                            tag.data
                              .map((f) => (f.data[column] || {}).value)
                              .reduce((total, value) => total + value)
                          )}
                        </div>
                      ))}
                      <div style={{ width: "100%", textAlign: "center" }}></div>
                      <div style={{ width: "100%", textAlign: "center" }}></div>
                    </div>
                  }
                >
                  {tag.data.map((facility) => (
                    <FacilityRow>
                      <div style={{ width: "15%", textAlign: "right" }}>
                        <Tag {...facility} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "85%",
                          justifyContent: "space-evenly",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7].map((column) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              width: "100%",
                              borderRadius: "8px",
                              border: (facility.data[column] || { limit: 0 }).limit
                                ? (facility.data[column].limit_status !== 3 && facility.data[column].limit_status !== 4)
                                  ? "1px solid #fff"
                                  : facility.data[column].limit_status === 4
                                    ? "1px solid #ff0000"
                                    : "1px solid #ffffff00"
                                : "1px solid #ffffff00",
                            }}
                            onClick={() => {
                              if (column)
                                this.handleCapacityClick(
                                  tag,
                                  weekDays[column - 1].id
                                );
                            }}
                          >
                            <div style={{ width: "100%", textAlign: "center" }}>
                              {Math.floor(
                                (facility.data[column] || { value: 0 }).value
                              )}
                            </div>
                            {(facility.data[column] || { var: 0 }).var ? (
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  color:
                                    facility.data[column].var < 0
                                      ? "#FF4259"
                                      : "#fff",
                                }}
                              >
                                {facility.data[column].var > 0
                                  ? "+".concat(facility.data[column].var)
                                  : facility.data[column].var}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                        <div className="facility-limit-options">
                          {// facility.status_validated ===  1
                          facility.status === 1 ? (
                            <>
                              <ValidatePerms
                                perms={[
                                  {
                                    id: "43",
                                    perm: "u",
                                  },
                                ]}
                              >
                                <Button
                                  text={"Rechazar"}
                                  type={"primary outline"}
                                  onClick={() => {
                                    this.setState({
                                      validationAction: 1,
                                      selectedFacilityId: tag.id,
                                      selectedFacilityLimits: facility.data
                                        .map((d) => d.limit)
                                        .filter((l) => l !== 0),
                                    });
                                    this.showConfirmChangesModal(true);
                                  }}
                                />
                                <Button
                                  text={"Validar"}
                                  type={"primary"}
                                  onClick={() => {
                                    // debugger;
                                    this.setState({
                                      validationAction: 0,
                                      selectedFacilityId: tag.id,
                                      selectedFacilityLimits: facility.data
                                        .map((d) => d.limit)
                                        .filter((l) => l !== 0),
                                    });
                                    this.showConfirmChangesModal(true);
                                  }}
                                />
                              </ValidatePerms>
                            </>
                          ) : facility.status === 4 ? (
                            <div className="indicator rejected">&#10006;</div>
                          ) : (
                            facility.data.some(d => d.limit_status === 4) 
                            ? <></>
                            : <div className="indicator approved">&#10004;</div>
                          )}
                        </div>
                        <div className="facility-limit-options">
                          {// facility.status_validated ===  1
                          facility.status === 1 ? (
                            <div className="indicator pending">&#65281;</div>
                          ) : // : facility.status_applied ===  1
                          facility.status === 2 ? (
                            <>
                              <ValidatePerms
                                perms={[
                                  {
                                    id: "41",
                                    perm: "u",
                                  },
                                ]}
                              >
                                <Button
                                  text={"Rechazar"}
                                  type={"primary outline"}
                                  onClick={() => {
                                    this.setState({
                                      validationAction: 3,
                                      selectedFacilityId: tag.id,
                                      selectedFacilityLimits: facility.data
                                        .map((d) => d.limit)
                                        .filter((l) => l !== 0),
                                    });
                                    this.showConfirmChangesModal(true);
                                  }}
                                />
                                <Button
                                  text={"Aplicar"}
                                  type={"primary"}
                                  onClick={() => {
                                    this.setState({
                                      validationAction: 2,
                                      selectedFacilityId: tag.id,
                                      selectedFacilityLimits: facility.data
                                        .map((d) => d.limit)
                                        .filter((l) => l !== 0),
                                    });
                                    this.showConfirmChangesModal(true);
                                  }}
                                />
                              </ValidatePerms>
                            </>
                          ) : facility.status === 4 ? (
                            <div className="indicator rejected">&#10006;</div>
                          ) : (
                            facility.data.some(d => d.limit_status === 4) 
                            ? <></>
                            : <div className="indicator approved">&#10004;</div>
                          )}
                        </div>
                      </div>
                    </FacilityRow>
                  ))}
                </CollapsibleFacilityHeader>
              ))}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.showConfirmChangesModal}
          portalClassName="dialog emails-modal confirm-limits-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">
            ¿Deseas {validationActions[this.state.validationAction].description}
            ?
          </div>
          <div
            className="close"
            onClick={() => this.showConfirmChangesModal(false)}
          ></div>
          <div className="message"></div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => {
                this.showConfirmChangesModal(false);
              }}
            />
            <Button
              text="Aceptar"
              type="primary"
              onClick={
                this[validationActions[this.state.validationAction].action]
              }
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showEditLimitModal}
          portalClassName="dialog emails-modal capacities-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">{this.state.selectedFacilityName}</div>
          <div
            className="close"
            onClick={() => this.showEditLimitModal(false)}
          ></div>
          <div className="message">
            <div className="subtitle">
              Límites de capacidad del {this.state.selectedLimitDate}
            </div>
            <div className="capacities-table">
              <div className="capacities-table-header">
                <div>Operación</div>
                <div>Clase</div>
                <div>Capacidad máxima</div>
                <div>Límite</div>
              </div>
              <div className="capacities-table-body">
                {Array.isArray(this.props.facilityLimits) &&
                  this.props.facilityLimits.map((operation) => {
                    let currentLimit = (
                      this.state.selectedFacilityLimits.find(
                        (l) => l.operation_id === operation.operation.id
                      ) || { limit: null }
                    ).limit;
                    return (
                      <div
                        key={"capacity-".concat(operation.operation.id)}
                        className="capacity-row"
                      >
                        <div>
                          <Tag {...operation.operation} />
                        </div>
                        <div>Todas</div>
                        <div>{operation.calculated_capacity}</div>
                        <div>
                          <input
                            name={operation.operation.id}
                            type="number"
                            max={operation.calculated_capacity}
                            min={0}
                            // onKeyDown="return false"
                            value={currentLimit || ''}
                            onChange={this.handleLimitInput}
                            disabled={!this.getResponsePerm("42", "u")}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              className={"day-picker-popup ".concat(
                this.state.showReplicatePopup ? "" : "hidden"
              )}
            >
              <div className="title">Replicar límites</div>
             
              <DayPicker
                selectedDays={this.state.selectedDays}
                onDayClick={this.handleDayClick}
                disabledDays={[
                  this.adjustedPickerDate(this.state.selectedLimitDate),
                  {
                    before: today_picker
                  },
                ]}
              />
              <div className="actions">
                <Button
                  text="Cancelar"
                  type="secondary"
                  onClick={() => this.showReplicatePopupCancel(false)}
                />
                <Button text="Aceptar" type="primary" onClick={() => this.showReplicatePopup(false)} />
              </div>
            </div>
          </div>
          <div className="actions">
            <ValidatePerms
              perms={[
                {
                  id: "42",
                  perm: "u",
                },
              ]}
            >
              <Button
                text="Replicar límites"
                type="primary outline"
                onClick={() => this.showReplicatePopup(true)}
              />
              <Button
                text="Guardar"
                type="secondary"
                disabled={this.isPastDay(this.state.selectedLimitDate)}
                onClick={() => this.editLimits()}
              />
            </ValidatePerms>
          </div>
        </Modal>
      </SummaryPanel>
    );
  }

  handleChangeDay = (e: SharedTypes.SimpleEvent) => {
    const { currentDate } = this.state;
    const { loadFacilities } = this.props;
    // debugger;
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      let selectedD = new Date(
        this.state.selectedDate.getFullYear(),
        this.state.selectedDate.getMonth(),
        this.state.selectedDate.getDate() +
          parseInt(e.target.attributes["name"].value)
      );
      this.setState(
        {
          currentDate: d,
          selectedDate: selectedD,
          selectedDateId: selectedD.toISOString().split("T")[0],
        },
        () => loadFacilities(selectedD.toISOString().split("T")[0])
      );
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      });
      loadFacilities(d.toISOString().split("T")[0]);
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        },
        () => loadFacilities(d.toISOString().split("T")[0])
      );
    }
  };
}

export default FacilitySummaryList;
