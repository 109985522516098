import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ColorSelector extends Component {
  handleInput = (e) => {
    e = {
      target: {
        name: (e.target.attributes['name'] || {}).value || undefined,
        value: (e.target.attributes['value'] || {}).value || undefined,
      }
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  hasValue = () => {
    return this.props.value && this.props.value.length
  }

  render() {
    return (
      <div
        className={"color-selector ".concat(this.props.className || '')}
        onMouseDown={this.handleInput}
      >
        <input
          className={"field " + (this.hasValue() ? 'has-input' : '')}
          onChange={() => { }}
          value=''
          style={{ backgroundColor: this.props.value }}
        />
        <label htmlFor={this.props.name} className='label'>
          {this.props.label}
        </label >
        {/* <SVGIcon name={icons.truck} className='icon' viewBox=' 0 -4 20 20'></SVGIcon> */}
        <img src='/images/color-picker.png' alt='selecciona un color' className='icon' />
        <div className='window' style={{backgroundColor: "#CCC", padding: "16px", height: "auto"}}>
          {this.props.colors.map(color =>
            <div
              name={this.props.name}
              value={color.description}
              key={color.id}
              style={{ backgroundColor: color.description }}
              className='color-item'
            />
          )}
        </div>
        {/* <SVGIcon name={icons.truck} className='icon' viewBox=' 0 -4 20 20'></SVGIcon> */}
      </div>
    );
  }
}

export default ColorSelector;

const defaultColors = [
  { id: 1, description: '#ffffff' },
  { id: 2, description: '#000000' },
  { id: 3, description: '#ff0000' },
  { id: 4, description: '#00ff00' },
  { id: 5, description: '#00aa00' },
  { id: 6, description: '#005500' },
  { id: 7, description: '#002200' },
  { id: 8, description: '#0000ff' },
  { id: 9, description: '#c0c0c0' },
  { id: 10, description: '#000080' },
  { id: 11, description: '#008080' },
  { id: 12, description: '#00ffff' },
  { id: 13, description: '#ffff00' },
  // { id: 14, description: '#008000' },
  { id: 15, description: '#808000' },
  { id: 16, description: '#800000' },
  { id: 17, description: '#ff9900' },
  { id: 18, description: '#ff00ff' },
  { id: 19, description: '#800080' }
];

ColorSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string
  }))
}
ColorSelector.defaultProps = {
  colors: defaultColors
}
