import React from "react";
import { getUrlsEnv } from "../backend-api";
import ExceedPlus from "../controls/exceed-plus";
import Tag from "../controls/tag";
import SubStringText from "./substring-text";
import ReactTooltip from "react-tooltip";
import { Fragment } from "react";

class FacilityRow extends React.Component {
  // toggleSelected = () => {
  //     this.props.selectFacility(this.props.facilityInfo.id, !this.props.isSelected);
  // }
  handleClick = () => {
    this.props.onClick({
      target: { name: this.props.id, value: !this.props.isSelected },
    });
  };
  render() {
    // let cont = 1;

    return (
      <div
        className={"row facility-row ".concat(
          this.props
            .className /* this.props.selectionModeOn ? ' selection-on' : '', this.props.isSelected ? ' selected' : '' */
        )}
        onClick={this.handleClick}
      >
        <div className={"overlay"} onClick={this.toggleSelected}>
          <div className="checkbox"></div>
        </div>
        <div className="logo default">
          <div className="avatar-container">
            <img
              alt=""
              src={getUrlsEnv().files.concat(
                this.props.facilityInfo.avatar &&
                  this.props.facilityInfo.avatar.image
              )}
            />
          </div>
        </div>
        <div className="credentials">
          <SubStringText
            label={this.props.facilityInfo.name}
            labelTag={"p"}
            labelSize={30}
            labelClass={"name"}
            tooltipClass={"tooltip-class-name-" + this.props.facilityInfo.name}
          />
          {/* <p className="name">
                        {this.props.facilityInfo.name}
                    </p> */}
          <p className="code">
            {this.props.facilityInfo.alias} {this.props.facilityInfo.code}
          </p>
        </div>
        <div className="handling">
          {/* <img alt="" src='/images/double-truck-white.svg'/>
                    {this.props.facilityInfo.handling.double} 
                    <img alt="" src='/images/single-truck-white.svg'/>
                    {this.props.facilityInfo.handling.single} */}
        </div>
        <div className="tags">
          <ExceedPlus maxVisibleItems={3} name={"tags ".concat(this.props.id)}>
            {this.props.facilityInfo.facility_tags_facility.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  title={tag.title}
                  color={tag.color}
                />
              );
            })}
          </ExceedPlus>
        </div>
        <div className="status">
        {this.props.facilityInfo.registration_complete
            ? <Fragment>
              <img alt="" src={"/images/".concat("check.svg")}/>
              <span>Completo</span>
            </Fragment> : <Fragment>
              <img alt="" src={"/images/".concat("red-exclamation.svg")} className="plus" data-tip data-for={"tooltip-facilities-".concat(this.props.facilityInfo.id)}/>
              <span className="plus" data-tip data-for={"tooltip-facilities-".concat(this.props.facilityInfo.id)}>
                Pendiente
              </span>
              <ReactTooltip
                className={"user-facilities-tooltip"}
                id={"tooltip-facilities-".concat(this.props.facilityInfo.id)}
                getContent={() =>
                  this.props.facilityInfo.incomplete_motives.map((c, index) => (
                    <Fragment key={index}>
                      {c}
                      <br />
                    </Fragment>
                  ))
                }
                delayHide={200}
                place={this.props.tooltipPosition}
                border={true}
                type={"light"}
              />
            </Fragment> 
        }
        </div>
        <div className="rfc">
          {
            this.props.rfc && this.props.rfc.length > 0 ? 
            <div
              style={{width: 20, height: 20, fontSize: 14, color: 'white', fontWeight: 'bold'}}
              data-tip data-for={"tooltip-rfc-".concat(this.props.facilityInfo.id)}
            >
              {
                this.props.rfc && this.props.rfc.length > 0 ? this.props.rfc.length : ''
              }
              <ReactTooltip
                id={"tooltip-rfc-".concat(this.props.facilityInfo.id)}
                getContent={() => {
                  if (this.props.rfc && this.props.rfc.length > 4) {
                    return(
                      <div 
                        style={{
                          height: '70px',
                          overflowY: 'scroll',
                        }}
                      >
                        {
                          this.props.rfc.map( (item) => <div>
                            {item}
                            <br />
                          </div>)
                        }
                      </div>
                    );
                  } else {
                    return(
                      <div>
                        {
                          this.props.rfc.map( (item) => <div>
                            {item}
                            <br />
                          </div>)
                        }
                      </div>
                    );
                  }
                }}
                delayHide={200}
                place={'right'}
                border={true}
                type={"light"}
                effect="solid"
                clickable={true}
              />
            </div>
            :
            <div/>
          }
        </div>
        <div className="geofences">
          <div className="fences">
            {this.props.facilityInfo.geofences.fences}
          </div>
          <div className="entry">{this.props.facilityInfo.geofences.entry}</div>
          <div className="validation">
            {this.props.facilityInfo.geofences.validation}
          </div>
        </div>
      </div>
    );
  }
}

export default FacilityRow;
