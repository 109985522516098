import React from 'react';
import UserChip from './usermail-chip';
import { validateEmail, validateWebDomain } from '../validate';
import ExceedPlus from "../../shared/controls/exceed-plus";

class InputTagger extends React.Component {
  static defaultProps = {
    tags: [],
    skipMailValidation: false,
    taggerClass: ""
  }

  state = {
    value: ''
  }

  separateInputs = (e) => {
    let emails = e.target.value.split(/[ ,]/gi)
      .filter((v, i, s) =>
        s.indexOf(v) === i && v.length
      )
      .filter(f =>
        (this.props.skipMailValidation || 
        (this.props.className ? validateWebDomain(f.trim()) : validateEmail(f.trim()))) && !this.isRepeated(f.trim()))
      .map(m => ({ email: m }))
      // debugger;
    this.props.onChange({ target: { value: this.props.tags.concat(emails), name: 'inviteTags' } });
    this.handleInput({ target: { value: '' } })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.separateInputs(e);
    }
  }

  handleInput = (e) => {
    if (this.props.isUserTyping) {
      this.props.isUserTyping();
    }
    if (this.props.onlyLowerCase) {
      this.setState({ value: e.target.value.toLowerCase() });
    } else {
      this.setState({ value: e.target.value });
    }
  }

  isRepeated = (email) => {
    return this.props.tags.find(t => t.email === email) ? true : false;
  }

  // setUsersValue = (values) => {
  //   this.tagList.current.innerHTML = "";
  //   for (var iValue = 0; iValue < values.length; iValue++) {
  //     this.onEmail(values[iValue].toString());
  //   }
  // }
  // getUsersValue = () => {
  //   var values = [];
  //   for (var iTag = 0; iTag < this.tagList.current.children.length; iTag++) {
  //     if (this.tagList.current.children[iTag].email) {
  //       values.push(this.tagList.current.children[iTag].email);
  //     }
  //   }
  //   return values;
  // }


  deleteTag = (email) => {
    this.props.onChange({ target: { name: 'deleteTags', value: this.props.tags.filter(f => f.email !== email) } });
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="text"
          className={this.props.className || 'email-input'}
          placeholder={this.props.placeholder || ''}
          value={this.state.value}
          onChange={this.handleInput}
          // onBlur={this.separateInputs}
          onKeyDown={this.handleKeyDown}
        />

        {
          !this.props.className && 
            <div className={`tags-container ${this.props.taggerClass}`}>
              <ExceedPlus maxVisibleItems={1} name={50} position="bottom" delayHide={5000}>
                {
                  this.props.list_mails ?
                  <div>
                    {this.props.tags.map((tag) => {
                    return (
                      <UserChip
                        mail={tag.email}
                        avatar={tag.avatar}
                        avatarType={tag.avatarType}
                        key={tag.email}
                        deleteTag={this.deleteTag}
                      />
                    )
                  })}
                  </div>
                  :
                  this.props.tags.map((tag) => {
                    return (
                      <UserChip
                        mail={tag.email}
                        avatar={tag.avatar}
                        avatarType={tag.avatarType}
                        key={tag.email}
                        deleteTag={this.deleteTag}
                      />
                    )
                  })
                }
              </ExceedPlus>
            </div>
        }
      </React.Fragment>
    )
  }
}

export default InputTagger;

InputTagger.defaultProps = {
  onChange: () => {console.log('Not yet implemented.')},
  tags: []
}