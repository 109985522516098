//@flow

const ACTIONS = {
  SET_CONFIG: {
    TYPE: "SET_CONFIG",
    ACTION: (view: string, config: any) => ({
      type: ACTIONS.SET_CONFIG.TYPE,
      config,
      view,
    }),
  },
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONFIG":
      return {
        ...state,
        [action.view]: action.config,
      };
    default:
      return state;
  }
};

export const setConfig = (view: string, config: any) => {
  return function (dispatch: any) {
    dispatch(ACTIONS.SET_CONFIG.ACTION(view, config));
  };
};
