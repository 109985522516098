//@flow
import * as React from "react";
import styled from "styled-components";
import type TableDConfig from "./table-d";

type Props = { tableConfig: TableDConfig, children: any, selected: boolean };
type State = { hover: boolean, focus: boolean };

class RowD extends React.Component<Props, State> {
  state = { hover: false, focus: false };

  toggleState = (element) => {
    this.setState({ [element]: !this.state[element] });
  };

  render() {
    const hovered = this.state.hover
      ? this.props.tableConfig.rowStyle?.hover
      : {};
    const selected = this.props.selected
      ? this.props.tableConfig.rowStyle?.selected
      : {};
    return (
      <Row
        onMouseEnter={() => this.toggleState("hover")}
        onMouseLeave={() => this.toggleState("hover")}
        style={{ ...this.props.tableConfig.rowStyle, ...hovered, ...selected }}
        className={this.props.className}
        onClick={this.props.onClick || (() => {})}
      >
        {this.props.children.map((e, index) => (
          <Cell
            key={index}
            style={{
              width: this.props.tableConfig.columns[index]?.width || "100%",
              ...(this.props.tableConfig.columns[index]?.cellStyle || {}),
            }}
          >
            {e}
          </Cell>
        ))}
      </Row>
    );
  }
}

export default RowD;

const Cell = styled.div`
  display: flex;
  margin: auto;
  height: auto;
  width: 100%;
`;

const Row: React.ComponentType<{}> = styled.div`
  display: flex;
  width: 100%;
`;
