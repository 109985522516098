import React, { Component } from "react";
import { connect } from "react-redux";
import OptionSelectorSwitch from '../../shared/controls/option-selector-switch';
import { Flex } from "../../shared/styled/flex";
import { View, ViewTitle } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from "../../shared/component/helper_files_modal";
import { readEntities, readEntity } from "../../redux/api";
import { FILTER_LIST_TYPES } from '../../shared/component/filter/filter-bar-content';
import styled from "styled-components";
import PlatesActivityResult from "./plates-activity-result";
import { cancelMultipleRequests } from '../../redux/api/actions';
import Loader from '../../shared/icons/tat/loader';
import { isPremiumSubscriptionCo } from "../../shared/utils";

class PlatesActivityView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadType: 'received',
      textSearch: '',
      buttonFilterName: 'Aplicar',
      platesSelected: null,

      //FILTERS

      units: [],
      units_selected: [],
      transports: [], 
      transports_selected: [], 
      units_cargo_owner: [],
      units_cargo_owner_selected: [],
  
      selectedCargoOwner: [],
      coordinateSelected: ['0'],
      dates: [],
      lastCoordinates: [
        { id: '0', description: 'Última hora' },
        { id: '1', description: 'Últimas 3 horas' },
        { id: '2', description: 'Últimas 12 horas' },
        { id: '3', description: 'Últimas 24 horas' },
        { id: '4', description: 'Últimas 2 dias' },
        { id: '5', description: 'Últimas 7 dias' }
      ],
      result: [],
      isFreeSubscription: false
    };

  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    document.addEventListener("keydown", this.cancelUpdate);

    this.props.loadFilters(this.props.companyId, {
      onSuccess: (response) => {

        let units_cargo_owner = [];
        let transports = [];
        let units = [];

        if (`${this.props.companyType}` === '1') {

          units = (response.assemblies || []).map( item => {
            return { id: item.plates, description: `${item.plates}-${item.economic_number}`, plates: item.plates}
          });

        } else {

          units_cargo_owner = (response || []).map( item => {

            let items = [];
  
            (item?.fleet_assemblies_from?.assemblies_list || []).forEach( assembly => {
  
              (assembly?.assembly_detail_vehicle_list || []).forEach( vehicle_detail => {
                if (vehicle_detail.fleet_vehicle_log && vehicle_detail.fleet_vehicle_log.fleet_vehicle) {
                  items.push({
                    id: vehicle_detail.fleet_vehicle_log.fleet_vehicle.plates,
                    description: `${vehicle_detail.fleet_vehicle_log.fleet_vehicle.plates}-${vehicle_detail.fleet_vehicle_log.fleet_vehicle.economic_number}`
                  });
                }
              });
  
            });
  
            (item?.fleet_assemblies_to?.assemblies_list || []).forEach( assembly => {
  
              (assembly?.assembly_detail_vehicle_list || []).forEach( vehicle_detail => {
                if (vehicle_detail.fleet_vehicle_log && vehicle_detail.fleet_vehicle_log.fleet_vehicle) {
                  items.push({
                    id: vehicle_detail.fleet_vehicle_log.fleet_vehicle.plates,
                    description: `${vehicle_detail.fleet_vehicle_log.fleet_vehicle.plates}-${vehicle_detail.fleet_vehicle_log.fleet_vehicle.economic_number}`
                  });
                }
              });
  
            });
  
            let description_units = '';
            let description_transports = '';
  
            if (item?.fleet_assemblies_from) {
              description_units = item.secondary_alias_to ? item.secondary_alias_to : item.secondary_orbi_id_to;
              description_transports = item.secondary_alias_to ? item.secondary_alias_to : item.secondary_orbi_id_to;
            } else if (item?.fleet_assemblies_to) {
              description_units = item.secondary_alias_from ? item.secondary_alias_from : item.secondary_orbi_id_from;
              description_transports = item.secondary_alias_from ? item.secondary_alias_from : item.secondary_orbi_id_from;
            }
  
            return { id: item.id, description: description_units, items, description_2: description_transports };
          }).filter( item => item.items.length > 0);
  
          transports = (units_cargo_owner || []).map( item => {
            return {id: item.id, description: item.description_2};
          });

        }

        this.setState({ 
          units, 
          transports, 
          units_cargo_owner 
        });

      }
    });

    this.props.setNavbarAction("activity-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  cancelUpdate = (e) => {
    if (e.key === "Escape" && this.props.isLoading) {
      this.props.cancelMultipleRequests();
    }
  }

  getHours = (start_date, end_date) => {

    if (start_date !== '' && end_date !== '') {
      return '';
    }

    let result = 1;

    (this.state.coordinateSelected || []).forEach( item => {
      if (`${item}` === '1') {
        result = 3;
      } else if (`${item}` === '2') {
        result = 12;
      } else if (`${item}` === '3') {
        result = 24;
      } else if (`${item}` === '4') {
        result = 48;
      } else if (`${item}` === '5') {
        result = 168;
      }
    });

    return result;
  }

  loadPlates = () => {

    let plates_to_search = [];

    let start_date = '';
    let end_date = '';

    (this.state.dates || []).forEach((item, index) => {
      if (index === 0) {
        start_date = item.toISOString();
      } else if (index === 1) {
        end_date = item.toISOString();
      }
    });

    plates_to_search = this.state.units_cargo_owner_selected.concat(this.state.units_selected);

    if (this.state.transports_selected.length > 0) {
      this.state.transports_selected.forEach( item => {

        let transports = this.state.units_cargo_owner.filter( co => `${co.id}` === `${item}`);

        if (transports.length > 0) {
          (transports[0].items || []).forEach( trans => {
            if (!plates_to_search.includes(`${trans.id}`)) {
              plates_to_search.push(`${trans.id}`);
            }
          });
        }
      });
    }

    this.props.cancelMultipleRequests();
    this.props.getPlates({
      company_id: this.props.companyId,
      plates: plates_to_search.join(),
      in_last_hours: this.getHours(start_date, end_date),
      loadType: this.state.loadType === 'received' ? 'gps_data_motum' : 'gps_data',
      start_date,
      end_date,
      isMultipleCancels: true
    }, {
      onSuccess: (response) => {
        this.setResultsData(response, plates_to_search);
      }
    });

  }

  // acciones de filtro

  getFilterContent= () => {
    if (`${this.props.companyType}` === '1') {
      return [
        {
          title: "Unidades",
          items: this.state.units,
          name: "units_selected",
          listType: FILTER_LIST_TYPES.checkListTextSearchSingle,
        },
        {
          title: "Últimas coordenadas",
          items: this.state.lastCoordinates,
          name: "coordinateSelected",
          listType: FILTER_LIST_TYPES.checkExclusive,
        },
        {
          title: "Fechas",
          name: "dates",
          listType: 7,
          time: true
        }
      ]
    } else {
      return [
        // {
        //   title: "Transportista",
        //   items: this.state.transports,
        //   name: "transports_selected",
        //   listType: 3,
        // },
        {
          title: "Unidades",
          items: this.state.units_cargo_owner,
          name: "units_cargo_owner_selected",
          listType: FILTER_LIST_TYPES.checkListTextSearchSingle,
        },
        {
          title: "Últimas coordenadas",
          items: this.state.lastCoordinates,
          name: "coordinateSelected",
          listType: FILTER_LIST_TYPES.checkExclusive,
        },
        {
          title: "Fechas",
          name: "dates",
          listType: 7,
          time: true
        }
      ]
    }
  }

  manageFilter = (e) => {

    if (e.target.name === 'coordinateSelected') {
      this.state.dates = [];
    }

    if (e.target.name === 'dates') {
      this.state.coordinateSelected = []
    }

    this.setState({[e.target.name]: e.target.value, buttonFilterName: "Aplicar" });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.loadPlates());
  }

  // CONTENT:

  setResultsData = (plates, plates_string) => {

    let platesToValidate = plates_string;
    let result = [];

    platesToValidate.forEach( plates_item => {

      let plates_filter = plates.filter(item => item.plates === plates_item);

      result.push({
        plates: plates_item,
        items: plates_filter
      });

    });
    
    this.setState({ result });

  }

  getContent = () => {

    return(
      <PlatesActivityResult
        isMotum={this.state.loadType === 'received'}
        result={this.state.result}
        platesSelected={this.state.platesSelected}
        didSelectPlates={(index) => {
          if (index === this.state.platesSelected) {
            this.setState({
              platesSelected: null
            });
          } else {
            this.setState({
              platesSelected: index
            });
          }
        }}
      />
    );

  }

  getEmptyContent = () => {
    return <NoResultsStyled>
      Ingresa una busqueda en los filtros para obtener resultados de busqueda
    </NoResultsStyled>
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return(
      <View>
        <ViewTitle>
          {
            this.props.isLoading &&
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#00000080",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1001,
                backdropFilter: "blur(3px)"
              }}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <Loader width="100px" height="100px"/>
                <div style={{color: "#fff", fontSize: "36px"}}>
                  Cargando
                </div>
              </div>
              <div style={{color: "#fff", fontSize: "30px"}}>
                Si desea cancelar la consulta, presione ESC
              </div>
            </div>
          }

          <span>
            Reporte de Actividad de Placas
          </span>

          <FilterBarCompact
            filtered={false}
            showTextSearch={false}
            searchAction={(e) => {}}
            searchStr={this.state.textSearch}
            searchPlaceholder={'Buscar placa'}
            content={this.getFilterContent()}
            filterAction={() => {
              if (this.state.buttonFilterName === "Aplicar") {

                this.setState({ buttonFilterName: "Restablecer", filterApplied: true });
                this.loadPlates();

              } else {
                this.setState({
                  units_cargo_owner_selected: [],
                  coordinateSelected: [],
                  units_selected: [],
                  transports_selected: [],
                  dates: [],
                  buttonFilterName: "Aplicar",
                  filterApplied: false
                });
              }
            }}
            selectedItems={{
              coordinateSelected: this.state.coordinateSelected,
              units_selected: this.state.units_selected,
              transports_selected: this.state.transports_selected,
              units_cargo_owner_selected: this.state.units_cargo_owner_selected,
              dates: this.state.dates,
              transports_selected: this.state.transports_selected
            }}
            buttonFilterName={this.state.buttonFilterName}
            onChange={this.manageFilter}
            onSearch={() => {}}
          />
        </ViewTitle>

        <Flex>
          <div
            style={{
              width: "300px",
              marginLeft: "50%",
              transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={this.state.loadType}
              name="loadType"
              onChange={this.handleChange}
              items={[
                { description: "Recibidas", value: "received" },
                { description: "Aceptadas", value: "approved" },
              ]}
            />
          </div>
        </Flex>

        {
          this.state.result.length === 0 ? this.getEmptyContent() : this.getContent()
        }

      </View>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => { 

  const filtersData = state?.api["COMPANIES.GPSACTIVITY.FILTERS"]?.items || {};
  let isFilterLoading = state?.api["COMPANIES.GPSACTIVITY.FILTERS"]?.status?.isFetching || false;

  const transportLinesData = (filtersData?.transport_lines || []).map( (value) => {
    const { secondary_companies, secondary_orbi_id, secondary_alias, secondary_code } = value;
    return {
      id: secondary_companies, description: `${secondary_orbi_id} - ${secondary_alias} - ${secondary_code}`
    }
  }).sort((a, b) => a.description.localeCompare(b.description));

  const assembliesData = (filtersData?.assemblies || []).map( (value) => {
    const { id, economic_number, plates } = value;
    return {
      id, description: `${economic_number} / ${plates}`
    }
  });

  let isLoading = state?.api["COMPANIES.REPORTS.PLATES"]?.status?.isFetching || false;
  let plates = state?.api["COMPANIES.REPORTS.PLATES"]?.items || [];

  return {
    isLoading: isFilterLoading || isLoading,
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
    transportLinesData,
    assembliesData,
    plates,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadFilters: (company_id, opts) => dispatch(readEntity("COMPANIES.REPORTS.FILTERS", {company_id, isMultipleCancels: true}, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    getPlates: (params, opts) => dispatch(readEntities("COMPANIES.REPORTS.PLATES", params, opts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatesActivityView);

/// STYLES COMPONENTS
 
const NoResultsStyled = styled.div`
  text-align: center;
  color: white;
  font-size: 35px;
  margin-top: 80px;
`;

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;