import React from "react";
import MenuFaqsDetails from "./details";
import {
  FaqsContainer,
  FaqsHeader,
  FaqsOptions,
  FaqsContent,
} from "./styled";

export default class MenuFaqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOpc: "faqs",
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.content && this.content.contains(e.target) || this.props.helpButton && this.props.helpButton.contains(e.target)) return;
    if (this.props.closeClick && this.props.show) {
      this.props.closeClick();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        currentOpc: "faqs",
      });
    }
  }

  changeOpc = (opc) => {
    this.setState({
      currentOpc: opc,
    });
  };

  render() {
    const { show, open, closeClick } = this.props;
    return show ? (
      <FaqsContainer 
        ref={node => (this.content = node)}
        className={`${open ? "open" : "closed"}`}
      >
        <FaqsHeader>
          {/* <span onClick={() => closeClick()}>x</span> */}
        </FaqsHeader>
        <FaqsOptions>
          <div>
            <span>Preguntas frecuentes</span>
          </div>
        </FaqsOptions>
        <FaqsContent>
          <MenuFaqsDetails currentOpc={this.state.currentOpc} faqs ={this.props.faqs} />
        </FaqsContent>
      </FaqsContainer>
    ) : (
      ""
    );
  }
}
