import React from "react";
const Factory = (props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 27 20"
      fill="currentColor"
      {...props}
    >
      <path
        d="M5.09179776,0.561649572 L5.09179776,7.09789735 L7.92060572,8.60695331 L7.92060572,5.61523232 C7.91508106,5.2302897 8.39686383,4.93198794 8.74271389,5.10636462 L15.8413246,8.63320387 L15.8413246,5.61509195 C15.8357999,5.23014932 16.3175827,4.93184756 16.6634328,5.10622424 L24.584095,9.0367886 C24.7719449,9.1311053 24.8968198,9.33728025 24.8935743,9.54565631 L24.8935743,20.7758402 C24.8935743,21.0697622 24.6238907,21.3373494 24.3277485,21.3373494 L0.565761592,21.3373494 C0.269613687,21.3373494 0,21.0697566 0,20.7758402 L0,0.561509195 C0,0.267587207 0.269619344,0 0.565761592,0 L4.52609274,0 C4.83437623,0.00438819436 5.08523492,0.308184321 5.09185433,0.561509195 L5.09179776,0.561649572 Z M22.1104418,13.8692771 L17.8429719,13.8692771 L17.8429719,16.714257 L22.1104418,16.714257 L22.1104418,13.8692771 Z M14.997992,13.8692771 L10.7305221,13.8692771 L10.7305221,16.714257 L14.997992,16.714257 L14.997992,13.8692771 Z M7.88554217,13.8692771 L3.61807229,13.8692771 L3.61807229,16.714257 L7.88554217,16.714257 L7.88554217,13.8692771 Z"
        id="path-1"
      ></path>
    </svg>
  );
};

export default Factory;
