import React from "react";
const ArrowDownUpSeparate = (props) => {
  return (
    <svg
      width="26px"
      height="20px"
      viewBox="0 0 40 30"
      fill="currentColor"
      {...props}
    >
      <path
        d="M11.0309445,3.25616196 L11.0309445,31.1615888 C11.0309445,32.2794704 8.96953778,32.2794704 8.96953778,31.1615888 L8.96953778,3.25616196 L1.84887661,10.5771139 C1.00778275,11.4446438 -0.608670793,10.3893479 0.238714462,9.52701639 L9.19418382,0.314518107 C9.61154709,-0.113167196 10.4081511,-0.0992587671 10.814922,0.323210319 L19.763883,9.52725227 C20.6049769,10.3913106 18.9926995,11.4448797 18.1537209,10.5773498 L11.0330597,3.25639785 L11.0309445,3.25616196 Z"
        id="Fill-1"
      ></path>
      <path
        d="M28.9701323,28.7376714 L28.9701323,0.838231354 C28.9701323,-0.279410451 31.0313378,-0.279410451 31.0313378,0.838231354 L31.0313378,28.7376714 L38.1513039,21.4182901 C38.9923156,20.5509463 40.6086114,21.6060114 39.7613088,22.4681623 L30.6396827,31.8179611 C30.1948218,32.1065002 29.548721,32.0456633 29.1906891,31.6806465 L20.2360939,22.4701246 C19.3950821,21.6062516 21.0072021,20.5529086 21.8460989,21.4202524 L28.966065,28.7396337 L28.9701323,28.7376714 Z"
        id="Fill-2"
      ></path>
    </svg>
  );
};

export default ArrowDownUpSeparate;
