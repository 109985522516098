import React from 'react';
import Modal from 'react-modal';
import TextInput from '../controls/text-input';
import Button from '../controls/button';
import { validateEmail } from '../validate';
import SVGIcon, { icons } from '../svg-icon';
import { resendUserValidation } from '../../redux/reducers/register.reducer';
import { connect } from 'react-redux';

Modal.setAppElement('#root');

class RecoverPasswordDialog extends React.Component {

  constructor() {
    super();

    this.state = {
      email: '',
      emailFocused: false,
      error: false
    }

  }

  onClose = () => {
    this.props.closeAction();
    this.setState({
      email: '',
      emailFocused: false,
      error: false
    });
  }

  getErrorEmail = () => {
    
    if (this.state.emailFocused && this.state.email === '') {
      return ['Oops... requerimos este campo'];
    }

    if (this.state.emailFocused && !validateEmail(this.state.email)) {
      return ['Oops... el correo no es valido'];
    }

    if (this.state.emailFocused && this.state.error) {
      return ['Oops... el correo no existe']
    }

    return [];

  }

  isValidFields = () => {
    return (this.state.email !== '' && validateEmail(this.state.email))
  }

  recoverPassword = () => {
    this.props.recoverPassword(this.state.email).then( () => {

      if (this.props.error === null) {
        this.onClose();
        if (this.props.closure) this.props.closure();
      } else {
        this.setState({error: true})
      }

    })
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog login-dialog"
        overlayClassName="overlay"
        className="content"
        >

        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>

        <div className='form'>

          <TextInput
            type='text'
            label='Ingresa tu correo electrónico'
            name='email'
            className="full"
            errors={this.getErrorEmail()}
            onChange={event => {
              const { target: { value: email = '' } } = event;
              this.setState({ email, emailFocused: false, error: false });
            }}
            value={this.state.email}
            handleBlur={  () => this.setState({emailFocused: true})}
            suffix={{
              element: (this.state.email !== '' && validateEmail(this.state.email)) ? <SVGIcon name={icons.checkMark} fill='#AFC8FF'/> : <div/>,
              className: ''
            }}
            handleEnter={ () => {
              if (this.isValidFields() && !this.props.isFetching) 
              this.recoverPassword();
            }}
          />

          <div className='button-container'>

            <div/>

            <div className='recover-password'> 
              <Button
                text={this.props.isFetching ? 'Cargando...' : 'Recuperar contraseña'}
                type={'primary'}
                onClick = { () => this.recoverPassword() }
                disabled = { !this.isValidFields() || this.props.isFetching }
              />
            </div>

          </div>

        </div>

      </Modal>
    );   
  }

}

const mapStateToProps = state => {
  return {
      isFetching: state.register.userValidation.isFetching,
      data: state.register.userValidation.data,
      error: state.register.userValidation.error
  };
}

const mapDispatchToProps = dispatch => {
  return ({
    recoverPassword: (email) => dispatch(resendUserValidation(email)),
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordDialog);