import moment from 'moment';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getUrlsEnv } from '../../shared/backend-api';
import { TruckDoubleEquipment, TruckEquipment } from '../../shared/icons/assemble';
import CellphoneIcon from '../../shared/icons/gps/cellphone-icon';
import EquipmentMinIcon from '../../shared/icons/gps/equipment-min-icon';
import TruckMinIcon from '../../shared/icons/gps/truck-min-icon';
import WifiIcon from '../../shared/icons/gps/wifi-icon';

const { files } = getUrlsEnv();

function getTooltip(connection) {

  if (connection !== null) {
    const { inserted = '', sent = '' } = connection;

    const insertedDateUtc = inserted !== '' ? moment(inserted).utc().format('YYYY/MM/DD HH:mm') : '';
    const insertedDate = inserted !== '' ? moment(inserted).format('YYYY/MM/DD HH:mm') : '';

    const sentDateUtc = sent !== '' ? moment(sent).utc().format('YYYY/MM/DD HH:mm') : '';
    const sentDate = sent !== '' ? moment(sent).format('YYYY/MM/DD HH:mm') : '';

    const divHeader = '<div style=color:rgb(167,180,186);font-size:15;font-weight:500;margin-bottom:8;text-align:center;>';
    const divTime = '<div style=color:rgb(52,66,90);font-size:14;font-weight:500;margin-bottom:8;text-align:center;>';

    return `${divHeader}Hora de última coordenada</div> ${divTime}UTC ${sentDateUtc} hrs</div> ${divTime}Local ${sentDate} hrs</div><br/>  ${divHeader}Hora de última insersión</div>${divTime}UTC ${insertedDateUtc} hrs</div>${divTime}Local ${insertedDate} hrs</div>`
  } else {
    return `No contiene ningun dato`;
  }
}

function getConnection(truck, device) {
  const { connection_status = [] } = truck;

  if (connection_status.length > 0) {
    for (var i = 0; i < connection_status.length; i++) {
      if (connection_status[i].device === device) {
        return connection_status[i];
      }
    }
  }

  return null;
}

function getColorForStatus(connection) {

  if (connection !== null) {
    const { status = 3 } = connection;

    if (status === 1) {
      return "#B1F06C";
    } else if (status === 2) {
      return "orange"
    }

  }

  return "#70889E";
}

export default function GpsAssembleRow(props) {

  const { truck, selectTruck = () => {} } = props;
  const { contract: { facility: { alias = '', name = '' } = { alias: '', name: '' } } } = truck;

  var motumConnection = getConnection(truck, 2);
  var deliveryConnection = getConnection(truck, 1);

  return (
    <div 
      className={'gps-assemble-row'}
      onClick={ () => selectTruck(truck) }
    >

      <div className={'gps-assemble-row-container'}>

        <div className={'lt'}>

          <img
            style={{
              height: 30,
              width: 30,
              borderRadius: 15,
            }}
            alt=""
            src={
              truck.contract && truck.contract.fleet_provider
                ? `${files}${truck.contract.fleet_provider &&
                truck.contract.fleet_provider.logo}`
                : truck.trips.length
                  ? `${files}${truck.trips[0].fleet_provider.logo}`
                  : ""
            }
            onError={(e)=>{e.target.onerror = null; e.target.src="/images/factory-bckgrnd.png"}}
            data-tip={truck.contract && truck.contract.fleet_provider
              ? truck.contract.fleet_provider.alias || 
                  truck.contract.fleet_provider.orbi_id
                : truck.trips.length
              ? truck.trips[0].fleet_provider.alias ||
                truck.trips[0].fleet_provider.orbi_id
              : ""}
            data-for={"orbi-tooltip-time"}
          />

        </div>

        <div className={'unit'}>

          {
            (Object.keys(truck.equipments) || []).length > 1 ? (
              <TruckDoubleEquipment
                fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                width={'40px'}
                height={'20px'}
              />
            ) : (
                <TruckEquipment
                  fill={truck.trips.length ? "#AFC8FF" : "#fff"}
                  stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
                  width={'30px'}
                  height={'20px'}
                />
              )
          }
          <div>
            {Object.keys(truck.vehicles).length
              ? Object.keys(truck.vehicles)
                .map((key) => {
                  return truck.vehicles[key]
                    .map((ve) => {
                      return ve.economic_number;
                    })
                    .join(", ");
                })
                .join(", ")
              : "-"}
          </div>

        </div>

        <div className={'contract'}>

          {name + ' - ' + alias}

        </div>

        <div className={'trips'}>
          {(truck.trips != null && truck.trips.length > 0) ? truck.trips.length : '0'}
        </div>

        <ReactTooltip
          id={"orbi-tooltip-time"}
          place="left"
          type="light"
          effect="solid"
          html={true}
          event="mouseover"
          eventOff="mouseleave"
        />

        <div className={'app'}>
          <div
            style={{
              marginBottom: 10,
              marginLeft: -8
            }}
            data-tip={getTooltip(deliveryConnection)}
            data-for={"orbi-tooltip-time"}
          >
            <WifiIcon
              height={"20px"}
              width={"20px"}
              fill={getColorForStatus(deliveryConnection)}
            />

          </div>

          <div>
            <CellphoneIcon
              height={"20px"}
              width={"20px"}
              fill="#FFF"
              className="cellphone"
            />
          </div>
          <div
            style={{
              marginBottom: 10,
              marginLeft: -5
            }}
          >
            <EquipmentMinIcon
              height={"20px"}
              width={"20px"}
              fill="#FFF"
              className="equipment"
            />
          </div>
        </div>

        <div className={'gps'}>
          <div
            style={{
              marginBottom: 10,
              marginLeft: -8
            }}
            data-tip={getTooltip(motumConnection)}
            data-for={"orbi-tooltip-time"}
          >
            <WifiIcon
              height={"20px"}
              width={"20px"}
              fill={getColorForStatus(motumConnection)}
            />
          </div>
          <div
            style={{
              marginBottom: 10,
              marginRight: 0
            }}
          >
            <TruckMinIcon
              height={"20px"}
              width={"20px"}
              fill="#FFF"
            />
          </div>
        </div>

      </div>

    </div>
  );
}