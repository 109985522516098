// @flow
import * as React from "react";
import type { ComponentType } from "react";
import styled from "styled-components";
import Select from "react-select";

type Props = {| options: Array<{ value: string, label: string }> |};

const ModalSelectInput = (props: Props) => {
  return (
    <ModalSelectInputStyled>
      {" "}
      <Select styles={CustomStyle} {...props} isSearchable={true} />
    </ModalSelectInputStyled>
  );
};

export default ModalSelectInput;

const ModalSelectInputStyled: ComponentType<{}> = styled.div`
  width: 100%;
`;

const CustomStyle = {
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    color: "#20293D",
    fontSize: "12px",
    letterSpacing: "0",
    lineHeight: "18px",
    border: "none",
    borderBottom: "2px solid hsl(0,0%,80%)",
    borderRadius: "0",
    boxShadow: "",
    div: {
      div: {
        svg: {
          fill: "#79a3ff",
          transition: "0.2s ease-in-out",
          transform: state.menuIsOpen ? "rotate(0deg)" : "rotate(180deg)",
        },
      },
    },
  }),
  menuList: (provided, state) => ({
    height: "160px",
    overflow: "auto",
  }),
};
