import React from 'react';
import PropTypes from 'prop-types';
import { getContrastYIQ } from '../utils';

class Tag extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    color: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.number,
    isFull: PropTypes.bool,
    isActive: PropTypes.bool,
    parentBackground: PropTypes.oneOf(['light', 'dark']),
    className: PropTypes.string,
    onClick: PropTypes.func
  }
  onClick = (e) => {
    let tag = {
      id: this.props.id,
      title: this.props.title,
      color: this.props.color,
      type: this.props.type,
      isFull: this.props.isFull,
      isActive: this.props.isActive
    };
    this.props.onClick({ target: { id: this.props.id, name: this.props.name || this.props.id, value: tag.isFull, tag: tag }, e });
  }
  getOpacity = () => {
    if (this.props.isFull) {
      return 'FF';
    } else {
      return '00';
    }
  }
  getTagTextClass = (backgroundColor) => {
    if (this.props.isFull) {
      let color = getContrastYIQ(backgroundColor);
      if (color === 'black') {
        return 'light';
      } else {
        return 'dark';
      }
    } else {
      return this.props.parentBackground;
    }
  }
  render() {
    return (
      <div className={"tag ".concat(this.props.className)} onClick={this.onClick}>
        <div className="tag-selector">
        </div>
        <div
          className={"tag-body ".concat(this.getTagTextClass(this.props.color))}
          style={{ "backgroundColor": '#'.concat(this.props.color, this.getOpacity()), "borderColor": "#".concat(this.props.color)/* , "color":getContrastYIQ(this.props.color) */ }}
        >
          {this.props.title}
        </div>
      </div>
    )
  }
}

export default Tag;

Tag.defaultProps = {
  id: 0,
  color: '3D77F7',
  title: '-',
  type: 0,
  isFull: true,
  isActive: true,
  parentBackground: 'dark', // dark/light
  className: '',
  onClick: (() => { console.log('Not yet implemented') })
}