import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';

Modal.setAppElement('#root');

class ResetPasswordMessageDialog extends React.Component {

  onClose = () => {
    this.props.closeAction();
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog password-dialog"
        overlayClassName="overlay"
        className="content"
        >

        <div className="close" onClick={this.onClose}></div>

        <div className='message'>{this.props.message}</div>

        <div className='bottom-container'>

          <div/>

          <Button
            text={'Iniciar sesión'}
            type={'primary'}
            onClick = { () => {
              if (this.props.acceptAction) this.props.acceptAction();
            }}
          />

        </div>

      </Modal>
    );   
  }

}

export default ResetPasswordMessageDialog;