import * as React from "react";
import { FILES_SERVER } from "../../redux/api";
import styled from "styled-components";
import * as Icons from "../../shared/icons/assemble";

const ContractDetailHeaderTitle = ({ contract }) => {
  const company =
    contract?.ownCompany === 1
      ? contract?.network?.from_companies
      : contract?.network?.to_companies;
  const facility = contract?.facilities;
  const classes = contract?.transport_classes?.map((t) => t.transport_class);
  return company && facility && classes ? (
    <Container>
      <Content>
        <Logo
          src={
            (company.logo && FILES_SERVER.concat(company.logo)) ||
            "/".concat(company.type.avatar_image)
          }
          alt=""
        />
        <Span>{company.orbi_id}</Span>
        <AvatarContainer>
          <Avatar src={FILES_SERVER.concat(facility.avatar.image)} />
        </AvatarContainer>
        <Span>{facility.name}</Span>
        <Span>{classes.map((c) => c.code).join(",")}</Span>
        <Span>
          {classes.map((c) =>
            getAssembleIcon(
              c.equipment_quantity,
              c.operator_quantity,
              c.vehicle_type
            )
          )}
        </Span>
        <Span>{`${contract.actives_fleets.count}/${contract.total_fleets}`}</Span>
      </Content>
    </Container>
  ) : (
    <React.Fragment />
  );
};

export default ContractDetailHeaderTitle;

const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 12px;
`;
const AvatarContainer = styled.div`
  border-radius: 16px;
  background-color: white;
  width: 25px;
  height: 25px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 18px;
  height: 18px;
  background-color: white;
  margin-top: 3px;
`;

const Container = styled.div`
  //   position: absolute;
  margin: 0 5px 0 5px;
  //   width: 100%;
  //   width: 100%;
  left: -25%;
  position: relative;
  top: -10px;
`;

const Content = styled.div`
  position: relative;
  font-size: 14px;
  display: flex;
  line-height: 25px;
`;

const Span = styled.span`
  margin-left: 6px;
  margin-right: 6px;
`;

const getAssembleIcon = (equips, operators, vehicle) => {
  let operatorIcon = null;
  if (operators === 0) operatorIcon = null;
  else if (operators === 1) operatorIcon = <Icons.Operator />;
  else operatorIcon = <Icons.DoubleOperator />;

  let vehicleIcon = null;
  if (vehicle > 0) {
    // vehicleIcon = vehicles[0].icon();
    vehicleIcon = <Icons.Unitary />;
    if (equips === 1) vehicleIcon = <Icons.TruckEquipment />;
    else if (equips > 1) vehicleIcon = <Icons.TruckDoubleEquipment />;
  } else {
    if (equips === 1) vehicleIcon = <Icons.Equipment />;
    else if (equips > 1) vehicleIcon = <Icons.DoubleEquipment />;
  }
  return (
    <div
      style={{
        width: "100px",
        // height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "30px",
          height: "100%",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "flex-end",
          // margin: "0 6px",
        }}
      ></div>
      <div
        style={{
          width: "20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // margin: "0 6px",
        }}
      >
        {" "}
        {operatorIcon}
      </div>
      <div
        style={{
          width: "50px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          // margin: "0 6px",
        }}
      >
        {vehicleIcon}
      </div>
    </div>
  );
};
