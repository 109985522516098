import React from "react";
const OrderIcon = (props) => {
  return (
    <svg
      width="71px"
      height="49px"
      viewBox="0 0 71 49"
      fill="#2196F3"
      {...props}
    >
      <path
        d="M49.5,13 L21.5,13 C19.5575,13 18.0175,14.2905 18.0175,15.9 L18,33.3 C18,34.9095 19.5575,36.2 21.5,36.2 L49.5,36.2 C51.4425,36.2 53,34.9095 53,33.3 L53,15.9 C53,14.2905 51.4425,13 49.5,13 Z M49.5,33.3 L21.5,33.3 L21.5,24.6 L49.5,24.6 L49.5,33.3 Z M49.5,18.8 L21.5,18.8 L21.5,15.9 L49.5,15.9 L49.5,18.8 Z"
        id="Shape"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};

export default OrderIcon;
