//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import styled from "styled-components";
import SVGIcon, { icons } from "../../../shared/svg-icon";
import ModalSelectInput from "../modal-select-input";

type Props = {
  show: boolean,
  onChange: (SharedTypes.SimpleEvent) => void,
  data: { logo: string, maneuver: string, trip: string },
  reasons: { id: number, description: string }[],
  date: Date,
  place: string,
  reasonSelect: number,
};

const CancelAppointmentModal = ({
  show,
  onChange,
  data,
  date,
  reasons,
  place,
  reasonSelect,
}: Props) => {
  return (
    <BaseDialog
      open={show}
      title="Cancelar cita"
      message="Esta acción no es reversible y devolverá tu cita al listado, esperando ser reprogramado"
      acceptText="Cancelar cita"
      cancelText="Cerrar"
      showCancel={true}
      cancelButtonType="outline dark primary"
      acceptButtonType="secondary"
      disabledAccept={reasonSelect === 0}
      acceptAction={() =>
        onChange({
          target: {
            name: "cancelAppointment",
            value: data.maneuver === "Carga" ? "load" : "unload",
          },
        })
      }
      closeAction={(a) => onChange(a)}
      contentObject={
        <ModalContent
          {...data}
          onChange={onChange}
          reasons={reasons}
          date={date}
          place={place}
          // appointmentAction={appointmentAction}
          reasonSelect={reasonSelect}
        />
      }
      style={{ content: { width: "450px", marginLeft: "-200px" } }}
      //   disabledAccept: PropTypes.bool,
    />
  );
};
export default CancelAppointmentModal;

const ModalContent = (props) => {
  return (
    <div>
      <Avatar alignItems={flexOpts.alignItems.flexEnd}>
        <Logo src={props.logo} alt="" />
        <Flex direction={flexOpts.direction.column}>
          <Trip>{`N° orden: ${props.trip}`}</Trip>
          <Trip>{`Maniobra: ${props.maneuver}`}</Trip>
        </Flex>
      </Avatar>
      <Flex
        direction={flexOpts.direction.column}
        style={{ width: "80%", margin: "auto" }}
      >
        <Appointment>{"Cita Anterior"}</Appointment>
        <AppointmentDate>
          <Icon
            name={icons.calendar}
            stroke={"#AFC8FF"}
            fill={"#AFC8FF"}
            height={"15px"}
            width={"15px"}
          />
          {formatDate(props.date)}
        </AppointmentDate>
        <AppointmentDate>{props.place}</AppointmentDate>
      </Flex>
      <Flex justifyContent={flexOpts.justifyContent.spaceAround}></Flex>
      <Avatar>
        <ModalSelectInput
          isSearchable={false}
          defaultValue={props.reasons[0]}
          onChange={(a) =>
            props.onChange({
              target: { name: "reasonSelect", value: a.value },
            })
          }
          name="reasonSelect"
          options={props.reasons}
        />
      </Avatar>
    </div>
  );
};

const formatDate = (date: Date) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const tomorrow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes()
  );

  let result =
    date.toDateString() === today.toDateString()
      ? "Hoy, "
      : date.toDateString() === tomorrow.toDateString()
      ? "Mañana, "
      : date.toUTCString().substring(4, 16);

  result = result.concat(
    " ",
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    " hrs"
  );
  return result;
};

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 17px;
  margin: 5px;
`;

const Trip = styled.span`
  color: #20293d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

// const Maneuver = styled.span`
//   color: #20293d;
//   font-size: 12px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 14px;
// `;

const Appointment = styled.span`
  color: #20293d;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const AppointmentDate = styled(Flex)`
  color: #2d364c;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Avatar = styled(Flex)`
  margin: 20px;
`;

const Icon = styled(SVGIcon)`
  margin-right: 5px;
`;
