import styled from "styled-components";
import TabSelectorRibbon from "../../../shared/controls/tab-selector-ribbon";

const CalendarSelectorRibbon = styled(TabSelectorRibbon)`
justify-content:center;

    & :first-child,
    & :last-child {
      font-size: 28px;
    }

    & div    {
      width: 60px;  
      height: 100%;
      margin: auto 3px;
      padding: 13px;

      &.selected {
        border-bottom: #e9643f 4px solid;
      }
    }
  }
`;
export default CalendarSelectorRibbon;
