import React from 'react';
import { connect } from 'react-redux';
import { createEntity, getEntityItems, partialEntity, readEntities, readEntity } from '../../redux/api';
import StepTitle from '../../shared/component/step-title';
import Button from '../../shared/controls/button';
import Chip from '../../shared/controls/chip';
import FileSelector, { acceptTypes, getFileDescription } from '../../shared/controls/file-selector';
import RadioInput from '../../shared/controls/radio-input';
import SelectInput from '../../shared/controls/select-input';
import TextInput from '../../shared/controls/text-input';
import ErrorDialog from '../../shared/dialog/error-dialog';
import CheckCircleIcon from '../../shared/icons/check-circle-icon';
import ForbiddenIcon from '../../shared/icons/forbidden-icon';
import Loader from '../../shared/icons/tat/loader';
import { View, ViewTitle } from '../../shared/styled/view';
import SVGIcon, { icons } from '../../shared/svg-icon';
import { validateRFC, validateMoralRFC, validatePhysicalRFC, validators } from '../../shared/validate';
import ReactTooltip from 'react-tooltip';

let typingTimer;

class TaxAddressFormView extends React.Component {
  state={
    loading: false,
    addressType: '2',
    personType: '1',
    companyName: '',
    rfc: '',
    country: 0,
    zipCode: '',
    zipCodeId: 0,
    phoneNumber: '',
    state: 0,
    city: 0,
    regime: 0,
    locality: 0,
    address: '',
    extNumber: '',
    intNumber: '',
    neighborhood: 0,
    email: '',
    fiscalRegime: 0,
    // SCTPermitType: 0,
    // SCTPermitNumber: '',
    keyFile: [],
    cerFile: [],
    filePassword: '',
    pwdType: true,
    zip_file: null,
    errorMsg: [],
    showError: false,

    countryOptions: [],
    neighborhoodOptions: [],
    stateOptions: [],
    cityOptions: [],
    localityOptions: [],
    zoneId: 0,
    onFocused: false,
  }

  componentDidMount(){
    this.props.loadTaxRegime({},{
      prefix: 'orbisat.',
    });
    this.props.loadZones();
    this.props.getRegimes({}, {
      prefix: 'orbisat.',
    });
    if(this.props.companyType === 1){
      this.props.loadPermitType({}, {
        prefix: 'orbisat.',
      })
    }
    if(this.props.match?.params?.addressId){
      this.props.getAddress({
        company_id: this.props.companyId,
        tax_info_id: this.props.match?.params?.addressId
      }, {
        onSuccess: (r) => {
          let countryOptions = [{id: r.data?.country?.id, description: r.data?.country?.descripcion}];
          let neighborhoodOptions = [{id: r.data?.suburb?.id, description: r.data?.suburb?.nombre}];
          let stateOptions = [{id: r.data?.state?.id, description: r.data?.state?.descripcion}];
          let cityOptions = [{id: r.data?.city?.id, description: r.data?.city?.descripcion}];
          let localityOptions = [{id: r.data?.locality?.id, description: r.data?.locality?.descripcion}];

          let regime = 0;
          let isMoral = false;
          let rfc = r.data?.fiscal_number || '';

          if (validateMoralRFC(rfc)) {
            isMoral = true;
          }

          if (r.data) {
            regime = `${r.data?.capital_regime || 3}`
          }

          // debugger;
          this.setState({
            countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions,

            companyName: r.data?.legal_company_name || '',
            rfc,
            country: r.data?.country?.id || 0,
            zipCode: r.data?.zip_code?.cp || '',
            zipCodeId: r.data?.zip_code?.id || 0,
            phoneNumber: r.data?.phone_number || '',
            state: r.data?.state.id || 0,
            city: r.data?.city?.id || 0,
            locality: r.data?.locality?.id || 0,
            address: r.data?.street || '',
            extNumber: r.data?.exterior_number || '',
            intNumber: r.data?.interior_number || '',
            neighborhood: r.data?.suburb?.id || 0,
            email: r.data?.email || '',
            fiscalRegime: r.data?.tax_regime?.id || 0,
            // SCTPermitType: r.data?.sct_type?.id || 0,
            // SCTPermitNumber: r.data?.sct_number || '',
            keyFile: [],
            cerFile: [],
            zip_file: r.data?.zip_file || '',
            addressType: ''.concat(r.type || 2),
            zoneId: r.time_zone || 0,
            regime,
            personType: isMoral ? '1' : '2'
          })
        }
      })
    }
  }
  
  handleChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    // debugger;
    switch(name){
      case "zipCode":
        this.setState({[name]: value}, () => {
          clearTimeout(typingTimer);
          typingTimer = setTimeout(this.getZipCodeInfo, 1000);
        })
        break;
      case "rfc":
        this.setState({[name]: value.toUpperCase()});
        break;
      case "keyFile":
        if(value.length && value[0] && value[0].name){
          const fname = value[0].name;
          let ext = fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);
          if(ext === "key"){
            this.setState({[name]: value})
          } else {
            this.setState({showError: true, errorMsg: ['Tipo de archivo no válido.']})
          }
        }
        break;
      case "cerFile":
        if(value.length && value[0] && value[0].name){
          const fname = value[0].name;
          let ext = fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);
          if(ext === "cer"){
            this.setState({[name]: value})
          } else {
            this.setState({showError: true, errorMsg: ['Tipo de archivo no válido.']})
          }
        }
        break;
      case "companyName":
        if (value.length <= 250) {
          this.setState({[name]: value.toUpperCase()})
        } else {
          this.setState({[name]: value.substring(0, 250).toUpperCase()})
        }
        break;
      case "personType":
        if (this.rfcTextInput) {
          setTimeout(function() {
            this.rfcTextInput.isValid(this.state.rfc);
          }.bind(this), 400)
        }
        
        this.setState({[name]: value});
        break;
      default: 
        this.setState({[name]: value})
    }
    // if(name === "zipCode"){
    //   this.setState({[name]: value}, () => {
    //     clearTimeout(typingTimer);
    //     typingTimer = setTimeout(this.getZipCodeInfo, 1000);
    //   })
    // } else {
      
    // }
  }

  handleRemoveDocument = (property) => {
    // if (!this.state.loading) {
      this.setState({ [property]: [] });
    // }
  };

  getZipCodeInfo = () => {
    this.props.checkZipCode({
      cp: this.state.zipCode,
      country: this.props.companyCountry
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];
          this.setState({
            countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions, 

            country: result[0].pais_id,
            neighborhood: result.length > 1 ? 0 : result[0].colonia_id,
            state: result[0].estado_id,
            city: result[0].municipio_id,
            locality: result[0].localidad_id,
            zipCodeId: result[0].cp_id,
          })
        } else {
          this.setState({
            countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],

            country: 0,
            neighborhood: 0,
            state: 0,
            city: 0,
            locality: 0,
            zipCodeId: 0
          })
        }
      }
    })
  }

  saveForm = () => {
    this.setState({loading: true})
    let params = {
      legal_company_name: this.state.companyName,
      street: this.state.address,
      suburb: this.state.neighborhood,
      state: this.state.state,
      country: this.state.country,
      email: this.state.email,
      fiscal_number: this.state.rfc,
      exterior_number: this.state.extNumber,
      interior_number: this.state.intNumber || null,//OBLIG
      phone_number: this.state.phoneNumber || null,//OBLIG

      city: this.state.city,
      locality: this.state.locality || null,
      zip_code: this.state.zipCodeId,
      tax_regime: this.state.fiscalRegime,
      zip_file: this.state.zip_file,
      // cert_file: ".",
      // key_file: ".",
      // tax: 1,//'',
      // tax_retention: 1,//'',
    };

    if (this.state.personType === '1' && this.state.regime !== 0) {
      params.capital_regime = parseInt(this.state.regime);
    } else {
      params.capital_regime = 0;
    }

    // if(this.props.companyType === 1){
    //   params.sct_type = this.state.SCTPermitType;
    //   params.sct_number = this.state.SCTPermitNumber;
    // }

    if(this.state.addressType !== '2'){
      if(this.state.addressType !== '1'){
        params.tax_regime = null;
      }
      // params.tax_regime = null;
      // params.cert_file = null;
      // params.key_file = null;
      params.sct_type = null;
      params.sct_number = null;
    }
    if(this.props.match?.params?.addressId){
      var formData = {
        company_id: this.props.companyId,
        tax_info_id: this.props.match?.params?.addressId,
        "form-data": JSON.stringify({
          data: params,
          company: this.props.companyId, 
          type: this.props.companyType === 1 ? this.state.addressType : '1',
        }),
        cert_file: this.state.cerFile[0] || '',
        key_file: this.state.keyFile[0] || '',
        password: this.state.filePassword || ''
      }

      formData.time_zone = this.state.zoneId;

      this.props.updateAddress(
        formData,
        {
          isForm: true,
          onSuccess: () => {
            this.setState({loading: false});
            // this.props.history.goBack();
            this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
          },
          onError: (e) => {
            this.setState({loading: false, showError: true, errorMsg: e.response?.data?.errors || []});
          }
        }
      )
      // this.props.updateAddress({
      //   company: this.props.companyId,
      //   tax_info_id: this.props.match?.params?.addressId,
      //   company_id: this.props.companyId,
      //   type: this.props.companyType === 1 ? this.state.addressType : '1',
      //   data: params,
        
      // }, {
      //   onSuccess: (r) => {
      //     if(this.state.addressType !== '3' && this.state.addressType !== '4'){
      //       if(this.state.cerFile.length && this.state.keyFile.length){
      //         this.props.updateAddress({
      //           company_id: this.props.companyId,
      //           tax_info_id: r.id,
      //           cert_file: this.state.cerFile[0],
      //           key_file: this.state.keyFile[0],
      //           password: this.state.filePassword
      //         }, {
      //           isForm: true,
      //           onSuccess: () => {
      //             this.setState({loading: false});
      //             // this.props.history.goBack();
      //             this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //           },
      //           onError: (e) => {
      //             this.setState({loading: false, showError: true, errorMsg: e.response?.data?.errors || []});
      //           }
      //         })
      //       } else {
      //         this.setState({loading: false});
      //         // this.props.history.goBack();
      //         this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //       }
      //     } else {
      //       this.setState({loading: false});
      //       // this.props.history.goBack();
      //       this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //     }
      //   }
      // })
    } else {
      let formData = {
        company_id: this.props.companyId,
        "form-data": JSON.stringify({
          data: params,
          company: this.props.companyId, 
          type: this.props.companyType === 1 ? this.state.addressType : '1',
        }),
        // {
        //   ...params, 

        // },
        cert_file: this.state.cerFile[0] || '',
        key_file: this.state.keyFile[0] || '',
        password: this.state.filePassword || ''
      }

      if (this.props.companyType === 1) {
        formData.time_zone = this.state.zoneId;
      }

      // console.log(formData, JSON.stringify(formData));
      this.props.saveAddress(
        formData, 
      {
        isForm: true,
        onSuccess: () => {
          this.setState({loading: false});
          // this.props.history.goBack();
          this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
        },
        onError: (e) => {
          this.setState({loading: false, showError: true, errorMsg: e.response?.data?.errors || []});
          // console.error(e.response);
        }
      })
      // this.props.saveAddress({
      //   company: this.props.companyId,
      //   company_id: this.props.companyId,
      //   type: this.props.companyType === 1 ? this.state.addressType : '1',
      //   data: params,
      // }, {
      //   onSuccess: (r) => {
      //     // debugger;
      //     if(this.state.addressType !== '3' && this.state.addressType !== '4'){
      //       if(this.state.cerFile.length && this.state.keyFile.length){
      //         this.props.updateAddress({
      //           company_id: this.props.companyId,
      //           tax_info_id: r.id,
      //           cert_file: this.state.cerFile[0],
      //           key_file: this.state.keyFile[0],
      //           password: this.state.filePassword
      //         }, {
      //           isForm: true,
      //           onSuccess: () => {
      //             this.setState({loading: false});
      //             // this.props.history.goBack();
      //             this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //           },
      //           onError: (e) => {
      //             this.setState({loading: false, showError: true, errorMsg: e.response?.data?.errors || []});
      //             // console.error(e.response);
      //           }
      //         })
      //       } else {
      //         this.setState({loading: false});
      //         // this.props.history.goBack();
      //         this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //       }
      //     } else {
      //       this.setState({loading: false});
      //       // this.props.history.goBack();
      //       this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
      //     }
      //   }, 
      //   onError: () => {
      //     this.setState({loading: false});
      //   }
      // })
    }
    
  }

  getErrors = () => {
    let errors = "";

    if (this.evaluateForm()) {
      errors = "Por favor completa los siguientes campos:<br/>";
    }

    errors += String(this.state.companyName).length === 0 ? `${this.state.personType === '1' ? "<b>Ingresa la razon social<b/><br/>" : "<b>Ingresa el nombre completo<b/><br/>"}` : '';

    if (String(this.state.rfc).length === 0) {
      errors += "<b>Ingresa el rfc<b/><br/>";
    } else {
      errors += (this.state.personType === '1' ? !validateMoralRFC(this.state.rfc) : !validatePhysicalRFC(this.state.rfc)) ? "<b>El formato de rfc no es valido<b/><br/>" : "";
    }

    errors += (this.state.personType === '1' && this.state.regime === 0) ? "<b>Selecciona un régimen capital<b/><br/>" : "";
    errors += String(this.state.zipCode).length === 0 ? "<b>Ingresa el código postal<b/><br/>" : "";
    errors += this.state.zipCodeId === 0 ? "<b>Selecciona el código postal<b/><br/>" : "";
    // errors += this.state.country === 0 ? "<b>Selecciona el país<b/><br/>" : "";
    // errors += this.state.state === 0 ? "<b>Selecciona el estado<b/><br/>" : "";
    // errors += this.state.city === 0 ? "<b>Selecciona la ciudad<b/><br/>" : "";
    errors += String(this.state.address).length === 0  ? "<b>Ingresa el domicilio<b/><br/>" : "";
    errors += String(this.state.extNumber).length === 0  ? "<b>Ingresa el num exterior<b/><br/>" : "";
    errors += this.state.neighborhood === 0 ? "<b>Selecciona la colonia<b/><br/>" : "";
    errors += (this.state.addressType === '2' && this.state.fiscalRegime === 0) ?  "<b>Selecciona el régimen fiscal<b/><br/>" : "";
    errors += this.state.zoneId === 0 ? "<b>Selecciona la zona horaria<b/><br/>" : "";

    return errors;
  }

  evaluateForm = () => {
    return (
      !this.state.companyName 
      || !this.state.rfc
      || (this.state.personType === '1' ? !validateMoralRFC(this.state.rfc) : !validatePhysicalRFC(this.state.rfc))
      || !validateRFC(this.state.rfc)
      || !this.state.zipCode
      || !this.state.zipCodeId
      || !this.state.country
      || !this.state.state
      || !this.state.city
      || !this.state.address
      //
      || !this.state.extNumber
      // || !this.state.intNumber
      //
      || !this.state.neighborhood
      // || !this.state.email
      || (this.state.addressType === '2' && !this.state.fiscalRegime)
      || !this.state.zoneId
      || (this.state.personType === '2' ? false : this.state.regime === 0)
      // || (this.state.addressType === '2' && !this.state.keyFile.length && !this.state.zip_file)
      // || (this.state.addressType === '2' && !this.state.cerFile.length && !this.state.zip_file)
      // || (this.props.companyType === 1 && this.state.addressType === '2' && !this.state.SCTPermitType)
      // || (this.props.companyType === 1 && this.state.addressType === '2' && !this.state.SCTPermitNumber)
    )
  }

  getRegimeInfo = () => {
    let regimeInfo = this.props.regimes.filter(item => `${item.id}` === `${this.state.regime}`);
    if (regimeInfo.length > 0) {
      return regimeInfo[0].key_description ?? '';
    }
    return ' ';
  }

  render () {
    return (
      <View className="tax-address-form-view">
        <ViewTitle>
          Información de domicilio fiscal
        </ViewTitle>
        <div className="content">
          <div className="panel">
            <div className="left-side">
              <StepTitle number={'01'} title={'Datos fiscales'} />
              {
                this.props.companyType === 1 && (!this.props.match?.params?.addressId || this.state.addressType !== "2")
                ? <>
                  <div className="row" style={{marginTop: "20px"}}>
                    Tipo de domicilio: 
                    {
                      this.props.match?.params?.addressId 
                      ? ''
                      : <RadioInput
                          className="radio-input"
                          name="addressType"
                          title="Emisor"
                          value="2"
                          selected={this.state.addressType}
                          // onClick={(e) => setContract(e.target.value)}
                          onClick={this.handleChange}
                        />
                    }
                    <RadioInput
                      className="radio-input"
                      name="addressType"
                      title="Arrendatario"
                      value="3"
                      selected={this.state.addressType}
                      // onClick={(e) => setContract(e.target.value)}
                      onClick={this.handleChange}
                    />
                    <RadioInput
                      className="radio-input"
                      name="addressType"
                      title="Propietario"
                      value="4"
                      selected={this.state.addressType}
                      // onClick={(e) => setContract(e.target.value)}
                      onClick={this.handleChange}
                    />
                  </div>
                </>
                : <></>
              }
              <div className="row" style={{marginTop: "20px"}}>
                Tipo de persona: 
                <div style={{width: 7}}/>
                <RadioInput
                  className="radio-input"
                  name="personType"
                  title="Moral"
                  value="1"
                  selected={this.state.personType}
                  // onClick={(e) => setContract(e.target.value)}
                  onClick={this.handleChange}
                />
                <RadioInput
                  className="radio-input"
                  name="personType"
                  title="Física"
                  value="2"
                  selected={this.state.personType}
                  // onClick={(e) => setContract(e.target.value)}
                  onClick={this.handleChange}
                />
              </div>
              <div className="row">
                <TextInput 
                  label={this.state.personType === '1' ? 'Razón social:' : 'Nombre completo'}
                  type='text'
                  value={this.state.companyName}
                  name='companyName'
                  onChange={this.handleChange}
                  extraInfo={
                    this.state.personType === '1' ?
                    <div>
                      <div>-CFDI 4.0 El nombre debe coincidir con el registrado ante el SAT.</div>
                      <div>-Considera acentos y espacios</div>
                      <div>-No debe llevar las siglas del régimen de capital (se agregan en el siguiente campo)</div>
                      <div>-Maximo 250 caracteres</div>
                    </div> :
                    <div>
                      <div>-CFDI 4.0 Todos los nombres, primer y segundo apellidos deben coincidir con los registrado ante el SAT.</div>
                      <div>-Considera acentos y espacios</div>
                      <div>-Maximo 250 caracteres</div>
                    </div>
                  }
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>
              <div className="row">
                {
                  this.state.personType === '1' &&
                  <SelectInput
                    label='Régimen capital:'
                    value={this.state.regime}
                    name='regime'
                    items={this.props.regimes}
                    onChange={this.handleChange}
                    extraInfo={this.getRegimeInfo()}
                    validate={[validators.required]}
                    onFocused={this.state.onFocused}
                  />
                }
                <TextInput 
                  ref={(input) => { this.rfcTextInput = input; }} 
                  label='RFC:'
                  type='text'
                  value={this.state.rfc}
                  name='rfc'
                  onChange={this.handleChange}
                  validate={ this.state.personType === '1' ? ['moralRfc'] : ['physicalRfc']}
                  onFocused={this.state.onFocused}
                />
              </div>
              {/* <div className="row">
                
              </div> */}
              <div className="row">
                {/* <SelectInput
                  label='País de origen:'
                  // className="full"
                  value={this.state.country}
                  name='country'
                  items={this.state.countryOptions}
                  onChange={this.handleChange}
                  disabled={!this.state.zipCode}
                /> */}
                <TextInput 
                  label='Código Postal:'
                  type='text'
                  value={this.state.zipCode}
                  name='zipCode'
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                <TextInput 
                  label='Teléfono (opcional):'
                  type='text'
                  value={this.state.phoneNumber}
                  name='phoneNumber'
                  onChange={this.handleChange}
                />
              </div>
              <div className="row">
                <SelectInput
                  label='Estado:'
                  // className="full"
                  value={this.state.state}
                  name='state'
                  items={this.state.stateOptions}
                  onChange={this.handleChange}
                  disabled={!this.state.zipCode}
                />
                <SelectInput
                  label='Ciudad:'
                  // className="full"
                  value={this.state.city}
                  name='city'
                  items={this.state.cityOptions}
                  onChange={this.handleChange}
                  disabled={!this.state.zipCode}
                />
              </div>
              <div className="row">
                {
                  this.state.locality 
                  ? <SelectInput
                    label='Localidad:'
                    // className="full"
                    value={this.state.locality}
                    name='locality'
                    items={this.state.localityOptions}
                    onChange={this.handleChange}
                    disabled={!this.state.zipCode}
                  />
                  : ''
                }
                <TextInput 
                  label='Domicilio:'
                  type='text'
                  value={this.state.address}
                  name='address'
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />    
              </div>
              <div className="row">
                <TextInput 
                  label='Num Ext:'
                  type='text'
                  value={this.state.extNumber}
                  name='extNumber'
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                <TextInput 
                  label='Num Int:'
                  type='text'
                  value={this.state.intNumber}
                  name='intNumber'
                  onChange={this.handleChange}
                />       
              </div>
              <div className="row">
                <SelectInput
                  label='Colonia:'
                  // className="full"
                  value={this.state.neighborhood}
                  name='neighborhood'
                  items={this.state.neighborhoodOptions}
                  onChange={this.handleChange}
                  disabled={!this.state.zipCode}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>
              {/* <div className="row">
                <TextInput 
                  label='Correo electrónico:'
                  type='text'
                  value={this.state.email}
                  name='email'
                  onChange={this.handleChange}
                />
              </div> */}
              {
                this.state.addressType !== '3' && this.state.addressType !== '4'
                ? <div className="row">
                    <SelectInput
                      label='Régimen Fiscal:'
                      // className="full"
                      value={this.state.fiscalRegime}
                      name='fiscalRegime'
                      items={this.props.taxRegimes}
                      onChange={this.handleChange}
                      validate={[validators.required]}
                      onFocused={this.state.onFocused}
                    />
                  </div>
                : <></>
              }

              <div className="row">
                <SelectInput
                  label='Zona horaria:'
                  // className="full"
                  value={this.state.zoneId}
                  name='zoneId'
                  items={this.props.zones}
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>
              
            </div>
            {
              this.state.addressType !== '3' && this.state.addressType !== '4'
              ? <div className="right-side">
                  <StepTitle number={'02'} title={'Sellos digitales y permisos SCT'} />
                  <div>Dale clic al cuadro para agregar los archivos de sellos digitales.</div>
                  <div>*Recuerda que los archivos deben ser <span>.key</span> o  <span>.cer</span></div>
                  <div className="row file-upload-segment">
                    <div className="file-upload-container">
                      <div className="files-container">
                        {
                          this.state.keyFile.length 
                          ? (this.state.keyFile || []).map((f, i) => (
                              <Chip
                                key={i}
                                id={i}
                                active={true}
                                className="small"
                                name="files"
                                description={getFileDescription(f, 25)}
                                onClick={() => {}}
                                suffix={{
                                  element: (
                                    <div
                                      onClick={() => this.handleRemoveDocument("keyFile")}
                                    >
                                      {" "}
                                      <SVGIcon
                                        name={icons.cross}
                                        fill="#A7B6D6"
                                        viewBox="0 3 16 16"
                                      />
                                    </div>
                                  ),
                                }}
                              />
                            ))
                          : this.state.zip_file
                            ? <Chip
                                id={'key1'}
                                active={true}
                                className="small"
                                name="files"
                                description={"Archivo key"}
                                onClick={() => {}}
                                prefix={{
                                  element: (
                                    <CheckCircleIcon />
                                  ),
                                }}
                                
                              />
                            : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <ForbiddenIcon fill="#cc0033" width="50px" height="50px"/>
                                <div>No se ha subido el archivo</div>
                              </div>
                        }
                      </div>
                      <FileSelector
                        name={"keyFile"}
                        acceptTypes={[acceptTypes.key]}
                        onChange={this.handleChange}
                        // allowMultiple={true}
                      >
                        {/* <AttachIcon style={{display: ""}} className="attach-icon"/> */}
                        <Button
                          settings={{
                            type: "primary",
                            text: "Selecciona archivo .key",
                            prefix: {
                              element: (
                                <SVGIcon
                                  name={icons.uploadFile}
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                />
                              ),
                            },
                          }}
                        />
                      </FileSelector>
                    </div>
                    <div className="file-upload-container">
                      <div className="files-container">
                      {
                          this.state.cerFile.length 
                          ? (this.state.cerFile || []).map((f, i) => (
                              <Chip
                                key={i}
                                id={i}
                                active={true}
                                className="small"
                                name="files"
                                description={getFileDescription(f, 25)}
                                onClick={() => {}}
                                suffix={{
                                  element: (
                                    <div
                                      onClick={() => this.handleRemoveDocument("cerFile")}
                                    >
                                      {" "}
                                      <SVGIcon
                                        name={icons.cross}
                                        fill="#A7B6D6"
                                        viewBox="0 3 16 16"
                                      />
                                    </div>
                                  ),
                                }}
                              />
                            ))
                          : this.state.zip_file
                            ? <Chip
                                id={'key1'}
                                active={true}
                                className="small"
                                name="files"
                                description={"Archivo cer"}
                                onClick={() => {}}
                                prefix={{
                                  element: (
                                    <CheckCircleIcon />
                                  ),
                                }}
                              />
                            : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <ForbiddenIcon fill="#cc0033" width="50px" height="50px"/>
                                <div>No se ha subido el archivo</div>
                              </div>
                        }
                        {}
                      </div>
                      <FileSelector
                        name={"cerFile"}
                        acceptTypes={[acceptTypes.cer]}
                        onChange={this.handleChange}
                        // allowMultiple={true}
                      >
                        {/* <AttachIcon style={{display: ""}} className="attach-icon"/> */}
                        <Button
                          settings={{
                            type: "primary",
                            text: "Selecciona archivo .cer",
                            prefix: {
                              element: (
                                <SVGIcon
                                  name={icons.uploadFile}
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                />
                              ),
                            },
                          }}
                        />
                      </FileSelector>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: "40px"}}>
                    <TextInput 
                      label='Contraseña:'
                      type={this.state.pwdType ? "password" : "text"}
                      // type='text'
                      value={this.state.filePassword}
                      name='filePassword'
                      onChange={this.handleChange}
                      suffix={{
                        element: (
                          <div
                            onClick={() =>
                              this.setState({ pwdType: !this.state.pwdType })
                            }
                          >
                            <SVGIcon
                              name={icons.passwordToggle}
                              fill={this.state.pwdType ? "#AFC8FF" : "rgb(30,70,233)"}
                            />
                          </div>
                        ),
                        className: ""
                      }}
                      className="inline-input"
                    />
                  </div>
                  <div className="extra-info">
                    <p>Se requiere la contraseña de los sellos digitales.</p>
                    <p>La contraseña es sensible a mayúsculas, minúsculas y caracteres especiales.</p>
                  </div>
                  {/* {
                    this.props.companyType === 1 
                    ? <>
                      <div className="row">
                        <SelectInput
                          label='Tipo de Permiso SCT:'
                          // className="full"
                          value={this.state.SCTPermitType}
                          name='SCTPermitType'
                          items={this.props.permitTypes}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="row">
                        <TextInput 
                          label='Número de permiso SCT:'
                          type='text'
                          value={this.state.SCTPermitNumber}
                          name='SCTPermitNumber'
                          onChange={this.handleChange}
                        />
                      </div>
                    </>
                    : <></>
                  } */}
                  
                </div>
                : <></>
            }
            
          </div>
          <div className="actions">
            <Button 
              type="secondary"
              text="Cancelar"
              onClick={() => {
                this.props.history.push('/company/profile/'.concat(this.props.company_name, '?tab=rfc'))
              }}
            />
            <ReactTooltip
              id={"tooltip-error-rfc"}
              place="top"
              type="light"
              effect="solid"
              html={true}
              event="mouseover"
              eventOff="mouseleave"
            />
            <Button 
              type="primary"
              text={this.state.loading ? <Loader /> : "Guardar"}
              disabled={this.state.loading || this.evaluateForm()}
              onClick={this.saveForm}
              dataTip={this.getErrors()}
              dataFor="tooltip-error-rfc"
              onMouseLeave={() => this.setState({ onFocused: true })}
            />
          </div>
        </div>
        <ErrorDialog
          open={this.state.showError}
          // title="Error"
          message={this.state.errorMsg}
          acceptText="OK"
          acceptAction={() => this.setState({showError: false, errorMsg: []})}
          closeAction={() => this.setState({showError: false, errorMsg: []})}
        />
      </View>
    )
  }
}

// export default TaxAddressFormView;

const mapStateToProps = (state) => {
  // console.log(state);
  const permitTypes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.TIPOPERMISO").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion)}))
  const taxRegimes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.REGIMENFISCAL").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion)}));
  const zones = getEntityItems(state, "COMPANIES.SAT.CATALOGS.ZONES");
  const regimes = getEntityItems(state, "COMPANIES.SAT.REGIMES").map(p => ({id: p.id, description: p.clave, key_description: p.descripcion}));

  // console.log(state, taxRegimes);
  // if(state.api && state.api["COMPANIES.SAT.REGIMENFISCAL"] && state.api["COMPANIES.SAT.REGIMENFISCAL"].items.length){
    // debugger;
  // }
  return {
    zones,
    companyId: state.globalCatalog.session.company.id,
    company_name: state.globalCatalog.session.company?.orbi_id,
    companyType: state.globalCatalog.session.company.type.id,
    companyCountry: state.globalCatalog.session.company.country,
    permitTypes,
    taxRegimes: taxRegimes,
    regimes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadZones:  (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.ZONES", params, opt) 
      );
    },
    loadPermitType:  (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.TIPOPERMISO", params, opt) 
      );
    },
    loadTaxRegime:  (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.REGIMENFISCAL", params, opt) 
      );
    },
    checkZipCode: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.ZIPCODECHECK", params, opt) 
      );
    },
    saveAddress: (params, opt) =>{
      dispatch(
        createEntity("COMPANIES.SAT.ADDRESS.CREATE", params, opt) 
      );
    },
    getAddress: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.SAT.ADDRESS.RETRIEVE", params, opt) 
      );
    },
    updateAddress: (params, opt) =>{
      dispatch(
        partialEntity("COMPANIES.SAT.ADDRESS.UPDATE", params, opt) 
      );
    },
    getRegimes: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.REGIMES", params, opt) 
      );
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxAddressFormView);