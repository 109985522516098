import {
  BACKEND,
  getApiUrl,
  postApiUrl,
  putApiUrl,
  postMultipartApiUrl,
  interpolate,
  deleteApiUrl,
  patchApiUrl,
} from "../../shared/backend-api";
import store from "../store";
import { cloneDeep } from "lodash";
// import { throwError } from "rxjs";

const ACTIONS = {
  FLEETS: {
    SHARES: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_SHARES_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SHARES.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SHARES_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SHARES.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SHARES_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SHARES.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_SHARES_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SHARES.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SHARES_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SHARES.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SHARES_POST_SUCCESS",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SHARES.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      FLEET_PROVIDER: {
        REQUEST: {
          TYPE: "FLEET_SHARES_FLEET_PROVIDER_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SHARES_FLEET_PROVIDER_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SHARES_FLEET_PROVIDER_SUCCESS",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    POST: {
      REQUEST: {
        TYPE: "FLEET_POST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FLEETS.POST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FLEET_POST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FLEETS.POST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FLEET_POST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FLEETS.POST.ERROR.TYPE,
          error,
        }),
      },
    },
    GET: {
      REQUEST: {
        TYPE: "FLEET_GET_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FLEETS.GET.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FLEET_GET_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FLEETS.GET.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FLEET_GET_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FLEETS.GET.ERROR.TYPE,
          error,
        }),
      },
    },

    DATA: {
      PUT: {
        REQUEST: {
          TYPE: "FLEET_DATA_PUT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.PUT.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_PUT_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.PUT.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_PUT_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.PUT.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_DATA_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      LIST: {
        REQUEST: {
          TYPE: "FLEET_DATA_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      GET: {
        REQUEST: {
          TYPE: "FLEET_DATA_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      UPDATE_NORMATIVITY_STATUS: {
        REQUEST: {
          TYPE: "ACTIONS_FLEETS_DATA_UPDATE_NORMATIVITY_STATUS_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.REQUEST.TYPE,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_FLEETS_DATA_UPDATE_NORMATIVITY_STATUS_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.ERROR.TYPE,
            error,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_FLEETS_DATA_UPDATE_NORMATIVITY_STATUS_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.SUCCESS.TYPE,
            response,
          }),
        },
      },
      UPDATE: {
        REQUEST: {
          TYPE: "FLEET_DATA_UPDATE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DATA.UPDATE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_UPDATE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DATA.UPDATE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_UPDATE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DATA.UPDATE.ERROR.TYPE,
            error,
          }),
        },
      },
      VALIDATIONS: {
        ECONOMIC: {
          REQUEST: {
            TYPE: "FLEET_DATA_VALIDATIONS_ECONOMIC_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_DATA_VALIDATIONS_ECONOMIC_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_DATA_VALIDATIONS_ECONOMIC_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.ERROR.TYPE,
              error,
            }),
          },
        },
        NIV: {
          REQUEST: {
            TYPE: "FLEET_DATA_VALIDATIONS_NIV_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_DATA_VALIDATIONS_NIV_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_DATA_VALIDATIONS_NIV_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.ERROR.TYPE,
              error,
            }),
          },
        },
        PLATES: {
          REQUEST: {
            TYPE: "FLEET_DATA_VALIDATIONS_PLATES_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_DATA_VALIDATIONS_PLATES_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_DATA_VALIDATIONS_PLATES_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.ERROR.TYPE,
              error,
            }),
          },
        },
        GPS_IDENTIFIER: {
          REQUEST: {
            TYPE: "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_DATA_VALIDATIONS_GPS_IDENTIFIER_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.ERROR.TYPE,
              error,
            }),
          },
        },
        ENGINE_NUMBER: {
          REQUEST: {
            TYPE: "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_DATA_VALIDATIONS_ENGINE_NUMBER_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.ERROR.TYPE,
              error,
            }),
          },
        },
      },
    },

    SEARCH_BY_TEXT: {
      TYPE: "FLEET_DATA_PUT_REQUEST",
      ACTION: (searchStr) => ({
        type: ACTIONS.FLEETS.SEARCH_BY_TEXT.TYPE,
        searchStr,
      }),
    },

    OPERATOR: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      PUT: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_PUT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR.PUT.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_PUT_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR.PUT.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_PUT_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR.PUT.ERROR.TYPE,
            error,
          }),
        },
      },
      GET_BY_LICENSE: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_BY_LICENSE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_BY_LICENSE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_BY_LICENSE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.ERROR.TYPE,
            error,
          }),
        },
      },
      UPDATE_NORMATIVITY_STATUS: {
        REQUEST: {
          TYPE: "ACTIONS_FLEETS_OPERATOR_UPDATE_NORMATIVITY_STATUS_REQUEST",
          ACTION: () => ({
            type:
              ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.REQUEST.TYPE,
          }),
        },
        ERROR: {
          TYPE: "ACTIONS_FLEETS_OPERATOR_UPDATE_NORMATIVITY_STATUS_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.ERROR.TYPE,
            error,
          }),
        },
        SUCCESS: {
          TYPE: "ACTIONS_FLEETS_OPERATOR_UPDATE_NORMATIVITY_STATUS_SUCCESS",
          ACTION: (response) => ({
            type:
              ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.SUCCESS.TYPE,
            response,
          }),
        },
      },
      /*TAG: {
        GET: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_TAG_GET_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.GET.REQUEST.TYPE
            })
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_TAG_GET_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.GET.SUCCESS.TYPE,
              response
            })
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_TAG_GET_ERROR",
            ACTION: error => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.GET.ERROR.TYPE,
              error
            })
          }
        },
        POST: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_TAG_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.POST.REQUEST.TYPE
            })
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_TAG_POST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.POST.SUCCESS.TYPE,
              response
            })
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_TAG_POST_ERROR",
            ACTION: error => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.POST.ERROR.TYPE,
              error
            })
          }
        },
        PUT: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_TAG_PUT_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.PUT.REQUEST.TYPE
            })
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_TAG_PUT_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.PUT.SUCCESS.TYPE,
              response
            })
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_TAG_PUT_ERROR",
            ACTION: error => ({
              type: ACTIONS.FLEETS.OPERATOR_TAG.PUT.ERROR.TYPE,
              error
            })
          }
        }
      }*/
      VALIDATIONS: {
        ID: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_ID_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_ID_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_ID_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.ERROR.TYPE,
              error,
            }),
          },
        },
        LICENSE: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_LICENSE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_LICENSE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_LICENSE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.ERROR.TYPE,
              error,
            }),
          },
        },
        APTITUDE: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_APTITUDE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_APTITUDE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_APTITUDE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.ERROR.TYPE,
              error,
            }),
          },
        },
        SECURITY: {
          REQUEST: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_SECURITY_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_SECURITY_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_OPERATOR_VALIDATIONS_SECURITY_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.ERROR.TYPE,
              error,
            }),
          },
        },
      },
    },

    FLEET_CLASS: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_FLEET_CLASS_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.FLEET_CLASS.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_FLEET_CLASS_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.FLEET_CLASS.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_FLEET_CLASS_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.FLEET_CLASS.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    EQUIPMENT_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    EQUIPMENT_SUSPENSION_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_SUSPENSION_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_SUSPENSION_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_SUSPENSION_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    EQUIPMENT: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      LIST: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      PUT: {
        REQUEST: {
          TYPE: "FLEET_EQUIPMENT_PUT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EQUIPMENT.PUT.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EQUIPMENT_PUT_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.PUT.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EQUIPMENT_PUT_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EQUIPMENT.PUT.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    VEHICLE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_VEHICLE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.VEHICLE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_VEHICLE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.VEHICLE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_VEHICLE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.VEHICLE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      LIST: {
        REQUEST: {
          TYPE: "FLEET_DATA_VEHICLE_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.VEHICLE.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DATA_VEHICLE_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.VEHICLE.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DATA_VEHICLE_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.VEHICLE.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    OPERATOR_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    SANCTIONS: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_SANCTIONS_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SANCTIONS.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SANCTIONS_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SANCTIONS.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SANCTIONS_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SANCTIONS.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_SANCTIONS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SANCTIONS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SANCTIONS_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SANCTIONS.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SANCTIONS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SANCTIONS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      PATCH: {
        REQUEST: {
          TYPE: "FLEET_SANCTIONS_PATCH_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SANCTIONS.PATCH.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SANCTIONS_PATCH_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SANCTIONS.PATCH.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SANCTIONS_PATCH_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SANCTIONS.PATCH.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE: {
        REQUEST: {
          TYPE: "FLEET_SANCTIONS_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.SANCTIONS.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_SANCTIONS_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.SANCTIONS.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_SANCTIONS_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.SANCTIONS.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    EXPEDITION_CENTER: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_EXPEDITION_CENTER_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.EXPEDITION_CENTER.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_EXPEDITION_CENTER_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.EXPEDITION_CENTER.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_EXPEDITION_CENTER_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.EXPEDITION_CENTER.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    COMPANY_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_COMPANY_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.COMPANY_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_COMPANY_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.COMPANY_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_COMPANY_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.COMPANY_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    VEHICLE_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_VEHICLE_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_VEHICLE_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_VEHICLE_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },

      POST: {
        REQUEST: {
          TYPE: "FLEET_VEHICLE_TYPE_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_VEHICLE_TYPE_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_VEHICLE_TYPE_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      PUT: {
        REQUEST: {
          TYPE: "FLEET_VEHICLE_TYPE_PUT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.PUT.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_VEHICLE_TYPE_PUT_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.PUT.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_VEHICLE_TYPE_PUT_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.VEHICLE_TYPE.PUT.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    OPERATOR_TAG: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_TAG_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_TAG.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_TAG_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR_TAG.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_TAG_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_TAG.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    OPERATOR_NORMATIVITY_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    OPERATOR_NORMATIVITY: {
      POST: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      STATUS: {
        REQUEST: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_STATUS_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_STATUS_SUCCESS",
          ACTION: (data) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.SUCCESS.TYPE,
            data,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATOR_NORMATIVITY_STATUS_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    NORMATIVITY_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    NORMATIVITY_STATUS: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_STATUS_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_STATUS_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_STATUS_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    OPERATE_TYPE: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_OPERATE_TYPE_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.OPERATE_TYPE.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_OPERATE_TYPE_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.OPERATE_TYPE.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_OPERATE_TYPE_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.OPERATE_TYPE.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    FLEET_PROVIDER: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_FLEET_PROVIDER_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.FLEET_PROVIDER.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_FLEET_PROVIDER_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.FLEET_PROVIDER.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_FLEET_PROVIDER_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.FLEET_PROVIDER.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    CATALOG: {
      VEHICLE: {
        GET: {
          REQUEST: {
            TYPE: "FLEET_CATALOG_VEHICLE_GET_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.CATALOG.VEHICLE.GET.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_CATALOG_VEHICLE_GET_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.CATALOG.VEHICLE.GET.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_CATALOG_VEHICLE_GET_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.CATALOG.VEHICLE.GET.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      EQUIPMENT: {
        GET: {
          REQUEST: {
            TYPE: "FLEET_CATALOG_EQUIPMENT_GET_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_CATALOG_EQUIPMENT_GET_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_CATALOG_EQUIPMENT_GET_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      OPERATOR: {
        GET: {
          REQUEST: {
            TYPE: "FLEET_CATALOG_OPERATOR_GET_REQUEST",
            ACTION: () => ({
              type: ACTIONS.FLEETS.CATALOG.OPERATOR.GET.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "FLEET_CATALOG_OPERATOR_GET_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.FLEETS.CATALOG.OPERATOR.GET.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "FLEET_CATALOG_OPERATOR_GET_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.FLEETS.CATALOG.OPERATOR.GET.ERROR.TYPE,
              error,
            }),
          },
        },
      },
      STATUS_LOG: {
        REQUEST: {
          TYPE: "FLEET_CATALOG_STATUS_LOG_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.CATALOG.STATUS_LOG.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_CATALOG_STATUS_LOG_SUCCESS",
          ACTION: (response, typeId) => ({
            type: ACTIONS.FLEETS.CATALOG.STATUS_LOG.SUCCESS.TYPE,
            response,
            typeId,
          }),
        },
        ERROR: {
          TYPE: "FLEET_CATALOG_STATUS_LOG_REQUEST",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.CATALOG.STATUS_LOG.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    GPS_PROVIDER: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_GPS_PROVIDER_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_GPS_PROVIDER_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_GPS_PROVIDER_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_GPS_PROVIDER_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_GPS_PROVIDER_POST_SUCCESS",
          ACTION: (data) => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.POST.SUCCESS.TYPE,
            data,
          }),
        },
        ERROR: {
          TYPE: "FLEET_GPS_PROVIDER_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.GPS_PROVIDER.POST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    HINTS: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_HINTS_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.HINTS.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_HINTS_GET_SUCCESS",
          ACTION: (data) => ({
            type: ACTIONS.FLEETS.HINTS.GET.SUCCESS.TYPE,
            data,
          }),
        },
        ERROR: {
          TYPE: "FLEET_HINTS_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.HINTS.GET.ERROR.TYPE,
            error,
          }),
        },
      },

      POST: {
        REQUEST: {
          TYPE: "FLEET_HINTS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.HINTS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_HINTS_POST_SUCCESS",
          ACTION: (data) => ({
            type: ACTIONS.FLEETS.HINTS.POST.SUCCESS.TYPE,
            data,
          }),
        },
        ERROR: {
          TYPE: "FLEET_HINTS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.HINTS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    TAG: {
      DELETE: {
        REQUEST: {
          TYPE: "FLEET_TAG_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.TAG.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_TAG_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.TAG.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_TAG_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.TAG.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_TAG_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.TAG.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_TAG_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.TAG.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_TAG_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.TAG.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      LIST: {
        REQUEST: {
          TYPE: "FLEET_TAG_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.TAG.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_TAG_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.TAG.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_TAG_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.TAG.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      GET: {
        REQUEST: {
          TYPE: "FLEET_TAG_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.TAG.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_TAG_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.TAG.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_TAG_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.TAG.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    NORMATIVITY: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      PUT: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_PUT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PUT.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_PUT_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PUT.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_PUT_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PUT.ERROR.TYPE,
            error,
          }),
        },
      },
      PATCH: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_PATCH_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PATCH.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_PATCH_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PATCH.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_PATCH_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.TYPE,
            error,
          }),
        },
      },
      LIST: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      IMAGE: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_IMAGE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_IMAGE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.IMAGE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_IMAGE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.IMAGE.ERROR.TYPE,
            error,
          }),
        },
      },
      GET_IMAGE: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_GET_IMAGE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_GET_IMAGE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_GET_IMAGE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE_IMAGE: {
        REQUEST: {
          TYPE: "FLEET_NORMATIVITY_DELETE_IMAGE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_NORMATIVITY_DELETE_IMAGE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_NORMATIVITY_DELETE_IMAGE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    DRIVE_AXLE_SUSPENSION: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_DRIVE_AXLE_SUSPENSION_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_DRIVE_AXLE_SUSPENSION_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_DRIVE_AXLE_SUSPENSION_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    STEERING_AXLE_SUSPENSION: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_STEERING_AXLE_SUSPENSION_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_STEERING_AXLE_SUSPENSION_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_STEERING_AXLE_SUSPENSION_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },
    LOAD_SHAFT_SUSPENSION: {
      GET: {
        REQUEST: {
          TYPE: "FLEET_LOAD_SHAFT_SUSPENSION_GET_REQUEST",
          ACTION: () => ({
            type: ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "FLEET_LOAD_SHAFT_SUSPENSION_GET_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "FLEET_LOAD_SHAFT_SUSPENSION_GET_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.ERROR.TYPE,
            error,
          }),
        },
      },
    },
  },
};

const REQUEST_SUMMARY_FLEET = "REQUEST_SUMMARY_FLEET";
const RESPONSE_SUMMARY_FLEET = "RESPONSE_SUMMARY_FLEET";
const ERROR_SUMMARY_FLEET = "ERROR_SUMMARY_FLEET";

const SEARCH_FLEETS_BY_NAME = "SEARCH_FLEETS_BY_NAME";

export const requestSummaryFleet = () => ({
  type: REQUEST_SUMMARY_FLEET,
});
export const errorSummaryFleet = (error) => ({
  type: ERROR_SUMMARY_FLEET,
  error: error,
});
export const responseSummaryFleet = (json) => ({
  type: RESPONSE_SUMMARY_FLEET,
  data: json,
});

export const searchFleetsByName = (name) => ({
  type: SEARCH_FLEETS_BY_NAME,
  payload: { name: name },
});

const initialState = {
  fleets: {
    isFetching: false,
    error: "",
    data: [],
  },
  data: {
    isFetching: false,
    error: "",
    data: {},
  },
  fleetClass: {
    isFetching: false,
    error: "",
    data: [],
  },
  equipmentType: {
    isFetching: false,
    error: "",
    data: [],
  },
  equipmentSuspensionType: {
    isFetching: false,
    error: "",
    data: [],
  },
  equipment: {
    isFetching: false,
    error: "",
    data: [],
  },
  createEquipment: {
    isFetching: false,
    error: "",
    data: [],
  },
  updateEquipment: {
    isFetching: false,
    error: "",
    data: [],
  },
  operatorType: {
    isFetching: false,
    error: "",
    data: [],
  },
  sanctionsCatalog: {
    isFetching: false,
    error: "",
    data: [],
  },
  sanctions: {
    isFetching: false,
    error: "",
    data: [],
  },
  operatorData: {
    isFetching: false,
    error: "",
    data: [],
  },
  vehicleType: {
    isFetching: false,
    error: "",
    data: [],
  },
  operatorByLicense: {
    isFetching: false,
    error: "",
    data: [],
  },
  operatorNormativityType: {
    isFetching: false,
    error: "",
    data: {},
  },
  normativityType: {
    isFetching: false,
    error: "",
    data: [],
  },
  normativityStatus: {
    isFetching: false,
    error: "",
    data: [],
  },
  operateType: {
    isFetching: false,
    error: "",
    data: [],
  },
  fleetProvider: {
    isFetching: false,
    error: "",
    data: [],
  },
  gpsProvider: {
    isFetching: false,
    error: "",
    data: [],
  },
  updateFleetData: {
    isFetching: false,
    error: "",
    data: {},
  },
  fleetData: {
    isFetching: false,
    error: "",
    data: {},
  },
  validate: {
    isFetching: false,
    error: "",
    data: {},
  },
  vehicleData: {
    isFetching: false,
    error: "",
    data: {},
  },
  vehicleCatalog: {
    isFetching: false,
    error: "",
    data: [],
  },
  equipmentCatalog: {
    isFetching: false,
    error: "",
    data: [],
  },
  operatorCatalog: {
    isFetching: false,
    error: "",
    data: [],
  },
  normativityData: {
    isFetching: false,
    error: "",
    data: [],
  },
  hints: {
    isFetching: false,
    error: "",
    data: [],
  },
  driveAxleSuspension: {
    isFetching: false,
    error: "",
    data: [],
  },
  steeringAxleSuspension: {
    isFetching: false,
    error: "",
    data: [],
  },
  vehicles: {
    isFetching: false,
    error: "",
    data: [],
  },
  tags: {
    isFetching: false,
    error: "",
    data: [],
  },
  normativities: {
    isFetching: false,
    error: "",
    data: [],
  },
  normativityImages: {
    isFetching: false,
    error: "",
    data: [],
  },
  summary: {
    isFetching: false,
    error: "",
    data: [],
  },
  vehicle: {
    isFetching: false,
    error: "",
    data: [],
  },

  dataTags: {
    isFetching: false,
    error: {},
    data: [],
  },
  loadShaftSuspensionTypes: {
    isFetching: false,
    error: {},
    data: [],
  },
  shares: {
    isFetching: false,
    error: "",
    data: [],
  },
  sharesFleets: {
    isFetching: false,
    error: "",
    data: null,
  },
  normativityStatusLog: {
    isFetching: false,
    error: "",
    data: [],
  },
  searchStr: "",
  companyType: {
    isFetching: false,
    error: "",
    data: [],
  },
  catalogStatusLog: {
    isFetching: false,
    error: {},
    data: {},
  },
  expeditionCenter: {
    isFetching: false,
    error: "",
    data: [],
  },
};

export default function fleetReducer(state = initialState, action) {
  let catalogStatusLog = cloneDeep(state.catalogStatusLog);

  switch (action.type) {
    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.REQUEST.TYPE: {
      return Object.assign({}, state, {
        normativityStatusLog: {
          isFetching: true,
          data: [],
          error: "",
        },
      });
    }

    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.SUCCESS.TYPE: {
      return Object.assign({}, state, {
        normativityStatusLog: {
          isFetching: false,
          data: action.data,
          error: "",
        },
      });
    }

    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.ERROR.TYPE: {
      return Object.assign({}, state, {
        normativityStatusLog: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });
    }

    case ACTIONS.FLEETS.SHARES.POST.REQUEST: {
      return Object.assign({}, state, {
        sharesFleets: {
          isFetching: true,
          data: null,
          error: "",
        },
      });
    }

    case ACTIONS.FLEETS.SHARES.POST.SUCCESS: {
      return Object.assign({}, state, {
        sharesFleets: {
          isFetching: false,
          data: action.response,
          error: "",
        },
      });
    }

    case ACTIONS.FLEETS.SHARES.POST.ERROR: {
      return Object.assign({}, state, {
        sharesFleets: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      });
    }

    case SEARCH_FLEETS_BY_NAME: {
      const name = action.payload.name.toLowerCase();
      let vehicles = state.vehicleCatalog.originalResponse.results.filter(
        (f) => {
          return (
            f.economic_number.toLowerCase().includes(name) ||
            f.licence.toLowerCase().includes(name) ||
            f.operator_name.toLowerCase().includes(name)
          );
        }
      );
      let equipment = state.equipmentCatalog.originalResponse.results.filter(
        (f) => {
          return (
            f.economic_number.toLowerCase().includes(name) ||
            f.licence.toLowerCase().includes(name) ||
            f.operator_name.toLowerCase().includes(name)
          );
        }
      );
      let operator = state.operatorCatalog.originalResponse.results.filter(
        (f) => {
          return (
            f.economic_number.toLowerCase().includes(name) ||
            f.licence.toLowerCase().includes(name) ||
            f.operator_name.toLowerCase().includes(name)
          );
        }
      );
      // debugger;
      return Object.assign({}, state, {
        vehicleCatalog: {
          isFetching: false,
          response: {
            count: state.vehicleCatalog.originalResponse.count,
            results: vehicles,
          },
          originalResponse: state.vehicleCatalog.originalResponse,
        },
        operatorCatalog: {
          isFetching: false,
          response: {
            count: state.operatorCatalog.originalResponse.count,
            results: operator,
          },
          originalResponse: state.operatorCatalog.originalResponse,
        },
        equipmentCatalog: {
          isFetching: false,
          response: {
            count: state.equipmentCatalog.originalResponse.count,
            results: equipment,
          },
          originalResponse: state.equipmentCatalog.originalResponse,
        },
        // searchStr: action.payload.name
      });
    }
    case ACTIONS.FLEETS.SHARES.GET.ERROR.TYPE:
      return state;
    case ACTIONS.FLEETS.SHARES.GET.SUCCESS.TYPE:
      return state;
    case ACTIONS.FLEETS.SHARES.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        shares: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.SEARCH_BY_TEXT.TYPE: {
      return Object.assign({}, state, {
        searchStr: action.searchStr,
      });
    }
    case ACTIONS.FLEETS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        fleets: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorByLicense: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorByLicense: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorByLicense: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.CATALOG.VEHICLE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        vehicleCatalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.CATALOG.VEHICLE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        vehicleCatalog: {
          isFetching: false,
          response: action.response,
          originalResponse: action.response,
        },
      });
    case ACTIONS.FLEETS.CATALOG.VEHICLE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        vehicleCatalog: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        equipmentCatalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        equipmentCatalog: {
          isFetching: false,
          response: action.response,
          originalResponse: action.response,
        },
      });
    case ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        equipmentCatalog: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.CATALOG.OPERATOR.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorCatalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.CATALOG.OPERATOR.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorCatalog: {
          isFetching: false,
          response: action.response,
          originalResponse: action.response,
        },
      });
    case ACTIONS.FLEETS.CATALOG.OPERATOR.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorCatalog: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.DATA.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        data: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        data: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        data: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleetsData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleetsData: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        fleetsData: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.DATA.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        fleetData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.REQUEST.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.SUCCESS.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.ERROR.TYPE:
      return Object.assign({}, state, {
        validate: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.FLEET_CLASS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleetClass: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.FLEET_CLASS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleetClass: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.FLEET_CLASS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        fleetClass: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        equipmentType: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        equipmentType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        equipmentType: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        equipmentSuspensionType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        equipmentSuspensionType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        equipmentSuspensionType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.LIST.REQUEST.TYPE:
    case ACTIONS.FLEETS.EQUIPMENT.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        equipment: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.LIST.SUCCESS.TYPE:
    case ACTIONS.FLEETS.EQUIPMENT.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        equipment: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.LIST.ERROR.TYPE:
    case ACTIONS.FLEETS.EQUIPMENT.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        equipment: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        createEquipment: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        createEquipment: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        createEquipment: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        updateEquipment: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        updateEquipment: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EQUIPMENT.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        updateEquipment: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        sanctionsCatalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        sanctionsCatalog: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        sanctionsCatalog: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.POST.REQUEST.TYPE:
    case ACTIONS.FLEETS.SANCTIONS.PATCH.REQUEST.TYPE:
      return Object.assign({}, state, {
        sanctions: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.POST.SUCCESS.TYPE:
    case ACTIONS.FLEETS.SANCTIONS.PATCH.SUCCESS.TYPE:
      return Object.assign({}, state, {
        sanctions: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.POST.ERROR.TYPE:
    case ACTIONS.FLEETS.SANCTIONS.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        sanctions: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.SANCTIONS.DELETE.REQUEST.TYPE:
    case ACTIONS.FLEETS.SANCTIONS.DELETE.SUCCESS.TYPE:
    case ACTIONS.FLEETS.SANCTIONS.DELETE.ERROR.TYPE:
      return state;
    case ACTIONS.FLEETS.COMPANY_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        companyType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.COMPANY_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        companyType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.COMPANY_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        companyType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        vehicleType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        vehicleType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        vehicleType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        operatorNormativityType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operatorNormativityType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        operatorNormativityType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.PUT.REQUEST.TYPE:
    case ACTIONS.FLEETS.VEHICLE_TYPE.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        vehicleData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.PUT.SUCCESS.TYPE:
    case ACTIONS.FLEETS.VEHICLE_TYPE.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        vehicleData: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.VEHICLE_TYPE.PUT.ERROR.TYPE:
    case ACTIONS.FLEETS.VEHICLE_TYPE.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        vehicleData: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.VEHICLE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        vehicles: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.VEHICLE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        vehicles: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.VEHICLE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        vehicles: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativityType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        normativityType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        normativityType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativityStatus: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        normativityStatus: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        normativityStatus: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.OPERATE_TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        operateType: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.OPERATE_TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        operateType: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.OPERATE_TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        operateType: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.FLEET_PROVIDER.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        fleetProvider: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.FLEET_PROVIDER.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        fleetProvider: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.FLEET_PROVIDER.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        fleetProvider: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.PUT.REQUEST.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.PATCH.REQUEST.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativities: {
          isFetching: true,
          data: state.normativities.data,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.PUT.SUCCESS.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.PATCH.SUCCESS.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.POST.SUCCESS.TYPE:
      let f = Array.isArray(state.normativities.data)
        ? state.normativities.data.filter((d) => d.id !== action.response.id)
        : [];
      f.push(action.response);
      return Object.assign({}, state, {
        normativities: {
          isFetching: false,
          data: f,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.PUT.ERROR.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.POST.ERROR.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        normativities: {
          isFetching: false,
          error: action.error,
        },
      });
    // return Object.assign({}, state, {
    //   normativityData: {
    //     isFetching: true
    //   }
    // });
    // return Object.assign({}, state, {
    //   normativityData: {
    //     isFetching: false,
    //     data: action.response
    //   }
    // });
    // return Object.assign({}, state, {
    //   normativityData: {
    //     isFetching: false,
    //     error: action.error
    //   }
    // });
    case ACTIONS.FLEETS.NORMATIVITY.LIST.REQUEST.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativities: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.LIST.SUCCESS.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        normativities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.TYPE:
    case ACTIONS.FLEETS.NORMATIVITY.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        normativities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.IMAGE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.IMAGE.ERROR.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.REQUEST.TYPE:
      // case ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.ERROR.TYPE:
      return Object.assign({}, state, {
        normativityImages: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.REQUEST.TYPE:
      return state;

    case ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.SUCCESS.TYPE:
      return state;

    case ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.ERROR.TYPE:
      return state;

    case ACTIONS.FLEETS.GPS_PROVIDER.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        gpsProvider: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.GPS_PROVIDER.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gpsProvider: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.GPS_PROVIDER.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        gpsProvider: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.GPS_PROVIDER.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        gps: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.GPS_PROVIDER.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gps: {
          isFetching: false,
          data: action.data,
        },
      });
    case ACTIONS.FLEETS.GPS_PROVIDER.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        gps: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.UPDATE.REQUEST.TYPE:
      return Object.assign({}, state, {
        updateFleetData: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DATA.UPDATE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        updateFleetData: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DATA.UPDATE.ERROR.TYPE:
      return Object.assign({}, state, {
        updateFleetData: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.HINTS.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.HINTS.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: false,
          data: action.data,
        },
      });
    case ACTIONS.FLEETS.HINTS.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.HINTS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.HINTS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: false,
          data: action.data,
        },
      });
    case ACTIONS.FLEETS.HINTS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        hints: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.TAG.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        // data: {
        //   isFetching: true
        // }
      });
    case ACTIONS.FLEETS.TAG.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        // data: {
        //   isFetching: false,
        //   data: action.response
        // }
      });
    case ACTIONS.FLEETS.TAG.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        // data: {
        //   isFetching: false,
        //   error: action.error
        // }
      });
    case ACTIONS.FLEETS.TAG.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        dataTags: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.TAG.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        dataTags: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.TAG.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        dataTags: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.TAG.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        tags: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.TAG.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        tags: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.TAG.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        tags: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        driveAxleSuspension: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        driveAxleSuspension: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        driveAxleSuspension: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        steeringAxleSuspension: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        steeringAxleSuspension: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        steeringAxleSuspension: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        loadShaftSuspensionTypes: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        loadShaftSuspensionTypes: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        loadShaftSuspensionTypes: {
          isFetching: false,
          error: action.error,
        },
      });
    case REQUEST_SUMMARY_FLEET:
      return Object.assign({}, state, {
        summary: {
          isFetching: true,
        },
      });

    case RESPONSE_SUMMARY_FLEET:
      return Object.assign({}, state, {
        summary: {
          isFetching: false,
          data: action.data,
        },
      });

    case ERROR_SUMMARY_FLEET:
      return Object.assign({}, state, {
        summary: {
          isFetching: false,
          error: action.error,
        },
      });

    // case ACTIONS.FLEETS.VEHICLE.GET.REQUEST.TYPE:
    //   return Object.assign({}, state, {
    //     vehicle: {
    //       isFetching: true
    //     }
    //   });
    // case ACTIONS.FLEETS.VEHICLE.GET.SUCCESS.TYPE:
    //   return Object.assign({}, state, {
    //     vehicle: {
    //       isFetching: false,
    //       data: action.response
    //     }
    //   });
    // case ACTIONS.FLEETS.VEHICLE.GET.ERROR.TYPE:
    //   return Object.assign({}, state, {
    //     vehicle: {
    //       isFetching: false,
    //       error: action.error
    //     }
    //   });

    case ACTIONS.FLEETS.VEHICLE.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        vehicle: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.VEHICLE.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        vehicle: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.VEHICLE.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        vehicle: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.REQUEST:
    case ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.ERROR:
    case ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.SUCCESS:
    case ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.REQUEST:
    case ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.ERROR:
    case ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.SUCCESS:
      return state;

    case ACTIONS.FLEETS.CATALOG.STATUS_LOG.REQUEST.TYPE:
      catalogStatusLog.isFetching = true;
      state.catalogStatusLog = catalogStatusLog;
      return state;
    case ACTIONS.FLEETS.CATALOG.STATUS_LOG.ERROR.TYPE:
      catalogStatusLog.isFetching = false;
      catalogStatusLog.error = action.error;
      state.catalogStatusLog = catalogStatusLog;
      return state;
    case ACTIONS.FLEETS.CATALOG.STATUS_LOG.SUCCESS.TYPE:
      catalogStatusLog.isFetching = false;
      catalogStatusLog.data[action.typeId] = action.response;
      state.catalogStatusLog = catalogStatusLog;
      return state;

    case ACTIONS.FLEETS.EXPEDITION_CENTER.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        expeditionCenter: {
          isFetching: true,
        },
      });
    case ACTIONS.FLEETS.EXPEDITION_CENTER.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        expeditionCenter: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FLEETS.EXPEDITION_CENTER.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        expeditionCenter: {
          isFetching: false,
          error: action.error,
        },
      });

    default:
      return state;
  }
}

export const createFleet = (code, company) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FLEETS.POST, { code, company })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.POST.SUCCESS.ACTION(response))
      );
  };
};

export const setFleetSearchStr = (searchStr) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SEARCH_BY_TEXT.ACTION(searchStr));
  };
};

export const loadFleets = () => {
  let company = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.GET, { company })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.GET.SUCCESS.ACTION(response))
      );
  };
};

export const loadFleetVehicleCatalog = (
  companyId,
  typeId = 1,
  maxResults = 10,
  page = 1,
  filters = {}
) => {
  let company = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters)
    );
    dispatch(ACTIONS.FLEETS.CATALOG.VEHICLE.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.CATALOG, {
      company_id: company,
      type_id: typeId,
      limit: maxResults,
      page: page,
      tags: filters.tags || "",
      status: filters.status || "",
      companies: filters.companies || "",
      type: filters.type || "",
      gps: filters.gps || "",
      text: filters.text || "",
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.CATALOG.VEHICLE.GET.ERROR.ACTION(error))
      )
      .then((response) => {
        dispatch(ACTIONS.FLEETS.CATALOG.VEHICLE.GET.SUCCESS.ACTION(response));
        // loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters);
      });
  };
};

export const loadFleetOperatorCatalog = (
  companyId,
  typeId = 3,
  maxResults = 10,
  page = 1,
  filters = {}
) => {
  let company = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters)
    );
    dispatch(ACTIONS.FLEETS.CATALOG.OPERATOR.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.CATALOG, {
      company_id: company,
      type_id: typeId,
      limit: maxResults,
      page: page,
      tags: filters.tags || "",
      status: filters.status || "",
      companies: filters.companies || "",
      type: filters.type || "",
      gps: filters.gps || "",
      text: filters.text || "",
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.CATALOG.OPERATOR.GET.ERROR.ACTION(error))
      )
      .then((response) => {
        dispatch(ACTIONS.FLEETS.CATALOG.OPERATOR.GET.SUCCESS.ACTION(response));
        // loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters);
      });
  };
};

export const loadFleetEquipmentCatalog = (
  companyId,
  typeId = 2,
  maxResults = 10,
  page = 1,
  filters = {}
) => {
  let company = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters)
    );
    dispatch(ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.CATALOG, {
      company_id: company,
      type_id: typeId,
      limit: maxResults,
      page: page,
      tags: filters.tags || "",
      status: filters.status || "",
      companies: filters.companies || "",
      type: filters.type || "",
      gps: filters.gps || "",
      text: filters.text || "",
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.ERROR.ACTION(error))
      )
      .then((response) => {
        dispatch(ACTIONS.FLEETS.CATALOG.EQUIPMENT.GET.SUCCESS.ACTION(response));
        // loadFleetCatalogStatusLog(company, typeId, maxResults, page, filters);
      });
  };
};

const loadFleetCatalogStatusLog = (
  company_id,
  type_id,
  limit,
  page,
  filters = {}
) => {
  const {
    tags = "",
    status = "",
    companies = "",
    type = "",
    gps = "",
    text = "",
  } = filters;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.CATALOG.STATUS_LOG.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.STATUS_LOG, {
      tags,
      status,
      companies,
      type,
      gps,
      company_id,
      type_id,
      limit,
      page,
      text,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.CATALOG.STATUS_LOG.ERROR.ACTION(error))
      )
      .then((response) => {
        dispatch(
          ACTIONS.FLEETS.CATALOG.STATUS_LOG.SUCCESS.ACTION(response, type_id)
        );
      });
  };
};

export const createFleetData = (fleetId, fleetDataObj) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.POST,
      Object.assign(fleetDataObj, { company_id: companyId, fleet_id: fleetId })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.DATA.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) => dispatch(ACTIONS.FLEETS.DATA.POST.ERROR.ACTION(e)))
      );
  };
};

export const updateFleetData = (fleet_id, id, fleetDataObj) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.PUT.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.PUT,
      Object.assign(fleetDataObj, { company_id, fleet_id, id })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.DATA.PUT.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.DATA.PUT.SUCCESS.ACTION(response))
      );
  };
};

export const loadFleetData = (fleetDataId, fleetId) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.GET, {
      company_id: companyId,
      fleet_id: fleetId,
      data_id: fleetDataId,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.DATA.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.DATA.GET.SUCCESS.ACTION(response))
      );
  };
};

export const loadShareFleetData = (fleetDataId, fleetId, type) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  const url =
    type === 0
      ? BACKEND.FLEETS.DATA.SHARE.VEHICLE
      : BACKEND.FLEETS.DATA.SHARE.EQUIPMENT;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.GET.REQUEST.ACTION());
    return getApiUrl(url, {
      company_id: companyId,
      fleet_id: fleetId,
      data_id: fleetDataId,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.DATA.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.DATA.GET.SUCCESS.ACTION(response))
      );
  };
};

export function postValidateEconomicNumber(fleet_id, economic_number) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VALIDATIONS.ECONOMIC, {
      company_id,
      fleet_id,
      economic_number,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.ECONOMIC.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateNiv(fleet_id, niv) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VALIDATIONS.NIV, {
      company_id,
      fleet_id,
      niv,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.NIV.ERROR.ACTION(e))
        )
      );
  };
}

export function validatePlates(fleet_id, plates) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VALIDATIONS.PLATE, {
      company_id,
      fleet_id,
      plates,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.PLATES.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateGpsIdentifier(fleet_id, gps_identifier) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VALIDATIONS.GPS, {
      company_id,
      fleet_id,
      gps_identifier,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(
            ACTIONS.FLEETS.DATA.VALIDATIONS.GPS_IDENTIFIER.ERROR.ACTION(e)
          )
        )
      );
  };
}

export function postValidateId(fleet_id, id) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.VALIDATIONS.ID, {
      company_id,
      fleet_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.ID.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateLicense(fleet_id, licensenum) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.VALIDATIONS.LICENSE, {
      company_id,
      fleet_id,
      licensenum,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.LICENSE.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateAptitude(fleet_id, aptitude) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.VALIDATIONS.APTITUDE, {
      company_id,
      fleet_id,
      aptitude,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.APTITUDE.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateSecurity(fleet_id, socialsecurity) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.VALIDATIONS.SECURITY, {
      company_id,
      fleet_id,
      socialsecurity,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.VALIDATIONS.SECURITY.ERROR.ACTION(e))
        )
      );
  };
}

export function postValidateEngineNumber(fleet_id, engine_number) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VALIDATIONS.ENGINE, {
      company_id,
      fleet_id,
      engine_number,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(
            ACTIONS.FLEETS.DATA.VALIDATIONS.ENGINE_NUMBER.ERROR.ACTION(e)
          )
        )
      );
  };
}

export const loadFleetClasses = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.fleetClass;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.FLEET_CLASS.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.FLEET_CLASS, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.FLEET_CLASS.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.FLEET_CLASS.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const loadEquipmentTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.equipmentType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.FLEETS.DATA.EQUIPMENT.GET_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.EQUIPMENT_TYPE.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const loadEquipmentSuspensionTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.equipmentSuspensionType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.FLEETS.DATA.EQUIPMENT.GET_SUSPENSION_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(
              ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.ERROR.ACTION(error)
            )
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.EQUIPMENT_SUSPENSION_TYPE.GET.SUCCESS.ACTION(
              response
            )
          )
        );
    }
  };
};

export const createEquipmentData = (fleetDataId, fleetId, fleetDataObj) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.EQUIPMENT.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.EQUIPMENT.POST,
      Object.assign(fleetDataObj, {
        fleet_data_id: fleetDataId,
        fleet_id: fleetId,
        company_id: companyId,
      })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.EQUIPMENT.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.EQUIPMENT.POST.ERROR.ACTION(e))
        )
      );
  };
};

export const loadEquipmentData = (fleetDataId, fleetId) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.EQUIPMENT.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.EQUIPMENT.GET, {
      fleet_data_id: fleetDataId,
      fleet_id: fleetId,
      company_id: companyId,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.EQUIPMENT.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.EQUIPMENT.GET.SUCCESS.ACTION(response))
      );
  };
};

export const listEquipments = (fleet_data_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.EQUIPMENT.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.EQUIPMENT.LIST, {
      fleet_data_id,
      fleet_id,
      company_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.EQUIPMENT.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.EQUIPMENT.LIST.SUCCESS.ACTION(response))
      );
  };
};

export const updateEquipmentData = (
  fleetDataId,
  fleetId,
  fleetDataObj,
  equipmentId
) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.EQUIPMENT.PUT.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.EQUIPMENT.PUT,
      Object.assign(fleetDataObj, {
        fleet_data_id: fleetDataId,
        fleet_id: fleetId,
        company_id: companyId,
        id: equipmentId,
      })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.EQUIPMENT.PUT.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.EQUIPMENT.PUT.SUCCESS.ACTION(response))
      );
  };
};

export const loadOperatorTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.operatorType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.OPERATOR_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.OPERATOR_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.OPERATOR_TYPE.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.OPERATOR_TYPE.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const loadSanctions = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.sanctionsCatalog;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.SANCTIONS.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.GET_SANCTIONS, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.SANCTIONS.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.SANCTIONS.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const setSanctions = (company_id, provider_id, operator_id, sanctionObj) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SANCTIONS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.SET_SANCTIONS,
      Object.assign(sanctionObj, { company_id, provider_id, operator_id })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.SANCTIONS.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SANCTIONS.POST.SUCCESS.ACTION(response))
      );
  };
};

export const patchSanctions = (company_id, provider_id, operator_id, sanction_id, sanctionObj) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SANCTIONS.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.COMPANY_CATALOG.DEL_SANCTIONS,
      Object.assign(sanctionObj, { company_id, provider_id, operator_id, sanction_id })
    )
      .then(
        (response) => response.json()
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SANCTIONS.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.SANCTIONS.PATCH.ERROR.ACTION(error))
      );
  };
};

export const deleteSanctions = (company_id, provider_id, operator_id, sanction_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SANCTIONS.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.COMPANY_CATALOG.DEL_SANCTIONS, {
      company_id,
      provider_id,
      operator_id,
      sanction_id,
    })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) => dispatch(ACTIONS.FLEETS.SANCTIONS.DELETE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SANCTIONS.DELETE.SUCCESS.ACTION(response))
      );
  };
};

export const loadVehicleTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.vehicleType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.VEHICLE_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const getOperatorByLicense = (fleet, license) => {
  return function (dispatch) {
    let companyId = store.getState().globalCatalog.session.company.id;
    // let storeItems = store.getState().fleetReducer.operatorByLicense;
    // if (!storeItems.isFetching) {
    //   return new Promise(resolve => resolve(storeItems))
    // } else {
    dispatch(ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.OPERATOR.GET_BY_LICENSE, {
      company_id: companyId,
      fleet_id: fleet,
      license: license,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.GET_BY_LICENSE.SUCCESS.ACTION(response)
        )
      );
    // }
  };
};

export const loadOperator = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.operatorData;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.OPERATOR.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.GET, {})
        .then(
          (response) => response.json(),
          (error) => dispatch(ACTIONS.FLEETS.OPERATOR.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const createOperator = (fleet_id, operatorObj) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.POST,
      Object.assign(operatorObj, { company_id, fleet_id })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.OPERATOR.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.POST.ERROR.ACTION(e))
        )
      );
  };
};

export const updateOperator = (id, fleet_id, operatorObj) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.POST.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.PUT,
      Object.assign(operatorObj, { id, company_id, fleet_id })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.OPERATOR.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR.POST.ERROR.ACTION(e))
        )
      );
  };
};

export const createVehicleData = (fleetId, fleet_data_id, vehicleDataObj) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.VEHICLE.POST,
      Object.assign(vehicleDataObj, {
        company_id: companyId,
        fleet_id: fleetId,
        fleet_data_id,
      })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.POST.ERROR.ACTION(e))
        )
      );
  };
};

export const loadVehicle = (fleet_id, fleet_data_id, id = "") => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VEHICLE.GET, {
      company_id: companyId,
      fleet_id,
      fleet_data_id,
      id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.VEHICLE.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE.GET.SUCCESS.ACTION(response))
      );
  };
};

export const listVehicles = (fleet_id, fleet_data_id) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VEHICLE.LIST, {
      company_id: companyId,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.VEHICLE.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE.LIST.SUCCESS.ACTION(response))
      );
  };
};

export const listShareVehicles = (fleet_id, fleet_data_id) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VEHICLE.LIST_SHARE, {
      company_id: companyId,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.VEHICLE.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE.LIST.SUCCESS.ACTION(response))
      );
  };
};

export const updateVehicleData = (
  fleet_id,
  fleet_data_id,
  id,
  vehicleDataObj
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.PUT.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.VEHICLE.PUT,
      Object.assign(vehicleDataObj, { company_id, fleet_id, fleet_data_id, id })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.PUT.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE_TYPE.PUT.SUCCESS.ACTION(response))
      );
  };
};

export const loadVehicles = (fleet_data_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.VEHICLE.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.VEHICLE.LIST, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.VEHICLE.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.VEHICLE.GET.SUCCESS.ACTION(response))
      );
  };
};

export const loadTagsForFleetData = (fleet_data_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.TAG.GET, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.TAG.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.GET.SUCCESS.ACTION(response))
      );
  };
};

// const NORMATIVITY_TYPE_VEHICLE = '1';
// const NORMATIVITY_TYPE_EQUIPMENT = '2';

export const loadOperatorNormativityTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.operatorNormativityType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.OPERATOR_NORMATIVITY_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(
              ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.ERROR.ACTION(error)
            )
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.OPERATOR_NORMATIVITY_TYPE.GET.SUCCESS.ACTION(
              response
            )
          )
        );
    }
  };
};

export const loadNormativityTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.normativityType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.NORMATIVITY_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY_TYPE.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const loadNormativityStatus = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.normativityStatus;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.NORMATIVITY_STATUS, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.NORMATIVITY_STATUS.GET.SUCCESS.ACTION(response)
          )
        );
    }
  };
};

export const loadOperateType = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.operateType;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.OPERATE_TYPE.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.OPERATE_TYPE, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.OPERATE_TYPE.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.OPERATE_TYPE.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const loadFleetProvider = () => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.fleetProvider;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.FLEET_PROVIDER.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.FLEET_PROVIDER, {
        company_id: companyId,
      })
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.FLEET_PROVIDER.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(ACTIONS.FLEETS.FLEET_PROVIDER.GET.SUCCESS.ACTION(response))
        );
    }
  };
};

export const createNormativityData = (
  fleetId,
  fleet_data_id,
  normativityDataObj
) => {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.NORMATIVITY.POST,
      Object.assign(normativityDataObj, {
        company_id: companyId,
        fleet_id: fleetId,
        fleet_data_id,
      })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.NORMATIVITY.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.POST.SUCCESS.ACTION(response))
      );
  };
};

export const createNormativityDataForOperator = (
  fleet_id,
  operator_id,
  normativityDataObj
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.POST,
      Object.assign(normativityDataObj, { company_id, fleet_id, operator_id })
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        error.then((e) =>
          dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.ERROR.ACTION(e))
        )
      );
  };
};

export const updateNormativityDataForOperator = (
  fleet_id,
  operator_id,
  normativityDataObj,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.PUT,
      Object.assign(normativityDataObj, {
        company_id,
        fleet_id,
        operator_id,
        id,
      })
    )
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.POST.SUCCESS.ACTION(response)
        )
      );
  };
};

export const loadOperatorNormativities = (fleet_id, operator_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.GET, {
      company_id,
      fleet_id,
      operator_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.ERROR.ACTION(error))
      );
  };
};

export const loadShareOperatorNormativities = (fleet_id, operator_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.SHARE, {
      company_id,
      fleet_id,
      operator_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.ERROR.ACTION(error))
      );
  };
};

export const loadNormativitiesStatus = (fleet_id, fleet_data_id, type) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let url = "";

  switch (type) {
    case 0:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.VEHICLE;
      break;
    case 1:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.EQUIPMENT;
      break;
    case 2:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.OPERATOR;
      break;
    case 3:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.OWN_OPERATOR;
      break;
    default:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.GET;
      break;
  }

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.REQUEST.ACTION());
    return getApiUrl(url, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.STATUS.ERROR.ACTION(error))
      );
  };
};

export const loadImageOperatorNormativities = (
  fleet_id,
  operator_id,
  normativity_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.IMAGE, {
      company_id,
      fleet_id,
      operator_id,
      normativity_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.OPERATOR_NORMATIVITY.GET.ERROR.ACTION(error))
      );
  };
};

export const loadOperatorTags = (fleet_id, operator_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.TAG.GET, {
      company_id,
      fleet_id,
      operator_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.GET.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.FLEETS.TAG.GET.ERROR.ACTION(error)));
  };
};

export const loadFleetDataNormativities = (fleet_id, fleet_data_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.NORMATIVITY.LIST, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.ACTION(error))
      );
  };
};

export const loadShareFleetDataNormativities = (
  fleet_id,
  fleet_data_id,
  type
) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  let url = "";

  switch (type) {
    case 0:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_VEHICLE_LIST;
      break;
    case 1:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_EQUIPMENT_LIST;
      break;
    default:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_OPERATOR_LIST;
      break;
  }

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.REQUEST.ACTION());
    return getApiUrl(url, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.LIST.ERROR.ACTION(error))
      );
  };
};

export const updateNormativityData = (
  fleet_id,
  fleet_data_id,
  id,
  normativityDataObj
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.PUT.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FLEETS.DATA.NORMATIVITY.PUT,
      Object.assign(normativityDataObj, {
        company_id,
        fleet_id,
        id,
        fleet_data_id,
      })
    )
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.PUT.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PUT.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PUT.ERROR.ACTION(error))
      );
  };
};

export const patchNormativityData = (
  fleet_id,
  fleet_data_id,
  id,
  normativityDataObj
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.REQUEST.ACTION());
    return patchApiUrl(
      BACKEND.FLEETS.DATA.NORMATIVITY.PATCH,
      Object.assign(normativityDataObj, {
        company_id,
        fleet_id,
        id,
        fleet_data_id,
      })
    )
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      );
  };
};

export const patchShareNormativityData = (
  fleet_id,
  fleet_data_id,
  id,
  normativityDataObj,
  type
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let url = "";

  switch (type) {
    case 0:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.PATCH_SHARE_VEHICLE;
      break;
    case 1:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.PATCH_SHARE_EQUIPMENT;
      break;
    default:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.PATCH_SHARE_OPERATOR;
      break;
  }

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.REQUEST.ACTION());
    return patchApiUrl(
      url,
      Object.assign(normativityDataObj, {
        company_id,
        fleet_id,
        id,
        fleet_data_id,
      })
    )
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      );
  };
};

export const patchNormativityOperatorData = (
  fleet_id,
  operator_id,
  id,
  normativityDataObj
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.REQUEST.ACTION());
    return patchApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.PUT,
      Object.assign(normativityDataObj, {
        company_id,
        fleet_id,
        id,
        operator_id,
      })
    )
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.PATCH.ERROR.ACTION(error))
      );
  };
};

export const loadNormativityForFleetData = (fleet_data_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.NORMATIVITY.GET, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.NORMATIVITY.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.GET.SUCCESS.ACTION(response))
      );
  };
};

export const loadNormativityImagesForFleetData = (
  fleet_data_id,
  fleet_id,
  normativity_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.NORMATIVITY.IMAGE, {
      company_id,
      fleet_id,
      fleet_data_id,
      normativity_id,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.SUCCESS.ACTION(response))
      );
  };
};

export const loadShareNormativityImagesForFleetData = (
  fleet_data_id,
  fleet_id,
  normativity_id,
  type
) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  let url = "";

  switch (type) {
    case 0:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_IMAGE_VEHICLE;
      break;
    case 1:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_IMAGE_EQUIPMENT;
      break;
    default:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.SHARE_IMAGE_OPERATOR;
      break;
  }

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.REQUEST.ACTION());
    return getApiUrl(url, {
      company_id,
      fleet_id,
      fleet_data_id,
      normativity_id,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.GET_IMAGE.SUCCESS.ACTION(response))
      );
  };
};

export const deleteNormativityImagesForFleetData = (
  fleet_data_id,
  fleet_id,
  normativity_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FLEETS.DATA.NORMATIVITY.DELETE_IMAGE, {
      company_id,
      fleet_id,
      fleet_data_id,
      normativity_id,
      id,
    })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.NORMATIVITY.DELETE_IMAGE.SUCCESS.ACTION(response)
        )
      );
  };
};

export const addTagToFleet = (fleet_id, fleet_data_id, tagDataObj) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.TAG.POST,
      Object.assign(tagDataObj, { company_id, fleet_id, fleet_data_id })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.TAG.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.POST.SUCCESS.ACTION(response))
      );
  };
};

export const deleteTagInFleetData = (fleet_data_id, fleet_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FLEETS.DATA.TAG.DELETE, {
      company_id,
      fleet_id,
      fleet_data_id,
      id,
    })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) => dispatch(ACTIONS.FLEETS.TAG.DELETE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.DELETE.SUCCESS.ACTION(response))
      );
  };
};

export const loadFleetDataTags = (fleet_id, fleet_data_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.TAG.LIST, {
      company_id,
      fleet_id,
      fleet_data_id,
    })
      .then(
        (response) => response.json()
        // error => dispatch(ACTIONS.FLEETS.TAG.LIST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.LIST.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.FLEETS.TAG.LIST.ERROR.ACTION(error)));
  };
};
// export const loadTagsForOperator = (fleet_id) => {
//   let company_id = store.getState().globalCatalog.session.company.id;

//   return function (dispatch) {
//     dispatch(ACTIONS.FLEETS.TAG.GET.REQUEST.ACTION());
//     return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.TAG.GET, { company_id, fleet_id })
//       .then(
//         response => response.json(),
//         error => dispatch(ACTIONS.FLEETS.TAG.GET.ERROR.ACTION(error))
//       )
//       .then(response =>
//         dispatch(ACTIONS.FLEETS.TAG.GET.SUCCESS.ACTION(response))
//       );
//   };
// };

export const addTagToOperator = (fleet_id, operator_id, tagDataObj) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FLEETS.DATA.OPERATOR.TAG.POST,
      Object.assign(tagDataObj, { company_id, fleet_id, operator_id })
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.TAG.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.POST.SUCCESS.ACTION(response))
      );
  };
};

export const deleteTagInOperador = (operator_id, fleet_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FLEETS.DATA.OPERATOR.TAG.DELETE, {
      company_id,
      fleet_id,
      operator_id,
      id,
    })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) => dispatch(ACTIONS.FLEETS.TAG.DELETE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.DELETE.SUCCESS.ACTION(response))
      );
  };
};

export const loadTagsForOperator = (operator_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.TAG.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.TAG.GET, {
      company_id,
      fleet_id,
      operator_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.TAG.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.TAG.GET.SUCCESS.ACTION(response))
      );
  };
};

// export const deleteTagInOperator = (fleet_data_id, fleet_id, id) => {
//   let company_id = store.getState().globalCatalog.session.company.id;
//   return function (dispatch) {
//     dispatch(ACTIONS.FLEETS.TAG.DELETE.REQUEST.ACTION());
//     return deleteApiUrl(BACKEND.FLEETS.DATA.TAG.DELETE, { company_id, fleet_id, fleet_data_id, id })
//       .then(
//         response =>  response ? { msg: 'ok' } : response.json(),
//         error => dispatch(ACTIONS.FLEETS.TAG.DELETE.ERROR.ACTION(error))
//       )
//       .then(response =>
//         dispatch(ACTIONS.FLEETS.TAG.DELETE.SUCCESS.ACTION(response))
//       );
//   };
// };

export function postUploadImage(fleet_id, fleet_data_id, normativity_id, file) {
  let company_id = store.getState().globalCatalog.session.company.id;

  const urlToUploadImage = interpolate(BACKEND.FLEETS.DATA.NORMATIVITY.IMAGE, {
    company_id,
    fleet_id,
    fleet_data_id,
    normativity_id,
  });

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.ACTION());

    let formData = new FormData();
    formData.append("url", file);
    formData.append("normativity", normativity_id);

    return postMultipartApiUrl(urlToUploadImage, formData)
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.ERROR.ACTION(error))
      )
      .then((data) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.SUCCESS.ACTION(data))
      );
  };
}

export function postUploadImageOperator(
  fleet_id,
  operator_id,
  normativity_id,
  file
) {
  let company_id = store.getState().globalCatalog.session.company.id;

  const urlToUploadImage = interpolate(
    BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.IMAGE,
    { company_id, fleet_id, operator_id, normativity_id }
  );

  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.ACTION());

    let formData = new FormData();
    formData.append("url", file);
    formData.append("normativity", normativity_id);

    return postMultipartApiUrl(urlToUploadImage, formData)
      .then(
        (response) => response.json(),
        (error) => {
          dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.ERROR.ACTION(error));
          return { error };
        }
      )
      .then((data) => {
        dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.SUCCESS.ACTION(data));
        if (data.error) {
          return data;
        }
      });
  };
}

export const deleteNormativityImagesForOperator = (
  fleet_id,
  operator_id,
  normativity_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.DELETE_IMAGE, {
      company_id,
      fleet_id,
      operator_id,
      normativity_id,
      id,
    })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) =>
          dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.ERROR.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.NORMATIVITY.IMAGE.SUCCESS.ACTION(response))
      );
  };
};

export const loadGpsProvider = () => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.GPS_PROVIDER.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY_CATALOG.GPS_PROVIDER, {})
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.GPS_PROVIDER.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.GPS_PROVIDER.GET.SUCCESS.ACTION(response))
      );
  };
};

export const createGPSProvider = (name, email, telephone) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.GPS_PROVIDER.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.GPS_PROVIDER, {
      name,
      email,
      telephone,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.GPS_PROVIDER.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.GPS_PROVIDER.POST.SUCCESS.ACTION(response))
      );
  };
};

export const loadHints = () => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.HINTS.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY_CATALOG.HINT, {})
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.HINTS.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.HINTS.GET.SUCCESS.ACTION(response))
      );
  };
};

export const createHint = (description, type) => {
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.HINTS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.HINT, { description, type })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.HINTS.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.HINTS.POST.SUCCESS.ACTION(response))
      );
  };
};

export const loadDriveAxleSuspension = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.driveAxleSuspension;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.DRIVE_AXLE_SUSPENSION, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(
              ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.ERROR.ACTION(error)
            )
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.SUCCESS.ACTION(response)
          )
        );
    }
  };
};

export const loadSteeringAxleSuspension = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.steeringAxleSuspension;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.STEERING_AXLE_SUSPENSION, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(
              ACTIONS.FLEETS.DRIVE_AXLE_SUSPENSION.GET.ERROR.ACTION(error)
            )
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.STEERING_AXLE_SUSPENSION.GET.SUCCESS.ACTION(response)
          )
        );
    }
  };
};

export const loadLoadShaftSuspensionTypes = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.loadShaftSuspensionTypes;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.LOAD_SHAFT_SUSPENSION, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(
              ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.ERROR.ACTION(error)
            )
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.LOAD_SHAFT_SUSPENSION.GET.SUCCESS.ACTION(response)
          )
        );
    }
  };
};

export const loadOperatorData = (operator_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.GET, {
      company_id,
      fleet_id,
      id: operator_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.OPERATOR.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.ACTION(response))
      );
  };
};

export const loadShareOperatorData = (operator_id, fleet_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.OPERATOR.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.SHARE.OPERATOR, {
      company_id,
      fleet_id,
      id: operator_id,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.OPERATOR.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.ACTION(response))
      );
  };
};

export const getNormativitiesChanges = (id) => {
  return function (dispatch) {
    return [];
    // return [
    //   {
    //     imageIcon: 'https://lh3.googleusercontent.com/proxy/A6XOZRIJRRrog5in-QonZ5wey_m7339gbWUU-fbVfOsa4XLgYNLvTdSmIKqtSyaEoeK93c6g7WX8eleF81wBZMjWa0UpF7HFvTb8cHGZ0t2MFM-Bn2sySrcj_gl-nNQDTFUcy7Q',
    //     companyName: 'Grupo modelo',
    //     status: 2
    //   },
    //   {
    //     imageIcon: 'https://lh3.googleusercontent.com/proxy/A6XOZRIJRRrog5in-QonZ5wey_m7339gbWUU-fbVfOsa4XLgYNLvTdSmIKqtSyaEoeK93c6g7WX8eleF81wBZMjWa0UpF7HFvTb8cHGZ0t2MFM-Bn2sySrcj_gl-nNQDTFUcy7Q',
    //     companyName: 'Stark inc',
    //     status: 4
    //   },
    //   {
    //     imageIcon: 'https://lh3.googleusercontent.com/proxy/A6XOZRIJRRrog5in-QonZ5wey_m7339gbWUU-fbVfOsa4XLgYNLvTdSmIKqtSyaEoeK93c6g7WX8eleF81wBZMjWa0UpF7HFvTb8cHGZ0t2MFM-Bn2sySrcj_gl-nNQDTFUcy7Q',
    //     companyName: 'Kugar',
    //     status: 1
    //   },
    //   {
    //     imageIcon: 'https://lh3.googleusercontent.com/proxy/A6XOZRIJRRrog5in-QonZ5wey_m7339gbWUU-fbVfOsa4XLgYNLvTdSmIKqtSyaEoeK93c6g7WX8eleF81wBZMjWa0UpF7HFvTb8cHGZ0t2MFM-Bn2sySrcj_gl-nNQDTFUcy7Q',
    //     companyName: 'Transporistas',
    //     status: 3
    //   }
    // ];
  };
};

export const getNormativityHistoryData = (fleet_id, fleet_data_id, type) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let url = "";

  switch (type) {
    case 0:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.VEHICLE;
      break;
    case 1:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.EQUIPMENT;
      break;
    default:
      url = BACKEND.FLEETS.DATA.NORMATIVITY.HISTORY;
      break;
  }

  return getApiUrl(url, {
    company_id,
    fleet_id,
    fleet_data_id,
  })
    .then((response) => response.json())
    .catch((error) => error.then((e) => []));
  // .then(response =>
  //   dispatch(ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.ACTION(response))
  // );
};

export const getNormativityHistoryOperatorData = (
  fleet_id,
  operator_id,
  type
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let url = "";

  if (type === 0) {
    url = BACKEND.FLEETS.DATA.OPERATOR.NORMATIVITY.HISTORY;
  } else {
    url = BACKEND.FLEETS.DATA.NORMATIVITY.STATUS.OPERATOR;
  }

  return getApiUrl(url, {
    company_id,
    fleet_id,
    operator_id,
    fleet_data_id: operator_id,
  })
    .then((response) => response.json())
    .catch((error) => error.then((e) => []));
  // .then(response =>
  //   dispatch(ACTIONS.FLEETS.OPERATOR.GET.SUCCESS.ACTION(response))
  // );
};

export const shareVehicleFleet = (provider_id, fleet_data) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SHARES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.SHARE_VEHICLES, {
      provider_id,
      company_id,
      fleet_data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.SHARES.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SHARES.POST.SUCCESS.ACTION(response))
      );
  };
};

export function getFleetSummary() {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestSummaryFleet());
    return getApiUrl(BACKEND.COMPANY.FLEET.SUMMARY, { company_id })
      .then((response) => response.json())
      .then((response) => dispatch(responseSummaryFleet(response)))
      .catch((error) => error.then((e) => dispatch(errorSummaryFleet(e))));
  };
}

export const shareEquipmentFleet = (provider_id, fleet_data) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SHARES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.SHARE_EQUIPMENTS, {
      provider_id,
      company_id,
      fleet_data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.SHARES.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SHARES.POST.SUCCESS.ACTION(response))
      );
  };
};

export const shareOperatorFleet = (provider_id, operator) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SHARES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.SHARE_OPERATORS, {
      provider_id,
      company_id,
      operator,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.SHARES.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SHARES.POST.SUCCESS.ACTION(response))
      );
  };
};

export const linkCompanyToFleet = (network) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.REQUEST.ACTION());
    return postApiUrl(BACKEND.COMPANY_CATALOG.FLEET_PROVIDER, {
      company_id,
      network,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.SHARES.FLEET_PROVIDER.SUCCESS.ACTION(response))
      );
  };
};

export const loadFleetCompanyTypes = (type_catalog) => {
  return function (dispatch) {
    let companyId = store.getState().globalCatalog.session.company.id;
    dispatch(ACTIONS.FLEETS.COMPANY_TYPE.GET.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY_CATALOG.COMPANY_TYPE, {
      company_id: companyId,
      type_catalog: type_catalog,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FLEETS.COMPANY_TYPE.GET.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FLEETS.COMPANY_TYPE.GET.SUCCESS.ACTION(response))
      );
  };
};

export const updateDataNormativityStatus = (
  company_id = undefined,
  fleet_id,
  id
) => {
  company_id = company_id
    ? company_id
    : store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.REQUEST.ACTION());
    return getApiUrl(BACKEND.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS, {
      company_id,
      fleet_id,
      id,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(
            ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.ERROR.ACTION(error)
          )
      )
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.DATA.UPDATE_NORMATIVITY_STATUS.SUCCESS.ACTION(response)
        )
      );
  };
};

export const updateOperatorNormativityStatus = (
  company_id = undefined,
  fleet_id,
  id
) => {
  company_id = company_id
    ? company_id
    : store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.REQUEST.ACTION()
    );
    return getApiUrl(BACKEND.FLEETS.DATA.OPERATOR.UPDATE_NORMATIVITY_STATUS, {
      company_id,
      fleet_id,
      id,
    })
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(
            ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.ERROR.ACTION(
              error
            )
          )
      )
      .then((response) =>
        dispatch(
          ACTIONS.FLEETS.OPERATOR.UPDATE_NORMATIVITY_STATUS.SUCCESS.ACTION(
            response
          )
        )
      );
  };
};

export const DATA_TYPES = {
  VEHICLE: 1,
  EQUIPMENT: 2,
  OPERATOR: 3,
};

export const FleetDataObj = {
  photo_url: "",
  economic_number: "",
  year: "",
  color: "",
  plates: "",
  height: "",
  length: "",
  width: "",
  weight: "",
  load: "",
  axles: "",
  tires: "",
  traction_axles_capacity: "",
  traction_axles_unit: "",
  abs: "",
  gps_identifier: "",
  gps_contact: "",
  gps_email: "",
  gps_telephone: "",
  status: "",
  validated: "",
  type: "",
  fleet: "",
  brand: "",
  fleet_class: "",
};

export function postUploadFleets(excel_file, type_id) {
  let company_id = store.getState().globalCatalog.session.company.id;

  const urlToUploadImage = interpolate(BACKEND.FLEETS.UPLOAD.POST, {
    company_id,
    type_id,
  });

  let formData = new FormData();
  formData.append("excel_file", excel_file[0]);

  return postMultipartApiUrl(urlToUploadImage, formData)
    .then(
      (response) => response.json(),
      (error) => error
    )
    .then((data) => data);
}

export const loadExpeditionCenter = () => {
  return function (dispatch) {
    let storeItems = store.getState().fleetReducer.expeditionCenter;
    if (!storeItems.isFetching && storeItems.data.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.FLEETS.EXPEDITION_CENTER.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.COMPANY_CATALOG.EXPEDITION_CENTER, {})
        .then(
          (response) => response.json(),
          (error) =>
            dispatch(ACTIONS.FLEETS.EXPEDITION_CENTER.GET.ERROR.ACTION(error))
        )
        .then((response) =>
          dispatch(
            ACTIONS.FLEETS.EXPEDITION_CENTER.GET.SUCCESS.ACTION(response)
          )
        );
    }
  };
};
//expeditionCenter
