import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  loadSession,
  loadPermissionsUser
} from "../../redux/reducers/global-catalog.reducer";

class ValidatePerms extends React.Component {
  render() {
    let permissionList = this.props.perms || [];
    let valid = false;
    if (
      permissionList !== undefined &&
      this.props.permission !== undefined &&
      Object.values(this.props.permission).length
    ) {
      if (this.props.eval === "and") {
        valid = true;
        permissionList.forEach(item => {
          if (
            ((this.props.permission[item.id] || {})[item.perm] || false) ===
            false
          ) {
            valid = false;
          }
        });
      } else if (this.props.eval === "or") {
        valid = false;
        permissionList.forEach(item => {
          if (
            ((this.props.permission[item.id] || {})[item.perm] || false) ===
            true
          ) {
            // console.log(
            //   "1",
            //   item.perm,
            //   this.props.permission[item.id],
            //   this.props.permission[item.id][item.perm]
            // );
            valid = true;
          }
        });
      }
    }

    if (valid) {
      return this.props.children;
    } else {
      return this.props.optionalElement;
    }
  }
}

ValidatePerms.propTypes = {
  perms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      perm: PropTypes.string
    })
  ),
  optionalElement: PropTypes.element,
  eval: PropTypes.string,
  id: PropTypes.number
};

ValidatePerms.defaultProps = {
  optionalElement: <React.Fragment></React.Fragment>,
  eval: "and",
  id: 0
};

const mapStateToProps = state => {
  return {
    permission: state.globalCatalog.permissionUser.data || {}
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadSession());
  dispatch(loadPermissionsUser());
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatePerms);
