import React, { Component } from "react";
import Button from '../button';
import SVGIcon, { icons } from '../../svg-icon';

class SettingControl extends Component {

  state = {
    isOpen: false,
    options: [],
    temp: [],

    showName: false,
    showCode: false,
    showAlias: false,

    showNameTemp: false,
    showCodeTemp: false,
    showAliasTemp: false,

    saveInfo: null,
    categoriesSelected: []
  };

  componentDidUpdate(prevProps) {
    
    if (!this.props.isFilterHasContent && this.state.categoriesSelected.length == 0 && this.props.options.length > 0) {

      let idsToAdd = this.props.options.map( item => { return item.id; });

      this.setState({
        categoriesSelected: idsToAdd,
        temp: idsToAdd
      });
    }

    if (this.state.options.length === 0 && this.props.options.length > 0) {
      this.setState({
        options: this.props.options,
        temp: this.props.categoriesSelected,
        categoriesSelected: this.props.categoriesSelected,
        saveInfo: false,
        showName: this.props.showName === true,
        showCode: this.props.showCode === true,
        showAlias: this.props.showAlias === true,
        showNameTemp: this.props.showName === true,
        showCodeTemp: this.props.showCode === true,
        showAliasTemp: this.props.showAlias === true
      });
    }

    if (this.state.categoriesSelected.length == 0 && this.props.categoriesSelected.length > 0 && this.state.saveInfo === null) {
      this.setState({
        categoriesSelected: this.props.categoriesSelected,
        temp: this.props.categoriesSelected,
        showName: this.props.showName === true,
        showCode: this.props.showCode === true,
        showAlias: this.props.showAlias === true,
        showNameTemp: this.props.showName === true,
        showCodeTemp: this.props.showCode === true,
        showAliasTemp: this.props.showAlias === true
      });
    }

    if (!this.state.isOpen && this.state.saveInfo !== null) {

      if (!this.state.saveInfo) {
        this.state.categoriesSelected = this.state.temp;
        this.state.showName = this.state.showNameTemp;
        this.state.showCode = this.state.showCodeTemp;
        this.state.showAlias = this.state.showAliasTemp;
      } else {

        this.state.temp = this.state.categoriesSelected;

        this.state.showNameTemp = this.state.showName;
        this.state.showCodeTemp = this.state.showCode;
        this.state.showAliasTemp = this.state.showAlias;

        this.props.applyFilter({
          options: this.state.categoriesSelected,
          showName: this.state.showName,
          showCode: this.state.showCode,
          showAlias: this.state.showAlias
        });
      }

      this.setState({
        saveInfo: null
      });
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target) || this.content.contains(e.target)) return;
    this.setState({ isOpen: false, saveInfo: false });
  };

  buildShowFacilitiesOptions = () => {
    return(
      <div
        style={{
          marginLeft: 25,
          width: 150,
        }}
      >
        <div
          style={{
            marginBottom: 10
          }}
        >
          {'Mostrar filtro de instalaciones:'}
        </div>

        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center'
          }}
          onClick={(e) => {
            this.state.showName = !this.state.showName;
            this.setState({});
          }}
        >
          <SVGIcon
            name={icons.passwordToggle}
            fill={ !this.state.showName ? "#AFC8FF" : "rgb(30,70,233)"}
          />
          <div style={{width: 8}}/>
          {'Nombre'}
        </div>

        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center'
          }}
          onClick={(e) => {
            this.state.showCode = !this.state.showCode;
            this.setState({});
          }}
        >
          <SVGIcon
            name={icons.passwordToggle}
            fill={ !this.state.showCode ? "#AFC8FF" : "rgb(30,70,233)"}
          />
          <div style={{width: 8}}/>
          {'Código'}
        </div>

        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center'
          }}
          onClick={(e) => {
            this.state.showAlias = !this.state.showAlias;
            this.setState({});
          }}
        >
          <SVGIcon
            name={icons.passwordToggle}
            fill={ !this.state.showAlias ? "#AFC8FF" : "rgb(30,70,233)"}
          />
          <div style={{width: 8}}/>
          {'Alias'}
        </div>

      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          position: 'relative'
        }}
      >

        <img 
          ref={node => (this.node = node)}
          alt='' 
          src='/images/settings.png' 
          style={{width: 24, height: 24, cursor: 'pointer'}} 
          onClick= {(e) => {

            // this.state.categoriesSelected = this.state.temp;
            // this.state.temp = [];

            this.setState({
              isOpen: !this.state.isOpen,
              saveInfo: false
            })
          }}
        />

        <div
          ref={node => (this.content = node)}
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            right: 0,
            top: 35,
            borderRadius: 8,
            visibility: this.state.isOpen ? 'visible' : 'hidden',
            paddingBottom: 20,
            paddingTop: 30,
            paddingRight: 30,
            paddingLeft: 30,
            width: this.props.isCargoOwner === true ? 450 : 350,
            fontSize: 15,
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >

          <div>
            <div
              style={{
                marginBottom: 10
              }}
            >
              {'Control de mando:'}
            </div>

            {
              this.state.options.map( (value, index) => {
                return(
                  <div
                    key={index}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      marginBottom: 10,
                      alignItems: 'center'
                    }}
                    onClick={(e) => {

                      if (this.state.categoriesSelected.includes(value.id)) {
                        this.state.categoriesSelected = this.state.categoriesSelected.filter( filter => { return `${filter}` !== `${value.id}`; } );
                      } else {
                        this.state.categoriesSelected = this.state.categoriesSelected.concat(value.id);
                      }

                      this.setState({});
                    }}
                  >
                    <SVGIcon
                      name={icons.passwordToggle}
                      fill={ this.state.categoriesSelected.includes(value.id) ? "rgb(30,70,233)" : "#AFC8FF"}
                    />
                    <div style={{width: 8}}/>
                    {value.description}
                  </div>
                );
              })
            }

            <div style={{height: 20}}/>

            <Button
              text='Aplicar'
              className='small'
              onClick={() => {
                this.setState({ isOpen: false, saveInfo: true });
              }}
              disabled={false}
            />

          </div>

          { this.props.isCargoOwner === true ? this.buildShowFacilitiesOptions() : <div/> }

        </div>

      </div>
    );
  }


}

export default SettingControl;