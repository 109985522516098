import React from "react";
import SelectInputStyled from "../../../shared/controls/select-input-styled";
import TextInput from "../../../shared/controls/text-input";

class RouteCatalogZoneFields extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedZoneDetails: []
    }
  }

  render() {
    return <div style={{color: "#9ba1ac"}}>
      <div>
        {
          this.props.mode === 2 &&
          "Selecciona convoys de salida y puntos de detención para esta ruta:"
        }
      </div>
      <div>
        {
          this.props.convoys.zones.map(zone => {
            let tags = [];
            if(zone.selectedZone.length){
              let zoneTags = (this.props.convoys.catalog.find(z => z.map_zone_id.toString() === zone.selectedZone[0].toString()) || {transport_classes: []}).transport_classes;
              tags = (this.props.tags || []).map(t => ({...t, items: t.items.filter(i => zoneTags.includes(i.id))})).filter(t => t.items.length);
            }
            return (
              <div style={{display: "flex"}}>
                <div style={{width: "30%", marginRight: "20px"}}>
                  <SelectInputStyled
                    selectedItems={zone.selectedZone}
                    items={(this.props.convoys.catalog || []).map(z => ({id: z.map_zone_id, description: z.map_zone__description}))}
                    onChange={(e) => this.props.convoys.handleInput(zone, e)}
                    className={""}
                    name={"selectedZone"}
                    label={"Convoy inicial"}
                    multiple={false}
                    disabled={this.props.mode !== 2}
                  />
                </div>
                <div style={{width: "20%"}}>
                  <SelectInputStyled
                    selectedItems={zone.selectedZoneClass}
                    items={tags}
                    onChange={(e) => this.props.convoys.handleInput(zone, e)}
                    className={""}
                    name={"selectedZoneClass"}
                    label={"Clase"}
                    multiple={true}
                    disabled={this.props.mode !== 2}
                  />
                </div>
                {
                  this.props.mode === 2 &&
                  <div style={{display: "flex", alignItems: "flex-end", margin: "0px 0 10px 20px"}}>
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer"}}
                      onClick={() => {this.props.convoys.deleteZone(zone.id)}}
                    />
                  </div>
                }
              </div>
            )
          })
          
        }
        {
          this.props.mode === 2 &&
          <div>
            <div style={{cursor: "pointer", marginTop: "10px", width: "fit-content", fontSize: "13px", fontWeight: "bold"}} onClick={this.props.convoys.addZone}>
              Agregar convoy
            </div>
          </div>
        }
      </div>
      <div>
        {
          this.props.detention.zones.map(zone => {
            let tags = [];
            if(zone.selectedZone.length){
              let zoneTags = (this.props.detention.catalog.find(z => z.map_zone_id.toString() === zone.selectedZone[0].toString()) || {transport_classes: []}).transport_classes;
              tags = (this.props.tags || []).map(t => ({...t, items: t.items.filter(i => zoneTags.includes(i.id))})).filter(t => t.items.length);
            }
            return (
              <div style={{display: "flex"}}>
                <div style={{width: "30%", marginRight: "20px"}}>
                  <SelectInputStyled
                    selectedItems={zone.selectedZone}
                    items={(this.props.detention.catalog || []).map(z => ({id: z.map_zone_id, description: z.map_zone__description}))}
                    onChange={(e) => this.props.detention.handleInput(zone, e)}
                    className={""}
                    name={"selectedZone"}
                    label={"Punto de detención"}
                    multiple={false}
                    disabled={this.props.mode !== 2}
                  />
                </div>
                <div style={{width: "15%", marginRight: "20px", display: "flex", alignItems: "baseline"}}>
                  <TextInput
                    label="Duración"
                    name="break_time"
                    value={zone.break_time}
                    className="item"
                    onChange={(e) => this.props.detention.handleInput(zone, e)}
                    disabled={this.props.mode !== 2}
                    naturalNumber={true}
                  /> mins
                </div>
                <div style={{width: "20%"}}>
                  <SelectInputStyled
                    selectedItems={zone.selectedZoneClass}
                    items={tags}
                    onChange={(e) => this.props.detention.handleInput(zone, e)}
                    className={""}
                    name={"selectedZoneClass"}
                    label={"Clase"}
                    multiple={true}
                    disabled={this.props.mode !== 2}
                  />
                </div>
                {
                  this.props.mode === 2 &&
                  <div style={{display: "flex", alignItems: "flex-end", margin: "0px 0 23px 20px"}}>
                    <img
                      alt=""
                      src="/images/bin.png"
                      style={{ cursor: "pointer"}}
                      onClick={() => this.props.detention.deleteZone(zone.id)}
                    />
                  </div>
                }
              </div>
            )
          })
        }
        {
          this.props.mode === 2 &&
          <div>
            <div style={{cursor: "pointer", marginTop: "10px", width: "fit-content", fontSize: "13px", fontWeight: "bold"}} onClick={this.props.detention.addZone}>
              Agregar punto de detención
            </div>
          </div>
        }
      </div>
    </div>
  }
}

export default RouteCatalogZoneFields;