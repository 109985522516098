import React from "react";
import Modal from "react-modal";
import TextInput from "../controls/text-input";
import Button from "../controls/button";
import { validateEmail } from "../validate";
import SVGIcon, { icons } from "../svg-icon";
import { connect } from "react-redux";
import { loginUser } from "../../redux/reducers/register.reducer";
import { handleWSError } from "../utils";
import { withRouter } from "react-router-dom";

Modal.setAppElement("#root");

class LoginDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",

      pwdType: true,

      emailFocused: false,
      passwordFocused: false
    };
  }

  onClose = () => {
    this.props.closeAction();
    this.setState({
      email: "",
      password: "",

      pwdType: true,

      emailFocused: false,
      passwordFocused: false
    });
  };

  getErrorEmail = () => {
    if (this.state.emailFocused && this.state.email === "") {
      return ["Oops... requerimos este campo"];
    }

    if (this.state.emailFocused && !validateEmail(this.state.email)) {
      return ["Oops... el correo no es valido"];
    }

    return [];
  };

  getErrorPasswordEmail = () => {
    if (this.state.passwordFocused && this.state.password === "") {
      return ["Oops... requerimos este campo"];
    }

    return [];
  };

  isValidFields = () => {
    return (
      this.state.password !== "" &&
      this.state.email !== "" &&
      validateEmail(this.state.email)
    );
  };

  login = () => {
    const { email, password } = this.state;
    this.props.loginUser({ username: email, password }).then(response => {
      this.onClose();

      if (this.props.error !== null) {
        const message = handleWSError(this.props.error);
        this.props.showError(message); 
      } else {
        let object = this.props.data;
        if (object.company) {
          if (object.company.length === 1) {
            object.company = object.company[0];
            window.sessionStorage.setItem(
              "orbinetwork_session",
              JSON.stringify(object)
            );
            this.props.history.push("/configurations");
          } else if (object.company.length > 1) {
            object.listCompanies = object.company;
            object.company = {
              id: 0
            };
            window.sessionStorage.setItem(
              "orbinetwork_session",
              JSON.stringify(object)
            );
            this.props.history.push("/company/select");
          } else {
            object.company = {
              id: 0
            };
            window.sessionStorage.setItem(
              "orbinetwork_session",
              JSON.stringify(object)
            );
            this.props.history.push("/configurations");
          }
        }
      }
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog login-dialog"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>

        <div className="form">
          <TextInput
            type="text"
            label="Ingresa tu correo electrónico"
            name="email"
            className="full"
            errors={this.getErrorEmail()}
            onChange={event => {
              const {
                target: { value: email = "" }
              } = event;
              this.setState({ email: email.toLowerCase(), emailFocused: false });
            }}
            value={this.state.email}
            handleBlur={() => this.setState({ emailFocused: true })}
            suffix={{
              element:
                this.state.email !== "" && validateEmail(this.state.email) ? (
                  <SVGIcon name={icons.checkMark} fill="#AFC8FF" />
                ) : (
                  <div />
                ),
              className: ""
            }}
            handleEnter={() => {
              if (this.isValidFields() && !this.props.isFetching) this.login();
            }}
          />

          <TextInput
            type={this.state.pwdType ? "password" : "text"}
            label="Ingresa tu contraseña"
            name="password"
            className="full"
            errors={this.getErrorPasswordEmail()}
            onChange={event => {
              const {
                target: { value: password = "" }
              } = event;
              this.setState({ password, passwordFocused: false });
            }}
            value={this.state.password}
            handleBlur={() => this.setState({ passwordFocused: true })}
            suffix={{
              element: (
                <div
                  onClick={() =>
                    this.setState({ pwdType: !this.state.pwdType })
                  }
                >
                  <SVGIcon
                    name={icons.passwordToggle}
                    fill={this.state.pwdType ? "#AFC8FF" : "rgb(30,70,233)"}
                  />
                </div>
              ),
              className: ""
            }}
            handleEnter={() => {
              if (this.isValidFields() && !this.props.isFetching) this.login();
            }}
          />
          <div className="options-container">
            <div className="no-account">¿No tienes cuenta?</div>
            <div
              className="forgot-link"
              onClick={() => this.props.forgotAction(true)}
            >
              ¿Olvidaste tu contraseña?
            </div>
          </div>

          <div className="button-container">
            <Button
              text={"Crear tu cuenta"}
              type={"secondary"}
              onClick={() => {
                this.onClose();
                if (this.props.closure) this.props.closure();
              }}
            />

            <Button
              text={this.props.isFetching ? "Cargando..." : "Iniciar sesión"}
              type={"primary"}
              onClick={() => this.login()}
              disabled={!this.isValidFields() || this.props.isFetching}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.register.login.isFetching,
    data: state.register.login.data,
    error: state.register.login.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUser: data => dispatch(loginUser(data))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginDialog)
);
