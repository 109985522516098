import React from 'react';
import Modal from 'react-modal';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from 'react-dnd'
import { useRef } from 'react'
import styled from "styled-components";
import SearchTypeDropdown from '../controls/search-type-dropdown';

const ItemTypes = {
  CARD: 'card',
}

const Card = ({ id, component, index, moveCard, dropItems }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({

    accept: ItemTypes.CARD,
    drop(item, monitor) {
      dropItems();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div 
      ref={ref} 
      style={{
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        opacity
      }}
      data-handler-id={handlerId}
    >
      {component}
      <img
        alt=''
        src='/images/draggable-dots.png'
        style={{ cursor: 'move', height: 30, width: 30}}
      />
    </div>
  )
}

class TripControlColumnsSettingsModal extends React.Component {

  getHeaderRow = () => {
    return(
      <div className='row' style={{marginTop: 20, marginBottom: 20}}>
        <div style={{width: 40}}>{'#'}</div>
        <div style={{width: 300}}>{'Nombre de la columna'}</div>
        <div style={{width: 50}}>{'Mostrar'}</div>
      </div>
    );
  }

  isValueFound = (value) => {
    var itemsToHide = this.props.itemsToHide || [];
    return itemsToHide.find( item => {
      return item === value;
    });
  }

  updateValue = (value) => {
    var itemsToHide = this.props.itemsToHide || [];

    if (itemsToHide.find( item => {
      return item === value;
    })) {
      itemsToHide = itemsToHide.filter( item => {
        return item !== value
      });
    } else {
      itemsToHide = itemsToHide.concat(value);
    }

    this.props.updateItems(itemsToHide);
  }

  moveCard = (dragIndex, hoverIndex) => { 
    this.props.moveCard(dragIndex, hoverIndex);
  }

  dropItems = () => {
    this.props.dropItems();
  }

  getContet = () => {
    return(
      <DndProvider backend={HTML5Backend}>
        {this.getHeaderRow()}
        <div className='rows-content'>
          {
            (this.props.columns || []).map( (item, index) => {
              return(
                <Card
                  id={item.id}
                  key={item.id}
                  component={ 
                    <div className='row' key={item.id} id={item.id}>
                      <div style={{width: 40}}>{index + 1}</div>
                      <div style={{width: 280, maxWidth: 300, paddingLeft: 20}}>{item.title}</div>
                      <div style={{width: 20, display: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                        <img alt="" src={ this.isValueFound(item.id) ? '/images/toggle_off.png' : '/images/toggle_on.png'} style={{ width: 24, height: 24, cursor: 'pointer'}} onClick={ () => {
                          this.updateValue(item.id);
                        }}/>
                      </div>
                    </div>
                  }
                  index={index} 
                  moveCard={this.moveCard}
                  dropItems={this.dropItems}
                />
              );
            })
          }
        </div>
      </DndProvider>
    );
  }

  getAutorefreshContent = () => {
    return( 
      <AutoRefreshContent>
        <AutoRefreshTitle>{'Autorefresh'}</AutoRefreshTitle>
        
        <SearchTypeDropdown
          styleMenu={2}
          items={[
            {
              title: 'Desactivado',
              value:  null
            },
            {
              title: '1 min',
              value:  '1'
            },
            {
              title: '3 min',
              value:  '2'
            },
            {
              title: '5 min',
              value:  '3'
            },
            {
              title: '10 min',
              value:  '4'
            }
          ]}
          selected={this.props.refreshSelected}
          onSelect={(item) => {
            if (this.props.changeAutorefresh) this.props.changeAutorefresh(item)
          }}
        />

      </AutoRefreshContent>
    );
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog trip-control-columns-settings-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{'Configurar columnas'}</div>
        <div className='subTitle'>{'Selecciona las columnas que deseas ver'}</div>
        <div className="close" onClick={() => {this.props.onClose()}}></div>

        { this.getContet() }

        { this.getAutorefreshContent() }

      </Modal>
    );
  }

}

export default TripControlColumnsSettingsModal;

const AutoRefreshContent = styled.div`
  margin-top: 10px;
  margin-left: 50px; 
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

const AutoRefreshTitle = styled.div`
  font-size: 13px; 
  color: black;
  margin-right: 10px;
  align-items: center;
  display: flex;
`;