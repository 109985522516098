// @flow
import React from "react";
import AssembleSummary from "../assemble-summary";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import TextInput from "../../../shared/controls/text-input";
import SVGIcon, { icons } from "../../../shared/svg-icon";
// import ReactDatePicker from "react-datepicker";
import {
  // FooterDatePickerText,
  AssembleTable,
  // FooterTitle
} from "./styled";
import AssembleTableDataRowBlock from "./assemble-table-data-row-block";
import AssembleTableOperatorRowBlock from "./assemble-table-operator-row-block";

type Props = {
  step: 1 | 2 | 3,
  onlyOne: boolean,
  selectedEquipments: Array<any>,
  selectedVehicles: Array<any>,
  vehiclesList: Array<any>,
  operatorsList: Array<any>,
  equipmentsList: Array<any>,
  onChange: Function,
  onSearch: Function,
  selectedDate: Date,
  selectedOperators: Array<any>,
  modalSearchText: string,
  tableClassification: Array<{
    id: number,
    title: string,
    icon: any,
    description: any,
  }>,
  data: Array<any>,
  openingDate: Date,
};

class AssembleModalContent extends React.Component<Props> {
  render() {
    const {
      onSearch,
      // onChange,
      selectedVehicles,
      // selectedDate,
      selectedOperators,
      modalSearchText,
      selectedEquipments,
      step,
    } = this.props;

    return (
      <Flex direction={flexOpts.direction.column}>
        <Flex>
          <Flex direction={flexOpts.direction.column}>
            {`Selecciona ${optionText[step]} para el ensamble`}
            <TextInput
              value={modalSearchText}
              label="Buscar"
              name="modalSearchText"
              onChange={(e) => onSearch(e.target.value)}
              suffix={{
                element: (
                  <SVGIcon
                    name={icons.search}
                    fill="#AFC8FF"
                    style={{ right: "0%", position: "absolute" }}
                  />
                ),
              }}
            />
          </Flex>
          <Flex style={{ marginLeft: "20%", maxWidth: "50%" }}>
            <AssembleSummary
              style={{ position: "relative", top: "-40px" }}
              equipments={selectedEquipments}
              operators={selectedOperators}
              vehicles={
                step > 1 && selectedVehicles.length === 0
                  ? [{ description: "Sin vehículo", showIcon: false }]
                  : selectedVehicles
              }
            />
          </Flex>
        </Flex>
        <AssembleTable headerContent={headers(step)} />
        <Flex
          direction={flexOpts.direction.column}
          style={{
            maxHeight: "300px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {step !== 3 ? this.getDataTable() : this.getOperatorTable()}
        </Flex>
        <Flex direction={flexOpts.direction.column}>
          {/* <FooterTitle>Duración en ensamble</FooterTitle> */}
          {/* <Flex
            alignItems={flexOpts.alignItems.center}
            style={{ width: "50%" }}
          >
            <div style={{ width: "100px" }}>A partir de:</div>
            <ReactDatePicker
              selected={selectedDate}
              onChange={(date) =>
                onChange({ target: { name: "selectedDate", value: date } })
              }
              minDate={this.getMinDate()}
              // minTime={this.getMinDate()}
              maxDate={null}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={10}
              popperPlacement="top-start"
              timeCaption="horario"
              dateFormat="d MMMM yyyy HH:mm"
              // dateFormat="dd/MM/yyyy h:mm aa"
              customInput={<DatePickerCustom />}
            />
            <Checkbox
              onChange={onChange}
              name="nowActive"
              item={{
                description: "Ahora",
              }}
            /> 
          </Flex> */}
        </Flex>
      </Flex>
    );
  }
  getDataTable = () => {
    const { step, data, onChange, onlyOne, tableClassification } = this.props;
    return (
      <AssembleTableDataRowBlock
        data={data}
        onChange={onChange}
        onlyOne={onlyOne}
        step={step}
        tableClassification={tableClassification}
        headers={headers(step)}
        initialStateCollapsed={false}
      />
    );
  };

  getOperatorTable = () => {
    const { step, data, onChange, onlyOne, tableClassification } = this.props;
    return (
      <AssembleTableOperatorRowBlock
        // {...this.props}
        data={data}
        onChange={onChange}
        onlyOne={onlyOne}
        step={step}
        tableClassification={tableClassification}
        headers={headers(step)}
        initialStateCollapsed={false}
      />
    );
  };

  getMinDate() {
    const { step, selectedVehicles, openingDate } = this.props;
    if (step === 1 && selectedVehicles.length) {
      return new Date(selectedVehicles[0].from);
    }
    return openingDate;
  }
  getMaxDate() {}
  getCurrentDate() {}
}

export default AssembleModalContent;

const optionText = {
  "1": "un vehículo",
  "2": "los equipos",
  "3": "los operadores",
};

const headers = (step) =>
  step !== 3
    ? [
        { text: "Número Económico", width: "14%" },
        { text: "Placas", width: "10%" },
        { text: "Estatus", width: "10%" },
        { text: "Etiquetas", width: "15%" },
        { text: "Disponible a partir de", width: "18%" },
        { text: "Próxima salida de operación", width: "18%" },
        { text: "Estatus normatividad", width: "15%" },
      ]
    : [
        { text: "Nombre", width: "24%" },
        { text: "Estatus", width: "10%" },
        { text: "Etiquetas", width: "17%" },
        { text: "Disponible a partir de", width: "17%" },
        { text: "Disponible hasta", width: "17%" },
        { text: "Estatus normatividad", width: "15%" },
      ];

// const DatePickerCustom = ({
//   value,
//   onClick,
// }: {
//   value?: any,
//   onClick?: Function,
// }) => (
//   <Flex style={{ width: "fit-content" }}>
//     <SVGIcon
//       name={icons.calendar}
//       fill="#AFC8FF"
//       width="20px"
//       height="20px"
//       onClick={onClick}
//     />
//     <FooterDatePickerText onClick={onClick}>{value}</FooterDatePickerText>
//   </Flex>
// );
