import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import { connect } from 'react-redux';
import TextInput from '../controls/text-input';
import { loadSession, clearSession } from '../../redux/reducers/global-catalog.reducer';
import { validators } from '../validate';
import ChecklistTooltip from '../component/checklist-tooltip';
import { length, number, upper, secuentialNumber, lower } from '../string-constants';
import ReactTooltip from 'react-tooltip';
import { updatePassword, ERROR_UPDATE_PASSWORD, RESPONSE_UPDATE_PASSWORD } from '../../redux/reducers/register.reducer';
import { handleWSError } from '../utils';

Modal.setAppElement('#root');

class ChangePasswordDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            confirmPassword: '',
            response: this.props.response || '',
            error: this.props.error || false,
            passwordChanged: false
        }
    }

    onClose = () => {
        if (this.state.passwordChanged) {
            this.props.clearSession();
            window.location.href = '/login.html';
            return;
        }
            
        if (this.props.closeAction) {
            this.props.closeAction();
            this.setState({response: '', email: '', error: false, password: '', newPassword: '', confirmPassword: ''});
        }
    }

    getErrorValidation = () => {
        const { password, newPassword, confirmPassword } = this.state;

        if (password === '' || newPassword === '' || confirmPassword === '') {
            return 'Todos los campos son requeridos';
        }

        if (
            !length.test(newPassword) || 
            !number.test(newPassword) || 
            !upper.test(newPassword) || 
            !lower.test(newPassword) || 
            secuentialNumber.test(newPassword)
        ) {
            return 'Nueva contraseña no es valida';
        }

        if (newPassword !== confirmPassword) {
            return 'La nueva contraseña y confirmar contraseña deben coincidir';
        }

        return '';

    }

    isValid = () => {
        return this.getErrorValidation() === '';
    }

    render() {
        return (
        <Modal
            isOpen={this.props.open}
            portalClassName="dialog change-password-dialog"
            overlayClassName="overlay"
            className="content"
        >
            <div className="title">{this.props.title}</div>
            <div className="close" onClick={this.onClose}></div>
            {
                (this.state.response !== '' && this.state.error === true) ?
                <div
                    style={{
                        color: 'red',
                        marginLeft: 84,
                        fontSize: 15,
                        marginBottom: 35,
                        marginTop: 20,
                        marginRight: 84
                    }}
                >{this.state.response}</div> :
                <div className="message">{ this.state.response === '' ? this.props.message : this.state.response}</div>
            }

            {
                (this.state.response === '' || this.state.error === true) ?
                <React.Fragment>
                    <div style={{width: 'calc(99% - 160px)', paddingLeft: '80px', paddingRight: '80px', marginTop: '-30px'}}>
                        <TextInput
                            type='password'
                            label='Contraseña actual'
                            name='password'
                            className='full'
                            value={this.state.password}
                            onChange={ (event) => this.setState({password: event.target.value}) }
                            validate={[ validators.required ]}
                            />

                        <TextInput
                            ref={(input) => { this.newPassword = input; }} 
                            type='password'
                            label='Nueva contraseña'
                            name='newPassword'
                            className='full'
                            value={this.state.newPassword}
                            onChange={ (event) => this.setState({newPassword: event.target.value}) }
                            dataFor="orbi-tooltip-password"
                            dataTip={''}
                            validate={[ validators.required ]}
                            />

                        <ChecklistTooltip
                            tooltipId='orbi-tooltip-password'
                            className='custom-tooltip'
                            event='focusin'
                            eventOff='focusout'
                            title='Tu contraseña debe contener:'
                            valueToValidate={this.state.newPassword}
                            validations={[
                                { valid: (value) => { return length.test(value) }, textToShow: 'Minimo 8 caracteres' },
                                { valid: (value) => { return number.test(value) }, textToShow: 'Un número (0-9)' },
                                { valid: (value) => { return upper.test(value) }, textToShow: 'Una mayúscula (A-Z)' },
                                { valid: (value) => { return lower.test(value) }, textToShow: 'Una minúscula' },
                                { valid: (value) => { return !secuentialNumber.test(value) }, textToShow: 'No deben ser 3 o más números consecutivos' }
                            ]}        
                        />

                        <TextInput
                            type='password'
                            label='Confirmar nueva contraseña'
                            name='confirmPassword'
                            className='full'
                            value={this.state.confirmPassword}
                            onChange={ (event) => this.setState({confirmPassword: event.target.value}) }
                            validate={[ validators.required ]}
                            />
                    </div>

                    <div className="button_container">
                        <Button 
                            disabled = { !this.isValid() || this.props.isFetching  }
                            text= { this.props.acceptText }
                            dataFor="orbi-tooltip-error"
                            dataTip={ this.getErrorValidation()}
                            onClick={ () => {

                                this.props.updatePassword(this.state.password, this.state.newPassword).then( (response) => {
                                    

                                    switch (response.type) {
                                        case ERROR_UPDATE_PASSWORD:

                                            const message = handleWSError(response.error);
                                            this.setState({ 
                                                error: true, 
                                                response: message,
                                                passwordChanged: false,
                                                newPassword: '',
                                                confirmPassword: ''
                                            }, () => {
                                                this.newPassword.setFocus();
                                            })
                                            
                                            break;

                                        case RESPONSE_UPDATE_PASSWORD:

                                            this.setState({
                                                response: 'Se actualizó correctamente tu contraseña, inicia sesión de nuevo',
                                                error: false,
                                                passwordChanged: true
                                            });

                                            break;

                                    
                                        default:
                                            break;
                                    }

                                })

                            }}
                            />

                    <ReactTooltip
                        id={"orbi-tooltip-error"}
                        place="right"
                        effect="solid"
                        html={true}
                        event="mouseover"
                        eventOff="mouseleave"
                    />
                            

                    </div>
                </React.Fragment>
                :
                <div className="button_container">
                    <Button 
                        disabled = { false }
                        text= { 'Continuar' }
                        onClick= { () => {

                            if (!this.state.error) {
                                this.props.clearSession();
                                window.location.href = '/login.html';
                            } else {
                                this.props.acceptAction();
                                this.setState({response: '', email: '', error: false, password: '', newPassword: '', confirmPassword: ''});
                            }

                        }}/>
                </div>
            }

        </Modal>
    );
  }
}

const mapStateToProps = state => {
    return {
        isFetching: state.register.isFetching,
        data: state.register.data,
        error: state.register.error
    };
}

const mapDispatchToProps = dispatch => {
    dispatch(loadSession());
    
    return ({
      updatePassword: (current_password, password) => dispatch(updatePassword({ current_password, password})),
      clearSession: () => dispatch(clearSession())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordDialog);