import React, { useEffect, useState, createRef } from 'react';
import { RowSpaceWrapper, Timeline, TimelineCaption, TimelineSubtitle, TimelineSubtitleExtra, TimelineWrapper, Wrapper } from './apoointments-grid-styles';
import AppointmentSpaceRow from './appointment-space-row';


const AppointmentsGrid = ({
  begin,
  end,
  spaces,
  facilities,
  searchStr
}) => {
  const [listHours, setListHours] = useState([])
  
  const scrollRef = createRef();
  
  useEffect(() => {
    if (begin < end && end > begin) {
      const times = []
      for (let time = begin; time <= end; time += 100) {
        times.push(formatTime(time))
      }
      setListHours(times)
      // setListHours(prev => [...prev, formatTime(time)])
    } else {
      throw new Error('Bad end and begin props')
    }
  }, [begin, end])

  useEffect(() => {
    // let searched = (spaces.map(s => s.appointments || []).flat()).filter(a => a.searched);
    let confSearched = (spaces.map(s => s.conflicts || []).flat()).map(c => c.orders.map(o => ({...o, id: c.id}))).flat().filter(o => o.searched);
    // let totalSearched = searched.concat(confSearched);
    if(confSearched.length === 1){
      // if(scrollRef?.current){
      //   const r = document.getElementById(
      //     'card-'.concat(confSearched[0].id)
      //   )
      //   if(r){
      //     r.scrollIntoView({
      //       block: 'center',
      //     });
      //     // console.log("ELEMENT ", r.getBoundingClientRect(), r.getBoundingClientRect().left);
      //     // console.log("verticalScrollRef", verticalScrollRef.current);
      //     // if(verticalScrollRef.current){
      //     //   console.log("ELEMENT TOP:", r.getBoundingClientRect().top);
      //     //   verticalScrollRef.current.scrollTop = r.getBoundingClientRect().top - 200;
      //     // }
          
      //     scrollRef.current.scrollLeft = r.getBoundingClientRect().left -300;
      //     const rows = document.getElementsByClassName(
      //       'scroll-test-traffic-row'
      //     )
      //     Array.from(rows).forEach(
      //       a => (a.scrollLeft = scrollRef.current?.scrollLeft || 0)
      //     );
      //   }
      // }
    }
    // console.log("SPACES", searched, confSearched, spaces);
  }, spaces);

  const formatTime = value => {
    const hour = value / 100
    const stringValue = value.toString()
  
    if (hour < 10) {
      return `0${stringValue.charAt(0)}:${stringValue.charAt(1) ||
        '0'}${stringValue.charAt(2) || '0'}`
    }
  
    return `${stringValue.charAt(0)}${stringValue.charAt(1)}:${stringValue.charAt(
      2
    )}${stringValue.charAt(3)}`
  }

  const getHourByTime = time => parseInt(time.split(':')[0], 10)

  const checkCurrentHour = time => {
    const hour = getHourByTime(time)
    const currentHour = new Date().getHours()
  
    return hour === currentHour
  }

  const setTimetoPercent = value => {
    return parseInt(value / 100) * 100 + (Math.min(value % 100, 59) * 100) / 60
  }

  return (
    <Wrapper>
      <TimelineWrapper>
        <div style={{ width: '15%' }} />
        <div
          className="scroll-test-traffic-row"
          style={{ width: '85%', overflow: 'hidden' }}
        >
          <Timeline>
            {listHours.map(time => (
              <TimelineCaption
                key={time}
                width={`${100 / listHours.length}%`}
                isCurrentTime={checkCurrentHour(time)}
              >
                {time}
              </TimelineCaption>
            ))}
          </Timeline>
        </div>
      </TimelineWrapper>
      <RowSpaceWrapper /* ref={verticalScrollRef}  */style={{width: spaces.length > 5 ? "100%" : "99%"}}>
        {
          facilities 
          ? (
            facilities.map(f => {
              return <>
                <TimelineSubtitle>
                  {f.name}
                  <TimelineSubtitleExtra>
                    {f.time}
                  </TimelineSubtitleExtra>
                </TimelineSubtitle>
                {
                  spaces.filter(s => s.facility === f.id).map((space, key) => (
                    <AppointmentSpaceRow
                      // dateValidation={dateValidation}
                      key={`${space.id}-${key}`}
                      range={[setTimetoPercent(begin), setTimetoPercent(end)]}
                      {...space}
                      spaceIndex={key + 1}
                      windows={space.windows.map(w => ({
                        ...w,
                        begin: setTimetoPercent(w.begin),
                        end: setTimetoPercent(w.end)
                      }))}
                      // appointments={space.appointments.map(a => ({
                      //   ...a,
                      //   begin: setTimetoPercent(a.begin),
                      //   end: setTimetoPercent(a.end)
                      // }))}
                      searchStr={searchStr}
                      conflicts={space.conflicts.map(c => ({
                        ...c,
                        begin: setTimetoPercent(c.begin),
                        end: setTimetoPercent(c.end)
                      }))}
                    />
                  ))
                }
              </>
            })
          )
          : spaces.map((space, key) => (
            <AppointmentSpaceRow
              // dateValidation={dateValidation}
              key={`${space.id}-${key}`}
              range={[setTimetoPercent(begin), setTimetoPercent(end)]}
              {...space}
              windows={space.windows.map(w => ({
                ...w,
                begin: setTimetoPercent(w.begin),
                end: setTimetoPercent(w.end)
              }))}
              // appointments={space.appointments.map(a => ({
              //   ...a,
              //   begin: setTimetoPercent(a.begin),
              //   end: setTimetoPercent(a.end)
              // }))}
              searchStr={searchStr}
              conflicts={space.conflicts.map(c => ({
                ...c,
                begin: setTimetoPercent(c.begin),
                end: setTimetoPercent(c.end)
              }))}
            />
          ))
        }
      </RowSpaceWrapper>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row'/* , position: 'absolute', bottom: '-40px'  */}}>
        <div style={{ minWidth: '15%', width: '15%', height: '44.4px' }}></div>
        <div
          ref={scrollRef}
          onScroll={e => {
            const r = document.getElementsByClassName(
              'scroll-test-traffic-row'
            )
            Array.from(r).forEach(
              a => (a.scrollLeft = scrollRef.current.scrollLeft)
            )
          }}
          style={{ 
            width: '85%', 
            overflowX: 'scroll', 
            position: 'absolute', 
            left: '14%', 
            bottom: '0', 
            // height: '100%', 
            height: '44.4px',
            // borderRight: "1px dotted #ffffff33",
            // borderLeft: "1px dotted #ffffff33"
          }}
        >
          <div
            style={{
              width: '2000px',
              height: '30px'
            }}
          ></div>
        </div>
      </div>
    </Wrapper>
  )
}

export default AppointmentsGrid;


AppointmentsGrid.defaultProps = {
  begin: 600,
  end: 1900,
  spaces: []
}