import React from "react";

const FleetDetail = (props) => {
  const {
    isDataCollapsed,
    data: {
      valids = 0,
      pendings = 0,
      rejecteds = 0,
      incomplete = 0,
      expired = 0,
    },
  } = props;

  return (
    <div className="fleet-container fleet-detail line-container">
      <div className="fleet-container fleet-content">
        <div className="total">
          {valids + pendings + rejecteds + incomplete + expired}
        </div>
        <div className="catalog-header">En catálogo</div>
        {!isDataCollapsed && (
          <div className="fleet-container fleet-content">
            <div className="value">{valids}</div>
            <div className="value" style={{ color: "rgb(218, 137, 136)" }}>
              {pendings}
            </div>
            <div className="value">{rejecteds}</div>
            <div className="value">{incomplete}</div>
            <div className="value">{expired}</div>
          </div>
        )}
      </div>

      {/* <div className="line" /> */}
    </div>
  );
};

export default FleetDetail;
