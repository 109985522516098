//@flow
import * as React from "react";
import Button from "../../shared/controls/button";
import Modal from "react-modal";

import TextInput from "../../shared/controls/text-input";
import SelectInput from "../../shared/controls/select-input";
import Tag from "../../shared/controls/tag";
import { validators } from "../../shared/validate";

import { ToastContainer } from "react-toastify";

type CompoundEquipment = {
  classID: number,
  description: string,
  id: number,
  typeID: number,
  upload_description: string,
};

type EquipmentSubType = {
  avatar: any,
  description: string,
  id: number,
  type: string,
};

type Nomenclature = {
  configuration: string,
  id: number,
};

type CompoundVehicle = {
  abbreviation: string,
  classID: number,
  description: string,
  id: number,
  typeID: number,
};
type ExpeditionClass = {
  code: string,
  tag: number,
  vehicle: CompoundVehicle,
  equipment1: CompoundEquipment,
  equipmentSubType1: number,
  equipment2: CompoundEquipment,
  equipmentSubType2: number,
  equipment3: CompoundEquipment,
  equipmentSubType3: number,
};

type TagType = {
  id: number,
  title: string,
  color: string,
  type: string,
};

type Props = {
  show: boolean,
  manageModal: () => void,
  onChange: () => void,
  expClass: ExpeditionClass,
  tags: TagType[],
  compoundEquipments: CompoundEquipment[],
  compoundVehicles: CompoundVehicle[],
  equipmentSubTypes: EquipmentSubType[],
  nomenclatures: Nomenclature[],
  addEquipment: boolean,
  secondEquipment: boolean,
};

const EditExpeditionClassModal = ({
  show,
  manageModal,
  onChange,
  expClass,
  tags,
  compoundEquipments,
  compoundVehicles,
  equipmentSubTypes,
  nomenclatures,
  addEquipment,
  secondEquipment,
}: Props) => {
  EditExpeditionClassModal.defaultProps = {
    expClass: {
      code: "",
      tag: 0,
      vehicle: {},
      equipment1: {},
      equipmentSubType1: 0,
      equipment2: {},
      equipmentSubType2: 0,
      equipment3: {},
      equipmentSubType3: 0,
      addEquipment: false,
      secondEquipment: false,
    },
  };

  const resourcesData = (resource) => {
    let result = { visible: false, items: [], subItems: [] };

    // if (editMode) return result;
    // const v = expClass.vehicle?.abbreviation?.substring(0, 2) || "";
    const e1 = expClass.equipment1?.upload_description?.substring(0, 2) || "";
    const e2 = expClass.equipment2?.upload_description?.substring(0, 2) || "";
    const e3 = expClass.equipment3?.upload_description?.substring(0, 2) || "";
    switch (resource) {
      case "E1":
        result.visible = e1 ? true : false;

        result.items = compoundEquipments;

        result.subItems = equipmentSubTypes;
        break;
      case "E2":
        result.visible = e2 ? true : false;

        result.items = compoundEquipments;

        result.subItems = equipmentSubTypes;
        break;
      case "D1":
        result.visible = e3 ? true : false;

        result.items = compoundEquipments;
        result.subItems = equipmentSubTypes;
        break;
      default:
        result.visible = false;
    }
    return result;
  };

  const getNomenclature = () => {
    const nom = [
      expClass.vehicle?.abbreviation?.substring(0, 2) || "",
      expClass.equipment1?.upload_description?.substring(0, 2) || "",
      expClass.equipment2?.upload_description?.substring(0, 2) || "",
      //   nomenclatureEquipment2,
    ].filter((x) => x);

    if (expClass.equipment3?.id) {
      const last = nom.pop();
      const n =
        parseInt(last.substring(1, 2)) +
        parseInt(expClass.equipment3.upload_description.substring(1, 2));
      nom.push("R".concat(n));
    }
    return nom.join("-");
  };

  const isValidForm = () => {
    return (
      expClass.code.length > 0 &&
      (nomenclatures.find((n) => n.configuracion === getNomenclature())
        ? true
        : false) &&
      (expClass.equipmentType?.id
        ? expClass.equipmentSubType > 0
          ? true
          : false
        : true) &&
      (expClass.equipmentType2?.id
        ? expClass.equipmentSubType2 > 0
          ? true
          : false
        : true)
      // &&
      // (current.dollyType?.id ? (current.dollySubType > 0 ? true : false) : true)
    );
  };

  return (
    <Modal
      isOpen={show}
      portalClassName="dialog emails-modal expedition-class-modal"
      overlayClassName="overlay"
      className="content"
    >
      <ToastContainer className="" />
      <div className="title">Editar clase de expedición</div>
      <div
        className="close"
        onClick={() =>
          manageModal({ target: { name: "close", value: "close" } })
        }
      ></div>
      <div className="message">
        <div>
          <TextInput
            label="Expedición (Máximo 4 caracteres)"
            name="expedition"
            value={expClass.code}
            className="item"
            onChange={onChange}
            validate={[validators.required, "maxLength"]}
            validationParam={4}
            // validate={[validators.required]}
            //   onFocused={onFocused}
            disabled={true}
          />
        </div>
        <div>
          <span className="section-title">Etiqueta de clase de expedición</span>
          <div className="tags">
            {tags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  color={tag.color}
                  title={tag.title}
                  type={tag.type}
                  isFull={expClass.tag === tag.id}
                  onClick={(e) =>
                    onChange({
                      target: {
                        name: "selectModalTag",
                        value: tag.id,
                      },
                    })
                  }
                  parentBackground="light"
                />
              );
            })}
          </div>
        </div>
        <div>
          <SelectInput
            label="Tipo de vehículo"
            name="vehicleType"
            value={expClass.vehicle?.id || 0}
            className="item"
            onChange={onChange}
            items={compoundVehicles}
            //   validate={[validators.required]}
            //   onFocused={onFocused}
            disabled={true}
          />
        </div>
        {resourcesData("E1").visible && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de equipo"
                name="equipmentType"
                value={expClass.equipment1?.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("E1").items}
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                disabled={true}
              />
            </div>

            <div>
              <SelectInput
                label="Sub-tipo de equipo"
                name="equipmentSubType"
                value={expClass.equipmentSubType1}
                className="item"
                onChange={onChange}
                items={resourcesData("E1").subItems}
                // items={equipmentSubTypes}
                disabled={true}
              />
            </div>
          </React.Fragment>
        )}
        {resourcesData("E2").visible && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de equipo"
                name="equipmentType2"
                value={expClass.equipment2?.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("E2").items}
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                disabled={true}
              />
            </div>

            <div>
              <SelectInput
                label="Sub-tipo de equipo"
                name="equipmentSubType2"
                value={expClass.equipmentSubType2}
                className="item"
                onChange={onChange}
                items={resourcesData("E2").subItems}
                disabled={true}
              />
            </div>
          </React.Fragment>
        )}

        {resourcesData("D1").visible && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de dolly"
                name="dollyType"
                value={expClass.equipment3?.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("D1").items}
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                disabled={true}
              />
            </div>

            <div>
              <SelectInput
                label="Sub-tipo de dolly"
                name="dollySubType"
                value={expClass.equipmentSubType3}
                className="item"
                onChange={onChange}
                items={resourcesData("D1").subItems}
                disabled={true}
              />
            </div>
          </React.Fragment>
        )}

        <div className="top-space">
          <span className="section-title">Nomenclatura</span> <br />
          <span>{getNomenclature()}</span>
        </div>
      </div>
      <div className="actions">
        <Button
          text="Cancelar"
          type="primary outline"
          onClick={() =>
            manageModal({ target: { name: "cancel", value: "cancel" } })
          }
        />

        <Button
          text="Guardar"
          type="primary"
          onClick={() => manageModal({ target: { name: "update", value: "" } })}
          disabled={!isValidForm()}
          // dataTip={getErrors()}
          // dataFor="orbi-tooltip-error"
          //   onMouseLeave={() => handleLeave()}
        />
        {/* <ReactTooltip
        id={"orbi-tooltip-error"}
        place="top"
        type="light"
        effect="float"
        html={true}
        event="mouseover"
        eventOff="mouseleave"
      /> */}
      </div>
    </Modal>
  );
};

export default EditExpeditionClassModal;
