// @flow
import * as React from "react";
import { AssembleAvatar } from "./assemble-avatar";
import { Flex, flexOpts } from "../../shared/styled/flex";
type Props = {
  vehicles: Array<{
    id?: number,
    description: string,
    showIcon: boolean,
    icon: any,
  }>,
  equipments: Array<{
    id: number,
    description: string,
    showIcon: boolean,
    icon: any,
  }>,
  operators: Array<{
    id: number,
    description: string,
    imageUrl: string,
    showIcon: boolean,
  }>,
};

class AssembleSummary extends React.Component<Props> {
  static defaultProps = {
    vehicles: [],
    equipments: [],
    operators: [],
  };
  render() {
    const { vehicles, equipments, operators } = this.props;
    return (
      <Flex
        direction={flexOpts.direction.column}
        style={{ width: "100%" }}
        {...this.props}
      >
        <Flex alignItems={flexOpts.alignItems.flexStart}>
          <div
            style={{
              alignItems: "center",
              lineHeight: "30px",
              width: "5rem",
              textAlignLast: "right",
            }}
          >
            Vehículo(s):
          </div>
          <div>
            {vehicles.map((ve) => (
              <AssembleAvatar
                key={ve.id}
                icon={ve.icon}
                showIcon={ve.showIcon}
                description={ve.description}
                fleetTypeId={1}
                color="#000000"
              />
            ))}
          </div>
        </Flex>
        <Flex alignItems={flexOpts.alignItems.flexStart}>
          <div
            style={{
              alignItems: "center",
              lineHeight: "30px",
              width: "5rem",
              minWidth: "5rem",
              textAlignLast: "right",
            }}
          >
            Equipo(s):
          </div>
          <Flex wrap={flexOpts.wrap.wrap}>
            {equipments.map((eq) => (
              <AssembleAvatar
                icon={eq.icon}
                showIcon={eq.showIcon}
                key={eq.id}
                description={eq.description}
                fleetTypeId={2}
                color="#000000"
              />
            ))}
          </Flex>
        </Flex>
        <Flex alignItems={flexOpts.alignItems.flexStart}>
          <div
            style={{
              alignItems: "center",
              lineHeight: "30px",
              width: "5rem",
              textAlignLast: "right",
            }}
          >
            Operador:
          </div>
          <div>
            {operators.map((op) => (
              <AssembleAvatar
                showIcon={op.showIcon}
                key={op.id}
                description={op.description}
                fleetTypeId={3}
                color="#000000"
                avatarUrl={op.imageUrl}
              />
            ))}
          </div>
        </Flex>
      </Flex>
    );
  }
}

export default AssembleSummary;
