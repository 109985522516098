import React from 'react';
import PrevSwitch from '../controls/prev-switch';

class SwitchElementRow extends React.Component {
    render() {
        return (
            <div className="elem-switch-row">
                <div>
                    <img alt="" src={this.props.user.avatar}/>
                    {this.props.user.name} {this.props.user.lastName}
                    <div className="right-align">
                        {this.props.user.type}
                        <PrevSwitch checked={true} handleClick={this.props.onChange}/>
                    </div>
                </div>
                <div>
                    {this.props.user.email}
                </div>
            </div>
        )
    }
}

export default SwitchElementRow;

SwitchElementRow.defaultProps = {
    user: {
        name: '',
        lastName: '',
        avatar: '/images/help.png',
        type: '',
        email: ''
    },
    onChange: () => { console.log('Not implemented yet!') }
}