import React from "react";
const EquipmentMinIcon = (props) => {
  return (
    <svg 
      width="49px" 
      height="37px"
      viewBox="0 0 49 37"
      // version="1.1" 
      // xmlns="http://www.w3.org/2000/svg" 
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="#252E47"
      opacity="0.5"
      transform="translate(7.000000, 7.000000)"
      stroke-width="1"
      {...props}
    >
      <path d="M32.6722186,0 C33.4035164,0 34,0.56310585 34,1.25348189 L34,16.7465181 C34,17.4368942 33.4035164,18 32.6722186,18 L13.2640385,18 C13.276139,17.8775891 13.2813262,17.7535487 13.2813262,17.6295042 C13.2813262,15.3428607 11.3155902,13.4887521 8.89513331,13.4887521 C6.47295029,13.4887521 4.50894037,15.3444903 4.50894037,17.6295042 C4.50894037,17.7535487 4.51585811,17.8775891 4.52622808,18 L1.32778116,18 C0.596483444,18 0,17.4368942 0,16.7465181 L0,1.25348189 C0,0.56310585 0.596483444,0 1.32778116,0 L32.6722186,0 Z" id="Fill-1"></path>
      <path d="M9,14.5 C8.03317865,14.5 7.15820441,14.8916328 6.5249186,15.5249186 C5.89163279,16.1582044 5.5,17.0331786 5.5,18 C5.5,18.9668214 5.89163279,19.8417956 6.5249186,20.4750814 C7.15820441,21.1083672 8.03317865,21.5 9,21.5 C9.96682135,21.5 10.8417956,21.1083672 11.4750814,20.4750814 C12.1083672,19.8417956 12.5,18.9668214 12.5,18 C12.5,17.0331786 12.1083672,16.1582044 11.4750814,15.5249186 C10.8417956,14.8916328 9.96682135,14.5 9,14.5 Z" id="Fill-4" stroke="#FFFFFF"></path>
      <polygon id="Triangle" points="30.5 13 33 22 28 22"></polygon>
    </svg>
  );
};

export default EquipmentMinIcon;