//@flow
import * as React from "react";
import AssembleShipmentList from "./assemble-shipment-list";
import AssembleShipmentGrid from "./assemble-shipment-grid";
import * as SharedTypes from "../../shared/types";
import { connect } from "react-redux";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import AssembleShipmentAppointments from "./assemble-shipment-appointments";
import AssembleShipmentOrdersList from "./assemble-shipment-orders-list";
import { getEntityItems, readEntities } from "../../redux/api";

type State = {
  viewMode: "list" | "grid" | "appointments" | "orders",
};
type Props = {};

class AssembleShipmentView extends React.Component<Props, State> {
  state = {
    viewMode: "list",
    urlFacilities: '',
    tabFilters: null
  };

  componentDidMount() {
    this.props.loadFilters({
      company_id: this.props.companyId,
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let urlFacilities = urlParams.get('facilities');
    if(urlFacilities) urlFacilities = urlFacilities.split(",")
    this.state.urlFacilities = urlFacilities;
    let orders = urlParams.get('orders');
    if(orders){
      this.setState({ viewMode: 'orders' });
    } else {
      this.setState({ viewMode: this.props.configuration?.viewMode || 'list' });
    }
  }
  componentWillUnmount() {
    this.props.setBaseConfig({ ...this.state });
  }
  setTabFilters = (filters) => {
    this.setState({tabFilters: filters})
  }

  onChange = (e: SharedTypes.SimpleEvent) => {
    const name = e.target.attributes["name"]?.value;
    if (name) {
      this.setState({ viewMode: name });
    }
  };

  render() {
    const { viewMode } = this.state;
    return viewMode === "list" ? (
      <AssembleShipmentList 
        viewMode={viewMode} 
        onChange={this.onChange} 
        history={this.props.history}
        filterInfo={this.props.filterInfo}
        tabFilters={this.state.tabFilters}
        saveFilters={this.setTabFilters}
      />
    ) : viewMode === "grid" ? (
      <AssembleShipmentGrid 
        viewMode={viewMode} 
        onChange={this.onChange} 
        history={this.props.history}
        filterInfo={this.props.filterInfo}
      />
    ) : viewMode === "appointments" ? <AssembleShipmentAppointments 
          viewMode={viewMode} 
          onChange={this.onChange} 
          history={this.props.history}
          urlFacilities={this.state.urlFacilities}
          filterInfo={this.props.filterInfo}
          facilities={this.props.facilities}
        /> 
      : viewMode === "orders" ? <AssembleShipmentOrdersList
          viewMode={viewMode} 
          onChange={this.onChange} 
          history={this.props.history}
          urlFacilities={this.state.urlFacilities}
          filterInfo={this.props.filterInfo}
          facilities={this.props.facilities}
          tabFilters={this.state.tabFilters}
          saveFilters={this.setTabFilters}
        /> 
      : 
      <></>
  }
}

// export default AssembleShipmentView;

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const filterInfo = getEntityItems(state, "COMPANIES.SHIPMENTS.LT.FILTERS");
  const facilities = filterInfo.map(f => f.facilities).flat();
  return {
    configuration: state.configuration.AssembleShipmentView,
    companyId,
    filterInfo,
    facilities
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBaseConfig: (config) => dispatch(setConfig("AssembleShipmentView", config)),
    loadFilters: (params, opt) => 
      dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opt)),
  }
}

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(AssembleShipmentView);
