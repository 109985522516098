// @flow
import * as React from "react";

import * as SharedTypes from "../../shared/types";

import { SummaryPanel } from "./styled/summary-panel";
import CalendarSelectorRibbon from "./styled/calendar-selector-ribbon";
import { getRibbonDates } from "./utils";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { CollapsibleHeaderHeat } from "./styled/collapsible-header";

type Props = {};
type State = { currentDate: Date, selectedDate: Date, selectedDateId: string };

class ShipmentSummaryHeatmap extends React.Component<Props, State> {
  state = {
    currentDate: new Date(),
    selectedDate: new Date(),
    selectedDateId: new Date().toISOString().split("T")[0],
  };
  render() {
    return (
      <SummaryPanel>
        <div style={{ width: "100%", height: "auto", color: "white" }}>
          <CalendarSelectorRibbon
            bgColor="#212A3C"
            selectedUnderlineColor="#E9643F"
            // className="profile-calendar-ribbon"
            onChange={this.handleChangeDay}
            items={getRibbonDates(this.state.currentDate, "WEEKDAY")}
            activeId={this.state.selectedDateId}
          />

          <Flex
            direction={flexOpts.direction.reverseRow}
            style={{ width: "100%" }}
          >
            <Flex style={{ width: "85%", height: "40px" }}>
              {["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"].map((d) => (
                <Flex
                  justifyContent={flexOpts.justifyContent.center}
                  style={{
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  {d}
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex
            direction={flexOpts.direction.column}
            style={{
              width: "100%",
              minHeight: "400px",
            }}
          >
            {ROWS.map((row) => (
              <CollapsibleHeaderHeat
                maxExpandedHeight="300px"
                // contentStyle={{
                //   display: "flex",
                //   flexDirection: "column",
                //   width: "100%",
                // }}
                collapsedHeight="40px"
                title={<div className="title">{row.title}</div>}
                caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                initialStateCollapsed={false}
                headerContent={
                  <div
                    style={{
                      width: "85%",
                      display: "flex",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {row.summaryData.map((c) => (
                      <div
                        style={{
                          width: "100%",
                          height: "32px",
                          margin: "1px",
                          backgroundColor: REFERENCE.find(
                            (r) => c >= r.min && c <= r.max
                          ).color,
                        }}
                      ></div>
                    ))}
                  </div>
                }
              >
                {row.content.map((data) => (
                  <Flex>
                    <Flex
                      justifyContent={flexOpts.justifyContent.flexEnd}
                      style={{
                        width: "15%",
                        // height: "30px",
                        margin: "auto",
                      }}
                    >
                      {data.title}
                    </Flex>
                    <Flex
                      style={{
                        backgroundColor: "green",
                        width: "85%",
                        height: "30px",
                      }}
                    >
                      {data.data.map((d) => (
                        <div
                          style={{
                            width: "100%",
                            height: "30px",
                            margin: "1px",
                            backgroundColor: REFERENCE.find(
                              (r) => d >= r.min && d <= r.max
                            ).color,
                          }}
                        ></div>
                      ))}
                    </Flex>
                  </Flex>
                ))}
              </CollapsibleHeaderHeat>
            ))}
          </Flex>
          <Flex
            justifyContent={flexOpts.justifyContent.flexEnd}
            style={{ width: "auto", height: "40px" }}
          >
            <div
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
                letterSpacing: "0",
                lineHeight: "25px",
                marginRight: "6px",
              }}
            >
              % de utilización
            </div>
            {REFERENCE.concat([
              { min: 200, max: 200, color: "transparent" },
            ]).map((r) => (
              <div
                style={{
                  borderBottom: `solid 8px ${r.color}`,
                  width: "60px",
                  height: "20px",
                  color: "white",
                }}
              >
                <div
                  style={{ transform: "translate(-50%)", width: "fit-content" }}
                >
                  {r.min}
                </div>
              </div>
            ))}
          </Flex>
        </div>
      </SummaryPanel>
    );
  }

  handleChangeDay = (e: SharedTypes.SimpleEvent) => {
    const { currentDate } = this.state;
    // const { loadFacilities } = this.props;
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      });
      // loadFacilities(d.toISOString().split("T")[0]);
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        }
        // () => loadFacilities(d.toISOString().split("T")[0])
      );
    }
  };
}

export default ShipmentSummaryHeatmap;

const REFERENCE = [
  { min: 0, max: 24, color: "#BFFEDF" },
  { min: 25, max: 49, color: "#6BE6A9" },
  { min: 50, max: 74, color: "#09CF6C" },
  { min: 75, max: 99, color: "#009940" },
  { min: 100, max: 124, color: "#FDF6C4" },
  { min: 125, max: 149, color: "#F9BA7E" },
  { min: 150, max: 174, color: "#FF5A60" },
  { min: 175, max: 200, color: "#FF4259" },
];

const ROWS = [
  {
    title: "OCCIDENTE",
    summaryData: [10, 5, 120, 90, 80, 100, 123],
    content: [{ title: "FE00-GDL", data: [10, 23, 34, 54, 55, 35, 6] }],
  },
  {
    title: "CENTRO",
    summaryData: [130, 55, 10, 9, 8, 10, 120],
    content: [{ title: "FE05-CENTRO", data: [78, 93, 52, 89, 23, 62, 35] }],
  },
];
