import {
  BACKEND,
  putMultipartApiUrl,
  getApiUrl,
  patchMultipartApiUrl
} from "../../shared/backend-api";

export const REQUEST_USER = "REQUEST_USER";
export const ERROR_REQUEST_USER = "ERROR_REQUEST_USER";
export const RECEIVE_REQUEST_USER = "RECEIVE_REQUEST_USER";

export const REQUEST_INVITATIONS = "REQUEST_INVITATIONS";
export const ERROR_REQUEST_INVITATIONS = "ERROR_REQUEST_INVITATIONS";
export const RECEIVE_REQUEST_INVITATIONS = "RECEIVE_REQUEST_INVITATIONS";

export const SEND_INVITATION = "SEND_INVITATION";
export const ERROR_SEND_INVITATION = "ERROR_SEND_INVITATION";
export const RECEIVE_SEND_INVITATION = "RECEIVE_SEND_INVITATION";

export const REQUEST_COMPANIES = "REQUEST_COMPANIES";
export const ERROR_REQUEST_COMPANIES = "ERROR_REQUEST_COMPANIES";
export const RECEIVE_REQUEST_COMPANIES = "RECEIVE_REQUEST_COMPANIES";

export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const ERROR_REGISTER_ACCOUNT = "ERROR_REGISTER_ACCOUNT";
export const RECEIVE_REGISTER_ACCOUNT = "RECEIVE_REGISTER_ACCOUNT";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const ERROR_UPDATE_ACCOUNT = "ERROR_UPDATE_ACCOUNT";
export const RECEIVE_UPDATE_ACCOUNT = "RECEIVE_UPDATE_ACCOUNT";

export const requestCompanies = () => ({
  type: REQUEST_COMPANIES
});

const requestUser = () => ({
  type: REQUEST_USER
});

const errorRequestUser = error => ({
  type: ERROR_REQUEST_USER,
  error: error
});

const receiveRequestUser = json => ({
  type: RECEIVE_REQUEST_USER,
  data: json
});

const sendInvitation = () => ({
  type: SEND_INVITATION
});

const errorSendInvitation = error => ({
  type: ERROR_SEND_INVITATION,
  error: error
});

const receiveSendInvitation = json => ({
  type: RECEIVE_SEND_INVITATION,
  data: json
});

const requestData = () => ({
  type: REQUEST_COMPANIES
});

const errorData = error => ({
  type: ERROR_REQUEST_COMPANIES,
  error: error
});

const receiveCompanies = json => ({
  type: RECEIVE_REQUEST_COMPANIES,
  data: json
});

const requestInvitations = () => ({
  type: REQUEST_INVITATIONS
});

const receiveInvitations = json => ({
  type: RECEIVE_REQUEST_INVITATIONS,
  data: json
});

const errorInvitations = error => ({
  type: ERROR_REQUEST_INVITATIONS,
  error: error
});

const receiveRegisterAccount = json => ({
  type: RECEIVE_REGISTER_ACCOUNT,
  data: json
});

const registerAccount = params => ({
  type: REGISTER_ACCOUNT,
  params: params
});

const errorRegisterAccount = error => ({
  type: ERROR_REGISTER_ACCOUNT,
  error: error
});

const receiveUpdateAccount = json => ({
  type: RECEIVE_UPDATE_ACCOUNT,
  data: json
});

const updateAccount = params => ({
  type: UPDATE_ACCOUNT,
  params: params
});

const errorUpdateAccount = error => ({
  type: ERROR_UPDATE_ACCOUNT,
  error: error
});

const initialState = {
  suggestedCompanies: [],
  acceptedCompanies: [],
  error: null,
  isFetching: false,
  accountData: null,

  isFetchingEmail: false,
  emailSent: false,
  errorEmail: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_REQUEST_COMPANIES:
      return {
        ...state,
        isFetching: false,
        suggestedCompanies: Array.isArray(action.data) ? action.data : [],
        error: null
      };

    case RECEIVE_REQUEST_INVITATIONS:
      return {
        ...state,
        isFetching: false,
        acceptedCompanies: Array.isArray(action.data) ? action.data : [],
        error: null
      };

    case REQUEST_COMPANIES:
      return {
        ...state,
        companies: testCompanies
      };

    case UPDATE_ACCOUNT:
    case REGISTER_ACCOUNT:
      return {
        ...state,
        isFetching: true
      };

    case SEND_INVITATION:
      return {
        ...state,
        isFetchingEmail: true,
        emailSent: false
      };

    case RECEIVE_SEND_INVITATION:
      return {
        ...state,
        isFetchingEmail: false,
        emailSent: true
      };

    case ERROR_SEND_INVITATION:
      return {
        ...state,
        isFetchingEmail: false,
        emailSent: false
      };

    case ERROR_REQUEST_INVITATIONS:
    case ERROR_UPDATE_ACCOUNT:
    case ERROR_REGISTER_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case RECEIVE_UPDATE_ACCOUNT:
    case RECEIVE_REGISTER_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        accountData: action.data,
        error: null
      };

    default:
      return state;
  }
}

export function getSuggestedCompanies() {
  return function(dispatch) {
    dispatch(requestData());
    return getApiUrl(BACKEND.PUBLIC.SUGGESTED_COMPANIES)
      .then(
        response => response.json(),
        error => dispatch(errorData(error))
      )
      .then(data => dispatch(receiveCompanies(data)));
  };
}

export function getUser(userId, companyId) {
  return function(dispatch) {
    dispatch(requestUser());
    // return getApiUrl(BACKEND.PUBLIC.GET_USERS, { id: id.id })
    return getApiUrl(
      BACKEND.PUBLIC.USERS + userId + "/?company_id=" + companyId
    )
      .then(
        response => response.json(),
        error => dispatch(errorRequestUser(error))
      )
      .then(data => dispatch(receiveRequestUser(data)));
  };
}

export function getAcceptedCompanies() {
  return function(dispatch) {
    dispatch(requestInvitations());
    return getApiUrl(BACKEND.PUBLIC.ACCEPTED_COMPANIES)
      .then(
        response => response.json(),
        error => dispatch(errorInvitations(error))
      )
      .then(data => dispatch(receiveInvitations(data)));
  };
}

export function sendInvitationCompany(user_id, request_company) {
  return function(dispatch) {
    dispatch(sendInvitation());
    return getApiUrl(BACKEND.PUBLIC.SEND_INVITATION, {
      user_id,
      request_company
    })
      .then(
        response => response.json(),
        error => dispatch(errorSendInvitation(error))
      )
      .then(data => dispatch(receiveSendInvitation(data)));
  };
}

export function patchRegisterAccount(params) {
  return function(dispatch) {
    dispatch(registerAccount(params));
    let formData = new FormData();
    formData.append("first_name", params.first_name);
    formData.append("last_name", params.last_name);
    formData.append("companies", params.companies);
    formData.append("user_name", params.user_name);
    formData.append("email", params.email);
    if (
      params.profile_image &&
      !String(params.profile_image).includes("http")
    ) {
      formData.append("profile_image", params.profile_image);
    }

    return patchMultipartApiUrl(
      BACKEND.PUBLIC.USERS + `${params.userId}/`,
      formData
    )
      .then(
        response => response.json(),
        error => dispatch(errorRegisterAccount(error))
      )
      .then(data => dispatch(receiveRegisterAccount(data)));
  };
}

export function putUpdateAccount(userId, email, params) {
  return function(dispatch) {
    dispatch(updateAccount(params));
    let formData = new FormData();

    formData.append("first_name", params.first_name);
    formData.append("email", email);
    formData.append("username", params.user_name);
    formData.append("last_name", params.last_name);
    formData.append("companies", params.companies);
    if (
      params.profile_image &&
      !String(params.profile_image).includes("http")
    ) {
      formData.append("profile_image", params.profile_image);
    }

    return putMultipartApiUrl(
      BACKEND.PUBLIC.USERS + String(userId) + "/",
      formData
    )
      .then(response => response.json())
      .then(response => dispatch(receiveUpdateAccount(response)))
      .catch(error => error.then(e => dispatch(errorUpdateAccount(e))));
  };
}

/// Companies dummies

const testCompanies = [];
