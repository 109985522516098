import React from 'react';
import { validateWebDomain } from '../validate';

class InputCompany extends React.Component {
  static defaultProps = {
    companies: []
  }

  state = {
    value: ''
  }

  separateInputs = (e) => {
    if(this.props.domain){
      // let emails = e.target.value.split(/[ ,]/gi)
      // .filter((v, i, s) =>
      //   s.indexOf(v) === i
      // )
      let emails = [e.target.value]
      .filter(f => validateWebDomain(f.trim()) && !this.isRepeated(f.trim()))
      .map(m => ({ email: m }))
      this.props.onChange({ target: { value: this.props.tags.concat(emails), name: 'inviteTags' } });
      this.handleInput({ target: { value: '' } })
    } else {
      // let companies = e.target.value.split(/[ ,]/gi)
      // .filter((v, i, s) =>
      //   s.indexOf(v) === i
      // )
      let companies = [e.target.value]
      .filter(f => f.length > 0 && !this.isRepeated(f.trim()))
      .map(m => ({ company: m }))
      this.props.onChange({ target: { value: this.props.companies.concat(companies), name: 'inviteCompanies' } });
      this.handleInput({ target: { value: '' } })
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.separateInputs(e);
    }
  }

  handleInput = (e) => {
    this.setState({ value: e.target.value });
  }

  isRepeated = (company) => {
    if(this.props.domain){
      return this.props.tags.find(t => t.email === company) ? true : false;
    } else {
      return this.props.companies.find(t => t.company === company) ? true : false;
    }
  }

  deleteCompany = (company) => {
    this.props.onChange({ target: { name: 'deleteCompanies', value: this.props.companies.filter(f => f.company !== company) } });
  }

  hasValue = value => {
    return value && value.toString().length ? true : false;
  };

  render() {
    return (
      <div className='input-company'>
        <input
          type="text"
          className={'inputText'.concat(this.hasValue(this.state.value) ? " has-input" : "")}
          value={this.state.value}
          onChange={this.handleInput}
          // onBlur={this.separateInputs}
          onKeyDown={this.handleKeyDown}
          // list="listamodelos"
        />
        <ul className={"autocomplete".concat(this.state.value.length ? '' : ' hidden')}>
          {
            this.props.companiesCatalog.filter(c => {
              return (
                (c.name || '').toLowerCase().includes((this.state.value || '').toLowerCase()) 
                || 
                (c.alias || '').toLowerCase().includes((this.state.value || '').toLowerCase())
              );
            }).map(c => {
              return <li onClick={() => {this.separateInputs({target: {value: c.name}});} }>{c.name} - {c.alias}</li>
            })
          }
        </ul>
        <span className="search-floating-label">{this.props.placeholder || ''}</span>
        {
          !this.props.domain &&
            <img alt='' src='/images/search.png' className='search-icon'/>
        }
      </div>
    )
  }
}

export default InputCompany;

InputCompany.defaultProps = {
  onChange: () => {console.log('Not yet implemented.')},
  companies: [],
  companiesCatalog: []
}