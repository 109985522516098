import React, { Component } from "react";
import { getUrlsEnv } from "../backend-api";

let filesPath = getUrlsEnv().files;

class TruckIconStatus extends Component {
  getAvatar = props => {
    if (props.image !== null && props.image !== "") {
      return filesPath.concat(props.image);
    } else {
      return "/" + props.avatar;
    }
  };

  render() {
    return (
      <div className={"truck-icon"}>
        <img
          className={"icon-image"}
          src={this.getAvatar(this.props)}
          alt={""}
          onError={e => {
            e.target.onerror = null;
            e.target.src = "/images/factory-bckgrnd.png";
          }}
        />

        <div className={"status-container"}>
          <div className={"company"}>{this.props.title}</div>

          <div style={{ display: "flex", marginTop: "-3px" }}>
            <img
              className={"status-icon"}
              src={this.props.statusIcon}
              alt={""}
              onError={e => {
                e.target.onerror = null;
                e.target.src = "/images/factory-bckgrnd.png";
              }}
            />
            <div className={"status"}>{this.props.statusLabel}</div>
          </div>

          <div className={"company"}>{this.props.date}</div>
        </div>
      </div>
    );
  }
}

export default TruckIconStatus;
