import { BACKEND, getApiUrl, postApiUrl, putApiUrl, deleteApiUrl } from '../../shared/backend-api';
import store from '../store';
// import store from '../store';


export const REQUEST_FACILITY_TAGS = 'REQUEST_FACILITY_TAGS';
export const RECEIVE_FACILITY_TAGS = 'RECEIVE_FACILITY_TAGS';
export const ERROR_FACILITY_TAGS = 'ERROR_FACILITY_TAGS';

export const REQUEST_CREATE_TAG = 'REQUEST_CREATE_TAG';
export const SUCCESS_CREATE_TAG = 'SUCCESS_CREATE_TAG';
export const ERROR_CREATE_TAG = 'ERROR_CREATE_TAG';

export const REQUEST_UPDATE_TAG = 'REQUEST_UPDATE_TAG';
export const SUCCESS_UPDATE_TAG = 'SUCCESS_UPDATE_TAG';
export const ERROR_UPDATE_TAG = 'ERROR_UPDATE_TAG';

export const REQUEST_DELETE_TAG = 'REQUEST_DELETE_TAG';
export const SUCCESS_DELETE_TAG = 'SUCCESS_DELETE_TAG';
export const ERROR_DELETE_TAG = 'ERROR_DELETE_TAG';

const requestFacilityTags = () => ({
  type: REQUEST_FACILITY_TAGS
});
const receiveFacilityTags = (json) => ({
  type: RECEIVE_FACILITY_TAGS,
  data: json
});
const errorFacilityTags = (error) => ({
  type: ERROR_FACILITY_TAGS,
  error: error
});

const TAG = {
  GET: {
    REQUEST: {
      TYPE: 'TAG_GET_REQUEST',
      ACTION: () => ({
        type: TAG.GET.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'TAG_GET_SUCCESS',
      ACTION: response => ({
        type: TAG.GET.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'TAG_GET_ERROR',
      ACTION: error => ({
        type: TAG.GET.ERROR.TYPE,
        error
      })
    }
  },
  POST: {
    REQUEST: {
      TYPE: 'TAG_POST_REQUEST',
      ACTION: () => ({
        type: TAG.POST.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'TAG_POST_SUCCESS',
      ACTION: response => ({
        type: TAG.POST.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'TAG_POST_ERROR',
      ACTION: error => ({
        type: TAG.POST.ERROR.TYPE,
        error
      })
    }
  },
  PUT: {
    REQUEST: {
      TYPE: 'TAG_PUT_REQUEST',
      ACTION: () => ({
        type: TAG.PUT.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'TAG_PUT_SUCCESS',
      ACTION: response => ({
        type: TAG.PUT.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'TAG_PUT_ERROR',
      ACTION: error => ({
        type: TAG.PUT.ERROR.TYPE,
        error
      })
    }
  },
  DELETE: {
    REQUEST: {
      TYPE: 'TAG_DELETE_REQUEST',
      ACTION: () => ({
        type: TAG.DELETE.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'TAG_DELETE_SUCCESS',
      ACTION: response => ({
        type: TAG.DELETE.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'TAG_DELETE_ERROR',
      ACTION: error => ({
        type: TAG.DELETE.ERROR.TYPE,
        error
      })
    }
  },
  TYPE: {
    GET: {
      REQUEST: {
        TYPE: 'TAG_TYPE_GET_REQUEST',
        ACTION: () => ({
          type: TAG.TYPE.GET.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: 'TAG_TYPE_GET_SUCCESS',
        ACTION: response => ({
          type: TAG.TYPE.GET.SUCCESS.TYPE,
          response
        })
      },
      ERROR: {
        TYPE: 'TAG_TYPE_GET_ERROR',
        ACTION: error => ({
          type: TAG.TYPE.GET.ERROR.TYPE,
          error
        })
      }
    }
  }
}



const initialState = {
  isFetching: false,
  error: '',
  facilityTags: [],
  tags: [],
  tagTypes: {
    isFetching: false,
    error: '',
    data: []
  }
};


export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_FACILITY_TAGS:
      return Object.assign({}, state, {
        isFetching: true
      })
    case RECEIVE_FACILITY_TAGS:
      return Object.assign({}, state, {
        isFetching: false,
        facilityTags: action.data
      })
    case ERROR_FACILITY_TAGS:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })

    case TAG.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        isFetching: true
      })
    case TAG.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        isFetching: false,
        tags: action.response
      })
    case TAG.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })

    case TAG.TYPE.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        tagTypes: {
          isFetching: true
        }
      })
    case TAG.TYPE.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        tagTypes: {
          isFetching: false,
          data: action.response
        }
      })
    case TAG.TYPE.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        tagTypes: {
          isFetching: false,
          error: action.error
        }
      })

    default: {
      return state;
    }
  }
}

export function loadFacilityTags() {
  return function (dispatch) {
    dispatch(requestFacilityTags())
    return getApiUrl(BACKEND.PUBLIC.TAG_FACILITY)
      .then(
        response => response.json(),
        error => dispatch(errorFacilityTags(error))
      )
      .then(
        data => dispatch(receiveFacilityTags(data))
      )
  }
}

export function loadTags() {
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    loadTagTypes(dispatch);
    dispatch(TAG.GET.REQUEST.ACTION)
    return getApiUrl(BACKEND.TAG.GET, { company_id: companyId })
      .then(
        response => response.json(),
        error => dispatch(TAG.GET.ERROR.ACTION(error))
      ).then(
        response => dispatch(TAG.GET.SUCCESS.ACTION(response))
      )
  }
}

export function createTag(tagParams) {
  return function (dispatch) {
    dispatch(TAG.POST.REQUEST.ACTION)
    return postApiUrl(BACKEND.TAG.POST, tagParams)
      .then(
        response => response.json(),
        error => dispatch(TAG.POST.ERROR.ACTION(error))
      ).then(
        response => dispatch(TAG.POST.SUCCESS.ACTION(response))
      )
  }
}

export function updateTag(tagParams) {
  return function (dispatch) {
    dispatch(TAG.PUT.REQUEST.ACTION)
    return putApiUrl(BACKEND.TAG.PUT, tagParams)
      .then(
        response => response.json(),
        error => dispatch(TAG.PUT.ERROR.ACTION(error))
      ).then(
        response => dispatch(TAG.PUT.SUCCESS.ACTION(response))
      )
  }
}

export function deleteTag(tagId) {
  return function (dispatch) {
    dispatch(TAG.DELETE.REQUEST.ACTION)
    return deleteApiUrl(BACKEND.TAG.DELETE, { tag_id: tagId })
      .then(
        response => dispatch(TAG.DELETE.SUCCESS.ACTION(response)),
        error => dispatch(TAG.DELETE.ERROR.ACTION(error))
      )
  }
}

const loadTagTypes = (dispatch) => {
  let storeItems = store.getState().tags.tagTypes;
  if (!storeItems.isFetching && storeItems.data.length) {
    return;
  } else {
    dispatch(TAG.TYPE.GET.REQUEST.ACTION())
    return getApiUrl(BACKEND.TAG.TYPE.GET)
      .then(
        response => response.json(),
        error => dispatch(TAG.TYPE.GET.ERROR.ACTION(error))
      ).then(
        response => dispatch(TAG.TYPE.GET.SUCCESS.ACTION(response))
      )
  }
}