import React from "react";
import { connect } from "react-redux";
import Button from "../../shared/controls/button";
import WorkflowStepControl from "./workflow-step-control";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import FacilityData from "./facility-data";
import {
  showStep,
  completeStep
} from "../../redux/reducers/facility-create.reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import ErrorDialog from "../../shared/dialog/error-dialog";
import FacilityUsers from "./facility-users";
import FacilityGeofences from "./facility-geofences";
import FacilitySchedules from "./facility-schedules";
import FacilityConfig from "./facility-config";

import { withRouter } from "react-router-dom";
import {
  createFacility,
  updateFacility
} from "../../redux/reducers/facility-reducer";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { createEntity, partialEntity } from "../../redux/api";
import ReactTooltip from "react-tooltip";

class FacilityCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      validity: false,
      showError: false,
      errorMsg: '',
      facility: {
        id: this.props.facility.id || 0,
        //id: 16,
        name: this.props.facility.name || "",
        name_unique: true,
        code: this.props.facility.code || "",
        code_unique: true,
        alias: this.props.facility.alias || "",
        alias_unique: true,
        country: 0, // México
        state:  0,
        city: 0,
        locality:  0,
        address:  "",
        exterior_number: '',
        interior_number: '',
        neighborhood: 0,
        zip_code_id: 0,
        zip_code:  "",
        phone:  "",
        time_zone: this.props.facility.time_zone || 50, // Ciudad de México
        avatar: this.props.facility.avatar || 2,
        tags: [],
        geopos_x: this.props.facility.geopos_x || 0,
        geopos_y: this.props.facility.geopos_y || 0,
      },
      geopos_x: 0,
      geopos_y: 0,
      onFocused: false
    };
  }

  actionSettingsExit = {
    type: "secondary white",
    text: "Salir",
    onClick: () => {
      this.setState({ openDialog: true });
    }
  };

  actionCloseDialog = () => {
    this.setState({
      openDialog: false,
      showError: false
    });
  };

  actionSettingsPrevious = {
    type: "primary outline",
    text: "Anterior",
    onClick: () => {
      this.props.showStep(
        this.props.currentStep > 1
          ? this.props.currentStep - 1
          : this.props.currentStep
      );
    }
  };

  getActionSettingsNext = () => {
    return {
      type: "primary",
      text: "Continuar",
      disabled: this.getErrors() !== '',
      onClick: () => {
        this.setState({ onFocused: true });
        if (this.state.validity) {
          this.setState({
            facility: Object.assign(this.state.facility, {
              geopos_x: this.state.geopos_x,
              geopos_y: this.state.geopos_y
            })
          });
          if (this.props.currentStep === 1) {
            this.saveFacility();
          } else if (this.props.currentStep === 3) {
            this.props.history.push("/company/facility");
          } else {
            this.props.completeStep(this.state.facility);
          }
        } else {
          this.setState({ showError: true });
        }
      }
    };
  }

  getErrors = () => {
    let errors = "";
    let facility = this.state.facility;

    errors += facility.name.length ? '' : `<b>Ingresa el nombre de la instalación<b/><br/>` 
    if (facility.name.length) {
      errors += facility.name_unique ? '' : `<b>Nombre de la instalación usado por otra instalación<b/><br/>` 
    }

    errors += facility.code.length ? '' : `<b>Ingresa el código<b/><br/>` 

    if (facility.code.length) {
      errors += facility.code_unique ? '' : `<b>Código usado por otra instalación<b/><br/>` 
    }

    errors += facility.alias_unique ? '' : `<b>Alias usado por otra instalación<b/><br/>` 

    errors += facility.zip_code.length ? '' : `<b>Ingresa el código postal<b/><br/>` 
    errors += parseInt(facility.state) ? '' : `<b>Ingresa el estado<b/><br/>` 
    errors += parseInt(facility.city) ? '' : `<b>Ingresa la ciudad<b/><br/>` 
    errors += facility.address.length ? '' : `<b>Ingresa el domicilio<b/><br/>` 
    errors += facility.exterior_number.length ? '' : `<b>Ingresa el num ext<b/><br/>` 
    errors += parseInt(facility.neighborhood) ? '' : `<b>Ingresa la colonia<b/><br/>` 

    if (errors !== '') {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`;
    }

    return errors;
  }

  saveFacility = () => {
    return new Promise(resolve => {
      if (this.state.facility.id) {
        this.props.updateFacility({
          company_id: this.props.companyId,
          facility_id: this.state.facility.id,
          name: this.state.facility.name,
          code: this.state.facility.code,
          alias: this.state.facility.alias,
          phone: this.state.facility.phone,
          time_zone: this.state.facility.time_zone,
          avatar: this.state.facility.avatar,
          tags: this.state.facility.tags,
          geopos_x: this.state.facility.geopos_x,
          geopos_y: this.state.facility.geopos_y,
          company: this.props.companyId,
          data: {
            street: this.state.facility.address,
            suburb: this.state.facility.neighborhood,
            state: this.state.facility.state,
            country: this.state.facility.country,
            exterior_number: this.state.facility.exterior_number,
            interior_number: this.state.facility.interior_number,
            city: this.state.facility.city,
            locality: this.state.facility.locality || null,
            zip_code: this.state.facility.zip_code_id 
          }
        }, {
          onSuccess: () => {
            resolve(true);
          },
          onError: () => {
            resolve(false)
          }
        })
        // this.props.updateFacility(this.state.facility).then(result => {
        //   if (result.response)
        //     resolve(true);
        //   else
        //     resolve(false);
        // });
      } else {
        this.props.createFacility({
          company_id: this.props.companyId,
          name: this.state.facility.name,
          code: this.state.facility.code,
          alias: this.state.facility.alias,
          phone: this.state.facility.phone,
          time_zone: this.state.facility.time_zone,
          avatar: this.state.facility.avatar,
          tags: this.state.facility.tags,
          geopos_x: this.state.facility.geopos_x,
          geopos_y: this.state.facility.geopos_y,
          company: this.props.companyId,
          data: {
            street: this.state.facility.address,
            suburb: this.state.facility.neighborhood,
            state: this.state.facility.state,
            country: this.state.facility.country,
            exterior_number: this.state.facility.exterior_number,
            interior_number: this.state.facility.interior_number,
            city: this.state.facility.city,
            locality: this.state.facility.locality || null,
            zip_code: this.state.facility.zip_code_id 
          }
        }, {
          onSuccess: (r) => {
            this.setState({
              facility: Object.assign(this.state.facility, {
                id: r.id
              })
            });
            resolve(true);
          },
          onError: () => {
            resolve(false)
          }
        })
        // this.props.createFacility(this.state.facility).then(result => {
        //   if (result.response) {
        //     this.setState({
        //       facility: Object.assign(this.state.facility, {
        //         id: result.response.id
        //       })
        //     });
        //     resolve(true);
        //   } else {
        //     resolve(false)
        //   }
        // })
      }
    }).then(success => {
      if (success)
        this.props.completeStep(this.state.facility);
      else
        this.setState({
          showError: true,
          errorMsg: 'Ocurrió un error al registrar tus datos, verifica tus datos'
        });
    });
  };

  quit = () => {
    this.setState({ openDialog: false });
    window.location.href = "/company/facility";
  };

  handleGeoposChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  resetStateCities = () => {
    const resetFacility = {...this.state.facility, state: 0, city: 0};
    this.setState({
      facility: resetFacility
    });
  }

  resetCities = () => {
    const resetFacility = {...this.state.facility, city: 0};
    this.setState({
      facility: resetFacility
    });
  }

  handleFacilityChange = event => {
    if (event.target.name.includes("geopos")) {
      // debugger;
      this.handleGeoposChange(event);
      return;
    }
    let property = event.target.name;
    let value = event.target.value;
    let newFacilityValue = Object.assign({}, this.state.facility, {
      [property]: value
    });

    if (property === 'alias' && value === '') {
      newFacilityValue = Object.assign({}, newFacilityValue, {
        alias_unique: true
      });
    }

    this.setState({
      facility: newFacilityValue
    });
    this.validateFacility(newFacilityValue);
  };

  validateFacility = facility => {
    let result = false;
    let message = '';
    if (facility.name.length && facility.code.length && facility.address.length && facility.zip_code.length && facility.exterior_number.length && parseInt(facility.state) && parseInt(facility.city) && parseInt(facility.time_zone) && parseInt(facility.neighborhood) && parseInt(facility.avatar)) {
      if (facility.name_unique && facility.code_unique && facility.alias_unique) {
        result = true;
      } else {
        message = 'Nombre, código o alias ya existen.';
      }
    } else {
      message = 'Falta información para completar tu instalación';
    }
    this.setState({ validity: result, errorMsg: message });
  };

  getCurrentView = () => {
    switch (this.props.currentStep) {
      case 1:
        return (
          <FacilityData
            facility={this.state.facility}
            onFacilityChange={this.handleFacilityChange}
            onFocused={this.state.onFocused}
            // resetState={ () => this.resetStateCities()}
            // resetCity={ () => this.resetCities()}
          />
        );
      case 2:
      //   return <FacilityUsers facilityId={this.props.facility.id} />;
        return <FacilityGeofences
          facility={this.state.facility}
          edit={false}
        />;
      case 3:
      // case 4:
      //   return <FacilityConfig facility={this.props.facility.id} />;
      // case 5:
        return <FacilitySchedules facilityId={this.state.facility.id} />;
      default:
        return <div />;
    }
  };

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  render() {
    return (
      <div className="facility-workflow">
        <div className="header">
          <div className="title">Registro de instalación</div>
          <div className="steps">
            <WorkflowStepControl />
          </div>
        </div>
        <div className="content">{this.getCurrentView()}</div>
        <div className="footer">
          <Button settings={this.actionSettingsExit} />
          {this.props.currentStep > 1 ? (
            <Button settings={this.actionSettingsPrevious} />
          ) : (
              ""
            )}
          <Button
            settings={Object.assign(
              this.getActionSettingsNext(),
              this.props.currentStep === 3 ? { text: "Finalizar" } : {}
            )}
            dataTip= {this.getErrors()}
            dataFor= "orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <ConfirmDialog
          open={this.state.openDialog}
          title="Salir"
          message="Guardaremos tus avances para cuando regreses"
          acceptText="Si, deseo salir"
          acceptAction={this.quit}
          closeAction={this.actionCloseDialog}
        />

        <ErrorDialog
          open={this.state.showError}
          // title="Error"
          message={this.state.errorMsg}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.globalCatalog.session.company.id,
    currentStep: state.facilityCreate.currentStep,
    lastStep: state.facilityCreate.lastStep,
    facility: state.facilityCreate.facility,
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadSession());
  // dispatch(loadFacilityTags());
  dispatch(loadTags());
  return {
    createFacility: (params, opt) =>{
      dispatch(
        createEntity("COMPANIES.FACILITIES.CREATE", params, opt) 
      );
    },
    updateFacility: (params, opt) =>{
      dispatch(
        partialEntity("COMPANIES.FACILITIES.UPDATE", params, opt) 
      );
    },
    showStep: step => dispatch(showStep(step)),
    completeStep: data => dispatch(completeStep(data)),
    // createFacility: facility => dispatch(createFacility(facility)),
    // updateFacility: facility => dispatch(updateFacility(facility))
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(connectToStore(FacilityCreate));
