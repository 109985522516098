import React from "react";
const Facility = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeWidth="1"
        fill="currentColor"
        fillrule="evenodd"
      >
        <path d="M14.3999616,14.4 L14.3999616,9.6 L4.7999616,14.4 L4.0004736,0 L0.8009856,0 L0,14.4 L0,24 L24,24 L24,9.6 L14.3999616,14.4 Z M7,22 L2,22 L2,19 L7,19 L7,22 Z M14,22 L9,22 L9,19 L14,19 L14,22 Z M22,22 L17,22 L17,19 L22,19 L22,22 Z"></path>
      </g>
    </svg>
  );
};
export default Facility;
