// @flow
import * as React from "react";
// import Tag from "../../controls/tag";
import { validationErrors } from "../validate";
import Checkbox from "./checkbox";
import TextInput from "./text-input";
import { normalizeStr } from "../utils";

type Props = {};

class SelectInputStyled extends React.Component<Props> {
  state = {
    isOpen: false,
    searchText: "",

    valid: true,
    validationErrors: []
  };

  static defaultProps = {
    selectedItems: [],
    items: [],
    onChange: () => {},
    className: '',
    name: '',
    label: '',
    multiple: false,
    disabled: false,
    showSearch: true,
    showDeselector: true
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target)) return;
    this.setState({ isOpen: false, searchText: "" });
  };

  toggleFilter = () => {
    this.setState({isOpen: !this.state.isOpen, searchText: ""});
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deselectAll = (e) => {
    this.props.onChange({ target: { name: e.target.name, value: [] } });
  };

  selectAll = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props/* .content
        .find((c) => c.name === e.target.name) */
        .items.forEach((fe) => {
          if ((fe.items || []).length > 0) {
            fe.items.forEach((ch) => {
              if (!st.includes(ch.id)) st.push(ch.id.toString());
            });
          } else {
            st.push(fe.id.toString());
          }
        });
    }
    this.props.onChange({ target: { name: e.target.name, value: st } });
  };

  handleSelectedItemsChange = (e, items) => {
    let st =
      (this.props.selectedItems || []).map((m) =>
        m.toString()
      ) || [];
    if ((items || {}).length > 0) {
      if(this.props.multiple) {
        items.forEach((item) => {
          let val = item.id.toString();
          st = st.filter((a) => a !== val);
          if (e.target.checked) {
            st = st.concat(val);
          }
        });
      }
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = this.props.multiple ? st.concat(e.target.value.toString()) : [e.target.value.toString()];
      }
    }
    this.props.onChange({ target: { name: e.target.name, value: st } });
  };

  handleFilterListSearch = (items, field, value = "") => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field]).includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    "description",
                    this.state.searchText
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field]).includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };

  getSelectedLabel = () => {
    if(!this.props.selectedItems.length)
      return ''
    else {
      let labels = this.props.items
        .map((i) => {
          if((i.items || []).length){
            return i.items
              .filter(ii => 
                this.props.selectedItems.includes(ii.id.toString())
              )
              .map(ii => 
                ii.description
              )
          }
          else {
            return this.props.selectedItems.includes(i.id.toString()) 
              ? i.description 
              : []
          }
        })
        .flat();
      labels = [...new Set(labels)]
      return labels.join(', ')
    }
  }

  render () {
    return (
      <div ref={node => (this.node = node)} className={"select-input select-input-styled ".concat(this.props.className || '', this.state.valid === false ? ' invalid' : ' valid')}>
        <label
          htmlFor={this.props.name}
          className={'label'.concat(this.state.isOpen || this.props.selectedItems.length ? ' focused' : '')}>
          {this.props.label}
        </label>
        <div
          className={"".concat(
            "list",
            this.state.isOpen ? " open" : "",
            (this.props.selectedItems || []).length > 0 ? " filtered" : ""
          )}
          onClick={(e) => {if(!this.props.disabled)this.toggleFilter()}}
          key={this.props.name}
        >
          <div className="select-title">
            {this.getSelectedLabel()}
          </div>
          <div className="caret">
            <img alt="" src="/images/caret.svg" />
          </div>
          <div className={"select-content"} onClick={(e) => e.stopPropagation()}>
            {
              this.props.showDeselector &&
              <div className="deselector">
                <span onClick={() => this.deselectAll({ target: { name: this.props.name } })}>
                  Limpiar
                </span>
              </div>
            }
            {this.props.showSearch && this.getTextSearch()}
            {
              this.props.multiple &&
              this.getSelectAll(this.props.name)
            }
            {/* {this.getSelectAll(this.props.name)} */}
            {this.checkList(this.props.items, this.props.name)}
          </div>
        </div>
        {/* <i className="caret"> </i> */}
        {
          this.state.valid === false &&
          <div className='text-input-invalid'>
            <img className='i-error' alt='' src='/images/red-exclamation.svg' />
            <p className="p-error">{this.state.validationErrors && this.state.validationErrors.length > 0 ? this.state.validationErrors[0] : validationErrors.required}</p>
          </div>
        }
      </div>
    )
  }

  checkList = (items, name) => {
    let counter = -9999;
    return (
      <div className="items checks">
        {
          // (items || []).map(i => {
          this.handleFilterListSearch(
            items || [],
            "description",
            this.state.searchText
          ).map((i) => {
            let l = [];
            l.push(
              <Checkbox
                key={counter++}
                onChange={(e) => this.handleSelectedItemsChange(e, i.items)}
                item={{
                  id: i.id,
                  description: i.description,
                  active: (this.props.selectedItems || []).find(
                    (si) => si.toString() === i.id.toString()
                  )
                    ? true
                    : false,
                }}
                name={name}
                className={((i.items || []).length ? "bold" : "").concat(!this.props.multiple && (i.items || []).length  ? " single" : "")}
              />
            );
            if ((i.items || []).length) {
              // this.handleFilterListSearch(i.items, 'description', this.state.searchText).forEach(ci =>
              i.items.forEach((ci) =>
                l.push(
                  <Checkbox
                    key={counter++}
                    className={"sub-item"}
                    onChange={(e) => this.handleSelectedItemsChange(e)}
                    item={{
                      id: ci.id,
                      description: ci.description,
                      active: (this.props.selectedItems || []).find(
                        (si) => si.toString() === ci.id.toString()
                      )
                        ? true
                        : false,
                    }}
                    disabled={ci.disabled}
                    name={name}
                  />
                )
              );
              l.push(<div className="separator" key={counter++} />);
              let full = true;
              i.items.forEach((fe) =>
                (this.props.selectedItems || []).find(
                  (si) => si.toString() === fe.id.toString()
                ) && full
                  ? (full = true)
                  : (full = false)
              );
              l[0].props.item.active = full;
            }
            return l;
          })
        }
      </div>
    );
  };

  getTextSearch = () => {
    return (
      <div className="search-list">
        <TextInput
          value={this.state.searchText}
          label="Buscar"
          name="searchText"
          onChange={this.handleChange}
        />
      </div>
    );
  };

  getSelectAll = (name) => {
    return (
      <React.Fragment>
        {" "}
        <Checkbox
          onChange={(e) => this.selectAll(e)}
          item={{
            id: 0,
            description: "Todos",
            active:
              (this.props.selectedItems || []).length > 0 &&
              (this.props.selectedItems || []).length ===
                (this.props/* .content
                  .find((c) => c.name === name) */
                  .items.some((i) => i.items)
                  ? this.props/* .content
                      .find((c) => c.name === name)
                      */ .items.map((i) => i.items.flat())
                      .flat().length
                    // .items.reduce((sum, i) => sum + i.items.length)
                  : this.props/* .content.find((c) => c.name === name) */.items
                      .length),
          }}
          name={name}
          className="bold"
        />
        <div className="separator" />
      </React.Fragment>
    );
  };
}

export default SelectInputStyled;