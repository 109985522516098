// Table Configuration
// Para agregar una nueva columna es necesario los siguientes pasos:
// Agregar su configuracion en el arreglo CategoriesData
// Agregar el tamaño en la funcion calculateTableWidth
// Agregar su id correspondiente en searchDataById y transformIdArrays
// Y en el archivo UnassignedOrderRow, agregar su respectivo view dependiendo su id, en la funcion getItemUsingIdConfig

const cellStyleCO = {
  height: "50px",
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  overflow: 'hidden',
};
const headerItemStyleCO = {
  color: "#afc8ff",
  fontSize: "16px",
  // lineHeight: "25px",
  textAlign: "left",
  height: "100%",
  // display: "flex",
  // alignItems: "center",
  lineHeight: "50px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
};

const headerStyleCO = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  backgroundColor: "#455471",
  borderBottom: "solid #A4B4CE 2px"
};
const rowStyleCO = {
  backgroundColor: "#455471",
  height: "60px",
  borderBottom: "solid 1px #A4B4CE",
  minHeight: "60px"
};

const contentStyleCO = {
  height: 'calc(100% - 250px)',
  overflowY: "auto",
}

const CategoriesData = [
  {
    id: 1,
    title: 'Orden',
    configuration: {
      idConfig: 1,
      text: "Orden",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0 
    }
  },
  {
    id: 2,
    title: 'Viaje',
    configuration: {
      idConfig: 2,
      text: "Viaje",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 3,
    title: '',//isCO ? 'Cliente' : 'Programa',
    configuration: {
      idConfig: 3,
      text: '',//isCO ? "Cliente" : "Programa",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 4,
    title: 'Transportista',
    configuration: {
      idConfig: 4,
      text: "Transportista",
      width: "210px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 5,
    title: 'Origen/Cita',
    configuration: {
      idConfig: 5,
      text: "Origen/Cita",
      width: "240px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 16,
    title: 'Inicio de viaje',
    configuration: {
      idConfig: 16,
      text: "Inicio de viaje",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 17,
    title: 'ETA Origen',
    configuration: {
      idConfig: 17,
      text: "ETA Origen",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 18,
    title: 'Llegada a Origen',
    configuration: {
      idConfig: 18,
      text: "Llegada a Origen",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 19,
    title: 'Entrada a Origen',
    configuration: {
      idConfig: 19,
      text: "Entrada a Origen",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 20,
    title: 'Salida de Origen',
    configuration: {
      idConfig: 20,
      text: "Salida de Origen",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 6,
    title: 'Destino/Cita',
    configuration: {
      idConfig: 6,
      text: "Destino/Cita",
      width: "240px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 21,
    title: 'ETA Destino',
    configuration: {
      idConfig: 21,
      text: "ETA Destino",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 22,
    title: 'Llegada a Destino',
    configuration: {
      idConfig: 22,
      text: "Llegada a Destino",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 23,
    title: 'Entrada a Destino',
    configuration: {
      idConfig: 23,
      text: "Entrada a Destino",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 24,
    title: 'Salida de Destino',
    configuration: {
      idConfig: 24,
      text: "Salida de Destino",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 7,
    title: 'Etiquetas',
    configuration: {
      idConfig: 7,
      text: "Etiquetas",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 8,
    title: 'Vehículo',
    configuration: {
      idConfig: 8,
      text: "Vehículo",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 25,
    title: 'Operador',
    configuration: {
      idConfig: 25,
      text: "Operador",
      width: "140px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 9,
    title: 'Equipos',
    configuration: {
      idConfig: 9,
      text: "Equipos",
      width: "140px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 10,
    title: 'Estatus equipo',
    configuration: {
      idConfig: 10,
      text: "Estatus equipo",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 11,
    title: 'Tiempo de estatus',
    configuration: {
      idConfig: 11,
      text: "Tiempo de estatus",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 12,
    title: 'Clase',
    configuration: {
      idConfig: 12,
      text: "Clase",
      width: "150px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 13,
    title: 'Estatus de orden',
    configuration: {
      idConfig: 13,
      text: "Estatus orden",
      width: "180px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 14,
    title: 'Nivel',
    configuration: {
      idConfig: 14,
      text: "Nivel",
      width: "100px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 1
    }
  },
  {
    id: 15,
    title: 'Tipo',
    configuration: {
      idConfig: 15,
      text: "Tipo",
      width: "100px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0
    }
  },
  {
    id: 26,
    title: 'Última conexión',
    configuration: {
      idConfig: 26,
      text: "Última conexión",
      width: "200px",
      headerItemStyle: headerItemStyleCO,
      cellStyle: cellStyleCO,
      menuType: 0 
    }
  }
];

// 1: orden
// 2: viaje
// 3: Programa/cliente
// 4: Transportista
// 5: Origen/Cita
// 16: Inicio de viaje
// 17: ETA Origen
// 18: Llegada a Origen
// 19: Entrada a Origen
// 20: Salida de Origen
// 6: Destino/Cita
// 21: ETA Destino
// 22: Llegada a Destino
// 23: Entrada a Destino
// 24: Salida de Destino
// 7: Etiquetas
// 8: Vehículo
// 25: Operador
// 9: Equipos
// 10: Estatus equipo
// 11: Tiempo de estatus
// 12: Clase
// 13: Estatus de orden
// 14: Nivel
// 15: Tipo
// 26: Ultima conexión

export const initialOrder = (arrayToOrder) => {
  let newArray = [];
  let orderArray = CategoriesData.map( item => item.id);

  orderArray.map( item => {
    arrayToOrder.map( item2 => {
      if (`${item2.id}` === `${item}`) {
        newArray.push(item2);
      }
    })
  });

  return newArray;
}

export const buildFilters = (filters) => {

  return {
    '4': (filters.lts || []).map(item => {
      let fleetProvider = (filters.fleetProviders || []).filter( fleet => fleet.network === item.id);

      return({
        id: fleetProvider.length > 0 ? fleetProvider[0].id : item.id,
        description: item.secondary_orbi_id
      });
    }),
    '14': filters.priorities,
    '13': filters.order_status,
    '7': filters.order_tags,
    '10': filters.containers_status,
    '12': filters.transport_class,
    '21': [
      // {
      //   id: 1,
      //   description: 'Mayor a menor',
      //   value: 'desc'
      // },
      // {
      //   id: 2,
      //   description: 'Menor a mayor',
      //   value: 'asc'
      // },
      {
        id: 3,
        description: 'Sin fecha',
        value: 'withoutDate'
      }
    ],
    '17': [
      // {
      //   id: 1,
      //   description: 'Mayor a menor',
      //   value: 'desc'
      // },
      // {
      //   id: 2,
      //   description: 'Menor a mayor',
      //   value: 'asc'
      // },
      {
        id: 3,
        description: 'Sin fecha',
        value: 'withoutDate'
      }
    ]
  };

}

export const transformIdArrays = (ids) => {
  let newArray = [];

  (ids || []).map( item => {

    switch(item) {
      case 24: case 9: newArray.push(1); break;
      case 25: case 10: newArray.push(2); break;
      case 26: case 11: newArray.push(3); break;
      case 27: case 12: newArray.push(4); break;
      case 28: case 13: newArray.push(5); break;
      case 29: case 14: newArray.push(6); break;
      case 30: case 15: newArray.push(7); break;
      case 31: case 16: newArray.push(8); break;
      case 32: case 17: newArray.push(9); break;
      case 33: case 18: newArray.push(10); break;
      case 34: case 19: newArray.push(11); break;
      case 35: case 20: newArray.push(12); break;
      case 36: case 21: newArray.push(13); break;
      case 37: case 22: newArray.push(14); break;
      case 38: case 23: newArray.push(15); break;
      case 48: case 39: newArray.push(16); break;
      case 49: case 40: newArray.push(17); break;
      case 50: case 41: newArray.push(18); break;
      case 51: case 42: newArray.push(19); break;
      case 52: case 43: newArray.push(20); break;
      case 53: case 44: newArray.push(21); break;
      case 54: case 45: newArray.push(22); break;
      case 55: case 46: newArray.push(23); break;
      case 56: case 47: newArray.push(24); break;
      case 59: case 58: newArray.push(25); break;
      case 60: case 61: newArray.push(26); break;
    }

  });

  return newArray;
}

const searchDataById = (id) => {

  let idToSearch = -1;
  let data = null;

  switch(id) {
    case 24: case 9: idToSearch = 1; break;
    case 25: case 10: idToSearch = 2; break;
    case 26: case 11: idToSearch = 3; break;
    case 27: case 12: idToSearch = 4; break;
    case 28: case 13: idToSearch = 5; break;
    case 29: case 14: idToSearch = 6; break;
    case 30: case 15: idToSearch = 7; break;
    case 31: case 16: idToSearch = 8; break;
    case 32: case 17: idToSearch = 9; break;
    case 33: case 18: idToSearch = 10; break;
    case 34: case 19: idToSearch = 11; break;
    case 35: case 20: idToSearch = 12; break;
    case 36: case 21: idToSearch = 13; break;
    case 37: case 22: idToSearch = 14; break;
    case 38: case 23: idToSearch = 15; break;
    case 48: case 39: idToSearch = 16; break;
    case 49: case 40: idToSearch = 17; break;
    case 50: case 41: idToSearch = 18; break;
    case 51: case 42: idToSearch = 19; break;
    case 52: case 43: idToSearch = 20; break;
    case 53: case 44: idToSearch = 21; break;
    case 54: case 45: idToSearch = 22; break;
    case 55: case 46: idToSearch = 23; break;
    case 56: case 47: idToSearch = 24; break;
    case 59: case 58: idToSearch = 25; break;
    case 60: case 61: idToSearch = 26; break;
  }

  CategoriesData.map( item => {
    if (`${item.id}` === `${idToSearch}`) {
      data = item;
    }
  })

  return data;

}

export const getDefaultOrder = (isCO, categories) => {
  let newCategoriesArray = [];

  (categories || []).map( item => {

    let data = searchDataById(item.id);

    if (data !== null) {
      data.realId = item.id;
      data.title = item.description;
      data.configuration.text = item.description;
      newCategoriesArray.push(data);
    }

  });

  return newCategoriesArray;
};

export const reorderArray = (arrayToOrder, order) => {
  let newArray = [];

  order.map( item => {
    arrayToOrder.map( item2 => {
      if (`${item2.id}` === `${item}`) {
        newArray.push(item2);
      }
    })
  });

  return newArray;
}

export const calculateTableWidth = (columns) => {

  let width = 0;

  (columns || []).forEach( item => {
    let numericWidth = Number((item.width || "0px").slice(0,-2))
    width += numericWidth;
    // switch(item.idConfig) {
    //   case 1: case 2: case 3: case 7: case 8: case 11: width = width + 170; break;
    //   case 4: width = width + 210; break;
    //   case 5: case 6: width = width + 240; break;
    //   case 9: case 25: width = width + 140; break;
    //   case 10: case 13: width = width + 180; break;
    //   case 12: width = width + 150; break;
    //   case 14: case 15: width = width + 100; break;
    //   case 16: case 17: case 18: case 19: case 20: case 21: case 22: case 23: case 24: width = width + 180; break;
    // }

  });

  return width;

}

export const buildSampleTable = () => {
  return {
    rowStyle: rowStyleCO,
    headerStyle: headerStyleCO,
    contentStyle: contentStyleCO,
    columns: []
  };
}

export const buildTableConfiguration = (data, columnsHidden) => {

  let columnToShow = [];

  if (data && data.length) {

    data.map(item => {
      if (columnsHidden.length === 0 || !columnsHidden.includes(item.id)) {
        columnToShow.push(item.configuration);
      }
    })

  }

  columnToShow.push({
    idConfig: -1,
    text: "",
    width: "18px",
    headerItemStyle: headerItemStyleCO,
    cellStyle: cellStyleCO,
    menuType: 0 // 0: hidden - 1: alfanumerico
  });

  return {
    rowStyle: rowStyleCO,
    headerStyle: headerStyleCO,
    contentStyle: contentStyleCO,
    columns: columnToShow
  };

}

export const hasDifferentOrder = (list1, list2) => {

  if (list1 && list2 && list1.length && list2.length && list1.length === list2.length) {

    for (let index = 0; index < list1.length; index++) {
      if (list1[index].id !== list2[index].id) {
        return true;
      }
    }

    return false;

  } else {
    return false;
  }

}