import React from "react";
import { Link } from "react-router-dom";
import ValidatePerms from "../component/validate-perms";

export default class MenuCategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubcategory: props.activeSubcategoryId,
      isHovered: false
    };
  }

  openSubMenu = () => {
    var listClass = "closed";
    if (this.state[this.props.subcategoryId] === "closed") {
      listClass = "";
      this.props.setActiveSubcategory(this.props.subcategoryId);
    }
    this.setState({
      ...this.state,
      [this.props.subcategoryId]: listClass,
    });
    if (!this.props.isMenuOpen) this.props.openMenu();
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      [this.props.subcategoryId]: "closed",
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`item ${this.state[this.props.subcategoryId]}`}
          onClick={this.openSubMenu}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          <Link to={this.props.item.link ?? "#"}>
            <img
              src={this.props.item.icon.url}
              alt=""
              width={this.props.item.icon.width}
            />
            <span>{this.props.item.title}</span>
          </Link>
          <b>
            <img 
              width={this.state.isHovered ? "16" : "13"}
              height={this.state.isHovered ? "23" : "20"}
              src="/images/select-arrow.svg" alt=""
            />
          </b>
        </div>
        <div className="submenu">
          {this.props.item.submenu.map((item, key) => { 
            return (
              <ValidatePerms
                key={`validate-${key}`}
                perms={item.perms}
                eval={item.eval ? item.eval : undefined}
              >
                <div className="submenu-item" key={key}>
                  <Link to={item.link}>
                    <span onClick={item.onClick || (() => {})}>{item.title}</span>
                  </Link>
                </div>
              </ValidatePerms>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.isMenuOpen) {
      return {
        [nextProps.subcategoryId]: "closed",
      };
    } else if (
      nextProps.activeSubcategoryId !== prevState.activeSubcategoryId
    ) {
      if (nextProps.subcategoryId === nextProps.activeSubcategoryId) {
        return {
          activeSubcategoryId: nextProps.activeSubcategoryId,
          [nextProps.subcategoryId]: "",
        };
      } else {
        return {
          activeSubcategoryId: nextProps.activeSubcategoryId,
          [nextProps.subcategoryId]: "closed",
        };
      }
    }
    return null;
  }
}
