import React from "react";

const TruckDoubleEquipment = (props) => {
  return (
    <svg
      viewBox="-1 0 55 12"
      width="55px"
      height="20px"
      fill="#AFC8FF"
      stroke="#AFC8FF"
      {...props}
    >
      <g>
        <g>
          <g transform="translate(0.125071, 0.295455)">
            <path
              d="M40.3014426,11.5227273 C39.1078027,11.5227273 38.8125,10.464191 38.8125,9.45454545 C38.8125,8.44489993 39.1078027,7.86647727 40.3014426,7.86647727 C41.2424997,7.86647727 42.0430813,8.37521405 42.3397606,9.08528145 L44.6232744,9.08522727 L44.6239948,2.38210227 C44.6239948,2.04555375 44.9465402,1.77272727 45.3444201,1.77272727 L48.1754271,1.77272727 C48.8980566,1.77272727 49.5728747,2.07820904 49.9737175,2.58679098 L52.1856881,5.39329879 C52.4223731,5.69360032 52.5486738,6.04644252 52.5486738,6.4073601 L52.5486738,9.69460227 C52.5486738,10.0311508 52.2261284,10.3039773 51.8282484,10.3039773 L50.264417,10.3039773 C49.9623187,11.0174415 49.1639815,11.5227273 48.2261216,11.5227273 C47.2882617,11.5227273 46.4899245,11.0174415 46.190845,10.3111535 L42.3395085,10.3045261 C42.0426279,11.0142785 41.2422333,11.5227273 40.3014426,11.5227273 Z M48.2261216,7.90340909 C47.8282417,7.90340909 47.5056962,8.43252717 47.5056962,9.08522727 C47.5056962,9.73792737 47.8282417,10.2670455 48.2261216,10.2670455 C48.6240015,10.2670455 48.946547,9.73792737 48.946547,9.08522727 C48.946547,8.43252717 48.6240015,7.90340909 48.2261216,7.90340909 Z M40.5,8.27272727 C40.1021201,8.27272727 39.5810172,9.35805378 39.5810172,9.69460227 C39.5810172,10.0311508 39.9035626,10.3039773 40.3014426,10.3039773 C40.6993225,10.3039773 41.0218679,10.0311508 41.0218679,9.69460227 C41.0218679,9.35805378 40.8978799,8.27272727 40.5,8.27272727 Z M50.3873977,9.69460227 L50.3854152,9.61558577 L50.3830752,9.5867429 L50.3873977,9.69460227 Z M46.079254,9.90727415 L46.0713293,9.83414915 L46.077646,9.89471907 L46.079254,9.90727415 Z"
              id="Shape"
              // fill="#AFC8FF"
              fillRule="nonzero"
            />
            <rect
              id="Rectangle"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              // fill="#AFC8FF"
              x="22.8840999"
              y="0"
              width="20.3414221"
              height="7.09090909"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="36.0212683"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="24.1554388"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="29.2407943"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <polygon
              id="Rectangle"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              // fill="#AFC8FF"
              points="0 0 20.3414221 0 20.3414221 5.15702479 17.4355047 5.15702479 10.1707111 5.15702479 10.1707111 7.09090909 0 7.09090909"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="1.27133888"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="6.35669441"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="13.1371685"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              cx="18.222524"
              cy="9.30681818"
              rx="1.27133888"
              ry="1.32954545"
            />
            <line
              x1="12.7133888"
              y1="7.53409091"
              x2="20.3414221"
              y2="7.53409091"
              id="Line-6"
              // stroke="#AFC8FF"
              strokeWidth="1.5"
              // stroke-linecap="square"
            />
          </g>
          <ellipse
            id="Oval"
            // stroke="#AFC8FF"
            strokeWidth="1.5"
            cx="40.6463389"
            cy="9.60227273"
            rx="1.27133888"
            ry="1.32954545"
          />
          <ellipse
            id="Oval"
            // stroke="#AFC8FF"
            strokeWidth="1.5"
            cx="48.5213389"
            cy="9.60227273"
            rx="1.27133888"
            ry="1.32954545"
          />
        </g>
        <path
          transform="translate(-96,-13)"
          d="M144.064394,16 L144.064394,17.8030303 L145.094697,17.8030303 L146.125,17.8030303 L146.125,17.2878788 C145.964237,17.0356183 145.843665,16.8581813 145.763283,16.7555679 C145.368704,16.251856 144.974125,16 144.579545,16 C144.064394,16 143.892677,16 144.064394,16 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default TruckDoubleEquipment;
