import React from 'react';
import PropTypes from 'prop-types';

class InputSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // hasInput: this.props.value || false,
            input: this.props.value || '',
        };
    }

    handleInput = (e) => {
        this.setState({
            input: e.target.value,
            // hasInput: e.target.value.length
        });

        if (this.props.onChange)
            this.props.onChange(this.props.name, e.target.value);
    }

    handleHasValue = () => {
        return this.state.input || this.props.value;
    }

    render() {
        return (
            <div className={"text-input-search ".concat(this.props.className ? this.props.className : '')}>
                <input
                    name={this.props.name}
                    id={this.props.name}
                    type={this.props.type}
                    className={"field " + (this.handleHasValue() ? 'has-input' : '')}
                    onChange={this.handleInput}
                    value={this.props.value}
                    onKeyDown={this.props.handleKeyDown}
                />
                <label
                    htmlFor={this.props.name}
                    className='label'>{this.props.label}
                </label>
            </div>
        );
    }
}

export default InputSearch;

InputSearch.defaultProps = {
  handleKeyDown: () => {}
}

InputSearch.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string
}
