import React, { Component } from "react";
import {
  ClassItem,
  Ellipsis,
  ClassItemButton,
  ClassItemActions,
  EllipsisToolTip,
} from "../style";
import { Operator, DoubleOperator } from "../../../../shared/icons/assemble";
import { ArrowIconMargin } from "../../style";
import { Flex, flexOpts } from "../../../../shared/styled/flex";
import { ArrowUp, ArrowDown } from "orbi_v2_frontend_components/lib/Icons";
import RouteClassesDetails from "./routes-classes-details";
import update from "immutability-helper";

class RouteClassesItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {}

  getOperators = (operators) => {
    if (operators === 2) {
      return (
        <React.Fragment>
          <Operator />
          <DoubleOperator />
        </React.Fragment>
      );
    } else {
      return <Operator />;
    }
  };

  getTypeRamp = (row) => {
    switch (row) {
      case "load":
        return <ArrowUp width="6px" />;
      case "unload":
        return <ArrowDown width="6px" />;
      case "loadunload":
        return <ArrowIconMargin width="12px" />;

      default:
        return "";
    }
  };

  getOpenClass = () => {
    if (this.state.isOpen) {
      return "open";
    }
    return "";
  };

  openHandler = () => {
    let stateUpdate = update(this.state, {
      isOpen: { $set: !this.state.isOpen },
    });
    this.setState(stateUpdate);
  };

  convertToHours = (time) => {
    if (parseInt(time) !== 0) {
      var num = time;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hr " + rminutes + " min";
    }
    return time;
  };

  render() {
    const { config, row, rowKey, removeRow, updateRow, editMode } = this.props;
    const { item, details } = config;
    return (
      <React.Fragment>
        <ClassItem>
          <div
            style={{ width: item[0].width }}
            onClick={() => this.openHandler()}
          >
            {(row.sections || 0).length > 0 && (
              <img
                alt=""
                src="/images/caret.svg"
                //   onClick={() => this.openChild()}
                className={`${this.getOpenClass()}`}
              />
            )}
          </div>
          <div style={{ width: item[1].width }}>{row.class}</div>
          <div style={{ width: item[2].width }}>{row.configuration}</div>
          <div style={{ width: item[3].width }}>{row.distance} km</div>
          <div style={{ width: item[4].width }}>
            <Flex direction={flexOpts.direction.column}>
              {this.getOperators(row.operators)}
            </Flex>
          </div>
          <div
            style={{
              width: item[5].width,
            }}
          >
            <Flex direction={flexOpts.direction.column}>
              {row.tat.map((item, key) => (
                <span key={key}>{this.convertToHours(item)}</span>
              ))}
            </Flex>
          </div>
          <div style={{ width: item[6].width }}>
            <Flex direction={flexOpts.direction.column}>
              {row.origin.map((item, key) => (
                <div key={key}>
                  {this.getTypeRamp(item.type)}{" "}
                  {this.convertToHours(item.value)}
                </div>
              ))}
            </Flex>
          </div>
          <div
            style={{
              width: item[7].width,
            }}
          >
            <Flex direction={flexOpts.direction.column}>
              {row.transit.map((item, key) => (
                <span key={key}>{this.convertToHours(item)}</span>
              ))}
            </Flex>
          </div>
          <div style={{ width: item[8].width }}>
            <Flex direction={flexOpts.direction.column}>
              {row.destination.map((item, key) => (
                <div key={key}>
                  {this.getTypeRamp(item.type)}{" "}
                  {this.convertToHours(item.value)}
                </div>
              ))}
            </Flex>
          </div>
          <div
            style={{
              width: item[9].width,
            }}
          >
            <Flex direction={flexOpts.direction.column}>
              {row.return.map((item, key) => (
                <span key={key}>{this.convertToHours(item)}</span>
              ))}
            </Flex>
          </div>
          <div
            style={{
              width: item[10].width,
            }}
          >
            {/* <ClassItemMap>
              <a href="">Ver mapa</a>
            </ClassItemMap> */}

            <ClassItemButton>
              {/* <Button
                settings={{
                  type: "primary",
                  text: "Guardar",
                  // disabled: this.validForm(),
                  //   text: this.savingDataText(),
                  // onClick: this.createRoute,
                }}
                // dataTip={this.getErrors()}
                // dataFor="orbi-tooltip-error"
                // onMouseLeave={() => this.handleLeave()}
              /> */}
            </ClassItemButton>
            {editMode && (
              <React.Fragment>
                <ClassItemActions
                  data-tip
                  data-for={"tooltip-routes-child-".concat(rowKey)}
                >
                  <Ellipsis>
                    <b></b>
                  </Ellipsis>
                </ClassItemActions>
                <EllipsisToolTip
                  id={"tooltip-routes-child-".concat(rowKey)}
                  clickable={true}
                  event="click"
                  globalEventOff="click"
                  delayHide={200}
                  delayShow={200}
                  delayUpdate={200}
                  place={"left"}
                  border={true}
                  type={"light"}
                >
                  <div
                    className="tooltip-option"
                    id={"tooltip-action-routes".concat(rowKey)}
                    onClick={() => {
                      updateRow(this.props.rowKey);
                    }}
                  >
                    Modificar
                  </div>
                  <div
                    className="tooltip-option"
                    id={"tooltip-action-delete-".concat(rowKey)}
                    onClick={() => {
                      removeRow(rowKey);
                    }}
                  >
                    Eliminar
                  </div>
                </EllipsisToolTip>
              </React.Fragment>
            )}
          </div>
        </ClassItem>
        {(row.sections || 0).length > 0 && (
          <RouteClassesDetails
            sections={row.sections}
            details={details}
            isShow={this.getOpenClass()}
          />
        )}
      </React.Fragment>
    );
  }
}

export default RouteClassesItems;

RouteClassesItems.defaultProps = {};
