import React from "react";
const AccountIcon = (props) => {
  return (
    <svg
      width="68px"
      height="47px"
      viewBox="0 0 68 47"
      fill="#9C27B0"
      {...props}
    >
      <path
        d="M16.8965517,14 C20.0083333,14 22.5287356,11.9116667 22.5287356,9.33333333 C22.5287356,6.755 20.0083333,4.66666667 16.8965517,4.66666667 C13.7847701,4.66666667 11.2643678,6.755 11.2643678,9.33333333 C11.2643678,11.9116667 13.7847701,14 16.8965517,14 Z M16.8965517,16.3333333 C13.137069,16.3333333 5.63218391,17.8966667 5.63218391,21 L5.63218391,23.3333333 L28.1609195,23.3333333 L28.1609195,21 C28.1609195,17.8966667 20.6560345,16.3333333 16.8965517,16.3333333 Z"
        id="Shape"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};

export default AccountIcon;
