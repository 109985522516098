import React from 'react';
import { connect } from 'react-redux';

import TextInput from '../../shared/controls/text-input';
import Button from '../../shared/controls/button';
import SelectInput from '../../shared/controls/select-input';
import { MapController } from '../../shared/component/map-container';
import {
  loadCountries,
  loadCities,
  loadStates,
  loadTimeZones,
  loadSession,
} from '../../redux/reducers/global-catalog.reducer';
import {
  validateUniqueField
} from '../../redux/reducers/facility-reducer';
import ChipContainer from '../../shared/component/chip-container';
import ItemSelectDialog from '../../shared/dialog/avatar-select-dialog';
import { loadTags } from '../../redux/reducers/tags.reducer';
import { loadAvatares } from '../../redux/reducers/facility-reducer';
import { BACKEND } from '../../shared/backend-api';
import Panel, { SubPanel } from '../../shared/component/panel';
import { validators } from '../../shared/validate';
import ErrorDialog from '../../shared/dialog/error-dialog';
import { readEntities } from '../../redux/api';
// import { validators } from '../shared/validate';

let typingTimer;

class FacilityData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarList: props.avatares,
      avatarUrl: (props.avatares && props.facility.avatar ? (props.avatares.find(av => av.id === props.facility.avatar) || { image: '/images/fabrica.svg' }).image : '/images/fabrica.svg'),
      // cities: [],
      openDialog: false,
      showError: false,
      msgError: '',
      facilityTags: ((props.facility || {}).tags || []),

      countryOptions: [],
      neighborhoodOptions: [],
      stateOptions: [],
      cityOptions: [],
      localityOptions: [],
    };

    this.addressItems = {};
    this.mapController = new MapController();
    this.marker = {};
    this.geocodeResult = {};
    this.originalCredentials = { name: props.facility && props.facility.name, code: props.facility && props.facility.code, alias: props.facility && props.facility.alias }
  }

  // componentWillUnmount(){

  // }

  componentDidMount() {
    if(this.props.facility?.data){
      let countryOptions = [{id: this.props.facility?.data?.country?.id, description: this.props.facility?.data?.country?.descripcion}];
      let neighborhoodOptions = [{id: this.props.facility?.data?.suburb?.id, description: this.props.facility?.data?.suburb?.nombre}];
      let stateOptions = [{id: this.props.facility?.data?.state?.id, description: this.props.facility?.data?.state?.descripcion}];
      let cityOptions = [{id: this.props.facility?.data?.city?.id, description: this.props.facility?.data?.city?.descripcion}];
      let localityOptions = [{id: this.props.facility?.data?.locality?.id, description: this.props.facility?.data?.locality?.descripcion}];
      this.setState({
        countryOptions,
        neighborhoodOptions,
        stateOptions,
        cityOptions,
        localityOptions,
      }, () => {
      
        this.getFullAddress({target: {
          name: 'country',
          value: this.props.facility?.data?.country?.id
        }});
        
        this.getFullAddress({target: {
          name: 'state',
          value: this.props.facility?.data?.state?.id
        }});
        
        this.getFullAddress({target: {
          name: 'city',
          value: this.props.facility?.data?.city?.id
        }});
      });
    }
    this.mapController.loadMap(null, null, null, 'roadmap', false);

    this.mapController.isReadySubject.subscribe(ready => {
      if (ready) {
        this.marker = this.mapController.createMarker();
        // this.marker.setIcon('/images/icon_factory_map.svg');
        this.marker.setIcon(this.state.avatarUrl);
        this.marker.setMap(this.mapController.map);
        this.marker.setDraggable(true);
        window.google.maps.event.addListener(this.marker, 'dragend', () => {
          this.props.onFacilityChange({ target: { name: 'geopos_x', value: this.marker.getPosition().lat() } });
          this.props.onFacilityChange({ target: { name: 'geopos_y', value: this.marker.getPosition().lng() } });
        });
        this.geocodeResult = this.mapController.geocodeResultSubject.subscribe(
          next => {
            this.mapController.setCenterMap(next);
            this.mapController.setMarker(next, this.marker);
            this.props.onFacilityChange({ target: { name: 'geopos_x', value: next[0].geometry.location.lat() } });
            this.props.onFacilityChange({ target: { name: 'geopos_y', value: next[0].geometry.location.lng() } });
          }
        )
        this.mapController.geocodeLocation(this.props.facility.geopos_x, this.props.facility.geopos_y);
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (this.mapController.map) {
      this.marker.setIcon(this.state.avatarUrl);
    }
    // if (prevProps.cities.length === 0 && this.props.cities.length > 0 && this.props.facility.city) {
    //   this.filterCities(this.props.facility.state)
    // }
  }

  /* Input handlers */
  handleAvatarInput = (id) => {
    this.setState({
      avatarUrl: (this.props.avatares.find(av => av.id === id) || { image: '/images/fabrica.svg' }).image
    });
    this.props.onFacilityChange({ target: { name: 'avatar', value: id } });
    return;
  }

  // handleStateChange = (event) => {
  //   let { value, name } = event.target;

  //   this.handleAddress(event);
  //   this.filterCities(event.target.value);

  //   if (name === 'state') {
  //     this.props.loadCities(value);
  //     setTimeout(function() {
  //       this.props.resetCity && this.props.resetCity();
  //     }.bind(this), 100)
  //   }

  // }

  handleAddress = (event) => {
    let { value, name } = event.target;

    this.props.onFacilityChange(event);
    this.getFullAddress(event); 

    if(name === 'zip_code'){
      clearTimeout(typingTimer);
      typingTimer = setTimeout(this.getZipCodeInfo, 1000);
    }

    // if (name === 'country') {
    //   setTimeout(function() {
    //     this.props.resetState && this.props.resetState();
    //   }.bind(this), 100)
    //   this.props.loadStates(value);
    // }
  }

  getZipCodeInfo = () => {
    this.props.checkZipCode({
      cp: this.props.facility.zip_code,
      country: this.props.companyCountry
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];
          this.setState({
            countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions,

            // country: result[0].pais_id,
            // neighborhood: result.length > 1 ? 0 : result[0].colonia_id,
            // state: result[0].estado_id,
            // city: result[0].municipio_id,
            // postalCodeId: result[0].cp_id,
          })
          this.props.onFacilityChange({target: {
            name: 'country',
            value: result[0].pais_id
          }})
          this.getFullAddress({target: {
            name: 'country',
            value: result[0].pais_id
          }});

          this.props.onFacilityChange({target: {
            name: 'neighborhood',
            value: result.length > 1 ? 0 : result[0].colonia_id,
          }})

          this.props.onFacilityChange({target: {
            name: 'state',
            value: result[0].estado_id,
          }})
          this.getFullAddress({target: {
            name: 'state',
            value: result[0].estado_id,
          }});

          this.props.onFacilityChange({target: {
            name: 'city',
            value: result[0].municipio_id
          }})
          this.getFullAddress({target: {
            name: 'city',
            value: result[0].municipio_id
          }});
          
          this.props.onFacilityChange({target: {
            name: 'locality',
            value: result[0].localidad_id,
          }})

          this.props.onFacilityChange({target: {
            name: 'zip_code_id',
            value: result[0].cp_id
          }})
        } else {
          this.setState({
            countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],
            // country: 0,
            // neighborhood: 0,
            // state: 0,
            // city: 0,
            // postalCodeId: 0
          })
          this.props.onFacilityChange({target: {
            name: 'country',
            value: 0
          }})
          this.getFullAddress({target: {
            name: 'country',
            value: 0
          }});

          this.props.onFacilityChange({target: {
            name: 'neighborhood',
            value: 0
          }})

          this.props.onFacilityChange({target: {
            name: 'state',
            value: 0
          }})
          this.getFullAddress({target: {
            name: 'state',
            value: 0
          }});

          this.props.onFacilityChange({target: {
            name: 'city',
            value: 0
          }})
          this.getFullAddress({target: {
            name: 'city',
            value: 0
          }});
          
          this.props.onFacilityChange({target: {
            name: 'locality',
            value: 0,
          }})

          this.props.onFacilityChange({target: {
            name: 'zip_code_id',
            value: 0
          }})
        }
      }
    })
  }

  handleUniqueFieldBlur = event => {
    let { name, value } = event.target;
    if (value !== this.originalCredentials[name]) {

      if (value === '') return;

      this.props.validateUniqueField(name, value)
        .then((result) => {
          if (result.error) {
            this.props.onFacilityChange({ target: { name: name.concat('_unique'), value: false } });
            // this.setState({ showError: true, msgError: "Comprueba los datos" });
          } else {
            this.props.onFacilityChange({ target: { name: name.concat('_unique'), value: true } });
          }
        });
    } else {
      this.props.onFacilityChange({ target: { name: name.concat('_unique'), value: true } });
    }
  }

  actionOpenDialog = () => {
    this.setState({ openDialog: true });
  }

  actionCloseDialog = () => {
    this.setState({ openDialog: false, showError: false });
  }

  handleChipContainerClick = (event) => {
    let setTo = !event.target.value;
    const items = setTo ? [...this.state.facilityTags, event.target.name] : this.state.facilityTags.filter(f => f !== event.target.name);
    this.setState({ facilityTags: items });
    this.props.onFacilityChange({ target: { name: 'tags', value: items } });
  }

  render() {
    return (
      <div className="facility-data">
        <Panel title={'Datos de instalación'} number={'1.1'}>
          <div className="data-panel" >
            <div className="basic-data" >
              <TextInput
                type='text'
                label='Nombre de la instalación *'
                name='name'
                className="full"
                onChange={this.props.onFacilityChange}
                value={this.props.facility.name}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                handleBlur={this.handleUniqueFieldBlur}
                errors={this.props.facility.name_unique ? [] : ['Valor usado por otra instalación.']}
              />
              <TextInput
                type='text'
                label='Código *'
                name='code'
                className="half"
                onChange={this.props.onFacilityChange}
                value={this.props.facility.code}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                handleBlur={this.handleUniqueFieldBlur}
                errors={this.props.facility.code_unique ? [] : ['Valor usado por otra instalación.']}
              />
              <TextInput
                type='text'
                label='Alias'
                name='alias'
                className="half"
                onChange={this.props.onFacilityChange}
                value={this.props.facility.alias}
                handleBlur={this.handleUniqueFieldBlur}
                errors={this.props.facility.alias_unique ? [] : ['Valor usado por otra instalación.']}
                length={45}
              />
              
              <TextInput
                type='text'
                label='Código postal*'
                name='zip_code'
                className="half"
                value={this.props.facility.zip_code}
                onChange={this.handleAddress}
                validate={[validators.required]}
                numeric={true}
                onFocused={this.props.onFocused}
                length={10}
              />

              {/* <SelectInput
                label='País de origen (selecciona una opción)'
                className="half"
                name="country"
                // items={this.props.countries || []}
                items={this.state.countryOptions}
                value={this.props.facility.country}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                onChange={this.handleAddress}
                disabled={!this.props.facility.zip_code}
              /> */}

              <TextInput
                type='text'
                label='Teléfono'
                name='phone'
                className="half"
                value={this.props.facility.phone}
                onChange={this.props.onFacilityChange}
                numeric={true}
                length={14}
              />

              <SelectInput
                label='Estado *'  
                className="half"
                name="state"
                // items={this.props.states || []}
                items={this.state.stateOptions}
                value={this.props.facility.state}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                disabled={!this.props.facility.zip_code}
              />

              <SelectInput
                label='Ciudad *'
                className="half"
                name="city"
                // items={ ((this.props.states || []).length > 0 && parseInt(this.props.facility.state) !== 0) ? (this.props.cities || []) : []}
                  items={this.state.cityOptions}
                onChange={this.handleAddress}
                value={this.props.facility.city}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                disabled={!this.props.facility.zip_code}
              />

              {
                this.props.facility.locality 
                ? <SelectInput
                  label='Localidad *'
                  className="half"
                  name="locality"
                  // items={ ((this.props.states || []).length > 0 && parseInt(this.props.facility.state) !== 0) ? (this.props.cities || []) : []}
                  items={this.state.localityOptions}
                  onChange={this.props.onFacilityChange}
                  value={this.props.facility.locality}
                  // validate={[validators.required]}
                  // onFocused={this.props.onFocused}
                  disabled={!this.props.facility.zip_code}
                /> 
                : ''
              }
              <TextInput
                type='text'
                label='Domicilio *'
                name='address'
                className={this.props.facility.locality ? "half" : "full"}
                value={this.props.facility.address}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                length={100}
              />

              <TextInput 
                label='Num Ext:*'
                type='text'
                className="half"
                value={this.props.facility.exterior_number}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
                name='exterior_number'
                onChange={this.handleAddress}
              />
              <TextInput 
                label='Num Int:'
                type='text'
                className="half"
                value={this.props.facility.interior_number}
                name='interior_number'
                onChange={this.props.onFacilityChange}
              /> 

              <SelectInput
                label='Colonia:'
                className="full"
                value={this.props.facility.neighborhood}
                name='neighborhood'
                items={this.state.neighborhoodOptions}
                validate={[validators.required]}
                onChange={this.props.onFacilityChange}
                onFocused={this.props.onFocused}
                disabled={!this.props.facility.zip_code}
              /> 

              <SelectInput
                label='Zona horaria *'
                className="full"
                name="time_zone"
                items={this.props.timeZones || []}
                onChange={this.props.onFacilityChange}
                value={this.props.facility.time_zone}
                validate={[validators.required]}
                onFocused={this.props.onFocused}
              />
            </div>

            <div className="extra-data">
              <div className="avatar">
                <div className={'frame '.concat(this.props.facility.avatar ? 'selected' : '')}>
                  <img alt='' width="140px" height='175px'
                    src={this.state.avatarUrl}
                    onClick={this.actionOpenDialog}
                  ></img>
                </div>
                <Button settings={{ type: 'primary outline white', text: 'Selecciona el icono', onClick: this.actionOpenDialog }} />
              </div>

              <div className="map">
                {this.mapController.getMapContainer(
                  {
                    width: '570px',
                    height: '400px',
                    className: 'map-view'
                  }
                )}
              </div>
            </div>
          </div>

          <SubPanel number={'1.2'} title={'Etiquetas'} />
          <div className="tags-panel">
            <div className="text">Añade las etiquetas necesarias, para organizar o agrupar fácilmente tus instalaciones.</div>

            <ChipContainer items={this.mapTagsToChips()}
              background='dark'
              onChange={this.handleChipContainerClick}
            />
          </div>
        </Panel>
        <ItemSelectDialog
          open={this.state.openDialog}
          title="Selecciona tu icono"
          message="Elige un icono para tu instalación."
          acceptText="Guardar"
          items={this.facilityAvatar()}
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
      </div >
    );
  }

  // getAddressFromResult = (result) => {
  //   result[0].address_components.reverse().forEach(comp => {
  //     comp.types.forEach(type => {
  //       this.updateAddress = false;
  //       switch (type) {
  //         case 'street_number':
  //           this.props.onFacilityChange({ target: { name: 'address', value: this.props.facility.address.concat(' ', comp.long_name) } });
  //           break;
  //         case 'route':
  //           this.props.onFacilityChange({ target: { name: 'address', value: comp.long_name } });
  //           break;
  //         case 'locality':
  //           this.handleAddress({
  //             target: {
  //               name: 'city',
  //               value: (this.props.cities.find(c =>
  //                 this.normalizeStr(c.description) === this.normalizeStr(comp.long_name)) || {}).id || 0
  //             }
  //           }, false);
  //           break;
  //         case 'administrative_area_level_1':
  //           this.handleStateChange({
  //             target: {
  //               name: 'state',
  //               value: (this.props.states.find(c =>
  //                 this.normalizeStr(c.description) === this.normalizeStr(comp.long_name)) || {}).id || 0
  //             }
  //           });
  //           break;
  //         case 'country':
  //           this.handleAddress({
  //             target: {
  //               name: 'country',
  //               value: (this.props.countries.find(c =>
  //                 c.iso2 === comp.short_name) || {}).id || 0
  //             }
  //           });
  //           break;
  //         case 'postal_code':
  //           this.props.onFacilityChange({ target: { name: 'zip_code', value: comp.long_name } });
  //           break;
  //         default:
  //       }
  //     });
  //   });
  //   this.updateAddress = true;
  // }

  // getGeocodeFromClick = (mapContainer, event) => {
    // geocodeLocation(mapContainer.map, mapContainer.geocoder, event.latLng,
    //   (result) => {
    //     mapContainer.marker = setMarker(mapContainer.map, mapContainer.marker, result);
    //     this.getAddressFromResult(result);
    //   }
    // );
  // }

  facilityAvatar = () => {
    return this.props.avatares.map(
      avatar =>
        <div key={avatar.id} className={"item-container".concat(' ', avatar.image === this.state.avatarUrl ? 'active' : '')} onClick={() => this.handleAvatarInput(avatar.id)}>
          <div className="img-background" >
            <img
              alt={avatar.id}
              key={avatar.id}
              src={/* avatar.image */ CLEAR_AVATAR[avatar.id]}
            />
          </div>
        </div >
    )
  }
  // filterCities = (stateId) => {
  //   stateId = parseInt(stateId);
  //   //this.setState({ cities: this.props.cities.filter(city => city.state === stateId) })
  //   this.setState({ cities: this.props.cities })
  // }

  getFullAddress = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    if(value){
      switch (property) {
        case 'country':
          this.addressItems[property] = (this.state.countryOptions.find(c => c.id.toString() === value.toString()) || {}).description || '';
          break;
        case 'state':
          this.addressItems[property] = (this.state.stateOptions.find(c => c.id.toString() === value.toString()) || {}).description || '';
          break;
        case 'city':
          this.addressItems[property] = (this.state.cityOptions.find(c => c.id.toString() === value.toString()) || {}).description || '';
          break;
        case 'address':
          // value = event.target.value;
          // this.addressItems[property] = (value || '').concat(' ', this.props.facility.exterior_number);
          this.addressItems[property] = value;
          break;
        case 'exterior_number':
          // value = event.target.value;
          // this.addressItems[property] = (this.props.facility.address || '').concat(' ', value);
          this.addressItems[property] = value;
          break;
        case 'zip_code':
          // value = event.target.value;
          this.addressItems[property] = value;
          break;
        default: { }
      }
    }
    // console.log("ADDRESSITEM", this.addressItems);
    if(Object.values(this.addressItems).length){
      this.mapController.geocodeAddress(Object.values(this.addressItems).reduce((t, n) => t.concat(' ', n)))
    }
    // , () => {
    //   if (this.mapController.map) {
    //     let latLng = this.mapController.map.getCenter();
    //     this.props.onFacilityChange({ target: { name: 'geopos_x', value: latLng.lat() } });
    //     this.props.onFacilityChange({ target: { name: 'geopos_y', value: latLng.lng() } });
    //     this.marker = this.mapController.setMarker(latLng, this.marker);
    //     if (!this.marker.getMap())
    //       this.marker.setMap(this.mapController.map);
    //   }
    // });
  }

  mapTagsToChips = () => {
    return this.props.facilityTags.map((ft) => {
      let active = this.state.facilityTags.find(t => t === ft.id) ? true : false;
      return {
        id: ft.id,
        title: ft.title,
        color: ft.color,
        isFull: active,
        name: 'tags'
      }
    })
  }
  normalizeStr = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.avatares !== prevState.avatarList) {
      return {
        avatares: nextProps.avatares,
        avatarUrl: (nextProps.avatares.find(av => av.id === nextProps.facility.avatar) || { image: '/images/fabrica.svg' }).image
      }
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    // cities: state.globalCatalog.cities.items || [],
    // states: state.globalCatalog.states.items || [],
    // countries: state.globalCatalog.countries.items || [],
    companyCountry: state.globalCatalog.session.company.country,
    timeZones: state.globalCatalog.timeZones.items || [],
    avatares: (state.facilityReducer.avatar.items || []).map(av => ({
      id: av.id,
      image: BACKEND.FILES_SERVER.concat(av.image),
      avatar_type: av.avatar_type
    })),
    facilityTags: (Array.isArray(state.tags.tags) && Array.isArray(state.tags.tagTypes.data) ? state.tags.tags.filter(tag =>
      tag.type === (state.tags.tagTypes.data.find(type =>
        type.description.toLowerCase() === 'instalaciones') || {}).id
    ) : [])
  };
};
const mapDispatchToProps = dispatch => {
  // dispatch(loadCountries());
  //dispatch(loadCities());
  //dispatch(loadStates());
  dispatch(loadTimeZones());
  dispatch(loadTags());
  dispatch(loadAvatares());
  dispatch(loadSession());
  return {
    checkZipCode: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.ZIPCODECHECK", params, opt) 
      );
    },
    validateUniqueField: (companyId, field, value) => dispatch(validateUniqueField(companyId, field, value)),
    // loadStates: (id) => dispatch(loadStates(id)),
    // loadCities: (id) => dispatch(loadCities(id)),
    // loadFacilityByCode: (code) => dispatch(loadFacilityByCode(code)),
    // saveFacility:,
    // updateFacility:,
  }
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FacilityData);

const CLEAR_AVATAR = [
  '',
  '/images/tienda-clear.svg',
  '/images/fabrica-clear.svg',
  '/images/bodega-cajas-clear.svg',
  '/images/bodega-clear.svg'
]

// TODO: loader

// FIX: rutas de imagenes para avatar
// FIX: estilos de imagenes para avatar cuando se selecciona
// FIX: Código no es campo unico en facility
