import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";

Modal.setAppElement("#root");

class ErrorDialog extends React.Component {
  onClose = () => {
    if (this.props.closeAction) this.props.closeAction();
  };
  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName={`dialog error-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.props.message}</div>
        <div className="actions">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button
              settings={{
                text: this.props.acceptText,
                onClick: this.props.acceptAction
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default ErrorDialog;
