//@flow
import React from "react";
import { connect } from 'react-redux';
import Button from "../shared/controls/button";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

type StateToProps = {|
|};

type DispatchToProps = {|
|};

type OwnProps = {|
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {
};

class Landing extends React.Component<Props, State> {
  state = {
    companyTypeTab: 0,
  }

  componentDidMount() {

  }

  handleEnd = () => {
    const { currentSlide } = this.slider.context.state;
    this.setState({ companyTypeTab: currentSlide });
  };

  render() {
    return (
      <div className="landing-view">
        <div className="top-row" style={{backgroundImage: "url(images/landing-long-background.png)"}}>
          <div className="top-banner">
            <div className="">

            </div>
            <div className="">
              
            </div>
            <div className="">
              
            </div>
          </div>
          <div className="video-row" >
            <div className="video-container">

            </div>
            <div className="video-description">
              <div className="title">
                Conéctate con tus clientes y  dales visibilidad de sus pedidos.
              </div>
              <div className="">
                Orbinetwork es la red que conecta a las industrias, comercios y transportistas para automatizar, visualizar y medir el desempeño de las cadenas de abastecimiento.
              </div>
              <div className="actions">
                <Button 
                  text="CREA TU CUENTA"
                  type=""
                  className="btn-white"
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="blue-banner second-banner">
          <div className="main-text">
            Somos la transformación digital de la industria, comercio y transporte mundial.
          </div>
          <div className="sub-text">
            Hoy más que nunca las cadenas de abastecimiento necesitan transformarse para satisfacer las necesidades de sus clientes y consumidores en todo el mundo. Actualmente el mercado global demanda productos más sustentables, más rápido, menores precios y una mejor experiencia de compra en línea.
          </div>
        </div>
        <div className="dashed-banner company-type-row">
          <div className="intro">
            <div className="title">
              Una cuenta para cada tipo de empresa o negocio
            </div>
            <div className="subtitle">
              Orbinetwork tiene una cuenta para cada eslabón en la cadena de abastecimiento.
            </div>
          </div>
          <div className="company-types-container">
            <div className="company-type-tabs">
              <div className="tabs">
                <div 
                  className={"tab".concat(this.state.companyTypeTab === 0 ? ' selected' : '')}
                  onClick={() => this.setState({companyTypeTab: 0})}
                >
                  <img src="/images/account_cargo_owner_icon.svg" alt="" />
                  <div className="description">
                    Dueño de carga
                  </div>
                </div>
                <div 
                  className={"tab".concat(this.state.companyTypeTab === 1 ? ' selected' : '')}
                  onClick={() => this.setState({companyTypeTab: 1})}
                >
                  <img src="/images/account_lt_icon.svg" alt="" />
                  <div className="description">
                    Transportista
                  </div>
                </div>
                <div 
                  className={"tab".concat(this.state.companyTypeTab === 2 ? ' selected' : '')}
                  onClick={() => this.setState({companyTypeTab: 2})}
                >
                  <img src="/images/account_delivery_icon.svg" alt="" />
                  <div className="description">
                    Repartidor
                  </div>
                </div>
                <div 
                  className={"tab".concat(this.state.companyTypeTab === 3 ? ' selected' : '')}
                  onClick={() => this.setState({companyTypeTab: 3})}
                >
                  <img src="/images/account_consumer_icon.svg" alt="" />
                  <div className="description">
                    Consumidor
                  </div>
                </div>
              </div>
            </div>
            <CarouselProvider
              naturalSlideWidth={1}
              naturalSlideHeight={1}
              totalSlides={4}
              currentSlide={this.state.companyTypeTab}
              className="carousel"
              dragEnabled={false}
            >
              <Slider
                ref={(ref) => (this.slider = ref)}
                onTransitionEnd={this.handleEnd}
              >
                <Slide index={0}>
                  <div className="company-type-info">
                    <div className="avatar">
                      <img src="/images/account_cargo_owner_logo.svg" alt="" />
                      <div class="block"></div>
                    </div>
                    <div className="description">
                      <div className="title">
                        Orbinetwork dueño de carga
                      </div>
                      <div className="text">
                        Es un tipo de cuenta de la plataforma Web de Orbinetwork ideal para que las empresas que se dedican a la fabricación, distribución y/o venta de todo tipo de productos o materias primas, puedan gestionar y visualizar los pedidos de sus operaciones de:
                        <ul>
                          <li>Compra de insumos (materias primas)</li>
                          <li>Distribución Primaria</li>
                          <li>Distribución Secundaria</li>
                          <li>Ultima milla (venta directa a clientes)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide index={1}>
                  <div className="company-type-info">
                    <div className="avatar">
                      <img src="/images/account_cargo_owner_logo.svg" alt="" />
                      <div class="block"></div>
                    </div>
                    <div className="description">
                      <div className="title">
                        Orbinetwork transportista
                      </div>
                      <div className="text">
                        Es un tipo de cuenta de la plataforma Web de Orbinetwork ideal para que las empresas que se dedican a la fabricación, distribución y/o venta de todo tipo de productos o materias primas, puedan gestionar y visualizar los pedidos de sus operaciones de:
                        <ul>
                          <li>Compra de insumos (materias primas)</li>
                          <li>Distribución Primaria</li>
                          <li>Distribución Secundaria</li>
                          <li>Ultima milla (venta directa a clientes)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide index={2}>
                  <div className="company-type-info">
                    <div className="avatar">
                      <img src="/images/account_cargo_owner_logo.svg" alt="" />
                      <div class="block"></div>
                    </div>
                    <div className="description">
                      <div className="title">
                        Orbinetwork repartidor
                      </div>
                      <div className="text">
                        Es un tipo de cuenta de la plataforma Web de Orbinetwork ideal para que las empresas que se dedican a la fabricación, distribución y/o venta de todo tipo de productos o materias primas, puedan gestionar y visualizar los pedidos de sus operaciones de:
                        <ul>
                          <li>Compra de insumos (materias primas)</li>
                          <li>Distribución Primaria</li>
                          <li>Distribución Secundaria</li>
                          <li>Ultima milla (venta directa a clientes)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Slide>
                <Slide index={3}>
                  <div className="company-type-info">
                    <div className="avatar">
                      <img src="/images/account_cargo_owner_logo.svg" alt="" />
                      <div class="block"></div>
                    </div>
                    <div className="description">
                      <div className="title">
                        Orbinetwork consumidor
                      </div>
                      <div className="text">
                        Es un tipo de cuenta de la plataforma Web de Orbinetwork ideal para que las empresas que se dedican a la fabricación, distribución y/o venta de todo tipo de productos o materias primas, puedan gestionar y visualizar los pedidos de sus operaciones de:
                        <ul>
                          <li>Compra de insumos (materias primas)</li>
                          <li>Distribución Primaria</li>
                          <li>Distribución Secundaria</li>
                          <li>Ultima milla (venta directa a clientes)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Slide>
              </Slider>
            </CarouselProvider>
            
            <div className="actions">
                <Button 
                  text="CREA TU CUENTA"
                  type="secondary"
                  className=""
                  onClick={() => {}}
                />
                <Button 
                  text="MAS INFO"
                  type="primary"
                  className=""
                  onClick={() => {}}
                />
            </div>
          </div>
        </div>
        <div className="samples-row">
          <img src="" alt="" />
          <div className="blue-banner third-banner">
            <div className="controls">
              <div className={"switch left ".concat("disabled")}>
                <img src="images/caret.svg" alt="" />
              </div>      
              <div className="switch right">
                <img src="images/caret.svg" alt="" />
              </div>     
              <div className="counter">
                {1}/4
              </div>            
            </div>
            <div className="content">
              <div className="title">
                Catálogo de Usuarios e instalaciones
              </div>
              <div className="text">
                <ul>
                  <li>Invita a todos tus colaboradores de los equipos de planeación, ventas, compras, almacén, logística y transporte, etc. </li>
                  <li>Crea perfiles y permisos para cada uno de ellos.</li>
                  <li>Registra y crea un perfil para tus instalaciones: fábricas, almacenes y puntos de venta.</li>
                  <li>Registra la ubicación, capacidad y horarios de atención de tus instalaciones.</li>
                </ul>
              </div>
            </div>
            <div className="actions">
                <Button 
                  text="CREA UNA CUENTA"
                  type="secondary"
                  className=""
                  onClick={() => {}}
                />
            </div>
          </div>
        </div>
        <div className="dashed-banner ">
          <div className="packages-row">
            <div className="package">
              <div className="name">
                PUBLICA
              </div>
              <div className="price">
                <span>$</span> 0
              </div>
              <div className="description">
                <ul>
                  <li> Usuarios e instalaciones ilimitados</li>
                  <li> Tiendas Virtuales Ilimitadas</li>
                  <li> Control Pedidos: Compra / Venta</li>
                  <li> Control Citas: Recolección / Entrega</li>
                </ul>
              </div>
              <div className="actions">
                <Button
                  text="CREAR CUENTA"
                  type="primary"
                  action={() => {}}
                />
              </div>
            </div>
            <div className="package main">
              <div className="name">
                ON DEMAND
              </div>
              <div className="price">
                <span>$</span> 0.99 <span>USD</span>
              </div>
              <div className="description">
                <ul>
                  <li> Usuarios e instalaciones ilimitados</li>
                  <li> Tiendas Virtuales Ilimitadas</li>
                  <li> Control Pedidos: Compra / Venta</li>
                  <li> Control Citas: Recolección / Entrega</li>
                  <li> Histórico de eventos, pedidos y  viajes</li>
                  <li> Control de Tráfico</li>
                  <li> Reporte BI</li>
                </ul>
              </div>
              <div className="actions">
                <Button
                  text="CREAR CUENTA"
                  type="primary"
                  action={() => {}}
                />
              </div>
            </div>
            <div className="package">
              <div className="name">
                ILIMITADA
              </div>
              <div className="price">
                <span>$</span> 990 <span>USD</span>
              </div>
              <div className="description">
                <ul>
                  <li> Usuarios e instalaciones ilimitados</li>
                  <li> Tiendas Virtuales Ilimitadas</li>
                  <li> Control Pedidos: Compra / Venta</li>
                  <li> Control Citas: Recolección / Entrega</li>
                  <li> Histórico de eventos, pedidos y  viajes</li>
                  <li> Control de Tráfico</li>
                  <li> Reporte BI</li>
                </ul>
              </div>
              <div className="actions">
                <Button
                  text="CREAR CUENTA"
                  type="primary"
                  action={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="support-row">
            <div className="content">
              <div className="title">
                Nuestros expertos en soporte
              </div>
              <div className="subtitle">
                Equipos de expertos para ayudar, en cada paso del viaje
              </div>
              <div className="text">
                Conoce a tu “Escuadrón” de Orbi: un equipo dedicado de expertos en carga, envío, operaciones, aduanas y datos, disponible 24/7/365 para ayudarlo de manera proactiva a administrar su cadena de suministro y garantizar que sus productos lleguen a tiempo, en excelentes condiciones.
              </div>
            </div>
            <img src="" alt=""/>
          </div>
          <div className="users-row">
            <div className="title">
              Miles de equipos de logística de alto rendimiento en todo el mundo están en Orbi
            </div>
            <div className="users">

            </div>
          </div>
        </div>
        <div className="dark-banner statistics-row">
          <div className="title">
            Servicios para optimizar y acelerar su cadena de suministro
          </div>
          <div className="statistics">
            <div className="data">
              <div className="figures">
                +60
              </div>
              <div className="description">
                cuentas
              </div>
            </div>
            <div className="data">
              <div className="figures">
                +1,000
              </div>
              <div className="description">
                viajes diarios
              </div>
            </div>
            <div className="data">
              <div className="figures">
                +512,000
              </div>
              <div className="description">
                Kms
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              text="CREA TU CUENTA"
              type="secondary"
              onClick={() => {}}
            />
          </div>
        </div>
        <div className="closing-banner">
          <div className="blog-row">
            <div className="title">
              BLOG
            </div>
            <div className="cards">
              <div className="card">

              </div>
              <div className="card">

              </div>
              <div className="card">

              </div>
            </div>
          </div>
          <div className="ready-row">
            <div className="title">
              ¿Listo para comenzar?
            </div>
            <div className="text">
              Regístrate para obtener una cuenta Orbi o solicita un demo para ver nuestra plataforma en acción.
            </div>
            <div className="actions">
              <Button
                text="CREA TU CUENTA"
                type="primary"
                onClick={() => {}}
              />
            </div>
          </div>
          <div className="links-row">
            <img src="" alt=""/>
            <div className="links">
              <div className="column">
                <div className="title">Compañia</div>
                <div>Sobre Nosotros</div>
                <div>Careers</div>
                <div>Blog</div>
                <div>Historias de éxito</div>
              </div>
              <div className="column">
                <div className="title">Producto</div>
                <div>¿Por qué Orbi?</div>
                <div>Tipos de cuentas</div>
                <div>FAQ</div>
              </div>
              <div className="column">
                <div className="title">Ayuda</div>
                <div>Soporte</div>
                <div>Contácto</div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="guidelines">
            Orbinetwork ® 2020 conoce nuestro aviso de provacidad
          </div>
          <div className="language">
            Español /  English 
          </div>
          <div className="social">
            <div className="bubble">

            </div>
            <div className="bubble">

            </div>
            <div className="bubble">

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Landing);