import React from "react";
import { View, ViewTitle } from "../../shared/styled/view";
import { connect } from "react-redux";
import FilterBar from "../../shared/component/filter/filter-bar";
import MapIndicatorsUnitsTableView from "./map-indicators-units-table";
import MapIndicatorsResumesView from "./map-indicators-resume-container";
import IndicatorsMap from "./indicators-map";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { getEntityItems, readEntities } from "../../redux/api";
import { groupFacilities, isPremiumSubscriptionCo } from "../../shared/utils";
import { Flex } from "../../shared/styled/flex";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import { cloneDeep } from "lodash";
import FullscreenIcon from "../../shared/icons/fullscreen-icon";
import GearIcon from "../../shared/icons/gear-icon";
import RadioButtons from "../../shared/controls/radio";
import { listNetworkApprovedInvitations } from "../../redux/reducers/company-reducer";
import Button from "../../shared/controls/button";
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";
import { DownloadIcon } from "../../company/routes/style";
import Loader from "../../shared/icons/tat/loader";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import { cancelMultipleRequests } from '../../redux/api/actions';
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import styled from "styled-components";


let timer;

class MapIndicatorsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      // searchStr: "",
      detail_tab: 4,
      will_search: false,
      buttonFilterName: "Aplicar",
      hoverInfo: null,
      type: 0,
      selectedId: 0,
      filterDate: [],
      filterOrderTags: [],
      filterTripTags: [],
      filterExpeditionClass: [],
      filterFacilitiesTags: [],
      filterFacilities: [],
      filterIndicator: [],
      filterSupplement: [],
      filterFleetProvider: [],
      maneuver: "1",
      status: "1,2",
      noFilters: true,
      fullScreen: false,
      showConfig: false,
      barRadius: 12000,
      elevationScale: 1800,
      mapTheme: 'default',
      autorefresh: null,

      resumeColumnsToShow: [],
      currentIndexCategoryGroup: 0,
      
      viewState: {
        bearing:0,
        latitude:22.9734021,
        longitude:-102.7010398,
        pitch:60,
        zoom:5
      },
      isFreeSubscription: false
    };
    
  }

  componentDidMount() {
    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    document.addEventListener("mousedown", this.handleClick, false);
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });
    this.props.loadCompanySupplement1({company_id: this.props.companyId});
    this.props.loadFacilities({
      company_id: this.props.companyId
    });
    this.props.loadTags({
      company_id: this.props.companyId
    });
    this.props.setNavbarAction("indicators-map", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("indicators-map");
    document.removeEventListener("mousedown", this.handleClick, false);
    this.props.cancelMultipleRequests();
  }

  handleClick = e => {
    if (!this.node || this.node.contains(e.target)) return;
    // debugger;
    this.setState({hoverInfo: null})
  };

  handleFilter = () => {
    // if(this.state.noFilters){
    //   this.setState({noFilters: false});
    // }
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({noFilters: false, buttonFilterName: "Restablecer" }, this.loadKPIs);
    } else {
      this.setState({ noFilters: true, buttonFilterName: "Aplicar" }, () => {
        this.setState({
          filterDate: [],
          filterOrderTags: [],
          filterTripTags: [],
          filterExpeditionClass: [],
          filterFacilitiesTags: [],
          filterFacilities: [],
          filterIndicator: []
        })
      });
    }
  };

  filtersChange = (e) => {
    switch(e.target.name){
      case "filterFacilities":
        this.setState({ [e.target.name]: e.target.value, filterFacilitiesTags: [] });
        break;
      case "filterFacilitiesTags":
        this.setState({ [e.target.name]: e.target.value, filterFacilities: [] });
        break;
      case "filterIndicator": 
        this.setState({ [e.target.name]: e.target.value }, this.updateResumeColumnsToShow);
        break;
      default: 
        this.setState({ [e.target.name]: e.target.value });
    }
    this.setState({ buttonFilterName: "Aplicar", will_search: true, collapsed: true, hoverInfo: null });
  };

  handleSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  filterContents = () => {
    return [
      {
        title: "Indicador",
        name: "filterIndicator",
        listType: FILTER_LIST_TYPES.checkExclusive,
        // listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: [
          {
            id: "1",
            description: "Tiempos de estadía"
          },
          {
            id: "2",
            description: "Rangos de estadía"
          },
          {
            id: "3",
            description: "Tránsito"
          },
        ],
      },
      {
        title: "Instalaciones",
        name: "filterFacilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.facilitiesByTag,
      },
      {
        title: "Grupo de Instalaciones",
        name: "filterFacilitiesTags",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.facilityTags,
      },
      {
        title: "Etiqueta de Viaje",
        name: "filterTripTags",//type=8
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.tripTags,
      },
      {
        title: "Clase de Expedición",
        name: "filterExpeditionClass",//type=9
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.expeditionClass,
      },
      {
        title: "Operación",
        name: "filterOrderTags",//type=7
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.orderTags,
      },
      {
        title: "Fecha",
        name: "filterDate",
        listType: FILTER_LIST_TYPES.dateRangeFixed,
      },
      {
        title: "Suplemento 1",
        name: "filterSupplement",//type=7
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.supplement1.concat([{id: 'na', description: 'Sin suplemento'}]),
      },
      {
        title: "Transportista",
        name: "filterFleetProvider",//type=7
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        items: this.props.networkCompanies,
      },
    ];    
  };

  handleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  handleChange = (e) => {
    if(e.target.name === "maneuver" || e.target.name === "status"){
      this.setState({ [e.target.name]: e.target.value }, () => {
        if(e.target.name === "status"){
          this.updateResumeColumnsToShow();
        }
        this.loadKPIs()
      }
    );
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  getSideContent = () => {
    return(
      <div>
        <div className="title-container">
          {
            this.state.type === 1 ?
              <>
                <div>
                  <div className='title' onClick={() => {this.setState({detail_tab: 4})}}> {'Estadía total'} </div>
                  {
                    this.state.detail_tab === 4 && 
                    <div className='line-title'/>
                  }
                </div>
                <div>
                  <div className='title' onClick={() => {this.setState({detail_tab: 1})}}> {'Estadía vs cita'} </div>
                  {
                    this.state.detail_tab === 1 && 
                    <div className='line-title'/>
                  }
                </div>
                <div>
                  <div className='title' onClick={() => {this.setState({detail_tab: 2})}}> {'Previo a cita'} </div>
                  {
                    this.state.detail_tab === 2 && 
                    <div className='line-title'/>
                  }
                </div>
                <div>
                  <div className='title' onClick={() => {this.setState({detail_tab: 3})}}> {'Cita no válida'} </div>
                  {
                    this.state.detail_tab === 3 && 
                    <div className='line-title'/>
                  }
                </div>
              </>
            : this.state.type === 3 ?
            <>
              <div>
                <div className='title' onClick={() => {this.setState({detail_tab: 4})}}> {'Todas'} </div>
                {
                  this.state.detail_tab === 4 && 
                  <div className='line-title'/>
                }
              </div>
              <div>
                <div className='title' onClick={() => {this.setState({detail_tab: 1})}}> {'Retrasadas'} </div>
                {
                  this.state.detail_tab === 1 && 
                  <div className='line-title'/>
                }
              </div>
              <div>
                <div className='title' onClick={() => {this.setState({detail_tab: 2})}}> {'A tiempo'} </div>
                {
                  this.state.detail_tab === 2 && 
                  <div className='line-title'/>
                }
              </div>
            </>
            :
                  
              <div>
                <div className='title'> {'Total de unidades'} </div>
                <div className='line-title'/>
              </div>
          }
        </div>
        <MapIndicatorsUnitsTableView
          // items={[
          //   {
          //     unit: 'TZ-381',
          //     facility: 'Zapopan',
          //     classinfo: 'X2',
          //     eta: 15
          //   }, 
          //   {
          //     unit: 'TZ-382',
          //     facility: 'GDL',
          //     classinfo: 'X4',
          //     eta: -15
          //   }
          // ]}
          type={this.state.type}
          maneuver={this.state.maneuver}
          items={
            this.props.kpiDetail.sort((a,b) => {
              if(this.state.type === 3)//transito
                return a.eta - b.eta //menor a mayor
              if(this.state.type === 2)//rangos
                return b.estadia_total - a.estadia_total // mayor a menor
              if(this.state.detail_tab === 1)//estadia-estadia vs cita
                return b.estadia_vs_cita - a.estadia_vs_cita //mayor a menor
              if(this.state.detail_tab === 2)//estadia - previo a cita
                return b.previo_a_cita - a.previo_a_cita //mayor a menor
              if(this.state.detail_tab === 3)//estadia - cita no valida
                return (b.cita_no_valida || 0) - (a.cita_no_valida || 0)
              return b.estadia_total - a.estadia_total
            }).filter( t => {
              if(this.state.type === 3) {
                let onTime = t.a_tiempo ?? false;

                if (this.state.detail_tab === 2) {
                  return onTime;
                } else if (this.state.detail_tab === 1) {
                  return !onTime;
                } else {
                  return true;
                }

              } else {
                return true;
              }
            }).map(t => {
              let estadia = 0;
              if(this.state.type !== 1) estadia = t.estadia_total;
              else {
                switch(this.state.detail_tab){
                  case 1:
                    estadia = t.estadia_vs_cita;
                    break;
                  case 2:
                    estadia = t.previo_a_cita;
                    break;
                  case 3: 
                    estadia = t.cita_no_valida;
                    break;
                  default:
                    estadia = t.estadia_total;
                    break;
                }
              }

              let origin_date = '2022-03-21T12:52:31.092000Z'
              let destination_date = '2022-03-20T12:52:31.092000Z'

              let origin_eta = '2022-03-20T12:52:31.092000Z'
              let destination_eta = '2022-03-21T12:52:31.092000Z'

              return {
                unit: t.economico,
                facility: t.nombre_instalacion,
                classinfo: t.etiqueta_transporte,
                eta: (t.eta || 0)/60,
                estadia: (estadia || 0)/60,
                lt_avatar: t.url_transportista,
                lt_name: t.transportista,
                isFull: t.equipos > 1,
                arrival: t.llegada,
                appointment: t.cita_vigente,
                original_appointment: t.cita,
                exit: t.salida,
                valid: t.is_valid,
                folio: t.folio || '',
                salida_origen: t.salida_origen || '',
                origin_date: t.cita_carga || '',
                destination_date: t.cita_descarga || '',
                origin_eta: t.eta_carga || '',
                destination_eta: t.eta_descarga || '',
                orden: t.orden || ''
              }
            })
          }
        />
      </div>
    );
  }

  setData = (data) => {
    this.setState({
      hoverInfo: data, 
      type: data.layer.id.includes("transit") ? 3 : data.layer.id.includes("range") ? 2 : 1,
      selectedId: data.object?.id || 0,
    });
  }

  formatDate = (date) => {
    if(!date) return "";
    return (
      ''.concat(
        date.getFullYear(),
        '-',
        date.getMonth() + 1,
        '-',
        date.getDate()
      )
    )
  }

  
  getUrl = () => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/kpi/?download=true&start_date=${this.formatDate(this.state.filterDate[0] || new Date())}&end_date=${this.formatDate((this.state.filterDate[1] || this.state.filterDate[0]) || new Date())}&facilities=${this.state.filterFacilities}&facilities_tags=${this.state.filterFacilitiesTags}&order_tags=${this.state.filterOrderTags}&trip_tags=${this.state.filterTripTags}&expedition_class=${this.state.filterExpeditionClass}&operation=${this.state.maneuver}&status=${this.state.status}&supplement=${this.state.filterSupplement}&fleet_provider=${this.state.filterFleetProvider}&kpi_type=${1}`;
  };

  loadKPIs = () => {
    this.setState({will_search: true, collapsed: true, hoverInfo: null});
    this.props.cancelMultipleRequests();
    if(this.state.filterIndicator.includes("1")){
      this.props.loadKPITime({
        company_id: this.props.companyId,
        start_date: this.formatDate(this.state.filterDate[0] || new Date()),
        end_date: this.formatDate((this.state.filterDate[1] || this.state.filterDate[0]) || new Date()),
        facilities: this.state.filterFacilities,
        facilities_tags: this.state.filterFacilitiesTags,
        operation: this.state.maneuver,
        order_tags: this.state.filterOrderTags,
        trip_tags: this.state.filterTripTags,
        expedition_class: this.state.filterExpeditionClass,
        status: this.state.status,
        supplement: this.state.filterSupplement,
        fleet_provider: this.state.filterFleetProvider,
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.setState({will_search: false});
          if(response.map_centroid && response.map_centroid.length){
            this.setState({
              viewState: {
                ...this.state.viewState,
                latitude: response.map_centroid[0],
                longitude: response.map_centroid[1]
              }
            })
          }
        }
      })
    }
    
    if(this.state.filterIndicator.includes("2")){
      this.props.loadKPIRange({
        company_id: this.props.companyId,
        start_date: this.formatDate(this.state.filterDate[0] || new Date()),
        end_date: this.formatDate((this.state.filterDate[1] || this.state.filterDate[0]) || new Date()),
        facilities: this.state.filterFacilities,
        facilities_tags: this.state.filterFacilitiesTags,
        operation: this.state.maneuver,
        order_tags: this.state.filterOrderTags,
        trip_tags: this.state.filterTripTags,
        expedition_class: this.state.filterExpeditionClass,
        status: this.state.status,
        supplement: this.state.filterSupplement,
        fleet_provider: this.state.filterFleetProvider,
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.setState({will_search: false});
          if(response.map_centroid && response.map_centroid.length){
            this.setState({
              viewState: {
                ...this.state.viewState,
                latitude: response.map_centroid[0],
                longitude: response.map_centroid[1]
              }
            })
          }
        }
      })
    }
    
    if(this.state.filterIndicator.includes("3")){
      this.props.loadKPITransit({
        company_id: this.props.companyId,
        start_date: this.formatDate(this.state.filterDate[0] || new Date()),
        end_date: this.formatDate((this.state.filterDate[1] || this.state.filterDate[0]) || new Date()),
        facilities: this.state.filterFacilities,
        facilities_tags: this.state.filterFacilitiesTags,
        operation: this.state.maneuver,
        order_tags: this.state.filterOrderTags,
        trip_tags: this.state.filterTripTags,
        expedition_class: this.state.filterExpeditionClass,
        supplement: this.state.filterSupplement,
        fleet_provider: this.state.filterFleetProvider,
        isMultipleCancels: true
      }, {
        onSuccess: (response) => {
          this.setState({will_search: false});
          if(response.map_centroid && response.map_centroid.length){
            this.setState({
              viewState: {
                ...this.state.viewState,
                latitude: response.map_centroid[0],
                longitude: response.map_centroid[1]
              }
            })
          }
        }
      })
    }
    
    if(this.state.autorefresh){
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.loadKPIs()
      }, this.state.autorefresh);
    }
  }

  loadDetail = () => {
    this.props.cancelMultipleRequests();
    this.props.loadKPIDetail({
      company_id: this.props.companyId,
      start_date: this.formatDate(this.state.filterDate[0] || new Date()),
      end_date: this.formatDate((this.state.filterDate[1] || this.state.filterDate[0]) || new Date()),
      facilities: this.state.filterFacilities.length ? this.state.selectedId : [],
      facilities_tags: this.state.filterFacilitiesTags.length ? this.state.selectedId : [],
      operation: this.state.maneuver,
      order_tags: this.state.filterOrderTags,
      trip_tags: this.state.filterTripTags,
      expedition_class: this.state.filterExpeditionClass,
      type: this.state.type,
      status: this.state.status,
      supplement: this.state.filterSupplement,
      fleet_provider: this.state.filterFleetProvider,
      isMultipleCancels: true
    })
  }

  getTooltipBody = () => {
    let type = this.state.hoverInfo?.layer?.id || 0;
    if(type.includes("time")){
      return (
        <>
          {
            this.state.status.includes("1") &&
            <div className="tooltip-group-container">
              <div className="indicators">
                <div className="black">
                  <div>Estadía total</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.en_instalacion_estadia_total || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="orange">
                  <div>Estadía vs cita</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.en_instalacion_estadia_vs_cita || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="green">
                  <div>Previo a cita</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.en_instalacion_previo_a_cita || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="yellow">
                  <div>Cita no válida</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.en_instalacion_cita_no_valida || 0)/* /60).toFixed(1) */}</div>
                </div>
              </div>
              <div className="counter">
                <div>
                  <span>{this.state.hoverInfo?.object?.en_instalacion || 0}</span> <br/>
                  en sitio
                </div>
              </div>
            </div>
          }
          {
            this.state.status.includes("2") &&
            <div className="tooltip-group-container">
              <div className="indicators">
                <div className="black">
                  <div>Estadía total</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.atendidas_estadia_total || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="orange">
                  <div>Estadía vs cita</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.atendidas_estadia_vs_cita || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="green">
                  <div>Previo a cita</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.atendidas_previo_a_cita || 0)/* /60).toFixed(1) */}</div>
                </div>
                <div className="yellow">
                  <div>Cita no válida</div>
                  <div>{this.getTimeFormat(this.state.hoverInfo?.object?.atendidas_cita_no_valida || 0)/* /60).toFixed(1) */}</div>
                </div>
              </div>
              <div className="counter">
                <div>
                  <span>{this.state.hoverInfo?.object?.atendidas || 0}</span> <br/>
                  atendidas
                </div>
              </div>
            </div>
          }
          {/* <div className="indicators">
            <div className="black">
              <div>Estadía total</div>
              <div>{this.getTimeFormat(this.state.hoverInfo?.object?.estadia_total || 0)}</div>
            </div>
            <div className="green">
              <div>Previo a cita</div>
              <div>{this.getTimeFormat(this.state.hoverInfo?.object?.previo_a_cita || 0)}</div>
            </div>
            <div className="orange">
              <div>Estadía vs cita</div>
              <div>{this.getTimeFormat(this.state.hoverInfo?.object?.estadia_vs_cita || 0)}</div>
            </div>
          </div>
          <div className="counter">
            {
              this.state.status.includes("1") &&
              <div>
                <span>{this.state.hoverInfo?.object?.en_instalacion || 0}</span> <br/>
                en sitio
              </div>
            }
            {
              this.state.status.includes("2") &&
              <div>
                <span>{this.state.hoverInfo?.object?.atendidas || 0}</span> <br/>
                atendidas
              </div>
            }
          </div> */}
        </>
      )
    }
    if(type.includes("range")){
      return <>
        <div className="full-indicators">
          <div className="black">
            <div>Unidades en sitio</div>
            <div>{this.state.hoverInfo?.object?.total_viajes || 0}</div>
          </div>
          <div className="red">
            <div>Estadía +5 hrs</div>
            <div>{this.state.hoverInfo?.object?.rangos[4] || 0}</div>
          </div>
          <div className="orange">
            <div>Estadía 3-5 hrs</div>
            <div>{this.state.hoverInfo?.object?.rangos[3] || 0}</div>
          </div>
          <div className="yellow">
            <div>Estadía 2-3 hrs</div>
            <div>{this.state.hoverInfo?.object?.rangos[2] || 0}</div>
          </div>
          <div className="green">
            <div>Estadía 0-2 hrs</div>
            <div>{this.state.hoverInfo?.object?.rangos[1] || 0}</div>
          </div>
        </div>
      </>
    }
    if(type.includes("transit")){
      return <>
        <div className="full-indicators">

          <div 
            style={{
              display: 'flex',
            }}
          >
            <div style={{fontSize: 13}}>
              <div className="orange" style={{display: 'flex'}}>
                <div style={{marginRight: 10}}>{this.state.hoverInfo?.object?.con_retraso || 0}</div>
                <div>Retrasadas</div>
              </div>
              <div className="green" style={{display: 'flex'}}>
                <div style={{marginRight: 10}}>{this.state.hoverInfo?.object?.a_tiempo || 0}</div>
                <div>A tiempo</div>
              </div>
            </div>

            <div className="black" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 10, borderLeft: '1px solid #20293D80'}}>
              <div style={{marginBottom: -15}}>Unidades</div>
              <div style={{marginBottom: -15}}>en tránsito</div>
              <div style={{fontSize: 20}}>{this.state.hoverInfo?.object?.total_ordenes || 0}</div>
            </div>

          </div>
        </div>
      </>
    }
  }

  showDetail = () => {
    this.setState({
      collapsed: false,
      // trips: this.state.hoverInfo.object[this.state.hoverInfo.object.layer.id.includes("transit") ? "ordenes" : "viajes"],
      hoverInfo: null
    }, this.loadDetail);
  }

  changeColumnsToShow = (value) => {
    if(this.state.filterIndicator.includes("1")){
      if(value === 0){
        if(this.state.currentIndexCategoryGroup === 0){
          // this.setState({
          //   resumeColumnsToShow: [false, false, false, false, false, true, true, true, true, true],
          //   currentIndexCategoryGroup: 1
          // });
        } else {
          this.setState({
            resumeColumnsToShow: [true, true, true, true, true, false, false, false, false, false],
            currentIndexCategoryGroup: 0
          });
        }
      } else {
        if(this.state.currentIndexCategoryGroup === 0){
          this.setState({
            resumeColumnsToShow: [false, false, false, false, false, true, true, true, true, true],
            currentIndexCategoryGroup: 1
          });
        } else {
          // this.setState({
          //   resumeColumnsToShow: [true, true, true, true, true, false, false, false, false, false],
          //   currentIndexCategoryGroup: 0
          // });
        }
      }
    } else {
      let currentColumns = [...this.state.resumeColumnsToShow];
      currentColumns[value] = !currentColumns[value];
      this.setState({resumeColumnsToShow: currentColumns});
    }
  }

  updateResumeColumnsToShow = () => {
    if(this.state.filterIndicator.includes("1")){
      let columns = [];
      if(this.state.status.includes("1")){
        columns = [true, true, true, true, true, false, false, false, false, false];
      }
      else if(this.state.status.includes("2")){
        columns = [false, false, false, false, false, true, true, true, true, true];
      }
      this.setState({resumeColumnsToShow: columns});
    }
    else if(this.state.filterIndicator.includes("2")){
      
      this.setState({resumeColumnsToShow: [true, true, true, true]});
    }
    else if(this.state.filterIndicator.includes("3")){
      
      this.setState({resumeColumnsToShow: [true, true, true]});
    }
  }

  getResumeTableCategoryGroups = (currentIndex = 0) => {
    if(this.state.filterIndicator.includes("1")){
      return [
        {
          title: "En instalación",
          active: this.state.currentIndexCategoryGroup === 0
        },
        {
          title: "Atendidas",
          active: this.state.currentIndexCategoryGroup === 1
        }
      ]
    } else {
      return null
    }
  }

  getResumeTableCategories = () => {
    if(this.state.filterIndicator.includes("1")){
      let categories = [
        /* {
          title: "Estadía promedio",
          property_name: "totalAvg"
        } */];
      if(this.state.status.includes("1")){
        categories.push({
          title: "Estadía promedio instalación",
          property_name: "inFacilityAvg"
        })
        categories.push({
          title: "Estadía vs cita",
          property_name: "inFacilityVsAppointment"
        })
        categories.push({
          title: "Estadía previo a cita",
          property_name: "inFacilityPrevAppointment"
        })
        categories.push({
          title: "Cita no válida",
          property_name: "inFacilityInvalidAppointment"
        })
        categories.push({
          title: "Unidades en instalación",
          property_name: "unitsInFacility"
        })
      }
      if(this.state.status.includes("2")){
        categories.push({
          title: "Estadía promedio atendidos",
          property_name: "attendedAvg"
        })
        categories.push({
          title: "Estadía vs cita",
          property_name: "attendedVsAppointment"
        })
        categories.push({
          title: "Estadía previo a cita",
          property_name: "attendedPrevAppointment"
        })
        categories.push({
          title: "Cita no válida",
          property_name: "attendedInvalidAppointment"
        })
        categories.push({
          title: "Unidades atendidas",
          property_name: "unitsAttended"
        })
      }
      // this.setState({resumeColumnsToShow: [true, true]});
      return categories;
    }
    if(this.state.filterIndicator.includes("2")){
      
      // this.setState({resumeColumnsToShow: [true, true, true, true]});
      return [
        {
          title: "0-2 HRS",
          property_name: "low"
        },
        {
          title: "2-3 HRS",
          property_name: "mid"
        },
        {
          title: "3-5 HRS",
          property_name: "high"
        },
        {
          title: "5+ HRS",
          property_name: "extra"
        },
      ]
    }
    if(this.state.filterIndicator.includes("3")){
      
      // this.setState({resumeColumnsToShow: [true, true, true]});
      return [
        {
          title: "En tránsito",
          property_name: "total"
        },
        {
          title: "Retrasadas",
          property_name: "delayed"
        },
        {
          title: "A tiempo",
          property_name: "onTime"
        },
      ]
    }
  }

  getTimeFormat = (sec) => {

    const temp1 = parseInt(sec / 60);
    if(temp1 > 23){
      let h = parseInt(temp1 % 24);
      if(h < 10){
        h = '0'.concat(h);
      }
      return `${parseInt(temp1 / 24)}d ${h}h`;
    }
    const temp2 = parseInt(sec % 60);

    // const temp1String = temp1 < 10 ? `0${temp1}`: `${temp1}`;
    const temp2String = temp2 < 10 ? `0${temp2}`: `${temp2}`;

    return `${temp1}h ${temp2String}m`;

  }

  getDataByGroup = () => {
    
    if(this.state.filterIndicator.includes("1")){
      let dataSource = this.state.will_search ? [] : cloneDeep(this.props.kpiTime);
      return dataSource.sort((a,b) => b.estadia_total - a.estadia_total).map(data => ({
        title: data.grupo,
        totalAvg: this.getTimeFormat(data.estadia_total),
        attendedAvg: this.getTimeFormat(data.atendidas_estadia_total),
        attendedVsAppointment: this.getTimeFormat(data.atendidas_estadia_vs_cita),
        attendedPrevAppointment: this.getTimeFormat(data.atendidas_previo_a_cita),
        attendedInvalidAppointment: this.getTimeFormat(data.atendidas_cita_no_valida),
        inFacilityAvg: this.getTimeFormat(data.en_instalacion_estadia_total),
        inFacilityVsAppointment: this.getTimeFormat(data.en_instalacion_estadia_vs_cita),
        inFacilityPrevAppointment: this.getTimeFormat(data.en_instalacion_previo_a_cita),
        inFacilityInvalidAppointment: this.getTimeFormat(data.en_instalacion_cita_no_valida),
        unitsAttended: data.atendidas,
        unitsInFacility: data.en_instalacion
      }))
    }
    if(this.state.filterIndicator.includes("2")){
      let dataSource = this.state.will_search ? [] : cloneDeep(this.props.kpiRange);
      return dataSource.sort((a,b) => b.rangos[4] - a.rangos[4]).map(data => ({
        title: data.grupo,
        low: data.rangos[1],
        mid: data.rangos[2],
        high: data.rangos[3],
        extra: data.rangos[4]
      }))
    }
    if(this.state.filterIndicator.includes("3")){
      let dataSource = this.state.will_search ? [] : cloneDeep(this.props.kpiTransit);
      return dataSource.sort((a,b) => b.con_retraso - a.con_retraso).map(data => ({
        title: data.grupo,
        total: data.total_ordenes,
        onTime: data.a_tiempo,
        delayed: data.con_retraso
      }))
    }
  }

  getTableTags = () => {
    if(this.state.filterIndicator.includes("1")){
      return this.props.kpiTime.map(data => <span>{data.grupo}, </span>)
    }
    if(this.state.filterIndicator.includes("2")){
      return this.props.kpiRange.map(data => <span>{data.grupo}, </span>)
    }
    if(this.state.filterIndicator.includes("3")){
      return this.props.kpiTransit.map(data => <span>{data.grupo}, </span>)
    }
  }

  getDataGroups = () => {
    let groups = [];
    if(this.state.filterIndicator.includes("1")){
      let dataSource = this.state.will_search ? {} : this.props.kpiTimeResume;
      if(this.state.status.includes("1")){
        groups.push({
          title: "En instalación",
          qty: dataSource.en_instalacion?.count || 0,
          is_time: true,
          data: {
            prev: dataSource.en_instalacion?.previo_a_cita || 0,
            estadia: dataSource.en_instalacion?.estadia_vs_cita || 0,
            invalid: dataSource.en_instalacion?.cita_no_valida || 0,
          }
        })
      }
      if(this.state.status.includes("2")){
        groups.push({
          title: "Atendidos",
          qty: dataSource.atendidas?.count || 0,
          is_time: true,
          data: {
            prev: dataSource.atendidas?.previo_a_cita || 0,
            estadia: dataSource.atendidas?.estadia_vs_cita || 0,
            invalid:  dataSource.atendidas?.cita_no_valida || 0,
          }
        })
      }
      return groups;
    }
    if(this.state.filterIndicator.includes("2")){
      let dataSource = this.state.will_search ? {} : this.props.kpiRangeResume;
      if(this.state.status.includes("1")){
        groups.push({
          title: "En instalación",
          qty: dataSource.en_instalacion?.count || 0,
          // is_time: true,
          data: {
            low: dataSource.en_instalacion?.low || 0,
            mid: dataSource.en_instalacion?.mid || 0,
            high: dataSource.en_instalacion?.high || 0,
            extra: dataSource.en_instalacion?.extra || 0,
          }
        });
      }
      if(this.state.status.includes("2")){
        groups.push({
          title: "Atendidos",
          qty: dataSource.atendidas?.count || 0,
          // is_time: true,
          data: {
            low: dataSource.atendidas?.low || 0,
            mid: dataSource.atendidas?.mid || 0,
            high: dataSource.atendidas?.high || 0,
            extra: dataSource.atendidas?.extra || 0,
          }
        });
      }
      return groups;
    }
    if(this.state.filterIndicator.includes("3")){
      let dataSource = this.state.will_search ? {} : this.props.kpiTransitResume;
      return [
        {
          title: "En tránsito",
          qty: dataSource.count || 0,
          data: {
            delayed: dataSource.delayed || 0,
            onTime: dataSource.onTime || 0,
          }
        },
      ]
    }
    return []
  }

  getDataCategories = () => {
    if(this.state.filterIndicator.includes("1")){
      return [
        {
          title: "ESTADÍA VS CITA",
          color: "#EC5E0E",
          property_name: "estadia",
        },
        {
          title: "PREVIO A CITA",
          color: "#A2B80B",
          property_name: "prev",
        },
        {
          title: "CITA NO VÁLIDA",
          color: "#F3DD15",
          property_name: "invalid",
        },
      ]
    }
    if(this.state.filterIndicator.includes("2")){
      return [
        {
          title: "N4 5+ HRS",
          color: "#EC0E0E",
          property_name: "extra",
        },
        {
          title: "N3 3-5 HRS",
          color: "#EC5E0E",
          property_name: "high",
        },
        {
          title: "N2 2-3 HRS",
          color: "#F3DD15",
          property_name: "mid",
        },
        {
          title: "N1 0-2 HRS",
          color: "#A2B80B",
          property_name: "low",
        },
      ]
    }
    if(this.state.filterIndicator.includes("3")){
      return [
        {
          title: "RETRASADAS",
          color: "#EC5E0E",
          property_name: "delayed",
        },
        {
          title: "A TIEMPO",
          color: "#A2B80B",
          property_name: "onTime",
        },
      ]
    }
    return []
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };


  handleDownload = () => {
    let url = this.getUrl();
    this.setState({
      downloadingFile: true, 
      cancelTokenSource: Axios.CancelToken.source() 
    }, () => {
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        cancelToken: this.state.cancelTokenSource.token,
        headers: this.getToken(),
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "KPI.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadingFile: false
        });
      })
    });
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return (
      <View className={"map-indicators-view"}>
        <ViewTitle>Mapa de indicadores</ViewTitle>
        <Flex justifyContent={"space-evenly"}>
          <div
            style={{
              width: "300px",
              // marginLeft: "50%",
              marginBottom: "10px",
              // transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={this.state.maneuver}
              name="maneuver"
              onChange={this.handleChange}
              items={[
                { description: "Descarga", value: "2" },
                { description: "Carga", value: "1" },
                { description: "Todo", value: "1,2" },
              ]}
            />
          </div>
          {
            !this.state.filterIndicator.includes("3") &&
            <div
              style={{
                width: "500px",
                // marginLeft: "50%",
                marginBottom: "10px",
                // transform: "translate(-50%)",
              }}
            >
              <OptionSelectorSwitch
                value={this.state.status}
                name="status"
                onChange={this.handleChange}
                items={[
                  { description: "Atendidas", value: "2" },
                  { description: "En instalación", value: "1" },
                  { description: "Todo", value: "1,2" },
                ]}
              />
            </div>
          }
          {
            this.state.filterIndicator.includes("1") &&
            <DownloadIcon data-tip data-for={"disabled-message"}>
            { 
              this.state.downloadingFile ? 
                <Loader width="40px" height="40px" /> :
                <DownloadImage
                  fill={(!this.state.filterIndicator.length || (!this.state.filterFacilities.length && !this.state.filterFacilitiesTags.length)) ? "#FFFFFF6B" : "#FFFFFF"}
                  width={"50px"}
                  height={"50px"}
                  onClick={(e) => {
                    if(!this.state.filterIndicator.length || (!this.state.filterFacilities.length && !this.state.filterFacilitiesTags.length)) return;
                    e.stopPropagation(); 
                    this.handleDownload();
                  }}
                />
            }
            </DownloadIcon>
          }
        </Flex>
        <div className="panel">
          <div className="filters">
            <FilterBar
              filterAction={this.handleFilter}
              // searchStr={this.state.searchStr}
              selectedItems={{
                filterDate: this.state.filterDate,
                filterOrderTags: this.state.filterOrderTags,
                filterTripTags: this.state.filterTripTags,
                filterExpeditionClass: this.state.filterExpeditionClass,
                filterFacilitiesTags: this.state.filterFacilitiesTags,
                filterFacilities: this.state.filterFacilities,
                filterIndicator: this.state.filterIndicator,
                filterSupplement: this.state.filterSupplement,
                filterFleetProvider: this.state.filterFleetProvider
              }}
              onChange={this.filtersChange}
              searchButtonText={this.state.buttonFilterName}
              // searchAction={this.handleSearch}
              content={this.filterContents()}
              textSearch= {false}
              disableButton={!this.state.filterIndicator.length || (!this.state.filterFacilities.length && !this.state.filterFacilitiesTags.length)}
            />
          </div>
          <div
            className={"data-display".concat( this.state.collapsed ? " collapsed" : "", this.state.fullScreen ? " full" : "")}
          >
            <div className="map-area">
              {
                this.state.noFilters &&
                <div className="overlay">
                  Por favor selecciona el indicador y las instalaciones o etiquetas de instalación que deseas consultar.
                </div>
              }
              <IndicatorsMap 
                setData={this.setData}
                kpiTime={this.state.filterIndicator.includes("1") ? this.props.kpiTime : []}
                kpiRange={this.state.filterIndicator.includes("2") ? this.props.kpiRange : []}
                kpiTransit={this.state.filterIndicator.includes("3") ? this.props.kpiTransit : []}
                // hasBigBars={this.state.filterFacilitiesTags.length && !this.state.will_search}
                barRadius={this.state.barRadius}
                elevationScale={this.state.elevationScale}
                viewState={this.state.viewState}
                // onViewStateChange={this.onViewStateChange}
                mapTheme={this.state.mapTheme}
              />
              <MapIndicatorsResumesView
                data={{
                  resumeColumnsToShow: this.state.resumeColumnsToShow,
                  changeColumn: this.changeColumnsToShow,
                  categoryGroups: this.getResumeTableCategoryGroups(),
                  tableCategories: this.getResumeTableCategories(), //columnas de tabla resumen desglose
                  dataByTags: this.getDataByGroup(), //datos tabla resumen desglose
                  dataGroups: this.getDataGroups(), //datos resumen general
                  dataCategories: this.getDataCategories(), // niveles resumen general
                  generalData: {
                    tags: this.getTableTags(),
                  }
                }}
              
              />
              {
                (this.state.hoverInfo?.object) &&
                <div ref={node => (this.node = node)} className={"kpi-tooltip ".concat((this.state.hoverInfo?.layer?.id || "").includes("time") ? "long" : "")} style={{position: 'absolute', opacity: this.state.hoverInfo?.object ? 1 : 0, left: this.state.hoverInfo?.x || 0 , top: this.state.hoverInfo?.y || 0}}>
                  <div className="close" onClick={() => {this.setState({hoverInfo: null})}}>
                    X
                  </div>
                  <div className="title">
                    { this.state.hoverInfo?.object?.grupo || 'ZACATECAS' }
                  </div>
                  <div className="tooltip-body">
                    {
                      this.getTooltipBody()
                    }
                    
                  </div>
                  <div className="actions" onClick={this.showDetail}>
                    Ver detalle
                  </div>
                </div>
              }
              <div className="config-toggle" onClick={() => {this.setState({showConfig: !this.state.showConfig})}}>
                <GearIcon height="35" width="35"/>
                {
                  this.state.showConfig &&
                  <div className="options-container" onClick={e => {e.stopPropagation()}}>
                    <div className="title"> 
                      Configuraciones
                    </div>

                    <div className="subtitle">
                      Altura de las barras:
                    </div>
                    <div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              elevationScale: 1000
                            })
                          }
                          value="x1"
                          name="elevationScale"
                          id={String(1)}
                          checked={this.state.elevationScale === 1800}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              elevationScale: 10800
                            })
                          }
                          value="x2"
                          name="elevationScale"
                          id={String(2)}
                          checked={this.state.elevationScale === 10800}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              elevationScale: 21600
                            })
                          }
                          value="x3"
                          name="elevationScale"
                          id={String(3)}
                          checked={this.state.elevationScale === 21600}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              elevationScale: 41600
                            })
                          }
                          value="x4"
                          name="elevationScale"
                          id={String(4)}
                          checked={this.state.elevationScale === 41600}
                        />
                      </div>
                    </div>

                    <div className="subtitle">
                      Ancho de las barras:
                    </div>
                    <div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              barRadius: 1000
                            })
                          }
                          value="x1"
                          name="barRadius"
                          id={String(1)}
                          checked={this.state.barRadius === 1000}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              barRadius: 12000
                            })
                          }
                          value="x2"
                          name="barRadius"
                          id={String(2)}
                          checked={this.state.barRadius === 12000}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              barRadius: 36000
                            })
                          }
                          value="x3"
                          name="barRadius"
                          id={String(3)}
                          checked={this.state.barRadius === 36000}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              barRadius: 64000
                            })
                          }
                          value="x4"
                          name="barRadius"
                          id={String(4)}
                          checked={this.state.barRadius === 64000}
                        />
                      </div>
                    </div>
                    
                    <div className="subtitle">
                      Color del mapa:
                    </div>
                    <div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              mapTheme: 'default'
                            })
                          }
                          value="Default"
                          name="mapTheme"
                          id={String(1)}
                          checked={this.state.mapTheme === 'default'}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              mapTheme: 'dark'
                            })
                          }
                          value="Oscuro"
                          name="mapTheme"
                          id={String(2)}
                          checked={this.state.mapTheme === 'dark'}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              mapTheme: 'light'
                            })
                          }
                          value="Claro"
                          name="mapTheme"
                          id={String(3)}
                          checked={this.state.mapTheme === 'light'}
                        />
                      </div>
                    </div>
                  
                    <div className="subtitle">
                      Autorefresh:
                    </div>
                    <div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              autorefresh: null
                            }, () => clearTimeout(timer))
                          }
                          value="Desactivado"
                          name="autorefresh"
                          id={String(1)}
                          checked={this.state.autorefresh === null}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              autorefresh:  300000
                            }, () => this.loadKPIs())
                          }
                          value="5min"
                          name="autorefresh"
                          id={String(1)}
                          checked={this.state.autorefresh === 300000}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              autorefresh: 900000
                            }, () => this.loadKPIs())
                          }
                          value="15min"
                          name="autorefresh"
                          id={String(2)}
                          checked={this.state.autorefresh === 900000}
                        />
                      </div>
                      <div>
                        <RadioButtons
                          onChange={() =>
                            this.setState({
                              autorefresh: 1800000
                            }, () => this.loadKPIs())
                          }
                          value="30min"
                          name="autorefresh"
                          id={String(3)}
                          checked={this.state.autorefresh === 1800000}
                        />
                      </div>
                    </div>
                  </div>
                }
                
              </div>
              <div className="full-toggle" onClick={() => {this.setState({fullScreen: !this.state.fullScreen})}}>
                <FullscreenIcon height="35" width="35"/>
              </div>
            </div>
            
            
            <div className="side-bar">
              {
                !this.state.collapsed &&
                <div className="collapser" onClick={this.handleCollapse}></div>
              }
              { !this.state.collapsed && this.getSideContent() }
            </div>
          </div>
        </div>
      
      </View>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const facilityTags = companyTags.filter(t => t.type === 2).map(t => ({id: t.id, description: t.title}));
  const orderTags = companyTags.filter(t => t.type === 7).map(t => ({id: t.id, description: t.title}));
  const tripTags = companyTags.filter(t => t.type === 9).map(t => ({id: t.id, description: t.title}));
  const expeditionClass = companyTags.filter(t => t.type === 8).map(t => ({id: t.id, description: t.title}));
  const kpiTime = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.TIME")["data"] || [];
  const kpiTimeResume = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.TIME")["resume"] || {};
  const kpiRange = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.RANGE")["data"] || [];
  const kpiRangeResume = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.RANGE")["resume"] || {};
  const kpiTransit = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.INTRANSIT")["data"] || [];
  const kpiTransitResume = getEntityItems(state, "COMPANIES.KPIS.CATALOGS.INTRANSIT")["resume"] || {};
  const kpiDetail = getEntityItems(state, "COMPANIES.KPIS.DETAIL")["data"] || [];
  const supplement1 = getEntityItems(state, "COMPANIES.SYNC.SUPPLEMENT1").map(s => ({id: s.name, description: s.name}));
  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");
  const ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 }).id;
  const oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;
  const networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        let id = companyProviders.find(
          (provider) => provider.id === network.id
        );
        if (network.from_companies.id === companyId) {
          if (network.to_companies.type === oppositeCompanyType) {
            result.push({
              id: String(id?.fleet_provider?.fleet_provider_id),

              description: network.to_companies_alias.concat(
                " - ",
                network.to_companies_code,
                " - ",
                network.to_companies.orbi_id
              ),
            });
          }
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              // id: network.id,
              id: String(id?.fleet_provider?.fleet_provider_id),
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
            });
        }
        return result;
      },
      []
    );
  return {
    companyId,
    facilitiesByTag,
    facilityTags,
    orderTags,
    tripTags,
    expeditionClass,
    kpiTime,
    kpiRange,
    kpiTransit,
    kpiDetail,
    kpiTimeResume,
    kpiRangeResume,
    kpiTransitResume,
    supplement1,
    networkCompanies,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(listNetworkApprovedInvitations("", ""));
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanySupplement1: (params, opts) =>
      dispatch(readEntities("COMPANIES.SYNC.SUPPLEMENT1", params, opts)),
    loadFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    loadTags: (params, opts) =>
      dispatch(readEntities("COMPANIES.TAGS", params, opts)),
    loadKPITime: (params, opts) =>
      dispatch(readEntities("COMPANIES.KPIS.CATALOGS.TIME", params, opts)),
    loadKPIRange: (params, opts) =>
      dispatch(readEntities("COMPANIES.KPIS.CATALOGS.RANGE", params, opts)),
    loadKPITransit: (params, opts) =>
      dispatch(readEntities("COMPANIES.KPIS.CATALOGS.INTRANSIT", params, opts)),
    loadKPIDetail: (params, opts) =>
      dispatch(readEntities("COMPANIES.KPIS.DETAIL", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapIndicatorsView);

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;