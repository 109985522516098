import React from 'react';

class StepTitle extends React.Component {
  render() {
    return (
      <div className="step-title">
        <div className="number">
          {this.props.number}
          <div></div>
        </div>
        <div className="title">
          {this.props.title}
        </div>
        {this.props.component || <div/>}
      </div>
    )
  }
}

export default StepTitle;