import React from "react";

class UserChip extends React.Component {
  render() {
    return (
      <span className="user-chip">
        {this.props.avatarType === "contained" ? (
          <div className="avatar-container">
            <img
              className="avatar"
              alt="facility-avatar"
              src={this.props.avatar}
            />
          </div>
        ) : (
          this.props.avatar !== "hide" && (
            <img src={this.props.avatar} className="user-avatar" alt="" />
          )
        )}
        {this.props.mail}
        <b onClick={(e) => {e.stopPropagation(); this.props.deleteTag(this.props.mail)}}>
          <img src="/images/close-icon.svg" alt="" />
        </b>
      </span>
    );
  }
}

export default UserChip;

UserChip.defaultProps = {
  avatar: "/images/help.png",
  avatarType: "full",
  mail: "",
  deleteTag: () => {
    console.log("Not yet implemented.");
  },
};
