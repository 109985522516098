import React from "react";
const DoubleOperator = (props) => {
  return (
    <svg
      width="17px"
      height="20px"
      viewBox="0 0 17 15"
      stroke="none"
      strokeWidth="0.2"
      fill="#AFC8FF"
      fillRule="evenodd"
      {...props}
    >
      <g transform="translate(5, 0)">
        <path d="M5.03456313,0 C6.88835348,0 8.39093854,1.50277128 8.39093854,3.35637542 C8.39093854,5.21016577 6.88835348,6.71275083 5.03456313,6.71275083 C3.18095899,6.71275083 1.67818771,5.21016577 1.67818771,3.35637542 C1.67818771,1.50277128 3.18095899,0 5.03456313,0 Z M10.0691263,11.1039886 C10.0691263,13.143877 0,13.143877 0,11.1039886 C0,9.29742954 1.12577632,7.73403361 2.73028869,7.03994589 C3.01104149,7.38681095 3.93625376,7.8125 5.03437878,7.8125 C6.13268815,7.8125 7.05790042,7.38681095 7.33846887,7.03994589 C8.94334993,7.73403361 10.0691263,9.29742954 10.0691263,11.1039886 Z" />
      </g>
      <g stroke="#000000" transform="translate(0, 2)">
        <path d="M5.03456313,0 C6.88835348,0 8.39093854,1.50277128 8.39093854,3.35637542 C8.39093854,5.21016577 6.88835348,6.71275083 5.03456313,6.71275083 C3.18095899,6.71275083 1.67818771,5.21016577 1.67818771,3.35637542 C1.67818771,1.50277128 3.18095899,0 5.03456313,0 Z M10.0691263,11.1039886 C10.0691263,13.143877 0,13.143877 0,11.1039886 C0,9.29742954 1.12577632,7.73403361 2.73028869,7.03994589 C3.01104149,7.38681095 3.93625376,7.8125 5.03437878,7.8125 C6.13268815,7.8125 7.05790042,7.38681095 7.33846887,7.03994589 C8.94334993,7.73403361 10.0691263,9.29742954 10.0691263,11.1039886 Z" />
      </g>
    </svg>
  );
};

export default DoubleOperator;
