import React from "react";
const Availability = (props) => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M34.826 15.9419L41.5189 5.57502C41.6988 5.29694 41.7154 4.94005 41.5628 4.64567C41.4102 4.35038 41.1128 4.1665 40.7891 4.1665H9.21044C8.72623 4.1665 8.33325 4.5714 8.33325 5.0723V44.9274C8.33325 45.4283 8.72623 45.8332 9.21044 45.8332C9.69464 45.8332 10.0876 45.4283 10.0876 44.9274V27.7172H40.7891C41.1128 27.7172 41.4102 27.5334 41.5628 27.239C41.7154 26.9446 41.6988 26.5868 41.5189 26.3096L34.826 15.9419Z" fill="#05C985"/>
      <path d="M30.0469 20.8571C30.0469 21.9662 29.1808 22.866 28.1133 22.866C27.0458 22.866 26.1797 21.9662 26.1797 20.8571C26.1797 19.748 27.0458 18.8481 28.1133 18.8481C29.1808 18.8481 30.0469 19.748 30.0469 20.8571Z" fill="white"/>
      <path d="M26.8242 12.1516V9.47307C26.8242 8.73312 26.2466 8.13379 25.5352 8.13379H13.2891C12.5777 8.13379 12 8.73312 12 9.47307V16.8391C12 17.5783 12.5777 18.1784 13.2891 18.1784H14.5781V18.1952C15.1179 17.7699 15.7834 17.5088 16.5117 17.5088C18.2882 17.5088 19.7344 19.0113 19.7344 20.857H24.8906C24.8906 19.4876 25.6882 18.3107 26.8242 17.7925V12.1516Z" fill="white"/>
      <path d="M31.9128 13.3497C31.5946 12.6876 30.7591 12.1519 30.0469 12.1519H27.4688V17.5767C27.6774 17.5323 27.8933 17.5089 28.1133 17.5089C29.8898 17.5089 31.3359 19.0114 31.3359 20.8571C32.0473 20.8571 32.625 20.257 32.625 19.5178V14.8303L31.9128 13.3497ZM31.709 15.5H28.7577V12.8215H30.0468C30.4029 12.8215 30.8202 13.0893 30.9797 13.4208L31.7089 14.935L31.7081 15.5H31.709Z" fill="white"/>
      <path d="M18.4453 20.8571C18.4453 21.9662 17.5792 22.866 16.5117 22.866C15.4442 22.866 14.5781 21.9662 14.5781 20.8571C14.5781 19.748 15.4442 18.8481 16.5117 18.8481C17.5792 18.8481 18.4453 19.748 18.4453 20.8571Z" fill="white"/>
    </svg>
  );
};
export default Availability;