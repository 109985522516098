import * as React from "react";
import type TableDConfig from "../../../shared/component/table-d";
import TableD from "../../../shared/component/table-d";
import RowD from "../../../shared/component/row-d";
import styled from "styled-components";
import * as Types from "../types";
import { ActionsTricolon } from "../../../shared/controls/actions-tricolon";
import Button from "../../../shared/controls/button";
import * as moment from "moment";
import "moment/locale/es";
import ExceedPlus from "../../../shared/controls/exceed-plus";
import Tag from "../../../shared/controls/tag";
import ReactTooltip from 'react-tooltip';
import CopyIcon from '../../../shared/icons/copy-icon';
import ImageWithCheckMark from "../../../shared/component/image-with-check-mark";

type Props = {
  details: Types.UnassignedShipmentRowDetailType,
  actions: any[],
  currentFacilityId: number,
};

class UnassignedShipmentDetailTable extends React.Component<Props> {
  defaultProps = {
    details: [],
    actions: [],
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && (e.target.id.includes('container-eta') || e.target.id.includes('action-container'))) {
      return;
    }
    ReactTooltip.hide();
  }

  render() {
    return (
      <TableD tableConfig={Config}>
        {this.props.details.map((d) => (
          <UnassignedShipmentDetailRow
            history={this.props.history}
            facilityId={this.props.facilityId}
            orderId={this.props.orderId}
            {...d}
            actions={this.props.actions}
            currentFacilityId={this.props.currentFacilityId}
            setOrderDetail={orderToSelect => {
              if (this.props.setOrderDetail) {
                this.props.setOrderDetail(orderToSelect)
              }
            }}
          />
        ))}
      </TableD>
    );
  }
}

export default UnassignedShipmentDetailTable;

const getEtaLoading = (order_containers, appointment) => {

  let timesToShow = [];

  (order_containers || []).map( container => {
    
    let ataDate = container?.ata_loading ?? '';
    let etaDate = container?.eta_loading ?? '';

    let timeToValidate = ataDate === '' ? etaDate : ataDate;

    let isEta = ataDate === '';

    if (timeToValidate === '') {
      timesToShow.push(
        <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
      );
    } else {

      let isRedColor = false;

      if (appointment !== '') {
        isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
      }

      let eta_loading_data = container.eta_loading_data ?? null;

      if (isEta && eta_loading_data !== null) {
        timesToShow.push(
          <div id={'container-eta'} style={{display: 'flex', flexDirection: 'column', alignItems:'left'}}>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'} style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div id={'container-eta'} style={{color: isRedColor ? 'red' : 'white'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Creación de ETA: ${moment(eta_loading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'} >{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_loading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_loading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Distancia: ${eta_loading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Placa ETA: ${eta_loading_data?.plates ?? ''}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', maxWidth:350}}>{`Placas Ensamble: ${ (eta_loading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Dispositivo: ${eta_loading_data?.device ?? ''}`}</div>
          </div>
        );
      } else {
        timesToShow.push(
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{color: isRedColor ? 'red' : 'white'}}>
              {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
            </div>
          </div>
        );
      }

    }

  });

  return(
    <div> 
      { timesToShow }
    </div>
  );

}

const getEtaUnloading = (order_containers, appointment) => {

  let timesToShow = [];

  (order_containers || []).map( container => {
    
    let ataDate = container?.ata_unloading ?? '';
    let etaDate = container?.eta_unloading ?? '';

    let timeToValidate = ataDate === '' ? etaDate : ataDate;

    let isEta = ataDate === '';

    if (timeToValidate === '') {
      timesToShow.push(
        <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
      );
    } else {

      let isRedColor = false;

      if (appointment !== '') {
        isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
      }

      let eta_unloading_data = container.eta_unloading_data ?? null;

      if (isEta && eta_unloading_data !== null) {
        timesToShow.push(  
          <div id={'container-eta'} style={{display: 'flex', flexDirection: 'column', alignItems:'left'}}>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'} style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div id={'container-eta'} style={{color: isRedColor ? 'red' : 'white'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Creación de ETA: ${moment(eta_unloading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'}>{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_unloading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_unloading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Distancia: ${eta_unloading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Placa ETA: ${eta_unloading_data?.plates ?? ''}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', maxWidth: 350}}>{`Placas Ensamble: ${ (eta_unloading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Dispositivo: ${eta_unloading_data?.device ?? ''}`}</div>
          </div>
        );
      } else {
        timesToShow.push(
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{color: isRedColor ? 'red' : 'white'}}>
              {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
            </div>
          </div>
        );
      }

    }

  });

  return(
    <div id={'loading-eta'}> 
      { timesToShow }
    </div>
  );

}

const getEstimatedTime = (eta, ata, appointment) => { 

  let timeToValidate = ata === '' ? eta : ata;

  if (timeToValidate === '' || timeToValidate === undefined) {

    return(
      <div style={{width: 'auto', display: 'flex', flexDirection: 'row'}}>
        {`ETA: No disponible`}
      </div>
    );

  } else {

    let isEta = ata === ''

    if (appointment !== '') {

      let isRedColor = moment(timeToValidate).isSameOrAfter(appointment);

      return(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
          <div style={{color: isRedColor ? 'red' : 'white'}}>
            {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
          </div>
        </div>
      );

    } else {

      return(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
          <div style={{color: 'white'}}>
            {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
          </div>
        </div>
      );

    }

  }

}

const getIDCStatus = (props) => {

  let textToShow = [];
  let valuesAdded = [];

  if (props && props.order_containers && props.order_containers.length) {
    for(var i = 0; i < props.order_containers.length; i++) {

      let status = `${props.order_containers[i].status_description}`;
      if (!valuesAdded.includes(status)) {
        valuesAdded.push(status);
        textToShow.unshift(
          <Text>{status}</Text>
        );
      }

    }
  }

  if (textToShow.length > 0) {
    return(
      <IDCStatusContainer>
        {textToShow}
      </IDCStatusContainer>
    );
  } else {
    return(<div></div>);
  }

}

const UnassignedShipmentDetailRow = (props: Props) => {
  // const hasAction =
  //   (props.origin.editable && props.origin.date) ||
  //   (props.destination.editable && props.destination.date)
    // (props.origin.facilityId.toString() === props.currentFacilityId &&
    //   props.origin.date) ||
    // (props.destination.facilityId.toString() === props.currentFacilityId &&
    //   props.destination.date);

  const hasNullAppointment = 
    (props.origin.editable && !props.origin.date) ||
    (props.destination.editable && !props.destination.date)
    // (props.origin.facilityId.toString() === props.currentFacilityId &&
    //   !props.origin.date) ||
    // (props.destination.facilityId.toString() === props.currentFacilityId &&
    //   !props.destination.date);

      // debugger;

  

  const primaryAction = props.actions.find((a) => a.type.includes("primary"));
  // const secondaryAction = props.actions.find((a) =>
  //   a.type.includes("secondary")
  // );
  const secondaryAction = hasNullAppointment ? props.actions.find((a) =>
    a.type.includes("secondary")
  ) : null;

  // const cancelAction = props.actions.find(a => a.type.includes("cancel"));
  const assignAction = props.actions.find(a => a.type.includes("assign"));
  const changeAction = props.actions.find(a => a.type.includes("change"));


  const getDateFormat = (date) => {
    return (
      moment(date).format("D") +
      " " +
      moment(date)
        .format("MMM")
        .charAt(0)
        .toUpperCase() +
      moment(date)
        .format("MMM")
        .slice(1)
        .replace(".", "") +
      " " +
      moment(date).format("H:mm")
    );
  };

  let tooltip = `${props.id || ''}-${props.ticket || ''}`;

  return (
    <RowD {...props}>
      {
        <div style={{display: 'flex', alignItems: 'center', margin: 'auto'}}>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => {

              if (props.setOrderDetail) {
                props.setOrderDetail(props.ticket)
              }

              props.history.push(
                `/company/controls/shipment/orderdetail/${props.orderId}?facilityId=${props.facilityId}`
              )
            }}
          >
            {props.ticket}
          </Text>
          {
            props.ticket !== "" ?
            <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(props.ticket);}}/> : ""
          }
        </div>
      }
      <CompanyAvatar>
        <ImageWithCheckMark
          src={props.origin.logo}
          width={35}
          customStyle={{
            width: 35, height: 35, borderRadius: '50%', position: 'relative', marginRight: 8
          }}
          isCheckMark={props.origin.isCompanyVerified}
        />
        <div>
          <Name>{props.origin.name}</Name>

          <ReactTooltip
            id={tooltip}
            className='eta-tooltip-content'
            clickable={true}
            event="click"
          > 
            {
              getEtaLoading(props.order_containers, props.origin?.date || '')
            }
          </ReactTooltip>

          <div style={{display: 'flex', flexDirection: 'row'}}>
          <Text 
            data-tip
            data-for={tooltip}
            style={{display: "flex", justifyContent: "end", alignItems: "center", alignSelf: "center"}}
            onMouseEnter={(e) => {
              ReactTooltip.hide();
              ReactTooltip.show(e.currentTarget);
            }}
          >
            <span style={{whiteSpace: "nowrap"}}>{props.origin.date ? getDateFormat(props.origin.date) : "Pendiente"}</span>
          </Text>
          <div style={{cursor: 'pointer'}}>
          {props.origin.editable &&
              (props.origin.date
              ? (
                changeAction &&
                (
                  <ActionsTricolon
                    tooltipPlacement={"right"}
                    actions={props.actions
                      .filter((f) => f.type.includes("cancel") || f.type.includes("change"))
                      .map((a) => ({
                        ...a,
                        additional:{
                          ticket: props.ticket,
                          maneuver: {
                            ...props.origin,
                            maneuver: "Carga",
                            ticket: props.ticket,
                            orderId: props.id,
                          }
                        },
                      }))
                    }
                  />
                )
              )
              : (
                assignAction &&
                <img 
                  alt="" 
                  src="/images/edit-transparent.svg" 
                  class="edit-image"
                  onClick={() => {
                      assignAction.action({
                        ticket: props.ticket,
                        maneuver: {
                          ...props.origin,
                          maneuver: "Carga",
                          ticket: props.ticket,
                          orderId: props.id,
                        }
                      })
                    }
                  }
                ></img>
              ))
            }
            </div>
          </div>
        </div>
      </CompanyAvatar>
      <CompanyAvatar>
        <ImageWithCheckMark
          src={props.destination.logo}
          width={35}
          customStyle={{
            width: 35, height: 35, borderRadius: '50%', position: 'relative', marginRight: 8
          }}
          isCheckMark={props.destination.isCompanyVerified}
        />
        <div>
          <Name>{props.destination.name}</Name>

          <ReactTooltip
            id={tooltip + 'unload'}
            className='eta-tooltip-content'
            clickable={true}
            event="click"
          > 
            {
              getEtaUnloading(props.order_containers,props.destination?.date || '')
            }
          </ReactTooltip>
          
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <Text 
            data-tip
            data-for={tooltip + 'unload'}
            style={{display: "flex", justifyContent: "end", alignItems: "center", alignSelf: "center"}}
            onMouseEnter={(e) => {
              ReactTooltip.hide();
              ReactTooltip.show(e.currentTarget);
            }}
          >
            <span style={{whiteSpace: "nowrap"}}>
              {props.destination.date
              ? getDateFormat(props.destination.date)
              : "Pendiente"}
            </span>
          </Text>
            <div style={{cursor: 'pointer'}}>
            {props.destination.editable &&
              (props.destination.date
              ? (
                changeAction &&
                (
                  <ActionsTricolon
                    tooltipPlacement={"right"}
                    actions={props.actions
                      .filter((f) => f.type.includes("cancel") || f.type.includes("change"))
                      .map((a) => ({
                        ...a,
                        additional:{
                          ticket: props.ticket,
                          maneuver: {
                            ...props.destination,
                            maneuver: "Descarga",
                            ticket: props.ticket,
                            orderId: props.id,
                          }
                        },
                      }))
                    }
                  />
                )
              )
              : (
                assignAction &&
                <img 
                  alt="" 
                  src="/images/edit-transparent.svg" 
                  class="edit-image"
                  onClick={() => {
                      assignAction.action({
                        ticket: props.ticket,
                        maneuver: {
                          ...props.destination,
                          maneuver: "Descarga",
                          ticket: props.ticket,
                          orderId: props.id,
                        }
                      })
                    }
                  }
                ></img>
              ))
            }
            </div>
          </div>
        </div>
      </CompanyAvatar>
      {getIDCStatus(props)}
      <Text>
        <ExceedPlus maxVisibleItems={1} name="license">
          {(props.tags || []).map((f, i) => (
            <Tag {...f} key={i} />
          ))}
        </ExceedPlus>
      </Text>
      <Text>{props.assemble}</Text>
      <Text>{props.status}</Text>
      <Text>{props.type}</Text>
      <Text>{props.level}</Text>
      <Text>{props.weight} Ton</Text>
      <Text>{props.vol} HL</Text>
      {/* <Text>{props.quantity}</Text> */}

    {/* {hasAction && props.currentFacilityId ? ( */}
        <ActionsContainer>
          {secondaryAction && (
            <Button
              onClick={secondaryAction.action}
              type={`${secondaryAction.type} small`}
              text={secondaryAction.description}
              name="secondary"
            />
          )}
          {primaryAction && (
            <Button
              onClick={primaryAction.action}
              type={`${primaryAction.type} small`}
              text={primaryAction.description}
              name="primary"
            />
          )}
          {
            props.actions
            .filter((f) => f.type.includes("extra") || f.type.includes("xtra-".concat(props.id))).length 
            ? <ActionsTricolon
              tooltipPlacement={"left"}
              actions={props.actions
                .filter((f) => f.type.includes("extra"))
                .map((a) => ({
                  ...a,
                  additional: props.ticket,
                })).concat(props.actions
                  .filter((f) => f.type.includes("xtra-".concat(props.id)))
                  .map((a) => ({
                    ...a,
                    additional: props.ticket,
                  })))}
            />
            :
            ''
          }
          
        </ActionsContainer>
      {/*)  : hasNullAppointment && props.currentFacilityId && (
        <ActionsContainer>
          {secondaryAction && (
            <Button
              type={`${secondaryAction.type} small`}
              text={secondaryAction.description}
              onClick={() => secondaryAction.action(props.ticket)}
              name="secondary"
            />
          )}
        </ActionsContainer>
      )} */}
    </RowD>
  );
};

const Text = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  & > img {
    position: absolute;
    top: 0;
    right: -25px;
    cursor: pointer;
  }
`;
const Name = styled.div`
  opacity: 0.76;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 125px;
`;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  & > img {
    width: 35px;
    height: 35px;
    border-radius: 24px;
    margin-right: 6px;
  }
  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
  justifyContent: "center"
};

const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  margin: "auto",
  // overflow: "auto",
  maxHeight: "100%",
};

const IDCStatusContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const Config: TableDConfig = {
  rowStyle: {
    backgroundColor: "rgb(34,42,62)",
    height: "80px",
  },
  headerStyle: {
    backgroundColor: "rgb(34,42,62)",
    borderBottom: "2px solid #A4B4CE",
  },
  columns: [
    {
      text: "Folio",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Origen/Cita",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Destino/Cita",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus de Remolque",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Ensamble",
      width: "7%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus de Orden",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tipo",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Nivel",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Volumen",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    // {
    //   text: "Cantidad",
    //   width: "8%",
    //   headerItemStyle,
    //   cellStyle,
    // },
    {
      text: "",
      width: "3%",
      headerItemStyle,
      cellStyle: {
        ...cellStyle,
        // overflow: "hidden"
      },
    },
  ],
};
