//@flow
import * as React from "react";
import Button from "../../shared/controls/button";
import Modal from "react-modal";
import TextInput from "../../shared/controls/text-input";
import SelectInput from "../../shared/controls/select-input";
import Tag from "../../shared/controls/tag";

import { validators } from "../../shared/validate";

import { normalizeStr } from "../../shared/utils";

import { ErrorMessage } from "../../shared/styled";
import { ToastContainer } from "react-toastify";

type CompoundEquipment = {
  classID: number,
  description: string,
  id: number,
  typeID: number,
  upload_description: string,
};

type EquipmentSubType = {
  avatar: any,
  description: string,
  id: number,
  type: string,
};

type Nomenclature = {
  configuration: string,
  id: number,
};

type CompoundVehicle = {
  abbreviation: string,
  classID: number,
  description: string,
  id: number,
  typeID: number,
};

type ExpeditionClass = {
  code: string,
  tag: number,
  vehicle: CompoundVehicle,
  equipment1: CompoundEquipment,
  equipmentSubType1: number,
  equipment2: CompoundEquipment,
  equipmentSubType2: number,
  equipment3: CompoundEquipment,
  equipmentSubType3: number,
};

type TagType = {
  id: number,
  title: string,
  color: string,
  type: string,
};

type Props = {
  show: boolean,
  manageModal: () => void,
  onChange: () => void,
  expClass: ExpeditionClass,
  tags: TagType[],
  compoundEquipments: CompoundEquipment[],
  compoundVehicles: CompoundVehicle[],
  equipmentSubTypes: EquipmentSubType[],
  nomenclatures: Nomenclature[],
  addEquipment: boolean,
  secondEquipment: boolean,
};

const CreateExpeditionClassModal = ({
  show,
  manageModal,
  onChange,
  expClass,
  tags,
  compoundEquipments,
  compoundVehicles,
  equipmentSubTypes,
  nomenclatures,
  addEquipment,
  secondEquipment,
  errorText,
}: Props) => {
  CreateExpeditionClassModal.defaultProps = {
    expClass: {
      code: "",
      tag: 0,
      vehicle: {},
      equipment1: {},
      equipmentSubType1: 0,
      equipment2: {},
      equipmentSubType2: 0,
      equipment3: {},
      equipmentSubType3: 0,
      addEquipment: false,
      secondEquipment: false,
      errorText: "",
    },
  };

  const resourcesData = (resource) => {
    let result = { visible: false, items: [], subItems: [] };

    // if (editMode) return result;
    const v = expClass.vehicle?.abbreviation?.substring(0, 2) || "";
    const e1 = expClass.equipment1?.upload_description?.substring(0, 2) || "";
    const e2 = expClass.equipment2?.upload_description?.substring(0, 2) || "";
    switch (resource) {
      case "E1":
        result.visible = ["T2", "T3", "C2", "C3"].some((type) =>
          v.includes(type)
        )
          ? true
          : false;
        result.items = ["C2", "C3"].some((type) => v.includes(type))
          ? compoundEquipments.filter((eq) =>
              ["R2", "R3", "S1", "S2"].some((type) =>
                eq.upload_description.includes(type)
              )
            )
          : ["T2", "T3"].some((type) => v.includes(type))
          ? compoundEquipments.filter((eq) =>
              ["S1", "S2", "S3"].some((type) =>
                eq.upload_description.includes(type)
              )
            )
          : [];
        result.subItems = equipmentSubTypes.filter(
          (sub) => sub.description[0] === e1[0]
        );
        break;
      case "E2":
        result.visible =
          (["T2", "T3"].some((type) => v.includes(type)) && e1 !== "S3") ||
          (v === "T3" && e1 === "S3")
            ? true
            : false;
        if (v.includes("T2")) {
          result.items = e1.includes("S1")
            ? compoundEquipments.filter((eq) =>
                ["R2", "R3", "S1", "S2"].some((type) =>
                  eq.upload_description.includes(type)
                )
              )
            : e1.includes("S2")
            ? compoundEquipments.filter((eq) =>
                ["R2", "S1", "S2"].some((type) =>
                  eq.upload_description.includes(type)
                )
              )
            : [];
        } else if (v.includes("T3")) {
          result.items = e1.includes("S1")
            ? compoundEquipments.filter((eq) =>
                ["R2", "R3", "S1", "S2"].some((type) =>
                  eq.upload_description.includes(type)
                )
              )
            : e1.includes("S2")
            ? compoundEquipments.filter((eq) =>
                ["R2", "R3", "R4", "S1", "S2"].some((type) =>
                  eq.upload_description.includes(type)
                )
              )
            : compoundEquipments.filter((eq) =>
                ["S2"].some((type) => eq.upload_description.includes(type))
              );
        }
        result.subItems = equipmentSubTypes.filter(
          (sub) => sub.description[0] === e2[0]
        );
        break;
      case "D1":
        const rule1 =
          ["C2", "C3"].some((type) => v.includes(type)) &&
          ["S1", "S2"].some((type) => e1.includes(type));
        const rule2 =
          ["T2"].some((type) => v.includes(type)) &&
          ["S1"].some((type) => e1.includes(type)) &&
          ["S1", "S2"].some((type) => e2.includes(type));
        const rule3 =
          ["T2"].some((type) => v.includes(type)) &&
          ["S2"].some((type) => e1.includes(type)) &&
          ["S1"].some((type) => e2.includes(type));
        const rule4 =
          ["T3"].some((type) => v.includes(type)) &&
          ["S1", "S2"].some((type) => e1.includes(type)) &&
          ["S1", "S2"].some((type) => e2.includes(type));
        result.visible = rule1 || rule2 || rule3 || rule4 ? true : false;

        result.items = compoundEquipments.filter((eq) =>
          ["T3"].some((type) => v.includes(type)) &&
          ["S2"].some((type) => e1.includes(type)) &&
          ["S2"].some((type) => e2.includes(type))
            ? normalizeStr(eq.description.toLowerCase()).includes("dolly")
            : normalizeStr(eq.upload_description.toLowerCase()).includes("d1")
        );
        result.subItems = equipmentSubTypes.filter((e) =>
          e.type.toLowerCase().includes("dolly")
        );
        break;
      default:
        result.visible = false;
    }
    return result;
  };

  const getNomenclature = () => {
    const nom = [
      expClass.vehicle?.abbreviation?.substring(0, 2) || "",
      expClass.equipment1?.upload_description?.substring(0, 2) || "",
      expClass.equipment2?.upload_description?.substring(0, 2) || "",
      //   nomenclatureEquipment2,
    ].filter((x) => x);

    if (expClass.equipment3?.id) {
      const last = nom.pop();
      const n =
        parseInt(last.substring(1, 2)) +
        parseInt(expClass.equipment3.upload_description.substring(1, 2));
      nom.push("R".concat(n));
    }
    return nom.join("-");
  };

  const isValidForm = () => {
    const daRulez = [];
    daRulez.push(expClass.code.length > 0);
    daRulez.push(
      nomenclatures.find((n) => n.configuracion === getNomenclature())
        ? true
        : false
    );
    daRulez.push(
      addEquipment
        ? expClass.equipment1?.id && expClass.equipmentSubType1 > 0
          ? true
          : false
        : true
    );
    daRulez.push(
      secondEquipment
        ? expClass.equipment2?.id && expClass.equipmentSubType2 > 0
          ? true
          : false
        : true
    );
    daRulez.push(
      resourcesData("D1").visible
        ? (expClass.equipment3?.id && expClass.equipmentSubType3 > 0) ||
          getNomenclature() === "T3-S2-S2"
          ? true
          : false
        : true
    );

    return !daRulez.filter((r) => !r).length > 0;
  };

  return (
    <Modal
      isOpen={show}
      portalClassName="dialog emails-modal expedition-class-modal"
      overlayClassName="overlay"
      className="content"
    >
      <ToastContainer className="" />
      <div className="title">Nueva clase de expedición</div>
      <div
        className="close"
        onClick={() =>
          manageModal({ target: { name: "close", value: "close" } })
        }
      ></div>
      {errorText.length > 0 && <ErrorMessage>{`* ${errorText}`}</ErrorMessage>}
      <div className="message">
        <div>
          <TextInput
            label="Expedición (Máximo 4 caracteres)"
            name="expedition"
            value={expClass.code}
            className="item"
            onChange={onChange}
            validate={[validators.required, "maxLength"]}
            validationParam={4}
            // validate={[validators.required]}
            //   onFocused={onFocused}
            //   disabled={editMode}
          />
        </div>
        <div>
          <span className="section-title">Etiqueta de clase de expedición</span>
          <div className="tags">
            {tags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  color={tag.color}
                  title={tag.title}
                  type={tag.type}
                  isFull={expClass.tag === tag.id}
                  onClick={(e) =>
                    onChange({
                      target: {
                        name: "selectModalTag",
                        value: tag.id,
                      },
                    })
                  }
                  parentBackground="light"
                />
              );
            })}
          </div>
        </div>
        <div>
          <SelectInput
            label="Tipo de vehículo"
            name="vehicleType"
            value={expClass.vehicle.id || 0}
            className="item"
            onChange={onChange}
            items={compoundVehicles}
            validate={[validators.required]}
            //   onFocused={onFocused}
            //   disabled={editMode}
          />
        </div>
        {resourcesData("E1").visible && (
          <label
            name="addEquipment"
            onClick={() =>
              onChange({
                target: { name: "addEquipment", value: "" },
              })
            }
          >
            <u>{addEquipment ? "Sin remolque" : "Añadir remolque"}</u>
          </label>
        )}
        {resourcesData("E1").visible && addEquipment && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de equipo"
                name="equipmentType"
                value={expClass.equipment1.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("E1").items}
                validate={[validators.required]}
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                // disabled={editMode}
              />
            </div>

            <div style={{ marginBottom: "30px" }}>
              <SelectInput
                label="Sub-tipo de equipo"
                name="equipmentSubType"
                value={expClass.equipmentSubType1}
                className="item"
                onChange={onChange}
                items={resourcesData("E1").subItems}
                validate={[validators.required]}
                // items={equipmentSubTypes}
                // disabled={editMode}
              />
            </div>
            {resourcesData("E2").visible && (
              <label
                name="secondEquipment"
                onClick={() =>
                  onChange({
                    target: { name: "secondEquipment", value: "" },
                  })
                }
              >
                <u>
                  {secondEquipment
                    ? "Sin segundo remolque"
                    : "Añadir 2do remolque"}
                </u>
              </label>
            )}
          </React.Fragment>
        )}
        {resourcesData("E2").visible && secondEquipment && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de equipo"
                name="equipmentType2"
                value={expClass.equipment2.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("E2").items}
                validate={[validators.required]}
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                // disabled={editMode}
              />
            </div>

            <div>
              <SelectInput
                label="Sub-tipo de equipo"
                name="equipmentSubType2"
                value={expClass.equipmentSubType2}
                className="item"
                onChange={onChange}
                items={resourcesData("E2").subItems}
                validate={[validators.required]}
                // disabled={editMode}
              />
            </div>
          </React.Fragment>
        )}

        {resourcesData("D1").visible && (
          <React.Fragment>
            <div>
              <SelectInput
                label="Tipo de dolly"
                name="dollyType"
                value={expClass.equipment3.id || 0}
                className="item"
                onChange={onChange}
                items={resourcesData("D1").items}
                validate={
                  getNomenclature() === "T3-S2-S2" ? [] : [validators.required]
                }
                // validate={getEquipmentValidation()}
                // onFocused={onFocused}
                // disabled={editMode}
              />
            </div>

            <div>
              <SelectInput
                label="Sub-tipo de dolly"
                name="dollySubType"
                value={expClass.equipmentSubType3}
                className="item"
                onChange={onChange}
                items={resourcesData("D1").subItems}
                validate={
                  getNomenclature() === "T3-S2-S2" ? [] : [validators.required]
                }
                // disabled={editMode}
              />
            </div>
          </React.Fragment>
        )}

        <div className="top-space">
          <span className="section-title">Nomenclatura</span> <br />
          <span>{getNomenclature()}</span>
        </div>
      </div>
      <div className="actions">
        <Button
          text="Cancelar"
          type="primary outline"
          onClick={() =>
            manageModal({ target: { name: "cancel", value: "cancel" } })
          }
        />
        <Button
          text="Guardar"
          type="primary"
          onClick={() => manageModal({ target: { name: "save", value: "" } })}
          disabled={!isValidForm()}
          // dataTip={getErrors()}
          // dataFor="orbi-tooltip-error"
          //   onMouseLeave={() => handleLeave()}
        />
        {/* <ReactTooltip
        id={"orbi-tooltip-error"}
        place="top"
        type="light"
        effect="float"
        html={true}
        event="mouseover"
        eventOff="mouseleave"
      /> */}
      </div>
    </Modal>
  );
};

export default CreateExpeditionClassModal;
