import React from 'react';
import { connect } from "react-redux";
import StepTitle from '../../shared/component/step-title';
import SelectInput from '../../shared/controls/select-input';
import TextInput from '../../shared/controls/text-input';
import { Equipment, Truck } from '../../shared/icons/assemble';
import { View, ViewTitle } from '../../shared/styled/view';
import SVGIcon, { icons } from '../../shared/svg-icon';
import { US_HOURLY_FORMAT } from '../../shared/utils';

import {
  ApiOptions,
  readEntity,
  readEntities,
  getEntityItems,
  getEntityArgs,
  createEntity,
  cancelRequest,
} from "../../redux/api/";
import { isArray } from 'lodash';
import Button from '../../shared/controls/button';
import Loader from '../../shared/icons/tat/loader';
import GenericDialog from "../../shared/dialog/generic-modal";
import moment from 'moment';
import { validators } from '../../shared/validate';
import ReactTooltip from 'react-tooltip';
import OptionsList from '../../shared/component/options-list';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import { cancelMultipleRequests } from '../../redux/api/actions';

class BillingFormView extends React.Component {
  state = {
    sendingRfc: 0,
    sendingName: '',
    fiscalRegime: '',
    billType: 0,
    receivingRfc: 0,
    receivingName: '',
    billUse: 0,
    expeditionDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
      ),
    postalCode: '',
    currency: 0,
    exchangeRate:'',
    paymentMethod: 0,
    paymentType: 0,
    series: '',
    folio: '',
    confirmation: '',
    paymentConditions: '',
    productKey: 0,
    unitKey: 0,
    quantity: '1',
    unit: '',
    productId: '',
    productDescription: '',
    unitPrice: '',
    amount: '',
    discount: '',
    transferBase: '',
    transferTax: 0,
    transferRate: 0,
    transferRateValue: 0,
    transferAmount: '',
    retainBase: '',
    retainTax: 0,
    retainRate: 0,
    retainRateValue: '0.040000',
    retainAmount: '',
    origin: 0,
    destination: 0,
    SCTPermit: 0,
    SCTPermitNumber: '',
    insuranceCompanyName: '',
    insuranceNumber: '',
    vehicleConfiguration: 0,
    vehiclePlates: '',
    vehicleYear: '',
    equipmentOneSubtype: 0,
    equipmentOnePlates: '',
    equipmentTwoSubtype: 0,
    equipmentTwoPlates: '',
    operator: 0,
    tipoFacturaValue: 0,

    tax_info: {},
    details_info: {},
    rfcList1: [],
    rfcList2: [],
    originList: [],
    destinyList: [],
    loading: false,

    showSuccessMessage: false,
    showErrorMessage: false,

    onFocused: false,
    onFocusedProducts: false,
    errorMsg: [],

    isOrder: false,
    productsToAdd: [],
    productsToModify: [],
    editProductMode: false,
    showEditProducts: false,
    showSuccessEditProducts: false,
    isLoadingSearchProducts: false
  }

  totalWeight = 0;
  totalPrice = 0;

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener("keydown", this.cancelUpdate)
    this.props.loadTasaCuota({
      onSuccess: (r) => {
        let items = r.filter(item => (item.rango_o_fijo === 'Fijo' && item.impuesto === 'IVA'));
        this.setState({transferRateValue: (items.find(i => i.maximo.includes('0.16')) || {}).id || 0})
      }
    });
    this.props.loadTipoFactura({
      onSuccess: (r) => {
        let val = (r.find(tF => tF.descripcion === 'Ingreso') || {id: 0}).id
        this.setState({tipoFacturaValue: val});
      }
    });
    this.props.loadUsoCfdi();
    this.props.loadMonedas({
      onSuccess: (r) => {
        let currency = r.find(item => (item.clave === 'MXN')) || {};
        this.setState({currency: currency.id || 0})
      }
    });
    this.props.loadUnitCatalog({
      company_id: this.props.companyId
    });
    this.props.loadFormaDePago();
    this.props.loadMetodoDePago();
    this.props.loadClaveProdServCp();
    this.props.loadClaveUnidadPeso();
    this.props.loadImpuesto();
    this.props.loadTipoFactor();

    this.setState({isOrder: this.isOrder()})

    if (this.props.match?.params?.tripId) {

      this.props.getTaxInfo({
        company_id: this.props.companyId,
        trip_id: this.props.match?.params?.tripId,
        request_type: this.isOrder() ? 'orders' : 'trips',
        action: 'get_tax_info'
      },{
        onSuccess: (r) => {

          const rfcList1 = (r.tax_info_generator_company ?? []).map( d => ({id: d.id, description: d.data?.fiscal_number ?? '/'}))
          const rfcList2 = (r.tax_info_receiver_company ?? []).map( d => ({id: d.id, description: d.data?.fiscal_number ?? '/'}))
          const originList = (r.tax_info_origin ?? []).map( d => ({id: d.id, description: d.tax_info?.data?.legal_company_name ?? '/'}))
          const destinyList = (r.tax_info_destination ?? []).map( d => ({id: d.id, description: d.tax_info?.data?.legal_company_name ?? '/'}))
          const receivingRfc = r.preferences?.tax_info_receiver_company ?? 0;

          this.setState({
            tax_info: r,
            rfcList1,
            rfcList2,
            originList,
            destinyList,
            sendingRfc: rfcList1.length === 1 ? rfcList1[0].id : 0,
            receivingRfc: rfcList2.length === 1 ? rfcList2[0].id : receivingRfc,
            origin: originList.length === 1 ? originList[0].id : 0,
            destination: destinyList.length === 1 ? destinyList[0].id : 0,
          });
        }
      });

      this.props.getTaxInfo({
        company_id: this.props.companyId,
        trip_id: this.props.match?.params?.tripId,
        request_type: this.isOrder() ? 'orders' : 'trips',
        action: 'get_details'
      },{
        onSuccess: (r) => {
          if(this.isOrder()){
            let currentOrder = r.orders.find(o => o.id.toString() === this.props.match?.params?.tripId) || {};
            this.setState({folio: currentOrder.code ?? ''})
          } else {
            let folio = r.orders.map(o => o.code).join(' - ');
            this.setState({folio})
          }
          this.setState({
            details_info: r
          });
        }
      });
    }

    
  }

  handleClickOutside = event => {

    if (`${event.target.className}` !== 'option-list-component') {
      for (var i = 0; i < this.state.productsToAdd.length; i++) {
        this.state.productsToAdd[i].changing = false;
      }
  
      this.setState({});
    }

  }

  isOrder = () => {
    const { search = "" } = this.props.history.location;
    const variables = search === "" ? [] : search.substr(1).split("&");
    const result = variables.length ? (variables.find(v => v.includes("isOrder")) || "").split("=") : [];

    if (Array.isArray(result) && result.length > 1) {
      return result[1] === "1";
    } else {
      return false;
    }
  }

  isDirect = () => {
    const { search = "" } = this.props.history.location;
    const variables = search === "" ? [] : search.substr(1).split("&");
    const result = variables.length ? (variables.find(v => v.includes("status")) || "").split("=") : [];

    if (Array.isArray(result) && result.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'paymentType') {

      let paymentType = (this.props.metodoDePago || []).filter( item => `${item.id}` === `${value}`);
      if (paymentType && isArray(paymentType) && paymentType.length > 0 && paymentType[0].description.includes('PPD')) {

        let paymentMethod = (this.props.formaDePago || []).filter( item => `${item.description}`.includes('99'));
        if (paymentMethod && isArray(paymentMethod) && paymentMethod.length > 0) {
          this.state.paymentMethod = `${paymentMethod[0].id}`
        }

      }

    } else if (name === 'paymentMethod') {

      let paymentMethod = (this.props.formaDePago || []).filter( item => `${item.id}` === `${value}`);
      if (paymentMethod && isArray(paymentMethod) && paymentMethod.length > 0 && paymentMethod[0].description.includes('99')) {

        let paymentType = (this.props.metodoDePago || []).filter( item => `${item.description}`.includes('PPD'));
        if (paymentType && isArray(paymentType) && paymentType.length > 0) {
          this.state.paymentType = `${paymentType[0].id}`
        }

      }

    }

    this.setState({[name]: value})
  }

  getData = () => {

    let operatorsId = this.getOperatorsId();

    var tasaCuotaValue = null;

    for (var i = 0; i < (this.props.tasaCuota ?? []).length; i++) {
      if (`${this.state.transferRateValue}` === `${this.props.tasaCuota[i].id}`) {
        tasaCuotaValue = this.props.tasaCuota[i].description;
      }
    }
    let data = {
      // "trip_id": this.isOrder() ? null : this.props.match?.params?.tripId ?? 0,
      // "order_id": this.isOrder() ? this.props.match?.params?.tripId : null,
      "generator_company": this.state.sendingRfc,
      "receiver_company": this.state.receivingRfc,
      "data": {
        "export": "01",
        "objeto_imp": "02",
        "type": this.state.tipoFacturaValue,
        "invoice_use": this.state.billUse,
        "currency": this.state.currency,
        "exchange_rate": null,//this.state.exchangeRate,
        "payment_form": this.state.paymentMethod,
        "payment_method": this.state.paymentType,
        "invoice_serie": this.state.series === '' ? null : this.state.series,
        "invoice_number": this.state.folio === '' ? null : this.state.folio,
        "payment_conditions": this.state.paymentConditions === '' ? null : this.state.paymentConditions,
        "service_code": this.state.productKey === 0 ? this.props.productKeyDefault : this.state.productKey,
        "clave_de_unidad": this.props.unitKeyValue,
        "quantity": this.state.quantity,
        "unit_value": this.state.unitPrice,
        "invoice_amount": this.state.amount,
        "discount": this.state.discount === '' ? null : this.state.discount,
        "transfer_tax": {
          "base": this.state.amount,
          "tax": this.props.transferTax,
          "rate": this.props.transferRate,
          "rate_value": tasaCuotaValue,
          "amount": this.state.transferAmount,
        },
        "withhold_tax":{
          "base": this.state.amount,
          "tax": this.props.retainTax,
          "rate": this.props.retainRate,
          "rate_value": this.state.retainRateValue,
          "amount": this.state.retainAmount
        },
        "tax_info_generator_company": this.state.sendingRfc,
        "tax_info_receiver_company": this.state.receivingRfc,
        "tax_info_first_operator": operatorsId[0],
        "tax_info_second_operator": operatorsId[1],
        "tax_info_vehicle": this.getVehicleIds(),
        "tax_info_origin": this.state.origin,
        "tax_info_destination": this.state.destination
      }
    }

    if(this.isOrder()){
      data["order_id"] = this.props.match?.params?.tripId || 0;
    } else {
      data["trip_id"] = this.props.match?.params?.tripId || 0;
    }

    if(this.isDirect()){
      data["status"] = 3;
    }

    return data;
  }

  getOperatorsId = () => {

    let operators = this.state.tax_info?.tax_info_operators ?? [];

    if (operators.length > 1) {
      return [operators[0].id ?? 0, operators[1].id ?? 0];
    } else if (operators.length > 0) {
      return [operators[0].id ?? 0, 0];
    } else {
      return [0, 0];
    }

  }

  getVehicleIds = () => {

    let vehicles = this.state.tax_info?.tax_info_vehicle ?? [];

    if (vehicles.length > 0) {
      return vehicles[0].id ?? 0;
    } else {
      return 0
    }

  }

  getRFCEmisor = () => {
    var rfc = null;

    const rfcList = (this.state.tax_info?.tax_info_generator_company ?? []);

    for (var i = 0; i < rfcList.length; i++) {
      var item = rfcList[i];

      if (`${item.id}` == `${this.state.sendingRfc}`) {
        rfc = item
      }
    }

    return rfc;
  }

  getReceivingRFCName = () => {
    var name = '';

    const rfcList = (this.state.tax_info?.tax_info_receiver_company ?? []);

    for (var i = 0; i < rfcList.length; i++) {
      var item = rfcList[i];

      if (`${item.id}` == `${this.state.receivingRfc}`) {
        name = item.data?.legal_company_name ?? ''
      }
    }

    return name;
  }

  getReceivingRegimeFiscal = () => {
    var name = '';

    const rfcList = (this.state.tax_info?.tax_info_receiver_company ?? []);

    for (var i = 0; i < rfcList.length; i++) {
      var item = rfcList[i];

      if (`${item.id}` == `${this.state.receivingRfc}`) {
        name = item.data?.tax_regime?.descripcion ?? ''
      }
    }

    return name;
  }

  getReceivingPC = () => {
    var name = '';

    const rfcList = (this.state.tax_info?.tax_info_receiver_company ?? []);

    for (var i = 0; i < rfcList.length; i++) {
      var item = rfcList[i];

      if (`${item.id}` == `${this.state.receivingRfc}`) {
        name = item.data?.suburb?.cp ?? ''
      }
    }

    return name;
  }

  getDetailInfoData = (id, isOrigin) => {
    var dataToReturn = null;

    if (isOrigin) {

      const taxList = (this.state.tax_info?.tax_info_origin ?? []);

      for (var i = 0; i < taxList.length; i++) {
        var item = taxList[i];

        if (`${item.id}` == `${id}`) {
          dataToReturn = item.tax_info ?? {};
        }
      }

    } else {

      const taxList = (this.state.tax_info?.tax_info_destination ?? []);

      for (var i = 0; i < taxList.length; i++) {
        var item = taxList[i];

        if (`${item.id}` == `${id}`) {
          dataToReturn = item.tax_info ?? {};
        }
      }

    }

    return dataToReturn;
  }

  getDetailInfo = (d, dateString) => {
    if (d) {
      return (
        <div>RFC: {`${d.data?.fiscal_number ?? ''}`}</div>
        // <div className="detailInfo">
        //   <div className="row">
        //     <div>RFC: {`${d.data?.fiscal_number ?? ''}`}</div>
        //     <div>Salida: {dateString !== '' ? `${moment(dateString).format('DD MMM YYYY HH:mm')} hrs` : '-'}</div>
        //   </div>
        //   <div>
        //     Domicilio: { `${d.data?.street ?? ''} ${d.data?.exterior_number ?? ''} Int ${d.data?.interior_number ?? ''} ` }
        //   </div>
        //   <div>
        //     Col:{ `${d.data?.suburb?.cp ?? ''} ${d.data?.suburb?.nombre ?? ''}` }
        //   </div>
        //   <div>
        //     Localidad: { `${d.data?.locality?.clave ?? ''} (${d.data?.locality?.descripcion ?? ''})` }
        //   </div>
        //   <div>
        //     Referencia: { (d.geolocation ?? []).length > 1 ? `${d.geolocation[0]}, ${d.geolocation[1]}` : '-'}
        //   </div>
        //   <div>
        //     Municipio: { `${d.data?.city?.clave ?? ''} (${d.data?.city?.descripcion ?? ''})` }
        //   </div>
        //   <div>
        //     Estado: { `${d.data?.state?.clave ?? ''} (${d.data?.state?.descripcion ?? ''})` }
        //   </div>
        //   <div>
        //     Pais de origen: { `${d.data?.country?.clave ?? ''} (${d.data?.country?.descripcion ?? ''})` }
        //   </div>
        //   <div>
        //     Código postal: { `${d.data?.zip_code?.cp ?? ''}` }
        //   </div>
        // </div>
      );

    } else {
      return(
        <div style={{height: 20}}></div>
      );
    }
  }

  getNewDetailInfo = () => {

    let orders = this.state.details_info?.orders ?? [];
    let ordersToShow = [];

    let lastFacility = '';

    for (let i = 0; i < orders.length; i++) {
      let d = orders[i];
      let fromFacilityName = d?.from_facility?.alias ?? '';
      let toFacilityName = d?.to_facility?.alias ?? '';

      if (lastFacility === '') {
        ordersToShow = ordersToShow.concat({facility: d?.from_facility ?? {}, date: d?.appointment_loading_date ?? '', distance: '-', type: 1});
        ordersToShow = ordersToShow.concat({facility: d?.to_facility ?? {}, date: d?.appointment_unload_date ?? '', distance: d?.distance ?? '-', type: 2});
      } else if (fromFacilityName === lastFacility) {
        ordersToShow = ordersToShow.concat({facility: d?.to_facility ?? {}, date: d?.appointment_unload_date ?? '', distance: d?.distance ?? '-', type: 2});
      } else {
        ordersToShow = ordersToShow.concat({facility: d?.from_facility ?? {}, date: d?.appointment_loading_date ?? '', distance: '-', type: 1});
        ordersToShow = ordersToShow.concat({facility: d?.to_facility ?? {}, date: d?.appointment_unload_date ?? '', distance: d?.distance ?? '-', type: 2});
      }

      lastFacility = toFacilityName;

    }

    return(
      <div>
        {
          ordersToShow.map( d => (this.getAddressDetail(d.facility, d.date, d.distance, d.type)))
        }
      </div>
    );
  }

  getAddressDetail = (facility_info, dateString, distance, type) => {

    // var orders = this.state.details_info?.orders ?? [];

    return(
      // <div>
      //   {
          // orders.map( d => (
            <div className="location-info">
              <div className="address-title">
                <div>Ubicación {type === 1 ? 'Origen' : 'Destino'}: {facility_info.alias ?? ''}</div>
                <div>Salida: { dateString !== '' ? moment(dateString).format('DD MMM YYYY HH:mm') : '-'}</div>
              </div>
              <div className="address-info">
                <div>
                  <div>Domicilio: {facility_info.data?.street ?? ''}</div>
                  <div>Col: {facility_info.data?.suburb?.clave ?? ''} {facility_info.data?.suburb?.nombre ?? ''}</div>
                  <div>Localidad: {facility_info.data?.locality?.clave ?? ''} {facility_info.data?.locality?.descripcion ?? ''}</div>
                  <div>Municipio: {facility_info.data?.city?.clave ?? ''} {facility_info.data?.city?.descripcion ?? ''}</div>
                  <div>Estado: {facility_info.data?.state?.clave ?? ''}</div>
                  <div>País: {facility_info.data?.country?.clave ?? ''}</div>
                </div>
                <div>
                  <div>Codigo Postal: {facility_info.data?.suburb?.cp ?? ''}</div>
                  <div>Referencia: { (facility_info.geolocation ?? []).length > 1 ? `${facility_info.geolocation[0]}, ${facility_info.geolocation[1]}` : ''}</div>
                  <div>Distancia: {distance ?? '-'}</div>
                  <div>Clave de transporte: 01</div>
                </div>
              </div>
            </div>
            // )
          // )
      //   }
      // </div>
    );
  }

  getUnique = (array, key) => {
    if (typeof key !== 'function') {
      const property = key;
      key = function(item) { return item[property]; };
    }
    return Array.from(array.reduce(function(map, item) {
      const k = key(item);
      if (!map.has(k)) map.set(k, item);
      return map;
    }, new Map()).values());
  }

  getUniqueProducts = () => {
    let productsToOrder = [];

    let packagesTrip = this.state.details_info?.orders ?? [];

    for (let i = 0; i < packagesTrip.length; i++) {
      if (packagesTrip[i].packages && packagesTrip[i].packages.length)  {
        productsToOrder = productsToOrder.concat(packagesTrip[i].packages);
      }
    }
    
    this.totalWeight = 0;
    this.totalPrice = 0;

    for (let i = 0; i < productsToOrder.length; i++) {
      let quantity = parseFloat(productsToOrder[i].quantity ?? 0);

      let multiplier = parseFloat(1);

      if (productsToOrder[i].product_unit === 'TNE') {
        multiplier = parseFloat(1000);
      }

      this.totalWeight += (parseFloat(productsToOrder[i].gross_weight ?? 0) * multiplier);
      this.totalPrice += (parseFloat(productsToOrder[i].amount ?? 0) * multiplier);
    }
    
    return productsToOrder;
  }

  getProducts = (products = []) => {
    var itemsToShow = [];

    for (var i = 0; i < products.length; i++) {
      if (this.state.editProductMode) {
        if (!this.state.productsToModify[i].delete) {
          itemsToShow = itemsToShow.concat(this.getProduct(products[i], i));
        }
      } else {
        itemsToShow = itemsToShow.concat(this.getProduct(products[i], i));
      }
    } 

    for (var i = 0; i < this.state.productsToAdd.length; i++) {
      itemsToShow = itemsToShow.concat(this.getNewProduct(this.state.productsToAdd[i], i));
    }

    return(
      <div className="resume-body">
        {itemsToShow}
      </div>
    );
  }

  isValidField = (index) => {
    if (index < this.state.productsToModify.length) {
      return this.state.productsToModify[index].quantity !== '';
    }
    return false;
  }

  isSkuValid = (index) => {
    if (index < this.state.productsToAdd.length) {
      return this.state.productsToAdd[index].sku !== '';
    }
    return false;
  }

  isQuantityValid = (index) => {
    if (index < this.state.productsToAdd.length) {
      return this.state.productsToAdd[index].quantity !== '';
    }
    return false;
  }

  getQuantityField = (data, i) => {

    if (!this.state.editProductMode) {
      return (
        <>
          {data}
        </>
      );
    } 
    return(
      <>
        <div style={{
          width: 'calc(100% - 25px)'
        }}>
          <TextInput 
            label=''
            type='text'
            value={this.state.productsToModify[i].quantity}
            name='quantity'
            errors={(!this.isValidField(i) && this.state.onFocusedProducts) ? ['*'] : []}
            onFocused={this.state.onFocused}
            onChange={(e) => {
              const { name, value } = e.target;

              let valueToAdd = (`${value}` === '0' || `${value}` === '-') ? '' : value;
              let withDecimals = `${this.state.productsToModify[i].productUnit}` === '53' || `${this.state.productsToModify[i].productUnit}`.toLowerCase() === 'granel';

              if (withDecimals) {

                if (this.state.productsToModify[i].quantity.length > 0 && this.state.productsToModify[i].quantity.includes('.')) {

                  if (valueToAdd.length > 0) {
                    let pointsSegments = valueToAdd.split('.');
                    let pointsCount = pointsSegments.length - 1;

                    if (pointsCount < 2) {
                      if (pointsCount === 1) {
                        if (pointsSegments[1].length < 7) {
                          this.state.productsToModify[i].quantity = valueToAdd;  
                        }
                      } else {
                        this.state.productsToModify[i].quantity = valueToAdd;
                      }
                    }

                  } else {
                    this.state.productsToAdd[i].quantity = valueToAdd;
                  }

                } else {
                  this.state.productsToModify[i].quantity = valueToAdd;  
                }

              } else {
                this.state.productsToModify[i].quantity = valueToAdd.replace('.','');
              }

              this.setState({});
            }}
            numeric={true}
          />
        </div>
        {
          this.state.editProductMode ?
          <img
            key={`${i}-delete`}
            alt={`${i}`}
            src="/images/delete_blue.svg"
            style={{ cursor: 'pointer', height: '17px', width: '15px'}}
            onClick={(e) => {
              this.state.productsToModify[i].delete = true;
              this.setState({});
            }}
          /> : <div/>
        }
        <div style={{width: 14}}/>
      </>
    );
  }

  getProduct = (data, index) => {
    var weight = parseFloat(data.gross_weight ?? '0');
    var realAmount = parseFloat(data.amount ?? '0');

    if (data.product_unit === 'TNE') {
      weight = weight * 1000;
    }

    return(
      <div className="resume-row" style={{position: 'relative', zIndex: 99999}}>
        <div style={{width: "10%"}}>
          {`${data.sat_code ?? '-'}`}
        </div>
        <div style={{width: "22%"}}>
          {`${data.description ?? ''}`}
        </div> 
        <div style={{width: "9%", display: 'flex', alignItems: 'center'}}>
          {this.getQuantityField(`${data.quantity ?? '1'}`, index)}
        </div>
        {/* <div style={{width: "5%"}}>
          {`${data.packing_code ?? ''}`}
        </div> */}
        <div style={{width: "5%"}}>
          { this.getUnitProduct(`${data.product_unit_orbi ?? ''}`) }
        </div>
        <div style={{width: "5%"}}>
          {`${(data.dangerous_product) ? 'Si' : 'No'}`}
        </div>
        <div style={{width: "5%"}}>
          {`${data.dangerous_product_code ?? '-'}`}
        </div>
        <div style={{width: "7%"}}>
          {`${data.packing_code ?? '-'}`}
        </div>
        <div style={{width: "10%"}}>
          {`${weight}`}
        </div>
        <div style={{width: "10%"}}>
          {`${realAmount}`}
        </div>
        <div style={{width: "5%"}}>
        {`${data.currency_code ?? ''}`}
        </div>
        <div style={{width: "6%"}}>
          {`${data.product_arancel ?? '-'}`}
        </div>
        <div style={{width: "6%"}}>
          {`${data.sku ?? '-'}`}
        </div>
      </div>
    );
  }

  getProductsList = () => {
    return this.props.productsToShow
  }

  getNewProduct = (data, index) => {
    return(
      <div className="resume-row" style={{position: 'relative', zIndex: 99999, marginBottom: 15}}>
        <div style={{width: "10%"}}>
          <TextInput 
            label='SKU'
            type='text'
            value={this.state.productsToAdd[index].sku}
            name='new_sku'
            errors={(!this.isSkuValid(index) && this.state.onFocusedProducts) ? ['*'] : []}
            onFocused={this.state.onFocused}
            onChange={(e) => {
              const { name, value } = e.target;
              this.state.productsToAdd[index].changing = true;
              this.state.productsToAdd[index].sku = value;

              if (String(value).length > 2) {
                this.state.isLoadingSearchProducts = true;
                this.props.cancelMultipleRequests();
                this.props.searchProducts({ 
                  search: value,
                  company_id: this.props.companyId,
                  trip_id: this.props.match?.params?.tripId ?? 0,
                  request_type: this.isOrder() ? 'orders' : 'trips',
                  isMultipleCancels: true
                }, {
                  onSuccess: (r) => {
                    this.setState({isLoadingSearchProducts: false});
                  }
                })
              }

              this.setState({});

            }}
          />
          <OptionsList
            width={'45%'}
            visible={this.state.productsToAdd[index].changing && this.state.productsToAdd[index].sku !== ''}
            loading={this.state.isLoadingSearchProducts}
            items={this.props.productsToShow.filter( e => { 
              return true;
              // var isFound = false;

              // for (var i = 0; i < this.state.productsToModify.length; i++) {
              //   let item = this.state.productsToModify[i];
              //   if (item.sku === e.sku)  {
              //     isFound = true;
              //   }
              // }

              // if (!isFound) {
              //   for (var i = 0; i < this.state.productsToAdd.length; i++) {
              //     let item = this.state.productsToAdd[i];
              //     if (item.sku === e.sku && item.data !== null)  {
              //       isFound = true;
              //     }
              //   }
              // }

              // return !isFound;
            }).map( e => `${e.sku} - ${e.description}`)}
            onClick={(value) => {

              var dataToSave = null;

              for (var i = 0; i < this.props.productsToShow.length; i++) {
                let product = this.props.productsToShow[i];

                if (value === `${product.sku} - ${product.description}`) {
                  dataToSave = product;
                }
              }

              if (dataToSave) {
                this.state.productsToAdd[index].sku = dataToSave.sku;
                this.state.productsToAdd[index].data = dataToSave;
                this.state.productsToAdd[index].productUnit = `${dataToSave.product_unit}`;
              }
              
              this.state.productsToAdd[index].changing = false;
              this.setState({});
            }}
          />
        </div>
        <div style={{width: "22%"}}>
          {`${this.state.productsToAdd[index].data?.description ?? ''}`}
        </div> 
        <div style={{width: "9%"}}>
          <TextInput 
            label=''
            type='text'
            value={this.state.productsToAdd[index].quantity}
            name='new_quantity'
            errors={(!this.isQuantityValid(index) && this.state.onFocusedProducts) ? ['*'] : []}
            onFocused={this.state.onFocused}
            onChange={(e) => {
              const { name, value } = e.target;

              let valueToAdd = (`${value}` === '0' || `${value}` === '-') ? '' : value;
              let withDecimals = `${this.state.productsToAdd[index].productUnit}` === '53' || `${this.state.productsToAdd[index].productUnit}`.toLowerCase() === 'granel';

              if (withDecimals) {

                if (this.state.productsToAdd[index].quantity.length > 0 && this.state.productsToAdd[index].quantity.includes('.')) {

                  if (valueToAdd.length > 0) {
                    let pointsSegments = valueToAdd.split('.');
                    let pointsCount = pointsSegments.length - 1;

                    if (pointsCount < 2) {
                      if (pointsCount === 1) {
                        if (pointsSegments[1].length < 7) {
                          this.state.productsToAdd[index].quantity = valueToAdd;  
                        }
                      } else {
                        this.state.productsToAdd[index].quantity = valueToAdd;
                      }
                    }

                  } else {
                    this.state.productsToAdd[index].quantity = valueToAdd;
                  }

                } else {
                  this.state.productsToAdd[index].quantity = valueToAdd;  
                }

              } else {
                this.state.productsToAdd[index].quantity = valueToAdd.replace('.','');
              }

              this.setState({});

            }}
            numeric={true}
          />
        </div>

        <div style={{width: "5%"}}>
          {this.getUnitProduct(`${this.state.productsToAdd[index].data?.product_unit ?? ''}`)}
        </div>
        <div style={{width: "5%"}}>
          {`${(this.state.productsToAdd[index].data?.dangerous_product) ? 'Si' : 'No'}`}
        </div>
        <div style={{width: "5%"}}>
          {`${this.state.productsToAdd[index].data?.dangerous_product_code ?? ''}`}
        </div>
        <div style={{width: "7%"}}>
          {`${this.state.productsToAdd[index].data?.packing_code ?? ''}`}
        </div>
        <div style={{width: "10%"}}>
          {`${this.state.productsToAdd[index].data?.gross_weight ?? ''}`}
        </div>
        <div style={{width: "10%"}}>
        {`${this.state.productsToAdd[index].data?.amount ?? ''}`}
        </div>
        <div style={{width: "5%"}}>
        {`${this.state.productsToAdd[index].data?.currency_code ?? ''}`}
        </div>
        <div style={{width: "6%"}}>
          {`${this.state.productsToAdd[index].data?.product_arancel ?? ''}`}
        </div>
        <div style={{width: "6%"}}>
          {`${this.state.productsToAdd[index].data?.sku ?? ''}`}
        </div>
      </div>
    );
  }
  

  evaluateForm = () => {
    return (
      this.state.editProductMode
      || !this.state.sendingRfc 
      || !this.state.receivingRfc
      || !this.state.tipoFacturaValue
      || !this.state.billUse
      || !this.state.currency
      // || !this.state.exchangeRate
      || !this.state.paymentMethod
      || !this.state.paymentType
      // || !this.state.series
      // || !this.state.folio
      // || !this.state.paymentConditions
      || !(this.state.productKey === 0 ? this.props.productKeyDefault : this.state.productKey)
      || !this.props.unitKeyValue
      || !this.state.quantity
      || !this.state.unitPrice
      || !this.state.amount
      // || !this.state.discount
      || !this.props.transferTax
      || !this.props.transferRate
      || !this.state.transferRateValue
      || !this.state.transferAmount
      || !this.props.retainTax
      || !this.props.retainRate
      || !this.state.retainRateValue
      || !this.state.retainAmount
      || !this.state.origin
      || !this.state.destination
    );
  }

  getOperatorLicense = (id) => {
    let licenseString = '';

    for (let i = 0; i < (this.state.details_info?.assembly?.operators ?? []).length; i++) {
      let operator = (this.state.details_info?.assembly?.operators ?? [])[i];

      if ( `${operator?.operator?.id ?? 0}` === `${id}`) {
        licenseString = `${operator?.operator?.user?.username ?? '-'}`
      }
    }

    return licenseString;
  }

  getDescriptionConcept = () => {
    var descriptionString = '';

    for (var i = 0; i < (this.props.claveProdServCp ?? []).length; i++) {
      var prodServ = this.props.claveProdServCp[i];

      if (`${(this.state.productKey === 0 ? this.props.productKeyDefault : this.state.productKey)}` === `${prodServ.id}`) {
        descriptionString = `${prodServ.extra ?? ''}`
      }
    }

    return descriptionString;
  }

  getOperators = () => {
    return(
      <div>
        <div style={{color: 'white'}}>Operador:</div>
        {
          (this.state.tax_info?.tax_info_operators ?? []).map( d => (
            <div className="taxingAddressInfo">
              <div className="detailInfo">
                <div className="row">
                  <div>RFC: {`${d.data?.fiscal_number ?? ''}`}</div>
                  <div>Licencia: {this.getOperatorLicense(d.operator__id ?? 0)}</div>
                </div>
                <div className="row">
                  <div>Domicilio: {`${d.data?.street ?? ''} ${d.data?.exterior_number ?? ''} Int ${d.data?.interior_number ?? ''}`}</div>
                  <div>Col: {`${d.data?.suburb?.clave ?? ''} ${d.data?.suburb?.nombre ?? ''}`}</div>
                  <div>Localidad: {`${d.data?.locality?.clave ?? ''} ${d.data?.locality?.descripcion ?? ''}`}</div>
                </div>
                <div className="row">
                  <div>Referencia: { (d.geolocation ?? []).length > 1 ? `${d.geolocation[0]}, ${d.geolocation[1]}` : ''}</div>
                  <div>Municipio: {`${d.data?.city?.clave ?? ''} ${d.data?.city?.descripcion ?? ''}`}</div>
                  <div>Estado: {`${d.data?.state?.clave ?? ''} ${d.data?.state?.descripcion ?? ''}`}</div>
                </div>
                <div className="row">
                  <div>Pais de origen: {`${d.data?.country?.clave ?? ''} ${d.data?.country?.descripcion ?? ''}`}</div>
                  <div>Código postal: {`${d.data?.zip_code?.cp ?? ''}`}</div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    );
  }

  getTaxInfoTypeName = (type) => {
    switch(type){
      case 2:
        return 'Empresa';
      case 3:
        return 'Arrendatario';
      case 4:
        return 'Propietario';
      default: 
        return '';
    }
  }

  getVehicleOwner = (RFCemisor) => {
    return(
      <div>
        {
          (this.state.tax_info?.tax_info_vehicle ?? []).filter(v => (`${v.tax_info?.data?.fiscal_number ?? ''}`) !== `${RFCemisor}`).map( d => {
            if((d.tax_info?.type ?? '') === 2 || (d.tax_info?.type ?? '') === 1) return '';
            return (
            <div style={{marginTop: "20px"}}>
              <div style={{color: 'white'}}>{this.getTaxInfoTypeName(d.tax_info?.type ?? '')}: {/* `${d.tax_info?.type ?? ''}` === '4' ? 'Propietario:' : 'Arrendatario:' */}</div>
              <div className="taxingAddressInfo">
                <div className="detailInfo">
                  <div className="row">
                    <div>RFC: {`${d.tax_info?.data?.fiscal_number ?? ''}`}</div>
                  </div>
                  <div className="row">
                    <div>Domicilio: {`${d.tax_info?.data?.street ?? ''} ${d.tax_info?.data?.exterior_number ?? ''} Int ${d.tax_info?.data?.interior_number ?? ''}`}</div>
                    <div>Col: {`${d.tax_info?.data?.suburb?.clave ?? ''} ${d.tax_info?.data?.suburb?.nombre ?? ''}`}</div>
                    <div>Localidad: {`${d.tax_info?.data?.locality?.clave ?? ''} ${d.tax_info?.data?.locality?.descripcion ?? ''}`}</div>
                  </div>
                  <div className="row">
                    <div>Municipio: {`${d.tax_info?.data?.city?.clave ?? ''} ${d.tax_info?.data?.city?.descripcion ?? '-'}`}</div>
                    <div>Estado: {`${d.tax_info?.data?.state?.clave ?? ''} ${d.tax_info?.data?.state?.descripcion ?? '-'}`}</div>
                  </div>
                  <div className="row">
                    <div>Pais de origen: {`${d.tax_info?.data?.country?.clave ?? ''} ${d.tax_info?.data?.country?.descripcion ?? ''}`}</div>
                    <div>Código postal: {`${d.tax_info?.data?.zip_code?.cp ?? ''}`}</div>
                  </div>
                </div>
              </div>
            </div>
          )})
        }
      </div>
    );
  }

  getEquipments = () => {
    return <div className="row">
      {
        (this.state.details_info?.assembly?.equipments ?? []).map( d => (
          (`${d.fleet_class?.id ?? 0}` === `${21}` || `${d.fleet_class?.id ?? 0}` === `${22}`) ? <> </>:
          <>
            <div className="svg-container">
              <Equipment width={'63px'} height={'60px'}/>
            </div>
            <TextInput 
              label='Subtipo de remolque*:'
              type='text'
              value={d.sat_code ?? '-'}
              name=''
              disabled={true}
            />
            <TextInput 
              label='Placa remolque*:'
              type='text'
              value={d.plates ?? '-'}
              name=''
              disabled={true}
            />
          </>
        ))
      }
    </div>
  }

  addNewProduct = () =>  {
    this.setState({
      productsToAdd: this.state.productsToAdd.concat({sku: '', quantity: '', changing: false, data: null, productUnit: ''})
    });
  }

  cancelEditProducts = () => {
    if (this.state.editProductMode) {
      this.setState({
        editProductMode: !this.state.editProductMode,
        productsToAdd: [],
        productsToModify: [],
      });
    } else {
      this.setProductsToModify();

      this.setState({
        editProductMode: !this.state.editProductMode,
      });
    }
  }

  cancelAddProduct = () => {
    this.setState({
      productsToAdd: [],
    });
  }

  isAbleToSaveAllProducts = () => {
    let modifications = 0;
    let modificationsField = 0;

    let newProducts = 0;
    let newProductsField = 0;

    let productsDeleted = 0;

    // New products

    newProducts = this.state.productsToAdd.length;
    for (var i = 0; i < this.state.productsToAdd.length; i++) {
      if (this.state.productsToAdd[i].data !== null && this.state.productsToAdd[i].sku !== '' && this.state.productsToAdd[i].quantity !== '') {
        newProductsField += 1;
      }
    }

    // Modifications

    let productsToOrder = [];
    let packagesTrip = this.state.details_info?.orders ?? [];

    for (let i = 0; i < packagesTrip.length; i++) {
      if (packagesTrip[i].packages && packagesTrip[i].packages.length)  {
        productsToOrder = productsToOrder.concat(packagesTrip[i].packages);
      }
    }

    if (this.state.productsToModify.length > 0) {
      for (let i = 0; i < productsToOrder.length; i++) {
        let oldProduct = productsToOrder[i];
        let newProduct = this.state.productsToModify[i];

        if (newProduct.delete) {
          productsDeleted += 1;
        }

        if (newProduct.quantity !== '') {
          modificationsField += 1;
          if (`${newProduct.quantity}` !== `${oldProduct.amount}` || newProduct.delete) {
            modifications += 1;
          }
        }
      }
    }

    if (newProducts > 0 && modifications > 0) {
      return (newProductsField === newProducts && modificationsField === this.state.productsToModify.length);
    } else {
      return ((newProducts > 0 && newProductsField === newProducts) || (productsDeleted > 0) || (modifications > 0 && modificationsField === this.state.productsToModify.length));
    }

  }

  isAbleToAddNewProduct = () => {
    if (this.state.productsToAdd.length === 0) {
      return true;
    } else {
      var ableToAdd = true;

      for (var i = 0; i < this.state.productsToAdd.length; i++) {
        if (this.state.productsToAdd[i].data === null || this.state.productsToAdd[i].sku === '' || this.state.productsToAdd[i].quantity === '') {
          ableToAdd = false;
        }
      }

      return ableToAdd;
    }
  }

  setProductsToModify = () => {
    let productsToOrder = [];
    let packagesTrip = this.state.details_info?.orders ?? [];

    for (let i = 0; i < packagesTrip.length; i++) {
      if (packagesTrip[i].packages && packagesTrip[i].packages.length)  {
        productsToOrder = productsToOrder.concat(packagesTrip[i].packages);
      }
    }

    this.state.productsToModify = [];

    for (let i = 0; i < productsToOrder.length; i++) {
      this.state.productsToModify = this.state.productsToModify.concat({
        sku: productsToOrder[i].sku,
        quantity: productsToOrder[i].quantity,
        delete: false,
        productUnit: `${productsToOrder[i].product_unit_orbi}`
      });
    }

    this.setState({});

  }

  getProductsModifications = () => {

    let modificationsToShow = [];

    for (var i = 0; i < this.state.productsToAdd.length; i++) {
      let product = this.state.productsToAdd[i];
      modificationsToShow = modificationsToShow.concat(<div>{`Se añadieron ${product.quantity} item(s) del sku ${product.sku}`}</div>);
    }

    if (this.state.productsToModify.length > 0) {
      for (var i = 0; i < this.state.productsToModify.length; i++) {
        let product = this.state.productsToModify[i];
        if (product.delete) {
          modificationsToShow = modificationsToShow.concat(<div>{`Se eliminaron ${product.quantity} item(s) del sku ${product.sku}`}</div>);
        }
      }
  
      let productsToOrder = [];
      let packagesTrip = this.state.details_info?.orders ?? [];
  
      for (let i = 0; i < packagesTrip.length; i++) {
        if (packagesTrip[i].packages && packagesTrip[i].packages.length)  {
          productsToOrder = productsToOrder.concat(packagesTrip[i].packages);
        }
      }
  
      for (let i = 0; i < productsToOrder.length; i++) {
        let oldProduct = productsToOrder[i];
        let newProduct = this.state.productsToModify[i];
  
        if (!newProduct.delete && newProduct.quantity !== oldProduct.quantity) {
          modificationsToShow = modificationsToShow.concat(<div>{`Se modificó la cantidad del sku ${newProduct.sku} de ${oldProduct.quantity} a ${newProduct.quantity}`}</div>);
        }
      }
    }

    return <div style={{maxHeight: 200, overflowY: 'scroll'}}>{modificationsToShow}</div>;
  }

  buildUpdateProductsData = () => {

    var update_skus = [];
    var delete_skus = [];
    var add_skus = [];
    var current_skus = [];

    for (var i = 0; i < this.state.productsToAdd.length; i++) {
      let product = this.state.productsToAdd[i];

      add_skus = add_skus.concat({
        id: product.data?.id ?? 0,
        sku: product.sku,
        amount: product.quantity
      });
      
    }

    if (this.state.productsToModify.length > 0) {
      for (var i = 0; i < this.state.productsToModify.length; i++) {
        let product = this.state.productsToModify[i];
        if (product.delete) {
          delete_skus = delete_skus.concat(`${product.sku}`);
        }
      }
  
      let productsToOrder = [];
      let packagesTrip = this.state.details_info?.orders ?? [];
  
      for (let i = 0; i < packagesTrip.length; i++) {
        if (packagesTrip[i].packages && packagesTrip[i].packages.length)  {
          productsToOrder = productsToOrder.concat(packagesTrip[i].packages);
        }
      }
  
      for (let i = 0; i < productsToOrder.length; i++) {
        let oldProduct = productsToOrder[i];
        let newProduct = this.state.productsToModify[i];

        if (!newProduct.delete && newProduct.quantity !== oldProduct.quantity) {
          
          update_skus = update_skus.concat({
            id: oldProduct.product_id ?? 0,
            sku: newProduct.sku,
            new_amount: newProduct.quantity,
            amount: oldProduct.quantity
          });

        } else if (!newProduct.delete && newProduct.quantity === oldProduct.quantity) {

          current_skus = current_skus.concat({
            id: oldProduct.product_id ?? 0,
            sku: newProduct.sku,
            amount: oldProduct.quantity
          });

        }
      }
    }

    return {
      update_skus,
      delete_skus,
      add_skus,
      current_skus
    };
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape"){
      if(this.state.isLoadingUpdateProducts){
        this.props.cancelRequest("COMPANIES.SAT.TAXINFO.UPDATEPRODUCTS");
        this.setState({isLoadingUpdateProducts: false});
      }
    }
  }

  updateProducts = () => {

    let data = this.buildUpdateProductsData();
    const params = {
      company_id: this.props.companyId,
      trip_id: this.props.match?.params?.tripId ?? 0,
      request_type: this.isOrder() ? 'orders' : 'trips',
      data
    };

    this.props.updateProducts({...params, ...data}, {
      onSuccess: (r) => {

        this.props.getTaxInfo({
          company_id: this.props.companyId,
          trip_id: this.props.match?.params?.tripId,
          request_type: this.isOrder() ? 'orders' : 'trips',
          action: 'get_details'
        },{
          onSuccess: (r) => {
            if(this.isOrder()){
              let currentOrder = r.orders.find(o => o.id.toString() === this.props.match?.params?.tripId) || {};
              this.setState({folio: currentOrder.code ?? ''})
            } else {
              let folio = r.orders.map(o => o.code).join(' - ');
              this.setState({folio})
            }
            this.setState({
              details_info: r
            });
          }
        });
        this.cancelEditProducts();
        this.setState({showSuccessEditProducts: true, showErrorMessage: false});
      },
      onError: (e) => {
        this.setState({
          showSuccessEditProducts: false, 
          showErrorMessage: true,
          errorMsg: e.response?.data?.errors || ["Ocurrió un error, verifica tu información"]
        });
      },
    });

  }

  getProductsErrors = () => {
    let errors = ''

    if (!this.isAbleToSaveAllProducts()) {
      errors += `Todos los campos de mercancias son requeridos<br/>`;
    }

    return errors
  }

  getUnitProduct = (unitId) => {
    var productUnit = '';

    for (var i = 0; i < (this.props.unitsCatalog || []).length; i++) {
      let item = this.props.unitsCatalog[i];

      if (`${unitId}` === `${item.id}`) {
        productUnit = `${item.description}`;
      }
    }

    return productUnit;
  }

  getErrors = () => {
    let errors = ''

    errors += !this.state.sendingRfc ? `<b>RFC del emisor<b/><br/>` : '';
    errors += !this.state.receivingRfc ? `<b>RFC del receptor<b/><br/>` : '';
    errors += !this.state.billUse ? `<b>Uso de la factura<b/><br/>` : '';
    errors += !this.state.currency ? `<b>Moneda<b/><br/>` : '';
    errors += !this.state.paymentMethod ? `<b>Forma de pago<b/><br/>` : '';
    errors += !this.state.paymentType ? `<b>Método de pago<b/><br/>` : '';
    errors += !this.state.unitPrice ? `<b>Valor unitario<b/><br/>` : '';
    errors += !this.state.transferRateValue ? `<b>Valor de la tasa o cuota a trasladar<b/><br/>` : '';

    errors += !this.state.origin ? `<b>Remitente (origen)<b/><br/>` : '';
    errors += !this.state.destination ? `<b>Consignatario (destino)<b/><br/>` : '';

    if (this.state.editProductMode) {
      errors += `<b>No has guardardo información de mercancias<b/><br/>`;
    }

    if (errors !== '') {
      errors = `Completa los siguientes campos:<br/>${errors}`;
    }

    return errors
  }

  setValueCalculations = () => {

    let quantity = parseInt(this.state.quantity);
    let unitValue = this.state.unitPrice !== '' ? Number(this.state.unitPrice) : 0;
    let discountValue = this.state.discount !== '' ? Number(this.state.discount) : 0;
    let result = (quantity * unitValue) - discountValue;

    let retainAmount = result > 0 ? (result * parseFloat(this.state.retainRateValue)).toFixed(2) : 0;

    let tasaCuotaValue = 0;

    for (let i = 0; i < (this.props.tasaCuota ?? []).length; i++) {
      if (`${this.state.transferRateValue}` === `${this.props.tasaCuota[i].id}`) {
        tasaCuotaValue = parseFloat(this.props.tasaCuota[i].description);
      }
    }

    // var transferAmount = this.state.amount !== '' ? (parseFloat(this.state.amount) * tasaCuotaValue).toFixed(2) : 0
    let transferAmount = result > 0 ? (result * tasaCuotaValue).toFixed(2) : 0;

    this.setState({amount: result > 0 ? result.toFixed(2) : 0, retainAmount, transferAmount});    
  }

  render () {

    let rfcEmisor = this.getRFCEmisor();

    return (
      <View className="billing-form-view">

        {
          this.props.isLoadingUpdateProducts &&
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              opacity: ".95",
              backgroundColor: "#cbcbcb",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 2
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        <ViewTitle>
          CFDI
        </ViewTitle>
        <div className="content">
          <StepTitle number={'01'} title={'Datos del emisor'} />
          <div className="row">
            <SelectInput
              label='RFC*:'
              // className="full"
              name="sendingRfc"
              items={this.state.rfcList1}
              value={parseInt(this.state.sendingRfc)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              hideCaret={(this.state.rfcList1 || []).length === 1}
            />
            <TextInput  
              type='text'
              label='Nombre de la compañía (Razón social):'
              name='sendingName'
              value={rfcEmisor?.data?.legal_company_name ?? ''}
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput  
              type='text'
              label='Régimen fiscal:'
              name='fiscalRegime'
              value={ rfcEmisor !== null ? `${rfcEmisor?.data?.tax_regime?.clave ?? ''} ${rfcEmisor?.data?.tax_regime?.descripcion ?? ''}` : ''}
              onChange={this.handleChange}
              disabled={true}
            />
            <SelectInput
              label='Tipo de factura:'
              // className="full"
              name="tipoFacturaValue"
              // items={this.props.countries || [{id: 1, description: 'Uno'}, {id: 2, description: 'Dos'}]}
              items={this.props.tipoFactura}
              value={parseInt(this.state.tipoFacturaValue)}
              onChange={this.handleChange}
              // disabled={true}
              // hideCaret={true}
            />
          </div>
          <StepTitle number={'02'} title={'Datos del receptor'} />
          <div className="row">
            <SelectInput
              label='RFC*:'
              // className="full"
              name="receivingRfc"
              items={this.state.rfcList2}
              value={parseInt(this.state.receivingRfc)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              hideCaret={(this.state.rfcList2 || []).length === 1}
            />
            <TextInput 
              type='text'
              label='Nombre de la compañía (Razón social):'
              name='receivingName'
              value={this.getReceivingRFCName()}
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput 
              type='text'
              label='Régimen fiscal:'
              name='regime'
              value={this.getReceivingRegimeFiscal()} 
              onChange={this.handleChange}
              disabled={true}
            />
          </div>
          <div className="row">
            <TextInput 
              type='text'
              label='Código postal:'
              name='postalCode'
              value={this.getReceivingPC()}
              onChange={this.handleChange}
              disabled={true}
            />
            <SelectInput
              label='Uso de la factura:'
              // className="full"
              name='billUse'
              // items={this.props.countries || [{id: 1, description: 'Uno'}, {id: 2, description: 'Dos'}]}
              items={this.props.usoCfdi}
              value={parseInt(this.state.billUse)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
          </div>
          <StepTitle number={'03'} title={'Comprobante'} />
          <div className={"row"}>
            <TextInput
              type="date"
              format={"dd/MMM/yyyy HH:mm"}
              timeValueFormat={US_HOURLY_FORMAT}
              showTimeSelect={true}
              value={this.state.expeditionDate}
              label="Fecha de expedición*:"
              name="expeditionDate"
              onChange={this.handleChange}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
              }}
              disabled={true}
            />
            <TextInput 
              type='text'
              label='Código postal:'
              name='postalCode'
              value={rfcEmisor?.data?.suburb?.cp ?? ''}
              onChange={this.handleChange}
              disabled={true}
            />
            <SelectInput
              label='Moneda:'
              // className="full"
              name='currency'
              // items={this.props.countries || [{id: 1, description: 'Uno'}, {id: 2, description: 'Dos'}]}
              items={this.props.monedas}
              value={parseInt(this.state.currency)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
            {/* <TextInput 
              type='text'
              label='Tipo de cambio:'
              name='exchangeRate'
              value={this.state.exchangeRate}
              onChange={this.handleChange}
            /> */}
          </div>
          <div className="row">
            <SelectInput
              label='Forma de pago:'
              // className="full"
              name='paymentMethod'
              // items={this.props.countries || [{id: 1, description: 'Uno'}, {id: 2, description: 'Dos'}]}
              items={this.props.formaDePago}
              value={parseInt(this.state.paymentMethod)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
            <SelectInput
              label='Método de pago:'
              // className="full"
              name='paymentType'
              // items={this.props.countries || [{id: 1, description: 'Uno'}, {id: 2, description: 'Dos'}]}
              items={this.props.metodoDePago}
              value={parseInt(this.state.paymentType)}
              onChange={this.handleChange}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
            />
          </div>
          <div className="row">
            <TextInput 
              type='text'
              label='Serie:'
              name='series'
              value={this.state.series}
              onChange={this.handleChange}
              length={25}
            />
            <TextInput 
              type='text'
              label='Folio:'
              name='folio'
              value={this.state.folio}
              onChange={this.handleChange}
              length={40}
            />
            {/* <TextInput 
              type='text'
              label='Confirmación:'
              name='confirmation'
              value={this.state.confirmation}
              onChange={this.handleChange}
            /> */}
          </div>
          <div className="row">
            <TextInput 
              type='text'
              label='Condiciones de pago:'
              name='paymentConditions'
              value={this.state.paymentConditions}
              onChange={this.handleChange}
              length={1000}
            />
          </div>
          <StepTitle number={'04'} title={'Concepto'} />
          <div className="row">
            <SelectInput
              label='Clave de producto o servicio*:'
              // className="full"
              name='productKey'
              items={this.props.claveProdServCp}
              value={parseInt((this.state.productKey === 0) ? this.props.productKeyDefault : this.state.productKey)}
              onChange={this.handleChange}
            />
            <SelectInput
              label='Clave de unidad*:'
              // className="full"
              name='unitKey'
              items={this.props.claveUnidadPeso}
              value={parseInt(this.props.unitKeyValue)}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <TextInput 
              type='text'
              label='Cantidad:'
              name='quantity'
              value={this.state.quantity}
              onChange={this.handleChange}
              disabled={true}
            />
            <SelectInput
              label='Objecto impuesto*:'
              name='objc_imp'
              items={[{id: 1, description: '02 Sí objecto de impuesto'}]}
              value={1}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <SelectInput
              label='Exportación*:'
              name='export'
              items={[{id: 1, description: '01 No aplica'}]}
              value={1}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
          </div>
          {/* <div className="row">
            <TextInput 
              label='Unidad:'
              type='text'
              value={this.state.unit}
              name='unit'
              onChange={this.handleChange}
            />
            <TextInput 
              label='Número de identificación:'
              type='text'
              value={this.state.productId}
              name='productId'
              onChange={this.handleChange}
            />
          </div> */}
          <div className="row">
            <TextInput 
              label='Descripción:'
              type='text'
              value={this.getDescriptionConcept()}
              name='productDescription'
              onChange={this.handleChange}
              disabled={true}
            />
          </div>
          <div className="row">
            <TextInput 
              label='Valor unitario*:'
              type='text'
              value={this.state.unitPrice}
              name='unitPrice'
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              onChange={(e) => {
                const { name, value } = e.target;
                this.setState({[name]: (`${value}` === '0' || `${value}` === '-') ? '' : value}, () => {
                  this.setValueCalculations()
                })
              }}
              length={14}
              numeric={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            />
            <TextInput 
              label='Importe*:'
              type='text'
              value={this.state.amount}
              name='amount'
              onChange={this.handleChange}
              disabled={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            />
            <TextInput 
              label='Descuento:'
              type='text'
              value={this.state.discount}
              name='discount'
              onChange={(e) => {
                const { name, value } = e.target;
                let price = Number(this.state.unitPrice);
                if(Number(value) < price){
                  this.setState({[name]: (`${value}` === '0' || `${value}` === '-') ? '' : value}, () => {
                    this.setValueCalculations()
                  })
                }
              }}
              length={14}
              numeric={true}
              disabled={!this.state.unitPrice}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            />
          </div>
          <StepTitle number={'05'} title={'Impuestos'} />
          <div className="row">
            <TextInput 
              label='Base*:'
              type='text'
              value={this.state.amount}
              name='transferBase'
              onChange={this.handleChange}
              disabled={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            />
            <SelectInput
              label='Impuesto a trasladar*:'
              // className="full"
              value={parseInt(this.props.transferTax)}
              name='transferTax'
              items={this.props.impuesto}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <SelectInput
              label='¿Tasa o cuota a trasladar?*:'
              // className="full"
              value={parseInt(this.props.transferRate)}
              name='transferRate'
              items={this.props.tipoFactor}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <SelectInput
              label='¿Valor de la tasa o cuota a trasladar*:'
              // className="full"
              value={parseInt(this.state.transferRateValue)}
              name='transferRateValue'
              items={this.props.tasaCuota}
              validate={[validators.required]}
              onFocused={this.state.onFocused}
              onChange={ (e) => {
                const { name, value } = e.target;
                this.setState({[name]: (`${value}` === '0' || `${value}` === '-') ? '' : value}, () => {
                  this.setValueCalculations()
                })
              }}
            />
            <TextInput 
              label='Importe a trasladar:'
              type='text'
              value={this.state.transferAmount}
              name='transferAmount'
              onChange={this.handleChange}
              disabled={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            /> 
          </div>
          <div className="row">
            <TextInput 
              label='Base*:'
              type='text'
              value={this.state.amount}
              name='retainBase'
              onChange={this.handleChange}
              disabled={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            />
            <SelectInput
              label='Impuesto a retener*:'
              // className="full"
              value={parseInt(this.props.retainTax)}
              name='retainTax'
              items={this.props.impuesto}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <SelectInput
              label='¿Tasa o cuota a retener?*:'
              // className="full"
              value={parseInt(this.props.retainRate)}
              name='retainRate'
              items={this.props.tipoFactor}
              onChange={this.handleChange}
              disabled={true}
              hideCaret={true}
            />
            <TextInput 
              label='Valor de la tasa o cuota a retener*:'
              type='text'
              value={this.state.retainRateValue}
              name='retainRateValue'
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput 
              label='Importe a retener:'
              type='text'
              value={this.state.retainAmount}
              name='retainAmount'
              onChange={this.handleChange}
              disabled={true}
              prefix={{
                element: <div style={{
                  width: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                  color: 'grey'}}>$</div>,
                className: "",
              }}
            /> 
          </div>
          <StepTitle number={'06'} title={'Complemento carta porte: origen y destino'} />
          <div className="row" style={{
            width: '100%',
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '25px',
            color: '#FFFFFF',
            marginTop: '15px',
          }}>
            <div style={{marginRight: "10px"}}>
              Transporte internacional: { (this.state.details_info?.international ?? false) ? 'Si' : 'No' }
            </div>
            <div>
              Total de distancia recorrida: { this.state.details_info?.distance ?? 'km' }
            </div>
          </div>
          <div className="row">
            <div className="taxingAddressInfo">
              <div>
                <SelectInput 
                  label='Remitente (origen):'
                  // className="full"
                  value={parseInt(this.state.origin)}
                  name='origin'
                  items={this.state.originList}
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                  hideCaret={(this.state.originList || []).length === 1}
                />
              </div>
              { this.getDetailInfo(this.getDetailInfoData(this.state.origin, true), this.state.details_info?.appointment_loading_date ?? '') }
              {/* <div>RFC: {'OCI140203980'}</div> */}
            </div>
            <div className="taxingAddressInfo">
              <div>
                <SelectInput
                  label='Consignatario (destino):'
                  // className="full"
                  value={parseInt(this.state.destination)}
                  name='destination'
                  items={this.state.destinyList}
                  onChange={this.handleChange}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                  hideCaret={(this.state.destinyList || []).length === 1}
                />
              </div>
              { this.getDetailInfo(this.getDetailInfoData(this.state.destination, false), this.state.details_info?.appointment_unload_date ?? '') }
              {/* <div>RFC: {'OCI140203980'}</div> */}
            </div>
          </div>
          {this.getNewDetailInfo()}
          <StepTitle 
            number={'07'} 
            title={'Complemento carta porte: mercancias'} 
            component={
              <div style={{marginLeft: 10, display: 'flex', alignItems: 'center'}}>
                <img
                  key={'edit'}
                  src="/images/edit_blue.svg"
                  style={{ cursor: 'pointer', height: '17px', width: '15px'}}
                  onClick={(e) => this.cancelEditProducts()}
                />
              </div>
            }/>
          <div className="section-subtitle">
            Total de tipos de mercancias: {`${(this.getUniqueProducts() ?? []).length}`}
          </div>
          <div>
            <div className="products-resume">
              <div className="resume-header">
                <div style={{width: "10%"}}>
                  Bienes
                </div>
                <div style={{width: "22%"}}>
                  Descripción
                </div>
                <div style={{width: "9%"}}>
                  Cantidad
                </div>
                {/* <div style={{width: "5%"}}>
                  Clave
                </div> */}
                <div style={{width: "5%"}}>
                  Unidad
                </div>
                <div style={{width: "5%"}}>
                  Peligro
                </div>
                <div style={{width: "5%"}}>
                  Clave
                </div>
                <div style={{width: "7%"}}>
                  Embalaje
                </div>
                <div style={{width: "10%"}}>
                  Peso Kg
                </div>
                <div style={{width: "10%"}}>
                  Valor
                </div>
                <div style={{width: "5%"}}>
                  Moneda
                </div>
                <div style={{width: "6%"}}>
                  Fracción arancelaria
                </div>
                <div style={{width: "6%"}}>
                  UUID
                </div>
              </div>

              {this.getProducts(this.getUniqueProducts())}
              
              <div className="resume-total">
                  <div style={{width: "17%"}}>
                    {
                      this.state.editProductMode &&
                      <Button
                        text={"Agregar producto"}
                        type="primary outline"
                        onMouseLeave={() => {}}
                        onClick={() => this.addNewProduct()}
                        disabled={!this.isAbleToAddNewProduct()}
                      />
                    }
                  </div>
                  <div style={{width: "14%"}}>
                    {
                      this.state.editProductMode &&
                      <Button
                        text={ "Cancelar"}
                        type="secondary"
                        onMouseLeave={() => {}}
                        onClick={() => this.cancelEditProducts()}
                        disabled={false}
                      />
                    }
                  </div>
                  <div style={{width: "14%"}}>
                    {
                      this.state.editProductMode &&
                      <>
                        <Button
                          text={ "Guardar"}
                          type="primary"
                          dataTip={this.getProductsErrors()}
                          dataFor="orbi-tooltip-products-error"
                          onMouseLeave={() => this.setState({ onFocusedProducts: true })}
                          onClick={() => {
                            this.setState({ showEditProducts: true })
                          }}
                          disabled={!this.isAbleToSaveAllProducts()}
                        />
                        <ReactTooltip
                          id={"orbi-tooltip-products-error"}
                          place="bottom"
                          type="light"
                          effect="solid"
                          html={true}
                          event="mouseover"
                          eventOff="mouseleave"
                        />
                      </>
                    }
                  </div>
                  {/* <div style={{width: "5%"}}>
                  </div> */}
                  <div style={{width: "6%"}}>
                  </div>
                  <div style={{width: "5%"}}>
                  TOTAL:
                  </div>
                  <div style={{width: "7%"}}>
                  </div>
                  <div style={{width: "10%"}}>
                  {this.totalWeight}
                  </div>
                  <div style={{width: "10%"}}>
                  {'$' + `${this.totalPrice}`}
                  </div>
                  <div style={{width: "5%"}}>
                  MXN
                  </div>
                  <div style={{width: "6%"}}>
                  </div>
                  <div style={{width: "6%"}}>
                  </div>
              </div>
            </div>
          
          </div>
          <StepTitle number={'08'} title={'Complemento carta porte: autotransporte federal'} />
          <div className="section-subtitle"></div>
          <div className="row">
            {
              (this.state.details_info?.assembly?.vehicle?.extra_data || []).length > 0 ? 
              <>
                <TextInput 
                  label='Permiso de la SCT*:'
                  type='text'
                  value={`${this.state.details_info?.assembly?.vehicle?.extra_data[0]?.descripcion ?? ''}`}
                  name='SCTPermit'
                  onChange={this.handleChange}
                  disabled={true}
                />
                <TextInput 
                  label='Número de permiso de la SCT*:'
                  type='text'
                  value={`${this.state.details_info?.assembly?.vehicle?.extra_data[0]?.number ?? ''}`}
                  name='SCTPermitNumber'
                  onChange={this.handleChange}
                  disabled={true}
                />
              </>
              : <div/>
            }
            <TextInput 
              label='Nombre de aseguradora*:'
              type='text'
              value={this.state.details_info?.assembly?.vehicle?.insurance?.insurance_company ?? ''}
              name='insuranceCompanyName'
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput 
              label='Número de póliza de seguro*:'
              type='text'
              value={this.state.details_info?.assembly?.vehicle?.insurance?.policy ?? ''}
              name='insuranceNumber'
              onChange={this.handleChange}
              disabled={true}
            />
          </div>
          <div className="row">
            <div className="svg-container">
              <Truck width={"61px"} height={"60px"}/>
            </div>
            <TextInput 
              label='Configuración vehicular*:'
              type='text'
              value={this.state.details_info?.vehicle_nomenclature ?? ''}
              name='vehicleConfiguration'
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput 
              label='Placa vehículo motor*:'
              type='text'
              value={this.state.details_info?.assembly?.vehicle?.plates ?? ''}
              name='vehiclePlates'
              onChange={this.handleChange}
              disabled={true}
            />
            <TextInput 
              label='Año vehículo*:'
              type='text'
              value={this.state.details_info?.assembly?.vehicle?.year ?? ''}
              name='vehicleYear'
              onChange={this.handleChange}
              disabled={true}
            />
          </div>

          {this.getEquipments()}

          {this.getOperators()}

          {this.getVehicleOwner(rfcEmisor?.data?.fiscal_number ?? '')}
        
          <div style={{
            display: 'flex',
            justifyContent: 'right',
            marginTop: 40
          }}>
            
            <Button
              text={this.state.loading ? <Loader /> : "Guardar"} 
              type="primary"
              dataTip={this.getErrors()}
              dataFor="orbi-tooltip-error"
              onMouseLeave={() => this.setState({ onFocused: true })}
              onClick={() => {
                const data = this.getData();
                const params = {
                  company_id: this.props.companyId,
                  tripid: this.props.match?.params?.tripId ?? 0,
                  request_type: this.isOrder() ? 'orders' : 'trips',
                  data,
                };

                this.setState({loading: true});

                this.props.saveTaxInfo({...params, ...data}, {
                  onSuccess: (r) => {
                    this.setState({loading: false, showSuccessMessage: true, showErrorMessage: false});
                  },
                  onError: (e) => {
                    this.setState({
                      loading: false, 
                      showSuccessMessage: false, 
                      showErrorMessage: true,
                      errorMsg: e.response?.data?.errors || ["Ocurrió un error con el registró del CFDI, verifica tu información"]
                    });
                  },
                })
              }}
              disabled={this.state.loading || this.evaluateForm()}
            />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

          </div>
        </div>

        <GenericDialog
          open={this.state.showSuccessMessage}
          title="Listo!"
          message="Se registró con éxito el CFDI"
          acceptAction={() => {
            this.props.history.goBack();
          }}
          closeAction={() => {
            this.props.history.goBack();
          }}
        />

        <GenericDialog
          open={this.state.showErrorMessage}
          title="Error!"
          // message="Ocurrió un error con el registró del CFDI, verifica tu información"
          message={this.state.errorMsg}
          acceptAction={() => {
            this.setState({loading: false, showSuccessMessage: false, showErrorMessage: false, errorMsg: []});
          }}
          closeAction={() => {
            this.setState({loading: false, showSuccessMessage: false, showErrorMessage: false, errorMsg: []});
          }}
        />

        <GenericDialog
          open={this.state.showSuccessEditProducts}
          title="Listo!"
          message="Se editó correctamente las mercancías"
          acceptAction={() => {
            this.setState({
              showSuccessEditProducts: false
            });
          }}
          closeAction={() => {
            this.setState({
              showSuccessEditProducts: false
            });
          }}
        />

        <ConfirmDialog
          open={this.state.showEditProducts}
          title="Modificación de mercancías"
          //message={this.getProductsModifications()}
          contentObject={this.getProductsModifications()}
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ showEditProducts: false })}
          acceptAction={() => {
            this.setState({ showEditProducts: false }, () => this.updateProducts());
          }}
        />

      </View>
    )
  }
}

const mapStateToProps = (state) => {

  const tipoFactura = getEntityItems(state, "COMPANIES.SAT.CFDI.TIPOFACTURA");
  const usoCfdi = getEntityItems(state, "COMPANIES.SAT.CFDI.USOCFDI");
  const monedas = getEntityItems(state, "COMPANIES.SAT.CFDI.MONEDAS");
  let formaDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.FORMADEPAGO");
  const metodoDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.METODODEPAGO");
  const claveProdServCp = getEntityItems(state, "COMPANIES.SAT.CFDI.CLAVEPRODSERVCPACOTADO");
  const claveUnidadPeso = getEntityItems(state, "COMPANIES.SAT.CFDI.CLAVEUNIDADPESOACOTADO");
  const impuesto = getEntityItems(state, "COMPANIES.SAT.CFDI.IMPUESTO");
  const tipoFactor = getEntityItems(state, "COMPANIES.SAT.CFDI.TIPOFACTOR");
  const tasaCuota = getEntityItems(state, "COMPANIES.SAT.CFDI.TASACUOTA");
  const productsToShow = getEntityItems(state, 'COMPANIES.SAT.TAXINFO.SEARCHPRODUCTS') || [];
  const isLoadingUpdateProducts = state?.api['COMPANIES.SAT.TAXINFO.UPDATEPRODUCTS']?.status?.isFetching || false;
  const unitsCatalog = getEntityItems(state, "COMPANIES.CATALOGS.UNITS") || [];

  formaDePago = formaDePago.sort((a, b) => a.clave.localeCompare(b.clave))

  let impuestoForDefault = null;

  for (var i = 0; i < (isArray(impuesto) ? impuesto : []).length; i++) {
    if (impuesto[i].descripcion === 'IVA') {
      impuestoForDefault = impuesto[i].id;
    }
  }

  let tipoFactorForDefault = null;

  for (var i = 0; i < (isArray(tipoFactor) ? tipoFactor : []).length; i++) {
    if (tipoFactor[i].descripcion === 'Tasa') {
      tipoFactorForDefault = tipoFactor[i].id;
    }
  }

  // let tipoFacturaValue = 0;

  // for (var i = 0; i < (isArray(tipoFactura) ? tipoFactura : []).length; i++) {
  //   if (tipoFactura[i].descripcion === 'Ingreso') {
  //     tipoFacturaValue = tipoFactura[i].id;
  //   }
  // }

  let unitKeyValue = 0;

  for (var i = 0; i < (isArray(claveUnidadPeso) ? claveUnidadPeso : []).length; i++) {
    unitKeyValue = claveUnidadPeso[i].id;
  }

  let productKeyDefault = 0;

  for (var i = 0; i < (isArray(claveProdServCp) ? claveProdServCp : []).length; i++) {
    if (`${claveProdServCp[i].clave}` === '78101802') {
      productKeyDefault = claveProdServCp[i].id;
    }
  }

  return{
    unitsCatalog,
    isLoadingUpdateProducts,
    productsToShow,
    transferTax: impuestoForDefault === null ? 0 : impuestoForDefault,
    retainTax: impuestoForDefault === null ? 0 : impuestoForDefault,
    transferRate: tipoFactorForDefault === null ? 0 : tipoFactorForDefault,
    retainRate: tipoFactorForDefault === null ? 0 : tipoFactorForDefault,
    companyId: state.globalCatalog.session.company.id,
    // tipoFacturaValue,
    unitKeyValue,
    productKeyDefault,
    tipoFactura: (isArray(tipoFactura) ? tipoFactura : []).filter(i => i.descripcion === "Ingreso" || i.descripcion === "Traslado").map(item => ({id: item.id, description: item.descripcion})),
    usoCfdi: (isArray(usoCfdi) ? usoCfdi : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    monedas: (isArray(monedas) ? monedas : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    formaDePago: (isArray(formaDePago) ? formaDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    metodoDePago: (isArray(metodoDePago) ? metodoDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    impuesto: (isArray(impuesto) ? impuesto : []).map(item => ({id: item.id, description: item.descripcion})),
    tipoFactor: (isArray(tipoFactor) ? tipoFactor : []).map(item => ({id: item.id, description: item.descripcion})),
    claveProdServCp: (isArray(claveProdServCp) ? claveProdServCp : []).map(item => ({id: item.id, description: item.clave, extra: item.descripcion})),
    claveUnidadPeso: (isArray(claveUnidadPeso) ? claveUnidadPeso : []).map(item => ({id: item.id, description: `${item.clave} ${item.nombre}`})),
    tasaCuota: (isArray(tasaCuota) ? tasaCuota : []).filter(item => (item.rango_o_fijo === 'Fijo' && item.impuesto === 'IVA' && parseFloat(item.maximo) !== 0)).map(item => ({id: item.id, description: item.maximo}))
  };

}

const mapDispatchToProps = (dispatch) => {
  return{
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    loadTipoFactura: (opt) => dispatch(readEntities("COMPANIES.SAT.CFDI.TIPOFACTURA", {}, {prefix: 'orbisat.', ...opt})),
    loadUsoCfdi: () => dispatch(readEntities("COMPANIES.SAT.CFDI.USOCFDI", {}, {prefix: 'orbisat.'})),
    loadMonedas: (opt) => dispatch(readEntities("COMPANIES.SAT.CFDI.MONEDAS", {}, {prefix: 'orbisat.', ...opt})),
    loadTasaCuota: (opt) => dispatch(readEntities("COMPANIES.SAT.CFDI.TASACUOTA", {}, {prefix: 'orbisat.', ...opt})),
    loadFormaDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.FORMADEPAGO", {}, {prefix: 'orbisat.'})),
    loadMetodoDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.METODODEPAGO", {}, {prefix: 'orbisat.'})),
    loadClaveProdServCp: () => dispatch(readEntities("COMPANIES.SAT.CFDI.CLAVEPRODSERVCPACOTADO", {}, {prefix: 'orbisat.'})),
    loadClaveUnidadPeso: () => dispatch(readEntities("COMPANIES.SAT.CFDI.CLAVEUNIDADPESOACOTADO", {}, {prefix: 'orbisat.'})),
    loadImpuesto: () => dispatch(readEntities("COMPANIES.SAT.CFDI.IMPUESTO", {}, {prefix: 'orbisat.'})),
    loadTipoFactor: () => dispatch(readEntities("COMPANIES.SAT.CFDI.TIPOFACTOR", {}, {prefix: 'orbisat.'})),
    getTaxInfo: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO", params, opt)),
    saveTaxInfo: (params, opt) => dispatch(createEntity("COMPANIES.SAT.TAXINFO.CREATE", params, opt)),
    searchProducts: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO.SEARCHPRODUCTS", params, opt)),
    updateProducts: (params, opt) => dispatch(createEntity("COMPANIES.SAT.TAXINFO.UPDATEPRODUCTS", params, opt)),
    loadUnitCatalog: (params, opt) => dispatch(readEntities("COMPANIES.CATALOGS.UNITS", params, opt))
  };  
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingFormView);