import React, { Component } from "react";
import { TitleLabel, UploadButton, UploadChipsContainer } from "./style";
import FileSelector, {
  getFileDescription,
  acceptTypes,
} from "../../../shared/controls/file-selector";
import Chip from "../../../shared/controls/chip";
import SVGIcon, { icons } from "../../../shared/svg-icon";
import { getUrlsEnv } from "../../../redux/api/api-urls";
import { openSecureDoc } from "../../../shared/backend-api";

class RouteCatalogDetailsPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openDocument = (url) => {
    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();
      });
    });
  };

  render() {
    const { permissions, editMode } = this.props;
    return (
      <React.Fragment>
        <TitleLabel>
          <label htmlFor="">Permisos de conectividad</label>
        </TitleLabel>
        <UploadChipsContainer>
          {permissions.files.map((item, key) => {
            return (
              <Chip
                key={key}
                id={key}
                active={true}
                className="white medium full"
                name="files"
                description={getFileDescription(item, 10)}
                onClick={() => {
                  if (item.id) {
                    this.openDocument(item.url);
                  }
                }}
                prefix={{
                  element: (
                    <SVGIcon
                      name={icons.imageFile}
                      fill="#657795"
                      viewBox="0 -1 16 16"
                    />
                  ),
                }}
                suffix={{
                  element: (
                    <div
                      onClick={() =>
                        permissions.removeFiles("permissions", item)
                      }
                    >
                      {" "}
                      <SVGIcon
                        name={icons.cross}
                        fill="#A7B6D6"
                        viewBox="0 3 16 16"
                      />
                    </div>
                  ),
                }}
              />
            );
          })}
        </UploadChipsContainer>
        {editMode && (
          <FileSelector
            name={"permissions"}
            acceptTypes={[acceptTypes.imagesAndPdf]}
            onChange={(e) => permissions.handleInput(e)}
            allowMultiple={false}
          >
            <UploadButton
              settings={{
                type: "primary small",
                text: "Subir archivo",
                //   disabled: true,
                prefix: {
                  element: (
                    <SVGIcon
                      name={icons.uploadFile}
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                    />
                  ),
                },
              }}
            />
          </FileSelector>
        )}
      </React.Fragment>
    );
  }
}

export default RouteCatalogDetailsPermissions;

RouteCatalogDetailsPermissions.defaultProps = {};
