import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Button from '../controls/button';
import ModalSelectInput from '../../control/shipment/modal-select-input';
import { partialEntity } from '../../redux/api';

class DeleteOrderTripModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      successAction: false,
      showError: false
    }

    this.isLoaded = false;

  }

  componentDidUpdate(nextProps) {

    if (!nextProps.open && this.isLoaded) {
      this.setState({
        successAction: false,
        showError: false
      });
    }

  }

  getSuccesContent = () => {
    return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{height: 20}}/>
      { 'Se eliminó correctamente' }
      <div style={{height: 40}}/>
      <Button
        text={'Aceptar'}
        type={'primary'}
        onClick={() => {
          this.isLoaded = true;
          this.props.onClose();
        }}
      />
      <div style={{height: 20}}/>
    </div>
  }

  getContent = () => {
    return(
      <>
        <div className='content-select'>
          { (this.props.orderToDelete === null ? 'Esta acción es irreversible y eliminará toda la información de este viaje' : 'Esta acción es irreversible y eliminará toda la información de esta orden') + ', ¿Estás seguro que deseas continuar?'}
        </div>
        <div className='content-buttons'>

          <Button
            text={'Cancelar'}
            type={'primary outline dark'}
            onClick={() => { this.isLoaded = true; this.props.onClose() }}
          />

          <Button
            disabled={this.props.loading}
            text={ this.props.loading ? 'Cargando...' : 'Eliminar'}
            type={'primary'}
            onClick={() => {

              if (this.props.orderToDelete !== null) {

                this.props.deleteOrder({
                  company_id: this.props.companyId,
                  id: this.props.orderToDelete
                }, {
                  onSuccess: () => { 
                    this.props.success();
                    this.setState({
                      successAction: true,
                      showError: false
                    });
                  },
                  onError: () => {
                    this.setState({
                      showError: true
                    });
                  }
                });

              } else if (this.props.tripToDelete !== null) {

                this.props.deleteTrip({
                  company_id: this.props.companyId,
                  id: this.props.tripToDelete
                }, {
                  onSuccess: () => { 
                    this.props.success();
                    this.setState({
                      successAction: true,
                      showError: false
                    });
                  },
                  onError: () => {
                    this.setState({
                      showError: true
                    });
                  }
                });

              }

            }}
          />
        </div>
      </>
    );
  }

  render() {

    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog change-user-type-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{ this.props.orderToDelete === null ? 'Eliminar viaje' : 'Eliminar orden'}</div>
        <div className="close" onClick={() => { this.isLoaded = true;   this.props.onClose()} }></div>

        {
          this.state.successAction ? this.getSuccesContent() : this.getContent()
        }

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {

  const loadingOrder = state.api["COMPANIES.ORDERS.DELETE"]?.status?.isFetching || false;
  const loadingTrip = state.api["COMPANIES.TRIPS.DELETE"]?.status?.isFetching || false;

  return {
    loading: (loadingOrder || loadingTrip),
    companyId: state.globalCatalog?.session?.company?.id || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteOrder: (params, opts) => dispatch(partialEntity("COMPANIES.ORDERS.DELETE", params, opts)),
    deleteTrip: (params, opts) => dispatch(partialEntity("COMPANIES.TRIPS.DELETE", params, opts))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps 
)(DeleteOrderTripModal);