import React, { Component } from "react";
import Tag from "../../../shared/controls/tag";
import { TitleLabel } from "./style";

class RouteCatalogDetailsTags extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { items, selectTagHandler } = this.props.tags;
    return (
      <React.Fragment>
        <TitleLabel>
          <label htmlFor="">
            Selecciona o añade las etiquetas para este producto:
          </label>
        </TitleLabel>
        {items.map((item) => {
          return (
            <Tag
              key={item.id}
              id={item.id}
              color={item.color}
              title={item.title}
              isFull={item.isFull}
              // parentBackground={this.props.background}
              onClick={selectTagHandler}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

export default RouteCatalogDetailsTags;

RouteCatalogDetailsTags.defaultProps = {};
