import React from "react";
const Volume = (props) => {
  return (
    <svg
      viewBox="0 0 11 15"
      width="20px"
      height="20px"
      fill="currentColor"
      {...props}
    >
      <path d="M5.44927191,0 C8.05027011,2.18878356 11.3007901,6.18863758 11.3007901,9.81122905 C11.1384258,16.8299343 0.328789184,16.6036068 0.00402830902,9.88690834 C-0.158335955,5.43424916 4.63688452,4.30186079 5.44927191,0.000587219961 L5.44927191,0 Z M9.0952397,10.1469802 C9.0952397,9.55884974 10.0451467,9.55884974 10.0451467,10.1469802 C10.0451467,12.1319478 8.30399701,13.9701572 6.1667377,14.1176471 C5.5336711,14.1176471 5.45430743,13.3088964 6.08737246,13.2351661 C7.74977275,13.0882761 9.09465538,11.6907659 9.09465538,10.1470972 L9.0952397,10.1469802 Z"></path>
    </svg>
  );
};
export default Volume;
