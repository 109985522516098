import React from "react";
const ReportIcon = (props) => {
  return (
    <svg
      width="70px"
      height="45px"
      viewBox="0 0 70 45"
      fill="#795548"
      {...props}
    >
      <polygon
        id="Shape"
        fillRule="nonzero"
        points="22.5287356 7 25.7531609 9.67166667 18.8818966 15.365 13.2497126 10.6983333 2.81609195 19.355 4.80143678 21 13.2497126 14 18.8818966 18.6666667 27.7525862 11.3283333 30.9770115 14 30.9770115 7"
      ></polygon>
    </svg>
  );
};

export default ReportIcon;
