import React from "react";
const Trash = (props) => {
  return (
    <svg
      viewBox="2 2 28 28"
      width="20px"
      height="20px"
      fill="currentColor"
      {...props}
    >
      <path
        d="  M25,10H7v17c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V10z"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
      />
      <path
        d="  M20,7h-8V5c0-1.105,0.895-2,2-2h4c1.105,0,2,0.895,2,2V7z"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
      />
      <path
        d="  M28,10H4V8c0-0.552,0.448-1,1-1h22c0.552,0,1,0.448,1,1V10z"
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
      />
      <line
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
        x1="16"
        x2="16"
        y1="15"
        y2="24"
      />
      <line
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
        x1="12"
        x2="12"
        y1="15"
        y2="24"
      />
      <line
        // stroke-linecap="round"
        // stroke-linejoin="round"
        // stroke-miterlimit="10"
        // stroke-width="2"
        x1="20"
        x2="20"
        y1="15"
        y2="24"
      />
    </svg>
  );
};
export default Trash;
