import React from "react";
import PropTypes from "prop-types";

class RadioGroup extends React.Component {
  render() {
    return this.props.items.map((item) => {
      return (
        <React.Fragment>
          <label
            class={`radio-group${
              this.props.labelClass ? " " + this.props.labelClass : ""
            }`}
          >
            <input
              type="radio"
              name={this.props.radioName}
              value={item.value}
              onClick={(e) => this.props.onClickHandler(e)}
              checked={
                String(this.props.defaultValue) === String(item.value)
                  ? true
                  : false
              }
            />
            {item.label}
          </label>
        </React.Fragment>
      );
    });
  }
}

RadioGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  radioName: PropTypes.string,
  defaultValue: PropTypes.string,
  labelClass: PropTypes.string,
  onClickHandler: PropTypes.func,
};

RadioGroup.defaultProps = {
  radioName: "radiogroup",
  labelClass: "",
};

export default RadioGroup;
