import React from "react";
const ArrowLeft = (props) => {
  return (
    <svg
      width="69px"
      height="53px"
      viewBox="0 0 69 53"
      fill="currentColor"
      {...props}
    >
      <path
        d="M47.6470091,25.5577364 L40.086575,16.5731639 C39.460031,15.8877467 38.4580575,15.8216837 37.7998677,16.3501985 C37.1416779,16.8787133 37.0319976,17.943956 37.5720394,18.588097 L42.9555005,24.9798276 L22.620093,24.9798276 C21.7256937,24.9798276 21,25.6900316 21,26.5653404 C21,27.4406492 21.7256937,28.1508532 22.620093,28.1508532 L42.9555005,28.1508532 L37.5720394,34.5425838 C37.0320084,35.1867248 37.1543579,36.237497 37.7998677,36.7804823 C38.466482,37.3399569 39.5486501,37.2016368 40.086575,36.5575169 L47.6470091,27.5729443 C48.1385237,26.8958247 48.0963311,26.2124687 47.6470091,25.5580218 L47.6470091,25.5577364 Z"
        id="Fill-1-Copy"
        // fill="#AFC8FF"
      ></path>
    </svg>
  );
};

export default ArrowLeft;
