import React from 'react';
import { connect } from 'react-redux';
import Panel from '../shared/component/panel';
import SelectImage from '../shared/controls/select-image';
import InputProfileImage from '../shared/controls/input-profile-image';
import TextInput from '../shared/controls/text-input';
import SelectInput from '../shared/controls/select-input';
import {
  loadData,
  RECEIVE_COUNTRIES_,
  RECEIVE_STATES_,
  RECEIVE_CITIES_,
  RECEIVE_TYPES,
  postRegisterCompany,
  RECEIVE_REGISTER_COMPANY,
  ERROR_REGISTER_COMPANY,
  postValidateOrbiId,
  VALIDATE_ORBI_ID_ERROR,
  VALIDATE_ORBI_ID_RESPONSE
} from '../redux/reducers/register-company.reducer';
import Button from '../shared/controls/button';
import ErrorDialog from '../shared/dialog/error-dialog';
import ReactTooltip from 'react-tooltip';
import { loadSession, setSessionCompany, setSessionUser, refreshSession, setSessionCompanyList } from '../redux/reducers/global-catalog.reducer';
import { withRouter } from 'react-router-dom';
import { validators, validateWebPage } from '../shared/validate';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ConfirmDialog from '../shared/dialog/confirm-dialog';
import { createEntity, getEntityItems, partialEntity, readEntities, readEntity } from '../redux/api';

// FIX: Usar los datos de sesion y domicilios del reducer global reducer
// TODO: esta vista no recibe props
// TODO: Centralizar los mensajes de error de la aplicacion en un archivo

let typingTimer;

class RegisterCompanyView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      typeAccount: props.typeAccount || null,
      companyName: props.companyName || '',
      idOrbi: props.idOrbi || '',
      webPage: props.webPage || '',
      postalCode: '',
      postalCodeId: 0,
      phone:'',
      address:'',
      country: 0,
      state: 0,
      city: 0,
      locality: 0,
      extNumber: '',
      intNumber: '',
      neighborhood: 0,
      profileImage: null,
      showError: false,
      onFocused: false,

      crop: {
        unit: '%',
        width: 300,
        aspect: 1 / 1,
      },
      editImage: false,
      imageToCrop: null,

      
      countryOptions: [],
      neighborhoodOptions: [],
      stateOptions: [],
      cityOptions: [],
      localityOptions: [],
    }

  }

  componentDidMount() {
    // this.props.getCountries()
    // this.props.getStates()
    // this.props.getCities()
    this.props.loadCountries();
    this.props.getTypes();
  }

  isTypeSelected = typeId => {
    const { typeAccount } = this.state;
    return typeAccount && typeAccount === typeId;
  }

  handleClickAccountType = typeAccount => this.setState({ typeAccount });

  handlePicture = () => {
    this.refs.fileUploader.value = '';
    this.refs.fileUploader.click();
  }

  handleAddress = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    if (property === "idOrbi") {
      value = String(value).replace(/\s/g, '_')
      this.setState({ [property]: value })
    } /* else if (property === "country") {
      this.props.getStates(value);
      this.setState({ state: 0, city: 0 });
    } else if (property === "state") {
      this.props.getCities(value);
      this.setState({ city: 0 })
    } */
    else if(property === "postalCode"){
      this.setState({[property]: value}, () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(this.getZipCodeInfo, 1000);
      })
    } else if(property === "country"){
      this.setState({[property]: value}, () => {
        if(this.state.postalCode) this.getZipCodeInfo();
      })
    } else {
      this.setState({ [property]: value })
    }

    // if (property === "country") {
    //   if (this.props.states.find(a => a.country === Number(value)) !== undefined) {
    //     const firstState = this.props.states.find(a => a.country === Number(value)).id
    //     this.setState({ state: firstState })

    //     if (this.props.cities.find(a => a.state === Number(firstState)) !== undefined) {
    //       const firstCity = this.props.cities.find(a => a.state === Number(firstState)).id
    //       this.setState({ city: firstCity })
    //     } else {
    //       this.setState({ city: -1 })
    //     }
    //   } else {
    //     this.setState({ state: -1 })
    //     this.setState({ city: -1 })
    //   }
    // } else if (property === "state") {
    //   if (this.props.cities.find(a => a.state === Number(this.state.state)) !== undefined) {
    //     const firstCity = this.props.cities.find(a => a.state === Number(this.state.state)).id
    //     this.setState({ city: firstCity })
    //   } else {
    //     this.setState({ city: -1 })
    //   }
    // }
  }

  getZipCodeInfo = () => {
    this.props.checkZipCode({
      cp: this.state.postalCode,
      country: this.state.country,
    }, {
      prefix: 'orbisat.',
      onSuccess: (result) => {
        if(result.length){
          // let countryOptions = [{id: result[0].pais_id, description: result[0].pais}];
          let neighborhoodOptions = result.map(r => ({id: r.colonia_id, description: r.colonia}))
          let stateOptions = [{id: result[0].estado_id, description: result[0].estado}];
          let cityOptions = [{id: result[0].municipio_id, description: result[0].municipio}];
          let localityOptions = [{id: result[0].localidad_id, description: result[0].localidad}];
          this.setState({
            // countryOptions,
            neighborhoodOptions,
            stateOptions,
            cityOptions,
            localityOptions,

            // country: result[0].pais_id,
            neighborhood: result.length > 1 ? 0 : result[0].colonia_id,
            state: result[0].estado_id,
            city: result[0].municipio_id,
            locality: result[0].localidad_id,
            postalCodeId: result[0].cp_id,
          })
        } else {
          this.setState({
            // countryOptions: [],
            neighborhoodOptions: [],
            stateOptions: [],
            cityOptions: [],
            localityOptions: [],
            // country: 0,
            neighborhood: 0,
            state: 0,
            city: 0,
            locality: 0,
            postalCodeId: 0
          })
        }
      }
    })
  }

  validateCompanyName = (e) => {
    this.props.validateCompanyName({
      corporation_name: e.target.value
    }, {
      onSuccess: () => {
      }, 
      onError: () => {
        this.setState({ showError: true, msgError: `${this.state.companyName} ya existe` })
      }
    })
  }

  handleValidate = event => {
    this.props.postValidateOrbiId(event.target.value)
      .then(
        response => {
          switch (response.type) {
            case VALIDATE_ORBI_ID_RESPONSE:
              console.log("");
              break
            case VALIDATE_ORBI_ID_ERROR:
              if (response.error.error) {
                this.setState({ showError: true, msgError: `${this.state.idOrbi} ya existe` })
              } else {
                this.setState({ showError: true, msgError: "Comprueba los datos" });
              }
              break
            default:
              this.setState({ showError: true, msgError: "Falló la conexión" })
              break
          }
        }
      )
  }

  handleCreate = () => {
    const { address, city, locality, companyName, country, idOrbi, phone, state, typeAccount, webPage, postalCode, extNumber, intNumber, neighborhood, postalCodeId, profileImage } = this.state
    this.props.createCompany({
      orbi_id: idOrbi,
      corporation_name: companyName,
      type: typeAccount,
      data: {
        street: address,
        suburb: neighborhood,
        state: state,
        country: country,
        exterior_number: extNumber,
        interior_number: intNumber || null,
        city: city,
        locality: locality || null,
        zip_code: postalCodeId 
      },
      phone: phone || null,
      web_page: (webPage && webPage !== "") ? (webPage.includes("https://") || webPage.includes("http://")) ? webPage : "https://" + webPage : ""
    }, {
      onSuccess: (r) => {
        if(profileImage){
          this.props.updateCompany({
            company_id: r.id,
            logo: profileImage
          }, {
            isForm: true,
            onSuccess: () => {
              this.props.refreshSession(this.props.refreshToken).then(result => {
                if(result){
                  if(result.company){
                    if(result.company.length === 1 ){
                      result.company = result.company[0];
                      this.props.setSessionCompany(result.company);
                      this.props.setSessionUser(result.user);
                      this.props.setSessionCompaniesList([]);
                      this.props.history.push('/configurations');
                    } else if(result.company.length > 1){
                      result.listCompanies = result.company;
                      result.company = {
                        id: 0
                      };
                      
                      this.props.setSessionCompany(result.company);
                      this.props.setSessionUser(result.user);
                      this.props.setSessionCompaniesList(result.listCompanies);
                      this.props.history.push('/company/select');
                    } else {
                      result.company = {
                        id: 0
                      };
                      this.props.setSessionCompany(result.company);
                      this.props.setSessionUser(result.user);
                      this.props.setSessionCompaniesList([]);
                      this.props.history.push('/configurations');
                    }
                  }
                }
              })
            },
            onError: () => {
              this.setState({ showError: true, msgError: "Ocurrió un error al guardar la compañía" });
            }
          })
        }
        else {
          this.props.refreshSession(this.props.refreshToken).then(result => {
            if(result){
              if(result.company){
                if(result.company.length === 1 ){
                  result.company = result.company[0];
                  this.props.setSessionCompany(result.company);
                  this.props.setSessionUser(result.user);
                  this.props.setSessionCompaniesList([]);
                  this.props.history.push('/configurations');
                } else if(result.company.length > 1){
                  result.listCompanies = result.company;
                  result.company = {
                    id: 0
                  };
                  
                  this.props.setSessionCompany(result.company);
                  this.props.setSessionUser(result.user);
                  this.props.setSessionCompaniesList(result.listCompanies);
                  this.props.history.push('/company/select');
                } else {
                  result.company = {
                    id: 0
                  };
                  this.props.setSessionCompany(result.company);
                  this.props.setSessionUser(result.user);
                  this.props.setSessionCompaniesList([]);
                  this.props.history.push('/configurations');
                }
              }
            }
          })
        }
      },
      onError: () => {
        this.setState({ showError: true, msgError: "Ocurrió un error al guardar la compañía" });
      }
    })
    // this.props.registerCompany(address, city, companyName, country, idOrbi, phone, state, typeAccount, (webPage && webPage !== "") ? webPage.includes("https://") ? webPage : "https://" + webPage : "", postalCode, profileImage)
    //   .then(
    //     response => {
    //       switch (response.type) {
    //         case RECEIVE_REGISTER_COMPANY:
    //           this.props.refreshSession(this.props.refreshToken).then(result => {
    //             if(result){
    //               if(result.company){
    //                 if(result.company.length === 1 ){
    //                   result.company = result.company[0];
    //                   this.props.setSessionCompany(result.company);
    //                   this.props.setSessionUser(result.user);
    //                   this.props.setSessionCompaniesList([]);
    //                   this.props.history.push('/home');
    //                 } else if(result.company.length > 1){
    //                   result.listCompanies = result.company;
    //                   result.company = {
    //                     id: 0
    //                   };
                      
    //                   this.props.setSessionCompany(result.company);
    //                   this.props.setSessionUser(result.user);
    //                   this.props.setSessionCompaniesList(result.listCompanies);
    //                   this.props.history.push('/company/select');
    //                 } else {
    //                   result.company = {
    //                     id: 0
    //                   };
    //                   this.props.setSessionCompany(result.company);
    //                   this.props.setSessionUser(result.user);
    //                   this.props.setSessionCompaniesList([]);
    //                   this.props.history.push('/home');
    //                 }
    //               }
    //             }
    //           })
    //           break
    //         case ERROR_REGISTER_COMPANY:
    //           if (Array.isArray(response.error.orbi_id) && response.error.orbi_id.length > 0) {
    //             const res = response.error.orbi_id[0];
    //             if(String(res).includes("slug")){
    //               this.setState({ showError: true, msgError: "El ID Orbinetwork solo debe contener letras, números y/o guiones" })
    //             } else {
    //               this.setState({ showError: true, msgError: res })
    //             }
    //           } else {
    //             this.setState({ showError: true, msgError: "Comprueba los datos" });
    //           }
    //           break
    //         default:
    //           this.setState({ showError: true, msgError: "Falló la conexión" })
    //           break
    //       }
    //     }
    //   )
  }

  actionCloseDialog = () => {
    this.setState({
      openDialog: false,
      showError: false
    });
  };

  dragAndDrop = e => {

    e.preventDefault()
    e.stopPropagation()
    let dt = e.dataTransfer
    let files = dt.files

    let pictureFrame = document.getElementsByClassName("pictureFrameFac")
    let pictureButton = document.getElementsByClassName("btnPicture")

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)"

        pictureButton[0].style.color = "#3D77F7"
        pictureButton[0].style.border = "2px solid #ffffff"
        pictureButton[0].style.backgroundColor = "#ffffff"
        break
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)"

        pictureButton[0].style.color = "rgb(232, 240, 255)"
        pictureButton[0].style.border = "2px solid white"
        pictureButton[0].style.background = "none"

        var reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true
          });
        })
        reader.readAsDataURL(files[0]);
        break;
    }
  }

  isValidForm = () => {
    const { companyName, typeAccount, idOrbi, webPage, postalCode, address, country, state, city } = this.state;

    // if (webPage === null || webPage === '') {
      return (
        companyName !== '' 
        && companyName.length <= 45 
        && typeAccount !== null 
        && idOrbi !== '' 
        && postalCode !== '' 
        && address !== ''
        && country !== 0 
        && state !== 0 
        && city !== 0
        && this.state.postalCodeId
        && this.state.extNumber
        && this.state.neighborhood
        && (!webPage || validateWebPage(webPage))
      );
    // } else {
    //   return (
    //     companyName !== '' 
    //     && companyName.length <= 45 
    //     && typeAccount !== null 
    //     && idOrbi !== '' 
    //     && validateWebPage(webPage) 
    //     && postalCode !== '' 
    //     && address !== '' 
    //     && country !== '' 
    //     && state !== 0 
    //     && city !== 0
    //   );
    // }

  }

  handleLeave = () => {
    this.setState({ onFocused: true })
  }

  getErrors = () => {
    let errors = ''

    if (this.state.companyName === '' ||
      this.state.idOrbi === '' ||
      this.state.postalCode === '' ||
      this.state.country === 0 ||
      this.state.address === '' ||
      this.state.extNumber === '' ||
      this.state.neighborhood === 0 ||
      this.state.typeAccount === null|| 
      (this.state.webPage !== null && this.state.webPage !== '' && !validateWebPage(this.state.webPage))) {
      errors = 'Por favor completa los siguientes campos:<br/>'
    }

    errors += this.state.companyName === '' ? `<b>Nombre de la empresa<b/><br/>` : ''
    errors += this.state.idOrbi === '' ? `<b>ID Orbinetwork<b/><br/>` : ''
    errors += this.state.country === 0 ? `<b>País<b/><br/>` : ''
    errors += this.state.postalCode === '' ? `<b>Codigo Postal<b/><br/>` : ''
    errors += this.state.address === '' ? `<b>Domicilio<b/><br/>` : ''
    errors += this.state.extNumber === '' ? `<b>Num Ext<b/><br/>` : ''
    errors += this.state.neighborhood === 0 ? `<b>Colonia<b/><br/>` : ''
    errors += this.state.typeAccount === null ? `<b>Tipo de empresa/tipo de cuenta<b/><br/>` : ''

    if(this.state.webPage !== null && this.state.webPage !== '' && !validateWebPage(this.state.webPage)){
      errors += `<b>"${this.state.webPage}"<b/> no es una página web válida<br/>`
    }

    if(this.state.companyName.length > 45){
      errors += `<b>El nombre de tu empresa es mayor a 45 caracteres<br/>`
    }

    return errors
  }

  render() {

    // const stateList = this.props.states.filter(a => a.country === Number(this.state.country))
    // const cityList = this.props.cities.filter(a => a.state === Number(this.state.state))
    return (

      <div className='register'>

        <input accept="image/*" type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={event => {

          event.stopPropagation();
          event.preventDefault();
          var reader = new FileReader();
          reader.addEventListener('load', () => {
            this.setState({
              imageToCrop: reader.result,
              editImage: true
            });
          })
          reader.readAsDataURL(event.target.files[0]);

        }} />

        <img alt="" className='logo' src={'/images/logo-white.svg'} />

        <h1 className='title'>Registra tu empresa</h1>
        <h1 className='subtitle'>Y crea un ID Orbi</h1>

        <Panel title='Datos de tu empresa' number='01' className='panel'>

          <div className='form'>
            <div className='data'>

              <TextInput
                type='text'
                label='*Nombre de la empresa (Razón social)'
                name='companyName'
                dataTip="<ul><li>El nombre de tu empresa no debe exceder de <b>45 caracteres</b></li></ul>"
                dataFor="orbi-tooltip-company-name"
                className='full'
                value={this.state.companyName}
                onChange={this.handleAddress}
                validate={[validators.required, 'maxLength']}
                validationParam={45}
                onFocused={this.state.onFocused}
                handleBlur={this.validateCompanyName}
              />

              <ReactTooltip
                id={"orbi-tooltip-company-name"}
                place="right"
                type="light"
                effect="solid"
                html={true}
                event="focusin"
                eventOff="focusout"
              />

              <TextInput
                type='text'
                label='*ID Orbinetwork'
                name='idOrbi'
                dataTip="<ul><li>Tu Id Orbi debe ser una sola palabra, Si usas más de una palabra, los espacios se mostrarán como un guion bajo (ej. Mi_Empresa)</li><li>Asegúrate de no dejar espacios al final (ej. Mi_Empresa_)</li></ul>"
                dataFor="orbi-tooltip"
                value={this.state.idOrbi}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
                handleBlur={this.handleValidate}
              />

              <ReactTooltip
                id={"orbi-tooltip"}
                place="right"
                type="light"
                effect="solid"
                // style={{maxWidth: "380px", fontSize: "16px"}}
                html={true}
                event="focusin"
                eventOff="focusout"
              />

              <TextInput
                type='text'
                label='Página web de tu empresa (opcional)'
                name='webPage'
                className='full'
                value={this.state.webPage}
                onChange={this.handleAddress}
              />

              <SelectInput
                label='*País de origen (selecciona una opción)'
                className="full"
                name="country"
                items={this.props.countries || []}
                // items={this.state.countryOptions}
                value={this.state.country}
                onChange={this.handleAddress}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />

              <div className='formDivided'>
                <TextInput
                  type='number'
                  label='*Código Postal'
                  name='postalCode'
                  className='full'
                  value={this.state.postalCode}
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                  disabled={!this.state.country}
                />
                <TextInput
                  type='number'
                  label='Teléfono (opcional)'
                  name='phone'
                  className='full'
                  value={this.state.phone}
                  onChange={this.handleAddress}
                />

              </div>

              <div className='formDivided'>

                <SelectInput
                  label='*Selecciona estado'
                  className="full"
                  name="state"
                  // items={stateList}
                  items={this.state.stateOptions}
                  value={this.state.state}
                  onChange={this.handleAddress}
                  disabled={!this.state.postalCode}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />

                <SelectInput
                  label='*Selecciona Ciudad'
                  className="full"
                  name="city"
                  // items={cityList}
                  items={this.state.cityOptions}
                  value={this.state.city}
                  onChange={this.handleAddress}
                  disabled={!this.state.postalCode}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />

              </div>

              <div className='formDivided'>
                {
                  this.state.locality 
                  ? <SelectInput
                    label='Localidad:'
                    // className="full"
                    value={this.state.locality}
                    name='locality'
                    items={this.state.localityOptions}
                    onChange={this.handleAddress}
                    disabled={!this.state.postalCode}
                  />
                  : ''
                }
                <TextInput
                  type='text'
                  label='*Domicilio'
                  name='address'
                  className='full'
                  value={this.state.address}
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
              </div>


              <div className='formDivided'>   
                <TextInput 
                  label='*Num Ext:'
                  type='text'
                  value={this.state.extNumber}
                  name='extNumber'
                  onChange={this.handleAddress}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                <TextInput 
                  label='Num Int:'
                  type='text'
                  value={this.state.intNumber}
                  name='intNumber'
                  onChange={this.handleAddress}
                />   
              </div>

              
              <SelectInput
                label='Colonia:'
                // className="full"
                value={this.state.neighborhood}
                name='neighborhood'
                items={this.state.neighborhoodOptions}
                onChange={this.handleAddress}
                disabled={!this.state.postalCode}
                validate={[validators.required]}
                onFocused={this.state.onFocused}
              />

              <div className='extraPadding' />

            </div>

            <InputProfileImage
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              handleSave={this.handleSave}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar el logo de tu empresa, o bien, arrástralo desde tu computadora:"
              isFactory={true}
            />

          </div>

        </Panel>

        <Panel title='Selecciona tu tipo de empresa/tipo de cuenta' number='02' >

          <div className='companies'>
            {
              this.props.types.filter( (c) => {
                const name = c?.description || '';
                return name.toLowerCase().includes('dueño de carga') || name.toLowerCase().includes('transportista')
              }).map((c, index) => (

                <SelectImage
                  data={{
                    img: c.avatar_image,
                    name: c.description,
                    id: c.id
                  }}
                  key={index}
                  isSelected={this.isTypeSelected(c.id)}
                  handleClick={id => this.handleClickAccountType(id)}
                />

              ))
            }

          </div>

        </Panel>

        <div className="contentButton">

          <Button
            text={'Seguir'}
            type={'primary'}
            disabled={!this.isValidForm()}
            onClick={this.handleCreate}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="left"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

        </div>

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={'Edita tu imagen'}
          message={'Edita tu imagen'}
          acceptText={'Guardar'}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin='anonymous'
              style={{ maxHeight: '340px', overflow: 'auto', margin: '10px' }}
            />
          }
        />

      </div>

    );

  }


  closeImageEditModal = () => {
    this.setState({ editImage: false })
  }
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  }
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  }
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], 'image.jpeg');
        resolve(f);
      }, 'image/jpeg');
    });
  }

}

const mapStateToProps = state => {
  const {
    registerCompany:
    {
      // countries = [],
      // states = [],
      // cities = [],
      types = [],
      isFetching,
      error
    }
  } = state;

  const countries = getEntityItems(state, "COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY")
    .map(c => ({id: c.id, description: c.descripcion}))
    .sort((a, b) => {if(a.description > b.description) return 1; if(a.description < b.description) return -1; return 0});
  return { 
    types, 
    countries, 
    // states, 
    // cities, 
    isFetching, 
    error,
    refreshToken: state.globalCatalog.session && state.globalCatalog.session.refresh
  };
}

const mapDispatchToProps = dispatch => {
  dispatch(loadSession());

  return {
    loadCountries: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.CATALOGS.COUNTRY", {}, {prefix: 'orbisat.'}) 
      );
    },
    checkZipCode: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS.ZIPCODECHECKBYCOUNTRY", params, opt) 
      );
    },
    createCompany: (params, opt) =>{
      dispatch(
        createEntity("COMPANIES.CREATE", params, opt) 
      );
    },
    updateCompany: (params, opt) =>{
      dispatch(
        partialEntity("COMPANIES.UPDATE", params, opt) 
      );
    },
    validateCompanyName: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.CREATE.VALIDATIONS.COMPANYNAME", params, opt) 
      );
    },
    refreshSession: (token) => dispatch(refreshSession(token)),
    getCountries: () => dispatch(loadData(RECEIVE_COUNTRIES_, '')),
    getStates: (country) => dispatch(loadData(RECEIVE_STATES_, country)),
    getCities: (state) => dispatch(loadData(RECEIVE_CITIES_, state)),
    getTypes: () => dispatch(loadData(RECEIVE_TYPES, '')),
    registerCompany: (address, city, corporation_name, country, orbi_id, phone, state, type_company, website, zipcode, profile_image) => dispatch(postRegisterCompany({ address, city, corporation_name, country, orbi_id, phone, state, type_company, website, zipcode, profile_image })),
    postValidateOrbiId: (orbi_id) => dispatch(postValidateOrbiId(orbi_id)),
    setSessionCompany: (company) => dispatch(setSessionCompany(company)),
    setSessionUser: () => dispatch(setSessionUser({ welcome: true })),
    setSessionCompaniesList: (companiesList) => dispatch(setSessionCompanyList(companiesList))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterCompanyView))