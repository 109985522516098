//@flow
import * as React from "react";
import styled from "styled-components";

type Props = {
  children: any[],
  title: string,
};
const GroupContainer = ({ children, title }: Props) => {
  return (
    <GroupContainerStyled>
      <Title>{title}</Title>
      {children}
    </GroupContainerStyled>
  );
};

export default GroupContainer;

const GroupContainerStyled = styled.div`
  width: fit-content;
  background-color: rgb(55, 67, 93);
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.4);
  margin: 10px;
`;
