import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Panel extends Component {
  render() {
    return (
      <div className={'panel '.concat(this.props.className || '')}>
        <div className='title'>
          <div className='number'>
            {this.props.number || ''}
            <div className='border'></div>
          </div>
          {this.props.title || ''}
        </div>
        <div className='content'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export const SubPanel = (props) => {
  return (
    <React.Fragment>
      <div className={'panel sub-panel '.concat(props.className || '')}>
        <div className='title'>
          <div className='number'>
            {props.number || ''}
            <div className='border'></div>
          </div>
          {props.title || ''}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Panel;


Panel.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  className: PropTypes.string
};

