import React from 'react';
import PropTypes from 'prop-types';

class CollapsableContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: props.startsCollapsed
        }
    }
    toggleCollapse = () => {
        this.setState({collapsed: !this.state.collapsed})
    }
    render() {
        return(
            <div className="collapser">
                <div className="collapse-button"><span onClick={this.toggleCollapse}>{this.state.collapsed ? 'Mostrar' : 'Ocultar'}</span></div>
                <div className={"collapse-content".concat(this.state.collapsed ? ' collapsed' : '')}>
                    {this.props.children}
                </div>
            </div>
        )
    }

    static propTypes = {
        startsCollapsed: PropTypes.bool
    }
}

export default CollapsableContainer;

CollapsableContainer.defaultProps = {
    startsCollapsed: true
}