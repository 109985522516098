// @flow
import React, { Component } from "react";
import { connect } from "react-redux";

import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import Button from "../../shared/controls/button";
import { View, ViewTitle } from "../../shared/styled/view";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { dateAddDays, /* normalizeStr, */ dateAddHours } from "../../shared/utils";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import Modal from "react-modal";

import {
  GridConfig,
  ListAssembliesQueryParams,
  // AssembleResourceLog,
} from "./types";
import { getAssembles, VEHICLE_TYPE_ICON_SELECTOR } from "./assemble-utils";
import { AssembleGrid } from "./assemble-grid";
import AssembleSummary from "./assemble-summary";
import CreateAssemble from "./create-assemble";
import AssembleSubstituteResource from "./assemble-edit-modal/assemble-substitute-resource";

import type { Assemble, Resource, Timeline } from "./types";

import { CatalogList } from "../../shared/types";
import AssembleAddResource from "./assemble-edit-modal/assemble-add-resource";
import { License } from "../../shared/icons/assemble";
import * as SharedTypes from "../../shared/types/";
import { VerticalLineTooltipStyled } from "./assemble-edit-modal/styled";

import {
  getEntityItems,
  getEntityArgs,
  // SaveItemCounter,
  FILES_SERVER,
  getEntityCount,
} from "../../redux/api";
import { AssembleViewApi } from "./assemble-view-api";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
import AssembleLinkAssemble from "./assemble-edit-modal/assemble-link-assemble";
import Loader from "../../shared/icons/tat/loader";
import HelperFilesModal from '../../shared/component/helper_files_modal';

type DispatchToProps = {
  deleteAssembleEquipmentLog: Function,
  deleteAssembleOperatorLog: Function,
  deleteAssembleVehicleLog: Function,
  listCompanyFleets: Function,
  // listFleetOperators: Function,
  loadCompanyTags: Function,
  listAssemblies: Function,
  listFleetResourceCatalog: Function,
  listFleetResourceCounters: Function,
  updateAssembleVehicleLog: Function,
  updateAssembleOperatorLog: Function,
  updateAssembleEquipmentLog: Function,
  // listFleetData: Function,
  createAssembleEquipment: Function,
  createAssembleOperator: Function,
  createAssembleVehicle: Function,
  getUserTags: Function,
  cancelRequest: Function,
  loadMotives: (
    params: { company_id: number, type: 1 | 2 | 3 },
    opts: ApiOptions
  ) => void,
};

type StateToProps = {
  fleetVehicleTypesClasses: Array<any>,
  operatorLicenseTypes: Array<any>,
  companyFleets: any,
  companyId: number,
  userId: number,
  // fleetDatas: any,
  unasembledVehicles: CatalogList,
  unasembledEquipments: CatalogList,
  unasembledOperators: CatalogList,
  companyTags: Array<any>,

  fleetAssemblies: Array<any>,
  userTags: any,
};

type OwnProps = {||};

type Props = {
  ...DispatchToProps,
  ...StateToProps,
  ...OwnProps,
};

type State = {
  +textSearch: string,
  +filterParams: ListAssembliesQueryParams,

  // modales
  +showCreateModal: boolean,
  +showDisassembleModal: boolean,
  +showRemoveModal: boolean,
  +showSubstituteModal: boolean,
  // +modalSearchText: string,
  +currentAssembleId: number,
  +currentTimeline: Timeline | {},
  +showAddModal: boolean,
  +addType: number,

  // resumen
  // +vehicleData: AssembleResourceLog,
  // +equipmentsData: Array<AssembleResourceLog>,
  // +operators: Array<AssembleResourceLog>,

  +step: number,
  +selectedDate: Date,
  +gridConfig: GridConfig,
};

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
const hour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours()
);
const defaultGridConfig: GridConfig = {
  columnQuantity: 8,
  columnRangeType: "DAY",
  columnSections: 4,
  startDatetime: today,
  className: "",
  extended: true,
};

let typingTimer;

class AssembleView extends Component<Props, State> {
  state = {
    loading: false,
    textSearch: "",
    currentTimeline: {},
    addType: 0,
    addingDolly: false,
    showCreateModal: false,
    showDisassembleModal: false,
    showRemoveModal: false,
    showAddModal: false,
    showLinkModal: false,
    showSubstituteModal: false,
    currentAssembleId: 0,
    // modalSearchText: "",
    openXlsErrorModal: false,
    descriptionImport: [],
    equipmentsData: [],
    selectedDate: new Date(),
    vehicleData: undefined,
    operators: [],
    step: 1,
    gridConfig: defaultGridConfig,
    modalOffset: 1,
    actionPermissions: {},
    filterParams: {
      limit: 30,
      offset: 1,
      view: "shortdetail",
      operator_status: [],
      operator_license_type: [],
      equipment_status: [],
      equipment_class: [],
      equipment_type: [],
      vehicle_status: [],
      vehicle_class: [],
      vehicle_type: [],
      vehicle_tags: [],
      equipment_tags: [],
      operator_tags: [],
      start_date: today.toISOString(),
      end_date: dateAddDays(
        new Date(today),
        defaultGridConfig.columnQuantity
      ).toISOString(),
      status: "",
      get_trips: "",
      from_to_companies: [],
      from_facilities: [],
      to_facilities: [],
      from_to_facilities: [],
      vehicle: [],
      previous_assemblies: []
    },
  };

  componentDidMount() {
    const {
      companyId,
      userId,
      listCompanyFleets,
      loadCompanyTags,
      getUserTags,
      loadMotives,
    } = this.props;
    listCompanyFleets(companyId, {
      onSuccess: () => {this.loadAssemblies(); /* this.loadSingleAssemblies() */},
    });
    loadCompanyTags(companyId);
    getUserTags({ company_id: companyId, user_id: userId });
    loadMotives(
      { company_id: companyId, type: 1 },
      { alias: "vehicleMotives" }
    );
    loadMotives(
      { company_id: companyId, type: 2 },
      { alias: "equipmentMotives" }
    );
    loadMotives(
      { company_id: companyId, type: 3 },
      { alias: "operatorMotives" }
    );
    this.loadCounters();

    this.props.setNavbarAction("assemblies-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("assemblies-control");
  }

  componentDidUpdate(prevProps) {
    // debugger;
    // if(prevProps.fleetAssemblies.length !== this.props.fleetAssemblies.length){
    //   debugger;
    // }
  }

  loadSingleAssemblies = (strategy = "REPLACE") => {
    const {
      companyId,
      companyFleets,
      loadSingleAssemblies,
    } = this.props;

    loadSingleAssemblies({
      company_id: companyId,
      fleet_id: companyFleets[0].id,
      limit: 10,
      offset: this.state.modalOffset
    }, {
      strategy
    });
  }

  loadCounters = () => {
    this.props.listFleetResourceCounters(this.props.companyId);
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { value, name } = e.target;
    switch (e.target.name) {
      case "gridConfigSelect":
        this.setState(
          {
            gridConfig: {
              columnQuantity: parseInt(value),
              columnRangeType: value.includes("H") ? "HOUR" : "DAY",
              columnSections: 4,
              startDatetime: value.includes("H") ? hour : today,
              className: "",
              extended: true,
            },
          },
          () => {
            this.handleChange({
              target: {
                name: "gridDateChange",
                // value: value.includes("H") ? parseInt(value) : 0,
                value: 0,
              },
            });
          }
        );
        break;
      case "gridDateChange":
        let c = { ...this.state.gridConfig };
        let f = { ...this.state.filterParams };
        c.startDatetime =
          c.columnRangeType === "DAY"
            ? dateAddDays(c.startDatetime, e.target.value)
            : dateAddHours(c.startDatetime, e.target.value);
        f.start_date = c.startDatetime.toISOString();
        // c.columnRangeType === "DAY"
        // ? dateAddDays(c.startDatetime, e.target.value).toISOString()
        // : dateAddHours(c.startDatetime, e.target.value).toISOString();
        f.end_date =
          c.columnRangeType === "DAY"
            ? dateAddDays(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString()
            : dateAddHours(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString();
        this.setState({ gridConfig: c });
        this.setState({ filterParams: f }, () => this.loadAssemblies());
        break;
      case "saved":
        this.setState({
          showCreateModal: false,
          showSubstituteModal: false,
          showAddModal: false,
          showLinkModal: false,
          filterParams: {
            ...this.state.filterParams,
            offset: 1
          }
        }, () => {
          this.loadAssemblies();
          this.loadCounters();
        });
        break;
      case "showCreateModal":
      case "showSubstituteModal":
      case "showLinkModal":
      case "showAddModal":
        if (e.target.value === false) {
          this.loadAssemblies();
          this.loadCounters();
        }
        this.setState({ [name]: e.target.value });
        break;
      default:
        this.setState({ [name]: e.target.value });
    }
  };

  manageFilter = (e: SharedTypes.SimpleEvent) => {
    let filterParams = { ...this.state.filterParams };

    switch (e.target.name) {
      case "normativity_status":
        filterParams.operator_status = e.target.value;
        filterParams.equipment_status = e.target.value;
        filterParams.vehicle_status = e.target.value;
        break;
      case "tags":
        filterParams.vehicle_tags = e.target.value;
        filterParams.equipment_tags = e.target.value;
        filterParams.operator_tags = e.target.value;
        break;
      case "operator_type":
        filterParams.operator_license_type = e.target.value;
        break;
      case "vehicle_class":
        filterParams.vehicle_class = e.target.value;
        break;
      case "equipment_class":
        filterParams.equipment_class = e.target.value;
        break;
      // case "vehicle_type":
      //   // filterParams.vehicle_type = e.target.value;
      //   filterParams.vehicle_class = e.target.value;
      //   break;
      // case "equipment_type":
      //   filterParams.equipment_type = e.target.value;
      //   filterParams.equipment_type = e.target.value;
      //   break;
      default:
    }
    this.setState({ filterParams, buttonFilterName: "Aplicar" });
  };

  setSearchStr = (e) => {
    this.setState({ 
      textSearch: e.target.value || "",  
      filterParams: {
        ...this.state.filterParams, 
        offset: 1
      }
    });
    clearTimeout(typingTimer);
    typingTimer = setTimeout(this.loadAssemblies, 1000);
  };

  doSearch = (modalSearchString: string, typeId: number = 0) => {
    const { listFleetResourceCatalog, companyId, cancelRequest } = this.props;
    (typeId > 0 ? [typeId] : [1, 2, 3]).forEach((number) => {
      cancelRequest(`catalogType${number}Counter`);
      listFleetResourceCatalog(
        companyId,
        number,
        modalSearchString.length === 0 ? "unassembled" : "",
        modalSearchString,
        modalSearchString,
        modalSearchString,
        modalSearchString,
        {
          alias: `catalogType${number}Counter`,
        }
      );
    });
  };

  manageActions = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "remove":
        this.setState({
          showRemoveModal: true,
          currentTimeline: value,
        });
        break;
      case "substitute":
        this.setState({
          showSubstituteModal: true,
          currentTimeline: value,
          addingDolly: value.isDolly
        });
        break;
      case "addResource":
        this.setState({
          showSubstituteModal: true,
          currentTimeline: value,
        });
        break;
      default:
    }
  };

  manageError = (err) => {
    this.setState({openXlsErrorModal: true, descriptionImport: err})
  }

  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: []
    });
  };

  render() {
    const {
      textSearch,
      showCreateModal,
      gridConfig,
      filterParams,
      showSubstituteModal,
      currentTimeline,
      showAddModal,
      showLinkModal,
      currentAssembleId,
    } = this.state;
    const {
      // unasembledVehicles,
      // unasembledEquipments,
      // unasembledOperators,
      fleet_counters,
    } = this.props;
    return (
      <View className="small-pad-bottom">
        <ViewTitle>
          <span>Control ensamble</span>
          <Flex
            {...{
              direction: flexOpts.direction.column,
              alignItems: flexOpts.alignItems.flexEnd,
            }}
          >
            <FilterBarCompact
              filtered={false}
              searchAction={this.setSearchStr}
              filterAction={() => {
                if(this.state.buttonFilterName === "Aplicar"){
                  this.setState({ buttonFilterName: "Restablecer" }, () => {this.loadAssemblies();});
                } else {
                  this.setState({
                    filterParams: {
                      limit: 15,
                      offset: 1,
                      view: "shortdetail",
                      operator_status: [],
                      operator_license_type: [],
                      equipment_status: [],
                      equipment_class: [],
                      equipment_type: [],
                      vehicle_status: [],
                      vehicle_class: [],
                      vehicle_type: [],
                      vehicle_tags: [],
                      equipment_tags: [],
                      operator_tags: [],
                      start_date: today.toISOString(),
                      end_date: dateAddDays(
                        new Date(today),
                        defaultGridConfig.columnQuantity
                      ).toISOString(),
                      status: "",
                      get_trips: "",
                      from_to_companies: [],
                      from_facilities: [],
                      to_facilities: [],
                    },
                    buttonFilterName: "Aplicar"
                  }, () => {this.loadAssemblies();});
                }
              }}
              searchStr={textSearch}
              content={this.getFilterContent()}
              selectedItems={{
                normativity_status: filterParams.operator_status,
                tags: filterParams.vehicle_tags,
                operator_type: filterParams.operator_license_type,
                vehicle_class: filterParams.vehicle_class,
                equipment_class: filterParams.equipment_class,
              }}
              buttonFilterName={this.state.buttonFilterName}
              onChange={this.manageFilter}
            />

            {this.getResponsePerm("56", "c") && (
              <Button
                onClick={() =>
                  this.handleChange({
                    target: { name: "showCreateModal", value: true },
                  })
                }
                type="primary"
                text="Crear ensamble"
                name="showCreateModal"
              />
            )}
          </Flex>
        </ViewTitle>
        <div style={{ marginTop: "12px" }} />
        <AssembleGrid
          summary={(value) => (
            <VerticalLineTooltipStyled>
              <p>Ensambles Disponibles</p>
              {value}
            </VerticalLineTooltipStyled>
          )}
          counters={{
            // vehicles: unasembledVehicles.count,
            // equipments: unasembledEquipments.count,
            // operators: unasembledOperators.count,
            vehicles: fleet_counters.unassembled_vehicles,
            equipments: fleet_counters.unassembled_equipments,
            operators: fleet_counters.unassembled_operators,
          }}
          gridConfigChange={this.handleChange}
          dateChange={this.handleChange}
          {...{ gridConfig }}
          rows={this.props.fleetAssemblies
            // .filter((assemble: Assemble) =>
            //   assemble.resources.find(
            //     (resource: Resource) =>
            //       normalizeStr(resource.longDescription).includes(
            //         normalizeStr(textSearch)
            //       ) && resource.assembled
            //   )
            // )
            .map((assemble) => ({
              ...assemble,
              hoverCallback: () => {
                // console.log("CALLBACK HEADER", assemble.id)
                if(!this.state.actionPermissions[assemble.id]){
                  this.props.getAssemblyActions({
                    company_id: this.props.companyId,
                    fleet_id: this.props.companyFleets[0].id,
                    assembly_id: assemble.id,
                  }, {
                    onSuccess: (r) => {
                      this.setState({
                        actionPermissions: {
                          ...this.state.actionPermissions,
                          [assemble.id]: r
                        }
                      });
                    }
                  })
                }
              },
              actions: assemble.detail.find((detail) =>
                detail.timeLine.find((timeline) => timeline.assembled)
                  ? true
                  : false
              )
                ? this.getAssembleActions(assemble)
                : undefined,

              detail: assemble.detail.map((detail) => ({
                ...detail,
                hoverCallback: () => {
                  // console.log("CALLBACK ", assemble.id)
                  if(!this.state.actionPermissions[assemble.id]){
                    this.props.getAssemblyActions({
                      company_id: this.props.companyId,
                      fleet_id: this.props.companyFleets[0].id,
                      assembly_id: assemble.id,
                    }, {
                      onSuccess: (r) => {
                        this.setState({
                          actionPermissions: {
                            ...this.state.actionPermissions,
                            [assemble.id]: r
                          }
                        });
                      }
                    })
                  }
                },
                zIndex: 9999,
                // hoverCallback: () => {console.log("CALLBACK ", assemble.id)},
                timeLine: detail.timeLine.map((timeline) =>
                  this.getDetailTimelineActions(timeline)
                ),
              })),
            }))}
          hasInfiniteScroll={true}
          scrollCallback={this.scrollCallback}
          itemCount={this.props.fleetAssembliesCount}
        />
        {showCreateModal ? (
          <CreateAssemble
            {...this.props}
            showCreateModal={showCreateModal}
            onChange={this.handleChange}
            onSearch={this.doSearch}
            manageError={this.manageError}
          />
        ) : null}
        {showSubstituteModal ? (
          <AssembleSubstituteResource
            // FleetDatas={this.props.fleetDatas}
            manageError={this.manageError}
            operatorLicenseTypes={this.props.operatorLicenseTypes}
            unasembledEquipments={this.props.unasembledEquipments}
            unasembledOperators={this.props.unasembledOperators}
            unasembledVehicles={this.props.unasembledVehicles}
            fleetVehicleTypesClasses={this.props.fleetVehicleTypesClasses}
            // listFleetData={this.props.listFleetData}
            companyFleets={this.props.companyFleets}
            companyId={this.props.companyId}
            companyTags={this.props.companyTags}
            listFleetResourceCatalog={this.props.listFleetResourceCatalog}
            updateAssembleVehicleLog={this.props.updateAssembleVehicleLog}
            updateAssembleOperatorLog={this.props.updateAssembleOperatorLog}
            updateAssembleEquipmentLog={this.props.updateAssembleEquipmentLog}
            // listFleetOperators={this.props.listFleetOperators}
            assembleSummaryResources={this.getAssembleResources(
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentTimeline?.assembleId;
              })
            )}
            resources={
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentTimeline?.assembleId;
              })?.resources
            }
            showSubstituteModal={showSubstituteModal}
            original={currentTimeline}
            addingDolly={this.state.addingDolly}
            onChange={this.handleChange}
            onSearch={this.doSearch}
          />
        ) : null}
        {
          showLinkModal &&
          <AssembleLinkAssemble 
            companyFleets={this.props.companyFleets}
            companyId={this.props.companyId}
            manageError={this.manageError}
            showLinkModal={showLinkModal}
            onChange={this.handleChange}
            loadSingleAssemblies={this.props.loadSingleAssemblies}
            linkAssemblies={this.props.linkAssemblies}
            unasembledEquipments={this.props.singleAssemblies}
            assembleSummaryResources={this.getAssembleResources(
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentAssembleId;
              })
            )}
            currentAssembly={currentAssembleId}
            scrollCallback={this.modalScrollCallback}
            itemCount={this.props.singleAssembliesCount}
          />
        }
        {showAddModal ? (
          <AssembleAddResource
            // fleetDatas={this.props.fleetDatas}
            manageError={this.manageError}
            createAssembleEquipment={this.props.createAssembleEquipment}
            createAssembleOperator={this.props.createAssembleOperator}
            createAssembleVehicle={this.props.createAssembleVehicle}
            unasembledEquipments={this.props.unasembledEquipments}
            unasembledVehicles={this.props.unasembledVehicles}
            unasembledOperators={this.props.unasembledOperators}
            companyFleets={this.props.companyFleets}
            companyTags={this.props.companyTags}
            companyId={this.props.companyId}
            fleetVehicleTypesClasses={this.props.fleetVehicleTypesClasses}
            // listFleetData={this.props.listFleetData}
            // listFleetOperators={this.props.listFleetOperators}
            listFleetResourceCatalog={this.props.listFleetResourceCatalog}
            operatorLicenseTypes={this.props.operatorLicenseTypes}
            assembleSummaryResources={this.getAssembleResources(
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentAssembleId;
              })
            )}
            resources={
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentAssembleId;
              })?.resources
            }
            addType={this.state.addType}
            addingDolly={this.state.addingDolly}
            showAddModal={showAddModal}
            assemble={this.props.fleetAssemblies.find((assemble) => {
              return assemble.id === currentAssembleId;
            })}
            onSearch={this.doSearch}
            onChange={this.handleChange}
          />
        ) : null}
        <ConfirmDialog {...this.confirmDialogResolver()} />
        
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
      </View>
    );
  }

  getResponsePerm = (perm, type) => {
    return validateObjPerms(
      [
        {
          id: String(perm),
          perm: String(type),
        },
      ],
      true
    ) === true
      ? true
      : false;
  };

  getAssembleActions = (assemble: Assemble) => {
    const { resources } = assemble;
    let actions = [
      {
        description: "Desensamblar",
        action: () => {
          this.managedisassembleModal({
            target: { name: "confirm", value: assemble.id },
          });
        },
      },
    ];
    if (
      resources.filter((resource) => resource.type === 1 && resource.assembled)
        .length < 1
    )
      actions.push({
        description: "Agregar Vehículo",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addVehicle", value: assemble.id },
          });
        },
      });
    
    if(
      resources.filter((resource) => resource.type === 2 && resource.assembled && !resource.isDolly)
        .length === 1
      && resources.filter((resource) => resource.type === 2 && resource.assembled && resource.isDolly)
      .length === 1
      && resources.filter((resource) => resource.type === 1 && resource.assembled)
      .length === 1
      && resources.filter((resource) => resource.type === 3 && resource.assembled)
      .length
      && !assemble.is_vinculated
    ) {
      actions.push({
        description: "Vincular otro ensamble",
        action: () => {
          this.managedisassembleModal({
            target: { name: "linkAssemble", value: assemble.id },
          });
        },
      });
    }
    
    if (
      resources.filter((resource) => resource.type === 2 && resource.assembled && !resource.isDolly)
        .length < 2 && (this.state.actionPermissions[assemble.id] || {}).equipments?.add
    ) {
      actions.push({
        description: "Agregar Equipo",
        permission: "vehicle_add",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addEquipment", value: assemble.id },
          });
        },
      });
    }
    if (
      resources.filter((resource) => resource.type === 2 && resource.assembled && resource.isDolly)
        .length < 1
    ) {
      actions.push({
        description: "Agregar Dolly",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addDolly", value: assemble.id },
          });
        },
      });
    }
    if (
      resources.filter((resource) => resource.type === 3 && resource.assembled)
        .length < 3
    )
      actions.push({
        description: "Agregar Operador",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addOperator", value: assemble.id },
          });
        },
      });
    if (!this.getResponsePerm("56", "u")) {
      actions = actions.filter(
        (action) =>
          action.description !== "Agregar Vehículo" &&
          action.description !== "Agregar Equipo" &&
          action.description !== "Agregar Operador" && 
          action.description !== "Agregar Dolly" &&
          action.description !== "Vincular otro ensamble"
      );
    }
    if (!this.getResponsePerm("56", "d")) {
      actions = actions.filter(
        (action) => action.description !== "Desensamblar"
      );
    }

    // actions = [];
    return actions;
  };

  getDetailTimelineActions = (timeline: Timeline) => {
    const current = Date.parse(new Date().toISOString());
    let actions =
      timeline.from > current || timeline.to > current
        ? [
            {
              description: "Remover",
              action: () =>
                this.manageActions({
                  target: { name: "remove", value: timeline },
                }),
            },
            {
              description: "Sustituir",
              action: () =>
                this.manageActions({
                  target: { name: "substitute", value: timeline },
                }),
            },
          ]
        : null;
    if (actions !== null && !this.getResponsePerm("56", "u")) {
      actions = actions.filter((action) => action.description !== "Sustituir");
    }
    if (actions !== null && (!this.getResponsePerm("56", "d") || (timeline.type === 2 && !timeline.isDolly && !(this.state.actionPermissions[timeline.assembleId] || {}).equipments?.remove))) {
      actions = actions.filter((action) => action.description !== "Remover");
    }

    return {
      ...timeline,
      actions,
    };
  };

  getAssembleResources = (fleetAssemble: Assemble) => {
    const vehicles = fleetAssemble?.resources
      .filter((r: Resource) => r.type === 1 && r.assembled)
      .map((r) => ({ description: r.longDescription, ...r }));
    const equipments = fleetAssemble?.resources
      .filter((r) => r.type === 2 && r.assembled)
      .map((r) => ({ description: r.longDescription, ...r }));
    const operators = fleetAssemble?.resources
      .filter((r) => r.type === 3 && r.assembled)
      .map((r) => ({
        description: r.longDescription,
        imageUrl: r.image ? FILES_SERVER.concat(r.image) : "images/user.svg",
        ...r,
      }));
    return { vehicles, equipments, operators };
  };
  getAssembleSummary = (fleetAssemble: Assemble) => {
    const resources = this.getAssembleResources(fleetAssemble);
    return (
      <Flex justifyContent={flexOpts.justifyContent.center}>
        <AssembleSummary
          {...{
            vehicles: resources.vehicles,
            equipments: resources.equipments,
            operators: resources.operators,
          }}
        />
      </Flex>
    );
  };

  loadAssemblies = (strategy = "REPLACE") => {
    const {
      companyId,
      companyFleets,
      listAssemblies,
    } = this.props;
    let { filterParams } = this.state;
    filterParams["search"] = this.state.textSearch;
    filterParams.orderStatus = '';
    filterParams.baseTags = '';
    filterParams.fleet_provider = '';
    filterParams.equipment = '';
    listAssemblies(companyId, companyFleets[0].id, filterParams, {
      args: filterParams,
      strategy
    });
  };

  modalScrollCallback = () => {
    this.setState(
      {
          modalOffset: this.state.modalOffset + 1
      },
      () => {this.loadSingleAssemblies("ADD")}
    )
  }

  scrollCallback = () => {
    let { filterParams } = this.state;
    this.setState(
      {
        filterParams: {
          ...filterParams, 
          offset: filterParams.offset + 1
        }
      },
      () => {this.loadAssemblies("ADD")}
    )
  }

  getFilterContent = () => {
    return [
      {
        title: "Etiquetas",
        items: this.props.companyTags.filter((f) =>
          f.type === 3 && this.props.userTags.find((ut) => ut.tags === f.id)
            ? true
            : false
        ),
        name: "tags",
        listType: 1,
      },
      {
        title: "Tipo de vehículos",
        items: this.props.fleetVehicleTypesClasses
          .filter((f) => f.type === 1 && f.classes.length > 0)
          .map((type) => ({
            ...type,
            items: type.classes,
          })),
        name: "vehicle_class",
        listType: 5,
      },
      {
        title: "Tipo de equipos",
        items: this.props.fleetVehicleTypesClasses
          .filter((f) => f.type === 2)
          .map((type) => ({
            ...type,
            items: type.classes,
          })),
        name: "equipment_class",
        listType: 5,
      },
      {
        title: "Tipo de operadores",
        items: this.props.operatorLicenseTypes,
        name: "operator_type",
        listType: 3,
      },
      {
        title: "Estatus de validación",
        items: [
          { id: 1, description: "Pendiente" },
          { id: 2, description: "Validado" },
          { id: 3, description: "Rechazado" },
          { id: 4, description: "Incompleto" },
          { id: 5, description: "Vencido" },
        ],
        name: "normativity_status",
        listType: 3,
      },
    ];
  };
  managedisassembleModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "confirm":
        this.setState({
          showDisassembleModal: true,
          currentAssembleId: parseInt(value),
        });
        break;
      case "cancel":
        this.setState({
          showDisassembleModal: false,
          currentAssembleId: undefined,
        });
        break;
      case "disassemble":
        this.disassemble();
        break;
      case "addVehicle":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 1,
        });
        break;
      case "addEquipment":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 2,
          addingDolly: false,
        });
        break;
      case "addDolly":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 2,
          addingDolly: true,
        });
        break;
      case "linkAssemble":
        this.setState({
          showLinkModal: true,
          currentAssembleId: parseInt(value),
        }); 
        break;
      case "addOperator":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 3,
        });
        break;
      case "done":
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            offset: 1
          }
        }, () => {
          this.loadAssemblies();
          this.loadCounters();
          this.setState({
            showDisassembleModal: false,
            currentAssembleId: undefined,
          });
        });
        break;
      default:
    }
  };

  disassemble = () => {
    this.setState({loading: true});
    const { currentAssembleId } = this.state;
    const {
      // fleetAssemblies,
      // deleteAssembleEquipmentLog,
      // deleteAssembleOperatorLog,
      // deleteAssembleVehicleLog,
      companyFleets,
      companyId,
    } = this.props;
    this.props.deleteAssembleWithComponents({
      company_id: companyId,
      fleet_id: companyFleets[0].id,
      assemble_id: currentAssembleId,
    }, {
      onSuccess: () => {
        this.setState({loading: false});
        this.managedisassembleModal({ target: { name: "done", value: "" } })

      },
      onError: (e) => {
        this.setState({loading: false});
        this.managedisassembleModal({ target: { name: "cancel", value: "" } }); 
        let err = e.response?.data?.errors || []; 
        this.manageError(err);
      }
    });
    // const assemble: Assemble = fleetAssemblies.find(
    //   (f) => f.id === currentAssembleId
    // );
    // const counter = new SaveItemCounter(() =>
    //   this.managedisassembleModal({ target: { name: "done", value: "" } })
    // );
    // assemble.resources.forEach((resource) => {
    //   counter.add();
    //   switch (resource.type) {
    //     case 1:
    //       deleteAssembleVehicleLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //       break;
    //     case 2:
    //       deleteAssembleEquipmentLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //       break;
    //     default:
    //       deleteAssembleOperatorLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //   }
    // });
  };

  manageRemoveModal = (e: SharedTypes.SimpleEvent) => {
    const { name } = e.target;
    switch (name) {
      case "cancel":
        this.setState({ showRemoveModal: false, currentTimeline: {} });
        break;
      case "remove":
        this.removeCurrentResource();
        break;
      case "saved":
        this.setState({
          filterParams: {
            ...this.state.filterParams,
            offset: 1
          }
        }, () => {
          this.loadAssemblies();
          this.loadCounters();
          this.setState({ showRemoveModal: false, currentTimeline: {} });
        });
        break;
      default:
    }
  };

  confirmDialogResolver = (): { open: boolean } => {
    const {
      showDisassembleModal,
      showRemoveModal,
      currentTimeline,
      currentAssembleId,
    } = this.state;
    const { fleetAssemblies } = this.props;

    if (showDisassembleModal) {
      return {
        open: showDisassembleModal || false,
        closeAction: () =>
          this.managedisassembleModal({
            target: { name: "cancel", value: "" },
          }),

        acceptAction: () =>
          this.managedisassembleModal({
            target: { name: "disassemble", value: "" },
          }),

        title: "Desensamblar",
        message: "¿Estás seguro que deseas deshacer este ensamble?",
        contentObject: this.getAssembleSummary(
          fleetAssemblies.find((assemble) => assemble.id === currentAssembleId)
        ),
        showCancel: true,
        cancelText: "Cancelar",
        acceptText: this.state.loading ? <Loader /> : "Aceptar",
        disabledAccept: this.state.loading,
        cancelButtonType: "primary outline dark",
      };
    } else if (showRemoveModal) {
      const current = fleetAssemblies.find(
        (assemble) => assemble.id === currentTimeline.assembleId
      );
      return {
        open: showRemoveModal || false,
        closeAction: () =>
          this.manageRemoveModal({
            target: { name: "cancel", value: "" },
          }),

        acceptAction: () =>
          this.manageRemoveModal({
            target: { name: "remove", value: "" },
          }),

        title: "Remover recurso",
        message: `¿Estás seguro que deseas remover a ${currentTimeline.caption.props.name}?`,
        contentObject: this.getAssembleSummary(current),
        showCancel: true,
        cancelText: "Cancelar",
        acceptText: this.state.loading ? <Loader /> : "Remover",
        disabledAccept: this.state.loading,
        cancelButtonType: "primary outline dark",
      };
    } else {
      return { open: false };
    }
  };

  removeCurrentResource = () => {
    this.setState({loading: true});
    const { currentTimeline } = this.state;
    const {
      companyId,
      companyFleets,
      deleteAssembleVehicleLog,
      deleteAssembleEquipmentLog,
      deleteAssembleOperatorLog,
    } = this.props;
    switch (currentTimeline.type) {
      case 1:
        deleteAssembleVehicleLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      case 2:
        deleteAssembleEquipmentLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      case 3:
        deleteAssembleOperatorLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      default:
    }
  };
}

const mapStateToProps = (state) => {
  //console.log("ESTADO", state.api["COMPANIES.FLEETS.ASSEMBLIES"]);
  const fleetAssembliesArgs = getEntityArgs(
    state,
    "COMPANIES.FLEETS.ASSEMBLIES"
  );
  // const fleetAssemblies = getRowsFromFleetAssemblies(
  //   getEntityItems(state, "COMPANIES.FLEETS.ASSEMBLIES"),
  //   fleetAssembliesArgs.start_date,
  //   fleetAssembliesArgs.end_date
  // );

  const fleetVehicleTypesClasses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.VEHICLETYPES"
  ).map((vt) => ({
    ...vt,
    classes: getEntityItems(state, "COMPANIES.CATALOGS.FLEETCLASSES").filter(
      (fc) => fc.type === vt.id
    ),
    icon:
      VEHICLE_TYPE_ICON_SELECTOR[
        vt.abbreviation || (vt.description === "Dolly" ? "D" : undefined)
      ],
  }));
  const motives = {
    1: getEntityItems(state, "vehicleMotives"),
    2: getEntityItems(state, "equipmentMotives"),
    3: getEntityItems(state, "operatorMotives"),
  };
  const fleetAssemblies = getAssembles(
    (getEntityItems(state, "COMPANIES.FLEETS.ASSEMBLIES")) || [],
    Date.parse(fleetAssembliesArgs.start_date),
    Date.parse(fleetAssembliesArgs.end_date),
    fleetVehicleTypesClasses,
    motives
  );
  const fleetAssembliesCount = (getEntityCount(state, "COMPANIES.FLEETS.ASSEMBLIES") || 0);
  let operatorLicenseTypes = getEntityItems(
    state,
    "COMPANIES.CATALOGS.OPERATORLICENSETYPES"
  ).map((operator) => ({
    ...operator,
    icon: License,
  }));

  operatorLicenseTypes = operatorLicenseTypes.concat({
    id: 0,
    description: 'Sin categoría',
    abbreviation: 'NOL',
    icon: License
  });

  // const fleetDatas = getEntityItems(state, "COMPANIES.FLEETS.DATA");
  const companyFleets = getEntityItems(state, "COMPANIES.FLEETS");
  const companyId = state.globalCatalog.session.company.id;
  const userId = state.globalCatalog.session.user.id;
  const unasembledVehicles = state.api["catalogType1Counter"] || {};
  const unasembledEquipments = state.api["catalogType2Counter"] || {};
  const unasembledOperators = state.api["catalogType3Counter"] || {};
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  // const fleetOperators = getEntityItems(state, "COMPANIES.FLEETS.OPERATORS");
  const userTags = getEntityItems(state, "COMPANIES.USERS.USERSTAGS");

  const fleet_counters =
    getEntityItems(state, "COMPANIES.FLEETCATALOG.COUNTERS") || {};
  const singleAssemblies =
    getEntityItems(state, "COMPANIES.FLEETS.ASSEMBLIES.SINGLE") || [];
  const singleAssembliesCount = getEntityCount(state, "COMPANIES.FLEETS.ASSEMBLIES.SINGLE") || 0;

  return {
    // assembles,
    fleetVehicleTypesClasses,
    operatorLicenseTypes,
    companyFleets,
    companyId,
    userId,
    // fleetDatas,
    unasembledVehicles,
    unasembledEquipments,
    unasembledOperators,
    companyTags,
    // fleetOperators,
    fleet_counters,
    fleetAssemblies,
    userTags,
    singleAssemblies,
    singleAssembliesCount,
    fleetAssembliesCount,
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  AssembleViewApi
)(AssembleView);
