import React from 'react';
import { connect } from 'react-redux';
import { deleteEntity, getEntityItems, partialEntity, readEntity, SaveItemCounter } from '../../redux/api';
import { getUrlsEnv } from '../../redux/api/api-urls';
import Button from '../../shared/controls/button';
import TabSelectorRibbon from '../../shared/controls/tab-selector-ribbon';
import Tag from '../../shared/controls/tag';
import Loader from '../../shared/icons/tat/loader';
import { View, ViewTitle } from '../../shared/styled/view';
import { getLastSession } from '../../shared/utils';
import OrdersControlDetailProductTab from './orders-control-detail-product-tab';

const TABS = [
  {
    id: "1",
    description: "Productos"
  },
  // {
  //   id: "2",
  //   description: "Comentarios"
  // },
  // {
  //   id: "3",
  //   description: "Historial de Orden"
  // },
]

class OrdersControlDetailView extends React.Component {
  state = {
    editMode: false,
    selectedorderTags: [],
    isLoading: false,
    selectedTab: "1",
    isOrder: null,

    selectedProducts: [],
    palletName: "",
    errorMsg: ""
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const isOrder = urlParams.get("isOrder");
    this.setState({isOrder}, this.loadItems)
  }

  loadItems = () => {
    if(this.state.isOrder === "true"){
      this.props.loadOrderData({
        company_id: this.props.companyId,
        order_id: this.props.match.params.id
      })
    } else {
      this.props.loadRequisitionData({
        company_id: this.props.companyId,
        requisition_id: this.props.match.params.id
      })
    }
  }

  handleTagClick = () => {

  }

  handleSelectProduct = (productId) => {
    // debugger;
    if(!this.state.selectedProducts.includes(productId)){
      let ids = [...this.state.selectedProducts];
      ids.push(productId);
      this.setState({selectedProducts: ids})
    } else {
      let ids = this.state.selectedProducts.filter(i => i !== productId);
      this.setState({selectedProducts: ids});
    }
  }

  
  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  resetModalData = () => {
    this.setState({
      selectedProducts: [],
      palletName: "",
      // show
    })
  }

  getData = () => {
    if(this.state.isOrder === "true"){
      return this.props.orderData;
    } else {
      return this.props.requisitionData;
    }

  }

  switchTab = (e) => {
    let val = e.target.attributes["name"].value;
    this.setState({ selectedTab: val }/* , () => {
      if (val === "2" && this.state.routePoints.length) {
        let lats = this.state.routePoints.map(p => p.lat);
        let lngs = this.state.routePoints.map(p => p.lng);
        this.mapController.setCenterMapMultiple(
          Math.min(...lats),
          Math.min(...lngs),
          Math.max(...lats),
          Math.max(...lngs)
        )
      } else if (val === "3") {
        this.scrollComments();
      }

    } */);
  }

  getPackagesInfo = () => {
    if(this.state.isOrder === "true"){
      return (this.props.orderData["packages_order"] || [])/* .map(r => r.package_requisition).flat() */
    } else {
      return this.props.requisitionData["package_requisition"] || []
    }
  }

  deletePackage = (id) => {
    this.props.deletePackage({
      company_id: this.props.companyId,
      package_id: id
    }, {
      onSuccess: () => {this.loadItems()}
    })
  }

  getTabContent = () => {
    switch(this.state.selectedTab){
      case "1":
        return <OrdersControlDetailProductTab
          products={this.getPackagesInfo()}
          isOrder={this.state.isOrder === "true"}
          pallets={this.state.isOrder === "true" ? this.props.orderData["pallets_order"] : []}
          selectedProducts={this.state.selectedProducts}
          handleSelectProduct={this.handleSelectProduct}
          handleChange={this.handleChange}
          palletName={this.state.palletName}
          errorMsg={this.state.errorMsg}

          checkPalletCode={this.checkPalletCode}
          resetModalData={this.resetModalData}
          updatePackages={this.updatePackage}
          deletePackage={this.deletePackage}
        />;
      default:
        return <></>
    }
  }

  checkPalletCode = (callBack) => {
    this.props.checkPalletCode({
      company_id: this.props.companyId,
      code: this.state.palletName
    }, {
      onSuccess: (result) => {
        // debugger;
        if(result.from_facility || result.to_facility) {
          let data = this.getData();
          if(data.from_facility.id === result.from_facility && data.to_facility.id === result.to_facility){
            this.setState({errorMsg: ""})
            this.addPalletPackages(result, callBack);
          } else {
            this.setState({errorMsg: "Esta tarima tiene origen y/o destino distinto(s)."})
          }
        } else {
          this.setState({errorMsg: ""})
          this.addPalletPackages(result, callBack);
        }
      }
    })
  }

  addPalletPackages = (pallet, callBack) => {
    let packages = pallet.package_pallet.map(p => ({id: p.id})).concat(this.state.selectedProducts.map(sP => ({id: sP})));
    this.props.updatePallet({
      company_id: this.props.companyId,
      pallet_id: pallet.id,
      package_pallet: packages
    }, {
      onSuccess: () => {
        this.loadItems();
        this.resetModalData();
        callBack && callBack();
        // this.setState
      }
    })
  }

  updatePackage = () => {
    const counter = new SaveItemCounter(() => {
      this.loadItems();
      this.resetModalData();
    });
    this.state.selectedProducts.forEach(p => {
      counter.add();
      this.props.updatePackage({
        company_id: this.props.companyId,
        package_id: p,
        pallet_code: this.state.palletName
      }, {
        onSuccess: () => counter.done()
      });
    });
  }

  render(){
    return (
      <View className="order-detail-view">
        <ViewTitle>
          <div className="data">Detalle de {this.state.isOrder === "true" ? "orden" : "pedido"} 
            {/* {this.state.editMode
              ? <TextInput
                type='text'
                label=''
                name='selectedOrderCode'
                className=''
                value={this.state.selectedOrderCode}
                onChange={this.props.companyType === 2 ? this.onChange : () => { }}
              />
              : <SelectInput
                label=""
                name="selectedOrderId"
                value={this.state.currentOrder.id || 0}
                className="order"
                onChange={this.onChange}
                items={(this.props.trip.orders || []).map(o => ({ id: o.id, description: o.code }))}
              />
            } */}{<span className="order-number">n°{this.getData()["code"]}</span>}
          </div>
          <div className="order-detail-main-status">
            <span className="param">Estatus:</span> {(this.getData()["status"] || {}).description}
          </div>
        </ViewTitle>
        <div className="panel">
          {//Viaje completado
            /* (
              currentOrder.status?.description
              && (
                currentOrder.status.description.toLowerCase() !== "finalizado sin datos"
                && currentOrder.status.description.toLowerCase() !== "viaje completado"
                && currentOrder.status.description.toLowerCase() !== "cancelado"
              )
            ) && <div
              className="edit-info edit-btn"
              onClick={this.toggleEditMode}
            >
              {this.props.companyType === 1 ? <img alt="" src="/images/edit-transparent.svg" /> :
                <ValidatePerms
                  perms={[
                    {
                      id: "61",
                      perm: "u",
                    },
                  ]}
                >
                  <img alt="" src="/images/edit-transparent.svg" />
                </ValidatePerms>
              }
            </div> */
          }
          <div className="trip">
            <div className="trip-stop">
              <div className="avatar-container">
                <img
                  className="avatar"
                  src={getUrlsEnv().files.concat(
                    "/",
                    null
                  )}
                  onError={(e)=>{e.target.onerror = null; e.target.src="/images/interrogacion.svg"}}
                  alt=""
                />
              </div>
              <div className="credentials">
                <div className="location">
                  <img
                    className="location-pin"
                    src={"/images/location-pin.svg"}
                    alt=""
                  />
                  {(this.getData()["from_facility"] || {}).name}
                </div>
                <div className="param">Fecha de recolección:</div>
                {
                  this.state.isOrder === "true" &&
                  <div className="event-date">{/* 5 de junio de 2021 1:15 */}{ getLastSession(this.getData()["appointment_loading_date"], 'LOG_FORMAT') || '-'}</div>
                }
              </div>
            </div>
            <div className="transit-line">
              <div className="arrow-base"></div>
              <img
                className="arrow-body"
                src={"/images/arrow.svg"}
                alt=""
              />
              <div className="arrow-end"></div>
            </div>
            <div className="trip-stop">
              <div className="avatar-container">
                <img
                  className="avatar"
                  src={getUrlsEnv().files.concat(
                    "/",
                    null
                  )}
                  onError={(e)=>{e.target.onerror = null; e.target.src="/images/interrogacion.svg"}}
                  alt=""
                />
              </div>
              <div className="credentials">
                <div className="location">
                  <img
                    className="location-pin"
                    src={"/images/location-pin.svg"}
                    alt=""
                  />
                  {(this.getData()["to_facility"] || {}).name}
                </div>
                <div className="param">Fecha de llegada:</div>
                {
                  this.state.isOrder === "true" &&
                  <div className="event-date">{/* N/A */} { getLastSession(this.getData()["appointment_unload_date"], 'LOG_FORMAT') || '-'}</div>
                }
              </div>
            </div>
          </div>
          {
            this.state.isOrder === "true" &&
            <div className="details">
              <div className="info">
                <div className="left">
                  <div>
                    <span className="param">Transporte principal:</span>
                    {"Terrestre"}
                  </div>
                  <div>
                    <span className="param">Nivel de criticidad:</span>
                    {this.getData()["priority"]}
                  </div>
                  
                </div>
                <div className="right">
                  <div>
                    <span className="param">Incoterm:</span>
                    {''}
                  </div>
                  <div>
                    <span className="param">Transportista:</span>
                    <img
                      className="logo"
                      src={getUrlsEnv().files.concat(
                        "/",
                        null
                      )}
                      onError={(e)=>{e.target.onerror = null; e.target.src="/images/interrogacion.svg"}}
                      alt=""
                    />
                    {''}
                  </div>
                </div>
              </div>
              <div className="tags">
                {
                  <div>
                    <div className="param">
                      Etiquetas de orden:
                    </div>
                    <div className="tags-container">
                      {
                        this.state.editMode
                          ? (this.props.orderTags || []).map(tag => {
                            // debugger
                            return <Tag
                              id={tag.id}
                              color={tag.color}
                              title={tag.title}
                              type={tag.type}
                              isFull={this.state.selectedorderTags.includes(tag.id)}
                              parentBackground={'dark'}
                              className={''}
                              onClick={this.handleTagClick}
                            />
                          })
                          : (this.getData()["ordertag_order"] || []).map(tag => {
                            // let tInfo = (this.props.orderTags || []).find(t => t.id === tag.tag);
                            // if (tInfo)
                              return <Tag
                                id={tag.tag.id}
                                color={tag.tag.color}
                                title={tag.tag.title}
                                parentBackground={"dark"}
                              />
                            // return null
                          })
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {
            this.state.editMode
              ? <div className="actions">
                <Button
                  type={"secondary"}
                  text={"Cancelar"}
                  onClick={() => {}}
                />
                <Button
                  type={"primary"}
                  text={this.state.isLoading ? <Loader /> : "Guardar"}
                  onClick={() => {}}
                  disabled={false}
                />
              </div>
              : null
          }
        </div>
        <div className="tabs">
          <div className="tabs-row">
            <TabSelectorRibbon
              activeId={this.state.selectedTab}
              items={TABS}
              onChange={this.switchTab}
              selectedUnderlineColor={"#FF9145"}
            />
          </div>
          <div className="tab-content">
            {
              this.getTabContent()
            }
          </div>
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const orderData = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS");
  const requisitionData = getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS");
  return {
    companyId,
    orderData,
    requisitionData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadRequisitionData: (params, opt) =>
      dispatch(readEntity("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS", params, opt)),
    loadOrderData: (params, opt) =>
      dispatch(readEntity("COMPANIES.REQUISITIONSCONTROL.ORDERS", params, opt)),
    updatePackage: (params, opt) =>
      dispatch(partialEntity("COMPANIES.REQUISITIONSCONTROL.PACKAGES", params, opt)),
    deletePackage: (params, opt) =>
      dispatch(deleteEntity("COMPANIES.REQUISITIONSCONTROL.PACKAGES", params, opt)),
    checkPalletCode: (params, opt) =>
      dispatch(readEntity("COMPANIES.REQUISITIONSCONTROL.PALLETS.SEARCH", params, opt)),
    updatePallet: (params, opt) =>
      dispatch(partialEntity("COMPANIES.REQUISITIONSCONTROL.PALLETS", params, opt)),
  }
}

// export default OrdersControlDetailView;
export default connect (
  mapStateToProps,
  mapDispatchToProps
)(OrdersControlDetailView);