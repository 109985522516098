import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';

Modal.setAppElement('#root');

class GenericDialog extends React.Component {

  onClose = () => {
    this.props.closeAction();
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog generic-dialog"
        overlayClassName="overlay"
        className="content"
        >

        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>

        <div className='message' style={{maxHeight: 300, overflow: 'auto'}}>{this.props.message}</div>

        <div className='bottom-container'>

          <div/>

          <Button
            text={'Continuar'}
            type={'primary'}
            onClick = { () => {
              if (this.props.acceptAction) this.props.acceptAction();
            }}
          />

        </div>

      </Modal>
    );   
  }

}

export default GenericDialog;