import PropTypes from "prop-types";
import React from "react";
const iconPosition = {
  before: 'before',
  after: 'after'
}


class Button extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.props.onClick || this.props.settings.onClick;
    this.onMouseLeave = this.props.onMouseLeave || this.props.settings.onMouseLeave || (() => {});
    this.type = this.props.type || this.props.settings.type;
    this.text = this.props.text || this.props.settings.text;
    this.disabled = this.props.disabled || this.props.settings.disabled || false;
    this.className = this.props.className || this.props.settings.className;
    this.name = this.props.name || this.props.settings.name;
    this.prefix = this.props.prefix || this.props.settings.prefix;
    this.suffix = this.props.suffix || this.props.settings.suffix;


    if (props.settings.icon) {
      this.iconBefore = props.settings.icon.position === iconPosition.before;
      this.iconClassName = props.settings.icon.className || '';
      this.iconUrl = props.settings.icon.url ? props.settings.icon.url : "";
      this.icon = this.createIcon();
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.onClick !== this.props.onClick) {
      
  //   }
  // }

  handleClick = (e) => {
    if(!this.props.disabled && !this.props.settings.disabled)
      // this.onClick(e);
      this.props.onClick ? this.props.onClick(e) : this.props.settings.onClick(e);
  };

  render() {
    return (
      <button
        name={this.name}
        className={"btn ".concat(
          this.props.type || this.props.settings.type || 'primary',
          " ",
          this.props.className || this.props.settings.className || '',
          this.props.disabled || this.props.settings.disabled ? " disabled" : ""
        )}
        onClick={(e) => {e.stopPropagation(); this.handleClick(e)}}
        onMouseLeave={this.onMouseLeave}
        data-tip={this.props.dataTip}
        data-for={this.props.dataFor}
      >
        {(this.prefix || {}).element}
        {this.iconBefore ? this.icon : ""}
        {this.props.text || this.props.settings.text}
        {!this.iconBefore && this.icon ? this.icon : ""}
        {(this.suffix || {}).element}
      </button>
    );
  }

  createIcon() {
    return React.createElement("img", {
      className: "icon ".concat(this.iconClassName),
      src: this.iconUrl
    });
  }
}

export default Button;

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.shape({
    className: PropTypes.string,
    element: PropTypes.element
  }),
  suffix: PropTypes.shape({
    className: PropTypes.string,
    element: PropTypes.element
  }),

  // No utilizar el objeto settings, existe por compatibilidad
  // settings: PropTypes.shape({
  //   type: PropTypes.string,
  //   text: PropTypes.string,
  //   disabled: PropTypes.bool,
  //   className: PropTypes.string,
  //   onClick: PropTypes.func,
  //   name: PropTypes.string,
  //   icon: PropTypes.shape({
  //     url: PropTypes.string,
  //     position: PropTypes.oneOf([iconPosition.before, iconPosition.after]),
  //     className: PropTypes.string
  //   }),
  //   prefix: PropTypes.shape({
  //     className: PropTypes.string,
  //     element: PropTypes.element
  //   }),
  //   suffix: PropTypes.shape({
  //     className: PropTypes.string,
  //     element: PropTypes.element
  //   })
  // })
};

Button.defaultProps = {
  settings: {
    type: "primary",
    text: "OK",
    disabled: false,
    className: "",
    onClick: () => { },
    name: "",
    icon: undefined
  },
};
