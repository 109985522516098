import React from "react";
import SelectInput from "./select-input";

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalElements: this.props.totalElements,
      elementsPerPage: this.props.elementsPerPage || 10,
      page: 1,
      maxPages: Math.ceil(
        this.props.totalElements / this.props.elementsPerPage || 10
      )
    };
  }
  handleElementCountClick = e => {
    let elems = Number(e.target.value);
    let page = 1;
    this.setState({
      elementsPerPage: elems,
      maxPages: Math.ceil(this.state.totalElements / elems),
      page: page
    });
    this.props.reload(elems, page);
  };
  handlePageClick = e => {
    if (e.target.id) {
      let page = e.target.id.split("-")[1];
      this.setState({ page: page });
      this.props.reload(this.state.elementsPerPage, page);
    }
  };
  prevPage = () => {
    let page = this.state.page > 1 ? this.state.page - 1 : 1;
    this.setState({ page: page });
    this.props.reload(this.state.elementsPerPage, page);
  };
  nextPage = () => {
    let page =
      this.state.page < this.state.maxPages
        ? parseInt(this.state.page) + 1
        : this.state.maxPages;
    this.props.reload(this.state.elementsPerPage, page);
    this.setState({ page: page });
  };
  getPages = maxPages => {
    let pages = [];
    let firstVisiblePage = this.state.page - 4;
    firstVisiblePage = firstVisiblePage < 1 ? 1 : firstVisiblePage;
    let lastVisiblePage = firstVisiblePage + 8;
    lastVisiblePage = lastVisiblePage > maxPages ? maxPages : lastVisiblePage;
    // for (let iPage = 1; iPage <= maxPages; iPage++) {
    if(firstVisiblePage > 1){
      pages.push(
        <div
          id={"prev-pages"}
          key={"prev-page"}
          className={"extra-pages"}
          onClick={() => {}}
        >
          ...
        </div>
      );
    }
    for (let iPage = firstVisiblePage; iPage <= lastVisiblePage; iPage++) {  
      pages.push(
        <div
          id={"page-".concat(iPage)}
          key={"page-".concat(iPage)}
          className={parseInt(this.state.page) === iPage ? "selected" : ""}
          onClick={event => this.handlePageClick(event)}
        >
          {iPage}
        </div>
      );
    }
    if(lastVisiblePage < maxPages){
      pages.push(
        <div
          id={"next-pages"}
          key={"next-page"}
          className={"extra-pages"}
          onClick={() => {}}
        >
          ...
        </div>
      );
    }
    return pages;
  };
  getItemsPerPageOptions = () => {
    let updatedItems = ITEMS_PER_PAGE_OPTIONS;
    // updatedItems[3].id = this.props.totalElements;
    return updatedItems;
  };
  render() {
    return (
      <div className="paginator">
        <div className="counter">
          {(this.state.page - 1) * this.state.elementsPerPage + 1} -{" "}
          {Math.min(
            this.state.page * this.state.elementsPerPage,
            this.state.totalElements
          )}{" "}
          de {this.state.totalElements}
        </div>
        <div className="pages">
          <div onClick={this.prevPage}>&lt;</div>
          {this.getPages(this.state.maxPages)}
          <div onClick={this.nextPage}>&gt;</div>
        </div>
        <div className="number-elements">
          Mostrar:
          <SelectInput
            label=""
            name="elements"
            value={this.state.elementsPerPage}
            items={this.getItemsPerPageOptions()}
            onChange={this.handleElementCountClick}
          />
        </div>
      </div>
    );
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.totalElements !== prevState.totalElements) {
      if (nextProps.resetPaginator) {
        nextProps.onReset();
        return {
          page: 1,
          elementsPerPage: nextProps.elementsPerPage || 10,
          totalElements: nextProps.totalElements,
          maxPages: Math.ceil(
            nextProps.totalElements / prevState.elementsPerPage
          )
        };
      }
      return {
        totalElements: nextProps.totalElements,
        maxPages: Math.ceil(nextProps.totalElements / prevState.elementsPerPage)
      };
    }
    if (nextProps.resetPaginator) {
      nextProps.onReset();

      return {
        page: 1,
        elementsPerPage: nextProps.elementsPerPage || 10
      };
    }
    return null;
  }
}

export default Paginator;

Paginator.defaultProps = {
  totalElements: 0,
  onReset: () => {},
  reload: () => {
    console.log("Not implemented yet!");
  }
};

const ITEMS_PER_PAGE_OPTIONS = [
  {
    id: 10,
    description: "10"
  },
  {
    id: 25,
    description: "25"
  }
  // {
  //   id: 50,
  //   description: "50"
  // },
  // {
  //   id: 100,
  //   description: "100"
  // },
  // {
  //   id: 1,
  //   description: "Todos"
  // }
];
