// @flow
import React from "react";
import { NORMATIVITY_VALIDITY_ITEMS } from "./types";
import Tag from "../../shared/controls/tag";
import AssembleModalContent from "./assemble-edit-modal/assemble-modal-content";
import {
  ModalContentParams,
  Assemble,
  AssembleDetail,
  Resource,
  Timeline,
} from "./types";
import {
  Unitary,
  Truck,
  Equipment,
  License,
  Dolly,
} from "../../shared/icons/assemble";
import { AssembleAvatar } from "./assemble-avatar";
import ExceedPlus from "../../shared/controls/exceed-plus";
import AssembleTripCard from "../assemble-shipment/assemble-trip-card";
import Flag from "../../shared/icons/traffic/flag";
import Marker from "../../shared/icons/traffic/marker";
import ReactTooltip from "react-tooltip";
import Store from "../../shared/icons/traffic/store";
import { getLastSession } from "../../shared/utils";
import Availability from "../../shared/icons/traffic/availability";
import ManualAvailability from "../../shared/icons/traffic/manual-availability";

export const getAssembles = (
  assemblies: Array<any>,
  from: number,
  to: number,
  vehicleTypes: any,
  motives: any,
  range
): Array<Assemble> => {
  // debugger;
  if (isNaN(from) && isNaN(to)) return [];
  const result = assemblies
    .map((assemble) => {
      const lines = unwrapLines(assemble);
      let resources = lines
        .map((line) => getResourcesFromLine(line, vehicleTypes))
        .flat();
      let detail = lines
        .map((line) => getDetailFromLine(line, from, to, assemble.id))
        .filter((detail) =>
          detail.timeLine.some((timeline) => timeline.visible)
        );
      detail = getDetailTimelineCaptionsFromResources(
        detail,
        resources,
        motives
      );
      const layerItems = getTripsFromAssemble(assemble.trips, from, to/* , assemble.availability */);
      const availability = assemble.availability 
        ? {
          ...assemble.availability,
          position: `${getDetailTimeLinePercent(
            from,
            Math.max(Date.parse(assemble.availability.availability), from),
            from,
            to
          )}%`,
          item: <React.Fragment>
          <Availability data-tip data-for={`availability-${assemble.id}`}/>
          <ReactTooltip type="light" effect="solid" id={`availability-${assemble.id}`}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{fontWeight: "bold"}}>Disponible</div>
              <div>{(new Date(assemble.availability.availability)).toLocaleString("en-GB")}</div>
              <div>{assemble.availability.availability_facility.name.concat(" - ", assemble.availability.availability_facility.code, " - ", assemble.availability.availability_facility.alias)}</div>
              <div>Actualizado el: {(new Date(assemble.availability.inserted)).toLocaleString("en-GB")}</div>
            </div>
          </ReactTooltip>
        </React.Fragment>

        }
        : null;
      const manualAvailability = assemble.manual_availability 
        ? {
          ...assemble.manual_availability,
          position: `${getDetailTimeLinePercent(
            from,
            Math.max(Date.parse(assemble.manual_availability.availability), from),
            from,
            to
          )}%`,
          item: <React.Fragment>
          <ManualAvailability data-tip data-for={`manual-availability-${assemble.id}`}/>
          <ReactTooltip type="light" effect="solid" id={`manual-availability-${assemble.id}`}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{fontWeight: "bold"}}>Disponible</div>
              <div>{(new Date(assemble.manual_availability.availability)).toLocaleString("en-GB")}</div>
              <div>{assemble.manual_availability.availability_facility.name.concat(" - ", assemble.manual_availability.availability_facility.code, " - ", assemble.manual_availability.availability_facility.alias)}</div>
              <div>Actualizado el: {(new Date(assemble.manual_availability.inserted)).toLocaleString("en-GB")}</div>
            </div>
          </ReactTooltip>
        </React.Fragment>

        }
        : null;
      return {
        id: assemble.id,
        detail,
        resources,
        is_vinculated: assemble.is_vinculated,
        timeLine: getAssembleTimeline(detail, from, to),
        layerItems,
        contract: assemble.contracts && assemble.contracts[0],
        availability,
        manualAvailability
      };
    })
    .filter((r) => r.detail.length > 0);
  return result;
};

// export const getTripsFromAssemble_old = (trips, from, to) => {
//   if (!trips) return [];

//   const realTrips = trips
//     .filter((t) => t.trip_orders.length > 0)
//     .map((w) => ({
//       ...w,
//       number: w.signature || w.code,
//       tripBegin: Math.min(
//         ...w.trip_orders.map((t) => Date.parse(t.appointment_loading_date))
//       ),
//       tripEnd: Math.max(
//         ...w.trip_orders.map(
//           (t) =>
//             Date.parse(t.appointment_unload_exit) ||
//             Date.parse(t.appointment_unload_date) ||
//             Date.parse(t.meta_unload_date) ||
//             Date.parse(t.eta_unload_date) ||
//             0
//         )
//       ),
//     }))
//     .map((w) => ({
//       ...w,
//       position: `${getDetailTimeLinePercent(
//         from,
//         Math.max(w.tripBegin, from),
//         from,
//         to
//       )}%`,
//       width: `${getDetailTimeLinePercent(
//         Math.max(w.tripBegin, from),
//         Math.min(w.tripEnd, to),
//         from,
//         to
//       )}%`,
//     }))
//     .map((w) => ({
//       ...w,
//       item: (
//         <AssembleTripCard
//           style={{ width: "100%", backgroundColor: "rgba(50, 65, 94,0.5)" }}
//           content={
//             <div style={{ display: "flex", justifyContent: "space-around" }}>
//               <span>{`viaje: ${w.number}`}</span>
//             </div>
//           }
//           lines={w.trip_orders.map((t) => ({
//             data: {
//               orderId: t.id,
//               fromAvatarId: t.from_facility.avatar.id,
//               toAvatarId: t.to_facility.avatar.id,
//             },
//             tripId: w.id,
//             items: [
//               {
//                 date: t.appointment_loading_date,
//                 elementType: "flag",
//                 content: "Inicio cita de carga",
//               },
//               {
//                 date: t.appointment_loading_exit,
//                 elementType: "flag",
//                 content: "Fin cita de carga",
//               },
//               {
//                 date: t.appointment_unload_date,
//                 elementType: "flag",
//                 content: "Inicio cita descarga",
//               },
//               {
//                 date: t.appointment_unload_exit,
//                 elementType: "flagInverted",
//                 content: "Fin cita descarga",
//               },
//               {
//                 date:
//                   (Date.parse(t.appointment_unload_exit) -
//                     Date.parse(t.appointment_unload_date)) /
//                   2,
//                 elementType: "store",
//                 content: "",
//               },

//               // {
//               //   date: t.meta_unload_date,
//               //   elementType: "marker",
//               //   content: "Inicio cita de carga",
//               // },
//               // {
//               //   date: t.meta_loading_date,
//               //   elementType: "marker",
//               //   content: "Inicio cita de carga",
//               // },
//               ...t.events
//                 .filter((e) => e.type === 3)
//                 .map((e) => ({
//                   ...e,
//                   date: e.start_date,
//                   elementType: "text",
//                   content: e.id === 2 ? "Inicio de viaje" : "",
//                 })),
//             ].map((m, i) => ({
//               caption: getTripEventIcon({
//                 ...m,
//                 index: i,
//                 order: { id: t.id_container },
//               }),
//               position: `${getDetailTimeLinePercent(
//                 w.tripBegin,
//                 Date.parse(new Date(m.date)),
//                 w.tripBegin,
//                 w.tripEnd
//               )}%`,
//             })),
//             segments: [
//               ...t.events
//                 .filter((f) => f.type === 1)
//                 .map((m) => ({
//                   start_date: m.start_date,
//                   end_date: m.end_date,
//                   position: `${getDetailTimeLinePercent(
//                     w.tripBegin,
//                     Date.parse(new Date(m.start_date)),
//                     w.tripBegin,
//                     w.tripEnd
//                   )}%`,
//                   width: `${Math.abs(
//                     getDetailTimeLinePercentUnconditional(
//                       Date.parse(m.start_date),
//                       Date.parse(m.end_date),
//                       // Math.max(Date.parse(m.start_date), w.tripBegin),
//                       // Math.min(Date.parse(m.end_date), w.tripEnd),
//                       w.tripBegin,
//                       w.tripEnd,
//                       true,
//                       "wid"
//                     )
//                   )}%`,
//                   id: m.id,
//                 })),
//             ],
//             overPosition: [
//               ...t.events
//                 .filter((f) => f.type === 2)
//                 .map((m) => ({
//                   // caption: getTripEventIcon({ ...m }),
//                   width: `${getDetailTimeLinePercent(
//                     Math.max(Date.parse(m.start_date), w.tripBegin),
//                     Math.min(Date.parse(m.end_date), w.tripEnd),
//                     w.tripBegin,
//                     w.tripEnd
//                   )}%`,
//                   position: `${getDetailTimeLinePercent(
//                     w.tripBegin,
//                     Date.parse(new Date(m.start_date)),
//                     w.tripBegin,
//                     w.tripEnd
//                   )}%`,
//                   style: {
//                     height: m.height,
//                     opacity: m.opacity,
//                     backgroundColor: m.backgroundColor,
//                     pattern: m.pattern,
//                   },
//                 })),
//             ],
//           }))}
//         />
//       ),
//     }));
//   // console.log(realTrips);
//   return realTrips;
// };
export const getTripsFromAssemble = (trips, from, to/* , availability */) => {
  if (!trips) return [];
  let mt = 0;
  const statusItems = [
    { id: 1, color: "#495C80", fullCard: true },
    { id: 2, color: "#495C80", fullCard: true },
    { id: 3, fullCard: true },
    { id: 4, fullCard: true },
    { id: 5, color: "#05C985" },
    { id: 6, color: "#336741" },
  ];
  const realTrips = trips
    .filter((t) => t.trip_orders.length > 0)
    .map((w) => ({
      ...w,
      number: w.signature || w.code,
      tripBegin: Math.max(
        Math.min(
          ...w.trip_orders
            .map((t) => {
              let arr = [Date.parse(t.appointment_loading_date)];
              let arr2 = (t.order_containers || []).map(c => {
                  let events = (c.events || [])
                    .filter(e => e.type === 1 || e.type === 3)
                    .map(e => Date.parse(e.start_date))
                  return events;
                }).flat()
                return arr.concat(arr2)
            })
            .flat()
            .filter((x) => x)
        ),
        from
      ),
      tripEnd: Math.min(
        Math.max(
          ...w.trip_orders.map((t) => {
            return Math.max(
              Date.parse(t.appointment_loading_date) || 0,
              Date.parse(t.appointment_loading_exit) || 0,
              Date.parse(t.appointment_unload_date) || 0,
              Date.parse(t.appointment_unload_exit) || 0,
              Date.parse(t.estimated_loading_date) || 0,
              Date.parse(t.estimated_unload_date) || 0,
              Date.parse(t.eta_loading_date) || 0,
              Date.parse(t.eta_unload_date) || 0,
              Date.parse(t.meta_loading_date) || 0,
              Date.parse(t.meta_unload_date) || 0,
              ...(t.order_containers || []).map(c => {
                let events = (c.events || [])
                  .filter(e => e.type === 1 || e.type === 3)
                  .map(e => Date.parse(e.end_date))
                return events;
              }).flat()
            );
          })
        ),
        to
      ),
      baseline: getTripBaseLine(w, from, to/* , availability */),
      containers: getTripContainers(w.trip_orders),
    }))

    .map((w) => ({
      ...w,
      position: `${getDetailTimeLinePercent(
        from,
        Math.max(w.tripBegin, from),
        from,
        to
      )}%`,
      width: `${getDetailTimeLinePercent(
        Math.max(w.tripBegin, from),
        Math.min(w.tripEnd, to),
        from,
        to
      )}%`,
    }))
    .sort((a, b) => Date.parse(a.tripBegin) - Date.parse(b.tripBegin))
    .map((w) => ({
      ...w,
      index: mt++,
      item: (
        <AssembleTripCard
          // style={{ marginTop: mt++ * 15 }}
          status={statusItems.find((f) => f.id === w.status.id)}
          content={
            <React.Fragment>
              <span>{`viaje: ${w.number}`}</span>
              {statusItems
                .filter((f) => f.fullCard)
                .find((f) => f.id === w.status.id) && (
                <span
                  style={{
                    width: "fit-content",
                    backgroundColor: statusItems.find(
                      (f) => f.id === w.status.id
                    ).color,
                    color: "white",
                    borderRadius: "3px",
                  }}
                >{`${w.status.description}`}</span>
              )}
            </React.Fragment>
            // </div>
          }
          lines={w.containers.map((c) => { return ({
            data: {
              // orderId: t.id,
              // fromAvatarId: t.from_facility.avatar.id,
              // toAvatarId: t.to_facility.avatar.id,
            },
            tripId: w.id,
            items: w.baseline.items
              .concat(
                ...c.events
                  .filter((e) => e.type === 3)
                  .filter(
                    (e) =>
                      Date.parse(e.start_date) >= from &&
                      Date.parse(e.start_date) <= to
                  )
                  .map((e) => ({
                    ...e,
                    date: e.start_date,
                    // elementType: "text",
                    content: (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {e.lines
                          .map((l) => {
                            let item = '';
                            switch (l.type) {
                              case 1:
                                item = <span style={{ margin: "auto" }}>
                                  {l.description}
                                </span>
                                break;
                              case 2:
                                item = <hr width='100%'></hr>
                                break;
                              default:
                                item = '';
                            }
                            return item
                        })}
                      </div>
                    ),
                  }))
              )
              .map((m, i) => ({
                caption: getTripEventIcon({
                  ...m,
                  index: i,
                  order: { id: c.id_container },
                }),
                position: `${getDetailTimeLinePercentUnconditional(
                  w.tripBegin,
                  Date.parse(new Date(m.date)),
                  w.tripBegin,
                  w.tripEnd
                )}%`,
                verticalPosition: getIconVerticalPosition({
                  ...m,
                })
              })),

            segments: [
              ...c.events
                .filter((f) => f.type === 1)

                .filter(
                  // todos los anteriores
                  (f) => !(Date.parse(f.end_date) < from)
                )
                .filter(
                  // todos los posteriores
                  (f) => !(Date.parse(f.start_date) > to)
                )
                .map((m) => ({
                  ...m,
                  start_date: new Date(
                    Math.max(Date.parse(m.start_date), from)
                  ),
                  end_date: new Date(Math.min(Date.parse(m.end_date), to)),
                }))
                .filter(
                  (f) =>
                    Date.parse(f.start_date) >= from &&
                    Date.parse(f.end_date) <= to
                )

                .map((m) => {
                  let start = Date.parse(new Date(m.start_date));
                  // debugger;
                  if(start < w.tripBegin && start >= (w.tripBegin - 1000)) //Ajustar margen de 1 segundo
                    start = w.tripBegin;
                  return {
                  ...m,
                  start,
                  tripBegin: w.tripBegin,
                  startParsed: m.start_date,
                  tripBeginParsed: new Date(w.tripBegin),
                  position: `${getDetailTimeLinePercent(
                    w.tripBegin,
                    start,
                    w.tripBegin,
                    w.tripEnd
                  )}%`,
                  width: `${ start < w.tripBegin ? 0 : Math.abs(
                    getDetailTimeLinePercentUnconditional(
                      Date.parse(m.start_date),
                      Date.parse(m.end_date),
                      // Math.max(Date.parse(m.start_date), w.tripBegin),
                      // Math.min(Date.parse(m.end_date), w.tripEnd),
                      w.tripBegin,
                      w.tripEnd,
                      true,
                      "wid"
                    )
                  )}%`,
                  id: m.id,
                }})
                .concat({
                  start_date: w.tripBegin,
                  end_date: w.tripEnd,
                  id: 0,
                  width: "100%",
                  position: "0%",
                }),
            ],
            overPosition: [
              ...c.events
                .filter((f) => f.type === 2)
                .map((m) => ({
                  // caption: getTripEventIcon({ ...m }),
                  width: `${getDetailTimeLinePercent(
                    Math.max(Date.parse(m.start_date), w.tripBegin),
                    Math.min(Date.parse(m.end_date), w.tripEnd),
                    w.tripBegin,
                    w.tripEnd
                  )}%`,
                  position: `${getDetailTimeLinePercent(
                    w.tripBegin,
                    Date.parse(new Date(m.start_date)),
                    w.tripBegin,
                    w.tripEnd
                  )}%`,
                  style: {
                    height: m.height,
                    opacity: m.opacity,
                    backgroundColor: m.backgroundColor,
                    pattern: m.pattern,
                  },
                })),
            ],
          })})}
        />
      ),
    }));

  return realTrips;
};

const getTripContainers = (tripOrders) => {
  const result = [];
  tripOrders.forEach((t) => {
    t.order_containers.forEach((c, i) => {
      if( result[i]){
        if(result[i].events){
          result[i].events = result[i].events.concat(c.events || []);
          let now, arrivalTimestamp, entryTimestamp, exitTimestamp, stayDuration, appointmentBeginTimestamp, appointmentEndTimestamp, appointmentDuration;
          //ORIGEN
          let arrival = c.events.find(e => e.id === 202 && e.type === 1);
          let entry = c.events.find(e => e.id === 203 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_loading_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if (entry) { //Si no tiene llegada pero si entrada
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_loading_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
          //DESTINO
          arrival = c.events.find(e => e.id === 206 && e.type === 1);
          entry = c.events.find(e => e.id === 207 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_unload_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if(entry){
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_unload_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
        } else {
          result[i].events = c.events || [];
          let now, arrivalTimestamp, entryTimestamp, exitTimestamp, stayDuration, appointmentBeginTimestamp, appointmentEndTimestamp, appointmentDuration;
          //ORIGEN
          let arrival = c.events.find(e => e.id === 202 && e.type === 1);
          let entry = c.events.find(e => e.id === 203 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_loading_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if (entry) { //Si no tiene llegada pero si entrada
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_loading_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
          //DESTINO
          arrival = c.events.find(e => e.id === 206 && e.type === 1);
          entry = c.events.find(e => e.id === 207 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_unload_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if(entry){
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_unload_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
        }
      } else {
        result[i] = c;
        if(c.events.length){
          let now, arrivalTimestamp, entryTimestamp, exitTimestamp, stayDuration, appointmentBeginTimestamp, appointmentEndTimestamp, appointmentDuration;
          //ORIGEN
          let arrival = c.events.find(e => e.id === 202 && e.type === 1);
          let entry = c.events.find(e => e.id === 203 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_loading_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if (entry) { //Si no tiene llegada pero si entrada
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_loading_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_loading_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_loading_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
          //DESTINO
          arrival = c.events.find(e => e.id === 206 && e.type === 1);
          entry = c.events.find(e => e.id === 207 && e.type === 1);
          if(arrival){ //Si tiene evento de llegada a origen
            arrivalTimestamp = (new Date(arrival.start_date)).getTime();
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            if(arrivalTimestamp < appointmentBeginTimestamp){ //Si llego antes de la cita
              now = (new Date()).getTime();
              if(now > appointmentBeginTimestamp){  //Si ya paso la hora de la cita
                result[i].events.push({ //Barra de retraso en entrada
                  start_date: t.appointment_unload_date,
                  end_date: entry ? entry.start_date : (new Date()),
                  id: 2,
                  type: 1
                })
                if(entry){
                  entryTimestamp = (new Date(entry.start_date)).getTime();
                  exitTimestamp = (new Date(entry.end_date)).getTime();
                  stayDuration = exitTimestamp - entryTimestamp;
                  appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
                  appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
                  if(stayDuration > appointmentDuration){ //Si excedio el tiempo programado de atencion
                    result[i].events.push({
                      start_date: new Date(entryTimestamp + appointmentDuration),
                      end_date: entry.end_date,
                      id: 2,
                      owner: entry.owner,
                      type: 1
                    })
                  }
                }
              }
            }
          } else if(entry){
            entryTimestamp = (new Date(entry.start_date)).getTime();
            exitTimestamp = (new Date(entry.end_date)).getTime();
            stayDuration = exitTimestamp - entryTimestamp;
            appointmentBeginTimestamp = (new Date(t.appointment_unload_date)).getTime();
            appointmentEndTimestamp = (new Date(t.appointment_unload_exit)).getTime();
            appointmentDuration = appointmentEndTimestamp - appointmentBeginTimestamp;
            if(entryTimestamp > appointmentBeginTimestamp){
              result[i].events.push({
                start_date: t.appointment_unload_date,
                end_date: entry.start_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
            if(stayDuration > appointmentDuration){
              result[i].events.push({
                start_date: new Date(entryTimestamp + appointmentDuration),
                end_date: entry.end_date,
                id: 2,
                owner: entry.owner,
                type: 1
              })
            }
          }
        }
      }
      // result[i]
      //   ? result[i].events
      //     ? (result[i].events = result[i].events.concat(c.events || []))
      //     : (result[i].events = c.events || [])
      //   : (result[i] = c)
    });
  });
  // debugger;
  return result;
};

const getTripBaseLine = (trip, from, to/* , availability */) => {
  const result = { data: [], items: [] };
  // debugger;
  trip.trip_orders.forEach((t, index) => {
    result.data[index] = {
      orderId: t.id,
      fromAvatarId: t.from_facility.avatar.id,
      toAvatarId: t.to_facility.avatar.id,
    };
    result.tripId = trip.id;
    result.items = result.items.concat([
      {
        date: t.appointment_loading_date,
        elementType: "flag",
        content: <>
            <div style={{ textAlign: "center" }}>
              <div>{getLastSession(t.appointment_loading_date, 'TRAFFIC_FORMAT')}</div>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Inicio cita de carga</div>
              <div>{t.from_facility?.name || '-'}</div>
            </div>
          </>,
        mini: <>
            <div style={{ textAlign: "center" }}>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Inicio cita de carga</div>
              <div>{t.from_facility?.name || '-'}</div>
            </div>
          </>
      },
      {
        date: t.appointment_loading_exit,
        elementType: "flagInverted",
        content: <>
            <div style={{ textAlign: "center" }}>
              <div>{getLastSession(t.appointment_loading_exit, 'TRAFFIC_FORMAT')}</div>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Fin cita de carga</div>
              <div>{t.from_facility?.name || '-'}</div>
            </div>
          </>,
        mini: <>
            <div style={{ textAlign: "center" }}>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Fin cita de carga</div>
              <div>{t.from_facility?.name || '-'}</div>
            </div>
          </>,
      },
      {
        date: t.appointment_unload_date,
        elementType: "flag",
        content: <>
            <div style={{ textAlign: "center" }}>
              <div>{getLastSession(t.appointment_unload_date, 'TRAFFIC_FORMAT')}</div>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Inicio cita descarga</div>
              <div>{t.to_facility?.name || '-'}</div>
            </div>
          </>,
        mini: <>
            <div style={{ textAlign: "center" }}>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Inicio cita descarga</div>
              <div>{t.to_facility?.name || '-'}</div>
            </div>
          </>,
      },
      {
        date: t.appointment_unload_exit,
        elementType: "flagInverted",
        content: <>
            <div style={{ textAlign: "center" }}>
              <div>{getLastSession(t.appointment_unload_exit, 'TRAFFIC_FORMAT')}</div>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Fin cita descarga</div>
              <div>{t.to_facility?.name || '-'}</div>
            </div>
          </>,
        mini: <>
            <div style={{ textAlign: "center" }}>
              <hr width='100%'></hr>
              <div>{"Orden ".concat(t.code)}</div>
              <div>Fin cita descarga</div>
              <div>{t.to_facility?.name || '-'}</div>
            </div>
          </>,
      },
      {
        date: new Date(
          Date.parse(t.appointment_loading_date) +
            (Date.parse(t.appointment_loading_exit) -
              Date.parse(t.appointment_loading_date)) /
              2
        ),
        elementType: "store",
        content: "",
      },
      {
        date: new Date(
          Date.parse(t.appointment_unload_date) +
            (Date.parse(t.appointment_unload_exit) -
              Date.parse(t.appointment_unload_date)) /
              2
        ),
        elementType: "store",
        content: "",
      },
    ]);

    // if(availability) {
    //   result.items.push({
    //     date: availability,
    //     elementType: "text",
    //     content: <div style={{ textAlign: "center" }}>Disponibilidad</div>,
    //   })
    // }

    (t.order_containers || []).forEach(oC => {
      if(oC.eta){
        result.items.push({
          date: oC.eta,
          elementType: "text",
          content: <div style={{ textAlign: "center" }}>
            <div>{getLastSession(oC.eta, 'TRAFFIC_FORMAT')}</div>
            <hr width='100%'></hr>
            <div>ETA</div>
          </div>,
        })
      }
    })
  });
  // console.log(result.items);
  result.items = result.items.filter(
    (r) => Date.parse(r.date) >= from && Date.parse(r.date) <= to
  );

  let sorted_arr = result.items.slice().sort((a, b) => {
    // a.date < b.date
    if(typeof a.date !== "string"){
      return 1;
    }
    if(typeof b.date !== "string"){
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    if (a.date < b.date) {
      return -1;
    }
    return 0;
  });
  // debugger;
  let mergedItems = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if(sorted_arr[i]){
      if (sorted_arr[i + 1].date === sorted_arr[i].date) {
        mergedItems.push({
          ...sorted_arr[i],
          content: <>
            {sorted_arr[i].content}
            {sorted_arr[i+1].mini || sorted_arr[i].content}
          </>
        });
        sorted_arr[i+1] = null;
      } else {
        mergedItems.push(sorted_arr[i]);
      }
    }
  }
  // debugger;
  // return results;
  result.items = mergedItems;
  return result;
};

const getIconVerticalPosition = ({ elementType }) => {
  // m.elementType === "text" ? "12px" : "0"
  switch (elementType) {
    case "store":
    case "flag":
    case "flagInverted":
    case "marker":
      return "0px"
    case "text":
    default:
      return "12px";
  }
}

const getTripEventIcon = ({ elementType, content, index, order }) => {
  // console.log(elementType, content, index, order, order.id);
  var icon = undefined;
  const identificator = `${order.id}-${index}`;
  switch (elementType) {
    case "store":
      icon = <Store width="12px" height="12px" fill="#AFC8FF" />;
      break;
    case "flag":
    case "flagInverted":
      icon = (
        <Flag
          transform={elementType === "flagInverted" ? "scale(-1,1)" : "none"}
          style={{
            marginLeft: elementType === "flagInverted" ? "-11px" : "none",
          }}
          width="11px"
          height="11px"
          stroke="red"
          fill="red"
          data-tip
          data-for={identificator}
        />
      );
      break;
    case "marker":
      icon = (
        <Marker
          width="11px"
          height="11px"
          fill="red"
          data-tip
          data-for={identificator}
        />
      );
      break;
    case "text":
      icon = (
        <svg
          width="11px"
          height="17px"
          fill="red"
          data-tip
          data-for={identificator}
        >
          <text x="0" y="15" fill="red">
            {"|"}
          </text>
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width="11px"
          height="17px"
          fill="red"
          data-tip
          data-for={identificator}
        >
          <text x="0" y="15" fill="red">
            {"|"}
          </text>
        </svg>
      );
  }
  return (
    <React.Fragment>
      {icon}
      <ReactTooltip type="light" effect="solid" id={`${order.id}-${index}`}>
        {content}
      </ReactTooltip>
    </React.Fragment>
  );
};

const getDetailTimelineCaptionsFromResources = (
  details,
  resources,
  motives
) => {
  return details.map((d) => ({
    ...d,
    timeLine: d.timeLine?.map((tl) => ({
      ...tl,
      caption: getDetailTimeLineCaption(
        resources.find((r) => r.id === tl.resourceId),
        tl,
        motives
      ),
    })),
  }));
};

const getAssembleTimeline = (
  details: AssembleDetail,
  from: number,
  to: number
): Array<Timeline> => {
  let flatDetailLine = flattenMasterTimeline(details).sort(
    (a: AssembleDetail, b: AssembleDetail) => a.start - b.start
  );
  // console.log(flatDetailLine);
  return getAssembleFlatTimeLine(from, to, [...flatDetailLine]);
};

const getResourcesFromLine = (line, vehicleTypes): Array<Resource> => {
  return line.map((resource) => ({
    id: resource.fleet_data_id || resource.fleet_operator_id,
    type: resource.type || 3,
    shortDescription: resource.type
      ? `${resource.economic_number}`
      : `${resource.first_name} ${resource.last_name}`,
    longDescription: resource.type
      ? `${resource.economic_number}/${resource.plates}`
      : `${resource.first_name} ${resource.last_name}`,
    // avatar: element,
    isDolly: resource.equipment_type === 7 ? true : false,
    image: resource.profile_image,
    logId: resource.assembly_log_id,
    icon: vehicleTypes?.find((vt) =>
      vt.classes.find((cl) => cl.id === resource.class)
    )?.icon,
    abbreviation: vehicleTypes?.find((vt) =>
      vt.classes.find((cl) => cl.id === resource.class)
    )?.abbreviation,
    // fleetItemId: number,
    normativityStatusId: resource.status,
    classification: resource.licence_type || resource.class,
    item: resource,
    assembled: resource.assembled,
  }));
};

const getDetailFromLine = (line, from, to, assembleId): AssembleDetail => {
  return {
    lineId: line[0].in_line || line[0].assembly_substitute,
    type: line[0].type || 3,
    timeLine: getDetailTimeLine(line, from, to, assembleId),
  };
};

const getDetailTimeLine = (line, from, to, assembleId) => {
  const orderedData = line
    .map((r) => ({
      ...r,
      scheduled: Date.parse(r.scheduledate),
      unscheduled: Date.parse(r.modified),
    }))
    .sort((a, b) => a.scheduled - b.scheduled);

  const result = [];
  if (orderedData[0].scheduled > from) result.push({ from, visible: false });

  orderedData.forEach((r) => {
    if (r.assembled === false && r.unscheduled < from && r.scheduled < from) {
      result.push({ from, visible: false });
    } else if (r.assembled === false && r.unscheduled > r.scheduled) {
      result.push({ ...r, from: r.scheduled, visible: true, available: true });
      result.push({ from: r.unscheduled, visible: false });
    } else {
      result.push({
        ...r,
        assembled: true,
        from: r.scheduled,
        visible: true,
        available: true,
      });
    }

    if (r.exceptions.length > 0) {
      r.exceptions
        .map((e) => ({
          ...e,
          scheduled: Math.min(to, Math.max(from, Date.parse(e.exception_from))),
          unscheduled: Math.max(from, Math.min(to, Date.parse(e.exception_to))),
        }))
        .filter((e) => e.scheduled !== e.unscheduled)
        .sort((a, b) => a.scheduled - b.scheduled)
        .map((a, index, arr) => {
          if (arr[index + 1]) {
            return {
              ...a,
              scheduled: a.scheduled,
              unscheduled:
                a.unscheduled > arr[index + 1].scheduled
                  ? arr[index + 1].scheduled
                  : a.unscheduled,
            };
          } else {
            return {
              ...a,
              scheduled: a.scheduled,
              unscheduled: a.unscheduled,
            };
          }
        })
        .filter(a => a.scheduled !== a.unscheduled && (a.scheduled < to && a.unscheduled > from))
        .forEach((e) => {
          result.push({
            ...e,
            from: e.scheduled,
            available: false,
            visible: true,
            motive: e.motive,
            type: r.type || 3,
          });
          result.push({...r, from: e.unscheduled, visible: true, available: true });
        });
    }
  });

  return result
    .map((resource, index, source) => ({
      // id: resource.fleet_data_id || resource.fleet_operator_id,
      // itemId: resource.fleet_data_id || resource.fleet_operator_id,
      // lineId: resource.in_line,
      // type: resource.type || 3,
      // logId: resource.assembly_log_id,
      available: resource.available,
      // caption: getDetailTimeLineCaption(resource),
      visible: resource.visible,
      motiveId: resource.motive,
      width: `${getDetailTimeLinePercent(
        resource.from,
        (source[index + 1] || {}).from || to,
        from,
        to
      )}%`,
      to: (source[index + 1] || {}).from || to,
      from: resource.from,
      assembled: resource.assembled || false,
      resourceId: resource.fleet_data_id || resource.fleet_operator_id,
      assembleId: assembleId,
      logId: resource.assembly_log_id,
      type: resource.type || 3,
      isDolly: resource.equipment_type === 7 ? true : false,
      lineId: resource.in_line,
    }))
    .filter((r) => r.width !== "0%");
};

const flattenMasterTimeline = (details: Array<AssembleDetail>) => {
  return details
    .map((detail: AssembleDetail) =>
      detail.timeLine.map((timeline) => timeline)
    )
    .flat()
    .sort((a, b) => a.from - b.from)
    .filter((f) => f.visible);
};

// const mergeTimelines = (item, index, arr) => {
//   arr.forEach((a) => {
//     if (a.from >= item.from && a.from <= item.to) {
//       item.to = Math.max(a.to, item.to);
//       a.to = Math.max(a.to, item.to);
//     }
//     if (a.to <= item.to && a.to >= item.from) {
//       item.from = Math.min(a.from, item.from);
//       a.from = Math.min(a.from, item.from);
//     }
//     if (a.from === item.from && a.to === item.to && arr.length > 1) arr.pop(a);
//   });
// };

const getAssembleFlatTimeLine = (from, to, timelines) => {
  // if(timelines.some(t => t.assembleId === 1083)){
  //   debugger;
  // }
  const FROM = 0 + from;
  const TO = 0 + to;
  let unavailables = timelines.filter((t) => !t.available);
  let availables = timelines.filter((t) => t.available);



  availables = availables.sort((a, b) => a.from - b.from);
  // availables = availables.map((a, index, arr) => {
  //   if (arr[index + 1]) {
  //     return {
  //       available: true,
  //       start: a.from,
  //       end:
  //         a.to > arr[index + 1].from
  //           ? arr[index + 1].from
  //           : a.to,
  //     };
  //   } else {
  //     return {
  //       available: true,
  //       start: a.from,
  //       end: a.to,
  //     };
  //   }
  // }).filter(a => a.start !== a.end);
  let availableSpaceStart = (availables[0] || {from: from}).from;

  unavailables = unavailables.sort((a, b) => a.from - b.from);
  unavailables = unavailables.map((a, index, arr) => {
    if (arr[index + 1]) {
      return {
        available: false,
        start: a.from,
        end:
          a.to > arr[index + 1].from
            ? arr[index + 1].from
            : a.to,
      };
    } else {
      return {
        available: false,
        start: a.from,
        end: a.to,
      };
    }
  }).filter(a => a.start !== a.end && (a.start < TO && a.end > FROM));


  let timeLines = [];
  if (availableSpaceStart > FROM) {
    let firstTimeline = {
      available: true,
      caption: "",
      id: "",
      color: "#fff",
      borderColor: "#fff",
      visible: false,
      begin: FROM,
      end: availableSpaceStart,
      // width: this.getLineWidth(from, start, length),
      width: `${getDetailTimeLinePercent(FROM, availableSpaceStart, FROM, TO)}%`
    };
    timeLines.push(firstTimeline);
  }
  // let prevPeriodEnd = 0 + availableSpaceStart;
  if(unavailables.length){
    unavailables.forEach(e => {
      timeLines.push({
        available: true,
        caption: '',
        position: "flex-end",
        color: "#305473",
        borderColor: "#54b8f8",
        id: '',
        visible: true,
        begin: availableSpaceStart,
        end: e.start,
        // width: this.getLineWidth(prevPeriodEnd, e.start, length)
        width: `${getDetailTimeLinePercent(availableSpaceStart, e.start, FROM, TO)}%`
      });
      timeLines.push({
        available: false,
        caption: 'Faltan recursos',
        id: '',
        visible: true,
        begin: e.start,
        end: Math.min(e.end, TO),
        // width: this.getLineWidth(e.start, Math.min(e.end, assembly.end), length)
        width: `${getDetailTimeLinePercent(e.start, Math.min(e.end, TO), FROM, TO)}%`
      });
      availableSpaceStart = Math.min(e.end, TO);
    })
    timeLines.push({
      available: true,
      caption: '',
      id: '',
      visible: true,
      begin: availableSpaceStart,
      end: TO,
      // width: this.getLineWidth(prevPeriodEnd,  assembly.end, length)
      width: `${getDetailTimeLinePercent(availableSpaceStart, TO, FROM, TO)}%`
    })
  } else {
    timeLines.push({
      available: true,
      caption: "",
      position: "flex-end",
      id: "",
      color: "#305473",
      borderColor: "#54b8f8",
      visible: true,
      // width: this.getLineWidth(slotStart, slotEnd, length),
      width: `${getDetailTimeLinePercent(availableSpaceStart, TO, FROM, TO)}%`
    });
  }

  // if(timelines.some(t => t.assembleId === 1046)){
  //   console.log("ORIGTIMELINES", timelines);
  //   console.log("exceptions", unavailables);
  //   console.log("availables", availables);
  //   console.log("timeLines", timeLines)
  // }
  
  timeLines = timeLines.filter((tl) => tl.width !== '0%');
  return timeLines;
  
  /*unavailables.forEach(mergeTimelines);
  availables.forEach(mergeTimelines);
  const unified = unavailables.concat(availables);
  // console.log(unified);
  timelines = unified
    .sort((a, b) => a.from - b.from)
    // .sort((a, b) => a.available - b.available);

  // joinTimelines(unavailables, from, to);

  let result = [];
  let item = {};
  if (timelines.length) {
    let current = timelines.shift();
    // console.log("1", current);
    if (current.from > from) {
      result.push({ from: from, to: current.from, visible: false });
    }
    // console.log("2", result);

    item = { ...current, visible: true, available: true };

    // console.log("3", item);

    while (timelines.length > 0) {
      let next = timelines.shift();
      if (next.from >= item.from && next.to <= item.to) {
        continue;
      } else if (
        next.from >= item.from &&
        next.from <= item.to &&
        next.to > item.to
      ) {
        item.to = next.to;
      } else if (next.from >= item.to) {
        result.push(item);
        result.push({
          from: item.to,
          to: next.from,
          visible: true,
          available: false,
        });
        item = { ...next, visible: true, available: true };
      }
    }
  }

  if (item.available) result.push(item);
  let x = result.map((resource) => ({
    id: "",
    available: resource.available,
    caption: "",
    visible: resource.visible,
    width: `${getDetailTimeLinePercent(resource.from, resource.to, from, to)}%`,
  }));
  return x;*/
};

const getDetailTimeLinePercent = (initial, final, begin, end) => {
  initial = initial < begin ? begin : initial;
  final = final > end ? end : final;
  const totalLapse = end - begin;
  const calcLapse = final > initial ? final - initial : 0;
  return (calcLapse * 100) / totalLapse;
};

const getDetailTimeLinePercentUnconditional = (
  initial,
  final,
  begin,
  end,
  x,
  y
) => {
  // if(initial < begin)
  const totalLapse = end - begin;
  const calcLapse = final - initial;
  return (calcLapse * 100) / totalLapse;
};

const getDetailTimeLineCaption = (resource = {}, exception, motives) => {
  let icon = resource.icon;
  if (typeof icon === "function") icon = icon();
  if (exception.motiveId) {
    // debugger
    return (
      <span>
        {
          (
            motives[exception.type].find((m) => m.id === exception.motiveId) ||
            {}
          ).description
        }
      </span>
    );
  }
  return (
    <AssembleAvatar
      description={resource.longDescription}
      fleetTypeId={resource.type}
      icon={resource.icon}
      color="#AFC8FF"
      avatarUrl={resource.profile_image}
    />
  );
  // switch (resource.type) {
  //   case 1:
  //     return (
  //       <SvgAvatar
  //         svg={
  //           icon || (
  //             <SVGIcon
  //               name={icons.truck}
  //               height="24px"
  //               width="24px"
  //               fill="#AFC8FF"
  //               viewBox="-2 -2 22 22"
  //             />
  //           )
  //         }
  //         name={resource.longDescription}
  //       />
  //     );
  //   case 2:
  //     return (
  //       <SvgAvatar
  //         svg={
  //           icon || (
  //             <SVGIcon
  //               name={icons.equipment}
  //               height="24px"
  //               width="24px"
  //               fill="#AFC8FF"
  //               viewBox="0 -5 32 32"
  //             />
  //           )
  //         }
  //         name={resource.longDescription}
  //       />
  //     );
  //   default:
  //     return resource.profile_image ? (
  //       <UrlAvatar
  //         imgUrl={FILES_SERVER.concat(resource.profile_image)}
  //         name={resource.longDescription}
  //       />
  //     ) : (
  //       <SvgAvatar
  //         svg={
  //           icon || (
  //             <SVGIcon
  //               name={icons.user}
  //               height="24px"
  //               width="24px"
  //               fill="#AFC8FF"
  //               viewBox="0 -5 32 32"
  //             />
  //           )
  //         }
  //         name={resource.longDescription}
  //       />
  //     );
  // }
};

export const selectModalContent = ({
  step,
  vehiclesData = [],
  equipmentsData = [],
  operators = [],
  selectedDate,
  onlyOne,
  fleetVehicleTypesClasses,
  operatorLicenseTypes,
  unasembledVehicles,
  unasembledEquipments,
  unasembledOperators,
  // fleetDatas,
  companyTags,
  modalOpeningDate,
  handleChange,
  onSearch,
  modalSearchText,
  isEditing = false,
  addingDolly = false,
}: ModalContentParams) => {
  let data = [];
  switch (step) {
    case 1:
      data = unasembledVehicles?.items?.map((dta) => {
        const fullType = fleetVehicleTypesClasses.find((t) =>
          t.classes.find(
            (c) =>
              // c.id === fleetDatas.find((fd) => dta.id === fd.id)?.fleet_class
              c.id === dta.fleet_class
          )
        );
        return {
          id: dta.id,
          number: dta.economic_number,
          assembled: dta.assembled,
          plates: dta.plates,
          active: dta.active,
          from:
            modalOpeningDate > Date.parse(dta.available_from)
              ? modalOpeningDate
              : new Date(dta.available_from),
          to: dta.available_to ? new Date(dta.available_to) : "-",
          // class: fleetDatas.find((fd) => dta.id === fd.id)?.fleet_class,
          isOnTrip: dta.isOnTrip,
          class: dta.fleet_class,
          type: fullType?.id,
          icon: fullType?.icon({ fill: "currentColor" }),
          status: NORMATIVITY_VALIDITY_ITEMS[dta.status_id],
          selected: vehiclesData?.find((f) => f.id === dta.id) ? true : false,
          tags: dta.tags.map((t) => {
            const companyTag = companyTags?.find((f) => f.id === t);
            return (
              <Tag
                key={t}
                id={t}
                {...companyTag}
                isFull={true}
                parentBackground="light"
              />
            );
          }),
        };
      });
      break;
    case 2:
      data = unasembledEquipments?.items?.filter(dta => {
        if(!isEditing)
          return true;
        else {
          // debugger;
          if(addingDolly){
            const fullType = fleetVehicleTypesClasses.find((t) =>
              t.classes.find(
                (c) =>
                  // c.id === fleetDatas?.find((fd) => dta.id === fd.id)?.fleet_class
                  c.id === dta.fleet_class
              )
            );
            return fullType?.description.toLowerCase() === "dolly";
          } else {
            const fullType = fleetVehicleTypesClasses.find((t) =>
              t.classes.find(
                (c) =>
                  // c.id === fleetDatas?.find((fd) => dta.id === fd.id)?.fleet_class
                  c.id === dta.fleet_class
              )
            );
            return fullType?.description.toLowerCase() !== "dolly";
          }
        }
      }).map((dta) => {
        const fullType = fleetVehicleTypesClasses.find((t) =>
          t.classes.find(
            (c) =>
              // c.id === fleetDatas?.find((fd) => dta.id === fd.id)?.fleet_class
              c.id === dta.fleet_class
          )
        );
        return {
          id: dta.id,
          number: dta.economic_number,
          plates: dta.plates,
          active: dta.active,
          assembled: dta.assembled,
          from:
            modalOpeningDate > Date.parse(dta.available_from)
              ? modalOpeningDate
              : Date.parse(dta.available_from),
          to: dta.available_to ? new Date(dta.available_to) : "-",
          isOnTrip: dta.isOnTrip,
          // class: fleetDatas?.find((fd) => dta.id === fd.id)?.fleet_class,
          class: dta.fleet_class,
          type: fullType?.id,
          icon: fullType?.icon({
            fill: "currentColor",
            stroke: "currentColor",
          }),
          selected: equipmentsData.find((f) => f.id === dta.id) ? true : false,
          status: NORMATIVITY_VALIDITY_ITEMS[dta.status_id],
          tags: dta.tags.map((t) => {
            const companyTag = companyTags?.find((f) => f.id === t);
            return (
              <Tag
                key={t}
                id={t}
                {...companyTag}
                isFull={true}
                parentBackground="light"
              />
            );
          }),
        };
      });
      break;
    default:

      data = unasembledOperators?.items?.map((op) => {

        let licenseType = op.operator__licence_type || "NOL";

        if (licenseType === 'None') {
          licenseType = "NOL";
        }

        return({
          id: op.id,
          active: op.active,
          assembled: op.assembled,
          licenseType: licenseType.split(","),
          from:
            modalOpeningDate > Date.parse(op.available_from)
              ? modalOpeningDate
              : new Date(op.available_from),
          to: op.available_to ? new Date(op.available_to) : "-",
          icon: License({ fill: "currentColor" }),
          status: NORMATIVITY_VALIDITY_ITEMS[op.status],
          description: getOperatorName(
            `${op.operator__user__first_name} ${op.operator__user__last_name}`
          ),
          imageUrl: op.operator__user__profile_image,
          selected: operators.find((f) => f.id === op.id) ? true : false,
          tags: op.tags.map((t) => {
            const companyTag = companyTags?.find((f) => f.id === t);
            return (
              <Tag
                key={t}
                id={t}
                {...companyTag}
                isFull={true}
                parentBackground="light"
              />
            );
          }),
        });
    });
  }
  var classification;

  if (step < 3) {
    classification = fleetVehicleTypesClasses?.filter(
      (type) => type.type === step
    );
    classification = classification.map((type) =>
      type.classes?.map((cls) => ({
        description: `${type.description} (${cls.description})`,
        icon: type.icon({
          width: "50px",
          stroke: "currentColor",
          fill: "currentColor",
        }),
        // icon: <SVGIcon name={icons.truckUnloaded} fill="#000000" />,
        id: cls.id,
      }))
    );
    classification = classification.flat();
    classification = classification.filter((clsf) =>
      (data || []).find((dta) => dta.class === clsf.id)
    );
  } else {
    classification = (operatorLicenseTypes || []).map((type) => ({
      ...type,
      icon: type.icon({ width: "50px", fill: "currentColor" }),
    }));
    classification = classification.filter((f) =>
      data?.find((u) =>
        u.licenseType.find((lt) => lt === f.abbreviation) ? true : false
      )
    );
  }
  return (
    <AssembleModalContent
      //     modalSearchText={modalSearchText}
      equipmentsList={unasembledEquipments}
      vehiclesList={unasembledVehicles}
      operatorsList={unasembledOperators}
      onSearch={onSearch}
      modalSearchText={modalSearchText}
      onlyOne={onlyOne || false}
      step={step}
      selectedVehicles={vehiclesData}
      selectedEquipments={equipmentsData}
      selectedOperators={operators}
      openingDate={modalOpeningDate}
      selectedDate={selectedDate}
      onChange={handleChange}
      tableClassification={classification}
      data={data}
    />
  );
};

const getOperatorName = (name: string) => {
  let result = name;
  if (name.length > 25)
    result = (
      <ExceedPlus
        name={name}
        maxVisibleItems={0}
        replaceText={"..."}
        face={name.substring(0, 24)}
        tooltipOpts={{ delayHide: 20, clickable: false }}
      >
        {name}
      </ExceedPlus>
    );

  return result;
};

const unwrapLines = (assemble) => {
  return Object.keys(assemble.vehicles)
    .map((vehicleLine) => assemble.vehicles[`${vehicleLine}`])
    .concat(
      Object.keys(assemble.equipments).map(
        (equipmentLine) => assemble.equipments[`${equipmentLine}`]
      )
    )
    .concat(
      Object.keys(assemble.operators).map(
        (operatorLine) => assemble.operators[`${operatorLine}`]
      )
    )
    .filter((lines) => lines !== undefined && lines.length > 0);
};

export const VEHICLE_TYPE_ICON_SELECTOR = {
  C: Unitary,
  T: Truck,
  S: Equipment,
  R: Equipment,
  D: Dolly,
  undefined: Unitary,
};
