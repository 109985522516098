//@flow
import * as React from "react";
import { connect } from "react-redux";

import { ViewTitle, View } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
// import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import { AssembleGrid } from "../assemble/assemble-grid";

import * as Types from "./types";
// import * as Styled from "./styled";
import * as Utils from "./utils";
import * as SharedTypes from "../../shared/types";
import Modal from "react-modal";

import {
  getEntityItems,
  readEntities,
  ApiOptions,
  readEntity,
  getEntityArgs,
  cancelRequest,
  readCatalog,
  createEntity,
  FILES_SERVER,
  // SaveItemCounter,
  deleteEntity,
  partialEntity, 
  updateEntity,
} from "../../redux/api/";
import {
  getAssembles,
  VEHICLE_TYPE_ICON_SELECTOR,
} from "../assemble/assemble-utils";
import { getTimeZoneInfo, normalizeStr } from "../../shared/utils";
import {
  NewAssemble,
  AutoPageControls,
  TabSelectorRibbonStyled,
} from "./styled";
import CreateAssemble from "../assemble/create-assemble";
import { License } from "../../shared/icons/assemble";
import AssembleSubstituteResource from "../assemble/assemble-edit-modal/assemble-substitute-resource";
import AssembleAddResource from "../assemble/assemble-edit-modal/assemble-add-resource";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import { Flex, flexOpts } from "../../shared/styled/flex";
import AssembleSummary from "../assemble/assemble-summary";
import Checkbox from "../../shared/controls/checkbox";
import SelectInput from "../../shared/controls/select-input";
import ShipmentDetailDialog from "orbi_v2_frontend_components/lib/git/components/ShipmentDetail/ShipmentDetailDialog";
import {
  // getOrderDetail,
  getOrderDetailShipment,
} from "../shipment/detail/utils";
// import { Marker } from "../../shared/icons/traffic";
import AssembleShipmentAssignDialog from "./assemble-shipment-assign-dialog";
import CancelBookingModal from "../shipment/detail/cancel-booking-modal";
import RejectBookingModal from "../shipment/detail/reject-booking-modal";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
// import { listFacilities } from "../../redux/reducers/facility-reducer";
import BaseDialog from "../../shared/dialog/base-dialog";
import { Marker } from "../../shared/icons/traffic";
import FinalizeTripModal from "../shipment/detail/finalize-trip-modal";
import FinalizeOrderModal from "../shipment/detail/finalize-order-modal";
import RadioGroup from "../../shared/component/radio-group";
import Button from "../../shared/controls/button";
import ResetTripModal from "../shipment/detail/reset-trip-modal";
import SelectInputStyled from "../../shared/controls/select-input-styled";
import AvailabilityModal from "../traffic/availability-modal";
import Loader from '../../shared/icons/tat/loader';
import { handleWSError } from '../../shared/utils';
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";

type OwnProps = {|
  viewMode: "grid" | "list",
  onChange: () => void,
|};
type DispatchToProps = {|
  listFleets: (
    params: {
      company_id: number,
    },
    opts: ApiOptions
  ) => void,
  loadAssemblies: (
    params: Types.loadAssembliesParams,
    opts: ApiOptions
  ) => void,
  listFleetsCatalog: (params: { company_id: number }, opts: ApiOptions) => void,
  cancelRequest: (entityPath: string) => void,

  loadCompanyTags: (company_id: number) => void,
  listFleetResourceCatalog: (
    company_id: number,
    type_id: number,
    view: string,
    economic_number: string,
    plates: string,
    name: string,
    search: string,
    opts: ApiOptions
  ) => void,
  createAssemble: (
    company_id: number,
    fleet_id: number,
    status: number,
    opt: ApiOptions
  ) => void,
  createAssembleVehicle: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    fleet_vehicle: any,
    scheduledate: any,
    opt: ApiOptions
  ) => void,

  createAssembleEquipment: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    fleet_equipment: any,
    scheduledate: any,
    opt: ApiOptions
  ) => void,
  createAssembleOperator: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    fleet_operator: any,
    scheduledate: any,
    opt: ApiOptions
  ) => void,
  deleteAssembleVehicleLog: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    id: number,
    opts: ApiOptions
  ) => void,
  deleteAssembleOperatorLog: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    id: number,
    opts: ApiOptions
  ) => void,
  deleteAssembleEquipmentLog: (
    company_id: number,
    fleet_id: number,
    assembly_id: number,
    id: number,
    opts: ApiOptions
  ) => void,
|};
type StateToProps = {|
  unasembledVehicles: any,
  unasembledEquipments: any,
  unasembledOperators: any,
  companyTags: any,

  fleetAssemblies: any,
  fleetVehicleTypesClasses: any,
  operatorLicenseTypes: any,

  companyId: number,
  companyFleets: [{ id: number }],
  fleetCatalogCounters: {
    unassembled_equipments: number,
    unassembled_operators: number,
    unassembled_vehicles: number,
  },
|};

type Props = {
  ...OwnProps,
  ...DispatchToProps,
  ...StateToProps,
};

type State = {
  +gridConfig: Types.GridConfig,
  +filterParams: Types.loadAssembliesParams,
  +textSearch: string,
  +showCreateModal: boolean,
  +showDisassembleModal: boolean,
  +showAddModal: boolean,
  +currentAssembleId: number,
  +currentTimeline: any,
  +addType: number,
  +showSubstituteModal: boolean,
  +autopage: boolean,
  +lapse: 5000 | 10000 | 20000,
  +timer: any,
  +showTripDetailModal: boolean,
  +showAssignDialog: boolean,
  +showCancelBookingModal: boolean,
  +showRejectBookingModal: boolean,
};

let typingTimer;

class AssembleShipmentGrid extends React.Component<Props, State> {
  state = {
    gridConfig: Utils.defaultGridConfig,
    filterParams: Utils.DefaultFilters,
    buttonFilterName: "Aplicar",
    textSearch: "",
    showCreateModal: false,
    showDisassembleModal: false,
    showSubstituteModal: false,
    showFinalizeTripModal: false,
    showResetTripModal: false,
    showFinalizeOrderModal: false,
    showAddModal: false,
    currentAssembleId: 0,
    currentTimeline: {},
    currentTicket: {},
    addType: 0,
    autopage: false,
    lapse: 5000,
    timer: undefined,
    showTripDetailModal: false,
    showAssignDialog: false,
    showCancelBookingModal: false,
    showRejectBookingModal: false,
    showConfirmBookingModal: false,
    currentTrip: undefined,
    hasAutoStart: true,
    selectedEquipment: 0,
    showSelectEquipmentModal: false, 
    originFacilities: [],
    destinationFacilities: [],
    vehicle: [],
    vehiclesToShow: [],
    equipmentsToShow: [],
    searchStr: "",
    openXlsErrorModal: false,
    descriptionImport: [],
    addingDolly: false,
    showDonateModal: false,
    folioSelected: '',
    folioDataSelected: null,
    secondFolioSelected: '',
    secondFolioDataSelected: null,
    folios: [],
    containersSelected: '',
    transferData: null,
    firstContainerSelected: '',
    secondContainerSelected: '',
    transferEventsError: '',
    transferEventsSuccess: '',
    tripIdToTransfer: '',
    showResultModal: false,
    resultModalContent: '',
    resultModalTitle: '',
    
    showAvailabilityModal: false,
    currentResources: [],
    selectedFacility: 0,
    currentAvailabilityDate: null,
    currentAvailabilityInserted: null,
    currentAssemble: 0,
    reasonSelect: 0,
    isTransferingEvents: false,
    isRestartingLoading: false,
    showErrorMessage: '',
    sendEmailConfirmation: true
  };
  componentDidMount() {
    this.props.setNavbarAction("assemblies-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let base = urlParams.get('base');
    if(base) {
      base = base.split(",");
      this.setState({filterParams: {
        ...this.state.filterParams,
        baseTags: base
      }})
    } else {
      let fleet_provider = urlParams.get('fleet_provider');
      if(fleet_provider){
        fleet_provider = fleet_provider.split(",");
        this.setState({filterParams: {
          ...this.state.filterParams,
          from_to_companies: fleet_provider
        }})
      } else {
        let urlFacilities = urlParams.get('facilities');
        if(urlFacilities) urlFacilities = urlFacilities.split(",")
        this.setState({filterParams: {
          ...this.state.filterParams,
          from_to_facilities: urlFacilities || []
        }});
      }
    }
    
    
    this.props.loadCompanyTags({
      company_id: this.props.companyId
    });
    this.props.loadOrderStatus();
    this.props.loadTransportClasses(this.props.companyId);
    this.props.listFleets(
      { company_id: this.props.companyId },
      {
        onSuccess: () => {
          this.loadAssemblies();
        },
      }
    );
    this.props.loadFacilities({
      company_id: this.props.companyId
    });

    this.props.loadNetworkFacilities({
      company_id: this.props.companyId
    })

    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });

    this.props.loadCompanyTransportClasses({
      company_id: this.props.companyId,
    });

    this.props.loadTagsTripCatalog({ company_id: this.props.companyId });
    this.props.loadTagsOrderCatalog({ company_id: this.props.companyId });
    this.props.loadCompanyFacilities({ company_id: this.props.companyId });

    this.props.getFleetId(
      { company_id: this.props.companyId },
      {
        onSuccess: () => {
          this.getFacilityFilter();
        },
      }
    );

    // console.log(
    //   Date.parse((this.props.fleetAssembliesArgs || "").start_date),
    //   Date.parse((this.props.fleetAssembliesArgs || "").end_date)
    // );
    this.props.listEquipments(
      this.props.companyId,
      {
        onSuccess: (equipmentData) => {
          let newIndex = 9999999999;
          let equipmentsToShow = [];

          for (let i = 0; i < equipmentData.length; i++) {

            let dataEconomic = equipmentData[i];
            let vehicles = (dataEconomic.economic_numbers || []).map( (f) => ({
              id: f.id || 0,
              description: f.economic_number || '',
              isDisabled: !f.is_assembled || !f.is_hired || false,
              disableMessage: !f.is_assembled ? 'Equipo sin ensamble' : 'Equipo sin contrato'
            }));

            if (vehicles.length > 0) {
              equipmentsToShow.push({
                id: newIndex,
                description: `${dataEconomic.title || ''}`,
                items: vehicles
              });
              newIndex = newIndex - 1;
            }

          }

          this.setState({
            equipmentsToShow
          });
        }
      }
    )

    this.props.listVehicles(
      this.props.companyId,
      {
        onSuccess: (vehiclesData) => {
          let newIndex = 9999999999;
          let vehiclesToShow = [];

          for (let i = 0; i < vehiclesData.length; i++) {

            let dataEconomic = vehiclesData[i];
            let vehicles = (dataEconomic.economic_numbers || []).map( (f) => ({
              id: f.id || 0,
              description: f.economic_number || '',
              isDisabled: !f.is_assembled || !f.is_hired || false,
              disableMessage: !f.is_assembled ? 'Camion sin ensamble' : 'Camion sin contrato'
            }));

            if (vehicles.length > 0) {
              vehiclesToShow.push({
                id: newIndex,
                description: `${dataEconomic.title || ''}`,
                items: vehicles
              });
              newIndex = newIndex - 1;
            }

          }

          this.setState({
            vehiclesToShow
          });

        }
      }
    );
  }
  componentWillUnmount() {
    this.props.removeNavbarAction("assemblies-control");
    if (this.state.timer) clearTimeout(this.state.timer);
  }

  manageError = (err) => {
    this.setState({openXlsErrorModal: true, descriptionImport: err})
  }

  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: []
    });
  };

  closeResultModal = () => {
    this.setState({
      showResultModal: false
    })
  }

  doSearch = (modalSearchString: string, typeId: number = 0) => {
    const { listFleetResourceCatalog, companyId, cancelRequest } = this.props;

    (typeId > 0 ? [typeId] : [1, 2, 3]).forEach((number) => {
      cancelRequest(`catalogType${number}Counter`);
      listFleetResourceCatalog(
        companyId,
        number,
        modalSearchString.length === 0 ? "unassembled" : "",
        modalSearchString,
        modalSearchString,
        modalSearchString,
        modalSearchString,
        {
          alias: `catalogType${number}Counter`,
        }
      );
    });
  };

  manageAssignModal = (e: any) => {
    const { name, value } = e.target;
    // console.log(name, value);
    switch (name) {
      case "open":
        this.setState(
          {
            showAssignDialog: true,
            showTripDetailModal: false,
            currentTrip: value,
          },
          this.loadTripBookingAvailability
        );
        break;
      case "close":
      case "cancel":
        this.setState({ showAssignDialog: false, selectedAssembly: 0 });
        break;
      case "save":
        this.props.updateTrip(
          {
            company_id: this.props.companyId,
            trip_id: value.tripId,
            assembly: value.assembly,
            has_automatic_start: this.state.hasAutoStart ? "true" : "false",
            action: "set_assembly",
          },
          // { onSuccess: this.loadAssemblies }
          {
            onSuccess: () => {
              this.loadAssemblies();
              let equipmentList = this.props.tripBookingAvailability
                .find(a => a.id.toString() === this.state.selectedAssembly)
                .equipments.filter(e => e.type.toLowerCase() !== "dolly");
              if(this.state.currentTrip.tickets.some(d => d.order_containers.length < equipmentList.length)){
                this.setState({
                  showAssignDialog: false,
                  showSelectEquipmentModal: true,
                })
              } else {
                this.state.currentTrip.tickets.forEach(d => {
                  d.order_containers.forEach((c, i) => {
                    this.props.updateContainer({
                      company_id: this.props.companyId,
                      order_id: d.id,
                      container_id: c.id_container,
                      fleet_equipment: equipmentList[i].id || 0,
                    })
                  })
                });
                this.setState({
                  showAssignDialog: false,
                  selectedAssembly: 0,
                  currentTrip: undefined,
                  hasAutoStart: true,
                });
              }  
            },
            onError: () => {
              this.setState({
                showAssignDialog: false,
                selectedAssembly: 0,
                currentTrip: undefined,
                hasAutoStart: true,
              });
            }
          }
        );
        
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    console.log(name);
    switch (name) {
      case "gridConfigSelect":
        this.setState(
          {
            gridConfig: {
              columnQuantity: parseInt(value)*4,
              columnRangeType: value.includes("H") ? "HOUR" : "DAY",
              columnSections: 4,
              startDatetime: value.includes("H") ? Utils.dateRemoveHours(Utils.hour, parseInt(value)) : Utils.dateRemoveHours(Utils.today, parseInt(value)*24),
              className: "",
              extended: true,
              scrollable: true,
            },
          },
          () => {
            this.handleChange({
              target: {
                name: "gridDateChange",
                value: 0,
              },
            });
          }
        );
        break;
      case "gridDateChange":
        let c = { ...this.state.gridConfig };
        let f = { ...this.state.filterParams };
        c.startDatetime =
          c.columnRangeType === "DAY"
            ? Utils.dateAddDays(c.startDatetime, e.target.value)
            : Utils.dateAddHours(c.startDatetime, e.target.value);
        f.start_date = c.startDatetime.toISOString();
        f.end_date =
          c.columnRangeType === "DAY"
            ? Utils.dateAddDays(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString()
            : Utils.dateAddHours(
                new Date(f.start_date),
                c.columnQuantity
              ).toISOString();
        this.setState({ gridConfig: c }, () => {
          this.getFacilityFilter();
        });
        // const { cancelRequest } = this.props;
        this.setState({ filterParams: f }, () => {
          this.props.cancelRequest("COMPANIES.FLEETS.ASSEMBLIES");
          this.props.cancelRequest("COMPANIES.FLEETCATALOG.COUNTERS");
          this.loadAssemblies();
        });

        break;
      case "showCreateModal":
        if (e.target.value === false) {
          this.loadAssemblies();
        }
        this.setState({ showCreateModal: e.target.value });
        break;
      case "showSubstituteModal":
        if (e.target.value === false) {
          this.loadAssemblies();
        }
        this.setState({ showSubstituteModal: e.target.value });
        break;
      case "showAddModal":
        if (e.target.value === false) {
          this.loadAssemblies();
        }
        this.setState({ showAddModal: e.target.value });
        break;
      case "saved":
        this.setState({
          showCreateModal: false,
          showSubstituteModal: false,
          showAddModal: false,
        });
        this.loadAssemblies();
        break;
      case "searchStr":
        this.setState(
          {
            filterParams: Utils.DefaultFilters,
            buttonFilterName: "Aplicar",
            searchStr: e.target.value,
          },
          () => {
            this.loadAssembliesTemp(700);
          }
        );
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  transferEvents = (order) => {

    this.setState({
      showDonateModal: true
    });

    const { currentTrip } = this.state;

    this.props.getCandidates({
      company_id: this.props.companyId,
      trip_id: currentTrip.id,
    },
    {
      onSuccess: (val) => {

        const { candidates = [], donor: { equipments = [] } } = val;

        var candidatesToSave = []

        for (var i = 0; i < candidates.length; i++) {
          const { trip_id = '', code = '', from_facility, to_facility, containers } = candidates[i];
          candidatesToSave = candidatesToSave.concat({ id: trip_id, description: code, from_facility, to_facility, containers });
        }

        this.setState({
          containersSelected: equipments.map( item => {
            const { id, economic_number } = item;
            return `${economic_number}`;
          }).join(', '),
          transferData: val,
          folios: candidatesToSave,
          tripIdToTransfer: currentTrip.id
        });

      },
    });

  }

  resetTransferData = () => {
    this.setState({
      showDonateModal: false,
      folioSelected: '',
      folioDataSelected: null,
      secondFolioSelected: '',
      secondFolioDataSelected: null,
      folios: [],
      containersSelected: '',
      transferData: null,
      firstContainerSelected: '',
      secondContainerSelected: '',
      transferEventsSuccess: '',
      transferEventsError: ''
    })
  }

  /// Transfer events 

  getFolioToTransfer = () => {
    if (this.state.transferData === null) return '';
    const { donor : { code = '' } } = this.state.transferData;
    return code;
  }

  getOriginAlias = () => {
    if (this.state.transferData === null) return '';
    const { donor: { from_facility: { alias = '' } } } = this.state.transferData;
    return alias;
  }

  getDestinyAlias = () => {
    if (this.state.transferData === null) return '';
    const { donor: { to_facility: { alias = '' } } } = this.state.transferData;
    return alias;
  }

  getContainerInfo = () => {
    return this.state.containersSelected;
  }

  getCandidatesOriginAlias = () => {
    if (this.state.folioDataSelected === null) return '';

    const { from_facility: { alias = '' } } = this.state.folioDataSelected;
    return alias;
  }

  getCandidatesDestinyAlias = () => {
    if (this.state.folioDataSelected === null) return '';

    const { to_facility: { alias = '' } } = this.state.folioDataSelected;
    return alias;
  }

  getSecondCandidatesOriginAlias = () => {
    if (this.state.secondFolioDataSelected === null) return '';

    const { from_facility: { alias = '' } } = this.state.secondFolioDataSelected;
    return alias;
  }

  getSecondCandidatesDestinyAlias = () => {
    if (this.state.secondFolioDataSelected === null) return '';

    const { to_facility: { alias = '' } } = this.state.secondFolioDataSelected;
    return alias;
  }

  showExtraInfoToTransferEvents = () => {

    if (this.state.transferData === null || this.state.folioDataSelected === null) return false;

    const { donor: { equipments = [] } } = this.state.transferData;
    const { containers = 0 } = this.state.folioDataSelected;

    return equipments.length === 2 && containers === 1;
  }

  getLoader = () => {
    return (
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200}}>
        Cargando...
      </div>
    );
  }

  getContainersItems = () => {
    if (this.state.transferData === null) return [];

    const { donor: { equipments = [] } } = this.state.transferData;

    return equipments.map( (item) => {
      const { id, economic_number, plates } = item;
      return { id, description: economic_number, plates };
    });
  }

  isConfirmTransferEvents = () => {

    if (this.props.getTransferDataLoading) { return false; }

    if (this.showExtraInfoToTransferEvents()) {
      return (this.state.folioSelected.length && this.state.folioSelected.length > 0 && this.state.firstContainerSelected.length && this.state.firstContainerSelected.length > 0 && this.state.secondFolioSelected.length && this.state.secondFolioSelected.length > 0 && this.state.secondContainerSelected.length && this.state.secondContainerSelected.length > 0 );
    } else {
      return (this.state.folioSelected.length && this.state.folioSelected.length > 0);
    }

  }

  getTransferEventsContent = () => {

    if (this.state.transferEventsSuccess !== '') {

      return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, flexDirection: 'column'}}>
          { this.state.transferEventsSuccess }

          <div  style={{width: 20, height: 40}} />

          <Button
            settings={{
              text: "Ok",
              onClick: () => this.resetTransferData()
            }}
          />
        </div>
      );

    }

    const showExtraInfoToTransferEvents = this.showExtraInfoToTransferEvents();
    const listEquipments = this.getContainersItems();
    return(
      <div style={{width: '100%'}}>

        <div style={{fontSize: 14, marginBottom: 20, color: 'rgb(155, 161, 172)'}} >{'Selecciona el folio el cual recibirá los eventos.'} </div>

        {
          this.state.transferEventsError !== '' ? 
          <div className={'data-key'} style={{color: 'red'}}>{this.state.transferEventsError}</div> : <div/>
        }

        <div style={{width: '100%', height: 76, backgroundColor: 'rgb(214, 222, 230)', borderRadius: 4, marginBottom: 40}}>

          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Folios por transferir:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getFolioToTransfer()}</div></div>
          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getOriginAlias()}</div></div>
          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getDestinyAlias()}</div></div>

        </div>

        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>

          <div style={{width: '40%'}}>
            <div
              style={{
                marginLeft: -5,
                marginTop: -10
              }}
            >
              <SelectInputStyled
                selectedItems={this.state.folioSelected}
                items={this.state.folios.filter( (item) => {
                  const { id = '' } = item;

                  if (this.state.secondFolioDataSelected !== null) {
                    return `${this.state.secondFolioDataSelected.id}` !== `${id}`;
                  } else {
                    return true;
                  }
                })}
                onChange={ (e) => {

                  var folio = null;

                  for (var i = 0; i < this.state.folios.length; i++) {
                    if (`${this.state.folios[i].id}` === `${e.target.value}`) {
                      folio = this.state.folios[i];
                    }
                  }

                  this.setState({ [e.target.name]: e.target.value, folioDataSelected: folio })

                }}
                className={'transfer-events'}
                name={"folioSelected"}
                label={"Folio candidato:"}
                multiple={false}
              />
            </div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getCandidatesOriginAlias()}</div></div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getCandidatesDestinyAlias()}</div></div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{marginBottom: 15}}>Remolques:</div> 
            
              {
                showExtraInfoToTransferEvents ? 
                <div
                  style={{
                    marginLeft: 5,
                    marginTop: -10,
                    width: '100%'
                  }}
                >
                  <SelectInputStyled
                    showSearch={false}
                    showDeselector={false}
                    selectedItems={this.state.firstContainerSelected}
                    items={listEquipments}
                    onChange={ (e) => {

                      if (e.target.value.length && e.target.value.length > 0) {

                        const val = e.target.value;
                        if (JSON.stringify(val) === JSON.stringify(this.state.secondContainerSelected)) {

                          var tempContainer = '';
                          for (var i = 0; i < listEquipments.length; i++) {
                            if (`${listEquipments[i].id}` !== `${val[0]}`) {
                              tempContainer = `${listEquipments[i].id}`;
                            }
                          }

                          this.setState({[e.target.name]: val, secondContainerSelected: [tempContainer]})
                        } else {
                          this.setState({[e.target.name]: val});
                        }

                      }

                    }}
                    className={'transfer-events-container'}
                    name={"firstContainerSelected"}
                    label={""}
                    multiple={false}
                  />
                </div>
                :
                <div className={'data-value'} style={{marginLeft: 14}}>{this.getContainerInfo()}</div>
              }
            </div>
          </div>

          {
            showExtraInfoToTransferEvents ? 
            <div style={{width: '40%', marginLeft: 50}}>
              <div
                style={{
                  marginLeft: -5,
                  marginTop: -10
                }}
              >
                <SelectInputStyled
                  selectedItems={this.state.secondFolioSelected}
                  items={this.state.folios.filter( (item) => {
                    const { containers = 0, id = '' } = item;

                    if (this.state.folioDataSelected !== null) {
                      return containers === 1 && `${this.state.folioDataSelected.id}` !== `${id}`;
                    } else {
                      return containers === 1;
                    }
                  })}
                  onChange={ (e) => {

                    var folio = null;
                    var tempContainer = '';

                    for (var i = 0; i < this.state.folios.length; i++) {
                      if (`${this.state.folios[i].id}` === `${e.target.value}`) {
                        folio = this.state.folios[i];
                      }
                    }

                    if (this.state.firstContainerSelected !== '' && this.state.firstContainerSelected.length > 0) {

                      for (var i = 0; i < listEquipments.length; i++) {
                        if (`${listEquipments[i].id}` !== this.state.firstContainerSelected[0]) {
                          tempContainer = [`${listEquipments[i].id}`];
                        }
                      }

                    }

                    if (folio === null) {
                      tempContainer = '';
                    }

                    this.setState({ [e.target.name]: e.target.value, secondFolioDataSelected: folio, secondContainerSelected: tempContainer })

                  }}
                  className={'transfer-events'}
                  name={"secondFolioSelected"}
                  label={"Folio candidato:"}
                  multiple={false}
                />
              </div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getSecondCandidatesOriginAlias()}</div></div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getSecondCandidatesDestinyAlias()}</div></div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{marginBottom: 15}}>Remolques:</div> 
                <div
                  style={{
                    marginLeft: 5,
                    marginTop: -10,
                    width: '100%'
                  }}
                >
                  <SelectInputStyled
                    showSearch={false}
                    showDeselector={false}
                    selectedItems={this.state.secondContainerSelected}
                    items={listEquipments}
                    onChange={ (e) => {

                      if (e.target.value.length && e.target.value.length > 0) {

                        const val = e.target.value;

                        if (JSON.stringify(val) === JSON.stringify(this.state.firstContainerSelected)) {

                          var tempContainer = '';
                          for (var i = 0; i < listEquipments.length; i++) {
                            if (`${listEquipments[i].id}` !== `${val[0]}`) {
                              tempContainer = `${listEquipments[i].id}`;
                            }
                          }

                          this.setState({[e.target.name]: val, firstContainerSelected: [tempContainer]})
                        } else {
                          this.setState({[e.target.name]: val})
                        }

                      }

                    }}
                    className={'transfer-events-container'}
                    name={"secondContainerSelected"}
                    label={""}
                    multiple={false}
                  />
                </div>
              </div>
            </div>
            : 
            <div></div> 
          }
          
        </div>

      </div>
            
    );
  }

  buildTransferEventsModal = () => {
    return(
      <Modal
        isOpen={this.state.showDonateModal}
        portalClassName="dialog confirm-dialog"
        overlayClassName="overlay"
        className="content transfer-events successModal"
      >
        <div className="title">Transferir eventos</div>
        <div className="close" onClick={ () => 
          this.resetTransferData()
        }
        ></div>
        <div className="message">
          
          {
            this.props.getTransferDataLoading ? 
            this.getLoader() : this.getTransferEventsContent()
          }

        </div>

        {
          this.state.transferEventsSuccess !== '' ? <div/> :
          <div className="actions confirmActions">
            <Button
              settings={{
                type: 'primary outline dark',
                text: "Cancelar",
                onClick: () => this.resetTransferData()
              }}
            />
            <Button
              disabled={!this.isConfirmTransferEvents() || this.state.isTransferingEvents}
              settings={{
                text: this.state.isTransferingEvents ? <Loader/> : "Confirmar",
                onClick: () => {
                  var candidates = [];
                  var fleet_equipments = [];

                  var folioCandidate = this.state.folioSelected[0];
                  candidates = [folioCandidate];

                  if (this.state.firstContainerSelected.length && this.state.firstContainerSelected.length > 0) {

                    const firstContainer = this.state.firstContainerSelected[0];

                    fleet_equipments = [firstContainer];
                  }
                  
                  if (this.state.secondContainerSelected.length && this.state.secondContainerSelected.length > 0 &&
                    this.state.secondFolioSelected.length && this.state.secondFolioSelected.length > 0) {

                    const secondContainer = this.state.secondContainerSelected[0];
                    const secondFolioCandidate = this.state.secondFolioSelected[0];

                    candidates = candidates.concat(secondFolioCandidate);
                    fleet_equipments = fleet_equipments.concat(secondContainer);

                  }

                  const { tripIdToTransfer = '' } = this.state;

                  this.setState({
                    isTransferingEvents: true
                  });

                  this.props.transferEvents(
                    {
                      company_id: this.props.companyId,
                      trip_id: tripIdToTransfer,
                      candidates,
                      fleet_equipments
                    },
                    {
                      onSuccess: (val) => {

                        this.setState({
                          transferEventsSuccess: 'Los eventos fueron transferidos correctamente',
                          transferEventsError: '',
                          folioSelected: '',
                          folioDataSelected: null,
                          secondFolioSelected: '',
                          secondFolioDataSelected: null,
                          folios: [],
                          containersSelected: '',
                          transferData: null,
                          firstContainerSelected: '',
                          secondContainerSelected: '',
                          isTransferingEvents: false
                        }, () => {
                          this.loadAssemblies();
                        });

                      },
                      onError: (error) => {
                        this.setState({
                          isTransferingEvents: false,
                          transferEventsSuccess: '',
                          transferEventsError: 'Ocurrió un error al transferir los eventos'
                        });
                      }
                    }
                  );
                }
              }}
            />
          </div>
        }
      </Modal>
    );
  } 

  loadAssembliesTemp = (time = 300) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
      () => {
        this.loadAssemblies();
      }, time);
  }

  loadAssemblies = () => {
    const { filterParams } = this.state;
    if (!!!this.props.companyFleets[0]) return;
    const params: Types.loadAssembliesParams = {
      company_id: this.props.companyId,
      fleet_id: this.props.companyFleets[0].id,
      view: "shortdetail",
      ...filterParams,
      from_to_companies: '',
      fleet_provider: filterParams.from_to_companies,
      get_trips: true,
      search: this.state.searchStr,
      previous_assemblies: (this.props.fleetAssemblies || []).map(fA => fA.id)
    };
    this.props.loadAssemblies(params, {
      args: { ...filterParams },
    });
    this.props.listFleetsCatalog({ company_id: this.props.companyId });

    // if (this.state.autopage) setTimeout(() => console.log("next call"), 60000);
    if (this.state.timer) clearTimeout(this.state.timer);
    if (this.state.autopage)
      var timer = setTimeout(() => {
        const { limit, offset } = this.state.filterParams;

        const newOffset =
          offset * limit > this.props.assembliesCatalogCounter ? 1 : offset + 1;
        this.setState(
          { filterParams: { ...filterParams, offset: newOffset } },
          () => this.loadAssemblies()
        );
      }, this.state.lapse);
    this.setState({ timer });
  };

  ShipmentsFilters = (
    status: any[] = [],
    providers: any[] = [],
    origin: any[] = [],
    destination: any[] = [],
    vehicles: any[] = [],
    equipments: any[] = []
  ) => {
    
    let statusCondensed = status.filter(s => !s.description.toLowerCase().includes("reservación confirmada"));
    let combined = status.filter(s => s.description.toLowerCase().includes("reservación confirmada")).map(s => s.id);
    statusCondensed.push({id: combined.join(), description: "Reservación confirmada"});
    return [
      {
        title: "Base",
        items: this.props.companyTags.filter((f) =>
          f.type === 3
            ? true
            : false
        ),
        name: "baseTags",
        listType: 1,
      },
      {
        title: "Vehículo",
        items: vehicles,
        name: "vehicle",
        listType: 5,
      },
      {
        title: "Equipo",
        items: equipments,
        name: "equipment",
        listType: 5,
      },
      {
        title: "Estatus viaje",
        items: statusCondensed,
        name: "status",
        listType: 5,
      },
      {
        title: "Estatus orden",
        items: this.props.orderStatus,
        name: "orderStatus",
        listType: 5,
      },
      {
        title: "Cliente",
        items: providers,
        name: "from_to_companies",
        listType: 5,
      },
      {
        title: "Origen",
        items: this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_facilities",
        listType: 5,
      },
      {
        title: "Destino",
        items: this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "to_facilities",
        listType: 5,
      },
      {
        title: "Origen/Destino",
        items: this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_to_facilities",
        listType: 5,
      },
    ];
  };

  getFacilityFilter = () => {
    const { start_date } = this.state.filterParams;
    const { companyId, fleetId } = this.props;
    let id = (fleetId[0] || {}).id;

    if (id) {
      this.props.facilityFilter(
        {
          company_id: companyId,
          fleet_id: id,
          start_date: start_date,
          end_date: Utils.dateAddDays(new Date(start_date), 8).toISOString(),
        },
        {
          onSuccess: (response) => {
            this.setState({
              originFacilities: response.from_facilities.map((facility) => ({
                id: facility.id,
                description: facility.name.concat(
                  " - ",
                  facility.code,
                  " - ",
                  facility.alias
                ),
              })),
              destinationFacilities: response.to_facilities.map((facility) => ({
                id: facility.id,
                description: facility.name.concat(
                  " - ",
                  facility.code,
                  " - ",
                  facility.alias
                ),
              })),
            });
          },
        }
      );
    }
  };

  render() {
    const {
      gridConfig,
      textSearch,
      showCreateModal,
      showSubstituteModal,
      showAddModal,
      currentAssembleId,
      currentOrder,
    } = this.state;
    const { fleetCatalogCounters } = this.props;
    return (
      <View className="traffic-view">
        <ViewTitle>
          {"Control de embarques"}
          <FilterBarCompact 
            searchStr={this.state.searchStr}
            searchAction={this.handleChange}
            content={this.ShipmentsFilters(
              this.props.tripStatuses, 
              this.props.companyProviders,
              this.state.originFacilities,
              this.state.destinationFacilities,
              this.state.vehiclesToShow,
              this.state.equipmentsToShow
              // this.getFacilityFilter()
              // this.props.facilities
            )}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState(
                  {
                    buttonFilterName: "Restablecer"
                  },
                  () => { this.loadAssemblies(); }
                );
              } else {
                this.setState(
                  {
                    filterParams: Utils.DefaultFilters,
                    buttonFilterName: "Aplicar"
                  },
                  () => { this.loadAssemblies(); }
                );
              }
            }}
            onChange={(e) => {
              this.setState({
                filterParams: {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                },
                buttonFilterName: "Aplicar"
              });
            }}
            buttonFilterName={this.state.buttonFilterName}
            selectedItems={{
              status: this.state.filterParams.status || [],
              from_to_companies: this.state.filterParams.from_to_companies || [],
              from_facilities: this.state.filterParams.from_facilities || [],
              to_facilities: this.state.filterParams.to_facilities || [],
              from_to_facilities: this.state.filterParams.from_to_facilities || [],
              vehicle: this.state.filterParams.vehicle || [],
              equipment: this.state.filterParams.equipment || [],
              orderStatus: this.state.filterParams.orderStatus || [],
              baseTags: this.state.filterParams.baseTags || []
            }}
          />
        </ViewTitle>
        <div style={{ display: "flex", backgroundColor: "black" }}>
          <TabSelectorRibbonStyled
            onChange={this.props.onChange}
            bgColor="black"
            selectedUnderlineColor="#3D77F7"
            items={[
              { id: "orders", description: "ÓRDENES" },
              { id: "list", description: "VIAJES" },
              { id: "grid", description: "TRÁFICO" },
              { id: "appointments", description: "CITAS" },
            ]}
            activeId={this.props.viewMode}
          />
          <AutoPageControls>
            <Checkbox
              onChange={(e) => {
                this.setState({ autopage: !this.state.autopage }, () =>
                  this.loadAssemblies()
                );
              }}
              item={{
                description: "Autopaginado",
                active: this.state.autopage,
                id: 0,
              }}
              name="autopage"
              active={this.state.autopage}
            />
            <SelectInput
              label=""
              name="limit"
              value={this.state.filterParams.limit}
              onChange={(e) =>
                this.setState(
                  {
                    filterParams: {
                      ...this.state.filterParams,
                      limit: e.target.value,
                    },
                  },
                  () => this.loadAssemblies()
                )
              }
              items={[
                {
                  id: 10,
                  description: "10",
                },
                {
                  id: 25,
                  description: "25",
                },
              ]}
            />
            <SelectInput
              label=""
              name="lapse"
              value={this.state.lapse}
              onChange={(e) =>
                this.setState(
                  {
                    lapse: e.target.value,
                  },
                  () => this.loadAssemblies()
                )
              }
              items={[
                {
                  id: 5000,
                  description: "5 seg.",
                },
                {
                  id: 10000,
                  description: "10 seg.",
                },
                {
                  id: 30000,
                  description: "30 seg.",
                },
              ]}
            />
          </AutoPageControls>
          <NewAssemble
            onClick={() =>
              this.handleChange({
                target: { name: "showCreateModal", value: true },
              })
            }
            type="primary small wide"
            text="Crear ensamble"
            name="showCreateModal"
          />
        </div>
        <AssembleGrid
          gridConfigChange={this.handleChange}
          dateChange={this.handleChange}
          {...{ gridConfig }}
          counters={{
            operators: fleetCatalogCounters.unassembled_operators,
            vehicles: fleetCatalogCounters.unassembled_vehicles,
            equipments: fleetCatalogCounters.unassembled_equipments,
          }}
          rows={this.props.fleetAssemblies 
            .filter((assemble: Assemble) =>
              assemble.resources.find(
                (resource: Resource) =>
                  normalizeStr(resource.longDescription).includes(
                    normalizeStr(textSearch)
                  ) && resource.assembled
              )
            )
            .map((assemble) => ({
              ...assemble,
              actions: assemble.detail.find((detail) =>
                detail.timeLine.find((timeline) => timeline.assembled)
                  ? true
                  : false
              )
                ? this.getAssembleActions(assemble)
                : undefined,

              detail: assemble.detail.map((detail) => ({
                ...detail,
                timeLine: detail.timeLine.map((timeline) =>
                  this.getDetailTimelineActions(timeline)
                ),
              })),
              availability: assemble.availability 
                ? {
                    ...assemble.availability,
                    // item: React.cloneElement(assemble.availability.item, {
                      onClick: () => {
                        this.manageAvailabilityModal({
                          target: { 
                            name: "open", 
                            value: {
                              id: assemble.id, 
                              facility: assemble.availability.availability_facility.id,
                              availability: assemble.availability.availability,
                              inserted: assemble.availability.inserted,
                              resources: assemble.resources
                            } 
                          },
                        });
                      }
                    // })
                  }
                : null,
              manualAvailability: assemble.manualAvailability 
                ? {
                    ...assemble.manualAvailability,
                    // item: React.cloneElement(assemble.manualAvailability.item, {
                      onClick: () => {
                        this.manageAvailabilityModal({
                          target: { 
                            name: "open", 
                            value: {
                              id: assemble.id, 
                              facility: assemble.manualAvailability.availability_facility.id,
                              availability: assemble.manualAvailability.availability,
                              inserted: assemble.manualAvailability.inserted,
                              resources: assemble.resources
                            } 
                          },
                        });
                      }
                    // })
                  }
                : null,
              layerItems: assemble.layerItems.map((li) => ({
                ...li,
                item: React.cloneElement(li.item, {
                  onClick: () => {
                    this.manageDetailModal({
                      target: { name: "open", value: { ...li, tripId: li.id } },
                    });
                  },
                }),
              })),
            }))}
          summary={undefined}
        />
        <PaginatorControlled
          limit={this.state.filterParams.limit}
          offset={this.state.filterParams.offset}
          onChange={(e) =>
            this.setState(
              {
                filterParams: {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                },
              },
              () => {
                this.loadAssemblies();
              }
            )
          }
          itemCount={this.props.assembliesCatalogCounter}
        />
        {showCreateModal && (
          <CreateAssemble
            manageError={this.manageError}
            createAssemble={this.props.createAssemble}
            createAssembleWithComponents={this.props.createAssembleWithComponents}
            companyTags={this.props.companyTags}
            onlyOne={false}
            companyFleets={this.props.companyFleets}
            companyId={this.props.companyId}
            fleetVehicleTypesClasses={this.props.fleetVehicleTypesClasses}
            operatorLicenseTypes={this.props.operatorLicenseTypes}
            unasembledVehicles={this.props.unasembledVehicles}
            unasembledEquipments={this.props.unasembledEquipments}
            unasembledOperators={this.props.unasembledOperators}
            listFleetResourceCatalog={this.props.listFleetResourceCatalog}
            showCreateModal={showCreateModal}
            onChange={this.handleChange}
            onSearch={this.doSearch}
            createAssembleEquipment={this.props.createAssembleEquipment}
            createAssembleVehicle={this.props.createAssembleVehicle}
            createAssembleOperator={this.props.createAssembleOperator}
          />
        )}

        {
          this.state.showAvailabilityModal &&
          <AvailabilityModal 
            title={this.state.addAvailability ? "Agregar disponibilidad" : "Editar disponibilidad"}
            show={this.state.showAvailabilityModal}
            onChange={this.manageAvailabilityModal}
            data={{
              resources: this.state.currentResources,
              inserted: this.state.currentAvailabilityInserted,
              sendEmailConfirmation: this.state.sendEmailConfirmation
            }}
            facilities={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.networkFacilities || [])}
            date={this.state.currentAvailabilityDate}
            facility={this.state.selectedFacility}
            isLoading={this.props.isLoadingAvailabilityEdition}
          />
        }

        {showSubstituteModal && (
          <AssembleSubstituteResource
            // FleetDatas={this.props.fleetDatas}
            manageError={this.manageError}
            operatorLicenseTypes={this.props.operatorLicenseTypes}
            unasembledEquipments={this.props.unasembledEquipments}
            unasembledOperators={this.props.unasembledOperators}
            unasembledVehicles={this.props.unasembledVehicles}
            fleetVehicleTypesClasses={this.props.fleetVehicleTypesClasses}
            // listFleetData={this.props.listFleetData}
            companyFleets={this.props.companyFleets}
            companyId={this.props.companyId}
            companyTags={this.props.companyTags}
            listFleetResourceCatalog={this.props.listFleetResourceCatalog}
            updateAssembleVehicleLog={this.props.updateAssembleVehicleLog}
            updateAssembleOperatorLog={this.props.updateAssembleOperatorLog}
            updateAssembleEquipmentLog={this.props.updateAssembleEquipmentLog}
            // listFleetOperators={this.props.listFleetOperators}
            assembleSummaryResources={this.getAssembleResources(
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === this.state.currentTimeline?.assembleId;
              })
            )}
            resources={
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === this.state.currentTimeline?.assembleId;
              })?.resources
            }
            showSubstituteModal={showSubstituteModal}
            original={this.state.currentTimeline}
            // isDolly={this.state.isDolly}
            addingDolly={this.state.addingDolly}
            onChange={this.handleChange}
            onSearch={this.doSearch}
          />
        )}

        {showAddModal && (
          <AssembleAddResource
            // fleetDatas={this.props.fleetDatas}
            manageError={this.manageError}
            createAssembleEquipment={this.props.createAssembleEquipment}
            createAssembleOperator={this.props.createAssembleOperator}
            createAssembleVehicle={this.props.createAssembleVehicle}
            unasembledEquipments={this.props.unasembledEquipments}
            unasembledVehicles={this.props.unasembledVehicles}
            unasembledOperators={this.props.unasembledOperators}
            companyFleets={this.props.companyFleets}
            companyTags={this.props.companyTags}
            companyId={this.props.companyId}
            fleetVehicleTypesClasses={this.props.fleetVehicleTypesClasses}
            // listFleetData={this.props.listFleetData}
            // listFleetOperators={this.props.listFleetOperators}
            listFleetResourceCatalog={this.props.listFleetResourceCatalog}
            operatorLicenseTypes={this.props.operatorLicenseTypes}
            assembleSummaryResources={this.getAssembleResources(
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentAssembleId;
              })
            )}
            resources={
              this.props.fleetAssemblies.find((assemble) => {
                return assemble.id === currentAssembleId;
              })?.resources
            }
            addType={this.state.addType}
            addingDolly={this.state.addingDolly}
            showAddModal={showAddModal}
            assemble={this.props.fleetAssemblies.find((assemble) => {
              return assemble.id === currentAssembleId;
            })}
            onSearch={this.doSearch}
            onChange={this.handleChange}
          />
        )}
        <ConfirmDialog {...this.confirmDialogResolver()} />

        {this.state.showTripDetailModal ? (
          <ShipmentDetailDialog
            show={this.state.showTripDetailModal}
            {...currentOrder}
            tickets={currentOrder.tickets.map((t) => ({
              ...t,
              detailButton: (
                <Marker
                  fill="#3E4E6C"
                  style={{ marginTop: "5px", cursor: "pointer" }}
                  onClick={() =>
                    this.props.history.push(
                      `/company/controls/shipment/orderdetail/${currentOrder.id}`
                    )
                  }
                />
              ),
            }))}
            actions={
              currentOrder.extraActions?.map((extra) => ({
              ...extra,
              onAction: (a) => {
                this.manageDetailModal({
                  target: {
                    name: extra.caption,
                    value: a,
                  },
                });
              },
            }))}
            onClose={() =>
              this.manageDetailModal({
                target: {
                  name: "close",
                  value: undefined,
                },
              })
            }
            primary={
              currentOrder.buttons.primary
                ? {
                    caption: currentOrder.buttons.primary.caption,
                    onClick: (e) => {
                      this.manageDetailModal({
                        target: {
                          name: currentOrder.buttons.primary.caption,
                          value: currentOrder,
                        },
                      });
                    },
                    type: "primary",
                  }
                : null
            }
            secondary={
              currentOrder.buttons.secondary && {
                caption: currentOrder.buttons?.secondary?.caption,
                onClick: (e) => {
                  this.manageDetailModal({
                    target: {
                      name: currentOrder.buttons.secondary.caption,
                      value: currentOrder,
                    },
                  });
                },
                type: "outline",
              }
            }
          />
        ) : null}
        {this.state.showAssignDialog && (
          <AssembleShipmentAssignDialog
            assemblies={this.props.tripBookingAvailability}
            show={this.state.showAssignDialog}
            manager={this.manageAssignModal}
            logo={this.state.currentTrip.companyLogo}
            trip={this.state.currentTrip}
            selectedAssembly={this.state.selectedAssembly}
            hasAutoStart={this.state.hasAutoStart}
            handleAutoStart={this.handleAutoStart}
          />
        )}
        {this.state.showCancelBookingModal && (
          <CancelBookingModal 
            show={this.state.showCancelBookingModal}
            reasonSelect={this.state.reasonSelect}
            reasons={this.props.orderMotives.filter((m) => m.type === 2)}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            onChange={this.manageDetailModal}
            isCancelingLoading={this.props.bookingLoading}
          />
        )}
        {this.state.showRejectBookingModal && (
          <RejectBookingModal
            show={this.state.showRejectBookingModal}
            reasons={this.props.orderMotives.filter((m) => m.type === 2)}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showFinalizeTripModal && (
          <FinalizeTripModal
            show={this.state.showFinalizeTripModal}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showResetTripModal && (
          <ResetTripModal
            show={this.state.showResetTripModal}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
            }}
            onChange={this.manageDetailModal}
            isRestartingLoading={this.state.isRestartingLoading}
          />
        )}
        {this.state.showFinalizeOrderModal && (
          <FinalizeOrderModal
            show={this.state.showFinalizeOrderModal}
            data={{
              logo: this.state.currentOrder.courier.logo,
              name: this.state.currentOrder.courier.name,
              trip: this.state.currentOrder.number,
              card: this.state.currentTicket.number,
            }}
            onChange={this.manageDetailModal}
          />
        )}
        {this.state.showConfirmBookingModal && (
          <BaseDialog
            closeAction={() => {this.setState({showConfirmBookingModal: false, hasAutoStart: true, currentTrip: undefined})}}
            acceptAction={() => this.manageDetailModal({target: {name: "confirmBooking"}})}
            open={this.state.showConfirmBookingModal}
            title={"Confirmar reservación"}
            contentObject={this.getModalContent()}
            showCancel={true}
            cancelText="Cerrar"
            cancelButtonType="primary outline dark"
            acceptText="Confirmar"
            // disabledAccept={!this.props.selectedAssembly}
            acceptButtonType="primary"
            style={{ content: { width: "420px", marginLeft: "-210px" } }}
          ></BaseDialog>
        )}
        {this.state.showSelectEquipmentModal && (
          <BaseDialog
            closeAction={() => {
              this.setState({
                showSelectEquipmentModal: false, 
                selectedAssembly: 0,
                currentTrip: undefined,
                selectedEquipment: 0,
                hasAutoStart: true
              })
            }}
            acceptAction={this.assignContainers}
            open={this.state.showSelectEquipmentModal}
            title={"Seleccionar equipo"}
            contentObject={this.getEquipmentModalContent()}
            showCancel={false}
            cancelText="Cerrar"
            cancelButtonType="primary outline dark"
            acceptText="Guardar"
            disabledAccept={!this.state.selectedEquipment}
            acceptButtonType="primary"
            style={{ content: { width: "600px", marginLeft: "-300px" } }}
          ></BaseDialog>
        )}
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showResultModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">{this.state.resultModalTitle}</div>
          <div className="close" onClick={this.closeResultModal}></div>
          <div className="message">{this.state.resultModalContent}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeResultModal,
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showErrorMessage !== ''}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={() => this.setState({showErrorMessage: ''})}></div>
          <div className="message">{this.state.showErrorMessage}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Cerrar",
                onClick: () => this.setState({showErrorMessage: ''}),
              }}
            />
          </div>
        </Modal>

        { this.buildTransferEventsModal() }
      </View>
    );
  }

  manageAvailabilityModal = (e) => {
    const { name, value } = e.target;
    switch(name){
      case "sendEmailConfirmation":
        this.setState({
          sendEmailConfirmation: value
        });
        break;
      case "open":
        this.setState({
          showAvailabilityModal: true,
          currentAssemble: value.id,
          currentResources: value.resources,
          selectedFacility: value.facility,
          currentAvailabilityDate: value.availability,
          currentAvailabilityInserted: value.inserted,
          sendEmailConfirmation: true
        })
        break;
      case "facilitySelect":
        // debugger;
        this.setState({selectedFacility: value})
        break;
      case "currentAvailabilityDate":
        // debugger;
        this.setState({currentAvailabilityDate: value})
        break;
      case "saveAvailability":
        if(this.state.currentAvailabilityDate !== undefined && this.state.selectedFacility !== 0){
          let date = new Date(this.state.currentAvailabilityDate);
          date = date.toISOString();
          // debugger;
          this.props.editAvailability({
            company_id: this.props.companyId,
            fleet_id: (this.props.companyFleets[0] || {}).id || 0,
            assembly_id: this.state.currentAssemble,
            availability_date: date,
            availability_facility: this.state.selectedFacility,
            send_email: this.state.sendEmailConfirmation,
            timezone: getTimeZoneInfo()
          }, {
            onSuccess: () => {
              this.setState({
                showAvailabilityModal: false,
                showResultModal: true,
                resultModalTitle: 'Éxito',
                resultModalContent: 'La disponibilidad fue registrada con éxito.',
                currentAssemble: 0,
                currentResources: [],
                selectedFacility: 0,
                currentAvailabilityDate: null,
                currentAvailabilityInserted: null,
              }, () => { this.loadAssemblies(); });
            },
            onError: () => {
              this.setState({
                showAvailabilityModal: false,
                showResultModal: true,
                resultModalTitle: 'Error',
                resultModalContent: 'Ocurrió un error al guardar los cambios.',
                currentAssemble: 0,
                currentResources: [],
                selectedFacility: 0,
                currentAvailabilityDate: null,
                currentAvailabilityInserted: null,
              })
            }
          })
        }
        break;
      case "close":
      case "cancel":
        this.setState({
          showAvailabilityModal: false,
          currentAssemble: 0,
          currentResources: [],
          selectedFacility: 0,
          currentAvailabilityDate: null,
          currentAvailabilityInserted: null,
        })
        break;
      default:
    }
  }

  assignContainers = () => {
    let equipmentList = this.props.tripBookingAvailability
      .find(a => a.id.toString() === this.state.selectedAssembly)
      .equipments.filter(e => e.type.toLowerCase() !== "dolly");
    this.state.currentTrip.tickets.forEach(d => {
      if(d.order_containers.length < equipmentList.length){
        d.order_containers.forEach((c) => {
          this.props.updateContainer({
            company_id: this.props.companyId,
            order_id: d.id,
            container_id: c.id_container,
            fleet_equipment: this.state.selectedEquipment,
          })
        })
      } else {
        d.order_containers.forEach((c, i) => {
          this.props.updateContainer({
            company_id: this.props.companyId,
            order_id: d.id,
            container_id: c.id_container,
            fleet_equipment: equipmentList[i].id || 0,
          })
        })
      }
    });
    this.setState({
      showSelectEquipmentModal: false, 
      selectedAssembly: 0,
      currentTrip: undefined,
      selectedEquipment: 0,
    });
  }

  getEquipmentModalContent = () => {
    let equipments = this.props.tripBookingAvailability
      .find(a => a.id.toString() === this.state.selectedAssembly)
      .equipments.filter(e => e.type.toLowerCase() !== "dolly")
      // debugger;
    return (
      <>
        <div style={{fontSize: "17px"}}>
          Selecciona el equipo que deseas utilizar
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <RadioGroup
            items={
              equipments.map(e => ({
                value: e.id.toString(),
                label: e.economic_number.concat(" - ", e.plates),
              }))
            }
            radioName={"selectedEquipment"}
            defaultValue={this.state.selectedEquipment}
            onClickHandler={this.handleInput}
          />
        </div>
      </>
    )
  }

  handleInput = (e) => {
    let property = e.target.name;
    let value = e.target.value;
    this.setState({ [property]: value });
  }

  handleAutoStart = e => {
    this.setState({ hasAutoStart: !this.state.hasAutoStart });
  }

  getModalContent = () => {
    return (
      <>
        <div style={{fontSize: "17px"}}>
          Sí deseo confirmar la reservación a mi cliente
        </div>
        <div style={{marginTop: "10px"}}>
          <Checkbox
            name="auto"
            onChange={this.handleAutoStart}
            item={{
              id: 1,
              description: "Inicio automático",
              active: this.state.hasAutoStart,
              style: false
            }}
          />
        </div>
        
      </>
    )
  }

  getAssembleSummary = (fleetAssemble: Assemble) => {
    const resources = this.getAssembleResources(fleetAssemble);
    return (
      <Flex justifyContent={flexOpts.justifyContent.center}>
        <AssembleSummary
          {...{
            vehicles: resources.vehicles,
            equipments: resources.equipments,
            operators: resources.operators,
          }}
        />
      </Flex>
    );
  };

  disassemble = () => {
    const { currentAssembleId } = this.state;
    const {
      // fleetAssemblies,
      // deleteAssembleEquipmentLog,
      // deleteAssembleOperatorLog,
      // deleteAssembleVehicleLog,
      companyFleets,
      companyId,
    } = this.props;
    this.props.deleteAssembleWithComponents({
      company_id: companyId,
      fleet_id: companyFleets[0].id,
      assemble_id: currentAssembleId,
    }, {
      onSuccess: () => {this.managedisassembleModal({ target: { name: "done", value: "" } })},
      onError: (e) => {
        this.managedisassembleModal({ target: { name: "cancel", value: "" } }); 
        let err = e.response?.data?.errors || []; 
        this.manageError(err);
      }
    });


    // const assemble: Assemble = fleetAssemblies.find(
    //   (f) => f.id === currentAssembleId
    // );
    // const counter = new SaveItemCounter(() =>
    //   this.managedisassembleModal({ target: { name: "done", value: "" } })
    // );
    // assemble.resources.forEach((resource) => {
    //   counter.add();
    //   switch (resource.type) {
    //     case 1:
    //       deleteAssembleVehicleLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //       break;
    //     case 2:
    //       deleteAssembleEquipmentLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //       break;
    //     default:
    //       deleteAssembleOperatorLog(
    //         companyId,
    //         companyFleets[0].id,
    //         currentAssembleId,
    //         resource.logId,
    //         { onSuccess: () => counter.done() }
    //       );
    //   }
    // });
  };
  getAssembleResources = (fleetAssemble: Assemble) => {
    const vehicles = fleetAssemble?.resources
      .filter((r: Resource) => r.type === 1 && r.assembled)
      .map((r) => ({ description: r.longDescription, ...r }));
    const equipments = fleetAssemble?.resources
      .filter((r) => r.type === 2 && r.assembled)
      .map((r) => ({ description: r.longDescription, ...r }));
    const operators = fleetAssemble?.resources
      .filter((r) => r.type === 3 && r.assembled)
      .map((r) => ({
        description: r.longDescription,
        imageUrl: r.image ? FILES_SERVER.concat(r.image) : "images/user.svg",
        ...r,
      }));
    return { vehicles, equipments, operators };
  };

  manageDetailModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    // console.log(name, value);

    switch (name) {
      case "Transferir eventos":
        this.transferEvents(value);
        break;
      case "Cambiar ensamble":
      case "Seleccionar ensamble":
        const { currentTrip } = this.state;
        this.manageAssignModal({
          target: {
            name: "open",
            value: {
              id: currentTrip.id,
              companyLogo: currentTrip.cargo_owner.logo,
              companyName:
                currentTrip.cargo_owner.orbi_id ||
                currentTrip.cargo_owner.corporation_name,
              tripNumber: currentTrip.signature || currentTrip.code,
              tickets: currentTrip.trip_orders,
              class: currentTrip.transport_class,
              status: currentTrip.status,
              details: currentTrip.trip_orders.map((to) => ({
                ticket: to.id,
                origin: {
                  logo: to.from_company.logo,
                  name: to.from_facility.name,
                  date: to.estimated_loading_date,
                  location: "",
                },
                destination: {
                  logo: to.to_company.logo,
                  name: to.to_facility.name,
                  date: to.estimated_unload_date,
                  location: "-",
                },
              })),
            },
          },
        });
        break;
      case "Reservar":
        this.props.tripBooking(
          {
            company_id: this.props.companyId,
            facility_id: this.props.facilityId,
            trip_id: this.state.currentOrder.id,
            view: "booking",
          },
          { onSuccess: this.loadAssemblies }
        );
        this.setState({ showBookingModal: true, showTripDetailModal: false });
        break;
      case "Confirmar":
        
        // debugger;
        this.setState({ showConfirmBookingModal: true, showTripDetailModal: false, currentTrip: value.id });
        break;
      case "confirmBooking":
        this.props.updateTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip,
            has_automatic_start: this.state.hasAutoStart ? "true" : "false",
            action: "confirm_booking",
          },
          { onSuccess: () => {this.loadAssemblies(); this.setState({showConfirmBookingModal: false, currentTrip: undefined, hasAutoStart: true})} }
        );
        break;

      case "Cancelar reservación":
        this.setState({
          showCancelBookingModal: true,
          showTripDetailModal: false,
        });
        break;

      case "Rechazar":
        this.setState({
          showRejectBookingModal: true,
          showTripDetailModal: false,
        });
        break;

      case "rejectBooking":
        this.props.rejectBooking(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showRejectBookingModal: false,
                currentOrder: undefined,
              });
              this.loadAssemblies();
            },
          }
        );
        break;
      case "reasonSelect":
          this.setState({ reasonSelect: value });
          break;
      case "cancelBooking":

        let motiveDescription = '';

        (this.props.orderMotives || []).map( motive => {
          if (`${motive.value}` === `${this.state.reasonSelect}`) {
            motiveDescription = motive.label;
          }
        })

        this.props.cancelBooking(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
            motive_id: this.state.reasonSelect,
            motive_desc: motiveDescription
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelBookingModal: false,
                currentOrder: undefined,
              });
              this.loadAssemblies();
            },
          }
        );
        break;

      case "Finalizar viaje sin datos":
        // debugger;
        this.setState({
          showFinalizeTripModal: true,
          showTripDetailModal: false,
        });
        break;
      case "Finalizar orden sin datos":
        this.setState({
          showFinalizeOrderModal: true,
          showTripDetailModal: false,
          currentTicket: value,
        });
        break;
      case "finalizeTrip":
        this.props.finalizeTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeTripModal: false,
                currentOrder: undefined,
              });
              this.loadAssemblies();
            },
          }
        );
        break;
      case "finalizeOrder":
        // console.log(name, value);
        this.props.finalizeOrder(
          {
            company_id: this.props.companyId,
            id: this.state.currentTicket.id,
            view: value,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeOrderModal: false,
                currentOrder: undefined,
                currentTicket: undefined,
              });
              this.loadAssemblies();
            },
          }
        );
        break;
      
      case "Reiniciar viaje":
        this.setState({
          showResetTripModal: true,
          showTripDetailModal: false,
        });
        break;
      case "resetTrip":
        this.setState({
          isRestartingLoading: true
        });
        this.props.resetTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentOrder.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showResetTripModal: false,
                currentOrder: undefined,
                isRestartingLoading: false
              });
              this.loadAssemblies();
            },
            onError: (error) => {
              this.setState({
                showResetTripModal: false,
                showErrorMessage: handleWSError(error.response?.data ?? {}),
                isRestartingLoading: false
              })
            }
          }
        );
        break;
      case "open":
        // console.log("opening", name, value);
        this.props.loadTrip(
          {
            company_id: this.props.companyId,
            trip_id: value.tripId,
            view: "control",
          },
          {
            onSuccess: (trip) => {
              this.setState({
                showTripDetailModal: true,
                currentTrip: value,
                currentOrder: getOrderDetailShipment(trip, {
                  transportClasses: this.props.transportClasses,
                  tags: this.props.companyTags,
                  statusId: value.statusId,
                  facilityId: this.props.facilityId,
                }),
                card: value,
              });
            },
          }
        );
        break;

      case "close":
        this.setState({
          showTripDetailModal: false,
          showCancelAppointmentModal: false,
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showBookingModal: false,
          showFinalizeOrderModal: false,
          showFinalizeTripModal: false,
          showResetTripModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;
      case "cancel":
        this.setState({
          showTripDetailModal: false,
          showCancelAppointmentModal: false,
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showFinalizeOrderModal: false,
          showFinalizeTripModal: false,
          showResetTripModal: false,
          showBookingModal: false,
          currentOrder: undefined,
          card: undefined,
        });
        break;

      default:
    }
  };

  confirmDialogResolver = (): { open: boolean } => {
    const {
      showDisassembleModal,
      showRemoveModal,
      currentTimeline,
      currentAssembleId,
    } = this.state;
    const { fleetAssemblies } = this.props;

    if (showDisassembleModal) {
      return {
        open: showDisassembleModal || false,
        closeAction: () =>
          this.managedisassembleModal({
            target: { name: "cancel", value: "" },
          }),

        acceptAction: () =>
          this.managedisassembleModal({
            target: { name: "disassemble", value: "" },
          }),

        title: "Desensamblar",
        message: "¿Estás seguro que deseas deshacer este ensamble?",
        contentObject: this.getAssembleSummary(
          fleetAssemblies.find((assemble) => assemble.id === currentAssembleId)
        ),
        showCancel: true,
        cancelText: "Cancelar",
        acceptText: "Aceptar",
        cancelButtonType: "primary outline dark",
      };
    } else if (showRemoveModal) {
      const current = fleetAssemblies.find(
        (assemble) => assemble.id === currentTimeline.assembleId
      );
      return {
        open: showRemoveModal || false,
        closeAction: () =>
          this.manageRemoveModal({
            target: { name: "cancel", value: "" },
          }),

        acceptAction: () =>
          this.manageRemoveModal({
            target: { name: "remove", value: "" },
          }),

        title: "Remover recurso",
        message: `¿Estás seguro que deseas remover a ${currentTimeline.caption.props.name}?`,
        contentObject: this.getAssembleSummary(current),
        showCancel: true,
        cancelText: "Cancelar",
        acceptText: "Remover",
        cancelButtonType: "primary outline dark",
      };
    } else {
      return { open: false };
    }
  };

  manageRemoveModal = (e: SharedTypes.SimpleEvent) => {
    const { name } = e.target;
    switch (name) {
      case "cancel":
        this.setState({ showRemoveModal: false, currentTimeline: {} });
        break;
      case "remove":
        this.removeCurrentResource();
        break;
      case "saved":
        this.loadAssemblies();
        // this.loadCounters();
        this.setState({ showRemoveModal: false, currentTimeline: {} });
        break;
      default:
    }
  };

  removeCurrentResource = () => {
    const { currentTimeline } = this.state;
    const {
      companyId,
      companyFleets,
      deleteAssembleVehicleLog,
      deleteAssembleEquipmentLog,
      deleteAssembleOperatorLog,
    } = this.props;
    switch (currentTimeline.type) {
      case 1:
        deleteAssembleVehicleLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () =>
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              }),
            onError: (e) => {
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      case 2:
        deleteAssembleEquipmentLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () =>
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              }),
            onError: (e) => {
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      case 3:
        deleteAssembleOperatorLog(
          companyId,
          companyFleets[0].id,
          currentTimeline.assembleId,
          currentTimeline.logId,
          {
            onSuccess: () =>
              this.manageRemoveModal({
                target: { name: "saved", value: "" },
              }),
            onError: (e) => {
              this.manageRemoveModal({ target: { name: "cancel", value: "" } }); 
              let err = e.response?.data?.errors || []; 
              this.manageError(err);
            }
          }
        );
        break;
      default:
    }
  };

  getAssembleActions = (assemble: Assemble) => {
    const { resources } = assemble;
    console.log(assemble);
    let actions = [
      {
        description: "Desensamblar",
        action: () => {
          this.managedisassembleModal({
            target: { name: "confirm", value: assemble.id },
          });
        },
      },
    ];
    // debugger
    if(assemble.availability){
      actions.push({
        description: "Editar disponibilidad",
        action: () => {
          this.setState({ addAvailability: false }, () => {
            this.manageAvailabilityModal({
              target: { 
                name: "open", 
                value: {
                  id: assemble.id, 
                  facility: assemble.availability.availability_facility.id,
                  availability: assemble.availability.availability,
                  inserted: assemble.availability.inserted,
                  resources: assemble.resources
                } 
              },
            });
          })
        },
      });
    } else {
      actions.push({
        description: "Agregar disponibilidad",
        action: () => {
          this.setState({ addAvailability: true }, () => {
            this.manageAvailabilityModal({
              target: { 
                name: "open", 
                value: {
                  id: assemble.id, 
                  facility: 0,
                  resources: assemble.resources
                } 
              },
            });
          })
        },
      });
    }
    if (
      resources.filter((resource) => resource.type === 1 && resource.assembled)
        .length < 1
    )
      actions.push({
        description: "Agregar Vehículo",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addVehicle", value: assemble.id },
          });
        },
      });
    if (
      resources.filter((resource) => resource.type === 2 && resource.assembled && !resource.isDolly)
        .length < 2
    ) {
      actions.push({
        description: "Agregar Equipo",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addEquipment", value: assemble.id },
          });
        },
      });
    }
    if (
      resources.filter((resource) => resource.type === 2 && resource.assembled && resource.isDolly)
        .length < 1
    ) {
      actions.push({
        description: "Agregar Dolly",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addDolly", value: assemble.id },
          });
        },
      });
    }
    if (
      resources.filter((resource) => resource.type === 3 && resource.assembled)
        .length < 3
    )
      actions.push({
        description: "Agregar Operador",
        action: () => {
          this.managedisassembleModal({
            target: { name: "addOperator", value: assemble.id },
          });
        },
      });
    // if (!this.getResponsePerm("56", "u")) {
    //   actions = actions.filter(
    //     (action) =>
    //       action.description !== "Agregar Vehículo" &&
    //       action.description !== "Agregar Equipo" &&
    //       action.description !== "Agregar Operador"
    //   );
    // }
    // if (!this.getResponsePerm("56", "d")) {
    //   actions = actions.filter(
    //     (action) => action.description !== "Desensamblar"
    //   );
    // }

    return actions;
  };

  managedisassembleModal = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    // console.log(name, value);
    switch (name) {
      case "confirm":
        this.setState({
          showDisassembleModal: true,
          currentAssembleId: parseInt(value),
        });
        break;
      case "cancel":
        this.setState({
          showDisassembleModal: false,
          currentAssembleId: undefined,
        });
        break;
      case "disassemble":
        this.disassemble();
        break;
      case "addVehicle":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 1,
        });
        break;
      case "addEquipment":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 2,
          addingDolly: false,
        });
        break;
      case "addDolly":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 2,
          addingDolly: true,
        });
        break;
      case "addOperator":
        this.setState({
          showAddModal: true,
          currentAssembleId: parseInt(value),
          addType: 3,
        });
        break;
      case "done":
        this.loadAssemblies();
        this.setState({
          showDisassembleModal: false,
          currentAssembleId: undefined,
        });
        break;
      default:
    }
  };

  manageActions = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "remove":
        this.setState({
          showRemoveModal: true,
          currentTimeline: value,
        });
        break;
      case "substitute":
        this.setState({
          showSubstituteModal: true,
          currentTimeline: value,
          addingDolly: value.isDolly
        });
        break;
      default:
    }
  };

  getDetailTimelineActions = (timeline: Timeline) => {
    const current = Date.parse(new Date().toISOString());
    let actions =
      timeline.from > current || timeline.to > current
        ? [
            {
              description: "Remover",
              action: () =>
                this.manageActions({
                  target: { name: "remove", value: timeline },
                }),
            },
            {
              description: "Sustituir",
              action: () =>
                this.manageActions({
                  target: { name: "substitute", value: timeline },
                }),
            },
          ]
        : null;
    // if (actions !== null && !this.getResponsePerm("56", "u")) {
    //   actions = actions.filter((action) => action.description !== "Sustituir");
    // }
    // if (actions !== null && !this.getResponsePerm("56", "d")) {
    //   actions = actions.filter((action) => action.description !== "Remover");
    // }
    // const actions = [];
    return {
      ...timeline,
      actions,
    };
  };
  loadTripBookingAvailability = () => {
    this.props.loadTrip(
      {
        company_id: this.props.companyId,
        trip_id: this.state.currentTrip.id,
        view: "booking",
      },
      {
        alias: "TripBookingAvailability",
      }
    );
  };
}

const mapDispatchToProps = (dispatch): DispatchToProps => {
  dispatch(readEntities("COMPANIES.CATALOGS.VEHICLETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.FLEETCLASSES"));
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  dispatch(readEntities("COMPANIES.CATALOGS.OPERATORLICENSETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));

  const loadTagsTripCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "9" },
        { args: { ...params }, ...opts, alias: "tagsTrips" }
      )
    );

  const loadTagsOrderCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "7" },
        { args: { ...params }, ...opts, alias: "tagsOrder" }
      )
    );

  const facilityFilter = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.FLEETS.ASSEMBLIES.FILTERS",
        {
          ...params,
          view: "shortdetail",
          get_trips: "true",
          action: "get_facilities",
        },
        { args: { ...params }, ...opts }
      )
    );

  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadOrderStatus: () => dispatch(readEntities("COMPANIES.CATALOGS.ORDERSTATUS")),
    loadFacilities: (params, opt) =>
      dispatch(readEntities("COMPANIES.ORDERS.FACILITIES", params, opt)),
    loadNetworkFacilities: (params, opt) =>
      dispatch(readEntities("COMPANIES.NETWORK.FACILITIES", params, opt)),
    editAvailability: (params, opt) =>
      dispatch(partialEntity("COMPANIES.TRAFFIC.AVAILABILITY", params, opt)),
    getCandidates: (params, opts) =>
      dispatch(readEntity("COMPANIES.TRIPS.CANDIDATES", params, opts)),
    transferEvents: (params, opts) =>
      dispatch(partialEntity("COMPANIES.TRIPS.CANDIDATES", params, opts)),
    updateContainer: (params, opts) =>
      dispatch(partialEntity("COMPANIES.ORDERS.CONTAINERS", params, opts)),
    updateAssembleEquipmentLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_equipment,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_equipment,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    updateAssembleVehicleLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_vehicle,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLELOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_vehicle,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    updateAssembleOperatorLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_operator,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_operator,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    
    resetTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "reset_trip" },
          options
        )
      ),
    finalizeTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "finalize_trip" },
          options
        )
      ),
    finalizeOrder: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "finalize_order" },
          opts
        )
      ),
    loadAssemblies: (params, opts) =>
      dispatch(readCatalog("COMPANIES.FLEETS.ASSEMBLIES", params, opts)),
    listFleets: (params, opts) =>
      dispatch(readEntities("COMPANIES.FLEETS", params, opts)),
    listFleetsCatalog: (params, opts) =>
      dispatch(readEntity("COMPANIES.FLEETCATALOG.COUNTERS", params, opts)),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    loadCompanyTags: (params) =>
      dispatch(readEntities("COMPANIES.TAGS", params)),
    listFleetResourceCatalog: (
      company_id,
      type_id,
      view = "unassembled",
      economic_number = "",
      plates = "",
      name = "",
      search = "",
      opts
    ) =>
      dispatch(
        readCatalog(
          "COMPANIES.FLEETCATALOG",
          { company_id, type_id, view, economic_number, plates, name, search },
          opts
        )
      ),
    createAssemble: (company_id, fleet_id, status, opt) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES",
          {
            company_id,
            fleet_id,
            status,
          },
          opt
        )
      ),
    
    createAssembleWithComponents: (params, opt) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.CREATION",
          params,
          opt
        )
      ),
    
    deleteAssembleWithComponents: (params, opt) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.CREATION",
          params,
          opt
        )
      ),
    createAssembleVehicle: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_vehicle,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLES",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_vehicle,
            scheduledate,
          },
          opt
        )
      ),

    createAssembleEquipment: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_equipment,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTS",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_equipment,
            scheduledate,
          },
          opt
        )
      ),
    createAssembleOperator: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_operator,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORS",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_operator,
            scheduledate,
          },
          opt
        )
      ),
    deleteAssembleVehicleLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLELOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    deleteAssembleOperatorLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    deleteAssembleEquipmentLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    loadTrip: (
      params: { company_id: number, trip_id: number },
      opts: ApiOptions
    ) => dispatch(readEntity("COMPANIES.TRIPS", { ...params }, opts)),
    loadTransportClasses: (company_id, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id }, opts)),
    updateTrip: (params, opts) =>
      dispatch(partialEntity("COMPANIES.TRIPS", params, opts)),
    cancelBooking: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "cancel_booking" },
          options
        )
      ),
    rejectBooking: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "reject_booking" },
          options
        )
      ),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyTransportClasses: (params, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", params, opts)),
    loadTagsTripCatalog,
    loadTagsOrderCatalog,
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),

    getFleetId: (params, opts) => {
      dispatch(readEntities("COMPANIES.FLEETS", params, opts));
    },
    listVehicles: (
      company_id, opts
    ) =>
      dispatch(
        readCatalog(
          "COMPANIES.CATALOGS.VEHICLE",
          { company_id },
          opts
        )
      ),
    listEquipments: (
        company_id, opts
      ) =>
        dispatch(
          readCatalog(
            "COMPANIES.CATALOGS.EQUIPMENT",
            { company_id },
            opts
          )
        ),

    facilityFilter,
  };
};

const mapStateToProps = (state): StateToProps => {
  const isLoadingAvailabilityEdition = state.api["COMPANIES.TRAFFIC.AVAILABILITY"]?.status?.isFetching || false;
  const bookingLoading = state.api["COMPANIES.TRIPS"]?.status?.isFetching || false;
  const getTransferDataLoading = state.api["COMPANIES.TRIPS.CANDIDATES"]?.status?.isFetching;
  const companyId = state.globalCatalog.session.company.id;
  const companyFleets = getEntityItems(state, "COMPANIES.FLEETS");
  const fleetCatalogCounters = getEntityItems(
    state,
    "COMPANIES.FLEETCATALOG.COUNTERS"
  );

  const fleetVehicleTypesClasses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.VEHICLETYPES"
  ).map((vt) => ({
    ...vt,
    classes: getEntityItems(state, "COMPANIES.CATALOGS.FLEETCLASSES").filter(
      (fc) => fc.type === vt.id
    ),
    icon:
      VEHICLE_TYPE_ICON_SELECTOR[
        vt.abbreviation || (vt.description === "Dolly" ? "D" : undefined)
      ],
  }));
  const motives = {
    "1": getEntityItems(state, "vehicleMotives"),
    "2": getEntityItems(state, "equipmentMotives"),
    "3": getEntityItems(state, "operatorMotives"),
  };
  const fleetAssembliesArgs = getEntityArgs(
    state,
    "COMPANIES.FLEETS.ASSEMBLIES"
  );
  const assembliesCatalogCounter =
    state.api["COMPANIES.FLEETS.ASSEMBLIES"]?.count || 0;
  const fleetAssemblies = getAssembles(
    getEntityItems(state, "COMPANIES.FLEETS.ASSEMBLIES"),
    Date.parse(fleetAssembliesArgs.start_date),
    Date.parse(fleetAssembliesArgs.end_date),
    fleetVehicleTypesClasses,
    motives
  );
  const operatorLicenseTypes = getEntityItems(
    state,
    "COMPANIES.CATALOGS.OPERATORLICENSETYPES"
  ).map((operator) => ({
    ...operator,
    icon: License,
  }));
  const unasembledVehicles = state.api["catalogType1Counter"] || {};
  const unasembledEquipments = state.api["catalogType2Counter"] || {};
  const unasembledOperators = state.api["catalogType3Counter"] || {};
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  const transportClasses: Array<any> = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS"
  );
  const tripBookingAvailability = getEntityItems(
    state,
    "TripBookingAvailability"
  ).map(Utils.mapTripBookingAvailability);
  const orderMotives = getEntityItems(state, "COMPANIES.CATALOGS.ORDERSMOTIVES")
    .map((o) =>
      o.submotives.map((s) => ({
        label: `${o.description} - ${s.description}`,
        value: s.id,
        type: s.type,
      }))
    )
    .flat();

  const tripStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.TRIPSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK").map(
    (f) => ({
      description: f.fleet_provider.orbi_id || f.fleet_provider.corporation_name,
      id: f.fleet_provider.fleet_provider_id || 0,
    })
  );

  const companyTransportClasses = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS"
  ).map((f) => ({ description: f.code, id: f.id }));

  const companyTagsTrip = getEntityItems(state, "tagsTrips");
  const companyTagsOrder = getEntityItems(state, "tagsOrder");

  // const companyFacilities = getEntityItems(
  //   state,
  //   "COMPANIES.FACILITIES"
  // ).map((f) => ({ description: `${f.name} - ${f.alias}`, id: f.id }));

  const fleetId = getEntityItems(state, "COMPANIES.FLEETS");

  
  let facilities = ((getEntityItems(state, "COMPANIES.ORDERS.FACILITIES") || [])["facilities"] || [])
      .map(f => ({
        label: `${f.name} - ${f.code} - ${f.alias}`,
        value: f.id
      }))

  
  const orderStatus = getEntityItems(
      state,
      "COMPANIES.CATALOGS.ORDERSTATUS"
    ).map((f) => ({
      description: f.description,
      id: f.id || 0,
    }));

  const networkFacilities = getEntityItems(state, "COMPANIES.NETWORK.FACILITIES").map(f => ({
    label: `${f.name} - ${f.code} - ${f.alias} (${f.company?.alias || f.company?.orbi_id || f.company?.name || '-'})`,
    value: f.id || 0
  })).sort((a,b) => {
    if(a.label < b.label){
      return -1
    } else if (a.label > b.label){
      return 1
    }
    return 0
  });

  return {
    isLoadingAvailabilityEdition,
    facilities,
    transportClasses,
    unasembledVehicles,
    unasembledEquipments,
    unasembledOperators,
    companyTags,
    companyId,
    companyFleets,
    fleetCatalogCounters,
    fleetAssemblies,
    fleetVehicleTypesClasses,
    operatorLicenseTypes,
    assembliesCatalogCounter,
    tripBookingAvailability,
    orderMotives,
    tripStatuses,
    companyProviders,
    companyTransportClasses,
    companyTagsTrip,
    companyTagsOrder,
    fleetId,
    getTransferDataLoading,
    orderStatus,
    bookingLoading,
    networkFacilities
  };
};

export default connect<Props, OwnProps, StateToProps, DispatchToProps, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(AssembleShipmentGrid);
