import React, { Component } from "react";
import RouteClassesHeader from "./classes/route-classes-header";
import RouteClassesItems from "./classes/route-classes-item";
import TruckForward from "../../../shared/icons/routes/TruckForward";
import Agency from "../../../shared/icons/routes/Agency";
import TruckBackward from "../../../shared/icons/routes/TruckBackward";
import Factory from "../../../shared/icons/routes/Factory";
import RouteClassesItemsUpdate from "./classes/route-classes-item-update";

class RouteCatalogDetailsClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getWidth = () => {
    if (this.props.editMode) {
      return {
        full: "9%",
        small: "8%",
        actions: "20%",
      };
    }
    return {
      full: "11.8%",
      small: "11.8%",
      actions: "0%",
    };
  };

  tableWidth = () => {
    return [
      {
        name: "caret",
        width: "5%",
        headerText: "",
      },
      {
        name: "class",
        width: this.getWidth().full,
        headerText: "Clase",
      },
      {
        name: "configuration",
        width: this.getWidth().full,
        headerText: "Configuración",
      },
      {
        name: "distance",
        width: this.getWidth().full,
        headerText: "Distancia",
      },
      {
        name: "operatores",
        width: this.getWidth().full,
        headerText: "Operadores",
      },
      {
        name: "tat",
        width: this.getWidth().small,
        headerText: "TAT",
      },
      {
        name: "origin",
        width: this.getWidth().small,
        headerText: <Factory fill={"#b0baff"} />,
      },
      {
        name: "transit",
        width: this.getWidth().small,
        headerText: <TruckForward fill={"#b0baff"} />,
      },
      {
        name: "destination",
        width: this.getWidth().small,
        headerText: <Agency fill={"#b0baff"} />,
      },
      {
        name: "return",
        width: this.getWidth().small,
        headerText: <TruckBackward fill={"#b0baff"} />,
      },
      {
        name: "actions",
        width: this.getWidth().actions,
        headerText: "",
      },
    ];
  };

  tableDetailWidth = () => {
    return [
      {
        name: "name",
        width: "25%",
        headerText: "",
      },
      {
        name: "route",
        width: "15%",
        headerText: "Ruta",
      },
      {
        name: "rpd",
        width: "15%",
        headerText: "Nº RPD",
      },
      {
        name: "distance",
        width: "15%",
        headerText: "Distancia",
      },
      {
        name: "class",
        width: "15%",
        headerText: "Tipo de camino",
      },
      {
        name: "Zones",
        width: "15%",
        headerText: "Zonas",
      },
    ];
  };

  render() {
    const {
      data,
      setRow,
      classesItems,
      removeRow,
      updateRow,
      editMode,
    } = this.props;
    return (
      <React.Fragment>
        <RouteClassesHeader config={this.tableWidth()} />
        {/* {rows.map(row=>row.editMode ? <RouteClassesEdit config={rowConfig}> : <RouteClassesItem config={rowConfig}>)} */}
        {data.map((row, key) => {
          return row.editMode ? (
            <RouteClassesItemsUpdate
              key={key}
              config={this.tableWidth()}
              row={row}
              rowKey={key}
              setRow={setRow}
              classesItems={classesItems}
              removeRow={removeRow}
            />
          ) : (
            <RouteClassesItems
              key={key}
              config={{
                item: this.tableWidth(),
                details: this.tableDetailWidth(),
              }}
              row={row}
              rowKey={key}
              removeRow={removeRow}
              updateRow={updateRow}
              editMode={editMode}
            />
          );
        })}

        {/* <RouteClassesItems config={tableWidth} getElement={this.getElement} /> */}
      </React.Fragment>
    );
  }
}

export default RouteCatalogDetailsClasses;

RouteCatalogDetailsClasses.defaultProps = {};
