import React, { Component } from "react";
import { connect } from "react-redux";
import {
  RoutesContainer,
  Header,
  HeaderTitle,
  Content,
  PanelHeader,
  PanelTitle,
  Line,
  FieldsMaps,
  FieldsMapsLeft,
  FieldsMapsRight,
  ParentContainer,
  ClassesContainer,
  ClassAddItem,
  Footer,
} from "./style";
import RouteCatalogDetailsParent from "./route-catalog-details-parent";
import {
  getEntityItems,
  readEntities,
  readEntity,
  createEntity,
} from "../../../redux/api";
import { partialEntity, deleteEntity/* , readCatalog */ } from "../../../redux/api/actions";
import Button from "../../../shared/controls/button";
import { postUploadFileNomenclature } from "../../../redux/reducers/routes-reducer";
import RouteCatalogDetailsClasses from "./routes-catalog-details-classes";
import update from "immutability-helper";
import { MapController } from "../../../shared/component/map-container";
import { Link } from "react-router-dom";
import { getUrlsEnv } from "../../../redux/api/api-urls";
import { listExpeditionClasses } from "../../../redux/reducers/company-reducer";
import { cloneDeep } from "lodash";
import { groupFacilities } from "../../../shared/utils";

class RouteCatalogDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idRoute: 0,
      origin: 0,
      destination: 0,
      convoy: "",
      alias: "",
      ownAlias: "",
      dupliedAlias: false,
      code: "",
      ownCode: "",
      dupliedCode: false,
      tags: [],
      selectedTags: [],
      nomenclatures: [],
      permissions: [],
      childs: [],
      classes: [],
      rowEditMode: false,
      originMarker: {},
      destinationMarker: {},
      loadMap: false,
      ready: false,

      originalConvoys: [],
      zonesConvoy: [],
      maxConvoyId: 1,
      originalDetention: [],
      zonesDetention: [],
      maxDetentionId: 1,
    };

    this.mapController = new MapController();
  }

  componentDidMount() {
    this.props.loadfacilityCatalog({ company_id: this.props.companyId });
    this.props.loadfacilityCatalogNetwork({ company_id: this.props.companyId });
    this.props.loadTagsCatalog({ company_id: this.props.companyId });
    this.props.loadFacilities({
      company_id: this.props.companyId,
    });
    // this.props.loadFilters({
    //   company_id: this.props.companyId,
    // });
    this.props.loadCompanyTags(this.props.companyId);
    if (this.props.match.params.routeId) {
      this.props.loadZones1({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId
      });
      this.props.loadZones2({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId
      });
      this.props.loadZones3({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId
      });
      this.props.listRouteZones1({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId
      }, {
        onSuccess: r => {
          this.props.listRouteZones3({
            company_id: this.props.companyId,
            route_id: this.props.match.params.routeId
          }, {
            onSuccess: r3 => {
              const fullResult = r.concat(r3);
              this.setState({
                zonesConvoy: fullResult.map(zC => ({
                  id: zC.catalog_id, 
                  selectedZoneClass: zC.current__map_zone__transport_classes.map(i => i.toString()),
                  selectedZone: [zC.map_zone_id.toString()],
                  geofence: zC.polygon,
                })),
                maxConvoyId: (Math.max(0, ...fullResult.map(zC => zC.catalog_id))) + 1,
                originalConvoys: cloneDeep(fullResult),
              });
              if(this.mapController.map){
                this.paintFacilityGeofences(r3,"#FBA61A");
              } else {
                this.mapController.isReadySubject.subscribe((ready) => {
                  if (ready) {
                    this.paintFacilityGeofences(r3,"#FBA61A");
                  }
                });
              }
            }
          });
          // this.setState({
          //   zonesConvoy: r.map(zC => ({
          //     id: zC.catalog_id, 
          //     selectedZoneClass: zC.current__map_zone__transport_classes.map(i => i.toString()),
          //     selectedZone: [zC.map_zone_id.toString()],
          //     geofence: zC.polygon,
          //   })),
          //   maxConvoyId: (Math.max(0, ...r.map(zC => zC.catalog_id))) + 1,
          //   originalConvoys: cloneDeep(r),
          // });
          if(this.mapController.map){
            this.paintFacilityGeofences(r,"#50C4E3");
          } else {
            this.mapController.isReadySubject.subscribe((ready) => {
              if (ready) {
                this.paintFacilityGeofences(r,"#50C4E3");
              }
            });
          }
        }
      })
      this.props.listRouteZones2({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId
      }, {
        onSuccess: r => {
          this.setState({
            zonesDetention: r.map(zD => ({
              id: zD.catalog_id, 
              selectedZoneClass: zD.current__map_zone__transport_classes.map(i => i.toString()),
              selectedZone: [zD.map_zone_id.toString()],
              break_time: zD.break_time,
              geofence: zD.polygon,
            })),
            maxDetentionId: (Math.max(0, ...r.map(zD => zD.catalog_id))) + 1,
            originalDetention: cloneDeep(r),
          });
          if(this.mapController.map){
            this.paintFacilityGeofences(r,"#A659E9");
          } else {
            this.mapController.isReadySubject.subscribe((ready) => {
              if (ready) {
                this.paintFacilityGeofences(r,"#A659E9");
              }
            });
          }
        }
      })
      
      this.props.getRouteTag(
        {
          company_id: this.props.companyId,
          route_id: this.props.match.params.routeId,
        },
        {
          onSuccess: (response) => {
            let tags = response.map((item) => item.tag);
            let stateUpdate = update(this.state, {
              selectedTags: { $set: tags },
              tags: { $set: response },
            });
            this.setState(stateUpdate);
          },
        }
      );

      this.props.loadChilds(
        {
          company_id: this.props.companyId,
          route_id: this.props.match.params.routeId,
        },
        {
          onSuccess: () => {
            let stateUpdate = update(this.state, {
              childs: { $set: this.props.childsCatalogArgs },
            });
            this.setState(stateUpdate);
          },
        }
      );

      this.props.getRouteNomenclatures({
        company_id: this.props.companyId,
        route_id: this.props.match.params.routeId,
      });

      this.props.getRouteFiles(
        {
          company_id: this.props.companyId,
          route_id: this.props.match.params.routeId,
        },
        {
          onSuccess: (response) => {
            let data = response.map((item) => {
              return {
                id: item.id,
                name: item.original_name,
                size: this.convertMbtoKb(item.file_size),
                url: item.url,
              };
            });
            let stateUpdate = update(this.state, {
              permissions: { $set: data },
            });
            this.setState(stateUpdate);
          },
        }
      );

      this.props.getRouteMaps(
        {
          company_id: this.props.companyId,
          route_id: this.props.match.params.routeId,
        },
        {
          onSuccess: (response) => {
            this.mapController.loadMap(null, null, null, "roadmap", false);
            let stateUpdate = update(this.state, {
              loadMap: { $set: true },
              ready: { $set: true },
            });
            this.setState(stateUpdate);
            this.props.getRoute(
              {
                company_id: this.props.companyId,
                route_id: this.props.match.params.routeId,
              },
              {
                onSuccess: (response) => {
                  let stateUpdate = update(this.state, {
                    idRoute: { $set: response.id },
                    code: { $set: response.code },
                    ownCode: { $set: response.code },
                    alias: { $set: response.alias },
                    ownAlias: { $set: response.alias },
                    origin: { $set: response.from_facility },
                    destination: { $set: response.to_facility },
                  });
                  this.setState(stateUpdate);
                },
                onError: () => {},
              }
            );
          },
        }
      );
    } else {
      this.mapController.loadMap(null, null, null, "roadmap", true);
    }
    this.mapController.isReadySubject.subscribe((ready) => {
      if (ready && this.state.ready) {
        if (this.props.originMarker.position) {
          this.setState({
            originMarker: this.mapController.setMarkerImage(
              getUrlsEnv().files.concat(this.props.originMarker.icon),
              {
                lat: this.props.originMarker.position[0],
                lng: this.props.originMarker.position[1],
              },
              { width: 25, height: 25 }
            ),
          });
          this.state.originMarker.setMap(this.mapController.map);
        }
        if (this.props.destinationMarker.position) {
          this.setState({
            destinationMarker: this.mapController.setMarkerImage(
              getUrlsEnv().files.concat(this.props.destinationMarker.icon),
              {
                lat: this.props.destinationMarker.position[0],
                lng: this.props.destinationMarker.position[1],
              },
              { width: 25, height: 25 }
            ),
          });
          this.state.destinationMarker.setMap(this.mapController.map);
        }

        if (Object.values(this.props.mapNomenclatures).length) {
          this.props.mapNomenclatures.forEach((item) => {
            let line2 = this.mapController.createPolyline(
              item.geolocations.map((data) => {
                return {
                  lat: data[1],
                  lng: data[0],
                };
              }),
              "#2f4ff1"
            );

            line2.setMap(this.mapController.map);
          });
        }

        if (
          this.props.originMarker.position &&
          this.props.destinationMarker.position
        ) {
          this.mapController.setCenterMapMultiple(
            this.props.originMarker.position[0],
            this.props.originMarker.position[1],
            this.props.destinationMarker.position[0],
            this.props.destinationMarker.position[1]
          );
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.origin &&
      this.state.destination &&
      (prevState.origin !== this.state.origin ||
        prevState.destination !== this.state.destination)
    ) {
      this.props.loadClasses(
        {
          company_id: this.props.companyId,
          from_facility: this.state.origin,
          to_facility: this.state.destination,
        },
        {
          onSuccess: () => {
            let stateUpdate = update(this.state, {
              classes: { $set: this.props.classesCatalogArgs },
            });
            this.setState(stateUpdate);
          },
        }
      );
    }
  }

  paintFacilityGeofences = (r, color) => {
    r.forEach(zone => {
      let coord = zone.polygon[0];
      coord.pop();
      let pol = this.mapController.createPolygon(
        coord.map((g) => ({ lat: g[0], lng: g[1] })),
        color
        // "#7ED321"
        // (GEOFENCE_TYPES[gF.type] || { color: color }).color
      );
      pol.setVisible(true);
      pol.setMap(this.mapController.map);
    })
  }

  getAliasError = (data) => {
    this.props.validateAlias(
      {
        company_id: this.props.companyId,
        alias: data,
      },
      {
        // onSuccess: () => console.log("yxed bien"),
        onError: () => {
          let stateUpdate = update(this.state, {
            dupliedAlias: { $set: true },
          });
          this.setState(stateUpdate);
        },
      }
    );
  };

  getCodeError = (data) => {
    this.props.validateCode(
      {
        company_id: this.props.companyId,
        code: data,
      },
      {
        onError: () => {
          let stateUpdate = update(this.state, {
            dupliedCode: { $set: true },
          });
          this.setState(stateUpdate);
        },
      }
    );
  };

  getFacilityData = (data, without) => {
    return data
      .map((e) => {
        return {
          // classID: e.id,
          description: e.description,
          items: e.items.filter(f => String(f.id) !== without).map(f => ({
              classID: f.id, 
              // description: `${f.name} - ${f.code} - ${f.alias}`,
              description: f.description,
              id: f.id
            })) ,
          id: e.id,
        };
      });
  };

  getTagsData = () => {
    return this.props.tagsCatalogArgs.map((tag) => ({
      id: tag.id,
      color: tag.color,
      title: tag.title,
      isFull: this.existTag(tag.id),
    }));
    // tagsCatalogArgs
  };

  getOperatorNumber = (item) => {
    if (item.tat_2 || item.outward_trip_time_two) {
      return 2;
    }
    return 1;
  };

  returnItemData = (first, second) => {
    if (second !== "" && second > 0) {
      return [first, second];
    }
    return [first];
  };

  getFacilitiesData = (child, type) => {
    if (this.getOperatorNumber(child) === 2) {
      if (type === "origin") {
        return [
          {
            type: child.from_operation_type,
            value: child.from_operation_time,
          },
          {
            type: child.from_operation_type,
            value: child.from_operation_time,
          },
        ];
      }
      return [
        {
          type: child.to_operation_type,
          value: child.to_operation_time,
        },
        {
          type: child.to_operation_type,
          value: child.to_operation_time,
        },
      ];
    } else {
      if (type === "origin") {
        return [
          {
            type: child.from_operation_type,
            value: child.from_operation_time,
          },
        ];
      }
      return [
        {
          type: child.to_operation_type,
          value: child.to_operation_time,
        },
      ];
    }
  };

  getReturn = (child) => {
    if (this.getOperatorNumber(child) === 2) {
      return [child.return_trip_time_one, child.outward_trip_time_two];
    }
    return [child.return_trip_time_one];
  };

  setNewChildRow = () => {
    let row = {
      editMode: true,
      id: -1,
      classId: 0,
      class: "",
      distance: 0,
      configuration: "",
      operators: [],
      tat: [],
      origin: [],
      transit: [0, 0],
      destination: [],
      return: [0, 0],
      sections: [],
    };
    let stateUpdate = update(this.state, {
      childs: { $push: [row] },
    });
    this.setState(stateUpdate);
  };

  setRow = (data) => {
    let childs = this.state.childs;
    let row = this.state.childs[data.key];
    row = {
      ...row,
      id: data.id,
      class: data.class,
      classId: parseInt(data.classId),
      editMode: false,
      transport_class: parseInt(data.classId),
      transport_class_code: data.class,
      distancia: data.distance,
      configuration: data.configuration,
      outward_trip_time_one: data.outward_trip_time_one,
      outward_trip_time_two: data.outward_trip_time_two,
      return_trip_time_one: data.return_trip_time_one,
    };
    if (this.generalEditMode()) {
      if (data.id) {
        this.props.updateChild(
          {
            company_id: this.props.companyId,
            route_id: this.state.idRoute,
            row_id: data.id,
            distancia: data.distance,
            configuration: data.configuration,
            outward_trip_time_one: data.outward_trip_time_one,
            outward_trip_time_two: data.outward_trip_time_two,
            return_trip_time_one: data.return_trip_time_one,
            transport_class: data.classId,
          },
          {
            onSuccess: () => {
              this.props.loadChilds(
                {
                  company_id: this.props.companyId,
                  route_id: this.state.idRoute,
                },
                {
                  onSuccess: () => {
                    let stateUpdate = update(this.state, {
                      childs: { $set: this.props.childsCatalogArgs },
                      rowEditMode: { $set: false },
                    });
                    this.setState(stateUpdate);
                  },
                }
              );
            },
          }
        );
      } else {
        this.props.postChilds(
          {
            company_id: this.props.companyId,
            route_id: this.state.idRoute,
            distancia: data.distance,
            outward_trip_time_one: data.outward_trip_time_one,
            outward_trip_time_two: data.outward_trip_time_two,
            return_trip_time_one: data.return_trip_time_one,
            transport_class: data.classId,
          },
          {
            onSuccess: () => {
              this.props.loadChilds(
                {
                  company_id: this.props.companyId,
                  route_id: this.state.idRoute,
                },
                {
                  onSuccess: () => {
                    let stateUpdate = update(this.state, {
                      childs: { $set: this.props.childsCatalogArgs },
                      rowEditMode: { $set: false },
                    });
                    this.setState(stateUpdate);
                  },
                }
              );
            },
          }
        );
      }
    } else {
      childs[data.key] = row;

      let stateUpdate = update(this.state, {
        childs: { $set: childs },
        rowEditMode: { $set: false },
      });
      this.setState(stateUpdate);
    }
  };

  getChildsData = () => {
    return this.state.childs.map((child) => {
      if (child.editMode) {
        if (!this.state.rowEditMode) {
          let stateUpdate = update(this.state, {
            rowEditMode: { $set: true },
          });
          this.setState(stateUpdate);
        }
        return child;
      } else {
        return {
          editMode: false,
          id: child.id,
          classId: child.transport_class,
          class: child.transport_class_code,
          distance: child.distancia,
          configuration: child.configuration,
          operators: this.getOperatorNumber(child),
          tat: this.returnItemData(child.tat_1, child.tat_2),
          origin: this.getFacilitiesData(child, "origin"),
          transit: this.returnItemData(
            child.outward_trip_time_one,
            child.outward_trip_time_two
          ),
          destination: this.getFacilitiesData(child, "destination"),
          return: this.getReturn(child),
          sections: child.sections,
        };
      }
    });
  };

  getItems = () => {
    return {
      facilities: {
        origin: {
          items: this.getFacilityData(
            this.props.facilitiesByTag,
            this.state.destination
          ),
          value: this.state.origin,
        },
        destination: {
          items: this.getFacilityData(
            this.props.facilitiesByTag,
            this.state.origin
          ),
          value: this.state.destination,
        },
      },
      alias: {
        value: this.state.alias,
        ownAlias: this.state.ownAlias,
        dupliedAlias: this.state.dupliedAlias,
        duplicated: this.getAliasError,
      },
      code: {
        value: this.state.code,
        ownCode: this.state.ownCode,
        dupliedCode: this.state.dupliedCode,
        duplicated: this.getCodeError,
      },
      tags: {
        items: this.getTagsData(),
        selectTagHandler: this.selectTagHandler,
      },
      nomenclature: {
        items: this.getNomenclatures(),
        handleInput: this.setFiles,
        removeFiles: this.removeFiles,
        files: this.state.nomenclatures,
      },
      permissions: {
        handleInput: this.setFiles,
        removeFiles: this.removeFiles,
        files: this.state.permissions,
      },
      zones: {
        convoys: {
          catalog: this.props.zones1,
          zones: this.state.zonesConvoy,
          handleInput: this.handleConvoyInput,
          addZone: this.handleAddConvoy,
          deleteZone: this.deleteConvoy
        },
        detention: {
          catalog: this.props.zones2,
          zones: this.state.zonesDetention,
          handleInput: this.handleDetentionInput,
          addZone: this.handleAddDetention,
          deleteZone: this.deleteDetention
        },
        tags: this.props.classesByTag || []
      }
    };
  };

  
  handleAddConvoy = () => {
    let newConvoy = this.state.zonesConvoy;
    newConvoy.push({
      id: this.state.maxConvoyId,
      selectedZoneClass: [],
      selectedZone: [],
    });
    this.setState({ zonesConvoy: newConvoy, maxConvoyId: this.state.maxConvoyId + 1 });
  };

  handleConvoyInput = (convoy, e) => {
    convoy[e.target.name] = e.target.value;
    if(e.target.name === "selectedZone") {
      convoy.selectedZoneClass = [];
    }
    let convoys = Array.from(this.state.zonesConvoy);
    let i = convoys.findIndex((d) => d.id === convoy.id);
    convoys[i] = convoy;
    this.setState({ zonesConvoy: convoys });
  };

  deleteConvoy = (convoyId) => {
    let convoys = this.state.zonesConvoy.filter(
      (c) => c.id !== convoyId
    );
    this.setState({ zonesConvoy: convoys });
  };

  handleAddDetention = () => {
    let newDetention = this.state.zonesDetention;
    newDetention.push({
      id: this.state.maxDetentionId,
      selectedZoneClass: [],
      selectedZone: 0,
      break_time: '0',
    });
    this.setState({ zonesDetention: newDetention, maxDetentionId: this.state.maxDetentionId + 1 });
  };

  handleDetentionInput = (detention, e) => {
    // debugger;
    detention[e.target.name] = e.target.value;
    if(e.target.name === "selectedZone") {
      detention.selectedZoneClass = [];
    }
    let detentions = Array.from(this.state.zonesDetention);
    let i = detentions.findIndex((d) => d.id === detention.id);
    detentions[i] = detention;
    this.setState({ zonesDetention: detentions });
  };

  deleteDetention = (detentionId) => {
    let detentions = this.state.zonesDetention.filter(
      (c) => c.id !== detentionId
    );
    this.setState({ zonesDetention: detentions });
  };

  getNomenclatures = () => {
    return this.props.nomenclatures.map((item) => ({
      nomenclature: item.nomenclatura,
    }));
  };

  setFiles = (e) => {
    if (this.props.editMode) {
      let file = e.target.value;
      let value = (this.state[e.target.name] || []).concat(file);
      this.setState({ [e.target.name]: value });
    }
  };

  removeFiles = (name, file) => {
    if (this.props.editMode) {
      var fileRemoved = false;

      let value = this.state[name].filter((fi) => {
        if (fileRemoved || fi.name !== file.name || fi.size !== file.size) {
          return true;
        }

        if (file.id) {
          this.props.deletePermission({
            company_id: this.props.companyId,
            route_id: this.state.idRoute,
            file_id: file.id,
          });
        }

        fileRemoved = true;
        return false;
      });

      this.handleInput({ target: { name: name, value: value } });
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  existTag = (name) => {
    if (this.state.selectedTags.find((tag) => String(tag) === String(name))) {
      return true;
    }
    return false;
  };

  selectTagHandler = (e) => {
    if (this.props.editMode) {
      let tags = this.state.selectedTags;
      if (this.existTag(e.target.name)) {
        tags = tags.filter((item) => item !== e.target.name);
      } else {
        tags.push(e.target.name);
      }
      let stateUpdate = update(this.state, {
        selectedTags: { $set: tags },
      });
      this.setState(stateUpdate);
    }
  };

  validForm = () => {
    if (
      this.state.origin !== 0 &&
      this.state.destination !== 0 &&
      this.state.alias &&
      this.state.alias.length > 0 &&
      !this.state.dupliedAlias &&
      this.state.code &&
      this.state.code.length > 0 &&
      !this.state.dupliedCode &&
      !this.state.rowEditMode
    ) {
      return false;
    }
    return true;
  };

  getFacilityInfo = (id) => {
    let item_to_return = null;

    (this.props.facilitiesByTag ?? []).map( group_item => {

      (group_item.items).map( facility_item => {
        if (String(id) === String(facility_item.id)) {
          item_to_return = facility_item;
        }
      })

    })
    return item_to_return;
  }

  getFacilitiesItemsData = () => { 
    let originItem = this.props.facilitiesCatalogArgs.find(
      (item) => String(item.id) === String(this.state.origin)
    );
    
    if (originItem === undefined) {
      originItem = this.getFacilityInfo(this.state.origin);
    }

    let destinationItem = this.props.facilitiesCatalogArgs.find(
      (item) => String(item.id) === String(this.state.destination)
    );

    if (destinationItem === undefined) {
      destinationItem = this.getFacilityInfo(this.state.destination);
    }
    
    let companyId = this.props.companyId;
    
    return {
      route: `${originItem.name} - ${destinationItem.name}`,
      origin: {
        company: originItem.company ?? companyId,
        facility: parseInt(originItem.id),
      },
      destination: {
        company: destinationItem.company ?? companyId,
        facility: parseInt(destinationItem.id),
      },
    };
  };

  createRoute = () => {
    const { route, origin, destination } = this.getFacilitiesItemsData();
    console.log("guardo");
    this.props.createRoute(
      {
        company_id: this.props.companyId,
        description: route,
        code: this.state.code,
        alias: this.state.alias,
        from_company: origin.company,
        from_facility: origin.facility,
        to_company: destination.company,
        to_facility: destination.facility,
        tags: this.state.selectedTags,
      },
      {
        onSuccess: (response) => {
          let routeId = response.id;

          this.state.nomenclatures.forEach((file) => {
            this.props
              .postUploadFileNomenclature(routeId, "xml", file)
              .then((response) => {})
              .catch((error) => {
                console.log("error", error);
              });
          });

          this.state.permissions.forEach((file) => {
            this.props
              .postUploadFileNomenclature(routeId, "files", file)
              .then((response) => {})
              .catch((error) => {
                console.log("error", error);
              });
          });

          this.state.childs.forEach((item) => {
            this.props.postChilds(
              Object.assign(item, {
                company_id: this.props.companyId,
                route_id: routeId,
              })
            );
          });

          this.props.history.push(`/company/routes/route/details/${routeId}`);
        },
      }
    );
  };

  updateRoute = () => {
    // console.log("update");
    this.props.updateRoute(
      {
        company_id: this.props.companyId,
        route_id: this.state.idRoute,
        code: this.state.code,
        alias: this.state.alias,
      },
      {
        onSuccess: (response) => {
          const origConvoyIds = this.state.originalConvoys.map(d => d.catalog_id);
          const currentConvoyIds = this.state.zonesConvoy.map(d => d.id);
          const origDetentionIds = this.state.originalDetention.map(d => d.catalog_id);
          const currentDetentionIds = this.state.zonesDetention.map(d => d.id);

          let newConvoys = this.state.zonesConvoy.filter(d => !origConvoyIds.includes(d.id));
          let removedConvoys = this.state.originalConvoys.filter(d => !currentConvoyIds.includes(d.catalog_id));
          let modifiedConvoys = [];

          this.state.zonesConvoy.filter(d => origConvoyIds.includes(d.id)).forEach(c => {
            const origDetail = this.state.originalConvoys.find(o => o.catalog_id === c.id);

            if(!c.selectedZone.includes(origDetail.map_zone_id.toString())) {
              newConvoys.push(c);
              removedConvoys.push(origDetail);
            } else {
              if(c.selectedZoneClass.toString() !== origDetail.current__map_zone__transport_classes.toString()){
                modifiedConvoys.push(c)
              }
            }
          });

          newConvoys = newConvoys.concat(this.state.zonesDetention.filter(d => !origDetentionIds.includes(d.id)));
          removedConvoys = removedConvoys.concat(this.state.originalDetention.filter(d => !currentDetentionIds.includes(d.catalog_id)));

          this.state.zonesDetention.filter(d => origDetentionIds.includes(d.id)).forEach(c => {
            const origDetention = this.state.originalDetention.find(o => o.catalog_id === c.id);

            if(!c.selectedZone.includes(origDetention.map_zone_id.toString())) {
              newConvoys.push(c);
              removedConvoys.push(origDetention);
            } else {
              if(c.selectedZoneClass.toString() !== origDetention.current__map_zone__transport_classes.toString()
               || c.break_time.toString() !== origDetention.break_time.toString()){
                modifiedConvoys.push(c);
              }
            }
          });

          newConvoys.forEach(c => {
            this.props.createRouteZone({
              company_id: this.props.companyId,
              route_id: this.state.idRoute,
              map_zone_id: parseInt(c.selectedZone[0]),
              transport_classes: c.selectedZoneClass,
              break_time: c.break_time || 0,
            })
          });

          removedConvoys.forEach(c => {
            this.props.deleteRouteZone({
              company_id: this.props.companyId,
              route_id: this.state.idRoute,
              zone_id: c.catalog_id,
            })
          });

          modifiedConvoys.forEach(c => {
            this.props.editRouteZone({
              company_id: this.props.companyId,
              route_id: this.state.idRoute,
              zone_id: c.id,
              transport_classes: c.selectedZoneClass,
              break_time: c.break_time || 0,
            })
          });

          let deletetags = this.state.tags.filter(
            (n) => !this.state.selectedTags.includes(n.tag)
          );
          if (Object.keys(deletetags).length) {
            deletetags.forEach((tag) => {
              this.props.deleteRouteTag({
                company_id: this.props.companyId,
                route_id: this.state.idRoute,
                tag_id: tag.id,
              });
            });
          }

          let newtags = this.state.selectedTags.filter(
            (n) => !this.state.tags.find((item) => item.tag === n)
          );

          if (newtags.length) {
            newtags.forEach((tag) => {
              this.props.postRouteTag({
                company_id: this.props.companyId,
                route_id: this.state.idRoute,
                route: this.state.idRoute,
                tag: tag,
              });
            });
          }

          this.state.nomenclatures.forEach((file) => {
            this.props
              .postUploadFileNomenclature(this.state.idRoute, "xml", file)
              .then((response) => {})
              .catch((error) => {
                console.log("error", error);
              });
          });

          this.state.permissions.forEach((file) => {
            if (!file.id) {
              this.props
                .postUploadFileNomenclature(this.state.idRoute, "files", file)
                .then((response) => {})
                .catch((error) => {
                  console.log("error", error);
                });
            }
          });

          this.props.history.push(
            `/company/routes/route/details/${this.state.idRoute}`
          );
        },
      }
    );
  };

  removeRow = (itemKey, type = false) => {
    let childs = this.state.childs;
    if (type) {
      let data = this.state.childs[itemKey];
      let row = {
        ...data,
        id: data.id,
        editMode: false,
        transport_class: parseInt(data.classId),
        transport_class_code: data.class,
        distancia: data.distance,
        outward_trip_time_one: data.transit[0] || 0,
        outward_trip_time_two: data.transit[1] || 0,
        return_trip_time_one: data.return[0] || 0,
      };

      childs[itemKey] = row;
    } else {
      childs = childs.filter((item, key) => {
        return key !== itemKey;
      });
    }

    let stateUpdate = update(this.state, {
      childs: { $set: childs },
      rowEditMode: { $set: false },
    });
    this.setState(stateUpdate);

    if (this.generalEditMode()) {
      let data = this.state.childs[itemKey];
      if (data.id && this.state.idRoute) {
        this.props.deleteChild({
          company_id: this.props.companyId,
          route_id: this.state.idRoute,
          row_id: data.id,
        });
      }
    }
  };

  updateRow = (key) => {
    // let childs = this.state.childs;
    let row = this.state.childs[key];
    let data = {
      editMode: true,
      id: row.id || 0,
      classId: row.classId || row.transport_class,
      class: row.class || row.transport_class_code,
      distance: row.distancia,
      operators: [],
      tat: [],
      origin: [],
      transit: [row.outward_trip_time_one, row.outward_trip_time_two],
      destination: [],
      return: [row.return_trip_time_one, row.outward_trip_time_two],
    };

    let stateUpdate = update(this.state, {
      childs: {
        [key]: { $set: data },
      },
      rowEditMode: { $set: false },
    });
    this.setState(stateUpdate);
  };

  getClassData = () => {
    let child = this.state.childs
      .filter((item) => {
        return !item.editMode;
      })
      .map((item) => {
        return item.class || item.transport_class_code;
      });

    return this.state.classes
      .filter((n) => !child.includes(n.code))
      .map((item) => {
        return {
          classID: item.id,
          description: item.code,
          id: item.id,
        };
      });
  };

  fieldsEditMode = () => {
    if (this.props.match.params.routeId && this.props.editMode) {
      return {
        select: true,
        input: false,
      };
    } else if (this.props.editMode) {
      return {
        select: false,
        input: false,
      };
    }

    return {
      select: true,
      input: true,
    };
  };

  generalEditMode = () => {
    if (this.props.match.params.routeId && this.props.editMode) {
      return true;
    }
    return false;
  };

  getTagsMode = () => {
    if(this.props.match.params.routeId) {
      if(this.props.editMode)
        return 2;
      return 1;
    } else {
      return 0;
    }
  }

  convertMbtoKb = (mb) => {
    mb = mb.replace(" MB", "");
    return mb * 1024 * 1024;
  };

  getTitle = () => {
    if (this.props.match.params.routeId && this.props.editMode) {
      return "Edición de ruta";
    } else if (this.props.editMode) {
      return "Registro de ruta";
    } else {
      return "Detalles de ruta";
    }
  };

  render() {
    return (
      <RoutesContainer>
        <Header>
          <HeaderTitle>{this.getTitle()}</HeaderTitle>
        </Header>
        <Content>
          <PanelHeader>
            <PanelTitle>
              Descripción
              {!this.generalEditMode() && this.state.idRoute !== 0 && (
                <Link to={`/company/routes/route/edit/${this.state.idRoute}`}>
                  <img src="/images/edit-transparent.svg" alt="" />
                </Link>
              )}
            </PanelTitle>
            <Line />
          </PanelHeader>
          <FieldsMaps>
            <FieldsMapsLeft>
              <ParentContainer>
                <RouteCatalogDetailsParent
                  data={this.getItems()}
                  handleInput={this.handleInput}
                  editMode={this.props.editMode}
                  tagsMode={this.getTagsMode()}
                  fieldsEditMode={this.fieldsEditMode()}
                  generalEditMode={this.generalEditMode()}
                />
              </ParentContainer>
            </FieldsMapsLeft>
            <FieldsMapsRight>
              <div className="map">
                {this.mapController.getMapContainer({
                  className: "map-view",
                })}
              </div>
            </FieldsMapsRight>
          </FieldsMaps>
          <ClassesContainer>
            <RouteCatalogDetailsClasses
              data={this.getChildsData()}
              setRow={this.setRow}
              // classesItems={this.getClassItems}
              classesItems={this.getClassData}
              removeRow={this.removeRow}
              updateRow={this.updateRow}
              editMode={this.props.editMode}
            />
          </ClassesContainer>
          {this.props.editMode && (
            <ClassAddItem>
              <img
                className={`${
                  this.state.origin === 0 ||
                  this.state.destination === 0 ||
                  this.state.rowEditMode
                    ? "disabled"
                    : ""
                }`}
                src="/images/add_button_circle.png"
                alt=""
                onClick={() =>
                  this.state.origin === 0 ||
                  this.state.destination === 0 ||
                  this.state.rowEditMode
                    ? null
                    : this.setNewChildRow()
                }
              />
            </ClassAddItem>
          )}
        </Content>
        <Footer>
          {this.props.editMode && (
            <Button
              settings={{
                type: "primary",
                text: !this.generalEditMode()
                  ? "Guardar ruta"
                  : "Actualizar ruta",
                disabled: this.validForm(),
                //   text: this.savingDataText(),
                onClick: !this.generalEditMode()
                  ? this.createRoute
                  : this.updateRoute,
              }}
              // dataTip={this.getErrors()}
              // dataFor="orbi-tooltip-error"
              // onMouseLeave={() => this.handleLeave()}
            />
          )}
        </Footer>
      </RoutesContainer>
    );
  }
}

const buildNetworkFacilitiesList = (value) => {

  let items_to_return = [];

  (value ?? []).map( network_facility => {

    let corporations = [];

    (network_facility.corporation ?? []).map( corporation_item => {

      corporation_item.id = `${corporation_item.id}`
      corporation_item.description = `${corporation_item.name} - ${corporation_item.code} - ${corporation_item.alias}`
      corporations.push(corporation_item);

    })

    items_to_return.push({
      id: `${network_facility.id}`,
      description: network_facility.title,
      items: corporations
    });

  })

  return items_to_return;
}

const mapStateToProps = (state) => {
  const nomenclatures = getEntityItems(
    state,
    "COMPANIES.ROUTES.DETAILS.NOMENCLATURES"
  );

  const maps = getEntityItems(state, "COMPANIES.ROUTES.DETAILS.MAPS");

  const facilitiesCatalogArgs = getEntityItems(
    state,
    "COMPANIES.ROUTES.FACILITIES"
  );
  const tagsCatalogArgs = getEntityItems(state, "COMPANIES.ROUTES.TAGS");
  const childsCatalogArgs = getEntityItems(
    state,
    "COMPANIES.ROUTES.DETAILS.CHILDS"
  );
  const classesCatalogArgs = getEntityItems(state, "COMPANIES.ROUTES.CLASSES");

  let originMarker = {};
  let destinationMarker = {};
  let mapNomenclatures = [];

  if (Object.values(maps).length) {
    originMarker = {
      position: maps.from_facility__geolocation,
      icon: maps.from_facility__avatar__image,
      name: maps.from_facility__code,
    };

    destinationMarker = {
      position: maps.to_facility__geolocation,
      icon: maps.to_facility__avatar__image,
      name: maps.to_facility__code,
    };

    // mapNomenclatures = maps.routes_map;
    mapNomenclatures = maps.routes_map.filter((item) => {
      return item.geolocations.length;
    });
  }

  const zones1 = getEntityItems(state, "COMPANIES.ROUTES.ZONES.CATALOG1");
  const zones2 = getEntityItems(state, "COMPANIES.ROUTES.ZONES.CATALOG2");
  
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  let classesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (state.companyReducer.companyExpeditionClasses.data || []).forEach((c) => {
    if ((c.tags || []).length) {
      c.tags.forEach((t) => {
        let tagIndex = classesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          classesByTag[tagIndex].items.push({
            id: c.id,
            description: c.code,
          });
        } else {
          let newTag = {
            id: t.id,
            description: (
              (companyTags || []).find((tag) => tag.id === t.id) || {
                title: "-",
              }
            ).title,
            items: [
              {
                id: c.id,
                description: c.code,
              },
            ],
          };
          classesByTag.push(newTag);
        }
      });
    } else {
      classesByTag[0].items.push({
        id: c.id,
        description: c.code,
      });
    }
  });
  classesByTag = classesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  let taglessClasses = classesByTag.shift();
  classesByTag = classesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  classesByTag.push(taglessClasses);

  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTagTemp = groupFacilities(companyFacilities);

  const network_facilities_temp = getEntityItems(state, "COMPANIES.ROUTES.NETWORKFACILITIES");
  const facilitiesByTag = buildNetworkFacilitiesList(network_facilities_temp).concat(facilitiesByTagTemp).sort( (a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
  
  // const routesFilters = getEntityItems(state, "COMPANIES.ROUTES.FILTERS");
  // let originFilter = [];
  // if (routesFilters.origin) {
  //   originFilter = Object.keys(routesFilters.origin).map(function(item, key) {
  //     return {
  //       id: key + 1,
  //       description: item,
  //       items: routesFilters.origin[item].map((item) => {
  //         return {
  //           id: item.id,
  //           description: `${item.name} - ${item.alias} - ${item.code}`,
  //         };
  //       }).sort((a, b) => {
  //         if (a.description.toLowerCase() > b.description.toLowerCase()) {
  //           return 1;
  //         }
  //         if (a.description.toLowerCase() < b.description.toLowerCase()) {
  //           return -1;
  //         }
  //         return 0;
  //       }),
  //     };
  //   });
  // }

  return {
    //network_facilities,
    maps,
    facilitiesCatalogArgs,
    tagsCatalogArgs,
    classesCatalogArgs,
    nomenclatures,
    originMarker,
    destinationMarker,
    mapNomenclatures,
    childsCatalogArgs,
    companyId: state.globalCatalog.session.company.id,
    zones1,
    zones2,
    classesByTag,
    // originFilter,
    facilitiesByTag
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(listExpeditionClasses());
  const getRouteMaps = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.DETAILS.MAPS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const loadfacilityCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.FACILITIES",
        { ...params, view: "routes" },
        { args: { ...params }, ...opts }
      )
    );
  
  const loadfacilityCatalogNetwork = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.NETWORKFACILITIES",
        { ...params, view: "routes" },
        { args: { ...params }, ...opts }
      )
    );

  const validateAlias = (params = {}, opts = {}) =>
    dispatch(
      readEntity(
        "COMPANIES.ROUTES.ALIAS",
        { ...params, view: "catalog" },
        { args: { ...params }, ...opts }
      )
    );

  const validateCode = (params = {}, opts = {}) =>
    dispatch(
      readEntity(
        "COMPANIES.ROUTES.CODE",
        { ...params, view: "catalog" },
        { args: { ...params }, ...opts }
      )
    );

  const loadTagsCatalog = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.TAGS",
        { ...params, type: "4" },
        { args: { ...params }, ...opts }
      )
    );

  const createRoute = (params = {}, opts = {}) =>
    dispatch(
      createEntity(
        "COMPANIES.ROUTES",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const updateRoute = (params = {}, opts = {}) =>
    dispatch(
      partialEntity(
        "COMPANIES.ROUTES",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const loadChilds = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.DETAILS.CHILDS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const loadClasses = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.CLASSES",
        { ...params, view: "routes" },
        { args: { ...params }, ...opts }
      )
    );

  const postChilds = (params = {}, opts = {}) =>
    dispatch(
      createEntity(
        "COMPANIES.ROUTES.DETAILS.CHILDS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const getRoute = (params = {}, opts = {}) =>
    dispatch(
      readEntity(
        "COMPANIES.ROUTES.DETAILS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const getRouteTag = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.DETAILS.TAGS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const deleteRouteTag = (params = {}, opts = {}) =>
    dispatch(
      deleteEntity(
        "COMPANIES.ROUTES.DETAILS.TAGS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const postRouteTag = (params = {}, opts = {}) =>
    dispatch(
      createEntity(
        "COMPANIES.ROUTES.DETAILS.TAGS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const deleteChild = (params = {}, opts = {}) =>
    dispatch(
      deleteEntity(
        "COMPANIES.ROUTES.DETAILS.CHILDS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const updateChild = (params = {}, opts = {}) =>
    dispatch(
      partialEntity(
        "COMPANIES.ROUTES.DETAILS.CHILDS",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const getRouteNomenclatures = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.DETAILS.NOMENCLATURES",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const getRouteFiles = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.ROUTES.DETAILS.FILES",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

  const deletePermission = (params = {}, opts = {}) =>
    dispatch(
      deleteEntity(
        "COMPANIES.ROUTES.DETAILS.FILES",
        { ...params },
        { args: { ...params }, ...opts }
      )
    );

    

  const loadZones1 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.CATALOG1",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const loadZones2 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.CATALOG2",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const loadZones3 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.CATALOG3",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const listRouteZones1 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.TYPE1",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const listRouteZones2 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.TYPE2",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const listRouteZones3 = (params = {}, opts = {}) =>
  dispatch(
    readEntities(
      "COMPANIES.ROUTES.ZONES.TYPE3",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const createRouteZone = (params = {}, opts = {}) =>
  dispatch(
    createEntity(
      "COMPANIES.ROUTES.ZONES",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const editRouteZone = (params = {}, opts = {}) =>
  dispatch(
    partialEntity(
      "COMPANIES.ROUTES.ZONES",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  const deleteRouteZone = (params = {}, opts = {}) =>
  dispatch(
    deleteEntity(
      "COMPANIES.ROUTES.ZONES",
      { ...params },
      { args: { ...params }, ...opts }
    )
  );
  // const loadFilters = (params = {}, opts = {}) =>
  //   dispatch(
  //     readEntities(
  //       "COMPANIES.ROUTES.FILTERS",
  //       {
  //         ...params,
  //         view: "filters",
  //         group: "tags",
  //       },
  //       { args: { ...params }, ...opts }
  //     )
  //   );

  const loadFacilities = (params = {}, opts = {}) =>
    dispatch(
      readEntities(
        "COMPANIES.FACILITIES",
        params,
        opts
      )
    );

  return {
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    // loadFilters,
    loadFacilities,
    loadZones1,
    loadZones2,
    loadZones3,
    listRouteZones1,
    listRouteZones2,
    listRouteZones3,
    createRouteZone,
    editRouteZone,
    deleteRouteZone,
    getRouteMaps,
    loadfacilityCatalog,
    loadfacilityCatalogNetwork,
    validateAlias,
    validateCode,
    loadTagsCatalog,
    createRoute,
    updateRoute,
    loadChilds,
    loadClasses,
    postChilds,
    getRoute,
    getRouteTag,
    deleteRouteTag,
    postRouteTag,
    deleteChild,
    updateChild,
    getRouteNomenclatures,
    getRouteFiles,
    deletePermission,
    postUploadFileNomenclature: (routeId, type_file, file) =>
      dispatch(postUploadFileNomenclature(routeId, type_file, file)),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(RouteCatalogDetailsView);

RouteCatalogDetailsView.defaultProps = {};
