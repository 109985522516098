import React from 'react';
import ReactDOM from "react-dom";
import Modal from 'react-modal';
import { getUrlsEnv } from '../backend-api';
import { MapController } from '../component/map-container';
import Button from '../controls/button';
import * as moment from "moment";
import "moment/locale/es";

const EDIT_MODE = 3;

const { files } = getUrlsEnv();

class AvailabilityReportMapModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      mapController: null
    };

    this.mapLoaded = false;
    this.trailerLocation = false;
  }

  componentDidUpdate() {
    if (this.props.data !== null && !this.mapLoaded && this.state.mapController === null) {
      this.setState({
        mapController: new MapController()
      },() => {
        this.loadMap();
        this.mapLoaded = true;
      });
    }

    if (this.props.data !== null && !this.trailerLocation && this.props.trailerLocation !== null) {
      this.trailerLocation = true;
      this.loadTrailerLocation();
    }
  }

  isFull = () => {
    var r1Plates = this.props.data['Placas remolque 1**'] || '';
    var r2Plates = this.props.data['Placas remolque 2***'] || '';
    return `${r1Plates}` !== '' && `${r2Plates}` !== ''
  }

  loadTrailerLocation = () => {

    var markerIcon = {};

    if (this.isFull()) {
      markerIcon.icon = "/images/icon_fill_truck_doble_dark.svg";
      markerIcon.width = 50;
      markerIcon.height = 15;
    } else {
      markerIcon.icon = "/images/truck.svg";
      markerIcon.width = 30;
      markerIcon.height = 20;
    }

    let marker = this.state.mapController.setMarkerImage(
      markerIcon.icon,
      this.props.trailerLocation,
      {
        width: markerIcon.width,
        height: markerIcon.height,
      }
    )

    marker.setMap(this.state.mapController.map);
        
    marker.addListener("click", () => {
      this.showInfo(1, this.props.trailerLocation);
    });

    this.centerMap();

  }

  loadMarkers = () => {

    let flagLocation = this.getAvailabilityLocation();

    let locationMarker = this.state.mapController.setMarkerImage(
      '/images/availability_icon.png',
      flagLocation,
      {
        width: 30,
        height: 30,
      }
    )

    locationMarker.setMap(this.state.mapController.map);
        
    locationMarker.addListener("click", () => {
      this.showInfo(2, flagLocation);
    });

  }

  getAvailabilityLocation = () => {
    let coordinates = this.props.data['availability_coord'] || [];
    if (coordinates.length > 1) {
      return {
        lat: coordinates[0],
        lng: coordinates[1],
      };
    }
  }

  centerMap = () => {

    var lat_min = 0;
    var lat_max = 0;
    var lng_min = 0;
    var lng_max = 0;

    let availabilityLocation = this.getAvailabilityLocation();

    if (availabilityLocation.lng > this.props.trailerLocation.lng) {
      lng_min = this.props.trailerLocation.lng;
      lng_max = availabilityLocation.lng;
    } else {
      lng_max = this.props.trailerLocation.lng;
      lng_min = availabilityLocation.lng;
    }

    if (availabilityLocation.lat > this.props.trailerLocation.lat) {
      lat_min = this.props.trailerLocation.lat;
      lat_max = availabilityLocation.lat;
    } else {
      lat_max = this.props.trailerLocation.lat;
      lat_min = availabilityLocation.lat;
    }

    this.state.mapController.setCenterMapMultiple(lat_min, lng_min, lat_max, lng_max);

  }

  showInfo = (type, data) => {
    const content = `<div id='infowindowitem' class='info-window-item' ></div>`;

    const position = new window.google.maps.LatLng(
      data.lat,
      data.lng
    );

    this.state.mapController.showInfoWindow(content, position, 0, -15, () => this.showInfoActions(type));
  };

  showInfoActions = (type) => {

    let infoWindow = document.getElementById("infowindowitem");

    if (type === 1) {
      ReactDOM.render(
        <div>
          {'Disponibilidad creada:'}
          <div>{moment(this.props.data['Fecha de creación de la disponibilidad*'] || '').format("DD MMMM YYYY HH:mm")}</div>
        </div>,
        infoWindow
      );
    } else {
      ReactDOM.render(
        <div>
          {'Disponible:'}
          <div>{moment(this.props.data['Fecha de la descarga de la disponibilidad'] || '').format("DD MMMM YYYY HH:mm")}</div>
          <div>{this.props.data['Instalación'] || ''}</div>
        </div>,
        infoWindow
      );
    }

  }

  loadMap = () => {
    let self = this;
    this.state.mapController.loadMap(null, null, null, 'roadmap', true);
    this.state.mapController.isReadySubject.subscribe((ready) => {
      if (ready) {
        window.google.maps.event.addListener(
          this.state.mapController.map,
          'click',
          function(event) {
            if(self.state.mode !== EDIT_MODE) self.state.mapController.hideInfoWindow();
          }
        );
        this.loadMarkers();
      }
    });
  }

  getContent = () => {
    let truck = {}
    return(
      <>
        <div className='row' style={{marginTop: 20}}>
          <div className='space-header'>
          {'Unidad'}
          </div>
          <div className='space-header'>
          {'Transportista'}
          </div>
          <div className='space-header' style={{flex: 0.7}}>
          {'Clase Exp.'}
          </div>
          <div className='space-header'>
          {'Contrato'}
          </div>
          <div className='space-header'>
          {'Disponibilidad creada'}
          </div>
          <div className='space-header'>
          {'Disponible'}
          </div>
        </div>
        <div className='row' style={{marginTop: 5}}>
          <div className='space'>
          {this.props.data['Vehículo*'] || ''}
          </div>
          <div className='space'>
            <img
              style={{
                height: 20,
                width: 20,
                borderRadius: 10,
                marginRight: 5
              }}
              alt=""
              src={`${files}${this.props.data['lt_logo'] || ''}`}
              onError={(e)=>{e.target.onerror = null; e.target.src="/images/factory-bckgrnd.png"}}
            />
          {this.props.data['Alias transportista'] || ''}
          </div>
          <div className='space' style={{flex: 0.7}}>
          {this.props.data['Grupo de clase de expedición'] || ''}
          </div>
          <div className='space'>
          {`${this.props.data['Instalación'] || ''}-${this.props.data['Operación'] || ''}`}
          </div>
          <div className='space'>
          {moment(this.props.data['Fecha de creación de la disponibilidad*'] || '').format("DD MMMM YYYY HH:mm")}
          </div>
          <div className='space'>
          {moment(this.props.data['Fecha de la descarga de la disponibilidad'] || '').format("DD MMMM YYYY HH:mm")}
          </div>
        </div>
        <div className='map'>
          { this.state.mapController.getMapContainer({ className: 'map-view' }) }
        </div>
      </>
    );
  }

  render() {
    return(
      <Modal
        isOpen={this.props.data !== null}
        portalClassName="dialog availabilitty-report-map-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{'Unidad disponible'}</div>
        <div className='subTitle'>{'Ubica la unidad disponible en el mapa'}</div>
        <div className="close" onClick={() => {
          this.setState({
            mapController: null
          }, () => {
            this.mapLoaded = false;
            this.trailerLocation = false;
            this.props.onClose();
          });
        }}></div>

        {(this.props.data !== null && this.state.mapController !== null) && this.getContent()}

        <div style={{height: 30}}/>
        {/* {
          !this.props.isCargoOwner &&
          <div style={{display: 'flex', flexDirection: 'row-reverse', marginRight: 50, marginBottom: 20}}>
            <Button
              text={'Editar'}
              type={'primary'}
              onClick={() => {
                this.setState({
                  mapController: null
                }, () => {
                  this.mapLoaded = false;
                  this.trailerLocation = false;
                  this.props.openEdit(this.props.data);
                });
              }}
            />
          </div>
        } */}

      </Modal>
    );
  }

}

export default AvailabilityReportMapModal;