import {
  ApiOptions,
  readEntities,
  readEntity,
  deleteEntity,
  readCatalog,
  createEntity,
  updateEntity,
  cancelRequest,
  partialEntity,
} from "../../redux/api";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";

export const AssembleViewApi = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.VEHICLETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.FLEETCLASSES"));
  dispatch(readEntities("COMPANIES.CATALOGS.OPERATORLICENSETYPES"));

  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    getAssemblyActions: (params = {}, opts = {}) => {
      dispatch(
        readEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.ACTIONS",
          params,
          opts
        )
      )},
    loadSingleAssemblies: (params = {}, opts = {}) => {
      dispatch(
        readCatalog(
          "COMPANIES.FLEETS.ASSEMBLIES.SINGLE",
          params,
          opts
        )
      )},
    linkAssemblies: (params = {}, opts = {}) => 
      dispatch(
        partialEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.SINGLE.JOINED",
          params,
          opts
        )
      ),
    loadMotives: (
      params: { company_id: number, type: 1 | 2 | 3 },
      opts: ApiOptions
    ) =>
      dispatch(readCatalog("COMPANIES.FLEETS.CATALOG.MOTIVES", params, opts)),
    // listFleetData: (company_id, fleet_id) =>
    //   dispatch(readEntities("COMPANIES.FLEETS.DATA", { company_id, fleet_id })),
    // listFleetOperators: (company_id, fleet_id) =>
    //   dispatch(
    //     readEntities("COMPANIES.FLEETS.OPERATORS", { company_id, fleet_id })
    //   ),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    listFleetResourceCounters: (
      company_id,
      opts
    ) =>{
      dispatch(
        readEntity(
          "COMPANIES.FLEETCATALOG.COUNTERS",
          { company_id },
          opts
        )
      )},
    listFleetResourceCatalog: (
      company_id,
      type_id,
      view = "unassembled",
      economic_number = "",
      plates = "",
      name = "",
      search = "",
      opts
    ) =>
      dispatch(
        readCatalog(
          "COMPANIES.FLEETCATALOG",
          { company_id, type_id, view, economic_number, plates, name, search },
          opts
        )
      ),
    createAssemble: (company_id, fleet_id, status, opt) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES",
          {
            company_id,
            fleet_id,
            status,
          },
          opt
        )
      ),
    
    createAssembleWithComponents: (params, opt) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.CREATION",
          params,
          opt
        )
      ),
    
    deleteAssembleWithComponents: (params, opt) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.CREATION",
          params,
          opt
        )
      ),

    createAssembleVehicle: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_vehicle,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLES",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_vehicle,
            scheduledate,
          },
          opt
        )
      ),

    createAssembleEquipment: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_equipment,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTS",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_equipment,
            scheduledate,
          },
          opt
        )
      ),
    createAssembleOperator: (
      company_id,
      fleet_id,
      assembly_id,
      fleet_operator,
      scheduledate,
      opt
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORS",
          {
            company_id,
            fleet_id,
            assembly_id,
            fleet_operator,
            scheduledate,
          },
          opt
        )
      ),

    listAssemblies: (
      company_id,
      fleet_id,
      params: ListAssembliesQueryParams,
      opt
    ) =>
      dispatch(
        readCatalog(
          "COMPANIES.FLEETS.ASSEMBLIES",
          {
            company_id,
            fleet_id,
            ...params,
          },
          opt
        )
      ),
    listCompanyFleets: (company_id, opt) => {
      dispatch(readEntities("COMPANIES.FLEETS", { company_id }, opt));
    },

    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    deleteAssembleVehicleLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLELOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    deleteAssembleOperatorLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    deleteAssembleEquipmentLog: (company_id, fleet_id, assembly_id, id, opts) =>
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
          },
          opts
        )
      ),
    updateAssembleEquipmentLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_equipment,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.EQUIPMENTLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_equipment,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    updateAssembleVehicleLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_vehicle,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.VEHICLELOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_vehicle,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    updateAssembleOperatorLog: (
      company_id,
      fleet_id,
      assembly_id,
      id,
      scheduledate,
      fleet_operator,
      assembly_substitute,
      opts: ApiOptions
    ) =>
      dispatch(
        updateEntity(
          "COMPANIES.FLEETS.ASSEMBLIES.OPERATORLOGS",
          {
            company_id,
            fleet_id,
            assembly_id,
            id,
            scheduledate,
            fleet_operator,
            assembly_substitute,
            assembly: assembly_id,
          },
          opts
        )
      ),
    getUserTags: (
      params: { company_id: number, user_id: number },
      options: ApiOptions
    ) => dispatch(readEntities("COMPANIES.USERS.USERSTAGS", params, options)),
  };
};
