// @flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import { selectModalContent } from "../assemble-utils";
import type { SimpleEvent } from "../../../shared/types/";
import Loader from "../../../shared/icons/tat/loader";

type Props = {
  // FleetDatas: any,
  unasembledEquipments: any,
  unasembledOperators: any,
  operatorLicenseTypes: any,
  unasembledVehicles: any,
  showSubstituteModal: boolean,
  assembleSummaryResources: any,
  original: any,
  // listFleetOperators: any,
  listFleetResourceCatalog: any,
  companyId: number,
  companyFleets: any,
  onChange: Function,
  // listFleetData: Function,
  updateAssembleEquipmentLog: Function,
  updateAssembleVehicleLog: Function,
  updateAssembleOperatorLog: Function,
  fleetVehicleTypesClasses: Function,
  onSearch: Function,
};
type State = {
  vehiclesData: Array<any>,
  equipmentsData: Array<any>,
  operators: Array<any>,
  selectedItem: any,
  selectedDate: number,
  modalSearchText: string,
};

class AssembleSubstituteResource extends React.Component<Props, State> {
  static defaultProps = {
    manageError: () => {},
  };
  modalOpeningDate = Date.parse(new Date().toISOString());
  state = {
    loading: false,
    vehiclesData: [],
    equipmentsData: [],
    operators: [],
    selectedItem: {},
    selectedDate: this.modalOpeningDate,
    modalSearchText: "",
  };

  componentDidMount() {
    this.manageSubstituteModal({
      target: { name: "substituteResource", value: "" },
    });
  }
  // componentDidUpdate(prevProps: Props) {
  //   if (
  //     prevProps.showSubstituteModal === false &&
  //     this.props.showSubstituteModal === true
  //   ) {
  //     this.manageSubstituteModal({
  //       target: { name: "substituteResource", value: "" },
  //     });
  //   }
  // }
  render() {
    const {
      showSubstituteModal,
      // FleetDatas,
      unasembledEquipments,
      unasembledOperators,
      operatorLicenseTypes,
      unasembledVehicles,
      fleetVehicleTypesClasses,
      companyTags
    } = this.props;
    const {
      modalSearchText,
      vehiclesData,
      equipmentsData,
      operators,
      selectedItem,
      selectedDate,
    } = this.state;
    return (
      <BaseDialog
        open={showSubstituteModal}
        className="create-assemble-dialog"
        closeAction={this.manageSubstituteModal}
        acceptAction={() =>
          this.manageSubstituteModal({
            target: { name: "substitute", value: "" },
          })
        }
        title="Sustituir recurso"
        contentObject={selectModalContent({
          selectedDate: selectedDate,
          modalSearchText: modalSearchText,
          operatorLicenseTypes: operatorLicenseTypes,
          unasembledEquipments: unasembledEquipments,
          unasembledOperators: unasembledOperators,
          unasembledVehicles: unasembledVehicles,
          fleetVehicleTypesClasses: fleetVehicleTypesClasses,
          onlyOne: false,
          // fleetDatas: FleetDatas,
          companyTags: companyTags,
          vehiclesData: vehiclesData,
          equipmentsData: equipmentsData,
          operators: operators,
          step: this.props.original?.type,
          addingDolly: this.props.addingDolly,
          isEditing: true,
          handleChange: this.handleChange,
          modalOpeningDate: this.modalOpeningDate,
          onSearch: this.handleSearch,
        })}
        style={{ content: { width: "1100px", marginLeft: "-550px" } }}
        showCancel={true}
        cancelText="Cancelar"
        acceptText={this.state.loading ? <Loader /> : "Guardar"}
        acceptButtonType="primary"
        disabledAccept={
          !(
            selectedItem && selectedItem?.id !== this.props.original?.resourceId
          ) || this.state.loading
        }
      />
    );
  }

  handleSearch = (modalSearchText: string) => {
    this.setState({ modalSearchText: modalSearchText });
    this.props.onSearch(modalSearchText, this.props.original.type);
  };

  manageSubstituteModal = (e: SimpleEvent) => {
    const { name } = e.target;
    const {
      assembleSummaryResources,
      // listFleetData,
      // listFleetOperators,
      listFleetResourceCatalog,
      companyId,
      // companyFleets,
      onChange,
    } = this.props;
    switch (name) {
      case "substituteResource":
        // listFleetData(companyId, companyFleets[0].id);
        // listFleetOperators(companyId, companyFleets[0].id);
        [1, 2, 3].forEach((number) =>
          listFleetResourceCatalog(
            companyId,
            number,
            "unassembled",
            "",
            "",
            "",
            "",
            {
              alias: `catalogType${number}Counter`,
            }
          )
        );
        this.setState({
          vehiclesData: assembleSummaryResources.vehicles,
          equipmentsData: assembleSummaryResources.equipments,
          operators: assembleSummaryResources.operators,
        });
        break;
      case "close":
        onChange({
          target: { name: "showSubstituteModal", value: false },
        });
        break;
      case "cancel":
        onChange({
          target: { name: "showSubstituteModal", value: false },
        });
        break;
      case "substitute":
        this.updateAssembleResource();
        break;
      case "saved":
        this.setState({
          vehiclesData: undefined,
          equipmentsData: undefined,
          operators: undefined,
        });
        onChange({
          target: { name: "saved", value: false },
        });
        break;
      default:
    }
  };

  updateAssembleResource() {
    this.setState({loading: true});
    const { selectedItem } = this.state;
    const { original, companyId, companyFleets } = this.props;

    switch (original.type) {
      case 1:
        this.props.updateAssembleVehicleLog(
          companyId,
          companyFleets[0].id,
          original.assembleId,
          original.logId,
          new Date(selectedItem.schedule).toISOString(),
          selectedItem.id,
          original.lineId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "saved" } })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      case 2:
        this.props.updateAssembleEquipmentLog(
          companyId,
          companyFleets[0].id,
          original.assembleId,
          original.logId,
          new Date(selectedItem.schedule).toISOString(),
          selectedItem.id,
          original.lineId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "saved" } })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      case 3:
        this.props.updateAssembleOperatorLog(
          companyId,
          companyFleets[0].id,
          original.assembleId,
          original.logId,
          new Date(selectedItem.schedule).toISOString(),
          selectedItem.id,
          original.lineId,
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "saved" } })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageSubstituteModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      default:
    }
  }

  handleChange = (e: SimpleEvent) => {
    const { name, value } = e.target;
    const {
      operators,
      equipmentsData,
      vehiclesData,
      selectedDate,
      selectedItem,
    } = this.state;
    const { original, fleetVehicleTypesClasses } = this.props;
    switch (name) {
      case "operators":
        const operator = {
          ...value,
          schedule: selectedDate,
        };
        this.setState({
          operators: operators
            .filter(
              (o) => (o.fleet_operator_id || o.id) !== original.resourceId
            )
            .filter((o) => (o.fleet_operator_id || o.id) !== value.id)
            .filter((o) => (o.fleet_operator_id || o.id) !== selectedItem?.id)
            .concat([value]),
          selectedItem: operator,
        });
        break;
      case "equipmentsData":
        const equipment = {
          ...value,
          schedule: selectedDate,
          description: `${value.number}/${value.plates}`,
        };
        this.setState({
          equipmentsData: equipmentsData
            .filter((e) => e.id !== original.resourceId)
            .filter((e) => e.id !== value.id)
            .filter((e) => e.id !== selectedItem?.id)
            .concat([equipment]),
          selectedItem: equipment,
        });
        break;
      case "vehiclesData":
        const vcl = fleetVehicleTypesClasses
          .filter((f) => f.type === 1)
          .find((f) => f.classes.find((c) => c.id === value.class))
          .classes.find((c) => c.id === value.class);
        const vehicle = {
          ...value,
          schedule: selectedDate,
          description: `${vcl.description}/${value.number}/${value.plates}`,
        };
        this.setState({
          vehiclesData: vehiclesData
            .filter((e) => (e.fleet_data_id || e.id) !== original.resourceId)
            .filter((e) => (e.fleet_data_id || e.id) !== value.id)
            .filter((e) => (e.fleet_data_id || e.id) !== selectedItem?.id)
            .concat([vehicle]),
          selectedItem: vehicle,
        });
        break;
      case "selectedDate":
        this.setState({ selectedDate: Date.parse(value) });
        if (selectedItem.schedule)
          this.setState({
            selectedItem: { ...selectedItem, schedule: Date.parse(value) },
          });
        // switch (step) {
        //   case 1:
        //     this.setState({
        //       vehiclesData: vehiclesData.map((v) => ({
        //         ...v,
        //         schedule: value,
        //       })),
        //     });
        //     break;
        //   case 2:
        //     this.setState({
        //       equipmentsData: equipmentsData.map((e) => ({
        //         ...e,
        //         schedule: value,
        //       })),
        //     });
        //     break;
        //   case 3:
        //     this.setState({
        //       operators: operators.map((o) => ({
        //         ...o,
        //         schedule: value,
        //       })),
        //     });
        //     break;
        //   default:
        // }
        break;
      default:
        this.setState({ [name]: value });
    }
  };
}

export default AssembleSubstituteResource;
