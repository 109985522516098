import React, { createRef } from "react";
import { connect } from "react-redux";

import * as SharedTypes from "../../shared/types";

import { Flex, flexOpts } from "../../shared/styled/flex";
import CalendarSelectorRibbon from "../../control/shipment/styled/calendar-selector-ribbon";
import { getRibbonDates } from "../../control/shipment/utils";
import { CollapsibleHeaderHeat } from "../../control/shipment/styled/collapsible-header";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import WeekPicker from "../../control/shipment/week-picker";
import { getLastSession, LOG_FORMAT } from "../../shared/utils";
import { SERVER } from "../../redux/api/api-urls";
// import Axios from "axios";
import { cancelRequest, readEntity } from "../../redux/api";

type Props = {};
type State = { currentDate: Date, selectedDate: Date, selectedDateId: string };

const scrollRef = createRef()

class GSPActivityHeatmap extends React.Component<Props, State> {

  state = {
    currentDate: new Date(),
    selectedDate: new Date(),
    selectedDateId: new Date().toISOString().split("T")[0],
    device: '',
    receive: '',
    processed: '',
    location: '',
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = (id) => {
    return `${SERVER}/api/v2/companies/${this.props.companyId}/gps_activities/${id}/`;
  };

  getDates(id){
    this.props.cancelRequest("COMPANIES.GPSACTIVITY");
    this.props.getGpsActivity({
      company_id: this.props.companyId,
      id: id
    }, {
      onSuccess: (r) => {
        this.setState({
          device: getLastSession(r.device_time_stamp, LOG_FORMAT),
          location: r.id,
          processed: getLastSession(r.processed, LOG_FORMAT),
          receive: getLastSession(r.received, LOG_FORMAT)
        })
      }
    })
    // Axios({
    //   url: this.getUrl(id),
    //   method: "GET",
    //   headers: this.getToken(),
    // }).then((response) => {
    //   let { id, device_time_stamp, processed, received } = response.data
    //   this.setState({
    //     device: getLastSession(device_time_stamp, LOG_FORMAT),
    //     location: id,
    //     processed: getLastSession(processed, LOG_FORMAT),
    //     receive: getLastSession(received, LOG_FORMAT)
    //   })
    // })
  }

  resetScrollbar = () => {
    scrollRef.current.scrollTo(0, 0);
  }

  getContent = () => {
    if(!this.props.filterApplied){
      return (
        <div style={{minHeight: 400, height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', opacity: 0.7, top: 0, left: 0, fontWeight: 'bold', fontSize: 22.0, backdropFilter: 'blur(3px)', color: 'black'}}>
          {'Selecciona los filtros para visualizar el reporte de actividad de GPS'}
        </div>
      )
    } else if (this.props.isLoading) {
      return (
        <div style={{minHeight: 400, height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', opacity: 0.7, top: 0, left: 0, fontWeight: 'bold', fontSize: 22.0, backdropFilter: 'blur(3px)', color: 'black'}}>
          <div className="loader">
          <div className="progress-loader"></div>
            <p className="title">Cargando...</p>
          </div>
        </div>
      );
    } else if(this.props.data && this.props.data.length === 0){
        return (
          <div style={{minHeight: 400, height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', opacity: 0.7, top: 0, left: 0, fontWeight: 'bold', fontSize: 22.0, backdropFilter: 'blur(3px)', color: 'black'}}>
            {'No hay resultados, prueba con otra busqueda'}
          </div>
        )
    } else {
      return (
        <Flex
        direction={flexOpts.direction.column}
        style={{
          width: "100%",
          minHeight: "400px",
          maxHeight: "400px",
          overflowY: "scroll"
        }}
      >
        {this.props.data.map((row) => (
          <CollapsibleHeaderHeat
            maxExpandedHeight="auto"
            collapsedHeight="40px"
            title={<div className="title" style={{fontSize: 15, paddingLeft: 15, marginRight: -25}} >{row.title}</div>}
            caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
            initialStateCollapsed={true}
            headerContent={ <div/> }
          >
            {row.content.map((data) => (
              <Flex>
                <div
                  style={{
                    width: '15%',
                    fontSize: 15,
                    fontWeight: 'normal',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {data.title}
                </div>
                <Flex
                  className={'gps-activity-scroll'}
                  style={{
                    width: "85%",
                    height: "30px",
                    overflow: 'hidden'
                  }}
                >
                  {
                    TIME_ARRAY.map((d, i) => (
                      this.getBoxColor(i, d, data?.data || {} )
                    ))
                  }
                </Flex>
              </Flex>
            ))}
          </CollapsibleHeaderHeat>
        ))}
        <ReactTooltip
          id={`tooltip-connection`}
          clickable={true}
          effect="solid"
          place={"top"}
          type="light"
          // delayHide={0}
          // data-class={null}
          // className={null}
          // afterShow={() => {
          //   this.setState({
          //     device: '',
          //     receive: '',
          //     processed: '',
          //     location: '',
          //   })
          // }}
        >
          <div>
            <h3>{`Dispositivo: ${this.state.device || '-'}`}</h3>
            <h3>{`Recibido: ${this.state.receive || '-'}`}</h3>
            <h3>{`Procesado: ${this.state.processed || '-'}`}</h3>
            <h3>{`Coordenada: ${this.state.location || '-'}`}</h3>
          </div>
        </ReactTooltip>
      </Flex>

      );
    }

  }

  render() {

    const { selectedDate } = this.state;

    return (
      <GPSPanel>
        <div style={{ width: "100%", height: "auto", color: "white" }}>

          <Flex
            justifyContent={flexOpts.justifyContent.center}
            alignItems={flexOpts.alignItems.center}
            style={{ backgroundColor: "#212A3C" }}
          >
            <div style={{ width: "auto", minWidth: "20%" }} />
            <CalendarSelectorRibbon
              bgColor="#212A3C"
              selectedUnderlineColor="#E9643F"
              onChange={this.handleChangeDay}
              items={getRibbonDates(this.state.currentDate, "WEEKDAY")}
              activeId={this.state.selectedDateId}
            />
            <div style={{ width: 40, minWidth: 40 }} />
            <WeekPicker
              locale="es"
              name="calendar"
              date={selectedDate}
              onChange={this.handleChange}
            />
          </Flex>

          <Flex
            direction={flexOpts.direction.reverseRow}
            style={{ width: "100%" }}
          >
            <div style={{height: '30px', width: '85%', display: 'flex', flexDirection: 'row', overflow: 'hidden', fontSize: 14}} className={'gps-activity-scroll'}>
              {
                TIME_ARRAY.filter( (value) => { 

                  if (value.includes('23:45')) {
                    return true;
                  }

                  return !(value.includes(':15') || value.includes(':45')) 

                }).map( (value) => <div style={{width: value.includes('23:45') ? 43 : 30, minWidth: value.includes('23:45') ? 43 : 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: (value.includes('23:30') || value.includes('23:45')) ? 0 : 30}}>  { value.includes('23:45') ? '' : value } </div> )
              }
            </div>
          </Flex>

          { this.getContent() }

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse'}}>
            <div
              ref={scrollRef}
              onScroll={e => {
                const r = document.getElementsByClassName( 'gps-activity-scroll')
                Array.from(r).forEach( a => (a.scrollLeft = scrollRef.current.scrollLeft) )
              }}
              style={{ width: '85%', overflowY: 'scroll'}}
            >
              <div
                style={{
                  width: TIME_ARRAY.length * 30,
                  height: 10
                }}
              ></div>
            </div>
          </div>

          <Flex
            justifyContent={flexOpts.justifyContent.flexEnd}
            style={{ width: "auto", marginTop: 20, marginRight: 30}}
          >
            <div
              style={{
                alignSelf: "center",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold",
                letterSpacing: "0",
                lineHeight: "25px",
                marginRight: "6px"
              }}
            >
              Conexión
            </div>
            {this.props.colors.filter((j) => {

              if (this.props.loadType === 'received') {
                return !(`${j.id}` === '6' || `${j.id}` === '3' || `${j.id}` === '2' )
              } else {
                return !(`${j.id}` === '6')
              }

            }).map((r) => (
              <div
                style={{
                  width: "85px",
                  color: "white",
                  marginLeft: "20px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end'
                }}
              >
                <div
                  style={{textAlign: 'center', fontSize: 12, width: '100%' }}
                >
                  {r.description}

                  {
                    (`${r.id}` === '1') ? 
                    <div
                      style={{
                        backgroundColor: `#${r.color}`,
                        width: "100%",
                        height: "8px",
                        border: '1px solid #465477',
                      }}
                    />
                    :
                    <div
                      style={{
                        backgroundColor: `#${r.color}`,
                        width: "100%",
                        height: "8px",
                      }}
                    />
                  }
                </div>
              </div>
            ))}
          </Flex>
          <div style={{height: 30, minHeight: 30}}/>
        </div>
      </GPSPanel>
    );
  }

  getBoxColor = (index, time, data) => {

    var color = 'rgba(255, 255, 255, 0)';
    var id = -1;

    let timeFinded = data.find( item => time === `${item.time}` );
    if(timeFinded !== undefined){
      id = timeFinded.id;
      let colorFinded = this.props.colors.find( color => (`${color.id}` === `${timeFinded.color}` && `${color.id}` !== '1') );
      if(colorFinded !== undefined){
        color = colorFinded.color
      }
    }
    
    if (color === 'rgba(255, 255, 255, 0)') {
      return <div
        style={{
          minWidth: 26,
          width: 26,
          height: 26,
          margin: 1,
          backgroundColor: color,
          borderRadius: 2,
          border: '1px solid #465477',
          marginLeft: index === 0 ? 4 : 1
        }}
      />
    } else {
      return id !== -1 ? <React.Fragment>
        <div
          value={id}
          data-tip
          // data-for={`tooltip-${index}-${time}-${id}`}
          data-for={'tooltip-connection'}
          onMouseEnter={ () => { this.getDates(id) } }
          style={{
            minWidth: 28,
            width: 28,
            height: 28,
            margin: 1,
            backgroundColor: color,
            borderRadius: 2,
            marginLeft: index === 0 ? 4 : 1
          }}
        />
        {/*<ReactTooltip
          id={`tooltip-${index}-${time}-${id}`}
          clickable={true}
          effect="solid"
          place={"top"}
          type="light"
          // delayHide={0}
          // data-class={null}
          // className={null}
          // afterShow={() => {
          //   this.setState({
          //     device: '',
          //     receive: '',
          //     processed: '',
          //     location: '',
          //   })
          // }}
        >
          <div>
            <h3>{`Dispositivo: ${this.state.device || '-'}`}</h3>
            <h3>{`Recibido: ${this.state.receive || '-'}`}</h3>
            <h3>{`Procesado: ${this.state.processed || '-'}`}</h3>
            <h3>{`Coordenada: ${this.state.location || '-'}`}</h3>
          </div>
        </ReactTooltip>*/}
      </React.Fragment>
      : <div
          style={{
            minWidth: 28,
            width: 28,
            height: 28,
            margin: 1,
            backgroundColor: color,
            borderRadius: 2,
            marginLeft: index === 0 ? 4 : 1
          }}
        />
    }

  }

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "calendar":
        this.setState(
          {
            selectedDate: value,
            selectedDateId: value.toISOString().split("T")[0],
            currentDate: value,
          },
          () => this.props.handleDate(this.state)
        );
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  handleChangeDay = (e: SharedTypes.SimpleEvent) => {
    const { currentDate } = this.state;
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d }, () => {
        this.props.handleDate(this.state);
      });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      }, () => {
        this.props.handleDate(this.state);
      });

    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        },
        () => {
          this.props.handleDate(this.state);
        }
      );
    }
  };
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    getGpsActivity: (params, opt) => 
      dispatch(readEntity("COMPANIES.GPSACTIVITY", params, opt))
  }
}

// export default GSPActivityHeatmap;


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GSPActivityHeatmap);

const REFERENCE = [
  { id: 1, color: "rgba(255, 255, 255, 1)", description: "Vacio" },
  { id: 2, color: "rgba(14, 164, 1, 1)",  description: "0 - 15 min"},
  { id: 3, color: "rgba(164, 21, 1, 1)", description: "Sin coord" },
  { id: 4, color: "rgba(196, 193, 193, 1)", description: "No ensamble" },
  { id: 5, color: "rgba(255, 255, 255, 1)", description: "Sin contrato" },
];

const TIME_ARRAY = [
  '00:00', '00:15', '00:30', '00:45', '01:00', '01:15', '01:30', '01:45', '02:00', '02:15', '02:30', '02:45', '03:00', '03:15', '03:30', '03:45', '04:00', '04:15',
  '04:30', '04:45', '05:00', '05:15', '05:30', '05:45', '06:00', '06:15', '06:30', '06:45', '07:00', '07:15', '07:30', '07:45', '08:00', '08:15', '08:30', '08:45', '09:00',
  '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00',
  '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45',
  '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45',
  '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45',
];

const ROWS = 
[
  {
    title: "OCCIDENTE",
    content: [{ title: "FE00-GDL", data: [{id: 1, time: '13:00'}] }],
  },
  // {
  //   title: "CENTRO",
  //   content: [{ title: "FE05-CENTRO", data: [3, 3, 3, 3, 3, 3, 3] }],
  // },
  // {
  //   title: "CENTRO 2",
  //   content: [
  //     { title: "FE05-CENTRO", data: [2, 2, 2, 2, 2, 2, 2] },
  //     { title: "FE05-CENTRO 1", data: [2, 2, 2, 2, 2, 5, 5] },
  //     { title: "FE05-CENTRO 2", data: [2, 2, 2, 2, 2, 2, 2] },
  //     { title: "FE05-CENTRO 3", data: [2, 3, 3, 3, 4, 3, 3] }
  //   ],
  // }
];

const GPSPanel = styled.div`
  border-radius: 12px;
  background: linear-gradient(180deg, #404d6e 0%, #2f3b52 100%);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
  margin: 10px 0;


`;
