//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
// import { Flex, flexOpts } from "../../../shared/styled/flex";
// import styled from "styled-components";
// import SVGIcon from "../../../shared/svg-icon";
// import ModalSelectInput from "../modal-select-input";

const AppointmentSummaryModal = ({
  show,
  data,
  onAccept,
  onClose,
  hourName,
  ...props
}) => {
  return (
    <BaseDialog
      open={show}
      title={"Detalle de Hora ".concat(hourName)}
      message=""
      cancelText="Cerrar"
      acceptText="Control de ordenes"
      showCancel={true}
      cancelButtonType="primary"
      acceptButtonType="primary"
      // disabledAccept={reasonSelect === 0}
      // isAcceptLoading={isCancelingLoading}
      acceptAction={onAccept}
      closeAction={onClose}
      contentObject={
        <ModalContent
          {...data}
        />
      }
      style={{ content: { width: "600px", marginLeft: "-300px" } }}
    />
  );
};
export default AppointmentSummaryModal;

const ModalContent = (props) => {
  return (
    <div>
      <div style={{display: "flex", justifyContent: "space-around", marginBottom: "15px"}}>
        <div style={{width: "33%"}}>
          <div style={{fontSize: "16px", textAlign: "center"}}>Citas Agendadas</div>
          <div style={{fontSize: "24px", color: "#3D77F7", marginTop: "10px", fontWeight: "bold", textAlign: "center"}}>{props.scheduled}</div>
        </div>
        <div style={{width: "33%"}}>
          <div style={{fontSize: "16px", textAlign: "center"}}>Espacios disponibles</div>
          <div style={{fontSize: "24px", color: "#3D77F7", marginTop: "10px", fontWeight: "bold", textAlign: "center"}}>{props.free}</div>
        </div>
        <div style={{width: "33%"}}>
          <div style={{fontSize: "16px", textAlign: "center"}}>Conflictos</div>
          <div style={{fontSize: "24px", color: "#f73d46", marginTop: "10px", fontWeight: "bold", textAlign: "center"}}>{props.conflicts}</div>
        </div>
      </div>
      <div style={{overflow: "auto", maxHeight: "250px", display: "flex"}}>
        <div style={{width: "37%"}}>
          {
            props.appointmentsPerHour.map(a => (
              <div style={{fontSize: "16px", fontWeight: "bold", height: "25px", display: "flex"}}>
                <div style={{width: "60px"}}>{a.time}</div>{`${a.count} cita(s)`}
              </div>
            ))
          }
        </div>
        <div>
          {
            props.windowsPerHour.map(a => (
              <div style={{fontSize: "16px", fontWeight: "bold", height: "25px", display: "flex"}}>
                <div style={{width: "60px"}}>{a.time}</div>{`${a.count} espacio(s)`}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
