import React from "react";
// import Button from "../../shared/controls/button";
import PrevSwitch from "../../shared/controls/prev-switch";
// import SelectInputStyled from "../../shared/controls/select-input-styled";
// import TextInput from "../../shared/controls/text-input";
import AlertGroup from "./company-alert-group";
// import AlertLevel from "./alert-level-config";
// import PrevSwitch from "../controls/prev-switch";


class CompanyAlertRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      // inputErrors: {}
    };
  }
  manageCollapse = e => {
    // if (this.state.collapsed) {
    //   this.props.alert.levels.forEach(lvl => {
    //     if (lvl.id) this.props.loadEmailList(lvl.id);
    //   });
    // }
    this.setState({collapsed: !this.state.collapsed});
  };

  // getIndex = (index) => {
  //   console.log(this.state.inputErrors[index.toSTring()]);

  // }

  render() {
    return (
      <div className={"alert-row ".concat(this.props.className)}>
        <div className="alert-title">
          {this.props.alert.name}
          <div>
            <PrevSwitch
              name="active"
              checked={this.props.alert.is_active}
              handleClick={e => this.props.onAlertDataChange({
                  target: {
                    name: "active",
                    value: this.props.alert.id,
                    checked: e
                  }
                }, "active", this.props.alert.id)
              }
            />
            <div
              className={"collapser".concat(
                this.state.collapsed ? " collapsed" : ""
              )}
              onClick={this.manageCollapse}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
          </div>
        </div>
        <div
          className={"alert-content".concat(
            this.state.collapsed ? " collapsed" : ""
          )}
        >
          {
            !this.state.collapsed && 
            <>
              <div className="alert-levels-container">
              {
                (this.props.alert.groups || []).map((group) => {
                  // let takenFacilities = a.map((g, ii) => {
                  //   if(ii === i) return null;
                  //   return g.facilities.map(u => u.toString())
                  // }).flat();
                  let takenFacilities = (this.props.takenFacilities || []).filter(f => !(group.facilities || []).includes(f));
                  let takenBases = (this.props.takenBases || []).filter(b => !(group.bases || []).includes(b));
                  return (
                  <AlertGroup 
                    alert={this.props.alert}
                    group={group}
                    facilities={this.props.facilities}
                    takenFacilities={takenFacilities}
                    bases={this.props.baseTags}
                    takenBases={takenBases}
                    users={this.props.users}
                    mainSwitch={this.props.alert.is_active}
                    companyType={this.props.companyType}

                    onAlertGroupDataChange={this.props.onAlertGroupDataChange}
                  />
                )
              })}
              </div>
              <div 
                className={"add-button".concat(this.props.alert.groups.some(g => g.edit) ? " blocked" : "")}
                onClick={(e) => {if(!this.props.alert.groups.some(g => g.edit))this.props.onAlertGroupDataChange(e, "add", this.props.alert.id)}}  
              >
                +
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default CompanyAlertRow;