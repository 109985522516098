import React from "react";
import { Container, MainHeader, BuyContainer } from "./style";
import PaymentsBuyRow from "./buy/buy-row";
import { withRouter } from "react-router-dom";

class PaymentsBuy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getRows = () => {
    return [
      {
        id: 1,
        title: "100",
        qty: 100,
        price: 1.45,
        editable: false,
        iva: 10
      },
      {
        id: 2,
        title: "200",
        qty: 200,
        price: 1.30,
        editable: false,
        iva: 10
      },
      {
        id: 3,
        title: "500",
        qty: 500,
        price: 1.15,
        editable: false,
        iva: 10
      },
      {
        id: 4,
        title: "+1000",
        qty: 1000,
        price: 0.99,
        editable: true,
        iva: 10
      },
    ];
  };

  changeEditable = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validEditable = (e) => {
    if (parseInt(e.target.value) < 1000) {
      this.setState({ [e.target.name]: 1000 });
    }
  };

  render() {
    return (
      <Container>
        <MainHeader>
          <div className="full">
            <div className="title">Comprar Orbibucks</div>
          </div>
        </MainHeader>
        <BuyContainer>
          <PaymentsBuyRow
            data={this.getRows()}
            changeEditable={this.changeEditable}
            validEditable={this.validEditable}
            payAction={ (data) => {
              this.props.history.push('/company/payments/buy/shopping', { data: [data] });
            }}
          />
        </BuyContainer>
      </Container>
    );
  }
}


export default withRouter(PaymentsBuy);
