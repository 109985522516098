import React from "react";
import AlertList from "../../shared/component/alert-list";
import NotificationList from "../../shared/component/notification-list";

class FacilityConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "alertas"
    };
  }
  changeMode = e => {
    this.setState({ mode: e.target.id });
  };

  render() {
    return (
      <div className="facility-config">
        <div className="title">
          4.1 Configuración
          <div className="tabs">
            <span
              className={this.state.mode === "alertas" ? "selected" : ""}
              id="alertas"
              onClick={this.changeMode}
            >
              Alertas
            </span>
            {/* <span
              className={this.state.mode === "notificaciones" ? "selected" : ""}
              id="notificaciones"
              onClick={this.changeMode}
            >
              Notificaciones
            </span> */}
          </div>
        </div>
        <div className="content">
          <p className="intro">
            Activa y configura el tipo de {this.state.mode} que vas a necesitar:
          </p>
          {this.state.mode === "alertas" ? (
            <AlertList
              facility={this.props.facility}
              facilityId={this.props.facility}
            />
          ) : (
            <NotificationList
              facility={this.props.facility}
              facilityId={this.props.facility}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FacilityConfig;
