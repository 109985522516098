import React from 'react';
import Modal from 'react-modal';

class AvailabilityColumnsSettingsModal extends React.Component {

  getHeaderRow = () => {
    return(
      <div className='row' style={{marginTop: 20, marginBottom: 20}}>
        <div style={{width: 40}}>{'#'}</div>
        <div style={{width: 300}}>{'Nombre de la columna'}</div>
        <div style={{width: 50}}>{'Mostrar'}</div>
      </div>
    );
  }

  isValueFound = (value) => {
    var itemsToHide = this.props.itemsToHide || [];
    return itemsToHide.find( item => {
      return item === value;
    });
  }

  updateValue = (value) => {
    var itemsToHide = this.props.itemsToHide || [];

    if (itemsToHide.find( item => {
      return item === value;
    })) {
      itemsToHide = itemsToHide.filter( item => {
        return item !== value
      });
    } else {
      itemsToHide = itemsToHide.concat(value);
    }

    this.props.updateItems(itemsToHide);
  }

  getContet = () => {
    return(
      <>
        {this.getHeaderRow()}
        <div className='rows-content'>
          {
            (this.props.columns || []).map( (item, index) => {
              return(
                <div className='row' key={index}>
                  <div style={{width: 40}}>{index + 1}</div>
                  <div style={{width: 280, maxWidth: 300, paddingLeft: 20}}>{item.title}</div>
                  <div style={{width: 50, display: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                    <img alt="" src={ this.isValueFound(item.key) ? '/images/toggle_off.png' : '/images/toggle_on.png'} style={{ width: 24, height: 24}} onClick={ () => {
                      this.updateValue(item.key);
                    }}/>
                  </div>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }

  render() {
    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog availability-columns-settings-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{'Configurar columnas'}</div>
        <div className='subTitle'>{'Selecciona las columnas que deseas ver'}</div>
        <div className="close" onClick={() => {this.props.onClose()}}></div>

        { this.getContet() }

      </Modal>
    );
  }

}

export default AvailabilityColumnsSettingsModal;