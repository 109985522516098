import React from 'react'
import styled from 'styled-components'
import BaseCard from './base-card'


const AppointmentCard = ({
  icon,
  caption,
  onClick,
  childClick,
  conflictingIds,
  color,
  fontColor,
  orders,
  onDrop,
  id,
  free,
  close,
  unused,
  ...props
}) => {
  // const DraggableComponent = order => {
  //   const [, dragAppointment] = useDrag({
  //     item: { type: dndTypes.APPOINTMENT, orderId: order.id },
  //     collect: monitor => ({
  //       isDragging: !!monitor.isDragging()
  //     })
  //   })
  //   return dragAppointment
  // }

  // const [{ isOverAppointment }, dropAppointment] = useDrop({
  //   accept: dndTypes.APPOINTMENT,
  //   drop: item => onDrop({ begin: props.begin, spaceId: props.spaceId }, item),
  //   collect: monitor => ({
  //     isOverAppointment: !!monitor.isOver()
  //   })
  // })

  return (
    <BaseCard id={'card-'.concat(id)} /* rf={dropAppointment} */ color={color} unused={unused} free={free} close={close} {...props} onClick={onClick}>
      {
        !free && !close && !unused && (
          <Wrapper /* isOverAppointment={isOverAppointment} */ fontColor={fontColor}>
            {/* <span>{icon}</span> */}
            {/* <span>{caption}</span> */}
            {orders.map((order, key) => (
              <ConflictCard
                key={key}
                background={order.color}
                begin={order.begin}
                end={order.end}
                border={fontColor}
                // color={order.color}
                fontColor={order.fontColor}
                onClick={() => {
                  childClick(order)
                }}
                opacity={order.searched === false ? '0.5' : '1'}
                // ref={DraggableComponent(order)}
              >
                {
                  order.shared &&
                  order.shared.map(aO => (
                    <ConflictDescriptionWrapper
                      fontColor={aO.fontColor}
                      background={aO.color}
                    >
                      <div>{parseInt(aO.code)}</div>
                      <div>{aO.opIcon}</div>
                      {aO.expClass && <ConflictExpClassTitle>{aO.expClass}</ConflictExpClassTitle>}
                      <div></div>
                      <div>{aO.level || "-"}</div>
                    </ConflictDescriptionWrapper>
                  ))
                }
                <ConflictDescriptionWrapper
                  fontColor={order.fontColor}
                  background={order.color}
                >
                  <div>{parseInt(order.code)}</div>
                  <div>{order.opIcon}</div>
                  {order.expClass && <ConflictExpClassTitle>{order.expClass}</ConflictExpClassTitle>}
                  <div></div>
                  <div>{order.level || "-"}</div>
                </ConflictDescriptionWrapper>
                <ConflictContentWrapper>
                  {/* {content} */}
                  <div>{order.fleet_provider}</div>
                  <div style={{display: "flex", alignItems: "center"}}>{order.icon}{order.plates && <PlatesWrapper>{order.plates}</PlatesWrapper>}</div>
                </ConflictContentWrapper>
                {/* <ConflictCardText>
                  {order.code}
                  {
                    order.icon &&
                    <div>
                      {order.icon}
                    </div>
                  }
                </ConflictCardText>
                <ConflictClass>{order.expClass}</ConflictClass> */}
              </ConflictCard>
            ))}
          </Wrapper>
        )
      }
      
    </BaseCard>
  )
}

export default AppointmentCard;

AppointmentCard.defaultProps = {
  icon: null,
  className: null,
  caption: null,
  onClick: () => {},
  childClick: () => {},
  conflictingIds: [],
  color: null,
  fontColor: '#ffffff',
  orders: []
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  height: 100%;
  width: 100%;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
  overflow-y: auto;

  ${({ isOverAppointment }) =>
    isOverAppointment && 'border: 0.2rem solid orange; box-sizing: border-box;'}
`

const ConflictCard = styled.div`
  flex: 1;
  opacity: ${({ opacity }) => opacity};
  background: ${({ background }) => background};
  color: ${({ fontColor }) => fontColor};
  text-align: left;
  margin-left: ${({ begin }) => begin};
  margin-right: ${({ end }) => end};
  font-size: 10px;
  // display: flex;
  border-radius: 0.2rem;
  // padding: 0 0.2rem;
  border: 1px solid ${({ border }) => border};
  cursor: pointer;
  overflow: hidden;
`

const ConflictCardText = styled.div`
  flex: auto;
`

const ConflictClass = styled.div`
  flex: auto;
  border: 1px solid;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  height: 0.8rem;
`

const ConflictDescriptionWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  flex-direction: row;
  // align-items: flex-start;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  // margin-top: 3px;
  // margin-left: 2px;
  background: ${({ background }) => background};
  color: ${({ fontColor }) => fontColor};
  border-radius: .2rem .2rem 0 0;
`

const ConflictContentWrapper = styled.div`
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 10px;
`

const ConflictExpClassTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // height: 1rem;
  height: .8rem;
  width: auto;
  // opacity: 0.72;
  // min-width: 1rem;
  min-width: .8rem;
  // font-size: 10px;
  font-size: 8px;
  border: 1px solid;
  border-radius: 1.6rem;
  // margin-left: 0.2rem;
  // padding: 2px;
  padding: 0px;
`

const PlatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: .8rem;
  width: auto;
  font-size: 9px;
  border: 1px solid;
  border-radius: .2rem;
  padding: 0 4px;
`