import React from 'react';
import Modal from 'react-modal';
import InputSearch from '../controls/input-search';
import Button from '../controls/button';
import SwitchElementRow from '../component/switch-element-row';

class SwitchElementModal extends React.Component {
    cancelButtonSettings = {
        text: 'Cancelar',
        onClick: () => this.onClose(),
        type: 'primary outline'
    };
    saveButtonSettings = {
        text: 'Guardar',
        onClick: (() => {})
    };
    onClose = () => {
        this.props.closeAction && this.props.closeAction();
    }
    render() {
        return (
            <Modal
                isOpen = {this.props.open}
                portalClassName="dialog emails-modal switch-modal"
                overlayClassName="overlay"
                className="content"
                shouldCloseOnOverlayClick = {true}
            >
                <div className="title">{this.props.title}</div>
                <div className="close" onClick={this.onClose}></div>
                <div className="message">
                    <p>
                        {this.props.intro}
                    </p>
                    <div>
                        <InputSearch name="elem-search" type="text" label="Buscar usuarios"/>
                    </div>
                    <div className="switch-rows-container">
                        {
                            this.props.elements.map(user => {
                                return(
                                    <SwitchElementRow key={user.email} user={user} />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="actions">
                    <Button settings={this.cancelButtonSettings}/>
                    <Button settings={this.saveButtonSettings}/>
                </div>
            </Modal>
        )
    }
}

export default SwitchElementModal;

SwitchElementModal.defaultProps = {
    elements: [
    {
        name: 'Juan',
        lastName: 'López',
        avatar: '/images/guy-sinclair.png',
        type: 'Admin',
        email: 'j.lo@starkindustries.com'
    },
    {
        name: 'Ángel',
        lastName: 'Sánchez',
        avatar: '/images/guy-sinclair.png',
        type: 'Warehouser',
        email: 'a.fi@starkindustries.com'
    },
    {
        name: 'Joel',
        lastName: 'Alcantara',
        avatar: '/images/guy-sinclair.png',
        type: 'Admin',
        email: 'joelo@starkindustries.com'
    },
    {
        name: 'Rigo',
        lastName: 'Pérez',
        avatar: '/images/guy-sinclair.png',
        type: 'Warehouser',
        email: 'rigo@starkindustries.com'
    },
    {
        name: 'Ulises',
        lastName: 'Gómez',
        avatar: '/images/guy-sinclair.png',
        type: 'Admin',
        email: 'uli@starkindustries.com'
    },
]
}