// @flow
import React, { Component } from "react";
import type { ComponentType } from "react";
import { DownloadIcon } from "../../company/routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import Loader from "../../shared/icons/tat/loader";
import styled from "styled-components";
import { Fragment } from "react";
import ReactTooltip from "react-tooltip";
// import PropTypes from "prop-types";

type Props = {
  activeId: number | string,
  items: Array<{ id: number | string, description: string }>,
  onChange: Function,
  className?: string,
  bgColor?: string,
  selectedUnderlineColor?: string,
  // onDragOver?: Function,
};

class TabSelectorRibbon extends Component<Props> {
  static defaultProps = {
    bgColor: "#1a202e",
    selectedUnderlineColor: "#3d77f7",
    className: "",
    showSelected: true,
    addDownloadIcon: false,
    disableDownload: false,
    disableMessage: '',
    downloadingFile: false,
    badge: false,
    textColor: "#fff",
    onDragOver: () => {},
    isCalendar: false
  };
  handleClick = (e) => {
    if (e.target.attributes["name"]) this.props.onChange(e);
  };

  getBadge = (position) => {
    switch (position) {
      case 0:
        return this.props.badges.approved_request !== null ? this.props.badges.approved_request : 0
      case 1:
        return this.props.badges.received_request !== null ? this.props.badges.received_request : 0
      case 2:
        return this.props.badges.sent_request !== null ? this.props.badges.sent_request : 0
      case 3:
        return this.props.badges.sent_recommendation !== null ? this.props.badges.sent_recommendation : 0
      default:
        return 0
    }
  }

  getView = () => {
    return (this.props.isCalendar 
    ? <>
        {this.props.items.map((item, i) => (
          <TabSelectorRibbonItem
            key={item.id}
            name={item.id}
            selected={
              item.id === this.props.activeId && this.props.showSelected
            }
            color={this.props.selectedUnderlineColor}
            textColor={this.props.textColor}
            onDragEnter={(e) => {e.stopPropagation(); this.props.onDragOver(e)}}
          >
            {
              this.props.badge && this.props.badges && this.getBadge(i) > 0 ? 
              <a style={{ pointerEvents: "none" }}>
                <span>{item.description}</span>
                <div class="badge">{ this.getBadge(i) }</div>
              </a>
              : item.description
            }
          </TabSelectorRibbonItem>
        ))}
      </>
    : this.props.activeId === "list" || (this.props.activeId === 1 && this.props.items.length > 0) || this.props.addDownloadIcon ? <Fragment>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {this.props.items.map((item, i) => (
            <TabSelectorRibbonItem
              key={item.id}
              name={item.id}
              selected={
                item.id === this.props.activeId && this.props.showSelected
              }
              color={this.props.selectedUnderlineColor}
              textColor={this.props.textColor}
              onDragEnter={(e) => {e.stopPropagation(); this.props.onDragOver(e)}}
            >
              {
                this.props.badge && this.props.badges && this.getBadge(i) > 0 ? 
                <a style={{ pointerEvents: "none" }}>
                  <span>{item.description}</span>
                  <div class="badge">{ this.getBadge(i) }</div>
                </a>
                : item.description
              }
            </TabSelectorRibbonItem>
          ))}
        </div>

        <div style={{marginLeft: "auto", padding: "16px", display: 'flex', flexDirection: 'row'}}>

          {/* Validate if rightcomponent has a component to show */}
          { this.props.rightComponent && this.props.rightComponent }
          
          <DownloadIcon data-tip data-for={"disabled-message"}>
          { 
            (this.props.downloadingFile) ? 
              <Loader width="40px" height="40px" /> :
            (this.props.getUrl) ?
              <DownloadImage
                fill={this.props.disableDownload ? "#FFFFFF6B" : "#FFFFFF"}
                width={"50px"}
                height={"50px"}
                onClick={(e) => {
                  if(this.props.disableDownload) return;
                  e.stopPropagation(); 
                  this.props.openDocument(this.props.getUrl());
                }}
              /> : <div/>
          }
          </DownloadIcon>
          {
            this.props.disableDownload &&
            <ReactTooltip
              id={"disabled-message"}
              delayHide={50}
              delayShow={50}
              delayUpdate={200}
              place={"left"}
              border={true}
              type={"light"}
              effect="solid"
            >
              {`${this.props.disableMessage}`}
            </ReactTooltip>
          }
        </div>
      </Fragment> :
      <Fragment>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          {this.props.items.map((item, i) => (
            <TabSelectorRibbonItem
              key={item.id}
              name={item.id}
              selected={
                item.id === this.props.activeId && this.props.showSelected
              }
              color={this.props.selectedUnderlineColor}
              textColor={this.props.textColor}
              onDragEnter={(e) => {e.stopPropagation(); this.props.onDragOver(e)}}
            >
              {
                this.props.badge && this.props.badges && this.getBadge(i) > 0 ? 
                <a style={{ pointerEvents: "none" }}>
                  <span>{item.description}</span>
                  <div class="badge">{ this.getBadge(i) }</div>
                </a>
                : item.description
              }
            </TabSelectorRibbonItem>
          ))}
        </div>
        
        <div style={{marginLeft: "auto", padding: "16px", display: 'flex', flexDirection: 'row'}}>
          { this.props.rightComponent && this.props.rightComponent }
        </div>
      </Fragment>)
  }

  render() {
    return (
      <TabSelectorRibbonStyled
        // className={"tab-selector-ribbon ".concat(this.props.className)}
        bgColor={this.props.bgColor}
        className={this.props.className}
        onClick={this.handleClick}
        // bgColor={this.props.bgColor}
      >
        { this.getView() }
      </TabSelectorRibbonStyled>
    );
  }
}

export default TabSelectorRibbon;

// TabSelectorRibbon.propTypes = {
//   activeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//       description: PropTypes.string,
//     })
//   ),
//   onChange: PropTypes.func,
//   className: PropTypes.string,
// };

// TabSelectorRibbon.defaultProps = {
//   // activeId: 0,
//   // items: [],
//   // onChange: () => console.log("Not implemented yet!"),
//   // className: "",
//   showSelected: true,
// };

const TabSelectorRibbonStyled: ComponentType<{ bgColor: string }> = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;

  // &.control-ribbon {
  //   border-radius: 12px 12px 0 0;
  // }
`;

const TabSelectorRibbonItem: ComponentType<{
  selected: boolean,
  color: string,
  textColor: string,
}> = styled.div`
  margin: 16px;
  opacity: ${({ selected }) => (selected ? "1" : "0.6")};
  color: ${({textColor}) => textColor};
  font-size: 16px;
  // line-height: 50px;
  text-align: center;

  padding: 13px 26px 17px;
  position: relative;
  display: inline-block;

  box-sizing: border-box;
  border-bottom: 6px
    ${({ selected, color }) => (selected ? color : "transparent")} solid;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  & .badge {
    position: absolute;
    //top: -10px;
    //right: -10px;
    //width: 32px;
    //height: 32px;
    //line-height: 35px;
    //border-radius: 50%;
    //background-color: red;
    color: white;
    
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
  }
`;
