import {
  BACKEND,
  postMultipartApiUrl,
  interpolate,
} from "../../shared/backend-api";
import store from "../store";

const ACTIONS = {
  ROUTES: {
    NOMENCLATURE: {
      FILES: {
        REQUEST: {
          TYPE: "ROUTES_NOMENCLATURE_FILES_REQUEST",
          ACTION: () => ({
            type: ACTIONS.ROUTES.NOMENCLATURE.FILES.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ROUTES_NOMENCLATURE_FILES_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.ROUTES.NOMENCLATURE.FILES.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ROUTES_NOMENCLATURE_FILES_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.ROUTES.NOMENCLATURE.FILES.ERROR.TYPE,
            error,
          }),
        },
      },
    },
  },
};

export function postUploadFileNomenclature(route_id, type_file, file) {
  let company_id = store.getState().globalCatalog.session.company.id;

  const urlToUploadImage = interpolate(BACKEND.ROUTES.NOMENCLATURE.FILES, {
    company_id,
    route_id,
    type_file,
  });

  return function (dispatch) {
    dispatch(ACTIONS.ROUTES.NOMENCLATURE.FILES.REQUEST.ACTION());

    let formData = new FormData();
    formData.append("url", file);

    return postMultipartApiUrl(urlToUploadImage, formData)
      .then(
        (response) => response.json(),
        (error) => {
          dispatch(ACTIONS.ROUTES.NOMENCLATURE.FILES.ERROR.ACTION(error));
          return { error };
        }
      )
      .then((data) => {
        dispatch(ACTIONS.ROUTES.NOMENCLATURE.FILES.SUCCESS.ACTION(data));
        if (data.error) {
          return data;
        }
      });
  };
}
