import React, { Component } from "react";
import styled from "styled-components";
import TableSortable from '../../shared/component/table-sortable/table-sortable';

const columnsData = [
  {
    key: 'id',
    title: 'id'
  },
  {
    key: 'created',
    title: 'created'
  },
  {
    key: 'modified',
    title: 'modified'
  },
  {
    key: 'brand',
    title: 'brand'
  },
  {
    key: 'model',
    title: 'model'
  },
  {
    key: 'imei',
    title: 'imei'
  },
  {
    key: 'battery',
    title: 'battery'
  },
  {
    key: 'position',
    title: 'position'
  },
  {
    key: 'ram_used',
    title: 'ram_used'
  },
  {
    key: 'connected',
    title: 'connected'
  },
  {
    key: 'version',
    title: 'version'
  },
  {
    key: 'speed',
    title: 'speed'
  },
  {
    key: 'eta',
    title: 'eta'
  },
  {
    key: 'os',
    title: 'os'
  },
  {
    key: 'os_version',
    title: 'os_version'
  },
  {
    key: 'charging',
    title: 'charging'
  },
  {
    key: 'using_network_time',
    title: 'using_network_time'
  },
  {
    key: 'using_network_timezone',
    title: 'using_network_timezone'
  },
  {
    key: 'device_datetime',
    title: 'device_datetime'
  },
  {
    key: 'assembly',
    title: 'assembly'
  },
  {
    key: 'company',
    title: 'company'
  },
  {
    key: 'container',
    title: 'container'
  },
  {
    key: 'device',
    title: 'device'
  },
  {
    key: 'fleet_operator',
    title: 'fleet_operator'
  },
  {
    key: 'motum_data',
    title: 'motum_data'
  },
  {
    key: 'plates',
    title: 'plates'
  },
  {
    key: 'time_zone',
    title: 'time_zone'
  },
  {
    key: 'from_company',
    title: 'from_company'
  },
  {
    key: 'device',
    title: 'device'
  },
  
]

const columnsDataMotum = [
  {
    key: 'id',
    title: 'id'
  },
  {
    key: 'created',
    title: 'created'
  },
  {
    key: 'modified',
    title: 'modified'
  },
  {
    key: 'log_date',
    title: 'log_date'
  },
  {
    key: 'fleet',
    title: 'fleet'
  },
  {
    key: 'alias',
    title: 'alias'
  },
  {
    key: 'plates',
    title: 'placas'
  },
  {
    key: 'economic_number',
    title: 'economic_number'
  },
  {
    key: 'sub_fleet',
    title: 'sub_fleet'
  },
  {
    key: 'id_motum',
    title: 'id_motum'
  },
  {
    key: 'imei',
    title: 'imei'
  },
  {
    key: 'id_carrier',
    title: 'id_carrier'
  },
  {
    key: 'latitude',
    title: 'latitude'
  },
  {
    key: 'longitude',
    title: 'longitude'
  },
  {
    key: 'speed',
    title: 'speed'
  },
  {
    key: 'is_processed',
    title: 'is_processed',
  },
  {
    key: 'processing_date',
    title: 'processing_date'
  },
  {
    key: 'device',
    title: 'device'
  },
  {
    key: 'gps_provider',
    title: 'gps_provider'
  },
  {
    key: 'company',
    title: 'company'
  },
]

class PlatesActivityResult extends Component {

  constructor() {
    super();

    this.state = {
      hovered_index: null
    }

  }

  getTitleView = () => {
    return(
      <>
        <ResultsTitle>
          {'Resultados encontrados'}
        </ResultsTitle>
      </>
    );
  }

  isPlatesSelected = (index) => {
    return this.props.platesSelected === index;
  }

  noResultView = () => {
    return <NoResultTitle>No hay información disponible</NoResultTitle>
  }

  getPlateHeader = (result, index) => {
    return(
      <>
        <PlatesHeader
          key={`plates-${index}`}
          onMouseEnter={() => this.setState({ hovered_index: index })}
          onMouseLeave={() => this.setState({ hovered_index: null})}
          onClick={() => {
            if (this.props.didSelectPlates) {
              this.props.didSelectPlates(index);
            }
          }}
        >

          <img
            style={this.isPlatesSelected(index) ? {transform: `rotate(180deg)`, marginLeft: 20} : { marginLeft: 20}}
            alt=""
            src="/images/caret.svg"
            width={this.state.hovered_index === index ? 30 : 26}
            height={this.state.hovered_index === index ? 30 : 26}
          />

          <PlatesText>
            {result.plates}
          </PlatesText>

          <PlatesResultsText>
            {`${result.items.length} Resultados encontrados`}
          </PlatesResultsText>

        </PlatesHeader>

        { this.isPlatesSelected(index) ?
          result.items.length === 0 ? this.noResultView() : 
          <TableSortedContent>
            <TableSortable
              embedded={true}
              isLoading={false}
              itemsToHide={[]}
              didSelectRow={(value) => {

              }}
              columns={this.props.isMotum ? columnsDataMotum : columnsData}
              items={result.items}
            />
          </TableSortedContent> : <> </>
        }

        <Seperator
          key={`separator-${index}`}
        />
      </>
    );
  }

  getContent = () => {
    return(
      this.props.result.map((result_item, index) => {
        return this.getPlateHeader(result_item, index)
      })
    );
  }

  render() {
    return(
      <Content>
        { this.getTitleView() }
        <Seperator
          style={{
            marginTop: '1px'
          }}
        />
        { this.getContent() }
      </Content>
    );
  }
}

export default PlatesActivityResult;

const Content = styled.div`
  margin-top: 40px;
  overflow: hidden;
  border-radius: 10px;
`;

const ResultsTitle = styled.div`
  font-size: 29px;
  height: 80px;
  padding-left: 20px;
  background-color: #394c75;
  display: flex;
  align-items: center;
  color: rgb(123, 169, 200);
`;

const Seperator = styled.div`
  height: 1.5px;
  max-height: 1.5px;
  background-color: white;
  width: 100%;
  min-height: 1.5px;
`;

const PlatesHeader = styled.div`
  height: 60px;
  background-color: #394c75;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlatesText = styled.div`
  color: white;
  font-size: 15px;
  margin-left: 20px;
`;

const PlatesResultsText = styled.div`
  color: white;
  font-size: 12px;
  margin-left: 20px;
`;

const TableSortedContent = styled.div`
  width: 100%;
  height: 495px;
`;

const NoResultTitle = styled.div`
  width: 310px;
  height: 80px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  transform: translate(-50%);
  margin-left: 50%;
`;