// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { GridConfig } from "../../control/assemble/types";
import { View, ViewTitle } from "../../shared/styled/view";
import { ResourcesGrid } from "../../control/resources/resources-styled";
import {
  readEntities,
  updateEntity,
  deleteEntity,
} from "../../redux/api/actions";
import { getEntityItems, getEntityArgs } from "../../redux/api/helpers";
import { getUrlsEnv } from "../../shared/backend-api";
import {
  getFilterParamsInitialDateTime as addValueToFilterParamsDateTime,
  normalizeStr,
} from "../../shared/utils";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import TextInput from "../../shared/controls/text-input";
import Checkbox from "../../shared/controls/checkbox";
import SVGIcon, { icons } from "../../shared/svg-icon";
import { AssembleAvatar } from "../../control/assemble/assemble-avatar";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import { SaveItemCounter } from "../../redux/api";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import ExceedPlus from "../../shared/controls/exceed-plus";
import ColumnOrdenator from "./column-ordenator";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
import * as moment from "moment";
import "moment/locale/es";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import GenericDialog from "../../shared/dialog/generic-modal";
import ErrorDialog from "../../shared/dialog/error-dialog";

type Props = {};
type State = {
  +gridConfig: GridConfig,
  enabledSelectAll: boolean,
  gridSelectedItems: number[],
  searchStr: string,
  order: { column: string, descendent: boolean },
};

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
const defaultGridConfig: GridConfig = {
  columnQuantity: 4,
  columnRangeType: "WEEK",
  columnSections: 4,
  startDatetime: today,
  extended: true,
};

const hour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours()
);
const week = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() - new Date().getDay() + 1,
  new Date().getHours()
);
const month = new Date(new Date().getFullYear(), new Date().getMonth());

let typingTimer;

class ContractDetailView extends Component<Props, State> {
  state = {
    gridConfig: defaultGridConfig,
    filterParams: {
      start_date: today.toISOString(),
      end_date: addValueToFilterParamsDateTime(
        today,
        defaultGridConfig.columnQuantity,
        defaultGridConfig.columnRangeType
      ).toISOString(),
    },
    buttonFilterName: "Aplicar",
    enabledSelectAll: false,
    gridSelectedItems: [],

    modalSearchStr: "",
    openXlsErrorModal: false,
    descriptionImport: [],

    showRemoveAssembleModal: false,
    showAddAssembleModal: false,
    showEditContractPeriodModal: false,
    selectedSlot: 0,
    selectedSlotInitialDate: null,
    selectedSlotFinalDate: null,
    selectedAssembly: 0,
    assemblyStartDate: null,
    assemblyEndDate: null,
    selectedAddendum: 0,
    selectedAddendumInitialDate: null,
    selectedAddendumFinalDate: null,
    searchStr: "",
    order: { column: "operator", descendent: true },
    errorMessage: null
  };

  componentDidMount() {
    this.loadSlots();
    this.props.listContractAssemblies(
      this.props.companyId,
      this.props.match.params.contractId
    );

    this.props.setNavbarAction("contract-controls", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

  }

  componentWillUnmount() {
    this.props.removeNavbarAction("contract-controls");
  }

  loadSlots = () => {
    this.props.listContractSlots(
      this.props.companyId,
      this.props.match.params.contractId,
      {
        start_date: this.state.filterParams.start_date.split("T")[0],
        end_date: this.state.filterParams.end_date.split("T")[0],
      },
      { args: this.state.filterParams }
    );
  };

  updateAssemblies = () => {
    this.props.updateContractAssemblies(
      this.props.companyId,
      this.props.match.params.contractId,
      this.state.selectedSlot,
      this.state.selectedAssembly,
      this.state.assemblyStartDate,
      this.state.assemblyEndDate,
      {
        onSuccess: () => {
          this.loadSlots();
          this.props.listContractAssemblies(
            this.props.companyId,
            this.props.match.params.contractId
          );
          this.showAddAssembleModal(false);
        },
        onError: (e) => {
          this.showAddAssembleModal(false);
          let err = e.response?.data?.errors || []; 
          this.manageError(err);
        }
      }
    );
  };

  manageError = (err) => {
    this.setState({openXlsErrorModal: true, descriptionImport: err})
  }

  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: []
    });
  };

  updateContractPeriod = () => {
    // let addendumInit = this.state.selectedAddendumInitialDate;
    // if (addendumInit) {
    //   addendumInit = addendumInit.split("/");
    //   addendumInit = addendumInit[2].concat(
    //     "-",
    //     addendumInit[0],
    //     "-",
    //     addendumInit[1]
    //   );
    // }
    // let addendumEnd = this.state.selectedAddendumFinalDate;
    // if (addendumEnd) {
    //   addendumEnd = addendumEnd.split("/");
    //   addendumEnd = addendumEnd[2].concat(
    //     "-",
    //     addendumEnd[0],
    //     "-",
    //     addendumEnd[1]
    //   );
    // }
    this.props.updateContractPeriod(
      this.props.companyId,
      this.props.match.params.contractId,
      this.state.selectedAddendum,
      this.state.selectedAddendumInitialDate,
      this.state.selectedAddendumFinalDate,
      {
        onSuccess: () => {
          this.loadSlots();
          this.showEditContractPeriodModal(false);
        },
      }
    );
  };

  removeAssemblies = () => {
    this.props.deleteContractAssemblies(
      this.props.companyId,
      this.props.match.params.contractId,
      this.state.selectedSlot,
      {
        onSuccess: () => {
          this.loadSlots();
          this.props.listContractAssemblies(
            this.props.companyId,
            this.props.match.params.contractId
          );
          this.showRemoveAssembleModal(false);
        },
        onError: (e) => {
          this.showRemoveAssembleModal(false);
          let err = e.response?.data?.errors || []; 
          this.manageError(err);
        }
      }
    );
  };

  showRemoveAssembleModal = (show) => {
    if (!show)
      this.setState({
        showRemoveAssembleModal: show,
        selectedSlot: 0,
        selectedAssembly: 0,
      });
    else this.setState({ showRemoveAssembleModal: show });
  };

  showEditContractPeriodModal = (show) => {
    if (!show)
      this.setState({
        showEditContractPeriodModal: show,
        selectedAddendum: 0,
        selectedAddendumInitialDate: null,
        selectedAddendumFinalDate: null,
      });
    else this.setState({ showEditContractPeriodModal: show });
  };

  showAddAssembleModal = (show) => {
    if (!show)
      this.setState({
        manageErrorModal: false,
        showAddAssembleModal: show,
        selectedSlot: 0,
        selectedSlotInitialDate: null,
        selectedSlotFinalDate: null,
        selectedAssembly: 0,
        assemblyStartDate: null,
        assemblyEndDate: null,
        modalSearchStr: '',
      });
    else this.setState({ showAddAssembleModal: show });
  };

  setSelectedAssembly = (assemblyId) => {
    this.setState({ selectedAssembly: assemblyId });
  };

  getResponsePerm = (perm, type) => {
    return validateObjPerms(
      [
        {
          id: String(perm),
          perm: String(type),
        },
      ],
      true
    ) === true
      ? true
      : false;
  };

  getRows = () => {
    const contractRows = (this.props.companyContractSlots || []).map((slot) => {
      let resources = [];
      let actions = [];

      if (slot.assemblies.length) {
        slot.assemblies.forEach((assembly) => {
          resources = resources.concat(
            assembly.assembly.vehicle.map((ve) => {
              return {
                id: ve.id,
                type: 1,
                profile_image: ve.photo_url,
                shortDescription: ve.economic_number,
                longDescription: ve.economic_number.concat(
                  "/",
                  ve.plates ? ve.plates : ""
                ),
                classification: ve.fleet_class,
                //item: ,
                //logId: ,
                normativityStatusId: ve.status,
              };
            })
          );
          resources = resources.concat(
            assembly.assembly.equipment.map((eq) => {
              return {
                id: eq.id,
                type: 2,
                profile_image: eq.photo_url,
                shortDescription: eq.economic_number,
                longDescription: eq.economic_number.concat(
                  "/",
                  eq.plates ? eq.plates : ""
                ),
                classification: eq.fleet_class,
                //item: ,
                //logId: ,
                normativityStatusId: eq.status,
              };
            })
          );
          resources = resources.concat(
            assembly.assembly.operator.map((op) => {
              return {
                id: op.fleet_operator,
                type: 3,
                profile_image: op.profile_image,
                shortDescription: op.first_name.concat(" ", op.last_name),
                longDescription: op.first_name.concat(" ", op.last_name),
                classification: op.license_type,
                //item: ,
                //logId: ,
                normativityStatusId: op.status,
              };
            })
          );
        });
        actions = [];
        if (this.props.companyType === 1) {
          if (this.getResponsePerm("55", "u")) {
            actions.push({
              description: "Desasignar",
              action: () => {
                this.setState({
                  showRemoveAssembleModal: true,
                  selectedSlot: slot.id,
                });
              },
            });
            actions.push({
              description: "Editar vigencia",
              action: () =>
                this.manageUpdateContractAssemble({
                  target: { name: "open", value: slot },
                }),
            });
          }
        } else {
          let addendumInit = slot.initial_date;
          if (addendumInit) {
            addendumInit = addendumInit.split("-");
            addendumInit = addendumInit[1].concat(
              "/",
              addendumInit[2],
              "/",
              addendumInit[0]
            );
          }
          let addendumEnd = slot.final_date;
          if (addendumEnd) {
            addendumEnd = addendumEnd.split("-");
            addendumEnd = addendumEnd[1].concat(
              "/",
              addendumEnd[2],
              "/",
              addendumEnd[0]
            );
          }
          if (this.getResponsePerm("53", "u")) {
            actions.push({
              description: "Editar vigencia",
              action: () => {
                this.setState({
                  showEditContractPeriodModal: true,
                  selectedAddendum: slot.id,
                  selectedAddendumInitialDate: addendumInit,
                  selectedAddendumFinalDate: addendumEnd,
                });
              },
            });
          }
        }
      } else {
        if (this.props.companyType === 1) {
          if (this.getResponsePerm("55", "u")) {
            resources.push({
              id: slot.id,
              // type: 0,
              shortDescription: "Asignar",
              longDescription: "Asignar",
              action: () => {
                this.setState({
                  showAddAssembleModal: true,
                  selectedSlot: slot.id,
                  selectedSlotInitialDate: slot.initial_date,
                  selectedSlotFinalDate: slot.final_date,
                  assemblyEndDate: slot.final_date ? moment(slot.final_date).format("MM/DD/YYYY") : null
                });
              },
              empty: true,
            });
            actions = [];
            // actions = [{
            //   description: "Asignar",
            //   action: () => {
            //     this.setState({
            //       showAddAssembleModal: true,
            //       selectedSlot: slot.id,
            //       selectedSlotInitialDate: slot.initial_date,
            //       selectedSlotFinalDate: slot.final_date,
            //     });
            //   },
            // }];
          }
        } else {
          resources.push({ empty: true });
          let addendumInit = slot.initial_date;
          if (addendumInit) {
            addendumInit = addendumInit.split("-");
            addendumInit = addendumInit[1].concat(
              "/",
              addendumInit[2],
              "/",
              addendumInit[0]
            );
          }
          let addendumEnd = slot.final_date;
          if (addendumEnd) {
            addendumEnd = addendumEnd.split("-");
            addendumEnd = addendumEnd[1].concat(
              "/",
              addendumEnd[2],
              "/",
              addendumEnd[0]
            );
          }
          if (this.getResponsePerm("53", "u")) {
            actions = [
              {
                description: "Editar vigencia",
                action: () => {
                  this.setState({
                    showEditContractPeriodModal: true,
                    selectedAddendum: slot.id,
                    selectedAddendumInitialDate: addendumInit,
                    selectedAddendumFinalDate: addendumEnd,
                  });
                },
              },
            ];
          }
        }
      }

      return {
        id: slot.id,
        resources,
        detail: this.getAssembleDetail(slot),
        timeLine: this.getAssembleTimeLine(
          slot.initial_date,
          slot.final_date,
          slot
        ),
        selected: this.state.gridSelectedItems.find((item) => item === slot.id)
          ? true
          : false,
        header_actions: actions,
      };
    });
    return contractRows;
    // return []
  };

  getAssembleDetail = (slot) => {
    if (!slot.assemblies.length) return [];
    else {
      let details = [];
      slot.assemblies.forEach((assembly) => {
        details = details.concat(
          assembly.assembly.vehicle
            .map((ve) => {
              // debugger
              return {
                lineId: ve.id,
                type: 1,
                timeLine: this.getDetailTimeLine(
                  slot.initial_date,
                  slot.final_date,
                  assembly.initial_date,
                  assembly.final_date,
                  ve.resource_control,
                  ve.economic_number.concat(
                    "/",
                    ve.plates || ""
                  ),
                  1,
                  ve.photo_url
                ),
                // description: ve.economic_number.concat("/", ve.plates || ""),
              };
            })
            // .sort((a, b) => a.description - b.description)
        );
        details = details.concat(
          assembly.assembly.equipment
            .map((eq) => {
              return {
                lineId: eq.id,
                type: 2,
                timeLine: this.getDetailTimeLine(
                  slot.initial_date,
                  slot.final_date,
                  assembly.initial_date,
                  assembly.final_date,
                  eq.resource_control,
                  eq.economic_number.concat(
                    "/",
                    eq.plates || ""
                  ),
                  2,
                  eq.photo_url
                ),
                // description: eq.economic_number.concat("/", eq.plates || ""),
              };
            })
            // .sort((a, b) => a.description - b.description)
        );
        details = details.concat(
          assembly.assembly.operator
            .map((op) => {
              return {
                lineId: op.id,
                type: 3,
                timeLine: this.getDetailTimeLine(
                  slot.initial_date,
                  slot.final_date,
                  assembly.initial_date,
                  assembly.final_date,
                  op.resource_control,
                  op.first_name.concat(" ", op.last_name),
                  3,
                  op.profile_image
                ),
                // description: op.first_name.concat(" ", op.last_name),
              };
            })
            // .sort((a, b) => a.description - b.description)
        );
      });
      return details;
    }
  };

  getDateFromString = (dateString) => {
    let test = today.getTimezoneOffset();
    let nonTest = new Date(dateString);
    nonTest.setMinutes(nonTest.getMinutes() + test);
    return nonTest;
  };

  getDetailTimeLine = (
    slot_initial,
    slot_final,
    assembly_initial,
    assembly_final,
    exemptions,
    description,
    fleetType,
    image
  ) => {
    const from = Date.parse(this.state.filterParams.start_date);
    const to = Date.parse(this.state.filterParams.end_date);
    const length = to - from;
    if (isNaN(from) && isNaN(to)) return [];
    let timeLines = [];
    // debugger
    let slotStart = this.getDateFromString(slot_initial);
    slotStart = Date.parse(slotStart.toISOString());
    slotStart = slotStart < from ? from : slotStart > to ? to : slotStart;
    let assemblyStart = this.getDateFromString(assembly_initial);
    assemblyStart = Date.parse(assemblyStart.toISOString());
    assemblyStart = assemblyStart < slotStart ? slotStart : assemblyStart;
    let slotEnd = to;
    if (slot_final) {
      let newEnding = this.getDateFromString(slot_final);
      newEnding = Date.parse(newEnding.toISOString());
      slotEnd = newEnding > to ? to : newEnding < from ? from : newEnding;
    }
    let assemblyEnd = slotEnd;
    if (assembly_final) {
      let newAssemblyEnding = this.getDateFromString(assembly_final);
      newAssemblyEnding = Date.parse(newAssemblyEnding.toISOString());
      assemblyEnd = newAssemblyEnding > slotEnd ? slotEnd : newAssemblyEnding;
    }
    if (assemblyStart > from) {
      let firstTimeline = {
        available: true,
        caption: "",
        id: "",
        color: "#fff",
        borderColor: "#fff",
        visible: false,
        start: from,
        end: assemblyStart,
        width: this.getLineWidth(from, assemblyStart, length),
      };
      timeLines.push(firstTimeline);
    }
    let prevPeriodEnd = assemblyStart;
    // debugger;
    exemptions.filter((e) => {
      let eStart = Date.parse(e.exception_from);
      let eEnd = Date.parse(e.exception_to);
      return eStart > assemblyEnd || eEnd < assemblyStart ? false : true;
    }).sort((a, b) => {
      let aStart = (Date.parse(a.exception_from));
      let bStart = (Date.parse(b.exception_from));
      if(aStart > bStart)
        return 1
      if(aStart < bStart)
        return -1
      return 0;
    }).forEach(e => {
      timeLines.push({
        available: true,
        // caption: '',
        caption: (
          <>
            <AssembleAvatar
              description={description}
              fleetTypeId={fleetType}
              avatarUrl={image}
            />
            {/* <div
              style={{
                display: "flex",
                width: "auto",
                alignItems: "center",
                marginLeft: "auto",
                paddingLeft: "5px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              Vigencia del recurso{" "}
              {assembly.final_date
                ? new Date(assembly.final_date)
                    .toUTCString()
                    .substring(4, 16)
                : "indefinido"}
            </div> */}
          </>
        ),
        id: '',
        visible: true,
        start: prevPeriodEnd,
        end: Date.parse(e.exception_from),
        width: this.getLineWidth(prevPeriodEnd, Date.parse(e.exception_from), length)
      });
      timeLines.push({
        available: false,
        caption: "" /* this.getExemptionCaption(e.motive_id) */,
        id: '',
        visible: true,
        start: Date.parse(e.exception_from),
        end: Math.min(Date.parse(e.exception_to), assemblyEnd),
        width: this.getLineWidth(Date.parse(e.exception_from), Math.min(Date.parse(e.exception_to), assemblyEnd), length)
      });
      prevPeriodEnd = Math.min(Date.parse(e.exception_to), assemblyEnd);
    })
    timeLines.push({
      available: true,
      caption: <AssembleAvatar
        description={description}
        fleetTypeId={fleetType}
        avatarUrl={image}
      />,
      id: "",
      // color: '#fff',
      // borderColor: '#fff',
      visible: true,
      start: prevPeriodEnd,
      end: assemblyEnd,
      width: this.getLineWidth(prevPeriodEnd, assemblyEnd, length),
    });
    return timeLines.filter(tL => tL.width !== '0%');
  };

  flattenExemptions = exemptions => {
    if(!exemptions.length) return [];
    exemptions = exemptions.sort((a, b) => a.start - b.start);
    // debugger;
    return exemptions.reduce(function(combined, next) {
      // debugger
      if (!combined.length || combined[combined.length-1].end < next.start) combined.push(next);
      else {
          let prev = combined.pop();
          combined.push({start: prev.start, end: Math.max(prev.end, next.end)});
      }
      return combined;
    }, []);
  }

  getAssembleTimeLine = (initial_date, final_date, slot) => {
    const assemblies = slot.assemblies;
    const from = Date.parse(this.state.filterParams.start_date);
    const to = Date.parse(this.state.filterParams.end_date);
    const length = to - from;
    if (isNaN(from) && isNaN(to)) return [];
    let timeLines = [];
    const slotStart =
      Date.parse(this.getDateFromString(initial_date).toISOString()) < from
        ? from
        : Date.parse(this.getDateFromString(initial_date).toISOString());
    let slotEnd = to;
    if (final_date) {
      let newEnding = this.getDateFromString(final_date);
      newEnding = Date.parse(newEnding.toISOString());
      slotEnd = newEnding > to ? to : newEnding < from ? from : newEnding;
    }
    if (slotStart > from) {
      let firstTimeline = {
        available: true,
        caption: "",
        id: "",
        color: "#fff",
        borderColor: "#fff",
        visible: false,
        width: this.getLineWidth(from, slotStart, length),
      };
      timeLines.push(firstTimeline);
    }
    if (assemblies.length) {
      let assembliesLines = assemblies.map((assembly) => {
        let start = this.getDateFromString(assembly.initial_date);
        start = Date.parse(start.toISOString());
        if (start < slotStart) start = slotStart;
        else if (start > slotEnd) {
          start = slotEnd;
        }
        let end = null;
        if (assembly.final_date) {
          end = this.getDateFromString(assembly.final_date);
          end = Date.parse(end.toISOString());
        }
        if (end === null || end > slotEnd) end = slotEnd;
        if (end < slotStart) {
          end = slotStart;
        }
        let exemptions = [];
        assembly.assembly.equipment.forEach(e => {
          exemptions = exemptions.concat(
            e.resource_control.map(ex => { 
              return {
                start: Date.parse(ex.exception_from), 
                end: Date.parse(ex.exception_to)
              }
            })
          );
        })
        assembly.assembly.operator.forEach(e => {
          exemptions = exemptions.concat(
            e.resource_control.map(ex => { 
              return {
                start: Date.parse(ex.exception_from), 
                end: Date.parse(ex.exception_to)
              }
            })
          );
        })
        assembly.assembly.vehicle.forEach(e => {
          exemptions = exemptions.concat(
            e.resource_control.map(ex => { 
              return {
                start: Date.parse(ex.exception_from), 
                end: Date.parse(ex.exception_to)
              }
            })
          );
        })
        exemptions = this.flattenExemptions(exemptions);
        return {
          start,
          end,
          exemptions
        };
      });
      assembliesLines = assembliesLines.sort((a, b) => a.start - b.start);
      assembliesLines = assembliesLines.map((assembly, index, assemblies) => {
        if (assemblies[index + 1]) {
          return {
            exemptions: assembly.exemptions,
            start: assembly.start,
            end:
              assembly.end > assemblies[index + 1].start
                ? assemblies[index + 1].start
                : assembly.end,
          };
        } else {
          return {
            exemptions: assembly.exemptions,
            start: assembly.start,
            end: assembly.end,
          };
        }
      });
      let emptySpaceStart = slotStart;
      assembliesLines.forEach((assembly) => {
        timeLines.push({
          available: true,
          caption: ``,
          id: "",
          color: "#212a3c",
          borderColor: "#fff",
          visible: true,
          width: this.getLineWidth(emptySpaceStart, assembly.start, length),
        });
        let prevPeriodEnd = assembly.start;
        assembly.exemptions
          .filter(ex => ex.end > assembly.start && ex.start < assembly.end)
          .forEach(e => {
            timeLines.push({
              available: true,
              caption: '',
              position: "flex-end",
              color: "#305473",
              borderColor: "#54b8f8",
              id: '',
              visible: true,
              width: this.getLineWidth(prevPeriodEnd, e.start, length)
            });
            timeLines.push({
              available: false,
              caption: 'Faltan recursos',
              id: '',
              visible: true,
              width: this.getLineWidth(e.start, Math.min(e.end, assembly.end), length)
            });
            prevPeriodEnd = Math.min(e.end, assembly.end);
          })
        timeLines.push({
          available: true,
          caption: '',
          id: '',
          visible: true,
          width: this.getLineWidth(prevPeriodEnd,  assembly.end, length)
          })
        emptySpaceStart = assembly.end;
      });
      timeLines.push({
        available: true,
        caption: "",
        // caption: `Vigencia de contrato ${
        //   slot.final_date
        //     ? new Date(slot.final_date).toUTCString().substring(4, 16)
        //     : "indefinido"
        // }`,
        id: "",
        color: "#212a3c",
        borderColor: "#fff",
        visible: true,
        width: this.getLineWidth(
          assembliesLines[assembliesLines.length - 1].end,
          slotEnd,
          length
        ),
      });
    } else {
      timeLines.push({
        available: true,
        // caption: `Vigencia de contrato ${
        //   slot.final_date
        //     ? new Date(slot.final_date).toUTCString().substring(4, 16)
        //     : "indefinido"
        // }`,
        caption: "",
        position: "flex-end",
        id: "",
        color: "#fff",
        borderColor: "#fff",
        visible: true,
        width: this.getLineWidth(slotStart, slotEnd, length),
      });
    }
    timeLines = timeLines.filter((tl) => tl.width !== '0%');
    if(timeLines.length){
      // const addAssemble = assemblies.length 
      // && timeLines[timeLines.length - 1].borderColor === "#fff" 
      // && this.props.companyType === 1 ? (
      //   <div className="slot-action-header">
      //     <Button
      //       text="Asignar"
      //       type="primary"
      //       onClick={() =>
      //         this.setState({
      //           showAddAssembleModal: true,
      //           selectedSlot: slot.id,
      //           selectedSlotInitialDate: slot.initial_date,
      //           selectedSlotFinalDate: slot.final_date,
      //         })
      //       }
      //     />
      //   </div>
      // ) : null;
      timeLines[timeLines.length - 1] = {
        ...timeLines[timeLines.length - 1],
        caption: (
          <React.Fragment>
            {/* {addAssemble}{" "} */}
            {`Vigencia de contrato ${
              slot.final_date
                ? new Date(slot.final_date).toUTCString().substring(4, 16)
                : "indefinido"
            }`}
          </React.Fragment>
        ),
        position: /* addAssemble ? "space-between" :  */"flex-end",
      };
    }
    
    return timeLines;
  };

  getLineWidth = (start, end, totalLength) => {
    let length = (100 * (end - start)) / totalLength;
    if (length < 0) length = 0;
    return `${length}%`;
  };

  handleChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "modalSearchStr":
        this.setState({ [e.target.name]: e.target.value });
        clearTimeout(typingTimer);
        typingTimer = setTimeout(
          () => {
            this.props.listContractAssemblies(
              this.props.companyId,
              this.props.match.params.contractId,
              this.state.modalSearchStr
            );
          }, 500);
        break
      case "setOrder":
        this.setState({
          order: value,
        });
        break;
      case "gridConfigSelect":
        this.setState(
          {
            gridConfig: {
              columnQuantity: parseInt(value),
              columnRangeType: value.includes("H")
                ? "HOUR"
                : value.includes("D")
                ? "DAY"
                : value.includes("W")
                ? "WEEK"
                : "MONTH",
              columnSections: 4,
              startDatetime: value.includes("H")
                ? hour
                : value.includes("D")
                ? today
                : value.includes("W")
                ? week
                : month,
              className: "",
              extended: true,
            },
          },
          () => {
            this.handleChange({
              target: {
                name: "gridDateChange",
                // value: value.includes("H") ? parseInt(value) : 0,
                value: 0,
              },
            });
          }
        );
        break;
      case "gridDateChange":
        let c = { ...this.state.gridConfig };
        let f = { ...this.state.filterParams };
        c.startDatetime = addValueToFilterParamsDateTime(
          c.startDatetime,
          e.target.value,
          c.columnRangeType
        );
        // c.columnRangeType === "DAY"
        //   ? dateAddDays(c.startDatetime, e.target.value):c.columnRangeType === "HOUR"?
        //   dateAddHours(c.startDatetime, e.target.value):c.columnRangeType === "WEEK"?dateAddDays(c.startDatetime, e.target.value*7):
        f.start_date = c.startDatetime.toISOString();
        f.end_date = addValueToFilterParamsDateTime(
          c.startDatetime,
          c.columnQuantity,
          c.columnRangeType
        ).toISOString();
        // c.columnRangeType === "DAY"
        //   ? dateAddDays(
        //       new Date(f.start_date),
        //       c.columnQuantity
        //     ).toISOString()
        //   : dateAddHours(
        //       new Date(f.start_date),
        //       c.columnQuantity
        //     ).toISOString();
        this.setState({ gridConfig: c });
        this.setState({ filterParams: f }, () => this.loadSlots());
        break;
      case "gridSelectedItems":
        const selectedItems = this.state.gridSelectedItems.filter(
          (item) => item !== value.id
        );
        if (value.checked) {
          selectedItems.push(value.id);
        }
        this.setState({ gridSelectedItems: selectedItems });
        break;
      case "enabledSelectAll":
        this.setState({
          gridSelectedItems: [],
          [e.target.name]: e.target.value,
        });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }

    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleNewAssembleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDateToChange = (e) => {
    if (e.target.checked) {
      this.setState({ [e.target.name]: null });
    }
  };

  render() {
    const { gridConfig } = this.state;

    return (
      <View>
        <ViewTitle>
          <span>Control de contratos</span>
          <FilterBarCompact
            searchStr={this.state.searchStr}
            buttonFilterName={this.state.buttonFilterName}
            searchAction={this.handleChange}
            content={[]}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState({ buttonFilterName: "Restablecer" });
              } else {
                this.setState({
                  filterParams: {
                    start_date: today.toISOString(),
                    end_date: addValueToFilterParamsDateTime(
                      today,
                      defaultGridConfig.columnQuantity,
                      defaultGridConfig.columnRangeType
                    ).toISOString(),
                  },
                  buttonFilterName: "Aplicar"
                });
              }
            }}
          />
        </ViewTitle>
        <div className={"contract-detail-header"}>{this.getHeader()}</div>
        <ResourcesGrid
          currentContract={this.props.currentContract}
          dateChange={this.handleChange}
          gridConfigChange={this.handleChange}
          {...{ gridConfig }}
          companyType={this.props.companyType}
          rows={this.getRows().filter(
            (row) =>
              (
                !this.state.searchStr || 
                row.resources.filter((resource) =>
                  normalizeStr(resource.longDescription).includes(
                    normalizeStr(this.state.searchStr)
                  )
                ).length
              )
          )}
          showSelectAll={true}
          enabledSelectAll={this.state.enabledSelectAll}
        />
        <CatalogSelectionFooter
          show={this.state.enabledSelectAll}
          selectedElements={this.state.gridSelectedItems.length}
          actions={[
            {
              description: "Modificar vigencia",
              f: () =>
                this.manageUpdateContractAssemble({
                  target: { name: "open", value: "" },
                }),
            },
          ]}
        />
        <Modal
          isOpen={this.state.showRemoveAssembleModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Remover ensamble</div>
          <div
            className="close"
            onClick={() => this.showRemoveAssembleModal(false)}
          ></div>
          <div className="message">
            <div>
              ¿Estás seguro que deseas remover las unidades de este contrato?
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showRemoveAssembleModal(false)}
            />
            <Button
              text="Remover"
              type="secondary"
              onClick={() => this.removeAssemblies()}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showEditContractPeriodModal}
          portalClassName="dialog emails-modal invite-users-modal add-assemble-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Duración de contrato</div>
          <div
            className="close"
            onClick={() => this.showEditContractPeriodModal(false)}
          ></div>
          <div className="message">
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 1
                  )
                }
                maxDate={null}
                value={this.state.selectedAddendumInitialDate}
                label="Fecha de inicio"
                name="selectedAddendumInitialDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={this.state.selectedAddendumInitialDate}
                maxDate={null}
                value={this.state.selectedAddendumFinalDate}
                label="Fecha de fin"
                name="selectedAddendumFinalDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <Checkbox
                onChange={(e) => this.handleDateToChange(e)}
                item={{
                  id: 1,
                  description: "Indefinido",
                  active:
                    this.state.selectedAddendumFinalDate === null
                      ? true
                      : false,
                }}
                name={"selectedAddendumFinalDate"}
                // className={(i.items || []).length ? 'bold' : ''}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showEditContractPeriodModal(false)}
            />
            <Button
              text="Guardar"
              type="primary"
              onClick={() => this.updateContractPeriod()}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showEditAssemblePeriodModal}
          portalClassName="dialog emails-modal invite-users-modal add-assemble-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Duración de ensamble</div>
          <div
            className="close"
            onClick={() =>
              this.manageUpdateContractAssemble({
                target: { name: "close", value: "" },
              })
            }
          ></div>
          <div className="message">
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={today}
                dateAdjust={false}
                maxDate={null}
                value={this.state.selectedAddendumInitialDate}
                label="Fecha de inicio"
                name="selectedAddendumInitialDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={this.state.selectedAddendumInitialDate}
                maxDate={null}
                dateAdjust={false}
                value={this.state.selectedAddendumFinalDate}
                label="Fecha de fin"
                name="selectedAddendumFinalDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <Checkbox
                onChange={(e) => this.handleDateToChange(e)}
                item={{
                  id: 1,
                  description: "Indefinido",
                  active:
                    this.state.selectedAddendumFinalDate === null
                      ? true
                      : false,
                }}
                name={"selectedAddendumFinalDate"}
                // className={(i.items || []).length ? 'bold' : ''}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() =>
                this.manageUpdateContractAssemble({
                  target: { name: "close", value: "" },
                })
              }
            />
            <Button
              text="Guardar"
              type="primary"
              disabled={this.props.isLoadingContract}
              onClick={() =>
                this.manageUpdateContractAssemble({
                  target: { name: "update", value: "" },
                })
              }
            />

          </div>
        </Modal>

        <ErrorDialog
          open={this.state.errorMessage !== null}
          acceptText="OK"
          message={this.state.errorMessage}
          acceptAction={() => this.setState({errorMessage: null})}
          closeAction={() => this.setState({errorMessage: null})}
        />

        <Modal
          isOpen={this.state.showAddAssembleModal}
          portalClassName="dialog emails-modal invite-users-modal add-assemble-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Agregar ensamble a contrato</div>
          <div
            className="close"
            onClick={() => this.showAddAssembleModal(false)}
          ></div>
          <div className="message">
            <div>
              Puedes reservar con un camión disponible o aprovechar un camión
              dentro de la planta.
            </div>
            {this.addModalErrorManager().messages.map((msg) => (
              <div style={{ color: "red " }}>{"* " + msg}</div>
            ))}
            <div className="subtitle">
              {`Lista de ensambles disponibles ${this
              .props.currentContract?.actives_fleets?.count || ""}/${this.props
              .currentContract?.total_fleets || ""}`}
              <div className="text-search">
                <TextInput 
                  value={this.state.modalSearchStr}
                  label="Buscar"
                  name="modalSearchStr"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="assemblies-table">
              <div className="assemblies-table-header">
                <div
                  className="operator"
                  onClick={() =>
                    this.handleChange({
                      target: {
                        name: "setOrder",
                        value: {
                          column: "operator",
                          descendent: !this.state.order.descendent,
                        },
                      },
                    })
                  }
                >
                  Operador
                  <ColumnOrdenator descendent={this.state.order.descendent} />
                </div>
                <div
                  className="vehicle"
                  onClick={() =>
                    this.handleChange({
                      target: {
                        name: "setOrder",
                        value: {
                          column: "vehicle",
                          descendent: !this.state.order.descendent,
                        },
                      },
                    })
                  }
                >
                  Vehículo
                  <ColumnOrdenator descendent={this.state.order.descendent} />
                </div>
                <div
                  className="equipment"
                  onClick={() =>
                    this.handleChange({
                      target: {
                        name: "setOrder",
                        value: {
                          column: "equipment",
                          descendent: !this.state.order.descendent,
                        },
                      },
                    })
                  }
                >
                  Equipos
                  <ColumnOrdenator descendent={this.state.order.descendent} />
                </div>
                <div
                  className="status"
                >
                  Estatus
                </div>
              </div>
              <div className="assemblies-table-body">
                {Array.isArray(this.props.companyContractAssemblies) &&
                  this.props.companyContractAssemblies
                    // .filter( asm => {
                    //   let modalSearchStr = normalizeStr(this.state.modalSearchStr);
                    //   return (
                    //     asm.operator.some(o => normalizeStr(o.first_name).includes(modalSearchStr) || normalizeStr(o.last_name).includes(modalSearchStr))
                    //     || asm.vehicle.some(v => normalizeStr(v.economic_number).includes(modalSearchStr) || normalizeStr(v.plates).includes(modalSearchStr))
                    //     || asm.equipment.some(e => normalizeStr(e.economic_number).includes(modalSearchStr))
                    //   );
                    // })
                    .map((asm) => ({
                      ...asm,
                      ordering:
                        this.state.order.column === "operator"
                          ? asm.operator
                              .map((o) => o.first_name.concat(o.last_name))
                              .join()
                          : this.state.order.column === "vehicle"
                          ? asm.vehicle
                              .map((o) => o.economic_number.concat(o.plates))
                              .join()
                          : asm.equipment
                              .map((o) => o.economic_number.concat(o.plates))
                              .join(),
                    }))
                    .sort((a, b) =>
                      this.state.order.descendent && a.ordering < b.ordering
                        ? -1
                        : 1
                    )
                    .map((assembly) => {
                      return (
                        <div className="assemblies-table-row">
                          <label
                            key={"assembly-".concat(assembly.id)}
                            onClick={() => {
                              if(!assembly.in_contract)this.setSelectedAssembly(assembly.id)
                            }}
                            className={"radio-container ".concat(assembly.in_contract ? "busy" : "")}
                          >
                            <div className="assembly-label">
                              <div className="operator">
                                <ExceedPlus
                                  name={assembly.id}
                                  face={
                                    <span>
                                      {assembly.operator[0]?.first_name
                                        .concat(
                                          " ",
                                          assembly.operator[0].last_name
                                        )
                                        .substring(0, 20) || "Sin operador"}
                                    </span>
                                  }
                                >
                                  {assembly.operator.map((o) => (
                                    <div>
                                      {o.first_name.concat(" ", o.last_name)}
                                    </div>
                                  ))}
                                </ExceedPlus>
                              </div>
                              <div className="vehicle">
                                {assembly.vehicle
                                  .map((v) => { return v.economic_number && v.economic_number.concat(" / ", (v.plates || ""))})
                                  .join(", ") || "Sin vehículo"}
                              </div>
                              <div className="equipment">
                                {assembly.equipment
                                  .map((eq) => eq.economic_number)
                                  .join(", ") || "Sin equipo"}
                              </div>
                              <div className={"status ".concat(assembly.in_contract ? "busy" : "")}>
                                {
                                  !assembly.in_contract ? "Libre" : "En contrato"
                                }
                              </div>
                            </div>
                            {
                              !assembly.in_contract &&
                              <>
                                <input
                                  readOnly
                                  type="radio"
                                  name="assembly"
                                  value={assembly.id}
                                  checked={
                                    this.state.selectedAssembly === assembly.id
                                  }
                                />
                                <span className="checkmark check"></span>
                              </>
                            }
                            
                          </label>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={
                  new Date(this.state.selectedSlotInitialDate) < new Date()
                    ? new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        new Date().getDate() - 1
                      )
                    : new Date(
                        new Date(
                          this.state.selectedSlotInitialDate
                        ).getFullYear(),
                        new Date(this.state.selectedSlotInitialDate).getMonth(),
                        new Date(this.state.selectedSlotInitialDate).getDate() -
                          1
                      )
                }
                maxDate={
                  this.state.selectedSlotFinalDate
                    ? new Date(
                        this.state.selectedSlotFinalDate
                          .split("-")
                          .map((s: number, i) => (i === 2 ? s - 2 : s))
                      )
                    : undefined
                }
                value={this.state.assemblyStartDate}
                label="Fecha de inicio"
                name="assemblyStartDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy"
                minDate={this.state.assemblyStartDate}
                // minDate={this.state.selectedSlotInitialDate}
                maxDate={
                  this.state.selectedSlotFinalDate
                    ? new Date(
                        this.state.selectedSlotFinalDate
                          .split("-")
                          .map((s: number, i) => (i === 2 ? s - 2 : s))
                      )
                    : undefined
                }
                value={this.state.assemblyEndDate}
                label="Fecha de fin"
                name="assemblyEndDate"
                onChange={this.handleNewAssembleInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <Checkbox
                onChange={(e) => this.handleDateToChange(e)}
                item={{
                  id: 1,
                  description: "Indefinido",
                  active: this.state.assemblyEndDate === null ? true : false,
                }}
                name={"assemblyEndDate"}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showAddAssembleModal(false)}
            />
            <Button
              text="Asignar"
              // disabled={
              //   !this.state.selectedAssembly || !this.state.assemblyStartDate
              // }
              type="primary"
              onClick={() =>
                this.addModalErrorManager(true).result &&
                this.updateAssemblies()
              }
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>
      </View>
    );
  }

  addModalErrorManager = (doValidate) => {
    let messages = [];
    if (doValidate) this.setState({ manageErrorModal: true });
    if (doValidate || this.state.manageErrorModal) {
      if (!this.state.assemblyStartDate)
        messages.push("Debes seleccionar la fecha inicial");
      if (!this.state.selectedAssembly)
        messages.push("Debes seleccionar un ensamble");
    }
    return { result: !messages.length, messages };
  };

  getHeader = () => {
    let contractInfo = this.props.contractList.find(
      (c) => c.id.toString() === this.props.match.params.contractId.toString()
    );
    if (contractInfo) {
      let LT = {};
      if (contractInfo.network.from_companies.id === this.props.companyId) {
        LT.name = contractInfo.network.to_companies.corporation_name;
        LT.logo = contractInfo.network.to_companies.logo;
      } else {
        LT.name = contractInfo.network.from_companies.corporation_name;
        LT.logo = contractInfo.network.from_companies.logo;
      }
      return (
        <div>
          <div className="transport-line">
            <div className="logo">
              <div
                className={"avatar ".concat()}
                style={{
                  backgroundImage: "url(".concat(
                    this.getAvatarUrl(LT.logo),
                    ")"
                  ),
                }}
              ></div>
            </div>
            <div className="credentials">{LT.name}</div>
          </div>
          <div className="facility">
            <div className="logo">
              <div className="avatar">
                <img
                  alt=""
                  src={getUrlsEnv().files.concat(
                    contractInfo.facilities.avatar.image
                  )}
                />
              </div>
            </div>
          </div>
          <div className="credentials">
            {contractInfo.facilities.name} -{" "}
            {contractInfo.facilities.alias || "-"} -{" "}
            {contractInfo.facilities.code || "-"}
          </div>
          <div className="transport-class">
            {contractInfo.transport_class.code}
          </div>
          <div className="units">
            {contractInfo.actives_fleets.count}/{contractInfo.total_fleets}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  manageUpdateContractAssemble = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        //debugger;
        const current = value.assemblies?.find((asm) => asm.assembly.assembled);
        // debugger;
        if (current) {
          this.setState({
            showEditAssemblePeriodModal: true,
            currentAssembleSlotId: current.contract_slot,
            currentAssembleId: current.id,
            currentContractId: value.contract,
            selectedAddendumInitialDate: current.initial_date
              .split("-")
              .join("/"),
            selectedAddendumFinalDate: current.final_date ? current.final_date.split("-").join("/") : null,
          });
        } else {
          this.setState({
            showEditAssemblePeriodModal: true,
          });
        }
        break;
      case "close":
        this.setState({
          showEditAssemblePeriodModal: false,
        });
        break;
      case "update":
        //debugger;
        const selectedItems = this.state.gridSelectedItems.filter(
          (si) => si !== this.state.currentAssembleSlotId
        );
        if (this.state.currentAssembleSlotId) {
          selectedItems.push(this.state.currentAssembleSlotId);
        }
        let sic = new SaveItemCounter(() => {
          this.setState(
            {
              showEditAssemblePeriodModal: false,
              enabledSelectAll: false,
              gridSelectedItems: [],
            },
            () => this.loadSlots()
          );
        });
        selectedItems.forEach((item) => {
          sic.add();
          const current = this.props.companyContractSlots.find(
            (ccs) => ccs.id === item
          );
          this.props.updateContractAssemblies(
            this.props.companyId,
            this.props.companyContractSlots[0].contract,
            // this.state.currentContractId,
            item,
            current.assemblies.find((asm) => asm.assembly.assembled).assembly.id,
            // this.state.currentAssembleId,

            this.state.selectedAddendumInitialDate 
            ? new Date(this.state.selectedAddendumInitialDate).toLocaleDateString(
              "en-US"
            )
            : null,
            this.state.selectedAddendumFinalDate
              ? new Date(
                  this.state.selectedAddendumFinalDate
                ).toLocaleDateString("en-US")
              : null,
            {
              onSuccess: () => {
                sic.done();
              },
              onError: (error) => {
                let error_to_show = error.response?.data?.errors || ["Ocurrió un error, intente de nuevo"]

                this.setState({
                  errorMessage: error_to_show,
                  showEditAssemblePeriodModal: false
                })

              }
            }
          );

        });
        break;
      default:
    }
  };

  getAvatarUrl = (partialUrl) => {
    if (partialUrl) {
      return getUrlsEnv().files.concat(partialUrl);
    } else {
      return "/images/truck-bckgrnd.png";
    }
  };
}

const mapStateToProps = (state) => {

  const isLoadingContract = state?.api['COMPANIES.CONTRACTS.SLOTS']?.status?.isFetching || false;
  const companyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;
  const currentContract = state.contract.currentContractData;
  const companyContractSlotsArgs = getEntityArgs(
    state,
    "COMPANIES.CONTRACTS.SLOTS"
  );
  const companyContractSlots = (
    getEntityItems(state, "COMPANIES.CONTRACTS.SLOTS") || []
  ).filter((slot) =>
    // slot.initial_date < companyContractSlotsArgs.start_date?.split("T")[0] &&
    slot.final_date <= companyContractSlotsArgs.start_date?.split("T")[0] ||
    slot.initial_date >= companyContractSlotsArgs.end_date?.split("T")[0]
      ? // && slot.final_date > companyContractSlotsArgs.end_date?.split("T")[0])
        false
      : true
  );

  return {
    isLoadingContract,
    currentContract,
    companyId: state.globalCatalog.session.company.id,
    companyType,
    companyContractSlots,
    // companyContractSlotsArgs,
    companyContractAssemblies: getEntityItems(
      state,
      "COMPANIES.CONTRACTS.ASSEMBLIES"
    ).results,
    contractList: state.companyReducer.companyContracts.data.results || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    listContractSlots: (company_id, contract_id, params, opt) => {
      dispatch(
        readEntities(
          "COMPANIES.CONTRACTS.SLOTS",
          {
            company_id,
            contract_id,
            ...params,
          },
          opt
        )
      );
    },
    listContractAssemblies: (company_id, contract_id, search='') => {
      dispatch(
        readEntities("COMPANIES.CONTRACTS.ASSEMBLIES", {
          company_id,
          contract_id,
          search
        })
      );
    },
    deleteContractAssemblies: (company_id, contract_id, slot_id, opt) => {
      dispatch(
        deleteEntity(
          "COMPANIES.CONTRACTS.SLOTS.ASSEMBLIES",
          {
            company_id,
            contract_id,
            slot_id,
          },
          opt
        )
      );
    },
    updateContractAssemble: (
      company_id,
      contract_id,
      slot_id,
      assembly,
      date_from,
      date_to,
      opt
    ) => {
      dispatch(
        deleteEntity(
          "COMPANIES.CONTRACTS.SLOTS.ASSEMBLIES",
          {
            company_id,
            contract_id,
            slot_id,
            assembly,
            date_from,
            date_to,
          },
          opt
        )
      );
    },
    updateContractAssemblies: (
      company_id,
      contract_id,
      slot_id,
      assembly,
      initial_date,
      final_date,
      opt
    ) => {
      dispatch(
        updateEntity(
          "COMPANIES.CONTRACTS.SLOTS",
          {
            company_id,
            contract_id,
            slot_id,
            assembly,
            initial_date,
            final_date,
          },
          opt
        )
      );
    },
    updateContractPeriod: (
      company_id,
      contract_id,
      slot_id,
      initial_date,
      final_date,
      opt
    ) => {
      dispatch(
        updateEntity(
          "COMPANIES.CONTRACTS.SLOTS",
          {
            company_id,
            contract_id,
            slot_id,
            initial_date,
            final_date,
          },
          opt
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetailView);
