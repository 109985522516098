import React, { Component, createRef } from "react";
import * as rxjs from "rxjs";

const MAPS_API_KEY = "AIzaSyCnd2kziemN0LAbXP8SjbwqXx-fsm9Xofo";
//const  MAPS_API_KEY = 'AIzaSyAXMF4tIvraPvHSopBZCsEH6-10xQwCkrc';

export class MapContainer extends Component {
  render() {
    return (
      <div
        id="map-container"
        className={this.props.className || ""}
        ref={this.props.containerRef}
      />
    );
  }
}
export default MapContainer;

export class MapController {
  constructor() {
    this.geocodeResultSubject = new rxjs.Subject();
    this.markerSubject = new rxjs.Subject();
    this.isReadySubject = new rxjs.Subject(false);
    this.map = "";
    this.apiLoaded = false;
    this.geoCoder = false;
    this.drawingManager = false;
    this.mapContainerRef = createRef();
  }

  getMapContainer = (settings) => {
    return (
      <MapContainer
        width={settings.width || ""}
        height={settings.height || ""}
        className={settings.className || ""}
        map={this.map}
        containerRef={this.mapContainerRef}
      />
    );
  };

  loadMap = (zoom, lat, lng, mapTypeId, showUI) => {
    this.loadApi().then((value) => {
      this.createMap(zoom, lat, lng, mapTypeId, showUI);
      this.geocoder = new window.google.maps.Geocoder();
      this.drawingManager = new window.google.maps.drawing.DrawingManager();
      this.infoWindow = new window.google.maps.InfoWindow();
      this.apiLoaded = value;
      this.isReadySubject.next(value);
      this.isReadySubject.complete();
    });
  };

  geocodeAddress = (address) => {
    if (this.geocoder && address) {
      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.geocodeResultSubject.next(results);
        } else {
          // this.geocodeSubject.error(status);
        }
        return;
      });
    }
  };

  geocodeLocation = (lat, lng) => {
    let latLng = new window.google.maps.LatLng(lat, lng);
    if (this.geocoder && latLng) {
      this.geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.geocodeResultSubject.next(results);
        } else {
          // this.geocodeSubject.error(status)
        }
        return;
      });
    }
  };

  loadApi = () => {
    return new Promise((resolve) => {
      if (this.apiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://maps.googleapis.com/maps/api/js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&language=es-419&libraries=drawing`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  createMap = (zoom, lat, lng, mapTypeId, showUI = false) => {
    this.map = new window.google.maps.Map(this.mapContainerRef.current, {
      zoom: zoom || 5,
      center: {
        lat: lat || 22.2264473,
        lng: lng || -100.5384141,
      },
      clickableIcons: false,
      mapTypeId: mapTypeId || "satellite",
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      },
      disableDefaultUI: showUI,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    });
  };

  setCenterMap = (position) => {
    if (position && position[0] && position[0].geometry) {
      this.map.fitBounds(position[0].geometry.viewport);
    } else if (position && position.geometry) {
      this.map.fitBounds(position.geometry.viewport);
    } else if (position && position.lat) {
      this.map.setCenter(position);
    }
  };

  setCenterMapMultiple = (lat_min, lng_min, lat_max, lng_max, fitBounds = true) => {
    if (window.google && window.google.maps && window.google.maps.LatLng) {
      this.map.setCenter(
        new window.google.maps.LatLng(
          (lat_max + lat_min) / 2.0,
          (lng_max + lng_min) / 2.0
        )
      );
      if(fitBounds){
        this.map.fitBounds(
          new window.google.maps.LatLngBounds(
            //bottom left
            new window.google.maps.LatLng(lat_min, lng_min),
            //top right
            new window.google.maps.LatLng(lat_max, lng_max)
          )
        );
      }
    }
  };

  createPolygon = (
    coords = [],
    color = "#FF0000",
    editable = false,
    dragable = false
  ) => {
    if (window.google && window.google.maps && window.google.maps.Polygon) {
      return new window.google.maps.Polygon({
        paths: coords,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.35,
        editable: editable,
        dragable: dragable,
      });
    } else {
      return {};
    }
  };

  // drawPolygon = (polygon) => {
  //   polygon.setMap(this.map);
  // }

  createPolyline = (
    coords = [],
    color = "#FF0000",
    lineWeight = 2,
    editable = false,
    draggable = false
  ) => {
    return new window.google.maps.Polyline({
      path: coords,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: lineWeight,
      editable: editable,
      draggable: draggable,
    });
  };

  drawPolygon = (color, onComplete = () => {}) => {
    this.drawingManager.setOptions({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        fillColor: color,
        fillOpacity: 0.2,
        strokeColor: color,
        strokeWeight: 2,
        editable: false,
      },
    });
    let listener = window.google.maps.event.addListener(
      this.drawingManager,
      "polygoncomplete",
      (event) => this.polygonComplete(event, onComplete, listener)
    );
    if (!this.drawingManager.getMap()) this.drawingManager.setMap(this.map);
  };

  polygonComplete = (event, onComplete, listener) => {
    this.drawingManager.setDrawingMode(null);
    window.google.maps.event.removeListener(listener);
    onComplete(event);
  };

  removePolygon = (polygon) => {
    this.clearDrawingMode();
    polygon.setMap(null);
  };

  clearDrawingMode = () => {
    this.drawingManager.setDrawingMode(null);
  };

  showInfoWindow = (
    content,
    position,
    offsetX = 0,
    offsetY = 0,
    onReady = () => {}
  ) => {
    this.infoWindow.setOptions({
      content: content,
      position: position,
      pixelOffset: new window.google.maps.Size(offsetX, offsetY),
    });
    this.infoWindow.open(this.map);
    this.infoWindow.addListener("domready", onReady);
  };

  hideInfoWindow = () => {
    this.infoWindow.close();
  };

  createMarker = () => {
    return new window.google.maps.Marker({});
  };

  setMarker = (position, marker) => {
    if (position && position[0] && position[0].geometry) {
      marker.setPosition(position[0].geometry.location);
    } else if (position && position.geometry) {
      marker.setPosition(position.geometry.location);
    } else if (position && position.lat) {
      marker.setPosition(position);
    }
    return marker;
  };

  setMarkerImage = (url, position, size) => {
    let icon = {
      url: url, // url
      scaledSize: new window.google.maps.Size(size.width, size.height), // scaled size
    };
    let newPosition = new window.google.maps.LatLng(position?.lat ?? 0, position?.lng ?? 0);
    let marker = new window.google.maps.Marker({
      position: newPosition,
      icon: icon,
    });
    return marker;
  };

  // export const setMarker = (map, marker, position) => {
  //   if (position && position[0] && position[0].geometry) {
  //     position = position[0];
  //   }
  //   map.setCenter(position.geometry.location);
  //   if (marker) {
  //     marker.setPosition(position.geometry.location);
  //   } else {
  //     marker = new window.google.maps.Marker({
  //       map: map,
  //       position: position.geometry.location
  //     });
  //   }
  //   setCenterMap(map, position);
  //   return marker;
  // }

  // export const setCenterMap = (map, position) => {
  //   if (position && position[0] && position[0].geometry) {
  //     map.fitBounds(position[0].geometry.viewport);
  //   } else if (position && position.geometry) {
  //     map.fitBounds(position.geometry.viewport);
  //   } else if (position && position.lat) {
  //     map.setCenter(position);
  //   }
  // }

  // export const geocodeAddress = (map, geocoder, address, onOk, onError = (() => { })) => {
  //   //   if (map && geocoder && address) {
  //   //     geocoder.geocode({ 'address': address }, (results, status) => {
  //   //       if (status === window.google.maps.GeocoderStatus.OK) {
  //   //         onOk(results);
  //   //       } else {
  //   //         onError();
  //   //       }
  //   //       return;
  //   //     });
  //   //   }
  // }

  // export const geocodeLocation = (map, geocoder, latLng, onOk, onError = (() => { })) => {
  //   //   if (map && geocoder && latLng) {
  //   //     geocoder.geocode({ 'location': latLng }, (results, status) => {
  //   //       if (status === window.google.maps.GeocoderStatus.OK) {
  //   //         onOk(results);
  //   //       } else {
  //   //         onError();
  //   //       }
  //   //       return;
  //   //     });
  //   //   }
  // }
}
