import React from "react";
import {
  ArrowDown,
  ArrowUp,
  ArrowUpDown,
} from "orbi_v2_frontend_components/lib/Icons";
import {
  Equipment,
  TruckDoubleEquipment,
  Truck,
  License,
} from "../../../shared/icons/assemble";
import { Facility } from "../../../shared/icons/traffic";
import { Flex } from "../../../shared/styled/flex";
import { FILES_SERVER } from "../../../redux/api";
import {
  WeightIcon,
  VolumeIcon,
  SpaceIcon,
} from "../../../shared/icons/traffic/";
import Tag from "../../../shared/controls/tag";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { isArray } from "lodash";
// import OnHoverTooltip from "./on-hover-tooltip";

export const mapResponseToSpaces = (space, index) => ({
  rowIndex: index + 1,
  id: space.space_id,
  facility: space.facility_id,
  loadManeuver: space.windows.find((w) => w.load) ? true : false,
  unloadManeuver: space.windows.find((w) => w.unload) ? true : false,
  unloadLoadManeuver: space.windows.find((w) => w.loadunload) ? true : false,
  loadManeuverIcon: <ArrowUp width="10px" color="white"></ArrowUp>,
  unloadManeuverIcon: <ArrowDown width="10px" color="white"></ArrowDown>,
  unloadLoadManeuverIcon: (
    <ArrowUpDown width="10px" color="white"></ArrowUpDown>
  ),
});

export const mapSpaceWindows = (space: any) => {
  // debugger;
  let repetition = [];
  let results = [];
  space.windows.map((wnd) => {

    let begin = wnd.hour_from * 100 + wnd.minute_from;
    let end = wnd.hour_to * 100 + wnd.minute_to;

    if (wnd.next_day === true) {

      if (wnd.first_day === true) {
        end = 2359;
      } else if (wnd.first_day === false) {
        begin = 0;
      }

    }

    let itemFound = false;
    let isNextDay = wnd.next_day === true;
    let isFirstDay = wnd.first_day === true;

    for (let i = 0; i < repetition.length; i++) {
      if (repetition[i].begin === begin && repetition[i].end === end && !isNextDay) {
        itemFound = true;
      }
    }

    if (!itemFound) {

      if (isNextDay) {

        begin = isFirstDay ? begin : 0;
        end = isFirstDay ? 2400 : end;

        results.push({
          begin,
          end,
          capacities: wnd.capacity,
          first_day: wnd.next_day === true ? wnd.first_day === true : undefined
        });

      } else {
        results.push({
          begin,
          end,
          capacities: wnd.capacity
        });
      }

      repetition.push({
        end,
        begin,
      });
    }
  });

  return results;
};

export const mapSpaceTags = (space: any, companyTags: any[]) => {
  return space.windows[0]?.tags
    .split(",")
    .map((tagIdString) => {
      const tag =
        companyTags.find((tag) => tag.id.toString() === tagIdString) || {};
      return {
        id: tag.id,
        color: '#'.concat(tag.color),
        description: tag.title,
      };
    })
    .filter((tag) => tag.id);
};

export const mapSpaceCapacities = (space) => {};

const formatTimeZone = (tZ) => {
  if (tZ.length) {
    if (tZ.includes("UTC")) {
      tZ = tZ.slice(tZ.indexOf("/") + 1);
      tZ = tZ.trim();
    }
    return tZ;
  }
  return "America/Mexico_City";
};

export const mapSpaceAppointments = (
  loadOrders: any[] = [],
  unloadOrders: any[] = [],
  transportClasses,
  companyTags,
  windows: any[],
  onClick: Function,
  spaceIndex: number,
  spaceId: number,
  facilityId: number,
  facilityTimeZone = "",
  // conflicts: any[] = []
): OrderCard[] => {
  // console.log(conflicts);
  let data = loadOrders.filter(order => order.minutes > 0)
    .map((order) => {
      const {
        reserved,
        color,
        confirmed,
        visible,
        inFacility,
        fontColor,
      } = getOrderStatus(order.status, order.order_containers, "loading", order.alert, order.contabilizacion);
      const begin =
        new Date(order.appointment_loading_date).getHours() * 100 +
        new Date(order.appointment_loading_date).getMinutes();
      const { icon, code, group } = getAppointmentTransportClass(
        order.transport_class,
        transportClasses,
        companyTags,
        order.trip_id
      );
      // const capacity = windows
      //   .find((w) => begin >= w.begin && begin < w.end)
      //   .capacities.find((c) => c.code_class === code);
      const duration = (order.minutes / 60) * 100;
      // capacity &&
      // Math.max(
      //   capacity.single_load,
      //   capacity.double_load,
      //   capacity.single_loadunload,
      //   capacity.double_loadunload
      // );
      // let fProvider = order.fleet_provider && Object.values(order.fleet_provider);
      let eta = null;
      if(order.assembly_eta) {
        let today = (new Date());
        today.setHours(0,0,0,0);
        let etaDate = (new Date(order.assembly_eta));
        etaDate.setHours(0,0,0,0);
        let difference = (etaDate.getTime() - today.getTime())/(1000*60*60*24);
        
        eta = new Date(order.assembly_eta).toLocaleString("en-US", {
            timeZone: formatTimeZone(facilityTimeZone),
            hour: "numeric",
            minute: "numeric",
            hour12: false
          });
        if(difference) {
          eta = ''.concat(eta, ' +', difference);
        }
      }
      
      return {
        spaceIndex,
        spaceId,
        facilityId,
        statusId: order.status,
        id: order.order_id,
        level: confirmed ? order.priority : null,
        description: /*order.trip_id !== 0 ? <OnHoverTooltip order={order} text={order.code}/>
          : */parseInt(order.code).toString(),
        descriptionText: parseInt(order.code).toString(),
        begin,
        opIcon: <ArrowUp  width="8px" color={fontColor}></ArrowUp>,
        op: "load",
        onDrop: (a, b, c, d, e) => alert("drag & drop\ncita sobre cita"),
        fontColor: fontColor,
        color: color,
        icon: reserved ? icon : null,
        plates: order.plates,
        // expClass: code,
        fleet_provider:  order.fleet_provider && (order.fleet_provider.alias || order.fleet_provider.orbi_id),
        expClass: group ? group : code,
        content: inFacility && (
          <Flex style={{ margin: "auto" }}>
            <Facility />
          </Flex>
        ),
        end: begin + duration === 2400 ? 2399 : begin + duration,
        visible,
        onClick: onClick,
        tripId: order.trip_id,
        shadow: order.shadow,
        alert: order.alert,
        eta,
        containers: setContainersEta(order.order_containers || [], 'load', order.appointment_loading_date, ''),
        transportClassId: order.transport_class
      };
    })
    .concat(
      unloadOrders.filter(order => order.minutes > 0).map((order) => {
        const {
          reserved,
          color,
          // confirmed,
          visible,
          // inFacility,
          fontColor,
        } = getOrderStatus(order.status, order.order_containers, "unloading", order.alert, order.contabilizacion);
        const { icon, code, group } = getAppointmentTransportClass(
          order.transport_class,
          transportClasses,
          companyTags,
          order.trip_id
        );
        const begin =
          new Date(order.appointment_unload_date).getHours() * 100 +
          new Date(order.appointment_unload_date).getMinutes();
          // debugger;
        const capacity = ((windows || [])
          .find((w) => begin >= w.begin && begin < w.end)
          ?.capacities || []).find((c) => c.code_class === code);
          

        // debugger
        let duration = ((order.minutes / 60) * 100);
        // let duration = order.minutes ? ((order.minutes / 60) * 100) :
        //   (capacity &&
        //   (((Math.max(
        //     capacity.single_unload,
        //     capacity.double_unload,
        //     capacity.single_loadunload,
        //     capacity.double_loadunload
        //   ) || 0) / 60) * 100));
        // duration = ((duration || 0) / 60) * 100;
      // const eta = order.assembly_eta ? (
      //   new Date(order.assembly_eta).toLocaleString("en-US", {
      //     timeZone: formatTimeZone(facilityTimeZone),
      //     hour: "numeric",
      //     minute: "numeric",
      //   })
      // ) : null;
        let eta = null;
        if(order.assembly_eta) {
          let today = (new Date());
          today.setHours(0,0,0,0);
          let etaDate = (new Date(order.assembly_eta));
          etaDate.setHours(0,0,0,0);
          let difference = (etaDate.getTime() - today.getTime())/(1000*60*60*24);
          
          eta = new Date(order.assembly_eta).toLocaleString("en-US", {
              timeZone: formatTimeZone(facilityTimeZone),
              hour: "numeric",
              minute: "numeric",
              hour12: false
            });
          if(difference) {
            eta = ''.concat(eta, ' +', difference);
          }
        }
        return {
          spaceIndex,
          spaceId,
          facilityId,
          statusId: order.status,
          id: order.order_id,
          level: reserved ? order.priority : null,
          description: /*order.order_id !== 0 ? <OnHoverTooltip order={order} text={order.code}/>
            : */parseInt(order.code).toString(),
          descriptionText: parseInt(order.code).toString(),
          begin: begin,
          icon: reserved ? icon : null,
          plates: order.plates,
          // expClass: code,
          opIcon: <ArrowDown width="8px" color={fontColor}></ArrowDown>,
          fleet_provider: order.fleet_provider && (order.fleet_provider.alias || order.fleet_provider.orbi_id),
          op: "unload",
          expClass: group ? group : code,
          onDrop: (a, b, c, d, e) => alert("drag & drop\ncita sobre cita"),
          color: color,
          fontColor: fontColor,
          end: begin + duration === 2400 ? 2399 : begin + duration,
          duration,
          visible,
          onClick: onClick,
          tripId: order.trip_id,
          shadow: order.shadow,
          alert: order.alert,
          eta,
          containers: setContainersEta(order.order_containers || [], 'unload', '', order.appointment_unload_date,),
          transportClassId: order.transport_class
        };
      })
    )

  return data;
};

const setContainersEta = (containers, op, loadingDate, unloadingDate) => {
  let containersDates = [];

  if (op === 'load') {

    (containers || []).map( container => {
      containersDates.push({
        eta: container.eta_loading || '',
        ata: container.ata_loading ||'',
        date: loadingDate || ''
      });
    });

  } else {

    (containers || []).map( container => {
      containersDates.push({
        eta: container.eta_unloading || '',
        ata: container.ata_unloading ||'',
        date: unloadingDate || ''
      });
    });

  }

  return containersDates;
}

export const mapSpaceAppointmentsConflicts = (
  conflicts: any[] = [],
  transportClasses,
  companyTags,
  onClick: Function,
  spaceIndex: number,
  spaceId: number,
  facilityId: number,
  windows: any[] = []
): OrderCard[] => {
  // console.log("conflicts", conflicts);
  let data = conflicts
    .map((order) => {
      const conflictBegin =
        new Date(order.start).getHours() * 100 +
        new Date(order.start).getMinutes();

      let windowIn = null;

      // (windows || []).forEach( wind => {
      //   let start = (wind.hour_from * 60) + wind.minute_from;
      //   let end = (wind.hour_to * 60) + wind.minute_to;

      //   if (end === 0 && start === 0) { end = 1440; }

      //   let timeToValidate = 0;

      //   if (order.start) {
      //     timeToValidate = (new Date(order.start).getHours() * 60) + new Date(order.start).getMinutes();
      //   }

      //   if (timeToValidate >= start && timeToValidate < end) { windowIn = wind; }
      // });

      let orderToModify = [];
      let minutesToModify = 0;

      // if (windowIn !== null) {
      //   let capacities = windowIn.capacity;

      //   (order.shared || []).forEach( shared => {

      //     let isUnload = false;
      //     let isLoad = false;
      //     let ordersTemp = [];
  
      //     (shared || []).forEach( sharedItem => {
  
      //       if (sharedItem.op === 'load') {
      //         isUnload = true;
      //         ordersTemp.push(sharedItem);
      //       } else if (sharedItem.op === 'unload') {
      //         isLoad = true;
      //         ordersTemp.push(sharedItem);
      //       }
  
      //     });
  
      //     if (isUnload && isLoad) {
      //       orderToModify = orderToModify.concat(ordersTemp);
      //     }
  
      //   }); 

      //   orderToModify.forEach( item => {

      //     let capacity = capacities.filter(capacity_item => {
      //       return capacity_item.id_class === item.transport_class
      //     });

      //     if ((capacity || []).length > 0) {
      //       if ((item.order_containers).length === 1) {
      //         minutesToModify = (minutesToModify < capacity[0].single_loadunload) ? capacity[0].single_loadunload : minutesToModify;
      //       } else {
      //         minutesToModify = (minutesToModify < capacity[0].double_loadunload) ? capacity[0].double_loadunload : minutesToModify;
      //       }
      //     }

      //   });

      //}

      let endTemp = moment(order.start)
        .add(order.minutes, "m")
        .toDate();
      let conflictEnd = new Date(endTemp).getHours() * 100 + new Date(endTemp).getMinutes();
      
      let raw_orders = order.raw_orders.map((e) => {
        const { icon, code, group } = getAppointmentTransportClass(
          e.transport_class,
          transportClasses,
          companyTags,
          e.trip_id
        );

        let appointment = e.op === "load" ? e.appointment_loading_date : e.appointment_unload_date;
        let orderAppointment = new Date(appointment);
        
        if(orderAppointment.getTime() < (new Date(order.start)).getTime()) {
          orderAppointment = new Date(order.start);
          appointment = order.start;
        }
        const begin =
          orderAppointment.getHours() * 100 +
          orderAppointment.getMinutes();

        let endTemp = moment(appointment)
        .add(e.minutes, "m")
        .toDate();

        // if (orderToModify.length > 0 && (orderToModify.filter(order_item => order_item.order_id === e.order_id) || []).length > 0 && minutesToModify > e.minutes) {

        //   endTemp = moment(appointment)
        //   .add(minutesToModify, "m")
        //   .toDate();

        // } else {

        //   endTemp = moment(appointment)
        //   .add(e.minutes, "m")
        //   .toDate();

        // }

        let end =
          new Date(endTemp).getHours() * 100 + new Date(endTemp).getMinutes();

        const {
          color,
          fontColor,
          reserved,
        } = getOrderStatus(e.status, e.order_containers, e.op === "load" ? "loading" : "unloading", e.alert, e.contabilizacion);
        // debugger;
        return {
          id: e.order_id,
          code: /*e.order_id !== 0 ? <OnHoverTooltip order={e} text={e.code}/>
            : */parseInt(e.code).toString(),
          codeText: parseInt(e.code).toString(),
          op: e.op,
          statusId: e.status,
          facilityId,
          expClass: group ? group : code,
          begin: begin,
          end: end,
          tripId: e.trip_id,
          shadow: e.shadow,
          fontColor: fontColor || "#fff",
          color: color,
          icon: reserved ? icon : null,
          plates: e.plates,
          level: reserved ? e.priority : null,
          opIcon: e.op === "load" ? <ArrowUp  width="8px" color={fontColor || "#fff"}></ArrowUp> : <ArrowDown width="8px" color={fontColor || "#fff"}></ArrowDown>,
          fleet_provider: e.fleet_provider && (e.fleet_provider.alias || e.fleet_provider.orbi_id),
          alert: e.alert,
          spaceIndex,
          spaceId,
          containers: setContainersEta(e.order_containers || [], e.op, e.appointment_loading_date, e.appointment_unload_date),
        };
      })


      let orders = order.orders.map((e) => {
        const { icon, code, group } = getAppointmentTransportClass(
          e.transport_class,
          transportClasses,
          companyTags,
          e.trip_id
        );
        let appointment = e.op === "load" ? e.appointment_loading_date : e.appointment_unload_date;
        let orderAppointment = new Date(appointment);
        // debugger;
        if(orderAppointment.getTime() < (new Date(order.start)).getTime()) {
          orderAppointment = new Date(order.start);
          appointment = order.start;
        }
        const begin =
          orderAppointment.getHours() * 100 +
          orderAppointment.getMinutes();

        let endTemp = moment(appointment)
          .add(e.minutes, "m")
          .toDate();
        let end =
          new Date(endTemp).getHours() * 100 + new Date(endTemp).getMinutes();

        const {
          color,
          fontColor,
          reserved,
        } = getOrderStatus(e.status, e.order_containers, e.op === "load" ? "loading" : "unloading", e.alert, e.contabilizacion);
        // debugger;
        return {
          id: e.order_id,
          code: /*e.order_id !== 0 ? <OnHoverTooltip order={e} text={e.code}/>
            : */parseInt(e.code).toString(),
          codeText: parseInt(e.code).toString(),
          op: e.op,
          statusId: e.status,
          facilityId,
          expClass: group ? group : code,
          begin: begin,
          end: end,
          tripId: e.trip_id,
          shadow: e.shadow,
          fontColor: fontColor || "#fff",
          color: color,
          icon: reserved ? icon : null,
          plates: e.plates,
          level: reserved ? e.priority : null,
          opIcon: e.op === "load" ? <ArrowUp  width="8px" color={fontColor || "#fff"}></ArrowUp> : <ArrowDown width="8px" color={fontColor || "#fff"}></ArrowDown>,
          fleet_provider: e.fleet_provider && (e.fleet_provider.alias || e.fleet_provider.orbi_id),
          alert: e.alert,
          spaceIndex,
          spaceId,
          containers: setContainersEta(e.order_containers || [], e.op, e.appointment_loading_date, e.appointment_unload_date),
        };
      }).concat(order.shared.map(s => {
        // debugger;
        let mainAppointment = s[0] || {};
        const { icon, code, group } = getAppointmentTransportClass(
          mainAppointment.transport_class,
          transportClasses,
          companyTags,
          mainAppointment.trip_id
        );
        let appointment = mainAppointment.op === "load" ? mainAppointment.appointment_loading_date : mainAppointment.appointment_unload_date;
        let orderAppointment = new Date(appointment);
        // debugger;
        if(orderAppointment.getTime() < (new Date(order.start)).getTime()) {
          orderAppointment = new Date(order.start);
          appointment = order.start;
        }
        const begin =
          orderAppointment.getHours() * 100 +
          orderAppointment.getMinutes();


        let endTemp = moment(appointment)
        .add(mainAppointment.minutes, "m")
        .toDate(); 

        // if (orderToModify.length > 0 && (orderToModify.filter(order_item => order_item.order_id === mainAppointment.order_id) || []).length > 0 && minutesToModify > mainAppointment.minutes) {
        //   endTemp = moment(appointment)
        //   .add(minutesToModify, "m")
        //   .toDate();
        // } else {
        //   endTemp = moment(appointment)
        //   .add(mainAppointment.minutes, "m")
        //   .toDate();
        // }

        let end =
          new Date(endTemp).getHours() * 100 + new Date(endTemp).getMinutes();

        const {
          color,
          fontColor,
          reserved,
        } = getOrderStatus(mainAppointment.status, mainAppointment.order_containers, mainAppointment.op === "load" ? "loading" : "unloading", mainAppointment.alert, mainAppointment.contabilizacion);
        // debugger;
        let altOrders = null;
        if(s.length > 1){
          altOrders = s.filter(sA => sA.order_id !== mainAppointment.order_id).map(sA => {
            const altColor = getOrderStatus(sA.status, sA.order_containers, sA.op === "load" ? "loading" : "unloading", sA.alert, sA.contabilizacion);
            const altGroup = getAppointmentTransportClass(
              sA.transport_class,
              transportClasses,
              companyTags,
              sA.trip_id
            );
            return {
              codeText: parseInt(sA.code).toString(),
              code: /*sA.trip_id !== 0 ? <OnHoverTooltip order={sA} text={sA.code}/>
                : */parseInt(sA.code).toString(),
              opIcon: sA.op === "load" ? <ArrowUp  width="8px" color={altColor.fontColor || "#fff"}></ArrowUp> : <ArrowDown width="8px" color={altColor.fontColor || "#fff"}></ArrowDown>,
              expClass: altGroup.group ? altGroup.group : altGroup.code,
              level: altColor.reserved ? sA.priority : null,
              plates: sA.plates,
              color: altColor.color,
              fontColor: altColor.fontColor || "#fff",
              alert: sA.alert
            }
          })
        }
        return {
          id: mainAppointment.order_id,
          facilityId,
          spaceIndex,
          spaceId,
          // code: s.map(sA => sA.code).join(", "),
          codeText: parseInt(mainAppointment.code).toString(),
          code: /*mainAppointment.trip_id !== 0 ? <OnHoverTooltip order={mainAppointment} text={mainAppointment.code}/>
            : */parseInt(mainAppointment.code).toString(),
          expClass: group ? group : code,
          begin: begin,
          end: end,
          tripId: mainAppointment.trip_id,
          op: mainAppointment.op,
          icon: reserved ? icon : null,
          alert: mainAppointment.alert,
          // shadow: s[0].shadow,
          altOrders,
          statusId: mainAppointment.status,
          altTrips: s.map(sA => ({
            tripId: sA.trip_id,
            orderId: sA.order_id,
            tripCode: sA.signature,
            op: sA.op
          })),
          shadow: s.filter(sA => !sA.shadow).length ? false : true,
          fontColor: fontColor || "#fff",
          color: color,
          level: reserved ? mainAppointment.priority : null,
          plates: mainAppointment.plates,
          opIcon: mainAppointment.op === "load" ? <ArrowUp  width="8px" color={fontColor || "#fff"}></ArrowUp> : <ArrowDown width="8px" color={fontColor || "#fff"}></ArrowDown>,
          fleet_provider: mainAppointment.fleet_provider && (mainAppointment.fleet_provider.alias || mainAppointment.fleet_provider.orbi_id),
        };
      }))
      return {
        isConflict: true,
        spaceIndex,
        spaceId,
        statusId: "", //order.status,
        id: orders[0].id, //order.order_id,
        level: "", //reserved ? order.level : null,
        begin: conflictBegin, //c.start, //begin,
        onDrop: "", //(a, b, c, d, e) => alert("drag & drop\ncita sobre cita"),
        color: "none", //color,
        fontColor: "#6197F5", //fontColor,
        end:conflictEnd, //c.start, //begin + duration,
        visible: true, //,
        tripId: "", //order.trip_id,
        orders: orders,
        raw_orders,
        onHover: () => {},
        onClick: () => {},
      };
    })
    .filter((a) => a.visible);
  return data;
};

const getAppointmentTransportClass = (
  transportClassId,
  transportClasses,
  companyTags = [],
  trip_id = 0
) => {
  return (
    transportClasses
      .map((tc) => {
        let group =
          tc.tags.length && companyTags.length
            ? (
                companyTags.find((tag) => tag.id === tc.tags[0].id) || {
                  title: "",
                }
              ).title
            : null;
        return {
          icon: <TruckDoubleEquipment
            height={"8px"}
            fill="currentColor"
            stroke="currentColor"
          />,
          code: tc.code,
          group: group,
          id: tc.id,
        };
      })
      .find((tc) => tc.id === transportClassId) || {}
  );
};

const getColor = (color, status, containers, type, contabilization) => {
  let containersLength = Object.values(containers).length;
  if (containersLength >= 1) {
    switch (type) {
      case "loading":
        switch (status) {
          case 7:
            if (containersLength === 1) {
              if (containers[0].status === 2) {
                return contabilization ? "#006fa3" : "#86e3c2";
              } else if (
                containers[0].status >= 3 &&
                containers[0].status <= 6
              ) {
                return "#05C985";
              } else if (containers[0].status >= 7) {
                return "#336741";
              }
            } else {
              if (containers[0].status === 2 && containers[1].status === 2) {
                return contabilization ? "#006fa3" : "#86e3c2";
              } else if (
                containers[0].status >= 3 &&
                containers[0].status <= 6 &&
                containers[1].status >= 3 &&
                containers[1].status <= 6
              ) {
                return "#05C985";
              } else if (
                containers[0].status >= 7 &&
                containers[1].status >= 7
              ) {
                return "#336741";
              }
            }
            return color;

          default:
            return color;
        }

      default:
        switch (status) {
          case 7:
            if (containersLength === 1) {
              if (containers[0].status >= 2 && containers[0].status <= 10) {
                return contabilization ? "#006fa3" : "#86e3c2";
              } else if (
                containers[0].status >= 11 &&
                containers[0].status <= 14
              ) {
                return "#05C985";
              } else if (containers[0].status === 15) {
                return "#336741";
              }
            } else {
              if (
                containers[0].status >= 2 &&
                containers[0].status <= 10 &&
                containers[1].status >= 2 &&
                containers[1].status <= 10
              ) {
                return contabilization ? "#006fa3" : "#86e3c2";
              } else if (
                containers[0].status >= 11 &&
                containers[0].status <= 14 &&
                containers[1].status >= 11 &&
                containers[1].status <= 14
              ) {
                return "#05C985";
              } else if (
                containers[0].status === 15 &&
                containers[1].status === 15
              ) {
                return "#336741";
              }
            }
            return color;

          default:
            return color;
        }
    }
  }
};

const getOrderStatus = (statusId: number, containers, type, alert, contabilization): TripStatus => {
  switch (statusId) {
    case 1:
      return { // cb1717
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Pendiente",
        actions: {
          primary: { caption: "Reservar", newStatus: 5 },
          secondary: { caption: "Cerrar" },
          extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        },
        visible: false,                   
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 10:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Sin reservación",
        actions: {
          primary: { caption: "Reservar", newStatus: 5 },
          secondary: { caption: "Cerrar" },
          extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 4:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Reservación rechazada",
        actions: {
          primary: { caption: "Reservar", newStatus: 5 },
          secondary: { caption: "Cerrar" },
          extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };
    case 3:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "white"),
        description: "Reservación pendiente",
        actions: {
          primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cambiar cita" },
          extra: [
            { caption: "Cancelar viaje", newStatus: 18 },
            { caption: "*Confirma Reservación", newStatus: 7 },
            { caption: "*Rechaza Reservación", newStatus: 3 },
          ],
        },
        visible: true,
        reserved: true,
        confirmed: false,
        fontColor: alert || contabilization ? "white" : "#6197F5",
      };

    case 2:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Confirmado",
        actions: {
          primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cambiar cita" },
          extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 5:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Reservación confirmada sin asignar",
        actions: {
          primary: { caption: "Cancelar reservación" },
          secondary: { caption: "Asignar cita" },
          extra: [{ caption: "Cancelar viaje" }],
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 6:
      return {
        color: alert ? "#cb1717" : (contabilization ? "#006fa3" : "#8B95A6"),
        description: "Reservación confirmada asignada",
        actions: {
          primary: { caption: "Cancelar reservación" },
          secondary: { caption: "Asignar cita" },
          extra: [{ caption: "Cancelar viaje" }],
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 7:
      let color = getColor("rgb(130,228,194)", 7, containers, type, contabilization);

      return {
        color: alert ? "#cb1717" : color,
        description: "Viaje en proceso",
        actions: {
          primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cambiar cita" },
          extra: [{ caption: "Cancelar viaje", newStatus: 18 }],
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 8:
      return {
        color: alert ? "#cb1717" : "#408051",
        description: "Viaje completado",
        actions: { primary: { caption: "Cerrar" } },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 11:
      return {
        color: alert ? "#cb1717" : "#408051",
        description: "Viaje finalizado sin datos",
        actions: { },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    default:
      return {
        color: "transparent",
        description: "default",
        actions: {},
      };
  }
};

const getTripStatus = (statusId: number, orderStatus): TripStatus => {
  let extra = [];
  switch (statusId) {
    case 1:
      // debugger;
      extra = [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "white",
        description: "Reservación pendiente",
        actions: {
          // primary: { caption: "Reservar", newStatus: 5 },
          // secondary: { caption: "Cerrar" },
          extra,
        },
        visible: false,
        reserved: false,
        confirmed: false,
        fontColor: "#6197F5",
      };
    case 2:
      extra = [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "white",
        description: "Reservación rechazada",
        actions: {
          primary: { caption: "Reservar", newStatus: 5 },
          secondary: { caption: "Cerrar" },
          extra,
        },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: "#6197F5",
      };
    case 3:
      extra =  [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "#8B95A6",
        description: "Reservación confirmada sin asignar",
        actions: {
          // primary: { caption: "Reservar", newStatus: 5 },
          // secondary: { caption: "Cerrar" },
          extra,
        },
        visible: true,
        reserved: false,
        confirmed: false,
      };
    case 4:
      extra = [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "#8B95A6",
        description: "Reservación confirmada asignada",
        actions: {
          // primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cambiar cita" },
          extra,
        },
        visible: true,
        reserved: true,
        confirmed: false,
      };

    case 5:
      extra = [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "rgb(130,228,194)",
        description: "Viaje en proceso",
        actions: {
          // primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cambiar cita" },
          extra,
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 6:
      return {
        color: "rgb(130,228,194)",
        description: "Viaje completado",
        actions: {
          // secondary: { caption: "Cerrar" },
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 7:
      return {
        color: "black",
        description: "Cancelado",
        actions: {
          // secondary: { caption: "Cerrar" },
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 8:
      extra = [
        { caption: "Cancelar cita", newStatus: 18 },
        { caption: "Cancelar viaje", newStatus: 18 },
        // { caption: "Cancelar reservación", newStatus: 18 },
        { caption: "Finalizar viaje sin datos", newStatus: 18 },
      ];
      if(orderStatus !== 11){
        extra.push(
          { caption: "Finalizar orden sin datos", newStatus: 18 }
        )
      }
      return {
        color: "black",
        description: "Sin reservación",
        actions: {
          primary: { caption: "Reservar" },
          secondary: { caption: "Cerrar" },
          extra,
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 9:
      return {
        color: "black",
        description: "Finalizado sin datos",
        actions: {
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    default:
      return {
        color: "transparent",
        description: "default",
        actions: {},
      };
  }
};

const getTripStatusShipment = (statusId: number, isDonor): TripStatus => {
  switch (statusId) {
    case 1:
      return {
        color: "white",
        description: "Reservación pendiente",
        actions: {
          primary: { caption: "Confirmar" },
          // secondary: { caption: "Rechazar" },
          extra: [
            { caption: "Seleccionar ensamble" },
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"}
          ],
        },
        visible: false,
        reserved: false,
        confirmed: false,
        fontColor: "#6197F5",
      };
    case 2:
      return {
        color: "white",
        description: "Reservación rechazada",
        actions: {
          // primary: { caption: "Reservar", newStatus: 5 },
          // secondary: { caption: "Cerrar" },
          extra: [
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"}
          ]
          // extra: [
          //   { caption: "Cancelar cita", newStatus: 18 },
          //   { caption: "Cancelar viaje", newStatus: 18 },
          //   { caption: "Cancelar reservación", newStatus: 18 },
          // ],
        },
        visible: true,
        reserved: false,
        confirmed: false,
        fontColor: "#6197F5",
      };
    case 3:
      return {
        color: "#8B95A6",
        description: "Reservación confirmada sin asignar",
        actions: {
          primary: { caption: "Asignar Ensamble", newStatus: 5 },
          secondary: { caption: "Cerrar" },
          extra: [
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"}
          ]
          // extra: [{ caption: "Cancelar reservación", newStatus: 18 }],
        },
        visible: true,
        reserved: false,
        confirmed: false,
      };
    case 4:
      return {
        color: "#8B95A6",
        description: "Reservación confirmada asignada",
        actions: {
          primary: { caption: "Cerrar" },
          extra: [
            { caption: "Cambiar ensamble", newStatus: 18 },
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"}
            // { caption: "Cancelar reservación", newStatus: 18 },
          ],
        },
        visible: true,
        reserved: true,
        confirmed: false,
      };

    case 5:
      return {
        color: "rgb(130,228,194)",
        description: "Viaje en proceso",
        actions: {
          // primary: { caption: "Cancelar reservación", newStatus: 1 },
          // secondary: { caption: "Cerrar" },
          extra: isDonor ? [
            { caption: "Reiniciar viaje", newStatus: 18 },
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"},
            { caption: "Transferir eventos" }
          ] : [
            // { caption: "Cambiar ensamble", newStatus: 18 },
            { caption: "Reiniciar viaje", newStatus: 18 },
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"},
            // { caption: "Cancelar reservación", newStatus: 18 },
          ],
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 6:
      return {
        color: "rgb(130,228,194)",
        description: "Viaje completado",
        actions: {
          // secondary: { caption: "Cerrar" },
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };
    case 7:
      return {
        color: "black",
        description: "Cancelado",
        actions: {
          // secondary: { caption: "Cerrar" },
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    case 8:
      return {
        color: "black",
        description: "Sin reservación",
        actions: {
          // secondary: { caption: "Cerrar" },
          extra: [
            { caption: "Finalizar viaje sin datos", newStatus: 18},
            { caption: "Finalizar orden sin datos", newStatus: 18},
            { caption: "Agregar geocerca de desenganche"}
          ]
        },
        visible: true,
        reserved: true,
        confirmed: true,
      };

    default:
      return {
        color: "transparent",
        description: "default",
        actions: {},
      };
  }
};

export const getOrderDetail = (trip: Trip, extras): TripDetail => {
  //debugger;
  const { transportClasses } = extras;
  // const status = getOrderStatus(trip.status.id);
  const status = getTripStatus(trip.status.id, extras.orderStatus);
  const expClass = getAppointmentTransportClass(
    trip.transport_class.id,
    transportClasses
  );
  // const { courier } = trip;
  // console.log(expClass, trip.transport_class.id);

  const vehicles = trip.assembly_data?.fleet_vehicle_list
    ?.map((v, ind) => ({
      id: ind,
      number: (v.plates || "").concat("/", v.economic_number) ,
      // number: v.economic_number,
    }))
    .concat({});
    // debugger;
  const operators = trip.assembly_data?.fleet_operator_list
    ?.map((ol) => ol.operator || {})
    .flat()
    .map((o, ind) => ({
      id: ind,
      name: `${o.user?.first_name || ""} ${o.user?.last_name || ""}`,
    }));

    // debugger;

  return {
    id: trip.id,
    tripTag: trip.tags.map((a) => (
      <Tag {...extras.tags.find((t) => t.id === a.tag)} />
    )),
    number: trip.signature || trip.code,
    status: status,
    expeditionClass: expClass.code,
    expeditionClassIcon: expClass.icon,
    courier: {
      logo: trip.fleet_provider.logo
        ? FILES_SERVER.concat(trip.fleet_provider.logo)
        : "/images/interrogacion.svg",
      name: (
        <div>
          <ReactTooltip id="providerDetail">
            <div>{trip.fleet_provider.corporation_name}</div>
            <div>{trip.fleet_provider.orbi_id}</div>
            <div>{trip.fleet_provider.code}</div>
          </ReactTooltip>
          <span data-tip data-for="providerDetail">
            {trip.fleet_provider.alias ||
              trip.fleet_provider.orbi_id ||
              "Transportista"}
          </span>
        </div>
      ),
      vehicleIcon: <Truck fill={"#3f4f6c"} />,
      equipmentIcon: <Equipment fill={"#3f4f6c"} stroke={"#3f4f6c"} />,
      operatorIcon: <License fill={"#3f4f6c"} />,
      vehicle: (vehicles && vehicles[0]) || "",
      equipment: trip.assembly_data?.fleet_equipment_list?.map((v, ind) => ({
        id: ind,
        number: (v.plates || "").concat("/", v.economic_number),
        // number: v.economic_number,
      })),
      operator: operators || "",
    },
    tickets: trip.orders.map((order) => mapTripOrder(order, extras)),
    extraActions: status.actions.extra,
    buttons: status.actions,
  };
};

export const getOrderDetailShipment = (trip: Trip, extras): TripDetail => {
  // const status = getOrderStatus(trip.status.id);
  const status = getTripStatusShipment(trip.status.id, trip.is_donor);
  const expClass = trip.transport_class;
  // const { courier } = trip;

  const vehicles = trip.assembly_data?.fleet_vehicle_list
    ?.map((v, ind) => ({
      id: ind,
      number: v.plates || v.economic_number,
      // number: v.economic_number,
    }))
    .concat({});

  const operators = trip.assembly_data?.fleet_operator_list
    ?.map((ol) => ol.operator)
    .flat()
    .map((o, ind) => ({
      id: ind,
      name: `${o.user.first_name} ${o.user.last_name}`,
    }));

  const result = {
    id: trip.id,
    isDonor: trip.is_donor,
    cfdi: trip.cfdi,
    tripTag: trip.tags.map((a) => (
      <Tag {...extras.tags.find((t) => t.id === a.tag)} />
    )),
    number: trip.signature || trip.code,
    status: status,
    expeditionClass: expClass.code,
    expeditionClassIcon: expClass.icon,
    courier: {
      logo: trip.fleet_provider.logo
        ? FILES_SERVER.concat(trip.fleet_provider.logo)
        : "/images/interrogacion.svg",
      name: (
        <div>
          <ReactTooltip id="providerDetail">
            <div>{trip.fleet_provider.corporation_name}</div>
            <div>{trip.fleet_provider.orbi_id}</div>
            <div>{trip.fleet_provider.code}</div>
          </ReactTooltip>
          <span data-tip data-for="providerDetail">
            {trip.fleet_provider.alias ||
              trip.fleet_provider.orbi_id ||
              "Transportista"}
          </span>
        </div>
      ),
      vehicleIcon: <Truck fill={"#3f4f6c"} />,
      equipmentIcon: <Equipment fill={"#3f4f6c"} stroke={"#3f4f6c"} />,
      operatorIcon: <License fill={"#3f4f6c"} />,
      vehicle: (vehicles && vehicles[0]) || "",
      equipment: trip.assembly_data.fleet_equipment_list?.map((v, ind) => ({
        id: ind,
        number: v.plates || v.economic_number,
        // number: v.economic_number,
      })),
      operator: operators || "",
    },
    tickets: trip.orders.map((order) => mapTripOrderShipment(order, extras)),
    extraActions: status.actions.extra,
    buttons: status.actions,
  };
  return result;
};

const mapTripOrderShipment = (order, extras) => {
  // console.log(order, extras);
  const result = {
    id: order.id,
    number: order.code,
    origin: {
      companyLogo: FILES_SERVER.concat(order.from_company.logo),
      location: order.from_facility.name,
      date: new Date(order.appointment_loading_date),
      timedate: formatDetailDate(order.appointment_loading_date),
      eta: order.eta_loading_date || '',
      ata: order.ata_loading_date || ''
      // `${new Date(order.appointment_loading_date)
      //   .toTimeString()
      //   .substr(0, 5)}hr/${new Date(order.appointment_loading_date)
      //   .toUTCString()
      //   .substr(5, 11)}`,
    },
    // maneuver: extras.facilityId === order.to_facility.id ? "Descarga" : "Carga",
    // maneuverDate:
    //   extras.facilityId === order.to_facility.id
    //     ? order.appointment_unload_date
    //     : order.appointment_loading_date,
    destination: {
      companyLogo: FILES_SERVER.concat(order.to_company.logo),
      location: order.to_facility.name,
      date: new Date(order.appointment_unload_date),
      timedate: formatDetailDate(order.appointment_unload_date),
      eta: order.eta_unload_date || '',
      ata: order.ata_unload_date || ''
      // `${new Date(order.appointment_unload_date)
      //   .toTimeString()
      //   .substr(0, 5)}hr/${new Date(order.appointment_unload_date)
      //   .toUTCString()
      //   .substr(5, 11)}`,
    },
    // detailButton: (
    //   // <Marker fill="#3E4E6C" style={{ marginTop: "5px" }} onClick={() => {}} />
    // ),
    level: order.priority,
    // weight: `${order.net_weight.value} ${order.net_weight.um}`,
    weight: `${order.gross_weight.value} ${order.net_weight.um}`,
    volume: `${order.volume.value} ${order.volume.um}`,
    // capacity: `${order.gross_weight.value} ${order.gross_weight.um}`,
    capacity: `0`,

    weightIcon: <WeightIcon />,
    volumeIcon: <VolumeIcon />,
    capacityIcon: <SpaceIcon />,
    tags: [],
    // tags: order.tags.map((t) => {
    //   const tag = extras.tags.find((et) => et.id === t.tag);
    //   return {
    //     description: tag.title,
    //   };
    // }),
  };
  // console.log(result);
  return result;
};
const mapTripOrder = (order, extras) => {
  // console.log(order, extras);
  // debugger;
  return {
    id: order.id,
    number: order.code,
    origin: {
      companyLogo: FILES_SERVER.concat(order.from_company.logo),
      location: order.from_facility.name,
      date: new Date(order.appointment_loading_date),
      timedate: formatDetailDate(order.appointment_loading_date),
      eta: order.eta_loading_date || '',
      ata: order.ata_loading_date || ''
    },
    notificationCenter: order.notification_center !== null ? order.notification_center : [],
    maneuver:
      extras.facilityId === order.to_facility.id.toString()
        ? "Descarga"
        : "Carga",
    maneuverDate:
      extras.facilityId === order.to_facility.id.toString()
        ? order.appointment_unload_date
        : order.appointment_loading_date,
    destination: {
      companyLogo: FILES_SERVER.concat(order.to_company.logo),
      location: order.to_facility.name,
      date: new Date(order.appointment_unload_date),
      timedate: formatDetailDate(order.appointment_unload_date),
      eta: order.eta_unload_date || '',
      ata: order.ata_unload_date || ''
      // `${new Date(order.appointment_unload_date)
      //   .toTimeString()
      //   .substr(0, 5)}hr/${new Date(order.appointment_unload_date)
      //   .toUTCString()
      //   .substr(5, 11)}`,
    },
    // detailButton: (
    //   // <Marker fill="#3E4E6C" style={{ marginTop: "5px" }} onClick={() => {}} />
    // ),
    level: order.priority,
    // weight: `${order.net_weight.value} ${order.net_weight.um}`,
    weight: `${order.gross_weight.value} ${order.net_weight.um}`,
    volume: `${order.volume.value} ${order.volume.um}`,
    // capacity: `${order.gross_weight.value} ${order.gross_weight.um}`,
    capacity: `0`,

    weightIcon: <WeightIcon />,
    volumeIcon: <VolumeIcon />,
    capacityIcon: <SpaceIcon />,
    tags: order.tags.map((t) => {
      const tag = extras.tags.find((et) => et.id === t.tag);
      return {
        description: tag.title,
      };
    }),   
    status: mapContainerStatus(order.status, order.containers),
    containers: order.containers || []
  };
};

const mapContainerStatus = (statusData, containers) => {

  var statusToReturn = [];
  const { id } = statusData;

  if (containers !== null && isArray(containers) && `${id}` === '7') {
    for (var i = 0; i < containers.length; i++) {
      statusToReturn = statusToReturn.concat(
        {
          icon: <Equipment fill={"#3f4f6c"} stroke={"#3f4f6c"}/>,
          name: containers[i]?.status?.description || '',
          trailer: ' ' + (containers[i]?.fleet_equipment?.economic_number || containers[i]?.code || '')
        }
      );
    }
  }

  return statusToReturn;

}

const formatDetailDate = (dateString) => {
  const f = new Date(dateString)
    .toDateString()
    .split(" ")
    .slice(1, 3)
    .reverse()
    .join(" ");

  return dateString
    ? `${new Date(dateString).toTimeString().substr(0, 5)}hr/${f}`
    : "Pendiente";
};
