import {
  BACKEND,
  postApiUrl,
  getApiUrl,
  deleteApiUrl
} from "../../shared/backend-api";
import store from "../store";

export const COMPLETE_STEP = "COMPLETE_STEP";
export const SHOW_STEP = "SHOW_STEP";
export const EXIT_WORKFLOW = "EXIT_WORKFLOW";

export const REQUEST_CREATE_FACILITY = "REQUEST_CREATE_FACILITY";
export const SUCCESS_CREATE_FACILITY = "SUCCESS_CREATE_FACILITY";
export const ERROR_CREATE_FACILITY = "ERROR_CREATE_FACILITY";

// export const REQUEST_GET_FACILITY_BY_CODE = 'REQUEST_GET_FACILITY_BY_CODE';
// export const SUCCESS_GET_FACILITY_BY_CODE = 'SUCCESS_GET_FACILITY_BY_CODE';
// export const ERROR_GET_FACILITY_BY_CODE = 'ERROR_GET_FACILITY_BY_CODE';

// export const REQUEST_UPDATE_FACILITY = 'REQUEST_UPDATE_FACILITY';
// export const SUCCESS_UPDATE_FACILITY = 'SUCCESS_UPDATE_FACILITY';
// export const ERROR_UPDATE_FACILITY = 'ERROR_UPDATE_FACILITY';

export const REQUEST_GET_USERS_BY_FACILITY = "REQUEST_GET_USERS_BY_FACILITY";
export const SUCCESS_GET_USERS_BY_FACILITY = "SUCCESS_GET_USERS_BY_FACILITY";
export const ERROR_GET_USERS_BY_FACILITY = "ERROR_GET_USERS_BY_FACILITY";

export const REQUEST_GET_ALERTS_BY_FACILITY = "REQUEST_GET_ALERTS_BY_FACILITY";
export const SUCCESS_GET_ALERTS_BY_FACILITY = "SUCCESS_GET_ALERTS_BY_FACILITY";
export const ERROR_GET_ALERTS_BY_FACILITY = "ERROR_GET_ALERTS_BY_FACILITY";

export const REQUEST_GET_NOTIFICATIONS_BY_FACILITY =
  "REQUEST_GET_NOTIFICATIONS_BY_FACILITY";
export const SUCCESS_GET_NOTIFICATIONS_BY_FACILITY =
  "SUCCESS_GET_NOTIFICATIONS_BY_FACILITY";
export const ERROR_GET_NOTIFICATIONS_BY_FACILITY =
  "ERROR_GET_NOTIFICATIONS_BY_FACILITY";

export const SEARCH_USERS_BY_TEXT = "SEARCH_USERS_BY_TEXT";
// export const SUCCESS_SEARCH_USERS_BY_FACILITY =
//   "SUCCESS_SEARCH_USERS_BY_FACILITY";
// export const ERROR_SEARCH_USERS_BY_FACILITY = "ERROR_SEARCH_USERS_BY_FACILITY";

export const REQUEST_INVITE_USERS_TO_FACILITY =
  "REQUEST_INVITE_USERS_TO_FACILITY";
export const SUCCESS_INVITE_USERS_TO_FACILITY =
  "SUCCESS_INVITE_USERS_TO_FACILITY";
export const ERROR_INVITE_USERS_TO_FACILITY = "ERROR_INVITE_USERS_TO_FACILITY";

export const REQUEST_INVITE_USERS_TO_FACILITY_ALERTS =
  "REQUEST_INVITE_USERS_TO_FACILITY_ALERTS";
export const SUCCESS_INVITE_USERS_TO_FACILITY_ALERTS =
  "SUCCESS_INVITE_USERS_TO_FACILITY_ALERTS";
export const ERROR_INVITE_USERS_TO_FACILITY_ALERTS =
  "ERROR_INVITE_USERS_TO_FACILITY_ALERTS";

export const REQUEST_INVITE_USERS_TO_FACILITY_NOTIFICATIONS =
  "REQUEST_INVITE_USERS_TO_FACILITY_NOTIFICATIONS";
export const SUCCESS_INVITE_USERS_TO_FACILITY_NOTIFICATIONS =
  "SUCCESS_INVITE_USERS_TO_FACILITY_NOTIFICATIONS";
export const ERROR_INVITE_USERS_TO_FACILITY_NOTIFICATIONS =
  "ERROR_INVITE_USERS_TO_FACILITY_NOTIFICATIONS";

export const REQUEST_SET_FACILITY_ALERTS = "REQUEST_SET_FACILITY_ALERTS";
export const SUCCESS_SET_FACILITY_ALERTS = "SUCCESS_SET_FACILITY_ALERTS";
export const ERROR_SET_FACILITY_ALERTS = "ERROR_SET_FACILITY_ALERTS";

export const REQUEST_SET_FACILITY_NOTIFICATIONS =
  "REQUEST_SET_FACILITY_NOTIFICATIONS";
export const SUCCESS_SET_FACILITY_NOTIFICATIONS =
  "SUCCESS_SET_FACILITY_NOTIFICATIONS";
export const ERROR_SET_FACILITY_NOTIFICATIONS =
  "ERROR_SET_FACILITY_NOTIFICATIONS";

export const REQUEST_DELETE_USERS_FROM_FACILITY =
  "REQUEST_DELETE_USERS_FROM_FACILITY";
export const SUCCESS_DELETE_USERS_FROM_FACILITY =
  "SUCCESS_DELETE_USERS_FROM_FACILITY";
export const ERROR_DELETE_USERS_FROM_FACILITY =
  "ERROR_DELETE_USERS_FROM_FACILITY";

export const REQUEST_GET_GI_TYPES = "REQUEST_GET_GI_TYPES";
export const SUCCESS_GET_GI_TYPES = "SUCCESS_GET_GI_TYPES";
export const ERROR_GET_GI_TYPES = "ERROR_GET_GI_TYPES";

export const REQUEST_GET_EQUIPMENT_TYPES = "REQUEST_GET_EQUIPMENT_TYPES";
export const SUCCESS_GET_EQUIPMENT_TYPES = "SUCCESS_GET_EQUIPMENT_TYPES";
export const ERROR_GET_EQUIPMENT_TYPES = "ERROR_GET_EQUIPMENT_TYPES";

export const REQUEST_GET_RAMP_TYPES = "REQUEST_GET_RAMP_TYPES";
export const SUCCESS_GET_RAMP_TYPES = "SUCCESS_GET_RAMP_TYPES";
export const ERROR_GET_RAMP_TYPES = "ERROR_GET_RAMP_TYPES";

export const REQUEST_RESET_ALERTS_FROM_FACILITY =
  "REQUEST_RESET_ALERTS_FROM_FACILITY";
export const SUCCESS_RESET_ALERTS_FROM_FACILITY =
  "SUCCESS_RESET_ALERTS_FROM_FACILITY";
export const ERROR_RESET_ALERTS_FROM_FACILITY =
  "ERROR_RESET_ALERTS_FROM_FACILITY";

export const REQUEST_GET_FACILITY_LOCATIONS = "REQUEST_GET_FACILITY_LOCATIONS";
export const SUCCESS_GET_FACILITY_LOCATIONS = "SUCCESS_GET_FACILITY_LOCATIONS";
export const ERROR_GET_FACILITY_LOCATIONS = "ERROR_GET_FACILITY_LOCATIONS";

export const SET_SCHEDULES_DATA = "SET_SCHEDULES_DATA";
export const SET_DATA_WINDOW = "SET_DATA_WINDOW";
export const GET_DATA_WINDOW = "GET_DATA_WINDOW";
export const SET_DATA_WINDOWTEMP = "SET_DATA_WINDOWTEMP";
export const GET_DATA_WINDOWTEMP = "GET_DATA_WINDOWTEMP";

export const setSchedulesData = data => ({
  type: SET_SCHEDULES_DATA,
  data
});

export const setDataWindow = data => ({
  type: SET_DATA_WINDOW,
  data
});

export const setDataWindowTemp = data => ({
  type: SET_DATA_WINDOWTEMP,
  data
});

const GEOFENCE = {
  GI: {
    CREATE: {
      REQUEST: {
        TYPE: "GEOFENCE_GI_CREATE_REQUEST",
        ACTION: () => ({
          type: GEOFENCE.GI.CREATE.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: "GEOFENCE_GI_CREATE_SUCCESS",
        ACTION: json => ({
          type: GEOFENCE.GI.CREATE.SUCCESS.TYPE,
          response: json
        })
      },
      ERROR: {
        TYPE: "GEOFENCE_GI_CREATE_ERROR",
        ACTION: error => ({
          type: GEOFENCE.GI.CREATE.ERROR.TYPE,
          error: error
        })
      }
    },
    GET_BY_FACILITY: {
      REQUEST: {
        TYPE: "GEOFENCE_GI_GET_BY_FACILITY_REQUEST",
        ACTION: () => ({
          type: GEOFENCE.GI.GET_BY_FACILITY.REQUEST.TYPE
        })
      },
      SUCCESS: {
        TYPE: "GEOFENCE_GI_GET_BY_FACILITY_SUCCESS",
        ACTION: json => ({
          type: GEOFENCE.GI.GET_BY_FACILITY.SUCCESS,
          response: json
        })
      },
      ERROR: {
        TYPE: "GEOFENCE_GI_GET_BY_FACILITY_ERROR",
        ACTION: error => ({
          type: GEOFENCE.GI.GET_BY_FACILITY.ERROR,
          error: error
        })
      }
    },
    DATA: {
      CREATE: {
        REQUEST: {
          TYPE: "GEOFENCE_GI_DATA_CREATE_REQUEST",
          ACTION: () => ({
            type: GEOFENCE.GI.DATA.CREATE.REQUEST.TYPE
          })
        },
        SUCCESS: {
          TYPE: "GEOFENCE_GI_DATA_CREATE_SUCCESS",
          ACTION: json => ({
            type: GEOFENCE.GI.DATA.CREATE.SUCCESS,
            response: json
          })
        },
        ERROR: {
          TYPE: "GEOFENCE_GI_DATA_CREATE_ERROR",
          ACTION: error => ({
            type: GEOFENCE.GI.DATA.CREATE.ERROR,
            error: error
          })
        }
      }
    },
    RAMP: {
      CREATE: {
        REQUEST: {
          TYPE: "GEOFENCE_GI_RAMP_CREATE_REQUEST",
          ACTION: () => ({
            type: GEOFENCE.GI.RAMP.CREATE.REQUEST
          })
        },
        SUCCESS: {
          TYPE: "GEOFENCE_GI_RAMP_CREATE_SUCCESS",
          ACTION: json => ({
            type: GEOFENCE.GI.RAMP.CREATE.SUCCESS,
            data: json
          })
        },
        ERROR: {
          TYPE: "GEOFENCE_GI_RAMP_CREATE_ERROR",
          ACTION: error => ({
            type: GEOFENCE.GI.RAMP.CREATE.ERROR,
            error: error
          })
        }
      }
      // CAPACITY: {
      //   CREATE: {
      //     REQUEST: {
      //       TYPE: 'GEOFENCE_GI_RAMP_CAPACITY_CREATE_REQUEST',
      //       ACTION: () => ({
      //         TYPE: GEOFENCE.GI.RAMP.CAPACITY.CREATE.REQUEST
      //       })
      //     },
      //     SUCCESS: {
      //       TYPE: 'GEOFENCE_GI_RAMP_CAPACITY_CREATE_SUCCESS',
      //       ACTION: (json) => ({
      //         type: GEOFENCE.GI.RAMP.CAPACITY.CREATE.SUCCESS,
      //         data: json
      //       })
      //     },
      //     ERROR: {
      //       TYPE: 'GEOFENCE_GI_RAMP_CAPACITY_CREATE_ERROR',
      //       ACTION: (error) => ({
      //         type: GEOFENCE.GI.RAMP.CREATE.ERROR,
      //         error: error
      //       })
      //     }
      //   }
      // }
    }
  }
};

export const completeStep = facility => ({
  type: COMPLETE_STEP,
  payload: { facility: facility }
});

export const showStep = step => ({
  type: SHOW_STEP,
  payload: { step }
});

export const exitWorkflow = (step, facility) => ({
  type: EXIT_WORKFLOW,
  payload: { step, facility }
});

// export const requestGetFacilityByCode = (code) => ({
//   type: REQUEST_GET_FACILITY_BY_CODE,
//   code: code
// })
// export const successGetFacilityByCode = (json) => ({
//   type: SUCCESS_GET_FACILITY_BY_CODE,
//   data: json
// })
// export const errorGetFacilityByCode = (error) => ({
//   type: ERROR_GET_FACILITY_BY_CODE,
//   error: error
// })

// export const requestCreateFacility = data => {
//   return postApiUrl(BACKEND.FACILITY.POST, data);
// };
// export const successCreateFacility = json => ({
//   type: SUCCESS_CREATE_FACILITY,
//   facility: json
// });
// export const errorCreateFacility = error => ({
//   type: ERROR_CREATE_FACILITY,
//   error: error
// });

export const requestGetUsersByFacility = () => ({
  type: REQUEST_GET_USERS_BY_FACILITY
});
export const successGetUsersByFacility = json => ({
  type: SUCCESS_GET_USERS_BY_FACILITY,
  data: json
});
export const errorGetUsersByFacility = error => ({
  type: ERROR_GET_USERS_BY_FACILITY,
  error: error
});

export const requestGetFacilityLocations = id => ({
  type: REQUEST_GET_FACILITY_LOCATIONS,
  id: id
});
export const successGetFacilityLocations = json => ({
  type: SUCCESS_GET_FACILITY_LOCATIONS,
  data: json
});
export const errorGetFacilityLocations = error => ({
  type: ERROR_GET_FACILITY_LOCATIONS,
  error: error
});

export const requestGetAlertsByFacility = id => ({
  type: REQUEST_GET_ALERTS_BY_FACILITY,
  id: id
});
export const successGetAlertsByFacility = json => ({
  type: SUCCESS_GET_ALERTS_BY_FACILITY,
  data: json
});
export const errorGetAlertsByFacility = error => ({
  type: ERROR_GET_ALERTS_BY_FACILITY,
  error: error
});

export const requestGetNotificationsByFacility = id => ({
  type: REQUEST_GET_NOTIFICATIONS_BY_FACILITY,
  id: id
});
export const successGetNotificationsByFacility = json => ({
  type: SUCCESS_GET_NOTIFICATIONS_BY_FACILITY,
  data: json
});
export const errorGetNotificationsByFacility = error => ({
  type: ERROR_GET_NOTIFICATIONS_BY_FACILITY,
  error: error
});
///////
export const searchUsersByTextStr = searchStr => ({
  type: SEARCH_USERS_BY_TEXT,
  searchStr
});
// export const successSearchUsersByFacility = json => ({
//   type: SUCCESS_SEARCH_USERS_BY_FACILITY,
//   data: json
// });
// export const errorSearchUsersByFacility = error => ({
//   type: ERROR_SEARCH_USERS_BY_FACILITY,
//   error: error
// });

export const requestInviteUsersToFacility = id => ({
  type: REQUEST_INVITE_USERS_TO_FACILITY,
  id: id
});
export const successInviteUsersToFacility = json => ({
  type: SUCCESS_INVITE_USERS_TO_FACILITY,
  data: json
});
export const errorInviteUsersToFacility = error => ({
  type: ERROR_INVITE_USERS_TO_FACILITY,
  error: error
});

export const requestInviteUsersToFacilityAlerts = id => ({
  type: REQUEST_INVITE_USERS_TO_FACILITY_ALERTS,
  id: id
});
export const successInviteUsersToFacilityAlerts = json => ({
  type: SUCCESS_INVITE_USERS_TO_FACILITY_ALERTS,
  data: json
});
export const errorInviteUsersToFacilityAlerts = error => ({
  type: ERROR_INVITE_USERS_TO_FACILITY_ALERTS,
  error: error
});

export const requestInviteUsersToFacilityNotifications = id => ({
  type: REQUEST_INVITE_USERS_TO_FACILITY_NOTIFICATIONS,
  id: id
});
export const successInviteUsersToFacilityNotifications = json => ({
  type: SUCCESS_INVITE_USERS_TO_FACILITY_NOTIFICATIONS,
  data: json
});
export const errorInviteUsersToFacilityNotifications = error => ({
  type: ERROR_INVITE_USERS_TO_FACILITY_NOTIFICATIONS,
  error: error
});

export const requestSetFacilityAlerts = id => ({
  type: REQUEST_SET_FACILITY_ALERTS,
  id: id
});
export const successSetFacilityAlerts = json => ({
  type: SUCCESS_SET_FACILITY_ALERTS,
  data: json
});
export const errorSetFacilityAlerts = error => ({
  type: ERROR_SET_FACILITY_ALERTS,
  error: error
});

export const requestSetFacilityNotifications = id => ({
  type: REQUEST_SET_FACILITY_NOTIFICATIONS,
  id: id
});
export const successSetFacilityNotifications = json => ({
  type: SUCCESS_SET_FACILITY_NOTIFICATIONS,
  data: json
});
export const errorSetFacilityNotifications = error => ({
  type: ERROR_SET_FACILITY_NOTIFICATIONS,
  error: error
});

// export const requestDeleteUsersFromFacility = id => ({
//   type: REQUEST_DELETE_USERS_FROM_FACILITY,
//   id: id
// });
// export const successDeleteUsersFromFacility = json => ({
//   type: SUCCESS_DELETE_USERS_FROM_FACILITY,
//   data: json
// });
// export const errorDeleteUsersFromFacility = error => ({
//   type: ERROR_DELETE_USERS_FROM_FACILITY,
//   error: error
// });

export const requestGetGITypes = () => ({
  type: REQUEST_GET_GI_TYPES
});
export const successGetGITypes = json => ({
  type: SUCCESS_GET_GI_TYPES,
  data: json
});
export const errorGetGITypes = error => ({
  type: ERROR_GET_GI_TYPES,
  error: error
});

export const requestGetEquipmentTypes = () => ({
  type: REQUEST_GET_EQUIPMENT_TYPES
});
export const successGetEquipmentTypes = json => ({
  type: SUCCESS_GET_EQUIPMENT_TYPES,
  data: json
});
export const errorGetEquipmentTypes = error => ({
  type: ERROR_GET_EQUIPMENT_TYPES,
  error: error
});

export const requestGetRampTypes = () => ({
  type: REQUEST_GET_RAMP_TYPES
});
export const successGetRampTypes = json => ({
  type: SUCCESS_GET_RAMP_TYPES,
  data: json
});
export const errorGetRampTypes = error => ({
  type: ERROR_GET_RAMP_TYPES,
  error: error
});

export const requestResetAlertsFromFacility = id => ({
  type: REQUEST_RESET_ALERTS_FROM_FACILITY,
  id: id
});
export const successResetAlertsFromFacility = json => ({
  type: SUCCESS_RESET_ALERTS_FROM_FACILITY,
  data: json
});
export const errorResetAlertsFromFacility = error => ({
  type: ERROR_RESET_ALERTS_FROM_FACILITY,
  error: error
});

const initialState = {
  isFetching: false,
  currentStep: 1,
  lastStep: 1,
  facility: {
    id: 0,
    name: "",
    code: "",
    alias: "",
    country: 0,
    state: 0,
    city: 0,
    address: "",
    phone: "",
    zip_code: "",
    time_Zone: 0,
    avatar: 0,
    company: 0,
    tags: [],
    geopos_x: "",
    geopos_y: ""
  },
  tags: [],
  usersTextSearch: "",
  users: [],
  facilityUsers: {
    isFetching: false,
    count: 0,
    users: [],
    initialUsers: [],
    error: ""
  },
  error: "",
  giTypes: [],
  equipmentTypes: [],
  rampTypes: [],
  locationsbyfacility: [],
  schedules: {
    collapsed: true,
    currentDay: 1
  },
  schedulesRampsdetails: [],
  schedulesCards: [],
  schedulesCapacity: [],
  schedulesEquipments: [],
  schedulesCapacityequipments: [],
  currentWindowTooltip: {
    currentTooltip: "",
    currentTooltipShow: 0,
    currentTooltipNew: 0,
    currentTooltipTitle: "",
    capacityType: 1,
    capacityTooltip: "",
    capacityTooltipShow: false
  },
  notifications: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COMPLETE_STEP: {
      // const data = action.payload.data; // perform data saving if necessary
      const currentStep = state.currentStep + 1;
      const lastStep =
        state.lastStep !== state.currentStep
          ? state.lastStep
          : state.lastStep + 1;
      return Object.assign({}, state, {
        currentStep: currentStep,
        lastStep: lastStep,
        facility: action.payload.facility
      });
    }
    case SHOW_STEP: {
      const step =
        action.payload.step <= state.lastStep
          ? action.payload.step
          : state.currentStep;
      return Object.assign({}, state, {
        currentStep: step,
        lastStep: state.lastStep
      });
    }
    case EXIT_WORKFLOW: {
      return Object.assign({}, state);
    }
    case SEARCH_USERS_BY_TEXT: {
      // const name = action.payload.name.toLowerCase();
      // let users = state.facilityUsers.initialUsers.filter(u => {
      //   // debugger;
      //   return ((u.first_name && u.first_name.toLowerCase().includes(name))
      //     || (u.last_name && u.last_name.toLowerCase().includes(name))
      //     || (u.email && u.email.toLowerCase().includes(name))
      //     || (u.email_invited && u.email_invited.toLowerCase().includes(name)))
      // })
      // // debugger;
      // return Object.assign({}, state, {
      //   facilityUsers: {
      //     isFetching: false,
      //     users: users,
      //     initialUsers: state.facilityUsers.initialUsers,
      //     count: state.facilityUsers.count,
      //   }
      // });
      // // return state;
      return Object.assign({}, state, {
        usersTextSearch: action.searchStr
      });
    }

    case REQUEST_CREATE_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_CREATE_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        facility: action.facility
      });
    }
    case ERROR_CREATE_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_GET_USERS_BY_FACILITY: {
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: true
        }
      });
    }
    case SUCCESS_GET_USERS_BY_FACILITY: {
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          users: action.data.results,
          // initialUsers: action.data.results,
          count: action.data.count
        }
      });
    }
    case ERROR_GET_USERS_BY_FACILITY: {
      return Object.assign({}, state, {
        facilityUsers: {
          isFetching: false,
          error: action.error
        }
      });
    }

    case REQUEST_GET_ALERTS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_ALERTS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        alerts: action.data
      });
    }
    case ERROR_GET_ALERTS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case REQUEST_GET_NOTIFICATIONS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true,
        notifications: []
      });
    }
    case SUCCESS_GET_NOTIFICATIONS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        notifications: action.data
      });
    }
    case ERROR_GET_NOTIFICATIONS_BY_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        notifications: []
      });
    }

    // case REQUEST_SEARCH_USERS_BY_FACILITY: {
    //   return Object.assign({}, state, {
    //     isFetching: true
    //   });
    // }
    // case SUCCESS_SEARCH_USERS_BY_FACILITY: {
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     users: action.data
    //   });
    // }
    // case ERROR_SEARCH_USERS_BY_FACILITY: {
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     error: action.error
    //   });
    // }
    case REQUEST_INVITE_USERS_TO_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_INVITE_USERS_TO_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        invitations: action.data
      });
    }
    case ERROR_INVITE_USERS_TO_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_INVITE_USERS_TO_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_INVITE_USERS_TO_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: false,
        alertUsers: action.data
      });
    }
    case ERROR_INVITE_USERS_TO_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_INVITE_USERS_TO_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_INVITE_USERS_TO_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: false,
        notificationUsers: action.data
      });
    }
    case ERROR_INVITE_USERS_TO_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_SET_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_SET_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: false,
        updatedAlerts: action.data
      });
    }
    case ERROR_SET_FACILITY_ALERTS: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_SET_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_SET_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: false,
        updatedNotifications: action.data
      });
    }
    case ERROR_SET_FACILITY_NOTIFICATIONS: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_DELETE_USERS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_DELETE_USERS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        deleted_users: action.data
      });
    }
    case ERROR_DELETE_USERS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }
    case REQUEST_GET_GI_TYPES: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_GI_TYPES: {
      return Object.assign({}, state, {
        giTypes: action.data,
        isFetching: false
      });
    }
    case ERROR_GET_GI_TYPES: {
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false
      });
    }

    case REQUEST_GET_EQUIPMENT_TYPES: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_EQUIPMENT_TYPES: {
      return Object.assign({}, state, {
        equipmentTypes: action.data,
        isFetching: false
      });
    }
    case ERROR_GET_EQUIPMENT_TYPES: {
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false
      });
    }

    /* PARA FACILITIES Y LOCATIONS */
    case REQUEST_GET_FACILITY_LOCATIONS: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_FACILITY_LOCATIONS: {
      return Object.assign({}, state, {
        locationsbyfacility: action.data,
        isFetching: false
      });
    }
    case ERROR_GET_FACILITY_LOCATIONS: {
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false
      });
    }

    /* HASTA AQUI */

    case REQUEST_GET_RAMP_TYPES: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_RAMP_TYPES: {
      return Object.assign({}, state, {
        rampTypes: action.data,
        isFetching: false
      });
    }
    case ERROR_GET_RAMP_TYPES: {
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false
      });
    }
    case REQUEST_RESET_ALERTS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_RESET_ALERTS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        cleared_alerts: action.data
      });
    }
    case ERROR_RESET_ALERTS_FROM_FACILITY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    // GEOFENCE

    case GEOFENCE.GI.CREATE.REQUEST.TYPE: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case GEOFENCE.GI.CREATE.SUCCESS.TYPE: {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case GEOFENCE.GI.CREATE.ERROR.TYPE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case GEOFENCE.GI.DATA.CREATE.REQUEST.TYPE: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case GEOFENCE.GI.DATA.CREATE.SUCCESS.TYPE: {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case GEOFENCE.GI.DATA.CREATE.ERROR.TYPE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case GEOFENCE.GI.RAMP.CREATE.REQUEST.TYPE: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case GEOFENCE.GI.RAMP.CREATE.SUCCESS.TYPE: {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case GEOFENCE.GI.RAMP.CREATE.ERROR.TYPE: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case GEOFENCE.GI.GET_BY_FACILITY.REQUEST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case GEOFENCE.GI.GET_BY_FACILITY.SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case GEOFENCE.GI.GET_BY_FACILITY.ERROR: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case SET_SCHEDULES_DATA: {
      return Object.assign({}, state, { schedules: action.data });
    }

    case SET_DATA_WINDOW: {
      return Object.assign({}, state, { currentWindowTooltip: action.data });
    }

    case SET_DATA_WINDOWTEMP: {
      return Object.assign({}, state, { currentTemp: action.data });
    }

    case "FACILITY_NOTIFICATION_CONFIG":
      let { notifications } = state;

      return Object.assign({}, state, {
        notifications: notifications
          .filter(notif => notif.id !== action.notification.id)
          .concat([action.notification])
      });

    default: {
      return state;
    }
  }
}

// export const loadFacilityByCode = (code) => {
//   return function (dispatch) {
//     dispatch(requestGetFacilityByCode(code));
//     return getApiUrl(BACKEND.FACILITY.GET_BY_CODE, { code: code }))
//       .then(
//         response => response.json(),
//         error => dispatch(errorGetFacilityByCode(error))
//       )
//       .then(
//         data => dispatch(successGetFacilityByCode(data))
//       )
//   }
// }

// export const completeStepCreateFacility = data => {
//   return function (dispatch) {
//     return requestCreateFacility(data)
//       .then(
//         response => response.json(),
//         error => dispatch(errorCreateFacility(error))
//       )
//       .then(result => {
//         dispatch(successCreateFacility(result));
//         dispatch(completeStep(result));
//       });
//   };
// };

export const searchUsersByText = searchStr => {
  return function(dispatch) {
    dispatch(searchUsersByTextStr(searchStr));
  };
};

export const loadLocationsFacilities = () => {};

export const loadUsersByFacility = (id_facility, limit = 10, page = 1) => {
  return function(dispatch) {
    dispatch(requestGetUsersByFacility());
    return getApiUrl(BACKEND.FACILITY.GET_STAFF, {
      id_facility,
      limit,
      page
    })
      .then(
        response => response.json(),
        error => dispatch(errorGetUsersByFacility(error))
      )
      .then(data => dispatch(successGetUsersByFacility(data)));
  };
};

export const loadAlertsByFacility = idFacility => {
  return function(dispatch) {
    dispatch(requestGetAlertsByFacility(idFacility));
    return getApiUrl(BACKEND.FACILITY.GET_ALERTS, { id_facility: idFacility })
      .then(
        response => response.json(),
        error => dispatch(errorGetAlertsByFacility(error))
      )
      .then(data => dispatch(successGetAlertsByFacility(data)));
  };
};

export const loadNotificationsByFacility = idFacility => {
  return function(dispatch) {
    dispatch(requestGetNotificationsByFacility(idFacility));
    return getApiUrl(BACKEND.FACILITY.GET_NOTIFICATIONS, {
      id_facility: idFacility
    })
      .then(
        response => response.json(),
        error => dispatch(errorGetNotificationsByFacility(error))
      )
      .then(data => dispatch(successGetNotificationsByFacility(data)));
  };
};

export const inviteUsersToFacility = (idFacility, users, company) => {
  return function(dispatch) {
    dispatch(requestInviteUsersToFacility(idFacility, users, company));
    return postApiUrl(BACKEND.FACILITY.INVITE_STAFF, {
      emails: users,
      id_facility: idFacility,
      company: company
    })
      .then(
        response => response.json(),
        error => dispatch(errorInviteUsersToFacility(error))
      )
      .then(data => dispatch(successInviteUsersToFacility(data)));
  };
};

export const inviteUsersToFacilityAlerts = alert => {
  return function(dispatch) {
    dispatch(requestInviteUsersToFacilityAlerts(alert));
    return postApiUrl(BACKEND.FACILITY.SET_USER_ALERT, alert)
      .then(
        response => response.json(),
        error => dispatch(errorInviteUsersToFacilityAlerts(error))
      )
      .then(data => dispatch(successInviteUsersToFacilityAlerts(data)));
  };
};

export const inviteUsersToFacilityNotifications = notification => {
  return function(dispatch) {
    dispatch(requestInviteUsersToFacilityNotifications(notification));
    return postApiUrl(BACKEND.FACILITY.SET_USER_NOTIFICATION, notification)
      .then(
        response => response.json(),
        error => dispatch(errorInviteUsersToFacilityNotifications(error))
      )
      .then(data => dispatch(successInviteUsersToFacilityNotifications(data)));
  };
};

export const setFacilityAlerts = (idFacility, alerts) => {
  return function(dispatch) {
    dispatch(requestSetFacilityAlerts(idFacility, alerts));
    return postApiUrl(BACKEND.FACILITY.SET_ALERT, alerts)
      .then(
        response => response.json(),
        error => dispatch(errorSetFacilityAlerts(error))
      )
      .then(data => dispatch(successSetFacilityAlerts(data)));
  };
};

export const setFacilityNotifications = notifications => {
  return function(dispatch) {
    dispatch(requestSetFacilityNotifications(notifications));
    return (notifications.status === "1"
      ? postApiUrl(BACKEND.FACILITY.SET_NOTIFICATION, notifications)
      : deleteApiUrl(BACKEND.FACILITY.SET_NOTIFICATION, notifications)
    )
      .then(
        response => response.json(),
        error => dispatch(errorSetFacilityNotifications(error))
      )
      .then(data => dispatch(successSetFacilityNotifications(data)));
  };
};

export const loadGiTypes = () => {
  return function(dispatch) {
    return getApiUrl(BACKEND.FACILITY.GET_GI_TYPES)
      .then(
        response => response.json(),
        error => dispatch(errorGetGITypes(error))
      )
      .then(data => dispatch(successGetGITypes(data)));
  };
};

export const loadEquipmentTypes = () => {
  return function(dispatch) {
    return getApiUrl(BACKEND.FACILITY.GET_EQUIPMENT_TYPES)
      .then(
        response => response.json(),
        error => dispatch(errorGetEquipmentTypes(error))
      )
      .then(data => dispatch(successGetEquipmentTypes(data)));
  };
};

export const loadFacilityLocations = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    return getApiUrl(BACKEND.FACILITY.LOCATIONS, { company_id })
      .then(
        response => response.json(),
        error => dispatch(errorGetFacilityLocations(error))
      )
      .then(data => dispatch(successGetFacilityLocations(data)));
  };
};

export const loadRampTypes = () => {
  return function(dispatch) {
    return getApiUrl(BACKEND.FACILITY.GET_RAMP_TYPES)
      .then(
        response => response.json(),
        error => dispatch(errorGetRampTypes(error))
      )
      .then(data => dispatch(successGetRampTypes(data)));
  };
};

export const resetAlertsFromFacility = alert => {
  return function(dispatch) {
    // dispatch(requestResetAlertsFromFacility(idUser, idFacility));
    return postApiUrl(BACKEND.FACILITY.RESET_ALERT, alert)
      .then(
        response => response.json(),
        error => dispatch(errorResetAlertsFromFacility(error))
      )
      .then(data => dispatch(successResetAlertsFromFacility(data)));
  };
};

export const createfacilityRamp = (
  facilityId,
  giTypeId,
  giName,
  rampTypeId,
  polygon
) => {
  return function(dispatch) {
    dispatch(GEOFENCE.GI.CREATE.REQUEST.ACTION());
    let params = {
      description: giName,
      facility: facilityId || 882,
      gi_type: giTypeId
    };
    return postApiUrl(BACKEND.FACILITY.GEO.POST, params)
      .then(
        response => response.json(),
        error => dispatch(GEOFENCE.GI.CREATE.ERROR.ACTION(error))
      )
      .then(data => {
        dispatch(GEOFENCE.GI.CREATE.SUCCESS.ACTION(data));
        dispatch(GEOFENCE.GI.DATA.CREATE.REQUEST.ACTION());
        params = {
          facility_gi_id: data.id,
          polygons: polygon.map((p, i) => ({
            order: i,
            geopos_x: p.lat(),
            geopos_y: p.lng()
          }))
        };
        postApiUrl(BACKEND.FACILITY.GEO.POST_POLYGON, params)
          .then(
            response => response.json(),
            error => dispatch(GEOFENCE.GI.DATA.CREATE.ERROR.ACTION(error))
          )
          .then(data => dispatch(GEOFENCE.GI.DATA.CREATE.SUCCESS.ACTION(data)));

        dispatch(GEOFENCE.GI.RAMP.CREATE.REQUEST.ACTION());
        params = {
          facility_gi_id: data.id,
          type: rampTypeId
        };

        postApiUrl(BACKEND.FACILITY.GEO.CREATE_RAMP, params)
          .then(
            response => response.json(),
            error => dispatch(GEOFENCE.GI.RAMP.CREATE.ERROR.ACTION(error))
          )
          .then(data => dispatch(GEOFENCE.GI.RAMP.CREATE.SUCCESS.ACTION(data)));
      });
  };
};

export const createfacilityGeofence = (
  facilityId,
  giTypeId,
  giName,
  polygon
) => {
  return function(dispatch) {
    dispatch(GEOFENCE.GI.CREATE.REQUEST.ACTION());
    let params = {
      description: giName,
      facility: facilityId,
      gi_type: giTypeId
    };
    return postApiUrl(BACKEND.FACILITY.GEO.POST, params)
      .then(
        response => response.json(),
        error => dispatch(GEOFENCE.GI.CREATE.ERROR.ACTION(error))
      )
      .then(data => {
        dispatch(GEOFENCE.GI.CREATE.SUCCESS.ACTION(data));
        dispatch(GEOFENCE.GI.DATA.CREATE.REQUEST.ACTION());
        let params = {
          facility_gi_id: data.id,
          polygons: polygon.map((p, i) => ({
            order: i,
            geopos_x: p.lat(),
            geopos_y: p.lng()
          }))
        };
        postApiUrl(BACKEND.FACILITY.GEO.POST_POLYGON, params)
          .then(
            response => response.json(),
            error => dispatch(GEOFENCE.GI.DATA.CREATE.ERROR.ACTION(error))
          )
          .then(data => dispatch(GEOFENCE.GI.DATA.CREATE.SUCCESS.ACTION(data)));
      });
  };
};

// const createGeofenceGI = (/*dispatch, */facilityId, giTypeId, giName) => {
//   return function (dispatch) {
//     dispatch(GEOFENCE.GI.CREATE.REQUEST.ACTION());
//     let params = {
//       description: giName,
//       facility: facilityId,
//       gi_type: giTypeId
//     }
//     return postApiUrl(BACKEND.FACILITY.GEO.CREATE, params))
//       .then(
//         response => response.json(),
//         error => dispatch(GEOFENCE.GI.CREATE.ERROR.ACTION(error))
//       )
//       .then(data =>
//         dispatch(GEOFENCE.GI.CREATE.SUCCESS.ACTION(data))
//       )
//   }
// }

// const createGeofenceGIData = (dispatch, giId, polygon) => {
//   dispatch(GEOFENCE.GI.DATA.CREATE.REQUEST.ACTION());
//   let params = {
//     facility_gi_id: giId,
//     polygons: polygon.map((p, i) => ({
//       order: i,
//       geopos_x: p.lat(),
//       geopos_y: p.lng()
//     }))
//   }
//   return postApiUrl(BACKEND.FACILITY.GEO.CREATE_POLYGON, params))
//     .then(
//       response => response.json(),
//       error => dispatch(GEOFENCE.GI.DATA.CREATE.ERROR.ACTION(error))
//     ).then(data =>
//       dispatch(GEOFENCE.GI.DATA.CREATE.SUCCESS.ACTION(data))
//     )
// }

// const createGeofenceGIRamp = (dispatch, giId, rampTypeId) => {
//   dispatch(GEOFENCE.GI.RAMP.CREATE.REQUEST.ACTION());
//   let params = {
//     facility_gi_id: giId,
//     ramp_type_id: rampTypeId
//   }
//   return postApiUrl(BACKEND.FACILITY.GEO.CREATE_POLYGON, params))
//     .then(
//       response => response.json(),
//       error => dispatch(GEOFENCE.GI.RAMP.CREATE.ERROR.ACTION(error))
//     ).then(data =>
//       dispatch(GEOFENCE.GI.RAMP.CREATE.SUCCESS.ACTION(data))
//     )
// }

export const loadFacilityGeofences = facilityId => {
  return dispatch => {
    dispatch(GEOFENCE.GI.GET_BY_FACILITY.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.GEO.GET_BY_FACILITY_ID, {
      facility_id: facilityId
    })
      .then(
        response => response.json(),
        error => dispatch(GEOFENCE.GI.GET_BY_FACILITY.ERROR.ACTION(error))
      )
      .then(response =>
        dispatch(GEOFENCE.GI.GET_BY_FACILITY.SUCCESS.ACTION(response))
      );
  };
};

export const facilityNotificationConfigActions = notification => ({
  type: "FACILITY_NOTIFICATION_CONFIG",
  notification
});
