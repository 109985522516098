import styled from "styled-components";

export const BuyRow = styled.div`
  display: flex;
  height: 100px;
  color: #fff;
  & .left {
    width: 30%;
    background: #313a52;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #8a9cbe;
    border-right: none;
    border-bottom: none;
    & .title {
      font-weight: 800;
    }
    & .subtitle {
      font-size: 12px;
    }
  }
  & .right {
    width: 70%;
    background: #52627f;
    border: 1px solid #8a9cbe;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & .title {
      display: flex;
      width: 100%;
      & > div {
        display: flex;
        justify-content: center;
        width: 25%;
        opacity: 50%;
      }
    }

    & .details {
      display: flex;
      width: 100%;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;

        & input {
          background: #6a81ad;
          border: none;
          width: 70px;
          height: 35px;
          color: #ffffff;
          font-weight: bolder;
          text-align: center;
          font-size: 16px;
          border-bottom: 2px solid #35315a;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }

        & input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        & input[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }
`;
