import React from "react";
import PrevSwitch from "../controls/prev-switch";
import Button from "../controls/button";

class NotificationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  manageCollapse = () => {
    if (this.state.collapsed)
      this.props.loadEmailList(this.props.notification.id);
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { notification } = this.props;
    return (
      <div className="alert-row">
        <div className="alert-title">
          {notification.description}
          <div>
            <PrevSwitch
              checked={notification.active}
              handleClick={e =>
                this.props.onChange({
                  target: {
                    name: `active-${notification.notificationId}`,
                    value: notification.id,
                    checked: e
                  }
                })
              }
            />
            <div
              className={"collapser".concat(
                this.state.collapsed ? " collapsed" : ""
              )}
              onClick={this.manageCollapse}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
          </div>
        </div>
        <div
          className={"alert-content".concat(
            this.state.collapsed ? " collapsed" : ""
          )}
        >
          <p className="alert-description">{notification.description}</p>
          {/* <p className="alert-see-more">
            <span>Ver más documentación</span>
          </p> */}
          <div className="alert-levels-container">
            <div className="alert-level">
              <Button
                name="editEmails"
                text={`${notification.emails.user_list.length +
                  notification.emails.email_list.length} Agregar`}
                prefix={{
                  name: "",
                  element: <img alt="" src="/images/user-white.svg" />
                }}
                type="primary"
                onClick={e =>
                  this.props.onChange({
                    target: {
                      name: `editEmails-${notification.notificationId}`,
                      value: notification.id
                    }
                  })
                }
                disabled={!notification.active}
              />
              <img
                alt=""
                src="/images/bin.png"
                onClick={e =>
                  this.props.onChange({
                    target: {
                      name: `clear-${notification.notificationId}`,
                      value: ""
                    }
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationRow;
