import React from "react";
import { getUrlsEnv } from "../backend-api";
import Tag from "../controls/tag";
import ExceedPlus from "../controls/exceed-plus";
import ReactTooltip from "react-tooltip";
import UserInfo from "./user-info";
import { getLastSession, LOG_FORMAT } from "../utils";
import { Flex, flexOpts } from "../styled/flex";
import { FILES_SERVER } from "../../redux/api/api-urls";
import SubStringText from "./substring-text";

class FleetCardContent extends React.Component {
  getValidationIcon = (type) => {
    switch (type) {
      case "Tarjeta de circulación":
      case "Licencia": //
        return "/images/credential.svg";
      case "Póliza de seguro":
        return "/images/shield.svg";
      case "Autorización expresa":
        return "/images/x2Sign.svg";
      case "Certificado de condición físico - mecánico":
      case "Certificado de condición físico-mecánico":
        return "/images/wrench.svg";
      case "Dictamen de baja emisión de contaminantes":
        return "/images/pollution-icon.svg";
      case "Seguridad Social": //
        return "/images/medRecords.svg";
      case "Constancia de aptitud psico-física": //
        return "/images/heart.svg";
      default:
        return "/images/help.png";
    }
  };
  getValidationValueIcon = (value) => {
    switch (String(value)) {
      case "1":
        return (
          <div className="pending">
            <img alt="" src="/images/info_pendiente.svg"></img>
          </div>
        );
      // return (<div className="pending">&#65281;</div>);
      case "2":
        return (
          <div className="validated">
            <img alt="" src="/images/oval green.svg"></img>
          </div>
        );
      // return (<div className="validated">&#10004;</div>);
      case "3":
        return (
          <div className="rejected">
            <img alt="" src="/images/oval red.svg"></img>
          </div>
        );
      // return (<div className="rejected">&#10799;</div>);
      case "4":
        return (
          <div className="nodata">
            <img alt="" src="/images/interrogacion.svg"></img>
          </div>
        );
      // return (<div className="nodata">&#9866;</div>);
      case "5":
        return (
          <div className="defeated">
            <img alt="" src="/images/interrogacion_red.svg"></img>
          </div>
        );
      // return (<div className="defeated">&#9866;</div>);
      default:
        return "";
    }
  };
  getAvatarUrl = (route, isCompany) => {
    if (route) {
      return getUrlsEnv().files.concat(route);
    }
    if (isCompany) {
      if (this.props.itemInfo.company_icon === "") {
        return COMPANY_IMAGE;
      } else {
        return `/${this.props.itemInfo.company_icon}`;
      }
    }
    switch (this.props.itemType.id || 0) {
      case 1:
        return VEHICLE_IMAGE;
      case 2:
        return EQUIPMENT_IMAGE;
      case 3:
        return OPERATOR_IMAGE;
      default:
        return DEFAULT_IMAGE;
    }
  };

  getContent = (validation) => {
    const {
      itemInfo: { shared = 0 },
    } = this.props;

    let content = (this.props.itemInfo.content || []).filter(
      (item) =>
        item.object_id &&
        item.object_id.type &&
        item.object_id.type.id === validation.id
    );

    let companyAdded = [];
    // eslint-disable-next-line
    return content.map((item, index) => {
      const { company = { orbi_id: "", logo: "" } } = item;

      if (shared === -1) {
        if (index === 0) {
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} - ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${item.comment} - ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      } else {
        if (!companyAdded.includes(company.orbi_id)) {
          companyAdded.push(company.orbi_id);
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} - ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${item.comment} - ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      }
    });
  };

  render() {
    let cont = 1;
    return (
      <>
        <div className="top-row top-row-card">
          <div className="avatars">
            <img
              alt=""
              src={`${this.getAvatarUrl(
                this.props.itemInfo.avatar
              )}`}
              style={
                this.props.itemType.id && this.props.itemType.id !== 3
                  ? {
                      backgroundColor: "#FFF",
                      padding: "5px",
                      width: "40px",
                      height: "40px",
                    }
                  : { backgroundColor: "#FFF" }
              }
            />
            {/* {console.log("1", this.props.itemInfo)}
            {console.log("2", this.props.itemType)} */}
            <img
              alt=""
              src={`${this.getAvatarUrl(
                this.props.itemInfo.company_avatar,
                -1
              )}`}
              className="company-logo"
              style={
                this.props.itemInfo.company_avatar
                  ? { backgroundColor: "#1F8EFA" }
                  : this.props.itemInfo.company_icon
                  ? { backgroundColor: "#1F8EFA" }
                  : {
                      backgroundColor: "#1F8EFA",
                      padding: "4px",
                      width: "17px",
                      height: "17px",
                    }
              }
            />
          </div>
          <div className="credentials">
            {this.props.itemType.id === 3 ? (
              <SubStringText
                label={this.props.itemInfo.name}
                labelTag={"p"}
                labelSize={30}
                labelClass={"id"}
                tooltipClass={"tooltip-class-" + this.props.itemInfo.name}
              />
            ) : (
              // <p className="id">{this.props.itemInfo.name}</p>
              <>
                <p className="id">
                  {this.props.itemInfo.economic_number}{" "}
                  {this.props.itemInfo.plates}
                </p>
                <p className="description">
                  {/* {this.props.itemInfo.model} {this.props.itemInfo.brand}{" "}
                  {this.props.itemInfo.year} */}
                  {this.props.itemInfo.fleet_data_type}
                </p>
              </>
            )}
            <p className="company-name">{this.props.itemInfo.company_name}</p>
          </div>
          <div className="gps-validation">
            <div className="content">
              {this.props.itemType.id !== 3 ? (
                <span
                  className={"gps-badge".concat(
                    this.props.itemInfo.hasGps ? " active" : ""
                  )}
                >
                  GPS
                </span>
              ) : (
                ""
              )}
              {this.props.itemInfo.shared && this.props.itemInfo.shared > 0 ? (
                // <React.Fragment>
                //   <div className={"share-text"}>
                //     {this.props.itemInfo.shared}
                //   </div>

                //   <div className={"share-icon"}>
                //     <img src="/images/share.svg" alt="" />
                //   </div>
                // </React.Fragment>
                <React.Fragment>
                  <Flex
                    data-tip
                    data-for={"tooltip-shared-icon".concat(
                      this.props.itemInfo.id
                    )}
                    alignItems={flexOpts.alignItems.flexEnd}
                    style={{ marginLeft: "4px" }}
                  >
                    <span style={{ fontSize: "0.8rem" }}>
                      {this.props.itemInfo.shared}
                    </span>
                    <div className={"share-icon"}>
                      <img src="/images/share.svg" alt="" />
                    </div>
                  </Flex>
                  <ReactTooltip
                    className="truck-tooltip"
                    id={"tooltip-shared-icon".concat(this.props.itemInfo.id)}
                    // getContent={() => el}
                    clickable={true}
                    delayHide={600}
                    delayShow={200}
                    delayUpdate={500}
                    place={"bottom"}
                    // isCapture={true}
                    border={true}
                    type={"light"}
                    effect="solid"
                  >
                    <Flex direction={flexOpts.direction.column}>
                      {this.props.itemInfo.network?.map((cmp) => (
                        <Flex>
                          <img
                            alt=""
                            style={{
                              borderRadius: "20px",
                              width: "40px",
                              height: "40px",
                              margin: "5px",
                            }}
                            src={FILES_SERVER.concat(
                              cmp.logo || this.props.itemInfo.company_avatar
                            )}
                          />
                          <Flex
                            direction={flexOpts.direction.column}
                            alignItems={flexOpts.alignItems.flexStart}
                          >
                            <span>
                              <b>{cmp.corporation_name || cmp.name || ""}</b>
                            </span>
                            <span>{cmp.alias || ""}</span>
                            <span>{cmp.code || ""}</span>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </ReactTooltip>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <span
              className={"validated ".concat(
                VALIDATED_MESSAGE[this.props.itemInfo.validated]
              )}
            >
              {this.props.itemInfo.validated}
            </span>
          </div>
        </div>
        <div className="middle-row middle-row-card">
          {(this.props.itemInfo.validations || []).map((validation) => {
            let conti = cont++;
            return (
              (validation.isConsidered || validation.isConsidered === "") && (
                <React.Fragment
                  key={validation.type.concat("-container-", conti)}
                >
                  <div
                    key={validation.type.concat(" ", conti)}
                    className="validation-icon-container-card"
                    data-tip=""
                    data-for={`normativity-tooltip-${
                      this.props.itemInfo.id
                    }-${validation.type.concat(" ", conti)}`}
                  >
                    <div className="img-container">
                      <img
                        src={this.getValidationIcon(validation.type)}
                        alt=""
                      />
                    </div>
                    <div className="icon-container">
                      {this.getValidationValueIcon(validation.value)}
                    </div>
                  </div>
                  <ReactTooltip
                    id={`normativity-tooltip-${
                      this.props.itemInfo.id
                    }-${validation.type.concat(" ", conti)}`}
                    place="bottom"
                    type="light"
                    effect="solid"
                    delayHide={250}
                    clickable={true}
                    className="cardToolTip"
                    getContent={() => {
                      let data = this.getContent(validation);
                      if (data.length) {
                        return <div className="cardToltipContent">{data}</div>;
                      } else {
                        return false;
                      }
                    }}
                    // style={{maxWidth: "380px", fontSize: "16px"}}
                    //   event="focusin"
                    //   eventOff="focusout"
                  />
                </React.Fragment>
              )
            );
          })}
        </div>
        <div className="bottom-row">
          <ExceedPlus maxVisibleItems={3} name={"tags ".concat(this.props.id)}>
            {(this.props.itemInfo.tags || []).map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  title={tag.title}
                  color={tag.color}
                />
              );
            })}
          </ExceedPlus>
        </div>
      </>
    );
  }
}

export default FleetCardContent;

FleetCardContent.defaultProps = {
  itemInfo: {
    id: "T-380",
    plates: "657-EF-8",
    model: "T600",
    brand: "Kenworth",
    year: "2005",
    company_name: "Transporte Mextravel",
    avatar: "/images/help.png",
    company_avatar: "/images/help.png",
    hasGps: false,
    validated: 0,
    validations: [],
    tags: [
      { tag: { title: "Etiqueta 1" } },
      { tag: { title: "Etiqueta 2" } },
      { tag: { title: "Etiqueta 3" } },
    ],
  },
  onClick: () => {
    console.log("Not yet implemented");
  },
};

const VALIDATED_MESSAGE = {
  Rechazado: "Rechazado",
  Pendiente: "Pendiente",
  Validado: "Validado",
  "Sin documento": "Sin documento",
};

const DEFAULT_IMAGE = "/images/truck.svg";
const VEHICLE_IMAGE = "/images/truck.svg";
const EQUIPMENT_IMAGE = "/images/remolque.svg";
const OPERATOR_IMAGE = "/images/Artboard.svg";
const COMPANY_IMAGE = "/images/modal-factory-icon.svg";
