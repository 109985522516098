import React from "react";
import { connect } from "react-redux";
import Button from "../../shared/controls/button";
import UserRow from "../../shared/component/user-row";
import Paginator from "../../shared/controls/paginator";
import TextInput from "../../shared/controls/text-input";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import AddEmailsModal from "../../shared/dialog/add-emails-modal";
import { searchUsersByText } from "../../redux/reducers/facility-create.reducer";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { normalizeStr, tranformSpecialCharacterUrl } from "../../shared/utils";
import {
  createInvitation,
  loadCatalogUsers,
  addUserFacility,
  loadCompanyUsers,
  loadUserFacilities,
  listCompanyInvitations,
  listInvitationFacilities,
  addFacilityToInvitation,
  addFacilityToUser,
  removeFacilityFromUser,
  removeFacilityFromInvitation,
  listCatalogUsers,
  cancelInvitation
} from "../../redux/reducers/company-reducer";
import { getUrlsEnv } from "../../shared/backend-api";
import {
  getUserByEmail,
  getUserTypes
} from "../../redux/reducers/user-reducer";
import ValidatePerms from "../../shared/component/validate-perms";

class FacilityUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInviteModal: false,
      openConfirmModal: false,
      inviteTags: [],
      inviteDone: false,
      resetPaginator: false,
      currentUser: {}
    };
  }

  inviteUserButtonSettings = {
    text: "Invitar",
    type: "primary",
    onClick: () => {
      this.setState({ openInviteModal: true });
    },
    prefix: {
      element: (
        <img className="user-icon" src="/images/user-white.svg" alt=""></img>
      )
    }
  };

  confirmDeleteUsers = user => {
    if (user.invitation_id) {
      this.props.loadInvitationFacilities(user.invitation_id).then(inv => {
        if (inv.response && inv.response.length) {
          user.invitationFacilityId = inv.response.find(
            i => i.facilities === this.props.facilityId
          ).id;
          user.cancelInvitation = inv.response.length === 1;
        } else {
          user.cancelInvitation = true;
        }
        this.setState({
          openConfirmModal: true,
          currentUser: user
        });
      });
    } else {
      this.props
        .userHasFacility(user.user_id, this.props.facilityId)
        .then(uf => {
          if (uf.response && uf.response.length) {
            user.userFacilityId = uf.response.find(
              i => i.facilities === this.props.facilityId
            ).id;
          }
          this.setState({
            openConfirmModal: true,
            currentUser: user
          });
        });
    }
  };

  deleteUser = () => {
    let { currentUser } = this.state;
    new Promise(resolve => {
      if (currentUser.userFacilityId) {
        this.props
          .removeFacilityFromUser(
            currentUser.user_id,
            currentUser.userFacilityId
          )
          .then(() => resolve());
      } else {
        currentUser.cancelInvitation
          ? this.props
              .cancelInvitation(currentUser.invitation_id)
              .then(() => resolve())
          : this.props
              .removeFacilityFromInvitation(
                currentUser.invitation_id,
                currentUser.invitationFacilityId
              )
              .then(() => resolve());
      }
    }).then(() => {
      this.props.listCatalogUsers(this.props.facilityId);
      this.props.reloadCompanyUsers();
      this.props.reloadCompanyInvitations();
    });
    this.setState({
      resetPaginator: true,
      openConfirmModal: false,
      currentUser: {}
    });
  };

  closeEmailsModal = () => {
    this.setState({
      openInviteModal: false,
      inviteDone: false,
      resetPaginator: true,
      inviteTags: []
    });
    this.props.listCatalogUsers(this.props.facilityId);
  };

  updateView = (limit, page) => {
    this.setState({ limit, page });
    this.props.listCatalogUsers(this.props.facilityId, limit, page);
  };
  searchUser = e => {
    this.props.searchUsers(e.target.value);
    this.setState({searched: e.target.value});
  }
  render() {
    let cont = 1;
    return (
      <div className="facility-users">
        <div className="content-header">
          2.1 Añadir Personal
          <TextInput
            type="text"
            label="Buscar Staff"
            name="searched"
            className="search-bar"
            value={this.state.searched}
            onChange={e => this.searchUser(e)}
          />
          <ValidatePerms
            perms={[
              {
                id: "30",
                perm: "c"
              }
            ]}
          >
            <Button settings={this.inviteUserButtonSettings} />
          </ValidatePerms>
        </div>
        <div className="userList-header">
          <div style={{ width: "7%" }}></div>
          <div style={{ width: "33%" }}>Nombre</div>
          <div style={{ width: "30%" }}>Correo</div>
          <div style={{ width: "20%" }}>Estatus</div>
          {/* <div></div> */}
        </div>
        <div>
          {(this.props.users || []).map(user => {
            return (
              <UserRow
                user={user}
                key={cont++}
                delete={() => this.confirmDeleteUsers(user)}
              />
            );
          })}
        </div>
        <Paginator
          totalElements={this.props.totalUsers}
          reload={(limit, offset) =>
            this.props.listCatalogUsers(this.props.facilityId, limit, offset)
          }
          resetPaginator={this.state.resetPaginator}
          onReset={() => {
            this.setState({ resetPaginator: false });
          }}
        />
        <AddEmailsModal
          open={this.state.openInviteModal}
          title="Invitar personal"
          buttonText="Enviar invitación"
          tags={this.state.inviteTags}
          closeAction={this.closeEmailsModal}
          acceptAction={this.handleInvitationsModalChange}
          facilityId={this.props.facilityId}
          inviteDone={this.state.inviteDone}
        />
        <ConfirmDialog
          open={this.state.openConfirmModal}
          title="Eliminar de la instalación"
          message={"¿Seguro que deseas eliminar a ".concat(
            this.state.currentUser.username || this.state.currentUser.email,
            "?"
          )}
          acceptText="Eliminar"
          class="delete-user"
          closeAction={() => this.setState({ openConfirmModal: false })}
          acceptAction={this.deleteUser}
        />
      </div>
    );
  }

  handleInvitation = /* async */ () => {
    // debugger
    this.state.inviteTags.forEach( /* async */ it => {
      it.sent = true;
      if (
        it.alreadyInFacility ||
        (it.invitedToFacility && it.invitedToCompany)
      ) {
        it.sent = false;
      } else if (!it.invitedToFacility && it.invitedToCompany) {
         /* await */ this.props.addFacilityToInvitation(
          it.invitedToCompany,
          this.props.facilityId
        );
      } else if (it.alreadyInCompany) {
        /* await */ this.props.addFacilityToUser(it.userId, this.props.facilityId);
      } else if (!it.userId) {
         /* await */ this.props.invitePerson(
          it.email,
          this.props.facilityId,
          (
            this.props.userTypes.find(uT => uT.description === "Warehouser") ||
            {}
          ).id
        );
      } else {
         /* await  */this.props.inviteUser(
          it.userId,
          it.email,
          this.props.facilityId,
          (
            this.props.userTypes.find(uT => uT.description === "Warehouser") ||
            {}
          ).id
        );
      }
    });
    this.setState({ inviteDone: true });
  };

  onEmail = email => {
    this.props.findUserByEmail(tranformSpecialCharacterUrl(email)).then(user => {
      if (user.type === "USERS_GET_BY_EMAIL_SUCCESS") {
        let tag = {
          email: email,
          avatar: "/images/mail.svg",
          avatarType: "contained",
          userId: null,
          alreadyInFacility: false,
          alreadyInCompany: false,
          invitedToCompany: false,
          invitedToFacility: false,
          processed: true,
          admin: false
        };
        // debugger
        if (user.response.length > 0) {
          tag.avatar = user.response[0].profile_image
            ? getUrlsEnv().files.concat(user.response[0].profile_image)
            : "/images/user-white.svg";
          tag.avatarType = user.response[0].profile_image
            ? "full"
            : "contained";
          tag.userId = user.response[0].id;
          this.props
            .userHasFacility(user.response[0].id, this.props.facilityId)
            .then(uf => {
              if (
                uf.response &&
                uf.response.length > 0 &&
                uf.response.find(
                  res => res.facilities === this.props.facilityId
                )
              ) {
                tag.alreadyInFacility = true;
                this.setState({
                  inviteTags: this.state.inviteTags.concat(tag)
                }); //tiene facility
              } else {
                // debugger
                if (
                  this.props.companyUsers.find(cu => cu.users === tag.userId)
                ) {
                  tag.alreadyInCompany = true;
                  this.setState({
                    inviteTags: this.state.inviteTags.concat(tag)
                  }); //esta en la empresa
                } else {
                  let invite = this.props.companyInvitations.find(ci =>
                    ci.email.includes(email)
                  );
                  if (invite) {
                    tag.invitedToCompany = invite.id;
                    this.props.loadInvitationFacilities(invite.id).then(inv => {
                      if (
                        inv.response &&
                        inv.response.length &&
                        inv.response.find(
                          r => r.facilities === this.props.facilityId
                        )
                      ) {
                        tag.invitedToFacility = true;
                      }
                      this.setState({
                        inviteTags: this.state.inviteTags.concat(tag)
                      }); //con invitacion
                    });
                  } else {
                    this.setState({
                      inviteTags: this.state.inviteTags.concat(tag)
                    }); // sin invitacion
                  }
                }
              }
            });
        } else {
          let invite = this.props.companyInvitations.find(ci =>
            ci.email.includes(email)
          );
          if (invite) {
            tag.invitedToCompany = invite.id;
            this.props.loadInvitationFacilities(invite.id).then(inv => {
              if (
                inv.response &&
                inv.response.length &&
                inv.response.find(r => r.facilities === this.props.facilityId)
              ) {
                tag.invitedToFacility = true;
              }
              this.setState({ inviteTags: this.state.inviteTags.concat(tag) }); //con invitacion
            });
          } else {
            this.setState({ inviteTags: this.state.inviteTags.concat(tag) }); //sin invitacion
          }
        }
      }
    });
  };

  handleInvitationsModalChange = e => {
    switch (e.target.name) {
      case "inviteTags":
        e.target.value
          .filter(it => !it.processed)
          .forEach(i => this.onEmail(i.email));
        break;
      case "admins":
        if (e.target.checked) {
          this.props.loadAdmins().then(() => {
            let items = this.props.admins.map(adm => ({
              email: adm.email,
              avatar: adm.profile_image
                ? getUrlsEnv().files.concat(adm.profile_image)
                : "/images/mail.svg",
              avatarType: adm.profile_image ? "full" : "contained",
              userId: adm.id,
              alreadyInFacility: false,
              alreadyInCompany: true,
              invitedToCompany: false,
              invitedToFacility: false,
              processed: true,
              admin: true
            }));
            this.setState({ inviteTags: this.state.inviteTags.concat(items) });
          });
        } else {
          this.setState({
            inviteTags: this.state.inviteTags.filter(it => !it.admin)
          });
        }
        break;
      case "deleteTags":
        this.setState({ inviteTags: [].concat(e.target.value) });
        break;
      case "sendInvitations":
        this.handleInvitation();
        break;
      default:
    }
  };
}

const mapStateToProps = state => {
  let searchStr = normalizeStr(state.facilityCreate.usersTextSearch);
  let totalUsers = (state.companyReducer.catalogUsers.data || {}).count || 0;
  let users = (
    (state.companyReducer.catalogUsers.data || {}).results || []
  ).filter(
    fu =>
      normalizeStr(fu.first_name || "").includes(searchStr) ||
      normalizeStr(fu.last_name || "").includes(searchStr) ||
      normalizeStr(fu.username || "").includes(searchStr) ||
      normalizeStr(fu.email_invited || "").includes(searchStr) ||
      normalizeStr(fu.email || "").includes(searchStr)
  );

  return {
    admins: state.companyReducer.users.data,
    users,
    totalUsers,
    companyInvitations: state.companyReducer.invitations.data,
    facilityUsers: state.facilityCreate.facilityUsers,
    companyUsers: state.companyReducer.companyUsers.data,
    companyId: state.globalCatalog.session.company.id,
    userTypes: state.userReducer.userTypes.data
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadSession());
  dispatch(loadCompanyUsers());
  dispatch(listCompanyInvitations());
  dispatch(getUserTypes());
  return {
    // loadUsers: ((facilityId, limit, page) => dispatch(loadUsersByFacility(facilityId, limit, page))),
    searchUsers: textSearch => dispatch(searchUsersByText(textSearch)),
    // deleteUsers: ((idUser, idFacility) => dispatch(deleteUsersFromFacility(idUser, idFacility))),
    invitePerson: (email, facilityId, userType) =>
      dispatch(
        createInvitation(3, userType, undefined, email, {
          facilities: [facilityId]
        }, {}, {}, true)
      ),
    inviteUser: (userId, email, facilityId, userType) =>
      dispatch(
        createInvitation(2, userType, userId, email, {
          facilities: [facilityId]
        }, {}, {}, true)
      ),
    userHasFacility: (userId, facilityId) =>
      dispatch(loadUserFacilities(userId, facilityId)),
    addUserFacility: (userId, facilityId) =>
      dispatch(addUserFacility(userId, facilityId)),
    loadAdmins: () => dispatch(loadCatalogUsers("administrador")),
    findUserByEmail: email => dispatch(getUserByEmail(email, "", "")),
    loadInvitationFacilities: invitationId =>
      dispatch(listInvitationFacilities(invitationId)),
    addFacilityToInvitation: (invitationId, facilityId) =>
      dispatch(addFacilityToInvitation(invitationId, facilityId)),
    addFacilityToUser: (userId, facilityId) =>
      dispatch(addFacilityToUser(userId, facilityId)),
    removeFacilityFromUser: (userId, UserFacilityId) =>
      dispatch(removeFacilityFromUser(userId, UserFacilityId)),
    removeFacilityFromInvitation: (invitationId, invitationFacilityId) =>
      dispatch(
        removeFacilityFromInvitation(invitationId, invitationFacilityId)
      ),
    listCatalogUsers: (facilityId, limit = 10, page = 1) =>
      dispatch(listCatalogUsers(facilityId, limit, page)),
    cancelInvitation: invitationId => dispatch(cancelInvitation(invitationId, true)),
    reloadCompanyUsers: () => dispatch(loadCompanyUsers()),
    reloadCompanyInvitations: () => dispatch(listCompanyInvitations())
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FacilityUsers);
