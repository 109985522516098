export class TimeLine {
  constructor(duration, onChange) {
    this.duration = duration;
    this.direction = 1;
    this._position = 0;
    this.status = "STOP";
    this.startTime = 0;
    this.interval = 33;

    this.intervalObj = null;
    this.onChange = onChange;

    // this.data = {};
    // this.events = {};

    // let self = this;
    // let iFrame = 0;
    this.REQ_ANIMATION = (timeStamp) => { this.update();/*  console.log(timeStamp, iFrame); *//* iFrame++; */ };
  }

  play = () => {
    // debugger;
    if (((this.direction === 1) && (this._position === this.duration)) || ((this.direction === -1) && (this._position === 0)))
    {
    }
    else
    {
      this.startTime = new Date().getTime() - this._position;
      if (this.status !== 'PLAY')
      {
        // debugger;
        // let self = this;
        let doAnimation = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.oRequestAnimationFrame;

        if (doAnimation)
        {
          doAnimation(this.REQ_ANIMATION);
        }
        else
        {
          // let self = this;
          this.intervalObj = setInterval(() => {this.update()}, this.interval);
        }
      }
      this.status = 'PLAY';
    }
  }

  pause = () => {
    this.status = 'PAUSE';
    clearInterval(this.intervalObj);
  }

  stop = () => {
    this.status = 'STOP';
    clearInterval(this.intervalObj);
  }

  getPosition = () => {
    return this._position;
  }

  setPosition = (value) => {
    // debugger;
    this._position = value;
    this.startTime = new Date().getTime() - this._position;
  }

  update = () => {
    // debugger;
    if (!Date.now)
    {
      Date.now = () =>
      {
        return new Date().getTime();
      };
    }
    if (this.status !== 'PLAY')
    {
      return false;
    }
    let TIME = Date.now();
    if (this.direction === 1)
    {
      this._position =  TIME - this.startTime;
      this._position = Math.min(this.duration, this._position);
      if (this._position === this.duration)
      {
        // debugger;
        this.status = 'STOP';
        clearInterval(this.intervalObj);
        this.onComplete && this.onComplete(Event.COMPLETE);

        if (this.onComplete)
        {
          this.onComplete();
        }
      }
      else if (this.status === 'PLAY')
      {
        let doAnimation = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.oRequestAnimationFrame;

        if (doAnimation)
        {
          doAnimation(this.REQ_ANIMATION);
        }
      }
    }
    else
    {
      this._position = Math.max((2 * this._position) - (TIME - this.startTime), 0);
      this.startTime = TIME - this._position;
      if (this._position === 0)
      {
        this.status = 'STOP';
        clearInterval(this.intervalObj);
        this.onComplete && this.onComplete(Event.COMPLETE);

        if (this.onComplete)
        {
          this.onComplete();
        }
      }
      else if (this.status === 'PLAY')
      {
        let doAnimation = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.oRequestAnimationFrame;

        if (doAnimation)
        {
          doAnimation(this.REQ_ANIMATION);
        }
      }
    }
    // debugger;
    this.onChange && this.onChange(Event.CHANGE);
    
  }

  getTime = (timeOffset, duration, easeFunction) =>
  {
    let time = Math.min(Math.max(this._position - timeOffset, 0), duration);
    // if (easeFunction !== null)
    // {
    //   return easeFunction(time, 0, 1, duration);
    // }
    return this.easeInOut(time, 0, 1, duration);
  }

  easeInOut = (t, b, c, d) =>
  {
    return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
  }

  easeInOutPos = (pos, b, c, d) =>
  {
    return  Math.acos(-(pos - b) * (2 / c)  + 1) * d / Math.PI;
  }

  easeInSine = (t, b, c, d) => {
    return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
  }
  easeOutSine = (t, b, c, d) => {
    return c * Math.sin(t/d * (Math.PI/2)) + b;
  }
  easeInOutSine = (t, b, c, d) => {
    return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
  }
  easeInElastic = (t, b, c, d) => 
  {
    var s=1.70158;var p=0;var a=c;
    if (t===0) return b;  if ((t/=d)===1) return b+c;  if (!p) p=d*.3;
    if (a < Math.abs(c)) { a=c; s=p/4; }
    else s = p/(2*Math.PI) * Math.asin (c/a);
    return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
  }
  easeOutElastic = (t, b, c, d) => {
    var s=1.70158;var p=0;var a=c;
    if (t===0) return b;  if ((t/=d)===1) return b+c;  if (!p) p=d*.3;
    if (a < Math.abs(c)) { a=c; s=p/4; }
    else s = p/(2*Math.PI) * Math.asin (c/a);
    return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
  }
  easeInOutElastic = (t, b, c, d) => {
    var s=1.70158;var p=0;var a=c;
    if (t===0) return b;  if ((t/=d/2)===2) return b+c;  if (!p) p=d*(.3*1.5);
    if (a < Math.abs(c)) { a=c; s=p/4; }
    else s = p/(2*Math.PI) * Math.asin (c/a);
    if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
    return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
  }
  easeInBack = (t, b, c, d, s) => {
    if (s === undefined) s = 1.70158;
    return c*(t/=d)*t*((s+1)*t - s) + b;
  }
  easeOutBack = (t, b, c, d, s) => {
    if (s === undefined) s = 1.70158;
    return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
  }
  easeInOutBack = (t, b, c, d, s) => {
    if (s === undefined) s = 1.70158;
    if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
    return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
  }
  easeOutBounce = (t, b, c, d) => {
    if ((t/=d) < (1/2.75)) {
      return c*(7.5625*t*t) + b;
    } else if (t < (2/2.75)) {
      return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
    } else if (t < (2.5/2.75)) {
      return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
    } else {
      return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
    }
  }
}

export const Scroll = {
  end: null,
}
Scroll.go = (position, element, scrollTop, offset) => {
  // debugger;
  Scroll.element = element;
  Scroll.start = (Scroll.element ? element.scrollTop : (scrollTop || document.body.scrollTop || document.documentElement.scrollTop));
  if (position.nodeType)
  {
    position = position.getBoundingClientRect().top + Scroll.start - (element ? Math.max(0, element.getBoundingClientRect().top) : 0);
  }
  position += (offset || 0);

  Scroll.end = position;
  // console.log(Scroll.start, Scroll.end)
  Scroll.timeLine.duration = Math.min(Math.max(200, Math.abs(Scroll.end - Scroll.start) * 1.5), 1500);
  Scroll.timeLine._position = 0;
  // debugger;
  Scroll.timeLine.play();
}
Scroll.refresh = () => {
  // debugger;
  let position = Scroll.start + (Scroll.end - Scroll.start) * Scroll.timeLine.getTime(0, Scroll.timeLine.duration);
  if (Scroll.element)
  {
      Scroll.element.scrollTop = position
  }
  else
  {
      document.body.scrollTop = position
      document.documentElement.scrollTop = position;
  }
}
Scroll.timeLine = new TimeLine(500, Scroll.refresh);