import React from "react";
const Agency = (props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 13 13"
      fill="currentColor"
      {...props}
    >
      <path d="M0 12V3.75L6 0L12 3.75V12H9.75V4.8H2.25V12H0ZM3.75 10.5V12H5.25V10.5H3.75ZM6.75 10.5V12H8.25V10.5H6.75ZM3.75 9.75V8.25H5.25V9.75H3.75ZM6.75 9.75V8.25H8.25V9.75H6.75ZM6.75 7.5H5.27207L5.25 6H6.72793L6.75 7.5Z" />
    </svg>
  );
};

export default Agency;
