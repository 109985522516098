import * as React from "react";
// import type TableDConfig from "../../shared/component/table-d";
import RowD from "../../shared/component/row-d";
import styled from "styled-components";
import * as Types from "./types";
import * as moment from "moment";
import "moment/locale/es";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
import Tag from "../../shared/controls/tag";
import ReactTooltip from 'react-tooltip';
import Button from "../../shared/controls/button";
import PDFIcon from "../../shared/icons/pdf-icon";
import XMLIcon from "../../shared/icons/xml-icon";
import CopyIcon from '../../shared/icons/copy-icon';
import ImageWithCheckMark from "../../shared/component/image-with-check-mark";

type Props = {
  details: Types.UnassignedShipmentRowDetailType,
};

const getDateFormat = (date) => {
  return (
    moment(date).format("D") +
    " " +
    moment(date)
      .format("MMM")
      .charAt(0)
      .toUpperCase() +
    moment(date)
      .format("MMM")
      .slice(1)
      .replace(".", "") +
    " " +
    moment(date).format("H:mm")
  );
};

const getEtaLoading = (order_containers, appointment) => {

  let timesToShow = [];

  (order_containers || []).map( container => {
    
    let ataDate = container?.ata_loading_date ?? '';
    let etaDate = container?.eta_loading ?? '';

    let timeToValidate = ataDate === '' ? etaDate : ataDate;

    let isEta = ataDate === '';

    if (timeToValidate === '') {
      timesToShow.push(
        <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
      );
    } else {

      let isRedColor = false;

      if (appointment !== '') {
        isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
      }

      let eta_loading_data = container.eta_loading_data ?? null;

      if (isEta && eta_loading_data !== null) {
        timesToShow.push(
          <div id={'container-eta'} style={{display: 'flex', flexDirection: 'column', alignItems:'left'}}>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div id={'container-eta'} style={{color: isRedColor ? 'red' : 'white', textAlign: 'left'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Creación de ETA: ${moment(eta_loading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'}>{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_loading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_loading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Distancia: ${eta_loading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Placa ETA: ${eta_loading_data?.plates ?? ''}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', maxWidth: 350}}>{`Placas Ensamble: ${ (eta_loading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Dispositivo: ${eta_loading_data?.device ?? ''}`}</div>
          </div>
        );
      } else {

        timesToShow.push(
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{color: isRedColor ? 'red' : 'white'}}>
              {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
            </div>
          </div>
        );

      }

    }

  });

  return(
    <div> 
      { timesToShow }
    </div>
  );

}

const getEtaUnloading = (order_containers, appointment) => {

  let timesToShow = [];

  (order_containers || []).map( container => {
    
    let ataDate = container?.ata_unload_date ?? '';
    let etaDate = container?.eta_unloading ?? '';

    let timeToValidate = ataDate === '' ? etaDate : ataDate;

    let isEta = ataDate === '';

    if (timeToValidate === '') {
      timesToShow.push(
        <div style={{marginLeft: 5}}>{`ETA: No disponible`}</div>
      );
    } else {

      let isRedColor = false;

      if (appointment !== '') {
        isRedColor = moment(timeToValidate).isSameOrAfter(appointment);
      }

      let eta_unloading_data = container.eta_unloading_data ?? null;

      if (isEta && eta_unloading_data !== null) {
        timesToShow.push(
          <div id={'container-eta'} style={{display: 'flex', flexDirection: 'column', alignItems:'left'}}>
            <div id={'container-eta'} style={{display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
              <div id={'container-eta'} style={{color: isRedColor ? 'red' : 'white', textAlign: 'left'}}>
                {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
              </div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Creación de ETA: ${moment(eta_unloading_data?.created ?? '').format("DD/MM/YYYY HH:mm")} hr`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
              <div id={'container-eta'}>{'Coordenada:'}</div>
              <div
                id={'container-eta'}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginLeft: 5
                }}
                onClick={() => {
                  let points = (eta_unloading_data?.from ?? '').split(',');
                  if (points.length > 1) {
                    window.open(`https://www.google.com/maps/search/?api=1&query=${points[0]},${points[1]}`, "");
                  }
                }}
              >{` ${eta_unloading_data?.from ?? ''}`}</div>
            </div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Distancia: ${eta_unloading_data?.distance ?? ''} kms`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Placa ETA: ${eta_unloading_data?.plates ?? ''}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left', maxWidth: 350}}>{`Placas Ensamble: ${ (eta_unloading_data?.plates_history ?? []).join(', ')}`}</div>
            <div id={'container-eta'} style={{marginRight: 5, width: 'auto', textAlign: 'left'}}>{`Dispositivo: ${eta_unloading_data?.device ?? ''}`}</div>
          </div>
        );
      } else {

        timesToShow.push(
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
            <div style={{color: isRedColor ? 'red' : 'white'}}>
              {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
            </div>
          </div>
        );

      }

    }

  });

  return(
    <div id={'loading-eta'}> 
      { timesToShow }
    </div>
  );

}

const getEstimatedTime = (eta, ata, appointment) => {  
  let timeToValidate = ata === '' ? eta : ata;

  if (timeToValidate === '') {

    return(
      <div style={{width: 140, display: 'flex', flexDirection: 'row'}}>
        {`ETA: No disponible`}
      </div>
    );

  } else {

    let isEta = ata === ''

    if (appointment !== '') {

      let isRedColor = moment(timeToValidate).isSameOrAfter(appointment);

      return(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
          <div style={{color: isRedColor ? 'red' : 'white'}}>
            {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
          </div>
        </div>
      );

    } else {

      return(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 'auto'}}>{isEta ? 'ETA: ' : 'ATA: '}</div>
          <div style={{color: 'white'}}>
            {`${moment(timeToValidate).format("DD MMMM HH:mm")} hr`}
          </div>
        </div>
      );

    }

  }

}

const openDocument = (url) => {
  // window.open(url, "_blank");
  let array = url.split("/");
  let lastSegment = array.length > 0 ? array[array.length - 1] : ".";

  fetch(url)
    .then(response => response.blob())
    .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = lastSegment;
        link.click();
    })
    .catch(console.error);
}

const getCFDIColor = (status) => {
  switch(status){
    case 2: 
      return "#dcdcdc";
    case 3: 
      return "#3d77f7";
    case 4:
    case 5:
    case 6:
      return "#dc1111";
    default:
      return "#dcdcdc";
  }
}

const getIDCStatus = (props) => {

  let textToShow = [];
  let valuesAdded = [];

  if (props && props.container_status && props.container_status.length) {
    for(var i = 0; i < props.container_status.length; i++) {

      let status = `${props.container_status[i].status}`;
      if (!valuesAdded.includes(status)) {
        valuesAdded.push(status);
        textToShow.unshift(
          <Text>{status}</Text>
        );
      }

    }
  }

  if (textToShow.length > 0) {
    return(
      <IDCStatusContainer>
        {textToShow}
      </IDCStatusContainer>
    );
  } else {
    return(<div></div>);
  }

}

const AssembleShipmentListRowDetail = (props: Props) => {

  let tooltip = `${props.id || ''}-${props.ticket || ''}`;
  
  return (
    <RowD {...props}>

      {
        <div style={{display: 'flex', alignItems: 'center', margin: 'auto'}}>
          <Text
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.history.push(
                `/company/controls/shipment/orderdetail/${props.tripId}`
              )
              
              if (props.setOrderDetail) {
                props.setOrderDetail(props.ticket);
              }
            }}
          >{props.ticket}</Text>
          {
            props.ticket !== "" ?
            <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(props.ticket);}}/> : ""
          }
        </div>
      }
      <CompanyAvatar>
        <ImageWithCheckMark
          src={props.origin.logo}
          width={35}
          isCheckMark={props.origin.is_company_verified}
          customStyle={{
            width: 35, height: 35, borderRadius: '50%', position: 'relative', marginRight: 8
          }}
        />
        <div>
          <Name>{props.origin.name}</Name>

          <ReactTooltip
            id={tooltip}
            className='eta-tooltip-content'
            clickable={true}
            event="click"
          > 
            {getEtaLoading(props.orderContainers, props.origin?.date || '')}
          </ReactTooltip>

          <Text
            data-tip
            data-for={tooltip}
            onMouseEnter={(e) => {
              ReactTooltip.hide();
              ReactTooltip.show(e.currentTarget);
            }}
          >
            {props.origin.date ? getDateFormat(props.origin.date) : "Pendiente"}
          </Text>
        </div>
      </CompanyAvatar>
      <CompanyAvatar>
        <ImageWithCheckMark
          src={props.destination.logo}
          width={35}
          isCheckMark={props.destination.is_company_verified}
          customStyle={{
            width: 35, height: 35, borderRadius: '50%', position: 'relative', marginRight: 8
          }}
        />
        <div>
          <Name>{props.destination.name}</Name>

          <ReactTooltip
            id={tooltip + 'unload'}
            className='eta-tooltip-content'
            clickable={true}
            event="click"
          > 
            {getEtaUnloading(props.orderContainers,props.destination?.date || '')}
          </ReactTooltip>

          <Text
            data-tip
            data-for={tooltip + 'unload'}
            onMouseEnter={(e) => {
              ReactTooltip.hide();
              ReactTooltip.show(e.currentTarget);
            }}
          >
            {props.destination.date
              ? getDateFormat(props.destination.date)
              : "Pendiente"}
          </Text>
        </div>
      </CompanyAvatar>
      {/* <Text>{props.tags.map(t => {return <Tag title={(t["tag:"] || {}).title  || ""} color={(t["tag:"] || {}).color}/>})}</Text> */}
      {getIDCStatus(props)}
      {/* <IDCStatusContainer>
        <Text>{'hola'}</Text>
        <Text>{'adios'}</Text>
      </IDCStatusContainer> */}
      <Text>{(props.base && props.base.title) && <Tag title={props.base.title} color={props.base.color}/>}</Text>
      <Text>{props.assembly}</Text>
      <Text>{props.statusDescription}</Text>
      <Text>{props.type}</Text>
      <Text>{props.level}</Text>
      <Text>{props.weight} Ton</Text>
      <Text>{props.vol} HL</Text>
      {/* <Text>{props.quantity}</Text> */}
      <div>
        {
          props.cfdi && props.cfdi.status !== 1 && props.cfdi.status !== 4 && props.cfdi.status !== 5
          ? true
            ? <div style={{display: "flex"}}>
                {
                  props.cfdi.pdf_file 
                    ? <div 
                        style={{ cursor: "pointer"}} 
                        onClick={() => {
                          openDocument(props.cfdi.pdf_file);
                        }}
                      >
                        <PDFIcon 
                          height="23" 
                          width="23"
                          fill={getCFDIColor(props.cfdi.status)}
                        />
                      </div>
                    : ''
                }
                {
                  props.cfdi.xml_file 
                    ? <div 
                        style={{ cursor: "pointer"}} 
                        onClick={() => {
                          openDocument(props.cfdi.xml_file);
                        }}
                      > 
                        <XMLIcon  
                          height="23" 
                          width="23"
                          fill={getCFDIColor(props.cfdi.status)}
                        />
                      </div>
                    : ''
                }
              </div>
            : ''
          : props.cfdiErrors 
            ? props.cfdiErrors.length 
              ? <>
                  <div
                    data-place="left"
                    data-tip
                    data-for={`actions-${props.id}-cfdi`}
                  >
                    <Button
                      onClick={() => {}}
                      type={`btn primary small`}
                      text={'Crear CFDI'}
                      name="secondary"
                      disabled={true}
                    />
                  </div>

                  <ReactTooltip
                    html={true}
                    id={`actions-${props.id}-cfdi`}
                  >
                    {props.cfdiErrors}
                  </ReactTooltip>
                </>
              : <Button
                  onClick={() => props.history.push(`/company/billing/${props.id}/?isOrder=1`)}
                  type={`btn primary small`}
                  text={'Crear CFDI'}
                  name="secondary"
                />
            : ''
        }
      </div>
      <ActionsContainer>
          {
            props.actions
            .filter((f) => f.type.includes("extra") || f.type.includes("xtra-".concat(props.id))).length 
            ? <ActionsTricolon
              tooltipPlacement={"left"}
              actions={props.actions
                .filter((f) => f.type.includes("extra") || f.type.includes("xtra-".concat(props.id)))
                .map((a) => ({
                  ...a,
                  additional: props.ticket,
                }))}
            />
            :
            ''
          }
          
        </ActionsContainer>
    </RowD>
  );
};
export default AssembleShipmentListRowDetail;

const Text = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  //line-height: 19px;

`;
const Name = styled.div`
  opacity: 0.76;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  & img {
    width: 35px;
    height: 35px;
    border-radius: 24px;
    margin-right: 6px;
  }
  & div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const IDCStatusContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

// const headerItemStyle = {
//   color: "#A4B4CE",
//   fontSize: "16px",
//   fontWeight: "bold",
//   letterSpacing: "0",
//   textAlign: "center",
// };

// const cellStyle = {
//   color: "#FFFFFF",
//   fontSize: "16px",
//   fontWeight: "bold",
//   letterSpacing: "0",
//   //   textAlign: "center",
//   display: "flex",
//   justifyContent: "center",
//   margin: "auto",
// };

// const Config: TableDConfig = {
//   rowStyle: {
//     backgroundColor: "rgb(34,42,62)",
//     height: "50px",
//   },
//   headerStyle: {
//     backgroundColor: "rgb(34,42,62)",
//     borderBottom: "2px solid #A4B4CE",
//   },
//   columns: [
//     {
//       text: "Folio",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Origen/Cita",
//       width: "20%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Destino/Cita",
//       width: "20%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Tipo",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Nivel",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Peso",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Vol.",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//     {
//       text: "Cantidad",
//       width: "10%",
//       headerItemStyle,
//       cellStyle,
//     },
//   ],
// };
