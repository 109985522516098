// @flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
// import { selectModalContent } from "../assemble-utils";
import type { SimpleEvent } from "../../../shared/types/";
import AssembleSummary from "../assemble-summary";
import RadioButtons from "../../../shared/controls/radio";
// import { size } from "lodash";
import { Dolly, Equipment } from "../../../shared/icons/assemble";
import { AssembleAvatar } from "../assemble-avatar";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../shared/icons/tat/loader";

type Props = {
  // FleetDatas: any,
  unasembledEquipments: any,
  showLinkModal: boolean,
  loadSingleAssemblies: any,
  companyId: number,
  companyFleets: any,
  onChange: Function,
  linkAssemblies: Function,
};
type State = {
  equipmentsData: Array<any>,
  selectedItem: any,
};

class AssembleLinkAssemble extends React.Component<Props, State> {
  static defaultProps = {
    manageError: () => {},
    scrollCallback: () => {}
  };
  state = {
    equipmentsData: [],
    selectedItem: 0,
    loading: false,
  };

  componentDidMount() {
    this.manageLinkModal({
      target: { name: "loadResources", value: "" },
    });
  }

  render() {
    const {
      showLinkModal,
      unasembledEquipments,
      assembleSummaryResources,
      itemCount,
      scrollCallback
    } = this.props;
    const {
      selectedItem,
    } = this.state;
    return (
      <BaseDialog
        open={showLinkModal}
        className="create-assemble-dialog"
        closeAction={this.manageLinkModal}
        acceptAction={() =>
          this.manageLinkModal({
            target: { name: "link", value: "" },
          })
        }
        title="Vincular a otro ensamble"
        contentObject={
          <div>
            <div style={{display: "flex", justifyContent: "space-between", marginRight: "10%"}}>
              <div style={{fontSize: "14px"}}>
                Selecciona otro ensamble para vincularlo a este:
              </div>
              <div style={{position: "relative"}}>
                <AssembleSummary 
                  style={{ position: "relative", top: "-40px" }}
                  equipments={assembleSummaryResources.equipments}
                  operators={assembleSummaryResources.operators}
                  vehicles={assembleSummaryResources.vehicles}
                />
              </div>
            </div>
            <div style={{marginBottom: "20px", borderBottom: "1px solid #c7c8cec4"}}>
              <div style={{
                display: "flex", 
                lineHeight: "50px",
                backgroundColor: "#c7c8ce7a",
                fontWeight: "bold",
                fontSize: "15px"
              }}>
                <div style={{width: "50%", textAlign: "center"}}>
                  Ensamble
                </div>
                <div style={{width: "50%", textAlign: "center"}}>
                  Base
                </div>
              </div>
              <InfiniteScroll
                dataLength={unasembledEquipments.length}
                next={scrollCallback}
                hasMore={itemCount > unasembledEquipments.length}
                loader={<h4>Cargando...</h4>}
                height={250}
                // endMessage={
                //   <p style={{ textAlign: 'center' }}>
                //     <b>Yay! You have seen it all</b>
                //   </p>
                // }
              >
                {
                  unasembledEquipments.map(e => {
                    return (
                      <div style={{display: "flex", alignItems: "center", marginLeft: "20px", lineHeight: "50px"}}>
                        <div style={{width: "10%"}}>
                          <RadioButtons 
                            value={""}
                            name={"assemblies"}
                            onChange={() => {
                              this.handleChange({
                                target: {
                                  name: "equipmentsData",
                                  value: e.id,
                                },
                              })
                            }}
                            id={e.id}
                            checked={selectedItem === e.id}
                          />
                        </div>
                        <div>
                          {Object.values(e.equipments).map(eq => 
                            <AssembleAvatar
                              icon={eq[0].equipment_type === 7 ? Dolly : Equipment}
                              showIcon={true}
                              description={(eq[0].plates || "").concat('/', eq[0].economic_number)}
                              fleetTypeId={2}
                              color="#000000"
                            />) }
                        </div>
                      </div>
                    )
                  })
                }
              </InfiniteScroll>
              {/* <div>
                {
                  unasembledEquipments.map(e => {
                    return (
                      <div style={{display: "flex", alignItems: "center", marginLeft: "20px", lineHeight: "50px"}}>
                        <div style={{width: "10%"}}>
                          <RadioButtons 
                            value={""}
                            name={"assemblies"}
                            onChange={() => {
                              this.handleChange({
                                target: {
                                  name: "equipmentsData",
                                  value: e.id,
                                },
                              })
                            }}
                            id={e.id}
                            checked={selectedItem === e.id}
                          />
                        </div>
                        <div>
                          {Object.values(e.equipments).map(eq => 
                            <AssembleAvatar
                              icon={eq[0].equipment_type === 7 ? Dolly : Equipment}
                              showIcon={true}
                              description={(eq[0].plates || "").concat('/', eq[0].economic_number)}
                              fleetTypeId={2}
                              color="#000000"
                            />) }
                        </div>
                      </div>
                    )
                  })
                }
              </div> */}
            </div>
          </div>
        }
        style={{ content: { width: "800px", marginLeft: "-400px" } }}
        showCancel={true}
        cancelText="Cancelar"
        acceptText={this.state.loading ? <Loader /> : "Asignar"}
        acceptButtonType="primary"
        disabledAccept={!selectedItem || this.state.loading}
      />
    );
  }

  manageLinkModal = (e: SimpleEvent) => {
    const { name } = e.target;
    const {
      loadSingleAssemblies,
      companyId,
      companyFleets,
      onChange,
    } = this.props;
    switch (name) {
      case "loadResources":
        loadSingleAssemblies(
            {
              company_id: companyId,
              fleet_id: companyFleets[0].id,
              limit: 10,
              offset: 1
            }, {
              strategy: "REPLACE"
            }
          )
        break;
      case "close":
        onChange({
          target: { name: "showLinkModal", value: false },
        });
        break;
      case "cancel":
        onChange({
          target: { name: "showLinkModal", value: false },
        });
        break;
      case "link":
        // this.updateAssembleResource();
        this.linkAssemblies();
        break;
      case "saved":
        onChange({
          target: { name: "saved", value: false },
        });
        this.setState({selectedItem: 0});
        break;
      default:
    }
  };

  linkAssemblies = () => {
    this.setState({loading: true});
    const {
      companyId,
      companyFleets,
      currentAssembly
    } = this.props;
    this.props.linkAssemblies({
      company_id: companyId,
      fleet_id: companyFleets[0].id,
      assembly_id: currentAssembly,
      merged_assembly: this.state.selectedItem,
    }, {
      onSuccess: () => {
        this.setState({loading: false});
        this.manageLinkModal({ target: { name: "saved" } })
      },
      onError: (e) => {
        this.setState({loading: false});
        this.manageLinkModal({ target: { name: "cancel" } });
        let err = e.response?.data?.errors || []; 
        this.props.manageError(err);
      }
    })
  }

  handleChange = (e: SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "equipmentsData":
        this.setState({
          selectedItem: value,
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };
}

export default AssembleLinkAssemble;
