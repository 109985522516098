import React from 'react';
import { connect } from 'react-redux';
import Button from '../../shared/controls/button';
import { showStep } from '../../redux/reducers/facility-create.reducer';

const STEPS = 3;
const BUTTON_STATE_DISABLED = 'disabled';
const BUTTON_STATE_DONE = 'done';
const BUTTON_STATE_ACTIVE = 'active';

class WorkflowStepControl extends React.Component {
  cnt = 0;
  getButtons = () => {
    let items = [];
    for (let i = 1; i <= STEPS; i++) {
      let buttonState = BUTTON_STATE_DISABLED;
      if (this.props.lastStep > i) {
        buttonState = BUTTON_STATE_DONE;
      }
      if (this.props.currentStep === i) {
        buttonState = BUTTON_STATE_ACTIVE;
      }
      if (this.props.lastStep === i && this.props.currentStep < i) {
        buttonState = '';
      }

      items.push(<Button key={i + this.cnt} settings={{
        type: 'circle '.concat(buttonState),
        text: buttonState === BUTTON_STATE_DONE ? '✓' : i.toString(),
        onClick: () => { this.props.showStep(i) }
      }} />);
    }
    this.cnt += STEPS;
    return items;
  }

  render() {
    return (
      <React.Fragment >
        {this.getButtons()}
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentStep: state.facilityCreate.currentStep,
    lastStep: state.facilityCreate.lastStep
  };
};
const mapDispatchToProps = dispatch => {
  return { showStep: step => dispatch(showStep(step)) }
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default connectToStore(WorkflowStepControl);