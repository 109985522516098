import React, { Component } from "react";
import PropTypes from "prop-types";

const buildFileSelector = (acceptTypesArray, multiple) => {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept", acceptTypesArray.join());
  if (multiple) {
    fileSelector.setAttribute("multiple", "multiple");
  }
  return fileSelector;
};

export const acceptTypes = {
  allImages: "image/*",
  pdf: ".pdf",
  doc: ".doc",
  jpg: ".jpg,.jpeg",
  file: "file",
  cer: ".cer",
  key: ".key",
  imagesAndPdf: "image/jpeg,image/gif,image/png,application/pdf,image/x-eps",
  xls:
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  xml: ".xml, text/xml",
};

class FileSelector extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    acceptTypes: PropTypes.array,
    allowMultiple: PropTypes.bool,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    onChange: () => {},
    className: "",
    label: "",
    acceptTypes: [],
    allowMultiple: false,
  };

  fileSelector = buildFileSelector(
    this.props.acceptTypes,
    this.props.allowMultiple
  );

  changeImage = (e) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: Array.from(this.fileSelector.files),
      },
    });
    e.target.value = "";
  };

  handleInput = () => {
    this.fileSelector.onchange = (e) => this.changeImage(e);
    this.fileSelector.click();
  };

  render() {
    return (
      <div className={"file-selector ".concat(this.props.className)}>
        {React.cloneElement(this.props.children, { onClick: this.handleInput })}
      </div>
    );
  }
}

// export const handleAddDocument = (e) => {
//   let value = (this.props.value[e.target.name] || []).concat(e.target.value);
//   this.handleInput({ target: { name: e.target.name, value: value } });
// }

// handleRemoveDocument = (file) => {
//   let value = this.props.value['files'].filter(fi => fi.name !== file.name || fi.size !== file.size);
//   this.handleInput({ target: { name: 'files', value: value } });
// }

export const getFileDescription = (f, maxLength = 10) => {
  let size = f.size;
  let magnitude = ["B", "KB", "MB", "GB"];
  let magCount = 0;
  while (size > 1024) {
    size /= 1024;
    magCount++;
  }

  if (size) {
    return (f.name.length > maxLength
      ? f.name.substring(0, maxLength - 4).concat("...")
      : f.name
    ).concat(" (", size.toFixed(2), magnitude[magCount], ")");
  } else {
    return String(f.name).length > 10
      ? String(f.name).substring(0, 10) + "... "
      : f.name;
  }
};

export default FileSelector;
