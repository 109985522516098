import React from "react";

class CommandControlCard extends React.Component {

  state = {
    mouseOver: false,
    mouseOverCategory: null
  }

  buildContent = () => {

    return(
      <div
        style={{
          width: 'calc(100% - 20px)',
          height: 'calc(100% - 20px)',
          color: 'white',
          margin: 10,
          fontSize: 14,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div>{this.props.data.title}</div>

        <div
          style={{
            flexGrow: 1
          }}
        >
          {this.buildItems()}
        </div>

        {/* <div
          style={{
            width: '100%',
            height: 34,
            borderRadius: 8,
            backgroundColor: '#3E4E6C',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            float: 'bottom',
            cursor: 'pointer'
          }}
          onClick={ () => this.props.showDetails()}
        >
          {'Ver Detalles'}
        </div> */}
      </div>
    );

  }

  buildItems = () => {

    let itemsToShow = this.props.data.items ?? [];

    if (itemsToShow.length === 1) {

      return(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-around'
          }}
        >
          <div
            style={{
              cursor: 'pointer'
            }}
            onMouseEnter={() => {
              this.setState({
                mouseOverCategory: itemsToShow[0].id
              });
            }}
            onMouseLeave={() => {
              if (this.state.mouseOverCategory !== null) {
                this.setState({
                  mouseOverCategory: null
                });
              }
            }}
            onClick={ () => this.props.showDetails(itemsToShow[0].id)}
          >
            <div
              style={{
                fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[0].id) ? 14 : 10,
              }}
            >
              {itemsToShow[0].title}
            </div>
            <div
              style={{
                fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[0].id) ? 40 : 32
              }}
            >
              {itemsToShow[0].value}
            </div>
          </div>
        </div>
      );

    } else if (itemsToShow.length === 2) {

      return(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%'
          }}
        >
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-around'
            }}
          >
            <div
              onMouseEnter={() => {
                this.setState({
                  mouseOverCategory: itemsToShow[0].id
                });
              }}
              onMouseLeave={() => {
                if (this.state.mouseOverCategory !== null) {
                  this.setState({
                    mouseOverCategory: null
                  });
                }
              }}
              style={{
                cursor: 'pointer'
              }}
              onClick={ () => this.props.showDetails(itemsToShow[0].id)}
            >
              <div
                style={{
                  fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[0].id) ? 14 : 10
                }}
              >
                {itemsToShow[0].title}
              </div>
              <div
                style={{
                  fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[0].id) ? 40 : 32
                }}
              >
                {itemsToShow[0].value}
              </div>
            </div>
          </div>

          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'space-around',
              cursor: 'pointer'
            }}
            onMouseEnter={() => {
              this.setState({
                mouseOverCategory: itemsToShow[1].id
              });
            }}
            onMouseLeave={() => {
              if (this.state.mouseOverCategory !== null) {
                this.setState({
                  mouseOverCategory: null
                });
              }
            }}
            onClick={ () => this.props.showDetails(itemsToShow[1].id)}
          >
            <div>
              <div
                style={{
                  fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[1].id) ? 14 : 10
                }}
              >
                {itemsToShow[1].title}
              </div>
              <div
                style={{
                  fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === itemsToShow[1].id) ? 40 : 32
                }}
              >
                {itemsToShow[1].value}
              </div>
            </div>
          </div>
        </div>
      );

    } else if (itemsToShow.length > 2) {

      return(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            marginBottom: 10,
            marginTop: 10,
            height: 180
          }}
        >
          {
            itemsToShow.map( (item, index) => {
              return(
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    alignItems: 'center'
                  }}
                  onMouseEnter={() => {
                    this.setState({
                      mouseOverCategory: item.id
                    });
                  }}
                  onMouseLeave={() => {
                    if (this.state.mouseOverCategory !== null) {
                      this.setState({
                        mouseOverCategory: null
                      });
                    }
                  }}
                  onClick={ () => this.props.showDetails(item.id)}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === item.id) ? 14 : 10
                    }}
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      fontSize: (this.state.mouseOverCategory !== null && this.state.mouseOverCategory === item.id) ? 40 : 20
                    }}
                  >
                    {item.value}
                  </div>
                </div>
              );
            })
          }
        </div>
      );

    } else {
      return(<div/>);
    }

  }

  render() {
    
    return(
      <div
        onMouseEnter={() => {
          if (!this.state.mouseOver) {
            this.setState({
              mouseOver: true
            });
          }
        }}
        onMouseLeave={() => {
          if (this.state.mouseOver) {
            this.setState({
              mouseOver: false
            });
          }
        }}
        style={{
          width: 240,
          height: 190,
          background: 'linear-gradient(139.24deg, #1C36BC 45%, #4A82E3 100%)',
          borderRadius: 15,
          position: 'relative'
        }}
      >
        {this.buildContent()}

        <div
          style={{
            position: 'absolute',
            top: -5,
            left: -5,
            width: 250,
            height: 200,
            background: '#3D77F7',
            borderRadius: 15,
            visibility: this.state.mouseOver ? 'visible' : 'hidden',
          }}
        >
          {this.buildContent()}
        </div> 

      </div>
    );
  }

}

export default CommandControlCard;