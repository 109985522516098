import React from "react";

const FleetDetailTitle = (props) => {
  const { isDataCollapsed } = props;
  return (
    <div className="fleet-container fleet-title total-title line-container">
      <div className="fleet-container fleet-values-title">
        {!isDataCollapsed && (
          <div>
            <div className="fleet-container fleet-values-title value">
              Válidados
            </div>
            <div className="fleet-container fleet-values-title value">
              Pendientes
            </div>
            <div className="fleet-container fleet-values-title value">
              Rechazados
            </div>
            <div className="fleet-container fleet-values-title value">
              Incompleto
            </div>
            <div className="fleet-container fleet-values-title value">
              Vencido
            </div>
          </div>
        )}
        <div className="fleet-container fleet-values-title total">Total</div>
      </div>

      {/* <div className="line" style={{ marginTop: "30px" }} /> */}
    </div>
  );
};

export default FleetDetailTitle;
