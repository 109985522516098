import * as React from "react";
import TextInput from "../../shared/controls/text-input";
import BaseDialog from "../../shared/dialog/base-dialog";
import SVGIcon, { icons } from "../../shared/svg-icon";
import ModalSelectInput from "../shipment/modal-select-input";
import Checkbox from '../../shared/controls/checkbox';

const AvailabilityModal = ({
  title,
  show,
  onChange,
  data,
  facilities,
  date,
  facility,
  isLoading
}) => {
  return (
    <BaseDialog
      open={show}
      title={title ? title : "Editar disponibilidad"}
      message="¿Estás seguro que deseas reportar este ensamble como disponible?"
      acceptText="Confirmar"
      cancelText="Cancelar"
      showCancel={true}
      cancelButtonType="outline dark primary"
      acceptButtonType="primary"
      disabledAccept={isLoading === true}
      acceptAction={() =>
        onChange({
          target: {
            name: "saveAvailability",
            value: true,
          },
        })
      }
      closeAction={(a) => onChange(a)}
      contentObject={
        <ModalContent
          {...data}
          onChange={onChange}
          facilities={facilities}
          date={date}
          facility={facility}
          // appointmentAction={appointmentAction}
          // reasonSelect={reasonSelect}
        />
      }
      style={{ content: { width: "850px", marginLeft: "-425px", overflow: "visible"/* height: "570px", top: "20px" */ } }}
      //   disabledAccept: PropTypes.bool,
    />
  );
};
export default AvailabilityModal;

const ModalContent = (props) => {
  return (
    <div
      style={{
        marginTop: "10px"
      }}
    >
      <div 
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "20px 0"
        }}
      >
        <div>
          <div style={{color: "#20293d99", textAlign: "center"}}>
            Vehículo:
          </div>
          <div style={{fontWeight: "bold", fontSize: "14px"}}>
            {
              (props.resources || [])
                .filter(r => r.type === 1)
                .map(r => (<div>{r.longDescription}</div>))
            }
          </div>
        </div>
        <div>
          <div style={{color: "#20293d99", textAlign: "center"}}>
            Equipos:
          </div>
          <div style={{fontWeight: "bold", fontSize: "14px"}}>
            {
              (props.resources || [])
                .filter(r => r.type === 2)
                .map(r => (<div>{r.longDescription}</div>))
            }
          </div>
        </div>
        <div>
          <div style={{color: "#20293d99", textAlign: "center"}}>
            Operadores:
          </div>
          <div style={{fontWeight: "bold", fontSize: "14px"}}>
            {
              (props.resources || [])
                .filter(r => r.type === 3)
                .map(r => (<div>{r.longDescription}</div>))
            }
          </div>
        </div>
      </div>
      {
        props.inserted && 
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            <div>
              Última actualización de disponibilidad:
            </div>
            <div style={{fontSize: "14px"}}>
              {(new Date(props.inserted)).toLocaleString("en-GB")}
            </div>
          </div>
      }
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <div
            style={{width: "45%"}}
          >
            <TextInput
              type="date"
              format="dd/MMM/yyyy HH:mm"
              minDate={new Date()}
              // maxDate={this.state.selectedFleetExemptionEnd || null}
              value={props.date}
              dateAdjust={false}
              label="Fecha y hora de disponibilidad"
              name="currentAvailabilityDate"
              onChange={props.onChange}
              suffix={{
                element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
              }}
              showTimeSelect={true}
              customHeader={false}
              timeValueFormat={"US_HOURLY_FORMAT"}
              // filterDate={this.filterBusyDates}
              // selectsStart={true}
              // startDate={this.state.selectedFleetExemptionStart}
              // endDate={this.state.selectedFleetExemptionEnd}
            />
          </div>
          <div
            style={{width: "45%", display: "flex", alignItems: "center", position: "relative"}}
          >
            <div
              style={{
                position: "absolute",
                top: "14px",
                left: "0",
                opacity: "0.6",
                color: "#9ba1ac",
                fontSize: "14px",
                transform: "scale(0.8)",
                transformOrigin: "0 0"
              }}
            >
              Ubicación de disponibilidad
            </div>
            <ModalSelectInput
              isSearchable={true}
              defaultValue={props.facilities.find(f => f.value.toString() === props.facility.toString())}
              onChange={(a) =>
                props.onChange({
                  target: { name: "facilitySelect", value: a.value },
                })
              }
              name="facilitySelect"
              options={props.facilities}
            />
          </div>
        </div>

        <Checkbox
          onChange={(e) => {
            props.onChange({
              target: { name: "sendEmailConfirmation", value: !props.sendEmailConfirmation },
            })
          }}
          item={{
            description: "Enviar correo de confirmación",
            active: props.sendEmailConfirmation,
            id: 0,
          }}
          name="sendEmail"
          active={props.sendEmailConfirmation}
        />
        
      </div>
    </div>
  );
};