import React from "react";
const FavMarker = (props) => {
  return (
    <svg 
      width="14" 
      height="20" 
      viewBox="0 0 14 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.39456 13.8792L1 17.9828V1H13V17.9828L7.60544 13.8792L7 13.4186L6.39456 13.8792Z" stroke="#3D77F7" stroke-width="2"/>
    </svg>
  );
};

export default FavMarker;