//@flow
import * as React from "react";
import styled from "styled-components";

type Props = {
  title: string,
  //   checked: boolean,
  selected: number | string,
  value: number | string,
  name: string,
};

const RadioInput = (props: Props) => {
  return (
    <Container className={props.className}>
      {props.title}
      <RadioInputStyled {...props} type="radio"></RadioInputStyled>
      <Check
        checked={props.selected.toString() === props.value.toString()}
      ></Check>
    </Container>
  );
};
export default RadioInput;

const Container: React.ComponentType<{}> = styled.label`
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;

  color: #20293d;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const RadioInputStyled: React.ComponentType<{}> = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
const Check: React.ComponentType<{ checked: boolean }> = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  box-sizing: border-box;

  background-color: ${({ checked }) => (checked ? "#2196F3" : "#eee")};
  border-radius: 50%;
  ${({ checked }) => !checked && "border: 1px solid #6C7C9F;"}

  &:after {
    content: "";
    top: 2px;
    left: 2px;
    transform: translate(50%, 50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
  }
`;
