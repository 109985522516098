import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

class SelectOptionDialog extends React.Component {
  handleClose = (result) => {
    this.props.closeAction(result);
  }
  handleAccept = (result) => {
    this.props.acceptAction(result);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog select-option-dialog"
        overlayClassName="overlay"
        className={"content ".concat(this.props.class ? this.props.class : '')}
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={(result = false) => this.handleClose(result)}></div>
        <div className="message">
          {this.props.message}
          {this.props.contentObject}
        </div>
        <div className="actions">
          <Button settings={{
            text: this.props.acceptText,
            onClick: (result = true) => this.handleAccept(result)
          }} />
          {this.props.showCancel ?
            <Button settings={{
              text: this.props.cancelText,
              type: this.props.cancelButtonType || 'primary',
              onClick: (result = false) => this.handleClose(result)
            }} /> : ''
          }
        </div>
      </Modal >
    );
  }
}

export default SelectOptionDialog;


SelectOptionDialog.propTypes = {
  closeAction: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.array,
  acceptText: PropTypes.string,
  acceptAction: PropTypes.func,
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string
};

SelectOptionDialog.defaultProps = {
  closeAction: () => { return false; },
  title: '',
  message: '',
  acceptText: 'Continuar',
  acceptAction: () => { return true; },
  showCancel: false,
  cancelText: 'Cancelar',
  options: []
}

