import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";
import PropTypes from "prop-types";

Modal.setAppElement("#root");

class LoaderDialog extends React.Component {
  handleClose = result => {
    this.props.closeAction(result);
  };
  handleAccept = result => {
    this.props.acceptAction(result);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog confirm-dialog"
        overlayClassName="overlay"
        className={"content ".concat(
          this.props.class || this.props.className
            ? this.props.class || this.props.className
            : ""
        )}
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={() => this.handleClose(false)}></div>
        <div className="message">
          {this.props.message}
          {this.props.contentObject}

          <div className="progress-loader"></div>
        </div>
        <div className="actions">
          {this.props.showCancel ? (
            <Button
              settings={{
                text: this.props.cancelText,
                type: this.props.cancelButtonType || "primary",
                onClick: () => this.handleClose(false)
              }}
            />
          ) : (
            ""
          )}
          {/* <Button
            settings={{
              text: this.props.acceptText,
              disabled: this.props.disabledAccept,
              onClick: () => this.handleAccept(true)
            }}
          /> */}
        </div>
      </Modal>
    );
  }
}

export default LoaderDialog;

LoaderDialog.propTypes = {
  closeAction: PropTypes.func,
  acceptAction: PropTypes.func,
  open: PropTypes.bool.isRequired,
  class: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  contentObject: PropTypes.element,
  showCancel: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelButtonType: PropTypes.string,
  acceptText: PropTypes.string,
  disabledAccept: PropTypes.bool
};

LoaderDialog.defaultProps = {
  closeAction: () => {
    return false;
  },
  title: "",
  message: "",
  acceptText: "Continuar",
  acceptAction: () => {
    return true;
  },
  showCancel: false,
  cancelText: "Cancelar",
  disabledAccept: false,
  class: "",
  className: ""
};
