import React, { Component } from "react";
import Modal from "react-modal";
import Button from "../../../shared/controls/button";

class ConflictDetailModal extends Component {
  state = {
    begin: 0,
    end: 0,
    parsedOrders: []
  }

  componentDidMount() {
    let parsedValues = this.getParsedValues();
    this.setState({
      begin: parsedValues.begin,
      end: parsedValues.end,
      parsedOrders: parsedValues.parsedOrders
    })
  }

  getParsedValues = () => {
    let begin = this.setTimetoPercent(this.props.data?.begin ?? 0);
    let end = this.setTimetoPercent(this.props.data?.end ?? 0);
    let parsedOrders = (this.props.data?.orders || []).map(o => ({
      ...o,
      begin: this.setTimetoPercent(o?.begin ?? 0),
      end: this.setTimetoPercent(o?.end ?? 0),
    }))
    return {
      begin,
      end,
      parsedOrders
    }
  }
  
  setTimetoPercent = value => {
    return parseInt(value / 100) * 100 + (Math.min(value % 100, 59) * 100) / 60
  }

  getTimeFromPercent = (percent) => {
    let hour = parseInt(percent / 100);
    let minute = parseInt(60 * (percent % 100) / 100)

    return `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`
  }

  getConflictRow = (o) => {
    return <div className="conflict-row">
      <div className="orders">
        {o.codeText}
      </div>
      <div className="truck">
        <div>{o.icon}</div>
        <div className="plates">{o.plates}</div>
      </div>
      <div className="timeline">
        <div 
          className="conflict-bar"
          style={{
            width: `${this.getRelativeWidth(o.begin, o.end, this.state.begin, this.state.end)}%`,
            left: `${this.getRelativeWidth(this.state.begin, o.begin, this.state.begin, this.state.end)}%`,
          }}
        ></div>
        {
          this.getHourLines(this.state.begin, this.state.end, false)
        }
        {
          this.getHourLines(this.state.begin, this.state.end, false, 25)
        }
      </div>
      <div className="table-actions">
          <Button
            type="primary small"
            text={"Ver detalle"}
            onClick={() => {this.props.showDetail(o)}}
            // disabled={true} 
          />
      </div>
    </div>
  }

  getRelativeWidth = (relativeBegin, relativeEnd, absBegin, absEnd) => {
    let relativeEndFix = relativeEnd > absEnd ? absEnd : relativeEnd;
    return 100 * (relativeEndFix - relativeBegin) / (absEnd - absBegin);
  }

  getHourLines = (begin, end, isHeader, fraction = 100) => {
    let result = [];
    const endCentena = parseInt(end/fraction);
    const beginCentena = parseInt(begin/fraction);
    const linesQty = endCentena - beginCentena;
    if(linesQty === 0) return result;
    const appWidth = end - begin;
    for(let i = beginCentena*fraction + fraction; i < end; i += fraction){
      let lineFractionWidth = i - begin;
      let pct = 100 * lineFractionWidth / appWidth;
      result.push({
        time: i,
        pct
      })
    }
    return result.map(line => (
      <div 
        style={{
          position: "absolute",
          height: isHeader ? "100%" : "calc(100% + 5px)",
          borderLeft: `${fraction === 100 ? '0.1rem solid' : '1px dashed'} #979797`,
          left: line.pct + '%',
          top: isHeader ? '' : '-5px'
        }}
      >{isHeader && fraction === 100 ? this.getTimeFromPercent(line.time) : ''}</div>
    ));
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        portalClassName="dialog confirm-dialog"
        overlayClassName="overlay"
        className="content conflict-modal"
      >
        <div className="title">Resolución de conflictos</div>
        <div className="close" onClick={this.props.closeModal}></div>
        <div className="message">
          <div className="header">
            <div>
              {`${this.props.date} ${this.getTimeFromPercent(this.state.begin)}`}
            </div>
            <div>
              {`Conflictos: `}<b>{this.state.parsedOrders.length}</b>
            </div>
          </div>
          <div className="conflict-table">
            <div className="table-header">
              <div className="orders">
                Orden
              </div>
              <div className="truck">
                Camion
              </div>
              <div className="timeline">
                <div className="time-begin">{this.getTimeFromPercent(this.state.begin)}</div>
                <div className="time-end">{this.getTimeFromPercent(this.state.end)}</div>
                {
                  this.getHourLines(this.state.begin, this.state.end, true)
                }
                {
                  this.getHourLines(this.state.begin, this.state.end, true, 25)
                }
              </div>
              <div className="table-actions">

              </div>
            </div>
            <div className="table-body">
              {
                this.state.parsedOrders.map(o => this.getConflictRow(o))
              }
            </div>
          </div>
        </div>
        <div className="actions">
          <Button
            settings={{
              text: "Cancelar",
              type: "primary",
              onClick: this.props.closeModal,
            }}
          />
        </div>
      </Modal>  
    )
  }
}

export default ConflictDetailModal;