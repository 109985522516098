import { BACKEND, getApiUrl, postApiUrl, deleteApiUrl, patchMultipartApiUrl, patchApiUrl } from '../../shared/backend-api';
import store from '../store';

export const REQUEST_NEW_PRODUCT = 'REQUEST_NEW_PRODUCT';
export const RECEIVE_NEW_PRODUCT = 'RECEIVE_NEW_PRODUCT';
export const ERROR_NEW_PRODUCT = 'ERROR_NEW_PRODUCT';

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
export const ERROR_PRODUCT = 'ERROR_PRODUCT';

export const REQUEST_PRODUCT_IMAGE = 'REQUEST_PRODUCT_IMAGE';
export const RECEIVE_PRODUCT_IMAGE = 'RECEIVE_PRODUCT_IMAGE';
export const ERROR_PRODUCT_IMAGE = 'ERROR_PRODUCT_IMAGE';

export const REQUEST_PRODUCTS_FILTER = 'REQUEST_PRODUCTS_FILTER';
export const RECEIVE_PRODUCTS_FILTER = 'RECEIVE_PRODUCTS_FILTER';
export const ERROR_PRODUCTS_FILTER = 'ERROR_PRODUCTS_FILTER';

export const REQUEST_UNITS_CATALOG = 'REQUEST_UNITS_CATALOG';
export const RECEIVE_UNITS_CATALOG = 'RECEIVE_UNITS_CATALOG';
export const ERROR_UNITS_CATALOG = 'ERROR_UNITS_CATALOG';

const requestProduct = () => ({
  type: REQUEST_PRODUCT
});
const receiveProduct = (json) => ({
  type: RECEIVE_PRODUCT,
  data: json
});
const errorProduct = (error) => ({
  type: ERROR_PRODUCT,
  error: error
});

const requestProductImage = () => ({
  type: REQUEST_PRODUCT_IMAGE
});
const receiveProductImage = (json) => ({
  type: RECEIVE_PRODUCT_IMAGE,
  data: json
});
const errorProductImage = (error) => ({
  type: ERROR_PRODUCT_IMAGE,
  error: error
});

const requestNewProduct = () => ({
  type: REQUEST_NEW_PRODUCT
});
const receiveNewProduct = (json) => ({
  type: RECEIVE_NEW_PRODUCT,
  data: json
});
const errorNewProduct = (error) => ({
  type: ERROR_NEW_PRODUCT,
  error: error
});

const requestProductsFilters = () => ({
  type: REQUEST_PRODUCTS_FILTER
});
const receiveProductsFilters = (json) => ({
  type: RECEIVE_PRODUCTS_FILTER,
  data: json
});
const errorProductsFilters = (error) => ({
  type: ERROR_PRODUCTS_FILTER,
  error: error
});

const requestUnitsCatalog = () => ({
  type: REQUEST_UNITS_CATALOG
});
const receiveUnitsCatalog= (json) => ({
  type: RECEIVE_UNITS_CATALOG,
  data: json
});
const errorUnitsCatalog = (error) => ({
  type: ERROR_UNITS_CATALOG,
  error: error
});

const PRODUCTS = {
  GET: {
    REQUEST: {
      TYPE: 'PRODUCTS_FILTER_REQUEST',
      ACTION: () => ({
        type: PRODUCTS.GET.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'PRODUCTS_FILTER_SUCCESS',
      ACTION: response => ({
        type: PRODUCTS.GET.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'PRODUCTS_FILTER_ERROR',
      ACTION: error => ({
        type: PRODUCTS.GET.ERROR.TYPE,
        error
      })
    }
  },
  CATALOG: {
    REQUEST: {
      TYPE: 'UNITS_CATALOG_REQUEST',
      ACTION: () => ({
        type: PRODUCTS.CATALOG.REQUEST.TYPE
      })
    },
    SUCCESS: {
      TYPE: 'UNITS_CATALOG_SUCCESS',
      ACTION: response => ({
        type: PRODUCTS.CATALOG.SUCCESS.TYPE,
        response
      })
    },
    ERROR: {
      TYPE: 'UNITS_CATALOG_ERROR',
      ACTION: error => ({
        type: PRODUCTS.CATALOG.ERROR.TYPE,
        error
      })
    }
  }
}

const initialState = {
  filter: {
    isFetching: false,
    data: {},
    error: ''
  },
  catalogs: {
    isFetching: false,
    error: '',
    data: []
  },
  product: {
    isFetching: false,
    error: '',
    data: {}
  },
  productImage: {
    isFetching: false,
    error: '',
    data: {}
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_PRODUCT_IMAGE:
      return Object.assign({}, state, {
        productImage: {
          isFetching: true
        }
      })
    case RECEIVE_PRODUCT_IMAGE:
      return Object.assign({}, state, {
        productImage: {
          isFetching: false,
          data: action.data
        }
      })
    case ERROR_PRODUCT_IMAGE:
      return Object.assign({}, state, {
        productImage: {
          isFetching: false,
          error: action.error
        }
      })
    case REQUEST_NEW_PRODUCT:
      return Object.assign({}, state, {
        product: {
          isFetching: true
        }
      })
    case RECEIVE_NEW_PRODUCT:
      return Object.assign({}, state, {
        product: {
          isFetching: false,
          data: action.data
        }
      })
    case ERROR_NEW_PRODUCT:
      return Object.assign({}, state, {
        product: {
          isFetching: false,
          error: action.error
        }
      })
    case REQUEST_PRODUCTS_FILTER:
      return Object.assign({}, state, {
        filter: {
          isFetching: true
        }
      })
    case RECEIVE_PRODUCTS_FILTER:
      return Object.assign({}, state, {
        filter: {
          isFetching: false,
          data: action.data
        }
      })
    case ERROR_PRODUCTS_FILTER:
      return Object.assign({}, state, {
        filter: {
          isFetching: false,
          error: action.error
        }
      })
    case REQUEST_UNITS_CATALOG:
      return Object.assign({}, state, {
        catalogs: {
          isFetching: true
        }
      })
    case RECEIVE_UNITS_CATALOG:
      return Object.assign({}, state, {
        catalogs: {
          isFetching: false,
          data: action.data
        }
      })
    case ERROR_UNITS_CATALOG:
      return Object.assign({}, state, {
        catalogs: {
          isFetching: false,
          error: action.error
        }
      })
    default: {
      return state;
    }
  }
}

export function loadProductsFilters() { 
  let companyId = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestProductsFilters())
    return getApiUrl(BACKEND.PRODUCTS.FILTERS, { company_id: companyId })
      .then(
        response => response.json(),
        error => dispatch(errorProductsFilters(error))
      )
      .then(
        data => dispatch(receiveProductsFilters(data))
      )
  }
}

export function loadProductsCatalogs() { 
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestUnitsCatalog())
    return getApiUrl(BACKEND.PRODUCTS.UNITS_CATALOGS, {company_id})
      .then(
        response => response.json(),
        error => dispatch(errorUnitsCatalog(error))
      )
      .then(
        data => dispatch(receiveUnitsCatalog(data))
      )
  }
}

export function createNewProduct(data) { 
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestNewProduct())
    return postApiUrl(BACKEND.PRODUCTS.NEW_PRODUCT, {...data, company: company_id})
      .then(
        response => response.json(),
        // error => dispatch(errorNewProduct(error))
      )
      .then(
        data => dispatch(receiveNewProduct(data))
      )
      .catch(
        (error) => dispatch(errorNewProduct(error))
      )
  }
}

export function updateProduct(product_id, form) { 
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestProductImage())
    return patchMultipartApiUrl(BACKEND.PRODUCTS.BASE + `${company_id}/products/${product_id}`, form)
      .then(
        response => response.json(),
        // error => dispatch(errorProductImage(error))
      )
      .then(
        data => dispatch(receiveProductImage(data))
      )
      .catch(
        (error) => dispatch(errorProductImage(error))
      )
  }
}

export function updateProductData(product_id, json) { 
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(requestProductImage())
    return patchApiUrl(BACKEND.PRODUCTS.BASE + `${company_id}/products/${product_id}/`, json)
      .then(
        response => response.json(),
        // error => dispatch(errorProductImage(error))
      )
      .then(
        data => dispatch(receiveProductImage(data))
      )
      .catch(
        (error) => dispatch(errorProductImage(error))
      )
  }
}

export function getProduct(product_id) {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(requestProduct())
    return getApiUrl(BACKEND.PRODUCTS.PRODUCT, {
      company_id,
      product_id,
    })
    .then(
      response => response.json(),
      // error => dispatch(errorProduct(error))
    )
    .then(
      data => dispatch(receiveProduct(data))
    )
    .catch(
      (error) => dispatch(errorProduct(error))
    )
  };
};

export const deleteProduct = (product_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return deleteApiUrl(BACKEND.PRODUCTS.PRODUCT, {
      company_id,
      product_id,
    })
      .then((response) => response.json())
      .then((response) => true)
      .catch((error) => false);
  };
};

export const cancelTrips = (trips) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function() {
    return postApiUrl(BACKEND.PRODUCTS.BASE + `${company_id}/trips/?action=multiple_cancel_trips`, trips)
      .then((response) => response.json())
      .catch((error) => false);
  };
};