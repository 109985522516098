import React, { Component } from "react";
import styled from "styled-components";

class HelperFilesModal extends Component {

  state = {
    isOpen: false,
    hovered: -1
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target) || this.content.contains(e.target)) return;
    this.setState({ isOpen: false, saveInfo: false });
  };

  render() {
    return (
      <HelperModal>
        <img 
          ref={node => (this.node = node)}
          src="/images/menu/Help.png" 
          alt="" 
          width="20" 
          class="icon"
          style={{cursor: 'pointer', minWidth: 20, height: 20}}
          onClick= {(e) => this.setState({ isOpen: !this.state.isOpen })}  
        />

        <HelperModalContent
          ref={node => (this.content = node)}
          isOpen={this.state.isOpen}
        >
          <HelperModalScroll>
            {
              (this.props.files ?? []).map( (item, i) => {
                return(
                  <a key={i} href={item.url_to_open} target="_blank" rel="noopener noreferrer" style={{textDecorationLine: 'blink'}}>
                    <HelperModalItem
                      isHovered={this.state.hovered === i}
                      onMouseEnter={() => this.setState({ hovered: i })}
                      onMouseLeave={() => this.setState({ hovered: -1 })}
                    >
                      {item.name}
                    </HelperModalItem>
                  </a>
                );
              })
            }
          </HelperModalScroll>

        </HelperModalContent>
      </HelperModal>
    );
  }

}

export default HelperFilesModal;

export const HelperModal = styled.div`
  display: flex;
  position: relative;
  cursor: default;
`;

export const HelperModalContent = styled.div`
  background-color: white;
  position: absolute;
  right: 0px;
  top: 35px;
  border-radius: 8px;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 20px;
  width: 140px;
  max-height: 160;
  font-size: 14px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
`;

export const HelperModalItem = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 14px;
  font-weight: 100;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  background-color: ${({isHovered}) => isHovered ? '#ccc' : 'white'}
`;

export const HelperModalScroll = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
`;