import React from 'react';
import TitleArrow from '../../control/gps/title-arrow';
import MapIndicatorsUnitsRow from './map-indicators-units-row';
import ReactTooltip from 'react-tooltip';

export default class MapIndicatorsUnitsTableView extends React.Component {

  constructor(props) {
    super(props);

    const { items = [] } = props;

    this.state = {
      isLtOpen: false,
      isFacilityOpen: false,
      isClassOpen: false,
      items,
      maxElementsToShow: 50,
    };

    this.tooltips = {};

  }

  componentDidUpdate(nextProps) {

    const { items = [] } = nextProps;

    if (nextProps && items.length !== this.state.items.length) {
      this.setState({
        isLtOpen: false,
        isFacilityOpen: false,
        isClassOpen: false,
        items, maxElementsToShow: 50
      });
    }

  }

  render() {

    return (
      <div className={'map-indicators-table-view'} onScroll={this.handleScroll}>
        <div className={'map-indicators-table-view-header'}>
          { this.props.type === 3 ? this.getTransitTopTitle() : this.getTopTitle() }
        </div>
        <div className={'content-units'} >
          {this.getContent()}
        </div>
      </div>
    );
  }

  compareDates = (dateString1, dateString2) => {

    if (dateString1 === '' || dateString2 === '') { return false; }
  
    let date1 = new Date(dateString1);
    
    let limit1 = new Date(date1.getTime() - 60000);
    let limit2 = new Date(date1.getTime() + 60000);
  
    let date2 = new Date(dateString2);
    
    return (date2 >= limit1 && date2 <=limit2);
  }
  
  buildNewArray = (items) => {
    let newItemsToReturn = [];
    var uniqueValues = [...new Set(items.map(item => item.orden))];
  
    uniqueValues.map( value => {
  
      let itemsToValidate = items.filter( item => {
        return item.orden === value;
      });
  
      newItemsToReturn.push(itemsToValidate[0]);
  
      for (var i = 0; i < (itemsToValidate.length - 1); i++) {
        let firstEtaCarga = itemsToValidate[i].eta_carga || '';
        let secondEtaCarga = itemsToValidate[i + 1].eta_carga || '';
  
        let firstEtaDescarga = itemsToValidate[i].eta_descarga || '';
        let secondEtaDescarga = itemsToValidate[i + 1].eta_descarga || '';
  
        if ( (firstEtaCarga !== '' && secondEtaCarga !== '') || (firstEtaDescarga !== '' && secondEtaDescarga !== '') ) {
          if (!this.compareDates(firstEtaCarga, secondEtaCarga) || !this.compareDates(firstEtaDescarga, secondEtaDescarga)) {
            newItemsToReturn.push(itemsToValidate[i+1]);
          }
        }
  
      }
  
    });
      
    return newItemsToReturn;
  }

  getContent() {

    const { selectTruck = () => {} } = this.props;
    const { maxElementsToShow } = this.state;

    let newArray = this.props.type === 3 ? this.buildNewArray(this.props.items) : this.props.items;

    if (newArray.length > 0) {

      if (this.state.isLtOpen) {
        return newArray.slice(0, maxElementsToShow).map( (item, index) => 
          <MapIndicatorsUnitsRow 
            truck={item} 
            key={index} 
            selectTruck={ (truck) => selectTruck(truck) } 
            type={this.props.type} 
            updateTooltips={(newTooltips) => { this.tooltips = Object.assign({}, this.tooltips, newTooltips); }} 
            tooltips={this.tooltips} 
            hideTooltips={(key) => this.hideTooltips(key)}
          />
        );

      } else if (this.state.isFacilityOpen) {

        return newArray.slice(0, maxElementsToShow).map( (item, index) => 
          <MapIndicatorsUnitsRow 
            truck={item} 
            key={index} 
            selectTruck={ (truck) => selectTruck(truck) } 
            type={this.props.type} 
            updateTooltips={(newTooltips) => { this.tooltips = Object.assign({}, this.tooltips, newTooltips); }} 
            tooltips={this.tooltips}
            hideTooltips={(key) => this.hideTooltips(key)}
          />
        );

      } else if (this.state.isClassOpen) {

        return newArray.slice(0, maxElementsToShow).map( (item, index) => 
          <MapIndicatorsUnitsRow 
            truck={item} 
            key={index} 
            selectTruck={ (truck) => selectTruck(truck) } 
            type={this.props.type} 
            updateTooltips={(newTooltips) => { this.tooltips = Object.assign({}, this.tooltips, newTooltips); }} 
            tooltips={this.tooltips}
            hideTooltips={(key) => this.hideTooltips(key)}
          />
        );

      } else {
        return newArray.slice(0, maxElementsToShow).map( (item, index) => 
          <MapIndicatorsUnitsRow 
            truck={{...item, key: index}} ç
            type={this.props.type} 
            key={index} 
            selectTruck={ (truck) => selectTruck(truck) } maneuver={this.props.maneuver} 
            updateTooltips={(newTooltips) => { this.tooltips = Object.assign({}, this.tooltips, newTooltips); }} 
            tooltips={this.tooltips}
            hideTooltips={(key) => this.hideTooltips(key)}
          />
        );
      }

    } else {
      return [];
    }
  }

  hideTooltips = (tooltipId) => {
    for (var key in this.tooltips) {
      if (this.tooltips.hasOwnProperty(key) && key !== tooltipId) {           
        ReactTooltip.hide(this.tooltips[key]);
      }
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 1;
    if (bottom) { 
      this.setState({maxElementsToShow: this.state.maxElementsToShow + 50});
    }
  }

  getTopTitle() {
    return (
      <div className={'map-indicators-table-top-title'}>

        <div style={{
          height: 50,
          width: '10%',
        }}>
          <TitleArrow
            text={'LT'}
            isOpen={this.state.isLtOpen}
            showArrow={false}
            onClick={(value) => this.setState({isLtOpen: value, isFacilityOpen: false, isClassOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '26.3%',
        }}>
          <TitleArrow
            text={'Unidad'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '26.3%',
        }}>
          <TitleArrow
            text={'Instalación'}
            isOpen={this.state.isFacilityOpen}
            showArrow={false}
            onClick={(value) => this.setState({isFacilityOpen: value, isClassOpen: false, isLtOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '22%',
        }}>
          <TitleArrow
            text={'Clase'}
            isOpen={this.state.isClassOpen}
            showArrow={false}
            onClick={(value) => this.setState({isClassOpen: value, isLtOpen: false, isFacilityOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '15.1%',
        }}>
          <TitleArrow
            text={this.props.type === 3 ? 'ETA' : this.props.type === 2 ? 'Estadía' : 'Duración'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

      </div>
    );
  }

  getTransitTopTitle() {
    return (
      <div className={'map-indicators-table-top-title'}>

        <div style={{
          height: 50,
          width: '10%',
        }}>
          <TitleArrow
            text={'LT'}
            isOpen={this.state.isLtOpen}
            showArrow={false}
            onClick={(value) => this.setState({isLtOpen: value, isFacilityOpen: false, isClassOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '15.5%',
        }}>
          <TitleArrow
            text={'Unidad'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '19.5%',
        }}>
          <TitleArrow
            text={'Instalación'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '14.5%',
        }}>
          <TitleArrow
            text={'Clase'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '18.5%',
        }}>
          <TitleArrow
            text={'Tránsito'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '20.5%',
        }}>
          <TitleArrow
            text={'Adherencia'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

      </div>
    );
  }

}