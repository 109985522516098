import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import Panel from "../../shared/component/panel";
import Button from "../../shared/controls/button";
import FleetInfoHeader from "../../shared/component/fleet/fleet-info-header";
import FleetInfoHeaderSubSection from "../../shared/component/fleet/fleet-info-header-subsection";
import FleetInfoData from "../../shared/component/fleet/fleet-info-data";
import { connect } from "react-redux";
import { getFleetSummary } from "../../redux/reducers/fleet-reducer";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "../../shared/backend-api";
import { removeNavbarAction, setNavbarAction } from '../../redux/reducers/navbar-reducer';
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setConfig } from "../../redux/reducers/configuration-reducer";
import styled from "styled-components";
import { isPremiumSubscriptionCo } from "../../shared/utils";

const { files } = getUrlsEnv();

class FleetSummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFreeSubscription: false
    };
  }

  componentDidMount() {

    if (this.isCO() && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.props.getFleetSummary();
    this.props.setNavbarAction("fleet-catalog", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("fleet-catalog");
  }

  componentWillUnmount() {
    if (this.props.configuration && this.props.configuration.base && this.props.configuration.base.searchStr) {
      let config = this.props.configuration;
      config.base.searchStr = '';

      this.props.setBaseConfig(config);
    }
  }

  buildData = () => {
    let operators = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let vehicles = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let trailers = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let dataFleets = {};

    // if (this.props.companyType === 2) {
    dataFleets = this.props.summaryFleets.filter((elem) => {
      return elem.group !== 3;
    });
    // } else {
    //   dataFleets = this.props.summaryFleets;
    // }

    dataFleets.forEach((data) => {
      const { status } = data;

      status.forEach((s) => {
        switch (s.description) {
          case "Validado":
            vehicles.valids += s.count[0];
            trailers.valids += s.count[1];
            operators.valids += s.count[2];
            break;
          case "Pendiente":
            vehicles.pendings += s.count[0];
            trailers.pendings += s.count[1];
            operators.pendings += s.count[2];
            break;
          case "Rechazado":
            vehicles.rejecteds += s.count[0];
            trailers.rejecteds += s.count[1];
            operators.rejecteds += s.count[2];
            break;
          case "Incompleto":
            vehicles.incomplete += s.count[0];
            trailers.incomplete += s.count[1];
            operators.incomplete += s.count[2];
            break;
          case "Vencido":
            vehicles.expired += s.count[0];
            trailers.expired += s.count[1];
            operators.expired += s.count[2];
            break;

          default:
            break;
        }
      });
    });

    return { operators, vehicles, trailers };
  };

  buildDataSubSection = (dataFleets) => {
    let operators = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let vehicles = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let trailers = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    dataFleets.forEach((data) => {
      const { status } = data;

      status.forEach((s) => {
        switch (s.description) {
          case "Validado":
            vehicles.valids += s.count[0];
            trailers.valids += s.count[1];
            operators.valids += s.count[2];
            break;
          case "Pendiente":
            vehicles.pendings += s.count[0];
            trailers.pendings += s.count[1];
            operators.pendings += s.count[2];
            break;
          case "Rechazado":
            vehicles.rejecteds += s.count[0];
            trailers.rejecteds += s.count[1];
            operators.rejecteds += s.count[2];
            break;
          case "Incompleto":
            vehicles.incomplete += s.count[0];
            trailers.incomplete += s.count[1];
            operators.incomplete += s.count[2];
            break;
          case "Vencido":
            vehicles.expired += s.count[0];
            trailers.expired += s.count[1];
            operators.expired += s.count[2];
            break;

          default:
            break;
        }
      });
    });

    return { operators, vehicles, trailers };
  };

  buildDataByCompany = (data) => {
    const { id, name, logo, status } = data;

    let operators = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let vehicles = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    let trailers = {
      valids: 0,
      pendings: 0,
      rejecteds: 0,
      incomplete: 0,
      expired: 0,
    };

    status.forEach((s) => {
      switch (s.description) {
        case "Validado":
          vehicles.valids += s.count[0];
          trailers.valids += s.count[1];
          operators.valids += s.count[2];
          break;
        case "Pendiente":
          vehicles.pendings += s.count[0];
          trailers.pendings += s.count[1];
          operators.pendings += s.count[2];
          break;
        case "Rechazado":
          vehicles.rejecteds += s.count[0];
          trailers.rejecteds += s.count[1];
          operators.rejecteds += s.count[2];
          break;

        case "Incompleto":
          vehicles.incomplete += s.count[0];
          trailers.incomplete += s.count[1];
          operators.incomplete += s.count[2];
          break;

        case "Vencido":
          vehicles.expired += s.count[0];
          trailers.expired += s.count[1];
          operators.expired += s.count[2];
          break;

        default:
          break;
      }
    });

    return {
      id,
      companyName: name || "",
      logoUrl: logo
        ? `${files}${logo}`
        : "/images/factory-bckgrnd.png",
      operators,
      vehicles,
      trailers,
    };
  };

  getFleets = (type) => {
    let data = this.props.summaryFleets.filter((elem) => {
      return elem.group === type;
    });

    return {
      count: Object.values(data).length,
      data: data,
    };
  };

  isCO = () => { return `${this.props.companyType}` !== '1'; }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent = () => {

    let own = this.getFleets(1) || {};
    let shared = this.getFleets(2) || {};
    let sharedThird = this.getFleets(3) || {};

    return (
      <div className="fleet-summary view">
        <div className="title">Resumen de Flotilla</div>
        <div className="content">
          <Panel number="" title="">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div className="action" style={{ marginBottom: "30px" }}>
                <Button
                  settings={{
                    type: "outline primary white",
                    text: "Ir a catálogo",
                    onClick: () => {
                      this.props.history.push("/company/fleet");
                    },
                  }}
                />
              </div>
            </div>

            {/* <div className='fleet-summary-panel-header'>
              <div className='column'>
                
              </div>
              <div className='column'>
                <SVGIcon name={icons.user} fill='#AFC8FF' width='26px' height='24px' viewBox='0 0 26 26' />
                Operadores
              </div>
              <div className='column'>
                <SVGIcon name={icons.truck} fill='#AFC8FF' width='36px' height='26px' viewBox='0 0 18 18' />
                Vehículos
              </div>
              <div className='column'>
                <SVGIcon name={icons.equipment} fill='#AFC8FF' width='26px' height='26px' />
                Remolques
              </div>
              
            </div> */}

            {/* <FleetSummaryRow /> */}

            <FleetInfoHeader data={this.buildData()} showData={!this.isCO()}/>

            {
              this.isCO() ? <div/> :
              Object.values(own).length && own.count ? (
                <React.Fragment>
                  <div className="subtitle-fleet">Mi Flotilla Propia</div>
                  {own.data.map((data, index) => (
                    <React.Fragment>
                      <FleetInfoData
                        data={this.buildDataByCompany(data)}
                        key={index}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ) : (
                ""
              )
            }

            {Object.values(shared).length && shared.count ? (
              <React.Fragment>
                <div className="subtitle-fleet separate">
                  Flotilla compartida con mi empresa {shared.count} Empresas
                </div>
                <FleetInfoHeaderSubSection
                  data={this.buildDataSubSection(shared.data)}
                />
                {shared.data.map((data, index) => (
                  <React.Fragment>
                    <FleetInfoData
                      data={this.buildDataByCompany(data)}
                      key={index}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              ""
            )}

            {Object.values(sharedThird).length && sharedThird.count ? (
              <React.Fragment>
                <div className="subtitle-fleet separate">
                  Flotilla propia compartida con terceros {sharedThird.count}{" "}
                  Empresas
                </div>
                <FleetInfoHeaderSubSection
                  data={this.buildDataSubSection(sharedThird.data)}
                />
                {sharedThird.data.map((data, index) => (
                  <React.Fragment>
                    <FleetInfoData
                      data={this.buildDataByCompany(data)}
                      key={index}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              ""
            )}
          </Panel>
        </div>
      </div>
    );

  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {

  const configuration = {
    base: state.configuration.FleetCatalogBase,
    1: state.configuration.FleetCatalog1,
    2: state.configuration.FleetCatalog2,
    3: state.configuration.FleetCatalog3,
  };

  return {
    configuration,
    summaryFleets: state.fleetReducer.summary.data || [],
    companyType: state.globalCatalog.session.company.type.id || 0,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    setBaseConfig: (config) => dispatch(setConfig("FleetCatalogBase", config)),
    getFleetSummary: () => dispatch(getFleetSummary()),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FleetSummaryView));

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;