import { BACKEND, getApiUrl, postMultipartApiUrl, putApiUrlTemp, patchMultipartApiUrl, postApiUrlWithoutAuthorization, getApiUrlWithoutAuthorization } from '../../shared/backend-api';

const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESPONSE_UPDATE_PASSWORD = 'RESPONSE_UPDATE_PASSWORD';
export const ERROR_UPDATE_PASSWORD = 'ERROR_UPDATE_PASSWORD';

const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_RESPONSE = 'CREATE_USER_RESPONSE'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

const ASSIGN_USER_REQUEST = 'ASSIGN_USER_REQUEST'
const ASSIGN_USER_RESPONSE = 'ASSIGN_USER_RESPONSE'
const ASSIGN_USER_ERROR = 'ASSIGN_USER_ERROR'

const GET_USER_REQUEST = 'GET_USER_REQUEST'
const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
const GET_USER_ERROR = 'GET_USER_ERROR'

const VALIDATE_USERNAME_REQUEST = 'VALIDATE_USERNAME_REQUEST';
const VALIDATE_USERNAME_ERROR = 'VALIDATE_USERNAME_ERROR';
const VALIDATE_USERNAME_RESPONSE = 'VALIDATE_USERNAME_RESPONSE';

const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE'
const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'

const VALIDATION_USER_REQUEST = 'VALIDATION_USER_REQUEST'
const VALIDATION_USER_RESPONSE = 'VALIDATION_USER_RESPONSE'
const VALIDATION_USER_ERROR = 'VALIDATION_USER_ERROR'

const VALIDATION_EMAIL_REQUEST = 'VALIDATION_EMAIL_REQUEST'
const VALIDATION_EMAIL_RESPONSE = 'VALIDATION_EMAIL_RESPONSE'
const VALIDATION_EMAIL_ERROR = 'VALIDATION_EMAIL_ERROR'

const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
const CREATE_ACCOUNT_RESPONSE = 'CREATE_ACCOUNT_RESPONSE'
const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR'

const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE'
const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

const resetNewPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST
});

const resetNewPasswordResponse = (json) => ({
    type: RESET_PASSWORD_RESPONSE,
    data: json
});

const resetNewPasswordError= error => ({
    type: RESET_PASSWORD_ERROR,
    error
});

const createAccount = () => ({
    type: CREATE_ACCOUNT_REQUEST
});

const responseCreateAccount = (json) => ({
    type: CREATE_ACCOUNT_RESPONSE,
    data: json
});

const errorCreateAccount= error => ({
    type: CREATE_ACCOUNT_ERROR,
    error
});

const validateEmail = () => ({
    type: VALIDATION_EMAIL_REQUEST
});

const responseValidateEmail = (json) => ({
    type: VALIDATION_EMAIL_RESPONSE,
    data: json
});

const errorValidateEmail= error => ({
    type: VALIDATION_EMAIL_ERROR,
    error
});

const loginAction = () => ({
    type: LOGIN_USER_REQUEST
});

const responseloginUser = (json) => ({
    type: LOGIN_USER_RESPONSE,
    data: json
});

const errorLoginUser= error => ({
    type: LOGIN_USER_ERROR,
    error
});

const getValidationUser = () => ({
    type: VALIDATION_USER_REQUEST
});

const responseGetValidationUser = (json) => ({
    type: VALIDATION_USER_RESPONSE,
    data: json
});

const errorGetValidationUser= error => ({
    type: VALIDATION_USER_ERROR,
    error
});

const updatePasswordAction = () => ({
    type: UPDATE_PASSWORD
});

const responseUpdatePassword = (json) => ({
    type: RESPONSE_UPDATE_PASSWORD,
    data: json
});

const errorUpdatePassword = error => ({
    type: ERROR_UPDATE_PASSWORD,
    error
});

const getUserRequest = () => ({
    type: GET_USER_REQUEST
});

const getUserResponse = (json) => ({
    type: GET_USER_SUCCESS,
    data: json
});

const getUserError = (json) => ({
    type: GET_USER_ERROR,
    data: json
});

const createUserRequest = () => ({
    type: CREATE_USER_REQUEST
});

const createUserResponse = (json) => ({
    type: CREATE_USER_RESPONSE,
    data: json
});

const createUserError = (json) => ({
    type: CREATE_USER_ERROR,
    data: json
});

const assignUserRequest = () => ({
    type: ASSIGN_USER_REQUEST
});

const assignUserResponse = (json) => ({
    type: ASSIGN_USER_RESPONSE,
    data: json
});

const assignUserError = (json) => ({
    type: ASSIGN_USER_ERROR,
    data: json
});

const validateUsernameRequest = () => ({
    type: VALIDATE_USERNAME_REQUEST
  });
  
const validateUsernameError = error => ({
    type: VALIDATE_USERNAME_ERROR,
    error
});

const validateUsernameResponse = response => ({
    type: VALIDATE_USERNAME_RESPONSE,
    editCompany: response
});

export const initialState = {
    isFetching: false,
    data: null,
    error: null,
    login: {
        isFetching: false,
        data: null,
        error: null,
    },
    userValidation: {
        isFetching: false,
        data: null,
        error: null,
    },
    emailValidation: {
        isFetching: false,
        data: null,
        error: null,
    },
    account: {
        isFetching: false,
        data: null,
        error: null,
    },
    resetNewPassword: {
        isFetching: false,
        data: null,
        error: null,
    }
};

export default function (state = initialState, action) {

    switch (action.type) {

        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetNewPassword: {
                    isFetching: true,
                    data: null,
                    error: null
                }
            };

        case RESET_PASSWORD_RESPONSE:
            return {
                ...state,
                resetNewPassword: {
                    isFetching: false,
                    data: action.data,
                    error: null
                }
            };

        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetNewPassword: {
                    isFetching: false,
                    data: null,
                    error: action.error
                }
            };

        case CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                account: {
                    isFetching: true,
                    data: null,
                    error: null
                }
            };

        case CREATE_ACCOUNT_RESPONSE:
            return {
                ...state,
                account: {
                    isFetching: false,
                    data: action.data,
                    error: null
                }
            };

        case CREATE_ACCOUNT_ERROR:
            return {
                ...state,
                account: {
                    isFetching: false,
                    data: null,
                    error: action.error
                }
            };

        case VALIDATION_USER_REQUEST:
            return {
                ...state,
                userValidation: {
                    isFetching: true,
                    data: null,
                    error: null
                }
            };

        case VALIDATION_USER_RESPONSE:
            return {
                ...state,
                userValidation: {
                    isFetching: false,
                    data: action.data,
                    error: null
                }
            };

        case VALIDATION_USER_ERROR:
            return {
                ...state,
                userValidation: {
                    isFetching: false,
                    data: null,
                    error: action.error
                }
            };

        case VALIDATION_EMAIL_REQUEST:
            return {
                ...state,
                emailValidation: {
                    isFetching: true,
                    data: null,
                    error: null
                }
            };

        case VALIDATION_EMAIL_RESPONSE:
            return {
                ...state,
                emailValidation: {
                    isFetching: false,
                    data: action.data,
                    error: null
                }
            };

        case VALIDATION_EMAIL_ERROR:
            return {
                ...state,
                emailValidation: {
                    isFetching: false,
                    data: null,
                    error: action.error
                }
            };

        case LOGIN_USER_REQUEST:
            return {
                ...state,
                login: {
                    isFetching: true,
                    data: null,
                    error: null
                }
            };

        case LOGIN_USER_RESPONSE:
            return {
                ...state,
                login: {
                    isFetching: false,
                    data: action.data,
                    error: null
                }
            };

        case LOGIN_USER_ERROR:
            return {
                ...state,
                login: {
                    isFetching: false,
                    data: null,
                    error: action.error
                }
            };

        case UPDATE_PASSWORD: 

            return {
                ...state,
                isFetching: true
            };

        case RESPONSE_UPDATE_PASSWORD:

            return {
                ...state,
                data: action.data,
                isFetching: false,
                error: null
            };

        case ERROR_UPDATE_PASSWORD:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                data: null
            }

        case CREATE_USER_REQUEST: 

            return {
                ...state,
                isFetching: true
            };

        case CREATE_USER_RESPONSE:

            return {
                ...state,
                data: action.data,
                isFetching: false,
                error: null
            };

        case CREATE_USER_ERROR:

            return {
                ...state,
                isFetching: false,
                error: action.data,
                data: null
            }

        case ASSIGN_USER_REQUEST: 

            return {
                ...state,
                isFetching: true
            };

        case ASSIGN_USER_RESPONSE:

            return {
                ...state,
                data: action.data,
                isFetching: false,
                error: null
            };

        case ASSIGN_USER_ERROR:

            return {
                ...state,
                isFetching: false,
                error: action.data,
                data: null
            }

        case VALIDATE_USERNAME_REQUEST: 

            return {
                ...state,
                isFetching: true
            };

        case VALIDATE_USERNAME_RESPONSE:

            return {
                ...state,
                validate: action.data
            };

        case VALIDATE_USERNAME_ERROR:

            return {
                ...state,
                error: action.data
            }

        default: return state;

    }

}

export const updatePassword = (params) => {
    return function (dispatch) {
      dispatch(updatePasswordAction);
      return putApiUrlTemp(BACKEND.PUBLIC.UPDATE_PASSWORD, {
        current_password: params.current_password,
        password: params.password
      })
      .then(response => response.json())
      .then(response => dispatch(responseUpdatePassword(response)))
      .catch(error => error.then(e => dispatch(errorUpdatePassword(e))))
    };
  };

export const createUserMobile = objUser => {
    return function (dispatch) {
        dispatch(createUserRequest);
        let formData = new FormData();
        formData.append('first_name', objUser.first_name)
        formData.append('last_name', objUser.last_name)
        formData.append('username', objUser.username)
        formData.append('password', objUser.password)
        formData.append('visible', objUser.visible)
        if (objUser.profile_image && !String(objUser.profile_image).includes("http")) {
            formData.append('profile_image', objUser.profile_image)
        }
        return postMultipartApiUrl(BACKEND.USERS.MOBILE.POST, formData)
        .then(response => response.json())
        .then(response => dispatch(createUserResponse(response)))
        .catch(error => error.then(e => dispatch(createUserError(e))))

    };
};

export const updateUserMobile = (idUser, objUser) => {
    return function (dispatch) {
        dispatch(createUserRequest);
        let formData = new FormData();
        formData.append('first_name', objUser.first_name)
        formData.append('last_name', objUser.last_name)
        formData.append('username', objUser.username)
        // formData.append('password', objUser.password)
        formData.append('visible', objUser.visible)
        if (objUser.profile_image && !String(objUser.profile_image).includes("http")) {
            formData.append('profile_image', objUser.profile_image)
        }
        return patchMultipartApiUrl(`${BACKEND.USERS.MOBILE.POST + idUser}/` , formData)
        .then(response => response.json())
        .then(response => dispatch(createUserResponse(response)))
        .catch(error => error.then(e => dispatch(createUserError(e))))
    };
};

export const loadMobileUser = (user_id) => {
    return function (dispatch) {
      dispatch(getUserRequest);
      return getApiUrl(BACKEND.USERS.MOBILE.GET, { user_id })
        .then(
          response => response.json(),
          error => dispatch(getUserError(error))
        )
        .then(response => dispatch(getUserResponse(response)));
    };
};

export function postValidateUsername(username) {
    return function (dispatch) {
      dispatch(validateUsernameRequest())
      return getApiUrl(BACKEND.USERS.MOBILE.VALIDATE_USER, { username })
        .then(response => response.json())
        .then(response => dispatch(validateUsernameResponse(response)))
        .catch(error => error.then(e => dispatch(validateUsernameError(e))))
    }
}

export const assignCompany = (company_id, user_id) => {
    return function (dispatch) {
      dispatch(assignUserRequest);
      return getApiUrl(BACKEND.COMPANY_CATALOG.ASSIGN, { company_id, user_id })
        .then(
          response => response.json(),
          error => dispatch(assignUserError(error))
        )
        .then(response => dispatch(assignUserResponse(response)));
    };
};

export const loginUser = (user_data) => {
    return function (dispatch) {
      dispatch(loginAction());
      return postApiUrlWithoutAuthorization(BACKEND.USERS.LOGIN, user_data)
        .then(response => response.json())
        .then(response => dispatch(responseloginUser(response)))
        .catch(error => error.then(e => dispatch(errorLoginUser(e))))
    };
};

export const resendUserValidation = (email) => {
    return function (dispatch) {
      dispatch(getValidationUser());
      return postApiUrlWithoutAuthorization(BACKEND.USERS.RECOVER, {email})
        .then(response => response.json())
        .then(response => dispatch(responseGetValidationUser(response)))
        .catch(error => error.then(e => dispatch(errorGetValidationUser(e))))
    };
};

export const validateEmailDuplicated = (email) => {
    return function (dispatch) {
      dispatch(validateEmail());
      return getApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.CHECK_EMAIL, {email})
        .then(response => response.json())
        .then(response => dispatch(responseValidateEmail(response)))
        .catch(error => error.then(e => dispatch(errorValidateEmail(e))))
    };
};

export const createNewAccount = (user) => {
    return function (dispatch) {
      dispatch(createAccount());
      return postApiUrlWithoutAuthorization(BACKEND.USERS.SIGN_UP.POST, user)
        .then(response => response.json())
        .then(response => dispatch(responseCreateAccount(response)))
        .catch(error => error.then(e => dispatch(errorCreateAccount(e))))
    };
};

export const resetNewPassword = (data) => {
    return function (dispatch) {
      dispatch(resetNewPasswordRequest());
      return postApiUrlWithoutAuthorization(BACKEND.USERS.RESET, data)
        .then(response => response.json())
        .then(response => dispatch(resetNewPasswordResponse(response)))
        .catch(error => error.then(e => dispatch(resetNewPasswordError(e))))
    };
};