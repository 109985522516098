// @flow
import * as React from "react";
import Tag from "../../controls/tag";
import TextInput from "../../controls/text-input";
import Checkbox from "../../controls/checkbox"; 
import { normalizeStr } from "../../utils";
import ReactDatePicker from "react-datepicker";
import { FILTER_LIST_TYPES } from '../filter/filter-bar-content';
import ExceedPlus from '../../controls/exceed-plus';
import Button from '../../controls/button';
type Props = {};

class NewFilterBarContent extends React.Component<Props> {

  state = {
    indexHovered: -1
  }

  currentList = '';
  selectedItemsTemp = undefined;

  componentDidUpdate(prevProps) {

    if (this.props.applyFilter) {
      if (this.props.isOpen) {

        if (this.currentList === '') {
          this.currentList = this.props.current;
          this.selectedItemsTemp = this.props.selectedItems[this.props.current];
        } else if (this.currentList !== this.props.current) {
  
          this.props.onChange({ target: { name: this.currentList, value: this.selectedItemsTemp } });
  
          this.currentList = this.props.current;
          this.selectedItemsTemp = this.props.selectedItems[this.props.current];
        }
  
      } else {
        if (this.currentList !== '') {
          this.props.onChange({ target: { name: this.currentList, value: this.selectedItemsTemp } });
          this.currentList = '';
        }
      }
    }

  }
  
  getSelectedNumber = (c) => {
    if(c.listType === 8 || c.listType === 7){
      let items = (this.props.selectedItems[c.name] || [])
      // debugger;
      items = items.filter((value, i, a) => { 
        // debugger;
        return (value && (i === 0 || value.getTime() !== a[0].getTime()))
      });
      // debugger;
      return items.length;
    }
    return (this.props.selectedItems[c.name] || []).length
  }

  render() {
    return this.props.content.map((c, index) => (
      <div
        key={`new-filter-${index}`}
        className={"".concat(
          "list",
          c.name === this.props.current && this.props.isOpen ? " open" : "",
          (this.props.selectedItems[c.name] || []).length > 0 ? " filtered" : ""
        )}
        onClick={(e) => this.manageView(e, c.name)}
        style={{
          cursor: 'pointer'
        }}
        onMouseEnter={() => this.setState({ indexHovered: index })}
        onMouseLeave={() => this.setState({ indexHovered: -1})}
      >

        {
          (c.listType === 8 || c.listType === 7) ?
          <div className="title" key={`new-filter-title-${index}`}>
            <div
              style={{
                display:
                  (this.props.selectedItems[c.name] || []).length > 0
                    ? "block"
                    : "none",
              }}
            >
              {this.getSelectedNumber(c)
                .toString()
                .concat("\u00a0\u00a0")}
            </div>
            {c.title}
          </div> :
          <div className="title" key={`new-filter-title-${index}`}>
          { (this.props.selectedItems[c.name] || []).length === 0 ? c.title : ''}
            <div>
              <ExceedPlus maxVisibleItems={1} name={c.name} position="bottom" delayHide={5000}>
                {(this.props.selectedItems[c.name] || []).map((item, index) => {
                  return (
                    <div
                      className={'delete_filter_' + `${index}`}
                      style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                      onClick={(e) => {
                        this.removeFilterItem(c.name, item);
                      }}
                    >
                      <img 
                        src='/images/close-icon.svg' 
                        alt='' 
                        style={{width: 12, height: 12, marginRight: 9}}
                        className={'delete_filter_x_' + `${index}`}
                      />
                      {this.getItemDescription(c, item)}
                    </div>
                  )
                })}
              </ExceedPlus>
              <div
                style={{
                  position: 'absolute',
                  left: 20,
                  bottom: 0,
                  top: 0,
                  right: 40
                }}
              />
            </div>
          </div>

        }
        <div className="caret">
          <img 
            width={this.state.indexHovered === index ? "21px" : "16px"}
            height={this.state.indexHovered === index ? "15px" : "10px"}
            alt="" 
            src="/images/caret.svg" />
        </div>
        {
          c.name === this.props.current && this.props.isOpen
          ? <div className={"content".concat(c.listType === 7 || c.listType === 8 || c.listType === 9 || c.listType === 10 ? " date" : "")} onClick={(e) => e.stopPropagation()}>
              {this.getListType(c)}
            </div>
          : ''
        }
        
      </div>
    ));
  }

  removeFilterItem = (name, item) => {
    let st = [];

    (this.props.selectedItems[name] || [])
      .filter( filterItem => { return `${filterItem}` !== `${item}` } )
      .forEach((fe) => {
        st.push(fe);
      }
    );

    this.props.onChange({ target: { name, value: st } });
    if (this.props.applyFilter) {
      this.props.applyFilter({name});
    }
  };

  getItemDescription = (data, id) => {
    let name = data.name
    var descriptionName = '';

    if (data.listType === 1) {

      this.props.content
        .find((c) => c.name === name)
        .items
        .filter(filterItem => { return `${filterItem.id}` === `${id}` })
        .forEach((fe) => {
          descriptionName = fe.title
        });

    } else {

      this.props.content
        .find((c) => c.name === name)
        .items
        .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
        .forEach((fe) => {
          if ((fe.items || []).length > 0) {
            fe.items
            .filter( filterItem => { return `${filterItem.id}` === `${id}`})
            .forEach((ch) => {
              descriptionName = ch.description;
            });
          } else {
            if (`${fe.id}` === `${id}`) {
              descriptionName = fe.description;
            }
          }
        });

    }

    return descriptionName;
  }

  handleChange = (name, value) => {
    if (name.target) this.props.handleChange(name);
    else this.props.handleChange({ target: { name, value } });
  };

  getListType = (c) => {
    switch (c.listType) {
      case FILTER_LIST_TYPES.chips:
        return this.getChipsList(c.items, c.name);
      case FILTER_LIST_TYPES.checkList:
        return this.getCheckList(c.items, c.name);
      case FILTER_LIST_TYPES.checkListTextSearch: 
        return this.getCheckList(c.items, c.name, true);
      case FILTER_LIST_TYPES.checkListAll:
        return this.getCheckList(c.items, c.name, false, true);
      case FILTER_LIST_TYPES.checkListTextSearchAll:
        return this.getCheckList(c.items, c.name, true, true);
      case FILTER_LIST_TYPES.checkExclusive:
        return this.getCheckList(c.items, c.name, true, false, true);
      case FILTER_LIST_TYPES.date:
        return this.getDatePicker(c.name, true, false);
      case FILTER_LIST_TYPES.dateFixed:
        return this.getDatePicker(c.name, false, false);
      case FILTER_LIST_TYPES.dateRange:
        return this.getDatePicker(c.name, true, true);
      case FILTER_LIST_TYPES.dateRangeFixed:
        return this.getDatePicker(c.name, false, true);
      default:
        return <React.Fragment />;
    }
  };

  getDatePicker = (name, clear = true, range = true) => {
    // debugger;
    return (
      <div className="items" style={ this.props.applyFilter ? {flexDirection: 'column'} : {}}>
        {
          clear &&
          <div className="deselector">
            <span onClick={() => this.deselectAll({ target: { name } })}>
              Limpiar filtro
            </span>
          </div>
        }
        <ReactDatePicker
          onChange={dates => {
            if(range){
              let [start, end] = dates;
              // if(end) {
              //   end = new Date(end.setMinutes(1))
              // }
              this.props.onChange({ target: { name: name, value: [start, end] } })
            } else {
              // debugger;
              this.props.onChange({ target: { name: name, value: [dates] } })
            }
          }}
          selected={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
          startDate={(this.props.selectedItems[name] && this.props.selectedItems[name][0]) || null}
          endDate={(this.props.selectedItems[name] && (this.props.selectedItems[name][1])) || null}
          selectsRange={range}
          inline
        />

        {
          this.props.applyFilter ? 
          <div style={{marginTop: 10, marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <Button 
              text='Aplicar'
              className='small'
              onClick={() => {
                this.selectedItemsTemp = this.props.selectedItems[this.props.current];
                this.props.applyFilter({name});
                this.handleChange('isOpen', false);
              }}
              disabled={false}
            />
          </div> : <div/>
        }
      </div>
    )
  }

  getChipsList = (items, name) => {
    return (
      <React.Fragment>
        <div className="deselector">
          <span onClick={() => this.deselectAll({ target: { name } })}>
            Limpiar filtro
          </span>
        </div>
        <div className="search-list">
          <TextInput
            value={this.props.searchText}
            label="Buscar"
            name="searchText"
            onChange={this.handleChange}
          />
        </div>
        <div className="items chips">
          {this.handleFilterListSearch(
            items,
            "title",
            this.props.searchText
          ).map((i) => (
            <Tag
              key={i.id}
              id={i.id}
              color={i.color || "3D77F7"}
              title={i.title}
              isFull={
                (this.props.selectedItems[name] || []).find(
                  (s) => s.toString() === i.id.toString()
                )
                  ? true
                  : false
              }
              parentBackground={"light"}
              onClick={(e) =>
                this.handleSelectedItemsChange({
                  target: {
                    name: name,
                    value: e.target.id,
                  },
                })
              }
            />
          ))}
        </div>
        
        {
          this.props.applyFilter ? 
          <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <Button 
              text='Aplicar'
              className='small'
              onClick={() => {
                this.selectedItemsTemp = this.props.selectedItems[this.props.current];
                this.props.applyFilter({name});
                this.handleChange('isOpen', false);
              }}
              disabled={false}
            />
          </div> : <div/>
        }

      </React.Fragment>
    );
  };

  deselectAll = (e) => {
    this.props.onChange({ target: { name: e.target.name, value: [] } });
  };

  selectAll = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props.content
        .find((c) => c.name === e.target.name)
        .items
        .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
        .forEach((fe) => {
          if ((fe.items || []).length > 0) {
            fe.items
            .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
            .forEach((ch) => {
              if (!st.includes(ch.id)) st.push(ch.id);
            });
          } else {
            st.push(fe.id);
          }
        });
    }
    this.props.onChange({ target: { name: e.target.name, value: st } });
  };

  handleSelectedItemsChange = (e, items, single = false) => {
    if(single){
      let st=[];
      if(e.target.checked) {
        st.push(e.target.value.toString())
      }
      this.props.onChange({ target: { name: e.target.name, value: st } });
    } else {
      let st =
        (this.props.selectedItems[e.target.name] || []).map((m) =>
          m.toString()
        ) || [];
      if ((items || {}).length > 0) {
        items.forEach((item) => {
          let val = item.id.toString();
          if (!item.isDisabled || false) {
            st = st.filter((a) => a !== val);
            if (e.target.checked) {
              st = st.concat(val);
            }
          }
        });
      } else {
        if (st.includes(e.target.value.toString())) {
          st = st.filter((a) => a.toString() !== e.target.value.toString());
        } else {
          st = st.concat(e.target.value.toString());
        }
      }
      this.props.onChange({ target: { name: e.target.name, value: st } });
    }
  };

  manageView = (e, name) => {

    if (e.target.className.includes('delete_filter_')) {
      return;
    }

    if (name === this.props.current) {
      this.handleChange("isOpen", !this.props.isOpen);
      this.handleChange("searchText", "");
    } else if (name === "") {
      this.handleChange("current", name);
      this.handleChange("isOpen", false);
      this.handleChange("searchText", "");
    } else {
      this.handleChange("current", name);
      this.handleChange("isOpen", true);
      this.handleChange("searchText", "");
    }
  };

  handleFilterListSearch = (items, field, value = "") => {
    if(!value.length > 0) return items;

    let result = [];
    items.forEach(i => {
      if(normalizeStr(i[field] || "").includes(normalizeStr(value))){
        result.push(i)
      } else {
        if((i.items || []).length){
          let matches = i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)));
          if(matches.length){
            result.push({
              ...i,
              items: matches
            })
          } else {
            
          }
        }
      }
    })
    return result;
    // return value.length > 0
    //   // ? items
    //   //     .filter(i => {

    //   //     })
    //   ? items
    //       .map((i) => {
    //         if(normalizeStr(i[field] || "").includes(normalizeStr(value))){
    //           return i
    //         } else {
    //           if((i.items || []).length){
    //             let matches = i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)));
    //             if(matches.length){
    //               return {
    //                 ...i,
    //                 items: matches
    //               }
    //             } else {
    //               return null
    //             }
    //           }
    //         }
    //         (i.items || []).length &&
    //         !normalizeStr(i[field] || "").includes(normalizeStr(value))
    //           ? {
    //               ...i,
    //               // items: this.handleFilterListSearch(
    //               //   i.items,
    //               //   "description",
    //               //   this.props.searchText
    //               // ),
    //               items: i.items.filter(ii => normalizeStr(ii[field] || "").includes(normalizeStr(value)))
    //             }
    //           : i
    //       })
    //       // .filter(
    //       //   (i) =>
    //       //     normalizeStr(i[field] || "").includes(normalizeStr(value)) ||
    //       //     (i.items || []).length
    //       // )
    //   : items;
  };

  getCheckList = (items, name, search = false, all = false, single = false) => {
    return (
      <React.Fragment>
        <div className="deselector">
          <span onClick={() => this.deselectAll({ target: { name } })}>
            Limpiar filtro
          </span>
        </div>
        {search ? this.getTextSearch() : <React.Fragment />}
        {all ? this.getSelectAll(name) : <React.Fragment />}
        {this.checkList(items, name, single)}
        {
          this.props.applyFilter ? 
          <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <Button
              text='Aplicar'
              className='small'
              onClick={() => {
                this.selectedItemsTemp = this.props.selectedItems[this.props.current];
                this.props.applyFilter({name});
                this.handleChange('isOpen', false);
              }}
              disabled={false}
            />
          </div> : <div/>
        }
      </React.Fragment>
    );
  };
  checkList = (items, name, single) => {
    let counter = -9999;
    return (
      <div className="items checks">
        {
          // (items || []).map(i => {
          this.handleFilterListSearch(
            items || [],
            "description",
            this.props.searchText
          ).map((i) => {
            let l = [];
            l.push(
              <Checkbox
                disabled={i.isDisabled || false}
                disableMessage={i.disableMessage}
                key={counter++}
                onChange={(e) => this.handleSelectedItemsChange(e, i.items, single)}
                item={{
                  id: i.id,
                  description: i.description,
                  active: (this.props.selectedItems[name] || []).find(
                    (si) => si.toString() === i.id.toString()
                  )
                    ? true
                    : false,
                }}
                name={name}
                className={(i.items || []).length ? "bold" : ""}
              />
            );
            if ((i.items || []).length) {
              // this.handleFilterListSearch(i.items, 'description', this.state.searchText).forEach(ci =>
              i.items.forEach((ci) =>
                l.push(
                  <Checkbox 
                    disableMessage={ci.disableMessage}
                    disabled={ci.isDisabled || false}
                    key={counter++}
                    className={"sub-item"}
                    onChange={(e) => this.handleSelectedItemsChange(e, single)}
                    item={{
                      id: ci.id,
                      description: ci.description,
                      active: (this.props.selectedItems[name] || []).find(
                        (si) => si.toString() === ci.id.toString()
                      )
                        ? true
                        : false,
                    }}
                    name={name}
                  />
                )
              );
              l.push(<div className="separator" key={counter++} />);
              let full = true;
              i.items.filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }).forEach((fe) =>
                (this.props.selectedItems[name] || []).find(
                  (si) => si.toString() === fe.id.toString()
                ) && full
                  ? (full = true)
                  : (full = false)
              );
              l[0].props.item.active = full;
            }
            return l;
          })
        }
      </div>
    );
  };

  getTextSearch = () => {
    return (
      <div className="search-list">
        <TextInput
          value={this.props.searchText}
          label="Buscar"
          name="searchText"
          onChange={this.handleChange}
        />
      </div>
    );
  };

  isAllSelected = (name) => {
    let totalItems = (((this.props.content
      .find((c) => c.name === name) || {})
      .items || [])
      .filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true })
      .some((i) => i.items)
      ? [...new Set(this.props.content
          .find((c) => c.name === name).items
          .map((i) => i.items.filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }).flat())
          .flat().map(i => i.id))]
      : [...new Set(((this.props.content.find((c) => c.name === name) || {}).items || []).filter( filterItem => { return (filterItem.isDisabled !== null && filterItem.isDisabled !== undefined) ? !filterItem.isDisabled : true }))]
          );
    // debugger;
    return (this.props.selectedItems[name] || []).length > 0 &&
    (this.props.selectedItems[name] || []).length === totalItems.length;
  }

  getSelectAll = (name) => {
    return (
      <React.Fragment>
        {" "}
        <Checkbox
          onChange={(e) => this.selectAll(e)}
          item={{
            id: 0,
            description: "Todos",
            active: this.isAllSelected(name)
              
          }}
          name={name}
          className="bold"
        />
        <div className="separator" />
      </React.Fragment>
    );
  };
}

export default NewFilterBarContent;
