import React from 'react';

class CompanyChip extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      profileImage: `${props.company.avatar}` || ''
    }
  }

  componentDidMount() {
    this.isValid(this.state.profileImage)
  }

  isValid(url) {
    fetch(url).then(response => this.setState({ profileImage: response.status === 200 ? url : null }))
  }

  render () {

    const { name = '', alias = '', code = '', error = ''} = this.props.company;
    
    return(
      <div className='company-chip'>

        {
          (this.state.profileImage === null) ?
          <div className={'avatar'} style={{display: 'flex', justifyContent: 'center', alignItems:'center', backgroundColor: 'white'}}>
            <img src={'/images/modal-factory-blue-icon.svg'} alt={''} onError={(e)=>{e.target.onerror = null; e.target.src='/images/modal-factory-blue-icon.svg'}}/>
          </div>
          :
          <img className='avatar' alt='' src={this.state.profileImage} onError={(e)=>{e.target.onerror = null; e.target.src='/images/modal-factory-blue-icon.svg'}}/>
        }

        <div style={{marginRight: '35px'}}>
          {
            (error !== '') ? 
              <div className='text' style={{color: 'red'}}>{error}</div>
            :
              <React.Fragment>
                <div className='text'>{name}</div>
                <div className='details-container'>
                    <div className='text' style={{marginRight: '10px'}}>{alias}</div>
                    <div className='text'>{code}</div>
                </div>
              </React.Fragment>
          }
          
        </div>

        <b onClick={() => this.props.deleteCompany(this.props.company)}><img src='/images/close-icon.svg' alt=''/></b>

      </div>
    )
  }
}

export default CompanyChip;

CompanyChip.defaultProps = {
    avatar: '/images/help.png',
    company: {},
    deleteCompany: () => {console.log('Not yet implemented.')}
}