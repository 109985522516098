// @flow
import React from "react";
import { Flex } from "../../../shared/styled/flex";
import Checkbox from "../../../shared/controls/checkbox";
import ExceedPlus from "../../../shared/controls/exceed-plus";
import { AssembleAvatar } from "../assemble-avatar";
import RadioButtons from "../../../shared/controls/radio";
type Props = {
  onlyOne: boolean,
  operator: any,
  headers: Array<any>,
  onChange: Function,
};

export const AssembleModalOperatorRow = ({
  operator,
  headers,
  onChange,
  onlyOne,
}: Props) => {
  return (
    <div>
      <Flex style={{ alignItems: "center", marginLeft: "20px" }}>
        <Flex style={{ width: headers[0].width }}>
          {onlyOne ? (
            <RadioButtons
              value={""}
              name="operators"
              onChange={() =>
                onChange({
                  target: {
                    name: "operators",
                    value: operator,
                  },
                })
              }
              id={operator.id}
              checked={operator.selected || false}
            />
          ) : (
            <Checkbox
              onChange={(e) =>
                onChange({
                  target: {
                    name: "operators",
                    value: operator,
                    checked: e.target.checked,
                  },
                })
              }
              item={{
                description: "",
                active: operator.selected,
                id: operator.id,
              }}
              name="operators"
            />
          )}
          <AssembleAvatar
            description={operator.description}
            fleetTypeId={3}
            avatarUrl={operator.imageUrl}
          />
        </Flex>
        <div style={{ width: headers[1].width, textAlign: "center", color: operator.assembled ? 'red' : 'initial' }}>
          {operator.assembled ? 'Ensamblado' : 'Libre'}
        </div>
        <Flex justifyContent="center" style={{ width: headers[2].width }}>
          <ExceedPlus name={operator.id}>{operator.tags}</ExceedPlus>
        </Flex>
        <div style={{ width: headers[3].width, textAlign: "center" }}>
          {new Date(operator.from).toLocaleString("es-MX")}
        </div>{" "}
        <div style={{ width: headers[4].width, textAlign: "center" }}>
          {typeof operator.to === "string"
            ? operator.to
            : operator.to.toLocaleString("es-MX")}
        </div>
        <div style={{ width: headers[5].width, textAlign: "center" }}>
          {
            <div style={{ display: "flex", alignItems: "center" }}>
              <img width="24px" src={operator.status.ICON} alt="" />
              <span style={{ color: operator.status.COLOR }}>
                {operator.status.CAPTION}
              </span>
            </div>
          }
        </div>
      </Flex>
    </div>
  );
};
