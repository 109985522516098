import React from "react";
import Modal from "react-modal";
import Button from "../controls/button";

Modal.setAppElement("#root");

class LoginErrorDialog extends React.Component {
  onClose = () => {
    if (this.props.closeAction) this.props.closeAction();
  };

  getButtons = () => {

    if (this.props.message.includes('Olvidaste tu contraseña')) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}>
          <div
            className="forgot-link"
            onClick={() => this.props.openForgetPassword()}
            style={{ cursor: 'pointer'}}
          >
            ¿Olvidaste tu contraseña?
          </div>
          <Button
            settings={{
              text: this.props.acceptText,
              onClick: this.props.acceptAction
            }}
          />
        </div>
      );
    } else if (this.props.message.includes('Usuario no existe')) {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}>
          <Button
              text={"Crear tu cuenta"}
              type={"secondary"}
              onClick={ () => this.props.openCreateAccount() }
            />
          <Button
            settings={{
              text: this.props.acceptText,
              onClick: this.props.acceptAction
            }}
          />
        </div>
      );
    } else {
      return(
        <Button
          settings={{
            text: this.props.acceptText,
            onClick: this.props.acceptAction
          }}
        />
      );
    }
  }


  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName={`dialog error-dialog ${
          this.props.addClass ? this.props.addClass : ""
        }`}
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.props.message}</div>
        <div className="actions"> { this.getButtons() } </div>
      </Modal>
    );
  }
}

export default LoginErrorDialog;
