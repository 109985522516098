import moment from "moment";
import React from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import { cancelRequest, getEntityItems, readEntities, readEntity } from "../../redux/api";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import Caret from "../../shared/component/caret";
import TableD from "../../shared/component/table-d";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { View, ViewTitle } from "../../shared/styled/view";
import ServiceReportRow from "./service-report-row";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import { isPremiumSubscriptionCo } from "../../shared/utils";

class ServicesReportView extends React.Component {
  state = {
    collapseAll: true,
    limit: 10,
    offset: 1,
    isHovered: false,
    isFreeSubscription: false
  }

  componentDidMount() {
    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.loadServices();
    this.props.setNavbarAction("services-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("services-report");
  }

  loadServices = () => {
    this.props.cancelRequest("COMPANIES.BRIDGE.CATALOG");
    this.props.loadServices({
      company_id: this.props.companyId,
      limit: this.state.limit,
      offset: this.state.offset
    }, {

    })
  }

  openDetail = (id, param) => {
    this.props.setConfig(null);
    this.props.history.push(`/company/reports/services/${id}?search_param=${param}`)
  }
  
  openTable = (id) => {
    // this.props.history.push(`/company/reports/services/table/${id}`)
  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    return(
      <View className="web-service-report-view">
        <ViewTitle>
          Reporte de servicios web
        </ViewTitle>
        <div className="content">
          <TableD 
            tableConfig={
              // Utils.TableConfig
              {
                ...TABLECONFIG, 
                columns: [
                  {
                    ...TABLECONFIG.columns[0],
                    text: <CaretContainer
                        onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                        onMouseEnter={() => this.setState({ isHovered: true })}
                        onMouseLeave={() => this.setState({ isHovered: false })}
                      >
                        <Caret up={this.state.collapseAll} fill="#AFC8FF" isHovered={this.state.isHovered}/>
                      </CaretContainer>
                  }
                ].concat(TABLECONFIG.columns.slice(1))
              }
            }
          >
            {(this.props.serviceRows || []).map((wS) => (
              <ServiceReportRow  
                collapsed={this.state.collapseAll}
                key={`row-${wS.id}`}
                data={{
                  ...wS,
                  status: moment().diff(wS.expires) > 0 ? 'Vencido' : 'Activo',
                  expiry_date: moment(wS.expires).format('DD/MM/YYYY'),
                  last_activity: moment(wS.last_use).format('DD/MM/YYYY HH:mm (utc Z)'),
                }}
                openDetail={() => this.openDetail(wS.bridge?.collection_name || 0, wS.bridge?.params?.search_param || '')}
                openTable={this.openTable}
                actions={[
                  {
                    description: 'Descargar documentación',
                    action: () => {},
                    disable: true,
                  }
                ]}
              />
            ))}
          </TableD>
          <PaginatorControlled
            itemCount={this.props.serviceItemCount || 0}
            limit={this.state.limit}
            offset={this.state.offset}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () =>{
                this.loadServices();
                // if (this.state.getTripsCalled === true) {
                  // console.log("SEARCHING-FILTER-PAGINATOR");
                  // this.loadTrips();
                // }
              })
            }
          />
        </div>
      </View>
    )
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  let companyId = state.globalCatalog.session.company.id;
  // let serviceRows = DUMMY;
  const serviceRows = getEntityItems(state, "COMPANIES.BRIDGE.CATALOG");
  let serviceItemCount = serviceRows.length;
  return {
    companyId,
    serviceItemCount,
    serviceRows,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
}

const mapDispatchToProps = (dispatch) => {
  
  return {
    setConfig: (config) => dispatch(setConfig("ServiceDetailView", config)),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadServices: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.BRIDGE.CATALOG", params, opt) 
      );
    },
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  }

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesReportView);

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const DUMMY = [
  {
    name: 'Motum',
    url: "https://v2.orbinetwork.com/api/v2/bridge/octopus/receiver",
    id: 1,
    expiry_date: "2020-09-18T13:31:41-05:00",
    status: "Activo",
    last_activity: "2020-09-18T13:31:41-05:00",
    sync_tables: [
      {
        id: 10,
        name: "sync_table_1"
      },
      {
        id: 20,
        name: "sync_table_2"
      }
    ]
  },
  {
    name: 'Octopus MX',
    url: "https://v2.orbinetwork.com/api/v2/bridge/octopus/receiver",
    id: 2,
    expiry_date: "2020-09-18T13:31:41-05:00",
    status: "Activo",
    last_activity: "2020-09-18T13:31:41-05:00",
    sync_tables: [
      {
        id: 10,
        name: "sync_table_1"
      }
    ]
  },
  {
    name: 'Octopus COPEC',
    url: "https://v2.orbinetwork.com/api/v2/bridge/octopus/receiver",
    id: 3,
    expiry_date: "2020-09-18T13:31:41-05:00",
    status: "Activo",
    last_activity: "2020-09-18T13:31:41-05:00",
    sync_tables: [
      {
        id: 10,
        name: "sync_table_1"
      }
    ]
  },
]

const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
};

const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  textAlign: "center",
};

const TABLECONFIG = {
  rowStyle: {
    backgroundColor: "#3D5074",
    height: "81px",
    borderBottom: "1px solid #A4B4CE",
    minHeight: "81px"
  },
  headerStyle: {
    backgroundColor: "#3D5074",
    borderBottom: "2px solid #A4B4CE",
  },
  contentStyle: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  columns: [
    {
      text: "",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Servicio",
      width: "30%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
    },
    {
      text: "Vence",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Última actividad",
      width: "30%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
  ],
};

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;