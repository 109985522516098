import React from 'react'
import styled from 'styled-components'

const BaseCard = ({
  children,
  width,
  height,
  rf,
  isOverFree,
  id,
  ...props
}) => {
  return (
    <BaseWrapper id={id} /* ref={rf} */ width={width} height={height}>
      <Wrapper /* isOverFree={isOverFree} */ {...props}>
        {children}
      </Wrapper>
    </BaseWrapper>
  )
}

export default BaseCard;

const BaseWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  overflow: hidden;
`

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  margin-right: 0.2rem;
  opacity: ${({ searched }) => searched === false ? '0.5' : '1'};
  background: ${({ color }) => color || '#1D2638'};
  color: ${({ textColor }) => textColor || 'white'};
  border-radius: ${({ borderRadius }) => borderRadius || '0.2rem'};
  border-right: ${({ borderRight, free, close }) =>
    borderRight && !free && !close ? borderRight : 'initial'};
  margin: ${({ margin }) => margin || 'initial'};
  ${({ conflict, color }) =>
    conflict &&
    `
    background: ${color || '#F12A1C'};
  `}
  ${({ free, unused, isOverFree }) =>
    free &&
    `
      border-radius: 0.2rem;
      background: ${unused ? 'rgba(60, 100, 138, 0.2)' : '#3C648A'};
      margin-right: 0.2rem;
      border: 0.2rem ${
        unused ? 'rgba(81, 175, 236, 0.2)' : isOverFree ? 'orange' : '#51AFEC'
      } solid;
    `}
  ${({ close }) =>
    close &&
    `
    border-radius: 0.2rem;
    border: none;
    background: linear-gradient(to top right,
      #1D2638 0%,
      #1D2638 calc(50% - 0.15rem),
      rgba(0,0,0,1) 50%,
      #1D2638 calc(50% + 0.15rem),
      #1D2638 100%);
  `};
  
`