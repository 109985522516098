import React from 'react';

class PrevSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propsChecked: props.checked || false,
      checked: props.checked || false
    }
  }
  static defaultProps = {
    handleClick: () => {},
    disabled: false,
  };
  handleClick = () => {
    if(!this.props.disabled){
      this.props.handleClick && this.props.handleClick(!this.state.checked);
      this.setState({checked: !this.state.checked});
    }
  }
  render(){
    return(
        <div className={"switch-container " + (this.state.checked ? 'checked' : '')} onClick={this.handleClick}>
        </div>
    )
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.checked !== prevState.propsChecked){
        return {
            propsChecked: nextProps.checked,
            checked: nextProps.checked
        }
    }
    return null;
  }
}

export default PrevSwitch;