// @flow
export class SaveItemCounter {
  counter: number;
  callback: Function;
  constructor(callback: Function) {
    this.counter = 0;
    this.callback = callback;
  }

  add(value: number = 1) {
    this.counter += value;
  }

  done(value: number = 1) {
    this.counter -= value;
    if (this.counter === 0) this.callback();
  }
}
