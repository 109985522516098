import moment from 'moment';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getUrlsEnv } from '../../shared/backend-api';
import { TruckDoubleEquipment, TruckEquipment } from '../../shared/icons/assemble';
import ForbiddenIcon from '../../shared/icons/forbidden-icon';
import styled from 'styled-components';

const { files } = getUrlsEnv();

function formatDate(date) {
  if(!date) return "-";
  if(date.slice(-1) === 'Z'){//fix ignorar UTC, BD guarda la hora ya convertida con formato de UTC
    date = date.slice(0, -1);
  }
  let n = new Date(date);
  return n.toLocaleString("en-GB", {dateStyle: "short", timeStyle: "short"})
}

function getTooltip(truck, type) {
  switch(type){
    case 1:
    case 2:
      return (`
        <div><b>Folio:</b> ${truck.folio}</div>
        <div><b>Llegada:</b> ${formatDate(truck.arrival)}</div>
        <div><b>Cita original:</b> ${formatDate(truck.original_appointment)}</div>
        <div><b>Cita vigente:</b> ${formatDate(truck.appointment)}</div>
        <div><b>Salida:</b> ${formatDate(truck.exit)}</div>`);
    default:
      return `<div><b>Folio:</b> ${truck.folio}</div>`;
  }
  
}

function getTooltipDiv(truck, type) {
  switch(type){
    case 1:
    case 2:
      return(
        <div>
          <SelectFolioDiv 
            style={{cursor: 'pointer'}}
            onClick={() => {
              navigator.clipboard.writeText(truck.folio)
            }}
          >
            <b>Folio: </b>{truck.folio}
            <b>  </b>
            <img
              alt=""
              width={"15px"}
              height={"15px"}
              src={"/images/copy-icon.png"}
            />
          </SelectFolioDiv>
          <div><b>Llegada: </b> {formatDate(truck.arrival)}</div>
          <div><b>Cita original: </b> {formatDate(truck.original_appointment)}</div>
          <div><b>Cita vigente: </b> {formatDate(truck.appointment)}</div>
          <div><b>Salida: </b> {formatDate(truck.exit)}</div>
        </div>
      );
    default:
      return (
        <div>
          <SelectFolioDiv 
            style={{cursor: 'pointer'}}
            onClick={() => {
              navigator.clipboard.writeText(truck.folio)
            }}
          >
            <b>Folio: </b>{truck.folio}
            <b>  </b>
            <img
              alt=""
              width={"15px"}
              height={"15px"}
              src={"/images/copy-icon.png"}
            />
          </SelectFolioDiv>
          <div>Sin datos validos</div>
        </div>
      );
  }
}

function getTransitTooltip(props) {

  const { origin_date, destination_date, origin_eta, destination_eta, orden, salida_origen } = props.truck;
  const { maneuver } = props;

  return (`
    <div><b>Folio:</b> ${orden}</div>
    <div><b>Cita carga:</b> ${formatDate(origin_date)}</div>
    <div><b>Cita descarga:</b> ${formatDate(destination_date)}</div>
    <div><b>Eta:</b> ${formatDate(maneuver === '1' ? origin_eta : maneuver === '2' ? destination_eta : salida_origen === "" ? origin_eta : destination_eta)}</div>`
  );

}

function getTransitTooltipDiv(props) {

  const { origin_date, destination_date, origin_eta, destination_eta, orden, salida_origen } = props.truck;
  const { maneuver } = props;

  return(
    <div>
      <SelectFolioDiv 
        style={{cursor: 'pointer'}}
        onClick={() => {
          navigator.clipboard.writeText(orden)
        }}
      >
        <b>Folio: </b>{orden}
        <b>  </b>
        <img
          alt=""
          width={"15px"}
          height={"15px"}
          src={"/images/copy-icon.png"}
        />
      </SelectFolioDiv>
      <div><b>Cita carga: </b> {formatDate(origin_date)}</div>
      <div><b>Cita descarga: </b> {formatDate(destination_date)}</div>
      <div><b>Eta: </b> {formatDate(maneuver === '1' ? origin_eta : maneuver === '2' ? destination_eta : salida_origen === "" ? origin_eta : destination_eta)}</div>
    </div>
  );

}

function formatHours(hour) {
  let parts = hour.split('.');
  if(parts[0] > 23){
    let h = parseInt(parts[0] % 24);
    if(h < 10){
      h = '0'.concat(h);
    }
    return ''.concat(parseInt(parts[0] / 24), 'd ', h, 'h')
  }
  let min = Math.round(60*parts[1]/100);
  if(min < 10){
    min = '0'.concat(min);
  }
  return parts[0].concat('h ', min, 'm');
}

function getTransitTime(time) {

  if (time === '') return '-';

  let milliseconds = parseInt(moment().diff(time));

  var hours = milliseconds / (1000*60*60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

  return h + ':' + m;
}

function getAdherenceTime(props) {
  const { origin_date, destination_date, origin_eta, destination_eta, salida_origen } = props.truck;
  const { maneuver } = props;

  let milliseconds = parseInt(0);

  if (maneuver === '1') {
    if (origin_eta !== '' && origin_date !== '')
      milliseconds = moment(origin_eta).diff(origin_date);
  } else if (maneuver === '2') {
    if (destination_eta !== '' && destination_date !== '')
      milliseconds = moment(destination_eta).diff(destination_date);
  } else {
    if (salida_origen !== "") {
      if (destination_eta !== '' && destination_date !== '')
        milliseconds = moment(destination_eta).diff(destination_date);
    } else {
      if (origin_eta !== '' && origin_date !== '')
        milliseconds = moment(origin_eta).diff(origin_date);
    }
  }

  if (milliseconds !== 0) {

    var hours = Math.abs(milliseconds) / (1000*60*60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;

    return(
      <div
        style={{
          color: milliseconds < 0 ? 'white' : 'red'
        }}
      >
        { `${milliseconds < 0 ? '-' : ''}` +  h + ':' + m}
      </div>
    );

  } else {
    return '-';
  }

}

export default function MapIndicatorsUnitsRow(props) {

  const { unit = '', facility = '', classinfo = '', valid = true, salida_origen, tooltips = {}} = props.truck;

  const eta = props.type === 3 ? props.truck.eta : props.truck.estadia;

  if (props.type === 3) {
    return(
      <div 
        className={'gps-assemble-row'}
        onClick={ () => props.selectTruck(null) }
      >

        <div className={'gps-assemble-row-container'}>

          <ReactTooltip
            id={"orbi-tooltip-lt-".concat(props.truck.key)}
            place="left"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

          <div className={'lt'}>

            <img
              data-tip={`<div>${props.truck.lt_name || '-'}</div>`} 
              data-for={"orbi-tooltip-lt-".concat(props.truck.key)}
              style={{
                height: 30,
                width: 30,
                borderRadius: 15,
              }}
              alt=""
              src={ `${files}${props.truck.lt_avatar}`}
              onError={(e)=>{e.target.onerror = null; e.target.src="/images/truck-bckgrnd.png"}}
            />

          </div>

          <div className={'unit'} style={{width: '15.5%'}}>
            {unit}
          </div>
            
          <div className={'facility'} style={{width: '19.5%'}}>
            {facility}
          </div>

          <div className={'class'} style={{width: '14.5%'}}>
            {classinfo}
          </div>

          <div className={'facility'} style={{width: '18.5%'}}>
            {getTransitTime(salida_origen)}
          </div>

          <div className={'facility'} style={{width: '20.5%'}}>

            <ReactTooltip
              id={"orbi-tooltip-time-".concat(props.truck.key)}
              place="left"
              type="light"
              effect="solid"
              event="mouseover"
              eventOff="click"
              clickable={true}
              globalEventOff="click"
              afterShow={() => {
                props.hideTooltips("orbi-tooltip-time-".concat(props.truck.key));
              }}
            >
              {getTransitTooltipDiv(props)}
            </ReactTooltip>

            <div 
              ref={ node => { 
                tooltips["orbi-tooltip-time-".concat(props.truck.key)] = node; 
                props.updateTooltips(tooltips);
              }} 
              className={'eta'} 
              data-tip={getTransitTooltip(props)} 
              data-for={"orbi-tooltip-time-".concat(props.truck.key)}
            >
              {getAdherenceTime(props)}
            </div>
          </div>

        </div>
        
      </div>
    );

  }

  return (
    <div 
      className={'gps-assemble-row'}
      onClick={ () => props.selectTruck(null) }
    >

      <div className={'gps-assemble-row-container'}>

        <ReactTooltip
          id={"orbi-tooltip-lt-".concat(props.truck.key)}
          place="left"
          type="light"
          effect="solid"
          html={true}
          event="mouseover"
          eventOff="mouseleave"
        />

        <div className={'lt'}>

          <img
            data-tip={`<div>${props.truck.lt_name || '-'}</div>`} 
            data-for={"orbi-tooltip-lt-".concat(props.truck.key)}
            style={{
              height: 30,
              width: 30,
              borderRadius: 15,
            }}
            alt=""
            src={ 
              // truck.contract && truck.contract.fleet_provider ?
                `${files}${props.truck.lt_avatar}`
                //&& truck.contract.fleet_provider.logo}`
              //   : truck.trips.length
              //     ? `${files}${truck.trips[0].fleet_provider.logo}`
              //     : ""
            }
            onError={(e)=>{e.target.onerror = null; e.target.src="/images/truck-bckgrnd.png"}}
          />

        </div>

        <div className={'unit'}>

          {
            props.truck.isFull
            ? <TruckDoubleEquipment
                fill={"#fff"}
                stroke={"#fff"}
                width={'30px'}
                height={'20px'}
              />
            : <TruckEquipment
              fill={"#fff"}
              stroke={"#fff"}
              width={'30px'}
              height={'20px'}
            />
            // (Object.keys(truck.equipments) || []).length > 1 ? (
            //   <TruckDoubleEquipment
            //     fill={truck.trips.length ? "#AFC8FF" : "#fff"}
            //     stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
            //     width={'40px'}
            //     height={'20px'}
            //   />
            // ) : (
            //     <TruckEquipment
            //       fill={truck.trips.length ? "#AFC8FF" : "#fff"}
            //       stroke={truck.trips.length ? "#AFC8FF" : "#fff"}
            //       width={'30px'}
            //       height={'20px'}
            //     />
            //   )
          }
          <div style={{marginLeft: "5px"}}>{unit}</div>

        </div>

        <div className={'facility'}>

          {facility}

        </div>

        <div className={'class'}>
          {classinfo}
        </div>
          
        {
          valid ? <>
            <ReactTooltip
              id={"orbi-tooltip-time-".concat(props.truck.key)}
              place="left"
              type="light"
              effect="solid"
              event="mouseover"
              eventOff="click"
              clickable={true}
              globalEventOff="click"
              afterShow={() => {
                props.hideTooltips("orbi-tooltip-time-".concat(props.truck.key));
              }}
            >
              { getTooltipDiv(props.truck, props.type) }
            </ReactTooltip>

            <div 
              ref={ node => { 
                tooltips["orbi-tooltip-time-".concat(props.truck.key)] = node; 
                props.updateTooltips(tooltips);
              }} 
              className={'eta'} 
              data-tip={getTooltip(props.truck, props.type)} 
              data-for={"orbi-tooltip-time-".concat(props.truck.key)} 
            >
              <div style={{ color: parseInt(eta) < 0 ? 'red' : 'green' }}>
                {formatHours(eta.toFixed(2))}
              </div>
            </div>
          </>
          : <>
            <ReactTooltip
              id={"orbi-tooltip-valid-".concat(props.truck.key)}
              place="left"
              type="light"
              effect="solid"
              event="mouseover"
              clickable={true} 
              eventOff="click"
              globalEventOff="click"
              afterShow={() => {
                props.hideTooltips("orbi-tooltip-time-".concat(props.truck.key));
              }}
            >
              { getTooltipDiv(props.truck, 10) }
            </ReactTooltip>

            <div 
              ref={ node => { 
                tooltips["orbi-tooltip-time-".concat(props.truck.key)] = node; 
                props.updateTooltips(tooltips);
              }} 
              className={'eta'} 
              data-tip={`<div><b>Folio:</b> ${props.truck.folio}</div><div>Sin datos validos</div>`}  
              data-for={"orbi-tooltip-valid-".concat(props.truck.key)} id={"orbi-tooltip-time-".concat(props.truck.key)}
            >
              <ForbiddenIcon fill={"#fff"} width={"18"}/>
            </div>
          </>
        }
      </div>

    </div>
  );
}

const SelectFolioDiv = styled.div`
  cursor: pointer;
  &:hover {
    font-size: 14px;
  }
`