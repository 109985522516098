import { combineReducers } from "redux";
import facilityCatalogReducer from "./facility-catalog.reducer";
import facilityCreateReducer from "./facility-create.reducer";
import globalCatalogReducer from "./global-catalog.reducer";
import tagsReducer from "./tags.reducer";
import facilityReducer from "./facility-reducer";
import userReducer from "./user-reducer";
import registerAccount from "./register-account.reducer";
import fleetReducer from "./fleet-reducer";
import registerCompany from "./register-company.reducer";
import home from "./home.reducer";
import register from "./register.reducer";
import companyReducer from "./company-reducer";
import alerts from "./alerts-reducer";
import notifications from "./notifications-reducer";
import apiReducer from "./api-reducer";
import shipment from "./shipment-reducer";
import contract from "./contract-reducer";
import configuration from "./configuration-reducer";
import navbar from "./navbar-reducer";
import productsReducer from "./products.reducer";

export default combineReducers({
  facilityCatalog: facilityCatalogReducer,
  facilityCreate: facilityCreateReducer,
  globalCatalog: globalCatalogReducer,
  facilityReducer: facilityReducer,
  tags: tagsReducer,
  registerAccount,
  fleetReducer: fleetReducer,
  registerCompany,
  home,
  register,
  companyReducer,
  userReducer: userReducer,
  alerts,
  notifications,
  // spaces,
  api: apiReducer,
  shipment,
  contract,
  configuration,
  navbar,
  productsReducer
});
