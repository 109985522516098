import React from "react";
import Tag from "../../shared/controls/tag";
// import ExceedPlus from "../controls/exceed-plus";
// import Tag from "../controls/tag";
// import ValidatePerms from "./validate-perms";

class ItemSelector extends React.Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     tags: props.tags || [],
  //     labels: props.labels || [],
  //     onClick: props.onClick || (() => { }),
  //     title: props.title || ''
  //   };

  // }

  render() {
    return (
      <div className="tags-content" style={{width: "100%"}}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <div className="title" style={{textAlign: "left", width: "auto", marginRight: "10px"}}>{this.props.title}</div>
            <img
              alt=""
              src="/images/edit-transparent.svg"
              className="edit-image"
              style={{height: "16px", width: "16px"}}
              onClick={this.props.onClick}
            />
        </div>
        {this.props.tags.length > 0 && (
            <div
              className="container"
              style={{
                height:
                  this.props.labels.length > 0
                    ? "calc(40% - 25px)"
                    : "calc(100% - 40px)"
              }}
            >
              {/* <ExceedPlus
              name={this.props.name}
              className='wrap'
              maxVisibleItems={(this.props.labels.length > 0) ? 3 : 5} > */}
              {this.props.tags.map(tag => (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  title={tag.title}
                  color={tag.color}
                />
              ))}
              {/* </ExceedPlus> */}
            </div>
        )}
        {this.props.labels.length > 0 && (
          <React.Fragment>
            {this.props.tags.length > 0 && <div className="line" />}

            <div
              className="container"
              style={{
                height:
                  this.props.labels.length > 0 && this.props.tags.length > 0
                    ? "calc(60% - 25px)"
                    : "calc(100% - 40px)"
              }}
            >
              {this.props.labels.map((item, index) => {
                return index === 0 ? (
                  <span key={index} className="label">
                    {item}
                  </span>
                ) : (
                  <span key={index} className="label">
                    {", " + item}
                  </span>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ItemSelector;

ItemSelector.defaultProps = {
  tags: [],
  labels: [],
  onClick: () => {},
  title: ""
};
