import React, { Component } from "react";
import SelectImage from "../shared/controls/select-image";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loadSession,
  setSessionFacility,
} from "../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "../shared/backend-api";
import { getFacilityCatalogSimple } from "../redux/reducers/facility-reducer";

class FacilitySelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: -1,
      results: [],
    };
  }

  componentDidMount() {
    this.props.getFacilityCatalogSimple().then((response) => {
      let data = response.response;
      this.setState({
        count: data.count,
        results: data.results,
      });
    });
  }

  handleClick = (id) => {
    const res = this.state.results.find((c) => String(c.id) === String(id));

    this.props.setSessionFacility(res);

    this.props.history.push("/configurations");
  };

  render() {
    return (
      <div className="company-select">
        <img alt="" className="logo" src="/images/logo-white.svg" />
        <div className="welcome-content">
          <div className="welcome-title">
            ¿A qué instalación deseas ingresar?
          </div>
          {/* <div className="welcome-actions">
            <div className="dropdown">
              <div className="dropbtn"></div>
              <div className="dropdown-content">
                <a href="/register/company">Crear empresa</a>
              </div>
            </div>
          </div> */}

          {this.state.count >= 0 && (
            <div className="companies">
              {this.state.results.map((c, index) => {
                // console.log(c);
                const { files } = getUrlsEnv();
                //const { type: { description = '' }, companyuser: { type = '' } } = c;

                let newFacility = {
                  id: c.id,
                  name: c.name + "\n" + c.code + "\n" + c.alias,
                  img: c.avatar.image
                    ? `${files}${c.avatar.image}`
                    : c.type.avatar_image,
                  //subtitle: description,
                  //role: type
                };

                return (
                  <SelectImage
                    data={newFacility}
                    key={index}
                    isSelected={false}
                    handleClick={this.handleClick}
                    icon={true}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // let storage = JSON.parse(window.sessionStorage.getItem('orbinetwork_session'));

  // const {
  //     companies = storage.listCompanies || []
  // } = state;

  return {
    companies: state.globalCatalog.session.listCompanies || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());

  return {
    setSessionFacility: (facility) => dispatch(setSessionFacility(facility)),
    getFacilityCatalogSimple: () => dispatch(getFacilityCatalogSimple()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FacilitySelect)
);
