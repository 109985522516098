import React, { Component } from "react";
import SelectInput from "../../../shared/controls/select-input";
import { validators } from "../../../shared/validate";
import { FieldsContainer, FieldsArrow } from "./style";
import TextInput from "../../../shared/controls/text-input";

class RouteCatalogDetailsFields extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createTargetData = (name, value) => {
    return {
      target: {
        name: name,
        value: value,
      },
    };
  };

  render() {
    const { data, handleInput, fieldsEditMode } = this.props;
    const { alias, code } = data;
    const { origin, destination } = data.facilities;
    return (
      <FieldsContainer>
        <div>
          <SelectInput
            label="Origen"
            name="origin"
            value={origin.value}
            className="item"
            onChange={handleInput}
            items={origin.items}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
            disabled={fieldsEditMode.select}
            grouped={true}
          />
          <div>
            <FieldsArrow fill={"#AFC8FF"} />
          </div>
          <SelectInput
            label="Destino"
            name="destination"
            value={destination.value}
            className="item"
            onChange={handleInput}
            items={destination.items}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
            disabled={fieldsEditMode.select}
            grouped={true}
          />
        </div>
        <div>
          {/* <SelectInput
            label="Convoy"
            name="convoy"
            //   value={destination.value}
            value={""}
            className="item"
            //   onChange={handleInput}
            //   items={destination.items}
            items={[]}
            //   validate={[validators.required]}
            onFocused={this.state.onFocused}
            disabled={fieldsEditMode.input}
          /> */}
          <TextInput
            label="Alias"
            name="alias"
            value={alias.value}
            className="item"
            onChange={(e) => {
              if (alias.dupliedAlias)
                handleInput(this.createTargetData("dupliedAlias", false));
              handleInput(e);
            }}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
            errors={
              alias.dupliedAlias
                ? [`El alias "${alias.value}" esta en uso`]
                : []
            }
            handleBlur={(e) => {
              if (String(alias.ownAlias) !== String(e.target.value)) {
                alias.duplicated(e.target.value);
              }
            }}
            disabled={fieldsEditMode.input}
          />
          <TextInput
            label="Codigo"
            name="code"
            value={code.value}
            className="item"
            onChange={(e) => {
              if (code.dupliedCode)
                handleInput(this.createTargetData("dupliedCode", false));
              handleInput(e);
            }}
            validate={[validators.required]}
            onFocused={this.state.onFocused}
            errors={
              code.dupliedCode ? [`El codigo "${code.value}" esta en uso`] : []
            }
            handleBlur={(e) => {
              if (String(code.ownCode) !== String(e.target.value)) {
                code.duplicated(e.target.value);
              }
            }}
            disabled={fieldsEditMode.input}
          />
        </div>
      </FieldsContainer>
    );
  }
}

export default RouteCatalogDetailsFields;

RouteCatalogDetailsFields.defaultProps = {};
