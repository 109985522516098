import React from "react";
const TruckMinIcon = (props) => {
  return (
    <svg 
      width="49px" 
      height="38px" 
      viewBox="0 0 49 38" 
      fill="#252E47"
      opacity="0.5"
      transform="translate(5.500000, 6.000000)"
      stroke-width="1"
      {...props}
    >
      <path d="M15.9501821,19.0375 C16.2530035,19.0375 16.5,19.8798125 16.5,20.9125 L16.5,20.0875 C16.5,21.1201875 16.2530035,21.9625 15.9501821,21.9625 L10.9924745,21.9625 L6.04981778,21.9625 C5.74699643,21.9625 5.5,21.1201875 5.5,20.0875 L5.5,20.9125 C5.5,19.8798125 5.74699643,19.0375 6.04981778,19.0375 L15.9501821,19.0375 Z" id="Fill-1"></path>
      <path d="M36.8406513,22 L33.9692493,22 C33.984394,21.8389094 33.9908862,21.6756799 33.9908862,21.5124449 C33.9908862,18.5033634 31.5306182,16.0634709 28.5012325,16.0634709 C25.4696865,16.0634709 23.0115789,18.5055077 23.0115789,21.5124449 C23.0115789,21.6756799 23.0202369,21.8389094 23.0332213,22 L20.1618192,22 C19.248705,22 18.5,21.258982 18.5,20.3504898 L18.5,1.64950474 C18.5,0.741012511 19.2465446,0 20.1618192,0 L26.7287749,0 C27.1875035,0 27.5813436,0.163234989 27.9037365,0.483255403 L38.0131368,10.5155432 C38.3355464,10.8355636 38.5,11.2286241 38.5,11.681798 L38.5,20.3504953 C38.5,21.2568431 37.751295,22 36.8381808,22 L36.8406513,22 Z M34.0904513,10.3522917 L26.3181227,2.63975741 L21.1595589,2.63975741 L21.1595589,10.3522917 L34.0904513,10.3522917 Z" id="Fill-2"></path>
      <path d="M28.5,17 C30.7098774,17 32.5,18.7901226 32.5,21 C32.5,23.2098774 30.7098774,25 28.5,25 C26.2901226,25 24.5,23.2098774 24.5,21 C24.5,18.7901226 26.2901226,17 28.5,17 Z" id="Fill-3"></path>
      <path d="M4.5,16.5 C3.25694398,16.5 2.13196973,17.0035155 1.31774259,17.8177426 C0.503515456,18.6319697 1.11022302e-16,19.756944 1.11022302e-16,21 C1.11022302e-16,22.243056 0.503515456,23.3680303 1.31774259,24.1822574 C2.13196973,24.9964845 3.25694398,25.5 4.5,25.5 C5.74305602,25.5 6.86803027,24.9964845 7.68225741,24.1822574 C8.49648454,23.3680303 9,22.243056 9,21 C9,19.756944 8.49648454,18.6319697 7.68225741,17.8177426 C6.86803027,17.0035155 5.74305602,16.5 4.5,16.5 Z" id="Fill-4" stroke="#FFFFFF"></path>
    </svg>
  );
};

export default TruckMinIcon;