import React from 'react';
import { connect } from 'react-redux';
import { createEntity, deleteEntity, getEntityItems, partialEntity, readEntities, SaveItemCounter } from '../../redux/api';
import { groupFacilities } from '../../shared/utils';
import TaxAddressRow from './tax-address-row';

class TaxAddressListView extends React.Component {
  state = {

  }

  componentDidMount() {
    this.props.loadAddressList({
      company_id: this.props.companyId
    })
    if(this.props.companyType === 1){
      this.props.loadCompanyVehicles({ company_id: this.props.companyId })
    } else {
      this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    }
    // this.props.loadCityCatalog({}, {prefix: 'orbisat.'});
    // this.props.loadSuburbCatalog({}, {prefix: 'orbisat.'});
    // this.props.loadStateCatalog({}, {prefix: 'orbisat.'});
    // this.props.loadCountryCatalog({}, {prefix: 'orbisat.'});
    // this.props.loadZipcodeCatalog({}, {prefix: 'orbisat.'});
  }

  saveFacilities = (tax_info, facilities, callback) => {
    // let counter = new SaveItemCounter(() => {
    //   callback && callback();
    //   this.props.loadAddressList({
    //     company_id: this.props.companyId
    //   });
    // });

    // if(newFacilities.length){
    //   counter.add();
      this.props.updateTaxInfo({
        company_id: this.props.companyId,
        tax_info_id: tax_info,
        link_facilities: facilities
      }, {
        onSuccess: () => {
          // counter.done()
          callback && callback();
          this.props.loadAddressList({
            company_id: this.props.companyId
          });
        }
      })
    // }
    // newFacilities.forEach(f => {
    //   counter.add();
    //   this.props.linkFacilityTaxInfo({
    //     company_id: this.props.companyId,
    //     facility_id: f,
    //     tax_info
    //   }, {
    //     onSuccess: () => {counter.done()}
    //   })
    // });

    // oldFacilities.forEach(f => {
    //   counter.add();
    //   this.props.deleteFacilityTaxInfo({
    //     company_id: this.props.companyId,
    //     // facility_id: f.id,
    //     facility_tax_info_id: f.facility_tax_info_id
    //   }, {
    //     onSuccess: () => {counter.done()}
    //   })
    // });
  }
  
  saveVehicles = (tax_info, vehicles, callback) => {
    // let counter = new SaveItemCounter(() => {
    //   this.props.loadCompanyVehicles({ company_id: this.props.companyId })
    //   this.props.loadAddressList({
    //     company_id: this.props.companyId,
    //   });
    //   callback && callback();
    // });
    
    // for(let i = 0; i < (oldVehicles.length + (newVehicles.length ? 1 : 0)); i++){
    //   counter.add();
    // }

    // oldVehicles.forEach(f => {
    //   this.props.deleteVehicleTaxInfo({
    //     company_id: this.props.companyId,
    //     // facility_id: f.id,
    //     fleet_data_tax_info_id: f.id
    //   }, {
    //     onSuccess: () => {counter.done()}
    //   })
    // });

    // if(newVehicles.length){
      this.props.updateTaxInfo({
        company_id: this.props.companyId,
        tax_info_id: tax_info,
        link_vehicles: vehicles
      }, {
        onSuccess: () => {
          // counter.done()
          this.props.loadCompanyVehicles({ company_id: this.props.companyId })
          this.props.loadAddressList({
            company_id: this.props.companyId,
          });
          callback && callback();
        }
      })
    // }
    // newVehicles.forEach(f => {
    //   counter.add();
    //   this.props.linkFacilityTaxInfo({
    //     company_id: this.props.companyId,
    //     facility_id: f,
    //     tax_info
    //   }, {
    //     onSuccess: () => {counter.done()}
    //   })
    // });

    
  }

  deleteAddress = (addressId, callback) => {
    this.props.deleteTaxInfo({
      company_id: this.props.companyId,
      tax_info_id: addressId,
    }, {
      onSuccess: () => {
        callback && callback();
        this.props.loadAddressList({
          company_id: this.props.companyId,
        });
      }
    })
  }

  render() {
    return (
      <div className="tax-address-tab">
        <div className="title">Domicilios Fiscales</div>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <div 
            className="add-icon"
            onClick={() => {this.props.history.push('/company/billingaddress/')}}
          >+</div>
        </div>
        <div className="address-list">
          {
            this.props.addressList.map((address, i) => {
              return(
                <TaxAddressRow 
                  key={'address-'.concat(i)}
                  address={address}
                  companyType={this.props.companyType}//1
                  history={this.props.history}
                  // cityCatalog={this.props.cityCatalog}
                  // suburbCatalog={this.props.suburbCatalog}
                  // zipCodeCatalog={this.props.zipCodeCatalog}
                  // stateCatalog={this.props.stateCatalog}
                  // countryCatalog={this.props.countryCatalog}
                  facilities={this.props.facilitiesByTag}
                  // selectedFacilities={[]}
                  saveFacilities={(facilities, callback) => this.saveFacilities(address.id, facilities, callback)}
                  // removeFacilities={(facilities, callback) => this.removeFacilities(address.id, facilities, callback)}

                  vehicles={this.props.vehiclesByTag}
                  saveVehicles={(vehicles, callback) => this.saveVehicles(address.id, vehicles, callback)}

                  deleteAddress={this.deleteAddress}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

// export default TaxAddressListView;

const mapStateToProps = (state) => {
  const addressList = getEntityItems(state, "COMPANIES.SAT.ADDRESS");
  const cityCatalog = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CIUDAD");
  const suburbCatalog = getEntityItems(state, "COMPANIES.SAT.CATALOGS.COLONIA");
  const zipCodeCatalog = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CODIGOPOSTAL");
  const stateCatalog = getEntityItems(state, "COMPANIES.SAT.CATALOGS.ESTADO");
  const countryCatalog = getEntityItems(state, "COMPANIES.SAT.CATALOGS.PAIS");
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);
  const vehiclesByTag = getEntityItems(state, "COMPANIES.CATALOGS.VEHICLE").map(t => ({
    id: t.id,
    description: t.title,
    items: t.economic_numbers.map(e => ({id: e.id, is_assembled: e.tax_info, description: e.economic_number.concat('/', e.plates)}))
  }));
  return {
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
    addressList,
    cityCatalog,
    suburbCatalog,
    stateCatalog,
    countryCatalog,
    zipCodeCatalog,
    facilitiesByTag,
    vehiclesByTag
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCompanyVehicles: (params, opts) =>
      dispatch(readEntities("COMPANIES.CATALOGS.VEHICLE", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    loadAddressList: (params, opt) =>{
      dispatch(
        readEntities("COMPANIES.SAT.ADDRESS", params, opt) 
      );
    },
    // linkFacilityTaxInfo:  (params, opt) =>{
    //   dispatch(
    //     createEntity("COMPANIES.SAT.ADDRESS.FACILITY.LINK", params, opt) 
    //   );
    // },
    updateTaxInfo: (params, opt) =>{
      dispatch(
        partialEntity("COMPANIES.SAT.ADDRESS.UPDATE", params, opt) 
      );
    },
    deleteTaxInfo: (params, opt) =>{
      dispatch(
        deleteEntity("COMPANIES.SAT.ADDRESS.ERASE", params, opt) 
      );
    },
    deleteFacilityTaxInfo:  (params, opt) =>{
      dispatch(
        deleteEntity("COMPANIES.SAT.ADDRESS.FACILITY.ERASE", params, opt) 
      );
    },
    deleteVehicleTaxInfo:  (params, opt) =>{
      dispatch(
        deleteEntity("COMPANIES.SAT.ADDRESS.VEHICLE.ERASE", params, opt) 
      );
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxAddressListView);