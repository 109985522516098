// @flow
import * as React from "react";
import { connect } from "react-redux";
import TextInput from "../controls/text-input";
import SVGIcon from "../svg-icon";
import { icons } from "../svg-icon";
import { listCompanyUsersFacilities } from "../../redux/reducers/company-reducer";
import TabSelectorRibbon from "../controls/tab-selector-ribbon";
import PrevSwitch from "../controls/prev-switch";
import AlertRow from "./alert-row";
import { listUserNotificationFacilities } from "../../redux/reducers/notifications-reducer";
import { listCompanyNotifications } from "../../redux/reducers/notifications-reducer";
import { listFacilityNotifications } from "../../redux/reducers/notifications-reducer";
import { updateUserNotificationFacility } from "../../redux/reducers/notifications-reducer";
// import { ListCompanyAlerts } from "../../redux/reducers/alerts-reducer";
// import { listFacilityAlerts } from "../../redux/reducers/alerts-reducer";
// import { listFacilityAlertUsers } from "../../redux/reducers/alerts-reducer";
// import { listUserAlertFacilities } from "../../redux/reducers/alerts-reducer";
// import { updateUserAlertFacility } from "../../redux/reducers/alerts-reducer";

type Props = {
  userId: number,
  facilities: Array<any>,
  // listCompanyUsersFacilities: Function,
  // listFacilityAlerts: Function,
  notifications: Array<any>
};

type State = {
  textSearch: string,
  selectedFacilityId: number
};

class UserNotificationList extends React.Component<Props, State> {
  state = {
    textSearch: "",
    selectedFacilityId: 0
  };

  componentDidMount() {
    this.props
      .listCompanyUsersFacilities(this.props.userId)
      .then(() => this.setFacility((this.props.facilities[0] || {}).id));
  }

  handleChanges = e => {
    const name = e.target.name || e.target.attributes["name"].value;
    const value = e.target.value;
    const checked = e.target.checked;
    switch (name) {
      case "textSearch":
        this.setState({ [name]: value });
        break;
      case "email":
        this.props.updateUserNotificationFacility(
          this.state.selectedFacilityId,
          value.notification_facility,
          value.id,
          checked,
          value.push_notifications,
          value.user_company
        );
        break;
      case "push":
        this.props.updateUserNotificationFacility(
          this.state.selectedFacilityId,
          value.notification_facility,
          value.id,
          value.email_notifications,
          checked,
          value.user_company
        );
        break;
      default:
    }
  };

  render() {
    return (
      <div>
        <div style={{ width: "250px", margin: "auto" }}>
          <TextInput
            value={this.state.textSearch}
            label="Buscar por instalaciones"
            name="textSearch"
            onChange={this.handleChanges}
            suffix={{
              element: (
                <SVGIcon
                  name={icons.search}
                  fill="#AFC8FF"
                  style={{ right: "0%", position: "absolute" }}
                />
              )
            }}
          />
        </div>
        <div>
          <TabSelectorRibbon
            activeId={this.state.selectedFacilityId}
            items={this.props.facilities
              .filter(f => f.code.includes(this.state.textSearch))
              .map(f => ({
                id: f.id,
                description: f.code
              }))}
            onChange={e =>
              this.setFacility((e.target.attributes["name"] || {}).value)
            }
          />
        </div>
        <div>
          {this.props.notifications.map(ca => (
            <AlertRow
              alert={ca}
              key={ca.id}
              onAlertDataChange={() => {}}
              onLevelDataChange={this.handleChanges}
              loadEmailList={() => {}}
              mode={"alerts-compact"}
            />
          ))}
        </div>
      </div>
    );
  }

  alertLevelConfig = (push: boolean = false, email: boolean = false) => {
    return (
      <React.Fragment>
        <PrevSwitch handleClick={() => {}} checked={push} />

        <PrevSwitch handleClick={() => {}} checked={email} />
      </React.Fragment>
    );
  };

  setFacility = id => {
    // if (id) {
    this.props.listUserNotificationFacilities(this.props.userId);
    this.setState({ selectedFacilityId: parseInt(id) });
    this.props.listFacilityNotifications(id);
    // }
  };
}

const mapStateToProps = state => {
  const notifications = state.notifications.companyNotifications.data
    .map(ca => {
      const levels = state.notifications.facilityNotifications.data
        .filter(
          fa =>
            fa.notifications === ca.id &&
            state.notifications.userFacilityNotifications.data.find(
              ufa => ufa.notification_facility === fa.id
            )
        )
        .map(fa => ({
          ...fa,
          notificationFacility: state.notifications.userFacilityNotifications.data.find(
            ufa => ufa.notification_facility === fa.id
          )
        }))
        .sort((a, b) => a.level - b.level)
        .map(fa => ({
          notificationFacility: fa.notificationFacility,
          id: fa.id,
          status: fa.status,
          level: fa.level,
          emails: { email_list: [], user_list: [] },
          push: fa.notificationFacility.push_notifications,
          email: fa.notificationFacility.email_notifications
        }));
      const active =
        levels.filter(lvl => lvl.push || lvl.email).length > 0 ? true : false;
      return {
        id: ca.id,
        name: ca.name || ca.description,
        description: ca.description || ca.name,
        levels,
        active
      };
    })
    .filter(ca => ca.levels.length > 0);
  return {
    notifications,
    r: state.notifications.userFacilityNotifications.data,
    facilities: state.companyReducer.companyUsersFacilities.data || [],
    facilityAlerts: state.alerts.facilityAlerts.data
  };
};
const mapDispatchToProps = dispatch => {
  dispatch(listCompanyNotifications());
  return {
    listCompanyUsersFacilities: userId =>
      dispatch(listCompanyUsersFacilities(userId)),
    listFacilityNotifications: facilityId =>
      dispatch(listFacilityNotifications(facilityId)),
    // listFacilityAlertUsers: (facilityId, alertFacilityId) =>
    //   dispatch(listFacilityAlertUsers(facilityId, alertFacilityId)),
    listUserNotificationFacilities: userId =>
      dispatch(listUserNotificationFacilities(userId)),
    updateUserNotificationFacility: (
      facility_id,
      alert_facility_id,
      id,
      email_notifications,
      push_notifications,
      user_company
    ) =>
      dispatch(
        updateUserNotificationFacility(
          facility_id,
          alert_facility_id,
          id,
          email_notifications,
          push_notifications,
          user_company
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotificationList);
