// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { View, ViewTitle } from "../shared/styled/view";
import FilterBarCompact from "../shared/component/filter/filter-bar-compact";
import { cancelRequest, readEntities } from "../redux/api/actions";
import OptionSelectorSwitch from "../shared/controls/option-selector-switch";
import { Flex } from "../shared/styled/flex";
import GSPActivityHeatmap from "./gps/gps-activity-heatmap";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";

type Props = {};
type State = {
  +textSearch: string,
  +selectedPlates: array,
  +selectedContracts: array,
  +selectedCargoOwner: array,
};

let typingTimer;
// var activityHeatMap = null;

class GPSActivityReportView extends Component<Props, State> {
  state = {
    buttonFilterName: "Aplicar",
    textSearch: '',
    selectedPlates: [],
    selectedContracts: [],
    selectedCargoOwner: [],
    currentDate: new Date().toISOString().split("T")[0],

    loadType: 'received',

    filterApplied: false
  };

  componentDidMount() {
    // this.loadReport(this.state.currentDate);
    this.props.loadFilters(this.props.companyId);
    this.props.setNavbarAction("activity-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("activity-report");
  }

  loadReport = (date, executeWs = false) => {
    if(this.state.buttonFilterName === 'Aplicar' || executeWs){
      this.props.cancelRequest("COMPANIES.GPSACTIVITY.REPORT");
      if (date === '') {
        this.props.loadReport(this.props.companyId, this.state.currentDate, this.state.loadType, this.state.selectedPlates, this.state.selectedContracts, this.state.selectedCargoOwner, this.state.textSearch);
      } else {  
        this.setState({currentDate: date}, () => {
          this.props.loadReport(this.props.companyId, date, this.state.loadType, this.state.selectedPlates, this.state.selectedContracts, this.state.selectedCargoOwner, this.state.textSearch);
        });
      }
      // if (activityHeatMap !== null) {
      //   activityHeatMap.resetScrollbar();
      // }
    }
  }

  switchFleetType = e => {
    if (e.target.attributes["name"]) {
      this.setState({
        textSearch: '',
        selectedPlates: [],
        selectedContracts: [],
        selectedCargoOwner: [],
        buttonFilterName: "Aplicar"
      }, () => {
        this.loadReport('');
      });

    }
  };

  //FILTERS
  doSearch = () => {
    this.setState({});
    this.loadReport('');
  }
  setSearchStr = (e) => {
    this.setState({ textSearch: e.target.value || "" });
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      this.loadReport('')
    }, 1000);
  };

  getFilterContent= () => {
    if (`${this.props.companyType}` === '1') {
      return [
        {
          title: "Unidades",
          items: this.props.assembliesData,
          name: "selectedPlates",
          listType: 3,
        },
        {
          title: "Instalación",
          items: this.props.contractsData,
          name: "selectedContracts",
          listType: 5,
        },
        {
          title: "Transportista",
          items: this.props.cargoOwnerData,
          name: "selectedCargoOwner",
          listType: 3,
        }
      ]
    } else {
      return [
        {
          title: "Instalación",
          items: this.props.contractsData,
          name: "selectedContracts",
          listType: 5,
        },
        {
          title: "Transportista",
          items: this.props.transportLinesData,
          name: "selectedCargoOwner",
          listType: 3,
        }
      ]
    }
  }

  manageFilter = (e) => {
    this.setState({[e.target.name]: e.target.value, buttonFilterName: "Aplicar" });
  }

  handleNewExemptionInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case 'loadType': 
        this.setState({
          loadType: value
        }, () => {
          if(this.state.filterApplied){
            this.loadReport('', true);
          }
        });
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  transformReportData = () => {
    return Object.keys(this.props.reportData).map( (key, item) => {

      const content = Array.isArray(this.props.reportData[key]) ? this.props.reportData[key].map((contentData) => {
        const contentToReturn = Object.keys(contentData).map( (contentKey, contentItem) => {
          return {
            title: contentKey,
            data: Array.isArray(contentData[contentKey]) ? contentData[contentKey].map( (data) => {
              
              var date = data['date'];
              const dateStrings = date.split('T');
              if (dateStrings.length > 1) {
                date = dateStrings[1];
              } 

              date = date.replace(':00Z', '');

              return {...data, time: date};

            }) : []
          }
        });

        if (contentToReturn.length > 0) {
          return contentToReturn[0];
        } else {
          return null;
        }

      }) : [];

      return {
        title: key,
        content
      }
    });
  }

  transformColors = () => {
    var colors = [];

    if (Array.isArray(this.props.colorsData)) {
      for( var i = 0; i < this.props.colorsData.length; i++ ) {
        colors = colors.concat({...this.props.colorsData[i], description: this.props.colorsData[i].code});
      }
    }

    return colors;
  }

  render () {
    const { loadType } = this.state;
    return (
      <View>
        <ViewTitle>
          <span>
            Reporte de actividad GPS
          </span>
          <FilterBarCompact
            filtered={false}
            searchAction={this.setSearchStr}
            searchStr={this.state.textSearch}
            searchPlaceholder={'Buscar placa / eco'}
            content={this.getFilterContent()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState({ buttonFilterName: "Restablecer", filterApplied: true });
              } else {
                this.setState({
                  selectedPlates: [],
                  selectedCargoOwner: [],
                  selectedContracts: [],
                  buttonFilterName: "Aplicar",
                  filterApplied: false
                });
              }
            }}
            selectedItems={{
              selectedPlates: this.state.selectedPlates,
              selectedContracts: this.state.selectedContracts,
              selectedCargoOwner: this.state.selectedCargoOwner
            }}
            buttonFilterName={this.state.buttonFilterName}
            onChange={this.manageFilter}
            onSearch={this.doSearch}
          />
        </ViewTitle>

        <Flex>
          <div
            style={{
              width: "300px",
              marginLeft: "50%",
              transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={loadType}
              name="loadType"
              onChange={this.handleChange}
              items={[
                { description: "Recibidas", value: "received" },
                { description: "Aceptadas", value: "approved" },
              ]}
            />
          </div>
        </Flex>
        
        <GSPActivityHeatmap
          filterApplied={ this.state.filterApplied }
          // ref={ ref => { activityHeatMap = ref; }}
          companyId={this.props.companyId}
          isLoading={this.props.isLoadingReport}
          data={this.transformReportData()}
          colors={this.transformColors()}
          loadType={loadType}
          handleDate={(value) => {
            const { selectedDateId } = value;
            this.setState({
              currentDate: selectedDateId
            }, () => {
              if(this.state.filterApplied){
                this.loadReport(selectedDateId, true);
              }
            });
          }}
        />

      </View>
    )
  }
}

const mapStateToProps = (state) => { 

  const isLoadingReport = state?.api["COMPANIES.GPSACTIVITY.REPORT"]?.status?.isFetching || false;
  const reportData = state?.api["COMPANIES.GPSACTIVITY.REPORT"]?.items || {};

  const colorsData = state?.api["COMPANIES.GPSACTIVITY.COLORS"]?.items || {};
  const filtersData = state?.api["COMPANIES.GPSACTIVITY.FILTERS"]?.items || {};

  const contractsData = Object.keys(filtersData?.contracts || {}).map( (key, item) => {
    return {
      id: item,
      description: key,
      items: (filtersData?.contracts[key] || []).map( (filterData) => {
        const { facility_id, alias, code, name} = filterData;
        return { id: facility_id, description: `${name} - ${alias} - ${code}`}
      })
    }
  });
  const assembliesData = (filtersData?.assemblies || []).map( (value) => {
    const { id, economic_number, plates } = value;
    return {
      id, description: `${economic_number} / ${plates}`
    }
  });
  const cargoOwnerData = (filtersData?.cargo_owners || []).map( (value) => {
    const { secondary_companies, secondary_orbi_id, secondary_alias, secondary_code } = value;
    return {
      id: secondary_companies, description: `${secondary_orbi_id} - ${secondary_alias} - ${secondary_code}`
    }
  });
  const transportLinesData = (filtersData?.transport_lines || []).map( (value) => {
    const { secondary_companies, secondary_orbi_id, secondary_alias, secondary_code } = value;
    return {
      id: secondary_companies, description: `${secondary_orbi_id} - ${secondary_alias} - ${secondary_code}`
    }
  }).sort((a, b) => a.description.localeCompare(b.description));

  return {
    companyId: state.globalCatalog.session.company.id,
    companyType: state.globalCatalog.session.company.type.id,
    isLoadingReport,
    reportData,
    colorsData,
    contractsData,
    assembliesData,
    cargoOwnerData,
    transportLinesData
  };
};
const mapDispatchToProps = (dispatch) => {

  dispatch(readEntities('COMPANIES.GPSACTIVITY.COLORS'));

  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadReport: (company_id, date, view, assemblies, contracts, transport_lines, search) => dispatch(readEntities("COMPANIES.GPSACTIVITY.REPORT", {company_id, date, view, transport_lines, contracts, assemblies, search})),
    loadFilters: (company_id) => dispatch(readEntities("COMPANIES.GPSACTIVITY.FILTERS", {company_id})),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GPSActivityReportView);