import React from "react";
const TATFactory = (props) => {
  return (
    <svg 
      width="24px" 
      height="22px" 
      viewBox="0 0 24 22" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      {...props}
    >
      <g id="vistas" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M0.5,21.8740645 L0.5,5.90427094 L9.35438712,11 L9.35438712,5.90427094 L18.5,11.5 L18.5,0.485290496 L23.5,0.485290496 L23.5,21.8740645 L0.5,21.8740645 Z M2.5,14.5 L2.5,17.5 L7.5,17.5 L7.5,14.5 L2.5,14.5 Z M9.5,14.5 L9.5,17.5 L14.5,17.5 L14.5,14.5 L9.5,14.5 Z M16.5,14.5 L16.5,17.5 L21.5,17.5 L21.5,14.5 L16.5,14.5 Z" id="ic/fabrica_azul" fill="#4CABFF">
        </path>
      </g>
    </svg>
  );
};

export default TATFactory;