import React from "react";
const FillIcon = (props) => {
  return (
    <svg width="68px" height="54px" viewBox="0 0 68 54" {...props}>
      <path
        d="M51.551069,35.2252171 L36.3404839,10.2376545 C35.3287316,8.57189932 33.6716224,8.5953547 32.6597302,10.2610782 L17.4491433,35.2140749 C16.437391,36.8798301 17.1934682,38.1666667 19.1294935,38.1666667 L49.8691752,38.1666667 C51.8065761,38.1666667 52.5626462,36.8880836 51.5508905,35.2251148 L51.551069,35.2252171 Z M34.503081,14.8333333 C35.6279723,14.8333333 36.500115,15.9860058 36.4416701,17.3949943 L36.0846065,25.8842458 C36.027731,27.291772 35.3120021,28.4444444 34.4983448,28.4444444 C33.68311,28.4444444 32.9673731,27.291772 32.9105056,25.8842458 L32.5581823,17.3949943 C32.5013068,15.9860058 33.3749945,14.8333333 34.503081,14.8333333 Z M34.488659,34.2777778 C33.3281095,34.2777778 32.5315847,33.4562338 32.5561064,32.3333333 C32.5561064,31.1854318 33.3539201,30.3888889 34.488659,30.3888889 C35.6711524,30.3888889 36.4186008,31.1866803 36.4444444,32.3333333 C36.4431523,33.4562338 35.6711854,34.2777778 34.488659,34.2777778 Z"
        id="Fill-1"
        fill="#FF5A60"
        {...props}
      ></path>
    </svg>
  );
};

export default FillIcon;
