import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../shared/controls/button";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import Checkbox from "../../shared/controls/checkbox";
import { AttentionSpanContainerGrid } from "orbi_v2_frontend_components";
import SVGIcon, { icons } from "../../shared/svg-icon";
import {
  listSpaces,
  listSpaceCalendar,
  createSpaceSchedule,
  listSpaceScheduleCapacities,
  createSpaceScheduleCapacity,
  deleteSpaceSchedule,
  updateSpaceSchedule,
  deleteSpaceScheduleCapacity,
  updateSpaceScheduleCapacity,
  listSpaceScheduleCapacityTransport,
  createSpaceScheduleCapacityTransport,
  deleteSpaceScheduleCapacityTransport,
  listSpaceCapacitySummary,
  loadSpaceTags,
  listSpaceWindowCapacities,
  listSpaceWindowCapacityTransport,
} from "../../redux/reducers/facility-reducer";
import ExceedPlus from "../../shared/controls/exceed-plus";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { months_full } from "../../shared/utils";
import ErrorDialog from "../../shared/dialog/error-dialog";
import OrbiSelector, {
  ORBI_SELECTOR_LISTS,
} from "../../shared/controls/orbi-selector";
import { listExpeditionClasses } from "../../redux/reducers/company-reducer";
import Tag from "../../shared/controls/tag";
import { loadTags } from "../../redux/reducers/tags.reducer";
import ValidatePerms from "../../shared/component/validate-perms";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
// import Caret from "./caret";
import styled from "styled-components";
import Caret from "../../shared/component/caret";
import { readEntity, partialEntity } from "../../redux/api";
import Loader from '../../shared/icons/tat/loader';
import TextInput from "../../shared/controls/text-input";
import GenericDialog from '../../shared/dialog/generic-modal';
import { cloneDeep } from 'lodash';

class FacilitySchedulesProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWindowDialog: false,
      showConfirmWindowDialog: false,
      showErrorDialog: false,
      maneuverFilter: "all",
      currentSpace: {},
      currentSchedule: {},
      capacities: [],
      selectedDateId: new Date().toISOString().split("T")[0],
      selectedDate: new Date(),
      currentDate: new Date(),
      expeditionClasses: [],
      editClasses: false,
      selectedCapacitySummary: {},
      currentConflicts: [],
      isEngineHovered: false,
      isDefaultManueverTimeLoading: false,
      defaultManueverTime: {
        isEditMode: false,
        full: '0',
        single: '0',
        unit: '0'
      },
      defaultManueverTimeMessage: '',
      maxElementsToShow: 15,
      isLoadingSpaces: false,
    };
  }
  counter = -99999;

  componentDidMount() {
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    if (this.props.facilityId) this.loadSpaces();
    this.props.getDefaultTime({
        company_id: this.props.companyId,
        facility_id: this.props.facilityId,
      }, {
        onSuccess: (m) => {
          // debugger;
          this.showDefaultManueverTime(m);
        }
      }
    )
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  handlePageContentScroll = (data) => {
    const e = data.detail;
    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const validation = ((e.target.clientHeight + 2) > scrollTop) && ((e.target.clientHeight - 2) < scrollTop);

    if (validation && (this.props.spaces || []).length > this.state.maxElementsToShow) {
      this.props.spaces.slice(this.state.maxElementsToShow, this.state.maxElementsToShow + 15).forEach((sp) =>
        this.props.loadSpaceTags(this.props.facilityId, sp.id)
      );

      this.setState({maxElementsToShow: this.state.maxElementsToShow + 15});
    }

  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.facilityId !== this.props.facilityId)
  //     this.loadSpaces();
  // }

  loadSpaces() {
    this.setState({
      isLoadingSpaces: true,
      maxElementsToShow: 15
    });
    
    this.props.loadSpaces(this.props.facilityId).then(() => {

      // this.props.spaces.forEach((sp) =>
      //   this.props.loadSpaceTags(this.props.facilityId, sp.id)
      // );

      this.props.spaces.slice(0, this.state.maxElementsToShow).forEach((sp) =>
        this.props.loadSpaceTags(this.props.facilityId, sp.id)
      );
      this.props.listCalendar(
        this.props.facilityId,
        this.state.selectedDate.toISOString().split("T")[0]
      );
      this.props
        .listSpaceCapacitySummary(
          this.props.facilityId,
          this.state.selectedDate.toISOString().split("T")[0]
        )
        .then((r) => {
          // if (!this.state.selectedCapacitySummary.code) {
          this.setState({
            selectedCapacitySummary: this.props.capacitiesSummary[0] || {},
          });
          // }
          // if (!this.props.capacitiesSummary.length)
          // this.setState({
          //   selectedCapacitySummary: this.props.capacitiesSummary[0] || {}
          // });
        });
    });
  }

  scheduleConflictDetector = (schedule) => {
    let prospect = {
      // days: [].concat(schedule.repeatDays === '2' ? [1, 2, 3, 4, 5, 6, 7] : schedule.days || []).filter(d => d !== schedule.day || schedule.weekdays).concat([schedule.day || schedule.weekdays]),
      spaces: []
        .concat(
          schedule.allSpaces
            ? this.props.spaces.map((sp) => sp.id)
            : schedule.spaces || []
        )
        .filter((sp) => sp.toString() !== this.state.currentSpace.id.toString())
        .concat([this.state.currentSpace.id]),
      begin: schedule.begin,
      end: schedule.end,
    };
    if (Object.keys(schedule).length === 0 && schedule.constructor === Object)
      return;
    let conflictWindows = [];

    // debugger;
    // prospect.days.forEach(day => {
    prospect.spaces.forEach((space) => {
      (
        (
          this.props.spaces.find(
            (fi) => fi.id.toString() === space.toString()
          ) || {}
        ).schedules || []
      )
        // .filter(fi => fi.weekdays === day)
        .forEach((existent) => {
          existent.begin = existent.hour_from * 100 + existent.minute_from;
          existent.end = existent.hour_to * 100 + existent.minute_to;
          if (existent.begin < prospect.begin) {
            if (existent.end > prospect.begin) {
              conflictWindows.push(existent);
            }
          } else if (prospect.begin < existent.begin) {
            if (prospect.end > existent.begin) {
              conflictWindows.push(existent);
            }
          } else {
            conflictWindows.push(existent);
          }
        });
    });
    // })
    this.setState({ currentConflicts: conflictWindows });
  };

  handleSaveSchedule = () => {
    // debugger;
    let {
      currentSchedule,
      currentSpace,
      capacities,
      selectedDate,
      expeditionClasses,
      currentConflicts,
    } = this.state;
    if (currentSchedule.schedule_id && currentConflicts.length === 0) {
      this.props
        .updateSpaceSchedule(
          this.props.facilityId,
          currentSpace.id,
          currentSchedule.schedule_id,
          parseInt(currentSchedule.begin / 100),
          currentSchedule.begin % 100,
          parseInt(currentSchedule.end / 100),
          currentSchedule.end % 100,
          currentSchedule.day
        )
        .then(() => {
          capacities.forEach((capacity, i) => {
            if (capacity.invisible)
              this.props.deleteSpaceScheduleCapacity(
                this.props.facilityId,
                currentSpace.id,
                currentSchedule.schedule_id,
                capacity.id
              );
            else if (capacity.id < 0)
              this.props
                .createSpaceScheduleCapacity(
                  this.props.facilityId,
                  currentSpace.id,
                  currentSchedule.schedule_id,
                  capacity.single_load,
                  capacity.single_unload,
                  capacity.doble_load,
                  capacity.doble_unload,
                  capacity.single_loadunload,
                  capacity.doble_loadunload,
                  capacity.dobleequipment
                )
                .then((r) => {
                  if (r.response) {
                    expeditionClasses
                      .filter((f) => f.currentCapacityId === capacity.id)
                      .forEach((ec) =>
                        this.props.createSpaceScheduleCapacityTransport(
                          this.props.facilityId,
                          currentSpace.id,
                          currentSchedule.schedule_id,
                          r.response.id,
                          ec.id
                        )
                      );
                  }
                });
            else
              this.props
                .updateSpaceScheduleCapacity(
                  this.props.facilityId,
                  currentSpace.id,
                  currentSchedule.schedule_id,
                  capacity.id,
                  capacity.single_load,
                  capacity.single_unload,
                  capacity.doble_load,
                  capacity.doble_unload,
                  capacity.single_loadunload,
                  capacity.doble_loadunload,
                  capacity.dobleequipment
                )
                .then(() => {
                  expeditionClasses
                    .filter((f) => f.currentCapacityId === null)
                    .forEach((ec) =>
                      this.props.deleteSpaceScheduleCapacityTransport(
                        this.props.facilityId,
                        currentSpace.id,
                        currentSchedule.schedule_id,
                        capacity.id,
                        ec.relationId
                      )
                    );
                  expeditionClasses
                    .filter(
                      (f) =>
                        f.currentCapacityId !== f.originalCapacityId &&
                        f.originalCapacityId === capacity.id
                    )
                    .forEach((ec) =>
                      this.props.deleteSpaceScheduleCapacityTransport(
                        this.props.facilityId,
                        currentSpace.id,
                        currentSchedule.schedule_id,
                        capacity.id,
                        ec.relationId
                      )
                    );
                  expeditionClasses
                    .filter(
                      (f) =>
                        f.currentCapacityId === capacity.id &&
                        f.currentCapacityId !== f.originalCapacityId
                    )
                    .forEach((ec) =>
                      this.props.createSpaceScheduleCapacityTransport(
                        this.props.facilityId,
                        currentSpace.id,
                        currentSchedule.schedule_id,
                        capacity.id,
                        ec.id
                      )
                    );
                });
            if (i === capacities.length - 1) this.loadSpaces();
          });
        });
    } else {
      let spaceIds = [];
      if (currentSchedule.allSpaces)
        spaceIds = this.props.spaces.map((sp) => sp.id);
      else if (currentSchedule.spaces) spaceIds = currentSchedule.spaces;
      else spaceIds.push(currentSpace.id);
      let count = spaceIds.length;
      currentConflicts.forEach((conflict) =>
        this.props.deleteSpaceSchedule(
          conflict.facilities_id,
          conflict.space_id,
          conflict.schedule_id
        )
      );
      spaceIds.forEach((spId) => {
        this.props
          .createSpaceSchedule(
            this.props.facilityId,
            spId,
            parseInt(currentSchedule.begin / 100),
            currentSchedule.begin % 100,
            parseInt(currentSchedule.end / 100),
            currentSchedule.end % 100,
            selectedDate.toISOString().split("T")[0]
          )
          .then((schedule) => {
            if (schedule.response) {
              // debugger;
              capacities.filter(cap => !cap.invisible).forEach((cap) =>
                this.props
                  .createSpaceScheduleCapacity(
                    this.props.facilityId,
                    spId,
                    schedule.response.id,
                    cap.single_load,
                    cap.single_unload,
                    cap.doble_load,
                    cap.doble_unload,
                    cap.single_loadunload,
                    cap.doble_loadunload,
                    cap.dobleequipment
                  )
                  .then((capacity) => {
                    if (capacity.response) {
                      expeditionClasses
                        .filter((f) => f.currentCapacityId === cap.id)
                        .forEach((ec) =>
                          this.props.createSpaceScheduleCapacityTransport(
                            this.props.facilityId,
                            currentSpace.id,
                            schedule.response.id,
                            capacity.response.id,
                            ec.id
                          )
                        );
                    }
                  })
              );
            } else if (schedule.error) {
              this.handleErrorDialog();
            }
          })
          .then(() => (count < 1 ? count-- : this.loadSpaces()));
      });
    }
    this.handleScheduleDialog();
  };

  handleDeleteScheduleDialog = (scheduleId) => {
    if (this.state.selectedDateId >= new Date().toISOString().split("T")[0]) {
      let currentSpace = {};
      let currentSchedule = {};
      if (parseInt(scheduleId)) {
        if (scheduleId < 0) {
          this.props.spaces.forEach((sp) => {
            let sch = sp.schedules.find((w) => w.window_id === scheduleId * -1);
            if (sch ? true : false) {
              currentSpace = sp;
              currentSchedule = sch;
              currentSchedule.begin = sch.hour_from * 100 + sch.minute_from;
              currentSchedule.end = sch.hour_to * 100 + sch.minute_to;
            }
          });
        } else {
          this.props.spaces.forEach((sp) => {
            let sch = sp.schedules.find((w) => w.schedule_id === scheduleId);
            if (sch ? true : false) {
              currentSpace = sp;
              currentSchedule = sch;
              currentSchedule.begin = sch.hour_from * 100 + sch.minute_from;
              currentSchedule.end = sch.hour_to * 100 + sch.minute_to;
            }
          });
        }
      } else if (scheduleId) {
        if (this.state.currentSchedule.window_id) {
          this.props
            .createSpaceSchedule(
              this.props.facilityId,
              this.state.currentSpace.id,
              parseInt(this.state.currentSchedule.begin || 0 / 100),
              this.state.currentSchedule.begin || 0 % 100,
              parseInt(this.state.currentSchedule.end / 100),
              this.state.currentSchedule.end % 100,
              this.state.selectedDate.toISOString().split("T")[0],
              false
            )
            .then(() => this.loadSpaces());
        } else {
          this.props
            .deleteSpaceSchedule(
              this.props.facilityId,
              this.state.currentSpace.id,
              this.state.currentSchedule.schedule_id
            )
            .then(() => this.loadSpaces());
        }
      }
      this.setState({
        currentSpace,
        currentSchedule,
        showConfirmWindowDialog: !this.state.showConfirmWindowDialog,
      });
    }
  };

  handleChange = (e) => {
    const name =
      e.target.name || (e.target.attributes["name"] || {}).value || "";
    let currentSpace = Object.assign({}, this.state.currentSpace);
    let currentSchedule = Object.assign({}, this.state.currentSchedule);
    let capacities = [...this.state.capacities];
    let expeditionClasses = [...this.state.expeditionClasses];
    switch (name) {
      case "startHour":
        currentSchedule.begin =
          (currentSchedule.begin % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "startMinute":
        currentSchedule.begin =
          parseInt(currentSchedule.begin / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
      case "endHour":
        currentSchedule.end =
          (currentSchedule.end % 100) +
          this.limitNumber(parseInt(e.target.value), 23) * 100;
        break;
      case "endMinute":
        currentSchedule.end =
          parseInt(currentSchedule.end / 100) * 100 +
          this.limitNumber(parseInt(e.target.value), 59);
        break;
      case "allDay":
        if (e.target.checked) {
          currentSchedule.begin = 0;
          currentSchedule.end = 2359;
        }
        break;
      case "removeCapacity":
        if (e.target.value < 0) {
          capacities = capacities.filter((c) => c.id !== e.target.value);
        } else {
          capacities.find(
            (c) => c.id.toString() === e.target.value.toString()
          ).invisible = true;
        }

        expeditionClasses
          .filter(
            (c) =>
              (c.currentCapacityId || "").toString() ===
              e.target.value.toString()
          )
          .forEach((c) => {
            c.currentCapacityId = null;
            c.active = false;
          });
        break;
      case "allSpaces":
        currentSchedule.allSpaces = e.target.checked;
        break;
      case "addSpace":
        currentSchedule.spaces = (currentSchedule.spaces || []).filter(
          (s) => s.toString() !== e.target.value.toString()
        );
        if (e.target.checked) currentSchedule.spaces.push(e.target.value);
        break;
      case "addDay":
        currentSchedule.days = (currentSchedule.days || []).filter(
          (s) => s.toString() !== e.target.value.toString()
        );
        if (e.target.checked) currentSchedule.days.push(e.target.value);
        break;
      case "repeatDays":
        currentSchedule.repeatDays = e.target.value;
        if (e.target.value === "3") this.setState({ showDays: true });
        else this.setState({ showDays: false });
        break;
      case "single_load":
      case "single_unload":
      case "doble_load":
      case "doble_unload":
      case "single_loadunload":
      case "doble_loadunload":
        (capacities.find((c) => c.id.toString() === e.target.id.toString()) ||
          {})[e.target.name] = e.target.value;
        break;
      case "expClass":
        if (e.target.value === "-1") {
          let x = capacities.find((c) => c.id === this.state.currentCapacityId);
          expeditionClasses
            .filter((c) => c.equipment_quantity === (x.dobleequipment ? 2 : 1))
            .filter(
              (c) =>
                c.currentCapacityId === this.state.currentCapacityId ||
                !c.active
            )
            .forEach((c) => {
              c.active = e.target.checked;
              c.currentCapacityId = e.target.checked
                ? this.state.currentCapacityId
                : null;
            });
        } else {
          (
            expeditionClasses.find(
              (c) => c.id.toString() === e.target.value.toString()
            ) || {}
          ).active = e.target.checked;
          (
            expeditionClasses.find(
              (c) => c.id.toString() === e.target.value.toString()
            ) || {}
          ).currentCapacityId = e.target.checked
            ? this.state.currentCapacityId
            : null;
        }
        break;
      case "capacitySummary":
        this.setState({
          selectedCapacitySummary: this.props.capacitiesSummary.find(
            (cs) => cs.class_id === e.target.value
          ),
        });
        break;
      default:
    }
    this.scheduleConflictDetector(currentSchedule);
    this.setState({ currentSpace, currentSchedule, capacities });
  };

  handleScheduleDialog = (index, initTime) => {
    if (this.state.selectedDateId >= new Date().toISOString().split("T")[0]) {
      initTime *= 100;
      let currentSpace = {};
      let currentSchedule = {};
      let capacities = [];
      let expeditionClasses = this.props.expClasses;
      let currentConflicts = [];
      let editClasses = false;
      if (parseInt(index) && isNaN(initTime)) {
        this.props.spaces.forEach((sp, ind) => {
          let sch = sp.schedules.find((w) => w.schedule_id === index);
          if (sch ? true : false) {
            this.props
              .listSpaceScheduleCapacities(
                this.props.facilityId,
                sp.id,
                sch.schedule_id
              )
              .then(() => {
                let caps = this.props.capacities;
                caps.forEach((capacity) =>
                  this.props
                    .listSpaceScheduleCapacityTransport(
                      this.props.facilityId,
                      sp.id,
                      sch.schedule_id,
                      capacity.id
                    )
                    .then((aver) => {
                      if (aver.response)
                        aver.response.forEach((x) => {
                          (
                            expeditionClasses.find(
                              (l) => l.id === x.transport_class
                            ) || {}
                          ).active = true;
                          (
                            expeditionClasses.find(
                              (l) => l.id === x.transport_class
                            ) || {}
                          ).currentCapacityId = capacity.id;
                          (
                            expeditionClasses.find(
                              (l) => l.id === x.transport_class
                            ) || {}
                          ).originalCapacityId = capacity.id;
                          (
                            expeditionClasses.find(
                              (l) => l.id === x.transport_class
                            ) || {}
                          ).relationId = x.id;
                        });
                      this.setState({ capacities: caps });
                    })
                );
              });
            currentSpace = sp;
            currentSpace.name = ind + 1;
            currentSchedule = sch;
            currentSchedule.begin = sch.hour_from * 100 + sch.minute_from;
            currentSchedule.end = sch.hour_to * 100 + sch.minute_to;
          }
        });
        if (index < 0) {
          this.props.spaces.forEach((sp, ind) => {
            let sch = sp.schedules.find((w) => w.window_id === index * -1);
            if (sch ? true : false) {
              // this.props.listSpaceScheduleCapacities(this.props.facilityId, sp.id, sch.window_id).then(() =>
              this.props
                .listSpaceWIndowCapacities(
                  this.props.facilityId,
                  sp.id,
                  sch.window_id
                )
                .then(() => {
                  let caps = this.props.windowCapacities;
                  caps.forEach((capacity) =>
                    this.props
                      .listSpaceWindowCapacityTransport(
                        this.props.facilityId,
                        sp.id,
                        sch.window_id,
                        capacity.id
                      )
                      .then((aver) => {
                        if (aver.response)
                          aver.response.forEach((x) => {
                            (
                              expeditionClasses.find(
                                (l) => l.id === x.transport_class
                              ) || {}
                            ).active = true;
                            (
                              expeditionClasses.find(
                                (l) => l.id === x.transport_class
                              ) || {}
                            ).currentCapacityId = capacity.id;
                            (
                              expeditionClasses.find(
                                (l) => l.id === x.transport_class
                              ) || {}
                            ).originalCapacityId = capacity.id;
                            (
                              expeditionClasses.find(
                                (l) => l.id === x.transport_class
                              ) || {}
                            ).relationId = x.id;
                          });
                        this.setState({ capacities: caps });
                      })
                  );

                  // this.setState({ capacities: this.props.windowCapacities })
                  // capacities = this.props.capacities
                });
              currentSpace = sp;
              currentSpace.name = ind + 1;
              currentSchedule = sch;
              currentSchedule.begin = sch.hour_from * 100 + sch.minute_from;
              currentSchedule.end = sch.hour_to * 100 + sch.minute_to;
            }
          });
        }
      } else if (index) {
        currentSpace = this.props.spaces[index - 1];
        currentSpace.name = index;
        currentSchedule = {
          day: this.state.selectedDate,
          begin: initTime,
          end: initTime + 100,
          spaceIndex: index,
          id: null,
        };
      }
      this.setState({
        currentSpace,
        currentSchedule,
        showWindowDialog: !this.state.showWindowDialog,
        capacities,
        showDays: false,
        showSpaces: false,
        expeditionClasses,
        editClasses,
        currentConflicts,
      });
    }
  };

  handleChangeDay = (e) => {
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = this.state.currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      });
      this.loadSpaces();
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState({
        selectedDate: d,
        selectedDateId: e.target.attributes["name"].value,
      });
      this.loadSpaces();
    }
  };

  handleTimeInput = (e) => {
    let tms = e.target.value.replace(":", "");
    this.handleChange({ target: { name: e.target.name, value: tms } });
  };

  getRibbonDates = () => {
    let c = 1;
    let dat = this.state.currentDate;
    let d = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate());
    let x = [];
    while (c <= 10) {
      x.push({
        id: d.toISOString().split("T")[0],
        description: d
          .toString()
          .split(" ")
          .slice(1, 3)
          .join()
          .replace(",", "\n"),
      });
      d.setDate(d.getDate() + 1);
      c++;
    }

    let y = [{ id: -10, description: "❰" }];
    y = y.concat(x);
    y.push({ id: 10, description: "❱" });
    return y;
  };

  filterManeuver = (e) => {
    this.setState({ maneuverFilter: e.target.id.split("-")[1] });
  };

  getCapacityValues = (single = true) => {
    let { maneuverFilter, selectedCapacitySummary } = this.state;
    switch (maneuverFilter) {
      case "load":
        return single
          ? selectedCapacitySummary.single_load
          : selectedCapacitySummary.double_load;
      case "unload":
        return single
          ? selectedCapacitySummary.single_unload
          : selectedCapacitySummary.double_unload;
      case "loadUnload":
        return single
          ? selectedCapacitySummary.single_loadunload
          : selectedCapacitySummary.double_loadunload;
      default:
        return single
          ? [
              selectedCapacitySummary.single_load,
              selectedCapacitySummary.single_unload,
              selectedCapacitySummary.single_loadunload,
            ].sort((a, b) => a - b)[0]
          : [
              selectedCapacitySummary.double_load,
              selectedCapacitySummary.double_unload,
              selectedCapacitySummary.double_loadunload,
            ].sort((a, b) => a - b)[0];
    }
  };

  showDefaultManueverTime = (manuever) => {

    let fullTime = '0';
    let singleTime = '0';
    let unitTime = '0';

    if (manuever !== null) { 

      if (manuever.full_operation_minutes) {
        fullTime = `${manuever.full_operation_minutes}`
      }

      if (manuever.single_operation_minutes) {
        singleTime = `${manuever.single_operation_minutes}`
      }

      if (manuever.unit_operation_minutes) {
        unitTime = `${manuever.unit_operation_minutes}`
      }

    }
    
    let manueverTime = this.state.defaultManueverTime;

    manueverTime.full = fullTime;
    manueverTime.single = singleTime;
    manueverTime.unit = unitTime;

    this.setState({
      defaultManueverTime: manueverTime
    });

  }

  setDefaultManueverMessage = (message) => {

    let tempValue = this.state.defaultManueverTime;
    tempValue.isEditMode = false;

    this.setState({
      defaultManueverTime: tempValue,
      defaultManueverTimeMessage: message,
      isDefaultManueverTimeLoading: false
    });

  }

  getSpaces = () => {
    return this.props.spaces.slice(0, this.state.maxElementsToShow);
  }

  render() {
    let spaces_data = this.getSpaces();

    return (
      <React.Fragment>
        <div className="facility-schedules-header" style={{height: 86, minHeight:86, paddingBottom: 10}}>
          <div className="maneuver-filters-container">
            <div className="maneuver-filters">
              <div
                id="maneuver-load"
                className={"maneuver-filter-item ".concat(
                  this.state.maneuverFilter === "load" ? "selected" : ""
                )}
                onClick={this.filterManeuver}
              >
                Carga
              </div>
              <div
                id="maneuver-unload"
                className={"maneuver-filter-item ".concat(
                  this.state.maneuverFilter === "unload" ? "selected" : ""
                )}
                onClick={this.filterManeuver}
              >
                Descarga
              </div>
              <div
                id="maneuver-loadUnload"
                className={"maneuver-filter-item ".concat(
                  this.state.maneuverFilter === "loadUnload" ? "selected" : ""
                )}
                onClick={this.filterManeuver}
              >
                Carga y descarga
              </div>
              <div
                id="maneuver-all"
                className={"maneuver-filter-item ".concat(
                  this.state.maneuverFilter === "all" ? "selected" : ""
                )}
                onClick={this.filterManeuver}
              >
                Todas
              </div>
            </div>
          </div>
          <div className="facility-schedules-capacity-summary">
            <span>Capacidad total del día:</span>
            <SVGIcon
              name={icons.TruckDouble}
              fill="white"
              viewBox="0 -6 70 30"
              width="80px"
              height="22px"
            />
            {this.getCapacityValues(false) || 0}
            <SVGIcon
              name={icons.truckSingle}
              fill="white"
              viewBox="0 -6 40 30"
              width="60px"
              height="22px"
            />
            {this.getCapacityValues() || 0}
          </div>
          <div className="facility-schedules-types">
            <div className="equipment-selector">
              <OrbiSelector
                title={this.state.selectedCapacitySummary.code}
                items={this.props.capacitiesSummary.map((cs) => ({
                  id: cs.class_id,
                  description: cs.code,
                }))}
                onChange={this.handleChange}
                listType={ORBI_SELECTOR_LISTS.SIMPLE_LIST}
                showCaret={true}
                name="capacitySummary"
                className="capacity-selector"
              />
            </div>
          </div>
          <ValidatePerms
            eval="or"
            perms={[
              {
                id: "33",
                perm: "c",
              },
              {
                id: "33",
                perm: "r",
              },
              {
                id: "33",
                perm: "u",
              },
              {
                id: "33",
                perm: "d",
              },
            ]}
          >
            <div 
              className="config-gear" 
              onClick={this.switchEditMode}
              style={{
                cursor: "pointer",
              }}
              onMouseEnter={() => this.setState({ isEngineHovered: true })}
              onMouseLeave={() => this.setState({ isEngineHovered: false})}
            >
              <img 
                width={ this.state.isEngineHovered ? "35px" : "30px"} 
                height={ this.state.isEngineHovered ? "35px" : "30px"} 
                src="/images/menu/gear.svg" alt="" />
            </div>
          </ValidatePerms>
          {/* <div className="default-time-selection">
            
                <div style={{width: 115}}>
                  {'Tiempos de atención\ndefault en minutos:'}
                </div>

                <ManueverTimeComponent>
                  <TextInput 
                    label="Full"
                    name="full"
                    type="number"
                    value={this.state.defaultManueverTime.full}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.full = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent>

                <ManueverTimeComponent>
                  <TextInput
                    label="Sencillo"
                    name="sencillo"
                    type="number"
                    value={this.state.defaultManueverTime.single}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.single = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent>

                <ManueverTimeComponent>
                  <TextInput 
                    label="Unitario"
                    name="unit"
                    type="number"
                    value={this.state.defaultManueverTime.unit}
                    disabled={!this.state.defaultManueverTime.isEditMode}
                    onChange={(e) => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.unit = `${e.target.value}`.replace(/[^0-9]/g, '');

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  />
                </ManueverTimeComponent> 

                <div style={{width: 15}}/>
                
                {
                  this.state.defaultManueverTime.isEditMode ?
                  <Button
                    settings={{
                      type: "primary small",
                      text: this.state.isDefaultManueverTimeLoading ? <Loader /> : "Guardar",
                      className: "mb-10",
                      onClick: () => {

                        if (this.state.isDefaultManueverTimeLoading) { return; }

                        this.setState({
                          isDefaultManueverTimeLoading: true
                        }, () => {

                          this.props.updateDefaultTime({
                            company_id: this.props.companyId,
                            facility_id: this.props.facilityId,
                            full_operation_minutes: this.state.defaultManueverTime.full,
                            single_operation_minutes: this.state.defaultManueverTime.single,
                            unit_operation_minutes: this.state.defaultManueverTime.unit,
                          }, {
                            onSuccess: (response) => {
                              this.setDefaultManueverMessage("Se guardó con éxito los tiempo de atención");
                            },
                            onError: () => {
                              this.setDefaultManueverMessage("Ocurrió un error, intente de nuevo");
                            }
                          });

                        });
                      }
                    }}
                  /> : <EditManueverTimeComponent
                    onClick={() => {
                      let tempValue = this.state.defaultManueverTime;
                      tempValue.isEditMode = true;

                      this.setState({
                        defaultManueverTime: tempValue
                      });
                    }}
                  >
                    <img alt="" src="/images/edit-transparent.svg" />
                  </EditManueverTimeComponent>
                }
          </div> */}
        </div>
        <div className="facility-schedules">
          <div className="ribbon-container" style={{overflow: 'hidden'}}>
            <TabSelectorRibbon
              className="profile-calendar-ribbon"
              onChange={this.handleChangeDay}
              items={this.getRibbonDates()}
              activeId={this.state.selectedDateId}
            />
            <ReactDatePicker
              onChange={this.handleChangeDay}
              customInput={
                <div className="month-selector">
                  {months_full[this.state.selectedDate.getMonth()]}
                  <SVGIcon name={icons.calendar} fill={"#79A3FF"} />
                </div>
              }
              dateFormat="MM/yyyy"
              // showMonthYearPicker
            />
          </div>
          <div
            style={{ minHeight: "auto", width: "100%", position: "relative" }}
          >
            <div
              style={{ height: "auto", overflowX: "auto", overflowY: "auto" }}
            >
              <div
                style={{
                  minWidth: "2000px",
                  width: "100%",
                  color: "white",
                }}
              >
                <AttentionSpanContainerGrid
                  // compact={true}
                  editable={false}
                  spaces={
                    spaces_data.map((sp, index) => ({
                      className: (this.state.maneuverFilter === "all"
                      ? true
                      : sp[this.state.maneuverFilter.toLowerCase()])
                        ? ""
                        : "row-disabled",
                      id: index + 1,
                      onEditSpace: this.handleSpaceDialog,
                      onDeleteSpace: this.handleDeleteSpaceDialog,
                      // onCreateWindow: validateObjPerms(
                      //   [{ id: "34", perm: "c" }],
                      //   { r: true }
                      // ).r
                      //   ? this.handleScheduleDialog
                      //   : undefined,
                      onCreateWindow: null,
                      // onEditWindow: validateObjPerms(
                      //   [{ id: "34", perm: "u" }],
                      //   { r: true }
                      // ).r
                      //   ? this.handleScheduleDialog
                      //   : undefined,
                      onEditWindow: null,
                      // onDeleteWindow: validateObjPerms(
                      //   [{ id: "34", perm: "d" }],
                      //   { r: true }
                      // ).r
                      //   ? this.handleDeleteScheduleDialog
                      //   : undefined,
                      onDeleteWindow: null,
                      load: sp.load,
                      tagElement: (
                        <ExceedPlus maxVisibleitems="1" name={sp.id}>
                          {(sp.tags || [])
                            .map((spt) =>
                              this.props.tags.find((prt) => prt.id === spt.tags)
                            )
                            .map((t) => (
                              <Tag
                                key={t?.id}
                                id={t?.id}
                                color={t?.color}
                                title={t?.title}
                              />
                            ))}
                        </ExceedPlus>
                      ),
                      unload: sp.unload,
                      loadUnload: sp.loadunload,
                      // enabled: this.state.maneuverFilter === 'all' ? true : sp[this.state.maneuverFilter],
                      attentionSpans: sp.schedules.map((w) => ({
                        id: w.schedule_id || w.window_id * -1,
                        startTime: parseInt(w.hour_from) * 100 + w.minute_from,
                        endTime: parseInt(w.hour_to) * 100 + w.minute_to,
                        first_day: w.next_day === '1' ? w.first_day === '1' : undefined
                      })),
                    }))
                    // .filter(sp =>
                    //   this.state.maneuverFilter === 'all' ? true :
                    //     sp[this.state.maneuverFilter]
                    // )
                  }
                  visible={this.props.gridVisible}
                />
              </div>
            </div>
            {
              (this.state.isLoadingSpaces && (this.props.spaces || []).length === 0) &&
              <div
                style={{
                  height: 140,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: 23
                }}
              >
                Cargando
                <Loader width="60px" height="60px"/>
              </div>
            }
          </div>

          <ConfirmDialog
            closeAction={this.handleScheduleDialog}
            open={this.state.showWindowDialog}
            title=""
            className="spaces-window-modal"
            acceptText="Guardar"
            acceptAction={this.handleSaveSchedule}
            contentObject={this.getScheduleContent()}
            showCancel={true}
            cancelText="Cancelar"
            disabledAccept={this.validWindowData()}
          />

          <ConfirmDialog
            closeAction={this.handleDeleteScheduleDialog}
            open={this.state.showConfirmWindowDialog}
            title="Eliminar"
            message={"¿Estas seguro de eliminar la ventana?"}
            className=""
            acceptText="Eliminar"
            acceptAction={this.handleDeleteScheduleDialog}
            showCancel={true}
            cancelText="Cancelar"
          />

          <ErrorDialog
            closeAction={this.handleErrorDialog}
            open={this.state.showErrorDialog}
            message="Ocurrio un error a registrar tus datos"
            acceptText="Cerrar"
            acceptAction={this.handleErrorDialog}
          />

          <GenericDialog
            open={this.state.defaultManueverTimeMessage !== ''}
            title="Listo!"
            message= {this.state.defaultManueverTimeMessage}
            acceptAction={() => {
              this.setState({defaultManueverTimeMessage: ''});
            }}
            closeAction={() => {
              this.setState({defaultManueverTimeMessage: ''});
            }}
          />

        </div>
      </React.Fragment>
    );
  }

  validWindowData = () => {
    let {
      currentSpace,
      currentSchedule,
      capacities,
      expeditionClasses,
    } = this.state;
    let caps = capacities.filter((cap) => !cap.invisible);

    let x =
      currentSchedule.begin < currentSchedule.end &&
      caps.length > 0 &&
      caps.every(
        (cap) =>
          expeditionClasses.filter(
            (xp) => xp.active && xp.currentCapacityId === cap.id
          ).length > 0
      ) &&
      caps.every((cap) =>
        currentSpace.load
          ? parseInt(cap.single_load) || parseInt(cap.doble_load)
          : true
      ) &&
      caps.every((cap) =>
        currentSpace.unload
          ? parseInt(cap.single_unload) || parseInt(cap.doble_unload)
          : true
      ) &&
      caps.every((cap) =>
        currentSpace.loadunload
          ? parseInt(cap.single_loadunload) || parseInt(cap.doble_loadunload)
          : true
      );
    return !x;
  };

  switchEditMode = () => {
    this.props.switchEditView(true);
  };

  handleErrorDialog = () => {
    this.setState({ showErrorDialog: !this.state.showErrorDialog });
  };

  getScheduleContent = () => {
    let { currentSpace, currentSchedule, showSpaces, capacities } = this.state;
    return (
      <React.Fragment>
        <div className="space-window-dialog">
          <div className="header">
            <div className="title">
              {/* {`Espacio ${currentSpace.name}`} */}
              {`${
                currentSchedule.schedule_id || currentSchedule.window_id
                  ? "Editar"
                  : "Crear"
              } ventana en espacio ${currentSpace.name}`}
            </div>
            <div className="attention-span-data">
              <div className="time-block">
                <div className="title">Horario de atención:</div>
                <div className="data">
                  de:
                  <input
                    name="startHour"
                    type="number"
                    value={this.formatNumberToTimePart(
                      currentSchedule.begin,
                      true
                    )}
                    onChange={this.handleTimeInput}
                  />
                  :
                  <input
                    name="startMinute"
                    type="number"
                    value={this.formatNumberToTimePart(currentSchedule.begin)}
                    onChange={this.handleTimeInput}
                  />
                  a:
                  <input
                    name="endHour"
                    type="number"
                    value={this.formatNumberToTimePart(
                      currentSchedule.end,
                      true
                    )}
                    onChange={this.handleTimeInput}
                  />
                  :
                  <input
                    name="endMinute"
                    type="number"
                    value={this.formatNumberToTimePart(currentSchedule.end)}
                    onChange={this.handleTimeInput}
                  />
                  <Checkbox
                    name="allDay"
                    item={{
                      id: 0,
                      description: "Todo el día",
                    }}
                    active={
                      currentSchedule.begin === 0 &&
                      currentSchedule.end === 2359
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div
                className="replicate-block"
                // style={{
                //   display: currentSchedule.schedule_id ? "none" : "block",
                // }}
              >
                <div className="title">Aplicar a otros espacios:</div>
                <div className="spaces">
                  <Checkbox
                    item={{
                      id: 0,
                      description: "Aplicar a todos",
                    }}
                    active={currentSchedule.allSpaces}
                    name="allSpaces"
                    onChange={this.handleChange}
                  />
                  <div className="select-spaces">
                    <SVGIcon
                      name={icons.dotBoard}
                      fill="#79A3FF"
                      onClick={() => this.setState({ showSpaces: !showSpaces })}
                    />
                    Seleccionar espacios
                    <div
                      className={
                        showSpaces ? "tooltip-spaces-container" : "hidden"
                      }
                    >
                      {this.props.spaces.map((sp, ind) => (
                        <Checkbox
                          key={sp.id}
                          name="addSpace"
                          item={{
                            id: sp.id,
                            description: `espacio ${ind + 1}`,
                            active: (
                              [].concat((currentSchedule || {}).spaces) || []
                            ).find(
                              (ws) => (ws || "").toString() === sp.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          onChange={this.handleChange}
                        />
                      ))}
                      <Button
                        type="primary small"
                        text="cerrar"
                        className="close-action"
                        onClick={() => this.setState({ showSpaces: false })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="capacity">
            <div className="capacity-title">Tipo de atención(min.):</div>
            <div className="capacity-header">
              <div className="header-content">
                <div className="titles">
                  <div className="classes">
                    <SVGIcon
                      name={icons.truckSingle}
                      fill="#1F8EFA"
                      viewBox="0 -6 45 36"
                      width="25px"
                      height="20px"
                    />
                    Clases sencillo
                  </div>
                  <div
                    className="load"
                    style={
                      this.state.currentSpace.load ? {} : { display: "none" }
                    }
                  >
                    Carga
                  </div>
                  <div
                    className="unload"
                    style={
                      this.state.currentSpace.unload ? {} : { display: "none" }
                    }
                  >
                    Descarga
                  </div>
                  <div
                    className="load-unload"
                    style={
                      this.state.currentSpace.loadunload
                        ? {}
                        : { display: "none" }
                    }
                  >
                    Carga y Descarga
                  </div>
                </div>
              </div>
            </div>
            <div className="capacity-rows">
              {capacities
                .filter((c) => !c.dobleequipment && !c.invisible)
                .map((cp) => this.getCapacityRowContent(cp))}
            </div>
            <div className="dialog-actions">
              <Button
                type="primary small outline dark circle"
                name="addCapacity"
                onClick={this.addCapacity}
                text="+"
              />
            </div>
            <div className="division"></div>
            <div className="capacity-header">
              <div className="header-content">
                <div className="titles">
                  <div className="classes">
                    <SVGIcon
                      name={icons.TruckDouble}
                      fill="#1F8EFA"
                      viewBox="0 0 80 30"
                      width="40px"
                      height="22px"
                    />
                    Clases full
                  </div>
                  <div
                    className="load"
                    style={
                      this.state.currentSpace.load ? {} : { display: "none" }
                    }
                  >
                    Carga
                  </div>
                  <div
                    className="unload"
                    style={
                      this.state.currentSpace.unload ? {} : { display: "none" }
                    }
                  >
                    Descarga
                  </div>
                  <div
                    className="load-unload"
                    style={
                      this.state.currentSpace.loadunload
                        ? {}
                        : { display: "none" }
                    }
                  >
                    Carga y Descarga
                  </div>
                </div>
              </div>
            </div>
            <div className="capacity-rows">
              {capacities
                .filter((c) => c.dobleequipment && !c.invisible)
                .map((cp) => this.getCapacityRowContent(cp))}
            </div>
            <div style={{ position: "absolute" }}>
              <div style={{ position: "relative", display: "block" }}></div>
            </div>
            <div className="dialog-actions">
              <Button
                type="primary small outline dark circle"
                name="addCapacity"
                onClick={(e) => this.addCapacity(e, true)}
                text="+"
              />
            </div>
          </div>
          {this.state.currentConflicts.filter(
            (cc) =>
              (cc.window_id || cc.schedule_id) !== currentSchedule.schedule_id
          ).length ? (
            <div style={{ color: "#fb8a8a", marginTop: "15px" }}>
              {`Existe(n) ${
                this.state.currentConflicts.filter(
                  (cc) =>
                    (cc.window_id || cc.schedule_id) !==
                    currentSchedule.schedule_id
                ).length
              } conflicto(s). Las ventanas en conflicto se eliminaran`}
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </React.Fragment>
    );
  };

  handleEditClasses = (item) => {
    let capacity = item.dobleequipment ? 2 : 1;
    if (!this.state.editClasses && capacity && item.id) {
      this.setState({
        editClasses: capacity,
        currentCapacityId: item.id,
      });
    } else if (
      this.state.editClasses !== capacity ||
      this.state.currentCapacityId !== item.id
    ) {
      this.setState({
        editClasses: capacity,
        currentCapacityId: item.id,
      });
    } else {
      this.setState({
        editClasses: false,
        currentCapacityId: null,
      });
    }
  };

  addCapacity = (e, dobleequipment = false) => {
    let newCapacity = {
      single_load: 0,
      single_unload: 0,
      doble_load: 0,
      doble_unload: 0,
      single_loadunload: 0,
      doble_loadunload: 0,
      dobleequipment,
      id: this.counter++,
    };
    this.handleEditClasses(newCapacity);
    this.setState({ capacities: this.state.capacities.concat([newCapacity]) });
  };

  removeCapacity = (id = null) => {
    if (id) {
      this.props.deleteSpaceWindowCapacity(
        this.props.facilityId,
        this.state.currentSpace.id,
        this.state.currentSchedule.id,
        id
      );
    }
  };

  getCapacityRowContent = (item) => {
    return (
      <div key={item.id} className="row">
        <div className="equipment-inputs classes">
          {/* <ExpeditionClassSelect /> */}
          <div
            style={{ width: "100%", height: "100%" }}
            onClick={() => {
              this.handleEditClasses(item);
            }}
          >
            <ExceedPlus name={item.id} maxVisibleItems={3}>
              {this.state.expeditionClasses
                .filter((f) => f.active && f.currentCapacityId === item.id)
                .map((exc) => (
                  <span className="span-label" key={exc.id}>
                    {exc.description}{" "}
                  </span>
                ))}
            </ExceedPlus>
          </div>
          <Caret
            up={
              this.state.editClasses === (item.dobleequipment ? 2 : 1) &&
              item.id === this.state.currentCapacityId
            }
          />
          <OrbiSelectorStyled
            manageByState={false}
            onClose={() => {
              this.setState({
                editClasses: false,
                currentCapacityId: null,
              });
              // this.handleEditClasses(item);
            }}
            className={"classes-selector"}
            isOpen={
              this.state.editClasses === (item.dobleequipment ? 2 : 1) &&
              item.id === this.state.currentCapacityId
            }
            title=""
            items={[
              {
                id: -1,
                description: "Todos",
                equipment_quantity: item.dobleequipment ? 2 : 1,
              },
            ]
              .concat(
                (this.state.expeditionClasses || [])
                  .filter((c) => c.tags.length === 0)
                  .filter(
                    (ec) =>
                      (ec.equipment_quantity ===
                        (item.dobleequipment ? 2 : 1) &&
                        !ec.active) ||
                      ec.currentCapacityId === this.state.currentCapacityId
                  )
                  .concat(
                    (
                      (
                        this.state.capacities.find(
                          (cp) => cp.id === this.state.currentCapacityId
                        ) || {}
                      ).expeditionClasses || []
                    ).filter((g) => g.active) || []
                  )
              )
              .concat(
                this.props.classTags
                  .map((ct) => ({
                    ...ct,
                    items: (this.state.expeditionClasses || [])
                      .filter((c) => c.tags.length > 0)
                      .filter((c) => c.tags.some((t) => t === ct.id))
                      .filter(
                        (ec) =>
                          (ec.equipment_quantity ===
                            (item.dobleequipment ? 2 : 1) &&
                            !ec.active) ||
                          ec.currentCapacityId === this.state.currentCapacityId
                      )
                      .concat(
                        (
                          (
                            this.state.capacities.find(
                              (cp) => cp.id === this.state.currentCapacityId
                            ) || {}
                          ).expeditionClasses || []
                        ).filter((g) => g.active) || []
                      ),
                  }))
                  .filter((ct) => ct.items.length > 0)
              )
              .sort((a, b) => a.id - b.id)}
            selected={[]}
            listType={ORBI_SELECTOR_LISTS.CHECKLISTRECURSIVE}
            name="expClass"
            onChange={this.handleChange}
          />
        </div>
        <div
          className="inputs load"
          style={this.state.currentSpace.load ? {} : { display: "none" }}
        >
          <input
            name={item.dobleequipment ? "doble_load" : "single_load"}
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={item.dobleequipment ? item.doble_load : item.single_load}
          />
        </div>
        <div
          className="inputs unload"
          style={this.state.currentSpace.unload ? {} : { display: "none" }}
        >
          <input
            name={item.dobleequipment ? "doble_unload" : "single_unload"}
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={item.dobleequipment ? item.doble_unload : item.single_unload}
          />
        </div>
        <div
          className="inputs load-unload"
          style={this.state.currentSpace.loadunload ? {} : { display: "none" }}
        >
          <input
            name={
              item.dobleequipment ? "doble_loadunload" : "single_loadunload"
            }
            onChange={(e) => this.handleManeuverValues(e, item)}
            value={
              item.dobleequipment
                ? item.doble_loadunload
                : item.single_loadunload
            }
          />
        </div>
        <div className="action-inputs">
          <SVGIcon
            name={icons.trash}
            fill="#1F8EFA"
            onClick={() =>
              this.handleChange({
                target: { name: "removeCapacity", value: item.id },
              })
            }
          />
        </div>
      </div>
    );
  };

  handleManeuverValues = (e, i) => {
    if (RegExp(/^[0-9]*$/).test(e.target.value) && e.target.value.length < 4) {
      this.handleChange({
        target: { value: e.target.value, name: e.target.name, id: i.id },
      });
    }
  };
  formatNumberToTimePart = (number, hour = false) => {
    let hours = parseInt(number / 100);
    hours = this.limitNumber(hours, 23);
    let minutes = parseInt(number) % 100;
    minutes = this.limitNumber(minutes, 59);
    hours = "00".concat(hours.toString()).substr(-2, 2);
    minutes = "00".concat(minutes.toString()).substr(-2, 2);
    return hour ? hours : minutes;
  };

  limitNumber = (number, upperLimit, lowerLimit = 0) => {
    number = number < lowerLimit ? lowerLimit : number;
    number = number > upperLimit ? upperLimit : number;
    return number;
  };
}

const mapStateToProps = (state) => {
  const classTags = (state.tags.tags || [])
    .filter((tag) => tag.type === 8)
    .map((t) => ({ id: t.id, description: t.title }));
  let schedules = state.facilityReducer.spaceCalendar.data || [];
  let spaces = (state.facilityReducer.spaces.data || [])
    .sort((a, b) => a.id - b.id)
    .map((sp) => {
      sp.tags = sp.tags || [];
      sp.schedules = schedules.filter((w) => w.space_id === sp.id && w.hour_from !== null);
      return sp;
    });

    for (let j in (spaces || [])) {
      let tempSpace = cloneDeep(spaces[j]);
      let tempSpace2 = cloneDeep(spaces[j]);
      tempSpace.schedules = [];
  
      for (let i in tempSpace2.schedules) {
        let tempSchedule = tempSpace2.schedules[i];

        if (tempSchedule.next_day === '1') {

          if (tempSchedule.first_day === '1') {
            tempSchedule.hour_to = 23;
            tempSchedule.minute_to = 59;
          } else if (tempSchedule.first_day === '0') {
            tempSchedule.hour_from = 0;
            tempSchedule.minute_from = 0;
          }

          tempSpace.schedules.push(tempSchedule);
        } else {
          tempSpace.schedules.push(tempSchedule);
        }
  
      }

      spaces[j] = tempSpace;
    }

  // const classesExp = classTags.map((t) => ({
  //   id: t.id,
  //   description: t.title,
  //   items: (state.companyReducer.companyExpeditionClasses.data || [])
  //     .filter((c) => c.tags.some((s) => s.id === t.id))
  //     .map((ec) => ({
  //       description: ec.code,
  //       equipment_quantity: ec.equipment_quantity,
  //       id: ec.id,
  //     })),
  // }));
  let expClasses = (
    state.companyReducer.companyExpeditionClasses.data || []
  ).map((ec) => ({
    description: ec.code,
    equipment_quantity: ec.equipment_quantity,
    id: ec.id,
    tags: ec.tags.map((t) => t.id),
  }));
  return {
    companyId: state.globalCatalog.session.company.id,
    classTags,
    windowCapacities: state.facilityReducer.spaceWindowCapacities.data || [],
    capacitiesSummary: (
      state.facilityReducer.spaceCapacitySummary.data || []
    ).filter((scs) => parseInt(scs.single_load) >= 0),
    expClasses,
    tags: (state.tags.tags || []).filter((tag) => tag.type === 7),

    capacities: state.facilityReducer.spaceScheduleCapacities.data || [],
    spaces,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadTags());
  dispatch(listExpeditionClasses());
  return {
    getDefaultTime: (params, opt = {}) =>
      dispatch(
        readEntity(
          "COMPANIES.FACILITIES.DEFAULTTIME",
          params,
          opt
        )
      ),
    updateDefaultTime: (params, opt = {}) =>
      dispatch(
        partialEntity(
          "COMPANIES.FACILITIES.DEFAULTTIME",
          params,
          opt
        )
      ),
    loadSpaces: (facilityId) => dispatch(listSpaces(facilityId)),
    listCalendar: (facilityId, date) =>
      dispatch(listSpaceCalendar(facilityId, date)),
    loadSpaceTags: (facilityId, spaceId) =>
      dispatch(loadSpaceTags(facilityId, spaceId)),

    createSpaceSchedule: (
      facilityId,
      spaceId,
      hourFrom,
      minuteForm,
      hourTo,
      minuteTo,
      day,
      is_open = true
    ) =>
      dispatch(
        createSpaceSchedule(
          facilityId,
          spaceId,
          hourFrom,
          minuteForm,
          hourTo,
          minuteTo,
          day,
          is_open
        )
      ),
    listSpaceScheduleCapacities: (facilityId, spaceId, scheduleId) =>
      dispatch(listSpaceScheduleCapacities(facilityId, spaceId, scheduleId)),
    deleteSpaceSchedule: (facilityId, spaceId, scheduleId) =>
      dispatch(deleteSpaceSchedule(facilityId, spaceId, scheduleId)),
    updateSpaceSchedule: (
      facilityId,
      spaceId,
      scheduleId,
      hourFrom,
      minuteFrom,
      hourTo,
      minuteTo,
      day,
      is_open = true
    ) =>
      dispatch(
        updateSpaceSchedule(
          facilityId,
          spaceId,
          scheduleId,
          hourFrom,
          minuteFrom,
          hourTo,
          minuteTo,
          day,
          is_open
        )
      ),
    listSpaceWIndowCapacities: (facilityId, spaceId, windowId) =>
      dispatch(listSpaceWindowCapacities(facilityId, spaceId, windowId)),
    listSpaceWindowCapacityTransport: (
      facilityId,
      spaceId,
      windowId,
      capacityId
    ) =>
      dispatch(
        listSpaceWindowCapacityTransport(
          facilityId,
          spaceId,
          windowId,
          capacityId
        )
      ),

    createSpaceScheduleCapacity: (
      facilityId,
      spaceId,
      scheduleId,
      sLoad,
      sUnload,
      dLoad,
      dUnload,
      sLoadUnload,
      dLoadUnload,
      dEquipment
    ) =>
      dispatch(
        createSpaceScheduleCapacity(
          facilityId,
          spaceId,
          scheduleId,
          sLoad,
          sUnload,
          dLoad,
          dUnload,
          sLoadUnload,
          dLoadUnload,
          dEquipment
        )
      ),
    deleteSpaceScheduleCapacity: (facilityId, spaceId, scheduleId, id) =>
      dispatch(
        deleteSpaceScheduleCapacity(facilityId, spaceId, scheduleId, id)
      ),
    updateSpaceScheduleCapacity: (
      facilityId,
      spaceId,
      scheduleId,
      capacityId,
      sLoad,
      sUnload,
      dLoad,
      dUnload,
      sLoadUnload,
      dLoadUnload,
      double
    ) =>
      dispatch(
        updateSpaceScheduleCapacity(
          facilityId,
          spaceId,
          scheduleId,
          capacityId,
          sLoad,
          sUnload,
          dLoad,
          dUnload,
          sLoadUnload,
          dLoadUnload,
          double
        )
      ),

    listSpaceScheduleCapacityTransport: (
      facilityId,
      spaceId,
      scheduleId,
      capacityId
    ) =>
      dispatch(
        listSpaceScheduleCapacityTransport(
          facilityId,
          spaceId,
          scheduleId,
          capacityId
        )
      ),
    createSpaceScheduleCapacityTransport: (
      facilityId,
      spaceId,
      scheduleId,
      capacityId,
      classId
    ) =>
      dispatch(
        createSpaceScheduleCapacityTransport(
          facilityId,
          spaceId,
          scheduleId,
          capacityId,
          classId
        )
      ),
    deleteSpaceScheduleCapacityTransport: (
      facilityId,
      spaceId,
      scheduleId,
      capacityId,
      classId
    ) =>
      dispatch(
        deleteSpaceScheduleCapacityTransport(
          facilityId,
          spaceId,
          scheduleId,
          capacityId,
          classId
        )
      ),
    listSpaceCapacitySummary: (facilityId, date) =>
      dispatch(listSpaceCapacitySummary(facilityId, date)),
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(FacilitySchedulesProfile);

// TODO:quitar el cuadro esquinero de los scrolls
// TODO:mostrar encabezado fijo

const OrbiSelectorStyled = styled(OrbiSelector)`
  & .orbi-selector-content {
    width: 200px;
    & .items {
      & .sub-item {
        margin-left: 10px;
      }
    }
  }
`;

export const ManueverTimeComponent = styled.div`
  width: 60px;
  min-width: 60px;
  margin-left: 10px;
`;

export const EditManueverTimeComponent = styled.div`
  padding: 5px;
  cursor: pointer;
`;