import React from 'react';
import styled from "styled-components";

class HeaderTime extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      isSelected: false
    }
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (this.state.isHovered !== nextState.isHovered) {
      return true;
    } else if (this.props.timeSelected !== nextProps.timeSelected) {
      return (this.props.value === this.props.timeSelected || this.props.value === nextProps.timeSelected);
    } else {
      return false;
    }

  }

  render() {
    return(
      <Content
        style={{
          width: this.props.windw_space_width, 
          minWidth: this.props.windw_space_width, 
        }}
      >
        <Detail
          style={{
            backgroundColor: `${this.props.value}` === `${this.props.timeSelected}` ? 'rgb(61, 119, 247)' : 'transparent',
            color: `${this.props.value}` === `${this.props.timeSelected}` ? 'white' : 'black',
            fontSize: this.state.isHovered ? 15 : 12,
          }}
          onMouseEnter={() => this.setState({isHovered: true})}
          onMouseLeave={() => this.setState({isHovered: false})}
          onClick={() => {
            if (this.props.didSelect) this.props.didSelect(`${this.props.value}` === `${this.props.timeSelected}` ? '' : this.props.value);
          }}
        >
          {this.props.value}
        </Detail>
      </Content>
    );
  }

}

export default HeaderTime;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 12;
  font-weight: bold;
  letter-spacing: 0;
`;

const Detail = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 5px;
`;