import React, { Component } from "react";
import RouteCatalogDetailsFields from "./route-catalog-details-fields";
import {
  TagsAndFilesContainer,
  TagsContainer,
  FilesContainer,
  Nomenclature,
  Permissions,
} from "./style";
import RouteCatalogDetailsTags from "./route-catalog-details-tags";
import RouteCatalogZoneFields from "./route-catalog-details-zone";
import RouteCatalogDetailsNomenclatures from "./route-catalog-details-nomenclature";
import RouteCatalogDetailsPermissions from "./route-catalog-details-permissions";

class RouteCatalogDetailsParent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, handleInput, editMode, fieldsEditMode, tagsMode } = this.props;
    const { tags, nomenclature, permissions, zones } = data;
    return (
      <React.Fragment>
        <RouteCatalogDetailsFields
          data={data}
          handleInput={handleInput}
          fieldsEditMode={fieldsEditMode}
        />
        <RouteCatalogZoneFields 
          convoys={zones.convoys}
          detention={zones.detention}
          mode={tagsMode}
          tags={zones.tags}
        />
        <TagsAndFilesContainer>
          <TagsContainer>
            <RouteCatalogDetailsTags tags={tags} />
          </TagsContainer>
          <FilesContainer>
            <Nomenclature>
              <RouteCatalogDetailsNomenclatures
                nomenclature={nomenclature}
                editMode={editMode}
              />
            </Nomenclature>
            <Permissions>
              <RouteCatalogDetailsPermissions
                permissions={permissions}
                editMode={editMode}
              />
            </Permissions>
          </FilesContainer>
        </TagsAndFilesContainer>
      </React.Fragment>
    );
  }
}

export default RouteCatalogDetailsParent;

RouteCatalogDetailsParent.defaultProps = {};
