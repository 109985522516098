import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OptionSelectorSwitch from '../shared/controls/option-selector-switch';
import SettingControl from '../shared/controls/command-control/setting-control';
import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import NewFilterBar from "../shared/component/new-filter/new-filter-bar";
import { cancelRequest, getEntityItems, readEntities, readEntity, createEntity } from "../redux/api";
import { groupFacilitiesCustom } from '../shared/utils';
import CommandControlCard from './command-control/command-control-card';
import Loader from "../shared/icons/tat/loader";
import { downloadPermissionFile, loadSession } from "../redux/reducers/global-catalog.reducer";
import HelperFilesModal from '../shared/component/helper_files_modal';

class CommandControl extends React.Component {

  state = {
    showProgressLoader: false,
    buttonFilterName: "Aplicar",
    showCode: true,
    showAlias: true,
    showName: true,

    maneuver: "all",
    facilitiesByTag: [],
    categoriesSelected: [],
    inFacility: [],
    transport: [],
    transportClasses: [],

    baseTags: []
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  componentDidMount() {

    document.addEventListener("keydown", this.cancelUpdate)

    let isCargoOwner = `${this.props.companyType}` !== '1';

    this.props.loadCompanyProviders({ company_id: this.props.companyId, view: "booking" });

    if (isCargoOwner) {
      this.loadFacilities();
      this.props.loadCompanyTransportClasses({ company_id: this.props.companyId });
    } else {
      this.props.loadCompanyTags({ company_id: this.props.companyId });
    }

    this.props.loadFilterCategories({ company_id: this.props.companyId, filter_type: 1, company_type: this.props.companyType});
    this.props.loadUserFilter({ company_id: this.props.companyId, filter_type: 1 }, {
      onSuccess: (response) => {

        if (isCargoOwner) {
          let facilityName = response.filter?.facility ?? [];

          this.setState({
            categoriesSelected: response.filter?.categories ?? [],
            showName: facilityName.length == 0 ? true : facilityName.includes('name'),
            showCode: facilityName.length == 0 ? true : facilityName.includes('code'),
            showAlias: facilityName.length == 0 ? true : facilityName.includes('alias'),
            maneuver: response.filter?.maneuver_type ?? 'all',
            inFacility: (response.filter?.facilities ?? []).map( item => { return `${item}` }),
            transport: (response.filter?.transports ?? []).map( item => { return `${item}` }),
            transportClasses: (response.filter?.transport_classes ?? []).map( item => { return `${item}` }),
          }, () => {
            this.getCards();
          });
        } else {
          this.setState({
            transport: (response.filter?.clients ?? []).map( item => { return `${item}` }),
            baseTags: (response.filter?.base_tags ?? []).map( item => { return `${item}` }),
          }, () => {
            this.getCards();
          });
        }

      }
    });
  }

  cancelUpdate = (e) => {
    if(e.key === "Escape"){
      if(this.props.isSavingFilter || this.props.isGettingCards){
        this.props.cancelRequest("COMPANIES.COMMANDCONTROL.FILTER");
        this.props.cancelRequest("COMPANIES.COMMANDCONTROL");
      }
    }
  }

  loadFacilities = () => {
    this.props["loadCompanyFacilities"](this.props.companyId, {
      onSuccess: (result) => {
        this.setState({
          facilitiesByTag: groupFacilitiesCustom(
            result.map(f => ({...f, tags: (f.tags || []).map(t => ({...t, id: t.id_tag}))})), 
            this.state.showName, 
            this.state.showCode, 
            this.state.showAlias
          )
        });
      }
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "maneuver":
        this.setState({ [name]: value }, () => { this.saveFilter() });
        break;
      default:
        this.setState({ [name]: value }, () => { this.saveFilter() });
    }
  };

  handleFilter = () => {};

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  filterContents = () => {
    if (`${this.props.companyType}` === '1') {
      return [
        {
          title: "Base",
          items: this.props.companyTags.filter((f) =>
            f.type === 3
              ? true
              : false
          ),
          name: "baseTags",
          listType: 1,
        },
        {
          title: "Cliente",
          items: this.props.companyProviders,
          name: "transport",
          listType: 5,
        }
      ];
    } else {
      return [
        {
          title: "Instalación",
          items: this.state.facilitiesByTag,
          name: "inFacility",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Clase",
          items: this.props.companyTransportClasses,
          name: "transportClasses",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Transportista",
          items: this.props.companyProviders,
          name: "transport",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
      ];
    }
  }

  getCards = () => {

    if (`${this.props.companyType}` !== '1') {
      this.props.getCards(
        {
          company_id: this.props.companyId,
          maneuver: this.state.maneuver, 
          facilities: this.state.inFacility || "",
          transport_classes: this.state.transportClasses|| "",
          fleet_providers: this.state.transport || "",
          categories: this.state.categoriesSelected || "",
          base: "",
          clients: ""
        }
      );
    } else {
      this.props.getCards(
        {
          company_id: this.props.companyId,
          maneuver: "",
          facilities: "",
          transport_classes: "",
          fleet_providers: "",
          categories: this.state.categoriesSelected || "",
          base: this.state.baseTags || "",
          clients: this.state.transport || ""
        }
      );
    }

  }

  saveFilter = () => {

    let filterToSave = {};
    let isCargoOwner = `${this.props.companyType}` !== '1';

    let categoriesToSave = [];

    //if (this.props.filterUser?.filter) {
      categoriesToSave = this.state.categoriesSelected;
    // } else {
    //   categoriesToSave = this.props.categories.map( item => { return item.id; });
    //   // this is when the filter is null
    //   this.setState({
    //     categoriesSelected: categoriesToSave
    //   });
    // }

    if (isCargoOwner) {
      let facilityName = [];

      if (this.state.showName) {
        facilityName = facilityName.concat('name');
      }

      if (this.state.showCode) {
        facilityName = facilityName.concat('code');
      }

      if (this.state.showAlias) {
        facilityName = facilityName.concat('alias');
      }

      filterToSave = {
        company_id: this.props.companyId,
        type: 1,
        filter: {
          transport_classes: this.state.transportClasses.map( item => { return parseInt(item); }),
          transports: this.state.transport.map( item => { return parseInt(item); }),
          facilities: this.state.inFacility.map( item => { return parseInt(item); }),
          maneuver_type: this.state.maneuver,
          categories: categoriesToSave,
          facility: facilityName
        },
        company_user: this.props.userData
      };
    } else {
      filterToSave = {
        company_id: this.props.companyId,
        type: 1,
        filter: {
          base_tags: this.state.baseTags.map( item => { return parseInt(item); }),
          clients: this.state.transport.map( item => { return parseInt(item); })
        },
        company_user: this.props.userData
      };
    }

    this.props.saveFilter(filterToSave, {
      onSuccess: (response) => {
        this.getCards();
      }
    });
  }

  extra = () => {
    let isCargoOwner = `${this.props.companyType}` !== '1';

    return (
      <div style={{
        display: 'flex', 
        alignItems: 'center', 
        width: isCargoOwner ? '40%' : '50%', 
        height: '100%', 
        flexDirection: 'row-reverse',
      }}>
        <SettingControl
          isFilterHasContent={true}
          isCargoOwner={isCargoOwner}
          showCode={this.state.showCode}
          showAlias={this.state.showAlias}
          showName={this.state.showName}
          options={this.props.categories}
          categoriesSelected={this.state.categoriesSelected}
          applyFilter={(e) => {

            this.setState({
              categoriesSelected: e.options,
              showCode: e.showCode === true,
              showName: e.showName === true,
              showAlias: e.showAlias === true,
              facilitiesByTag: groupFacilitiesCustom(this.props.facilities, e.showName === true, e.showCode === true, e.showAlias === true)
            }, () => {
              this.saveFilter();
            });

          }}
        />
        {
          isCargoOwner ? 
          <div
            style={{
              marginRight: '20px',
              width: '300px',
            }}
          >
            <OptionSelectorSwitch
              value={this.state.maneuver}
              name="maneuver"
              onChange={this.handleChange}
              items={[
                { description: "Descarga", value: "unload" },
                { description: "Carga", value: "load" },
                { description: "Todo", value: "all" },
              ]}
            />
          </div> : <div/>
        }
      </div>
    );
  }

  redirect = (categoryId, subcategory, name) => {

    //if ((this.state.inFacility ?? []).length > 0) {
      let url = null;
    
      if (`${this.props.companyType}` !== '1') {
        url = `/company/controls/shipment/detail/1/?category=${categoryId}-${subcategory}&facilities=${this.state.inFacility || []}&transport_classes=${this.state.transportClasses || []}&fleet_providers=${this.state.transport || []}&maneuver=${this.state.maneuver}&orders=true&category_name${name}`;
      } else {
        url = `/company/controls/shipment/assemble/?base=&category=${categoryId}-${subcategory}&facilities=${this.state.inFacility || []}&transport_classes=${this.state.transportClasses || []}&fleet_providers=${this.state.transport || []}&maneuver=${this.state.maneuver}&orders=true&category_name${name}`;
      }
      
      if (url){
        this.props.history.push(url);
        document.location.reload(true);
      }
    //}

  }

  showCards = (cards) => {
    var itemsToShow = [];
    let cardsDivided = (cards || []).length / 4;

    for (var i = 0; i < cardsDivided; i++) {

      let mainIndex = (i * 4);

      let data1 = mainIndex < cards.length ? cards[mainIndex] : null;
      let data2 = (mainIndex + 1) < cards.length ? cards[mainIndex + 1] : null;
      let data3 = (mainIndex + 2) < cards.length ? cards[mainIndex + 2] : null;
      let data4 = (mainIndex + 3) < cards.length ? cards[mainIndex + 3] : null;

      itemsToShow = itemsToShow.concat(
        <div
          key={`row-card-${i}`}
          style={{
            display: 'flex',
            marginBottom: 30,
            justifyContent: 'space-around',
            marginRight: 30,
            alignItems: 'center'
          }}
        >
          {
            data1 === null ? <div key={`card-${mainIndex}`} style={{width: 240, height: 190}}/> :
            <CommandControlCard
              key={`card-${mainIndex}`}
              data = {data1}
              showDetails={(subcategory) => {
                this.redirect(data1.id, subcategory, data1.title ?? '');
              }}
            />
          }

          {
            data2 === null ? <div key={`card-${mainIndex + 1}`} style={{width: 240, height: 190}}/> :
            <CommandControlCard
              key={`card-${mainIndex + 1}`}
              data = {data2}
              showDetails={(subcategory) => {
                this.redirect(data2.id, subcategory, data2.title ?? '');
              }}
            />
          }
          
          {
            data3 === null ? <div key={`card-${mainIndex + 2}`} style={{width: 240, height: 190}}/> :
            <CommandControlCard
              key={`card-${mainIndex + 2}`}
              data = {data3}
              showDetails={(subcategory) => {
                this.redirect(data3.id, subcategory, data3.title ?? '');
              }}
            />
          }

          {
            data4 === null ? <div key={`card-${mainIndex + 3}`} style={{width: 240, height: 190}}/> :
            <CommandControlCard
              key={`card-${mainIndex + 3}`}
              data = {data4}
              showDetails={(subcategory) => {
                this.redirect(data4.id, subcategory, data4.title ?? '');
              }}
            />
          }

        </div>
      );
    }

    return(
      itemsToShow
    );

  }

  render() {

    return (
      <div className="home" style={{paddingTop: 21}}>

        <div
          style={{
            display: 'flex',
            marginRight: 60,
            flexDirection: 'row-reverse',
            marginBottom: 15
          }}
        >
          <HelperFilesModal
            files={[]}
          />
        </div>

        {
          (this.props.isSavingFilter || this.props.isGettingCards) &&
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }

        <NewFilterBar
          filterAction={this.handleFilter}
          disabledInputText={this.state.showProgressLoader}
          textSearch={false}
          selectedItems={{
            inFacility: this.state.inFacility,
            transport: this.state.transport,
            transportClasses: this.state.transportClasses,
            baseTags: this.state.baseTags,
            clients: this.state.transport,
          }}
          onChange={this.filtersChange}
          content={this.filterContents()}
          extra={this.extra()}
          applyFilter={ (e) => { 
            setTimeout(function() { 
              this.saveFilter()
            }.bind(this), 500) 
          }}
        />

        <div
          style={{
            width: '100%',
            paddingTop: 30
          }}
        >
          {
            this.props.cards.length > 0 ? this.showCards(this.props.cards) :
            <div
              style={{
                color: 'white',
                fontSize: 30,
                display: 'flex',
                justifyContent: 'center',
                marginTop: 100
              }}
            > 
              {'No has seleccionado ninguna categoría'}
            </div>
          }
        </div>

      </div>
    );
  }

}

const mapStateToProps = (state) => {

  let companyId = state.globalCatalog.session.company.id;
  let companyTags = getEntityItems(state, "COMPANIES.TAGS");
  let companyTagsOrder = getEntityItems(state, "tagsOrder");
  let gps_facilities = getEntityItems(state, "COMPANIES.GPS.FACILITIES");
  let categories = getEntityItems(state,"categories_to_show");
  let filterUser = getEntityItems(state,"COMPANIES.COMMANDCONTROL.FILTER");
  let isSavingFilter = state?.api['COMPANIES.COMMANDCONTROL.FILTER']?.status?.isFetching || false;
  let isGettingCards = state?.api['COMPANIES.COMMANDCONTROL']?.status?.isFetching || false;
  let cards = getEntityItems(state, "COMPANIES.COMMANDCONTROL");

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK").map(
    (f) => ({
      description: `${f.fleet_provider?.alias ?? f.fleet_provider?.orbi_id ?? ''}`,
      id: f.fleet_provider.fleet_provider_id || 0,
    })
  );

  const companyTransportClasses = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.transport_classes.map((tc) => ({
      id: tc.id,
      description: tc.code,
    })),
  }));

  return {
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
    companyId,
    companyType: state.globalCatalog.session.company.type.id,
    facilities: gps_facilities.map(f => ({...f, tags: (f.tags || []).map(t => ({...t, id: t.id_tag}))})),
    companyTagsOrder,
    categories: categories.map(item => { return {id: item.orbi_id, description: item.description, selected: false} }),
    filterUser,
    isSavingFilter,
    companyProviders,
    companyTransportClasses,
    companyTags,
    isGettingCards,
    cards
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(downloadPermissionFile());

  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadCompanyTags: (params, opts) => dispatch(readEntities("COMPANIES.TAGS",params,opts)),
    loadCompanyProviders: (params, opts) => dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyFacilities: (company_id, opt) => dispatch(readEntities("COMPANIES.GPS.FACILITIES", { company_id }, opt)),
    loadTransportClass: (params, opt) => dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opt)),
    loadFilterCategories: (params, opt) => dispatch(readEntities("COMPANIES.COMMANDCONTROL.FILTER.CATEGORIES", params, {...opt, alias: "categories_to_show" })),
    loadUserFilter: (params, opt) => dispatch(readEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opt)),
    saveFilter: (params, opts) => dispatch(createEntity("COMPANIES.COMMANDCONTROL.FILTER", params, opts)),
    getCards: (params, opt) => dispatch(readEntities("COMPANIES.COMMANDCONTROL", params, opt)),
    loadCompanyTransportClasses: (params, opts) =>
      dispatch(
        readEntities(
          "COMPANIES.TRANSPORTCLASS.FILTERS",
          { ...params, view: "grouped" },
          opts
        )
      ),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommandControl));