//@flow
import * as React from "react";
import * as Styled from "./styled";
// import Unitary from "../../shared/icons/assemble/unitary";
// import Store from "../../shared/icons/traffic/store";
// import Factory from "../../shared/icons/traffic/factory";
// import Storage from "../../shared/icons/traffic/storage";
// import Warehouse from "../../shared/icons/traffic/warehouse";

export type Props = {|
  fullCard: boolean,
  microCard: boolean,
  style: {},
  content: any,
  contentStyle: any,
  onClick: () => void,
  setMeOnTop: () => void,
  lines: {
    data: { fromAvatarId: number, toAvatarId: number },
    items: { caption: any, position: string }[],
    segments: { type: number, width: string, id: number }[],
  }[],
|};

class AssembleTripCard extends React.Component<Props> {
  state = { hovered: false };
  static defaultProps = {
    fullCard: true,
    microCard: false,
    style: {},
    content: "",
    lines: [],
    setMeOnTop: () => {},
  };

  render() {
    const { style, lines, content, fullCard, /* microCard, */ status } = this.props;
    // const baseItems = (data: any) => [
    //   {
    //     caption: facilityIcon(data.fromAvatarId),
    //     position: "5%",
    //   },
    //   {
    //     caption: <Unitary width="12px" height="12px" />,
    //     position: "50%",
    //   },
    //   {
    //     caption: facilityIcon(data.toAvatarId),
    //     position: "95%",
    //   },
    // ];
    return (
      <React.Fragment>
        <Styled.AssembleTripCardContainer
          onMouseEnter={() => {
            this.props.setMeOnTop(true);
            this.setState({ hovered: true });
          }}
          onMouseLeave={() => {
            this.props.setMeOnTop(false);
            this.setState({ hovered: false });
          }}
          hovered={this.state.hovered}
          style={{ ...style }}
          onClick={this.props.onClick}
        >
          {content && (
            <Styled.CardContent
              // fullCard={fullCard}
              fullCard={true}
              color={(status || "").color || ""}
            >
              {content}
            </Styled.CardContent>
          )}
          {fullCard ?
            lines.map((l) => (
              <Styled.CardLine>
                <Styled.CardLineItemContainer>
                  {l.items.map((i) => (
                    // {l.items.concat(baseItems(l.data)).map((i) => (
                    <Styled.CardLineItem {...i}>
                      {i.caption}
                    </Styled.CardLineItem>
                  ))}
                </Styled.CardLineItemContainer>
                <Styled.CardLineSegmentContainer>
                  {l.segments.map((s) => (
                    <Styled.CardLineSegment
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        ...SegmentTypeStyles(s.id),
                        width: s.width,
                        left: s.position,
                      }}
                    />
                  ))}
                </Styled.CardLineSegmentContainer>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {l.overPosition.map((s) => (
                    <div
                      style={{
                        bottom: "4px",
                        position: "absolute",
                        border: "0.5px solid #FFFFFF",
                        borderRadius: "2px",
                        ...s.style,
                        left: s.position,
                        width: s.width,
                      }}
                    />
                  ))}
                </div>
              </Styled.CardLine>
            ))
            : lines.map((l) => (
              <Styled.CardLine>
                {/* <Styled.CardLineItemContainer>
                  {l.items.map((i) => (
                    // {l.items.concat(baseItems(l.data)).map((i) => (
                    <Styled.CardLineItem {...i}>
                      {i.caption}
                    </Styled.CardLineItem>
                  ))}
                </Styled.CardLineItemContainer> */}
                <Styled.CardLineSegmentContainer>
                  {l.segments.map((s) => (
                    <Styled.CardLineSegment
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        ...SegmentTypeStyles(s.id),
                        width: s.width,
                        left: s.position,
                      }}
                    />
                  ))}
                </Styled.CardLineSegmentContainer>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {l.overPosition.map((s) => (
                    <div
                      style={{
                        bottom: "4px",
                        position: "absolute",
                        border: "0.5px solid #FFFFFF",
                        borderRadius: "2px",
                        ...s.style,
                        left: s.position,
                        width: s.width,
                      }}
                    />
                  ))}
                </div>
              </Styled.CardLine>
            ))}
        </Styled.AssembleTripCardContainer>
      </React.Fragment>
    );
  }
}

export default AssembleTripCard;

const SegmentTypeStyles = (eventId) => {
  // 204	  Tiempo de instalación con dispersión
  // 206	  Tiempo de tránsito con dispersión
  // 207	  Tiempo de ocio
  // 208	  Periodo de tiempo sin señal (GPS o celular)
  // 209	  Tiempo de Descanso
  // 210	  Alerta de Periodo de ensamble sin vigencia
  // 211	  Alerta de tiempo de ocio
  // 212	  Alerta de tiempo de tránsito e impacto en siguiente etapa
  // 213	  Parada

  switch (eventId) {
    case 0: //0 linea base
      return {
        zIndex: 0,
        height: "8px",
        width: "5px",
        border: "0.5px solid #FFFFFF",
        borderRadius: "1px",
        backgroundColor: "#212a3c",
      };
    case 201: //201	  Inicio de viaje
      return {
        height: "8px",
        width: "5px",
        border: "0.5px solid #FFFFFF",
        borderRadius: "1px",
        backgroundColor: "rgba(125,122,122,1)",
      };
    case 202: // 202 Llegada a origen
    case 206: // 206 Llegada a destino
      return {
        height: "8px",
        width: "262px",
        border: "0.5px solid #FFFFFF",
        backgroundColor: "#FFFFFF",
      };
    case 2: //	  Tiempo de espera excedido
      return {
        height: "8px",
        width: "79px",
        border: "0.5px solid #FFFFFF",
        borderRadius: "2px",
        backgroundColor: "rgba(192,0,0)",
      };
    case 203: // 203	  Tiempo de instalación origen transcurrido OK
    case 207: // 207	  Tiempo de instalación destino transcurrido OK
      return {
        height: "8px",
        width: "79px",
        border: "0.5px solid #FFFFFF",
        borderRadius: "2px",
        backgroundColor: "rgba(175,200,255,0.82)",
      };
    case 205: // 205	  Tiempo de tránsito OK
      return {
        height: "8px",
        width: "179px",
        border: "0.5px solid #FFFFFF",
        borderRadius: "2px",
        backgroundColor: "rgba(255,255,255,0.31)",
      };
    default:
      return {
        height: "8px",
        width: "262px",
        border: "0.5px solid #FFFFFF",
        backgroundColor: "#FFFFFF",
      };
  }
};

// const facilityIcon = (avatarId: number) => {
//   switch (avatarId) {
//     case 1:
//       return <Store width="12px" height="12px" fill="#AFC8FF" />;
//     case 2:
//       return <Factory width="12px" height="12px" fill="#AFC8FF" />;
//     case 3:
//       return <Storage width="12px" height="12px" fill="#AFC8FF" />;
//     case 4:
//       return <Warehouse width="12px" height="12px" fill="#AFC8FF" />;
//     default:
//       return <Store width="12px" height="12px" fill="#AFC8FF" />;
//   }
// };
