// @flow
import * as React from "react";
import type { ComponentType } from "react";
import styled from "styled-components";
import Select from "react-select";

type Props = {| options: Array<{ value: string, label: string }> |};

const HeaderSelect = (props: Props) => {
  return (
    <HeaderSelectStyled>
      {" "}
      <Select styles={CustomStyle} {...props} />
    </HeaderSelectStyled>
  );
};

export default HeaderSelect;

const HeaderSelectStyled: ComponentType<{}> = styled.div`
  position: relative;
`;

const CustomStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(47,59,83)",
    fontSize: "14px",
    padding: "0px 12px",
  }),
  container: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: "3px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    color: "#ffffff",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(47,59,83)",
    margin: "0",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(47,59,83)",
    width: "150px",
    fontSize: "14px",
    color: "#ffffff",
    border: "none",
    boxShadow: "",
    div: {
      div: {
        svg: {
          fill: "#79a3ff",
          transition: "0.2s ease-in-out",
          transform: state.menuIsOpen ? "rotate(0deg)" : "rotate(180deg)",
        },
      },
    },
  }),
};
