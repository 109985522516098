import * as React from "react";
import styled from "styled-components";
import { DownloadIcon } from "../../company/routes/style";
import RowD from "../../shared/component/row-d";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import Loader from "../../shared/icons/tat/loader";

class ServiceDetailRow extends React.Component {
  state = {
    loading: false
  }

  setLoading = (value) => {
    this.setState({loading: value})
  }

  render() {
    return(
      <>
        <RowD {...this.props} >
            <Text >
              {this.props.data?.date || '-'}
            </Text>
            <Text >
              {this.props.data?.transaction || '-'}
            </Text>
            <Text >
              <span
                style={{cursor: "pointer"}}
                onClick={this.props.openDetail}
              >
                {this.props.data?.file || '-'}
              </span>
            </Text>
            <ActionsContainer onClick={(e) => {e.stopPropagation()}}>
              {
                this.state.loading
                ? <Loader />
                : <DownloadIcon>
                    <DownloadImage 
                      width="25px" 
                      height="25px" 
                      onClick={(e) => {e.stopPropagation(); this.props.downloadFile(this.setLoading, "json");}}
                    />
                  </DownloadIcon>
              }
            </ActionsContainer>
            <ActionsContainer onClick={(e) => {e.stopPropagation()}}>
              {
                this.state.loading
                ? <Loader />
                : <DownloadIcon>
                    <DownloadImage 
                      width="25px" 
                      height="25px" 
                      onClick={(e) => {e.stopPropagation(); this.props.downloadFile(this.setLoading, "xlsx");}}
                    />
                  </DownloadIcon>
              }
            </ActionsContainer>
        </RowD>
      </>
    )
  }
}

export default ServiceDetailRow;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-around;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;