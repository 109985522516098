import React from "react";
import { PurchaseContainer } from "./style";
import Button from "../../../shared/controls/button";
import ReactTooltip from "react-tooltip";
import moment from "moment";

class PaymentsPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCoupons = () => {
    let message = '';

    (this.props.coupons || []).forEach(item => {
      message += `<b>Cupón por haber invitado a #${item.receiver_user?.username || ''} de #${item.company?.orbi_id || ''} válido por ${item.discount}% de descuento en su siguiente compra.<br/>Valido hasta: ${moment(item.validity).format('DD/MMM/YYYY')}<b/><br/><br/>`;
    });

    return message;
  }

  render() {
    const { credits } = this.props.data;
    return (
      <PurchaseContainer>
        <div>SALDO AL DIA DE HOY</div>
        <div className="info">
          <img src="/images/orbicoin.png" alt=""/>
          <div className="qty">
            <span className="money">{credits}</span>
            <span>ORBIBUCKS</span>
          </div>
          <Button 
            disabled={this.props.errorCompanyVerification !== ''}
            dataTip={this.props.errorCompanyVerification}
            dataFor="purchase-tooltip-error"
            text="Comprar saldo" 
            type="primary" 
            onClick={() => {
              if (this.props.openBuyOrbibucksAction) {
                this.props.openBuyOrbibucksAction();
              }
            }}
          />
          <ReactTooltip
            id={"purchase-tooltip-error"}
            place="bottom"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <ReactTooltip
            id={"payment-purchase-info"}
            place="bottom"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

          <div 
            data-tip={this.getCoupons()}
            data-for="payment-purchase-info"
            style={{
              width: 'fit-content'
            }}
          >{`${this.props.coupons.length} cupones`}</div>

        </div>

        </PurchaseContainer>
    );
  }
}

export default PaymentsPurchase;
