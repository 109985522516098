import { cloneDeep } from "lodash";
import store from "../store";
import { getApiUrl } from "../../shared/backend-api";
import { BACKEND } from "../../shared/backend-api";
import { putApiUrl } from "../../shared/backend-api";
import { postApiUrl } from "../../shared/backend-api";
import { deleteApiUrl } from "../../shared/backend-api";

const ACTIONS = {
  COMPANY: {
    NOTIFICATION: {
      REQUEST: {
        TYPE: "ACTIONS_COMPANY_NOTIFICATION_REQUEST",
        ACTION: () => ({
          type: ACTIONS.COMPANY.NOTIFICATION.REQUEST.TYPE
        })
      },
      ERROR: {
        TYPE: "ACTIONS_COMPANY_NOTIFICATION_ERROR",
        ACTION: error => ({
          type: ACTIONS.COMPANY.NOTIFICATION.ERROR.TYPE,
          error
        })
      },
      LIST: {
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_NOTIFICATION_LIST_SUCCESS",
          ACTION: response => ({
            type: ACTIONS.COMPANY.NOTIFICATION.LIST.SUCCESS.TYPE,
            response
          })
        }
      }
    },
    USER: {
      NOTIFICATION_FACILITY: {
        REQUEST: {
          TYPE: "COMPANY_USER_NOTIFICATION_FACILITY_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.REQUEST.TYPE
          })
        },
        ERROR: {
          TYPE: "COMPANY_USER_NOTIFICATION_FACILITY_ERROR",
          ACTION: error => ({
            type: ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.ERROR.TYPE,
            error
          })
        },
        LIST: {
          SUCCESS: {
            TYPE: "COMPANY_USER_NOTIFICATION_FACILITY_LIST_SUCCESS",
            ACTION: response => ({
              type:
                ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.LIST.SUCCESS.TYPE,
              response
            })
          }
        }
      }
    },
    FACILITY: {
      NOTIFICATION: {
        REQUEST: {
          TYPE: "COMPANY_FACILITY_NOTIFICATION_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.REQUEST.TYPE
          })
        },
        ERROR: {
          TYPE: "COMPANY_FACILITY_NOTIFICATION_ERROR",
          ACTION: error => ({
            type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.ERROR.TYPE,
            error
          })
        },
        GET: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_GET_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.GET.SUCCESS.TYPE,
              response
            })
          }
        },
        LIST: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_LIST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST.SUCCESS.TYPE,
              response
            })
          }
        },
        POST: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_POST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.POST.SUCCESS.TYPE,
              response
            })
          }
        },
        PUT: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_PUT_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.PUT.SUCCESS.TYPE,
              response
            })
          }
        },
        LIST_MAILS: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_LIST_MAILS_SUCCESS",
            ACTION: (response, facilityNotificationId) => ({
              type:
                ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST_MAILS.SUCCESS.TYPE,
              response,
              facilityNotificationId
            })
          }
        },
        EMAIL: {
          REQUEST: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_EMAIL_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.REQUEST.TYPE
            })
          },
          ERROR: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_EMAIL_ERROR",
            ACTION: error => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.ERROR.TYPE,
              error
            })
          },
          POST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_EMAIL_POST_SUCCESS",
              ACTION: response => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.POST.SUCCESS.TYPE,
                response
              })
            }
          },
          DELETE: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_EMAIL_DELETE_SUCCESS",
              ACTION: (id, notification_facility_id) => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.DELETE.SUCCESS
                    .TYPE,
                id,
                notification_facility_id
              })
            }
          }
        },
        USER: {
          REQUEST: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.REQUEST.TYPE
            })
          },
          ERROR: {
            TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_ERROR",
            ACTION: error => ({
              type: ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.ERROR.TYPE,
              error
            })
          },
          POST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_POST_SUCCESS",
              ACTION: (response, email) => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.POST.SUCCESS.TYPE,
                response,
                email
              })
            }
          },
          DELETE: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_DELETE_SUCCESS",
              ACTION: (id, notification_facility_id) => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.DELETE.SUCCESS
                    .TYPE,
                id,
                notification_facility_id
              })
            }
          },
          LIST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_LIST_SUCCESS",
              ACTION: response => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.LIST.SUCCESS.TYPE,
                response
              })
            }
          },
          PUT: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_NOTIFICATION_USER_PUT_SUCCESS",
              ACTION: response => ({
                type:
                  ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.PUT.SUCCESS.TYPE,
                response
              })
            }
          }
        }
      }
    }
  }
};

const initialState = {
  companyNotifications: {
    data: [],
    isFetching: false,
    error: {}
  },
  facilityNotifications: {
    data: [],
    isFetching: false,
    error: {}
  },
  facilityNotificationEmails: {
    data: {},
    isFetching: false,
    error: {}
  },
  userFacilityNotifications: {
    data: {},
    isFetching: false,
    error: {}
  }
};

const setEntity = (entity, identity, entityList) => {
  return entityList
    .filter(
      e =>
        (identity ? e[identity] : e) !== (identity ? entity[identity] : entity)
    )
    .concat([entity]);
};

export default function(state = initialState, action) {
  let {
    companyNotifications,
    facilityNotifications,
    facilityNotificationEmails,
    userFacilityNotifications
  } = cloneDeep(state);
  switch (action.type) {
    //Company alerts
    case ACTIONS.COMPANY.NOTIFICATION.REQUEST.TYPE:
      companyNotifications.isFetching = true;
      return Object.assign({}, state, companyNotifications);
    case ACTIONS.COMPANY.NOTIFICATION.ERROR.TYPE:
      companyNotifications.isFetching = false;
      companyNotifications.error = action.error;
      return Object.assign({}, state, companyNotifications);
    case ACTIONS.COMPANY.NOTIFICATION.LIST.SUCCESS.TYPE:
      companyNotifications.isFetching = false;
      companyNotifications.data = action.response;
      return Object.assign({}, state, { companyNotifications });

    // Facility alerts
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.REQUEST.TYPE:
      facilityNotifications.isFetching = true;
      return Object.assign({}, state, facilityNotifications);
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.ERROR.TYPE:
      facilityNotifications.isFetching = false;
      facilityNotifications.error = action.error;
      return Object.assign({}, state, facilityNotifications);
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.PUT.SUCCESS.TYPE:
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.POST.SUCCESS.TYPE:
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.GET.SUCCESS.TYPE:
      facilityNotifications.isFetching = false;
      facilityNotifications.data = setEntity(
        action.response,
        "id",
        facilityNotifications.data
      );
      return Object.assign({}, state, { facilityNotifications });
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST.SUCCESS.TYPE:
      facilityNotifications.isFetching = false;
      facilityNotifications.data = action.response;
      return Object.assign({}, state, { facilityNotifications });

    // // Facility alert emails
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST_MAILS.SUCCESS.TYPE:
      facilityNotificationEmails.data[action.facilityNotificationId] =
        action.response;
      return Object.assign({}, state, { facilityNotificationEmails });

    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.REQUEST.TYPE:
      facilityNotificationEmails.isFetching = true;
      return Object.assign({}, state, { facilityNotificationEmails });
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.ERROR.TYPE:
      facilityNotificationEmails.isFetching = false;
      facilityNotificationEmails.error = action.error;
      return Object.assign({}, state, { facilityNotificationEmails });

    // Facility alert user/email list
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.POST.SUCCESS.TYPE:
      facilityNotificationEmails.isFetching = false;
      facilityNotificationEmails.data[
        action.response.notification_facility
      ].user_list = (
        facilityNotificationEmails.data[action.response.notification_facility]
          .user_list || []
      ).concat([[action.response.id, action.email]]);
      return Object.assign({}, state, { facilityNotificationEmails });
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.POST.SUCCESS.TYPE:
      facilityNotificationEmails.isFetching = false;
      facilityNotificationEmails.data[
        action.response.notification_facility
      ].email_list = (
        facilityNotificationEmails.data[action.response.notification_facility]
          .email_list || []
      ).concat([[action.response.id, action.response.email]]);
      return Object.assign({}, state, { facilityNotificationEmails });

    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.DELETE.SUCCESS.TYPE:
      facilityNotificationEmails.isFetching = false;
      facilityNotificationEmails.data[
        action.notification_facility_id
      ].user_list = facilityNotificationEmails.data[
        action.notification_facility_id
      ].user_list.filter(e => e[0] !== action.id);
      return Object.assign({}, state, { facilityNotificationEmails });

    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.DELETE.SUCCESS.TYPE:
      facilityNotificationEmails.isFetching = false;
      facilityNotificationEmails.data[
        action.notification_facility_id
      ].email_list = facilityNotificationEmails.data[
        action.notification_facility_id
      ].email_list.filter(e => e[0] !== action.id);
      return Object.assign({}, state, { facilityNotificationEmails });

    case ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.REQUEST.TYPE:
      userFacilityNotifications.isFetching = true;
      return Object.assign({}, state, { userFacilityNotifications });
    case ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.ERROR.TYPE:
      userFacilityNotifications.isFetching = false;
      userFacilityNotifications.error = action.error;
      return Object.assign({}, state, { userFacilityNotifications });
    case ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.LIST.SUCCESS.TYPE:
      userFacilityNotifications.isFetching = false;
      userFacilityNotifications.data = action.response;
      return Object.assign({}, state, { userFacilityNotifications });

    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.REQUEST.TYPE:
      userFacilityNotifications.isFetching = true;
      return Object.assign({}, state, { userFacilityNotifications });
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.ERROR.TYPE:
      userFacilityNotifications.isFetching = false;
      userFacilityNotifications.error = action.error;
      return Object.assign({}, state, { userFacilityNotifications });
    case ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.PUT.SUCCESS.TYPE:
      userFacilityNotifications.isFetching = false;
      userFacilityNotifications.data = userFacilityNotifications.data
        .filter(ufa => ufa.id !== action.response.id)
        .concat([action.response]);
      return Object.assign({}, state, { userFacilityNotifications });
    default:
      return state;
  }
}

export const listCompanyNotifications = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.NOTIFICATION.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.NOTIFICATIONS.LIST, { company_id })
      .then(response => response.json())
      .then(response =>
        dispatch(ACTIONS.COMPANY.NOTIFICATION.LIST.SUCCESS.ACTION(response))
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.NOTIFICATION.ERROR.ACTION(error))
      );
  };
};

export const listFacilityNotifications = facility_id => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.NOTIFICATIONS.LIST, {
      company_id,
      facility_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST.SUCCESS.ACTION(response)
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.ERROR.ACTION(error))
      );
  };
};

export const setFacilityNotification = (
  facility_id,
  notifications,
  id,
  status
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.REQUEST.ACTION());
    return (id !== undefined
      ? putApiUrl(BACKEND.FACILITY.NOTIFICATIONS.PUT, {
          company_id,
          facility_id,
          facilities: facility_id,
          notifications,
          status,
          id
        })
      : postApiUrl(BACKEND.FACILITY.NOTIFICATIONS.POST, {
          company_id,
          facility_id,
          notifications,
          status
        })
    )
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.POST.SUCCESS.ACTION(response)
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.ERROR.ACTION(error))
      );
  };
};

export const listFacilityNotificationEmailsAndUsers = (facility_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.NOTIFICATIONS.LIST_MAILS, {
      company_id,
      facility_id,
      id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.LIST_MAILS.SUCCESS.ACTION(
            response,
            id
          )
        )
      )
      .catch(error =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.ERROR.ACTION(error)
        )
      );
  };
};

export const createFacilityNotificationUser = (
  facility_id,
  notification_facility_id,
  user_company,
  email
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.NOTIFICATIONS.USER.POST, {
      company_id,
      facility_id,
      notification_facility_id,
      notification_facility: notification_facility_id,
      user_company
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.POST.SUCCESS.ACTION(
            response,
            email
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.ERROR.ACTION(error))
      );
  };
};

export const createFacilityNotificationEmail = (
  facility_id,
  notification_facility_id,
  email
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.NOTIFICATIONS.EMAIL.POST, {
      company_id,
      facility_id,
      notification_facility_id,
      email,
      notification_facility: notification_facility_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.POST.SUCCESS.ACTION(
            response,
            "email_list"
          )
        )
      )
      .catch(error =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.ERROR.ACTION(error)
        )
      );
  };
};

export const removeFacilityNotificationEmail = (
  facility_id,
  notification_facility_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.NOTIFICATIONS.EMAIL.DELETE, {
      company_id,
      facility_id,
      notification_facility_id,
      id
    })
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.DELETE.SUCCESS.ACTION(
            id,
            notification_facility_id
          )
        )
      )
      .catch(error =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.EMAIL.ERROR.ACTION(error)
        )
      );
  };
};

export const removeFacilityNotificationUser = (
  facility_id,
  notification_facility_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.NOTIFICATIONS.USER.DELETE, {
      company_id,
      facility_id,
      notification_facility_id,
      id
    })
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.DELETE.SUCCESS.ACTION(
            id,
            notification_facility_id
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.ERROR.ACTION(error))
      );
  };
};

export const listUserNotificationFacilities = user_id => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.NOTIFICATION_FACILITY, {
      company_id,
      user_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.LIST.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.USER.NOTIFICATION_FACILITY.ERROR.ACTION(error))
      );
  };
};

export const updateUserNotificationFacility = (
  facility_id,
  notification_facility_id,
  id,
  email_notifications,
  push_notifications,
  user_company
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.REQUEST.ACTION());
    return putApiUrl(BACKEND.FACILITY.NOTIFICATIONS.USER.PUT, {
      company_id,
      facility_id,
      notification_facility_id,
      id,
      email_notifications,
      push_notifications,
      notification_facility: notification_facility_id,
      user_company
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.PUT.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.NOTIFICATION.USER.ERROR.ACTION(error))
      );
  };
};
