//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import styled from "styled-components";
import SVGIcon, { icons } from "../../../shared/svg-icon";
import * as Types from "../types";
// import RadioInput from "../../../shared/controls/radio-input";
import ModalSelectInput from "../modal-select-input";
import ReactDatePicker from "react-datepicker";
import { FooterDatePickerText } from "../../assemble/assemble-edit-modal/styled";
// import { filter } from "lodash";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";

type Props = {
  show: boolean,
  onChange: (SharedTypes.SimpleEvent) => void,
  data: Types.EditAppointmentModalData,
  reasons: { id: number, description: string }[],
  spaceSelect: number,
};

const todayHour = new Date(
);

const ChangeAppointmentModal = ({
  show,
  onChange,
  data,
  spaces,
  spaceSelect,
  onMinutes,
  selectedNewDate,
  hourFrom = [],
  hourTo = [],
  minuteFrom = [],
  minuteTo = [],
  date,

  reasons,
  place,
  reasonSelect,
}: Props) => {

  return (
    <BaseDialog
      open={show}
      title="Cambiar cita"
      message=""
      acceptText="Cambiar cita"
      cancelText="Cancelar"
      showCancel={true}
      cancelButtonType="outline dark primary"
      disabledAccept={spaceSelect === 0 || !selectedNewDate}
      acceptAction={() => onChange({ target: { name: "update", value: true } })}
      closeAction={(a) => onChange(a)}
      contentObject={
        <ModalContent
          {...data}
          onChange={onChange}
          spaces={spaces}
          spaceSelect={spaceSelect}
          onMinutes={onMinutes}
          selectedNewDate={selectedNewDate}
          hourFrom ={hourFrom}
          hourTo ={hourTo}
          minuteFrom ={minuteFrom}
          minuteTo ={minuteTo}
          reasons={reasons}
          date={date}
          place={place}
          // appointmentAction={appointmentAction}
          reasonSelect={reasonSelect}
        />
      }
      style={{ content: { width: "450px", marginLeft: "-200px", overflow: "initial" } }}
      //   disabledAccept: PropTypes.bool,
      className="appointment-modal"
    />
  );
};
export default ChangeAppointmentModal;

const ModalContent = (props: Types.EditAppointmentModalData) => {

  const filterPassedTime = time => {
    if(!props.hourFrom?.length || !props.hourTo?.length || !props.minuteTo?.length || !props.minuteFrom?.length)
      return false;
    let result = false;
    // debugger;
    props.hourFrom.forEach((hourFrom, i) => {
      const minTime = setHours(setMinutes(new Date(time), props.minuteFrom[i]), hourFrom);
      const maxTime = setHours(setMinutes(new Date(time), props.minuteTo[i]), props.hourTo[i]);
      const selectedDate = new Date(time);
      if(selectedDate.getTime() >= minTime.getTime() &&  selectedDate.getTime() < maxTime.getTime())
        result = true;
    })
    return result;
  }

  return (
    <div>
      <Avatar alignItems={flexOpts.alignItems.flexEnd}>
        <Logo src={props.logo} alt="" />
        <Flex direction={flexOpts.direction.column}>
          <Trip>{`N° orden: ${props.code}`}</Trip>
          <Maneuver>{`Maniobra: ${props.maneuver}`}</Maneuver>
        </Flex>
      </Avatar>
      <Flex justifyContent={flexOpts.justifyContent.spaceAround}>
        <Flex
          direction={flexOpts.direction.column}
          // style={{ width: "80%", margin: "auto" }}
        >
          <Appointment>{"Cita Anterior"}</Appointment>
          <AppointmentDate>
            <Icon
              name={icons.calendar}
              stroke={"#AFC8FF"}
              fill={"#AFC8FF"}
              height={"15px"}
              width={"15px"}
            />
            {formatDate(props.date)}
          </AppointmentDate>
          <AppointmentDate>{props.place}</AppointmentDate>
        </Flex>
        <Flex direction={flexOpts.direction.column}>
          <Appointment>{"Nueva Cita:"}</Appointment>
          <AppointmentDate>
            <ReactDatePicker
              selected={props.selectedNewDate/*  ||  props.newAppointment.date */}
              onChange={(date) =>{
                // debugger;
                props.onMinutes({ target: { name: "selectedNewDate", value: date } })
              }}
              minDate={todayHour}
              // minTime={}
              maxDate={null}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              filterTime={filterPassedTime}
              popperPlacement="top-start"
              timeCaption="horario"
              dateFormat="d MMMM yyyy HH:mm"
              customInput={<DatePickerCustom />}
              className="appointment-date-picker"
              disabled={!props.spaceSelect}
            />
          </AppointmentDate>
        </Flex>
      </Flex>
      <Avatar>
        <ModalSelectInput
          isSearchable={false}
          // defaultValue={props.spaces.lentgh === 2 ? props.spaces[1] : props.spaces[0]}
          value={props.spaces.find(s => s.value === props.spaceSelect) || props.spaces[0]}
          onChange={(a) =>
            props.onChange({
              target: { name: "spaceSelect", value: a.value },
            })
          }
          name="spaceSelect"
          options={props.spaces}
        />
      </Avatar>
      <Avatar>
        <ModalSelectInput
          isSearchable={false}
          defaultValue={props.reasons[0]}
          onChange={(a) =>
            props.onChange({
              target: { name: "reasonSelect", value: a.value },
            })
          }
          name="reasonSelect"
          options={props.reasons}
        />
      </Avatar>
    </div>
  );
};

const formatDate = (date: Date) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const tomorrow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes()
  );

  let result =
    date.toDateString() === today.toDateString()
      ? "Hoy, "
      : date.toDateString() === tomorrow.toDateString()
      ? "Mañana, "
      : date.toUTCString().substring(4, 16);

  result = result.concat(
    " ",
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    " hrs"
  );
  return result;
};

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 17px;
  margin: 5px;
`;

const Trip = styled.span`
  color: #20293d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

const Maneuver = styled.span`
  color: #20293d;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

const Appointment = styled.span`
  color: #20293d;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const AppointmentDate = styled(Flex)`
  color: #2d364c;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Avatar = styled(Flex)`
  margin: 20px;
`;

const Icon = styled(SVGIcon)`
  margin-right: 5px;
`;

const DatePickerCustom = ({
  value,
  onClick,
}: {
  value?: any,
  onClick?: Function,
}) => (
  <Flex style={{ width: "fit-content" }}>
    <SVGIcon
      name={icons.calendar}
      fill="#AFC8FF"
      width="20px"
      height="20px"
      onClick={onClick}
    />
    <FooterDatePickerText onClick={onClick}>{value}</FooterDatePickerText>
  </Flex>
);