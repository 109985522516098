import React from "react";
import Tag from "../../shared/controls/tag";
import ExceedPlus from "../../shared/controls/exceed-plus";
// import ReactTooltip from "react-tooltip";
import UserInfo from "../../shared/component/user-info";
import { getLastSession, LOG_FORMAT } from "../../shared/utils";
import { DoubleOperator, Operator } from "../../shared/icons/assemble";
import { ArrowDown, ArrowUp } from "orbi_v2_frontend_components/lib/Icons";
import { ArrowIconMargin, TooltipRow } from "./style";
import TruckForward from "../../shared/icons/routes/TruckForward";
import TruckBackward from "../../shared/icons/routes/TruckBackward";
import Factory from "../../shared/icons/routes/Factory";
import Agency from "../../shared/icons/routes/Agency";
import ReactTooltip from "react-tooltip";

class RouteRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isHovered: false
    };
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick({
        target: { name: this.props.id, value: !this.props.isSelected },
      });
    }
  };

  openChild = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  getTypeRamp = (type) => {
    switch (type) {
      case "load":
        return (
          <ArrowUp width="10px" color="white" style={{ marginRight: "3px" }} />
        );
      case "unload":
        return (
          <ArrowDown
            width="10px"
            color="white"
            style={{ marginRight: "3px" }}
          />
        );
      case "loadunload":
        return <ArrowIconMargin width="13px" />;
      default:
    }
  };

  getOperatorImage = (numOperators) => {
    switch (numOperators) {
      case 2:
        return <DoubleOperator />;
      default:
        return <Operator />;
    }
  };

  convertToHours = (time) => {
    if (parseInt(time) !== 0) {
      var num = time;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + " hr " + rminutes + " min";
    }
    return "0 hr 0 min";
  };

  render() {
    let { itemInfo, clickHandler } = this.props;
    return (
      <React.Fragment>
        <div
          className={`row route-row ${this.props.className}`}
          onClick={this.handleClick}
        >
          <div className={"overlay"} /*  onClick={this.toggleSelected} */>
            <div className="checkbox"></div>
          </div>
          <div className="actions" onClick={() => clickHandler(itemInfo)}></div>
          <div className="route" onClick={() => clickHandler(itemInfo)}>
            {itemInfo.route}
          </div>
          <div className="code" onClick={() => clickHandler(itemInfo)}>
            {itemInfo.code}
          </div>
          <div className="alias" onClick={() => clickHandler(itemInfo)}>
            {itemInfo.alias}
          </div>
          <div className="classes" onClick={() => clickHandler(itemInfo)}>
            {itemInfo.classes}
          </div>
          <div className="tags" onClick={() => clickHandler(itemInfo)}>
            <ExceedPlus
              maxVisibleItems={3}
              name={"tags ".concat(this.props.id)}
            >
              {this.props.itemInfo.tags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    title={tag.title}
                    color={tag.color}
                  />
                );
              })}
            </ExceedPlus>
          </div>
          <div className="tat" onClick={() => clickHandler(itemInfo)}>
            {this.convertToHours(itemInfo.tat.split(" ")[0])}
          </div>
          <div 
            className={`caret${this.state.open ? " open" : ""}`}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false})}
            onClick={() => this.openChild()}
          >
            <img
              alt=""
              src="/images/caret.svg"
              width={this.state.isHovered ? "21px" : "16px"}
              height={this.state.isHovered ? "15px" : "10px"}
            />
          </div>
        </div>
        <div className={`route-row-child${this.state.open ? " open" : ""}`}>
          <div className="row route-row-child-header">
            <div className="class">Clase</div>
            <div className="config">Configuración</div>
            <div className="distance">Distancia</div>
            <div></div>
            <div>TAT</div>
            <div>
              <Factory fill={"#b0baff"} />
            </div>
            <div>
              <TruckForward fill={"#b0baff"} />
            </div>
            <div>
              <Agency fill={"#b0baff"} />
            </div>
            <div>
              <TruckBackward fill={"#b0baff"} />
            </div>
          </div>
          {itemInfo.childs.map((child, key) => {
            return (
              <div className="row route-row-child-body" key={key}>
                <div
                  className="class"
                  data-tip
                  data-for={"tooltip-nomenclature-".concat(child.id)}
                >
                  {child.classes}
                </div>
                <ReactTooltip
                  id={"tooltip-nomenclature-".concat(child.id)}
                  delayHide={50}
                  delayShow={50}
                  delayUpdate={200}
                  place={"bottom"}
                  border={true}
                  type={"light"}
                  effect="solid"
                >
                  <TooltipRow>
                    <h2>{child.tooltip.tipo}</h2>
                    {/* <span>Configuración: {child.tooltip.configuracion}</span> */}
                    <span>Ejes: {child.tooltip.ejes}</span>
                    <span>Llantas: {child.tooltip.llantas}</span>
                  </TooltipRow>
                </ReactTooltip>
                <div className="config">{child.tooltip.configuracion}</div>
                <div className="distance">{child.distance} km</div>
                <div className="operators">
                  {child.details.map((detail, key) => {
                    return (
                      <div className="row operators_details" key={key}>
                        <div>{this.getOperatorImage(key + 1)}</div>
                        <div>{this.convertToHours(detail.tat)}</div>
                        <div>
                          {this.getTypeRamp(detail.originType)}{" "}
                          {this.convertToHours(detail.origin)}
                        </div>
                        <div>{this.convertToHours(detail.transit)}</div>
                        <div>
                          {this.getTypeRamp(detail.destinationType)}
                          {this.convertToHours(detail.destination)}
                        </div>
                        <div>{this.convertToHours(detail.comeback)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  getContent = (validation) => {
    const {
      itemInfo: { shared = 0 },
    } = this.props;

    let content = (this.props.itemInfo.content || []).filter(
      (item) =>
        item.object_id &&
        item.object_id.type &&
        item.object_id.type.id === validation.id
    );

    let companyAdded = [];
    // eslint-disable-next-line
    return content.map((item, index) => {
      const { company = { orbi_id: "", logo: "" } } = item;

      if (shared === -1) {
        if (index === 0) {
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} -  ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      } else {
        if (!companyAdded.includes(company.orbi_id)) {
          companyAdded.push(company.orbi_id);
          return (
            <UserInfo
              key={item.id}
              avatar={company.logo}
              avatarSecond={company.avatar}
              title={`${item.user.first_name} ${item.user.last_name} -  ${company.orbi_id}`}
              subtitle={item.object_id.type.description}
              extra={`${item.data_after.description} ${getLastSession(
                item.log_date,
                LOG_FORMAT
              )}`}
            />
          );
        }
      }
    });
  };
}

export default RouteRow;

// RouteRow.defaultProps = {
//   itemInfo: {
//     id: "T-380",
//     plates: "657-EF-8",
//     model: "T600",
//     brand: "Kenworth",
//     year: "2005",
//     company_name: "Transporte Mextravel",
//     avatar: "/images/help.png",
//     company_avatar: "/images/help.png",
//     hasGps: false,
//     validated: 0,
//     validations: [],
//     tags: [
//       { tag: { title: "Etiqueta 1" } },
//       { tag: { title: "Etiqueta 2" } },
//       { tag: { title: "Etiqueta 3" } },
//     ],
//   },
//   itemType: { id: 0, description: "" },
//   onClick: () => {
//     console.log("Not yet implemented");
//   },
// };

RouteRow.defaultProps = {
  itemInfo: {
    route: "",
    code: "",
    tags: [],
    validations: [],
  },
};

// const VALIDATED_MESSAGE = {
//   Rechazado: "Rechazado",
//   Pendiente: "Pendiente",
//   Validado: "Validado",
//   Incompleto: "Incompleto",
//   "Sin documento": "Sin documento",
// };

// const DEFAULT_IMAGE = "/images/truck.svg";
// const VEHICLE_IMAGE = "/images/truck.svg";
// const EQUIPMENT_IMAGE = "/images/remolque.svg";
// const OPERATOR_IMAGE = "/images/Artboard.svg";
// const COMPANY_IMAGE = "/images/modal-factory-icon.svg";
