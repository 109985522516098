export const columnsDataCO = [
  {
    key: 'Fecha de la descarga de la disponibilidad',
    title: 'Fecha de la descarga de la disponibilidad',
  },
  {
    key: 'Id_Contract',
    title: 'Id_Contract',
  },
  {
    key: 'Id_Slot',
    title: 'Id_Slot',
  },
  {
    key: 'Estatus de Slot',
    title: 'Estatus de Slot',
  },
  {
    key: 'Fecha de creación de la disponibilidad*',
    title: 'Fecha de creación de la disponibilidad*',
  },
  {
    key: 'Dispositivo*',
    title: 'Dispositivo*',
  },
  {
    key: 'Usuario',
    title: 'Usuario',
  },
  // {
  //   key: 'Contrato',
  //   title: 'Contrato',
  // },
  {
    key: 'Instalación',
    title: 'Instalación',
  },
  {
    key: 'Código de instalación',
    title: 'Código de instalación',
  },
  {
    key: 'Alias de instalación',
    title: 'Alias de instalación',
  },
  {
    key: 'Operación',
    title: 'Operación',
  },
  {
    key: 'Clase de expedición del contrato',
    title: 'Clase de expedición del contrato',
  },
  {
    key: 'Grupo de clase de expedición',
    title: 'Grupo de clase de expedición',
  },
  {
    key: 'Configuración vehicular',
    title: 'Configuración vehicular',
  },
  {
    key: 'Estatus*',
    title: 'Estatus*',
  },
  {
    key: 'Folio',
    title: 'Folio*',
  },
  {
    key: 'Viaje',
    title: 'Viaje',
  },
  {
    key: 'Id SAP transportista',
    title: 'Id SAP transportista',
  },
  {
    key: 'Orbi id transportista*',
    title: 'Orbi id transportista*',
  },
  {
    key: 'Alias transportista',
    title: 'Alias transportista',
  },
  {
    key: 'Operador*',
    title: 'Operador*',
  },
  {
    key: 'Vehículo*',
    title: 'Vehículo*',
  },
  {
    key: 'Placas vehículo*',
    title: 'Placas vehículo*',
  },
  {
    key: 'Clase de vehículo',
    title: 'Clase de vehículo',
  },
  {
    key: 'Peso vehículo*',
    title: 'Peso vehículo*',
  },
  {
    key: 'Remolque 1**',
    title: 'Remolque 1**',
  },
  {
    key: 'Placas remolque 1**',
    title: 'Placas remolque 1**',
  },
  {
    key: 'Peso R1**',
    title: 'Peso R1**',
  },
  {
    key: 'Clase R1**',
    title: 'Clase R1**',
  },
  {
    key: 'SubTipo R1**',
    title: 'Subtipo R1**',
  },
  {
    key: 'Remolque 2***',
    title: 'Remolque 2***',
  },
  {
    key: 'Placas remolque 2***',
    title: 'Placas remolque 2***',
  },
  {
    key: 'Peso R2***',
    title: 'Peso R2***',
  },
  {
    key: 'Clase R2***',
    title: 'Clase R2***',
  },
  {
    key: 'SubTipo R2***',
    title: 'Subtipo R2***',
  },
  {
    key: 'Dolly***',
    title: 'Dolly***',
  },
  {
    key: 'Placas dolly***',
    title: 'Placas dolly***',
  },
  {
    key: 'Peso dolly***',
    title: 'Peso dolly***',
  },
  {
    key: 'Clase dolly***',
    title: 'Clase dolly***',
  },
  {
    key: 'Tipo dolly***',
    title: 'Tipo dolly***',
  },
  {
    key: 'Peso total (tara)',
    title: 'Peso total (tara)',
  },
  {
    key: 'Proveedor GPS',
    title: 'Proveedor GPS',
  },
  {
    key: 'Identificador GPS',
    title: 'Identificador GPS',
  },
  {
    key: 'Fecha de fin de viaje',
    title: 'Fecha fin de viaje',
  },
  {
    key: 'Instalación de fin de viaje',
    title: 'Instalación de fin de viaje',
  },
  {
    key: 'Código de instalación de fin de viaje',
    title: 'Código de instalación de fin de viaje',
  },
  {
    key: 'Alias de instalación de fin de viaje',
    title: 'Alias de instalación de fin de viaje',
  },
  {
    key: 'Lugar de disponibilidad',
    title: 'Lugar de disponibilidad',
  },
  {
    key: 'Compañia de la instalación de disponibilidad',
    title: 'Compañía de la instalación de disponibilidad',
  },
  {
    key: 'Código del lugar de disponibilidad',
    title: 'Código del lugar de disponibilidad',
  },
  {
    key: 'Alias del lugar de disponibilidad',
    title: 'Alias del lugar de disponibilidad',
  },
  {
    key: 'Fecha de disponibilidad UTC',
    title: 'Fecha de disponibilidad UTC',
  },
  {
    key: 'Fecha de disponibilidad hora local',
    title: 'Fecha de disponibilidad hora local',
  },
  {
    key: 'ETA Hora UTC  Lugar de Disponibilidad',
    title: 'ETA UTC Lugar de Disponibilidad',
  },
  {
    key: 'ETA Hora Local Lugar de disponibilidad',
    title: 'ETA Hora local Lugar de Disponibilidad',
  },
  {
    key: 'Fecha de disponibilidad  manual hora UTC',
    title: 'Fecha de disponibilidad manual UTC',
  },
  {
    key: 'Fecha de disponibilidad  manual hora local',
    title: 'Fecha de disponibilidad manual hora local',
  },
  {
    key: 'Instalaciones de dispo Manual',
    title: 'Instalaciones de dispo Manual',
  },
  {
    key: 'Codigo de Instalacion de dispo Manual',
    title: 'Código de instalación de dispo manual',
  },
  {
    key: 'Alias de Instalacion de dispo Manual',
    title: 'Alias de instalación de dispo manual',
  },
  {
    key: 'Fecha UTC de creacion disponibilidad manual',
    title: 'Fecha UTC de creación de la disponibilidad manual',
  },
  {
    key: 'Fecha local de creación de la disponibilidad manual',
    title: 'Fecha local de creación de la disponibilidad manual',
  },
  {
    key: 'Usuario disponibilidad manual',
    title: 'Usuario disponibilidad manual',
  },
  {
    key: 'No. Folios activos',
    title: 'No. Folios activos',
  },
  // {
  //   key: 'Destino deseado',
  //   title: 'Destino deseado',
  // },
  // {
  //   key: 'Compañía de la instalación de destino deseado',
  //   title: 'Compañia de la instalación de destino deseado',
  // },
  // {
  //   key: 'Código de destino deseado',
  //   title: 'Código de destino deseado',
  // },
  // {
  //   key: 'Alias de destino deseado',
  //   title: 'Alias de destino deseado',
  // },
]

export const columnsDataTR = [
  {
    key: 'Fecha de la descarga de la disponibilidad',
    title: 'Fecha de la descarga de la disponibilidad',
  },
  {
    key: 'Id_Contract',
    title: 'Id_Contract',
  },
  {
    key: 'Id_Slot',
    title: 'Id_Slot',
  },
  {
    key: 'Estatus de Slot',
    title: 'Estatus de Slot',
  },
  {
    key: 'Fecha de creación de la disponibilidad*',
    title: 'Fecha de creación de la disponibilidad*',
  },
  {
    key: 'Dispositivo*',
    title: 'Dispositivo*',
  },
  {
    key: 'Usuario',
    title: 'Usuario',
  },
  {
    key: 'Instalación',
    title: 'Instalación',
  },
  {
    key: 'Código de instalación',
    title: 'Código de instalación',
  },
  {
    key: 'Alias de instalación',
    title: 'Alias de instalación',
  },
  {
    key: 'Operación',
    title: 'Operación',
  },
  {
    key: 'Clase de expedición del contrato',
    title: 'Clase de expedición del contrato',
  },
  {
    key: 'Grupo de clase de expedición',
    title: 'Grupo de clase de expedición',
  },
  {
    key: 'Configuración vehicular',
    title: 'Configuración vehicular',
  },
  {
    key: 'Estatus*',
    title: 'Estatus*',
  },
  {
    key: 'Folio',
    title: 'Folio*',
  },
  {
    key: 'Viaje',
    title: 'Viaje',
  },
  {
    key: 'Orbi id dueño de carga*',
    title: 'Orbi id dueño de carga*',
  },
  {
    key: 'Alias dueño de carga',
    title: 'Alias dueño de carga',
  },
  {
    key: 'Cliente',
    title: 'Cliente',
  },
  {
    key: 'Operador*',
    title: 'Operador*',
  },
  {
    key: 'Vehículo*',
    title: 'Vehículo*',
  },
  {
    key: 'Placas vehículo*',
    title: 'Placas vehículo*',
  },
  {
    key: 'Clase de vehículo',
    title: 'Clase de vehículo',
  },
  {
    key: 'Peso vehículo*',
    title: 'Peso vehículo*',
  },
  {
    key: 'Remolque 1**',
    title: 'Remolque 1**',
  },
  {
    key: 'Placas remolque 1**',
    title: 'Placas remolque 1**',
  },
  {
    key: 'Peso R1**',
    title: 'Peso R1**',
  },
  {
    key: 'Clase R1**',
    title: 'Clase R1**',
  },
  {
    key: 'SubTipo R1**',
    title: 'Subtipo R1**',
  },
  {
    key: 'Remolque 2***',
    title: 'Remolque 2***',
  },
  {
    key: 'Placas remolque 2***',
    title: 'Placas remolque 2***',
  },
  {
    key: 'Peso R2***',
    title: 'Peso R2***',
  },
  {
    key: 'Clase R2***',
    title: 'Clase R2***',
  },
  {
    key: 'SubTipo R2***',
    title: 'Subtipo R2***',
  },
  {
    key: 'Dolly***',
    title: 'Dolly***',
  },
  {
    key: 'Placas dolly***',
    title: 'Placas dolly***',
  },
  {
    key: 'Peso dolly***',
    title: 'Peso dolly***',
  },
  {
    key: 'Clase dolly***',
    title: 'Clase dolly***',
  },
  {
    key: 'Tipo dolly***',
    title: 'Tipo dolly***',
  },
  {
    key: 'Peso total (tara)',
    title: 'Peso total (tara)',
  },
  {
    key: 'Proveedor GPS',
    title: 'Proveedor GPS',
  },
  {
    key: 'Identificador GPS',
    title: 'Identificador GPS',
  },
  {
    key: 'Fecha de fin de viaje',
    title: 'Fecha fin de viaje',
  },
  {
    key: 'Instalación de fin de viaje',
    title: 'Instalación de fin de viaje',
  },
  {
    key: 'Código de instalación de fin de viaje',
    title: 'Código de instalación de fin de viaje',
  },
  {
    key: 'Alias de instalación de fin de viaje',
    title: 'Alias de instalación de fin de viaje',
  },
  {
    key: 'Lugar de disponibilidad',
    title: 'Lugar de disponibilidad',
  },
  {
    key: 'Compañia de la instalación de disponibilidad',
    title: 'Compañía de la instalación de disponibilidad',
  },
  {
    key: 'Código del lugar de disponibilidad',
    title: 'Código del lugar de disponibilidad',
  },
  {
    key: 'Alias del lugar de disponibilidad',
    title: 'Alias del lugar de disponibilidad',
  },
  {
    key: 'Fecha de disponibilidad UTC',
    title: 'Fecha de disponibilidad UTC',
  },
  {
    key: 'Fecha de disponibilidad hora local',
    title: 'Fecha de disponibilidad hora local',
  },
  {
    key: 'ETA Hora UTC  Lugar de Disponibilidad',
    title: 'ETA UTC Lugar de Disponibilidad',
  },
  {
    key: 'ETA Hora Local Lugar de disponibilidad',
    title: 'ETA Hora local Lugar de Disponibilidad',
  },
  {
    key: 'Fecha de disponibilidad  manual hora UTC',
    title: 'Fecha de disponibilidad manual UTC',
  },
  {
    key: 'Fecha de disponibilidad  manual hora local',
    title: 'Fecha de disponibilidad manual hora local',
  },
  {
    key: 'Instalaciones de dispo Manual',
    title: 'Instalaciones de dispo Manual',
  },
  {
    key: 'Codigo de Instalacion de dispo Manual',
    title: 'Código de instalación de dispo manual',
  },
  {
    key: 'Alias de Instalacion de dispo Manual',
    title: 'Alias de instalación de dispo manual',
  },
  {
    key: 'Fecha UTC de creacion disponibilidad manual',
    title: 'Fecha UTC de creación de la disponibilidad manual',
  },
  {
    key: 'Fecha local de creación de la disponibilidad manual',
    title: 'Fecha local de creación de la disponibilidad manual',
  },
  {
    key: 'Usuario disponibilidad manual',
    title: 'Usuario disponibilidad manual',
  },
  {
    key: 'No. Folios activos',
    title: 'No. Folios activos',
  },
]