//@flow
import Axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { getEntityItems, readEntities } from "../../redux/api";
import { listCompanyTags } from "../../redux/reducers/company-reducer";
import { SERVER, FILES_SERVER } from "../../redux/api/api-urls";
import FilterBar from "../../shared/component/filter/filter-bar";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { View, ViewTitle } from "../../shared/styled/view";
import { groupFacilities } from "../../shared/utils";

type StateToProps = {|
|};

type DispatchToProps = {||};

type OwnProps = {|
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {};

class KPIView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
      filterTimes: [],
      filterUnits: [],
      // filterRange: [],
      // filterFields: [],
      filterOperation: [],
      filterDate: [],
      filterFacilities: [],
      autopage: true,
      // url: `${FILES_SERVER}/files/kpi/c953a527-cd48-4f5f-9d67-7e1ada439b6d.html`
      url: null,

      time: [ {id: 1, description: "Tiempo de atención"}, {id: 2, description: "Tiempo de ocio"}, {id: 3, description: "Tiempo de espera"} ],
      units: [ {id: 1, description: "Unidades en atención"}, {id: 2, description: "Unidades en ocio"}, {id: 3, description: "Unidades en espera"} ],
      // range: [ {id: 1, description: "Unidades en atención"}, {id: 2, description: "Unidades en ocio"}, {id: 3, description: "Unidades en espera"} ],
      // fields: [],
      operation: [],
    }
  }

  componentWillUnmount(){
    if (this.state.timer) clearTimeout(this.state.timer);
    this.setState({autopage: false});
  }

  componentDidMount() {
    this.props.loadFacility({
      company_id: this.props.companyId
    }, {
      onSuccess: (result) => {
        this.setState({loading: false, filterFacilities: result.map(f => f.id)})
      }
    })
    this.props.listCompanyTags("2").then((result) => {
      result.forEach(e => e.description = e.title);
      this.setState({loading: false, fields: result});
    })
    this.props.listCompanyTags("7").then((result) => {
      result.forEach(e => e.description = e.title);
      this.setState({loading: false, operation: result});
    })
  }

  getUrl = () => {
    const time = (this.state.filterTimes || []).join(",");
    const units = (this.state.filterUnits || []).join(",");
    // const range = (this.state.filterRange || []).join(",");
    // const fields = (this.state.filterFields || []).join(",");
    const operation = (this.state.filterOperation || []).join(",");
    const facilities = (this.state.filterFacilities || []).join(",");
    if(this.state.filterDate.length === 0){
      const date = new Date();
      const startDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;//T00:00:00.000Z`;
      const endDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;//T23:59:59.999Z`;
      return `${SERVER}/api/v2/companies/${this.props.companyId}/kpi/?start_date=${startDate}&end_date=${endDate}&facilities=${facilities}&time=${time}&units=${units}&operation=${operation}`;
    } else {
      const startDate = this.state.filterDate[0] ? `${this.state.filterDate[0].getFullYear()}-${this.state.filterDate[0].getMonth() + 1}-${this.state.filterDate[0].getDate()}` : "";
      const endDate = this.state.filterDate[1] ? `${this.state.filterDate[1].getFullYear()}-${this.state.filterDate[1].getMonth() + 1}-${this.state.filterDate[1].getDate()}` : "";
      return `${SERVER}/api/v2/companies/${this.props.companyId}/kpi/?start_date=${startDate}&end_date=${endDate}&facilities=${facilities}&time=${time}&units=${units}&operation=${operation}`;
    }
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  handleFilter = () => {
    let url = this.getUrl();
    this.setState({loading: true});
    Axios({
      url: url,
      method: "GET",
      headers: this.getToken(),
    }).then((response) => {
      this.setState({loading: false, url: `${FILES_SERVER}/${response.data}`});
    });

    if (this.state.timer) clearTimeout(this.state.timer);
    if (this.state.autopage) {
      var timer = setTimeout(() => {
        this.handleFilter();
      }, 15 * 60 * 1000);
      this.setState({ timer });
    }
  }

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "filterTimes":
        this.setState({ filterUnits: [], filterRange: [] });
        break;
      case "filterUnits":
        this.setState({ filterTimes: [], filterRange: [] });
        break;
      // case "filterRange":
      //   this.setState({ filterUnits: [], filterTimes: [] });
      //   break;
      default:
        break;
    }
  }

  handleSearch = () => {

  }

  filterContents = () => {
    return [
      {
        title: "Tiempos en instalación",
        items: this.state.time,
        name: "filterTimes",
        listType: 5,
      },
      {
        title: "Cantidad de unidades",
        items: this.state.units,
        name: "filterUnits",
        listType: 5,
      },
      // {
      //   title: "Unidades por rango de tiempos",
      //   items: this.state.range,
      //   name: "filterRange",
      //   listType: 5,
      // },
      // {
      //   title: "Etiquetas",
      //   items: this.state.fields,
      //   name: "filterFields",
      //   listType: 5,
      // },
      {
        title: "Operación",
        items: this.state.operation,
        name: "filterOperation",
        listType: 5,
      },
      {
        title: "Instalación",
        items: this.props.facilitiesByTag,
        name: "filterFacilities",
        listType: 5,
      },
      {
        title: "Fecha",
        name: "filterDate",
        listType: FILTER_LIST_TYPES.dateRangeFixed,
      },
    ]
  }

  render() {
    return (
      <View className={"gps-view kpi-view"}>
        <ViewTitle>KPIs</ViewTitle>
        <div className="panel">
          <div className="filters">
            <FilterBar
              filterAction={this.handleFilter}
              searchStr={this.state.searchStr}
              textSearch={false}
              selectedItems={{
                filterDate: this.state.filterDate,
                filterFacilities: this.state.filterFacilities,
                filterTimes: this.state.filterTimes,
                filterUnits: this.state.filterUnits,
                // filterRange: this.state.filterRange,
                // filterFields: this.state.filterFields,
                filterOperation: this.state.filterOperation,
              }}
              onChange={this.filtersChange}
              searchAction={this.handleSearch}
              content={this.filterContents()}
            />
          </div>
          <div className={"data-display"}>
            <div className="map-area">
              {this.state.url === null ? 
                <h1 style={{ color: "white", justifyContent: "center", display: "flex" }}>
                  Selecciona los filtros
                </h1> : 
                <iframe id="inlineFrameExample"
                  title="Inline Frame Example"
                  className="iFrame"
                  // width="1300"
                  // height="600"
                  src={this.state.url}
                  >
                </iframe>
              }
            </div>
          </div>
        </div>
        
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company.type?.id;
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);
  const firstFacility = companyFacilities[0]?.id || 0;
  return {
    companyId,
    companyType,
    facilitiesByTag,
    firstFacility
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadFacility: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    listCompanyTags: (type) =>
      dispatch(listCompanyTags(type)),
  }
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(KPIView);