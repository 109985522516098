import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';

Modal.setAppElement('#root');

class InvitationDialog extends React.Component {
  onClose = () => {
    if (this.props.closeAction)
      this.props.closeAction();
  }
  render() {
    return (
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog invitation-dialog"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">{this.props.title}</div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.props.message}</div>
        <div className="button_container">
          <Button settings={{
            text: this.props.acceptText,
            onClick: this.props.acceptAction
          }} />
        </div>
      </Modal>
    );
  }
}

export default InvitationDialog;