import React from "react";
import Tag from "../controls/tag";
import Selector from "./selector";
import ValidatePerms from "./validate-perms";

class TagCategoryContainer extends React.Component {
  toggleSelectionMode = () => {
    this.props.toggleSelectionMode(this.props.id);
  };

  validShow = (perm) => {
    let response = this.props.validateObjPerms(
      [perm],
      {
        display: true,
      },
      "or"
    );

    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  validTag = (perm, tag) => {
    let response = this.props.validateObjPerms(perm, {
      display: true,
    });

    if (Object.keys(response).length) {
      return (
        <Tag
          key={tag.id}
          id={tag.id}
          color={tag.color}
          title={tag.title}
          type={tag.type}
          onClick={this.props.handleTagClick}
          parentBackground="dark"
        />
      );
    } else {
      return (
        <Tag
          key={tag.id}
          id={tag.id}
          color={tag.color}
          title={tag.title}
          type={tag.type}
          parentBackground="dark"
        />
      );
    }
  };

  render() {
    const { permsTabs, permsAdd, permsUpdate } = this.props;

    return (
      <div className="tag-category-container">
        <div className="title">{this.props.title}</div>
        <div className="tags-container">
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection-on"
            selectedItems={this.props.selectedTags}
            onSelect={this.props.selectTag}
            selectionModeOn={this.props.selectionModeOn}
            toggleSelectionMode={this.toggleSelectionMode}
            showSelector={this.validShow(permsTabs)}
          >
            {this.props.tags.map((tag) => {
              return this.validTag(permsUpdate, tag);
            })}
            {this.props.children}
          </Selector>
          <ValidatePerms perms={permsAdd}>
            <div
              className="add-icon"
              onClick={() => this.props.handleCreateClick(this.props.id)}
            >
              +
            </div>
          </ValidatePerms>
        </div>
      </div>
    );
  }
}

export default TagCategoryContainer;

TagCategoryContainer.defaultProps = {
  title: "-",
  tags: [],
  showEditModal: () => {
    console.log("Not yet implemented");
  },
  selectTag: () => {
    console.log("Not yet implemented");
  },
};
