import React from "react";
// import ReactTooltip from "react-tooltip";
import SubStringText from "../substring-text";

const FleetDataTitle = (props) => {
  const { isDataCollapsed, companyName, logoUrl } = props;

  // function getCompanyName(companyName) {
  //   let text =
  //     String(companyName).length > 10
  //       ? String(companyName).substring(0, 10) + "... "
  //       : companyName || "";

  //   return {
  //     isLarge: String(companyName).length > 10 ? true : false,
  //     text: text,
  //   };
  // }

  return (
    <div className="fleet-container fleet-title line-container">
      {!isDataCollapsed ? (
        <div className="fleet-container fleet-values-title">
          <div className="fleet-container fleet-values-title data-value">
            Vencido
          </div>
          <div className="fleet-container fleet-values-title data-value">
            Incompleto
          </div>
          <div className="fleet-container fleet-values-title data-value">
            Rechazados
          </div>
          <div className="fleet-container fleet-values-title data-value">
            Pendientes
          </div>
          <div className="fleet-container fleet-values-title data-value">
            Válidados
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="fleet-container fleet-values-title data-image">
              <img
                alt=""
                src={logoUrl}
                style={{ flex: 1, width: 45, height: 45 }}
              />
            </div>
            <SubStringText
              label={companyName}
              labelTag={"h2"}
              labelSize={10}
              labelStyle={{ marginLeft: "15px" }}
              tooltipClass={"tooltip-class-" + companyName}
            />
            {/* {getCompanyName(companyName).isLarge ? (
              <React.Fragment>
                <h2
                  style={{ marginLeft: "15px" }}
                  data-tip
                  data-for={"tooltip-class"}
                >
                  {getCompanyName(companyName).text}
                </h2>
                <ReactTooltip
                  id="tooltip-class"
                  place={"bottom"}
                  type={"light"}
                  effect="solid"
                >
                  {companyName}
                </ReactTooltip>
              </React.Fragment>
            ) : (
              <h2 style={{ marginLeft: "15px" }}>
                {getCompanyName(companyName).text}
              </h2>
            )} */}
            )}
          </div>
        </div>
      ) : (
        <div className="fleet-container fleet-values-title">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="fleet-container fleet-values-title data-image">
              <img
                alt=""
                src={logoUrl}
                style={{ flex: 1, width: 45, height: 45 }}
              />
            </div>
            <SubStringText
              label={companyName}
              labelTag={"h2"}
              labelSize={10}
              labelStyle={{ marginLeft: "15px" }}
              tooltipClass={"tooltip-class-" + companyName}
            />
            {/* {getCompanyName(companyName).isLarge ? (
              <React.Fragment>
                <h2
                  style={{ marginLeft: "15px" }}
                  data-tip
                  data-for={"tooltip-class"}
                >
                  {getCompanyName(companyName).text}
                </h2>
                <ReactTooltip
                  id="tooltip-class"
                  place={"bottom"}
                  type={"light"}
                  effect="solid"
                >
                  {companyName}
                </ReactTooltip>
              </React.Fragment>
            ) : (
              <h2 style={{ marginLeft: "15px" }}>
                {getCompanyName(companyName).text}
              </h2>
            )} */}
          </div>
        </div>
      )}

      {/* <div className="line" /> */}
    </div>
  );
};

export default FleetDataTitle;
