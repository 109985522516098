import { BACKEND, getApiUrl } from "../../shared/backend-api";
import store from "../store";

export const TOGGLE_VIEW_MODE = "TOGGLE_VIEW_MODE";

export const TOGGLE_SELECTED_FACILITY = "TOGGLE_SELECTED_FACILITY";
export const SEARCH_FACILITIES_BY_NAME = "SEARCH_FACILITIES_BY_NAME";

export const REQUEST_GET_FACILITIES_BY_COMPANY =
  "REQUEST_GET_FACILITIES_BY_COMPANY";
export const SUCCESS_GET_FACILITIES_BY_COMPANY =
  "SUCCESS_GET_FACILITIES_BY_COMPANY";
export const ERROR_GET_FACILITIES_BY_COMPANY =
  "ERROR_GET_FACILITIES_BY_COMPANY";

export const REQUEST_GET_FILTERED_FACILITIES_BY_COMPANY =
  "REQUEST_GET_FILTERED_FACILITIES_BY_COMPANY";
export const SUCCESS_GET_FILTERED_FACILITIES_BY_COMPANY =
  "SUCCESS_GET_FILTERED_FACILITIES_BY_COMPANY";
export const ERROR_GET_FILTERED_FACILITIES_BY_COMPANY =
  "ERROR_GET_FILTERED_FACILITIES_BY_COMPANY";

export const SEARCH_FACILITIES_BY_TEXT = "SEARCH_FACILITIES_BY_TEXT";

export const toggleViewMode = viewMode => ({
  type: TOGGLE_VIEW_MODE,
  payload: { viewMode }
});

export const searchByText = searchStr => ({
  type: SEARCH_FACILITIES_BY_TEXT,
  searchStr
});

export const toggleSelectedFacility = selectedFacility => ({
  type: TOGGLE_SELECTED_FACILITY,
  payload: { selectedFacility: selectedFacility }
});

export const searchFacilitiesByName = name => ({
  type: SEARCH_FACILITIES_BY_NAME,
  payload: { name: name }
});

export const requestGetFacilitiesByCompany = id => ({
  type: REQUEST_GET_FACILITIES_BY_COMPANY,
  id: id
});
export const successGetFacilitiesByCompany = json => ({
  type: SUCCESS_GET_FACILITIES_BY_COMPANY,
  data: json
});
export const errorGetFacilitiesByCompany = error => ({
  type: ERROR_GET_FACILITIES_BY_COMPANY,
  error: error
});

export const requestGetFilteredFacilitiesByCompany = id => ({
  type: REQUEST_GET_FILTERED_FACILITIES_BY_COMPANY,
  id: id
});
export const successGetFilteredFacilitiesByCompany = json => ({
  type: SUCCESS_GET_FILTERED_FACILITIES_BY_COMPANY,
  data: json
});
export const errorGetFilteredFacilitiesByCompany = error => ({
  type: ERROR_GET_FACILITIES_BY_COMPANY,
  error: error
});

const initialState = {
  viewMode: "grid",
  isFetching: false,
  error: "",
  originalFacilities: [],
  facilities: [],
  selectedFacility: 0,
  searchStr: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_VIEW_MODE: {
      const viewMode = action.payload.viewMode;
      return Object.assign({}, state, {
        viewMode: viewMode
      });
    }

    case TOGGLE_SELECTED_FACILITY: {
      const selectedFacility = action.payload.selectedFacility;
      return Object.assign({}, state, {
        selectedFacility: selectedFacility
      });
    }

    case SEARCH_FACILITIES_BY_NAME: {
      const name = action.payload.name.toLowerCase();
      let facilities = state.originalFacilities.filter(f => {
        return (
          f.name.toLowerCase().includes(name) ||
          f.code.toLowerCase().includes(name) ||
          f.alias.toLowerCase().includes(name) ||
          f.tags.some(tag => tag.title.toLowerCase().includes(name))
        );
      });
      return Object.assign({}, state, {
        facilities: {
          count: state.facilities.count,
          results: facilities
        }
      });
    }

    case REQUEST_GET_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: false,
        facilities: action.data,
        originalFacilities: action.data.results
      });
    }
    case ERROR_GET_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case REQUEST_GET_FILTERED_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case SUCCESS_GET_FILTERED_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: false,
        facilities: action.data,
        originalFacilities: action.data.results
      });
    }
    case ERROR_GET_FILTERED_FACILITIES_BY_COMPANY: {
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    }

    case SEARCH_FACILITIES_BY_TEXT:
      return Object.assign({}, state, {
        searchStr: action.searchStr
      });
    default:
      return state;
  }
}

export const loadFacilitiesByCompany = (limit = 10, offset = 1) => {
  let idCompany = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(requestGetFacilitiesByCompany(idCompany));
    return getApiUrl(BACKEND.FACILITY.GET_CATALOG, {
      limit: limit,
      offset: offset
    })
      .then(
        response => response.json(),
        error => dispatch(errorGetFacilitiesByCompany(error))
      )
      .then(data => dispatch(successGetFacilitiesByCompany(data)));
  };
};

export const loadFilteredFacilitiesByCompany = (idCompany, filters) => {
  // console.log('filtros ', filters)
  return function(dispatch) {
    dispatch(requestGetFilteredFacilitiesByCompany(idCompany));
    return getApiUrl(
      BACKEND.FACILITY.GET_CATALOG_FILTERS,
      /* {idCompany: idCompany}, */ filters
    )
      .then(
        response => response.json(),
        error => dispatch(errorGetFilteredFacilitiesByCompany(error))
      )
      .then(data => dispatch(successGetFilteredFacilitiesByCompany(data)));
  };
};

export const searchFacilities = searchStr => {
  return function(dispatch) {
    dispatch(searchByText(searchStr));
  };
};
