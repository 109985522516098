import * as React from "react";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import TextInput from "../../shared/controls/text-input";
import { normalizeStr } from "../../shared/utils";
import ModalSelectInput from "../shipment/modal-select-input";
import ProductCard from "./product-card";
import ProductRow from "./product-row";

class CreateOrderModal extends React.Component {
  state = {
    stage: 1,
    orderModalSearchStr: ""
    // products: []
  };

  static defaultProps = {
    onClose: () => {},
    selectedProducts: [],
    show: false
  }

  clearSearch = () => {
    this.setState({
      orderModalSearchStr: ""
    })
  }

  nextStage = () => {
    this.setState({
      stage: this.state.stage + 1,
    })
  }

  prevStage = () => {
    this.setState({
      stage: this.state.stage - 1
    })
  }

  onClose = () => {
    this.setState({
      stage: 1
    })
    this.props.onClose();
  }

  finalizeOrder = () => {
    if(this.state.stage === 3 && !this.props.selectedProducts.length){
      this.nextStage();
    } else {
      this.props.handleChange({
        target: {
          name: "createOrder",
          value: this.onClose
        }
      })
    }
  }

  getTitle = () => {
    switch(this.state.stage){
      case 1:
      case 2:
        return "Pedido de compra para ".concat(this.props.currentFacilityName);
      case 3: 
        return "Confirmar pedido de compra n°".concat(this.props.selectedCode);
      case 4:
        return "Crear pedido"
      default: 
        return ""
    }
    // return "Orden de compra para"
  }

  handleChange = (e) => {
    // debugger;
    this.setState({[e.target.name]: e.target.value})
  }

  getContent = () => {
    switch(this.state.stage){
      case 1:
        return (
          <div className="first-step-options">
            <ModalSelectInput
              isSearchable={false}
              isDisabled={false}
              // defaultValue={this.props.providerCatalog[0]}
              onChange={(a) =>
                this.props.handleChange({
                  target: { name: "selectedProvider", value: a.value },
                })
              }
              name="selectedProvider"
              placeholder="Proveedor"
              value={this.props.providerCatalog.find(fC => fC.value === this.props.selectedProvider) || null}
              options={this.props.providerCatalog}
            />
            <ModalSelectInput
              isSearchable={false}
              isDisabled={!this.props.selectedProvider}
              // defaultValue={this.props.facilitiesCatalog[0]}
              onChange={(a) =>
                this.props.handleChange({
                  target: { name: "selectedFacility", value: a.value },
                })
              }
              name="selectedFacility"
              placeholder="Sucursal"
              value={this.props.facilitiesCatalog.find(fC => fC.value === this.props.selectedFacility) || null}
              options={this.props.facilitiesCatalog}
            />
            <TextInput
              label="Número de orden"
              name="selectedCode"
              value={this.props.selectedCode}
              className="item"
              onChange={this.props.handleChange}
              // validate={[validators.required, "maxLength"]}
              // validationParam={4}
              // validate={[validators.required]}
              //   onFocused={onFocused}
              // disabled={true}
            />
          </div>
        )
      case 2: 
        return (
          <div className="second-step">
            <div className="headers">
              
              <TextInput
                label="Buscar producto"
                name="orderModalSearchStr"
                value={this.state.orderModalSearchStr}
                className="search"
                onChange={this.handleChange}
                // validate={[validators.required, "maxLength"]}
                // validationParam={4}
                // validate={[validators.required]}
                //   onFocused={onFocused}
                // disabled={true}
              />

              <div className="counters">
                {
                  // this.props.selectedProducts.length 
                  `${this.props.selectedProducts.length} producto(s) agregado(s)`
                  // : "Carrito vacio"
                }
              </div>
            </div>
            <div className="cards-container">
              {
                this.props.productCatalog
                  .filter(p => 
                    normalizeStr(p.sku || "").includes(normalizeStr(this.state.orderModalSearchStr)) 
                    || normalizeStr(p.description || "").includes(normalizeStr(this.state.orderModalSearchStr))
                  ).map(p => {
                  return <ProductCard 
                    key={p.id}
                    productInfo={p} 
                    unitCatalog={this.props.unitsCatalog}
                    qty={(this.props.selectedProducts.find(sP => sP.id === p.id) || {qty: ''}).qty}
                    handleChange={this.props.handleChange}
                  />
                })
              }
            </div>
          </div>
        )
      case 3:
        return (
          <div className="third-step">
            <div className="headers">
              <div className="avatar">
                <div className="picture"></div>
                <div className="data">
                  <div><b>Proveedor:</b> {(this.props.providerCatalog.find(p => p.value === this.props.selectedProvider) || {label: "-"}).label}</div>
                  <div><b>Origen:</b> {(this.props.facilitiesCatalog.find(p => p.value === this.props.selectedFacility) || {label: "-"}).label}</div>
                </div>
              </div>
              <div className="avatar">
                <div className="picture"></div>
                <div className="data">
                  <div><b>Destino:</b> {this.props.currentFacilityName}</div>
                </div>
              </div>
            </div>
            {
              this.props.selectedProducts.length ?
              <>
                <div className="table-title">
                  Detalles del pedido:
                </div>
                <div className="table-header">
                  <div style={{width: "30%"}}>Producto</div>
                  <div style={{width: "19%"}}>SKU</div>
                  <div style={{width: "10%"}}>Cantidad</div>
                  <div style={{width: "9%"}}>Unidades</div>
                  <div style={{width: "10%"}}>Peso Neto</div>
                  <div style={{width: "10%"}}>Peso Total</div>
                  <div style={{width: "10%"}}>Volumen</div>
                  <div style={{width: "2%"}}></div>
                </div>
                <div className="table-body">
                  {
                    this.props.selectedProducts
                    .map(sP => {
                      let productInfo = this.props.productCatalog.find(p => p.id === sP.id) || {};
                      return <ProductRow 
                        key={productInfo.id}
                        productInfo={{...productInfo, product: productInfo}} 
                        unitCatalog={this.props.unitsCatalog}
                        qty={sP.qty}
                        removeProduct={(id) => {this.props.handleChange({target: {name:'removeProduct', value: id}})}}
                      />
                    })
                  }
                </div>
                <div className="resume">
                  <div style={{width: "30%"}}>Total</div>
                  <div style={{width: "19%"}}></div>
                  <div style={{width: "10%"}}>{this.props.selectedProducts.reduce((acum, value) => {return (acum + Number(value.qty))}, 0)}</div>
                  <div style={{width: "9%"}}></div>
                  <div style={{width: "10%"}}>{}</div>
                  <div style={{width: "10%"}}>{}</div>
                  <div style={{width: "10%"}}>{}</div>
                  <div style={{width: "2%"}}></div>
                </div>
              </>
              : ''
            }
            {/* <div className="table-title">
              Detalles del envío:
            </div>
            <div>

            </div> */}
          </div>
        )
      case 4:
        return (
          <div style={{fontSize: "23px", margin: "25px 0"}}>
            ¿Estás seguro de crear orden sin producto?
          </div>
        )
    }
    return <div></div>
  }

  render() {
    return(
      <Modal
        isOpen={this.props.show}
        portalClassName="dialog emails-modal invite-users-modal create-orders-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">
          {this.getTitle()}
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">{this.getContent()}</div>
        <div className="actions">
          {
            this.state.stage === 1 &&
            <Button
              text={"Continuar sin producto"}
              type="secondary"
              onClick={() => {this.setState({stage: 3})}}
              disabled={!this.props.selectedProvider || !this.props.selectedFacility || !this.props.selectedCode}
            />
          }
          {
            (this.state.stage === 2 || this.state.stage === 3) &&
            <Button
              text={"Atras"}
              type="primary outline"
              onClick={() => {this.prevStage(); this.clearSearch()}}
              disabled={false}
            />
          }
          {
            this.state.stage === 1 &&
            <Button
              text={"Agregar producto"}
              type="primary"
              onClick={this.nextStage}
              disabled={!this.props.selectedProvider || !this.props.selectedFacility || !this.props.selectedCode}
            />
          }
          {
            this.state.stage === 2 &&
            <Button
              text={"Ir al carrito"}
              type="primary"
              onClick={() => {this.nextStage(); this.clearSearch()}}
              disabled={false}
            />
          }
          {
            this.state.stage === 4 &&
            <Button
              text={"Agregar producto"}
              type="primary outline"
              onClick={() => {this.setState({stage: 2})}}
              disabled={false}
            />
          }
          {
            (this.state.stage === 4 || this.state.stage === 3) &&
            <Button
              text={"Crear pedido"}
              type="primary outline"
              onClick={this.finalizeOrder}
              disabled={false}
            />
          }
        </div>
      </Modal>
    )
  }
}

export default CreateOrderModal;