import React from "react";
import { getUrlsEnv } from "../backend-api";

class UserInfo extends React.Component {
  getAvatarUrlCard = logo => {
    if (logo !== null && logo !== "") {
      return `${getUrlsEnv().files}${logo}`;
    } else {
      return "/" + this.props.avatarSecond;
    }
  };

  render() {
    return (
      <div className="user-info">
        <div className="user-avatar">
          <img
            className="avatar"
            alt="User avatar"
            src={this.getAvatarUrlCard(this.props.avatar)}
          />
        </div>
        <div className="user-content">
          {this.props.title !== "" ? (
            <div className="user-title">{this.props.title}</div>
          ) : (
            ""
          )}
          {this.props.subtitle !== "" ? <div>{this.props.subtitle}</div> : ""}
          {this.props.extra !== "" ? <div>{this.props.extra}</div> : ""}
        </div>
      </div>
    );
  }
}

export default UserInfo;
