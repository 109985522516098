import React from 'react';

export default function TitleArrow(props) {

  const { text = '', isOpen = false, showArrow = false, onClick= ()=>{} } = props;

  return(
    <div className={'title-arrow'}
      onClick={ () => onClick(!isOpen)}
      onMouseEnter={() => {
        if (props.onMouseEnter) {
          props.onMouseEnter();
        }
      }}
      onMouseLeave={() => {
        if (props.onMouseLeave) {
          props.onMouseLeave();
        }
      }}
    >
      { text }
      {
        showArrow &&
        <img
          style={isOpen ? {transform: `rotate(180deg)`, marginLeft: 10} : { marginLeft: 10}}
          alt=""
          src="/images/caret.svg"
          width={props.isHovered ? 20 : 16}
          height={props.isHovered ? 14 : 10}
        />
      }
    </div>
  );

}