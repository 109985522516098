// @flow
import * as React from "react";
import type { RouterHistory } from "react-router-dom";

import { SummaryPanel } from "./styled/summary-panel";
import { CollapsibleHeaderList } from "./styled/collapsible-header";
import { FacilityRow } from "./styled/facility-row";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { Column } from "./styled/column";
import CalendarSelectorRibbon from "./styled/calendar-selector-ribbon";

import * as Types from "./types";

import { getRibbonWeeklyDates } from "./utils";

import Tag from "../../shared/controls/tag";
import * as SharedTypes from "../../shared/types";
import WeekPicker from "./week-picker";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import { connect } from "react-redux";

const COLUMNS = [
  { description: "Programado", name: "programed" },
  { description: "Adicionales", name: "additional" },
  { description: "Adelantados", name: "early_date" },
  { description: "Retrasados", name: "late_date" },
  { description: "Atendido", name: "attended" },
  { description: "Arribos", name: "arrivals" },
  { description: "Por atender", name: "to_attend" },
  // { description: "Últ. estimación", name: "last_estimate" },
  // { description: "Variación", name: "variation" },
  {
    description: "Últ. est. Variación",
    name: "last_estimate_variation",
    sum: true,
  },
  { description: "Cancelados", name: "canceled" },
  { description: "Reprogramados", name: "rescheduled" },
];

type State = {
  currentDate: Date,
  selectedDate: Date,
  selectedDateId: string,
};

type OwnProps = {
  facilitiesByTag: Array<Types.FacilitiesByTag>,
  loadFacilities: (dateString: string) => void,
};

type Props = {
  ...OwnProps,
  history: RouterHistory,
};

class ShipmentSummaryList extends React.Component<Props, State> {
  state = {
    currentDate: new Date(),
    selectedDate: new Date(),
    selectedDateId: new Date().toISOString().split("T")[0],
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case "calendar":
        this.setState(
          {
            selectedDate: value,
            selectedDateId: value.toISOString().split("T")[0],
            currentDate: value,
          },
          () => this.props.loadFacilities(value.toISOString().split("T")[0])
        );
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  render() {
    const { facilitiesByTag } = this.props;
    const { selectedDate } = this.state;
    return (
      <SummaryPanel>
        <Flex
          justifyContent={flexOpts.justifyContent.center}
          alignItems={flexOpts.alignItems.center}
          style={{ backgroundColor: "#212A3C" }}
        >
          <div style={{ width: "auto", minWidth: "20%" }} />
          <CalendarSelectorRibbon
                  isCalendar={true}
            bgColor="#212A3C"
            selectedUnderlineColor="#E9643F"
            // className="profile-calendar-ribbon"
            onChange={this.handleChangeDay}
            items={getRibbonWeeklyDates(this.state.currentDate, "WEEKDAY")}
            activeId={this.state.selectedDateId}
            isCalendar={true}
          />
          <WeekPicker
            locale="es"
            name="calendar"
            date={selectedDate}
            onChange={this.handleChange}
          />
        </Flex>
        <div style={{ margin: "25px" }}>
          <div style={{ position: "relative" }}>
            <Flex
              style={{
                // width: "100%",
                width: "calc(100% - 12px)",
                // height: "100%",
                height: "calc(100% + 60px)",
                position: "absolute",
                top: "-60px",
                paddingRight: "0px",
              }}
              direction={flexOpts.direction.reverseRow}
            >
              <Flex
                style={{
                  width: "85%",
                  height: "100%",
                }}
                justifyContent={flexOpts.justifyContent.spaceEvenly}
              >
                {COLUMNS.map((column) => (
                  <Column>{column.description}</Column>
                ))}
              </Flex>
            </Flex>
            <div style={{ position: "relative", /* paddingTop: "60px", */ maxHeight: "97%", overflowY: "auto", marginTop: "120px" }}>
              {facilitiesByTag.map((tag) => (
                <CollapsibleHeaderList
                  maxExpandedHeight="auto"
                  contentStyle={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  collapsedHeight="40px"
                  title={
                    <Tag 
                      {...tag} 
                      onClick={(e) => {
                        e.e.stopPropagation(); 
                        this.props.setBaseConfig("ShipmentDetailBase", null);
                        this.props.setBaseConfig("assignedShipmentBoard", null);
                        this.props.setBaseConfig("UnassignedShipmentBoardBase", null);
                        this.props.history.push(
                          `/company/controls/shipment/detail/${tag.facilities[0].id}/?facilities=${tag.facilities.map(f => f.id).join(',')}`
                        )
                      }} 
                    />}
                  caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                  initialStateCollapsed={true}
                  // redirect={() =>
                  //   this.props.history.push(
                  //     `/company/controls/shipment/detail/${tag.facilities[0].id}/?facilities=${tag.facilities.map(f => f.id).join(',')}`
                  //   )
                  // }
                  headerContent={
                    <div
                      style={{
                        width: "85%",
                        display: "flex",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {COLUMNS.map((column) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          {tag.facilities
                            .map((f) => f[column.name])

                            .reduce((total, value, i, a) => {
                              if (!!!column.sum) {
                                return total + value;
                              } else {
                                const t = (total || "0 - 0").split(" - ");
                                const v = value.split(" - ");
                                let first = (parseInt(t[0]) + parseInt(v[0]));
                                let second = (parseInt(t[1]) + parseInt(v[1]));

                                if(i === a.length - 1){
                                  return <><span style={{color: first > 0 ? "#5BCA86" : first < 0 ? "#ff0000" : "#ffffff"}}>{(first || "- ")}</span> - 
                                  <span style={{color: second > 0 ? "#5BCA86" : second < 0 ? "#ff0000" : "#ffffff"}}>{(second || " -")}</span></>
                                } else {
                                  return (first)
                                  .toString()
                                  .concat(" - ", second);
                                }
                                
                              }
                            })

                            || "-"
                          }
                        </div>
                      ))}
                    </div>
                  }
                >
                  {tag.facilities
                    .filter((f) => !f.sum)
                    .sort(function(a, b) {
                      if (a.description > b.description) {
                        return 1;
                      }
                      if (a.description < b.description) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((facility) => (
                      <FacilityRow
                        onClick={() => {
                          this.props.setBaseConfig("ShipmentDetailBase", null);
                          this.props.setBaseConfig("assignedShipmentBoard", null);
                          this.props.setBaseConfig("UnassignedShipmentBoardBase", null);
                          this.props.history.push(
                            `/company/controls/shipment/detail/${facility.id}/`
                          )
                        }}
                      >
                        <div style={{ width: "15%", textAlign: "right", cursor: "pointer" }}>
                          {facility.description}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "85%",
                            justifyContent: "space-evenly",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {COLUMNS.map((column) => {
                            if(facility[column.name].toString().includes(" - ")){
                              let values = facility[column.name].split(" - ");
                              return <div style={{ width: "100%", textAlign: "center" }}>
                                <><span style={{color: values[0] > 0 ? "#5BCA86" : values[0] < 0 ? "#ff0000" : "#ffffff"}}>{((values[0] === "0" ? "- " : values[0]) || "- ")}</span> - 
                                  <span style={{color: values[1] > 0 ? "#5BCA86" : values[1] < 0 ? "#ff0000" : "#ffffff"}}>{((values[1] === "0" ? " -" : values[1]) || " -")}</span></>
                              </div>
                            } else {
                              return <div style={{ width: "100%", textAlign: "center" }}>
                                {facility[column.name] === 0 ? "-" : facility[column.name]}
                              </div>
                            }
                          })}
                        </div>
                      </FacilityRow>
                    ))}
                </CollapsibleHeaderList>
              ))}
            </div>
          </div>
        </div>
      </SummaryPanel>
    );
  }

  handleChangeDay = (e: SharedTypes.SimpleEvent) => {
    const { currentDate } = this.state;
    const { loadFacilities } = this.props;
    if (
      e.target &&
      e.target.attributes["name"] &&
      parseInt(e.target.attributes["name"].value) &&
      e.target.attributes["name"].value.length < 9
    ) {
      let dat = currentDate;
      let d = new Date(
        dat.getFullYear(),
        dat.getMonth(),
        dat.getDate() + parseInt(e.target.attributes["name"].value)
      );
      this.setState({ currentDate: d });
    } else if (e instanceof Date) {
      let d = new Date(e.getFullYear(), e.getMonth(), e.getDate());
      this.setState({
        selectedDate: d,
        currentDate: d,
        selectedDateId: d.toISOString().split("T")[0],
      });
      loadFacilities(d.toISOString().split("T")[0]);
    } else if (
      e.target.attributes["name"] &&
      e.target.attributes["name"].value
    ) {
      let x = e.target.attributes["name"].value.split("-");
      let d = new Date(x[0], parseInt(x[1]) - 1, x[2]);
      this.setState(
        {
          selectedDate: d,
          selectedDateId: d.toISOString().split("T")[0],
        },
        () => loadFacilities(d.toISOString().split("T")[0])
      );
    }
  };
}

const mapStateToProps = (state) => {
  return 
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBaseConfig: (alias, config) => dispatch(setConfig(alias, config)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentSummaryList);

// export default ShipmentSummaryList;
