import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { loadSession, removeToast } from "../../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "../../shared/backend-api";
import { ToastContainer, toast } from "react-toastify";
import Tag from "../../shared/controls/tag";
import { getProduct } from "../../redux/reducers/products.reducer";
import { getEntityItems, readEntities } from "../../redux/api";

class ProductProfile extends React.Component {
  constructor(props) {
    super(props);

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  state = {
    productName: '',
    sku: '',
    netWeight: '',
    grossWeight: '',
    editProductId: 0,
    urlImage: '',
    tags: [],
    sat_code: 0,
    dangerous_product: false,
    dangerous_product_code: 0,
    packing_code: 0,
    data: null
  };

  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  componentDidMount() {
    // this.props.loadProductKeys();
    // this.props.loadDangerCodes();
    // this.props.loadPackingTypes();

    const productIdToEdit = this.props.match?.params?.product || 0

    if ( productIdToEdit !== 0 ) {
      this.props.getProduct(productIdToEdit).then( item => {

        const { data = {} } = item;
        const { description, gross_weight, net_weight, sku, url, producttag_product, sat_code, packing_code, dangerous_product, dangerous_product_code  } = data;

        var profileImage = null;

        if (url !== null) {
          profileImage = getUrlsEnv().files.concat(url);
        }

        this.setState({
          editProductId: productIdToEdit,
          productName: description,
          netWeight: net_weight,
          grossWeight: gross_weight,
          sku,
          urlImage: profileImage,
          tags: producttag_product,
          sat_code: data?.data?.clave_prod_serv_cp?.id ?? '',
          dangerous_product: dangerous_product || false,
          dangerous_product_code: data?.data?.material_peligroso?.id ?? '',
          packing_code: data?.data?.tipo_embalaje?.id ?? 0,
          data: data.data || null
        });

      });
    }

  }

  getDescription = (type) => {
    switch(type){
      case 1: 
        return (this.props.product_keys.find(p => p.id.toString() === this.state.sat_code.toString()) || {}).clave;
      case 2: 
        return (this.props.packing_types.find(p => p.id.toString() === this.state.packing_code.toString()) || {}).clave;
      case 3: 
        return (this.props.danger_codes.find(p => p.id.toString() === this.state.dangerous_product_code.toString()) || {}).clave;
      default: 
        return '';
    }
  }

  render() {
    return (
      <div className="vehicle-profile view">
        <ToastContainer className="global-toast-container" />

        <div className="left-column">
          <div className="credentials panel">
            <div className="upper-row">
              <div className="profile-information">
                <div className="avatars">
                  <img
                    alt=""
                    src={`${
                      this.state.urlImage || "/images/product_avatar.png"
                    }`}
                    style={{
                      backgroundColor: "#FFF",
                      padding: "1px",
                      width: "70px",
                      height: "65px",
                    }}
                  />
                </div>
                <div className="edit">
                  <div
                    className="edit-info"
                    onClick={() =>
                      this.props.history.push(`/company/product/${this.state.editProductId}`)
                    }
                  >
                    <img alt="" src="/images/edit-transparent.svg" />
                  </div>
                </div>
              </div>

              <p className="id">
                {this.state.productName}
              </p>
              {/* <p className="description"> 
                {`SKU: ${this.state.sku}`}
              </p> */}
              <div style={{color: "#afc8ff", fontSize: "14px", display: "flex"}}>
                  <div style={{width: "100%", textAlign: "end"}}>
                    SKU: {this.state.sku}
                  </div>
                      <div style={{width: "100%", textAlign: "end"}}>
                      </div>
                  <div style={{width: "100%", textAlign: "start"}}>
                    Bienes: {this.state.sat_code ? this.getDescription(1) : '-'}
                  </div>
              </div>
              <div style={{color: "#afc8ff", fontSize: "14px", display: "flex"}}>
                <div style={{width: "100%", textAlign: this.state.dangerous_product ? "end" : "center"}}>
                  Peligroso: {this.state.dangerous_product ? 'Sí' : 'No'}
                </div>
                {
                  this.state.dangerous_product ?
                  <>
                    <div style={{width: "100%", textAlign: "center"}}>
                      Embalaje: {this.state.data?.tipo_embalaje?.clave || '-'}
                    </div>
                    <div style={{width: "100%", textAlign: "start"}}>
                      Clave peligro: {this.state.data?.material_peligroso?.clave || '-'} 
                    </div>
                  </>
                  : ''
                }
              </div>

            </div>
            <div className="lower-row-products">
              <div>
                <p className="submodule-title">Peso Neto</p>
                <p>{this.state.netWeight}</p>
              </div>
              <div>
                <p className="submodule-title">Peso Total</p>
                <p>{this.state.grossWeight}</p>
              </div>

            </div>
          </div>
        
        </div>
        <div className="right-column">
          <div className="tags panel">
            <span className="submodule-title">
              Etiquetas:&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <div>
            {
              this.state.tags.map((item) => {
                if (item && item.tag) {
                  const { id = "", title = "", color = "" } = item.tag;
                  return <Tag key={id} id={id} title={title} color={color} />;
                }
              })
            }
            </div>
          </div>
        </div>

      </div>
    );
  }

}

const mapStateToProps = (state) => {
  
  const product_keys = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPRODUCTO");
  const packing_types = getEntityItems(state, "COMPANIES.SAT.CATALOGS.TIPOEMBALAJE");
  const danger_codes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO");
  return {
    toast: state.globalCatalog.toast,
    product_keys,
    packing_types,
    danger_codes,
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadProductKeys: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPRODUCTO", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadPackingTypes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.TIPOEMBALAJE", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadDangerCodes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO", {}, {prefix: 'orbisat.'}) 
      );
    },
    getProduct: (productId) => dispatch(getProduct(productId)),
    removeToast: () => dispatch(removeToast()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductProfile)
);
