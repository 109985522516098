import React from 'react';
import Checkbox from '../../shared/controls/checkbox';
import ExceedPlus from '../../shared/controls/exceed-plus';
import Tag from "../../shared/controls/tag";
import GearIcon from '../../shared/icons/gear-icon';

export default class MapIndicatorsResumesView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstResumeOpen: false,
      secondResumeOpen: false
    };
    
  }

  buildFirstResume = () => {

    const dataToShow = this.props.data?.dataByTags || [];

    return(
      <div className='resume' style={{height: this.state.firstResumeOpen ? '200' : '28'}}>

        <div className='resume-title' onClick={() => { this.setState({ firstResumeOpen: !this.state.firstResumeOpen }) }}>
          <div>
            DESGLOSE POR ETIQUETA
          </div>
          <div className='open-indicator'>
            { this.state.firstResumeOpen ? '-' : '+' }
          </div>
        </div>

        {
          this.state.firstResumeOpen && 
          <div style={{height: "calc(100% - 21px)"}}>
            <div className='row-content'>
              <div className='main-space'>
                <div  className="config" onClick={() => {this.setState({showConfig: !this.state.showConfig})}}>
                  <GearIcon height="20" width="20"/>
                  {
                    this.state.showConfig && 
                    <div className="options-container" onClick={e => {e.stopPropagation()}}>
                      <div style={{marginBottom: "3px", fontWeight: "bold", fontSize: "16px"}}>Columnas</div>
                      {
                        (this.props.data.categoryGroups || this.props.data.tableCategories || []).map((tC, index) => (
                          <div key={index} className='space' style={{textAlign: "left", width: "max-content"}}>
                            <Checkbox
                              key={index}
                              onChange={() => this.props.data.changeColumn(index)}
                              item={{
                                id: index,
                                description: tC.title,
                                active: this.props.data.categoryGroups ? this.props.data.categoryGroups[index].active : (this.props.data.resumeColumnsToShow[index] || false),
                              }}
                              // name={name}
                              // className={(i.items || []).length ? "bold" : ""}
                            />
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
              {
                (this.props.data.tableCategories || []).map((tC, index) => (
                  this.props.data.resumeColumnsToShow[index] 
                  ? <div key={index} className='space'>{tC.title}</div>
                  : ''
                ))
              }
            </div>

            <div className='content-data'>
              {
                dataToShow.map( data => {
                  return(
                    <div className='row-content'>
                      <div className='main-space'>
                        {data.title}
                        {/* <div className='tag' style={{backgroundColor: data.tag.color}}>
                          {data.tag.title}
                        </div> */}
                      </div>
                      {
                        (this.props.data.tableCategories || []).map((tC, index) => (
                          this.props.data.resumeColumnsToShow[index] 
                          ? <div key={index} className='space'>{data[tC.property_name]}</div>
                          : ''
                        ))
                      }
                      {/* <div className='space'>{data.eta}</div>
                      <div className='space'>{data.unitsInFacility}</div>
                      <div className='space'>{data.unitsAttended}</div> */}
                    </div>
                  );
                })
              }
            </div>
          </div>
        }

      </div>
    );
  }

  getTimeFormat = (sec) => {

    const temp1 = parseInt(sec / 60);
    if(temp1 > 23){
      let h = parseInt(temp1 % 24);
      if(h < 10){
        h = '0'.concat(h);
      }
      return `${parseInt(temp1 / 24)}d ${h}h`;
      // return `${parseInt(temp1 / 24)}d ${h}h`;
    }
    const temp2 = parseInt(sec % 60);

    // const temp1String = temp1 < 10 ? `0${temp1}`: `${temp1}`;
    const temp2String = temp2 < 10 ? `0${temp2}`: `${temp2}`;

    return `${temp1}h ${temp2String}m`;

  }

  getTimeFormatChart = (sec) => {

    const temp1 = parseInt(sec / 60);
    if(temp1 > 23){
      let h = parseInt(temp1 % 24);
      if(h < 10){
        h = '0'.concat(h);
      }
      return <div className="chart-legend"><div>{`${parseInt(temp1 / 24)}d`}</div> <div>{`${h}h`}</div></div>;
      // return `${parseInt(temp1 / 24)}d ${h}h`;
    }
    const temp2 = parseInt(sec % 60);

    // const temp1String = temp1 < 10 ? `0${temp1}`: `${temp1}`;
    const temp2String = temp2 < 10 ? `0${temp2}`: `${temp2}`;

    return <div className="chart-legend"><div>{`${temp1}h`}</div> <div>{`${temp2String}m`}</div></div>;

  }

  getHeight = (value, highValue, category) => {
    //console.log('CALCULATE', category, value, highValue);
    return `${value * 100 / highValue}%`;
  }

  buildChart = (highValue, data, is_time) => {

    // const etaPercentage = eta * 100 / highValue;
    // const datePercentage = date * 100 / highValue;
    let total = Object.values(data || {a: 0}).reduce((acum, value) => acum + value);

    return(
      <div className='chart-container'>
        {
          is_time
            ? this.getTimeFormatChart(parseInt(total))
            : total
        }
        {
          (this.props.data.dataCategories || []).map((category, i) => (
            <div className='chart' 
              key={category.property_name.concat('-', i)}
              style={{
                backgroundColor: category.color,
                height: this.getHeight(data[category.property_name], highValue, category),
                // borderBottomLeftRadius: 5,
                // borderBottomRightRadius: 5,
              }}
            >
              {
                // this.getTimeFormat(parseInt(date))
                is_time
                 ? this.getTimeFormatChart(parseInt(data[category.property_name]))
                 : data[category.property_name]
              }
            </div>
          ))
        }
        {/* <div className='chart' style={{
          backgroundColor: 'rgba(215, 234, 67, 1)',
          height: `${datePercentage}%`,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}>
          {
            this.getTimeFormat(parseInt(date))
          }
        </div>
        <div className='chart' style={{
          backgroundColor: 'rgba(235, 94, 14, 1)', 
          height: `${etaPercentage}%`,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}>
          {
            this.getTimeFormat(parseInt(eta))
          }
        </div> */}

        

      </div>
    );
  }

  buildSecondResume = () => {

    // const { inFacility = {}, attended = {} } = this.props?.data?.generalData || {};
    const tagsToPresent = this.props?.data?.generalData?.tags || [];

    let highValue = 0;

    // const facilityValue = (parseInt(inFacility?.eta || 0) + parseInt(inFacility?.date || 0));
    // const attededValue = (parseInt(attended?.eta || 0) + parseInt(attended?.date || 0));

    highValue = (this.props.data.dataGroups || []).reduce((acum, value) => {
      let currentMax = 0;
      Object.values(value.data || {a: 0}).forEach(d => {currentMax = currentMax + d});
      return acum > currentMax ? acum : currentMax;
    }, 0);

    // if (facilityValue > attededValue) {
    //   highValue = facilityValue;
    // } else {
    //   highValue = attededValue;
    // }

    return(
      <div className='resume' style={{height: this.state.secondResumeOpen ? '290' : '28'}}>

        <div className='resume-title' onClick={() => { this.setState({ secondResumeOpen: !this.state.secondResumeOpen }) }}>
          <div>
            RESUMEN GENERAL
          </div>
          <div className='open-indicator'>
            { this.state.secondResumeOpen ? '-' : '+' }
          </div>
        </div>

        {
          this.state.secondResumeOpen &&
          <div className='general-resume-content'> 

            <div className='units-section'>
              <div className='units-truck'>
                <img src="/images/truck_resume.svg" alt="" className="icono" style={{marginLeft: 20, marginTop: 15}}/>
              </div>
              {
                (this.props.data.dataGroups || []).map(group => (
                  <div className='units-data'>
                    <div className='title'>{group.title}</div>
                    <div className='units'>{group.qty}</div>
                  </div>
                ))
              }
              {/* <div className='units-data'>
                <div className='title'>Atendidos</div>
                <div className='units'>{attended?.units || 0}</div>
              </div>
              <div className='units-data'>
                <div className='title'>En instalación</div>
                <div className='units'>{inFacility?.units || 0}</div>
              </div> */}
            </div>

            <div className='units-charts'>

              <div className='units-truck'>
                <div style={{marginTop: 10}}>TOTAL</div>
                {
                  (this.props.data.dataCategories || []).map((category, i) => (
                    <div key={i} style={{color: category.color}}>{category.title}</div>
                  ))
                }
                {/* <div>ESTADÍA VS CITA</div>
                <div style={{marginBottom: 10}}>PREVIO A CITA</div> */}
              </div>

              {
                (this.props.data.dataGroups || []).map((group, i) => (
                  <div key={i} className='units-data'>
                    { this.buildChart(highValue, group.data, group.is_time) }
                  </div>
                ))
              }

              {/* <div className='units-data'>
                { this.buildChart(highValue, inFacility.eta, inFacility.date) }
              </div>

              <div className='units-data'>
                { this.buildChart(highValue, attended.eta, attended.date) }
              </div> */}

            </div>

            <div className='units-tags'>
              <ExceedPlus
                maxVisibleItems={3}
              >
                {
                  tagsToPresent.map( tag => (tag
                    // <Tag
                    //   key={tag.id}
                    //   id={tag.id}
                    //   title={tag.description}
                    //   color={tag.color}
                    // />
                  ))
                }

              </ExceedPlus>
            </div>

          </div>
        }

      </div>
    );
  }

  render() {
    return(
      <div className='resume-panel'>

        { this.buildFirstResume() }
        <div style={{height: 10}}/>
        { this.buildSecondResume() }

      </div>
    );
  }

}