import {
  BACKEND,
  getApiUrl,
  postApiUrl,
  putApiUrl,
  deleteApiUrl,
  patchApiUrl,
} from "../../shared/backend-api";
import store from "../store";

export const FACILITY_VALIDATE_UNIQUE_FIELD_REQUEST =
  "FACILITY_VALIDATE_UNIQUE_FIELD_REQUEST";
export const FACILITY_VALIDATE_UNIQUE_FIELD_ERROR =
  "FACILITY_VALIDATE_UNIQUE_FIELD_ERROR";
export const FACILITY_VALIDATE_UNIQUE_FIELD_RESPONSE =
  "FACILITY_VALIDATE_UNIQUE_FIELD_RESPONSE";

const ACTIONS = {
  FACILITY: {
    POST: {
      REQUEST: {
        TYPE: "FACILITY_POST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.POST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_POST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.POST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_POST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.POST.ERROR.TYPE,
          error,
        }),
      },
    },
    PUT: {
      REQUEST: {
        TYPE: "FACILITY_PUT_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.PUT.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_PUT_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.PUT.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_PUT_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.PUT.ERROR.TYPE,
          error,
        }),
      },
    },
    DELETE: {
      REQUEST: {
        TYPE: "FACILITY_DELETE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.DELETE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_DELETE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.DELETE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_DELETE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.DELETE.ERROR.TYPE,
          error,
        }),
      },
    },
    PUT_BY_CODE: {
      REQUEST: {
        TYPE: "FACILITY_PUT_BY_CODE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.PUT_BY_CODE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_PUT_BY_CODE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.PUT_BY_CODE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_PUT_BY_CODE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.PUT_BY_CODE.ERROR.TYPE,
          error,
        }),
      },
    },
    GET_BY_CODE: {
      REQUEST: {
        TYPE: "FACILITY_GET_BY_CODE_REQUEST",
        ACTION: (code) => ({
          type: ACTIONS.FACILITY.GET_BY_CODE.REQUEST.TYPE,
          code,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_GET_BY_CODE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.GET_BY_CODE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_GET_BY_CODE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.GET_BY_CODE.ERROR.TYPE,
          error,
        }),
      },
    },
    VALIDATE_UNIQUE_FIELD: {
      REQUEST: {
        TYPE: FACILITY_VALIDATE_UNIQUE_FIELD_REQUEST,
        ACTION: () => ({
          type: ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: FACILITY_VALIDATE_UNIQUE_FIELD_RESPONSE,
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: FACILITY_VALIDATE_UNIQUE_FIELD_ERROR,
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.ERROR.TYPE,
          error,
        }),
      },
    },
    LIST: {
      REQUEST: {
        TYPE: "FACILITY_LIST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.LIST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_LIST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.LIST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_LIST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.LIST.ERROR.TYPE,
          error,
        }),
      },
    },
    CATALOG: {
      REQUEST: {
        TYPE: "FACILITY_CATALOG_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.CATALOG.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_CATALOG_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.CATALOG.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_CATALOG_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.CATALOG.ERROR.TYPE,
          error,
        }),
      },
    },
    CATALOG_SIMPLE: {
      REQUEST: {
        TYPE: "FACILITY_CATALOG_SIMPLE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.FACILITY.CATALOG_SIMPLE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_CATALOG_SIMPLE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.FACILITY.CATALOG_SIMPLE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_CATALOG_SIMPLE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.FACILITY.CATALOG_SIMPLE.ERROR.TYPE,
          error,
        }),
      },
    },
  },
  SPACE: {
    LIST: {
      REQUEST: {
        TYPE: "SPACE_LIST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.LIST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_LIST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.LIST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_LIST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.LIST.ERROR.TYPE,
          error,
        }),
      },
    },
    POST: {
      REQUEST: {
        TYPE: "SPACE_POST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.POST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_POST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.POST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_POST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.POST.ERROR.TYPE,
          error,
        }),
      },
    },
    DELETE: {
      REQUEST: {
        TYPE: "SPACE_DELETE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.DELETE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_DELETE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.DELETE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_DELETE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.DELETE.ERROR.TYPE,
          error,
        }),
      },
    },
    PATCH: {
      REQUEST: {
        TYPE: "SPACE_PATCH_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.PATCH.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_PATCH_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.PATCH.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_PATCH_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.PATCH.ERROR.TYPE,
          error,
        }),
      },
    },
    CALENDAR: {
      REQUEST: {
        TYPE: "SPACE_CALENDAR_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.CALENDAR.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_CALENDAR_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.CALENDAR.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_CALENDAR_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.CALENDAR.ERROR.TYPE,
          error,
        }),
      },
    },
    CAPACITY_SUMMARY: {
      REQUEST: {
        TYPE: "SPACE_CAPACITY_SUMMARY_REQUEST",
        ACTION: () => ({
          type: ACTIONS.SPACE.CAPACITY_SUMMARY.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "SPACE_CAPACITY_SUMMARY_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.SPACE.CAPACITY_SUMMARY.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "SPACE_CAPACITY_SUMMARY_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.SPACE.CAPACITY_SUMMARY.ERROR.TYPE,
          error,
        }),
      },
    },

    TAGS: {
      LIST: {
        REQUEST: {
          TYPE: "SPACE_TAGS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.TAGS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_TAGS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.TAGS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_TAGS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.TAGS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "SPACE_TAGS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.TAGS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_TAGS_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.TAGS.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_TAGS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.TAGS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE: {
        REQUEST: {
          TYPE: "SPACE_TAGS_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.TAGS.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_TAGS_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.TAGS.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_TAGS_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.TAGS.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
    },

    WINDOWS: {
      LIST: {
        REQUEST: {
          TYPE: "SPACE_WINDOWS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.WINDOWS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_WINDOWS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.WINDOWS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_WINDOWS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.WINDOWS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "SPACE_WINDOWS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.WINDOWS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_WINDOWS_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.WINDOWS.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_WINDOWS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.WINDOWS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE: {
        REQUEST: {
          TYPE: "SPACE_WINDOWS_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.WINDOWS.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_WINDOWS_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.WINDOWS.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_WINDOWS_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.WINDOWS.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
      PATCH: {
        REQUEST: {
          TYPE: "SPACE_WINDOWS_PATCH_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.WINDOWS.PATCH.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_WINDOWS_PATCH_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.WINDOWS.PATCH.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_WINDOWS_PATCH_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.WINDOWS.PATCH.ERROR.TYPE,
            error,
          }),
        },
      },
      CAPACITIES: {
        LIST: {
          REQUEST: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
        POST: {
          REQUEST: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_POST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_POST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.ERROR.TYPE,
              error,
            }),
          },
        },
        DELETE: {
          REQUEST: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_DELETE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_DELETE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_DELETE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.ERROR.TYPE,
              error,
            }),
          },
        },
        PATCH: {
          REQUEST: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_PATCH_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_PATCH_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_WINDOWS_CAPACITIES_PATCH_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.ERROR.TYPE,
              error,
            }),
          },
        },
        TRANSPORT: {
          LIST: {
            REQUEST: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_LIST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.REQUEST.TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_LIST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.SUCCESS.TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_LIST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.ERROR.TYPE,
                error,
              }),
            },
          },
          POST: {
            REQUEST: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_POST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.REQUEST.TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_POST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.SUCCESS.TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_POST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.ERROR.TYPE,
                error,
              }),
            },
          },
          DELETE: {
            REQUEST: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_DELETE_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_DELETE_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_WINDOWS_CAPACITIES_TRANSPORT_DELETE_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.ERROR.TYPE,
                error,
              }),
            },
          },
        },
      },
    },

    SCHEDULES: {
      LIST: {
        REQUEST: {
          TYPE: "SPACE_SCHEDULES_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.WINDOWS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_SCHEDULES_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.SCHEDULES.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_SCHEDULES_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.SCHEDULES.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "SPACE_SCHEDULES_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.SCHEDULES.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_SCHEDULES_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.SCHEDULES.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_SCHEDULES_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.SCHEDULES.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE: {
        REQUEST: {
          TYPE: "SPACE_SCHEDULES_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.SCHEDULES.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_SCHEDULES_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.SCHEDULES.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_SCHEDULES_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.SCHEDULES.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
      PATCH: {
        REQUEST: {
          TYPE: "SPACE_SCHEDULES_PATCH_REQUEST",
          ACTION: () => ({
            type: ACTIONS.SPACE.SCHEDULES.PATCH.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "SPACE_SCHEDULES_PATCH_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.SPACE.SCHEDULES.PATCH.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "SPACE_SCHEDULES_PATCH_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.SPACE.SCHEDULES.PATCH.ERROR.TYPE,
            error,
          }),
        },
      },
      CAPACITIES: {
        LIST: {
          REQUEST: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_LIST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_LIST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_LIST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.ERROR.TYPE,
              error,
            }),
          },
        },
        POST: {
          REQUEST: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_POST_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_POST_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_POST_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.ERROR.TYPE,
              error,
            }),
          },
        },
        DELETE: {
          REQUEST: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_DELETE_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_DELETE_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_DELETE_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.ERROR.TYPE,
              error,
            }),
          },
        },
        PATCH: {
          REQUEST: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_PATCH_REQUEST",
            ACTION: () => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.REQUEST.TYPE,
            }),
          },
          SUCCESS: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_PATCH_SUCCESS",
            ACTION: (response) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.SUCCESS.TYPE,
              response,
            }),
          },
          ERROR: {
            TYPE: "SPACE_SCHEDULES_CAPACITIES_PATCH_ERROR",
            ACTION: (error) => ({
              type: ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.ERROR.TYPE,
              error,
            }),
          },
        },
        TRANSPORT: {
          LIST: {
            REQUEST: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_LIST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_LIST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_LIST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.ERROR.TYPE,
                error,
              }),
            },
          },
          POST: {
            REQUEST: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_POST_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_POST_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_POST_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.ERROR.TYPE,
                error,
              }),
            },
          },
          DELETE: {
            REQUEST: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_DELETE_REQUEST",
              ACTION: () => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.REQUEST
                    .TYPE,
              }),
            },
            SUCCESS: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_DELETE_SUCCESS",
              ACTION: (response) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.SUCCESS
                    .TYPE,
                response,
              }),
            },
            ERROR: {
              TYPE: "SPACE_SCHEDULES_CAPACITIES_TRANSPORT_DELETE_ERROR",
              ACTION: (error) => ({
                type:
                  ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.ERROR
                    .TYPE,
                error,
              }),
            },
          },
        },
      },
    },
  },
  ALERT: {
    GET: {
      REQUEST: {
        TYPE: "ALERT_GET_REQUEST",
        ACTION: () => ({
          type: ACTIONS.ALERT.GET.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ALERT_GET_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.ALERT.GET.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ALERT_GET_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.ALERT.GET.ERROR.TYPE,
          error,
        }),
      },
    },
    LIST: {
      REQUEST: {
        TYPE: "ALERT_LIST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.ALERT.LIST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ALERT_LIST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.ALERT.LIST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ALERT_LIST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.ALERT.LIST.ERROR.TYPE,
          error,
        }),
      },
    },
    POST: {
      REQUEST: {
        TYPE: "ALERT_POST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.ALERT.POST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ALERT_POST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.ALERT.POST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ALERT_POST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.ALERT.POST.ERROR.TYPE,
          error,
        }),
      },
    },
    PUT: {
      REQUEST: {
        TYPE: "ALERT_PUT_REQUEST",
        ACTION: () => ({
          type: ACTIONS.ALERT.PUT.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ALERT_PUT_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.ALERT.PUT.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ALERT_PUT_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.ALERT.PUT.ERROR.TYPE,
          error,
        }),
      },
    },
    DELETE: {
      REQUEST: {
        TYPE: "ALERT_DELETE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.ALERT.DELETE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "ALERT_DELETE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.ALERT.DELETE.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "ALERT_DELETE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.ALERT.DELETE.ERROR.TYPE,
          error,
        }),
      },
    },
    EMAILS: {
      LIST: {
        REQUEST: {
          TYPE: "ALERT_EMAILS_LIST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.ALERT.EMAILS.LIST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ALERT_EMAILS_LIST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.ALERT.EMAILS.LIST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ALERT_EMAILS_LIST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.ALERT.EMAILS.LIST.ERROR.TYPE,
            error,
          }),
        },
      },
      POST: {
        REQUEST: {
          TYPE: "ALERT_EMAILS_POST_REQUEST",
          ACTION: () => ({
            type: ACTIONS.ALERT.EMAILS.POST.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ALERT_EMAILS_POST_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.ALERT.EMAILS.POST.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ALERT_EMAILS_POST_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.ALERT.EMAILS.POST.ERROR.TYPE,
            error,
          }),
        },
      },
      DELETE: {
        REQUEST: {
          TYPE: "ALERT_EMAILS_DELETE_REQUEST",
          ACTION: () => ({
            type: ACTIONS.ALERT.EMAILS.DELETE.REQUEST.TYPE,
          }),
        },
        SUCCESS: {
          TYPE: "ALERT_EMAILS_DELETE_SUCCESS",
          ACTION: (response) => ({
            type: ACTIONS.ALERT.EMAILS.DELETE.SUCCESS.TYPE,
            response,
          }),
        },
        ERROR: {
          TYPE: "ALERT_EMAILS_DELETE_ERROR",
          ACTION: (error) => ({
            type: ACTIONS.ALERT.EMAILS.DELETE.ERROR.TYPE,
            error,
          }),
        },
      },
    },
  },
  AVATAR: {
    GET: {
      REQUEST: {
        TYPE: "FACILITY_AVATAR_GET_REQUEST",
        ACTION: () => ({
          type: ACTIONS.AVATAR.GET.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_AVATAR_GET_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.AVATAR.GET.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_AVATAR_GET_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.AVATAR.GET.ERROR.TYPE,
          error,
        }),
      },
    },
  },
  GI: {
    POST: {
      REQUEST: {
        TYPE: "FACILITY_GI_POST_REQUEST",
        ACTION: () => ({
          type: ACTIONS.GI.POST.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_GI_POST_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.GI.POST.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_GI_POST_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.GI.POST.ERROR.TYPE,
          error,
        }),
      },
    },
    GET_BY_FACILITY_ID: {
      REQUEST: {
        TYPE: "FACILITY_GI_GET_BY_FACILITY_ID_REQUEST",
        ACTION: () => ({
          type: ACTIONS.GI.GET_BY_FACILITY_ID.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_GI_GET_BY_FACILITY_ID_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.GI.GET_BY_FACILITY_ID.SUCCESS.TYPE,
          response,
        }),
      },
      ERROR: {
        TYPE: "FACILITY_GI_GET_BY_FACILITY_ID_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.GI.GET_BY_FACILITY_ID.ERROR.TYPE,
          error,
        }),
      },
    },
    DELETE: {
      REQUEST: {
        TYPE: "FACILITY_GI_DELETE_REQUEST",
        ACTION: () => ({
          type: ACTIONS.GI.DELETE.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_GI_DELETE_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.GI.DELETE.SUCCESS.TYPE,
          // response
        }),
      },
      ERROR: {
        TYPE: "FACILITY_GI_DELETE_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.GI.DELETE.ERROR.TYPE,
          error,
        }),
      },
    },
    PUT: {
      REQUEST: {
        TYPE: "FACILITY_GI_PUT_REQUEST",
        ACTION: () => ({
          type: ACTIONS.GI.PUT.REQUEST.TYPE,
        }),
      },
      SUCCESS: {
        TYPE: "FACILITY_GI_PUT_SUCCESS",
        ACTION: (response) => ({
          type: ACTIONS.GI.PUT.SUCCESS.TYPE,
          // response
        }),
      },
      ERROR: {
        TYPE: "FACILITY_GI_PUT_ERROR",
        ACTION: (error) => ({
          type: ACTIONS.GI.PUT.ERROR.TYPE,
          error,
        }),
      },
    },
  },
};

const initialState = {
  facility: {
    isFetching: false,
    error: "",
    data: {},
  },
  facilities: {
    isFetching: false,
    error: "",
    data: [],
  },
  unique_field_validation: {
    isFetching: false,
    error: "",
    response: "",
  },
  avatar: {
    isFetching: false,
    error: "",
    items: [],
  },
  gi: {
    isFetching: false,
    error: "",
    items: [],
  },
  catalog: {
    isFetching: false,
    error: "",
    data: {},
  },
  facilityAlerts: {
    isFetching: false,
    error: "",
    data: [],
  },
  facilityAlertEmails: {
    isFetching: false,
    error: "",
    data: {},
  },
  spaces: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceTags: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceWindows: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceWindowCapacities: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceWindowCapacityTransport: {
    isFetching: false,
    error: "",
    data: [],
  },

  spaceCalendar: {
    isFetching: false,
    error: "",
    data: [],
  },

  spaceSchedules: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceScheduleCapacities: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceScheduleCapacityTransport: {
    isFetching: false,
    error: "",
    data: [],
  },
  spaceCapacitySummary: {
    isFetching: false,
    error: "",
    data: [],
  },
  alertConfigs: [],
};

export default function facilityReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FACILITY.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilities: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.FACILITY.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.DELETE.REQUEST.TYPE:
      return state;
    case ACTIONS.FACILITY.DELETE.SUCCESS.TYPE:
      return state;
    case ACTIONS.FACILITY.DELETE.ERROR.TYPE:
      return state;
    case ACTIONS.FACILITY.PUT_BY_CODE.REQUEST.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.PUT_BY_CODE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.PUT_BY_CODE.ERROR.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.GET_BY_CODE.REQUEST.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.GET_BY_CODE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.GET_BY_CODE.ERROR.TYPE:
      return Object.assign({}, state, {
        facility: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.REQUEST.TYPE:
      return Object.assign({}, state, {
        unique_field_validation: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.SUCCESS.TYPE:
      return Object.assign({}, state, {
        unique_field_validation: {
          isFetching: false,
          response: action.response,
        },
      });
    case ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.ERROR.TYPE:
      return Object.assign({}, state, {
        unique_field_validation: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.CATALOG.REQUEST.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.CATALOG.SUCCESS.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.CATALOG.ERROR.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.FACILITY.CATALOG_SIMPLE.REQUEST.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: true,
        },
      });
    case ACTIONS.FACILITY.CATALOG_SIMPLE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.FACILITY.CATALOG_SIMPLE.ERROR.TYPE:
      return Object.assign({}, state, {
        catalog: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.AVATAR.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        avatar: {
          isFetching: true,
        },
      });
    case ACTIONS.AVATAR.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        avatar: {
          isFetching: false,
          items: action.response,
        },
      });
    case ACTIONS.AVATAR.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        avatar: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.GI.GET_BY_FACILITY_ID.REQUEST.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: true,
        },
      });
    case ACTIONS.GI.GET_BY_FACILITY_ID.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          items: action.response,
        },
      });
    case ACTIONS.GI.GET_BY_FACILITY_ID.ERROR.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.GI.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: true,
        },
      });
    case ACTIONS.GI.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          // items: action.response
        },
      });
    case ACTIONS.GI.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.GI.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: true,
        },
      });
    case ACTIONS.GI.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          // items: action.response
        },
      });
    case ACTIONS.GI.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.GI.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: true,
        },
      });
    case ACTIONS.GI.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          // items: action.response
        },
      });
    case ACTIONS.GI.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        gi: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.GET.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.GET.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.GET.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.PUT.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.PUT.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.PUT.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.ALERT.EMAILS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.EMAILS.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.EMAILS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.EMAILS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.EMAILS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.EMAILS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.ALERT.EMAILS.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: true,
        },
      });
    case ACTIONS.ALERT.EMAILS.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.ALERT.EMAILS.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        facilityAlerts: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.LIST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.PATCH.REQUEST.TYPE:
      // console.log('reducer', state.spaces.data);
      return Object.assign({}, state, {
        spaces: {
          isFetching: true,
          data: state.spaces.data,
        },
      });
    case ACTIONS.SPACE.PATCH.SUCCESS.TYPE:
      // let spaces = state.spaces.data.filter(sp => sp.id !== action.response.id).concat(action.response);
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          // data: spaces
        },
      });
    case ACTIONS.SPACE.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        spaces: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.TAGS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.TAGS.LIST.SUCCESS.TYPE:
      if (action.response.length > 0) {
        state.spaces.data.find((s) => action.response[0].spaces === s.id).tags =
          action.response;
      }
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.TAGS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.TAGS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.TAGS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.TAGS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.TAGS.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.TAGS.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.TAGS.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceTags: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.WINDOWS.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.LIST.SUCCESS.TYPE:
      if (action.response.length > 0)
        state.spaces.data.find(
          (sp) => sp.id === action.response[0].spaces
        ).windows = action.response;
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.WINDOWS.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.WINDOWS.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.WINDOWS.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.PATCH.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.PATCH.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.WINDOWS.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindows: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.SUCCESS.TYPE:
      // if (action.response.length > 0)
      // state.spaces.data.find(sp => sp.id === action.response[0].spaces).windows = action.response;
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.CALENDAR.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceCalendar: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.CALENDAR.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceCalendar: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.CALENDAR.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceCalendar: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.SCHEDULES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.LIST.SUCCESS.TYPE:
      if (action.response.length > 0)
        state.spaces.data.find(
          (sp) => sp.id === action.response[0].spaces
        ).windows = action.response;
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.PATCH.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.PATCH.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceSchedules: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.SUCCESS.TYPE:
      // if (action.response.length > 0)
      // state.spaces.data.find(sp => sp.id === action.response[0].spaces).windows = action.response;
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacities: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.SUCCESS.TYPE:
      // if (action.response.length > 0)
      // state.spaces.data.find(sp => sp.id === action.response[0].spaces).windows = action.response;
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          data: [],
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          data: [],
        },
      });
    case ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceWindowCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });

    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.SUCCESS.TYPE:
      // if (action.response.length > 0)
      // state.spaces.data.find(sp => sp.id === action.response[0].spaces).windows = action.response;
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          data: [],
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: true,
          data: [],
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          data: [],
        },
      });
    case ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceScheduleCapacityTransport: {
          isFetching: false,
          error: action.error,
        },
      });
    case ACTIONS.SPACE.CAPACITY_SUMMARY.REQUEST.TYPE:
      return Object.assign({}, state, {
        spaceCapacitySummary: {
          isFetching: true,
        },
      });
    case ACTIONS.SPACE.CAPACITY_SUMMARY.SUCCESS.TYPE:
      return Object.assign({}, state, {
        spaceCapacitySummary: {
          isFetching: false,
          data: action.response,
        },
      });
    case ACTIONS.SPACE.CAPACITY_SUMMARY.ERROR.TYPE:
      return Object.assign({}, state, {
        spaceCapacitySummary: {
          isFetching: false,
          error: action.error,
        },
      });
    case "FACILITY_ALERT_CONFIG":
      let { alertConfigs } = state;

      return Object.assign({}, state, {
        alertConfigs: alertConfigs
          .filter((alc) => alc.id !== action.alertConfig.id)
          .concat([action.alertConfig]),
      });
    default:
      return state;
  }
}

export const facilityAlertConfigActions = (alertConfig) => ({
  type: "FACILITY_ALERT_CONFIG",
  alertConfig,
});

export const loadFacilityByCode = (code) => {
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.GET_BY_CODE.REQUEST.ACTION(code));
    return getApiUrl(BACKEND.FACILITY.GET_BY_CODE, { code })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FACILITY.GET_BY_CODE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FACILITY.GET_BY_CODE.SUCCESS.ACTION(response))
      );
  };
};

export const loadFacility = (facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.GET_BY_CODE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.GET, { facility_id, company_id })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FACILITY.GET_BY_CODE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FACILITY.GET_BY_CODE.SUCCESS.ACTION(response))
      );
  };
};

export const listFacilities = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.LIST, { company_id })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FACILITY.LIST.SUCCESS.ACTION(response))
      )
      .catch(
        (error) =>
          dispatch(
            ACTIONS.FACILITY.LIST.ERROR.ACTION(error)
          ) /* error.then(e => dispatch(ACTIONS.FACILITY.LIST.ERROR.ACTION(e))) */
      );
  };
};

export const validateUniqueField = (field, value) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.VALIDATE.UNIQUE, {
      company_id,
      field,
      value,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.FACILITY.VALIDATE_UNIQUE_FIELD.ERROR.ACTION(error))
      );
  };
};

export const createFacility = (facility) => {
  facility.company = store.getState().globalCatalog.session.company.id;
  facility.company_id = facility.company;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.POST.REQUEST.ACTION(facility));
    return postApiUrl(BACKEND.FACILITY.POST, facility)
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FACILITY.POST.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.FACILITY.POST.ERROR.ACTION(error)));
  };
};

export const deleteFacility = (facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.DELETE, { company_id, facility_id })
      .then(
        (response) => (response ? { msg: "ok" } : response.json()),
        (error) => dispatch(ACTIONS.FACILITY.DELETE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FACILITY.DELETE.SUCCESS.ACTION(response))
      );
  };
};

export const updateFacilityByCode = (facility) => {
  facility.company = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.PUT_BY_CODE.REQUEST.ACTION(facility));
    return putApiUrl(BACKEND.FACILITY.PUT_BY_CODE, facility)
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.FACILITY.PUT_BY_CODE.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.FACILITY.PUT_BY_CODE.SUCCESS.ACTION(response))
      );
  };
};

export const updateFacility = (facility) => {
  facility.company = store.getState().globalCatalog.session.company.id;
  facility.company_id = facility.company;
  facility.facility_id = facility.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.PUT_BY_CODE.REQUEST.ACTION(facility));
    return putApiUrl(BACKEND.FACILITY.PUT, facility)
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FACILITY.PUT_BY_CODE.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FACILITY.PUT_BY_CODE.ERROR.ACTION(error))
      );
  };
};

export const loadAvatares = () => {
  return function (dispatch) {
    let storeItems = store.getState().facilityReducer.avatar;
    if (!storeItems.isFetching && storeItems.items.length) {
      return new Promise((resolve) => resolve(storeItems));
    } else {
      dispatch(ACTIONS.AVATAR.GET.REQUEST.ACTION());
      return getApiUrl(BACKEND.PUBLIC.AVATAR)
        .then(
          (response) => response.json(),
          (error) => dispatch(ACTIONS.AVATAR.GET.ERROR.ACTION(error))
        )
        .then((data) => dispatch(ACTIONS.AVATAR.GET.SUCCESS.ACTION(data)));
    }
  };
};

export const loadGIByFacilityId = (facility_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.GI.GET_BY_FACILITY_ID.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.GEO.GET_BY_FACILITY_ID, { facility_id })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.GI.GET_BY_FACILITY_ID.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.GI.GET_BY_FACILITY_ID.SUCCESS.ACTION(response))
      );
  };
};

export const createGI = (description, facility, gi_type, polygonPathArray) => {
  polygonPathArray.push(polygonPathArray[0]);
  let geo_data = polygonPathArray
    .reduce(
      (result, item, index) =>
        result.concat(index ? "," : "", item.lat(), " ", item.lng()),
      "POLYGON (("
    )
    .concat("))");
  return function (dispatch) {
    dispatch(ACTIONS.GI.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.GEO.POST, {
      description,
      facility,
      gi_type,
      geo_data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.GI.POST.ERROR.ACTION(error))
      )
      .then((response) => dispatch(ACTIONS.GI.POST.SUCCESS.ACTION(response)));
  };
};

export const deleteGI = (facility_gi_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.GI.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.GEO.DELETE, { facility_gi_id }).then(
      (response) => dispatch(ACTIONS.GI.DELETE.SUCCESS.ACTION(response)),
      (error) => dispatch(ACTIONS.GI.DELETE.ERROR.ACTION(error))
    );
  };
};

export const updateGI = (
  facility_gi_id,
  description,
  polygonPathArray,
  facility,
  gi_type
) => {
  polygonPathArray.push(polygonPathArray[0]);
  let geo_data = polygonPathArray
    .reduce(
      (result, item, index) =>
        result.concat(index ? "," : "", item.lat(), " ", item.lng()),
      "POLYGON (("
    )
    .concat("))");
  return function (dispatch) {
    dispatch(ACTIONS.GI.PUT.REQUEST.ACTION());
    return putApiUrl(BACKEND.FACILITY.GEO.PUT, {
      facility_gi_id,
      description,
      geo_data,
      facility,
      gi_type,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.GI.PUT.ERROR.ACTION(error))
      )
      .then((response) => dispatch(ACTIONS.GI.PUT.SUCCESS.ACTION(response)));
  };
};

export const createRamp = (facility_gi_id, type) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.RAMP.POST, { facility_gi_id, type })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.RAMP.POST.ERROR.ACTION(error))
      )
      .then((response) => dispatch(ACTIONS.RAMP.POST.SUCCESS.ACTION(response)));
  };
};

export const loadRampByGIId = (facility_gi_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.GET_BY_GI_ID.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.RAMP.GET_BY_GEO_ID, { facility_gi_id })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.RAMP.GET_BY_GI_ID.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.RAMP.GET_BY_GI_ID.SUCCESS.ACTION(response))
      );
  };
};

export const updateRamp = (facility_gi_id, ramp_id, type) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.PUT.REQUEST.ACTION());
    return putApiUrl(BACKEND.FACILITY.RAMP.PUT, {
      facility_gi_id,
      ramp_id,
      type,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.RAMP.PUT.ERROR.ACTION(error))
      )
      .then((response) => dispatch(ACTIONS.RAMP.PUT.SUCCESS.ACTION(response)));
  };
};

export const createWindowCard = (facility_gi_id, ramp_id, windowCard) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.WINDOW.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FACILITY.RAMP.POST_WINDOW,
      Object.assign(facility_gi_id, ramp_id, windowCard)
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.RAMP.WINDOW.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.RAMP.WINDOW.POST.SUCCESS.ACTION(response))
      );
  };
};

export const deleteWindowCard = (facility_gi_id, ramp_id, window_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.WINDOW.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.RAMP.DELETE_WINDOW, {
      facility_gi_id,
      ramp_id,
      window_id,
    }).then(
      (response) =>
        dispatch(ACTIONS.RAMP.WINDOW.DELETE.SUCCESS.ACTION(response)),
      (error) => dispatch(ACTIONS.RAMP.WINDOW.DELETE.ERROR.ACTION(error))
    );
  };
};

export const updateWindow = (
  facility_gi_id,
  ramp_id,
  window_id,
  windowCard
) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.WINDOW.PUT.REQUEST.ACTION());
    return putApiUrl(
      BACKEND.FACILITY.RAMP.PUT_WINDOW,
      Object.assign(facility_gi_id, ramp_id, window_id, windowCard)
    )
      .then(
        (response) => response.json(),
        (error) => dispatch(ACTIONS.RAMP.WINDOW.PUT.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.RAMP.WINDOW.PUT.SUCCESS.ACTION(response))
      );
  };
};

export const createWindowCapacity = (
  facility_gi_id,
  ramp_id,
  window_id,
  windowCard
) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FACILITY.RAMP.POST_WINDOW_CAPACITY,
      Object.assign(facility_gi_id, ramp_id, window_id, windowCard)
    )
      .then(
        (response) => response.json(),
        (error) =>
          dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.POST.ERROR.ACTION(error))
      )
      .then((response) =>
        dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.POST.SUCCESS.ACTION(response))
      );
  };
};

export const deleteWindowCapacity = (facility_gi_id, ramp_id, window_id) => {
  return function (dispatch) {
    dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.RAMP.DELETE_WINDOW_CAPACITY, {
      facility_gi_id,
      ramp_id,
      window_id,
    }).then(
      (response) =>
        dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.DELETE.SUCCESS.ACTION(response)),
      (error) =>
        dispatch(ACTIONS.RAMP.WINDOW_CAPACITY.DELETE.ERROR.ACTION(error))
    );
  };
};

export const getFacilityCatalog = (
  limit,
  offset,
  tags = [],
  locations = [],
  capacity = [],
  status = [],
  text = ""
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.CATALOG.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.CATALOG, {
      company_id,
      limit,
      offset,
      tags,
      locations,
      capacity,
      status,
      text,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FACILITY.CATALOG.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.FACILITY.CATALOG.ERROR.ACTION(error)));
  };
};

export const getFacilityCatalogSimple = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.FACILITY.CATALOG_SIMPLE.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.CATALOG_SIMPLE, {
      company_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.FACILITY.CATALOG_SIMPLE.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.FACILITY.CATALOG_SIMPLE.ERROR.ACTION(error))
      );
  };
};

// export const listFacilityAlerts = facility_id => {
//   let company_id = store.getState().globalCatalog.session.company.id;
//   return function(dispatch) {
//     dispatch(ACTIONS.ALERT.LIST.REQUEST.ACTION());
//     return getApiUrl(BACKEND.FACILITY.ALERTS.LIST, { company_id, facility_id })
//       .then(response => response.json())
//       .then(response => dispatch(ACTIONS.ALERT.LIST.SUCCESS.ACTION(response)))
//       .catch(error => dispatch(ACTIONS.ALERT.LIST.ERROR.ACTION(error)));
//   };
// };

export const listSpaces = (facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.LIST.REQUEST.ACTION());
    return (
      getApiUrl(BACKEND.FACILITY.SPACES.LIST, { company_id, facility_id })
        .then((response) => response.json())
        .then((response) =>
          dispatch(ACTIONS.SPACE.LIST.SUCCESS.ACTION(response))
        )
        // .then(r => r.response.forEach(k => console.log(k)))
        .catch((error) => dispatch(ACTIONS.SPACE.LIST.ERROR.ACTION(error)))
    );
  };
};
export const createSpace = (facility_id, name, load, unload, loadunload) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.POST, {
      company_id,
      facility_id,
      name,
      load,
      unload,
      loadunload,
    })
      .then((response) => response.json())
      .then((response) => dispatch(ACTIONS.SPACE.POST.SUCCESS.ACTION(response)))
      .catch((error) => dispatch(ACTIONS.SPACE.POST.ERROR.ACTION(error)));
  };
};
export const deleteSpace = (facility_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.DELETE, {
      company_id,
      facility_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.DELETE.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.SPACE.DELETE.ERROR.ACTION(error)));
  };
};
export const updateSpace = (facility_id, id, load, unload, loadunload) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.FACILITY.SPACES.PATCH, {
      company_id,
      facility_id,
      id,
      load,
      unload,
      loadunload,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.SPACE.PATCH.ERROR.ACTION(error)));
  };
};

export const loadSpaceTags = (facility_id, space_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.TAGS.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.SPACES.TAGS.LIST, {
      company_id,
      facility_id,
      space_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.TAGS.LIST.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.SPACE.TAGS.LIST.ERROR.ACTION(error)));
  };
};
export const createSpaceTag = (facility_id, space_id, tags) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.TAGS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.TAGS.POST, {
      company_id,
      facility_id,
      space_id,
      tags,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.TAGS.POST.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.SPACE.TAGS.POST.ERROR.ACTION(error)));
  };
};
export const deleteSpaceTag = (facility_id, space_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.TAGS.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.TAGS.DELETE, {
      company_id,
      facility_id,
      space_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.TAGS.DELETE.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.TAGS.DELETE.ERROR.ACTION(error))
      );
  };
};

export const listSpaceWindows = (facility_id, space_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.LIST.REQUEST.ACTION());
    return (
      getApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.LIST, {
        company_id,
        facility_id,
        space_id,
      })
        .then((response) => response.json())
        .then((response) =>
          dispatch(ACTIONS.SPACE.WINDOWS.LIST.SUCCESS.ACTION(response))
        )
        // .then(r => r.response.forEach(k => console.log(k)))
        .catch((error) =>
          dispatch(ACTIONS.SPACE.WINDOWS.LIST.ERROR.ACTION(error))
        )
    );
  };
};
export const createSpaceWindow = (
  facility_id,
  space_id,
  hour_from,
  minute_from,
  hour_to,
  minute_to,
  weekdays
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.POST, {
      company_id,
      facility_id,
      space_id,
      hour_from,
      minute_from,
      hour_to,
      minute_to,
      weekdays,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.WINDOWS.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.POST.ERROR.ACTION(error))
      );
  };
};
export const deleteSpaceWindow = (facility_id, space_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.DELETE, {
      company_id,
      facility_id,
      space_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.WINDOWS.DELETE.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.DELETE.ERROR.ACTION(error))
      );
  };
};
export const updateSpaceWindow = (
  facility_id,
  space_id,
  id,
  hour_from,
  minute_from,
  hour_to,
  minute_to,
  weekdays
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.PATCH, {
      company_id,
      facility_id,
      space_id,
      id,
      hour_from,
      minute_from,
      hour_to,
      minute_to,
      weekdays,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.WINDOWS.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.PATCH.ERROR.ACTION(error))
      );
  };
};

export const listSpaceWindowCapacities = (facility_id, space_id, window_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.REQUEST.ACTION());
    return (
      getApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.LIST, {
        company_id,
        facility_id,
        space_id,
        window_id,
      })
        .then((response) => response.json())
        .then((response) =>
          dispatch(
            ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.SUCCESS.ACTION(response)
          )
        )
        // .then(r => r.response.forEach(k => console.log(k)))
        .catch((error) =>
          dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.LIST.ERROR.ACTION(error))
        )
    );
  };
};
export const createSpaceWindowCapacity = (
  facility_id,
  space_id,
  window_id,
  single_load,
  single_unload,
  doble_load,
  doble_unload,
  single_loadunload,
  doble_loadunload,
  dobleequipment
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.POST, {
      company_id,
      facility_id,
      space_id,
      window_id,
      single_load,
      single_unload,
      doble_load,
      doble_unload,
      single_loadunload,
      doble_loadunload,
      dobleequipment,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.POST.ERROR.ACTION(error))
      );
  };
};
export const deleteSpaceWindowCapacity = (
  facility_id,
  space_id,
  window_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.DELETE, {
      company_id,
      facility_id,
      space_id,
      window_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.DELETE.ERROR.ACTION(error))
      );
  };
};
export const updateSpaceWindowCapacity = (
  facility_id,
  space_id,
  window_id,
  id,
  single_load,
  single_unload,
  doble_load,
  doble_unload,
  single_loadunload,
  doble_loadunload,
  dobleequipment
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.PATCH, {
      company_id,
      facility_id,
      space_id,
      window_id,
      id,
      single_load,
      single_unload,
      doble_load,
      doble_unload,
      single_loadunload,
      doble_loadunload,
      dobleequipment,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.PATCH.ERROR.ACTION(error))
      );
  };
};

export const listSpaceCalendar = (facility_id, date) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.CALENDAR.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.SPACES.CALENDAR, {
      company_id,
      facility_id,
      date,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.CALENDAR.SUCCESS.ACTION(response))
      )
      .catch((error) => dispatch(ACTIONS.SPACE.CALENDAR.ERROR.ACTION(error)));
  };
};

export const listSpaceSchedules = (facility_id, space_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.LIST, {
      company_id,
      facility_id,
      space_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.LIST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.LIST.ERROR.ACTION(error))
      );
  };
};

export const createSpaceSchedule = (
  facility_id,
  space_id,
  hour_from,
  minute_from,
  hour_to,
  minute_to,
  calendar_date,
  is_open
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let time_from = "".concat(
    hour_from,
    ":",
    (minute_from + 100).toString().substring(1, 3)
  );
  let time_to = "".concat(
    hour_to,
    ":",
    (minute_to + 100).toString().substring(1, 3)
  );
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.POST, {
      company_id,
      facility_id,
      space_id,
      facilityspace: space_id,
      time_from,
      time_to,
      calendar_date,
      is_open,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.POST.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.POST.ERROR.ACTION(error))
      );
  };
};
export const updateSpaceSchedule = (
  facility_id,
  space_id,
  id,
  hour_from,
  minute_from,
  hour_to,
  minute_to,
  calendar_date
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  let time_from = "".concat(
    hour_from,
    ":",
    (minute_from + 100).toString().substring(1, 3)
  );
  let time_to = "".concat(
    hour_to,
    ":",
    (minute_to + 100).toString().substring(1, 3)
  );
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.PATCH, {
      company_id,
      facility_id,
      space_id,
      facilityspace: space_id,
      id,
      time_from,
      time_to,
      calendar_date,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.PATCH.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.PATCH.ERROR.ACTION(error))
      );
  };
};
export const deleteSpaceSchedule = (facility_id, space_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.DELETE, {
      company_id,
      facility_id,
      space_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.DELETE.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.DELETE.ERROR.ACTION(error))
      );
  };
};

export const listSpaceScheduleCapacities = (
  facility_id,
  space_id,
  schedule_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.LIST, {
      company_id,
      facility_id,
      space_id,
      schedule_id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.LIST.ERROR.ACTION(error))
      );
  };
};
export const createSpaceScheduleCapacity = (
  facility_id,
  space_id,
  schedule_id,
  single_load,
  single_unload,
  doble_load,
  doble_unload,
  single_loadunload,
  doble_loadunload,
  dobleequipment
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.POST, {
      company_id,
      facility_id,
      space_id,
      schedule_id,
      windows: schedule_id,
      single_load,
      single_unload,
      doble_load,
      doble_unload,
      single_loadunload,
      doble_loadunload,
      dobleequipment,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.POST.ERROR.ACTION(error))
      );
  };
};
export const updateSpaceScheduleCapacity = (
  facility_id,
  space_id,
  schedule_id,
  id,
  single_load,
  single_unload,
  doble_load,
  doble_unload,
  single_loadunload,
  doble_loadunload,
  dobleequipment
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.REQUEST.ACTION());
    return patchApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.PATCH, {
      company_id,
      facility_id,
      space_id,
      schedule_id,
      id,
      single_load,
      single_unload,
      doble_load,
      doble_unload,
      single_loadunload,
      doble_loadunload,
      dobleequipment,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.PATCH.ERROR.ACTION(error))
      );
  };
};
export const deleteSpaceScheduleCapacity = (
  facility_id,
  space_id,
  schedule_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.DELETE, {
      company_id,
      facility_id,
      space_id,
      schedule_id,
      id,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.SUCCESS.ACTION(response)
        )
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.SCHEDULES.CAPACITIES.DELETE.ERROR.ACTION(error))
      );
  };
};

export const listSpaceWindowCapacityTransport = (
  facility_id,
  space_id,
  window_id,
  capacity_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.REQUEST.ACTION());
    return getApiUrl(
      BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.TRANSPORT.LIST,
      { company_id, facility_id, space_id, window_id, capacity_id }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.LIST.ERROR.ACTION(error)
        )
      );
  };
};
export const createSpaceWindowCapacityTransport = (
  facility_id,
  space_id,
  window_id,
  capacity_id,
  transport_class
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.REQUEST.ACTION());
    return postApiUrl(
      BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.TRANSPORT.POST,
      {
        company_id,
        facility_id,
        space_id,
        window_id,
        capacity_id,
        transport_class,
      }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.POST.ERROR.ACTION(error)
        )
      );
  };
};
export const deleteSpaceWindowCapacityTransport = (
  facility_id,
  space_id,
  window_id,
  capacity_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.REQUEST.ACTION()
    );
    return deleteApiUrl(
      BACKEND.FACILITY.SPACES.WINDOWS.CAPACITIES.TRANSPORT.DELETE,
      { company_id, facility_id, space_id, window_id, capacity_id, id }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.WINDOWS.CAPACITIES.TRANSPORT.DELETE.ERROR.ACTION(error)
        )
      );
  };
};

export const listSpaceScheduleCapacityTransport = (
  facility_id,
  space_id,
  schedule_id,
  capacity_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.REQUEST.ACTION()
    );
    return getApiUrl(
      BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.TRANSPORT.LIST,
      { company_id, facility_id, space_id, schedule_id, capacity_id }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.LIST.ERROR.ACTION(error)
        )
      );
  };
};
export const createSpaceScheduleCapacityTransport = (
  facility_id,
  space_id,
  schedule_id,
  capacity_id,
  transport_class
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.REQUEST.ACTION()
    );
    return postApiUrl(
      BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.TRANSPORT.POST,
      {
        company_id,
        facility_id,
        space_id,
        schedule_id,
        capacity_id,
        transport_class,
      }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.POST.ERROR.ACTION(error)
        )
      );
  };
};
export const deleteSpaceScheduleCapacityTransport = (
  facility_id,
  space_id,
  schedule_id,
  capacity_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(
      ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.REQUEST.ACTION()
    );
    return deleteApiUrl(
      BACKEND.FACILITY.SPACES.SCHEDULES.CAPACITIES.TRANSPORT.DELETE,
      { company_id, facility_id, space_id, schedule_id, capacity_id, id }
    )
      .then((response) => response.json())
      .then((response) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.SUCCESS.ACTION(
            response
          )
        )
      )
      .catch((error) =>
        dispatch(
          ACTIONS.SPACE.SCHEDULES.CAPACITIES.TRANSPORT.DELETE.ERROR.ACTION(
            error
          )
        )
      );
  };
};

export const listSpaceCapacitySummary = (facility_id, date) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function (dispatch) {
    dispatch(ACTIONS.SPACE.CAPACITY_SUMMARY.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.SPACES.CAPACITY_SUMMARY, {
      company_id,
      facility_id,
      date,
    })
      .then((response) => response.json())
      .then((response) =>
        dispatch(ACTIONS.SPACE.CAPACITY_SUMMARY.SUCCESS.ACTION(response))
      )
      .catch((error) =>
        dispatch(ACTIONS.SPACE.CAPACITY_SUMMARY.ERROR.ACTION(error))
      );
  };
};
