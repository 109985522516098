import React from "react";
const ZoneIcon = (props) => {
  return (
    <svg
      width="39px"
      height="41px"
      viewBox="0 0 39 41"
      fill="#000000"
      {...props}
    >
      <path
        d="M9.04718039,23.5238095 L8.64021574,23.0041714 C8.32429997,22.6012694 0.904761905,13.0860539 0.904761905,8.53297139 C0.904761905,3.82703724 4.55768364,0 9.04707073,0 C13.5375545,0 17.1904762,3.8281865 17.1904762,8.53297139 C17.1904762,13.0863412 9.77113004,22.6013556 9.45392571,23.0041714 L9.04718039,23.5238095 Z M9.04761905,1.13824885 C5.15588658,1.13824885 1.99047619,4.44204283 1.99047619,8.50327674 C1.99047619,12.0640135 7.49098065,19.5726103 9.04761905,21.6267281 C10.6042847,19.5726388 16.1047619,12.0617346 16.1047619,8.50327674 C16.1047619,4.44175798 12.9390786,1.13824885 9.04761905,1.13824885 Z"
        id="Fill-1-Copy"
        strokeWidth="0.5"
      ></path>
      <path
        d="M9.04761905,5.42857143 C7.5509617,5.42857143 6.33333333,6.64624144 6.33333333,8.14285714 C6.33333333,9.63947285 7.55100334,10.8571429 9.04761905,10.8571429 C10.5442348,10.8571429 11.7619048,9.63947285 11.7619048,8.14285714 C11.7619048,6.64538769 10.5442348,5.42857143 9.04761905,5.42857143 Z"
        id="Fill-2-Copy"
        strokeWidth="1.5"
      ></path>
      <path
        d="M5.66728916,16.9904762 L2.49470647,16.9904762 L-0.266828731,26.4380952 L19.2667967,26.4380952 L16.506444,16.9904762 L13.2801885,16.9904762 L13.2801885,18.3952212 L15.4229979,18.3952212 L17.3630191,25.0335076 L1.63816728,25.0335076 L3.57818843,18.3952212 L5.66728916,18.3952212 L5.66728916,16.9904762 Z"
        id="Fill-3-Copy"
        strokeWidth="0.4"
      ></path>
    </svg>
  );
};

export default ZoneIcon;
