import React from "react";
import { HistoryContainer } from "./style";

class PaymentsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { movements, total, details } = this.props.data;
    return (
      <HistoryContainer>
        <div className="title">
          <div>Historial de compras</div>
          <div>{movements} movimientos</div>
        </div>
        <div className="details">
          {details.map((item) => {
            return (
              <div className="row">
                <div className="date">{item.date}</div>
                <div className="details_title">{item.title}</div>
                <div className="description">{item.description}</div>
                <div className="qty">{item.qty}</div>
              </div>
            );
          })}
        </div>
        <div className="footer">
          <div className="qty">
            Total de compras <span>{total}</span>
          </div>
        </div>
      </HistoryContainer>
    );
  }
}

export default PaymentsHistory;
