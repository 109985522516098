import React from 'react';
import ReactTooltip from 'react-tooltip';

const ChecklistTooltip = (props) => {

    return(
        <ReactTooltip
            id={props.tooltipId}
            place={props.place || "right"}
            effect="solid"
            event={props.event}
            eventOff= {props.eventOff}
            className= {props.className}
        >

            <div className={'checklist-tooltip '.concat(props.className || '')}>
                <div style={{display: 'flex', marginBottom: '20px'}}>{props.title}</div>
                {
                    props.validations &&
                    props.validations.map( (item, index) => {

                        const isPending = props.valueToValidate === '';

                        if (isPending) {

                            return(
                                <div key={index} className= {'item-pending'}>
                                    <div className='point'>{'■'}</div>
                                    { item.textToShow }
                                </div>
                            );

                        } else {

                            const isValid = item.valid ? item.valid(props.valueToValidate) : true;
                        
                            return(
                                
                                <div key={index} className= { isValid ? 'item' : 'item-error' }>
                                    {
                                        isValid ? 
                                        <div className='point'>{'✓'}</div>
                                        :
                                        <div className='point'>{'⚠'}</div>
                                    }
                                    { item.textToShow }
                                </div>

                            );

                        }

                    })
                }

            </div>

        </ReactTooltip>
    );

}

export default ChecklistTooltip;