import React from 'react'
import Button from './button';
import { PropTypes } from 'prop-types';
import SVGIcon, { icons }  from '../svg-icon';
import FileSelector, { acceptTypes, getFileDescription } from './file-selector';

class InputProfileImage extends React.Component {

  handlePicture = () => {
    this.props.handlePicture()
  }

  render() {

    const { profileImage, defaultImage = '' } = this.props;

    var backgroundImage = '';

    if (defaultImage !== '') {
      backgroundImage = defaultImage;
    } else {
      backgroundImage = this.props.isFactory ? '/images/upload-logo-factory.jpg' : '/images/user_large.svg';
    }

    if (profileImage) {
      backgroundImage = ''
    }

    return (
      <div className="picture" style={ this.props.marginStyle && this.props.marginStyle }>

        <div className='send_photo'>Sube tu foto <div className='optional'>(opcional)</div></div>
        <h2 className="caption">{this.props.caption}</h2>
        <h2 className="caption">*Recuerda que tu imagen debe ser formato <i>.jpg</i>, <i>.png</i> o <i>.gif</i>.</h2>

        <div className="pictureImage">
          {
            profileImage ? 
              <div
                style={{position: 'relative'}}
              >
                <img alt="" className={"pictureFrame"} src={String(profileImage).includes("http") ? profileImage : URL.createObjectURL(profileImage)} />

                {
                  this.props.verification_status === 3 &&
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      width: 15,
                      height: 15,
                    }}
                  >
                    <img
                      alt=""
                      src="/images/check-mark.png"
                      style={{ 
                        width: 15,
                        height: 15,
                        borderRadius: '50%'
                      }}
                    />
                  </div>
                }
              </div>
            :

              <div 
                style={{backgroundImage: `url(${backgroundImage})` }}
                className={this.props.isFactory ? "pictureFrameFac" : "pictureFrame"}
                onClick={this.handlePicture}
                onDragEnter={this.props.dragAndDrop}
                onDragLeave={this.props.dragAndDrop}
                onDragOver={this.props.dragAndDrop}
                onDrop={this.props.dragAndDrop} >

                <img alt="" src="/images/add_large.svg" />
              </div>
          }
        </div>

        <div className="pictureImageCont">
          <div className="pictureButton">
            <Button settings={{
              text: 'Selecciona tu foto',
              type: 'button outline btnPicture',
              onClick: this.handlePicture
            }}
              onDragEnter={this.props.dragAndDrop}
              onDragLeave={this.props.dragAndDrop}
              onDragOver={this.props.dragAndDrop}
              onDrop={this.props.dragAndDrop}
            />
          </div>
        </div>
        
        {
          [0,2].includes(this.props.verification_status) &&
          <>
            <div style={{ height: 60 }}/>
            <div className='send_photo'>Verifica tu perfil de empresa <div className='optional'>(opcional)</div></div>
            <h2 className="caption">Sube tu constancia de RFC y asegúrate que el nombre y domicilio de tu perfil de empresa coincida con tu constancia y en un periodo de 24 a 72 hrs verificaremos tu perfil</h2>
            <div style={{ height: 30 }}/>

            {
              this.props.nameCompanyFile && this.props.nameCompanyFile !== '' &&
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div 
                  style={{
                    padding: 8,
                    backgroundColor: 'rgb(47,59,82)',
                    color: 'white',
                    width: 'fit-content',
                    cursor: 'pointer',
                    display: 'flex'
                  }}
                  onClick={() => {
                    this.props.deleteFile();
                  }}
                >
                  <div
                    style={{
                      maxWidth: 300,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      height: 20,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {this.props.nameCompanyFile}
                  </div>
                  <img alt="" src={"/images/close.svg"} style={{width: 12, marginLeft: 12}}/>
                </div>
              </div>
            }

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <FileSelector
                name={"verifyCompanyFile"}
                acceptTypes={[acceptTypes.imagesAndPdf]}
                onChange={this.props.handleChangeFile}
                // allowMultiple={true}
              >
                <Button
                  settings={{
                    type: "primary",
                    text: "Selecciona archivo",
                    prefix: {
                      element: (
                        <SVGIcon
                          name={icons.uploadFile}
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        />
                      ),
                    },
                  }}
                />
              </FileSelector>
            </div>
          </>
        }

      </div>
    )
  }
}

InputProfileImage.propTypes = {
  handlePicture: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
}

InputProfileImage.defaultProps = {
  className: '',
  title: ''
}

export default InputProfileImage