//@flow
import React from "react";
import { connect } from "react-redux";
import type { RouterHistory } from "react-router-dom";
import { SERVER } from "../../redux/api/api-urls";
import { View, ViewTitle } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import { Flex } from "../../shared/styled/flex";

import {
  ApiOptions,
  readEntities,
  getEntityItems,
  getEntityArgs,
  createEntity,
} from "../../redux/api/";
import * as SharedTypes from "../../shared/types";
import * as Types from "./types";

import ShipmentSummaryList from "./shipment-summary-list";
import ShipmentSummaryHeatmap from "./shipment-summary-heatmap";
import { normalizeStr } from "../../shared/utils";
import { SummaryFilters } from "./utils";
// import { DownloadIcon } from "../../company/routes/style";
// import DownloadImage from "../../shared/icons/routes/downloadIcon";
import axios from "axios";
import { FILES_SERVER } from "../../redux/api/api-urls";
import Modal from "react-modal";
import FileSelector, { acceptTypes, getFileDescription } from "../../shared/controls/file-selector";
import Button from "../../shared/controls/button";
import Chip from "../../shared/controls/chip";
import SVGIcon, { icons } from "../../shared/svg-icon";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import ErrorDialog from "../../shared/dialog/error-dialog";
import { DownloadIcon } from "../../company/routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import ReactTooltip from "react-tooltip";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from "../../shared/component/helper_files_modal";

type StateToProps = {|
  companyId: number,
  facilitiesByTag: Array<Types.FacilitiesByTag>,
  facilitiesByTagArgs: { date: string },
|};

type DispatchToProps = {|
  loadOrderSummary: (
    params: {
      company_id: number,
      view: Types.Maneuvers,
      date: string,
      facilities: string,
      transport_classes: string,
      fleet_providers: string,
    },
    opts: ApiOptions
  ) => void,
  loadCompanyFacilities: (
    params: { company_id: number },
    opts: ApiOptions
  ) => void,
  loadCompanyProviders: (
    params: { company_id: number, view: string },
    opts: ApiOptions
  ) => void,
  loadCompanyTransportClasses: (
    params: { company_id: number },
    opts: ApiOptions
  ) => void,
|};

type OwnProps = {|
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {
  maneuver: Types.Maneuvers,
  viewMode: Types.ViewMode,
  searchStr: string,
  filter: any,
};

class ShipmentSummaryView extends React.Component<Props, State> {
  state = {
    maneuver: "load",
    buttonFilterName: "Aplicar",
    // viewMode: "heatmap",
    viewMode: "list",
    searchStr: "",
    filter: {},
    openXlsModal: false,
    loading: false,
    xls: null,
    upload_success: 0,
    upload_fail: 0,
    upload_total: 0,
    upload_id: 0,

    openXlsSuccessModal: false,
    openXlsErrorModal: false,
    error: null
  };

  componentDidMount() {
    this.props.loadCompanyTags(this.props.companyId);
    this.loadFacilities(new Date().toISOString().split("T")[0]);
    this.props.loadCompanyFacilities({
      company_id: this.props.companyId,
      view: "grouped",
    });
    this.props.loadCompanyTransportClasses({
      company_id: this.props.companyId,
    });
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });
    this.props.setNavbarAction("resume-embarques", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("resume-embarques");
  }

  loadFacilities = (selectedDateId: string) => {
    const { companyId, loadOrderSummary } = this.props;
    const { maneuver } = this.state;
    // loadFacilities(companyId);
    loadOrderSummary(
      {
        company_id: companyId,
        view: maneuver,
        date: selectedDateId,
        facilities: this.state.filter.selectedFacilities || "",
        transport_classes: this.state.filter.transportClass || "",
        fleet_providers: this.state.filter.selectedProviders || "",
        tags: this.state.filter.selectedOrderTags || "",
      },
      {
        args: { date: selectedDateId, ...this.state.filter },
      }
    );
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    const { facilitiesByTagArgs } = this.props;
    switch (name) {
      case "maneuver":
        this.setState({ [name]: value }, () =>
          this.loadFacilities(facilitiesByTagArgs.date)
        );
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = () => {
    return `${FILES_SERVER}media/files/Cargador_Ordenes_V030521.xlsx`;
  };

  openDocument = (url) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      // headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Cargador_Ordenes_V030521.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  openXlsModal = () => {
    this.setState({ openXlsModal: true });
  };
  closeXlsModal = () => {
    if (!this.state.loading) {
      this.setState({
        openXlsModal: false,
        xls: null,
        loading: false,
      });
    }
  };

  handleAddDocument = (e) => {
    this.setState({ xls: e.target.value });
  };

  handleRemoveDocument = () => {
    if (!this.state.loading) {
      this.setState({ xls: null });
    }
  };

  importXls = () => {
    this.setState({ loading: true });
    this.props.uploadTrips({
      company_id: this.props.companyId,
      excel_file: this.state.xls[0]
    }, {
      // responseType: "BLOB",
      isForm: true,
      onSuccess: (data, status) => {
        if(status === 201){
          // const url = window.URL.createObjectURL(new Blob([data]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", "Template.xlsx");
          // // debugger;
          // document.body.appendChild(link);
          // link.click();
          // this.setState({
          //   openXlsModal: false,
          //   xls: null,
          //   loading: false,
          // })
          // this.manageError([]);
        } else {
          // debugger;
          this.setState({
            openXlsModal: false,
            xls: null,
            loading: false,
            upload_success: data.successful,
            upload_fail: data.errors,
            upload_total: data.total,
            upload_id: data.upload_id
          })
          this.showResultModal(true);
        }
      },
      onError: (e) => {
        this.setState({
          openXlsModal: false,
          xls: null,
          loading: false,
        })
        let err = e.response?.data?.errors || []; 
        this.manageError(err);
      }
    })
    // postUploadFleets(this.state.xls, this.state.fleetTypeItem.id)
    //   .then((data) => {
    //     if (data.status !== "error") {
    //       this.openXlsSuccessModal(data);
    //     } else {
    //       this.openXlsErrorModal(data);
    //     }
    //   })
    //   .catch((error) => {
    //     this.openXlsErrorModal(error);
    //   });
  };

  showResultModal = (show) => {
    if(!show){
      this.setState({
        openXlsSuccessModal: show,
        xls: null,
        loading: false,
        upload_success: 0,
        upload_fail: 0,
        upload_total: 0,
        upload_id: 0
      })
    } else {
      this.setState({openXlsSuccessModal: show});
    }
  }

  showErrorModal = (show) => {
    this.setState({openXlsErrorModal: show});
  }

  manageError = (err) => {
    this.setState({
      error: err
    });
    this.showErrorModal(true);
  }

  render() {
    const { maneuver, viewMode } = this.state;
    const { facilitiesByTag } = this.props;
    return (
      <View className="no-pad-bottom">
        <ViewTitle>
          <span>Resumen de embarques</span>
          <>
            <DownloadIcon data-tip data-for={"download-icon"}>
              <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                onClick={(e) => {e.stopPropagation(); this.openDocument(this.getUrl())}}
              />
            </DownloadIcon>
            <ReactTooltip
              id={"download-icon"}
              delayHide={50}
              delayShow={50}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
            >
              {`Descargar template para cargar viajes`}
            </ReactTooltip>
          </>
          <Button
            settings={{
              text: "Cargar viajes",
              // disabled:
              type: "primary outline",
              onClick: this.openXlsModal,
            }}
            // className={this.props.companyType === 1 ? "" : "hidden"}
          />
          <FilterBarCompact
            searchStr={this.state.searchStr}
            searchAction={this.handleChange}
            content={SummaryFilters(
              this.props.companyFacilities,
              this.props.companyProviders,
              this.props.companyTransportClasses,
              this.props.orderTags
            )}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState(
                  {
                    buttonFilterName: "Restablecer"
                  },
                  () => { this.loadFacilities(this.props.facilitiesByTagArgs.date); }
                );
              } else {
                this.setState(
                  {
                    filter: {},
                    buttonFilterName: "Aplicar"
                  },
                  () => { this.loadFacilities(this.props.facilitiesByTagArgs.date); }
                );
              }
            }}
            onChange={(e) =>
              this.setState({
                filter: {
                  ...this.state.filter,
                  [e.target.name]: e.target.value,
                },
                buttonFilterName: "Aplicar"
              })
            }
            buttonFilterName={this.state.buttonFilterName}
            selectedItems={this.state.filter}
          />
        </ViewTitle>
        <Flex>
          <div
            style={{
              width: "300px",
              marginLeft: "50%",
              transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={maneuver}
              name="maneuver"
              onChange={this.handleChange}
              items={[
                { description: "Descarga", value: "unload" },
                { description: "Carga", value: "load" },
                { description: "Todo", value: "all" },
              ]}
            />
          </div>
          <div style={{ width: "150px", marginLeft: "auto", marginRight: "0" }}>
            <OptionSelectorSwitch
              value={viewMode}
              name="viewMode"
              onChange={this.handleChange}
              items={[
                { description: "Lista", value: "list" },
                { description: "Heatmap", value: "heatmap" },
              ]}
            />
          </div>
        </Flex>
        {viewMode === "list" ? (
          <ShipmentSummaryList
            history={this.props.history}
            facilitiesByTag={facilitiesByTag.filter(
              (ft) =>
                normalizeStr(ft.title || "")
                  .toLowerCase()
                  .includes(normalizeStr(this.state.searchStr).toLowerCase()) ||
                ft.facilities.find((fc) =>
                  normalizeStr(fc.description)
                    .toLowerCase()
                    .includes(normalizeStr(this.state.searchStr).toLowerCase())
                )
            )}
            loadFacilities={this.loadFacilities}
          />
        ) : (
          <ShipmentSummaryHeatmap />
        )}
        <Modal
          isOpen={this.state.openXlsModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator"
        >
          <div className="title">Cargar archivo</div>
          <div className="close" onClick={this.closeXlsModal}></div>
          <div className="message">
            <div className="attach-xls">
              <div>
                <FileSelector
                  name={"xls"}
                  acceptTypes={[acceptTypes.xls]}
                  onChange={this.handleAddDocument}
                  allowMultiple={false}
                >
                  <Button
                    settings={{
                      type: "primary small",
                      text: "Subir archivo",
                      prefix: {
                        element: (
                          <SVGIcon
                            name={icons.uploadFile}
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                          />
                        ),
                      },
                    }}
                  />
                </FileSelector>
              </div>
              <div>
                {(this.state.xls || []).map((f, i) => (
                  <Chip
                    key={i}
                    id={i}
                    active={true}
                    className="medium"
                    name="files"
                    description={getFileDescription(f, 25)}
                    onClick={() => {}}
                    prefix={{
                      element: (
                        <SVGIcon
                          name={icons.imageFile}
                          fill="#657795"
                          viewBox="0 -1 16 16"
                        />
                      ),
                    }}
                    suffix={{
                      element: (
                        <div
                          onClick={() => this.handleRemoveDocument("xls", f)}
                        >
                          {" "}
                          <SVGIcon
                            name={icons.cross}
                            fill="#A7B6D6"
                            viewBox="0 3 16 16"
                          />
                        </div>
                      ),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="actions">
            <Button
              settings={{
                text: "Cancelar",
                type: "primary outline",
                onClick: this.closeXlsModal,
              }}
            />
            <Button
              settings={{
                text: this.state.loading ? "Cargando..." : "Cargar",
                disabled:
                  this.state.xls === null || this.state.loading ? true : false,
                onClick: () => {
                  this.importXls();
                },
              }}
            />
          </div>
        </Modal>
        <ConfirmDialog
          open={this.state.openXlsSuccessModal}
          title="Archivo cargado con éxito"
          acceptText="Aceptar"
          class="result-message invite-operator successModal"
          contentObject={<>
            <div className="successContent">
              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval green.svg"></img>
                </div>
                <div className="text">
                  {this.state.upload_success} de {this.state.upload_total}{" "}
                  Registros cargados éxitosamente.
                </div>
              </div>

              <div className="text-group">
                <div class="image">
                  <img alt="" src="/images/oval red.svg"></img>
                </div>
                <div className="text">
                  {this.state.upload_fail} de {this.state.upload_total}{" "}
                  Registros rechazados.
                </div>
              </div>

              <div className="errorsDetails"></div>

              <div className="export">
                Para ver más detalles ver el{" "}
                <a
                  href={`${SERVER}/api/v2/companies/${this.props.companyId}/trips_control/upload/${this.state.upload_id}/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  descargable
                </a>
              </div>
            </div>
          </>}
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
        <ErrorDialog
          open={this.state.openXlsErrorModal}
          title="Error"
          message={this.state.error}
          acceptText="Aceptar"
          closeAction={() => this.showErrorModal(false)}
          acceptAction={() => this.showErrorModal(false)}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const orderTags = companyTags.filter(tag => tag.type === 7);

  const facilitiesByTag: Types.FacilitiesByTag[] = (getEntityItems(
    state,
    "COMPANIES.ORDERS"
  ) || [])
    .map((tag) => ({
      color: tag.tag_color,
      id: tag.tag_id,
      title: tag.tag_name,
      facilities: tag.facilities.map((f) => ({
        id: f.facility_id,
        description: f.facility_name,
        programed: f.programed,
        additional: f.additional || 0,
        early_date: f.early_date,
        late_date: f.late_date,
        attended: f.attended,
        arrivals: f.arrivals,
        to_attend: f.to_attend,
        last_estimate_variation: ((f.facility_name === "CULIACAN" && f.last_estimate === 0) ? "-" :  f.last_estimate)
          .toString()
          .concat(" - ", ((f.facility_name === "CULIACAN" && f.last_estimate === 0) ? " -" :  f.variation)),
        canceled: f.canceled,
        rescheduled: f.rescheduled,

        // last_estimate_variation_calc: {
        //   last_estimate: f.last_estimate,
        //   variation: f.variation,
        // },
        // last_estimate: f.last_estimate,
        // variation: f.variation,
      })),
    }))
    .sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });

  const facilitiesByTagArgs = getEntityArgs(state, "COMPANIES.ORDERS");
  const companyFacilities = getEntityItems(
    state,
    "COMPANIES.FACILITIES.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.facilities.map((ff) => ({
      id: ff.id,
      description: `${ff.name} - ${ff.code} - ${ff.alias}`,
    })),
  }));
  const companyTransportClasses = getEntityItems(
    state,
    "COMPANIES.TRANSPORTCLASS.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.transport_classes.map((tc) => ({
      id: tc.id,
      description: tc.code,
    })),
  }));
  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK").map(
    (f) => ({
      description: `${f.fleet_provider.alias} - ${f.fleet_provider.code} - ${f.fleet_provider.orbi_id}`,
      id: f.fleet_provider.fleet_provider_id || 0,
    })
  );
  return {
    companyId,
    orderTags,
    facilitiesByTag,
    facilitiesByTagArgs,
    companyFacilities,
    companyProviders,
    companyTransportClasses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadOrderSummary: (params, opts) =>
      dispatch(readEntities("COMPANIES.ORDERS", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES.FILTERS", params, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyTransportClasses: (params, opts) =>
      dispatch(
        readEntities(
          "COMPANIES.TRANSPORTCLASS.FILTERS",
          { ...params, view: "grouped" },
          opts
        )
      ),
    uploadTrips: (params, opts) =>
      dispatch(createEntity("COMPANIES.TRIPS.CONTROL.UPLOAD", params, opts)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentSummaryView);
