import styled from "styled-components";
import ArrowDownUpSeparate from "../../shared/icons/routes/ArrowDownUpSeparate";

export const ArrowIconMargin = styled(ArrowDownUpSeparate)`
  margin-right: 3px;
`;

export const TooltipRow = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 14px;
  }
  span {
    font-size: 12px;
  }
`;

export const DownloadIcon = styled.div`
  cursor: pointer;
`;
