//@flow

import React from "react";
import SelectInput from "./select-input";
import { SimpleEvent } from "../../shared/types/index";
type Props = {
  limit: number,
  offset: number, //alias page
  onChange: (e: SimpleEvent) => void,
  itemCount: number,
};

const PAGES = 9;

class PaginatorControlled extends React.Component<Props> {
  constructor(props){
    super(props);
    this.state = {
      initPage: (((this.props.offset || 1) - 4) < 1 ? 1 : ((this.props.offset || 1) - 4))
    }
  }
  thing = 0;

  handleChange = (e: SimpleEvent) => {
    const { name, value } = e.target;
    switch (name) {
      case 'limit':
        this.props.onChange({ target: { name: 'offset', value: 1 } });
      default:
        this.props.onChange({ target: { name, value } });
    }
  };

  componentDidUpdate(prevProps) {
    this.thing = this.props.itemCount
      ? this.props.itemCount
      : prevProps.itemCount;
    if(prevProps.offset !== this.props.offset){
      let initPage = Math.floor(this.props.offset/10)*10 + 1;
      // debugger;
      this.setState({initPage});
    }
    // console.log(this.thing, this.props.itemCount, prevProps.itemCount);
    // console.log(this.thing);
  }

  prevPage = () => {
    // let offset = this.props.offset > 1 ? this.props.offset - 1 : 1;
    // this.handleChange({ target: { name: "offset", value: offset } });
    let initPage = (this.state.initPage - PAGES) > 1 ? this.state.initPage - 10 : this.state.initPage;
    this.setState({initPage});
  };

  nextPage = () => {
    // let offset =
    //   this.props.offset < this.maxPages()
    //     ? parseInt(this.props.offset) + 1
    //     : this.maxPages();
    // this.handleChange({ target: { name: "offset", value: offset } });
    let initPage = (this.state.initPage + PAGES) < this.maxPages() ? this.state.initPage + 10 : this.state.initPage;
    this.setState({initPage});
  };

  maxPages = () =>
    Math.ceil((this.props.itemCount || this.thing) / this.props.limit) || 0;
  // maxPages = () => Math.ceil(this.props.itemCount / this.props.limit) || 0;

  getPages = () => {
    let pages = [];
    // let firstVisiblePage = this.props.offset - 4;
    let firstVisiblePage = this.state.initPage;
    // firstVisiblePage = firstVisiblePage < 1 ? 1 : firstVisiblePage;
    let lastVisiblePage = firstVisiblePage + PAGES;
    lastVisiblePage =
      lastVisiblePage > this.maxPages() ? this.maxPages() : lastVisiblePage;
    // for (let iPage = 1; iPage <= this.maxPages(); iPage++) {
    if (firstVisiblePage > 1) {
      pages.push(
        <div
          id={"prev-pages"}
          key={"prev-page"}
          className={"extra-pages"}
          onClick={() => {}}
        >
          ...
        </div>
      );
    }
    for (let iPage = firstVisiblePage; iPage <= lastVisiblePage; iPage++) {
      pages.push(
        <div
          // id={"page-".concat(iPage)}
          key={"page-".concat(iPage)}
          className={parseInt(this.props.offset) === iPage ? "selected" : ""}
          onClick={() =>
            this.handleChange({ target: { name: "offset", value: iPage } })
          }
        >
          {iPage}
        </div>
      );
    }
    if (lastVisiblePage < this.maxPages()) {
      pages.push(
        <div
          id={"next-pages"}
          key={"next-page"}
          className={"extra-pages"}
          onClick={() => {}}
        >
          ...
        </div>
      );
    }
    return pages;
  };
  render() {
    return (
      <div className="paginator">
        <div className="counter">
          {(this.props.offset - 1) * this.props.limit + 1} -{" "}
          {Math.min(
            this.props.offset * this.props.limit,
            this.props.itemCount
          ) || ""}{" "}
          de {this.props.itemCount}
        </div>
        <div className="pages">
          <div onClick={this.prevPage}>&lt;</div>
          {this.getPages()}
          <div onClick={this.nextPage}>&gt;</div>
        </div>
        <div className="number-elements">
          Mostrar:
          <SelectInput
            label=""
            name="limit"
            value={this.props.limit}
            items={this.props.limitOptions}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

export default PaginatorControlled;

const ITEMS_PER_PAGE_OPTIONS = [
  {
    id: 10,
    description: "10",
  },
  {
    id: 25,
    description: "25",
  },
  {
    id: 50,
    description: "50",
  },
  {
    id: 100,
    description: "100",
  },
];

PaginatorControlled.defaultProps = {
  // itemCount: 0,
  page: 1,
  limit: 0,
  limitOptions: ITEMS_PER_PAGE_OPTIONS
};


