import React, { Component } from "react";
import PropTypes from "prop-types";

class RadioButtons extends Component {
  HandleChange = (e) => {
    if (this.props.onChange) this.props.onChange(e);
  };

  render() {
    return (
      <div className={"radioflex ".concat(this.props.className || "")}>
        <label>
          <input
            type="radio"
            value={this.props.value}
            name={this.props.name}
            onChange={this.HandleChange}
            key={this.props.id}
            checked={this.props.checked}
          />
          {this.props.value}
        </label>
      </div>
    );
  }
}

export default RadioButtons;

RadioButtons.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
};
