import React from "react";
import CatalogHeader, {
  LIST,
} from "../../shared/component/catalog-header";
import FilterBar from "../../shared/component/filter/filter-bar";
import Selector from "../../shared/component/selector";
import { connect } from "react-redux";
import {
  loadSession,
  removeToast
} from "../../redux/reducers/global-catalog.reducer";
import { loadProductsFilters, deleteProduct, loadProductsCatalogs } from "../../redux/reducers/products.reducer";
import {
  filterUsersByText,
} from "../../redux/reducers/user-reducer";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import ExceedPlus from "../../shared/controls/exceed-plus";
import Tag from "../../shared/controls/tag";
import { getUrlsEnv } from "../../shared/backend-api";
import CatalogRow from "../../shared/component/catalog-row";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import UserChipContainer from "../../shared/component/user-chip-container";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { getEntityItems, readEntities } from '../../redux/api';
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import { DownloadIcon } from "../routes/style";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from '../../shared/component/helper_files_modal';

const defaultPaginatorItems = 10;

class ProductsCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      limit: 10,
      products: [],
      offset: 1,

      isProductsLoading: false,
      productsCount: 0,
      showDeleteProductsModal: false,
      showResultModal: false,

      resetPaginator: false,

      resultModalText: "",
      resultModalTitle: "",

      selectionModeOn: false,
      selectedProductsId: [],

      namesFilter: [],
      skuFilter: [],
      tagFilter: [],
      searchStr: "",

      buttonFilterName: "Aplicar",
    };

    this.openedLink = false;

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  componentDidMount() {
    this.setState({ ...this.props.configuration });
    // this.props.loadProductKeys();
    // this.props.loadDangerCodes();
    // this.props.loadPackingTypes();

    this.props.setNavbarAction("products-catalog", [
      {
        icon: <HelperFilesModal 
          files={[]}
        />
      }
    ]);

  }

  componentWillUnmount() {
    let config = this.state;

    if (!this.openedLink) {
      config.searchStr = '';
    }

    this.props.setConfiguration(config);
    this.openedLink = false;

    this.props.removeNavbarAction("products-catalog");
  }

  loadProducts = () => {

    this.setState({
      isProductsLoading: true,
      products: []
    });

    this.props.loadProducts({
      company_id: this.props.companyId,
      tags: this.state.tagFilter,
      skus: this.state.skuFilter,
      products: this.state.namesFilter,
      search: this.state.searchStr,
      offset: this.state.offset,
      limit: this.state.limit,
      view: ''
    }, {
      onSuccess: (value) => {

        const { count = 0, results = [] } = value;

        this.setState({
          productsCount: count,
          products: results,
          isProductsLoading: false
        });

      }
    });
  };

  headerActionButtonSettings = {
    text: "Agregar",
    onClick: () => {
      this.props.history.push(`/company/product/`);
    },
  };

  toggleViewMode = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };

  redirectToProfile = (e) => {
    this.props.history.push(`/company/product/profile/${e.target.productId}`);
  };

  //SELECTOR
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedProductsId: []
    });
  };

  handleSelectProduct = (e) => {

    let selectedProductsId = [...this.state.selectedProductsId];
    if (e.target.value) {
      selectedProductsId.push(e.target.name);
    } else {
      selectedProductsId.splice(
        selectedProductsId.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedProductsId });

  };

  selectAllProducts = () => {
    if (this.state.products.length === (this.state.selectedProductsId.length)) {
      this.setState({ selectedProductsId: []});
    } else {
      let selectedProductsId = [];

      this.state.products
        .map((item) => {
          selectedProductsId.push(item.id);
          return null;
        });
      
      this.setState({ selectedProductsId});
    }
  };

  //SHOW/HIDE EACH MODAL

  showDeleteProductsModal = (show) => {
    this.setState({ showDeleteProductsModal: show });
  };

  showResultModal = (show) => {
    if (!show) {
      this.setState({ resetPaginator: true });
      this.loadProducts();
    }
    this.setState({ showResultModal: show });
  };

  //FILTERS
  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  handleFilter = (limit = defaultPaginatorItems, offset = 1) => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Restablecer" }, () => this.loadProducts() );
    } else {
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      skuFilter: [],
      namesFilter: [],
      tagFilter: []
    }, () => this.loadProducts() );
  }

  handlePaginator = (limit = defaultPaginatorItems, offset = 1) => {
    this.setState({ limit, offset }, () => this.loadProducts());
  };

  filterContents = () => [
    {
      title: "Nombre",
      items: this.props.namesFilter,
      name: "namesFilter",
      listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    },
    {
      title: "Sku",
      items: this.props.skuFilter,
      name: "skuFilter",
      listType: FILTER_LIST_TYPES.checkListTextSearchAll,
    },
    {
      title: "Etiquetas",
      items: this.props.tagFilter,
      name: "tagFilter",
      listType: FILTER_LIST_TYPES.chips,
    },
  ];

  handleProductChipClick = (id) => {
    let selectedProductsId = [...this.state.selectedProductsId];
    selectedProductsId.splice(
      selectedProductsId.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedProductsId });
  };

  deleteProducts = () => {
    asyncForEach(this.state.selectedProductsId, async (item) => {
      this.props.deleteProduct(item);
    });

    this.setState({
      showDeleteProductsModal: false,
      showResultModal: true,
      selectionModeOn: false,
      resultModalText: "Los productos han sido eliminados",
      resultModalTitle: "¡Listo!",
    });
  };

  //ROWS CONTENT

  getCodeUnit = (id) => {
    var valueToReturn = '-'

    if (id !== null && id !== undefined) {
      for (var i = 0; i < this.props.unitsCatalog.length; i++) {
        if (`${id}` === `${this.props.unitsCatalog[i].id}`) {
          valueToReturn = `${this.props.unitsCatalog[i].code}`;
        }
      }
    }

    return valueToReturn;
  }

  getDescriptionUnit = (id) => {
    var valueToReturn = '-'

    if (id !== null && id !== undefined) {
      for (var i = 0; i < this.props.unitsCatalog.length; i++) {
        if (`${id}` === `${this.props.unitsCatalog[i].id}`) {
          valueToReturn = `${this.props.unitsCatalog[i].description}`;
        }
      }
    }

    return valueToReturn;
  }
  
  getRow = (rowItem) => {

    const { description, gross_weight, gross_weight_unit, net_weight, net_weight_unit, sku, product_unit, volume_unit, sat_code, packing_code, dangerous_product, dangerous_product_code, data } = rowItem;

    return (
      <div className="row-content products-row" style={{paddingLeft: 0, paddingRight: 0}}>
        <div className={"contact products-table"}>
          <div
            className={"image ".concat(rowItem.url ? "" : "default")}
            style={{
              backgroundImage: "url(".concat(
                getAvatarUrl(rowItem.url, rowItem.id), ""
              ),
            }}
          >
          </div>
          <div className="contact-data">
            <span className="name">{description}</span>
            <span className="email">{`sku: ${sku}`}</span>
            <span className="email">{`Bienes: ${sat_code ? (this.props.product_keys.find(p => p.id.toString() === sat_code.toString()) || {}).clave : '-'}`}</span>
          </div>
        </div>

        <div className={'products'}> 
          {data?.sat_product_unit?.nombre || '-'}
        </div>

        <div className={'products'}>
          {this.getDescriptionUnit(volume_unit)}
        </div>

        <div className={'products'}>
          {`${net_weight || ''} ${data?.sat_net_weight_unit?.nombre || '-'}`}
        </div>

        <div className={'products'}>
          {`${gross_weight || ''} ${data?.sat_gross_weight_unit?.nombre || '-'}`}
        </div>

        
        <div className={'products danger'}>
          {dangerous_product ? 'Sí' : 'No'}
        </div>
        <div className={'products'}>
          {`${data?.material_peligroso?.clave || '-'}`}
        </div>
        <div className={'products'}>
          {`${data?.tipo_embalaje?.clave || '-'}`}
        </div>

        <div className="tags" /* style={{paddingLeft: 20, paddingRight: 20}} */>
          <ExceedPlus
            maxVisibleItems={2}
            name={rowItem.id.toString()}
          >
            {(rowItem.producttag_product || []).map((item) => (
              <Tag
                key={item.tag.id}
                id={item.tag.id}
                title={item.tag.title}
                color={item.tag.color}
              />
            ))}
          </ExceedPlus>
        </div>
      </div>
    );
  };

  getSelectedFunction = (item) => {
    return this.state.selectedProductsId.find((sU) => sU === item.id) ? true : false
  }

  render() {
    return (
      <div className="catalog" onClick={this.clickOutside}>
        <ToastContainer className="global-toast-container" />
        
        <CatalogHeader
          viewMode={this.state.viewMode}
          showViewMode={false}
          title={"Productos"}
          actionButtons={[
            {
              text: "Subir xls",
              onClick: () => {},
              type: "primary outline",
              // className: this.props.companyType === 1 ? "" : "hidden",
            },
            this.headerActionButtonSettings
          ]}
          toggleView={this.toggleViewMode}
        >
          <DownloadIcon data-tip data-for={"download-icon"}>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              onClick={() => {}}
            />
          </DownloadIcon>
          <ReactTooltip
            id={"download-icon"}
            delayHide={50}
            delayShow={50}
            delayUpdate={200}
            place={"bottom"}
            border={true}
            type={"light"}
            effect="solid"
          >
            {`Descargar template xlsx`}
          </ReactTooltip>
        </CatalogHeader>

        <FilterBar
          searchAction={this.handleTextSearch}
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          onChange={this.handleFilterChange}
          searchButtonText={this.state.buttonFilterName}
          content={this.filterContents()}
          selectedItems={{
            skuFilter: this.state.skuFilter,
            namesFilter: this.state.namesFilter,
            tagFilter: this.state.tagFilter
          }}
        />
        <div className={"content ".concat(this.state.viewMode)}>
          
          <div className="user-row-header products-row">
            {/* <div className="logo"></div> */}
            <div className="product-name">Nombre</div> 
            <div className="products">Presentación</div>
            <div className="products">Unidades</div>
            <div className="products">Peso Neto</div>
            <div className="products">Peso Total</div>
            <div className="danger">Peligroso</div>
            <div className="products">Clave Peligro</div>
            <div className="products">Clave Embalaje</div>
            <div className="tags-products">Etiquetas</div>
          </div>

          {
            this.state.isProductsLoading ? 
            <div style={{
              height: 140,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: 18
            }}>
              Cargando...
            </div>
            : 
            <div/>
          }

          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedProductsId}
            onSelect={this.handleSelectProduct}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectMode}
          >
            {Array.isArray(this.state.products) &&
              this.state.products.map((item) => {
                return (
                  <CatalogRow
                    key={ item.id}
                    defaultClass={"user-row ".concat(
                      "user-type-",
                      '',
                      " user-status-",
                      '',
                      ' products-row'
                    )}
                    id={item.id}
                    selected={this.getSelectedFunction(item)}
                    onClick={() => {
                      this.openedLink = true;
                      this.redirectToProfile({
                        target: {
                          productId: item.id,
                        },
                      })
                    }}
                  >
                    {this.getRow(item)}
                  </CatalogRow>
                );
              })}
          </Selector>
          <PaginatorControlled
            itemCount={this.state.productsCount}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () =>
                this.loadProducts()
              )
            }
            limit={this.state.limit}
            offset={this.state.offset}
          />
        </div>
        
        <CatalogSelectionFooter
          show={this.state.selectionModeOn}
          selectedElements={this.state.selectedProductsId.length}
          selectAll={this.selectAllProducts}
          actions={[
            {
              description: "Eliminar",
              f: () => this.showDeleteProductsModal(true),
            },
          ]}
        />

        <ConfirmDialog
          open={this.state.showResultModal}
          title={this.state.resultModalTitle}
          message={this.state.resultModalText}
          acceptText="Aceptar"
          class="result-message"
          closeAction={() => this.showResultModal(false)}
          acceptAction={() => this.showResultModal(false)}
        />
        <ConfirmDialog
          open={this.state.showDeleteProductsModal}
          title="¿Eliminar productos?"
          message={"¿Estás seguro que deseas eliminar estos productos?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleProductChipClick(id)}
              items={
                !Array.isArray(this.state.products)
                  ? []
                  : this.state.products
                      .filter((u) =>
                        this.state.selectedProductsId.includes(u.id)
                      )
                      .map((u) => {
                        return {
                          id: u.id,
                          name: u.description,
                          avatarType: "contained",
                          avatar: getAvatarUrl(u.url, u.status_id),
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.showDeleteProductsModal(false)}
          acceptAction={() => this.deleteProducts()}
        />
      </div>
    );
  }
}

const getAvatarUrl = (partialUrl, userStatus) => {
  if (partialUrl) {
    return getUrlsEnv().files.concat(partialUrl);
  }

  return "/images/product_avatar.png";
};

const mapStateToProps = (state) => {

  const companyId: number = state.globalCatalog.session.company.id;

  const skuFilterData = state?.productsReducer?.filter?.data?.skus || [];
  const namesFilter = state?.productsReducer?.filter?.data?.products || [];
  const tagsFilterData = state?.productsReducer?.filter?.data?.tags || [];

  const unitsCatalog = state?.productsReducer?.catalogs?.data || [];

  const skuFilter = skuFilterData.map( (item) => {
    const { id, sku } = item;
    return {id, description: sku};
  });

  const configuration = state.configuration.ProductsCatalog;
  
  const product_keys = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPRODUCTO");
  const packing_types = getEntityItems(state, "COMPANIES.SAT.CATALOGS.TIPOEMBALAJE");
  const danger_codes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO");
  return {
    companyId,
    configuration,
    searchStr: state.userReducer.searchStr,
    skuFilter,
    namesFilter,
    tagFilter: tagsFilterData,
    unitsCatalog,
    toast: state.globalCatalog.toast,
    product_keys,
    packing_types,
    danger_codes,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadProductsFilters());
  dispatch(loadProductsCatalogs());

  return {
    loadProductKeys: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPRODUCTO", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadPackingTypes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.TIPOEMBALAJE", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadDangerCodes: () =>{
      dispatch(
        readEntities("COMPANIES.SAT.CATALOGS.CLAVEPELIGROSO", {}, {prefix: 'orbisat.'}) 
      );
    },
    loadProducts: (params, opt) => dispatch( readEntities("COMPANIES.PRODUCTS", params, opt) ),
    searchByTextUser: (text) => dispatch(filterUsersByText(text)),
    deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    setConfiguration: (config) => dispatch(setConfig("ProductsCatalog", config)),
    removeToast: () => dispatch(removeToast()),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default connectToStore(ProductsCatalog);

ProductsCatalog.defaultProps = {
  products: [],
  productsCount: 0,
  connectionStatus: [],
  facilities: [],
  tags: [],
  loadProducts: () => {
    console.log("Not yet implemented");
  }
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
