import React from "react";
import CatalogHeader from "../../shared/component/catalog-header";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import FilterBar from "../../shared/component/filter/filter-bar";
import CatalogRow from "../../shared/component/catalog-row";
import ReactTooltip from "react-tooltip";
import { getUrlsEnv } from "../../shared/backend-api";
import {
  loadSession,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import { connect } from "react-redux";
import Button from "../../shared/controls/button";
import Modal from "react-modal";
// import SelectInput from "../../shared/controls/select-input";
import TextInput from "../../shared/controls/text-input";
import SVGIcon from "../../shared/svg-icon";
import { icons } from "../../shared/svg-icon";
import { listFacilities } from "../../redux/reducers/facility-reducer";
import {
  listNetworkApprovedInvitations,
  listExpeditionClasses,
  // listCompanyContracts,
  createCompanyContract,
  createCompanyContractAddendum,
  listExpeditionClassesDetail,
  createCompanyContractClass,
  deleteCompanyContract,
  setCompanyContractBase,
} from "../../redux/reducers/company-reducer";
import Checkbox from "../../shared/controls/checkbox";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { addToast } from "../../redux/reducers/global-catalog.reducer";
import { ToastContainer, toast } from "react-toastify";
import { removeToast } from "../../redux/reducers/global-catalog.reducer";
import { isPremiumSubscriptionCo, normalizeStr } from "../../shared/utils";
import { readEntities, getEntityItems } from "../../redux/api";
// import SelectMultipleInput from "../../shared/controls/select-multiple-input";
import { loadTags } from "../../redux/reducers/tags.reducer";
import Tag from "../../shared/controls/tag";
import RadioInput from '../../shared/controls/radio-input';
import { setCurrentContract } from "../../redux/reducers/contract-reducer";
import styled from "styled-components";
import ChipContainer from "../../shared/component/chip-container";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import SelectInputStyled from "../../shared/controls/select-input-styled";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import ImageWithCheckMark from "../../shared/component/image-with-check-mark";

const contractTransportType = [
  { id: 1, description: "Transportes Terrestres" },
];

const today = /* new Date ( */ new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() - 1
);

class ContractCatalogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewContractModal: false,
      showAddUnitsModal: false,
      showAddClassModal: false,
      showAddBaseModal: false,

      contractFacility: 0,
      contractNetwork: 0,
      contractTransportClass: "",
      contractQuantity: "",
      contractDateFrom: null,
      contractDateTo: null,
      contractBase: null,
      contractOperationId: null,

      selectedContractId: 0,
      selectedContractClass: "",
      selectedContractNetwork: "",
      selectedContractFacility: "",
      selectedContractActiveUnits: 0,
      selectedContractTotalUnits: 0,

      contractTransportTypeItem: contractTransportType[0],
      facilities: [],
      transportLine: [],
      transportClass: [],
      transportClassType: 'unitary',
      unitStatus: [],
      searchStr: "",
      limit: 10,
      offset: 1,

      contractError: false,

      contractList: [],

      buttonFilterName: "Aplicar",
      searchContracts: '',
      isFreeSubscription: false
    };

    this.showToast = (text) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };
  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    if (this.props.ownCompanyType === 1) {
      this.props.loadFilterClasses(this.props.ownCompanyId);
      this.props.loadFilterFacilities(this.props.ownCompanyId);
    }
    this.props.getUserTags(this.props.ownCompanyId, this.props.userId);
    this.setState({ ...this.props.configuration });

    this.props.setNavbarAction("contract-providers", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }
  componentWillUnmount() {
    this.props.setConfig({ ...this.state });
    this.props.removeNavbarAction("contract-providers");
  }

  loadContracts = () => {
    this.props.reloadContracts(
      this.props.ownCompanyId,
      this.state.limit,
      this.state.offset,
      this.state.facilities,
      this.state.transportClass,
      this.state.transportLine,
      this.state.searchContracts
    );
  };

  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }

  //CREATE CONTRACTS
  headerActionButtonSettings = {
    text: "Nuevo Contrato",
    onClick: () => this.showNewContractModal(true),
  };
  handleCreateInput = (e) => {
    // if (e.target.name === "contractTransportClass") {
    //   let license =
    //     this.state.contractTransportClass !== ""
    //       ? this.state.contractTransportClass.split(",")
    //       : [];
    //   if (e.target.checked) {
    //     license.push(e.target.value);
    //   } else {
    //     license = license.filter((item) => item !== e.target.value);
    //   }
    //   license.sort();
    //   license = license.join(",");
    //   this.setState({ [e.target.name]: license });
    // } else {
      if (e.target.name === "contractQuantity") {
        if (isNaN(e.target.value)) return;
      }
      this.setState({
        [e.target.name]: e.target.value,
      });
    // }
  };
  handleDateToChange = (e) => {
    if (e.target.checked) {
      this.setState({ contractDateTo: null });
    }
  };

  handleCreateContract = () => {
    // let dateFrom = this.state.contractDateFrom
    //   .toLocaleDateString("en-US")
    //   .split("/");
    // let newDateFrom = dateFrom[2].concat("-", dateFrom[0], "-", dateFrom[1]);
    // let newDateTo = null;
    // if (this.state.contractDateTo) {
    //   let dateTo = this.state.contractDateTo.split("/");
    //   newDateTo = dateTo[2].concat("-", dateTo[0], "-", dateTo[1]);
    // }
    this.props
      .createCompanyContract(
        this.state.contractFacility.join(),
        this.state.contractNetwork.join(),
        this.state.contractTransportClass.join(),
        this.state.contractOperationId,
        this.state.contractQuantity,
        new Date(this.state.contractDateFrom).toISOString().split("T")[0],
        // newDateFrom,
        // newDateTo
        this.state.contractDateTo &&
          new Date(this.state.contractDateTo).toISOString().split("T")[0]
      )
      .then(async (result) => {
        if (result) {
          let classes = this.state.contractTransportClass;
          await asyncForEach(classes, async (transportClass) => {
            this.props.createCompanyContractClass(
              result.id,
              transportClass,
              new Date(this.state.contractDateFrom).toLocaleDateString("en-US"),
              this.state.contractDateTo &&
                new Date(this.state.contractDateTo).toLocaleDateString("en-US")
            );
          });
          this.handleFilter();
          this.showNewContractModal(false);
          this.setState({ contractError: false });
          this.props.showToast({ text: `Se creó el contrato con éxito.` });
        } else {
          this.setState({ contractError: true });
        }
      });
  };

  //ADD BASE
  handleBaseOption = (contract) => {
    this.setState({
      showAddBaseModal: true,
      selectedContractId: contract.id,
      contractBase: (contract.base || {}).id,
    });
  };
  handleTagClick = (e) => {
    if (e.target.value) this.setState({ contractBase: null });
    else this.setState({ contractBase: e.target.id });
  };
  handleAddContractBase = () => {
    this.props
      .setCompanyContractBase(
        this.state.selectedContractId,
        this.state.contractBase
      )
      .then((result) => {
        if (result) {
          this.showAddBaseModal(false);
          this.handleFilter();
          this.props.showToast({
            text: `Se guardaron los cambios con éxito.`,
          });
        }
      });
  };

  //DELETE CONTRACT
  handleDeleteOption = (contract, network) => {
    this.setState({
      showDeleteModal: true,
      selectedContractClass: contract.transport_classes
        .map((t) => (t.transport_class || { code: "" }).code)
        .join(", "),
      selectedContractId: contract.id,
      selectedContractNetwork: network.name,
      selectedContractFacility: contract.facilities.name,
      selectedContractActiveUnits: contract.actives_fleets.count,
      selectedContractTotalUnits: contract.total_fleets,
    });
  };
  handleDeleteContract = () => {
    this.props.deleteCompanyContract(this.state.selectedContractId).then(() => {
      // if (result) {
      this.showDeleteModal(false);
      this.handleFilter();
      this.props.showToast({
        text: `El contrato ha sido eliminado.`,
      });
      // }
    });
  };

  //ADD UNITS
  handleAddOption = (contract) => {
    this.setState({
      showAddUnitsModal: true,
      selectedContractClass: contract.transport_classes
        .map((t) => (t.transport_class || { code: "" }).code)
        .join(", "),
      selectedContractId: contract.id,
    });
  };
  handleCreateContractAddendum = () => {
    let dateFrom = this.state.contractDateFrom.split("/");
    let newDateFrom = dateFrom[2].concat("-", dateFrom[0], "-", dateFrom[1]);
    let newDateTo = null;
    if (this.state.contractDateTo) {
      let dateTo = this.state.contractDateTo.split("/");
      newDateTo = dateTo[2].concat("-", dateTo[0], "-", dateTo[1]);
    }

    this.props
      .createCompanyContractAddendum(
        this.state.selectedContractId,
        this.state.contractQuantity,
        newDateFrom,
        newDateTo
      )
      .then((result) => {
        if (result) {
          this.showAddUnitsModal(false);
          this.handleFilter();
          this.props.showToast({
            text: `Se añadieron con exito las unidades.`,
          });
        }
      });
  };

  //MODALS
  showNewContractModal = (show) => {
    if (!show) {
      this.setState({
        contractFacility: 0,
        contractNetwork: 0,
        contractTransportClass: "",
        contractOperationId: 0,
        contractQuantity: "",
        contractDateFrom: null,
        contractDateTo: null,
      });
    }
    this.setState({ showNewContractModal: show, contractDateFrom: new Date() });
  };
  showDeleteModal = (show) => {
    if (!show) {
      this.setState({
        selectedContractClass: "",
        selectedContractId: 0,
        selectedContractNetwork: "",
        selectedContractFacility: "",
        selectedContractActiveUnits: 0,
        selectedContractTotalUnits: 0,
      });
    }
    this.setState({ showDeleteModal: show });
  };
  // selectedContractClass: contract.transport_classes.map(t => (t.transport_class || {code: ''}).code).join(', '),
  // selectedContractId: contract.id,
  // selectedContractNetwork: network.name,
  // selectedContractFacility: contract.facilities.name,
  showAddClassModal = (show) => {
    if (!show) {
      this.setState({
        contractFacility: 0,
        contractNetwork: 0,
        contractTransportClass: "",
        contractQuantity: "",
        contractDateFrom: null,
        contractDateTo: null,
        selectedContractClass: "",
        selectedContractId: 0,
      });
    }
    this.setState({ showAddClassModal: show });
  };
  showAddUnitsModal = (show) => {
    if (!show) {
      this.setState({
        contractFacility: 0,
        contractNetwork: 0,
        contractTransportClass: "",
        contractQuantity: "",
        contractDateFrom: null,
        contractDateTo: null,
        selectedContractClass: "",
        selectedContractId: 0,
      });
    }
    this.setState({ showAddUnitsModal: show });
  };
  showAddBaseModal = (show) => {
    if (!show) {
      this.setState({
        contractBase: 0,
        selectedContractId: 0,
      });
    }
    this.setState({ showAddBaseModal: show });
  };

  //FILTERS
  handleFilter = () => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ offset: 1, resetPaginator: true, buttonFilterName: "Restablecer" }, () => this.loadContracts() );
    } else {
      this.setState({ offset: 1, resetPaginator: true, buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      facilities: [],
      transportLine: [],
      transportClass: [],
    }, () => this.loadContracts() );
  }

  filtersChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleSearch = (name, value) => {
    this.setState({ searchContracts: value });
  };

  filterContents = () => {
    return [
      {
        title: "Instalación",
        items: this.props.facilities,
        name: "facilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title:
          this.props.ownCompanyType === 1 ? "Empresa" : "Línea transportista",
        items: this.props.networkCompanies,
        name: "transportLine",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Clases",
        items: this.props.expeditionClasses,
        name: "transportClass",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      // {
      //   title: "Estatus de unidades",
      //   items: [
      //     {
      //       id: 1,
      //       description: 'Ocupado vigente'
      //     },
      //     {
      //       id: 2,
      //       description: 'Ocupado vencido'
      //     },
      //     {
      //       id: 3,
      //       description: 'Descupado vigente'
      //     },
      //     {
      //       id: 4,
      //       description: 'Desocupado vencido'
      //     },
      //   ],
      //   name: "unitStatus",
      //   listType: FILTER_LIST_TYPES.checkListTextSearchAll
      // },
    ];
  };

  //PAGINATOR
  handlePaginator = () => {
    this.loadContracts();
  };

  getResponsePerm = (perm, type) => {
    return validateObjPerms(
      [
        {
          id: String(perm),
          perm: String(type),
        },
      ],
      true
    ) === true
      ? true
      : false;
  };

  //ROWS
  getRow = (contract) => {
    // if(!contract.transport_class)
    //   contract.transport_class = {};
    let LT = {};
    if (contract.network.from_companies.id === this.props.ownCompanyId) {
      LT.name = contract.network.to_companies.corporation_name;
      LT.alias = contract.network.to_companies_alias;
      LT.code = contract.network.to_companies_code;
      LT.logo = contract.network.to_companies.logo;
      LT.verification_status = contract.network.to_companies.verification_status;
    } else {
      LT.name = contract.network.from_companies.corporation_name;
      LT.alias = contract.network.from_companies_alias;
      LT.code = contract.network.from_companies_code;
      LT.logo = contract.network.from_companies.logo;
      LT.verification_status = contract.network.from_companies.verification_status;
    }
    // let classDetail =
    //   this.props.expeditionClassesDetail.filter(
    //     (eClass) =>  contract.transport_classes.map(t => (t.transport_class || {id: ''}).id).toString().includes(eClass.id)
    //   ) || {};
    return (
      <div className="row-content">
        {this.props.ownCompanyType === 2 ? (
          <React.Fragment>
            <div className="facility">
              <div className="logo">
                <div className="avatar">
                  <img
                    alt=""
                    src={getUrlsEnv().files.concat(
                      contract.facilities.avatar.image
                    )}
                  />
                </div>
              </div>
              <div className="credentials">
                <div className="principal">{contract.facilities.name}</div>
                <div className="alternative">
                  <div className="alias">
                    {contract.facilities.alias || "-"}
                  </div>
                  <div className="code">{contract.facilities.code || "-"}</div>
                </div>
              </div>
            </div>
            <div className="transport-line">
              <div className="logo">
                <ImageWithCheckMark
                  src={this.getAvatarUrl(LT.logo)}
                  width={48}
                  isCheckMark={LT.verification_status === 3}
                />
              </div>
              <div className="credentials">
                <div className="principal">{LT.name}</div>
                <div className="alternative">
                  <div className="alias">{LT.alias || "-"}</div>
                  <div className="code">{LT.code || "-"}</div>
                </div>
              </div>
            </div>

            <div className="transport-class">
              {contract.transport_classes.map((tC, index, arr) => {
                // let classDetail =
                //   this.props.expeditionClassesDetail.find(
                //     (eClass) => eClass.id === tC.transport_class.id
                //   ) || {};
                return (
                  <React.Fragment key={tC.transport_class.id}>
                    <span
                      data-tip
                      data-for={"tooltip-class-".concat(
                        contract.id,
                        "-",
                        tC.transport_class.id
                      )}
                    >
                      {(arr[index - 1] ? ", " : "").concat(
                        tC.transport_class.code
                      )}
                    </span>
                    <ReactTooltip
                      id={"tooltip-class-".concat(
                        contract.id,
                        "-",
                        tC.transport_class.id
                      )}
                      clickable={true}
                      delayHide={50}
                      delayShow={50}
                      delayUpdate={200}
                      place={"bottom"}
                      border={true}
                      type={"light"}
                    >
                      <div className="class-info">
                        {this.props.expeditionClassesDetail
                          .filter((ex) => ex.id === tC.transport_class.id)
                          .map((ex) => (
                            <React.Fragment>
                              <div>{ex.rutas_tipo_vehiculo.configuracion}</div>
                              <div>{`${ex.fleet_class_vehicle.description} ${ex.vehicle_type.description}`}</div>

                              {ex.fleet_class_equipment &&
                                ex.equipment_type && (
                                  <div>{`${
                                    ex.fleet_class_equipment.upload_description.split(
                                      "-"
                                    )[0]
                                  } ${ex.equipment_type.description.substr(
                                    2
                                  )} ${
                                    ex.fleet_class_equipment.description
                                  }`}</div>
                                )}
                              {ex.fleet_class_equipment3 &&
                                ex.equipment_type3 && (
                                  <div>{`${
                                    ex.fleet_class_equipment3.upload_description.split(
                                      "-"
                                    )[0]
                                  } ${ex.equipment_type3.description} tipo ${
                                    ex.equipment_type3.description.split(" ")[1]
                                  }`}</div>
                                )}
                              {/* <div>{"D2 Dolly 2 ejes tipo H"}</div> */}
                              {ex.fleet_class_equipment2 &&
                                ex.equipment_type2 && (
                                  <div>{`${
                                    ex.fleet_class_equipment2.upload_description.split(
                                      "-"
                                    )[0]
                                  } ${ex.equipment_type2.description.substr(
                                    2
                                  )} ${
                                    ex.fleet_class_equipment2.description
                                  }`}</div>
                                )}
                            </React.Fragment>
                          ))}
                      </div>
                    </ReactTooltip>
                  </React.Fragment>
                );
              })}
            </div>

            <div className="units">
              <span
                data-tip
                data-for={"tooltip-expired-units-".concat(contract.id)}
              >
                {contract.actives_fleets.count}/{contract.total_fleets}
              </span>
            </div>
            <div style={{ width: "15%" }}>
              {
                contract.tag &&
                <Tag 
                  // title={"operacion"} 
                  id={contract.tag.id}
                  color={contract.tag.color}
                  title={contract.tag.title}
                  type={contract.tag.type}
                />
              }
            </div>
            <ReactTooltip
              id={"tooltip-expired-units-".concat(contract.id)}
              className={
                contract.actives_fleets.expiration.expired.count ||
                contract.actives_fleets.expiration.near.count
                  ? ""
                  : "hidden"
              }
              clickable={true}
              delayHide={50}
              delayShow={50}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
            >
              {contract.actives_fleets.expiration.expired.count ? (
                <div className="expired">
                  <div className="count">
                    {contract.actives_fleets.expiration.expired.count}{" "}
                    unidad(es)
                  </div>
                  <div className="date">
                    vencida(s) desde{" "}
                    {contract.actives_fleets.expiration.expired.date}
                  </div>
                </div>
              ) : (
                ""
              )}
              {contract.actives_fleets.expiration.near.count ? (
                <div className="near-expiration">
                  <div className="count">
                    {contract.actives_fleets.expiration.near.count} unidad(es)
                  </div>
                  <div className="date">
                    vence(n) el {contract.actives_fleets.expiration.near.date}
                  </div>
                </div>
              ) : (
                ""
              )}
            </ReactTooltip>
            {(this.getResponsePerm("53", "u") ||
              this.getResponsePerm("53", "d")) && (
              <>
                <div
                  className="actions"
                  data-tip
                  data-for={"tooltip-".concat(contract.id)}
                >
                  <div className="ellipsis">
                    <b></b>
                  </div>
                </div>
                <ReactTooltip
                  id={"tooltip-".concat(contract.id)}
                  clickable={true}
                  event="click"
                  globalEventOff="click"
                  delayHide={200}
                  delayShow={200}
                  delayUpdate={200}
                  place={"left"}
                  border={true}
                  type={"light"}
                >
                  {this.getResponsePerm("53", "u") && (
                    <div
                      className="tooltip-option"
                      id={"tooltip-action".concat(contract.id)}
                      onClick={() => {
                        this.handleAddOption(contract);
                      }}
                    >
                      Añadir unidades
                    </div>
                  )}
                  {this.getResponsePerm("53", "d") && (
                    <div
                      className="tooltip-option"
                      id={"tooltip-action-delete-".concat(contract.id)}
                      onClick={() => {
                        this.handleDeleteOption(contract, LT);
                      }}
                    >
                      Eliminar contrato
                    </div>
                  )}
                </ReactTooltip>
              </>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.props.ownCompanyType === 1 ? (
              <div className="transport-line">
                <div className="logo">
                  <ImageWithCheckMark
                    src={this.getAvatarUrl(LT.logo)}
                    width={48}
                    isCheckMark={LT.verification_status === 3}
                  />
                </div>
                <div className="credentials">
                  <div className="principal">{LT.name}</div>
                  <div className="alternative">
                    <div className="alias">{LT.alias || "-"}</div>
                    <div className="code">{LT.code || "-"}</div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="facility">
              <div className="logo">
                <div className="avatar">
                  <img
                    alt=""
                    src={getUrlsEnv().files.concat(
                      contract.facilities.avatar.image
                    )}
                  />
                </div>
              </div>
              <div className="credentials">
                <div className="principal">{contract.facilities.name}</div>
                <div className="alternative">
                  {this.props.ownCompanyType === 2 && (
                    <React.Fragment>
                      <div className="alias">
                        {contract.facilities.alias || "-"}
                      </div>
                      <div className="code">
                        {contract.facilities.code || "-"}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            {this.props.ownCompanyType !== 1 ? (
              <div className="transport-line">
                <div className="logo">
                  <div
                    className={"avatar ".concat()}
                    style={{
                      backgroundImage: "url(".concat(
                        this.getAvatarUrl(LT.logo),
                        ")"
                      ),
                    }}
                  ></div>
                </div>
                <div className="credentials">
                  <div className="principal">{LT.name}</div>
                  <div className="alternative">
                    <div className="alias">{LT.alias || "-"}</div>
                    <div className="code">{LT.code || "-"}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="base">
                {contract.tag ? (
                  <Tag
                    key={contract.tag.id}
                    id={contract.tag.id}
                    color={contract.tag.color}
                    title={contract.tag.title}
                    type={contract.tag.type}
                    // isFull={this.state.contractBase === contract.tag.id}
                    // onClick={this.handleTagClick}
                    // parentBackground='dark'
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="transport-class">
              {contract.transport_classes.map((tC, index, arr) => {
                const { transport_class: transportClass } = tC;
                // let classDetail =
                //   this.props.expeditionClassesDetail.find(
                //     (eClass) => eClass.id === tC.transport_class.id
                //   ) || {};
                return (
                  <React.Fragment key={tC.transport_class.id}>
                    <span
                      data-tip
                      data-for={"tooltip-class-".concat(
                        contract.id,
                        "-",
                        tC.transport_class.id
                      )}
                    >
                      {(arr[index - 1] ? ", " : "").concat(
                        tC.transport_class.code
                      )}
                    </span>
                    <ReactTooltip
                      id={"tooltip-class-".concat(
                        contract.id,
                        "-",
                        tC.transport_class.id
                      )}
                      clickable={true}
                      delayHide={50}
                      delayShow={50}
                      delayUpdate={200}
                      place={"bottom"}
                      border={true}
                      type={"light"}
                    >
                      <div className="class-info">
                        {transportClass && (
                          <React.Fragment>
                            <div>
                              {
                                this.props.nomenclatures.find(
                                  (n) =>
                                    n.id === transportClass.rutas_tipo_vehiculo
                                ).configuracion
                              }
                            </div>
                            <div>{`${this.props.fleetClasses
                              .find(
                                (f) =>
                                  f.id === transportClass.fleet_class_vehicle
                              )
                              .description.substring(
                                0,
                                2
                              )} ${(this.props.vehicleTypes.find(
                                (f) => f.id === transportClass.vehicle_type
                              ) || {}).description || ""}`}</div>
                            {/* 1st Equipment */}
                            {transportClass.equipment_type && (
                              <div>
                                {`${this.props.fleetClasses
                                  .find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment
                                  )
                                  .upload_description.substr(
                                    0,
                                    2
                                  )} ${this.props.equipmentSubTypes
                                  .find(
                                    (f) =>
                                      f.id === transportClass.equipment_type
                                  )
                                  .description.substr(2)} ${
                                  this.props.fleetClasses.find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment
                                  ).description
                                }`}
                              </div>
                            )}
                            {/* Dolly */}
                            {transportClass.equipment_type3 && (
                              <div>
                                {`${this.props.fleetClasses
                                  .find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment3
                                  )
                                  .upload_description.substr(0, 2)} ${
                                  this.props.fleetClasses.find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment3
                                  ).description
                                } tipo ${
                                  this.props.equipmentSubTypes
                                    .find(
                                      (f) =>
                                        f.id === transportClass.equipment_type3
                                    )
                                    .description.split(" ")[1]
                                }`}
                              </div>
                            )}
                            {/* 2nd Equipment */}
                            {transportClass.equipment_type2 && (
                              <div>
                                {`${this.props.fleetClasses
                                  .find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment2
                                  )
                                  .upload_description.substr(
                                    0,
                                    2
                                  )} ${this.props.equipmentSubTypes
                                  .find(
                                    (f) =>
                                      f.id === transportClass.equipment_type2
                                  )
                                  .description.substr(2)} ${
                                  this.props.fleetClasses.find(
                                    (f) =>
                                      f.id ===
                                      transportClass.fleet_class_equipment2
                                  ).description
                                }`}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </ReactTooltip>
                  </React.Fragment>
                );
              })}
            </div>
            {this.props.ownCompanyType === 1 && (
              <div className="base">
                {contract.base ? (
                  // <span>{contract.base.title}</span>
                  <Tag
                    key={contract.base.id}
                    id={contract.base.id}
                    color={contract.base.color}
                    title={contract.base.title}
                    type={contract.base.type}
                    // isFull={this.state.contractBase === tag.id}
                    // onClick={this.handleTagClick}
                    // parentBackground='dark'
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="units">
              <span
                data-tip
                data-for={"tooltip-expired-units-".concat(contract.id)}
              >
                {contract.actives_fleets.count}/{contract.total_fleets}
              </span>
            </div>
            <ReactTooltip
              id={"tooltip-expired-units-".concat(contract.id)}
              className={
                contract.actives_fleets.expiration.expired.count ||
                contract.actives_fleets.expiration.near.count
                  ? ""
                  : "hidden"
              }
              clickable={true}
              delayHide={50}
              delayShow={50}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
            >
              {contract.actives_fleets.expiration.expired.count ? (
                <div className="expired">
                  <div className="count">
                    {contract.actives_fleets.expiration.expired.count}{" "}
                    unidad(es)
                  </div>
                  <div className="date">
                    vencida(s) desde{" "}
                    {contract.actives_fleets.expiration.expired.date}
                  </div>
                </div>
              ) : (
                ""
              )}
              {contract.actives_fleets.expiration.near.count ? (
                <div className="near-expiration">
                  <div className="count">
                    {contract.actives_fleets.expiration.near.count} unidad(es)
                  </div>
                  <div className="date">
                    vence(n) el {contract.actives_fleets.expiration.near.date}
                  </div>
                </div>
              ) : (
                ""
              )}
            </ReactTooltip>
            {this.getResponsePerm("54", "u") &&
              (this.props.ownCompanyType === 1 ? (
                <>
                  <div
                    className="actions"
                    data-tip
                    data-for={"tooltip-".concat(contract.id)}
                  >
                    <div className="ellipsis">
                      <b></b>
                    </div>
                  </div>
                  <ReactTooltip
                    id={"tooltip-".concat(contract.id)}
                    clickable={true}
                    event="click"
                    globalEventOff="click"
                    delayHide={200}
                    delayShow={200}
                    delayUpdate={200}
                    place={"left"}
                    border={true}
                    type={"light"}
                  >
                    <div
                      className="tooltip-option"
                      id={"tooltip-action".concat(contract.id)}
                      onClick={() => {
                        this.handleBaseOption(contract);
                      }}
                    >
                      Asignar base
                    </div>
                  </ReactTooltip>
                </>
              ) : (
                <>
                  <div
                    className="actions"
                    data-tip
                    data-for={"tooltip-".concat(contract.id)}
                  >
                    <div className="ellipsis">
                      <b></b>
                    </div>
                  </div>
                  <ReactTooltip
                    id={"tooltip-".concat(contract.id)}
                    clickable={true}
                    event="click"
                    globalEventOff="click"
                    delayHide={200}
                    delayShow={200}
                    delayUpdate={200}
                    place={"left"}
                    border={true}
                    type={"light"}
                  >
                    <div
                      className="tooltip-option"
                      id={"tooltip-action".concat(contract.id)}
                      onClick={() => {
                        this.handleAddOption(contract);
                      }}
                    >
                      Añadir unidades
                    </div>

                    <div
                      className="tooltip-option"
                      id={"tooltip-action-delete-".concat(contract.id)}
                      onClick={() => {
                        this.handleDeleteOption(contract, LT);
                      }}
                    >
                      Eliminar contrato
                    </div>
                  </ReactTooltip>
                </>
              ))}
          </React.Fragment>
        )}
      </div>
    );
  };

  getAvatarUrl = (partialUrl) => {
    if (partialUrl) {
      return getUrlsEnv().files.concat(partialUrl);
    } else {
      return "/images/truck-bckgrnd.png";
    }
  };

  getData = () => {
    let searchStr = normalizeStr(this.state.searchStr);
    return this.props.contractList.filter(
      (f) =>
        normalizeStr(f.facilities.name).includes(searchStr) ||
        normalizeStr(f.facilities.code).includes(searchStr) ||
        normalizeStr(f.facilities.alias).includes(searchStr) ||
        normalizeStr(f.network.from_companies_alias).includes(searchStr) ||
        normalizeStr(f.network.from_companies.orbi_id).includes(searchStr) ||
        normalizeStr(f.network.from_companies.corporation_id).includes(
          searchStr
        ) ||
        normalizeStr(f.network.to_companies_alias).includes(searchStr) ||
        normalizeStr(f.network.to_companies.orbi_id).includes(searchStr) ||
        normalizeStr(f.network.to_companies.corporation_id).includes(
          searchStr
        ) ||
        normalizeStr(
          f.transport_classes
            .map((t) => (t.transport_class || { code: "" }).code)
            .toString()
        ).includes(searchStr) ||
        normalizeStr(f.assembleStrings.join()).includes(searchStr) ||
        normalizeStr(f.base?.title).includes(searchStr)
    );
  };

  redirectToProfile = (e, contract) => {
    if (!e.target.id || !e.target.id.includes("tooltip-action")) {
      contract.ownCompany = this.props.ownCompanyType;
      this.props.setCurrentContract(contract);
      this.props.history.push(
        `/company/controls/contract/detail/${contract.id}/`
      );
    }
  };

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent = () => {
    return (
      <div className="catalog">
        <ToastContainer className="global-toast-container" />
        <CatalogHeader
          title={"Contratos proveedores"}
          actionButtons={
            this.props.ownCompanyType === 2
              ? this.getResponsePerm("53", "c")
                ? [this.headerActionButtonSettings]
                : []
              : []
          }
          showViewMode={false}
        />
        {this.props.ownCompanyType === 2 ? (
          <TabSelectorRibbon
            items={contractTransportType}
            onChange={this.switchCompanyStatusType}
            activeId={this.state.contractTransportTypeItem.id}
          />
        ) : (
          <></>
        )}
        <FilterBar
          filterAction={this.handleFilter}
          searchStr={this.state.searchContracts}
          selectedItems={{
            facilities: this.state.facilities,
            transportLine: this.state.transportLine,
            transportClass: this.state.transportClass,
            unitStatus: this.state.unitStatus,
          }}
          onChange={this.filtersChange}
          searchButtonText={this.state.buttonFilterName}
          searchAction={this.handleSearch}
          content={this.filterContents()}
        />
        <div className="content ">
          <div className="catalog-row-header contracts-row-header">
            {this.props.ownCompanyType === 1 ? (
              <React.Fragment>
                <div className="trasport-line">Cliente</div>
                <div className="facility">Instalación</div>
                <div className="operation">Operación</div>
                <div className="transport-class">Clases</div>
                <div className="base">Base</div>
                <div className="units">Ocupados/Totales</div>
                <div className="actions"></div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div style={{ width: "25%" }}>Instalación</div>
                <div style={{ width: "25%" }}>Transportista</div>
                <div style={{ width: "25%" }}>Clases</div>
                <div style={{ width: "10%" }}>Ocupados/Totales</div>
                <div style={{ width: "15%" }}>Operaciones</div>
                <div className="actions"></div>
              </React.Fragment>
            )}
          </div>
          {this.getData().map((contract) => (
            <CatalogRow
              key={contract.id}
              defaultClass={"contract-row "}
              id={contract.id}
              onClick={(e) => this.redirectToProfile(e, contract)}
            >
              {this.getRow(contract)}
            </CatalogRow>
          ))}
          <PaginatorControlled
            limit={this.state.limit}
            offset={this.state.offset}
            itemCount={this.props.contractCount}
            onChange={(e) =>
              this.setState(
                { [e.target.name]: e.target.value },
                this.loadContracts
              )
            }
          />
          {/* <Paginator
            totalElements={this.props.contractCount}
            reload={this.handlePaginator}
            resetPaginator={this.state.resetPaginator}
            onReset={() => {
              this.setState({ resetPaginator: false });
            }}
          /> */}
        </div>
        <Modal
          isOpen={this.state.showNewContractModal}
          portalClassName="dialog contract-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Crear contrato</div>
          <div
            className="close"
            onClick={() => this.showNewContractModal(false)}
          ></div>
          <div className="message">
            {this.state.contractError ? (
              <div className="errorInPopup">
                Ya existe un contrato con esa instalación, linea transportista,
                <br />clase de transporte y etiqueta de operación.
              </div>
            ) : (
              ""
            )}

            <div>
              {/* <SelectInput
                label="Instalación"
                name="contractFacility"
                value={this.state.contractFacility}
                className="item"
                onChange={(e) => {
                  this.handleCreateInput(e);
                }}
                grouped={true}
                items={this.props.facilitiesByTag || []}
              /> */}
              <SelectInputStyled 
                selectedItems={this.state.contractFacility}
                items={this.props.facilitiesByTag || []}
                onChange={this.handleCreateInput}
                className={ ''}
                name={"contractFacility"}
                label={"Instalación"}
              />
            </div>
            <div>
              {/* <SelectInput
                label="Linea Transportista"
                name="contractNetwork"
                value={this.state.contractNetwork}
                className="item"
                onChange={(e) => {
                  this.handleCreateInput(e);
                }}
                items={this.props.networkCompanies || []}
              /> */}
              <SelectInputStyled 
                selectedItems={this.state.contractNetwork}
                items={this.props.networkCompanies || []}
                onChange={this.handleCreateInput}
                className={ ''}
                name={"contractNetwork"}
                label={"Linea Transportista"}
              />
            </div>
            <div>
              {/* <SelectMultipleInput
                title={"Clase de transporte"}
                items={this.props.expeditionClasses || []}
                onChange={this.handleCreateInput}
                name="contractTransportClass"
                className="item"
                value={this.state.contractTransportClass}
                showDescription={true}
              /> */}
              <div className="row" style={{marginTop: "20px"}}>
              <RadioInput
                  className="radio-input"
                  name="transportClassType"
                  title="Camíon unitario/Automóvil"
                  value="unitary"
                  selected={this.state.transportClassType}
                  onClick={(e) => {
                    const { name, value } = e.target;
                    this.setState({[name]: value})
                  }}
                />
                <p style={{marginLeft: '15px'}} />
                <RadioInput
                  className="radio-input"
                  name="transportClassType"
                  title="Tracto camión"
                  value="truck"
                  selected={this.state.transportClassType}
                  onClick={(e) => {
                    const { name, value } = e.target;
                    this.setState({[name]: value})
                  }}
                />
              </div>
              <SelectInputStyled 
                selectedItems={this.state.contractTransportClass}
                items={this.props.expeditionClasses.map((e) => ({
                  ...e,
                  items: e.items.filter(i => {
                  if(this.state.transportClassType === "unitary"){
                    return i.transportClassType === "unitary_flag"
                  } else {
                    return i.transportClassType === "single_flag" || i.transportClassType === "full_flag"
                  }
                  })
                })).filter(i => {
                  return i.items.length > 0
                }) || []}
                // items={this.props.expeditionClasses || []}
                onChange={this.handleCreateInput}
                className={ ''}
                name={"contractTransportClass"}
                label={"Clase de transporte"}
                multiple={true}
              />
            </div>
            <div>
              <ChipContainerStyled
                //  items: PropTypes.arrayOf(
                //    PropTypes.shape({
                //      id: PropTypes.number,
                //      color: PropTypes.string,
                //      title: PropTypes.string,
                //      isFull: PropTypes.boolean
                //    })
                //  ),
                background="light"
                // className="dark"
                //  title: PropTypes.string,
                //  name: PropTypes.string
                className="no-modal"
                onChange={(e) => {
                  this.setState({ contractOperationId: e.target.name });
                }}
                title="Etiquetas de operación (solo puedes seleccionar 1 opción)"
                items={this.props.operationTags.map((t, i) => ({
                  ...t,
                  isFull:
                    t.id === this.state.contractOperationId ? true : false,
                }))}
              />
            </div>
            <div className="contractQuantity">
              <span>Cantidad:</span>
              <TextInput
                label=""
                name="contractQuantity"
                value={this.state.contractQuantity}
                className="item"
                length={5}
                onChange={this.handleCreateInput}
              />
              <span>Camiones</span>
            </div>
            <div className="date">
              <TextInput
                type="date"
                format={"dd/MMM/yyyy"}
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() - 1
                  )
                }
                value={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                  )
                }
                label="Fecha de inicio"
                name="contractDateFrom"
                onChange={this.handleCreateInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format={"dd/MMM/yyyy"}
                value={this.state.contractDateTo}
                label="Fecha de fin"
                minDate={this.state.contractDateFrom}
                name="contractDateTo"
                onChange={this.handleCreateInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <Checkbox
                onChange={(e) => this.handleDateToChange(e)}
                item={{
                  id: 1,
                  description: "Indefinido",
                  active: this.state.contractDateTo === null ? true : false,
                }}
                name={"contractDateFromUndefined"}
                // className={(i.items || []).length ? 'bold' : ''}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showNewContractModal(false)}
            />
            <Button
              text="Crear"
              type="primary"
              disabled={
                !(
                  this.state.contractFacility &&
                  this.state.contractNetwork &&
                  this.state.contractTransportClass &&
                  this.state.contractQuantity &&
                  this.state.contractOperationId &&
                  this.state.contractDateFrom
                )
              }
              onClick={this.handleCreateContract}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showAddUnitsModal}
          portalClassName="dialog contract-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Añadir unidades</div>
          <div
            className="close"
            onClick={() => this.showAddUnitsModal(false)}
          ></div>
          <div className="message">
            <div className="add-class">
              Clase <b>{this.state.selectedContractClass}</b>
            </div>
            <div className="contractQuantity">
              <span>Cantidad:</span>
              <TextInput
                label=""
                name="contractQuantity"
                value={this.state.contractQuantity}
                className="item"
                onChange={this.handleCreateInput}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format={"dd/MMM/yyyy"}
                minDate={today}
                value={this.state.contractDateFrom}
                label="Fecha de inicio"
                name="contractDateFrom"
                onChange={this.handleCreateInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format={"dd/MMM/yyyy"}
                minDate={
                  this.state.contractDateFrom
                    ? new Date(this.state.contractDateFrom)
                    : today
                }
                value={this.state.contractDateTo}
                label="Fecha de fin"
                name="contractDateTo"
                onChange={this.handleCreateInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
              />
              <Checkbox
                onChange={(e) => this.handleDateToChange(e)}
                item={{
                  id: 1,
                  description: "Indefinido",
                  active: this.state.contractDateTo === null ? true : false,
                }}
                name={"contractDateFromUndefined"}
                // className={(i.items || []).length ? 'bold' : ''}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showAddUnitsModal(false)}
            />
            <Button
              text="Agregar"
              type="primary"
              disabled={
                !(this.state.contractQuantity && this.state.contractDateFrom)
              }
              onClick={() => this.handleCreateContractAddendum()}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showAddBaseModal}
          portalClassName="dialog contract-modal base-contract-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Asignar una base</div>
          <div
            className="close"
            onClick={() => this.showAddBaseModal(false)}
          ></div>
          <div className="message">
            <div className="tags-options-container">
              {this.props.fleetTags.map((tag) => {
                return (
                  <Tag
                    key={tag.id}
                    id={tag.id}
                    color={tag.color}
                    title={tag.title}
                    type={tag.type}
                    isFull={this.state.contractBase === tag.id}
                    onClick={this.handleTagClick}
                    parentBackground="light"
                  />
                );
              })}
            </div>
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showAddBaseModal(false)}
            />
            <Button
              text="Asignar"
              type="primary"
              disabled={!this.state.contractBase}
              onClick={() => this.handleAddContractBase()}
            />
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showDeleteModal}
          portalClassName="dialog contract-modal delete-contract-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">Eliminar contrato</div>
          <div
            className="close"
            onClick={() => this.showDeleteModal(false)}
          ></div>
          <div className="message">
            <div className="subtitle">
              ¿Estás seguro que quieres eliminar este contrato?
            </div>
            <div className="contract-detail">
              <div className="detail-row">
                <div className="detail-title">Instalación:</div>
                <div className="detail-content">
                  {this.state.selectedContractFacility}
                </div>
              </div>
              <div className="detail-row">
                <div className="detail-title">Línea transportista:</div>
                <div className="detail-content">
                  {this.state.selectedContractNetwork}
                </div>
              </div>
              <div className="detail-row">
                <div className="detail-title">Clase de transporte:</div>
                <div className="detail-content">
                  {this.state.selectedContractClass}
                </div>
              </div>
              <div className="detail-row">
                <div className="detail-title">Unidades:</div>
                <div className="detail-content">
                  {this.state.selectedContractTotalUnits}
                </div>
              </div>
            </div>
            {this.state.selectedContractActiveUnits > 0 ? (
              <div className="warning">Tiene slots asignados</div>
            ) : (
              <></>
            )}
          </div>
          <div className="actions">
            <Button
              text="Cancelar"
              type="primary outline"
              onClick={() => this.showDeleteModal(false)}
            />
            <Button
              text="Eliminar"
              type="secondary"
              disabled={this.state.selectedContractActiveUnits > 0}
              onClick={() => this.handleDeleteContract()}
            />
          </div>
        </Modal>
      </div>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(listFacilities());
  dispatch(loadTags());
  dispatch(listNetworkApprovedInvitations("", ""));
  dispatch(listExpeditionClasses());
  dispatch(listExpeditionClassesDetail());
  dispatch(readEntities("COMPANIES.CATALOGS.VEHICLETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.FLEETCLASSES"));
  dispatch(readEntities("COMMONS.CHOICES.EQUIPMENTTYPE"));
  dispatch(readEntities("RUTAS.TIPOVEHICULO"));

  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    setConfig: (config) => dispatch(setConfig("ContractCatalog", config)),
    setCurrentContract: (contract) => dispatch(setCurrentContract(contract)),
    // listContractClasses: (
    //   company_id,
    //   contract_id,
    //   opt
    // ) => {
    //   dispatch(
    //     readEntities(
    //       "COMPANIES.CONTRACTS.CLASSES",
    //       {
    //         company_id,
    //         contract_id
    //       },
    //       opt
    //     )
    //   )
    // },
    loadFilterFacilities: (company_id) => {
      dispatch(
        readEntities("COMPANIES.CONTRACTS.FILTERS.FACILITIES", {
          company_id,
        })
      );
    },
    loadFilterClasses: (company_id) => {
      dispatch(
        readEntities("COMPANIES.CONTRACTS.FILTERS.CLASSES", {
          company_id,
        })
      );
    },
    reloadContracts: (
      company_id,
      limit,
      offset,
      facilities,
      transportClass,
      transportLine,
      search,
      opt
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.CONTRACTS",
          {
            company_id,
            limit,
            offset,
            facilities,
            transportClass,
            network: transportLine,
            search
          },
          opt
        )
      );
    },
    // reloadContracts: (
    //   limit = 10,
    //   offset = 1,
    //   facilities,
    //   transportClass,
    //   transportLine
    // ) =>
    //   dispatch(
    //     listCompanyContracts(
    //       limit,
    //       offset,
    //       facilities,
    //       transportClass,
    //       transportLine
    //     )
    //   ),
    createCompanyContract: (
      facility,
      network,
      transport_class,
      operation_id,
      quantity,
      date_from,
      date_to
    ) =>
      dispatch(
        createCompanyContract(
          facility,
          network,
          transport_class,
          operation_id,
          quantity,
          date_from,
          date_to
        )
      ),
    createCompanyContractAddendum: (contract, quantity, date_from, date_to) =>
      dispatch(
        createCompanyContractAddendum(contract, quantity, date_from, date_to)
      ),
    createCompanyContractClass: (contract, class_id, date_from, date_to) =>
      dispatch(
        createCompanyContractClass(contract, class_id, date_from, date_to)
      ),
    deleteCompanyContract: (contract) =>
      dispatch(deleteCompanyContract(contract)),
    setCompanyContractBase: (contract, base) =>
      dispatch(setCompanyContractBase(contract, base)),
    showToast: (payload) => dispatch(addToast(payload)),
    removeToast: () => dispatch(removeToast()),
    getUserTags: (company_id, user_id) =>
      dispatch(
        readEntities("COMPANIES.USERS.USERSTAGS", { company_id, user_id })
      ),
  };
};

const mapStateToProps = (state) => {
  const vehicleTypes = getEntityItems(state, "COMPANIES.CATALOGS.VEHICLETYPES");
  const fleetClasses = getEntityItems(state, "COMPANIES.CATALOGS.FLEETCLASSES");
  const equipmentSubTypes = getEntityItems(
    state,
    "COMMONS.CHOICES.EQUIPMENTTYPE"
  );
  const nomenclatures = getEntityItems(state, "RUTAS.TIPOVEHICULO");

  let ownCompanyId = state.globalCatalog.session.company.id;
  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;
  let oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;
  let networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        if (network.from_companies.id === ownCompanyId) {
          if (network.to_companies.type === oppositeCompanyType)
            result.push({
              id: network.id,
              // description: network.to_companies.corporation_name,
              description: network.to_companies.orbi_id.concat(' - ', network.to_companies_alias, ' - ', network.to_companies_code),
            });
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              id: network.id,
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(' - ', network.from_companies_alias, ' - ', network.from_companies_code),
            });
        }
        return result;
      },
      []
    );
  networkCompanies = networkCompanies.sort(function(a, b) {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  const contractListItems =
    getEntityItems(state, "COMPANIES.CONTRACTS").results || [];
  const contractList = contractListItems.map((item) => ({
    ...item,
    assembleStrings: item.assemblies
      .map((assemble) =>
        assemble.assembly.equipment
          .map((eq) => [eq.economic_number, eq.plates])
          .concat(
            assemble.assembly.operator.map((op) => [
              op.first_name,
              op.last_name,
            ]),
            assemble.assembly.vehicle.map((ve) => [
              ve.economic_number,
              ve.plates,
            ])
          )
      )
      .flat(3),
  }));

  const userId = state.globalCatalog.session.user.id;
  const userType = state.globalCatalog.session.company.companyuser.type;
  const userTags = getEntityItems(state, "COMPANIES.USERS.USERSTAGS");
  const operationTags = (state.tags.tags || []).filter(
    (tag) =>
      tag.type ===
      (
        (state.tags.tagTypes.data || []).find(
          (type) => type.description.toLowerCase() === "operaciones"
        ) || { id: 0 }
      ).id
  );
  const fleetTags = (state.tags.tags || [])
    .filter(
      (tag) =>
        tag.type ===
        (
          (state.tags.tagTypes.data || []).find(
            (type) => type.description.toLowerCase() === "flotilla"
          ) || { id: 0 }
        ).id
    )
    .filter((tag) => {
      if (userType.toLowerCase() === "owner") return true;
      else return userTags.find((ut) => ut.tags === tag.id) ? true : false;
    });

  const facilities =
    ownCompanyType !== 1
      ? state.facilityReducer.facilities.data &&
        state.facilityReducer.facilities.data
          .map((f) => ({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
            name: f.name,
            code: f.code,
            alias: f.alias,
          }))
          .sort(function(a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
      : (getEntityItems(state, "COMPANIES.CONTRACTS.FILTERS.FACILITIES") || [])
          .map((f) => ({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
            name: f.name,
            code: f.code,
            alias: f.alias,
          }))
          .sort(function(a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });

  let facilitiesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (
    (ownCompanyType !== 1
      ? state.facilityReducer.facilities.data
      : getEntityItems(state, "COMPANIES.CONTRACTS.FILTERS.FACILITIES")) || []
  ).forEach((f) => {
    if ((f.tags || []).length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesByTag[tagIndex].items.push({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            items: [
              {
                id: f.id,
                description: f.name.concat(" - ", f.code, " - ", f.alias),
              },
            ],
          };
          facilitiesByTag.push(newTag);
        }
      });
    } else {
      facilitiesByTag[0].items.push({
        id: f.id,
        description: f.name.concat(" - ", f.code, " - ", f.alias),
      });
    }
  });
  facilitiesByTag = facilitiesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  let tagless = facilitiesByTag.shift();
  facilitiesByTag = facilitiesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  facilitiesByTag.push(tagless);

////////

  let classesByTag = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (
    (ownCompanyType !== 1
      ? state.companyReducer.companyExpeditionClasses.data
      : getEntityItems(state, "COMPANIES.CONTRACTS.FILTERS.CLASSES")) || []
  ).forEach((c) => {
    if ((c.tags || []).length) {
      c.tags.forEach((t) => {
        let tagIndex = classesByTag.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          classesByTag[tagIndex].items.push({
            id: c.id,
            description: c.code,
            transportClassType: c.transport_class_type,
          });
        } else {
          let newTag = {
            id: t.id,
            description: ((state.tags.tags || []).find(tag => tag.id === t.id) || {title: '-'}).title,
            transportClassType: c.transport_class_type,
            items: [
              {
                id: c.id,
                description: c.code,
                transportClassType: c.transport_class_type,
              },
            ],
          };
          classesByTag.push(newTag);
        }
      });
    } else {
      if(c.tag){
        let tagIndex = classesByTag.map((fT) => fT.id).indexOf(c.id);
        if (tagIndex !== -1) {
          classesByTag[tagIndex].items.push({
            id: c.id,
            description: c.code,
            transportClassType: c.transport_class_type,
          });
        } else {
          let newTag = {
            id: c.id,
            description: c.tag.title,
            transportClassType: c.transport_class_type,
            items: [
              {
                id: c.id,
                description: c.code,
                transportClassType: c.transport_class_type,
              },
            ],
          };
          classesByTag.push(newTag);
        }
      } else {
        let newTag = {
          id: c.id,
          description: c.code,
          transportClassType: c.transport_class_type,
        };
        classesByTag[0].items.push(newTag);
      }
    }
  });
  classesByTag = classesByTag.map((tag) => {
    let sortedItems = tag.items.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    return { ...tag, items: sortedItems };
  });
  let taglessClasses = classesByTag.shift();
  classesByTag = classesByTag.sort((a, b) => {
    if (a.description.toLowerCase() > b.description.toLowerCase()) {
      return 1;
    }
    if (a.description.toLowerCase() < b.description.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  classesByTag.push(taglessClasses);
/////////

  const configuration = state.configuration.ContractCatalog;
  return {
    configuration,
    vehicleTypes,
    fleetClasses,
    equipmentSubTypes,
    nomenclatures,
    toast: state.globalCatalog.toast,
    userId,
    operationTags,
    userTags,
    fleetTags,
    ownCompanyId,
    userType,
    ownCompanyType,
    contractList,
    contractCount: getEntityItems(state, "COMPANIES.CONTRACTS").count || 0,
    // contractList: state.companyReducer.companyContracts.data.results || [],
    // contractCount: state.companyReducer.companyContracts.data.count || 0,

    facilities,
    facilitiesByTag,
    networkCompanies: networkCompanies || [],
    expeditionClasses: classesByTag,
      // ownCompanyType !== 1
      //   ? (
      //       state.companyReducer.companyExpeditionClasses.data || []
      //     ).map((e) => ({ ...e, description: e.code }))
      //   : (
      //       getEntityItems(state, "COMPANIES.CONTRACTS.FILTERS.CLASSES") || []
      //     ).map((e) => ({ ...e, description: e.code })),
    expeditionClassesDetail:
      state.companyReducer.companyExpeditionClassesDetail.data,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(ContractCatalogView);

ContractCatalogView.defaultProps = {
  contractList: [],
  contractCount: 0,
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const ChipContainerStyled = styled(ChipContainer)`
  cursor: pointer;
  & .title {
    margin-top: 10px !important;
    font-size: 16px !important;

    margin-left: 0 !important;
    color: #20293d !important;

    font-weight: 200 !important;
    line-height: 25px !important;
  }
`;

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;