//@flow
import * as React from "react";
import Button from "../../shared/controls/button";
import Modal from "react-modal";

import Tag from "../../shared/controls/tag";

import { ToastContainer } from "react-toastify";

type Props = {};

const EditMultipleExpeditionClassModal = ({
  show,
  manageModal,
  tags,
  selectedTag,
  onChange,
}: Props) => {
  return (
    <Modal
      isOpen={show}
      portalClassName="dialog emails-modal expedition-class-modal"
      overlayClassName="overlay"
      className="content"
    >
      <ToastContainer className="" />
      <div className="title">Editar clases de expedición</div>
      <div
        className="close"
        onClick={() =>
          manageModal({ target: { name: "close", value: "close" } })
        }
      ></div>
      <div className="message">
        <div>
          <span className="section-title">Etiqueta de clase de expedición</span>
          <div className="tags">
            {tags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  id={tag.id}
                  color={tag.color}
                  title={tag.title}
                  type={tag.type}
                  isFull={selectedTag === tag.id}
                  onClick={(e) =>
                    onChange({
                      target: {
                        name: "selectModalTag",
                        value: tag.id,
                      },
                    })
                  }
                  parentBackground="light"
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="actions">
        <Button
          text="Cancelar"
          type="primary outline"
          onClick={() =>
            manageModal({ target: { name: "cancel", value: "cancel" } })
          }
        />

        <Button
          text="Guardar"
          type="primary"
          onClick={() => manageModal({ target: { name: "update", value: "" } })}
          //   disabled={!isValidForm()}
          // dataTip={getErrors()}
          // dataFor="orbi-tooltip-error"
          //   onMouseLeave={() => handleLeave()}
        />
        {/* <ReactTooltip
        id={"orbi-tooltip-error"}
        place="top"
        type="light"
        effect="float"
        html={true}
        event="mouseover"
        eventOff="mouseleave"
      /> */}
      </div>
    </Modal>
  );
};

export default EditMultipleExpeditionClassModal;
