// @flow
import React from "react";
import styled from "styled-components";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import SVGIcon, { icons } from "../../shared/svg-icon";
import es from "date-fns/locale/es";
registerLocale("es", es);

type Props = {
  name: string,
  date: Date,
  onChange: Function,
  dateFormat?: string,
  locale?: string,
};

const WeekPicker = ({
  name,
  date,
  onChange,
  dateFormat = "MMMM",
  locale = "",
}: Props) => {
  const WeekPickerInput = ({ value, onClick }) => (
    <WeekPickerStyled onClick={onClick}>
      {value}
      <SVGIcon name={icons.calendar} fill="#79A3FF" />
    </WeekPickerStyled>
  );
  return (
    <ReactDatePicker
      locale={locale}
      selected={date}
      onChange={(d) => onChange({ target: { name, value: d } })}
      showWeekNumbers
      dateFormat={dateFormat}
      customInput={<WeekPickerInput />}
    />
  );
};

export default WeekPicker;

const WeekPickerStyled = styled.div`
  width: 140px;
  height: 42px;
  display: flex;
  align-items: center;
  background-color: #2f3b52;
  border-radius: 4px;
  opacity: 0.6;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  justify-content: space-around;
  text-transform: capitalize;
`;

// const MONTHS = {
//   1: "ENERO",
//   2: "FEBRERO",
//   3: "MARZO",
//   4: "ABRIL",
//   5: "MAYO",
//   6: "JUNIO",
//   7: "JULIO",
//   8: "AGOSTO",
//   9: "SEPTIEMBRE",
//   10: "OCTUBRE",
//   11: "NOVIEMBRE",
//   12: "DICIEMBRE",
// };
