// @flow
import React from "react";
import { TableCollapsible } from "./styled";
import AssembleTableDataRow from "./assemble-table-data-row";
import { Flex } from "../../../shared/styled/flex";

type Props = {
  step: number,
  onlyOne: boolean,
  tableClassification: Array<{
    id: number,
    title: string,
    icon: any,
    description: any,
  }>,
  headers: Array<any>,
  data: Array<{
    id: number,
    number: String,
    plates: String,
    tags: Array<any>,
    from: string,
    to: string,
    status: any,
    selected: boolean,
    class: number,
  }>,
  onChange: Function,
  initialStateCollapsed: boolean,
};

const AssembleTableDataRowBlock = ({
  tableClassification,
  step,
  headers,
  data,
  onChange,
  onlyOne,
  initialStateCollapsed,
}: Props) => {
  return tableClassification.map(
    (classification: { id: number, icon: any, description: string }) => (
      <TableCollapsible
        initialStateCollapsed={initialStateCollapsed}
        key={classification.id}
        headStyle={{
          backgroundColor: "rgb(244,246,248)",
        }}
        contentStyle={{ display: "flex", flexDirection: "column" }}
        title={
          <Flex>
            {classification.icon}
            {classification.description}
          </Flex>
        }
      >
        {data
          .filter((dt: { id: number }) => dt.class === classification.id)
          .map((dt: { id: number }) => (
            <AssembleTableDataRow
              key={dt.id}
              onChange={onChange}
              headers={headers}
              data={dt}
              onlyOne={onlyOne}
              step={step}
              active={dt.active}
            />
          ))}
      </TableCollapsible>
    )
  );
};

export default AssembleTableDataRowBlock;
