import React, { Component } from "react";
import PropTypes from "prop-types";
// import RadioButtons from '../../controls/radio';
import SVGIcon, { figures } from "../../svg-icon";

export const units = {
  systems: ["METRIC", "USA"],
  unitTypes: {
    length: "length",
    weight: "weight",
    load: "load",
  },
  length: {
    METRIC: "m",
    USA: "ft",
  },
  weight: {
    METRIC: "tons",
    USA: "lb",
  },
  load: {
    METRIC: "ton",
    USA: "ton",
  },
  loadCapacity: {
    METRIC: "N-m",
    USA: "lb-ft",
  },
};

const TYPES = {
  VEHICLE: {
    front: figures.vehicleFront,
    side: figures.vehicleSide,
  },
  EQUIPMENT: {
    front: figures.equipmentFront,
    side: figures.equipmentSide,
  },
};

class FleetSpecificationCompactVehicle extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    length: PropTypes.string,
    weight: PropTypes.string,
    singleMaxLoad: PropTypes.string,
    doubleMaxLoad: PropTypes.string,
    unitSystem: PropTypes.oneOf(units.systems),
  };
  static defaultProps = {
    className: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    singleMaxLoad: "",
    doubleMaxLoad: "",
    unitSystem: units.systems[0],
  };

  render() {
    return (
      <div className="specification-card compact">
        <div className="container">
          <div className="title">{this.props.title}</div>
          <div className="content">
            <div className="column">
              <div className="rows">
                <div>
                  <SVGIcon
                    name={TYPES[this.props.type].side}
                    fill="none"
                    stroke="#D5DEEC"
                  />
                  <div className="line-container-horizontal">
                    <div className="line-vertical"></div>
                    <div className="line-horizontal"></div>
                    <div className="line-vertical"></div>
                  </div>
                </div>
                <div
                  className="line-container-vertical"
                  style={{ marginLeft: "10px" }}
                >
                  <div className="line-horizontal"></div>
                  <div className="line-vertical"></div>
                  <div
                    className="line-horizontal"
                    style={{ marginBottom: "10px" }}
                  ></div>
                </div>
              </div>
              <div className="measures">
                {this.getMeasures(this.props.length, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="measures" style={{ marginTop: "100%" }}>
                {this.getMeasures(this.props.height, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="rows">
                <div
                  className="line-container-vertical"
                  style={{ marginRight: "10px" }}
                >
                  <div className="line-horizontal"></div>
                  <div className="line-vertical"></div>
                  <div
                    className="line-horizontal"
                    style={{ marginBottom: "10px" }}
                  ></div>
                </div>
                <div>
                  <SVGIcon
                    name={TYPES[this.props.type].front}
                    fill="none"
                    stroke="#D5DEEC"
                    viewBox="14 0 90 84"
                  />
                  <div className="line-container-horizontal">
                    <div className="line-vertical"></div>
                    <div className="line-horizontal"></div>
                    <div className="line-vertical"></div>
                  </div>
                </div>
              </div>
              <div className="measures">
                {this.getMeasures(this.props.width, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="measure-input">
                <label>Altura</label>
                <div className="input-block">
                  <label>
                    {"".concat(
                      this.props.height,
                      " ",
                      this.getUnitType(units.unitTypes.length)
                    )}
                  </label>
                </div>
              </div>
              <div className="measure-input">
                <label>Anchura</label>
                <div className="input-block">
                  <label>
                    {"".concat(
                      this.props.width,
                      " ",
                      this.getUnitType(units.unitTypes.length)
                    )}
                  </label>
                </div>
              </div>
              <div className="measure-input">
                <label>Largo</label>
                <div className="input-block">
                  <label>
                    {"".concat(
                      this.props.length,
                      " ",
                      this.getUnitType(units.unitTypes.length)
                    )}
                  </label>
                </div>
              </div>
              <div className="measure-input">
                <label>Peso</label>
                <div className="input-block">
                  <label>
                    {"".concat(
                      this.props.weight,
                      " ",
                      this.getUnitType(units.unitTypes.weight)
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getMeasures = (value, unitType) => {
    value = value > 0 ? value : "??";
    unitType = this.getUnitType(unitType);
    return value.toString().concat(" ", unitType);
  };

  getUnitType = (unitType) => {
    return units[unitType][this.props.unitSystem];
  };
}

export default FleetSpecificationCompactVehicle;
