import * as React from "react";
import BaseDialog from "../../shared/dialog/base-dialog";
// import { API_REQUEST_STRATEGY_OPTIONS } from "../../redux/api/helpers";
import { SaveItemCounter } from "../../redux/api/save-item-counter";
import { selectModalContent } from "./assemble-utils";
import Loader from "../../shared/icons/tat/loader";

type State = {
  step: number,
  selectedDate: Date,
  vehiclesData: Array<any>,
  equipmentsData: Array<any>,
  operators: Array<any>,
  modalSearchText: string,
};
type Props = {
  showCreateModal: boolean,
  onChange: Function,
  onSearch: Function,
  listFleetResourceCatalog: () => void,
  createAssemble: () => void,
  createAssembleVehicle: () => void,
  createAssembleEquipment: () => void,
  createAssembleOperator: () => void,

  companyId: number,
  onlyOne: boolean,
  fleetVehicleTypesClasses: any,
  operatorLicenseTypes: any,
  unasembledVehicles: any,
  unasembledEquipments: any,
  unasembledOperators: any,
  companyTags: any,
  companyFleets: any,
};

class CreateAssemble extends React.Component<Props, State> {
  static defaultProps = {
    showCreateModal: false,
    manageError: () => {},
  };
  state = {
    step: 1,
    vehiclesData: [],
    equipmentsData: [],
    operators: [],
    modalSearchText: "",
    loading: false,
  };
  modalOpeningDate = Date.parse(new Date());

  componentDidMount() {
    this.manageCreateModal({ target: { name: "newAssemble", value: "" } });
  }

  render() {
    const { showCreateModal } = this.props;
    const { step } = this.state;
    return (
      <BaseDialog
        open={showCreateModal}
        className="create-assemble-dialog"
        closeAction={this.manageCreateModal}
        acceptAction={() =>
          this.manageCreateModal({
            target: { name: "next", value: "next" },
          })
        }
        title="Creación de ensambles"
        contentObject={selectModalContent({
          ...this.state,
          ...this.props,
          onSearch: this.handleSearch,
          modalOpeningDate: this.modalOpeningDate,
          handleChange: this.handleChange,
        })}
        style={{ content: { width: "1100px", marginLeft: "-550px" } }}
        showCancel={step > 1 ? true : false}
        cancelText="Anterior"
        acceptText={this.state.loading ? <Loader /> : this.createModalAcceptButton()["text"]}
        acceptButtonType={this.createModalAcceptButton()["type"]}
        disabledAccept={this.state.loading || this.createModalAcceptButton()["disabled"]}
      />
    );
  }

  createAssemble = () => {
    this.setState({loading: true});
    // this.props.createAssemble(
    //   this.props.companyId,
    //   this.props.companyFleets[0].id,
    //   1,
    //   {
    //     onSuccess: this.registerAssembleResources,
    //     strategy: API_REQUEST_STRATEGY_OPTIONS.IGNORE,
    //   }
    // );
    const { vehiclesData, equipmentsData, operators } = this.state;
    this.props.createAssembleWithComponents({
      company_id: this.props.companyId,
      fleet_id: this.props.companyFleets[0].id,
      status: 1,
      vehicles: vehiclesData.map(ve => ({
        company_id: this.props.companyId,
        fleet_id: this.props.companyFleets[0].id,
        fleet_vehicle: ve.id,
        scheduledate: new Date(ve.schedule).toISOString()
      })),
      equipments: equipmentsData.map(ve => ({
        company_id: this.props.companyId,
        fleet_id: this.props.companyFleets[0].id,
        fleet_equipment: ve.id,
        scheduledate: new Date(ve.schedule).toISOString()
      })),
      operators: operators.map(ve => ({
        company_id: this.props.companyId,
        fleet_id: this.props.companyFleets[0].id,
        fleet_operator: ve.id,
        scheduledate: new Date(ve.schedule).toISOString()
      })),
    }, {
      onSuccess: () => {
        this.setState({loading: false});
        this.manageCreateModal({ target: { name: "saved", value: "" } })
      },
      onError: (e) => {
        this.setState({loading: false});
        this.manageCreateModal({ target: { name: "close", value: "" } }); 
        let err = e.response?.data?.errors || []; 
        this.props.manageError(err);
      },
    })
  };

  registerAssembleResources = (assemble) => {
    const asm = assemble;
    const {
      companyId,
      companyFleets,
      createAssembleVehicle,
      createAssembleEquipment,
      createAssembleOperator,
    } = this.props;
    const counter = new SaveItemCounter(() =>
      this.manageCreateModal({ target: { name: "saved", value: "" } })
    );
    const { vehiclesData, equipmentsData, operators } = this.state;
    vehiclesData.forEach((ve) => {
      counter.add();
      createAssembleVehicle(
        companyId,
        companyFleets[0].id,
        asm.id,
        ve.id,
        new Date(ve.schedule).toISOString(),
        {
          onSuccess: () => counter.done(),
          onError: () => counter.done(),
        }
      );
    });

    equipmentsData.forEach((eq) => {
      counter.add();
      createAssembleEquipment(
        companyId,
        companyFleets[0].id,
        asm.id,
        eq.id,
        new Date(eq.schedule).toISOString(),
        {
          onSuccess: () => counter.done(),
          onError: () => counter.done(),
        }
      );
    });

    operators.forEach((op) => {
      counter.add();
      createAssembleOperator(
        companyId,
        companyFleets[0].id,
        asm.id,
        op.id,
        new Date(op.schedule).toISOString(),
        {
          onSuccess: () => counter.done(),
          onError: () => counter.done(),
        }
      );
    });
  };

  manageCreateModal = (e) => {
    const { step, vehiclesData } = this.state;
    const { name = "" } = e.target;
    const {
      companyId,
      // companyFleets,
      // listFleetData,
      // listFleetOperators,
      listFleetResourceCatalog,
      onChange,
    } = this.props;
    switch (name) {
      case "newAssemble":
        // listFleetData(companyId, companyFleets[0].id);
        // listFleetOperators(companyId, companyFleets[0].id);
        [1, 2, 3].forEach((number) =>
          listFleetResourceCatalog(
            companyId,
            number,
            "unassembled",
            "",
            "",
            "",
            "",
            {
              alias: `catalogType${number}Counter`,
            }
          )
        );
        this.setState({
          step: 1,
          selectedDate: new Date(),
          vehiclesData: [],
          equipmentsData: [],
          operators: [],
        });
        return;
      case "next":
        if (step === 3) {
          this.createAssemble();
        } else {
          const v = vehiclesData?.find((f) => f.class === 5 || f.class === 6);
          if (v && step === 1) {
            this.setState({ step: 2 });
          } else if (vehiclesData.length === 0 && step === 1) {
            this.setState({ step: 2 });
          } else {
            this.setState({ step: 3 });
          }
          this.handleSearch("");
        }
        return;
      case "close":
        onChange({
          target: { name: "showCreateModal", value: false },
        });
        this.setState({
          step: 1,
          vehiclesData: [],
          equipmentsData: [],
          operators: [],
        });
        return;
      case "cancel":
        const v = vehiclesData?.find((f) => f.class === 5 || f.class === 6);
        if (v && step === 3) {
          this.setState({ step: 2 });
        } else {
          this.setState({ step: 1 });
        }
        this.handleSearch("");
        return;
      case "saved":
        onChange({ target: { name: "saved", value: "" } });
        return;
      default:
    }
  };

  handleChange = (e) => {
    const { name, value, checked = false } = e.target;
    const {
      selectedDate,
      equipmentsData,
      operators,
      step,
      vehiclesData,
    } = this.state;
    const { fleetVehicleTypesClasses } = this.props;

    switch (name) {
      case "vehiclesData":
        const vcl = fleetVehicleTypesClasses
          .filter((f) => f.type === 1)
          .find((f) => f.classes.find((c) => c.id === value.class))
          .classes.find((c) => c.id === value.class);
        const vehicle = {
          ...value,
          schedule: selectedDate,
          description: `${vcl.description}/${value.number}/${value.plates}`,
        };
        this.setState({ vehiclesData: [vehicle] });
        break;
      case "equipmentsData":
        if (checked) {
          const equipment = {
            ...value,
            schedule: selectedDate,
            description: `${value.number}/${value.plates}`,
          };
          if (
            // solo 1 dolly
            value.type === 7 &&
            equipmentsData.filter((e) => e.type === 7).length > 0
          )
            return;
          if (
            // solo dos remolques
            value.type === 8 &&
            equipmentsData.filter((e) => e.type === 8).length > 1
          )
            return;
          if (
            // solo dos semis
            value.type === 9 &&
            equipmentsData.filter((e) => e.type === 9).length > 1
          )
            return;
          this.setState({ equipmentsData: equipmentsData.concat([equipment]) });
        } else {
          this.setState({
            equipmentsData: equipmentsData.filter((f) => f.id !== value.id),
          });
        }
        break;
      case "operators":
        if (checked && operators.length <= 2) {
          const operator = {
            ...value,
            schedule: selectedDate,
          };
          this.setState({ operators: operators.concat([operator]) });
        } else {
          this.setState({
            operators: operators.filter((f) => f.id !== value.id),
          });
        }
        break;
      case "selectedDate":
        switch (step) {
          case 1:
            this.setState({
              vehiclesData: vehiclesData.map((v) => ({
                ...v,
                schedule: value,
              })),
            });
            break;
          case 2:
            this.setState({
              equipmentsData: equipmentsData.map((e) => ({
                ...e,
                schedule: value,
              })),
            });
            break;
          case 3:
            this.setState({
              operators: operators.map((o) => ({
                ...o,
                schedule: value,
              })),
            });
            break;
          default:
        }
        this.setState({ [name]: value });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  handleSearch = (modalSearchText: string) => {
    this.setState({ modalSearchText: modalSearchText });
    this.props.onSearch(modalSearchText, this.state.step);
  };

  createModalAcceptButton = () => {
    const { step, vehiclesData, equipmentsData } = this.state;
    if (step === 1 && vehiclesData?.length === 0) {
      return { text: "Omitir", type: "outline dark primary", disabled: false };
    // } else if (
    //   step === 2 &&
    //   equipmentsData.filter((f) => f.type === 9).length > 1 &&
    //   equipmentsData.filter((f) => f.type === 7).length === 0
    // ) {
    //   return {
    //     text: "Siguiente",
    //     type: "primary",
    //     disabled: true,
    //     message: "Debe seleccionar Un dolly",
    //   };
    } else if (
      step === 2 &&
      equipmentsData.filter((f) => f.type === 9).length < 2 &&
      equipmentsData.filter((f) => f.type === 7).length > 0
    ) {
      return {
        text: "Siguiente",
        type: "primary",
        disabled: true,
      };
    } else if (
      step === 2 &&
      vehiclesData.length === 1 &&
      equipmentsData.length === 0
    ) {
      return { text: "Siguiente", type: "primary", disabled: true };
    } else if (
      step === 2 &&
      vehiclesData.length === 0 &&
      equipmentsData.length === 0
    ) {
      return { text: "Siguiente", type: "primary", disabled: true };
    } else if (step === 3) {
      return { text: "Finalizar", type: "primary", disabled: false };
    } else {
      return {
        text: "Siguiente",
        type: "primary",
        disabled: false,
      };
    }
  };
}
export default CreateAssemble;
