//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import styled from "styled-components";
// import SVGIcon from "../../../shared/svg-icon";
// import * as Types from "../types";
// import RadioInput from "../radio-input";
// import SelectInput from "../../../shared/controls/select-input";
// import ModalSelectInput from "../modal-select-input";

type Props = {
  show: boolean,
  onChange: (SharedTypes.SimpleEvent) => void,
  data: { logo: string, name: String, trip: string },
};

const StartTripModal = ({
    show,
    onChange,
    data,
  }: Props) =>{
    return(
      <BaseDialog
        open={show}
        title="Iniciar viaje"
        message="Esta acción iniciará este viaje"
        acceptText="Aceptar"
        cancelText="Cerrar"
        showCancel={true}
        cancelButtonType="outline dark primary"
        acceptButtonType="secondary"
        acceptAction={() =>
          onChange({ target: { name: "startTrip", value: true } })
        }
        closeAction={(a) => onChange(a)}
        contentObject={
          <ModalContent
            {...data}
            onChange={onChange}
          />
        }
        style={{ content: { width: "450px", marginLeft: "-200px" } }}
      />
    );
  };
  export default StartTripModal;

const ModalContent = (props) => {
  return (
    <div>
      <Avatar alignItems={flexOpts.alignItems.flexEnd}>
        <Logo src={props.logo} alt="" />
        <Flex direction={flexOpts.direction.column}>
          <Trip>{props.name}</Trip>
          <Trip>{`N° viaje: ${props.trip}`}</Trip>
        </Flex>
      </Avatar>
      <Flex justifyContent={flexOpts.justifyContent.spaceAround}></Flex>
    </div>
  );
};

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 17px;
  margin: 5px;
`;

const Trip = styled.span`
  color: #20293d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

const Avatar = styled(Flex)`
  margin: 20px;
`;