//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import styled from "styled-components";
import SVGIcon, { icons } from "../../../shared/svg-icon";
import * as Types from "../types";
// import RadioInput from "../../../shared/controls/radio-input";
import ModalSelectInput from "../modal-select-input";
import ReactDatePicker from "react-datepicker";
import { FooterDatePickerText } from "../../assemble/assemble-edit-modal/styled";
// import setSeconds from "date-fns/setSeconds";
// import setMinutes from "date-fns/setMinutes";
// import setHours from "date-fns/setHours";
// import * as moment from "moment";

type Props = {
  show: boolean,
  onChange: (SharedTypes.SimpleEvent) => void,
  data: Types.EditAppointmentModalData,
  reasons: { id: number, description: string }[],
  appointmentAction: number,
  reasonSelect: number,
};

const todayHour = new Date(
  // new Date().getFullYear(),
  // new Date().getMonth(),
  // new Date().getDate()
);

const EditAppointmentModal = ({
  show,
  onChange,
  data,
  reasons,
  appointmentAction,
  reasonSelect,
  onMinutes,
  selectedNewDate,
}: Props) => {
  if (data.newAppointment?.date.toString() === "Invalid Date")
    onChange({ target: { name: "close", value: "" } });

  return (
    <BaseDialog
      open={show}
      title="Cambio de cita"
      message="Esta acción cancela la cita anterior y reasignará la nueva cita al conductor del camión"
      acceptText="Cambiar cita"
      cancelText="Cancelar"
      showCancel={true}
      cancelButtonType="outline dark primary"
      disabledAccept={reasonSelect === 0}
      acceptAction={() => onChange({ target: { name: "update", value: true } })}
      closeAction={(a) => onChange(a)}
      contentObject={
        <ModalContent
          {...data}
          onChange={onChange}
          reasons={reasons}
          appointmentAction={appointmentAction}
          reasonSelect={reasonSelect}
          onMinutes={onMinutes}
          selectedNewDate={selectedNewDate}
        />
      }
      style={{ content: { width: "450px", marginLeft: "-200px", overflow: "initial" } }}
      //   disabledAccept: PropTypes.bool,
      className="appointment-modal"
    />
  );
};
export default EditAppointmentModal;

const ModalContent = (props: Types.EditAppointmentModalData) => {
  return (
    <div>
      <Avatar alignItems={flexOpts.alignItems.flexEnd}>
        <Logo src={props.logo} alt="" />
        <Flex direction={flexOpts.direction.column}>
          <Trip>{`N° orden: ${props.code}`}</Trip>
          <Maneuver>{`Maniobra: ${props.maneuver}`}</Maneuver>
        </Flex>
      </Avatar>
      <Flex justifyContent={flexOpts.justifyContent.spaceAround}>
        <Flex direction={flexOpts.direction.column}>
          <Appointment>{"Cita Anterior"}</Appointment>
          <AppointmentDate>
            <Icon
              name={icons.calendar}
              stroke={"#AFC8FF"}
              fill={"#AFC8FF"}
              height={"15px"}
              width={"15px"}
            />
            {formatDate(props.currentAppointment.date)}
          </AppointmentDate>
          <AppointmentDate>{props.currentAppointment.space}</AppointmentDate>
        </Flex>
        <Flex direction={flexOpts.direction.column}>
          <Appointment>{"Cita nueva"}</Appointment>
          <AppointmentDate>
            {/* <Icon
              name={icons.calendar}
              stroke={"#AFC8FF"}
              fill={"#AFC8FF"}
              height={"15px"}
              width={"15px"}
            /> */}
            {/* {formatDate(props.newAppointment.date)} */}
            {/* <div style={{width: "60px", transform: "translateY(-10px)", zIndex: "5"}}>
              <ModalSelectInput
                isSearchable={false}
                defaultValue={Minutes[0]}
                // defaultValue={{label: "Minutos", type: 1, value: -1}}
                onChange={(a) =>
                  props.onMinutes({
                    target: { name: "selectedNewDate", value: a.value },
                  })
                }
                className="select-minute"
                name="selectedNewDate"
                options={Minutes}
              />
            </div>{" minutos"} */}
            <ReactDatePicker
              selected={props.selectedNewDate ||  props.newAppointment.date}
              onChange={(date) =>
                props.onMinutes({ target: { name: "selectedNewDate", value: date } })
              }
              minDate={todayHour}
              // minTime={}
              maxDate={null}
              // showTimeSelect
              // timeFormat="HH:mm"
              // timeIntervals={5}
              popperPlacement="top-start"
              timeCaption="horario"
              dateFormat="d MMMM yyyy HH:mm"
              // dateFormat="dd/MM/yyyy h:mm aa"
              // injectTimes={[
              //   setHours(setMinutes(new Date(), 0), 23),
              //   setHours(setMinutes(new Date(), 5), 23),
              //   setHours(setMinutes(new Date(), 10), 23),
              //   setHours(setMinutes(new Date(), 15), 23),
              //   setHours(setMinutes(new Date(), 20), 23),
              //   setHours(setMinutes(new Date(), 25), 23),
              //   setHours(setMinutes(new Date(), 30), 23),
              //   setHours(setMinutes(new Date(), 35), 23),
              //   setHours(setMinutes(new Date(), 40), 23),
              //   setHours(setMinutes(new Date(), 45), 23),
              //   setHours(setMinutes(new Date(), 50), 23),
              //   setHours(setMinutes(new Date(), 59), 23),
              // ]}
              timeInputLabel="Hora:"
              showTimeInput
              customInput={<DatePickerCustom />}
              className="appointment-date-picker"
            />
          </AppointmentDate>
          <AppointmentDate>{props.newAppointment.space}</AppointmentDate>
        </Flex>
      </Flex>
      <Avatar>
        <ModalSelectInput
          isSearchable={false}
          defaultValue={props.reasons[0]}
          onChange={(a) =>
            props.onChange({
              target: { name: "reasonSelect", value: a.value },
            })
          }
          name="reasonSelect"
          options={props.reasons}
        />
      </Avatar>
      {/* {props.overCard && (
        <Avatar justifyContent={flexOpts.justifyContent.spaceAround}>
          <RadioInput
            onClick={(e) =>
              props.onChange({
                target: { name: "appointmentAction", value: e.target.value },
              })
            }
            title="Sobreponer cita"
            value={1}
            selected={props.appointmentAction || ""}
            name="appointmentAction"
          />
          <RadioInput
            onClick={(e) =>
              props.onChange({
                target: { name: "appointmentAction", value: e.target.value },
              })
            }
            title="Reemplazar cita"
            value={2}
            selected={props.appointmentAction || ""}
            name="appointmentAction"
          />
        </Avatar>
      )} */}
    </div>
  );
};

const formatDate = (date: Date) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const tomorrow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes()
  );

  let result =
    date.toDateString() === today.toDateString()
      ? "Hoy, "
      : date.toDateString() === tomorrow.toDateString()
      ? "Mañana, "
      : date.toUTCString().substring(4, 16);

  result = result.concat(
    " ",
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    " hrs"
  );
  return result;
};

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 17px;
  margin: 5px;
`;

const Trip = styled.span`
  color: #20293d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

const Maneuver = styled.span`
  color: #20293d;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

const Appointment = styled.span`
  color: #20293d;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const AppointmentDate = styled(Flex)`
  color: #2d364c;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Avatar = styled(Flex)`
  margin: 20px;
`;

const Icon = styled(SVGIcon)`
  margin-right: 5px;
`;

const DatePickerCustom = ({
  value,
  onClick,
}: {
  value?: any,
  onClick?: Function,
}) => (
  <Flex style={{ width: "fit-content" }}>
    <SVGIcon
      name={icons.calendar}
      fill="#AFC8FF"
      width="20px"
      height="20px"
      onClick={onClick}
    />
    <FooterDatePickerText onClick={onClick}>{value}</FooterDatePickerText>
  </Flex>
);

// const Minutes = [
//   {
//     value: 0,
//     label: "0"
//   },
//   {
//     value: 10,
//     label: "10"
//   },
//   {
//     value: 20,
//     label: "20"
//   },
//   {
//     value: 30,
//     label: "30"
//   },
//   {
//     value: 40,
//     label: "40"
//   },
//   {
//     value: 50,
//     label: "50"
//   }
// ]
