import styled from "styled-components";
export const FacilityRow = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Overpass;
  font-size: 14px;
  width: 100%;
  height: auto;
  line-height: 33px;
  overflow: hidden;
`;
