import React from "react";
const ChatIcon = (props) => {
  return (
    <svg
      width="59px"
      height="49px"
      viewBox="0 0 59 49"
      fill="#0077E2"
      {...props}
    >
      <path
        d="M32.9885057,2.73333333 L6.59770115,2.73333333 C4.78333333,2.73333333 3.31534483,3.96333333 3.31534483,5.46666667 L3.29885057,30.0666667 L9.89655172,24.6 L32.9885057,24.6 C34.8028736,24.6 36.2873563,23.37 36.2873563,21.8666667 L36.2873563,5.46666667 C36.2873563,3.96333333 34.8028736,2.73333333 32.9885057,2.73333333 Z M29.6896552,19.1333333 L9.89655172,19.1333333 L9.89655172,16.4 L29.6896552,16.4 L29.6896552,19.1333333 Z M29.6896552,15.0333333 L9.89655172,15.0333333 L9.89655172,12.3 L29.6896552,12.3 L29.6896552,15.0333333 Z M29.6896552,10.9333333 L9.89655172,10.9333333 L9.89655172,8.2 L29.6896552,8.2 L29.6896552,10.9333333 Z"
        id="Shape"
        fillRule="nonzero"
      ></path>
    </svg>
  );
};

export default ChatIcon;
