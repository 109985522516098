import styled from "styled-components";
import PanelCollapsible from "../../../shared/component/panel-collapsible";

export const CollapsibleFacilityHeader = styled(PanelCollapsible)`
  & .tag {
    & .tag-body {
      min-width: 60px;
      text-align: center;
      line-height: 20px;
    }
  }
  & .caret {
    left: -10px;
    position: relative;
    width: 1%;
    cursor: pointer;
  }
  & .header {
    background-color: #465477;
    justify-content: start !important;
  }
`;