// @flow
import {
  isValidAction,
  requestStatus,
  receiveStatus,
  failStatus,
  deletedStatus,
} from "../api/helpers";
import { createdStatus } from "../api/helpers";
import { receiveCatalogStatus } from "../api/helpers";
import type { ApiReducerAction } from "../api/types";

const apiReducer = (state: any = {}, action: ApiReducerAction) => {
  if (!isValidAction(action)) {
    return state;
  }
  const { type } = action;
  if (type.startsWith(`API_SUCCESS_READ_`)) {
    return receiveStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_LIST_`)) {
    return receiveStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_CREATE_`)) {
    return createdStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_DELETE_`)) {
    return deletedStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_CATALOG_`)) {
    return receiveCatalogStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_UPDATE_`)) {
    return receiveCatalogStatus(state, action);
  }
  if (type.startsWith(`API_SUCCESS_PARTIAL_`)) {
    return receiveCatalogStatus(state, action);
  }
  if (type.startsWith(`API_REQUEST_`)) {
    return requestStatus(state, action, type === 'API_REQUEST_CANCEL_MULTIPLE_REQUESTS');
  }
  if (type.startsWith(`API_FAIL_`)) {
    return failStatus(state, action);
  }

  return state;
};

export default apiReducer;
