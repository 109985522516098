import React from "react";
import { connect } from "react-redux";
import ItemSelector from '../../company/billing/item-selector';
import Checkbox from '../controls/checkbox';
import InputSearch from '../controls/input-search';
import Modal from 'react-modal';
import Button from '../controls/button';
import Loader from '../icons/tat/loader';
import { handleWSError, normalizeStr } from "../utils";
import { getEntityItems } from "../../redux/api";
import { readEntity, createEntity, deleteEntity } from '../../redux/api/actions';
import ErrorDialog from '../dialog/error-dialog';
import GenericDialog from '../dialog/generic-modal';

class RfcList extends React.Component {

  state={
    showEditRfcModal: false,
    searchStr: '',
    rfcList: [],
    loading: false,
    messageString: '',
    errorMessageString: ''
  }

  componentDidMount() {
    this.props.getRfc({
      company_id: this.props.companyId,
      id: this.props.facilityId
    }, {
      onSuccess: (r) => {

        var rfcToList = [];

        for (var i = 0; i < (r.length ?? 0); i++) {
          let item = r[i];
          rfcToList = rfcToList.concat(`${item.tax_info?.id ?? 0}`);
        }

        this.setState({
          rfcList: rfcToList
        });
      }
    });
    
    this.props.getCompanyRfc({
      company_id: this.props.companyId,
    });
  }

  handleRfcSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  selectAllRfcs = (e) => {
    let st = [];
    if (e.target.checked) {
      this.props.rfc.forEach((fe) => {
        if ((fe.items || []).length > 0) {
          fe.items.forEach((ch) => {
            if (!st.includes(ch.id) && (!ch.is_assembled || ([]).includes(ch.id.toString()))) {
              st.push(ch.id)
            }
            // if (!st.includes(ch.id)) st.push(ch.id);
          });
        } else {
          st.push(fe.id);
        }
      });
    }

    this.setState({ rfcList: st });
  };

  handleSelectRfc = (e, items) => {
    let st = this.state.rfcList.map((m) => m.toString()) || [];
    if ((items || []).length > 0) {
      items.forEach((item) => {
        let val = item.id.toString();
        st = st.filter((a) => a !== val);
        if (e.target.checked && (!item.is_assembled || ([]).includes(val))) {
          st = st.concat(val);
        }
      });
    } else {
      if (st.includes(e.target.value.toString())) {
        st = st.filter((a) => a.toString() !== e.target.value.toString());
      } else {
        st = st.concat(e.target.value.toString());
      }
    }

    this.setState({ rfcList: st });
  };

  handleFilterListSearch = (items, field, value = "") => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field]).includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    "description",
                    this.state.searchStr
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field]).includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };

  getRfcList = () => {
    var rfcToList = [];

    for (var i = 0; i < this.props.taxInfo.length; i++) {
      let item = this.props.taxInfo[i];

      let rfc = item?.tax_info?.data?.fiscal_number ?? '';
      let facilityName = item?.tax_info?.data?.legal_company_name ?? '';
            
      rfcToList = rfcToList.concat(`${rfc} - ${facilityName}`);
    }

    return rfcToList;
  }

  getIdToDelete = (idToSearch) => {
    var idToRemove = null;

    for (var i = 0; i < this.props.taxInfo.length; i++) {
      if (`${this.props.taxInfo[i].tax_info?.id ?? 0}` === `${idToSearch}`) {
        idToRemove = `${this.props.taxInfo[i].id ?? 0}`;
      }
    }

    return idToRemove;
  }

  saveRfc = () => {
    this.setState({loading: true})

    var rfcToAdd = [];
    var rfcToDelete = [];

    var rfcToValidate = [];
    var counterRequest = 0;
    var errorStrings = [];

    for (var i = 0; i < (this.props.taxInfo.length ?? 0); i++) {
      let item = this.props.taxInfo[i];
      rfcToValidate = rfcToValidate.concat(`${item.tax_info?.id ?? 0}`);

      if (!this.state.rfcList.includes(`${item.tax_info?.id ?? 0}`)) {
        rfcToDelete = rfcToDelete.concat(`${item.tax_info?.id ?? 0}`);
      }
    }

    for (var i = 0; i < this.state.rfcList.length; i++) {
      if (!rfcToValidate.includes(this.state.rfcList[i])) {
        rfcToAdd = rfcToAdd.concat(this.state.rfcList[i]);
      }
    }

    for(var i = 0; i < rfcToAdd.length; i++) {

      this.props.addRfc({
        company_id: this.props.companyId,
        id: this.props.facilityId,
        tax_info: rfcToAdd[i],
      },{
        onSuccess: (r) => {
          counterRequest = counterRequest + 1;

          if (counterRequest === (rfcToAdd.length + rfcToDelete.length)) {
            if (errorStrings.length === 0) {
              this.successAddition();
            } else {
              this.errorAddition(errorStrings);
            }
          }
        },
        onError: (e) => {
          counterRequest = counterRequest + 1;
          errorStrings = errorStrings.concat(`${handleWSError(e.response?.data)}`)

          if (counterRequest === (rfcToAdd.length + rfcToDelete.length)) {
            this.errorAddition(errorStrings);
          }
        }
      });

    }

    for(var i = 0; i < rfcToDelete.length; i++) {

      let idToRemove = this.getIdToDelete(rfcToDelete[i]);

      if (idToRemove) {
        this.props.deleteRfc({
          company_id: this.props.companyId,
          id: this.props.facilityId,
          id_to_delete: idToRemove,
          tax_info: idToRemove,
        },{
          onSuccess: (r) => {
            counterRequest = counterRequest + 1;
  
            if (counterRequest === (rfcToAdd.length + rfcToDelete.length)) {
              if (errorStrings.length === 0) {
                this.successAddition();
              } else {
                this.errorAddition(errorStrings);
              }
            }
          },
          onError: (e) => {
            counterRequest = counterRequest + 1;
            errorStrings = errorStrings.concat(`${handleWSError(e.response?.data)}`)
  
            if (counterRequest === (rfcToAdd.length + rfcToDelete.length)) {
              this.errorAddition(errorStrings);
            }
          }
        });
      } else {
        counterRequest = counterRequest + 1;
  
        if (counterRequest === (rfcToAdd.length + rfcToDelete.length)) {
          if (errorStrings.length === 0) {
            this.successAddition();
          } else {
            this.errorAddition(errorStrings);
          }
        }
      }

    }

  }

  successAddition = () => {
    this.setState({
      showEditRfcModal: false, 
      searchStr: '',
      messageString: 'Se actualizó correctamente',
      errorMessageString: '',
      loading: false
    });

    this.props.getRfc({
      company_id: this.props.companyId,
      id: this.props.facilityId
    }, {
      onSuccess: (r) => {

        var rfcToList = [];

        for (var i = 0; i < (r.length ?? 0); i++) {
          let item = r[i];
          rfcToList = rfcToList.concat(`${item.tax_info?.id ?? 0}`);
        }

        this.setState({
          rfcList: rfcToList,
        });
      }
    });

  }

  errorAddition = (errors) => {
    this.setState({
      loading: false,
      showEditRfcModal: false, 
      searchStr: '',
      messageString:  '',
      errorMessageString: errors.join(", ")
    });

    this.props.getRfc({
      company_id: this.props.companyId,
      id: this.props.facilityId
    }, {
      onSuccess: (r) => {

        var rfcToList = [];

        for (var i = 0; i < (r.length ?? 0); i++) {
          let item = r[i];
          rfcToList = rfcToList.concat(`${item.tax_info?.id ?? 0}`);
        }

        this.setState({
          rfcList: rfcToList,
        });
      }
    });
  }

  render() {
    return(
      <div>
        {
          this.props.taxInfoLoading ?
          <div style={{height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, color: 'white'}}>
            {'Cargando'}
            <div style={{width: 8}}/>
            <Loader />
          </div> 
          :
          <ItemSelector
            title="RFC:"
            name="rfc"
            tags={[]}
            labels={this.getRfcList()}
            onClick={() => {
              this.setState({showEditRfcModal: true});
            }}
          />
        }

        <Modal
          isOpen={this.state.showEditRfcModal}
          portalClassName="dialog emails-modal invite-users-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">RFC</div>
          <div
            className="close"
            onClick={() => {this.setState({showEditRfcModal: false, searchStr: ''})}}
          ></div>

          <div className="message">
            <div className="facilities-options-container">
              <div className="search-container">
                <InputSearch
                  type="text"
                  label="Buscar rfc"
                  name="usersFacilitySearch"
                  className="full"
                  onChange={this.handleRfcSearch}
                />
                <img src="/images/search.svg" alt="" className="icono" />
              </div>
              <div className="facilities-list-container">
                <Checkbox
                  onChange={(e) => this.selectAllRfcs(e)}
                  item={{
                    id: 0,
                    description: "Todos",
                    active:
                      this.state.rfcList.length ===
                      (this.props.rfc || []).length,
                  }}
                  name="facilities"
                  className="bold"
                />
                <div className="separator" />
                <div className="items checks">
                  {
                    this.handleFilterListSearch(
                      this.props.rfc || [],
                      "description",
                      this.state.searchStr
                    ).map((i) => {
                      let l = [];
                      l.push(
                        <Checkbox
                          // key={counter++}
                          onChange={(e) =>
                            this.handleSelectRfc(e, i.items)
                          }
                          item={{
                            id: i.id,
                            description: i.description,
                            active: (this.state.rfcList || []).find(
                              (si) => si.toString() === i.id.toString()
                            )
                              ? true
                              : false,
                          }}
                          name="facilities"
                          className={(i.items || []).length ? "bold" : ""}
                        />
                      );
                      return l;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          
          <div className="actions">
            <Button
              disabled={this.state.loading}
              text={this.state.loading ? <Loader /> : "Guardar"}
              type="primary"
              onClick={this.saveRfc}
            />
          </div>
        </Modal>

        <ErrorDialog
          open={this.state.errorMessageString !== ''}
          message={this.state.errorMessageString}
          acceptText="OK"
          acceptAction={() => this.setState({errorMessageString: '', messageString:  ''})}
          closeAction={() => this.setState({errorMessageString: '', messageString: ''})}
        />

        <GenericDialog
          open={this.state.messageString !== ''}
          title="Listo!"
          message={this.state.messageString}
          acceptAction={() => this.setState({messageString: '', errorMessageString: ''})}
          closeAction={() => this.setState({messageString: '', errorMessageString: ''})}
        />

      </div>
    );
  }

}

const mapStateToProps = state => {

  const taxInfo = getEntityItems(state, "COMPANIES.TAXINFO.FACILITY") || [];
  const taxInfoLoading = state?.api["COMPANIES.TAXINFO.FACILITY"]?.status?.isFetching || false;
  const companyRfc = (getEntityItems(state, "COMPANIES.TAXINFO") || []).map( item => {
    let rfcToShow = item?.data?.fiscal_number ?? '';
    let name = item?.data?.legal_company_name ?? '';

    return({
      id: item.id,
      description: `${rfcToShow} - ${name}`
    });
  });

  return {
    companyId: state.globalCatalog.session.company.id,
    rfc: companyRfc,
    taxInfo,
    taxInfoLoading
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    getRfc: (params, opt) => {
      dispatch(
        readEntity("COMPANIES.TAXINFO.FACILITY", params, opt)
      );
    },
    getCompanyRfc: (params, opt) => {
      dispatch(
        readEntity("COMPANIES.TAXINFO", params, opt)
      );
    },
    addRfc: (params, opt) => {
      dispatch(
        createEntity("COMPANIES.TAXINFO.FACILITY", params, opt) 
      );
    },
    deleteRfc: (params, opts) => {
      dispatch(
        deleteEntity("COMPANIES.TAXINFO.FACILITY", params, opts)
      )
    },
  };
}

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(RfcList);