import React from "react";
import { connect } from "react-redux";
import { getEntityItems, readEntities } from "../../redux/api";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import { View, ViewTitle } from "../../shared/styled/view";
import OrdersSummaryList from "./orders-summary-list";

const TRANSACTIONS = [
  { description: "Compra", value: 1 },
  { description: "Venta", value: 2 },
  { description: "Externas", value: 3 },
]

const TRANSPORT = [
  { description: "Camión", value: 1 },
  { description: "Tren", value: 2 },
  { description: "Barco", value: 3 },
  { description: "Avión", value: 4 },
]

const ITEMS = [
  { description: "Pedidos", value: 1 },
  { description: "Órdenes", value: 2 },
]

class OrdersControlView extends React.Component {
  state = {
    transactionType: 1,
    transportType: 1,
    itemType: 1
  }

  componentDidMount() {
    this.loadItems();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    // if()
    this.setState({ [name]: value}, () => {if(name === 'itemType') this.loadItems()});
  };

  loadItems = () => {
    switch(this.state.itemType){
      case 1:
        this.props.loadCompanyRequisitionsResume({
          company_id: this.props.companyId
        })  
      break;
      case 2:
        this.props.loadCompanyOrdersResume({
          company_id: this.props.companyId
        })  
      break;
      default:
    }
  }

  render() {
    return (
      <View className="orders-control-view">
        <ViewTitle>
          <span>Resumen de {this.state.itemType === 1 ? 'pedidos' : 'órdenes'}</span>
        </ViewTitle>
        <div className="switches-container">
          <OptionSelectorSwitch
            value={this.state.itemType}
            name="itemType"
            onChange={this.handleChange}
            items={ITEMS}
          />
          <OptionSelectorSwitch
            value={this.state.transactionType}
            name="transactionType"
            onChange={this.handleChange}
            items={TRANSACTIONS}
          />
          <OptionSelectorSwitch
            value={this.state.transportType}
            name="transportType"
            onChange={this.handleChange}
            items={TRANSPORT}
          />
        </div>
        <div className="content">
          <OrdersSummaryList 
            history={this.props.history}
            ordersResume={this.state.itemType === 1 ? this.props.requisitionsResume : this.props.ordersResume}
            // ordersResume={[]}
            itemType={this.state.itemType}
            transactionType={this.state.transactionType}
          />
          
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  // const ordersResume = Object.entries(getEntityItems(state, "COMPANIES.ORDERS.CONTROL.RESUME"));
  const ordersResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME")['data'] || {});
  const requisitionsResume = Object.entries(getEntityItems(state, "COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME")['data'] || {});
  return {
    companyId,
    ordersResume,
    requisitionsResume
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // loadCompanyOrdersResume: (params, opt) =>
    //   dispatch(readEntities("COMPANIES.ORDERS.CONTROL.RESUME", params, opt)),
    loadCompanyOrdersResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.ORDERS.RESUME", params, opt)),
    loadCompanyRequisitionsResume: (params, opt) =>
      dispatch(readEntities("COMPANIES.REQUISITIONSCONTROL.REQUISITIONS.RESUME", params, opt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersControlView);