import * as React from "react";
import { connect } from "react-redux";
import Tag from "../../shared/controls/tag";
import { Flex, flexOpts } from "../../shared/styled/flex";
import CalendarSelectorRibbon from "../shipment/styled/calendar-selector-ribbon";
import { CollapsibleHeaderList } from "../shipment/styled/collapsible-header";
import { Column } from "../shipment/styled/column";
import { FacilityRow } from "../shipment/styled/facility-row";
import { SummaryPanel } from "../shipment/styled/summary-panel";
import { getRibbonWeeklyDates } from "../shipment/utils";
import WeekPicker from "../shipment/week-picker";

const COLUMNS = [
  { description: "Viajes recibidos", name: "recibidos" },
  { description: "Confirmados s/asignar", name: "confirmados_por_asignar" },
  { description: "Confirmados asignados", name: "confirmados_asiganados" },
  { description: "En tránsito", name: "en_transito" },
  // { description: "Completados", name: "completados" },
  // { description: "Completados s/datos", name: "completados_sin_datos" },
];

class AssembleShipmentSummaryList extends React.Component {
  state = {
  }

  render() {
    return (
      <SummaryPanel>
        <div style={{ margin: "25px" }}>
          <div style={{ position: "relative" }}>
            <Flex
              style={{
                // width: "100%",
                width: "calc(100% - 12px)",
                // height: "100%",
                height: "calc(100% + 60px)",
                position: "absolute",
                top: "-60px",
                paddingRight: "0px",
              }}
              direction={flexOpts.direction.reverseRow}
            >
              <Flex
                style={{
                  width: "85%",
                  height: "100%",
                }}
                justifyContent={flexOpts.justifyContent.spaceEvenly}
              >
                {COLUMNS.map((column) => (
                  <Column>{column.description}</Column>
                ))}
              </Flex>
            </Flex>
            <div style={{ position: "relative", /* paddingTop: "60px", */ maxHeight: "97%", overflowY: "auto", marginTop: "120px" }}>
              {this.props.companiesByBase.map((tag) => (
                <CollapsibleHeaderList
                  maxExpandedHeight="auto"
                  contentStyle={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  collapsedHeight="40px"
                  title={
                    tag.id ?
                      <Tag 
                        {...tag} 
                        onClick={(e) => {
                          e.e.stopPropagation(); 
                          // this.props.setBaseConfig("ShipmentDetailBase", null);
                          // this.props.setBaseConfig("assignedShipmentBoard", null);
                          // this.props.setBaseConfig("UnassignedShipmentBoardBase", null);
                          this.props.history.push(
                            `/company/controls/shipment/assemble/?base=${tag.id}&fleet_provider=${tag.cargo_owners.map(cO => cO.fleet_provider).join(',')}&facilities=${tag.facilities.join(',')}`
                          )
                        }} 
                      />
                    : 
                      <div 
                        style={{
                          width: "calc(15% - 28px)",
                          marginRight: "10px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          this.props.history.push(
                          `/company/controls/shipment/assemble/?fleet_provider=${tag.cargo_owners.map(cO => cO.fleet_provider).join(',')}&facilities=${tag.facilities.join(',')}`
                          )
                        }}
                      >
                        Sin base
                      </div>
                  }
                  caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                  initialStateCollapsed={true}
                  // redirect={() =>
                  //   this.props.history.push(
                  //     `/company/controls/shipment/detail/${tag.facilities[0].id}/?facilities=${tag.facilities.map(f => f.id).join(',')}`
                  //   )
                  // }
                  headerContent={
                    <div
                      style={{
                        width: "85%",
                        display: "flex",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {COLUMNS.map((column) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          {(tag.cargo_owners || []).length ?
                            tag.cargo_owners
                            .map((f) => f[column.name])

                            .reduce((total, value, i, a) => {
                              // if (!!!column.sum) {
                                return total + value;
                              
                            })

                            : "-"
                          }
                        </div>
                      ))}
                    </div>
                  }
                >
                  {(tag.cargo_owners || [])
                    // .filter((f) => !f.sum)
                    .sort(function(a, b) {
                      let totalA = 0;
                      let totalB = 0;
                      COLUMNS.forEach(column => {
                        totalA = totalA + a[column.name];
                        totalB = totalB + b[column.name];
                      }) 
                      if (totalA > totalB) {
                        return -1;
                      }
                      if (totalA < totalB) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((cO) => (
                      <FacilityRow
                        onClick={() => {
                          // this.props.setBaseConfig("ShipmentDetailBase", null);
                          // this.props.setBaseConfig("assignedShipmentBoard", null);
                          // this.props.setBaseConfig("UnassignedShipmentBoardBase", null);
                          this.props.history.push(
                            `/company/controls/shipment/assemble/?fleet_provider=${cO.fleet_provider}&cargo_owner=${cO.id}`
                          )
                        }}
                      >
                        <div style={{ width: "15%", textAlign: "right", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {cO.cargo_owner}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "85%",
                            justifyContent: "space-evenly",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {COLUMNS.map((column) => {
                            // if(cO[column.name].toString().includes(" - ")){
                            //   let values = cO[column.name].split(" - ");
                            //   return <div style={{ width: "100%", textAlign: "center" }}>
                            //     <><span style={{color: values[0] > 0 ? "#5BCA86" : values[0] < 0 ? "#ff0000" : "#ffffff"}}>{((values[0] === "0" ? "- " : values[0]) || "- ")}</span> - 
                            //       <span style={{color: values[1] > 0 ? "#5BCA86" : values[1] < 0 ? "#ff0000" : "#ffffff"}}>{((values[1] === "0" ? " -" : values[1]) || " -")}</span></>
                            //   </div>
                            // } else {
                              return <div style={{ width: "100%", textAlign: "center" }}>
                                {cO[column.name] === 0 ? "-" : cO[column.name]}
                              </div>
                            // }
                          })}
                        </div>
                      </FacilityRow>
                        ))}
                </CollapsibleHeaderList>
              ))}
            </div>
          </div>
        </div>
      </SummaryPanel>
    )
  }

}

const mapStateToProps = (state) => {
  return 
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setBaseConfig: (alias, config) => dispatch(setConfig(alias, config)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssembleShipmentSummaryList);