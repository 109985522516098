import React from "react";
import AlertLevel from "./alert-level-config";
import PrevSwitch from "../controls/prev-switch";

class AlertRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }
  manageCollapse = e => {
    if (this.state.collapsed) {
      this.props.alert.levels.forEach(lvl => {
        if (lvl.id) this.props.loadEmailList(lvl.id);
      });
    }
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div className={"alert-row ".concat(this.props.className)}>
        <div className="alert-title">
          {this.props.alert.name}
          <div>
            <PrevSwitch
              name="active"
              checked={this.props.alert.active}
              handleClick={e =>
                this.props.onAlertDataChange({
                  target: {
                    name: "active",
                    value: this.props.alert.id,
                    checked: e
                  }
                })
              }
            />
            <div
              className={"collapser".concat(
                this.state.collapsed ? " collapsed" : ""
              )}
              onClick={this.manageCollapse}
            >
              <img alt="" src="/images/select-arrow.svg" />
            </div>
          </div>
        </div>
        <div
          className={"alert-content".concat(
            this.state.collapsed ? " collapsed" : ""
          )}
        >
          <p className="alert-description">{this.props.alert.description}</p>
          {/* <p className="alert-see-more">
            <span>Ver más documentación</span>
          </p> */}
          <div className="alert-levels-container">
            <p className="alert-levels-intro">
              Configura los niveles de este correo:
            </p>
            {Object.values(this.props.alert.levels).map((level, i) =>
              this.props.mode === "alerts-compact" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px"
                  }}
                >
                  {level.level ? (
                    <div
                      style={{ width: "100px" }}
                    >{`Nivel ${level.level}`}</div>
                  ) : null}
                  <div style={{ marginRight: "20px" }}>Email: </div>
                  <PrevSwitch
                    handleClick={e =>
                      this.props.onLevelDataChange({
                        target: {
                          name: "email",
                          value:
                            level.alertFacility || level.notificationFacility,
                          checked: e
                        }
                      })
                    }
                    checked={level.email}
                  />
                  <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                    Push:{" "}
                  </div>

                  <PrevSwitch
                    handleClick={e =>
                      this.props.onLevelDataChange({
                        target: {
                          name: "push",
                          value:
                            level.alertFacility || level.notificationFacility,
                          checked: e
                        }
                      })
                    }
                    checked={level.push}
                  />
                </div>
              ) : (
                <AlertLevel
                  key={i}
                  level={level}
                  onChange={this.props.onLevelDataChange}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AlertRow;
