import React from "react";
import { withRouter } from "react-router-dom";
import FacilityData from "./facility-data";
import { connect } from "react-redux";
import { updateFacility } from "../../redux/reducers/facility-reducer";
import Button from "../../shared/controls/button";
import { loadSession } from "../../redux/reducers/global-catalog.reducer";
import ErrorDialog from "../../shared/dialog/error-dialog";
import { partialEntity } from "../../redux/api";
import ReactTooltip from "react-tooltip";

class FacilityProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      facility:
        (this.props.location.state.facility &&
          Object.assign({}, this.props.location.state.facility, {
            code_unique: true,
            name_unique: true,
            alias_unique: true,
            country: this.props.location.state.facility.data.country?.id,
            state: this.props.location.state.facility.data.state?.id,
            city: this.props.location.state.facility.data.city?.id,
            locality: this.props.location.state.facility.data?.locality?.id || 0,
            address: this.props.location.state.facility.data.street,
            exterior_number: this.props.location.state.facility.data.exterior_number,
            interior_number: this.props.location.state.facility.data.interior_number,
            neighborhood: this.props.location.state.facility.data.suburb?.id,
            zip_code_id: this.props.location.state.facility.data.zip_code?.id,
            tags: (this.props.location.state.facility?.tags || []).map(t => t.id)
          })) ||
        {},
      showGeneralError: false,
      errorMsg: "",
      validity: true,
    };
  }
  onChange = (e) => {
    let newFacilityValue = Object.assign({}, this.state.facility, {
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'alias' && e.target.value === '') {
      newFacilityValue = Object.assign({}, newFacilityValue, {
        alias_unique: true
      });
    }

    this.setState({
      facility: newFacilityValue,
    });
    this.validateFacility(newFacilityValue);
  };

  validateFacility = (facility) => {
    let result = false;
    let message = "";
    // debugger;
    if (
      facility.name?.length &&
      facility.code?.length &&
      facility.address?.length &&
      facility.zip_code?.length && 
      facility.exterior_number?.length && 
      parseInt(facility.state) &&
      parseInt(facility.city) &&
      parseInt(facility.time_zone) &&
      parseInt(facility.neighborhood) && 
      parseInt(facility.avatar)
    ) {
      if (
        facility.name_unique &&
        facility.code_unique &&
        facility.alias_unique
      ) {
        result = true;
      } else {
        message = "Nombre, código o alias ya existen.";
      }
    } else {
      message = "Falta información para completar tu instalación";
    }
    this.setState({ validity: result, errorMsg: message });
  };

  render() {
    return (
      <React.Fragment>
        <div className="facility-profile-edit view">
          <FacilityData
            facility={this.state.facility}
            onFacilityChange={this.onChange}
            onFocused={this.state.onFocused}
          />
          <div className="actions">
            {/* <Button
              //  onClick=
              type='secondary'
              text='Cancelar'
              name='cancel'
            /> */}
            <Button
              onClick={this.saveFacility}
              type="primary"
              text="Guardar"
              name="save"
              disabled={!this.state.validity}
              dataTip= {this.getErrors()}
              dataFor= "orbi-tooltip-error"
              onMouseLeave={() => this.handleLeave()}
            />
            <ReactTooltip
              id={"orbi-tooltip-error"}
              place="top"
              type="light"
              effect="solid"
              html={true}
              event="mouseover"
              eventOff="mouseleave"
            />
          </div>
        </div>
        <ErrorDialog
          open={this.state.showGeneralError}
          message={this.state.errorMsg}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />
      </React.Fragment>
    );
  }

  getErrors = () => {
    let errors = "";
    let facility = this.state.facility;

    errors += facility.name.length ? '' : `<b>Ingresa el nombre de la instalación<b/><br/>` 
    if (facility.name.length) {
      errors += facility.name_unique ? '' : `<b>Nombre de la instalación usado por otra instalación<b/><br/>` 
    }

    errors += facility.code.length ? '' : `<b>Ingresa el código<b/><br/>` 

    if (facility.code.length) {
      errors += facility.code_unique ? '' : `<b>Código usado por otra instalación<b/><br/>` 
    }

    errors += facility.alias_unique ? '' : `<b>Alias usado por otra instalación<b/><br/>` 

    errors += facility.zip_code.length ? '' : `<b>Ingresa el código postal<b/><br/>` 
    errors += parseInt(facility.state) ? '' : `<b>Ingresa el estado<b/><br/>` 
    errors += parseInt(facility.city) ? '' : `<b>Ingresa la ciudad<b/><br/>` 
    errors += (facility.address || '').length ? '' : `<b>Ingresa el domicilio<b/><br/>` 
    errors += (facility.exterior_number || '').length ? '' : `<b>Ingresa el num ext<b/><br/>` 
    errors += parseInt(facility.neighborhood) ? '' : `<b>Ingresa la colonia<b/><br/>` 

    if (errors !== '') {
      errors = `Por favor completa los siguientes campos:<br/>${errors}`;
    }

    return errors;
  }

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  actionCloseDialog = () => {
    this.setState({
      showGeneralError: false,
    });
  };

  saveFacility = () => {
    // if (this.state.validity) {
      // debugger;
    this.props.updateFacility({
      company_id: this.props.company,
      facility_id: this.state.facility.id,
      name: this.state.facility.name,
      code: this.state.facility.code,
      alias: this.state.facility.alias,
      phone: this.state.facility.phone,
      time_zone: this.state.facility.time_zone,
      avatar: this.state.facility.avatar,
      tags: this.state.facility.tags,
      geopos_x: this.state.facility.geopos_x,
      geopos_y: this.state.facility.geopos_y,
      company: this.props.company,
      data: {
        street: this.state.facility.address,
        suburb: this.state.facility.neighborhood,
        state: this.state.facility.state,
        country: this.state.facility.country,
        exterior_number: this.state.facility.exterior_number,
        interior_number: this.state.facility.interior_number,
        city: this.state.facility.city,
        locality: this.state.facility.locality || null,
        zip_code: this.state.facility.zip_code_id 
      }
    }, {
      onSuccess: () => {
        this.props.history.goBack();
      }, 
      onError: () => {
        this.setState({
          showGeneralError: true,
          errorMsg: "Ocurrió un error al registrar tus datos, verifica tus datos",
        });
      }
    })
    // this.props
    //   .updateFacility(
    //     Object.assign({}, this.state.facility, {
    //       tags: this.state.facility.tags.map((t) => (t.id ? t.id : t)),
    //       company: this.props.company,
    //       geolocation: {
    //         geopos_x: this.state.facility.geopos_x,
    //         geopos_y: this.state.facility.geopos_y,
    //       },
    //     })
    //   )
    //   .then((lol) => {
    //     if (lol.error) {
    //       lol.error.then((e) => {
    //         let message =
    //           "Ocurrió un error al registrar tus datos, verifica tus datos";
    //         message = e.name?.[0] ? "Nombre: ".concat(e.name?.[0]) : message;
    //         this.setState({
    //           showGeneralError: true,
    //           errorMsg: message,
    //         });
    //       });
    //     } else {
    //       this.props.history.goBack();
    //     }
    //   });
    // } else {
    // this.setState({
    //   showGeneralError: true,
    //   errorMsg: 'Ocurrió un error al registrar tus datos, verifica tus datos'
    // // });
    // }
  };
}

const mapStateToProps = (state) => {
  return {
    company: state.globalCatalog.session.company.id,
    // facility:state.
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    // updateFacility: (facility) => dispatch(updateFacility(facility)),
    updateFacility: (params, opt) =>{
      dispatch(
        partialEntity("COMPANIES.FACILITIES.UPDATE", params, opt) 
      );
    },
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connectToStore(FacilityProfileEdit));
