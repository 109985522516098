import React from 'react'
import Button from './button';
import { PropTypes } from 'prop-types';

class InputProfileImage2 extends React.Component {

  handlePicture = () => {
    this.props.handlePicture()
  }

  render() {

    const { profileImage } = this.props;
    let backgroundImage = this.props.isFactory ? '/images/upload-logo-factory.jpg' : '/images/user_large.svg';

    if (profileImage) {
      backgroundImage = ''
    }

    return (
      <div className="inputProfileImage2" style={ this.props.marginStyle && this.props.marginStyle }>

        <div className="pictureImage">
        {
          profileImage ? <img alt="" className={"pictureFrame"} src={String(profileImage).includes("http") ? profileImage : URL.createObjectURL(profileImage)} /> :

            <div 
              style={{backgroundImage: `url(${backgroundImage})` }}
              className={this.props.isFactory ? "pictureFrameFac" : "pictureFrame"}
              onClick={this.handlePicture}
              onDragEnter={this.props.dragAndDrop}
              onDragLeave={this.props.dragAndDrop}
              onDragOver={this.props.dragAndDrop}
              onDrop={this.props.dragAndDrop} >

              <img alt="" src="/images/add_large.svg" />
            </div>
        }
        </div>

        <div className="contentText">
          <h4>Sube tu foto del operador</h4>
          <p>{this.props.caption}</p>

          <div className="pictureButton">
            <Button settings={{
              text: 'Selecciona tu foto',
              type: 'button outline btnPicture',
              onClick: this.handlePicture
            }}
              onDragEnter={this.props.dragAndDrop}
              onDragLeave={this.props.dragAndDrop}
              onDragOver={this.props.dragAndDrop}
              onDrop={this.props.dragAndDrop}
            />
            <h4 className="caption">*Recuerda que tu imagen debe ser formato <i>.jpg</i>, <i>.png</i> o <i>.gif</i>.</h4>
          </div>
        </div>

      </div>
    )
  }
}

InputProfileImage2.propTypes = {
  handlePicture: PropTypes.func.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
}

InputProfileImage2.defaultProps = {
  className: '',
  title: ''
}

export default InputProfileImage2