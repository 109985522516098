import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "./checkbox";
import { validateControl, validationErrors } from "../validate";

class SelectMultipleInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      valid: undefined,
      validationErrors: [],
      touched: false,
      onFocused: false,
    };
  }

  manageView = (e) => {
    if (this.props.manageByState) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
    this.setState({ touched: true, onFocused: true });
    // if (this.state.isOpen) {
    this.isValid(this.props.value);
    // }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.manageByState === false &&
      this.props.isOpen !== prevProps.isOpen
    )
      this.setState({
        isOpen: this.props.isOpen,
      });

    const { touched } = this.state;
    const { onFocused } = this.props;

    if (onFocused && !touched) {
      this.setState({ touched: true }, () => {
        this.isValid(this.props.value);
      });
    }
  }

  handleChange = (e) => {
    this.manageView(e);
    this.props.onChange(e);
  };

  isValid = (value = this.props.value) => {
    this.setState({ onFocused: false });
    if (this.state.touched) {
      let result = validateControl(
        this.props.validate,
        value.length > 0 ? value : ""
      );
      // if (this.state.valid !== result.validationResult.valid) {
      this.setState({
        valid: result.validationResult.valid,
        validationErrors: result.validationResult.errors,
      });
      // }
    }
  };

  render() {
    return (
      // <div className={"select-input ".concat(this.props.className || '', this.state.valid === false ? ' invalid' : ' valid')}></div>
      <div
        ref={(node) => (this.node = node)}
        className={"select-multiple-input".concat(
          this.state.isOpen ? " open " : "",
          this.state.valid === false ? " invalid " : " valid ",
          this.props.className || ""
        )}
        onClick={this.manageView}
      >
        <label
          htmlFor={this.props.name}
          className={`label ${
            this.state.isOpen || this.props.value.length > 0 ? "focused" : ""
          }`}
        >
          {this.props.title || ""}
        </label>
        <div
          style={{position: 'relative'}}
        >
          <input 
            className="field" 
            value={
              this.props.showDescription ? 
              this.getDescription()
              :
              this.props.value
            } 
            disabled={true} 
          />
          <div
            style={{
              position: 'absolute',
              background: 'transparent',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 3,
              cursor: 'pointer'
            }}
          />
        </div>
        <i className="caret"> </i>
        <div
          className={"select-multiple-input-content"}
          onClick={(e) => e.stopPropagation()}
        >
          {this.getCheckList()}
        </div>
        {this.state.valid === false && (
          <div className="text-input-invalid">
            <img className="i-error" alt="" src="/images/red-exclamation.svg" />
            <p className="p-error">
              {this.state.validationErrors &&
              this.state.validationErrors.length > 0
                ? this.state.validationErrors[0]
                : validationErrors.required}
            </p>
          </div>
        )}
      </div>
    );
  }

  isActive = (data, id) => {
    return data.includes(id.toString());
  };

  getCheckList = () => {
    let data = this.props.value !== "" ? this.props.value.split(",") : [];
    return this.props.items.map((item) => {
      //   console.log(item);
      //   console.log(this.props.value);
      return (
        <Checkbox
          key={item.id}
          item={item}
          onChange={this.props.onChange}
          name={this.props.name}
          active={this.isActive(data, item.id)}
        />
      );
    });
  };

  getDescription = () => {
    let ids = this.props.value !== "" ? this.props.value.split(",") : [];
    let description = this.props.items.filter(i => this.isActive(ids, i.id)).map(i => i.description);
    return description.join(', ');
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = (e) => {
    if (this.node.contains(e.target)) return;
    this.setState({ isOpen: false });
  };
}
export default SelectMultipleInput;

SelectMultipleInput.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  manageByState: PropTypes.bool,
  validate: PropTypes.array,
  value: PropTypes.string,
  showDescription: PropTypes.bool
};
SelectMultipleInput.defaultProps = {
  className: "",
  isOpen: undefined,
  title: "",
  onChange: () => {
    console.log("Not implemented yet!");
  },
  manageByState: true,
  validate: [],
  value: "",
  showDescription: false
};
