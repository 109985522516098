import React from 'react';
import { connect } from "react-redux";
import { createEntity, getEntityItems, readEntities, readEntity } from '../../redux/api';
import Button from '../../shared/controls/button';
import SelectInput from '../../shared/controls/select-input';
import TextInput from '../../shared/controls/text-input';
import GenericDialog from '../../shared/dialog/generic-modal';
import Loader from '../../shared/icons/tat/loader';
import { View, ViewTitle } from '../../shared/styled/view';
import { validators } from '../../shared/validate';

class BillingCancellationView extends React.Component {
  state = {
    motive: 0,
    isLoading: false,
    showErrorMessage: false,
    errorMsg: [],
    related_uuid: ''
  }

  componentDidMount() {
    this.props.loadMotivos();
    if (this.props.match?.params?.tripId) {
      this.props.getTaxInfo({
        company_id: this.props.companyId,
        request_type: 'orders',
        trip_id: this.props.match?.params?.tripId ?? 0
      }, {

      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  cancelCFDI = () => {
    this.setState({isLoading: true});
    let params = {
      company_id: this.props.companyId,
      request_type: 'orders',
      trip_id: this.props.match?.params?.tripId ?? 0,
      clave_motivo: this.state.motive
    };
    if(this.state.motive === "01"){
      params.related_uuid = this.state.related_uuid;
    }
    this.props.cancelCFDI(params, {
      onSuccess: () => {
        this.setState({isLoading: false})
        this.props.history.goBack()
      }, 
      onError: (e) => {
        this.setState({
          isLoading: false, 
          showErrorMessage: true,
          errorMsg: e.response?.data?.errors || ["Ocurrió un error al cancelar el CFDI"]
        });
      }
    })
  }

  render() {
    return (
      <View className="billing-cancellation-view">
        <ViewTitle>
          Cancelación de CFDI
        </ViewTitle>
        <div className="content">
          <div className="data">
            <div>
              <div><b>RFC Emisor:</b> {this.props.cfdi_data?.EmisorRFC || '-'}</div>
              <div><b>RFC Receptor:</b> {this.props.cfdi_data?.ReceptorRFC || ''}</div>
              <div style={{marginTop: "20px"}}><b>Subtotal:</b> {this.props.cfdi_data?.SubTotal || ''}</div>
              <div><b>Iva:</b> {this.props.cfdi_data?.TotalImpuestosTrasladados || ''}</div>
              <div><b>Retención:</b> {this.props.cfdi_data?.TotalImpuestosRetenidos || ''}</div>
              <div><b>Total:</b> {this.props.cfdi_data?.Total || ''}</div>
              <div style={{marginTop: "20px"}}><b>Folio Fiscal (UUID):</b> {this.props.cfdi_data?.UUID || ''}</div>
            </div>
            <div>
              {/* <div><b>Origen:</b> {this.props.cfdi_data?.origin || ''}</div>
              <div><b>Destino:</b> {this.props.cfdi_data?.destination || ''}</div> */}
              <div /* style={{marginTop: "20px"}} */><b>Folio:</b> {this.props.cfdi_data?.Folio || ''}</div>
              <div><b>Serie:</b> {this.props.cfdi_data?.Serie || ''}</div>
              <div><b>Condiciones de Pago:</b> {this.props.cfdi_data?.CondicionesPago || ''}</div>
              <div style={{marginTop: "20px"}}><b>Uso de Factura:</b> {this.props.cfdi_data?.UsoCFDI || ''}</div>
              <div><b>Forma de Pago:</b> {this.props.cfdi_data?.FormaPago || ''}</div>
              <div><b>Método de Pago:</b> {this.props.cfdi_data?.MetodoPago || ''}</div>
            </div>
          </div>
          <div className="motives">
            <SelectInput 
              label='Motivo:'
              // className="full"
              value={this.state.motive}
              name='motive'
              items={this.props.motivesList}
              onChange={this.handleChange}
              validate={[validators.required]}
              // onFocused={this.state.onFocused}
              // hideCaret={(this.state.originList || []).length === 1}
            />
          </div>
          {
            this.state.motive === "01"
            ? <div className="motives">
                <TextInput 
                  label='UUID a relacionar:'
                  type='text'
                  value={this.state.related_uuid}
                  name='related_uuid'
                  onChange={this.handleChange}
                  // disabled={true}
                  validate={[validators.required]}
                />
              </div>
            : ''
          }
          <div className="actions">
            <Button 
              text={this.state.isLoading ? <Loader /> : "Cancelar CFDI"}
              type="primary"
              disabled={!this.state.motive || this.state.isLoading || (this.state.motive === "01" && !this.state.related_uuid)}
              onClick={this.cancelCFDI}
            />
          </div>
        </div>
        <GenericDialog
          open={this.state.showErrorMessage}
          title="Error"
          // message="Ocurrió un error con el registró del CFDI, verifica tu información"
          message={this.state.errorMsg}
          acceptAction={() => {
            this.setState({isLoading: false, showErrorMessage: false, errorMsg: []});
          }}
          closeAction={() => {
            this.setState({isLoading: false, showErrorMessage: false, errorMsg: []});
          }}
        />
      </View>
    )
  }
}

const mapStateToProps = state => {
  const motivesList = getEntityItems(state, "COMPANIES.SAT.CFDI.MOTIVOSCANCELACION");
  const cfdi_data = getEntityItems(state, "COMPANIES.SAT.TAXINFO.CANCELLATION");
  return {
    companyId: state.globalCatalog.session.company.id,
    motivesList: motivesList.map(i => ({id: i.clave, description: `Clave ${i.clave}: Motivo ${i.descripcion}`})),
    cfdi_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadMotivos: () => dispatch(readEntities("COMPANIES.SAT.CFDI.MOTIVOSCANCELACION", {}, {prefix: 'orbisat.'})),
    getTaxInfo: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO.CANCELLATION", params, opt)),
    cancelCFDI: (params, opt) => dispatch(createEntity("COMPANIES.SAT.TAXINFO.CANCELLATION.CANCEL", params, opt)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingCancellationView);