import React from "react";
import FillIcon from "../../shared/icons/alerts/fill";
import OrderIcon from "../../shared/icons/alerts/order";
import {
  DetailsCards,
  DetailsCardsHeader,
  DetailsCardsContent,
} from "./styled";

import { getUrlsEnv } from '../../shared/backend-api';

const { files } = getUrlsEnv();


export default class MenuFaqsDetailsCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getIcon = (type) => {
    switch (type) {
      case 1:
        return <FillIcon width="80px" height="80px" viewBox="10 0 48 45" />;
      case 4:
        return <OrderIcon width="80px" height="80px" viewBox="15 5 40 40" />;
      default:
    }
  };

  render() {
    const { title, description, file, video } = this.props.card;
    let file_storage = file?`${files}${file}`: null
    let video_storage = video? `${files}${video}`: null
    return (
      <DetailsCards>
        <DetailsCardsHeader>
          <div className="title">{title}</div>
        </DetailsCardsHeader>
        <DetailsCardsContent>
          <div className="description">{description}</div>
          <a href={file_storage} target="_blank" rel="noopener noreferrer"><div className="icon"><img
              src="/images/pdf.png"
              alt=""
              width="50"
              className="icon"
            /></div></a>
          {
            video_storage?
            <a href={video_storage} target="_blank" rel="noopener noreferrer"><div className="icon"><img
              src="/images/youtube.png"
              alt=""
              width="50"
              className="icon"
            /></div></a>
            : ''
          }
        </DetailsCardsContent>
      </DetailsCards>
    );
  }
}
