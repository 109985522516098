import React from "react";
const CallIcon = (props) => {
  return (
    <svg
      width="60px"
      height="40px"
      viewBox="0 0 60 40"
      fill="#0077E2"
      {...props}
    >
      <polygon
        id="Shape"
        fillRule="nonzero"
        points="12.6724138 9.33333333 12.6724138 11.6666667 21.9514368 11.6666667 5.63218391 25.1883333 7.61752874 26.8333333 23.9367816 13.3116667 23.9367816 21 26.7528736 21 26.7528736 9.33333333"
      ></polygon>
    </svg>
  );
};

export default CallIcon;
