import styled from "styled-components";

export const View = styled.div`
  padding: 16px 50px 60px 50px;
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 100%) no-repeat;
  background-size: auto 470px;
  //min-height: 90%;
  min-height: calc(100% - 60px);
  box-sizing: border-box;
`;

export const ViewTitle = styled.div`
  color: #ffffff;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 55px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;

  &.margin-title {
    margin-bottom: 50px;
  }

  & .chips {
    line-height: normal;
  }

  & span {
    flex: 1;
  }
`;
