//@flow
import * as React from "react";
import SVGIcon, { icons } from "../../shared/svg-icon";
import styled from "styled-components";
type Props = {};
type State = {
  collapsed: boolean,
};
class MiniFilter extends React.Component<Props, State> {
  node: any;

  handleOutsideClick = (e: any) => {
    if (!this.node.contains(e.target)) this.setState({ collapsed: true });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }
  state = {
    collapsed: true,
  };
  render() {
    return (
      <span ref={(node) => (this.node = node)}>
        <SVGIcon
          name={icons.filter}
          fill="black"
          height="25px"
          width="25px"
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        />
        <FilterContent
          style={{ display: this.state.collapsed ? "none" : "block" }}
        >
          {this.props.children}
        </FilterContent>
      </span>
    );
  }
}

export default MiniFilter;

const FilterContent = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
`;
