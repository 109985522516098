import React from "react";
const Truck = (props) => {
  return (
    <svg viewBox="0 0 21 17 " width="21px" height="20px" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.03333333,17 C2.38436887,17 1.04761905,15.6680471 1.04761905,14.025 C1.04761905,12.3819529 2.38436887,11.05 4.03333333,11.05 C5.33336515,11.05 6.43933578,11.877893 6.84918607,13.0334215 L10.0037667,13.0333333 L10.0047619,2.125 C10.0047619,1.57731762 10.4503452,1.13333333 11,1.13333333 L14.91092,1.13333333 C15.909203,1.13333333 16.8414365,1.63045921 17.3951844,2.45810025 L20.4509311,7.02527193 C20.7779017,7.51396776 20.952381,8.08816566 20.952381,8.67550504 L20.952381,14.025 C20.952381,14.5726823 20.5067976,15.0166667 19.9571429,15.0166667 L17.7967739,15.0166667 L17.7926036,15.0283448 C17.3794375,16.177723 16.2765674,17 14.9809524,17 C13.6853374,17 12.5824673,16.177723 12.1693012,15.0283448 L12.1651309,15.0166667 L6.84883779,15.0175599 C6.43870951,16.1725757 5.33299707,17 4.03333333,17 Z M15.1904762,14.7333333 C14.9011842,14.7333333 14.6666667,14.9870387 14.6666667,15.3 C14.6666667,15.6129613 14.9011842,15.8666667 15.1904762,15.8666667 C15.4797682,15.8666667 15.7142857,15.6129613 15.7142857,15.3 C15.7142857,14.9870387 15.4797682,14.7333333 15.1904762,14.7333333 Z M3.66666667,14.7333333 C3.37737465,14.7333333 3.14285714,14.9870387 3.14285714,15.3 C3.14285714,15.6129613 3.37737465,15.8666667 3.66666667,15.8666667 C3.95595868,15.8666667 4.19047619,15.6129613 4.19047619,15.3 C4.19047619,14.9870387 3.95595868,14.7333333 3.66666667,14.7333333 Z M20.4317143,14.2669667 L20.4288313,14.1200095 L20.4254286,14.0663667 L20.4317143,14.2669667 Z M14.148619,14.2346667 L14.1370952,14.0986667 L14.1462808,14.2113164 L14.148619,14.2346667 Z"
        id="Shape"
        fill={props?.fill || "#AFC8FF"}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.75,3.4 L13.75,7.36666667 L15.8452381,7.36666667 L17.9404762,7.36666667 L17.9404762,6.23333333 C17.6135463,5.67836019 17.3683489,5.28799884 17.2048839,5.06224929 C16.4024623,3.9540831 15.6000407,3.4 14.797619,3.4 C13.75,3.4 13.4007937,3.4 13.75,3.4 Z"
        id="Path"
        fill="#ffffff"
      />
    </svg>
  );
};

export default Truck;
