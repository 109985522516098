import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../shared/controls/text-input';
import Panel from '../../shared/component/panel';
import { putUpdateAccount, RECEIVE_UPDATE_ACCOUNT, ERROR_UPDATE_ACCOUNT } from '../../redux/reducers/register-account.reducer';
import InputProfileImage from '../../shared/controls/input-profile-image';
import Button from '../../shared/controls/button';
import { getUrlsEnv } from '../../shared/backend-api';
import ErrorDialog from '../../shared/dialog/error-dialog';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { validators } from '../../shared/validate';
import ChangePasswordDialog from '../../shared/dialog/change-password-modal';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

let user = null
let profileImage;

class UserData extends Component {

  constructor(props) {
    super(props);
    let storage = JSON.parse(window.sessionStorage.getItem('orbinetwork_session'));
    user = storage.user;

    const { files } = getUrlsEnv();

    profileImage = `${files}images/users/pictures/${user.id}.jpg`

    let companyIdToShow;

    if (storage.company && storage.company.id) {
      companyIdToShow = [storage.company.id];
    }

    this.state = {
      name: props.name || user.first_name || '',
      lastName: props.lastName || user.last_name || '',
      email: props.email || user.email || '',
      username: props.username || user.username || '',
      password: '111111',
      companySelected: props.companySelected || (companyIdToShow ? companyIdToShow : []),
      profileImage: props.profileImage || null,
      showError: false,
      msgError: 'Compruebe los datos',
      onFocused: false,
      showChangePasswordModal: false,
      crop: {
        unit: '%',
        width: 300,
        aspect: 1 / 1,
      },
      editImage: false,
      imageToCrop: null
    }
  }

  isValid(url) {
    fetch(url).then(response => this.setState({ profileImage: response.status === 200 ? profileImage : null }))
  }

  componentDidMount() {
    this.isValid(profileImage);
  }

  isValidForm = () => {
    const { name, lastName, email, username, password, profileImage } = this.state;
    return name !== '' && lastName !== '' && email !== '' && username !== '' && password !== '' && profileImage !== null;
  }

  isCompanyIdSelected = companyId => {
    const { companySelected } = this.state;
    return companySelected.includes(companyId);
  }

  actionCloseDialog = () => {
    this.setState({
      showError: false
    });
  };

  addCompanyComponent = () => (
    {
      id: -1,
      description: "Añadir otra empresa",
      image: "images/factory-bckgrnd.png"
    }
  )

  handleClickCompany = (company, newId) => {

    const { companySelected } = this.state;

    if (this.isCompanyIdSelected(company.id)) {

      this.setState({
        companySelected: companySelected.filter(id => id !== company.id)
      });

    } else {

      this.setState({
        companySelected: companySelected.concat(newId)
      });

    }

  }

  handlePicture = () => {
    this.refs.fileUploader.value = '';
    this.refs.fileUploader.click();
  }
  
  handleSave = () => {

    const { name, lastName, companySelected, profileImage, username } = this.state;

    this.props.updateProfile(user.id, user.email, {
      first_name: name,
      last_name: lastName,
      companies: companySelected,
      profile_image: profileImage,
      user_name: username
    }).then(
      response => {
        switch (response.type) {
          case RECEIVE_UPDATE_ACCOUNT:
            let newStorage = JSON.parse(window.sessionStorage.getItem('orbinetwork_session'));
            let newUser = response.data;

            newUser.id = user.id;
            newStorage.user = newUser;

            window.sessionStorage.setItem('orbinetwork_session', JSON.stringify(newStorage));
            this.props.history.goBack()
            break;
          case ERROR_UPDATE_ACCOUNT:
            this.setState({ showError: true, msgError: "Comprueba los datos" });
            break
          default:
            this.setState({ showError: true, msgError: "Falló la conexión" })
            break;
        }
      }
    )
  }

  handleCancel = () => {
    this.props.history.goBack()
  }

  dragAndDrop = e => {

    e.preventDefault()
    e.stopPropagation()
    let dt = e.dataTransfer
    let files = dt.files

    let pictureFrame = document.getElementsByClassName("pictureFrame")
    let pictureButton = document.getElementsByClassName("btnPicture")

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)"

        pictureButton[0].style.color = "#3D77F7"
        pictureButton[0].style.border = "2px solid #ffffff"
        pictureButton[0].style.backgroundColor = "#ffffff"
        break
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)"

        pictureButton[0].style.color = "rgb(232, 240, 255)"
        pictureButton[0].style.border = "2px solid white"
        pictureButton[0].style.background = "none"

        var reader = new FileReader();
        reader.addEventListener('load', () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true
          });
        })
        reader.readAsDataURL(files[0]);
        break;
    }
  }

  handleLeave = () => {
    this.setState({ onFocused: true })
  }

  getErrors = () => {
    let errors = ''

    if (this.state.name === '' ||
      this.state.lastName === '') {
      errors = 'Por favor completa los siguientes campos:<br/>'
    }

    errors += this.state.name === '' ? `<b>Nombre<b/><br/>` : ''
    errors += this.state.lastName === '' ? `<b>Apellidos<b/><br/>` : ''

    return errors
  }

  render() {

    return (
      <div className="register">

        <input accept="image/*" type="file" id="file" ref="fileUploader" style={{ display: "none" }} onChange={event => {

          event.stopPropagation();
          event.preventDefault();

          var reader = new FileReader();
          reader.addEventListener('load', () => {
            this.setState({
              imageToCrop: reader.result,
              editImage: true
            });
          })
          reader.readAsDataURL(event.target.files[0]);

        }} />

        <h1 className="title">Información de usuario</h1>

        <Panel title='Datos de perfil' number='01' className="panel">

          <div className="form">
            <div className="data-panel">
              <div className="basic-data">
                <TextInput
                  type='text'
                  label='*Nombre'
                  name='name'
                  className="half"
                  onChange={event => {
                    const { target: { value: name = '' } } = event;
                    this.setState({ name });
                  }}
                  value={this.state.name}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                <TextInput
                  type='text'
                  label='*Apellidos'
                  name='lastName'
                  className="half"
                  onChange={event => {
                    const { target: { value: lastName = '' } } = event;
                    this.setState({ lastName });
                  }}
                  value={this.state.lastName}
                  validate={[validators.required]}
                  onFocused={this.state.onFocused}
                />
                {/* <TextInput
                                    type='email'
                                    label='Correo'
                                    name='email'
                                    className="full"
                                    onChange = { event => {
                                        const { target: { value: email = '' } } = event;
                                        this.setState({ email });
                                    }}
                                    value = { this.state.email }
                                    disabled = {true}
                                /> */}

                <TextInput
                  type='text'
                  label='Usuario'
                  name='username'
                  className="half"
                  onChange={event => {
                    const { target: { value: username = '' } } = event;
                    this.setState({ username });
                  }}
                  value={this.state.username}
                  disabled={true}
                />

                <TextInput
                  type='password'
                  label='Contraseña'
                  name='password'
                  className="half"
                  onChange={event => {
                    const { target: { value: password = '' } } = event;
                    this.setState({ password });
                  }}
                  value={this.state.password}
                  disabled={true}
                  onClick={() => this.setState({ showChangePasswordModal: true, showError: false })}
                />

              </div>
            </div>

            <InputProfileImage marginStyle={{ margin: "-91px -16px 0px 22px" }}
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              handleSave={this.handleSave}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar tu foto o bien, arrástrala desde tu computadora:" />

          </div>

        </Panel>
        {/* 
                <Panel title='Empresa' number='02' >

                    <div className="companies">
                    {
                        this.props.error ? <p className="error"> {this.props.error} </p> :
                        this.props.companies.map( (c, index) => {

                            const {files} = getUrlsEnv();

                            let newCompany ={
                                id: c.id,
                                description: c.corporation_name,
                                image: `${files}${c.logo}`
                            }

                            return (
                            
                                <SelectImage 
                                    data={ newCompany } 
                                    key={ index } 
                                    isSelected={ this.isCompanyIdSelected(c.id) } 
                                    handleClick={ id => this.handleClickCompany(c, id) }/>
    
                            );

                        })
                    }
                    <SelectImage 
                        data={ this.addCompanyComponent() } 
                        key={ this.props.companies.lenght }
                        withButton={true}
                        />

                    </div>
                    
                </Panel> */}

        <div className="contentButton">
          <Button
            text={'Cancelar'}
            type={'secondary'}
            onClick={this.handleCancel}
          />
          <div className="separator" />
          <Button
            text={'Guardar'}
            type={'primary'}
            disabled={!this.isValidForm()}
            onClick={this.handleSave}
            dataTip={this.getErrors()}
            dataFor="orbi-tooltip-error"
            onMouseLeave={() => this.handleLeave()}
          />

          <ReactTooltip
            id={"orbi-tooltip-error"}
            place="left"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />

        </div>

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ChangePasswordDialog
          open={this.state.showChangePasswordModal}
          title='¡Cambiar contraseña!'
          message=''
          acceptText="Guardar"
          acceptAction={() => { this.setState({ showChangePasswordModal: false, showError: false }) }}
          closeAction={() => { this.setState({ showChangePasswordModal: false, showError: false }) }}
        />
        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={'Edita tu imagen'}
          message={'Edita tu imagen'}
          acceptText={'Guardar'}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin='anonymous'
              style={{ maxHeight: '340px', overflow: 'auto', margin: '10px' }}
            />
          }
        />
      </div>
    );

  };


  closeImageEditModal = () => {
    this.setState({ editImage: false })
  }
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false
    });
  }
  onImageLoaded = (image) => {
    this.imageRef = image;
  }
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  }
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], 'image.jpeg');
        resolve(f);
      }, 'image/jpeg');
    });
  }

}

const mapStateToProps = state => {
  const { registerAccount: { companies = [], error } } = state;
  return { companies, error };
}

const mapDispatchToProps = dispatch => ({
  updateProfile: (userId, email, params) => dispatch(putUpdateAccount(userId, email, params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserData))