import React from "react";
import PropTypes from "prop-types";

class Selector extends React.Component {
  render() {
    let el = React.Children.toArray(this.props.children).map(e =>
      React.cloneElement(e, {
        className: `${
          this.props.selectionModeOn ? this.props.selectionModeClass : ""
        } ${
          this.props.selectedItems.includes(e.props.id)
            ? this.props.selectedItemClass
            : ""
        }`,
        onClick: this.props.selectionModeOn
          ? this.props.onSelect
          : e.props.onClick
      })
    );
    // console.log('SELECTOR CHILDREN ', el);

    if (this.props.selectorStyle) {
      return (
        <div className={"selector ".concat(this.props.className)}>
          {this.props.showSelector ? (
            <div className="toggle-select-button" style={this.props.selectorStyle}>
              <span onClick={this.props.toggleSelectionMode}>
                {this.props.selectionModeOn
                  ? "Salir de modo de selección"
                  : "Seleccionar varios"}
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="items-container">{el}</div>
        </div>
      );

    } else {
      return (
        <div className={"selector ".concat(this.props.className)}>
          {this.props.showSelector ? (
            <div className="toggle-select-button">
              <span onClick={this.props.toggleSelectionMode}>
                {this.props.selectionModeOn
                  ? "Salir de modo de selección"
                  : "Seleccionar varios"}
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="items-container">{el}</div>
        </div>
      );
    }

  }

  static propTypes = {
    selectionModeOn: PropTypes.bool,
    selectedItemClass: PropTypes.string,
    selectionModeClass: PropTypes.string,
    selectedItems: PropTypes.arrayOf(PropTypes.number),
    toggleSelectionMode: PropTypes.func,
    onSelect: PropTypes.func,
    showSelector: PropTypes.bool
  };
}

export default Selector;

Selector.defaultProps = {
  selectionModeOn: false,
  toggleSelectionMode: () => {
    console.log("Not yet implemented");
  },
  onSelect: () => {
    console.log("Not yet implemented.");
  },
  selectedItemClass: "",
  selectionModeClass: "",
  selectedItems: [],
  showSelector: true,
  className: ""
};
