import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from 'redux-logger'
import apiMiddleware from "./api/api-middleware";
import rootReducer from "./reducers";

// const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const loggerMiddleware = createLogger()

export default createStore(
  rootReducer,
  // composeEnhacers(
  applyMiddleware(
    thunkMiddleware,
    apiMiddleware
    // loggerMiddleware
  )
  // )
);
