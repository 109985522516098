import React from "react";
import Button from "../../../shared/controls/button";
import styled from "styled-components";

class SubscriptionInfoView extends React.Component {

  buildFooterInfo = () => {
    let plans = this.props.plansInfo.plans;

    return(
      <FooterInfoContainerView>

        <FooterInfoDividerView>
          {'Precio en orbibucks'}
        </FooterInfoDividerView>

        <FooterInfoPricesColumnContainer>
          {
            plans.map( (plan, index) => {
              return(
                this.buildPricesColumn(plan.prices_description, index)
              );
            })
          }
        </FooterInfoPricesColumnContainer>
      </FooterInfoContainerView>
    );
  }

  buildPricesColumn = (prices, index) => {
    return(
      <FooterInfoPricesColumn
        key={`prices-column-${index}`}
      >
        {
          prices.map( (item, index_2) => {
            return(
              <FooterInfoPricesColumnDescription
                key={`prices-column-${index}-item-${index_2}`}
              >
                {item}
              </FooterInfoPricesColumnDescription>
            );
          })
        }
      </FooterInfoPricesColumn>
    );
  }
  
  buildHeaders = () => {
    return(
      <HeaderContainerView>

        <HeaderContainerTitleView>
          <div>
            {'Servicios de la'}
          </div>
          <div>
            {'suscripción'}
          </div>
        </HeaderContainerTitleView>

        {
          this.props.plansInfo.plans.map( (plan, index) => {
            return this.buildColumnHeader(plan, index)
          })
        }
      </HeaderContainerView>
    );
  }

  buildColumnHeader = (plan, index) => {

    return(
      <ColumnHeaderContainerView
        key={`header-container-${index}`}
      >
        <ColumnHeaderContainerTitleView>{plan.name}</ColumnHeaderContainerTitleView>

        <ColumnHeaderContainerInfoView>

          <ColumnHeaderInfoMainTitleView>{'Licencias para empresas pequeñas'}</ColumnHeaderInfoMainTitleView>

          { this.props.currentPlan && this.buildBuyPlanDescription(plan) }

          { this.buildPriceDescription(plan) }

        </ColumnHeaderContainerInfoView>

      </ColumnHeaderContainerView>
    );
  }

  buildBuyPlanDescription = (plan) => {

    if (plan.free) {
      return(
        <ColumnHeaderInfoPlanDescriptionView>
          {
            (this.props.currentPlan && this.props.currentPlan.id === 2) && 
            <>
              <img
                alt=""
                src="/images/check.svg"
              />
              <div>
                {'Plan actual'}
              </div>
            </>
          }
        </ColumnHeaderInfoPlanDescriptionView>
      );
    } else {
      return(
        <ColumnHeaderInfoPlanDescriptionView>
          {
            (this.props.currentPlan && this.props.currentPlan.id === 13) ? 
            <>
              <img
                alt=""
                src="/images/check.svg"
              />
              <div>
                {'Plan actual'}
              </div>
            </> :
            <Button
              text="Agrega plan"
              type="primary small"
              onClick={() => {
                if (this.props.addPlan) {
                  this.props.addPlan(plan)
                }
              }}
            />
          }
        </ColumnHeaderInfoPlanDescriptionView>
      );
    }

  }

  buildPriceDescription = (plan) => {

    if (plan.free) {
      return(
        <ColumnHeaderInfoPriceDescriptionView>
          {'Paga por cada viaje'}
        </ColumnHeaderInfoPriceDescriptionView>
      );
    } else {
      return(
        <ColumnHeaderInfoPriceDescriptionView>
          {plan.price_plan_description}
        </ColumnHeaderInfoPriceDescriptionView>
      );
    }

  }

  buildServicesList = () => {

    let plans = this.props.plansInfo.plans;
    let services = this.props.plansInfo.services;

    return(
      <ServicesListContainerView>
        {
          services.map( (service, index) => {
            return( this.buildServiceRow(service, plans, index) );
          })
        }
      </ServicesListContainerView>
    );
  }

  buildServiceRow = (service, plans, index) => {
    return(
      <ServicesListServiceRowContainerView
        key={`service-list-container-${index}`}
      >

        <ServicesListServiceRowServiceDescription>{service.description}</ServicesListServiceRowServiceDescription>

        {
          plans.map( (plan, index) => {
            return(
              <ServicesListServiceRowServiceCheckView
                key={`service-list-container-check-${index}`}
              >
                {
                  plan.services_activated.includes(service.id) ? 
                  <img
                    alt=""
                    src="/images/check.svg"
                  /> : ''
                }
              </ServicesListServiceRowServiceCheckView>
            );
          })
        }
      </ServicesListServiceRowContainerView>
    );
  }

  render() {
    return(
      <div>
        { this.buildHeaders() }
        { this.buildServicesList() }
        { this.buildFooterInfo() }
      </div>
    );
  }

}

export default SubscriptionInfoView

////////// style

const HeaderContainerView = styled.div`
  display: flex;
  background-color: #1a202e;
  border-top-left-radius: 12px;
`;

const HeaderContainerTitleView = styled.div`
  color: white;
  font-size: 20px;
  width: 301px;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ColumnHeaderContainerView = styled.div`
  width: 250px;
`;

const ColumnHeaderContainerTitleView = styled.div`
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #313A52;
`;

const ColumnHeaderContainerInfoView = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  background-color: #697EA6;
`;

const ColumnHeaderInfoMainTitleView = styled.div`
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`;

const ColumnHeaderInfoPlanDescriptionView = styled.div`
  color: rgb(5,201,133);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ColumnHeaderInfoPriceDescriptionView = styled.div`
  font-size: 14px;
  color: black;
`;

const ServicesListContainerView = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

const ServicesListServiceRowContainerView = styled.div`
  display: flex;
`;

const ServicesListServiceRowServiceDescription = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgb(53, 67, 93);
  font-size: 14px;
  border-bottom-color: #92A5C7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const ServicesListServiceRowServiceCheckView = styled.div`
  width: 250px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #52627F;
  border-bottom-color: #92A5C7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: #92A5C7;
  border-right-style: solid;
  border-right-width: 1px;
`;

const FooterInfoContainerView = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
`;

const FooterInfoDividerView = styled.div`
  background-color: #4C5A75;
  height: 30px;
  color: white;
  font-size: 14px;
  border-bottom-color: #92A5C7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterInfoPricesColumnContainer = styled.div`
  display: flex;
`;

const FooterInfoPricesColumn = styled.div`
  width: 250px;
`;

const FooterInfoPricesColumnDescription = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #52627F;
  border-bottom-color: #92A5C7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-color: #92A5C7;
  border-right-style: solid;
  border-right-width: 1px;
  color: white;
  font-size: 12px;
  text-align: center;
`;