// @flow

type RibbonDatesLabel = "MONTH" | "WEEKDAY";

export const getRibbonWeekDates = (
  currentDate: Date,
  label: RibbonDatesLabel
): Array<{ id: string | number, description: string }> => {
  let c = 1;
  let dat = currentDate;
  let d = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate());
  d.setDate(d.getDate() - d.getDay());
  let x: Array<{ id: string | number, description: string }> = [];
  while (c <= 7) {
    x.push({
      id: d.toISOString().split("T")[0],
      description:
        label === "MONTH"
          ? d.toString().split(" ").slice(1, 3).join().replace(",", "\n")
          : DAYS[d.getDay()].concat(" ", d.getDate()),
    });
    d.setDate(d.getDate() + 1);
    c++;
  }

  let y = [{ id: -7, description: "❰" }];
  y = y.concat(x);
  y.push({ id: 7, description: "❱" });
  return y;
};

const DAYS = {
  "0": "Dom",
  "1": "Lun",
  "2": "Mar",
  "3": "Mie",
  "4": "Jue",
  "5": "Vie",
  "6": "Sab",
};