import React from "react";
import CatalogHeader, {
  GRID,
  LIST,
} from "../../shared/component/catalog-header";
import FilterBar from "../../shared/component/filter/filter-bar";
import { connect } from "react-redux";
import {
  loadSession,
} from "../../redux/reducers/global-catalog.reducer";
import {
  filterUsersByText,
  getHistoricalUsers,
  getHistoricalUsersDownload,
} from "../../redux/reducers/user-reducer";
import { getUrlsEnv } from "../../shared/backend-api";
import CatalogRow from "../../shared/component/catalog-row";
import { normalizeStr } from "../../shared/utils";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import * as moment from "moment";
import "moment/locale/es";
import { DownloadIcon } from "../routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import Axios from "axios";
import PaginatorControlled from "../../shared/controls/paginator-controlled";

const defaultPaginatorItems = 10;

class UserHistorical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      limit: 10,
      offset: 1,

      resetPaginator: false,

      resultModalText: "",
      resultModalTitle: "",

      selectionModeOn: false,
      selectedUsersIds: [],
      selectedInvitationsIds: [],

      userTypes: [],
      connectionStatus: [],
      facilities: [],
      tags: [],
      permissions: [],
      accountStatus: [],
      searchStr: "",
      selected_invitation_id: 0,

      buttonFilterName: "Aplicar",
      filterDate: []
    };

    this.loadingDocument = false;
  }

  componentDidMount() {
    this.setState({ ...this.props.configuration });
    this.loadUsers();
  }

  componentWillUnmount() {
    this.props.setConfiguration({ ...this.state });
  }

  loadUsers = () => {
    var startDate = '';
    var endDate = '';

    if (this.state.filterDate.length > 1) {
      if (this.state.filterDate[0] !== null) {
        const startDateMonth = this.state.filterDate[0].getMonth() + 1
        const startDateDay = this.state.filterDate[0].getDate()
        startDate = `${this.state.filterDate[0].getFullYear()}-${ startDateMonth < 10 ? `0${startDateMonth}` : `${startDateMonth}`}-${ startDateDay < 10 ? `0${startDateDay}` : `${startDateDay}`}`;
      }

      if (this.state.filterDate[1] !== null) {
        const endDateMonth = this.state.filterDate[1].getMonth() + 1
        const endDateDay = this.state.filterDate[1].getDate()
        endDate = `${this.state.filterDate[1].getFullYear()}-${ endDateMonth < 10 ? `0${endDateMonth}` : `${endDateMonth}`}-${ endDateDay < 10 ? `0${endDateDay}` : `${endDateDay}`}`;
      }

    } 
    
    if (startDate === '' && endDate === '') {
      const date = new Date();
      const date2 = new Date();
      date2.setDate(date2.getDate() - 15);

      const startDateMonth = date2.getMonth() + 1
      const startDateDay = date2.getDate()
      startDate = `${date2.getFullYear()}-${startDateMonth < 10 ? `0${startDateMonth}` : `${startDateMonth}`}-${startDateDay < 10 ? `0${startDateDay}` : `${startDateDay}`}`;

      const endDateMonth = date.getMonth() + 1
      const endDateDay = date.getDate()

      endDate = `${date.getFullYear()}-${endDateMonth < 10 ? `0${endDateMonth}` : `${endDateMonth}`}-${endDateDay < 10 ? `0${endDateDay}` : `${endDateDay}`}`;
    } else if (endDate === '') {
      endDate = startDate;
    }

    this.props.reloadUsers(
      this.state.limit,
      this.state.offset,
      this.state.userTypes,
      this.state.facilities,
      this.state.tags,
      this.state.accountStatus,
      this.state.searchStr,
      startDate,
      endDate
    );
  };

  toggleViewMode = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };

  redirectToProfile = (e) => {
    //this.props.history.push(`/user/profile/${e.target.name}`);
  };

  //FILTERS
  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  handleFilter = (limit = defaultPaginatorItems, offset = 1) => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Restablecer" }, () => this.loadUsers() );
    } else {
      this.setState({ resetPaginator: true, limit, offset, buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      userTypes: [],
      facilities: [],
      tags: [],
      accountStatus: []
    }, () => this.loadUsers() );
  }

  handlePaginator = (limit = defaultPaginatorItems, offset = 1) => {
    this.setState({ limit, offset }, () => this.loadUsers());
  };

  filterContents = () => [
    {
      title: "Fecha",
      name: "filterDate",
      listType: FILTER_LIST_TYPES.dateRangeFixed,
    },
  ];

  getUserDate = (dateString) => {
    const dateData = moment(dateString);
    return dateData.format('HH:mm A/ DD MMM YYYY');
  }

  getActionDescription = (action, info, status) => {

    if (action.toLowerCase().includes('ingreso a sistema')) {
      return `${action} - ${status}`
    } else {
      return `${action} - ${info}`
    } 

  }

  getRow = (rowItem) => {
    return (
      <div className="row-content">
        <div className="historical-data">
          { this.getUserDate( rowItem?.date || '' ) }
        </div>  
        <div
          className={"contact"}
        >
          <div
            className={"image ".concat(rowItem.avatar ? "" : "default")}
            style={{
              minWidth: 48,
              backgroundImage: "url(".concat(
                getAvatarUrl(rowItem.avatar),""
              ),
            }}
          >
          </div>
          <div className="contact-data" style={{display: 'center', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 14, overflowX: 'hidden', whiteSpace: 'pre-wrap', overflowWrap: 'anywhere'}}>
            {rowItem?.user || ''}
          </div>
        </div>
        <div className={ this.getRoleClass(rowItem?.user_type || '') } style={{width: '20%'}}>
          {rowItem?.user_type || '-'}
        </div>
        <div className="historical-details" style={{maxHeight: "65px", overflowY: "scroll", display: (rowItem?.action || '').toLowerCase().includes('invitación usuario') ? 'block' : 'flex'}}>
          { this.getActionDescription(rowItem?.action || '-', rowItem?.information || '',rowItem?.status || '') }
        </div>  
      </div>
    );
  };

  getRoleClass = (value) => {
    if (value.toLowerCase().includes('admin')) {
      return "role user-type-1";
    } else {
      return "role user-type-2"
    }
  }

  openDocument = () => {

    var startDate = '';
    var endDate = '';

    if (this.state.filterDate.length > 1) {
      if (this.state.filterDate[0] !== null) {
        const startDateMonth = this.state.filterDate[0].getMonth() + 1
        const startDateDay = this.state.filterDate[0].getDate()
        startDate =`${this.state.filterDate[0].getFullYear()}-${ startDateMonth < 10 ? `0${startDateMonth}` : `${startDateMonth}`}-${ startDateDay < 10 ? `0${startDateDay}` : `${startDateDay}`}`;
      }

      if (this.state.filterDate[1] !== null) {
        const endDateMonth = this.state.filterDate[1].getMonth() + 1
        const endDateDay = this.state.filterDate[1].getDate()
        endDate = `${this.state.filterDate[1].getFullYear()}-${ endDateMonth < 10 ? `0${endDateMonth}` : `${endDateMonth}`}-${ endDateDay < 10 ? `0${endDateDay}` : `${endDateDay}`}`;
      }
      
    } 
    
    if (startDate === '' && endDate === '') {
      const date = new Date();
      const date2 = new Date();
      date2.setDate(date2.getDate() - 15);

      const startDateMonth = date2.getMonth() + 1
      const startDateDay = date2.getDate()
      startDate = `${date2.getFullYear()}-${startDateMonth < 10 ? `0${startDateMonth}` : `${startDateMonth}`}-${startDateDay < 10 ? `0${startDateDay}` : `${startDateDay}`}`;

      const endDateMonth = date.getMonth() + 1
      const endDateDay = date.getDate()

      endDate = `${date.getFullYear()}-${endDateMonth < 10 ? `0${endDateMonth}` : `${endDateMonth}`}-${endDateDay < 10 ? `0${endDateDay}` : `${endDateDay}`}`;
    } else if (endDate === '') {
      endDate = startDate;
    }

    const downloadUrl = getHistoricalUsersDownload(
      this.state.limit,
      this.state.offset,
      this.state.userTypes,
      this.state.facilities,
      this.state.tags,
      this.state.accountStatus,
      this.state.searchStr,
      startDate,
      endDate
    );

    const url = getUrlsEnv().api.concat("/", downloadUrl);

    if (this.loadingDocument === true) return;

    this.loadingDocument = true;

    Axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Historial.xlsx");
      document.body.appendChild(link);
      link.click();
      this.loadingDocument = false;
    });
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  render() {
    return (
      <div className="catalog" onClick={this.clickOutside}>
        
        <CatalogHeader
          viewMode={this.state.viewMode}
          title={"Historial"}
          actionButtons={[]}
          showViewMode={false}
        >
          <DownloadIcon data-tip data-for={"download-icon"}>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              onClick={() =>
                this.openDocument()
              }
            />
          </DownloadIcon>
        </CatalogHeader>


        <FilterBar
          searchAction={this.handleTextSearch}
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          onChange={this.handleFilterChange}
          searchButtonText={this.state.buttonFilterName}
          content={this.filterContents()}
          selectedItems={{
            filterDate: this.state.filterDate
          }}
        />

        <div className={"content ".concat(this.state.viewMode)}>
          
          <div className="user-row-header">
            <div className="historical-data">Fecha</div>
            <div className="historical-data">Usuario</div>
            <div className="historical-data">Rol</div>
            <div className="historical-details">Descripción del evento</div>
          </div>
        
          {
            (this.props.isLoadingUsers === true) ?
            <div style={{
              height: 140,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: 18
            }}>
              Cargando...
            </div>
            :
            <div style={{marginLeft: '2%', marginRight: '2%'}} >
              {Array.isArray(this.props.users) &&
                this.props.users.slice( (this.state.offset - 1) * this.state.limit, this.state.offset * this.state.limit).map((user) => {
                  return this.state.viewMode === GRID ? (
                    <div></div>
                  ) : (
                    <CatalogRow
                      key={
                        user.status_id === 5 ||
                        user.status_id === 1 ||
                        user.status_id === 3
                          ? "invitation-".concat(user.invitation_id)
                          : user.user_id
                      }
                      defaultClass={"user-row ".concat(
                        "user-type-",
                        user.user_type,
                        " user-status-",
                        user.status_id
                      )}
                      id={
                        user.status_id === 5 ||
                        user.status_id === 1 ||
                        user.status_id === 3
                          ? "invitation-".concat(user.invitation_id)
                          : user.user_id
                      }
                      selected={() => {}}
                      onClick={() =>
                        this.redirectToProfile({
                          target: {
                            name: user.user_id,
                            value: {
                              status_id: user.status_id,
                              invitation_type: user.invitation_type,
                            },
                          },
                        })
                      }
                    >
                      {this.getRow(user)}
                    </CatalogRow>
                  );
                })}
            </div> 
          }

          <PaginatorControlled
            itemCount={this.props.userCount}
            onChange={(e) => this.setState({ [e.target.name]: e.target.value})}
            limit={this.state.limit}
            offset={this.state.offset}
          />
          <div style={{height: 20}} />
        </div>
        
      </div>
    );
  }
}

const getAvatarUrl = (partialUrl) => {
  if (partialUrl) {
    return getUrlsEnv().files.concat(partialUrl);
  }
  return "/images/user.svg";
};

const mapStateToProps = (state) => {
  let searchStr = normalizeStr(state.userReducer.searchStr);
  const isLoadingUsers = state?.userReducer?.historical?.isFetching || false;

  let users = (
    (Array.isArray(state.userReducer.historical.data) && state.userReducer.historical.data) ||
    []
  ).filter(
    (u) =>
      normalizeStr(u.full_name).includes(searchStr) ||
      normalizeStr(u.email).includes(searchStr) ||
      (u.facilities || []).some((f) =>
        normalizeStr(f.name).includes(searchStr)
      ) ||
      (u.role_id || []).some((g) => normalizeStr(g.title).includes(searchStr))
  ).sort( (a, b) => {
    const aDate = a?.date || '';
    const bDate = b?.date || ''; 
    return moment(aDate).isBefore(bDate) ? 1 : -1;
  });

  const configuration = state.configuration.UserHistorical;
  return {
    isLoadingUsers,
    configuration,
    searchStr: state.userReducer.searchStr,
    users: users,
    userCount: users.length
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    reloadUsers: (
      limit,
      offset,
      userType,
      facilities,
      role,
      account_status,
      searchStr,
      startDate,
      endDate
    ) =>
      dispatch(
        getHistoricalUsers(
          limit,
          offset,
          userType,
          facilities,
          role,
          account_status,
          searchStr,
          startDate,
          endDate
        )
      ),
    searchByTextUser: (text) => dispatch(filterUsersByText(text)),
    setConfiguration: (config) => dispatch(setConfig("UserHistorical", config)),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default connectToStore(UserHistorical);

UserHistorical.defaultProps = {
  users: [],
  userCount: 0,
  userTypes: [],
  connectionStatus: [],
  facilities: [],
  tags: [],
  permissions: [],
  accountStatusCatalog: [],
  reloadUsers: () => {
    console.log("Not yet implemented");
  },
  searchByTextUser: () => {
    console.log("Not yet implemented");
  },
};
