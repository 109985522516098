import React, { Component } from 'react';
import Button from '../controls/button';
export default class Tooltip extends Component {


  handleClose = () => {
    if (this.props.onClose)
      this.props.onClose();
  }
  handleAction = () => {
    if (this.props.onAction)
      this.props.onAction();
  }

  render() {
    return (
      <div className={'tooltip '.concat(this.props.className || '', this.props.isOpen ? ' open' : '')}>
        <div className='close-button'>
          <Button
            settings={{
              type: 'circle close active',
              text: 'x',
              onClick: this.handleClose
            }}
          />
        </div>
        <div className='title'>{this.props.title}</div>
        <div className='content'>
          {this.props.content}
        </div>
        <div className='actions'>
          <Button
            settings={{
              type: 'primary small',
              text: this.props.actionText,
              onClick: this.handleAction
            }}
            disabled={!this.props.selectedItem}
          />
        </div>

      </div>
    )
  }
}

