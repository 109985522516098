import styled from 'styled-components'

export const Wrapper = styled.div`
  // height: 100%;
  width: 100%;
  background: #3d4a69;
  // padding-bottom: 40px;
  position: relative;
`

export const TimelineWrapper = styled.div`
  width: 99%;
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
`

export const TimelineSubtitle = styled.div`
  line-height: 50px;
  color: #fff;
  font-weight: bold;
  margin-left: 15px;
  border-top: 5px solid #242e42;
  margin-top: 10px;
  font-size: 19px;
`

export const TimelineSubtitleExtra = styled.span`
  font-size: 13px;
  opacity: .6;
`

export const Timeline = styled.div`
  // width: 85%;
  min-width: 2000px;
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0 0.4rem 0;
`

export const TimelineCaption = styled.div`
  width: ${({ width }) => width || '100%'};
  color: ${({ isCurrentTime }) => (isCurrentTime ? 'white' : '#778197')};
  display: flex;
  flex-direction: row;
  margin-right: 0.2rem;
  overflow: hidden;
`

export const RowSpaceWrapper = styled.div`
  // width: 99%;
  heigh: auto;
  background: #3d4a69;
  max-height: 530px;
  overflow: auto;
  position: relative;
  padding-right: 10px;
  box-sizing: border-box;
`