import React, { Component } from "react";
import PropTypes from "prop-types";
// import Tag from "../../controls/tag";
// import TextInput from "../../controls/text-input";
// import Checkbox from "../../controls/checkbox";
// import { normalizeStr } from "../../utils";
import { DownloadIcon } from "../../../company/routes/style";
import DownloadImage from "../../../shared/icons/routes/downloadIcon";
import Loader from "../../../shared/icons/tat/loader";
import Button from "../../controls/button";
import InputSearch from "../../controls/input-search";
import InputTagger from "../../controls/inputTagger";
import NewFilterBarContent from "./new-filter-bar-content";
import FilterBarContent from "../filter/filter-bar-content";

class NewFilterBar extends Component {
  static propTypes = {
    filterAction: PropTypes.func,
    selectedItems: PropTypes.object,
    onChange: PropTypes.func,
    searchAction: PropTypes.func,
    searchStr: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.array,
        name: PropTypes.string,
        listType: PropTypes.number
      })
    ),
    multiText: PropTypes.bool,
    disabledInputText: PropTypes.bool
  };
  state = {
    searchText: "",
    isOpen: false,
    current: ""
  };
  static defaultProps = {
    content: [],
    searchStr: "",
    selectedItems: {},
    textSearch: true,
    searchButtonText: "Aplicar",
    disableButton: false,
    showDownloadFile: false,
    downloadingFile: false,
    multiText: false,
    disabledInputText: false
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = e => {
    if (this.node.contains(e.target)) return;
    this.setState({ isOpen: false, searchText: "" });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  filterAction = () => {
    this.setState({
      isOpen: false,
      current: "",
      searchText: ""
    });
    this.props.filterAction();
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.filterAction();
    }
  }

  render() {
    return (
      <div className="filter-content" style={{backgroundColor: 'transparent'}}>
        <div ref={node => (this.node = node)} className={"filter".concat(!this.props.textSearch ? " full" : "")}>
          <NewFilterBarContent
            {...this.props}
            {...this.state}
            handleChange={this.handleChange}
          />
          {
            this.props.extra
          }
          {
            this.props.applyFilter ? <div/> :
            <div className="filter-button">
              <Button
                text={this.props.searchButtonText}
                className="small"
                onClick={this.filterAction}
                disabled={this.props.disableButton}
              />
            </div>
          }
        </div>
        {
          this.props.textSearch &&
            (this.props.multiText ? 
              !this.props.disabledInputText && <div className="filter-bar-compact search" style={{ display: "flex" }}>
                <InputTagger
                  placeholder='Buscar'
                  tags={this.props.searchStr}
                  onChange={this.props.searchAction}
                  skipMailValidation={true}
                  taggerClass={"tagger"}
                />
              </div>
              : <div className="search">
                <InputSearch
                  type="text"
                  label="Buscar"
                  name="facilitySearch"
                  className="full"
                  value={this.props.searchStr}
                  onChange={this.props.searchAction}
                  handleKeyDown={this.handleKeyDown}
                />
                <img src="/images/search.svg" alt="" className="icono" />
              </div>)
        }
        {
          this.props.showDownloadFile && <DownloadIcon style={{ marginLeft: "auto", padding: "16px" }}>
          {
            this.props.downloadingFile ? 
              <Loader width="40px" height="40px" /> :
              <DownloadImage
                fill={this.props.addDownloadIcon ? "#2F3B52" : "#FFFFFF"}
                width={"45px"}
                height={"45px"}
                onClick={(e) => {e.stopPropagation(); this.props.openDocument(this.props.getUrl())}}
              />
          }
          </DownloadIcon>
        }
      </div>
    );
  }
}

export default NewFilterBar;
