import { BACKEND, getApiUrl } from '../../shared/backend-api';

export const DATA_COMPANY = 'DATA_COMPANY';
export const ERROR_DATA_COMPANY = 'ERROR_DATA_COMPANY';
export const RECEIVE_DATA_COMPANY = 'RECEIVE_DATA_COMPANY';

const companyData = params => ({
    type: DATA_COMPANY,
    params: params
});

const errorCompanyData = params => ({
    type: ERROR_DATA_COMPANY,
    params: params
});

const receiveCompanyData = params => ({
    type: RECEIVE_DATA_COMPANY,
    params: params
});

export function getCompanyData(orbi_id) {
    return function (dispatch) {
      dispatch(companyData())
      return getApiUrl(BACKEND.PUBLIC.GET_COMPANY, { id_orbi: orbi_id.orbi_id })
        .then(
          response => response.json(),
          error => dispatch(errorCompanyData(error))
        )
        .then(
          data => dispatch(receiveCompanyData(data))
        )
    }
}