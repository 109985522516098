import styled from "styled-components";
import Button from "../../shared/controls/button";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";

export const AssembleTripCardContainer = styled.div`
  height: fit-content;
  width: 100%;
  background-color: ${({ hovered }) =>
    hovered ? "#526a99" : "rgba(50, 65, 94,1)"};
  transition: background-color 0.5s ease;

  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
`;

export const CardContent = styled.div`
  // border-color: ${({ fullCard, color }) => (fullCard ? "none" : color)};
  border-top: ${({ fullCard, color }) =>
    fullCard ? "none" : `solid 2px ${color}`};

  margin: 3px 10px;
  display: flex;

  flex-direction: ${({ fullCard }) => (fullCard ? "row" : "column")};
  ${({ fullCard }) => (fullCard ? "justify-content: space-between;" : "")}

  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`;

export const CardLine = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
`;

export const CardLineSegmentContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 15px;
  flex-direction: row;
`;

export const CardLineSegment = styled.div``;

export const CardLineItemContainer = styled.div`
  width: 100%;
  display: flex;
  height: 15px;
  flex-direction: row;
  position: relative;
`;

export const CardLineItem = styled.div`
  position: absolute;
  left: ${({ position }) => position};
  top: ${({ verticalPosition }) => verticalPosition || 0};
  z-index: 2;
`;

export const NewAssemble = styled(Button)`
  width: 200px !important;
  margin: 20px;
`;

export const AutoPageControls = styled.div`
  display: flex;
  width: calc(100% - 200px);
  justify-content: space-evenly;
  & label {
    margin-top: 20px;
    color: white;
  }
  & .select-input {
    padding-top: 0;
    margin-top: 2px;
    width: 80px;
  }
`;

export const TabSelectorRibbonStyled = styled(TabSelectorRibbon)`
  width: 60%;
`;
