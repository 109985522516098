// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import React from "react";
import CatalogHeader, {
  GRID,
  LIST,
} from "../../shared/component/catalog-header";

import FilterBar from "../../shared/component/filter/filter-bar";
import FacilityRow from "../../shared/component/facility-row";
// import { searchFacilities } from "../../redux/reducers/facility-catalog.reducer";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import { loadFacilityLocations } from "../../redux/reducers/facility-create.reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import UserChipContainer from "../../shared/component/user-chip-container";
import TagsSuggestionModal from "../../shared/dialog/tags-suggestion-modal";
import CatalogCard from "../../shared/component/catalog-card";
// import Chip from '../../shared/controls/chip';
import ExceedPlus from "../../shared/controls/exceed-plus";
import { BACKEND, getUrlsEnv } from "../../shared/backend-api";
import Tag from "../../shared/controls/tag";
import Selector from "../../shared/component/selector";
import {
  loadSession,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import { loadEquipmentTypes } from "../../redux/reducers/fleet-reducer";
import {
  deleteFacility,
  getFacilityCatalog,
} from "../../redux/reducers/facility-reducer";
import { normalizeStr } from "../../shared/utils";
import ValidatePerms from "../../shared/component/validate-perms";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import SubStringText from "../../shared/component/substring-text";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import ReactTooltip from "react-tooltip";
import { Fragment } from "react";
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import HelperFilesModal from '../../shared/component/helper_files_modal';

class FacilityCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: LIST,
      openConfirmDeleteModal: false,
      openTagsModal: false,
      selectionModeOn: false,
      selectedFacilitiesIds: [],
      openResultModal: false,

      // resetPaginator: false,
      limit: 10,
      offset: 1,

      tags: [],
      locations: [],
      capacities: [],
      statuses: [],
      searchStr: "",

      buttonFilterName: "Aplicar",
    };

    this.openedLink = false;
  }

  componentDidMount() {
    this.setState({ ...this.props.configuration });

    this.props.setNavbarAction("facility-catalog", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }
  
  componentWillUnmount() {
    let config = this.state;

    if (!this.openedLink) {
      config.searchStr = '';
    }

    this.props.setConfig(config);
    this.openedLink = false;

    this.props.removeNavbarAction("facility-catalog");
  }

  headerActionButtonSettings = {
    text: "Crear",
    onClick: () => {
      window.location.href = "/company/facility/create";
    },
    icon: {
      url: "/images/factory.svg",
      position: "before",
    },
  };

  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ buttonFilterName: "Aplicar" });
  };

  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedFacilitiesIds: [],
    });
  };

  handleSelectFacility = (e) => {
    let selectedFacilitiesIds = [...this.state.selectedFacilitiesIds];
    if (e.target.value) {
      selectedFacilitiesIds.push(e.target.name);
    } else {
      selectedFacilitiesIds.splice(
        selectedFacilitiesIds.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedFacilitiesIds: selectedFacilitiesIds });
  };

  deleteFacilities = () => {
    // debugger
    this.state.selectedFacilitiesIds.forEach((facilityId) => {
      this.props.deleteFacility(facilityId).then(() => {
        this.props.getFacilityCatalog();
        this.setState({
          openConfirmDeleteModal: false,
          selectedFacilitiesIds: [],
          selectionModeOn: false,
          openResultModal: true,
          resetPaginator: true,
        });
      });
    });
  };

  selectFacility = (id, selected) => {
    let selectedFacilitiesIds = this.state.selectedFacilitiesIds;
    if (selected) {
      selectedFacilitiesIds.push(id);
    } else {
      selectedFacilitiesIds.splice(
        selectedFacilitiesIds.findIndex((f) => f === id),
        1
      );
    }
    this.setState({ selectedFacilitiesIds: selectedFacilitiesIds });
  };

  selectAllFacilities = () => {
    if (
      this.props.facilities.length === this.state.selectedFacilitiesIds.length
    ) {
      this.setState({ selectedFacilitiesIds: [] });
    } else {
      let selectedFacilitiesIds = [];
      this.props.facilities.map((facility) => {
        selectedFacilitiesIds.push(facility.id);
        return null;
      });
      this.setState({ selectedFacilitiesIds: selectedFacilitiesIds });
    }
  };

  openConfirmDeleteModal = () => {
    this.setState({ openConfirmDeleteModal: true });
  };

  openTagsModal = () => {
    this.setState({ openTagsModal: true });
  };

  redirectToProfile = (e) => {
    this.props.history.push(`/company/facility/profile/${e.target.name}`);
  };

  handleSearch = (name, value) => {
    this.setState({ searchStr: value });
  };

  handleFilter = () => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ buttonFilterName: "Restablecer" }, () => this.handlePageUpdate() );
    } else {
      this.setState({ buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      locations: [],
      tags: [],
      statuses: []
    }, () => this.handlePageUpdate() );
  }

  handlePageUpdate = () => {
    this.props.getFacilityCatalog(
      this.state.limit,
      this.state.offset,
      this.state.tags,
      this.state.locations,
      this.state.capacities,
      this.state.statuses,
      this.state.searchStr
    );
  };

  toggleViewMode = (viewMode) => {
    this.setState({ viewMode: viewMode });
  };

  filterContents = () => [
    {
      title: "Etiquetas",
      items: this.props.tags,
      name: "tags",
      listType: FILTER_LIST_TYPES.chips,
    },
    {
      title: "Ubicaciones",
      items: this.props.locations,
      name: "locations",
      listType: FILTER_LIST_TYPES.checkListTextSearch,
    },
    // {
    //   title: 'Capacidad',
    //   items: this.props.equipmentTypes,
    //   name: 'capacities',
    //   listType: FILTER_LIST_TYPES.checkListTextSearchAll
    // },
    {
      title: "Estatus",
      items: this.props.statuses,
      name: "statuses",
      listType: FILTER_LIST_TYPES.checkList,
    },
  ];

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div className="catalog" onClick={this.clickOutside}>
        <ValidatePerms
          perms={[
            {
              id: "27",
              perm: "c",
            },
          ]}
          optionalElement={
            <CatalogHeader
              viewMode={this.state.viewMode}
              title="Instalaciones"
              toggleView={this.toggleViewMode}
            />
          }
        >
          <CatalogHeader
            viewMode={this.state.viewMode}
            title="Instalaciones"
            actionButtons={[this.headerActionButtonSettings]}
            toggleView={this.toggleViewMode}
          />
        </ValidatePerms>
        <FilterBar
          searchAction={this.handleSearch}
          filterAction={this.handleFilter}
          searchStr={this.state.searchStr}
          onChange={this.handleFilterChange}
          searchButtonText={this.state.buttonFilterName}
          content={this.filterContents()}
          selectedItems={{
            capacities: this.state.capacities,
            locations: this.state.locations,
            tags: this.state.tags,
            statuses: this.state.statuses,
          }}
        />
        <div className={"content ".concat(this.state.viewMode)}>
          {this.state.viewMode === GRID ? (
            ""
          ) : (
            <div className="facility-row-header">
              <div className="logo"></div>
              <div className="credentials">Instalación</div>
              <div className="handling">{/* Capacidad diaria */}</div>
              <div className="tags">Etiquetas</div>
              <div className="status">Estatus</div>
              <div className="rfc">RFC</div>
              <div className="geofences">Geocercas</div>
            </div>
          )}
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedFacilitiesIds}
            onSelect={this.handleSelectFacility}
            selectionModeOn={this.state.selectionModeOn}
            toggleSelectionMode={this.toggleSelectMode}
            showSelector={this.validShow({
              id: "27",
              perm: "d",
            })}
          >
            {Array.isArray(this.props.facilities) &&
              this.props.facilities.map((facility) => {
                return this.state.viewMode === GRID ? (
                  <CatalogCard
                    defaultClass="facility-card"
                    id={facility.id}
                    key={facility.id}
                    selected={
                      this.state.selectedFacilitiesIds.find(
                        (sf) => sf === facility.id
                      )
                        ? true
                        : false
                    }
                    onClick={() => {
                      this.openedLink = true;
                      this.redirectToProfile({ target: { name: facility.id } })
                    }}
                  >
                    {getCard(facility)}
                  </CatalogCard>
                ) : (
                  <FacilityRow
                    key={facility.id}
                    id={facility.id}
                    facilityInfo={facility}
                    rfc={facility.rfc || []}
                    isSelected={this.state.selectedFacilitiesIds.includes(
                      facility.id
                    )}
                    onClick={() => {
                      this.openedLink = true;
                      this.redirectToProfile({ target: { name: facility.id } })
                    }}
                  />
                );
              })}
          </Selector>
          <PaginatorControlled
            itemCount={this.props.facilitiesCount}
            limit={this.state.limit}
            offset={this.state.offset}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () => {
                this.handlePageUpdate();
              })
            }
          />
          {/* <Paginator
            totalElements={this.props.facilitiesCount}
            reload={this.handlePageUpdate}
            resetPaginator={this.state.resetPaginator}
            onReset={() => {
              this.setState({ resetPaginator: false });
            }}
            elementsPerPage={10}
          /> */}
        </div>
        <ValidatePerms
          perms={[
            {
              id: "27",
              perm: "d",
            },
          ]}
        >
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedFacilitiesIds.length}
            selectAll={this.selectAllFacilities}
            actions={[
              // {
              //   description: 'Editar etiquetas',
              //   f: this.openTagsModal
              // },
              {
                description: "Eliminar",
                f: this.openConfirmDeleteModal,
              },
            ]}
          />
        </ValidatePerms>
        <div className="footer"></div>
        <ConfirmDialog
          open={this.state.openConfirmDeleteModal}
          title="¿Eliminar instalación?"
          message={"¿Estás seguro que deseas eliminar esta instalación?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.selectFacility(id, false)}
              items={
                !Array.isArray(this.props.facilities)
                  ? []
                  : this.props.facilities
                      .filter((f) =>
                        this.state.selectedFacilitiesIds.includes(f.id)
                      )
                      .map((f) => {
                        return {
                          ...f,
                          avatarType: "contained",
                          avatar:
                            f.avatar &&
                            BACKEND.FILES_SERVER.concat(f.avatar.image),
                        };
                      })
              }
            />
          }
          acceptText="Sí, deseo eliminarla"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({ openConfirmDeleteModal: false })}
          acceptAction={this.deleteFacilities}
        />
        <ConfirmDialog
          open={this.state.openResultModal}
          title="¡Listo!"
          message="Los cambios se han guardado con éxito."
          acceptText="Aceptar"
          class="delete-facility"
          closeAction={() => {
            this.setState({ openResultModal: false });
          }}
          acceptAction={() => {
            this.setState({ openResultModal: false });
          }}
        />
        <TagsSuggestionModal
          closeAction={() => this.setState({ openTagsModal: false })}
          open={this.state.openTagsModal}
          items={
            Array.isArray(this.props.facilities)
              ? this.props.facilities
                  .map((f) => {
                    f.tags = f.facility_tags_facility;
                    return f;
                  })
                  .filter((f) =>
                    this.state.selectedFacilitiesIds.includes(f.id)
                  )
              : []
          }
          tagCatalog={this.props.tags}
        />
      </div>
    );
  }
}

const getCard = (cardItem) => {
  return (
    <React.Fragment>
      <div className="top-row">
        <div className="logo default">
          <div className="avatar-container">
            <img
              alt="facility-avatar"
              src={
                getUrlsEnv().files.concat(cardItem.avatar.image) ||
                "images/avatar/icon_factory.svg"
              }
            />
          </div>
        </div>
        <div className="credentials">
          <SubStringText
            label={cardItem.name}
            labelTag={"p"}
            labelSize={20}
            labelClass={"name"}
            tooltipClass={"tooltip-class-name-" + cardItem.name}
          />
          {/* <p className="name">{cardItem.name}</p> */}
          <p className="code">
            {cardItem.alias} - {cardItem.code}
          </p>
          {/* <p className="handling ">
            <img alt="" src='/images/double-truck-white.svg' />
            {cardItem.handling.double}
            <img alt="" src='/images/single-truck-white.svg' />
            {cardItem.handling.single}
          </p> */}
        </div>
        {cardItem.registration_complete ? 
          <img alt="" src={"/images/".concat("check.svg")} /> : 
          <Fragment>
            <img alt="" src={"/images/".concat("red-exclamation.svg")} className="plus" data-tip data-for={"tooltip-facilities-".concat(cardItem.id)}/>
            <ReactTooltip
              className={"user-facilities-tooltip"}
              id={"tooltip-facilities-".concat(cardItem.id)}
              getContent={() =>
                cardItem.incomplete_motives.map((c, index) => (
                  <Fragment key={index}>
                    {c}
                    <br />
                  </Fragment>
                ))
              }
              delayHide={200}
              place={"bottom"}
              border={true}
              type={"light"}
            />
          </Fragment>
        }
      </div>
      <div className="bottom-row">
        <div className="geofences">
          <div className="fences">{cardItem.geofences.fences}</div>
          <div className="entry">{cardItem.geofences.entry}</div>
          <div className="validation">{cardItem.geofences.validation}</div>
          {/* <div className="ramps">{cardItem.geofences.ramps}</div> */}
        </div>
        <div className="tags">
          <ExceedPlus maxVisibleItems={2} name={cardItem.id.toString()}>
            {cardItem.facility_tags_facility.map((tag) => (
              <Tag
                key={tag.id}
                id={tag.id}
                title={tag.title}
                color={tag.color}
              />
            ))}
          </ExceedPlus>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  let locations = (state.facilityCreate.locationsbyfacility || [])
    .map((l) => l.country)
    .filter((v, i, s) => s.indexOf(v) === i)
    .map((c) => ({
      id: c,
      items: state.facilityCreate.locationsbyfacility
        .filter((l) => l.country === c)
        .map((l) => ({
          id: l.city,
          description: l.city_name,
        })),
      description: state.facilityCreate.locationsbyfacility.find(
        (l) => l.country === c
      ).country_name,
    }));

  let equipmentTypes = (state.fleetReducer.equipmentType.data || [])
    .map((e) => e.type)
    .filter((v, i, s) => s.indexOf(v) === i)
    .map((t) => ({
      id: t,
      items: state.fleetReducer.equipmentType.data
        .filter((e) => e.type === t)
        .map((l) => ({
          id: l.id,
          description: l.description.substring(2, l.description.length),
        })),
      description: state.fleetReducer.equipmentType.data.find(
        (l) => l.type === t
      ).type,
    }));

  let searchStr = normalizeStr(state.facilityCatalog.searchStr);
  let facilities = (
    (state.facilityReducer.catalog.data || {}).results || []
  ).filter(
    (f) =>
      normalizeStr(f.alias).includes(searchStr) ||
      normalizeStr(f.code).includes(searchStr) ||
      normalizeStr(f.name).includes(searchStr) ||
      normalizeStr(
        f.facility_tags_facility.map((ftf) => ftf.title).join()
      ).includes(searchStr)
  );
  const configuration = state.configuration.FacilityCatalog;
  return {
    configuration,
    searchStr: state.facilityCatalog.searchStr,
    viewMode: state.facilityCatalog.viewMode,
    facilities,
    facilitiesCount: (state.facilityReducer.catalog.data || {}).count || 0,
    tags: (state.tags.tags || []).filter(
      (tag) =>
        tag.type ===
        (
          (state.tags.tagTypes.data || []).find(
            (type) => type.description.toLowerCase() === "instalaciones"
          ) || { id: 0 }
        ).id
    ),
    locations,
    equipmentTypes,
    companyId: state.globalCatalog.session.company.id,
    statuses: [
      { id: 1, description: "Completo" },
      { id: 0, description: "Pendiente" },
    ],
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadTags());
  dispatch(loadEquipmentTypes());
  dispatch(loadFacilityLocations());
  return {
    setConfig: (config) => dispatch(setConfig("FacilityCatalog", config)),
    // searchByTextFacility: (searchStr) => dispatch(searchFacilities(searchStr)),
    deleteFacility: (idFacility) => dispatch(deleteFacility(idFacility)),
    getFacilityCatalog: (
      limit,
      offset,
      tags,
      locations,
      capacity,
      status,
      searchStr
    ) =>
      dispatch(
        getFacilityCatalog(
          limit,
          offset,
          tags,
          locations,
          capacity,
          status,
          searchStr
        )
      ),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};
const connectToStore = connect(mapStateToProps, mapDispatchToProps);
export default connectToStore(FacilityCatalog);
