import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Tag from '../../shared/controls/tag';
import { CollapsibleHeaderList } from '../shipment/styled/collapsible-header';
// import { FacilityRow } from '../shipment/styled/facility-row';
import { SummaryPanel } from '../shipment/styled/summary-panel';

const COLUMNS = [
  { description: "Enviadas", name: "purchase_orders_sent" },
  { description: "Confirmadas", name: "purchase_orders_confirmed" },
  { description: "Canceladas", name: "purchase_orders_canceled" },
];

const PEDIDO_COLUMNS  = [
  { description: "Pendiente", name: 1 },
  { description: "Confirmado", name: 2 },
  // { description: "Orden creada", name: "purchase_orders_canceled" },
  // { description: "Salida inventario", name: "purchase_orders_sent" },
  // { description: "Entrada inventario", name: "purchase_orders_confirmed" },
  // { description: "Cancelado", name: "purchase_orders_canceled" },
];

const ORDER_COLUMNS = [
  { description: "Pendiente", name: 1 },
  { description: "Sin reservación", name: 2 },
  { description: "Reservación pendiente", name: 3 },
  { description: "Reservación rechazada", name: 4 },
  { description: "Reservación confirmada s/asignar", name: 5 },
  { description: "Reservación confirmada asignada", name: 6 },
  // { description: "Viaje en proceso", name: "purchase_orders_sent" },
  // { description: "Viaje completado", name: "purchase_orders_confirmed" },
  { description: "Cancelada", name: 9 },
  { description: "Finalizada s/datos", name: 11 },
  // { description: "Pendiente", name: "purchase_orders_confirmed" },
];

class OrdersSummaryList extends React.Component {
  state = {

  }

  chooseColumns = () => {
    switch(this.props.itemType){
      case 1: 
        return PEDIDO_COLUMNS;
      case 2:
        return ORDER_COLUMNS;
      default:
        return []
    } 
  }

  getDataIndex = () => {
    if(this.props.itemType === 1){//pedidos
      if(this.props.transactionType === 1){//compra
        return 'purchase_requisitions'
      } else {
        return 'sales_requisitions'
      }
    } else {
      if(this.props.transactionType === 1){//compra
        return 'purchase_orders'
      } else {
        return 'sales_orders'
      }
    }
  }

  render() {
    return(
      <SummaryPanel>
        <div className="resume-header">
          {/* <div>Enviadas</div>
          <div>Confirmadas</div>
          <div>Canceladas</div> */}
          {
            this.chooseColumns().map(c => (
              <div>{c.description}</div>
            ))
          }
        </div>
        <div style={{ margin: "0 25px 25px" }}>
          <div
            style={{
              position: "relative",
              maxHeight: "500px",
              overflowY: "auto",
              // marginTop: "120px"
            }}
          >
            {
              this.props.ordersResume.map(tag => {
                return(
                  <CollapsibleHeaderList
                    maxExpandedHeight="auto"
                    contentStyle={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    collapsedHeight="40px"
                    title={
                      <div style={{
                        width: "calc(15% - 18px)",
                        fontWeight: "bold",
                        fontSize: "16px",
                        lineHeight: "25px",
                        color: "#FFFFFF",
                      }}>
                        {tag[0]}
                      </div>
                    }
                    caret={{ fill: "#79A3FF", stroke: "#79A3FF" }}
                    initialStateCollapsed={true}
                    headerContent={
                      <div
                        style={{
                          width: "85%",
                          display: "flex",
                          fontSize: "24px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          justifyContent: "space-evenly",
                          borderBottom: "2px solid #ffffff80",
                          alignItems: "center",
                          height: "100%"
                        }}
                      >
                        {(this.chooseColumns()).map((column) => (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            { 
                              tag[1].length 
                              ? (tag[1]
                                .map((f) => f[this.getDataIndex()][column.name])
                                .reduce((total, value) => total + value))
                              : '-'
                            }
                            
                          </div>
                        ))}
                      </div>
                    }
                  >
                    {tag[1]
                      .sort(function(a, b) {
                        if (a.name > b.name) {
                          return 1;
                        }
                        if (a.name < b.name) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((facility) => (
                        <FacilityRow
                          onClick={() => {
                            this.props.history.push(
                              `/company/controls/order/detail/${facility.id}`
                            )
                          }}
                        >
                          <div style={{ width: "15%", textAlign: "right", cursor: "pointer" }}>
                            {facility.name} - {facility.alias} - {facility.code}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "85%",
                              justifyContent: "space-evenly",
                              fontSize: "20px",
                              fontWeight: "bold",
                              alignItems: "center"
                            }}
                          >
                            {(this.chooseColumns()).map((column) => {
                                return <div style={{ width: "100%", textAlign: "center" }}>
                                  {facility[this.getDataIndex()][column.name]}
                                  
                                </div>
                            })}
                          </div>
                        </FacilityRow>
                      ))}
                  </CollapsibleHeaderList>
                )
              })
            }
          </div>
        </div>
      </SummaryPanel>
    )
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  return {
    companyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersSummaryList);

const FacilityRow = styled.div`
  display: flex;
  color: #ffffff;
  font-family: Overpass;
  font-size: 14px;
  width: 100%;
  height: auto;
  line-height: 33px;
  overflow: hidden;
  transition: 1s ease-in;
  &:hover {
    background-color: #5c6d9a;
  }
`;