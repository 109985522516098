// @flow

type ContractData = {
  id: number,
};
type State = {
  currentContractData: ContractData | void,
};

type ReducerAction = {
  type: string,
  payload: any,
};

const ACTIONS = {
  SET_CURRENT_CONTRACT: {
    TYPE: "SET_CURRENT_CONTRACT",
    ACTION: (contractData: ContractData): ReducerAction => ({
      type: ACTIONS.SET_CURRENT_CONTRACT.TYPE,
      payload: contractData,
    }),
  },
};

const initialState: State = {
  currentContractData: {},
};

export default (state = initialState, action: ReducerAction) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_CONTRACT.TYPE:
      return {
        ...state,
        currentContractData: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const setCurrentContract = (contract: ContractData) => {
  return function (dispatch) {
    dispatch(ACTIONS.SET_CURRENT_CONTRACT.ACTION(contract));
    return "OK";
  };
};
