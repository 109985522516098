//@flow
import * as React from "react";
import BaseDialog from "../../shared/dialog/base-dialog";
import { FILES_SERVER } from "../../redux/api";
import styled from "styled-components";
import TableD from "../../shared/component/table-d";
import AssembliesAvailabilityRow from "./assemblies-availability-row";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import { normalizeStr } from "../../shared/utils";
import MiniFilter from "./mini-filter";
import RadioInput from "../../shared/controls/radio-input";
import Checkbox from "../../shared/controls/checkbox";
import Loader from "../../shared/icons/tat/loader";

type Props = {
  assemblies: any[],
  trip: any,
  manager: () => void,
  show: boolean,
  logo: string,
  selectedAssembly: number,
};

class AssembleShipmentAssignDialog extends React.Component<Props> {
  static defaultProps = {
    open: false,
    manager: () => {},
    assemblies: [],
    selectedAssembly: 0,
    hasAutoStart: false,
    handleAutoStart: () => {},
    isLoading: false
  };
  render() {
    const { show, manager, logo, trip } = this.props;
    return (
      <BaseDialog
        closeAction={manager}
        acceptAction={() =>
          manager({
            target: {
              name: "save",
              value: { tripId: trip.id, assembly: this.props.selectedAssembly },
            },
          })
        }
        open={show}
        title={<Title logo={logo} number={trip.tripNumber} />}
        contentObject={<ModalContent {...this.props} />}
        showCancel={true}
        cancelText="Cerrar"
        cancelButtonType="primary outline dark"
        acceptText={this.props.isLoading ? <Loader /> : "Aceptar"}
        disabledAccept={!this.props.selectedAssembly || this.props.isLoading}
        acceptButtonType="primary"
        style={{ content: { width: "1400px", marginLeft: "-700px" } }}
      ></BaseDialog>
    );
  }
}

export default AssembleShipmentAssignDialog;

const ModalContent = ({ assemblies, trip, manager, selectedAssembly, hasAutoStart, handleAutoStart }) => {
  const [searchStr, setSearchStr] = React.useState("");
  const [contract, setContract] = React.useState("");
  const [maxElementsToShow, setMaxElementsToShow] = React.useState(20);

  const AssembleTableConfig: TableDConfig = {
    headerStyle: {
      height: "53px",
      backgroundColor: "rgba(152, 172, 193,0.14)",
      color: "#252325",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: "0",
      lineHeight: "22px",
      borderTop: "solid 1px #979797",
    },
    rowStyle: {
      hover: {
        backgroundColor: "rgba(255,247,142,0.25)",
      },
      selected: {
        backgroundColor: "rgba(255,247,142,0.8)",
      },
    },
    contentStyle: {
      maxHeight: "150px",
      borderBottom: "solid 1px #979797",
      overflow: "auto",
    },
    columns: [
      {
        text: "",
        width: "2%",
        cellStyle: { ...cellStyle, margin: "6px 0px" },
        headerItemStyle,
      },
      {
        text: "Base",
        width: "10%",
        cellStyle: { ...cellStyle, justifyContent: "flex-start" },
        headerItemStyle,
      },
      {
        text: "Vehículo",
        width: "15%",
        cellStyle: { ...cellStyle, justifyContent: "flex-start" },
        headerItemStyle,
      },
      {
        text: "Equipo",
        width: "18%",
        cellStyle: { ...cellStyle, justifyContent: "flex-start" },

        headerItemStyle,
      },
      {
        text: "Operador",
        width: "25%",
        cellStyle: { ...cellStyle, justifyContent: "flex-start", overflow: "hidden" },
        headerItemStyle,
      },
      {
        text: (
          <span>
            {"Contrato"}
            <MiniFilter>
              <RadioInputStyled style>
                <RadioInput
                  className="mini-filter-radio"
                  name="contract"
                  title="Con contrato"
                  value="1"
                  selected={contract || ""}
                  onClick={(e) => setContract(e.target.value)}
                />
              </RadioInputStyled>
              <RadioInputStyled>
                <RadioInput
                  name="contract"
                  title="Sin contrato"
                  value="2"
                  selected={contract || ""}
                  onClick={(e) => setContract(e.target.value)}
                />
              </RadioInputStyled>
              <RadioInputStyled>
                <RadioInput
                  name="contract"
                  title="Todo"
                  value="3"
                  selected={contract || ""}
                  onClick={(e) => setContract(e.target.value)}
                />
              </RadioInputStyled>
            </MiniFilter>
          </span>
        ),
        width: "10%",
        cellStyle,
        headerItemStyle,
      },
      {
        text: "Disponible a partir de",
        width: "15%",
        cellStyle,
        headerItemStyle,
      },
      {
        text: "Alerta",
        width: "5%",
        cellStyle,
        headerItemStyle,
      },
    ],
  };
  return (
    <>
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PanelStyled>
          {`Lista de ensambles`}
          <FilterBarCompact
            searchOnly={true}
            searchStr={searchStr}
            searchAction={(e) => setSearchStr(e.target.value)}
          />
        </PanelStyled>
        <TableD tableConfig={AssembleTableConfig} onScroll={ (e) => {
          const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
          if (bottom) { 
            setMaxElementsToShow(maxElementsToShow + 20);
          }
        }}>
          {assemblies
            .filter(
              (f) =>
                f.tags?.find((t) =>
                  normalizeStr(t.title).includes(normalizeStr(searchStr))
                ) ||
                f.equipments?.find(
                  (e) =>
                    normalizeStr(e.economic_number).includes(
                      normalizeStr(searchStr)
                    ) ||
                    normalizeStr(e.plates).includes(normalizeStr(searchStr))
                ) ||
                f.vehicles?.find(
                  (v) =>
                    normalizeStr(v.economic_number).includes(
                      normalizeStr(searchStr)
                    ) ||
                    normalizeStr(v.plates).includes(normalizeStr(searchStr))
                ) ||
                f.operators?.find((o) =>
                  normalizeStr(o.name).includes(normalizeStr(searchStr))
                ) ||
                f.contracts?.find((c) =>
                  normalizeStr(c.facilityName).includes(normalizeStr(searchStr))
                )
            )
            .filter((f) => {
              switch (contract) {
                case "1":
                  return f.contracts.length > 0;
                case "2":
                  return f.contracts.length === 0;
                default:
                  return true;
              }
            })
            .slice(0, maxElementsToShow).map((f) => (
              <AssembliesAvailabilityRow
                {...f}
                onChange={manager}
                selectedAssembly={selectedAssembly}
              />
            ))}
        </TableD>
      </div>
      <OrdersContainer>
        <OrdersContainerTitle>{`Viaje No: ${trip.tripNumber}`}</OrdersContainerTitle>
        {trip.details.map(
          (o: Types.UnassignedShipmentRowDetailType, index, a) => (
            <Order>
              <OrderTitle>{`Folio: ${o.ticket}`}</OrderTitle>
              <OrderDataContainer>
                <OrderData>
                  <OrderLocation>{o.origin.name}</OrderLocation>
                  {o.origin.date ? new Date(o.origin.date).toUTCString().substring(4, 12) : '-'}
                </OrderData>
                <OrderConnector inner={index < a.length - 1} />
                <OrderData>
                  <OrderLocation>{o.destination.name}</OrderLocation>
                  {o.destination.date ? new Date(o.destination.date).toUTCString().substring(4, 12) : '-'}
                </OrderData>
              </OrderDataContainer>
            </Order>
          )
        )}
      </OrdersContainer>
    </div>
    <div style={{marginTop: "10px"}}>
      {
        trip.details.some(o => o.origin.date || o.destination.date) && 
        <Checkbox
          name="auto"
          onChange={handleAutoStart}
          item={{
            id: 1,
            description: "Inicio automático",
            active: hasAutoStart,
            style: false
          }}
        />
      }
    </div>
    </>
  );
};

const Title = ({ number, logo }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleHead>{`Selecciona un transportista para el viaje ${number}`}</TitleHead>
      </div>
    </div>
  );
};

const TitleHead = styled.span`
  color: #20293d;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 55px;
`;
const TitleMessage = styled.span`
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
`;

const PanelStyled = styled.div`
  color: #252325;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  align-items: center;

  width: 979px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // & .header {
  //   margin: 0 10px;
  // }
`;
/*
maxExpandedHeight="300px"
        collapsedHeight="53px"
        title="Lista de camiones disponibles"
        initialStateCollapsed={false}
        headStyle={TableHeaderCollapsible}
        */

// const TableHeaderCollapsible = {
//   backgroundColor: "rgba(152, 172, 193,0.11)",
//   color: "#252325",
//   fontSize: "20px",
//   fontWeight: "bold",
//   letterSpacing: "0",
//   lineHeight: "31px",
// };

const cellStyle = {
  justifyContent: "center",
};

const headerItemStyle = {
  textAlign: "center",
};

const OrdersContainer = styled.div`
  margin-left: 35px;
`;

const OrdersContainerTitle = styled.div`
  opacity: 0.8;
  color: #252325;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  text-align: center;
`;

const Order = styled.div`
  width: 240px;
  border-radius: 12px;
  background-color: rgb(152, 172, 193, 0.14);
  margin: 30px 20px;
  padding: 5px;
`;

const OrderConnector = styled.div`
  position: relative;
  display: ${({ inner }) => (inner ? "block" : "none")};
  width: 0;
  bottom: -46px;
  left: 20px;
  height: 20px;
  border: dashed 2px #5e6e8f;
  &::before {
    position: relative;
    top: -5px;
    left: -7;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #5e6e8f;
  }
  &::after {
    position: relative;
    top: 5px;
    left: -7;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #5e6e8f;
  }
`;

const OrderTitle = styled.div`
  color: #2f3b52;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

const OrderDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const OrderData = styled.div`
  color: #3c4453;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
`;
const OrderLocation = styled.div`
  color: #2f3b52;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
`;

const RadioInputStyled = styled.div`
  margin-top: 3px;
  & label {
    text-align: initial;
  }
`;
