import React from "react";
const Loader = (props) => {
  return (
    <svg 
      width="28px"  
      height="28px"  
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 100 100" 
      preserveAspectRatio="xMidYMid" 
      className={"lds-dual-ring ".concat(props.className)}
      {...props}
    >
      <circle cx="50" cy="50" ng-attr-r="{{config.radius}}" ng-attr-stroke-width="{{config.width}}" ng-attr-stroke="{{config.stroke}}" ng-attr-stroke-dasharray="{{config.dasharray}}" fill="none" strokeLinecap="round" r="27" strokeWidth="7" stroke={props.circleColor ? props.circleColor : "#ffffff"} strokeDasharray="42.411500823462205 42.411500823462205" transform="rotate(60 50 50)">
        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>
  );
};

export default Loader;