import React from 'react';
import DeckGL from '@deck.gl/react';
// import {LineLayer} from '@deck.gl/layers';
import {StaticMap/* , FullscreenControl */} from 'react-map-gl';
import {ColumnLayer} from '@deck.gl/layers';
// import { useCallback } from 'react';
// import {HexagonLayer} from '@deck.gl/aggregation-layers';

// import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from 'mapbox-gl';

// // eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiYW50b25pb29yYmkiLCJhIjoiY2txNnlndHZ6MDB4dDJwbHhsNmRmNTBrZCJ9.BsOAAVaMbYI0WtbN6N8EBw";
// const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiYW50b25pb29yYmkiLCJhIjoiY2txNnlndHZ6MDB4dDJwbHhsNmRmNTBrZCJ9.BsOAAVaMbYI0WtbN6N8EBw"

const mapStyle = 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json';
const mapStyleDark = 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json';
const mapStyleLight = 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json';

// Viewport settings
// const INITIAL_VIEW_STATE = {
//   "bearing":0,
//   "latitude":22.9734021,
//   "longitude":-102.7010398,
//   "pitch":60,
//   "zoom":5
// };

// const fullscreenControlStyle= {
//   right: 10,
//   top: 10
// };

// const SEPARATION = 1.5;
const SEPARATION = 0;

const COLORS = {
  green: [162, 184, 11, 192 ],
  yellow: [243, 221, 21, 192 ],
  orange: [236, 94, 14, 192 ],
  red: [236, 14, 14, 192 ],
}

const DEVIATION = {
  bottomRight: [SEPARATION, -SEPARATION],
  centerTop: [0, SEPARATION],
  bottomLeft: [-SEPARATION, -SEPARATION]
}

// const MAX_RADIUS = 6000;
// const MAX_RADIUS = 1000;

// const RADIUS = {
//   first: MAX_RADIUS,
//   second: MAX_RADIUS - 50,
//   third: MAX_RADIUS - 100,
//   fourth: MAX_RADIUS - 150
// }

// const MAX_RADIUS_LARGE = 6000; //64 36

// const LARGE_RADIUS = {
//   first: MAX_RADIUS_LARGE,
//   second: MAX_RADIUS_LARGE - 50,
//   third: MAX_RADIUS_LARGE - 100,
//   fourth: MAX_RADIUS_LARGE - 150
// }

// const ELEVATIONSCALE = 1800;
const ELEVATIONSCALE = 5800;

class IndicatorsMap extends React.Component {
  state = {
    hoverInfo: null,
    // initialViewState: {
    //   bearing:0,
    //   latitude:22.9734021,
    //   longitude:-102.7010398,
    //   pitch:60,
    //   zoom:5
    // }
  }

  getMapStyle = () => {
    switch(this.props.mapTheme) {
      case 'default':
        return mapStyle;
      case 'dark':
        return mapStyleDark;
      case 'light':
        return mapStyleLight;
      default:
        return mapStyle;
    }
  }

  getOffset = (radius, type) => {
    let factor = this.props.barRadius/radius;
    switch(type){
      case 1:
        return [factor*SEPARATION, -SEPARATION*factor];
      case 2:
        return [0, SEPARATION*factor];
      case 3:
        return [factor*-SEPARATION, -SEPARATION*factor];
    }
  }

  getRadius = (size, level) => {
    let factor = this.props.barRadius * .03;
    switch(level){
      case 1: 
        return size;
      case 2:
        return size - factor;
      case 3:
        return size - factor*2;
      case 4:
        return size - factor*3;
    }
  }

  getLayers = () => {
    // let radius = this.props.hasBigBars ? LARGE_RADIUS : RADIUS;
    return [
      new ColumnLayer({
        id: "time_invalid",
        data: this.props.kpiTime,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => (d.cita_no_valida || 0)/60,
        getFillColor: d => COLORS.yellow,//orange
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.second,
        radius: this.getRadius(this.props.barRadius, 1),
        // offset: DEVIATION.bottomRight,
        offset: DEVIATION.bottomRight,
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "time_prev",
        data: this.props.kpiTime,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => (d.cita_no_valida || 0)/60 + d.previo_a_cita/60,
        getFillColor: d => COLORS.green,//green
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.first,
        radius: this.getRadius(this.props.barRadius, 2),
        offset: this.getOffset(this.getRadius(this.props.barRadius, 2), 1),
        pickable: true,
        onClick: info => this.props.setData(info)
      }),
      new ColumnLayer({
        id: "time_appointment",
        data: this.props.kpiTime,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => (d.cita_no_valida || 0)/60 + d.previo_a_cita/60 + d.estadia_vs_cita/60,
        getFillColor: d => COLORS.orange,//orange
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.second,
        radius: this.getRadius(this.props.barRadius, 3),
        // offset: DEVIATION.bottomRight,
        offset: this.getOffset(this.getRadius(this.props.barRadius, 3), 1),
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "range_small",
        data: this.props.kpiRange,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.rangos[1],
        getFillColor: d => COLORS.green,//red
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.first,
        radius: this.getRadius(this.props.barRadius, 1),
        offset: DEVIATION.centerTop,
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "range_medium",
        data: this.props.kpiRange,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.rangos[1] + d.rangos[2],
        getFillColor: d => COLORS.yellow,//red
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.second,
        radius: this.getRadius(this.props.barRadius, 2),
        offset: this.getOffset(this.getRadius(this.props.barRadius, 2), 2),
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "range_large",
        data: this.props.kpiRange,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.rangos[1] + d.rangos[2] + d.rangos[3],
        getFillColor: d => COLORS.orange,//red
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.third,
        radius: this.getRadius(this.props.barRadius, 3),
        // offset: DEVIATION.centerTop,
        offset:  this.getOffset(this.getRadius(this.props.barRadius, 3), 2),
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "range_extra",
        data: this.props.kpiRange,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.rangos[1] + d.rangos[2] + d.rangos[3] + d.rangos[4],
        getFillColor: d => COLORS.red,//red
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.fourth,
        radius: this.getRadius(this.props.barRadius, 4),
        // offset: DEVIATION.centerTop,
        offset: this.getOffset(this.getRadius(this.props.barRadius, 4), 2),
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
      new ColumnLayer({
        id: "transit_early",
        data: this.props.kpiTransit,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.a_tiempo,
        getFillColor: d => COLORS.green,//green
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.first,
        radius: this.getRadius(this.props.barRadius, 1),
        offset: DEVIATION.bottomLeft,
        pickable: true,
        onClick: info => this.props.setData(info)
      }),
      new ColumnLayer({
        id: "transit_late",
        data: this.props.kpiTransit,
        coverage: 1,
        elevationScale: this.props.elevationScale,
        extruded: true,
        getElevation: d => d.total_ordenes,
        getFillColor: d => COLORS.orange,//orange
        getPosition: d => [Number(d.centroide[1]), Number(d.centroide[0])],
        // radius: radius.second,
        radius: this.getRadius(this.props.barRadius, 2),
        offset: this.getOffset(this.getRadius(this.props.barRadius, 2), 3),
        pickable: true,
        onClick: info => { this.props.setData(info)}
      }),
    ]
  }

  

  render() {
    
    return (
      <>
        <div>
          <DeckGL 
            initialViewState={this.props.viewState}
            // viewState={this.props.viewState}
            // onViewStateChange={this.props.onViewStateChange}
            controller={true}
            layers={this.getLayers()}
          >
            <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={this.getMapStyle()} /* preventStyleDiffing={true} */>
              {/* <FullscreenControl style={fullscreenControlStyle} /> */}
            </StaticMap>
          </DeckGL>  
        </div>
      </>
    )
  }
}

export default IndicatorsMap;