import React from "react";

const FleetDetailTitleSubSection = (props) => {
  const { isDataCollapsed } = props;
  return (
    <div className="fleet-container fleet-title line-container">
      <div className="fleet-container fleet-values-title subtitle-padding">
        {!isDataCollapsed && (
          <div className="fleet-details-subsection-titles">
            <div className="fleet-container fleet-values-title value">
              Válidados
            </div>
            <div className="fleet-container fleet-values-title value">
              Pendientes
            </div>
            <div className="fleet-container fleet-values-title value">
              Rechazados
            </div>
            <div className="fleet-container fleet-values-title value">
              Incompleto
            </div>
            <div className="fleet-container fleet-values-title value">
              Vencido
            </div>
          </div>
        )}
        <div
          className="fleet-container fleet-values-title subtitle-subsection"
          style={{ marginBottom: isDataCollapsed ? "10px" : "2px" }}
        >
          Total Compartida
        </div>
      </div>

      {/* <div className="line" /> */}
    </div>
  );
};

export default FleetDetailTitleSubSection;
