import React from "react";
const TruckForward = (props) => {
  return (
    <svg
      width="35px"
      height="30px"
      viewBox="0 0 88 30"
      fill="currentColor"
      {...props}
    >
      <path
        d="M53.7590518,19.7880495 L48.3832333,9.27847247 C47.9954791,8.55197053 47.2691383,8.06763591 46.4463504,8.06763591 L39.3287901,8.06763591 L39.3287901,6.27598526 C39.3287901,5.01594031 38.3111518,4 37.0533401,4 L10.2754505,4 C9.01570187,4 8,5.01787765 8,6.27598526 L8,29.9119992 C8,31.1720442 9.01763827,32.1879845 10.2754505,32.1879845 L12.5509,32.1879845 C12.8421878,35.432639 15.5054212,38 18.7963789,38 C22.0894671,38 24.7997474,35.432639 25.0418577,32.1879845 L36.7595149,32.1879845 C37.0508028,35.432639 39.7140361,38 43.0049938,38 C46.2980821,38 49.0083623,35.432639 49.2504726,32.1879845 L51.8210068,32.1879845 C53.0315586,32.1879845 54,31.2193153 54,30.0084787 L54,20.8061209 C54,20.4674644 53.9035335,20.1288273 53.7578896,19.7882432 L53.7590518,19.7880495 Z M41,13 L46.6187509,13 L50,20 L41.0002857,20 L41,13 Z M19,34 C17.9036005,34 17,32.8704994 17,31.5 C17,30.1295006 17.9036005,29 19,29 C20.0963995,29 21,30.1295006 21,31.5 C21,32.8704994 20.0963995,34 19,34 Z M43,34 C41.9036002,34 41,32.8704994 41,31.5 C41,30.1295006 41.9036002,29 43,29 C44.0963985,29 45,30.1295006 45,31.5 C45.0012601,32.8704994 44.0976567,34 43,34 Z"
        id="Fill-1-Copy-6"
      ></path>
      <polygon
        id="Path-3-Copy-2"
        fillRule="nonzero"
        points="57.6363636 41.8888889 44 41.8888889 44 40.1111111 57.6363636 40.1111111 57.6363636 33 74 41 57.6363636 49"
      ></polygon>
    </svg>
  );
};

export default TruckForward;
