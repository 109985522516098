//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import styled from "styled-components";
import { FILES_SERVER } from "../../../redux/api";

type Props = {
  open: boolean,
  onChange: Function,
};

class TransportsShipmentReserveDialog extends React.Component<Props> {
  render() { 
    return (
      <BaseDialogStyled
        open={this.props.open}
        title={<Title {...this.props} />}
        contentObject={this.getContent()}
        closeAction={() =>
          this.props.onChange({ target: { name: "back-transports", value: "" } })
        }
        disableClose={true}
        showCancel={true}
        cancelText="Atras"
        cancelButtonType="outline primary dark"
        acceptAction={() =>
          this.props.onChange({ target: { name: "save-transports", value: "" } })
        }
        acceptText="Reservar viaje"
        disabledAccept={this.props.selectedTransport === null}
      />
    );
  }

  getContent = () => {
    return(
      <div style={{marginTop: 25, overflowY: 'auto', minHeight: 65, maxHeight: 195}}>
        {
          (this.props.providers ?? []).map( item => this.getRow(item) )
        }
      </div>
    );
  }

  getRow = (item) => {

    var logo = (item?.company?.logo || '');
    var id = (item?.id || '');
    var isSelected = (this.props.selectedTransport !== null && id === this.props.selectedTransport);

    return(
      <div
        style={{marginLeft: 90}}
      >
        <div
          style={{display: "flex", fontSize: 18, alignItems: 'center'}}
          onClick={() => {
            this.props.onChange({ target: { name: 'select-transport', value: isSelected ? null : id } })
          }}
        >

          <img
            alt=""
            src={ isSelected ? '/images/radio_on.png' : '/images/radio_off.png' }
            style={{height: 20, width: 20}}
          ></img>

          <img
            width='35px'
            height='35px'
            style={{ borderRadius: '17px', margin: '15px' }}
            src={ logo === '' ? '/images/interrogacion.svg' : FILES_SERVER.concat(logo)}
            alt=''
          />

          {(item?.company?.orbi_id || '') + ' / ' + (item?.company?.orbi_id || '')}

        </div>
      </div>
    );
  }

}

export default TransportsShipmentReserveDialog;

const Title = () => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleMessage>
          {
            "Elige a traves de cual empresa se realizará este viaje:"
          }
        </TitleMessage>
      </div>
    </div>
  );
};

const TitleMessage = styled.span`
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
`;

const BaseDialogStyled: React.ComponentType<{}> = styled(BaseDialog)`
  width: 800px !important;
  margin-left: -400px !important;
`;

