export type AssembleResourceLog = {
  id: Number,
  description: String,
  iconName: string,
  imageUrl: string,
  from: date,
};

export type GridConfig = {
  columnRangeType: string,
  columnSections: number,
  columnQuantity: number,
  startDatetime: date,
  className: string,
  extended: boolean,
};

export type ListAssembliesQueryParams = {
  limit: number,
  offset: number,
  view: string,
  operator_status: array<number>,
  operator_license_type: array<number>,
  equipment_status: array<number>,
  equipment_class: array<number>,
  equipment_type: array<number>,
  vehicle_status: array<number>,
  vehicle_class: array<number>,
  vehicle_type: array<number>,
  vehicle_tags: array<number>,
  equipment_tags: array<number>,
  operator_tags: array<number>,
  start_date: string,
  end_date: string,
};

export type Resource = {
  id: number,
  type: 1 | 2 | 3,
  // fleetItem:any,
  shortDescription: string,
  longDescription: string,
  avatar: element,
  image: element | string,
  // fleetItemId: number,
  normativityStatusId: number,
  classification: number,
  item: any,
  logId: number,
  icon: any,
  // assembled: boolean,
};

export type Action = {
  description: string,
  action: Function,
};

export type Timeline = {
  available?: boolean,
  caption: element | string,
  from: Number,
  to: number,
  width: string,
  visible: boolean,
  assembled: boolean,
  resourceId: number,
  actions: Array<Action>,
  assembleId: number,
  logId: number,
  lineId: number,
  type: 1 | 2 | 3,
};
export type AssembleDetail = {
  id: number,
  type: 1 | 2 | 3,
  timeLine: Array<Timeline>,
};

export type Assemble = {
  id: number,
  detail: Array<AssembleDetail>,
  resources: Array<Resource>,
  actions: Array<Action>,
  timeLine: Array<Timeline>,
};

export const NORMATIVITY_VALIDITY_ITEMS = {
  1: {
    CAPTION: "Pendiente",
    ICON: "/images/info_pendiente.svg",
    COLOR: "#ff9800",
  },
  2: {
    CAPTION: "Validado",
    ICON: "/images/oval green.svg",
    COLOR: "#05c985",
  },
  3: {
    CAPTION: "Rechazado",
    ICON: "/images/interrogacion_red.svg",
    COLOR: "#ff4949",
  },
  4: {
    CAPTION: "Incompleto",
    ICON: "/images/interrogacion.svg",
    COLOR: "#aaa3a3",
  },
  5: {
    CAPTION: "Vencido",
    ICON: "/images/interrogacion_red.svg",
    COLOR: "#ff4949",
  },
  NONE: {
    CAPTION: "Desconocido",
    ICON: "/images/interrogacion.svg",
    COLOR: "#aaa3a3",
  },
};

export type ModalContentParams = {
  step: 1 | 2 | 3,
  vehiclesData: Array<any>,
  equipmentsData: Array<any>,
  operators: Array<any>,
  selectedDate: number,
  onlyOne: boolean,
  fleetVehicleTypesClasses: Array<any>,
  operatorLicenseTypes: Array<any>,
  unasembledVehicles: Array<any>,
  unasembledEquipments: Array<any>,
  unasembledOperators: Array<any>,
  fleetDatas: Array<any>,
  companyTags: Array<any>,
  modalOpeningDate: number,
  handleChange: Function,
  onSearch: Function,
  modalSearchText: string,
};
