// @flow
import React from "react";
import SVGIcon, { icons } from "../../shared/svg-icon";
import { FILES_SERVER } from "../../redux/api/api-urls";
import {
  AssembleAvatarStyled,
  AvatarProfileStyled,
} from "./assemble-edit-modal/styled";
import { Operator } from "../../shared/icons/assemble";

type Props = {
  fleetTypeId: number,
  description: string,
  avatarUrl?: string,
  color?: string,
  showIcon?: boolean,
  icon?: any,
};

export const AssembleAvatar = ({
  fleetTypeId,
  description,
  avatarUrl = "",
  color = "#AFC8FF",
  showIcon = true,
  icon,
}: Props) => {
  let avatar = null;
  if (typeof icon === "function") {
    icon = icon({ fill: color, stroke: color });
  }
  switch (fleetTypeId) {
    case 1:
      avatar = icon || (
        <SVGIcon
          name={icons.truck}
          height="22px"
          width="22px"
          fill={color}
          viewBox="-2 -9 22 22"
        />
      );
      break;
    case 2:
      avatar = icon || (
        <SVGIcon
          name={icons.equipment}
          height="22px"
          width="22px"
          fill={color}
          viewBox="0 -15 32 32"
        />
      );
      break;
    default:
      avatar =
        avatarUrl?.length > 0 && !avatarUrl?.includes("images/user.svg") ? (
          <AvatarProfileStyled
            src={
              avatarUrl?.includes(FILES_SERVER)
                ? avatarUrl
                : FILES_SERVER.concat(avatarUrl)
            }
            alt=""
          />
        ) : (
          Operator({ fill: color, width: "20px" })
        );
  }
  return (
    <AssembleAvatarStyled>
      {showIcon ? avatar : null}
      <span
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {description}
      </span>
    </AssembleAvatarStyled>
  );
};
