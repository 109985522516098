import styled from "styled-components";
import PanelCollapsible from "../../../shared/component/panel-collapsible";

export const CollapsibleHeaderList = styled(PanelCollapsible)`
  & .tag {
    // width: 13%;
    width: calc(15% - 28px);
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
    & .tag-body {
      min-width: 60px;
      text-align: center;
      cursor: pointer;
    }
  }
  & .caret {
    // left: -10px;
    position: relative;
    width: 18px;
    cursor: pointer;
  }
  & .header {
    background-color: #465477;
    justify-content: start !important;
    border-radius: 3px;
  }
`;
export const CollapsibleHeaderHeat = styled(PanelCollapsible)`
  // width: 100%;

  & .title {
    width: calc(15% - 18px);
  }

  & .caret {
    // left: -18px;
    // position: relative;
    // border: solid 1px red;
    width: 18px;
  }
  & .header {
    background-color: #465477;
    justify-content: start !important;
  }
`;
