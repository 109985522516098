import styled from "styled-components";
import Table from "../../../shared/component/table";
import PanelCollapsible from "../../../shared/component/panel-collapsible";

export const AssembleTable = styled(Table)`
  width: 100%;
  & .header {
    height: 43px;
    width: 100%;
    border-radius: 0;
    background-color: rgb(230, 235, 239);
    & .header-item {
      text-align: center;
      color: #252325;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  & .content {
    display: none;
  }
`;

export const FooterDatePickerText = styled.div`
  color: #004dff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 5px;
  margin-right: 5px;
  width: auto;
`;

export const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-top: 15px;
`;

export const TableCollapsible = styled(PanelCollapsible)``;

export const AssembleAvatarStyled = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  & svg,
  img {
    margin: 5px;
  }
`;
export const AvatarProfileStyled = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

export const AssembleModalOperatorRowStyled = styled.div``;

export const VerticalLineTooltipStyled = styled.div`
  margin: 10px;
`;
