// @flow

type RibbonDatesLabel = "MONTH" | "WEEKDAY";

export const getRibbonDates = (
  currentDate: Date,
  label: RibbonDatesLabel,
  tabLength: number = 10
): Array<{ id: string | number, description: string }> => {
  let c = 1;
  let dat = currentDate;
  let d = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate());
  let x: Array<{ id: string | number, description: string }> = [];
  while (c <= tabLength) {
    x.push({
      id: d.toISOString().split("T")[0],
      description:
        label === "MONTH"
          ? d
              .toString()
              .split(" ")
              .slice(1, 3)
              .join()
              .replace(",", "\n")
          : DAYS[d.getDay()].concat(" ", d.getDate()),
    });
    d.setDate(d.getDate() + 1);
    c++;
  }

  let y = [{ id: -tabLength, description: "❰" }];
  y = y.concat(x);
  y.push({ id: tabLength, description: "❱" });
  return y;
};

export const getRibbonWeeklyDates = (
  currentDate: Date,
  label: RibbonDatesLabel
): Array<{ id: string | number, description: string }> => {
  let c = 1;
  let dat = currentDate;
  let d = new Date(
    dat.getFullYear(),
    dat.getMonth(),
    dat.getDate() - dat.getDay()
  );
  let x: Array<{ id: string | number, description: string }> = [];
  while (c <= 7) {
    x.push({
      id: d.toISOString().split("T")[0],
      description:
        label === "MONTH"
          ? d
              .toString()
              .split(" ")
              .slice(1, 3)
              .join()
              .replace(",", "\n")
          : DAYS[d.getDay()].concat(" ", d.getDate()),
    });
    d.setDate(d.getDate() + 1);
    c++;
  }

  let y = [{ id: -7, description: "❰" }];
  y = y.concat(x);
  y.push({ id: 7, description: "❱" });
  return y;
};

const DAYS = {
  "0": "Dom",
  "1": "Lun",
  "2": "Mar",
  "3": "Mie",
  "4": "Jue",
  "5": "Vie",
  "6": "Sab",
};

export const SummaryFilters = (
  facilities: any[] = [],
  providers: any[] = [],
  transportClasses: any[] = [],
  orderTags: any[] = []
) => [
  {
    title: "Transportista",
    items: providers,
    name: "selectedProviders",
    listType: 5,
  },
  {
    title: "Clase",
    items: transportClasses,
    name: "transportClass",
    listType: 5,
  },
  {
    title: "Instalaciones",
    items: facilities,
    name: "selectedFacilities",
    listType: 5,
  },
  {
    title: "Ordenes",
    items: orderTags,
    name: "selectedOrderTags",
    listType: 1,
  }
];

export const convertDateStringToInt = (date) => {
  const dateRange = date.split(':');
  return (parseInt(dateRange[0]) * 60) + parseInt(dateRange[1]); 
}

export const convertDateToInt = (date) => {

  let hour = null;
  let minute = null;

  if (date.length === 4) {
    minute = date.slice(-2);
    hour = date.slice(0, -2);
  } else if (date.length === 3) {
    minute = date.slice(-2);
    hour = date.slice(0, 1);
  } else {
    minute = date.slice(-2);
    hour = 0;
  }

  return (parseInt(hour) * 60) + parseInt(minute);
}

export const getNotAllowedWindows = (dates, ranges, manuever_time, convert_time, own_manuever_time) => {

  let result_not_allowed = [];
  let result_overlapped = [];

  let dates_in_number = [];
  let ranges_in_number = [];

  let manuever_times = [];

  if (convert_time) {
    // Se convierten las fechas a numeros para validacion
    dates.forEach(item => {
      if (item[0] && item[1]) {
        dates_in_number.push([
          convertDateStringToInt(item[0]), convertDateStringToInt(item[1])
        ]);
      }
    });

    ranges.forEach(item => {
      if (item[0] && item[1]) {
        if (item[3].next_day) {

          if (item[3].first_day) {

            let end_time = convertDateStringToInt(item[1]);

            if (end_time === 0) {
              ranges_in_number.push([
                0,
                1440
              ]);
            } else {
              ranges_in_number.push([
                0,
                end_time
              ]);
            }

          } else {
            ranges_in_number.push([
              convertDateStringToInt(item[0]),
              1440 + convertDateStringToInt(item[1])
            ]);
          }

        } else {
          ranges_in_number.push([
            convertDateStringToInt(item[0]), convertDateStringToInt(item[1])
          ]);
        }

      }

      if (own_manuever_time && item[4]) {
        manuever_times.push(item[4]);
      }
    });
  } else {
    dates.forEach(item => {
      if (item.length > 1) {
        dates_in_number.push([
          item[0], item[1]
        ]);
      }
    });

    ranges.forEach(item => {
      if (item.length > 1) {
        ranges_in_number.push([
          item[0], item[1]
        ]);

        if (own_manuever_time && item[4]) {
          manuever_times.push(item[4]);
        }
      }
    });
  }

  // Se ordenan
  dates_in_number.sort((a, b) => a[0] - b[0])
  ranges_in_number.sort((a, b) => a[0] - b[0])

  // Se obtienen los espacios disponibles 
  ranges_in_number.forEach( (range_item, range_index, range_source) => {

    if (own_manuever_time && manuever_times[range_index]) {
      manuever_time = manuever_times[range_index];
    }

    let dates_in = dates_in_number.filter( date_item => {
      return date_item[0] >= range_item[0] && date_item[1] <= range_item[1];
    });

    if (dates_in.length > 0) {

      dates_in.forEach( (date_item, index, source) => {

        if (index === 0 && range_item[0] !== date_item[0]) {

          if ((date_item[0] - range_item[0]) >= manuever_time) {
            result_overlapped.push([
              date_item[0] - manuever_time + 1, 
              date_item[0] - 1
            ]);
          } else {
            result_overlapped.push(
              [range_item[0], date_item[0] - 1]
            );
          }

        }

        if (source[index + 1] && date_item[1] !== source[index + 1][0]) {

          if ((source[index + 1][0] - date_item[1]) >= manuever_time) {
            result_overlapped.push([
              source[index + 1][0] - manuever_time + 1, 
              source[index + 1][0] - 1
            ]);
          } else {
            result_overlapped.push([
              date_item[1], 
              source[index + 1][0] - 1
            ]);
          }

        }
        
      });

    } 

    if ((range_item[1] - range_item[0]) < manuever_time) {
      result_not_allowed.push(range_item);
    } else {

      if (range_source[range_index + 1] && range_source[range_index + 1][0] === range_item[1]) {
        result_not_allowed.push([
          range_item[1] - manuever_time + 1,
          range_item[1] - 1
        ]);
      } else {
        result_not_allowed.push([
          range_item[1] - manuever_time + 1,
          range_item[1]
        ]);
      }

    }

  });

  // Se filtran los espacios menores a la maniobra

  if (!own_manuever_time) {
    result_not_allowed = result_not_allowed.filter( item => {
      return (item[1] - item[0]) <= manuever_time;
    });
  }

  return {
    result_not_allowed,
    result_overlapped
  };
};

export const get_ranges_space = (ranges) => {
  return( ranges.map(range => {

    if (range[3].next_day) {
      return [
        range[3].first_day ? 0 : convertDateStringToInt(range[0]),
        range[3].first_day ? convertDateStringToInt(range[1]) : 1440
      ]
    } 

    return [
      convertDateStringToInt(range[0]),
      convertDateStringToInt(range[1])
    ]
  }));
}

export const sot_spaces_using = (spaces, time) => {
  if (time === '') { 
    spaces.sort((a, b) => parseInt(a.space_number) - parseInt(b.space_number));
    return spaces; 
  }
  
  // Se convierte la fecha a validar a int
  let time_in_number = convertDateStringToInt(time);
  let spaces_in = [];
  let spaces_out = [];

  spaces.forEach( space => {

    // se obtienen las ventanas convertidos en int
    let ranges = get_ranges_space(space.ranges);
    let is_space_in = false;

    ranges.forEach( range => {
      if (
        time_in_number >= range[0] && time_in_number < range[1] ||
        range[0] >= time_in_number && range[0] < (time_in_number + 5)
      ) {
        space.init_date_to_compare = range[0];
        space.end_date_to_compare = range[1];
        is_space_in = true;
      }
    });

    // Se separan los espacios que si entran en la validacion
    if (is_space_in) {
      spaces_in.push(space);
    } else {
      spaces_out.push(space);
    }

  });

  let first_group = [];
  let second_group = [];
  let third_group = [];

  spaces_in.forEach( item => {

    if (item.init_date_to_compare === time_in_number) {
      first_group.push(item);
    } else if (item.end_date_to_compare === (time_in_number + 5)) {
      second_group.push(item);
    } else {
      third_group.push(item);
    }

  });

  // se concatenan los espacios
  let result = first_group.concat(second_group).concat(third_group).concat(spaces_out);

  return result;
}