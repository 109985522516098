import React from "react";
const TATPlantaTransparent = (props) => {
  return (
    <svg 
      width="22px" 
      height="22px" 
      viewBox="0 0 24 22" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      {...props}
    ><g id="vistas" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M23.676,4.83347853 C23.873532,4.93499204 23.9982347,5.13659837 24,5.35728806 L24,20.8334785 C24,21.1622195 23.7313708,21.4287166 23.4,21.4287166 L21,21.4287166 C20.6686292,21.4287166 20.4,21.1622195 20.4,20.8334785 L20.4,8.33347853 L3.6,8.33347853 L3.6,20.8334785 C3.6,21.1622195 3.33137085,21.4287166 3,21.4287166 L0.6,21.4287166 C0.26862915,21.4287166 -5.32907052e-15,21.1622195 -5.32907052e-15,20.8334785 L-5.32907052e-15,5.35728806 C0.00176528776,5.13659837 0.126467971,4.93499204 0.324,4.83347853 L11.724,0.071573773 C11.8954273,-0.0238579243 12.1045727,-0.0238579243 12.276,0.071573773 L23.676,4.83347853 Z M7.6,16.6668119 L10.4,16.6668119 C10.9522847,16.6668119 11.4,17.1145271 11.4,17.6668119 L11.4,20.4287166 C11.4,20.9810014 10.9522847,21.4287166 10.4,21.4287166 L7.6,21.4287166 C7.04771525,21.4287166 6.6,20.9810014 6.6,20.4287166 L6.6,17.6668119 C6.6,17.1145271 7.04771525,16.6668119 7.6,16.6668119 Z M13.6,16.6668119 L16.4,16.6668119 C16.9522847,16.6668119 17.4,17.1145271 17.4,17.6668119 L17.4,20.4287166 C17.4,20.9810014 16.9522847,21.4287166 16.4,21.4287166 L13.6,21.4287166 C13.0477153,21.4287166 12.6,20.9810014 12.6,20.4287166 L12.6,17.6668119 C12.6,17.1145271 13.0477153,16.6668119 13.6,16.6668119 Z M7.6,10.7144309 L10.4,10.7144309 C10.9522847,10.7144309 11.4,11.1621462 11.4,11.7144309 L11.4,14.4763357 C11.4,15.0286204 10.9522847,15.4763357 10.4,15.4763357 L7.6,15.4763357 C7.04771525,15.4763357 6.6,15.0286204 6.6,14.4763357 L6.6,11.7144309 C6.6,11.1621462 7.04771525,10.7144309 7.6,10.7144309 Z" id="ic/almacen_gris" fill="currentColor"></path></g></svg>

  );
};

export default TATPlantaTransparent;