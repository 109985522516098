// @flow
import * as React from "react";
import SVGIcon, { icons } from "../../shared/svg-icon";

type Props = {
  className: string,
  maxExpandedHeight: string,
  collapsedHeight: string,
  title: any,
  headStyle: any,
  contentStyle: any,
  children: any,
  initialStateCollapsed: boolean,
  caret?: { fill?: String, stroke?: String },
  headerContent?: any,
};

type State = {
  collapsed: boolean,
};

class PanelCollapsible extends React.Component<Props, State> {
  state = {
    collapsed: this.props.initialStateCollapsed,
    isHovered: false
  };
  static defaultProps = {
    collapsed: true,
    maxExpandedHeight: "auto",
    collapsedHeight: "50px",
    title: "",
    className: "",
    headStyle: {},
    contentStyle: {},
    caret: { fill: "#000000", stroke: "#70889E" },
    initialStateCollapsed: true,
    headerContent: null,
  };

  setCollapsed = (e) => {
    // e.stopPropagation();
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const {
      title,
      className,
      maxExpandedHeight,
      collapsedHeight,
      headStyle,
      contentStyle,
      caret,
      headerContent,
      // redirect,
    } = this.props;
    const { collapsed } = this.state;
    return (
      <div
        className={`panel-collapsible ${className}`}
        // collapsed={collapsed}
        style={{ maxHeight: maxExpandedHeight, ...headStyle }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: collapsedHeight,
          }}
          // onClick={redirect || this.setCollapsed}
          onClick={this.setCollapsed}
        >
          {title}
          <div
            className="caret"
            style={{
              transform: `rotate(${!collapsed ? "0deg" : "180deg"})`,
              transition: "transform 0.2s ease-out",
            }}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false})}
            // onClick={this.setCollapsed}
          >
            <SVGIcon
              name={icons.caretDown}
              fill={caret.fill}
              stroke={caret.stroke}
              width={this.state.isHovered ? "22px" : "18px"}
              height={this.state.isHovered ? "22px" : "18px"}
            />
          </div>
          {headerContent}
        </div>
        <div
          style={{
            height: collapsed ? "0" : "auto",
            transition: "max-height 0.9s ease-in-out",
            overflow: "hidden",
            maxHeight: maxExpandedHeight,
            ...contentStyle,
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PanelCollapsible;
