import React, { Component } from "react";
import {
  ClassItem,
  Ellipsis,
  ClassItemButton,
  ClassItemActions,
  ClassItemSelect,
  ClassItemInput,
  ClassItemInputDouble,
  EllipsisToolTip,
} from "../style";
import { Operator, DoubleOperator } from "../../../../shared/icons/assemble";
import { ArrowIconMargin } from "../../style";
import Button from "../../../../shared/controls/button";
import { Flex, flexOpts } from "../../../../shared/styled/flex";

class RouteClassesItemsUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "",
      classId: 0,
      distance: 0,
      outward_trip_time_one_hr: 0,
      outward_trip_time_one_min: 0,
      outward_trip_time_two_hr: 0,
      outward_trip_time_two_min: 0,
      return_trip_time_one_hr: 0,
      return_trip_time_one_min: 0,
    };
  }

  componentDidMount() {
    const { row } = this.props;
    this.setState({
      class: row.class,
      classId: parseFloat(row.classId),
      distance: parseFloat(row.distance),
      outward_trip_time_one_hr: this.convertToHours(
        parseFloat(row.transit[0]) || 0,
        "hr"
      ),
      outward_trip_time_one_min: this.convertToHours(
        parseFloat(row.transit[0]) || 0,
        "min"
      ),
      outward_trip_time_two_hr: this.convertToHours(
        parseFloat(row.transit[1]) || 0,
        "hr"
      ),
      outward_trip_time_two_min: this.convertToHours(
        parseFloat(row.transit[1]) || 0,
        "min"
      ),
      return_trip_time_one_hr: this.convertToHours(
        parseFloat(row.return[0]) || 0,
        "hr"
      ),
      return_trip_time_one_min: this.convertToHours(
        parseFloat(row.return[0]) || 0,
        "min"
      ),
    });
  }

  getRows = (row) => {
    switch (row) {
      case 3:
        return <ArrowIconMargin width="12px" />;

      default:
        return "";
    }
  };

  validSave = () => {
    if (
      (this.state.classId !== 0 || this.state.className.length > 0) &&
      this.state.distance !== 0 &&
      this.state.outward_trip_time_one !== 0 &&
      this.state.return_trip_time_one !== 0
    ) {
      return false;
    }
    return true;
  };

  handleInput = (e) => {
    if (e.target.name === "classId") {
      let value = this.props
        .classesItems()
        .find((item) => String(item.id) === e.target.value).description;
      this.setState({ class: value });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  getRowData = () => {
    this.props.setRow({
      id: this.props.row.id === -1 ? 0 : this.props.row.id,
      key: this.props.rowKey,
      class: this.state.class,
      classId: this.state.classId,
      distance: this.state.distance,
      outward_trip_time_one:
        parseInt(this.state.outward_trip_time_one_hr * 60) +
        parseInt(this.state.outward_trip_time_one_min),
      outward_trip_time_two:
        parseInt(this.state.outward_trip_time_two_hr * 60) +
        parseInt(this.state.outward_trip_time_two_min),
      return_trip_time_one:
        parseInt(this.state.return_trip_time_one_hr * 60) +
        parseInt(this.state.return_trip_time_one_min),
    });
  };

  convertToHours = (time, type) => {
    if (parseInt(time) !== 0) {
      var num = time;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (type === "hr") {
        return rhours;
      }
      return rminutes;
    }
    return time;
  };

  render() {
    const { config, classesItems, rowKey, removeRow } = this.props;
    const {
      classId,
      distance,
      configuration,
      outward_trip_time_one_hr,
      outward_trip_time_one_min,
      outward_trip_time_two_hr,
      outward_trip_time_two_min,
      return_trip_time_one_hr,
      return_trip_time_one_min,
    } = this.state;
    return (
      <React.Fragment>
        <ClassItem>
          <div style={{ width: config[0].width }}>
            {/* <img
              alt=""
              src="/images/caret.svg"
              //   onClick={() => this.openChild()}
            /> */}
          </div>
          <div style={{ width: config[1].width }}>
            <ClassItemSelect
              label=""
              name="classId"
              value={classId}
              className="item"
              onChange={(e) => {
                this.handleInput(e);
              }}
              items={classesItems()}
              //   validate={[validators.required]}
              //   onFocused={this.state.onFocused}
            />
          </div>
          <div style={{ width: config[2].width }}>{configuration}</div>
          <div style={{ width: config[3].width }}>
            <ClassItemInput
              label=""
              name="distance"
              value={String(distance)}
              className="item"
              onChange={(e) => {
                this.handleInput(e);
              }}
              //   validate={[validators.required]}
              //   onFocused={this.state.onFocused}
              //   errors={
              //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
              //   }
              //   handleBlur={(e) => {
              //     if (String(alias.ownAlias) !== String(e.target.value)) {
              //       alias.duplicated(e.target.value);
              //     }
              //   }}
            />
            km
          </div>
          <div style={{ width: config[4].width }}>
            <Flex direction={flexOpts.direction.column}>
              <Operator />
              <DoubleOperator />
            </Flex>
          </div>
          <div
            style={{
              width: config[5].width,
            }}
          ></div>
          <div style={{ width: config[6].width }}></div>
          <div
            style={{
              width: config[7].width,
            }}
          >
            <Flex direction={flexOpts.direction.column}>
              <div>
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_one_hr"
                  value={String(outward_trip_time_one_hr)}
                  className="item"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                hr
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_one_min"
                  value={String(outward_trip_time_one_min)}
                  className="item separate"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                min
              </div>
              <div>
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_two_hr"
                  value={String(outward_trip_time_two_hr)}
                  className="item"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                hr
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_two_min"
                  value={String(outward_trip_time_two_min)}
                  className="item separate"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                min
              </div>
            </Flex>
          </div>
          <div style={{ width: config[8].width }}></div>
          <div
            style={{
              width: config[9].width,
            }}
          >
            <Flex direction={flexOpts.direction.column}>
              <div>
                <ClassItemInputDouble
                  label=""
                  name="return_trip_time_one_hr"
                  value={String(return_trip_time_one_hr)}
                  className="item"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                hr
                <ClassItemInputDouble
                  label=""
                  name="return_trip_time_one_min"
                  value={String(return_trip_time_one_min)}
                  className="item separate"
                  onChange={(e) => {
                    this.handleInput(e);
                  }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                min
              </div>
              <div>
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_two_hr"
                  value={String(outward_trip_time_two_hr)}
                  className="item"
                  disabled={true}
                  //   onChange={(e) => {
                  //     if (alias.dupliedAlias)
                  //       handleInput(this.createTargetData("dupliedAlias", false));
                  //     handleInput(e);
                  //   }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                hr
                <ClassItemInputDouble
                  label=""
                  name="outward_trip_time_two_min"
                  value={String(outward_trip_time_two_min)}
                  className="item separate"
                  disabled={true}
                  //   onChange={(e) => {
                  //     if (alias.dupliedAlias)
                  //       handleInput(this.createTargetData("dupliedAlias", false));
                  //     handleInput(e);
                  //   }}
                  //   validate={[validators.required]}
                  //   onFocused={this.state.onFocused}
                  //   errors={
                  //     alias.dupliedAlias ? [`El alias "${alias.value}" esta en uso`] : []
                  //   }
                  //   handleBlur={(e) => {
                  //     if (String(alias.ownAlias) !== String(e.target.value)) {
                  //       alias.duplicated(e.target.value);
                  //     }
                  //   }}
                />
                min
              </div>
            </Flex>
          </div>
          <div
            style={{
              width: config[10].width,
            }}
          >
            {/* <ClassItemMap>
              <a href="">Ver mapa</a>
            </ClassItemMap> */}

            <ClassItemButton>
              <Button
                settings={{
                  type: "primary",
                  text: "Guardar",
                  disabled: this.validSave(),
                  //   text: this.savingDataText(),
                  onClick: this.getRowData,
                  // onClick: this.createRoute,
                }}
                // dataTip={this.getErrors()}
                // dataFor="orbi-tooltip-error"
                // onMouseLeave={() => this.handleLeave()}
              />
            </ClassItemButton>
            <ClassItemActions
              data-tip
              data-for={"tooltip-routes-child-".concat(rowKey)}
            >
              <Ellipsis>
                <b></b>
              </Ellipsis>
            </ClassItemActions>
            <EllipsisToolTip
              id={"tooltip-routes-child-".concat(rowKey)}
              clickable={true}
              event="click"
              globalEventOff="click"
              delayHide={200}
              delayShow={200}
              delayUpdate={200}
              place={"left"}
              border={true}
              type={"light"}
            >
              <div
                className="tooltip-option"
                id={"tooltip-action-routes".concat(rowKey)}
                onClick={() => {
                  removeRow(rowKey, this.props.row.id === -1 ? false : true);
                }}
              >
                Cancelar
              </div>
              {/* <div
                className="tooltip-option"
                id={"tooltip-action-delete-".concat("yxed")}
                onClick={() => {
                  this.handleDeleteOption(contract, LT);
                }}
              >
                Eliminar contrato
              </div> */}
            </EllipsisToolTip>
          </div>
        </ClassItem>
      </React.Fragment>
    );
  }
}

export default RouteClassesItemsUpdate;

RouteClassesItemsUpdate.defaultProps = {};
