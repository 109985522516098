import React from 'react';
import Chip from '../controls/chip';

class SuggestionItemRow extends React.Component {
    handleChipClick = (e) => {
        this.props.swapTag && this.props.swapTag(this.props.item.id, e.target.name, !e.target.value);
    }
    render() {
        return (
            <div className="suggestion-item-row">
                <div className="credentials">
                    <img alt="" src={this.props.avatar}/>
                    {this.props.item.name}
                </div>
                <div className="tags">
                    <div className="active-tags">
                        {
                            this.props.item.originalTags.map(tag => {
                                if(this.props.item.removedTags.includes(tag)){
                                    return null;
                                } else {
                                    return <Chip id={tag} description={tag} active={true} onClick={this.handleChipClick}/>
                                }
                            })
                        }
                        {
                            this.props.item.addedTags.map(tag => {
                                return <Chip id={tag} description={tag} active={true} onClick={this.handleChipClick}/>
                            })
                        }
                    </div>
                    <div className="suggestions">
                        {
                            Object.keys(this.props.sharedTags).map(tag => {
                                if((this.props.item.originalTags.includes(tag) && !this.props.item.removedTags.includes(tag)) || this.props.item.addedTags.includes(tag)){
                                    return null;
                                } else if (this.props.sharedTags[tag] > (this.props.totalItems * 0.4)){
                                    return <Chip id={tag} description={tag} active={false} onClick={this.handleChipClick}/>
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SuggestionItemRow;

SuggestionItemRow.defaultProps = {
    avatar: "/images/help.png"
}