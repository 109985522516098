import React from "react";
import FleetData from "./fleet-data";
import FleetDataTitle from "./fleet-data-title";

class FleetInfoData extends React.Component {
  constructor() {
    super();

    this.state = {
      isCollapsed: true,
      isHovered: false
    };
  }

  render() {
    const {
      companyName = "",
      logoUrl = "",
      operators,
      vehicles,
      trailers
    } = this.props.data;

    return (
      <div
        className="fleet-container"
        style={{ marginTop: "15px", backgroundColor: "rgb(35, 42, 59)" }}
      >
        <div className="fleet-container fleet-column">
          <FleetDataTitle
            isDataCollapsed={this.state.isCollapsed}
            companyName={companyName}
            logoUrl={logoUrl}
          />
        </div>

        <div className="fleet-container fleet-column">
          <FleetData
            isDataCollapsed={this.state.isCollapsed}
            data={operators}
          />
        </div>

        <div className="fleet-container fleet-column">
          <FleetData isDataCollapsed={this.state.isCollapsed} data={vehicles} />
        </div>

        <div className="fleet-container fleet-column">
          <FleetData isDataCollapsed={this.state.isCollapsed} data={trailers} />
        </div>

        <div
          className="fleet-container collapse-btn"
          onClick={() => {
            this.setState({
              isCollapsed: !this.state.isCollapsed
            });
          }}
          style={{
            cursor: 'pointer'
          }}
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
        >
          <img
            alt=""
            src="/images/select-arrow.svg"
            width={this.state.isHovered ? "23px" : "19px"}
            height={this.state.isHovered ? "29px" : "25px"}
            style={{
              transform: this.state.isCollapsed
                ? "rotate(0deg)"
                : "rotate(180deg)"
            }}
          ></img>
        </div>
      </div>
    );
  }
}

export default FleetInfoData;
