import React, { Component } from 'react';
import Caret from '../component/caret';

class SearchTypeDropdown extends Component {

  state = {
    open: false,
    isHovered: false
  }

  getSelectedString = () => {
    const items = this.props.items || [];
    let valueToReturn = '';

    items.map( item => {
      if (`${(item.value || '')}` === `${this.props.selected || ''}`) {
        valueToReturn = item.title || '';
      }
    });

    return valueToReturn;
  }

  getItemsToSelect = () => {
    const items = this.props.items || [];
    let itemsToShow = [];

    items.map( item => {

      let isSelected = false;

      if (`${(item.value || '')}` === `${this.props.selected || ''}`) {
        isSelected = true;
      }

      itemsToShow.push(
        isSelected ? 
        <div
          style={{
            fontSize: 13,
            color: 'white',
            cursor: 'pointer',
            height: 30,
            backgroundColor: 'rgb(175, 200, 255)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => {
            this.setState({ open: false });

            if (this.props.onSelect && !isSelected) {
              this.props.onSelect(item.value || '');
            }
          }}
        >
          {item.title}
        </div>
        :
        <div
          style={{
            fontSize: 13,
            color: 'black',
            cursor: 'pointer',
            height: 30,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => {
            this.setState({ open: false });

            if (this.props.onSelect && !isSelected) {
              this.props.onSelect(item.value || '');
            }
          }}
        >
          {item.title}
        </div>
      );
    });

    return itemsToShow;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node.contains(e.target) || this.content.contains(e.target)) return;
    this.setState({ open: false });
  };

  buildMenu = () => {
    let styleToShow = 1;
    const items = this.props.items || [];

    if (this.props.styleMenu) {
      styleToShow = this.props.styleMenu;
    }

    if (styleToShow === 1) {
      return(
        <div
          ref={node => (this.content = node)}
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            right: 0,
            top: 25,
            borderRadius: 8,
            visibility: this.state.open ? 'visible' : 'hidden',
            paddingBottom: 10,
            paddingTop: 20,
            paddingRight: 20,
            paddingLeft: 20,
            width: 150,
            fontSize: 15,
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          {this.getItemsToSelect()}
        </div>
      )
    } else {
      return(
        <div
          ref={node => (this.content = node)}
          style={{
            border: '1px solid black',
            backgroundColor: 'white',
            position: 'absolute',
            left: 0,
            top: -((items.length * 30) + 40),
            borderRadius: 8,
            visibility: this.state.open ? 'visible' : 'hidden',
            paddingBottom: 10,
            paddingTop: 20,
            paddingRight: 20,
            paddingLeft: 20,
            width: 150,
            fontSize: 15,
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          {this.getItemsToSelect()}
        </div>
      )
    }

  }

  render() {
    return(
      <div 
        ref={node => (this.node = node)}
        style={{
          marginLeft: 25, 
          marginRight: 25,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          lineHeight: '25px'
        }}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <div
          style={{
            fontSize: 13,
            paddingRight: 10,
            cursor: 'pointer',
          }}
          onClick={ () => this.setState({open: !this.state.open})}
        >{this.getSelectedString()}</div>

        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={ () => this.setState({open: !this.state.open})}
        >
          <Caret 
            fill="rgb(175, 200, 255)"
            up={!this.state.open}
            isHovered={this.state.isHovered}
          />
        </div>

        { this.buildMenu() }

      </div>
    );
  }

}

export default SearchTypeDropdown;