import React from "react";
const DownloadImage = (props) => {
  return (
    <svg
      width="94px"
      height="90px"
      viewBox="0 0 94 90"
      fill="currentColor"
      {...props}
    >
      <g
        id="descarga"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="np_save_2209758_000000"
          transform="translate(23.000000, 21.000000)"
          fill="currentColor"
          fillRule="nonzero"
          {...props}
        >
          <path
            d="M48,34.3840406 L48,45.4755078 C48,45.6973877 47.9691093,45.9020124 47.9242428,46.0918435 C47.7117195,47.1815515 46.7907772,48 45.7021667,48 L2.28348337,48 C1.11930843,48 0.153535317,47.0680744 0.0306978748,45.8847069 C0,45.7417182 0,45.6159846 0,45.4582023 L0,34.3667351 C0,33.0576466 1.02719184,32 2.26693114,32 C2.88559176,32 3.46181543,32.2687223 3.86792859,32.6952521 C4.27644772,33.1217567 4.53386228,33.7208373 4.53386228,34.3667351 L4.53386228,43.2492495 L43.4803438,43.2492495 L43.4803438,34.3667351 C43.4803438,33.0576466 44.5075357,32 45.747275,32 C46.3659356,32 46.9421593,32.2687223 47.3482724,32.6952521 C47.7426217,33.1365504 48,33.7356057 48,34.384028 L48,34.3840406 Z"
            id="Path"
          ></path>
          <path
            d="M36.2435549,23.4253277 L25.3789657,34.1962249 C25.3651459,34.2259088 25.3352037,34.2396095 25.3190825,34.2555916 C24.9206199,34.6506181 24.4046787,34.8995307 23.8887493,34.9725993 C23.8449873,34.9725993 23.8012241,34.9862993 23.7551619,34.9862993 C23.6676367,35 23.5778119,35 23.4902867,35 L23.2392325,34.9862993 C23.195471,34.9862993 23.1517073,34.9725993 23.1056451,34.9725993 C22.5758946,34.8995307 22.0737862,34.6506415 21.6753118,34.2555916 C21.661492,34.2418916 21.6315498,34.2122076 21.6154286,34.1962249 L10.7508395,23.4253277 C10.248731,22.9275493 10,22.2699084 10,21.612291 C10,20.9546735 10.2510542,20.297056 10.7508395,19.7992542 C11.7527449,18.8059888 13.3903933,18.8059888 14.4060962,19.7992542 L20.935699,26.2725482 L20.9426089,2.55740124 C20.9426089,1.15541926 22.1080787,0 23.522256,0 C24.2293447,0 24.8788851,0.292274427 25.3510637,0.744364511 C25.8232305,1.21245954 26.1019031,1.84039361 26.1019031,2.55740124 L26.1019031,26.2748864 L32.6315059,19.8015924 C33.6334114,18.808327 35.2710598,18.808327 36.2867627,19.8015924 C37.2449173,20.8108745 37.2449173,22.4320623 36.2430006,23.4253277 L36.2435549,23.4253277 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default DownloadImage;
