import React, { Component } from "react";
import { ClassItemDetails, ClassItemzones } from "../style";

class RouteClassesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { sections, details, isShow } = this.props;
    return (
      <ClassItemDetails className={`${isShow}`}>
        <div className="header">
          {details.map((itemDetail, key) => {
            return (
              <div style={{ width: itemDetail.width }} key={key}>
                {itemDetail.headerText}
              </div>
            );
          })}
        </div>
        <div className="container">
          {sections.map((section, key) => {
            return (
              <div key={key}>
                <div
                  style={{ width: details[0].width }}
                  className="nombre_tramo"
                >
                  <span>
                    {key + 1}.- {section.nombre_tramo}
                  </span>
                </div>
                <div style={{ width: details[1].width }}>
                  {section.num_ruta}
                </div>
                <div style={{ width: details[2].width }}>{section.rpd}</div>
                <div style={{ width: details[3].width }}>
                  {section.kms_tramo}
                </div>
                <div style={{ width: details[4].width }}>
                  {section.clase_carretera}
                </div>
                <div style={{ width: details[5].width }}>
                  <ClassItemzones>
                    <img
                      src="/images/Zona_Descanso_Rutas_Inactiva.png"
                      alt=""
                    />
                    <span>0</span>
                  </ClassItemzones>
                  <ClassItemzones>
                    <img
                      src="/images/Zona_Prohibida_Rutas_Inactiva.png"
                      alt=""
                    />
                    <span>0</span>
                  </ClassItemzones>
                  <ClassItemzones>
                    <img src="/images/Zona_Riesgo_Rutas_Inactiva.png" alt="" />
                    <span>0</span>
                  </ClassItemzones>
                </div>
              </div>
            );
          })}
        </div>
      </ClassItemDetails>
    );
  }
}

export default RouteClassesDetails;

RouteClassesDetails.defaultProps = {};
