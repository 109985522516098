//@flow
import * as React from "react";
import styled from "styled-components";

type Props = {|
  onChange: Function,
  value: any,
  name: string,
  items: Array<{
    description: any,
    value: number | string,
  }>,

  bgColor?: string,
  color?: string,
  selectedColor?: string,
  selectedBgColor?: string,
  height?: string,
|};

const OptionSelectorSwitch = (props: Props) => {
  const { items, value, onChange, name } = props;
  return (
    <OptionSelectorSwitchStyled {...props}>
      {items.map((item, index) => (
        <OptionSelectorItemStyled
          key={index}
          selected={item.value === value}
          onClick={() => onChange({ target: { name, value: item.value } })}
          {...props}
        >
          {item.description}
        </OptionSelectorItemStyled>
      ))}
    </OptionSelectorSwitchStyled>
  );
};

OptionSelectorSwitch.defaultProps = {
  onChange: () => {
    alert("Not implemented yet!");
  },
  items: [],
  bgColor: "#212a3c",
  color: "#ffffff",
  height: "34px",
  selectedBgColor: "#485c85",
  selectedColor: "#ffffff",
};

export default OptionSelectorSwitch;

const OptionSelectorSwitchStyled: React.ComponentType<{}> = styled.div`
  height: ${({ height }) => height};
  line-height: ${({ height }) => height};
  display: flex;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
  color: ${({ color }) => color};
  overflow: overlay;
  align-items: center;
`;

const OptionSelectorItemStyled = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: ${({ selected, selectedBgColor, bgColor }) =>
    selected ? selectedBgColor : bgColor};
  color: ${({ selected, selectedColor, color }) =>
    selected ? selectedColor : color};
  transition: background-color 0.2s ease-in-out;
`;
