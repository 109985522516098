import * as React from "react";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import Tag from "../../shared/controls/tag";
import TextInput from "../../shared/controls/text-input";
import SVGIcon, { icons } from "../../shared/svg-icon";
import { LOG_FORMAT, US_HOURLY_FORMAT } from "../../shared/utils";

class ConsolidateOrderModal extends React.Component {
  state = {

  }
  
  onClose = () => {
    this.props.onClose();
  }

  createOrder = () => {
    this.props.handleChange({
      target: {
        name: "consolidateOrder",
        value: this.onClose
      }
    })
  }

  handleTagClick = (e) => {
    if (e.target.value) {
      this.props.handleChange({
        target: {
          name: "selectedTags",
          value: this.props.selectedTags.filter((t) => t !== e.target.id)
        }
      });
    } else {
      this.props.handleChange({
        target: {
          name: "selectedTags",
          value: this.props.selectedTags.concat(e.target.id)
        }
      });
    }
  };

  render () {
    return  <Modal
        isOpen={this.props.show}
        portalClassName="dialog emails-modal invite-users-modal consolidate-order-modal"
        overlayClassName="overlay"
        className="content"
      >
        <div className="title">
          Consolidar orden
        </div>
        <div className="close" onClick={this.onClose}></div>
        <div className="message">
          <div style={{width: "50%"}}>
            <TextInput
              label="Código de orden"
              name="selectedCode"
              value={this.props.selectedCode}
              className="item"
              onChange={this.props.handleChange}
              // disabled={true}
            />
          </div>
          <div>
            <div style={{display: "flex"}}>
              <div style={{width: "60%", marginRight: "15px"}}>
                <TextInput
                  type="date"
                  format={"dd/MMM/yyyy HH:mm"}
                  minDate={
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate() - 1
                    )
                  }
                  value={
                    this.props.selectedDate
                  }
                  label="Entrega deseada"
                  name="selectedDate"
                  onChange={this.props.handleChange}
                  showTimeSelect={true}
                  timeValueFormat={US_HOURLY_FORMAT}
                  suffix={{
                    element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                  }}
                />
              </div>
              <div>
                <TextInput
                  label="Nivel de criticidad"
                  name="selectedLevel"
                  naturalNumber={true}
                  value={this.props.selectedLevel}
                  className="item"
                  onChange={this.props.handleChange}
                  // disabled={true}
                />
              </div>
            </div>
            <div>
              <span style={{fontSize: "12px", lineHeight: "18px", color: "#9ba1acb3"}}>Selecciona las etiquetas de tu orden (opcional)</span>
              <div className="user-tags-options-container">
                {(this.props.tagsCatalog || []).map((tag) => {
                  return (
                    <Tag
                      key={tag.id}
                      id={tag.id}
                      color={tag.color}
                      title={tag.title}
                      type={tag.type}
                      isFull={this.props.selectedTags.includes(tag.id)}
                      onClick={this.handleTagClick}
                      parentBackground="light"
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="orders-container">
            <div className="resume-header">
              Pedidos seleccionados:
            </div>
            <div className="row-header">
              <div style={{width: "30%"}}>Código</div>
              <div style={{width: "35%"}}>Origen</div>
              <div style={{width: "35%"}}>Destino</div>
            </div>
            <div className="row-container">
              {
                this.props.selectedOrders.map(o => (
                  <div className="order-row">
                    <div style={{width: "30%"}}>{o.code}</div>
                    <div style={{width: "35%"}}>{o.from_facility?.name || '-'}</div>
                    <div style={{width: "35%"}}>{o.to_facility?.name || '-'}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="actions">
          <Button
            text={"Cancelar"}
            type="secondary outline"
            onClick={this.onClose}
            // disabled={!this.props.selectedCode}
          />
          <Button
            text={"Consolidar orden"}
            type="primary outline"
            onClick={this.createOrder}
            disabled={!this.props.selectedCode}
          />
        </div>
      </Modal>
  }

}

export default ConsolidateOrderModal