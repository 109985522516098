import React from "react";
import { connect } from "react-redux";
import { pullAll } from "lodash";
import NotificationRow from "./notification-row";
import InputTagger from "../controls/inputTagger";
import ConfirmDialog from "../dialog/confirm-dialog";
import { listCompanyNotifications } from "../../redux/reducers/notifications-reducer";
import { listFacilityNotifications } from "../../redux/reducers/notifications-reducer";
import { setFacilityNotification } from "../../redux/reducers/notifications-reducer";
import { listFacilityNotificationEmailsAndUsers } from "../../redux/reducers/notifications-reducer";
import { createFacilityNotificationEmail } from "../../redux/reducers/notifications-reducer";
import { removeFacilityNotificationEmail } from "../../redux/reducers/notifications-reducer";
import { createFacilityNotificationUser } from "../../redux/reducers/notifications-reducer";
import { removeFacilityNotificationUser } from "../../redux/reducers/notifications-reducer";
import { getUrlsEnv } from "../backend-api";
import { getUserByEmail } from "../../redux/reducers/user-reducer";
import { loadCompanyUsers } from "../../redux/reducers/company-reducer";

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInviteModal: false
    };
  }

  componentDidMount() {
    this.props.listFacilityNotifications(this.props.facilityId);
  }

  handleNotificationChange = e => {
    const { name, checked = false } = e.target;

    const instructions = name.split("-");
    const action = instructions[0];
    const id = instructions[1];

    const currentNotification =
      this.props.notifications.find(
        notif => notif.notificationId === parseInt(id)
      ) || {};
    switch (action) {
      case "active":
        this.props.setFacilityNotification(
          this.props.facilityId,
          currentNotification.notificationId,
          currentNotification.id,
          checked
        );
        break;
      case "editEmails":
        this.manageModal(currentNotification);
        break;
      case "setEmails":
        // currentNotification.emails = value;

        break;
      case "clear":
        currentNotification.emails.email_list.forEach(em =>
          this.props.removeFacilityNotificationEmail(
            this.props.facilityId,
            currentNotification.id,
            em[0]
          )
        );
        currentNotification.emails.user_list.forEach(em =>
          this.props.removeFacilityNotificationUser(
            this.props.facilityId,
            currentNotification.id,
            em[0]
          )
        );
        break;
      default:
        return;
    }
  };
  handleEmailsChange = e => {
    const { tags } = this.state;
    const toAdd = pullAll(
      e.target.value.map(t => t.email),
      tags.map(l => l.email)
    );
    const toRemove = pullAll(
      tags.map(l => l.email),
      e.target.value.map(t => t.email)
    );
    toAdd.forEach(a => this.getEmailTag(a));
    toRemove.forEach(a =>
      this.setState({ tags: tags.filter(t => t.email !== a) })
    );
  };

  getEmailTag = email => {
    this.props.getUserByEmail(email, "", "").then(() => {
      let tag = {
        email: email,
        avatar: "/images/mail.svg",
        avatarType: "contained",
        userId: null,
        processed: true
      };
      if (this.props.searchedUser.length) {
        tag = {
          email: email,
          avatar: this.props.searchedUser[0].profile_image,
          avatarType: "full",
          userId: this.props.searchedUser[0].id
        };
        tag.avatar = getUrlsEnv().files.concat(tag.avatar);
      }
      this.setState({
        tags: (this.state.tags || []).concat([tag])
      });
    });
  };

  // manageModal = (notification = {}) => {
  //   this.setState({
  //     openInviteModal: !this.state.openInviteModal,
  //     notification
  //   });
  // };

  manageModal = (ntf = {}) => {
    const { notification, tags } = this.state;
    if (ntf === true) {
      pullAll(
        tags.filter(t => t.avatarType === "contained").map(t => t.email),
        notification.emails.email_list.map(em => em[1])
      ).forEach(em =>
        this.props.createFacilityNotificationEmail(
          this.props.facilityId,
          notification.id,
          em
        )
      );

      pullAll(
        notification.emails.email_list.map(t => t[1]),
        tags.filter(t => t.avatarType === "contained").map(t => t.email)
      ).forEach(em =>
        this.props.removeFacilityNotificationEmail(
          this.props.facilityId,
          notification.id,
          notification.emails.email_list.find(xy => xy[1] === em)[0]
        )
      );

      pullAll(
        tags.filter(t => t.avatarType === "full").map(t => t.email),
        notification.emails.user_list.map(em => em[1])
      ).forEach(em => {
        const userCompany = this.props.companyUsers.find(
          u => u.users === tags.find(xy => xy.email === em).userId
        );
        if (userCompany) {
          this.props.createFacilityNotificationUser(
            this.props.facilityId,
            notification.id,
            userCompany.id,
            em
          );
        } else {
          this.props.createFacilityNotificationEmail(
            this.props.facilityId,
            notification.id,
            em
          );
        }
      });

      pullAll(
        notification.user_list,
        tags.filter(t => t.avatarType === "full").map(t => t.email)
      );
      pullAll(
        notification.emails.user_list.map(t => t[1]),
        tags.filter(t => t.avatarType === "full").map(t => t.email)
      ).forEach(em =>
        this.props.removeFacilityNotificationUser(
          this.props.facilityId,
          notification.id,
          notification.emails.user_list.find(xy => xy[1] === em)[0]
        )
      );
    } else if (ntf) {
      ntf.emails.email_list.forEach(em => this.getEmailTag(em[1]));
      ntf.emails.user_list.forEach(em => this.getEmailTag(em[1]));
    }
    this.setState({
      openInviteModal: !this.state.openInviteModal,
      notification: ntf,
      tags: []
    });
  };

  render() {
    const { notification = {} } = this.state;
    return (
      <React.Fragment>
        {this.props.notifications.map(notif => {
          return (
            <NotificationRow
              notification={notif}
              loadEmailList={id =>
                this.props.listFacilityNotificationEmailsAndUsers(
                  this.props.facilityId,
                  id
                )
              }
              key={notif.id}
              onChange={this.handleNotificationChange}
            />
          );
        })}
        <ConfirmDialog
          open={this.state.openInviteModal}
          title={`${notification.description} asignaciones`}
          message="Las personas que recibirán esta notificación son:"
          acceptText="Guardar"
          showCancel={true}
          contentObject={this.getUserList()}
          acceptAction={this.manageModal}
          closeAction={this.manageModal}
          className="facility-alert-users-dialog"
        />
      </React.Fragment>
    );
  }
  getUserList = () => {
    const { tags } = this.state;
    return (
      <React.Fragment>
        <InputTagger
          onlyLowerCase
          placeholder='Ingresa el o los correos separados por "enter"'
          tags={tags}
          onChange={this.handleEmailsChange}
        />
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => {
  const notifications = (
    state.notifications.companyNotifications.data || []
  ).map(cn => {
    const notification =
      state.notifications.facilityNotifications.data.find(
        fn => fn.notifications === cn.id
      ) || {};
    return {
      active: notification.status ? true : false,
      id: notification.id,
      notificationId: cn.id,
      emails: state.notifications.facilityNotificationEmails.data[
        notification.id
      ] || { user_list: [], email_list: [] },
      description: cn.description
    };
  });
  return {
    notifications,
    searchedUser: state.userReducer.searchedUser.data,
    emails: state.notifications.facilityNotificationEmails.data,
    companyUsers: state.companyReducer.companyUsers.data
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  dispatch(listCompanyNotifications());
  return {
    listFacilityNotifications: facilityId =>
      dispatch(listFacilityNotifications(facilityId)),
    setFacilityNotification: (
      facilityId,
      notificationId,
      facilityNotificationId,
      status
    ) =>
      dispatch(
        setFacilityNotification(
          facilityId,
          notificationId,
          facilityNotificationId,
          status
        )
      ),
    listFacilityNotificationEmailsAndUsers: (
      facilityId,
      facilityNotificationId
    ) =>
      dispatch(
        listFacilityNotificationEmailsAndUsers(
          facilityId,
          facilityNotificationId
        )
      ),
    getUserByEmail: email => dispatch(getUserByEmail(email, "", "")),
    createFacilityNotificationEmail: (
      facilityId,
      notificationFacilityId,
      email
    ) =>
      dispatch(
        createFacilityNotificationEmail(
          facilityId,
          notificationFacilityId,
          email
        )
      ),
    removeFacilityNotificationEmail: (
      facilityId,
      facilityNotificationId,
      emailId
    ) =>
      dispatch(
        removeFacilityNotificationEmail(
          facilityId,
          facilityNotificationId,
          emailId
        )
      ),
    createFacilityNotificationUser: (
      facilityId,
      notificationFacilityId,
      userCompanyId,
      email
    ) =>
      dispatch(
        createFacilityNotificationUser(
          facilityId,
          notificationFacilityId,
          userCompanyId,
          email
        )
      ),
    removeFacilityNotificationUser: (facilityId, notificationFacilityId, id) =>
      dispatch(
        removeFacilityNotificationUser(facilityId, notificationFacilityId, id)
      )
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(NotificationList);
