import React, { Component } from "react";
import PropTypes from "prop-types";

class Table extends Component {
  static propTypes = {
    headerContent: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        width: PropTypes.string
      })
    ),
    className: PropTypes.string,
    style: PropTypes.object
  };
  static defaultProps = {
    headerContent: [],
    className: "",
    style: {}
  };

  render() {
    let el = React.Children.toArray(this.props.children).map(e =>
      React.cloneElement(e, {
        headers: this.props.headerContent
      })
    );
    return (
      <div
        className={"table ".concat(this.props.className)}
        style={this.props.style}
      >
        <div className="header">
          {this.props.headerContent.map((hc, index) => (
            <div
              className="header-item"
              style={{ width: hc.width }}
              key={index}
            >
              {hc.text}
            </div>
          ))}
        </div>
        <div className="content">{el}</div>
      </div>
    );
  }
}

export default Table;
