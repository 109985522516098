import React from "react";
import Button from "../../../shared/controls/button";
import { getPriceFormatNumber } from "../../../shared/utils";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Loader from "../../../shared/icons/tat/loader";

class SubscriptionShoppingCarView extends React.Component {

  // Props que acepta el componente
  // {
  //   editable: boolean,
  //   itemsToBuy: [
  //     {
  //       id: Number,
  //       title: String,
  //       description: String,
  //       price: Number,
  //       quantity: Number,
  //     }
  //   ]
  // }

  buildTitle = () => {
    return(
      <TitleView>
        {'Carrito de compra'}
      </TitleView>
    );
  }

  buildListHeader = () => {
    let isEditable = this.props.editable;

    return(
      <HeaderContainerView>
        <ItemDescriptionContainerView
          isEditable={isEditable}
        />

        {
          isEditable ?
          this.buildQuantityHeader() : <></>
        }

        <QuantityItemContainer>
          {'IVA'}
        </QuantityItemContainer>

        <ItemDescriptionPricePlanView
          isEditable={isEditable}
        />

        <ItemDescriptionDeleteView
          isEditable={isEditable}
        />

      </HeaderContainerView>
    );
  }

  buildQuantityHeader = () => {
    return(
      <>
        <QuantityItemContainer>
          {'QTY'}
        </QuantityItemContainer>

        <QuantityItemContainer>
          {'P.U.'}
        </QuantityItemContainer>
      </>
    );
  }

  buildListOfItems = () => {

    let services = this.props.itemsToBuy || [];

    return(
      <ListOfItemsContainerView>
        {
          services.map((item, index) => {
            return(
              this.buildItem(item, index)
            );
          })
        }
      </ListOfItemsContainerView>
    );
  }

  deleteItem = (id) => {
    let items = (this.props.itemsToBuy || []).filter( item => item.id != id);

    if (this.props.deleteItem) {
      this.props.deleteItem(items);
    }
  }

  buildItem = (item, index) => {
    let isEditable = this.props.editable;
    let price_value = item.unit_price * item.quantity;
    let iva = (item.iva / 100) * price_value;

    return(
      <ItemContainerView
        key={`item-to-buy-${index}`}
      >

        <ItemDescriptionContainerView
          isEditable={isEditable}
        >
          <ItemDescriptionNameView>{`${item.title}`}</ItemDescriptionNameView>
          <ItemDescriptionPlanDescriptionView>{item.description}</ItemDescriptionPlanDescriptionView>
        </ItemDescriptionContainerView>

        {
          isEditable ?
          this.buildQuantityItem(item) : <></>
        }

        <QuantityItemContainer>
          {`$ ${getPriceFormatNumber(iva)}`}
        </QuantityItemContainer>

        <ItemDescriptionPricePlanView
          isEditable={isEditable}
        >
          {`$ ${getPriceFormatNumber(price_value)} USD`}
        </ItemDescriptionPricePlanView>
        
        <ItemDescriptionDeleteView
          isEditable={isEditable}
        >
          <img 
            alt='delete' 
            src='/images/delete.svg'
            style={{
              cursor: 'pointer'
            }}
            onClick={ () => { this.deleteItem(item.id) }}/>
        </ItemDescriptionDeleteView>

      </ItemContainerView>
    );
  }

  validEditable = (e, id) => {

    let newValue = e.target.value;

    if (parseInt(newValue) < 1000) {
      newValue = 1000;
    }

    let items = this.props.itemsToBuy || [];

    items.map( (item, index) => {
      if (item.id === id) {
        items[index].quantity = newValue;
      }
    })

    this.props.changeEditable(items);
  };

  buildQuantityItem = (item) => {
    return(
      <>
        <QuantityItemContainer>
          <QuantityItemInput
            name="editable"
            type="number"
            readOnly={!item.editable}
            value={item.quantity}
            onChange={(e) => {
              if (item.editable)
                this.validEditable(e, item.id);
            }}
            onBlur={(e) => {
              if (item.editable)
                this.validEditable(e, item.id);
            }}
          />
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`$${getPriceFormatNumber(item.unit_price)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>
      </>
    );
  }

  buildBuyInfo = () => {

    let services = this.props.itemsToBuy || [];
    let price_value = services.map(item => item.unit_price * item.quantity ).reduce((a, b) => a + b, 0);
    let iva_value = services.map(item => (item.iva / 100) * (item.unit_price * item.quantity) ).reduce((a, b) => a + b, 0);
    let iva = null;
    let unit_price = null;
    let quantity = null;

    services.map(item => {
      iva = item.iva;
      unit_price = item.unit_price;
      quantity = item.quantity;
    });

    return(
      <BuyInfoContainerView>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'SubTotal'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`$${getPriceFormatNumber(price_value)} USD`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        <DividerView/>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'Total'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`$${getPriceFormatNumber(price_value + iva_value)} USD`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        {
          this.props.content && this.props.content
        }

        <ButtonContainer>
          <ReactTooltip
            id={"tooltip-error-invoice_data"}
            place="top"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
          <Button
            text={this.props.isLoading ? <Loader/> : "Pagar"}
            type="primary"
            disabled={(this.props.itemsToBuy === null) || (this.props.itemsToBuy.length === 0) || this.props.isLoading ||(this.props.buyButtonDisabled) || (this.props.errors || '') !== ""}
            onClick={() => {
              if (this.props.buyAction) {
                this.props.buyAction({
                  iva_percentage: iva,
                  unit_price,
                  quantity
                });
              }
            }}
            dataTip={this.props.errors}
            dataFor="tooltip-error-invoice_data"
          />
        </ButtonContainer>

      </BuyInfoContainerView>
    );
  }

  render() {
    let isEditable = this.props.editable;

    return(
      <MainContainerView
        isEditable={isEditable}
      >
        { this.buildTitle() }
        { this.buildListHeader() }
        { this.buildListOfItems() }
        { this.buildBuyInfo() }
      </MainContainerView>
    );
  }

}

export default SubscriptionShoppingCarView;

////////// style

const MainContainerView: ComponentType<{ isEditable: boolean }>= styled.div`
  background-color: #1a202e;
  width: 550px;
  border-top-right-radius: 12px;
`;

const TitleView = styled.div`
  color: white;
  font-size: 20px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ListOfItemsContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  max-height: 350px;
  overflow-y: auto;
`;

const ItemContainerView = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ItemDescriptionContainerView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '24%' : '50%')};
  color: white;
`;

const ItemDescriptionNameView = styled.div`
  font-size: 15px;
  opacity: 65%;
`;

const ItemDescriptionPlanDescriptionView = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const ItemDescriptionPricePlanView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '16%' : '30%')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
`;

const ItemDescriptionDeleteView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '10%' : '20%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BuyInfoContainerView = styled.div`
  display: flex;
  flex-direction: column;
`;

const BuyInfoPriceContainerView = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
`;

const BuyInfoPriceTotalView = styled.div`
  font-size: 16px;
`;

const BuyInfoPriceView = styled.div`
  font-size: 18px;
`;

const QuantityItemContainer = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 59%;
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemTitle = styled.div`
  position: absolute;
  top: 0px;
  font-size: 16px;
  color: white;
  opacity: 59%;
`;

const QuantityItemPrice = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemInput = styled.input`
  background: #6a81ad;
  border: none;
  width: 70%;
  height: 30px;
  color: #ffffff;
  font-weight: bolder;
  text-align: center;
  font-size: 12px;
  border-bottom: 2px solid #35315a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const HeaderContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
`;

const DividerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
`;