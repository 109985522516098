//@flow
import React from "react";
import { connect } from "react-redux";
import type { RouterHistory } from "react-router-dom";

import { View, ViewTitle } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";
import { Flex } from "../../shared/styled/flex";

import {
  ApiOptions,
  readEntities,
  getEntityItems,
  getEntityArgs,
  createEntity,
} from "../../redux/api/";
import * as SharedTypes from "../../shared/types";
import * as Types from "../shipment/types";

// import ShipmentSummaryList from "../shipment/shipment-summary-list";
import FacilitySummaryList from "./facilities-summary-list";
import { partialEntity, readEntity } from "../../redux/api/actions";
import { isPremiumSubscriptionCo, normalizeStr } from "../../shared/utils";
import { listFacilities } from "../../redux/reducers/facility-reducer";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import {
  loadSession,
  loadPermissionsUser,
} from "../../redux/reducers/global-catalog.reducer";
import { DownloadIcon } from "../../company/routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import axios from "axios";
import { SERVER } from "../../redux/api/api-urls";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import styled from "styled-components";

type StateToProps = {|
  companyId: number,
  facilitiesByTag: Array<Types.FacilitiesByTag>,
  tagByFacilityArgs: { date: string },
  searchStr: string,
|};

type DispatchToProps = {||};

type OwnProps = {|
  history: RouterHistory,
|};

type Props = {
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
};
type State = {
  maneuver: Types.Maneuvers,
  viewMode: Types.ViewMode,
};

class FacilitiesSummaryView extends React.Component<Props, State> {
  state = {
    maneuver: "load,unload,loadunload",
    viewMode: "list",
    searchStr: "",
    buttonFilterName: "Aplicar",
    filterParams: {
      facilities: [],
      tags: [],
    },
    isFreeSubscription: false
  };

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    this.props.loadCompanyTags(this.props.companyId);
    this.loadFacilities(new Date().toISOString().split("T")[0]);

    this.props.setNavbarAction("resumen-capacity", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("resumen-capacity");
  }

  loadFacilities = (selectedDateId: string, maneuver = this.state.maneuver) => {
    const { companyId, loadFacilitiesSummary } = this.props;
    loadFacilitiesSummary(
      companyId,
      maneuver,
      selectedDateId,
      this.state.filterParams,
      {
        args: { date: selectedDateId },
      }
    );
  };

  loadFacilityLimits = (facility_id, date) => {
    this.props.loadFacilityLimits(this.props.companyId, facility_id, date);
  };

  showError = (error) => {
    console.log("Algo salio mal", error);
  };

  postFacilityLimit = (facility_id, date, tag_id, limit) => {
    let dateParam =
      this.props.tagByFacilityArgs.date ||
      new Date().toISOString().split("T")[0];
    //status=3 permiso 41u
    let status = {};
    if (this.props.permission["41"] && this.props.permission["41"].u) {
      status.status = 3;
    }
    this.props.postFacilityLimit(
      this.props.companyId,
      facility_id,
      date,
      tag_id,
      limit,
      status,
      {
        onSuccess: () => this.loadFacilities(dateParam),
        onError: this.showError,
        strategy: "IGNORE",
      }
    );
  };

  updateFacilityLimit = (facility_id, limit_id, limit) => {
    let dateParam =
      this.props.tagByFacilityArgs.date ||
      new Date().toISOString().split("T")[0];
    //status=3 permiso 41u
    let status = {};
    if (this.props.permission["41"] && this.props.permission["41"].u) {
      status.status = 3;
    }
    this.props.updateFacilityLimit(
      this.props.companyId,
      facility_id,
      limit_id,
      limit,
      status,
      {
        onSuccess: () => this.loadFacilities(dateParam),
        onError: this.showError,
        strategy: "IGNORE",
      }
    );
  };

  validateFacilityLimit = (facility_id, limit_id, valid) => {
    let dateParam =
      this.props.tagByFacilityArgs.date ||
      new Date().toISOString().split("T")[0];
    this.props.validateFacilityLimit(
      this.props.companyId,
      facility_id,
      limit_id,
      valid,
      {
        onSuccess: () => this.loadFacilities(dateParam),
        onError: this.showError,
      }
    );
  };

  applyFacilityLimit = (facility_id, limit_id, application) => {
    let dateParam =
      this.props.tagByFacilityArgs.date ||
      new Date().toISOString().split("T")[0];
    this.props.applyFacilityLimit(
      this.props.companyId,
      facility_id,
      limit_id,
      application,
      {
        onSuccess: () => this.loadFacilities(dateParam),
        onError: this.showError,
      }
    );
  };

  handleChange = (e: SharedTypes.SimpleEvent) => {
    const { name, value } = e.target;
    const { tagByFacilityArgs } = this.props;
    switch (name) {
      case "maneuver":
        this.setState({ [name]: value });
        this.loadFacilities(tagByFacilityArgs.date, value);
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  manageFilter = (e: SharedTypes.SimpleEvent) => {
    let filterParams = { ...this.state.filterParams };

    switch (e.target.name) {
      case "tags":
        filterParams.tags = e.target.value;
        break;
      case "facilities":
        filterParams.facilities = e.target.value;
        break;
      default:
    }
    this.setState({ filterParams, buttonFilterName: "Aplicar" });
  };

  getFilterContent = () => {
    return [
      {
        title: "Etiquetas",
        items: this.props.companyTags.filter((f) => f.type === 7),
        name: "tags",
        listType: 1,
      },
      {
        title: "Instalaciones",
        items: this.props.facilitiesGrouped,
        // items: this.props.facilities,
        name: "facilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
    ];
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = () => {
    const { companyId } = this.props;
    const { maneuver, filterParams } = this.state;

    let dateParam =
      this.props.tagByFacilityArgs.date ||
      new Date().toISOString().split("T")[0];

    let facilities = (filterParams.facilities || []).join(",");
    let tags = (filterParams.tags || []).join(",");

    return `${SERVER}/api/v2/companies/${companyId}/facility_control/?view=${maneuver}&date=${dateParam}&facilities=${facilities}&tags=${tags}&action=download`;
  };

  openDocument = (url) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Limits.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent = () => {
    const { maneuver, viewMode } = this.state;
    const { facilitiesByTag } = this.props;
    return (
      <View className="facilities-control-view">
        <ViewTitle>
          <span>Resumen de capacidad</span>
          <DownloadIcon data-tip data-for={"download-icon"}>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              onClick={() => this.openDocument(this.getUrl())}
            />
          </DownloadIcon>
          <FilterBarCompact
            searchStr={this.state.searchStr}
            searchAction={this.handleChange}
            content={this.getFilterContent()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState({ buttonFilterName: "Restablecer" }, () => {
                  let dateParam =
                    this.props.tagByFacilityArgs.date ||
                    new Date().toISOString().split("T")[0];
                  this.loadFacilities(dateParam)
                });
              } else {
                this.setState({
                  filterParams: {
                    facilities: [],
                    tags: [],
                  },
                  buttonFilterName: "Aplicar"
                }, () => {
                  let dateParam =
                    this.props.tagByFacilityArgs.date ||
                    new Date().toISOString().split("T")[0];
                  this.loadFacilities(dateParam)
                });
              }
            }}
            selectedItems={{
              tags: this.state.filterParams.tags,
              facilities: this.state.filterParams.facilities,
            }}
            buttonFilterName={this.state.buttonFilterName}
            onChange={this.manageFilter}
          />
        </ViewTitle>
        <Flex>
          <div
            style={{
              width: "555px",
              marginLeft: "50%",
              transform: "translate(-50%)",
            }}
          >
            <OptionSelectorSwitch
              value={maneuver}
              name="maneuver"
              onChange={this.handleChange}
              items={[
                { description: "Carga", value: "load" },
                { description: "Descarga", value: "unload" },
                { description: "Carga y descarga", value: "loadunload" },
                { description: "Todo", value: "load,unload,loadunload" },
              ]}
            />
          </div>
          {/* <div style={{ width: "150px", marginLeft: "auto", marginRight: "0" }}>
            <OptionSelectorSwitch
              value={viewMode}
              name="viewMode"
              onChange={this.handleChange}
              items={[
                { description: "Lista", value: "list" },
                { description: "Heatmap", value: "heatmap" },
              ]}
            />
          </div> */}
        </Flex>
        {viewMode === "list" ? (
          <FacilitySummaryList
            history={this.props.history}
            facilitiesByTag={facilitiesByTag}
            tagsByFacility={this.props.tagsByFacility.filter((f) => {
              return (
                normalizeStr(f.title).includes(
                  normalizeStr(this.state.searchStr)
                ) ||
                f.data.filter((t) =>
                  normalizeStr(t.title).includes(
                    normalizeStr(this.state.searchStr)
                  )
                ).length
              );
            })}
            loadFacilities={this.loadFacilities}
            loadFacilityLimits={this.loadFacilityLimits}
            postFacilityLimit={this.postFacilityLimit}
            updateFacilityLimit={this.updateFacilityLimit}
            validateFacilityLimit={this.validateFacilityLimit}
            applyFacilityLimit={this.applyFacilityLimit}
            facilityLimits={this.props.facilityLimits}
          />
        ) : (
          <></>
        )}
      </View>
    );
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}

const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;

  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  let facilitiesGrouped = [
    { id: 9999999999, description: "Sin etiqueta", items: [] },
  ];
  (state.facilityReducer.facilities.data || []).forEach((f) => {
    if (f.tags.length) {
      f.tags.forEach((t) => {
        let tagIndex = facilitiesGrouped.map((fT) => fT.id).indexOf(t.id);
        if (tagIndex !== -1) {
          facilitiesGrouped[tagIndex].items.push({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
          });
        } else {
          let newTag = {
            id: t.id,
            description: t.title,
            items: [
              {
                id: f.id,
                description: f.name.concat(" - ", f.code, " - ", f.alias),
              },
            ],
          };
          facilitiesGrouped.push(newTag);
        }
      });
    } else {
      facilitiesGrouped[0].items.push({
        id: f.id,
        description: f.name.concat(" - ", f.code, " - ", f.alias),
      });
    }
  });
  facilitiesGrouped.push(facilitiesGrouped.shift());

  const tagByFacilityArgs = getEntityArgs(
    state,
    "COMPANIES.FACILITIES.CONTROL.SUMMARY"
  );

  const tagsByFacility = (
    getEntityItems(state, "COMPANIES.FACILITIES.CONTROL.SUMMARY").facility || []
  ).map((facility) => ({
    ...facility,
    title: facility.name,
  })).sort((a,b) => {
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  const facilityLimits = getEntityItems(
    state,
    "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY"
  );
  // console.log(state);
  return {
    permission: state.globalCatalog.permissionUser.data || {},
    companyId,
    tagByFacilityArgs,
    tagsByFacility,
    facilityLimits,
    companyTags,
    facilitiesGrouped,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadPermissionsUser());
  dispatch(listFacilities());
  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadFacilitiesSummary: (
      company_id: number,
      view: Types.Maneuvers,
      date: string,
      params = {},
      opts: ApiOptions
    ) =>
      dispatch(
        readEntities(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY",
          { company_id, view, date, ...params },
          opts
        )
      ),

    loadFacilityLimits: (
      company_id: number,
      facility_id: number,
      date: string,
      opts: ApiOptions
    ) =>
      dispatch(
        readEntities(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY",
          { company_id, facility_id, date },
          opts
        )
      ),
    postFacilityLimit: (
      company_id: number,
      facility_id: number,
      date: string,
      tag_id,
      limit,
      status,
      opts: ApiOptions
    ) =>
      dispatch(
        createEntity(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY.LIMITS",
          {
            company_id,
            facility_id,
            calendar_date: date,
            tags: tag_id,
            maximum_attention: limit,
            ...status,
          },
          opts
        )
      ),
    updateFacilityLimit: (
      company_id: number,
      facility_id: number,
      limit_id,
      limit,
      status,
      opts: ApiOptions
    ) =>
      dispatch(
        partialEntity(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY.LIMITS",
          {
            company_id,
            facility_id,
            limit_id,
            maximum_attention: limit,
            ...status,
          },
          opts
        )
      ),
    validateFacilityLimit: (
      company_id: number,
      facility_id: number,
      limit_id,
      validation,
      opts: ApiOptions
    ) =>
      dispatch(
        readEntity(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY.LIMITS.VALIDATION",
          { company_id, facility_id, limit_id, validation },
          opts
        )
      ),
    applyFacilityLimit: (
      company_id: number,
      facility_id: number,
      limit_id,
      application,
      opts: ApiOptions
    ) =>
      dispatch(
        readEntity(
          "COMPANIES.FACILITIES.CONTROL.SUMMARY.CAPACITY.LIMITS.APPLICATION",
          { company_id, facility_id, limit_id, application },
          opts
        )
      ),
  };
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(FacilitiesSummaryView);

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;