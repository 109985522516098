import React from "react";
const Weight = (props) => {
  return (
    <svg
      viewBox="0 0 15 15"
      width="20px"
      height="20px"
      fill="currentColor"
      {...props}
    >
      <path d="M15.0147918,14.419543 L11.9417699,5.06495963 C11.8821374,4.89813046 11.7435105,4.79395963 11.5653941,4.79395963 L9.18628045,4.79395963 C9.76091571,4.29429296 10.1380845,3.50245963 10.1179414,2.64791796 C10.0784441,1.23108463 8.98802097,0.0650012976 7.63969794,0.00229296426 C6.17289504,-0.0603695357 4.96319502,1.16929296 4.96319502,2.7106263 C4.96319502,3.54395963 5.32022069,4.29429296 5.89485595,4.79395963 L3.49551978,4.79395963 C3.33675756,4.79395963 3.17798741,4.8981263 3.119144,5.06495963 L0.0258996701,14.419543 C-0.0732300673,14.690543 0.125029408,14.9818763 0.402275457,14.9818763 L14.637504,14.9818763 C14.9155233,14.960718 15.1137827,14.690543 15.014653,14.419543 L15.0147918,14.419543 Z M7.52038538,1.56558463 C8.11516381,1.56558463 8.6108125,2.0652513 8.6108125,2.71141796 C8.6108125,3.35677213 8.11516381,3.83608463 7.52038538,3.83608463 C6.92560696,3.83608463 6.42995827,3.33641796 6.42995827,2.6902513 C6.42995827,2.04408463 6.92560696,1.56558463 7.52038538,1.56558463 Z"></path>
    </svg>
  );
};
export default Weight;
