import React from "react";
const Unitary = (props) => {
  return (
    <svg
      viewBox="0 0 23 15"
      width="23px"
      height="20px"
      fill="#AFC8FF"
      {...props}
    >
      <g>
        <path d="M18.046875,12.8571429 C18.046875,13.9662429 17.18079,14.8660714 16.1132812,14.8660714 C15.0457725,14.8660714 14.1796875,13.9662429 14.1796875,12.8571429 C14.1796875,11.7480429 15.0457725,10.8482143 16.1132812,10.8482143 C17.18079,10.8482143 18.046875,11.7480429 18.046875,12.8571429" />
        <path d="M14.8242188,4.15178571 L14.8242188,1.47321429 C14.8242188,0.733264286 14.2465538,0.133928571 13.5351562,0.133928571 L1.2890625,0.133928571 C0.577665,0.133928571 0,0.733264286 0,1.47321429 L0,8.83928571 C0,9.5784 0.577665,10.1785714 1.2890625,10.1785714 L2.578125,10.1785714 L2.578125,10.1953114 C3.1179225,9.77008286 3.78340875,9.508935 4.51171875,9.508935 C6.28821188,9.508935 7.734375,11.0114421 7.734375,12.8571493 L12.890625,12.8571493 C12.890625,11.4877329 13.688235,10.31079 14.8242188,9.79264929 L14.8242188,4.15178571 Z" />
        <path d="M19.9128187,5.34964286 C19.594575,4.68754286 18.7590975,4.15182857 18.0468956,4.15182857 L15.4687706,4.15182857 L15.4687706,9.57668571 C15.6774338,9.53232214 15.8933569,9.50888357 16.1133019,9.50888357 C17.889795,9.50888357 19.3359581,11.0113907 19.3359581,12.8570979 C20.0473556,12.8570979 20.6250206,12.2569264 20.6250206,11.5178121 L20.6250206,6.83031214 L19.9128187,5.34964286 Z M19.708986,7.5 L16.7577548,7.5 L16.7577548,4.82142857 L18.0468173,4.82142857 C18.4029285,4.82142857 18.8202548,5.08928571 18.9797685,5.42076429 L19.7089035,6.93499286 L19.7080971,7.5 L19.708986,7.5 Z" />
        <path d="M4.51171875,14.8660714 C3.44421,14.8660714 2.578125,13.9662429 2.578125,12.8571429 C2.578125,11.7480429 3.44421,10.8482143 4.51171875,10.8482143 C5.5792275,10.8482143 6.4453125,11.7480429 6.4453125,12.8571429 C6.4453125,13.9662429 5.5792275,14.8660714 4.51171875,14.8660714 Z" />
      </g>
    </svg>
  );
};

export default Unitary;
