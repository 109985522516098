// @flow

import React from "react";
import styled from "styled-components";
import SVGIcon from "../../shared/svg-icon";
import { icons } from "../../shared/svg-icon";
import { AssembleGridContainer } from "orbi_v2_frontend_components";
import MultiDataGridRow from "orbi_v2_frontend_components/lib/MultiDataGridRow";
import ExceedPlus from "../../shared/controls/exceed-plus";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { AssembleAvatar } from "../assemble/assemble-avatar";
import Button from "../../shared/controls/button";
import HeaderSelect from "../assemble/header-select-styled";

import * as Icons from "../../shared/icons/assemble";
import Checkbox from "../../shared/controls/checkbox";
import ContractDetailHeaderTitle from "./contract-detail-header-title";

export const ResourcesGrid = ({
  // counters,
  currentContract,
  gridConfig,
  summary = () => {},
  rows = [],
  dateChange,
  gridConfigChange,
  showSelectAll,
  enabledSelectAll,
  companyType,
}) => {
  let cont = 0;
  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <AssembleGridContainer
        headerTitle={
          <AssembleGridHeader
            {...{
              dateChange,
              gridConfigChange,
              showSelectAll,
              enabledSelectAll,
              currentContract,
            }}
          />
        }
        {...gridConfig}
        onDivisionSummary={summary}
      />
      <div
        style={{minHeight: 70, maxHeight: "calc(100% - 125px)", overflow: "auto"}}
      >
      {rows.map((fa) => (
        <MultiDataGridRow
          key={fa.id}
          row={fa}
          header={{
            width: "20%",
            caption: (
              <Flex
                // alignItems={flexOpts.alignItems.center}
                justifyContent={flexOpts.justifyContent.spaceAround}
                style={{
                  color: "#FFFFFF",
                  fontSize: "12px",
                  width: "100%",
                  // margin: "0 10px 0 0"
                }}
              >
                {enabledSelectAll &&
                  fa.resources.filter((r) =>
                    companyType === 2 ? true : !r.empty
                  ).length > 0 && (
                    <Checkbox
                      onChange={() =>
                        dateChange({
                          target: {
                            name: "gridSelectedItems",
                            value: { id: fa.id, checked: !fa.selected },
                          },
                        })
                      }
                      item={{
                        description: "",
                        active: fa.selected,
                        id: fa.id,
                      }}
                      // name: PropTypes.string.isRequired,
                      // active: PropTypes.bool,
                    />
                  )}
                {getAssembleIcon(fa.resources)}
                <ExceedPlus
                  name={fa.id}
                  className="hide-first-icon resource-control-header"
                  tooltipOpts={{ delayHide: 20, clickable: false }}
                  face={
                    fa.resources[0]?.empty
                      ? undefined
                      : <div className="resource-id">{fa.resources[0]?.shortDescription}</div>
                  }
                  // additionalText="más"
                >
                  {fa.resources.map((resource) =>
                    resource.empty ? (
                      resource.longDescription ? (
                        <div className="slot-action-header" key={cont++}>
                          <Button
                            text={resource.longDescription}
                            type="primary"
                            onClick={resource.action}
                          />
                        </div>
                      ) : (
                        <></>
                      )
                    ) : (
                      <div>
                        <AssembleAvatar
                          key={resource.id}
                          description={resource.longDescription}
                          fleetTypeId={resource.type}
                          avatarUrl={resource.profile_image}
                        />
                      </div>
                    )
                  )}
                </ExceedPlus>
              </Flex>
            ),
            actions: fa.header_actions,
          }}
        />
      ))}
      </div>
    </div>
  );
};

const DateControl = styled.div`
  transform: rotate(
    ${({ position }) => (position === "prev" ? "270deg" : "90deg")}
  );
  cursor: pointer;
  margin: 0 20px;
  & .svg {
    fill: "#AFC8FF";
  }
`;

const AssembleGridHeader = ({
  dateChange,
  gridConfigChange,
  enabledSelectAll,
  showSelectAll,
  currentContract,
}) => (
  <AssembleGridHeaderStyled>
    {/* <AssembleGridHeaderTitle>Flotilla</AssembleGridHeaderTitle> */}
    <AssembleGridHeaderTitle>
      <div style={{ color: "#131823" }}>.</div>
      <HeaderSelectContracts
        isSearchable={false}
        defaultValue={GRID_OPTIONS[3]}
        options={GRID_OPTIONS}
        // menuIsOpen={true}
        maxMenuHeight={120}
        onChange={(a) =>
          gridConfigChange({
            target: { name: "gridConfigSelect", value: a.value },
          })
        }
        name="gridConfigSelect"
      />
    </AssembleGridHeaderTitle>
      {showSelectAll && (
        <SelectAllSwitch
          enabledSelectAll={enabledSelectAll}
          onChange={dateChange}
        />
      )}
    <AssembleGridHeaderContent>
      <ContractDetailHeaderTitle contract={currentContract} />
      <DateControl position="prev">
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          onClick={() =>
            dateChange({ target: { name: "gridDateChange", value: -1 } })
          }
        />
      </DateControl>
      {`Fecha`}
      <DateControl position="next">
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          onClick={() =>
            dateChange({ target: { name: "gridDateChange", value: 1 } })
          }
        />
      </DateControl>
    </AssembleGridHeaderContent>
  </AssembleGridHeaderStyled>
);

const AssembleGridHeaderStyled = styled.div`
  margin: 20px 0 15px 0;
  width: 100%;
  display: flex;
  border-radius: 12px 12px 0 0;
  position: relative;
`;

const AssembleGridHeaderTitle = styled.div`
  color: #ffffff;
  width: 20%;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 37px;
  align-self: flex-start;
`;

const AssembleGridHeaderContent = styled.div`
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin: auto;
  width: 80%;
`;

const GRID_OPTIONS = [
  { value: "1D", label: "1 Día" },
  { value: "4D", label: "4 Días" },
  { value: "8D", label: "8 Días" },
  { value: "4W", label: "1 Mes" },
  { value: "6M", label: "6 Meses" },
];

const getAssembleIcon = (resources) => {
  const operators = resources.filter((o) => o.type === 3);
  const vehicles = resources.filter((o) => o.type === 1);
  const equipments = resources.filter((o) => o.type === 2);

  let operatorIcon = null;
  if (operators.length === 0) operatorIcon = null;
  else if (operators.length === 1) operatorIcon = <Icons.Operator />;
  else operatorIcon = <Icons.DoubleOperator />;

  let vehicleIcon = null;
  if (vehicles.length > 0) {
    // vehicleIcon = vehicles[0].icon();
    vehicleIcon = <Icons.Unitary />;
    if (equipments.length === 1) vehicleIcon = <Icons.TruckEquipment />;
    else if (equipments.length > 1)
      vehicleIcon = <Icons.TruckDoubleEquipment />;
  } else {
    if (equipments.length === 1) vehicleIcon = <Icons.Equipment />;
    else if (equipments.length > 1) vehicleIcon = <Icons.DoubleEquipment />;
  }

  return (
   // <div
    //   style={{
    //     width: "100px",
    //     height: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //   }}
    // >
      // <div
      //   style={{
      //     width: "30px",
      //     height: "100%",
      //     // display: "flex",
      //     // alignItems: "center",
      //     // justifyContent: "flex-end",
      //     // margin: "0 6px",
      //   }}
      // ></div>
      // <div
      //   style={{
      //     width: "20px",
      //     height: "100%",
      //     display: "flex",
      //     alignItems: "center",
      //     justifyContent: "center",
      //     // margin: "0 6px",
      //   }}
      // >
      //   {" "}
      //   {operatorIcon}
      // </div>
      <div
        style={{
          width: "50px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // margin: "0 6px",
        }}
      >
        {operatorIcon}
        {vehicleIcon}
      </div>
    // </div>
  );
};

const SelectAllSwitch = ({ enabledSelectAll, onChange }) => {
  return (
    <SelectAllSwitchContainer>
      <div style={{ position: "absolute", width: "100%", height: "0" }}>
        <SelectAllSwitchStyled
          onClick={() =>
            onChange({
              target: { name: "enabledSelectAll", value: !enabledSelectAll },
            })
          }
        >
          {enabledSelectAll ? "Salir modo selección" : "Seleccionar varios"}
        </SelectAllSwitchStyled>
      </div>
    </SelectAllSwitchContainer>
  );
};

const SelectAllSwitchContainer = styled.div`
  // position: relative;
  // height: 1px;

  position: absolute;
  width: 20%;
  height: 1px;
  bottom: -15px;
`;
const SelectAllSwitchStyled = styled.div`
  position: relative;
  // top: 40px;
  padding: 0 5px;
  // left: -32px;
  font-size: 12px;
  // background-color: #32415e;
  cursor: pointer;
  // text-align: right;
  padding-right: 16px;
  // line-height: 37px;
  line-height: 30px;
  top: 17px;
  // padding: 8px 5px 0 5px;
  text-align: left;
`;

const HeaderSelectContracts = styled(HeaderSelect)`
  top: -10px !important;
  left: 20px;
`;
