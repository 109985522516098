//@flow
import * as React from "react";
import RowD from "../../../shared/component/row-d";
import RadioInput from "../../../shared/controls/radio-input";
import styled from "styled-components";
// import ReactTooltip from "react-tooltip";
// import Tag from "../../../shared/controls/tag";
import { FILES_SERVER } from "../../../redux/api";

const ContractAvailabilityRow = (props) => {
  return (
    <React.Fragment>
      <RowD {...props}>
        <RadioInput
          title=""
          onClick={props.onChange}
          selected={props.selectedProvider}
          value={props.id}
          name="selectedProvider"
        />
        <React.Fragment>
          <Logo src={FILES_SERVER.concat(props.fleet_provider.logo)} alt="" />
          <Text>{props.fleet_provider.alias}</Text>
        </React.Fragment>

        <Text>{props.contract_classes.map((cc) => cc.code).join(",")}</Text>
        <Text>{props.slots}</Text>
        <Text>{props.available}</Text>
        <Text>{props.in_transit}</Text>
        <Text>{props.out_of_service}</Text>
      </RowD>
    </React.Fragment>
  );
};

export default ContractAvailabilityRow;

const Logo = styled.img`
  width: 27px;
  height: 27px;
  margin-right: 10px;
  border-radius: 50%;
`;

const Text = styled.span`
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
`;
