import * as React from "react";
import { getUrlsEnv } from "../../shared/backend-api";
import SVGIcon, { icons } from "../../shared/svg-icon";

class ProductCard extends React.Component {
  state = {

  }

  static defaultProps = {
    productInfo: {},
    handleChange: () => {}
  }

  handleChange = (e) => {
    if (!RegExp(/^[0-9]*$/).test(e.target.value)) {
      return false;
    }
    this.props.handleChange({target: {
      name: "updateProduct",
      value: {id: this.props.productInfo.id, qty: e.target.value}
    }})
  }

  render() {
    return (
      <div className="product-card">
        <div className="product-image">
          <img 
            alt="" 
            src={this.props.productInfo.url ? getUrlsEnv().files.concat(this.props.productInfo.url) : "/images/interrogacion.svg"}
            className={this.props.productInfo.url ? "" : "default"}
          />
          <div>
            sku: {this.props.productInfo.sku}
          </div>
        </div>
        <div className="top-row">
          <div className="added" style={{opacity: this.props.qty ? "1" : "0"}}>
            <SVGIcon name={icons.checkMark} fill="#40D099" /><br/>
            Agregado
          </div>
          <div className="product-weigth">
            Unidades: <br/>
            {"1 "} {(this.props.unitCatalog.find(u => u.id === this.props.productInfo.product_unit) || {code: ""}).code}
          </div>
        </div>
        <div className="product-name">
          {this.props.productInfo.description}
        </div>
        <div className="product-weigth">
          Peso Neto: {this.props.productInfo.gross_weight} {" "} {(this.props.unitCatalog.find(u => u.id === this.props.productInfo.net_weight_unit) || {code: ""}).code}
        </div>
        <div className="product-weigth">
          Peso Total: {this.props.productInfo.net_weight} {" "} {(this.props.unitCatalog.find(u => u.id === this.props.productInfo.gross_weight_unit) || {code: ""}).code}
        </div>
        <div className="product-weigth">
          Cantidad: <input type="text" value={this.props.qty} onChange={this.handleChange} />
        </div>
      </div>
    )
  }
}

export default ProductCard;