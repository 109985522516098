import * as React from "react";
import * as Types from "./types";
import { FILES_SERVER } from "../../redux/api";
import Tag from "../../shared/controls/tag";
import { TableDConfig } from "../../shared/component/table-d";
import License from "../../shared/icons/assemble/license";
import * as Icons from "../../shared/icons/assemble";
import BlockedLicense from "../../shared/icons/assemble/blocked-license";
import * as moment from "moment";
import { isArray } from "lodash";

export const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
export const hour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours()
);

export const dateAddDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const dateAddHours = (date, hours) => {
  date.setHours(date.getHours() + hours);
  return date;
};


export const dateRemoveHours = (date, hours) => {
  date.setHours(date.getHours() - hours);
  return date;
};

export const defaultGridConfig: Types.GridConfig = {
  columnQuantity: 48,
  columnRangeType: "HOUR",
  columnSections: 4,
  // startDatetime: hour,
  startDatetime: dateRemoveHours(
    new Date(hour),
    12
  ),
  className: "",
  extended: true,
  scrollable: true,
};

export const DefaultFilters: Types.loadAssembliesParams = {
  limit: 10,
  offset: 1,
  view: "shortdetail",
  operator_status: [],
  operator_license_type: [],
  equipment_status: [],
  equipment_class: [],
  equipment_type: [],
  vehicle_status: [],
  vehicle_class: [],
  vehicle_type: [],
  vehicle_tags: [],
  equipment_tags: [],
  operator_tags: [],
  // start_date: hour.toISOString(),
  start_date: dateRemoveHours(
    new Date(hour),
    12
  ).toISOString(),
  // end_date: dateAddHours(
  //   new Date(hour),
  //   defaultGridConfig.columnQuantity
  // ).toISOString(),
  end_date: dateAddHours(
    new Date(hour),
    // defaultGridConfig.columnQuantity * 3
    36
  ).toISOString(),
  status: [],
  orderStatus: [],
  baseTags: [],
  from_to_companies: [],
  from_facilities: [],
  to_facilities: [],
  from_to_facilities: [],
  search: "",
  vehicle: [],
  equipment: []
};

export const mapTrips = (t): Types.AssembleShipmentListRowType => ({
  id: t.id,
  has_automatic_start: t.has_automatic_start,
  isDonor: t.is_donor,
  cfdi: t.cfdi,
  companyLogo: FILES_SERVER.concat(t.cargo_owner.logo),
  companyName: t.cargo_owner.corporation_name,
  tripNumber: t.signature,
  tickets: t.trip_orders.length,
  class: <Tag title={t.transport_class?.code} />,
  status: t.status,
  actions: [
    // { type: "primary", description: "Aceptar", action: () => {} },
    // { type: "secondary", description: "Rechazar", action: () => {} },
  ],
  assemble:  t.assembly,
  is_unhooked: t.trip_orders.map(o => (o.order_containers || [])).flat().some(c => c.status === 9),
  is_company_verified: t.cargo_owner.verification_status === 3,
  details: t.trip_orders.map((o: Types.AssembleShipmentListRowDetailType) => ({
    ticket: o.code,
    // sortField: Date.parse(o.appointment_loading_date) || 0,
    orderContainers: o.order_containers,
    container_status: (o.order_containers || []).map( item => ({
      container: item.code || '',
      status: item.status_description || ''
    })),
    has_contabilizacion_salida: o.has_contabilizacion_salida,
    is_unhooked: (o.order_containers || []).some(c => c.status === 9),
    id: o.id,
    origin: {
      logo: FILES_SERVER.concat(o.from_company.logo),
      is_company_verified: o.from_company.verification_status === 3,
      name: o.from_facility.name,
      date: o.appointment_loading_date
        ? new Date(o.appointment_loading_date)
        : "",
      // date: new Date(o.appointment_loading_date).toLocaleString("es-US"),
    },
    cfdi: o.cfdi,
    destination: {
      logo: FILES_SERVER.concat(o.to_company.logo),
      is_company_verified: o.to_company.verification_status === 3,
      name: o.to_facility.name,
      date: o.appointment_unload_date
        ? new Date(o.appointment_unload_date)
        : "",
      // date: new Date(o.appointment_unload_date).toLocaleString("es-US"),
    },
    type: t.transport_type?.description,
    level: o.priority,
    weight: o.net_weight,
    vol: o.volume,
    quantity: o.gross_weight,
    statusId: o.status?.id,
    statusDescription: o.status?.description,    
    tags: o.order_tags,
    base: t.base || null,
    assemble: o.assembly,
    eta_loading: o.eta_loading_date || '',
    eta_unload: o.eta_unload_date || '',
    ata_loading: o.ata_loading_date || '',
    ata_unload: o.ata_unload_date || ''
  })),
  // .sort((a, b) => a.sortField - b.sortField),
});

const isAllowedToRestart = (details) => {

  var isAllowed = true;

  details.map( (detail) => {
    if (detail.cfdi && isAllowed) {
      let cfdiStatus = detail?.cfdi?.status ?? 3;
      isAllowed = `${cfdiStatus}` !== '3';
    }
  });

  return isAllowed;

}

export const mapTripActions = (
  trip: Types.AssembleShipmentListRowType,
  actionMethods: {
    accept: () => void,
    reject: () => void,
    assign: () => void,
    reset: () => void,
    start: () => void,
    cancel: () => void,
    cancel: () => void,
    finalizeTrip: () => void,
    finalizeOrder: () => void,
    transferEvents: () => void,
    redirect: () => void,
    downloadFile: () => void,
    cancelCFDI: () => void
  }
): Types.AssembleShipmentListRowType => {
  trip.actions = [];

  let details = trip?.details || [];
  var isValidDate = true;
  if (isArray(details) && details.length > 0) {

    let origin = details[0]?.origin?.date;

    if (origin !== null && origin !== undefined && moment(origin).isBefore(moment(new Date()))) {
      isValidDate = false;
    }

  }

  if (trip.status.id === 1) {

    let has_finalized_order = false;
    let has_contabilizacion_salida = false;

    (trip?.details ?? []).map( folio => {
      if (folio.statusId === 11) {
        has_finalized_order = true;
      }
      if (folio.has_contabilizacion_salida) {
        has_contabilizacion_salida = true;
      }
    })

    let message = null;

    if (has_finalized_order) {
      message = "No se permite aceptar si alguna orden del viaje está finalizado sin datos,<br/> debe eliminar dicho estatus para permitirle asignar el viaje."
    }

    if (has_contabilizacion_salida) {
      message = message !== null ? `- ${message}<br/>- No se puede aceptar el viaje por que ya se contabilizó una de sus ordenes.` : 'No se puede aceptar el viaje por que ya se contabilizó una de sus ordenes.'
    }

    trip.actions = [
      {
        type: "primary",
        className: "primary",
        description: "Aceptar",
        action: () => actionMethods.accept(trip),
        hover: message,
        disable: has_finalized_order || has_contabilizacion_salida
      },
      // {
      //   type: "secondary",
      //   className: "outline primary",
      //   description: "Rechazar",
      //   action: () => actionMethods.reject(trip.id),
      // },
    ];
  } else if (trip.status.id === 3) {

    let has_finalized_order = false;
    let has_contabilizacion_salida = false;

    (trip?.details ?? []).map( folio => {
      if (folio.statusId === 11) {
        has_finalized_order = true;
      }
      if (folio.has_contabilizacion_salida) {
        has_contabilizacion_salida = true;
      }
    })

    let message = null;

    if (has_finalized_order) {
      message = "No se permite asignar si alguna orden del viaje está finalizado sin datos,<br/> debe eliminar dicho estatus para permitirle asignar el viaje."
    }

    if (has_contabilizacion_salida) {
      message = message !== null ? `- ${message}<br/>- No se puede asignar el viaje por que ya se contabilizó una de sus ordenes.` : 'No se puede asignar el viaje por que ya se contabilizó una de sus ordenes.'
    }

    trip.actions = [
      {
        type: "primary",
        className: "secondary",
        description: "Asignar",
        isValidDate,
        action: () => actionMethods.assign(trip),
        hover: message,
        disable: has_finalized_order || has_contabilizacion_salida
      },
      // {
      //   type: "secondary",
      //   className: "outline primary",
      //   description: "Cancelar",
      //   action: () => actionMethods.cancel(trip.id),
      // },
    ];

  } else if (trip.status.id === 4){//4-Reservcacion confirmada
    //Si la cita de carga del folio a (La mas temprana) ya pasó, no permitir iniciar viaje
    let appointments = trip.details.map(d => (d.origin?.date || null)).filter(d => d);
    if(appointments.length){
      let appointmentA = Math.min(...appointments.map(a => Date.parse(a)));
      let now = (new Date()).getTime();
      if(appointmentA > now){
        trip.actions = [
          {
            type: "extra",
            description: "Iniciar viaje",
            disable: trip.has_automatic_start,
            action: () => !trip.has_automatic_start ? actionMethods.start(trip) : { },
          },
        ];
      } else {
        trip.actions = [
          {
            type: "extra",
            hover: "Inicio bloqueado por cita vencida",
            description: "Iniciar viaje",
            disable: true,
            action: () => { },
          },
        ];
      }
    } else {
      trip.actions = [
        {
          type: "extra",
          description: "Iniciar viaje",
          disable: trip.has_automatic_start,
          action: () => !trip.has_automatic_start ? actionMethods.start(trip) : { },
        },
      ];
    }
  }

  if(trip.status.id === 5) {

    let messageToShow = isAllowedToRestart(trip.details || []) ? '' : 'Para reiniciar este viaje es importante cancelar primero las facturas timbradas existentes';

    trip.actions.push(
      {
        type: "extra",
        // className: "secondary",
        disable: messageToShow !== '',
        hover: messageToShow,
        description: "Reiniciar viaje",
        action: () => actionMethods.reset(trip),
      }
    )
  }

  if(trip.status.id !== 5 && trip.status.id !== 6 && trip.status.id !== 7 && trip.status.id !== 9 && trip.assemble) { //5-en proceso, 6-completado, 7-cancelado, 9-finalizadoSdatos
    let details = trip?.details || [];
    if (isArray(details) && details.length > 0) {
      // let origin = details[0]?.origin?.date;
      // if (origin !== null && origin !== undefined && !moment(origin).isBefore(moment(new Date()))) {
        trip.actions.push(
          {
            type: "extra",
            // className: "secondary",
            description: "Cambiar ensamble",
            action: () => actionMethods.validateStatusTrip ? actionMethods.validateStatusTrip("Cambiar ensamble", trip, () => { actionMethods.assign(trip) }) : actionMethods.assign(trip),
          }
        )
      //}
    }
  }
  if(trip.status.id !== 9 && trip.status.id !== 6 && trip.status.id !== 7) {
    trip.actions.push(
      {
        type: "extra",
        // className: "secondary",
        description: "Finalizar viaje sin datos",
        action: () => actionMethods.finalizeTrip(trip),
      },
    )
  }

  if (trip.isDonor) {
    trip.actions.push(
      {
        type: "extra",
        // className: "secondary",
        description: "Transferir eventos",
        action: (order) => actionMethods.transferEvents(trip, order),
      },
    )
  }

  trip.details.forEach(d => {
    if(d.statusId !== 11 && d.statusId !== 8 && d.statusId !== 9){ //8 completado, 11-finalizado sin datos, 9-cancelado
      trip.actions.push(
        {
          type: "detail xtra-".concat(d.id),
          description: "Finalizar orden sin datos",
          action: (order) => actionMethods.finalizeOrder(trip, order)
        }
      )
    }
    trip.actions.push(
      {
        type: "detail xtra-".concat(d.id),
        description: "Descargar XML de orden",
        disable: d.cfdi?.xml_file ? false : true,
        action: () => actionMethods.downloadFile(d.cfdi?.xml_file || '')
      }
    )
    trip.actions.push(
      {
        type: "detail xtra-".concat(d.id),
        description: "Descargar PDF de orden",
        disable: d.cfdi?.pdf_file ? false : true,
        action: () => actionMethods.downloadFile(d.cfdi?.pdf_file || '')
      }
    )
    if(d.cfdi?.status === 2){
      trip.actions.push(
        {
          type: "detail xtra-".concat(d.id),
          description: "Cancelar prefactura de orden",
          disable: false,
          action: () => {
            // actionMethods.redirect(`/company/billingcancellation/${d.id}/?isOrder=1`)
            actionMethods.cancelCFDI(d.id);
          }
        }
      )
    } else {
      trip.actions.push(
        {
          type: "detail xtra-".concat(d.id),
          description: "Cancelar CFDI de orden",
          disable: d.cfdi?.status === 3 ? false : true,
          action: () => {
            actionMethods.redirect(`/company/billingcancellation/${d.id}/?isOrder=1`)
          }
        }
      )
    }
  })
  
  trip.actions.push(
    {
      type: "extra",
      description: "Descargar XML de viaje",
      disable: trip.cfdi?.xml_file ? false : true,
      action: () => actionMethods.downloadFile(trip.cfdi?.xml_file || '')
    }
  )
  trip.actions.push(
    {
      type: "extra",
      description: "Descargar PDF de viaje",
      disable: trip.cfdi?.pdf_file ? false : true,
      action: () => actionMethods.downloadFile(trip.cfdi?.pdf_file || '')
    }
  )
  if(trip.cfdi?.status === 2){
    trip.actions.push(
      {
        type: "extra",
        description: "Cancelar prefactura de viaje",
        disable: true,
        action: () => {}
      }
    )
  }
  if(trip.cfdi?.status === 3){
    trip.actions.push(
      {
        type: "extra",
        description: "Cancelar CFDI de viaje",
        disable: true,
        action: () => {}
      }
    )
  }
  // trip.actions = trip.actions.concat([
    
  //   {
  //     type: "detail extra",
  //     // className: "secondary",
  //     description: "Finalizar orden sin datos",
  //     action: (order) => actionMethods.finalizeOrder(trip, order),
  //   },
  // ])
  return { ...trip };
};

export const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
};

export const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  textAlign: "center",
};

export const TableConfig: TableDConfig = {
  rowStyle: {
    backgroundColor: "#3D5074",
    height: "81px",
    borderBottom: "1px solid #A4B4CE",
    minHeight: "81px"
  },
  headerStyle: {
    backgroundColor: "#3D5074",
    borderBottom: "2px solid #A4B4CE",
  },
  contentStyle: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  columns: [
    {
      text: "",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Cliente",
      width: "25%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
    },
    {
      text: "No. de viaje",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Folios",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Clase",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Ensamble",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
  ],
};

export const TableConfigWithCFDI: TableDConfig = {
  rowStyle: {
    backgroundColor: "#3D5074",
    height: "81px",
    borderBottom: "1px solid #A4B4CE",
    minHeight: "81px"
  },
  headerStyle: {
    backgroundColor: "#3D5074",
    borderBottom: "2px solid #A4B4CE",
  },
  contentStyle: {
    height: 'calc(100% - 250px)',
    overflowY: "auto",
  },
  columns: [
    {
      text: "",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Cliente",
      width: "20%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-start" },
    },
    {
      text: "No. de viaje",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Folios",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Clase",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Ensamble",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "CFDI",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
  ],
};

export const mapTripBookingAvailability = (b) => ({
  id: b.assembly.assembly_id,
  tags: b.assembly.equipments
    .map((e) => e.fleet_equipment.fleet_tags)
    .concat(
      b.assembly.vehicles.map((e) => e.fleet_vehicle.fleet_tags),
      b.assembly.operators.map((e) => e.fleet_operator.fleet_tags)
    )
    .flat()
    .filter((v, i, a) => a.map((as) => as.id).indexOf(v.id) === i),

  vehicles: b.assembly.vehicles.map((v) => ({
    economic_number: v.fleet_vehicle.economic_number,
    plates: v.fleet_vehicle.plates,
    icon: getIconFromVehicleClass(
      v.fleet_vehicle.fleet_class.type.abbreviation
    ),
  })),
  operators: b.assembly.operators.map((o) => ({
    name: `${o.fleet_operator.operator.user.first_name} ${o.fleet_operator.operator.user.last_name}`,
    licenseType: o.fleet_operator.operator.licence_type,
    icon: <License />,
    blockedLicense: <BlockedLicense/>,
    avatar: o.fleet_operator.operator.user.profile_image
      ? FILES_SERVER.concat(o.fleet_operator.operator.user.profile_image)
      : "/images/user.svg",
    sanctions: o.fleet_operator.sanctions
  })),
  equipments: b.assembly.equipments.map((e) => ({
    economic_number: e.fleet_equipment.economic_number,
    plates: e.fleet_equipment.plates,
    type: e.fleet_equipment.fleet_class.type.description,
    id: e.fleet_equipment.fleet_equipment_id || 0,
    icon: getIconFromVehicleClass(
      e.fleet_equipment.fleet_class.type.abbreviation
    ),
  })),
  contracts: b.contracts.map((m) => ({
    companyLogo: FILES_SERVER.concat(m.cargo_owner.logo),
    facilityName: m.facility.name,
    tag: m.tag,
  })),
  availabilityDate: new Date(),
  alert: b.assembly.equipments
    .map((e) => e.fleet_equipment.status)
    .concat(
      b.assembly.vehicles.map((e) => e.fleet_vehicle.status),
      b.assembly.operators.map((e) => e.fleet_operator.status)
    )
    .filter((a) => a.id !== 2).length,
});

const getIconFromVehicleClass = (typeAbbreviation: string) => {
  let icon = <Icons.Equipment />;

  switch (typeAbbreviation) {
    case "T":
      icon = <Icons.Truck />;
      break;
    case "S":
      icon = <Icons.Equipment />;
      break;
    case "U":
      icon = <Icons.Unitary />;
      break;
    default:
      icon = <Icons.Equipment />;
  }
  return icon;
};
