import Axios from "axios";
import { cloneDeep } from "lodash";
import React from "react";
import { connect } from 'react-redux';
import { cancelRequest, getEntityItems, readEntities } from "../redux/api";
import { SERVER } from "../redux/api/api-urls";
import { listNetworkApprovedInvitations } from "../redux/reducers/company-reducer";
import { Scroll } from "../shared/animation";
import FilterBar from "../shared/component/filter/filter-bar";
import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import DownloadImage from "../shared/icons/routes/downloadIcon";
import ClockAlert from "../shared/icons/tat/clockAlert";
import Loader from "../shared/icons/tat/loader";
import TATFactory from "../shared/icons/tat/TATFactory";
import TATFactoryTransparent from "../shared/icons/tat/TATFactoryTransparent";
// import TATGlobal from "../shared/icons/tat/TATGlobal";
import TATPlant from "../shared/icons/tat/TATPlant";
import TATPlantaTransparent from "../shared/icons/tat/TATPlantTransparent";
import TATTruck from "../shared/icons/tat/TATTruck";
import { View } from "../shared/styled/view";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";

const today = new Date();
const yesterday = new Date(today.setDate(today.getDate() - 1));

const dateToString = (date) => {
  let dateStr = date.getFullYear();
  dateStr += "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  dateStr += "-" + (date.getDate() < 10 ? "0" : "") + (date.getDate());
  return dateStr;
}

const graphDecimals = 2;
const legendDecimals = 1;
const tableDecimals = 2;

class TATView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
      filterDate: [yesterday],
      filterDateTemp: [yesterday],
      filterFacilities: [],
      filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      filterBaseTags: [],
      filterCO: [],
      filterLT: [],

      innerFilterPlanta: 0,
      innerFilterAgencia: 0,
      innerFilterUEN: 0,
      innerFilterDRV: 0,
      innerFilterLT: 0, 
      innerFilterRuta: 0,

      refreshLabel: "",
      monthStats: {
        estadiaPlanta: 0,
        transitoIda: 0,
        estadiaAgencia: 0,
        transitoVuelta: 0
      },
      dayStats: {
        estadiaPlanta: 0,
        transitoIda: 0,
        estadiaAgencia: 0,
        transitoVuelta: 0
      },
      selectedMonth: (yesterday).getMonth(),
      selectedDailyMode: "tat",

      graphStartDate: props.isEmail ? dateToString(new Date(yesterday.getFullYear(), yesterday.getMonth(), 1)) : "", 
      graphEndDate: props.isEmail ? dateToString(yesterday) : "",
      
      showParetoRutas: false,
      selectedParetoMode: "tat",
      selectedParetoZoomMode: "zoom",
      selectedParetoDisplayMode: "sum",
      selectedParetoTransitMode: props.isEmail ? "ruta" : "lt",
      selectedParetoAgenciaMode: props.isEmail ? "agencia" : "drv",
      selectedParetoPage: props.isEmail ? 2 : 1,
      zoomDataLength: 1,

      showParetoArbol1: false,
      showParetoArbol2: false,
      showParetoArbol3: false,
      showParetoArbol4: false,
      showParetoArbol5: false,

      
      arbol1Criterio: 0,
      arbol1Filtros: [],
      arbol1Grupo: "",
      arbol1Orden: [],
      
      arbol2Criterio: 0,
      arbol2Filtros: [],
      arbol2Grupo: "",
      arbol2Orden: [],
      
      arbol3Criterio: 0,
      arbol3Filtros: [],
      arbol3Grupo: "",
      arbol3Orden: [],
      
      arbol4Criterio: 0,
      arbol4Filtros: [],
      arbol4Grupo: "",
      arbol4Orden: [],
      
      arbol5Criterio: 0,
      arbol5Filtros: [],
      arbol5Grupo: "",
      arbol5Orden: [],

      buttonFilterName: "Aplicar",

      firstLoaded: false
    }

    this.apiLoaded = false;
    this.paretoApiLoaded = false;
  }


  componentDidMount(){
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });
    this.props.loadCompanyFacilities({
      company_id: this.props.companyId,
      view: "grouped",
    });
    if(this.props.companyType === 1){
      this.setState({
        selectedParetoAgenciaMode: 'base',
        selectedParetoTransitMode: 'economico'
      })
    }
    this.props.loadCompanyTags(this.props.companyId);
    this.props.loadCompanyTransportClass(this.props.companyId);
    this.loadApi().then(value => {
      this.loadParetoApi().then(value2 => {
        this.paretoApiLoaded = value2;
        this.loadGlobal();
        if(this.props.isEmail) this.loadDesglose();
      });
      this.apiLoaded = value;
    });
    this.props.setNavbarAction("tat", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("tat");
  }

  loadGlobal = () => {
    this.props.cancelRequest('COMPANIES.TAT.GLOBAL');
    setTimeout(function() {
      this.props.loadTATGlobal({
        company_id: this.props.companyId,
        start_date: "".concat(this.state.filterDate[0].getFullYear(),'-01-01'),
        end_date: dateToString(this.state.filterDate[0]),
        from_facility: "",
        to_facility: "",
        fleet_provider: this.state.filterLT,
        cargo_owner: this.state.filterCO,
        uen: "",
        drv: "",
        route: "",
        action: "",
        // tags: this.state.filterTags.concat(this.state.filterTags2),
        order_tags: this.state.filterTags2,
        trip_tags: this.state.filterTags,
        base_tags: this.state.filterBaseTags,
        facilities: this.state.filterFacilities,
        transport_class: this.state.filterTransportClass,
      }, {
        onSuccess: () => {

          this.setState({
            firstLoaded: true
          });

          this.graphDaily();
          this.graphMonthly();
          this.renderCanvas();
        }
      })
    }.bind(this), 1)
  }

  
  getUrl = (type) => {//url para descargables
    switch(type){
      case "global": //tat por etapas
        return `${SERVER}/api/v2/companies/${this.props.companyId}/tat/?group=&view=desglose&action=${"download"}&start_date=${this.state.graphStartDate}&end_date=${this.state.graphEndDate}&from_facility=${""}&to_facility=${""}&fleet_provider=${this.state.filterLT}&cargo_owner=${this.state.filterCO}&uen=${""}&drv=${""}&route=${""}&order_tags=${this.state.filterTags2}&trip_tags=${this.state.filterTags}&base_tags=${this.state.filterBaseTags}&facilities=${this.state.filterFacilities}&transport_class=${this.state.filterTransportClass}`;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5: //Arboles
        let group = "";
        switch(this.state["arbol".concat(type, "Grupo")]){
          case "ruta":
            group= "route";
            break;
          case "uen":
            group= "uen";
            break;
          case "agencia":
            group= "to_facility";
            break;
          case "linea":
            group= "fleet_provider";
            break;
          case "economico":
            group= "economic_numbers";
            break;
          case "operador":
            group= "operators";
            break;
          default:
        }
        return `${SERVER}/api/v2/companies/${this.props.companyId}/tat/?group=${group}&view=criterio&action=${"download"}&start_date=${this.state.graphStartDate}&end_date=${this.state.graphEndDate}&from_facility=${this.state["arbol".concat(type, "Filtros")]["origen"] || ""}&to_facility=${this.state["arbol".concat(type, "Filtros")]["agencia"] || ""}&fleet_provider=${this.state["arbol".concat(type, "Filtros")]["linea"] || this.state.filterLT}&cargo_owner=${this.state.filterCO}&uen=${this.state["arbol".concat(type, "Filtros")]["uen"] || ""}&drv=${this.state["arbol".concat(type, "Filtros")]["drv"] || ""}&route=${this.state["arbol".concat(type, "Filtros")]["ruta"] || ""}&operators=${this.state["arbol".concat(type, "Filtros")]["operador"] || ""}&economic_numbers=${this.state["arbol".concat(type, "Filtros")]["economico"] || ""}&lt_base=${this.state["arbol".concat(type, "Filtros")]["base"] || ""}&order_tags=${this.state.filterTags2}&trip_tags=${this.state.filterTags}&base_tags=${this.state["arbol".concat(type, "Filtros")]["base"] || this.state.filterBaseTags}&facilities=${this.state.filterFacilities}&transport_class=${this.state.filterTransportClass}`
      default:
        return ""
    }
  }

  loadDesglose = () => {
    this.props.cancelRequest('COMPANIES.TAT.DESGLOSE');
    setTimeout(function() {
      this.props.loadTATDesglose({
        group: "",
        company_id: this.props.companyId,
        start_date: this.state.graphStartDate,
        end_date: this.state.graphEndDate,
        from_facility: "",
        to_facility: "",
        fleet_provider: this.state.filterLT,
        cargo_owner: this.state.filterCO,
        uen: "",
        drv: "",
        route: "",
        action: "",
        // tags: this.state.filterTags.concat(this.state.filterTags2),
        order_tags: this.state.filterTags2,
        trip_tags: this.state.filterTags,
        base_tags: this.state.filterBaseTags,
        facilities: this.state.filterFacilities,
        transport_class: this.state.filterTransportClass,
      }, {
        onSuccess: () => {
          if(!this.props.isEmail)
            this.graphPareto()
          else {
            this.graphPareto("tat");
            this.graphPareto("estadiaPlanta");
            this.graphPareto("transitoIda");
            this.graphPareto("estadiaAgencia");
            this.graphPareto("transitoVuelta");
          }
        }
      })
    }.bind(this), 1)
    if(!this.props.isEmail)
      Scroll.go(document.getElementById('paretoRutaPanel'), document.getElementById('pageContent'));
  }

  loadArbol = (level = 0) => {
    // debugger;
    if(level){
      let group = "";
      switch(this.state["arbol".concat(level, "Grupo")]){
        case "ruta":
          group= "route";
          break;
        case "uen":
          group= "uen";
          break;
        case "agencia":
          group= "to_facility";
          break;
        case "linea":
          group= "fleet_provider";
          break;
        case "economico":
          group= "economic_numbers";
          break;
        case "operador":
          group= "operators";
          break;
        default:
      }
      this.props.cancelRequest("arbol".concat(level));
      // debugger;
      setTimeout(function() {
        this.props.loadTATCriterio({
          group: group,
          company_id: this.props.companyId,
          start_date: this.state.graphStartDate,
          end_date: this.state.graphEndDate,
          from_facility: this.state["arbol".concat(level, "Filtros")]["origen"] || "",
          to_facility: this.state["arbol".concat(level, "Filtros")]["agencia"] || "",
          fleet_provider: this.state["arbol".concat(level, "Filtros")]["linea"] || this.state.filterLT,
          cargo_owner: this.state.filterCO,
          uen: this.state["arbol".concat(level, "Filtros")]["uen"] || "",
          drv: this.state["arbol".concat(level, "Filtros")]["drv"] || "",
          route: this.state["arbol".concat(level, "Filtros")]["ruta"] || "",
          action: "",
          operators: this.state["arbol".concat(level, "Filtros")]["operador"] || "",
          economic_numbers: this.state["arbol".concat(level, "Filtros")]["economico"] || "",
          base: this.state["arbol".concat(level, "Filtros")]["base"] || "",
          
          // tags: this.state.filterTags.concat(this.state.filterTags2),
          order_tags: this.state.filterTags2,
          trip_tags: this.state.filterTags,
          base_tags: this.state["arbol".concat(level, "Filtros")]["base"] || this.state.filterBaseTags,
          facilities: this.state.filterFacilities,
          transport_class: this.state.filterTransportClass,
        }, {
          alias: "arbol".concat(level),
          onSuccess: () => {this.graphArbol(level);}
        })
      }.bind(this), 1)
      Scroll.go(document.getElementById('paretoRutaArbol'.concat(level)), document.getElementById('pageContent'));
    }
  }

  loadParetoApi = () => {
    return new Promise((resolve) => {
      if (this.paretoApiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/modules/pareto.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/modules/pareto.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  loadApi = () => {
    return new Promise((resolve) => {
      if (this.apiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/highcharts.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/highcharts.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  digit2 = number => {
    if(number < 10){
      return '0'.concat(number);
    } else {
      return ''.concat(number);
    }
  }

  //Filters
  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  }

  handleFilterChange = (e) => {
    if(e.target.name === "filterDate"){
      // this.setState({ [e.target.name]: e.target.value }, this.renderCanvas);
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.setState({ buttonFilterName: "Aplicar" });
  }

  handleFilter = () => {
    // this.loadUsers()
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ showParetoRutas: false, buttonFilterName: "Restablecer", selectedMonth: this.state.filterDateTemp[0].getMonth(), filterDate: this.state.filterDateTemp }, () => {
        this.loadGlobal();
        this.hideTrees();
      })
    } else {
      this.setState({ showParetoRutas: false, buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      filterDate: [yesterday],
      filterDateTemp: [yesterday],
      filterFacilities: [],
      filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      filterBaseTags: [],
      filterLT: [],
      filterCO: [],
    }, () => {
      this.loadGlobal();
      this.hideTrees();
    });
  }

  filterContents = () => {
    if(this.props.companyType === 1)
      return [
        {
          title: "Fecha",
          // items: this.props.companyFacilities,
          name: "filterDateTemp",
          listType: FILTER_LIST_TYPES.dateFixed,
        },
        {
          title: "Instalación",
          items: this.props.LTFacilities,
          name: "filterFacilities",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Base",
          items: this.props.baseTags.map(oT => ({id: oT.id, description: oT.title})),
          name: "filterBaseTags",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Clase de expedición",
          items: this.props.transportClassesByTag,
          name: "filterTransportClass",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Clientes",
          items: this.props.networkCompanies,
          name: "filterCO",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
      ];
    return [
      {
        title: "Fecha",
        // items: this.props.companyFacilities,
        name: "filterDateTemp",
        listType: FILTER_LIST_TYPES.dateFixed,
      },
      {
        title: "Instalación",
        items: this.props.companyFacilities,
        name: "filterFacilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta",
        items: this.props.tripTags.map(oT => ({id: oT.id, description: oT.title})),
        name: "filterTags",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta 1",
        items: this.props.orderTags.map(oT => ({id: oT.id, description: oT.title})),
        name: "filterTags2",
        listType: FILTER_LIST_TYPES.checkExclusive,
      },
      {
        title: "Clase de expedición",
        items: this.props.transportClassesByTag,
        name: "filterTransportClass",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Linea Transportista",
        items: this.props.networkCompanies,
        name: "filterLT",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
    ]
  }

  getSelectedItems = () => {
    if(this.props.companyType === 1){
      return {
        filterDateTemp: [this.state.filterDateTemp[0]],
        filterFacilities: this.state.filterFacilities,
        filterBaseTags: this.state.filterBaseTags,
        filterTransportClass: this.state.filterTransportClass,
        filterCO: this.state.filterCO,
      }
    } else {
      return {
        filterDateTemp: [this.state.filterDateTemp[0]],
        filterFacilities: this.state.filterFacilities,
        filterTags2: this.state.filterTags2,
        filterTags: this.state.filterTags,
        filterTransportClass: this.state.filterTransportClass,
        filterLT: this.state.filterLT,
      }
    }
  }

  //StatsMonth
  getMonthStatsTitle = () => {
    return ("".concat(months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  //StatsDay
  getDayStatsTitle = () => {
    return ("".concat(this.state.filterDate[0].getDate(), " ", months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  //canvas
  renderCanvas = () => {
    const canvas = document.getElementById('canvas-month');
    const ctx = canvas.getContext('2d');
    
    let curMonth = this.state.filterDate[0].getMonth() + 1;
    let mtm = (this.props.tatGlobal?.mtm || []).filter(item => parseInt(item.id) === curMonth)[0] || {};
    const dtd = (this.props.tatGlobal?.dtd || []).find(d => d.id === "".concat(
      this.state.filterDate[0].getFullYear(), 
      '-', 
      this.digit2(this.state.filterDate[0].getMonth() + 1), 
      '-', 
      this.digit2(this.state.filterDate[0].getDate())
    )) || {};

    let startAngle = 0-90;
    let acc = 0;
    // if(filtrosValues['etiqueta1'][0] === 'PT'){
      let total = (mtm.avg_tat_real || 0); /* state.stats.total; */
      const stats = ['avg_order1_estadia_origen_minutos', 'avg_order1_estadia_transito_minutos', 'avg_order1_estadia_estadia_destino', 'avg_order2_estadia_transito_minutos'];
    // } else {
    //   let total = (state.stats.estadiaPlanta + state.stats.estadiaAgencia + state.stats.transitoIda);
    //   let stats = ['estadiaPlanta', 'transitoIda', 'estadiaAgencia'];
    // }
    // this.UI.total.innerHTML = '<b>' + total.toFixed(1) + '</b>hr';


    const colors = ['#4CABFF', '#FE6901', '#ACACAC', '#F3C91D'];
    const deg_rad = Math.PI / 180;

    ctx.clearRect(0, 0, 260, 260);
    for (let iStat = 0; iStat < stats.length; iStat++){
      acc += mtm[stats[iStat]] || 0;
      ctx.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx.fillStyle = colors[iStat];
      ctx.fill();
      ctx.closePath();
      startAngle = endAngle;
    }

    const canvas2 = document.getElementById('canvas-day');
    const ctx2 = canvas2.getContext('2d');
    startAngle = 0-90;
    acc = 0;
    total = (dtd.avg_tat_real || 0);
    ctx2.clearRect(0, 0, 260, 260);
    // debugger;
    for (let iStat = 0; iStat < stats.length; iStat++){
      acc += dtd[stats[iStat]] || 0;
      ctx2.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx2.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx2.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx2.fillStyle = colors[iStat];
      ctx2.fill();
      ctx2.closePath();
      startAngle = endAngle;
    }
  }

  //Daily
  getDailyTitle = () => {
    switch(this.state.selectedDailyMode) {
      case "estadiaPlanta":
        return "Estadía en origen";
      case "transitoVuelta":
        return "Tránsito vuelta";
      case "transitoIda":
        return "Tránsito ida";
      case "estadiaAgencia":
        return "Estadía en destino";
      case "tat":
        return "TAT diario";
      default:
        return ""
    }
  }

  graphDaily = () => {
    let serie = 0;
    let valorIndex = 'avg_order1_estadia_estadia_destino'; //estadiaAgencia transitoIda estadiaPlanta transitoVuelta
    let benchmark = 'avg_tat_max_estadia_destino_minutos';
    let self = this;
    switch(this.state.selectedDailyMode){
      case 'estadiaPlanta':
        serie = 0;
        valorIndex = 'avg_order1_estadia_origen_minutos';
        benchmark = 'avg_tat_max_estadia_origen_minutos';
        break;
      case 'transitoIda':
        serie = 1;
        valorIndex = 'avg_order1_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_ida_minutos';
        break;
      case 'estadiaAgencia':
        serie = 2;
        valorIndex = 'avg_order1_estadia_estadia_destino';
        benchmark = 'avg_tat_max_estadia_destino_minutos';
        break;
      case 'transitoVuelta':
        serie = 3;
        valorIndex = 'avg_order2_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_vuelta_minutos';
        break;
      case 'tat':
        serie = 4;
        valorIndex = 'avg_tat_real';
        benchmark = 'avg_tat_benchmark';
        break;
      default:
    }
    // debugger;
    let data = JSON.parse(JSON.stringify(MonthChart));
    data.chart.backgroundColor = 'transparent';
    data.legend = {
      itemStyle: {
          color: '#fff',
          fontSize: '15px'
      }
    };
    data.xAxis = {
      categories: [],
      crosshair: true,
      lineColor: '#303349',
      tickLength: 0,
      labels: {
        style: {
          color: 'white',
          fontSize: '15px'
        }
      }
    };
    data.yAxis = {
      gridLineWidth: 0,
      title: { text: '' },
      labels: {enabled: false}
    };
    data.series = [];
    data.series.push(cloneDeep(MonthChart.series[serie]));
    data.series.push(cloneDeep(MonthChart.series[5]));
    data.series[0].dataLabels = [{
      align: 'center top',
      x: 0,
      y: 10,
      color: '#fff',
      shadow: false,
      style: {
        fontWeight: 'normal',
      },
      formatter: function(){
        return (this.y !== 0 ? this.y.toFixed(legendDecimals) : '');
      }
    }];
    data.series[0].data = [];
    data.series[1].data = [];
    data.series[1].dataLabels = data.series[0].dataLabels;
    data.series[0].point = {
      events: {
        click: function () {
          let date = new Date();
          date.setDate(this.category)
          date.setMonth(self.state.selectedMonth);
          date.setFullYear(self.state.filterDate[0].getFullYear());
          self.setState({
            graphStartDate: dateToString(date),
            graphEndDate: dateToString(date),
            showParetoRutas: true,
            selectedParetoMode: self.state.selectedDailyMode
          }, self.loadDesglose)
        }
      }
    };
    let daysData = (this.props.tatGlobal?.dtd || []).filter(d => {
      let month = parseInt(d.description.split('-')[1]); 
      return month === this.state.selectedMonth + 1;
    })
    daysData.forEach(d => {
      data.xAxis.categories.push(d.description.split('-')[2]);
      data.series[0].data.push({ 
        y:(
          ( d[valorIndex] 
            && Number((d[valorIndex]/60).toFixed(graphDecimals))
          ) || null
        ) 
      });
      data.series[1].data.push({ 
        y:(
          ( d[benchmark] 
            && Number((d[benchmark]/60).toFixed(graphDecimals))
          ) || null
        ) 
      });
    })
    // debugger;
    window.Highcharts.chart('monthChart', data);
  }

  //Monthly
  graphMonthly = () => {
    // let months = {'Ene':1,'Feb':2,'Mar':3,'Abr':4,'May':5,'Jun':6,'Jul':7,'Ago':8,'Sep':9,'Oct':10,'Nov':11,'Dic':12};
    const year = this.props.tatGlobal?.year || [];
    const months = this.props.tatGlobal?.mtm || [];
    const self = this;
    let data = JSON.parse(JSON.stringify(DefaultChart));
    data.chart.height = 550;
    data.chart.backgroundColor = 'transparent';
    data.yAxis = [];
    data.yAxis.push(JSON.parse(JSON.stringify(DefaultChart.yAxis[0])));
    data.yAxis.push({
      title: {
        text: ""
      },
      linkedTo: 0,
      opposite: true,
      gridLineWidth: 0,
      labels: {
        enabled: false
      }
    });
    data.yAxis[0].stackLabels.style.fontSize = '22px';
    data.yAxis[0].stackLabels.formatter = function(){
      if (this.total === 0) {
        return null;
      } else if (this.total > 1000) {
      return (this.total/1000).toFixed(legendDecimals);
      } else {
        return (this.total/1).toFixed(legendDecimals);
      }
    }
    delete data.plotOptions.series.dataLabels.formatter;
    data.plotOptions = {
      series: {
        events: {
          legendItemClick: function () {
            if(this.yAxis.series.length === 4){
              let self4 = this;
              let cont = this.visible ? -1 : 1;
              let names = [];
              this.yAxis.series.forEach(function(x){
                if(x.visible){
                  cont++;
                }
                if(x.name === self4.name){
                  if(!self4.visible){
                    names.push(self4.name);
                  }
                }else{
                  if(x.visible){
                    names.push(x.name);
                  }
                }
              });
              if(names.length > 0){
                // var titulos = self.UI.title.innerHTML.split(' - ');
                // titulos[0] = (names.length === 4 || (filtrosValues['etiqueta1'][0] != 'PT' && names.length === 3) ? 'TAT Mensual' : names.join(', ') + ' Mensual');
                // self.UI.title.innerHTML = titulos.join(' - ');
              }
              this.chart.options.plotOptions.series.dataLabels.enabled = (cont < 2 ? false : true);
              if(cont < 4){
                this.chart.series[4].hide();
              } else{
                this.chart.series[4].show();
              }
              // console.log(this);
            }
          }
        },
        stacking: 'normal',
        dataLabels: {
          crop: false,
          enabled: true,
          allowOverlap: true,
          inside: true,
          formatter: 1,
          textOutline: false,
          style: {
            fontSize: '12px'
          } 
        }
      }
    };
    data.legend = {
        itemStyle: {
            color: '#fff',
            fontSize: '15px'
        }
    };
    data.tooltip = {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + '<br/>' + (point.series.name === 'Meta' ? ('<span style="color:#960c0c;font-weight: bold;font-size: 14px;">\u0C7C </span>') : ('<span style="color:'+point.color+';">\u25CF </span>')) + (point.series.name === 'Meta' ? 'Global' : point.series.name) + ': ' + (point.point.pct ? point.point.pct : (point.y ? point.y.toFixed(graphDecimals) : '-'));
        }, '<b>' + this.x + ' Metas:</b>');
      },
    };
    data.xAxis = {
      categories: [],
      crosshair: true,
      lineColor: '#303349',
      tickLength: 0,
      labels: {
        style: {
          color: '#fff',
          fontSize: '23px'
        }
      }
    };

    data.yAxis[0].labels = {enabled: false};

    data.series = [];
    data.series.push(cloneDeep(DefaultChart.series[3]));
    data.series.push(cloneDeep(DefaultChart.series[2]));
    data.series.push(cloneDeep(DefaultChart.series[1]));
    data.series.push(cloneDeep(DefaultChart.series[0]));
    data.series.push(cloneDeep(DefaultChart.series[5]));
    data.series[0].data = [];
    data.series[1].data = [];
    data.series[2].data = [];
    data.series[3].data = [];
    data.series[4].data = [];

    //YEAR
    if(year[0]){
      data.xAxis.categories.push(year[0].description);
      data.series[3].data.push({ 
        y: year[0].avg_order1_estadia_origen_minutos 
          ? Number((year[0].avg_order1_estadia_origen_minutos/60).toFixed(graphDecimals))
          : null,
        pct: year[0].avg_tat_max_estadia_origen_minutos
          ? Number((year[0].avg_tat_max_estadia_origen_minutos/60).toFixed(graphDecimals))
          : null
        });
      data.series[2].data.push({ 
        y: year[0].avg_order1_estadia_transito_minutos 
          ? Number((year[0].avg_order1_estadia_transito_minutos/60).toFixed(graphDecimals))
          : null,
        pct: year[0].avg_tat_max_transito_ida_minutos
          ? Number((year[0].avg_tat_max_transito_ida_minutos/60).toFixed(graphDecimals))
          : null
        });
      data.series[1].data.push({ 
        y: year[0].avg_order1_estadia_estadia_destino 
          ? Number((year[0].avg_order1_estadia_estadia_destino/60).toFixed(graphDecimals))
          : null,
        pct: year[0].avg_tat_max_estadia_destino_minutos
          ? Number((year[0].avg_tat_max_estadia_destino_minutos/60).toFixed(graphDecimals))
          : null
        });
      data.series[0].data.push({ 
        y: year[0].avg_order2_estadia_transito_minutos 
          ? Number((year[0].avg_order2_estadia_transito_minutos/60).toFixed(graphDecimals))
          : null,
        pct: year[0].avg_tat_max_transito_vuelta_minutos
          ? Number((year[0].avg_tat_max_transito_vuelta_minutos/60).toFixed(graphDecimals))
          : null
        });
      data.series[4].data.push({ 
        y: year[0].avg_tat_benchmark 
          ? Number((year[0].avg_tat_benchmark/60).toFixed(graphDecimals))
          : null,
        pct: year[0].avg_tat_benchmark
          ? Number((year[0].avg_tat_benchmark/60).toFixed(graphDecimals))
          : null
        });
    }
    //YEAR

    data.series[0].dataLabels = [{
      align: 'center',
      style: {
        fontWeight: 'normal',
        textOutline: false,
        fontSize: '15px',
        "color": "#000"
      },
      formatter: function(){
        return (this.y !== 0 ? this.y.toFixed(legendDecimals) : '');
      },
      x: 0,
      y: 0
    }];
    data.series[1].dataLabels = data.series[0].dataLabels;
    data.series[2].dataLabels = data.series[0].dataLabels;
    data.series[3].dataLabels = data.series[0].dataLabels;

    data.series[0].point = {
      events: {
        click: function () {
         
          let startDate = 0;
          let endDate = 0;
          // debugger;
          if(Object.keys(months_index).includes(this.category)){
            startDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category], 1);
            if(startDate.getMonth() === self.state.filterDate[0].getMonth()){
              endDate = self.state.filterDate[0];
            }else{
              endDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category]+1, 0);
            }
          } else{
            startDate = new Date(self.state.filterDate[0].getFullYear(), 0, 1);
            endDate = new Date(self.state.filterDate[0].getFullYear(), 11, 31);
          }
          self.setState({
            graphStartDate: dateToString(startDate),
            graphEndDate: dateToString(endDate),
            showParetoRutas: true,
            selectedParetoMode: 'tat'
          }, self.loadDesglose)
        }
      }
    };
    data.series[1].point = data.series[0].point;
    data.series[2].point = data.series[0].point;
    data.series[3].point = data.series[0].point;

    months.forEach((m, i) => {
      data.xAxis.categories.push(months_abbr[parseInt(m.id) - 1]);
      
      data.series[3].data.push({ 
        y: m.avg_order1_estadia_origen_minutos
          ? Number((m.avg_order1_estadia_origen_minutos/60).toFixed(graphDecimals))
          : null, 
        pct: m.avg_tat_max_estadia_origen_minutos
          ? Number((m.avg_tat_max_estadia_origen_minutos/60).toFixed(graphDecimals))
          : null 
      });
      data.series[2].data.push({ 
        y: m.avg_order1_estadia_transito_minutos
          ? Number((m.avg_order1_estadia_transito_minutos/60).toFixed(graphDecimals))
          : null, 
        pct: m.avg_tat_max_transito_ida_minutos
          ? Number((m.avg_tat_max_transito_ida_minutos/60).toFixed(graphDecimals))
          : null 
      });
      data.series[1].data.push({ 
        y: m.avg_order1_estadia_estadia_destino
          ? Number((m.avg_order1_estadia_estadia_destino/60).toFixed(graphDecimals))
          : null, 
        pct: m.avg_tat_max_estadia_destino_minutos
          ? Number((m.avg_tat_max_estadia_destino_minutos/60).toFixed(graphDecimals))
          : null 
      });
      data.series[0].data.push({ 
        y: m.avg_order2_estadia_transito_minutos
          ? Number((m.avg_order2_estadia_transito_minutos/60).toFixed(graphDecimals))
          : null, 
        pct: m.avg_tat_max_transito_vuelta_minutos
          ? Number((m.avg_tat_max_transito_vuelta_minutos/60).toFixed(graphDecimals))
          : null 
      });
      data.series[4].data.push({ 
        y: m.avg_tat_benchmark
          ? Number((m.avg_tat_benchmark/60).toFixed(graphDecimals))
          : null, 
        pct: m.avg_tat_benchmark
          ? Number((m.avg_tat_benchmark/60).toFixed(graphDecimals))
          : null 
      });
    })

    // console.log('CHART DATA', data);

    window.Highcharts.chart("yearSummaryChart", data, function(chart){
      chart.series[4].data.forEach(function(x){
        if(x.graphic)
          x.graphic.translate(-30,-15);
      });
    });
  }

  getArbolByCompanyType = () => {
    switch(this.props.companyType){
      case 1:
        return arbolesLT;
      case 2: 
        return arboles;
      default:
        return arboles; 
    }
  }

  //ParetoEtapa
  graphPareto = (etapa) => {
    const self = this;
    let width = null;
    let data = JSON.parse(JSON.stringify(DefaultChart));
    data.chart.backgroundColor = 'transparent';
    data.chart.width = width;
    data.chart.height = 500;
    delete data.plotOptions.series.dataLabels.formatter;
    // data.legend = { enabled: false };
    data.legend = {
        itemStyle: {
            color: '#fff',
            fontSize: '15px'
        }
    };
    data.plotOptions.series.dataLabels = {enabled: false};

    data.tooltip = {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          // eslint-disable-next-line
          return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': ' + (point.point.pct ? point.point.pct : (point.y ? point.y.toFixed(graphDecimals) : '-'));
        }, '<b>' + this.x + '</b>');
      },
    }
    
    data.xAxis = {
      categories: [],
      crosshair: true,
      lineColor: '#535661',
      tickLength: 0,
      labels: {
        //autoRotation: [-45],
            style: {
                color: 'white'
            }
        }
    };
    data.yAxis =  [{
      title: {
          text: ''
      },
      maxPadding: 0.4,
      lineColor: '#303349',
      gridLineColor: '#666666',
      gridLineWidth: 0,
      opposite: true,
      startOnTick: false,
      endOnTick: false,
      labels: {
          enabled: false
      }
    },{
      title: {
            text: ''
        },
        min: 0,
        max: 100,
        gridLineColor: '#666666',
        gridLineWidth: (self.state.selectedParetoDisplayMode === 'sum' ? 1 : 0),
        startOnTick: false,
        endOnTick: false,
        opposite: false,
        plotLines: [{
            color: '#fff',
            width: 2,
            value: 0,
            zIndex: 3
        }],
        labels: {
            formatter: function(){
              return Math.floor(this.value/1) + '%';
            },
            enabled: (self.state.selectedParetoDisplayMode  === 'sum' ? true : false)
        }
    }];
    // data.yAxis[1].labels

    data.series = [];
    // if(state.mode !== 'tat'){
    let serie = 0;
    let dataIndex = 'agencia'; //AGENCIA IDA PLANTA VUELTA
    let valorIndex = 'avg_order1_estadia_estadia_destino'; //estadiaAgencia transitoIda estadiaPlanta transitoVuelta
    let benchmark = 'avg_tat_max_estadia_destino_minutos';
    switch(etapa || this.state.selectedParetoMode){
      case 'estadiaPlanta':
        serie = (/* filtrosValues['etiqueta1'][0] === 'Vidrio' ? 6 : */ 0);
        dataIndex = 'planta';
        valorIndex = 'avg_order1_estadia_origen_minutos';
        benchmark = 'avg_tat_max_estadia_origen_minutos';
        break;
      case 'transitoIda':
        serie = 1;
        dataIndex = (
          self.state.selectedParetoTransitMode === 'lt' 
          ? 'lt' 
          : self.state.selectedParetoTransitMode === 'ruta' 
            ? 'ruta'
            : self.state.selectedParetoTransitMode === 'economico' 
              ? 'economico_ida' 
              : 'operadores_ida');
        valorIndex = 'avg_order1_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_ida_minutos';
        break;
      case 'estadiaAgencia':
        serie = (/* filtrosValues['etiqueta1'][0] === 'Vidrio' ? 7 : */ 2);
        dataIndex = self.state.selectedParetoAgenciaMode  === 'base' ? 'base_lt' : self.state.selectedParetoAgenciaMode/* === 'uen' ? 'UENS' :  'agencia') */;
        valorIndex = 'avg_order1_estadia_estadia_destino';
        benchmark = 'avg_tat_max_estadia_destino_minutos';
        break;
      case 'transitoVuelta':
        serie = 3;
        dataIndex = (
          self.state.selectedParetoTransitMode === 'lt' 
          ? 'lt' 
          : self.state.selectedParetoTransitMode === 'ruta' 
            ? 'ruta'
            : self.state.selectedParetoTransitMode === 'economico' 
              ? 'economico_vuelta' 
              : 'operadores_vuelta'
        );
        valorIndex = 'avg_order2_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_vuelta_minutos';
        break;
      case 'tat':
        serie = 4;
        dataIndex = 'ruta';
        valorIndex = 'avg_tat_real';
        benchmark = 'avg_tat_benchmark';
        break;
      default:

    }
    if(self.state.selectedParetoDisplayMode  === 'sum'){
      data.series.push({
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 2,
        color: '#008CFF',
        dataLabels:{
          enabled: false
        }
      });
    }
    else{
      data.series.push({
        name: 'Total1',
        data: [],
        showInLegend: false,
        stack: 0,
        color: '#00ffff',
        borderWidth: 0,
        visible: false
      });
    }
    data.series.push(cloneDeep(DefaultChart.series[serie]));
    data.series.push({
      name: 'Total',
      showInLegend: false,
      data: [],
      stack: 0,
      color: '#00ffff',
      borderWidth: 0,
      visible: false
    });
    data.series.push({
      name: 'Viajes',
      type: 'spline',
      legendIndex:5,
      yAxis: 1,
      zIndex: 11,
      lineWidth: 0,
      color: '#f7a35c',
      marker: {
        enabled: true,
        symbol: 'diamond',
        radius: 7
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          color: '#FFFFFF',
        },
        formatter: function(){
          return this.point.pct;
        }
      },
      data: []
    });
    data.series[1].data = [];
    data.series[1].dataLabels = [{
      rotation: 270,
      verticalAlign: 'top',
      y: -25,
      style: {
        color: '#FFFFFF',
        textOutline: '0px',
        fontSize: '14px',
      },
      formatter: function(){
        let localstring = Math.floor(this.y/1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (self.state.selectedParetoDisplayMode === 'sum' ? localstring : (this.y/1).toFixed(legendDecimals));
      },
      enabled: true
    }];
    data.series[1].point = {
      events: {
        click: function () {
          let filtros = [];
          // console.log("rutas on click", this);
          // debugger;
          if((etapa || self.state.selectedParetoMode) === 'transitoIda' || (etapa || self.state.selectedParetoMode) === 'transitoVuelta'){
            let modulo = (etapa || self.state.selectedParetoMode);
            let orden = (etapa || self.state.selectedParetoMode);
            if(self.state.selectedParetoTransitMode === 'lt'){
              filtros['linea'] = this.name;
              // this.setState({innerFilterLT: this.name});
            } else if(self.state.selectedParetoTransitMode === 'ruta'){
              filtros['ruta'] = this.name;
              // this.setState({innerFilterRuta: this.category});
              modulo = (etapa || self.state.selectedParetoMode) + 'Ruta';
              orden = (etapa || self.state.selectedParetoMode) + 'Ruta';
            }else if(self.state.selectedParetoTransitMode === 'operador'){
              filtros['operador'] = this.name;
              // this.setState({innerFilterRuta: this.category});
              modulo = (etapa || self.state.selectedParetoMode) + 'Operador';
              orden = (etapa || self.state.selectedParetoMode) + 'Operador';
            } else {
              filtros['economico'] = this.name;
              // this.setState({innerFilterRuta: this.category});
              modulo = (etapa || self.state.selectedParetoMode) + 'Economico';
              orden = (etapa || self.state.selectedParetoMode) + 'Economico';
            }
            let grupo = Object.keys(self.getArbolByCompanyType()[modulo])[0];
            self.setState({
              showParetoArbol1: true,
              showParetoArbol2: false,
              showParetoArbol3: false,
              showParetoArbol4: false,
              showParetoArbol5: false,
              arbol1Criterio: this.category,
              arbol1Filtros: filtros,
              // arbol1Modulo: modulo,
              arbol1Grupo: grupo,
              // arbol1Desviacion: self.state.desviacion,
              // arbol1Nivel: 1,
              arbol1Orden: [orden]
            }, () => self.loadArbol(1));
          } else if((etapa || self.state.selectedParetoMode) === 'estadiaAgencia' && self.state.selectedParetoAgenciaMode === 'drv'){
            filtros['drv'] = this.name;
            let grupo = Object.keys(self.getArbolByCompanyType()["estadiaDRV"])[0];
            // Orbinetwork.data.setState('TAT_ParetoAgencia', {
            self.setState({
              showParetoArbol1: true,
              showParetoArbol2: false,
              showParetoArbol3: false,
              showParetoArbol4: false,
              showParetoArbol5: false,
              arbol1Criterio: this.category,
              arbol1Filtros: filtros,
              // arbol1Modulo: "estadiaDRV",
              arbol1Grupo: grupo,
              // arbol1Desviacion: self.state.desviacion,
              // arbol1Nivel: 1,
              arbol1Orden: ["estadiaDRV"]
            }, () => self.loadArbol(1));
          } else if((etapa || self.state.selectedParetoMode) === 'estadiaAgencia' && self.state.selectedParetoAgenciaMode === 'uen'){
            filtros['uen'] = this.name;
            // let grupo = Object.keys(arboles["estadiaUEN"])[0];
            // debugger;
            self.setState({
              showParetoArbol1: true,
              showParetoArbol2: false,
              showParetoArbol3: false,
              showParetoArbol4: false,
              showParetoArbol5: false,
              arbol1Criterio: this.category,
              arbol1Filtros: filtros,
              // arbol1Modulo: "estadiaUEN",
              arbol1Grupo: "agencia",
              // arbol1Desviacion: self.state.desviacion,
              // arbol1Nivel: 1,
              arbol1Orden: ["estadiaUEN"]
            }, () => self.loadArbol(1));
          } else if((etapa || self.state.selectedParetoMode) === 'estadiaAgencia' && self.state.selectedParetoAgenciaMode === 'base'){
            filtros['base'] = this.name;
            let grupo = Object.keys(self.getArbolByCompanyType()["estadiaBase"])[0];
            // debugger;
            self.setState({
              showParetoArbol1: true,
              showParetoArbol2: false,
              showParetoArbol3: false,
              showParetoArbol4: false,
              showParetoArbol5: false,
              arbol1Criterio: this.category,
              arbol1Filtros: filtros,
              // arbol1Modulo: "estadiaUEN",
              arbol1Grupo: grupo,
              // arbol1Desviacion: self.state.desviacion,
              // arbol1Nivel: 1,
              arbol1Orden: ["estadiaBase"]
            }, () => self.loadArbol(1));
          } else{
            if((etapa || self.state.selectedParetoMode) === 'estadiaAgencia'){
              filtros['agencia'] = this.name;
            }else if((etapa || self.state.selectedParetoMode) === 'estadiaPlanta'){
              filtros['origen'] = this.name;
            }else{
              filtros['ruta'] = this.name;
            }
            let arbol = self.getArbolByCompanyType()[(etapa || self.state.selectedParetoMode)];
            let grupo = Object.keys(arbol)[0];
            // debugger;
            self.setState({
              showParetoArbol1: true,
              showParetoArbol2: false,
              showParetoArbol3: false,
              showParetoArbol4: false,
              showParetoArbol5: false,
              arbol1Criterio: this.category,
              arbol1Filtros: filtros,
              // arbol1Modulo: (etapa || self.state.selectedParetoMode),
              arbol1Grupo: grupo,
              // arbol1Desviacion: self.state.desviacion,
              // arbol1Nivel: 1,
              arbol1Orden: [(etapa || self.state.selectedParetoMode)]
            }, () => self.loadArbol(1));
          }
        }
      }
    };

    // if(filtrosValues['etiqueta1'][0] != 'PT'){
    //   for(let i = 0; i < state.data.RUTA.length; i++){
    //     state.data.RUTA[i].total = state.data.RUTA[i].estadiaPlanta + state.data.RUTA[i].transitoIda + state.data.RUTA[i].estadiaAgencia;
    //     state.data.RUTA[i].total_benchmark = state.data.RUTA[i].estadiaPlanta_benchmark + state.data.RUTA[i].transitoIda_benchmark + state.data.RUTA[i].estadiaAgencia_benchmark;
    //   }
    // }

    if(self.state.selectedParetoDisplayMode === 'sum'){
      this.props.tatDesglose[dataIndex].sort((a,b) => 
        ((a[valorIndex] - a[benchmark])*a.count_trips > (b[valorIndex] - b[benchmark])*b.count_trips) 
        ? -1 
        : (((b[valorIndex] - b[benchmark])*b.count_trips > a.count_trips*(a[valorIndex] - a[benchmark])) 
          ? 1 
          : 0
        )
      );
    } else if(self.state.selectedParetoDisplayMode === 'desv'){
      this.props.tatDesglose[dataIndex].sort((a,b) => 
        ((a[valorIndex] - a[benchmark]) > (b[valorIndex] - b[benchmark])) 
        ? -1 
        : (((b[valorIndex] - b[benchmark]) > (a[valorIndex] - a[benchmark])) 
          ? 1 
          : 0
        )
      );
    } else{
      this.props.tatDesglose[dataIndex].sort((a,b) => (a[valorIndex] > b[valorIndex]) 
        ? -1 
        : ((b[valorIndex] > a[valorIndex]) 
          ? 1 
          : 0
        )
      );
    }

    let zoomData = [];

    let zoomGroup = JSON.parse(JSON.stringify(data));
    if(self.state.selectedParetoDisplayMode === 'sum'){
      zoomGroup.series[0] = {
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        data: [],
        color: '#008CFF',
        dataLabels:{
          enabled: false
        }
      };
    }
    zoomGroup.series[1].dataLabels = [{
      rotation: 270,
      verticalAlign: 'top',
      y: -25,
      style: {
        color: '#FFFFFF',
        textOutline: '0px',
        fontSize: '14px',
      },
      formatter: function(){
        let localstring = Math.floor(this.y/1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (self.state.selectedParetoDisplayMode === 'sum' ? localstring : (this.y/1).toFixed(legendDecimals));
      },
      enabled: true
    }];
    
    let zoomIndex = 1;
    let aux = 0;

    let total = 0;

    let filtrotemp = this.props.tatDesglose[dataIndex];

    filtrotemp.forEach(x =>{
      if(x[valorIndex] !== null && x[valorIndex] !== undefined) {
        let tempsum = (x[valorIndex] - x[benchmark]) * x.count_trips;
        total += (tempsum < 0 ? 0 : tempsum);
      }
    });
    let pctacum = 0;

    for (let iTAT = 0; iTAT < this.props.tatDesglose[dataIndex].length; iTAT++){
      let agencia = this.props.tatDesglose[dataIndex][iTAT];
        
      if(agencia.avg_tat_real === 0){
        continue;
      }
      data.xAxis.categories.push(agencia.description);
      zoomGroup.xAxis.categories.push(agencia.description);
      // let lastDato = (state.desviacion === 'desviacion' ? agencia[valorIndex] - /*self.state.dataBenchmark[benchmark]*/ : agencia[valorIndex]);
      let lastDato = null;
      if(agencia[valorIndex] !== null && agencia[valorIndex] !== undefined){
        lastDato = (self.state.selectedParetoDisplayMode === 'sum' ? Math.round((agencia[valorIndex] - agencia[benchmark])*agencia.count_trips) : (self.state.selectedParetoDisplayMode === 'desv' ? (agencia[valorIndex] - agencia[benchmark]) : agencia[valorIndex]));
      }
      data.series[1].data.push({
        y: lastDato !== null ? Number((lastDato/60).toFixed(graphDecimals)) : null, 
        name: agencia.id, 
        pct: (lastDato !== null ? (self.state.selectedParetoDisplayMode === 'sum' ? Math.floor(lastDato/60) : null) : null)
      });
      data.series[3].data.push({y: 100, pct: agencia.count_trips});
      zoomGroup.series[1].data.push({
        y: lastDato !== null ? Number((lastDato/60).toFixed(graphDecimals)) : null, 
        name: agencia.id, 
        pct: lastDato !== null ? (self.state.selectedParetoDisplayMode === 'sum' ? Math.floor(lastDato/60) : null) : null
      });
      zoomGroup.series[3].data.push({y: 100, pct: agencia.count_trips});
      if(lastDato > 0){
        data.series[2].data.push(lastDato/**agencia.count_trips*/);
        pctacum += (100*(lastDato/**agencia.count_trips*/)/total);
        zoomGroup.series[0].data.push((pctacum.toFixed(graphDecimals))/1);
      }
      zoomIndex++;
      if(zoomIndex===11){
        zoomIndex = 1;
        zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
        zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
        zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
        zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
        zoomData[aux].series[1].point = data.series[1].point;
        zoomData[aux].tooltip = data.tooltip;
        zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
        if(aux < 2){
          if(aux < 1){
            zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
          }
          zoomData[aux].series[1].dataLabels[0].rotation = 0;
        }else{
          zoomData[aux].series[1].dataLabels[0].rotation = 270;
        }
        aux++;
        // zoomGroup.series[1].data = [];
        // zoomGroup.series[2].data = [];
      }
    }
    zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
    zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
    zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
    zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
    zoomData[aux].series[1].point = data.series[1].point;
    zoomData[aux].tooltip = data.tooltip;
    zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
    if(aux < 2){
      if(aux < 1){
        zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
      }
      zoomData[aux].series[1].dataLabels[0].rotation = 0;
    }else{
      zoomData[aux].series[1].dataLabels[0].rotation = 270;
    }

    if(data.xAxis.categories.length > 20){
      data.chart.width = 70 * data.xAxis.categories.length;
      // data.chart.height = 500;
      data.plotOptions.series.pointWidth = 35;
    }
    let selectedParetoPage = 1;
    if(self.props.isEmail){
      selectedParetoPage = (zoomData[1] 
        ? 2
        : 1);
      self.setState({zoomDataLength: zoomData.length});
    } else {
      selectedParetoPage = (zoomData[self.state.selectedParetoPage-1] 
        ? /* (self.state.selectedParetoPage === 1 /* && !urlvars.hasOwnProperty('tier') 
          ? (zoomData[1] ? 2 : 1) 
          : */ (self.state.selectedParetoPage) 
        : (zoomData[1] ? 2 : 1));
      self.setState({selectedParetoPage, zoomDataLength: zoomData.length});
    }

    this.rutaChart = window.Highcharts.chart("paretoRutaChart".concat(etapa || ""), (self.state.selectedParetoZoomMode === 'all' ? data : zoomData[selectedParetoPage-1]), function(chart) { // on complete
      let min = chart.yAxis[0].min/chart.yAxis[0].max;
      min = min * chart.yAxis[1].max;
      chart.yAxis[1].update({ min: min });
    });
    
    // this.LTCharttest.reflow();
    document.getElementById("paretoRutaChart".concat(etapa || "")).style.overflow = 'auto';
  }
  getParetoRutaTitle = () => {
    switch(this.state.selectedParetoMode) {
      case "estadiaPlanta":
        if(this.state.selectedParetoDisplayMode === "real") return "Estadía en Planta (hr/viaje)";
        else if(this.state.selectedParetoDisplayMode === "sum") return "Desviación Estadía en Planta (hr/viaje)";
        else return "Desviación Estadía en Planta (hr/viaje)";
      case "estadiaAgencia":
        if(this.state.selectedParetoDisplayMode === "real") return "Estadía en Agencia (hr/viaje)";
        else if(this.state.selectedParetoDisplayMode === "sum") return "Desviación Estadía en Agencia (hr/viaje)";
        else return "Desviación Estadía en Agencia (hr/viaje)";
      case "transitoIda":
        if(this.state.selectedParetoDisplayMode === "real") return "Tránsito Ida (hr/viaje)";
        else if(this.state.selectedParetoDisplayMode === "sum") return "Desviación Tránsito Ida (hr/viaje)";
        else return "Desviación Tránsito Ida (hr/viaje)";
      case "transitoVuelta":
        if(this.state.selectedParetoDisplayMode === "real") return "Tránsito Vuelta (hr/viaje)";
        else if(this.state.selectedParetoDisplayMode === "sum") return "Desviación Tránsito Vuelta (hr/viaje)";
        else return "Desviación Tránsito Vuelta (hr/viaje)";
      case "tat":
        if(this.state.selectedParetoDisplayMode === "real") return "TAT Ruta (hr/viaje)";
        else if(this.state.selectedParetoDisplayMode === "sum") return "Desviación TAT Ruta (hr/viaje)";
        else return "Desviación TAT Ruta (hr/viaje)";
      default:
        return ""
    }
  }
  getParetoDate = () => {
    if(this.state.graphStartDate === "" || this.state.graphEndDate === "")
      return ""
    let date1 = this.state.graphStartDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphStartDate.split('-')[1])-1]);
    let date2 = this.state.graphEndDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphEndDate.split('-')[1])-1]);
    if(date1 === date2)
      return date1;
    return date1.concat(' - ', date2);
  }

  //Arbol
  renderTable = (level) => {
    let table = (
      <div className="pareto-table">
        <div className="table-header" style={{marginRight: (this.props["tatArbol".concat(level)]["viajes"] || []).length >= 9 ? "16px" : ""}}>
          {
            this.state["arbol".concat(level, "Grupo")] === "tabla1"
            ? <>
                <div className="Ruta">Ruta</div>
                <div className="Signatura">Signatura</div>
                <div className="F-Ida">Folio Ida</div>
                <div className="F-Vuelta">Folio Vuelta</div>
                <div className="Linea">Línea</div>
                <div className="Camion">Camión</div>
                <div className="Inicio">Inicio Viaje</div>
                <div className="Termino">Término Viaje</div>
                <div className="Meta">TAT Meta</div>
                <div className="Real">TAT Real</div>
                <div className="Desv">Desviación</div>
              </>
            : this.state["arbol".concat(level, "Grupo")] === "tabla2"
            ? <>
                <div class="Ruta">Ruta</div>
                <div class="Signatura-small">Signatura</div>
                <div class="F-Ida-small">Folio Ida</div>
                <div class="F-Vuelta-small">Folio Vuelta</div>
                <div class="Linea-small">Línea</div>
                <div class="Camion-small">Camión</div>
                <div class="Inicio-small">Llegada Planta</div>
                <div class="Termino-small">Salida Planta</div>
                <div class="Cita-small">Cita Carga</div>
                <div class="Puntualidad-small">Punt.</div>
                <div class="Meta-small">Meta Est.</div>
                <div class="Real-small">Est. Real</div>
                <div class="Desv-small">Desviación</div>
              </>
            : this.state["arbol".concat(level, "Grupo")] === "tabla3"
            ? <>
                <div class="Ruta">Ruta</div>
                <div class="Signatura-small">Signatura</div>
                <div class="F-Ida-small">Folio Ida</div>
                <div class="F-Vuelta-small">Folio Vuelta</div>
                <div class="Linea-small">Línea</div>
                <div class="Camion-small">Camión</div>
                <div class="Inicio-small">Llegada Agencia</div>
                <div class="Termino-small">Salida Agencia</div>
                <div class="Cita-small">Cita Desc.</div>
                <div class="Puntualidad-small">Punt.</div>
                <div class="Meta-small">Meta Est.</div>
                <div class="Real-small">Est. Real</div>
                <div class="Desv-small">Desviación</div>
              </>
            :this.state["arbol".concat(level, "Grupo")] === "tabla4"
            ? <>
                <div class="Ruta">Ruta</div>
                <div class="Signatura-small">Signatura</div>
                <div class="F-Ida-small">Folio Ida</div>
                <div class="F-Vuelta-small">Folio Vuelta</div>
                <div class="Linea-small">Línea</div>
                <div class="Camion-small">Camión</div>
                <div class="Inicio-small">Salida Planta</div>
                <div class="Termino-small">Llegada Agencia</div>
                <div class="Cita-small">Cita Desc.</div>
                <div class="Puntualidad-small">Punt.</div>
                <div class="Meta-small">Meta Trán.</div>
                <div class="Real-small">Trán. Real</div>
                <div class="Desv-small">Desviación</div>
              </>
            :this.state["arbol".concat(level, "Grupo")] === "tabla5"
            ? <>
                <div class="Ruta">Ruta</div>
                <div class="Signatura">Signatura</div>
                <div class="F-Ida">Folio Ida</div>
                <div class="F-Vuelta">Folio Vuelta</div>
                <div class="Linea">Línea</div>
                <div class="Camion">Camión</div>
                <div class="Inicio">Salida Agencia</div>
                <div class="Termino">Llegada Planta</div>
                <div class="Meta">Meta Trán.</div>
                <div class="Real">Trán. Real</div>
                <div class="Desv">Desviación</div>
              </>
            : <></>
          }
        </div>
        <div class="list">
          {
            (this.props["tatArbol".concat(level)]["viajes"] || []).sort((a,b) => {
              switch(this.state["arbol".concat(level, "Grupo")]){
                case "tabla1":
                  return (a.tat_real - a.tat_benchmark) > (b.tat_real - b.tat_benchmark) ? -1 : (a.tat_real - a.tat_benchmark) < (b.tat_real - b.tat_benchmark) ? 1 : 0;
                case "tabla2":
                  return (a.o1_estadia_origen_minutos - a.tat_max_estadia_origen_minutos) > (b.o1_estadia_origen_minutos - b.tat_max_estadia_origen_minutos) ? -1 : (a.o1_estadia_origen_minutos - a.tat_max_estadia_origen_minutos) < (b.o1_estadia_origen_minutos - b.tat_max_estadia_origen_minutos) ? 1 : 0;
                case "tabla3":
                  return (a.o1_estadia_estadia_destino - a.tat_max_estadia_destino_minutos) > (b.o1_estadia_estadia_destino - b.tat_max_estadia_destino_minutos) ? -1 : (a.o1_estadia_estadia_destino - a.tat_max_estadia_destino_minutos) < (b.o1_estadia_estadia_destino - b.tat_max_estadia_destino_minutos) ? 1 : 0;
                case "tabla4":
                  return (a.o1_transito_minutos - a.tat_max_transito_ida_minutos) > (b.o1_transito_minutos - b.tat_max_transito_ida_minutos) ? -1 : (a.o1_transito_minutos - a.tat_max_transito_ida_minutos) < (b.o1_transito_minutos - b.tat_max_transito_ida_minutos) ? 1 : 0;
                case "tabla5":
                  return (a.o2_transito_minutos - a.tat_max_transito_vuelta_minutos) > (b.o2_transito_minutos - b.tat_max_transito_vuelta_minutos) ? -1 : (a.o2_transito_minutos - a.tat_max_transito_vuelta_minutos) < (b.o2_transito_minutos - b.tat_max_transito_vuelta_minutos) ? 1 : 0;
                default:
                  return 0;
              }
            }).map(v => {
              let row = <div className="trip">
                {
                  this.state["arbol".concat(level, "Grupo")] === "tabla1"
                  ? <>
                      <div class="Ruta">{v.o1_route_name}</div>
                      <div class="Signatura">{v.t_signature}</div>
                      <div class="F-Ida">{v.o1_code}</div>
                      <div class="F-Vuelta">{v.o2_code}</div>
                      <div class="Linea">{v.t_fleet_provider}</div>
                      <div class="Camion">{v.t_economic_number}</div>
                      <div class="Inicio">{v.o1_llegada_origen_folio}</div>
                      <div class="Termino">{v.o2_llegada_destino_folio_vuelta}</div>
                      <div class="Meta">{((v.tat_benchmark)/60).toFixed(tableDecimals)}</div>
                      <div class="Real">{v.tat_real !== null ? ((v.tat_real)/60).toFixed(tableDecimals) : '-'}</div>
                      <div class="Desv" style={{color: "#".concat(v.tat_real !== null && (v.tat_real - v.tat_benchmark > 0) ? 'e01a1a' : '7aff18')}}>
                        {v.tat_real !== null ? ((v.tat_real - v.tat_benchmark)/60).toFixed(tableDecimals) : '-'}
                      </div>
                    </>
                  : this.state["arbol".concat(level, "Grupo")] === "tabla2"
                  ? <>
                      <div class="Ruta">{v.o1_route_name}</div>
                      <div class="Signatura-small">{v.t_signature}</div>
                      <div class="F-Ida-small">{v.o1_code}</div>
                      <div class="F-Vuelta-small">{v.o2_code}</div>
                      <div class="Linea-small">{v.t_fleet_provider}</div>
                      <div class="Camion-small">{v.t_economic_number}</div>
                      <div class="Inicio-small">{v.o1_llegada_origen_folio}</div>
                      <div class="Termino-small">{v.o1_salida_origen_folio_ida}</div>
                      <div class="Cita-small">{v.o1_first_load_date}</div>
                      <div class="Puntualidad-small">-{/* '+(datos[i].puntualidad_planta ? 'S&iacute' : 'No')+' */}</div>
                      <div class="Meta-small">{((v.tat_max_estadia_origen_minutos)/60).toFixed(tableDecimals)}</div>
                      <div class="Real-small">{v.o1_estadia_origen_minutos !== null ? ((v.o1_estadia_origen_minutos)/60).toFixed(tableDecimals) : '-'}</div>
                      <div class="Desv-small" style={{color:"#".concat(v.o1_estadia_origen_minutos !== null && (v.o1_estadia_origen_minutos - v.tat_max_estadia_origen_minutos > 0) ? 'e01a1a' : '7aff18')}}>
                        {v.o1_estadia_origen_minutos !== null ? ((v.o1_estadia_origen_minutos - v.tat_max_estadia_origen_minutos)/60).toFixed(tableDecimals) : '-'}
                      </div>
                    </>
                  : this.state["arbol".concat(level, "Grupo")] === "tabla3"
                  ? <>
                      <div class="Ruta">{v.o1_route_name}</div>
                      <div class="Signatura-small">{v.t_signature}</div>
                      <div class="F-Ida-small">{v.o1_code}</div>
                      <div class="F-Vuelta-small">{v.o2_code}</div>
                      <div class="Linea-small">{v.t_fleet_provider}</div>
                      <div class="Camion-small">{v.t_economic_number}</div>
                      <div class="Inicio-small">{v.o1_llegada_destino_folio_ida}</div>
                      <div class="Termino-small">{v.o1_salida_destino_folio_ida}</div>
                      <div class="Cita-small">{v.o1_first_unload_date}</div>
                      <div class="Puntualidad-small">-{/* '+(datos[i].puntualidad_agencia ? 'S&iacute' : 'No')+' */}</div>
                      <div class="Meta-small">{((v.tat_max_estadia_destino_minutos)/60).toFixed(tableDecimals)}</div>
                      <div class="Real-small">{v.o1_estadia_estadia_destino !== null ? ((v.o1_estadia_estadia_destino)/60).toFixed(tableDecimals) : '-'}</div>
                      <div class="Desv-small" style={{color:"#".concat(v.o1_estadia_estadia_destino !== null && (v.o1_estadia_estadia_destino - v.tat_max_estadia_destino_minutos) > 0 ? 'e01a1a' : '7aff18')}}>
                        {v.o1_estadia_estadia_destino !== null ? ((v.o1_estadia_estadia_destino - v.tat_max_estadia_destino_minutos)/60).toFixed(tableDecimals) : '-'}
                      </div>
                    </>
                  :this.state["arbol".concat(level, "Grupo")] === "tabla4"
                  ? <>
                      <div class="Ruta">{v.o1_route_name}</div>
                      <div class="Signatura-small">{v.t_signature}</div>
                      <div class="F-Ida-small">{v.o1_code}</div>
                      <div class="F-Vuelta-small">{v.o2_code}</div>
                      <div class="Linea-small">{v.t_fleet_provider}</div>
                      <div class="Camion-small">{v.t_economic_number}</div>
                      <div class="Inicio-small">{v.o1_salida_origen_folio_ida}</div>
                      <div class="Termino-small">{v.o1_llegada_destino_folio_ida}</div>
                      <div class="Cita-small">{v.o1_first_unload_date}</div>
                      <div class="Puntualidad-small">-{/* '+(datos[i].puntualidad_ida ? 'S&iacute' : 'No')+' */}</div>
                      <div class="Meta-small">{((v.tat_max_transito_ida_minutos)/60).toFixed(tableDecimals)}</div>
                      <div class="Real-small">{v.o1_transito_minutos !== null ? ((v.o1_transito_minutos)/60).toFixed(tableDecimals) : '-'}</div>
                      <div class="Desv-small" style={{color:"#".concat(v.o1_transito_minutos !== null && (v.o1_transito_minutos - v.tat_max_transito_ida_minutos) > 0 ? 'e01a1a' : '7aff18')}}>
                        {v.o1_transito_minutos !== null ? ((v.o1_transito_minutos - v.tat_max_transito_ida_minutos)/60).toFixed(tableDecimals) : '-'}
                      </div>
                    </>
                  :this.state["arbol".concat(level, "Grupo")] === "tabla5"
                  ? <>
                      <div class="Ruta">{v.o1_route_name}</div>
                      <div class="Signatura">{v.t_signature}</div>
                      <div class="F-Ida">{v.o1_code}</div>
                      <div class="F-Vuelta">{v.o2_code}</div>
                      <div class="Linea">{v.t_fleet_provider}</div>
                      <div class="Camion">{v.t_economic_number}</div>
                      <div class="Inicio">{v.o1_salida_destino_folio_ida}</div>
                      <div class="Termino">{v.o2_llegada_destino_folio_vuelta}</div>
                      <div class="Meta">{((v.tat_max_transito_vuelta_minutos)/60).toFixed(tableDecimals)}</div>
                      <div class="Real">{v.o2_transito_minutos !== null ? ((v.o2_transito_minutos)/60).toFixed(tableDecimals) : '-'}</div>
                      <div class="Desv" style={{color:"#".concat(v.o2_transito_minutos !== null && (v.o2_transito_minutos - v.tat_max_transito_vuelta_minutos) > 0 ? 'e01a1a' : '7aff18')}}>
                        {v.o2_transito_minutos !== null ? ((v.o2_transito_minutos - v.tat_max_transito_vuelta_minutos)/60).toFixed(tableDecimals) : '-'}
                      </div>
                    </>
                  : <></>
                }
              </div>
              return row;
            })
          }
        </div>
      </div>
    )
    // document.getElementById("paretoRutaArbol".concat(level, "Chart")).innerHTML = table;
    return table;
  }

  graphArbol = (level) => {
    // debugger;
    if(!this.props["tatArbol".concat(level)]["criterio"]) return null;
    if(this.state["arbol".concat(level, "Grupo")].includes("tabla")){
      document.getElementById("paretoRutaArbol".concat(level, "Chart")).innerHTML = "";
      // this.renderTable(level);
      return null;
    } 
    const self = this;
    let width = null;
    let data = JSON.parse(JSON.stringify(DefaultChart));
    data.chart.backgroundColor = 'transparent';
    data.chart.width = width;
    data.chart.height = 500;
    delete data.plotOptions.series.dataLabels.formatter;
    // data.legend = { enabled: false };
    data.legend = {
        itemStyle: {
            color: '#fff',
            fontSize: '15px'
        }
    };
    data.plotOptions.series.dataLabels = {enabled: false};

    data.tooltip = {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          // eslint-disable-next-line
          return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': ' + (point.point.pct ? point.point.pct : (point.y.toFixed(graphDecimals)));
        }, '<b>' + this.x + '</b>');
      },
    }
    
    data.xAxis = {
      categories: [],
      crosshair: true,
      lineColor: '#535661',
      tickLength: 0,
      labels: {
        //autoRotation: [-45],
            style: {
                color: 'white'
            }
        }
    };
    data.yAxis =  [{
      title: {
          text: ''
      },
      maxPadding: 0.4,
      lineColor: '#303349',
      gridLineColor: '#666666',
      gridLineWidth: 0,
      opposite: true,
      startOnTick: false,
      endOnTick: false,
      labels: {
          enabled: false
      }
    },{
      title: {
            text: ''
        },
        min: 0,
        max: 100,
        gridLineColor: '#666666',
        gridLineWidth: (self.state.selectedParetoDisplayMode === 'sum' ? 1 : 0),
        startOnTick: false,
        endOnTick: false,
        opposite: false,
        plotLines: [{
            color: '#fff',
            width: 2,
            value: 0,
            zIndex: 3
        }],
        labels: {
            formatter: function(){
              return Math.floor(this.value/1) + '%';
            },
            enabled: (self.state.selectedParetoDisplayMode  === 'sum' ? true : false)
        }
    }];
    // data.yAxis[1].labels

    data.series = [];
    // if(state.mode !== 'tat'){
    let serie = 0;
    // let dataIndex = 'agencia'; //AGENCIA IDA PLANTA VUELTA
    let valorIndex = 'avg_order1_estadia_estadia_destino'; //estadiaAgencia transitoIda estadiaPlanta transitoVuelta
    let benchmark = 'avg_tat_max_estadia_destino_minutos';
    switch(this.state.selectedParetoMode){
      case 'estadiaPlanta':
        serie = (/* filtrosValues['etiqueta1'][0] === 'Vidrio' ? 6 : */ 0);
        // dataIndex = 'planta';
        valorIndex = 'avg_order1_estadia_origen_minutos';
        benchmark = 'avg_tat_max_estadia_origen_minutos';
        break;
      case 'transitoIda':
        serie = 1;
        // dataIndex = (self.state.selectedParetoTransitMode === 'lt' ? 'lt' : 'ruta');
        valorIndex = 'avg_order1_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_ida_minutos';
        break;
      case 'estadiaAgencia':
        serie = (/* filtrosValues['etiqueta1'][0] === 'Vidrio' ? 7 : */ 2);
        // dataIndex = (/* self.state.agencia === 'drv' ? 'DRVS' : (self.state.agencia === 'uen' ? 'UENS' :  */'agencia')/* ) */;
        valorIndex = 'avg_order1_estadia_estadia_destino';
        benchmark = 'avg_tat_max_estadia_destino_minutos';
        break;
      case 'transitoVuelta':
        serie = 3;
        // dataIndex = (self.state.selectedParetoTransitMode === 'lt' ? 'lt' : 'ruta');
        valorIndex = 'avg_order2_estadia_transito_minutos';
        benchmark = 'avg_tat_max_transito_vuelta_minutos';
        break;
      case 'tat':
        serie = 4;
        // dataIndex = 'ruta';
        valorIndex = 'avg_tat_real';
        benchmark = 'avg_tat_benchmark';
        break;
      default:

    }
    if(self.state.selectedParetoDisplayMode  === 'sum'){
      data.series.push({
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 2,
        color: '#008CFF',
        dataLabels:{
          enabled: false
        }
      });
    }
    else{
      data.series.push({
        name: 'Total1',
        data: [],
        showInLegend: false,
        stack: 0,
        color: '#00ffff',
        borderWidth: 0,
        visible: false
      });
    }
    data.series.push(cloneDeep(DefaultChart.series[serie]));
    data.series.push({
      name: 'Total',
      showInLegend: false,
      data: [],
      stack: 0,
      color: '#00ffff',
      borderWidth: 0,
      visible: false
    });
    data.series.push({
      name: 'Viajes',
      type: 'spline',
      legendIndex:5,
      yAxis: 1,
      zIndex: 11,
      lineWidth: 0,
      color: '#f7a35c',
      marker: {
        enabled: true,
        symbol: 'diamond',
        radius: 7
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          color: '#FFFFFF',
        },
        formatter: function(){
          return this.point.pct;
        }
      },
      data: []
    });
    data.series[1].data = [];
    data.series[1].dataLabels = [{
      rotation: 270,
      verticalAlign: 'top',
      y: -25,
      style: {
        color: '#FFFFFF',
        textOutline: '0px',
        fontSize: '14px',
      },
      formatter: function(){
        let localstring = Math.floor(this.y/1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (self.state.selectedParetoDisplayMode === 'sum' ? localstring : (this.y/1).toFixed(legendDecimals));
      },
      enabled: true
    }];
    data.series[1].point = {
      events: {
        click: function () {
          // let filtros = cloneDeep(self.state["arbol".concat(level, "Filtros")]);
          let filtros = [];
          for(let i in self.state["arbol".concat(level, "Filtros")]) {
            filtros[i] = self.state["arbol".concat(level, "Filtros")][i];
          }
          filtros[self.state["arbol".concat(level, "Grupo")]] = this.name;
          let orden = [];
          self.state["arbol".concat(level, "Orden")].forEach(x => orden.push(x));
          orden.push(self.state["arbol".concat(level, "Grupo")]);
          let nivel = level + 1;
          let temp = {};
          orden.forEach(o => {
            if((Object.keys(temp)).length){
              temp = temp[o];
            } else {
              temp = self.getArbolByCompanyType()[o];
            }
          })
          let grupo = Object.keys(temp)[0];
          if(grupo.includes("tabla")){
            return null;
          }
          // debugger;
          self.setState({
            ["showParetoArbol".concat(nivel)]: true,
            ["arbol".concat(nivel, "Criterio")]: this.category,
            ["arbol".concat(nivel,"Filtros")]: filtros,
            ["arbol".concat(nivel, "Grupo")]: grupo,
            ["arbol".concat(nivel, "Orden")]: orden
          }, () => self.loadArbol(nivel));
        }
      }
    };

    // if(filtrosValues['etiqueta1'][0] != 'PT'){
    //   for(let i = 0; i < state.data.RUTA.length; i++){
    //     state.data.RUTA[i].total = state.data.RUTA[i].estadiaPlanta + state.data.RUTA[i].transitoIda + state.data.RUTA[i].estadiaAgencia;
    //     state.data.RUTA[i].total_benchmark = state.data.RUTA[i].estadiaPlanta_benchmark + state.data.RUTA[i].transitoIda_benchmark + state.data.RUTA[i].estadiaAgencia_benchmark;
    //   }
    // }

    if(self.state.selectedParetoDisplayMode === 'sum'){
      this.props["tatArbol".concat(level)]["criterio"].sort((a,b) => 
        ((a[valorIndex] - a[benchmark])*a.count_trips > (b[valorIndex] - b[benchmark])*b.count_trips) 
        ? -1 
        : (((b[valorIndex] - b[benchmark])*b.count_trips > a.count_trips*(a[valorIndex] - a[benchmark])) 
          ? 1 
          : 0
        )
      );
    } else if(self.state.selectedParetoDisplayMode === 'desv'){
      this.props["tatArbol".concat(level)]["criterio"].sort((a,b) => 
        ((a[valorIndex] - a[benchmark]) > (b[valorIndex] - b[benchmark])) 
        ? -1 
        : (((b[valorIndex] - b[benchmark]) > (a[valorIndex] - a[benchmark])) 
          ? 1 
          : 0
        )
      );
    } else{
      this.props["tatArbol".concat(level)]["criterio"].sort((a,b) => (a[valorIndex] > b[valorIndex]) 
        ? -1 
        : ((b[valorIndex] > a[valorIndex]) 
          ? 1 
          : 0
        )
      );
    }

    let zoomData = [];

    let zoomGroup = JSON.parse(JSON.stringify(data));
    if(self.state.selectedParetoDisplayMode === 'sum'){
      zoomGroup.series[0] = {
        type: 'pareto',
        name: '% Acum.',
        yAxis: 1,
        zIndex: 10,
        data: [],
        color: '#008CFF',
        dataLabels:{
          enabled: false
        }
      };
    }
    zoomGroup.series[1].dataLabels = [{
      rotation: 270,
      verticalAlign: 'top',
      y: -25,
      style: {
        color: '#FFFFFF',
        textOutline: '0px',
        fontSize: '14px',
      },
      formatter: function(){
        let localstring = Math.floor(this.y/1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return (self.state.selectedParetoDisplayMode === 'sum' ? localstring : (this.y/1).toFixed(legendDecimals));
      },
      enabled: true
    }];
    
    let zoomIndex = 1;
    let aux = 0;

    let total = 0;

    let filtrotemp = this.props["tatArbol".concat(level)]["criterio"];

    filtrotemp.forEach(x =>{
      if(x[valorIndex] !== null){
        let tempsum = (x[valorIndex] - x[benchmark]) * x.count_trips;
        total += (tempsum < 0 ? 0 : tempsum);
      }
    });
    let pctacum = 0;

    for (let iTAT = 0; iTAT < this.props["tatArbol".concat(level)]["criterio"].length; iTAT++){
      let agencia = this.props["tatArbol".concat(level)]["criterio"][iTAT];
        
      if(agencia.avg_tat_real === 0){
        continue;
      }
      data.xAxis.categories.push(agencia.description);
      zoomGroup.xAxis.categories.push(agencia.description);
      let lastDato = null;
      if(agencia[valorIndex] !== null && agencia[valorIndex] !== undefined){
        lastDato = (self.state.selectedParetoDisplayMode === 'sum' ? Math.round((agencia[valorIndex] - agencia[benchmark])*agencia.count_trips) : (self.state.selectedParetoDisplayMode === 'desv' ? (agencia[valorIndex] - agencia[benchmark]) : agencia[valorIndex]));
      }
      data.series[1].data.push({
        y: lastDato !== null ? Number((lastDato/60).toFixed(graphDecimals)) : null, 
        name: agencia.id, 
        pct: lastDato !== null && self.state.selectedParetoDisplayMode === 'sum' ? Math.floor(lastDato/60) : null
      });
      data.series[3].data.push({y: 100, pct: agencia.count_trips});
      zoomGroup.series[1].data.push({
        y: lastDato !== null ? Number((lastDato/60).toFixed(graphDecimals)) : null, 
        name: agencia.id, 
        pct: lastDato !== null && self.state.selectedParetoDisplayMode === 'sum' ? Math.floor(lastDato/60) : null
      });
      zoomGroup.series[3].data.push({y: 100, pct: agencia.count_trips});
      if(lastDato > 0){
        data.series[2].data.push(lastDato/**agencia.count_trips*/);
        pctacum += (100*(lastDato/**agencia.count_trips*/)/total);
        zoomGroup.series[0].data.push((pctacum.toFixed(graphDecimals))/1);
      }
      zoomIndex++;
      if(zoomIndex===11){
        zoomIndex = 1;
        zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
        zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
        zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
        zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
        zoomData[aux].series[1].point = data.series[1].point;
        zoomData[aux].tooltip = data.tooltip;
        zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
        if(aux < 2){
          if(aux < 1){
            zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
          }
          zoomData[aux].series[1].dataLabels[0].rotation = 0;
        }else{
          zoomData[aux].series[1].dataLabels[0].rotation = 270;
        }
        aux++;
      }
    }
    zoomData.push(JSON.parse(JSON.stringify(zoomGroup)));
    zoomData[aux].series[1].dataLabels = JSON.parse(JSON.stringify(zoomGroup.series[1].dataLabels));
    zoomData[aux].series[1].dataLabels[0].formatter = zoomGroup.series[1].dataLabels[0].formatter;
    zoomData[aux].series[3].dataLabels = data.series[3].dataLabels;
    zoomData[aux].series[1].point = data.series[1].point;
    zoomData[aux].tooltip = data.tooltip;
    zoomData[aux].yAxis[1].labels = data.yAxis[1].labels;
    if(aux < 2){
      if(aux < 1){
        zoomData[aux].series[1].dataLabels[0].style.fontSize = '18px';
      }
      zoomData[aux].series[1].dataLabels[0].rotation = 0;
    }else{
      zoomData[aux].series[1].dataLabels[0].rotation = 270;
    }

    if(data.xAxis.categories.length > 20){
      data.chart.width = 70 * data.xAxis.categories.length;
      // data.chart.height = 500;
      data.plotOptions.series.pointWidth = 35;
    }

    this.rutaChart = window.Highcharts.chart("paretoRutaArbol".concat(level, "Chart"), data, function(chart) { // on complete
      let min = chart.yAxis[0].min/chart.yAxis[0].max;
      min = min * chart.yAxis[1].max;
      chart.yAxis[1].update({ min: min });
    });
    
    // this.LTCharttest.reflow();
    document.getElementById("paretoRutaArbol".concat(level, "Chart")).style.overflow = 'auto';
  }
  getArbolFilters = (level = 0) => {
    // const self = this;
    if(!level) return []
    let temp = {};
    this.state["arbol".concat(level, "Orden")].forEach(o => {
      if((Object.keys(temp)).length){
        temp = temp[o];
      } else {
        temp = this.getArbolByCompanyType()[o];
      }
    })
    const self = this;
    let f = []
    if((Object.keys(temp)).length > 1){
      for(let i in temp){
        f.push(
          <li className={"pantalla ".concat(
            self.state.selectedParetoMode,
            i === this.state["arbol".concat(level, "Grupo")] ? " selected" : "",
          )}
            onClick={() => {
              this.setState({["arbol".concat(level, "Grupo")]: i}, () => this.loadArbol(level));
            }}  
          >
            {
              i.includes('tabla') 
              ? "Folios"
              : i === "drv" 
                ? "DRVs"
                : i === "uen"
                  ? "UENs"
                  : i.charAt(0).toUpperCase() + i.slice(1)
            }
            <div></div>
          </li>
        );
      }
    }
    return f;
  }
  hideTrees = () => {
    this.setState({
      showParetoArbol1: false,
      showParetoArbol2: false,
      showParetoArbol3: false,
      showParetoArbol4: false,
      showParetoArbol5: false,
    })
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  openDocument = (type) => {
    let downloadUrl = this.getUrl(type);
    Axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "TAT.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  }

  getExtraModules = () => {
    return <>
      {
        this.getEtapaPanel("tat")
      }
      {
        this.getEtapaPanel("estadiaPlanta")
      }
      {
        this.getEtapaPanel("transitoIda")
      }
      {
        this.getEtapaPanel("estadiaAgencia")
      }
      {
        this.getEtapaPanel("transitoVuelta")
      }
    </>
  }

  getEtapaPanel = (etapa) => {
    return (
      <div id="paretoRutaPanel" className="dashboard-panel dashboard-TAT-Month ">
        <div className="panel-title etapas">
          <b >{
            etapa === "tat"
            ? "Desviación TAT Ruta (hr)"
            : etapa === "estadiaPlanta"
            ? "Desviación Estadía en Planta (hr)"
            : etapa === "transitoIda"
            ? "Desviación Tránsito Ida (hr)"
            : etapa === "estadiaAgencia"
            ? "Desviación Estadía en Agencia (hr)"
            : "Desviación Tránsito Vuelta (hr)"
          }</b>
          <span >
            {this.getParetoDate()}
          </span>
        </div>
        <ul className="filtros">
          <div className="desviacion" style={{display:etapa.includes("transito") ? "" : "none"}}>
            <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "lt" ? " selected ": "", " ", etapa)} onClick={() => this.setState({selectedParetoTransitMode: "lt"}, this.graphPareto)}>LTs<div></div></li>
            <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "ruta" ? " selected ": "", " ", etapa)} onClick={() => this.setState({selectedParetoTransitMode: "ruta"}, this.graphPareto)}>Rutas<div></div></li>
          </div>
          <div className="desviacion" var="filtroDRV" style={{display: etapa === "estadiaAgencia" ? "" : "none"}}>
            <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "drv" ? " selected ": "", " ", etapa)} onClick={() => this.setState({selectedParetoAgenciaMode: "drv"}, this.graphPareto)}>DRVs<div></div></li>
            <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "uen" ? " selected ": "", " ", etapa)} onClick={() => this.setState({selectedParetoAgenciaMode: "uen"}, this.graphPareto)} style={{borderRadius: "0px"}}>UENs<div></div></li>
            <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "agencia" ? " selected ": "", " ", etapa)} onClick={() => this.setState({selectedParetoAgenciaMode: "agencia"}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>Agencias<div></div></li>
          </div>
          <div className="desviacion" style={{display:"none"}}>
            <li className="desviacion selected" var="top.todos">Todos<div></div></li>
            <li className="desviacion" var="top.top">Top<div></div></li>
          </div>
          <div className="desviacion zoom">
            <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "all" ? " selected" : "", " ", etapa)} onClick={() => this.setState({selectedParetoZoomMode: "all"}, this.graphPareto)}>
              Todos<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "zoom" ? " selected" : "", " ", etapa)} onClick={() => this.setState({selectedParetoZoomMode: "zoom"}, this.graphPareto)} style={{zIndex: "10"}}>
              Acotado<div></div>
            </li>
            <div className={"navigate".concat(" ", etapa)} var="nav" style={{display: this.state.selectedParetoZoomMode === "zoom" ? "" : "none"}}>
              <span var="prev" className="prev" onClick={() => {if(this.state.selectedParetoPage > 1)this.setState({selectedParetoPage: this.state.selectedParetoPage -1}, this.graphPareto)}}>
                &#8211;
              </span>
              <span var="selectedParetoPage" className="pag">{this.state.selectedParetoPage*10}</span> 
              <span var="next" className="prev" onClick={() => {if(this.state.selectedParetoPage < this.state.zoomDataLength)this.setState({selectedParetoPage: this.state.selectedParetoPage +1}, this.graphPareto)}}>
                +
              </span>
            </div>
          </div>
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "real" ? " selected" : "", " ", etapa)} onClick={() => this.setState({selectedParetoDisplayMode: "real"}, this.graphPareto)}>
              TAT<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "desv" ? " selected" : "", " ", etapa)} onClick={() => this.setState({selectedParetoDisplayMode: "desv"}, this.graphPareto)} style={{borderRadius: "0px"}}>
              Desv./Viaje<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "sum" ? " selected" : "", " ", etapa)} onClick={() => this.setState({selectedParetoDisplayMode: "sum"}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>
              Desv.<div></div>
            </li>
          </div>
          <div className="desviacion etapas">
            <li var="globalback" className="global-back" style={{display: etapa === "tat" ? "" : "none"}}></li>
            <li var="global" className={"global".concat(etapa === "tat" ? " etapa-selected" : "")} onClick={() => this.setState({selectedParetoMode: "tat"}, this.graphPareto)}>
              <img alt="" src={etapa === "tat" ? "/images/tat-global-tenue.svg" : "/images/tat-global.svg"} />
            </li>
            <li var="planta" className={"planta".concat(etapa === "estadiaPlanta" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "estadiaPlanta"}, this.graphPareto)}}>
              <TATFactoryTransparent />
            </li>
            <li var="ida" className={"ida".concat(etapa === "transitoIda" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "transitoIda"}, this.graphPareto)}}>
              <TATTruck /> 
            </li>
            <li var="agencia" className={"agencia".concat(etapa === "estadiaAgencia" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "estadiaAgencia"}, this.graphPareto)}}>
              <TATPlantaTransparent /> 
            </li>
            <li var="vuelta" className={"vuelta".concat(etapa === "transitoVuelta" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "transitoVuelta"}, this.graphPareto)}}>
              <TATTruck /> 
            </li>
          </div>
        </ul>
        <div var="content" className="panel-content rutas">
          <div className="charts">
            <div className="paretoRuta" >
              <b className="loader" style={{display: "none"}}><Loader /></b>
              <div id={"paretoRutaChart".concat(etapa)}></div>
            </div>
          </div>
        </div>
        {/* <DownloadImage
          fill={"#FFFFFF"}
          width={"50px"}
          height={"50px"}
          className="download-icon"
          onClick={(e) => {e.stopPropagation(); this.openDocument("global")}}
        /> */}
      </div>
    )
  }

  presentBlurView = (count, isLoading) => {
    return( (count === 0 || isLoading) ?
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'white', opacity: 0.7, position: 'absolute', top: 0, left: 0, fontWeight: 'bold', fontSize: 20, backdropFilter: 'blur(3px)', borderRadius: 7, zIndex: 1}}>
        { isLoading || !this.state.firstLoaded ? '' : 'No hay datos que mostrar' }
      </div> : <div/> 
    );
  }

  presentLoader = () => {
    return(
      <div style={{height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#2F3B52', borderRadius: 7, marginTop: 20, color: 'white'}}>
        {'Cargando'}
        <div style={{width: 8}}/>
        <Loader />
      </div>
    );
  }

  getDesvTitleFilter = () => {
    if (this.state.selectedParetoMode === 'estadiaPlanta' || this.state.selectedParetoMode === 'estadiaAgencia') {
      return 'ATCT'
    } else if (this.state.selectedParetoMode === 'transitoIda' || this.state.selectedParetoMode === 'transitoVuelta') {
      return 'Tránsito'
    } else {
      return 'TAT'
    }
  }

  render() {
    let curMonth = this.state.filterDate[0].getMonth() + 1;
    let mtm = (this.props.tatGlobal?.mtm || []).filter(item => parseInt(item.id) === curMonth)[0] || {};
    let dtd = (this.props.tatGlobal?.dtd || []).find(d => d.id === "".concat(
      this.state.filterDate[0].getFullYear(), 
      '-', 
      this.digit2(this.state.filterDate[0].getMonth() + 1), 
      '-', 
      this.digit2(this.state.filterDate[0].getDate())
    )) || {};
    return (
      <View className="tat-view">
        <div className="tat-header">
          <FilterBar
            searchAction={this.handleTextSearch}
            filterAction={this.handleFilter}
            searchStr={this.state.searchStr}
            onChange={this.handleFilterChange}
            searchButtonText={this.state.buttonFilterName}
            content={this.filterContents()}
            textSearch= {false}
            selectedItems={this.getSelectedItems()}
          />
          <div className="refresh-button">
            <ClockAlert />
            <span>{this.state.refreshLabel}</span>
          </div>
        </div>
        <div className="tat-content">

          {
            (this.props.isLoadingTat || !this.state.firstLoaded) && this.presentLoader()
          }

          <div className="dashboard-panel dashboard-stats">
            <div className="panel-title">
              {this.getMonthStatsTitle()}
            </div>
            <div className="panel-content">
              <canvas width="260" height="260" id="canvas-month"></canvas>
              <div className="total"><b>{ (mtm.avg_tat_real || 0) === 0 ? '-' : mtm && (((mtm.avg_tat_real || 0) / 60 ).toFixed(1))}</b>hr</div>
              <div className="stats">
                <div className="icons">
                  <b className="label"></b>
                  <b className="icon" ><TATFactory /> </b>
                  <b className="icon transitoIda"><TATTruck /> </b>
                  <b className="icon" ><TATPlant /> </b>
                  <b className="icon transitoVuelta"><TATTruck /> </b>
                </div>
                <div className="data real">
                  <b className="label">Real<br/>(hr)</b>
                  <b className="real" >{(mtm.avg_order1_estadia_origen_minutos || 0) === 0 ? '-' : ((mtm.avg_order1_estadia_origen_minutos || 0)/60 ).toFixed(1)}</b>
                  <b className="real" >{(mtm.avg_order1_estadia_transito_minutos || 0) === 0 ? '-' : ((mtm.avg_order1_estadia_transito_minutos || 0)/60 ).toFixed(1)}</b>
                  <b className="real">{(mtm.avg_order1_estadia_estadia_destino || 0) === 0 ? '-' : ((mtm.avg_order1_estadia_estadia_destino || 0)/60 ).toFixed(1)}</b>
                  <b className="real">{(mtm.avg_order2_estadia_transito_minutos || 0) === 0 ? '-' : ((mtm.avg_order2_estadia_transito_minutos || 0)/60 ).toFixed(1)}</b>
                </div>
                <div className="data">
                  <b className="label">Desviación<br/>(hr/viaje)</b>
                  <b className={"deviation".concat(((mtm.avg_order1_estadia_origen_minutos || 0) - (mtm.avg_tat_max_estadia_origen_minutos || 0)) > 0 ? " alert" : "")}>
                    {((mtm.avg_order1_estadia_origen_minutos || 0) === 0 ) ? '-' : (Math.abs((mtm.avg_order1_estadia_origen_minutos || 0) - (mtm.avg_tat_max_estadia_origen_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((mtm.avg_order1_estadia_transito_minutos || 0) - (mtm.avg_tat_max_transito_ida_minutos || 0)) > 0 ? " alert" : "")}>
                    {((mtm.avg_order1_estadia_transito_minutos || 0) === 0 ) ? '-' : (Math.abs((mtm.avg_order1_estadia_transito_minutos || 0) - (mtm.avg_tat_max_transito_ida_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((mtm.avg_order1_estadia_estadia_destino || 0) - (mtm.avg_tat_max_estadia_destino_minutos || 0)) > 0 ? " alert" : "")}>
                    {((mtm.avg_order1_estadia_estadia_destino || 0) === 0 ) ? '-' : (Math.abs((mtm.avg_order1_estadia_estadia_destino || 0) - (mtm.avg_tat_max_estadia_destino_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((mtm.avg_order2_estadia_transito_minutos || 0) - (mtm.avg_tat_max_transito_vuelta_minutos || 0)) > 0 ? " alert" : "")}>
                    {((mtm.avg_order2_estadia_transito_minutos || 0) === 0 ) ? '-' : (Math.abs((mtm.avg_order2_estadia_transito_minutos || 0) - (mtm.avg_tat_max_transito_vuelta_minutos || 0))/60 ).toFixed(1)}
                  </b>
                </div>
              </div>
              <b className="loader" style={{display: "none"}}><Loader /></b>
            </div>
            { this.presentBlurView(this.props.tatCount, this.props.isLoadingTat) }
          </div>
          
          <div className="dashboard-panel dashboard-stats">
            <div className="panel-title">
              {this.getDayStatsTitle()}
            </div>
            <div className="panel-content">
              <canvas width="260" height="260" id="canvas-day"></canvas>
              <div className="total"><b>{(dtd.avg_tat_real || 0) === 0 ? '-' : (((dtd && dtd.avg_tat_real) || 0)/60).toFixed(1)}</b>hr</div>
              <div className="stats">
                <div className="icons">
                  <b className="label"></b>
                  <b className="icon" ><TATFactory /> </b>
                  <b className="icon transitoIda"><TATTruck /> </b>
                  <b className="icon" ><TATPlant /> </b>
                  <b className="icon transitoVuelta"><TATTruck /> </b>
                </div>
                <div className="data real">
                  <b className="label">Real<br/>(hr)</b>
                  <b className="real" >{(dtd.avg_order1_estadia_origen_minutos || 0) === 0 ? '-' : ((dtd.avg_order1_estadia_origen_minutos || 0)/60 ).toFixed(1)}</b>
                  <b className="real" >{(dtd.avg_order1_estadia_transito_minutos || 0) === 0 ? '-' : ((dtd.avg_order1_estadia_transito_minutos || 0)/60 ).toFixed(1)}</b>
                  <b className="real">{(dtd.avg_order1_estadia_estadia_destino || 0) === 0 ? '-' : ((dtd.avg_order1_estadia_estadia_destino || 0)/60 ).toFixed(1)}</b>
                  <b className="real">{(dtd.avg_order2_estadia_transito_minutos || 0) === 0 ? '-' : ((dtd.avg_order2_estadia_transito_minutos || 0)/60 ).toFixed(1)}</b>
                </div>
                <div className="data">
                  <b className="label">Desviación<br/>(hr/viaje)</b>
                  <b className={"deviation".concat(((dtd.avg_order1_estadia_origen_minutos || 0) - (dtd.avg_tat_max_estadia_origen_minutos || 0)) > 0 ? " alert" : "")}>
                    {((dtd.avg_order1_estadia_origen_minutos || 0) === 0)  ? '-' : (Math.abs((dtd.avg_order1_estadia_origen_minutos || 0) - (dtd.avg_tat_max_estadia_origen_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((dtd.avg_order1_estadia_transito_minutos || 0) - (dtd.avg_tat_max_transito_ida_minutos || 0)) > 0 ? " alert" : "")}>
                    {((dtd.avg_order1_estadia_transito_minutos || 0) === 0)  ? '-' : (Math.abs((dtd.avg_order1_estadia_transito_minutos || 0) - (dtd.avg_tat_max_transito_ida_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((dtd.avg_order1_estadia_estadia_destino || 0) - (dtd.avg_tat_max_estadia_destino_minutos || 0)) > 0 ? " alert" : "")}>
                    {((dtd.avg_order1_estadia_estadia_destino || 0) === 0)  ? '-' : (Math.abs((dtd.avg_order1_estadia_estadia_destino || 0) - (dtd.avg_tat_max_estadia_destino_minutos || 0))/60 ).toFixed(1)}
                  </b>
                  <b className={"deviation".concat(((dtd.avg_order2_estadia_transito_minutos || 0) - (dtd.avg_tat_max_transito_vuelta_minutos || 0)) > 0 ? " alert" : "")}>
                    {((dtd.avg_order2_estadia_transito_minutos || 0) === 0)  ? '-' : (Math.abs((dtd.avg_order2_estadia_transito_minutos || 0) - (dtd.avg_tat_max_transito_vuelta_minutos || 0))/60 ).toFixed(1)}
                  </b>
                </div>
              </div>
              <b className="loader" style={{display: "none"}}><Loader /></b>
            </div>
            { this.presentBlurView(this.props.tatCount, this.props.isLoadingTat) }
          </div>
          
          <div className="dashboard-panel dashboard-TAT-Month">
            <div className="panel-title tat">{this.getDailyTitle()}</div>
            <ul className="filtros">
              <div className="desviacion etapas">
                <li className="global-back" style={{display: this.state.selectedDailyMode === "tat" ? "" : "none"}}></li>
                <li className={"global".concat(this.state.selectedDailyMode === "tat" ? " etapa-selected" : "")} onClick={() => this.setState({selectedDailyMode: "tat"}, this.graphDaily)}>
                  <img alt="" src={this.state.selectedDailyMode === "tat" ? "/images/tat-global-tenue.svg" : "/images/tat-global.svg"} />
                </li>
                <li className={"planta".concat(this.state.selectedDailyMode === "estadiaPlanta" ? " etapa-selected" : "")} onClick={() => this.setState({selectedDailyMode: "estadiaPlanta"}, this.graphDaily)}>
                  <TATFactoryTransparent />
                </li>
                <li className={"ida".concat(this.state.selectedDailyMode === "transitoIda" ? " etapa-selected" : "")} onClick={() => this.setState({selectedDailyMode: "transitoIda"}, this.graphDaily)}>
                  <TATTruck /> 
                </li>
                <li className={"agencia".concat(this.state.selectedDailyMode === "estadiaAgencia" ? " etapa-selected" : "")} onClick={() => this.setState({selectedDailyMode: "estadiaAgencia"}, this.graphDaily)}>
                  <TATPlantaTransparent /> 
                </li>
                <li className={"vuelta".concat(this.state.selectedDailyMode === "transitoVuelta" ? " etapa-selected" : "")} onClick={() => this.setState({selectedDailyMode: "transitoVuelta"}, this.graphDaily)}>
                  <TATTruck /> 
                </li>
              </div>
            </ul>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              <ul style={{marginTop: "20px"}}>
                {
                  months_abbr.map((m, i) => {
                    let current = new Date();
                    return <li
                      className={(this.state.selectedMonth === i ? 'selected' : '').concat((i <= current.getMonth() || this.state.filterDate[0].getFullYear() < current.getFullYear()) ? '' : ' disabled')}
                      onClick={() => {
                        this.setState({selectedMonth: i}, this.graphDaily);
                      }}
                    >{m}</li>
                  })
                }
              </ul>
              <div className="charts">
                <div className="month" >
                  <b className="loader" style={{display: "none"}}><Loader /></b>
                  <div id="monthChart"></div>
                </div>
              </div>
            </div>
            { this.presentBlurView(this.props.tatCount, this.props.isLoadingTat) }
          </div>
          
          <div className="dashboard-panel dashboard-TAT-Month">
            <div className="panel-title tat">TAT Mensual</div>
            <div className="panel-content">
              <div className="charts">
                <div className="yearSummary">
                  <b className="loader" style={{display: "none"}}><Loader /></b>
                  <div id="yearSummaryChart"></div>
                </div>
              </div>
            </div>
            { this.presentBlurView(this.props.tatCount, this.props.isLoadingTat) }
          </div>
        
          {
            this.props.isLoadingDesglose && this.presentLoader()
          }
          
          <div id="paretoRutaPanel" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoRutas ? "" : "none"}}>
            <div className="panel-title etapas">
              <b >{this.getParetoRutaTitle()}</b>
              <span >
                {this.getParetoDate()}
              </span>
            </div>
            <ul className="filtros">
              <div className="desviacion" style={{display:this.state.selectedParetoMode.includes("transito") ? "" : "none"}}>
                {
                  this.props.companyType === 1 &&
                  <>
                    <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "operador" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoTransitMode: "operador"}, this.graphPareto)}>Operador<div></div></li>
                    <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "economico" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoTransitMode: "economico"}, this.graphPareto)} style={{borderRadius: "0px"}}>Unidad<div></div></li>
                  </>
                }
                {
                  this.props.companyType === 2 &&
                  <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "lt" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoTransitMode: "lt"}, this.graphPareto)}>LTs<div></div></li>
                }
                {/* <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "lt" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoTransitMode: "lt"}, this.graphPareto)}>LTs<div></div></li> */}
                <li className={"desviacion".concat(this.state.selectedParetoTransitMode === "ruta" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoTransitMode: "ruta"}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>Rutas<div></div></li>
              </div>
              {
                this.props.companyType === 2 &&
                <div className="desviacion" var="filtroDRV" style={{display: this.state.selectedParetoMode === "estadiaAgencia" ? "" : "none"}}>
                  <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "drv" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoAgenciaMode: "drv"}, this.graphPareto)}>DRVs<div></div></li>
                  <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "uen" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoAgenciaMode: "uen"}, this.graphPareto)} style={{borderRadius: "0px"}}>UENs<div></div></li>
                  <li className={"desviacion".concat(this.state.selectedParetoAgenciaMode === "agencia" ? " selected ": "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoAgenciaMode: "agencia"}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>Agencias<div></div></li>
                </div>
              }
              <div className="desviacion" style={{display:"none"}}>
                <li className="desviacion selected" var="top.todos">Todos<div></div></li>
                <li className="desviacion" var="top.top">Top<div></div></li>
              </div>
              <div className="desviacion zoom">
                <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "all" ? " selected" : "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoZoomMode: "all"}, this.graphPareto)}>
                  Todos<div></div>
                </li>
                <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "zoom" ? " selected" : "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoZoomMode: "zoom"}, this.graphPareto)} style={{zIndex: "10"}}>
                  Acotado<div></div>
                </li>
                <div className={"navigate".concat(" ", this.state.selectedParetoMode)} var="nav" style={{display: this.state.selectedParetoZoomMode === "zoom" ? "" : "none"}}>
                  <span var="prev" className="prev" onClick={() => {if(this.state.selectedParetoPage > 1)this.setState({selectedParetoPage: this.state.selectedParetoPage -1}, this.graphPareto)}}>
                    &#8211;
                  </span>
                  <span var="selectedParetoPage" className="pag">{this.state.selectedParetoPage*10}</span> 
                  <span var="next" className="prev" onClick={() => {if(this.state.selectedParetoPage < this.state.zoomDataLength)this.setState({selectedParetoPage: this.state.selectedParetoPage +1}, this.graphPareto)}}>
                    +
                  </span>
                </div>
              </div>
              <div className="desviacion desv">
                <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "real" ? " selected" : "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoDisplayMode: "real"}, this.graphPareto)}>
                  {this.getDesvTitleFilter()}<div></div>
                </li>
                <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "desv" ? " selected" : "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoDisplayMode: "desv"}, this.graphPareto)} style={{borderRadius: "0px"}}>
                  Desv./Viaje<div></div>
                </li>
                <li className={"desviacion".concat(this.state.selectedParetoDisplayMode === "sum" ? " selected" : "", " ", this.state.selectedParetoMode)} onClick={() => this.setState({selectedParetoDisplayMode: "sum"}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>
                  Desv.<div></div>
                </li>
              </div>
              <div className="desviacion etapas">
                <li var="globalback" className="global-back" style={{display: this.state.selectedParetoMode === "tat" ? "" : "none"}}></li>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? " etapa-selected" : "")} onClick={() => this.setState({selectedParetoMode: "tat"}, this.graphPareto)}>
                  <img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global-tenue.svg" : "/images/tat-global.svg"} />
                </li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "estadiaPlanta"}, this.graphPareto)}}>
                  <TATFactoryTransparent />
                </li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "transitoIda"}, this.graphPareto)}}>
                  <TATTruck /> 
                </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "estadiaAgencia"}, this.graphPareto)}}>
                  <TATPlantaTransparent /> 
                </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? " etapa-selected" : "")} onClick={() => {this.hideTrees(); this.setState({selectedParetoMode: "transitoVuelta"}, this.graphPareto)}}>
                  <TATTruck /> 
                </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" >
                  <b className="loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaChart"></div>
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument("global")}}
            />
            {
              this.presentBlurView(this.props.desgloseCount, this.props.isLoadingDesglose)
            }
          </div>

          {
            this.props.isEmail
            ? this.getExtraModules()
            : ""
          }
        
          {
            this.props.isLoadingArbol1 && this.presentLoader()
          }
          
          <div id="paretoRutaArbol1" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol1 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol1Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolFilters(1)
                }
              </div>
              <div className="desviacion etapas" style={{paddingRight: "16px"}}>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? "" : " not-selected")}><img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global.svg" : "/images/tat-global-black.svg"} /></li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? "" : " not-selected")}><TATFactoryTransparent /></li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? "" : " not-selected")}><TATTruck /> </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? "" : " not-selected")}><TATPlantaTransparent /> </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? "" : " not-selected")}><TATTruck /> </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaArbol1Chart" className="paretoArbol" style={{display: this.state.arbol1Grupo.includes("tabla") ? "none" : ""}}></div>
                  {
                    this.state.arbol1Grupo.includes("tabla") 
                    && this.renderTable(1)
                  }
                </div>
              </div>
            </div>
            {/* <div className="download-button" var="button.download">
              <img src="https://orbinetwork.com/orbinetwork/assets/img/descarga.png"/>
            </div>  */}
            
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(1)}}
            />

            {
              this.presentBlurView(this.props.arbol1Count, this.props.isLoadingArbol1)
            }

          </div>

          {
            this.props.isLoadingArbol2 && this.presentLoader()
          }
          
          <div id="paretoRutaArbol2" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol2 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol2Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolFilters(2)
                }
              </div>
              <div className="desviacion etapas" style={{paddingRight: "16px"}}>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? "" : " not-selected")}><img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global.svg" : "/images/tat-global-black.svg"} /></li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? "" : " not-selected")}><TATFactoryTransparent /></li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? "" : " not-selected")}><TATTruck /> </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? "" : " not-selected")}><TATPlantaTransparent /> </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? "" : " not-selected")}><TATTruck /> </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaArbol2Chart"  style={{display: this.state.arbol2Grupo.includes("tabla") ? "none" : ""}}></div>
                  {
                    this.state.arbol2Grupo.includes("tabla") 
                    && this.renderTable(2)
                  }
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(2)}}
            />
            {
              this.presentBlurView(this.props.arbol2Count, this.props.isLoadingArbol2)
            }
          </div>

          
          {
            this.props.isLoadingArbol3 && this.presentLoader()
          }

          <div id="paretoRutaArbol3" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol3 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol3Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolFilters(3)
                }
              </div>
              <div className="desviacion etapas" style={{paddingRight: "16px"}}>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? "" : " not-selected")}><img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global.svg" : "/images/tat-global-black.svg"} /></li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? "" : " not-selected")}><TATFactoryTransparent /></li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? "" : " not-selected")}><TATTruck /> </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? "" : " not-selected")}><TATPlantaTransparent /> </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? "" : " not-selected")}><TATTruck /> </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaArbol3Chart"  style={{display: this.state.arbol3Grupo.includes("tabla") ? "none" : ""}}></div>
                  {
                    this.state.arbol3Grupo.includes("tabla") 
                    && this.renderTable(3)
                  }
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(3)}}
            />

            {
              this.presentBlurView(this.props.arbol3Count, this.props.isLoadingArbol3)
            }
          </div>

          
          {
            this.props.isLoadingArbol4 && this.presentLoader()
          }

          <div id="paretoRutaArbol4" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol4 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol4Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolFilters(4)
                }
              </div>
              <div className="desviacion etapas" style={{paddingRight: "16px"}}>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? "" : " not-selected")}><img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global.svg" : "/images/tat-global-black.svg"} /></li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? "" : " not-selected")}><TATFactoryTransparent /></li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? "" : " not-selected")}><TATTruck /> </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? "" : " not-selected")}><TATPlantaTransparent /> </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? "" : " not-selected")}><TATTruck /> </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaArbol4Chart"  style={{display: this.state.arbol4Grupo.includes("tabla") ? "none" : ""}}></div>
                  {
                    this.state.arbol4Grupo.includes("tabla") 
                    && this.renderTable(4)
                  }
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(4)}}
            />

            {
              this.presentBlurView(this.props.arbol4Count, this.props.isLoadingArbol4)
            }
                  
          </div>

          
          {
            this.props.isLoadingArbol5 && this.presentLoader()
          }

          <div id="paretoRutaArbol5" className="dashboard-panel dashboard-TAT-Month " style={{display: this.state.showParetoArbol5 ? "" : "none"}}>
            <div var="title" className="panel-title etapas">
              <b var="titulo">{this.state.arbol5Criterio}</b>
              <span var="fecha">{this.getParetoDate()}</span>
            </div>
            <ul className="filtros" var="filtros">
              <div className="desviacion">
                {
                  this.getArbolFilters(5)
                }
              </div>
              <div className="desviacion etapas" style={{paddingRight: "16px"}}>
                <li var="global" className={"global".concat(this.state.selectedParetoMode === "tat" ? "" : " not-selected")}><img alt="" src={this.state.selectedParetoMode === "tat" ? "/images/tat-global.svg" : "/images/tat-global-black.svg"} /></li>
                <li var="planta" className={"planta".concat(this.state.selectedParetoMode === "estadiaPlanta" ? "" : " not-selected")}><TATFactoryTransparent /></li>
                <li var="ida" className={"ida".concat(this.state.selectedParetoMode === "transitoIda" ? "" : " not-selected")}><TATTruck /> </li>
                <li var="agencia" className={"agencia".concat(this.state.selectedParetoMode === "estadiaAgencia" ? "" : " not-selected")}><TATPlantaTransparent /> </li>
                <li var="vuelta" className={"vuelta".concat(this.state.selectedParetoMode === "transitoVuelta" ? "" : " not-selected")}><TATTruck /> </li>
              </div>
            </ul>
            <div var="content" className="panel-content rutas">
              <div className="charts">
                <div className="paretoRuta" var="paretoRuta">
                  <b className="loader" var="paretoRuta.loader" style={{display: "none"}}><Loader /></b>
                  <div id="paretoRutaArbol5Chart"  style={{display: this.state.arbol5Grupo.includes("tabla") ? "none" : ""}}></div>
                  {
                    this.state.arbol5Grupo.includes("tabla") 
                    && this.renderTable(5)
                  }
                </div>
              </div>
            </div>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"50px"}
              height={"50px"}
              className="download-icon"
              onClick={(e) => {e.stopPropagation(); this.openDocument(5)}}
            />

            {
              this.presentBlurView(this.props.arbol5Count, this.props.isLoadingArbol5)
            }
          </div>
        </div>
      </View>
    )
  }
}
const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company?.type?.id || 0;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const orderTags = companyTags.filter(tag => tag.type === 7);
  const tripTags = companyTags.filter(tag => tag.type === 9);
  const baseTags = companyTags.filter(tag => tag.type === 3);


  const tatGlobal = getEntityItems(state, "COMPANIES.TAT.GLOBAL");
  const isLoadingTat = state?.api['COMPANIES.TAT.GLOBAL']?.status?.isFetching || false;
  const tatCount = state?.api['COMPANIES.TAT.GLOBAL']?.items?.count || 0;
  const isLoadingDesglose= state?.api['COMPANIES.TAT.DESGLOSE']?.status?.isFetching || false;
  const desgloseCount = state?.api['COMPANIES.TAT.DESGLOSE']?.items?.count || 0;
  const LTFacilities = (tatGlobal?.filters_data?.facilities || []).map(f => ({id: f.id, description: f.name.concat(' - ', f.code, ' - ', f.alias)}));
  const LTClasses = (tatGlobal?.filters_data?.transport_classes || []);


  const transportTags = companyTags.filter(tag => tag.type === 8);
  const companyTransportClasses = getEntityItems(state, "COMPANIES.TRANSPORTCLASS");
  let transportClassesByTag = [];
  if(companyType === 1){
    transportClassesByTag = [
      { id: 9999999999, description: "Sin etiqueta", items: [] },
    ];
    (
      LTClasses || []
    ).forEach((c) => {
      if (c.tag) {
        // c.tag.forEach((t) => {
          let tagIndex = transportClassesByTag.map((fT) => fT.id).indexOf(c.tag.id);
          if (tagIndex !== -1) {
            transportClassesByTag[tagIndex].items.push({
              id: c.id,
              description: c.code,
            });
          } else {
            let newTag = {
              id: c.tag.id,
              description: c.tag.title || '-',
              items: [
                {
                  id: c.id,
                  description: c.code,
                },
              ],
            };
            transportClassesByTag.push(newTag);
          }
        // });
      } else {
        transportClassesByTag[0].items.push({
          id: c.id,
          description: c.code,
        });
      }
    });
    transportClassesByTag = transportClassesByTag.map((tag) => {
      let sortedItems = tag.items.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return { ...tag, items: sortedItems };
    });
    let taglessClasses = transportClassesByTag.shift();
    transportClassesByTag = transportClassesByTag.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    transportClassesByTag.push(taglessClasses);
    transportClassesByTag = transportClassesByTag.filter(cT => cT.items.length);
    // debugger;
  } else {
    transportClassesByTag = transportTags.map(tag => {
      return ({
        id: tag.id,
        description: tag.title,
        items: (companyTransportClasses || []).filter(tC => tC.tags.map(t => t.id).includes(tag.id)).map(tC => ({id: tC.id, description: tC.code}))
      })
    }).filter(tC => tC.items.length);
  }

  const tatDesglose = getEntityItems(state, "COMPANIES.TAT.DESGLOSE");
  
  const tatArbol1 = getEntityItems(state, "arbol1");
  const tatArbol2 = getEntityItems(state, "arbol2");
  const tatArbol3 = getEntityItems(state, "arbol3");
  const tatArbol4 = getEntityItems(state, "arbol4");
  const tatArbol5 = getEntityItems(state, "arbol5");

  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");
  let oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;
  const companyFacilities = getEntityItems(
    state,
    "COMPANIES.FACILITIES.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.facilities.map((ff) => ({
      id: ff.id,
      description: `${ff.name} - ${ff.code} - ${ff.alias}`,
    })),
  }));
  let networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        let id = companyProviders.find(
          (provider) => provider.id === network.id
        );
        if (network.from_companies.id === companyId) {
          if (network.to_companies.type === oppositeCompanyType) {
            result.push({
              id: String(id?.fleet_provider?.fleet_provider_id),

              description: network.to_companies_alias.concat(
                " - ",
                network.to_companies_code,
                " - ",
                network.to_companies.orbi_id
              ),
            });
          }
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              // id: network.id,
              id: String(id?.fleet_provider?.fleet_provider_id),
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
            });
        }
        return result;
      },
      []
    );
  return {
    isLoadingDesglose,
    desgloseCount,
    tatCount,
    isLoadingTat,
    companyId,
    companyType,
    LTFacilities,
    LTClasses,
    // companyTags,
    orderTags,
    tripTags,
    baseTags,
    transportClassesByTag,
    tatGlobal,
    tatDesglose,
    companyFacilities,
    networkCompanies,
    tatArbol1,
    tatArbol2,
    tatArbol3,
    tatArbol4,
    tatArbol5,
    isLoadingArbol1: state?.api['arbol1']?.status?.isFetching || false,
    arbol1Count: state?.api['arbol1']?.items?.count || 0,
    isLoadingArbol2: state?.api['arbol2']?.status?.isFetching || false,
    arbol2Count: state?.api['arbol2']?.items?.count || 0,
    isLoadingArbo3: state?.api['arbol3']?.status?.isFetching || false,
    arbol3Count: state?.api['arbol3']?.items?.count || 0,
    isLoadingArbol4: state?.api['arbol4']?.status?.isFetching || false,
    arbol4Count: state?.api['arbol4']?.items?.count || 0,
    isLoadingArbol5: state?.api['arbol5']?.status?.isFetching || false,
    arbol5Count: state?.api['arbol5']?.items?.count || 0
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(listNetworkApprovedInvitations("", ""));
  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES.FILTERS", params, opts)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadCompanyTransportClass: (company_id) => 
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id })),
    loadTATGlobal: (params, opt) => 
      dispatch(readEntities("COMPANIES.TAT.GLOBAL", params, opt)),
    loadTATDesglose: (params, opt) => 
      dispatch(readEntities("COMPANIES.TAT.DESGLOSE", params, opt)),
    loadTATCriterio: (params, opt) => 
      dispatch(readEntities("COMPANIES.TAT.CRITERIO", params, opt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TATView);

// export default TATView;

const months_index = {
  "Ene": 0,
  "Feb": 1,
  "Mar": 2,
  "Abr": 3,
  "May": 4,
  "Jun": 5,
  "Jul": 6,
  "Ago": 7,
  "Sep": 8,
  "Oct": 9,
  "Nov": 10,
  "Dic": 11,
}
const months_abbr = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
];
const months_full = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const arboles = {
  'tat': {
    'linea': {
      'tabla1': false
    },
    'tabla1': false
  },
  'estadiaPlanta': {
    'linea': {
      'ruta': {
        'tabla2': false
      },
      'tabla2': false
    },
    'ruta': {
      'tabla2': false
    },
    'tabla2': false
  },
  'estadiaDRV': {
    'uen':  {
      'agencia': {
        'linea': {
          'ruta': {
            'tabla3': false
          },
          'tabla3': false
        },
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },
      'linea': {
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'agencia': {
      'linea': {
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'linea': {
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'ruta': {
      'tabla3': false
    },
    'tabla3': false
  },
  'estadiaUEN': {
    'agencia': {
      'linea': {
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'linea': {
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'ruta': {
      'tabla3': false
    },
    'tabla3': false
  },
  'estadiaAgencia': {
    'linea': {
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'ruta': {
      'tabla3': false
    },
    'tabla3': false
  },
  'transitoIda': {
    'ruta': {
      'tabla4': false
    },
    'tabla4': false
  },
  'transitoVuelta': {
    'ruta': {
      'tabla5': false
    },
    'tabla5': false
  },
  'transitoIdaRuta': {
    'linea': {
      'tabla4': false
    },
    'tabla4': false
  },
  'transitoVueltaRuta': {
    'linea': {
      'tabla5': false
    },
    'tabla5': false
  }
};

const arbolesLT = {
  'tat': {
    'economico': {
      'tabla1': false
    },
    'operador': {
      'tabla1': false
    },
    'tabla1': false
  },
  'estadiaPlanta': {
    // 'linea': {
    //   'ruta': {
    //     'tabla2': false
    //   },
    //   'tabla2': false
    // },
    
    'economico': {
      'ruta': {
        'tabla2': false
      },
      'tabla2': false
    },
    'operador': {
      'ruta': {
        'tabla2': false
      },
      'tabla2': false
    },

    'ruta': {
      'tabla2': false
    },
    'tabla2': false
  },
  'estadiaBase': {
    // 'uen':  {
    //   'agencia': {
    //     'linea': {
    //       'ruta': {
    //         'tabla3': false
    //       },
    //       'tabla3': false
    //     },
    //     'ruta': {
    //       'tabla3': false
    //     },
    //     'tabla3': false
    //   },
    //   'linea': {
    //     'ruta': {
    //       'tabla3': false
    //     },
    //     'tabla3': false
    //   },
    //   'ruta': {
    //     'tabla3': false
    //   },
    //   'tabla3': false
    // },
    'agencia': {
      // 'linea': {
        // 'ruta': {
        //   'tabla3': false
        // },
        // 'tabla3': false
      // },
      'economico': {
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },
      'operador': {
        'ruta': {
          'tabla3': false
        },
        'tabla3': false
      },

      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    // 'linea': {
    //   'ruta': {
    //     'tabla3': false
    //   },
    //   'tabla3': false
    // },
    'economico': {
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },
    'operador': {
      'ruta': {
        'tabla3': false
      },
      'tabla3': false
    },

    'ruta': {
      'tabla3': false
    },
    'tabla3': false
  },
  // 'estadiaUEN': {
  //   'agencia': {
  //     'linea': {
  //       'ruta': {
  //         'tabla3': false
  //       },
  //       'tabla3': false
  //     },
  //     'ruta': {
  //       'tabla3': false
  //     },
  //     'tabla3': false
  //   },
  //   'linea': {
  //     'ruta': {
  //       'tabla3': false
  //     },
  //     'tabla3': false
  //   },
  //   'ruta': {
  //     'tabla3': false
  //   },
  //   'tabla3': false
  // },
  // 'estadiaAgencia': {
  //   'linea': {
  //     'ruta': {
  //       'tabla3': false
  //     },
  //     'tabla3': false
  //   },
  //   'ruta': {
  //     'tabla3': false
  //   },
  //   'tabla3': false
  // },
  'transitoIdaOperador': {
    'ruta': {
      'tabla4': false
    },
    'tabla4': false
  },
  'transitoVueltaOperador': {
    'ruta': {
      'tabla5': false
    },
    'tabla5': false
  },
  'transitoIdaEconomico': {
    'ruta': {
      'tabla4': false
    },
    'tabla4': false
  },
  'transitoVueltaEconomico': {
    'ruta': {
      'tabla5': false
    },
    'tabla5': false
  },
  'transitoIdaRuta': {
    // 'linea': {
    //   'tabla4': false
    // },
    'economico': {
      'tabla4': false
    },
    'operador': {
      'tabla4': false
    },
    'tabla4': false
  },
  'transitoVueltaRuta': {
    // 'linea': {
    //   'tabla5': false
    // },
    'economico': {
      'tabla5': false
    },
    'operador': {
      'tabla5': false
    },
    'tabla5': false
  }
};

const MonthChart = {
  chart: {
    type: 'spline'
  },
  title: {
      text: ''
  },
  xAxis: {
    categories: [
        'Overpriced',
    ],
    crosshair: true,
    lineColor: '#303349',
    tickLength: 0,
    labels: {
      style: {
        color: 'white'
      }
    }
  },
  yAxis: { gridLineWidth: 0, title: { text: '' } },
  tooltip: {
          enabled: false
      },
  plotOptions: {
      spline: {
          dataLabels: {
              enabled: true,
              style: {
                fontSize: '17px'
              }
          },
          
      }
  },
  series: [{
      name: 'Estad\u00EDa Planta',
      data: [100, 100, 150, 200],
      stack: 0,
      color: '#4CABFF',
      borderWidth: 0
    }, {
      name: 'Tr\u00E1nsito Ida',
      data: [2000, 100, 150, 200],
      stack: 0,
      color: '#FE6901',
      borderWidth: 0
    }, {
      name: 'Estad\u00EDa Agencia',
      data: [200, 100, 150, 200],
      stack: 0,
      color: '#ACACAC',
      borderWidth: 0
    }, {
      name: 'Tr\u00E1nsito Vuelta',
      data: [200, 100, 150, 200],
      stack: 0,
      color: '#F3C91D',
      borderWidth: 0
    },{
      name: 'TAT',
      data: [300, 100, 150, 200],
      stack: 0,
      color: '#4ce0c7',
      borderWidth: 0
    }, {
      name: 'Meta',
      marker: {
          symbol: 'circle'
      },
      dashStyle: 'Dash',
      lineWidth: 1,
      color: '#e0e0e0',
      data: [50, 50, 50, 50]
  }],
  credits: {
    enabled: false
  },
};
const DefaultChart = {
  chart: {
      renderTo: 'container',
      type: 'column',
      height: 500,
  },
  title: {
      text: ''
  },
  tooltip: {
    shared: true,
    valueDecimals: 2
  },
  credits: {
    enabled: false
  },
  xAxis: {
      categories: [
          'Overpriced',
      ],
      crosshair: true,
      lineColor: '#303349',
      tickLength: 0
  },
  yAxis: [{
      title: {
          text: ''
      },
      stackLabels: {
        enabled: true,
        formatter: 1,
        style: {
          color: '#FFFFFF',
          textOutline: '0px',
          fontSize: '14px',
        }
      },
      maxPadding: 0.1,
      lineColor: '#303349',
      gridLineColor: '#303349'
  }, {
      title: {
          text: ''
      },
      minPadding: 0,
      maxPadding: 0,
      max: 100,
      opposite: true,

      lineColor: '#303349',
      gridLineColor: '#303349'
  }],
  plotOptions: {
      series: {
          stacking: 'normal',
          pointWidth: null,
          dataLabels: {
            crop: false,
            enabled: true,
            inside: true,
            formatter: 1,
            textOutline: false,
            style: {
              fontSize: '12px'
            } 
          }
      }
  },
  series: [
  {
    name: 'Estad\u00EDa Planta',
    legendIndex:0,
    data: [100],
    stack: 0,
    color: '#4CABFF',
    borderWidth: 0
  }, {
    name: 'Tr\u00E1nsito Ida',
    legendIndex:1,
    data: [200],
    stack: 0,
    color: '#FE6901',
    borderWidth: 0
  }, {
    name: 'Estad\u00EDa Agencia',
    legendIndex:2,
    data: [150],
    stack: 0,
    color: '#ACACAC',
    borderWidth: 0
  }, {
    name: 'Tr\u00E1nsito Vuelta',
    legendIndex:3,
    data: [200],
    stack: 0,
    color: '#F3C91D',
    borderWidth: 0
  },{
    name: 'TAT',
    legendIndex:4,
    data: [],
    stack: 0,
    color: '#4ce0c7',
    borderWidth: 0
  },{
    name: 'Meta',
    type: 'spline',
    yAxis: 1,
    legendIndex:6,
    zIndex: 11,
    lineWidth: 0,
    // color: '#960c0c',
    color: 'transparent',
    marker: {
      enabled: true,
      symbol: 'url(https://orbinetwork.com/orbinetwork/assets/img/red_line.png)',
      width: '65px',
      height: '22px'
    },
    states: {
      hover: {
        lineWidthPlus: 0
      }
    },
    dataLabels: {
      enabled: false,
    },
    data: [400]
  }, {
    name: 'Estad\u00EDa Vidriera',
    legendIndex:0,
    data: [100],
    stack: 0,
    color: '#4CABFF',
    borderWidth: 0
  }, {
    name: 'Estad\u00EDa Planta',
    legendIndex:2,
    data: [200],
    stack: 0,
    color: '#ACACAC',
    borderWidth: 0
  }]
};