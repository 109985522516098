import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

class TextExceedPlus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      overflows: false,
    };
    this.updateWidth = this.updateWidth.bind(this);
  }
  static propTypes = {
    tooltipClassName: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };
  static defaultProps = {
    tooltipClassName: "",
    tooltipPosition: "right"
  };

  updateWidth() {
    if (!this.el) {
      return;
    }
    const width = this.el.clientWidth;
    const scrollWidth = this.el.scrollWidth;
    if (this.state.width !== width) {
      this.setState({ width });
    }
    this.setState({ overflows: width < scrollWidth });
  }
  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.updateWidth);
  }
  componentDidMount() {
    this.updateWidth();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }
  render() {
    return (
      <div className="text-exceed">
        <div
          ref={(el) => {
            if (el) this.el = el;
          }}
          className="text-exceed-content"
        >
          {this.props.children}
        </div>
        {this.state.overflows ? (
          <>
            <div
              className="plus"
              data-tip
              data-for={"tooltip-".concat(this.props.name)}
            >
              +
            </div>
            <ReactTooltip
              className={this.props.tooltipClassName}
              id={"tooltip-".concat(this.props.name)}
              getContent={() =>
                this.props.children.map((c, index) => (
                  <Fragment key={index}>
                    {c}
                    <br />
                  </Fragment>
                ))
              }
              delayHide={200}
              // clickable={true}
              // delayShow={200}
              // delayUpdate={500}
              place={'left'}
              border={true}
              type={"light"}
            />
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TextExceedPlus;
