import React from "react";
import styled from "styled-components";

class TermsConditionsView extends React.Component {

  render() {

    return(
      <MainContent 
        className="home"
      >

        <MainTitle>
        Términos y condiciones
        <Paragraph
          style={{fontSize: 18}}
        >
        Contrato de licenciamiento de uso no exclusivo para usuarios de orbinetwork.com
        </Paragraph>
        </MainTitle>

        <MainSubtTitle>
        Términos y Condiciones
        <TextLink
          onClick={() => {
            window.open('https://orbinetwork.com', '', '')
          }}
        >https://orbinetwork.com</TextLink>
        </MainSubtTitle>

        <Content>
        Términos y condiciones del contrato de licencia de uso no exclusivo para usuarios (en lo sucesivo “el usuario”) del sistema Orbinetwork (en lo sucesivo “el sistema”) otorgado y operado por la empresa ORBI CARGO IT SAPI de CV (en lo sucesivo “el proveedor”) con domicilio en Av. Lopez Mateos 2077 Int Z8A, colonia Jardines del Plaza del, en Guadalajara, Jalisco, México, CP 44510.
        </Content>

        <SubtTitle>
        Declaraciones
        </SubtTitle>

        <Content>
        <Paragraph>1.- El proveedor declara que cuenta con los derechos patrimoniales del sistema Orbinetwork y que cuenta con la autoridad para otorgar licencias de uso no exclusivo a los usuarios en todos los países del mundo, así como establecer las tarifas y cobrar las contraprestaciones generadas por la venta y prestación de servicios de la herramienta.</Paragraph>
        <Paragraph>2.- El proveedor declara que cuenta con los recursos para el diseño, desarrollo de softwares, comercialización, operación y monitoreo de sistemas de información en la web y aplicaciones móviles, así como para brindar soporte técnico, seguridad cibernética y atención a usuarios.</Paragraph>
        <Paragraph>3.- El proveedor tiene las facultades para realizar actualizaciones y cambio de versión del sistema en cualquier momento a discreción del proveedor quien podrá agregar, quitar o modificar las herramientas, funcionalidades y tarifas según las necesidades del mercado y del mismo proveedor.</Paragraph>
        <Paragraph>4.- El usuario declara que acepta los términos y condiciones establecidos en este documento al aceptar de forma electrónica al seleccionar la casilla de “acepto los términos y condiciones” que se mostró al momento de crear su usuario y contraseña.</Paragraph>
        <Paragraph>5.- El usuario declara que el contrato le permite al usuario crear, usar y mantener su cuenta por tiempo ilimitado y le prohíbe modificar los archivos del código fuente, realizar copias, distribuir o comercializar el sistema orbinetwork.</Paragraph>
        </Content>

        <SubtTitle>
        Objeto del Contrato
        </SubtTitle>

        <Content>
        El presente contrato tiene la finalidad de otorgar a los usuarios una licencia de uso no exclusivo de la plataforma Orbinetwork que les permita crear un usuario, un perfil personal y crear o vincularse a diferentes perfiles de empresas y de esta manera poder utilizar las herramientas del sistema Orbinetwork, toda vez que dichos usuarios cumplan con los requisitos y condiciones del contrato. El contrato también tiene la finalidad de establecer las obligaciones a las cuáles el proveedor se compromete a cumplir tales como resguardar y hacer cumplir los requisitos para controlar los accesos al sistema, asegurar que la plataforma pueda prestar los servicios (funcionalidades del sistema) de forma ininterrumpida en la medida posible, brindar atención y soporte a usuarios, así como mantener en la medida posible la seguridad y privacidad de la información de los usuarios y sus empresas generada y almacenada en el sistema.
        </Content>

        <SubtTitle>
        Definiciones y Requerimientos para Acceder al Sistema
        </SubtTitle>

        <Content>
        Orbinetwork: en lo sucesivo “el sistema” o “la plataforma” es un sistema web y móvil en la nube que se encuentra en https://orbinetwork.com al que se puede acceder desde una computadora o dispositivo móvil a través de aplicaciones móviles. Consiste en una herramienta para empresas industrias, comercios, transportes y proveedores de logística en general para industrias y empresas de cualquier tamaño y que ofrezcan productos y o servicios lícitos y para ingresar al sistema es necesario crear un usuario, un perfil personal y perfil de empresa.

        <Paragraph>
        Usuarios: son las personas que se registran en el sistema que cumplan con los siguientes requisitos:
        1) Deben proporcionar un correo electrónico válido el cuál será verificado por Orbinetwork y servirá como credencial para iniciar sesión.2) Deben crear una contraseña ingresando dos veces la misma contraseña para su creación y servirá como credencial para iniciar sesión. La contraseña debe contener las siguientes medidas de seguridad:
         Mínimo de 8 caracteres.
         Al menos un dígito del 0 al 9
         Una letra mayúscula de la A a la Z
         Una letra minúscula de la A a la Z
         No debe contener 3 o más números consecutivos.
        3) Deben de aceptar los términos y condiciones del sistema
        Todas las personas que cumplen con estos requisitos son consideradas usuarios. Todos los usuarios deben cumplir con los siguientes requisitos para poder utilizar el sistema:
        1) Deben crear un perfil personal.
        2) Deben vincular su perfil personal a un perfil de empresa por medio de alguna de las
        siguientes maneras:
        o Crear un perfil de empresa: todos los usuarios que cuenten con un perfil
        personal tienen la facultad de crear uno o varios perfiles de empresa para sus diferentes negocios en caso de que así lo requiera. Lo pueden hacer en el momento cuando están creando su perfil personal o si ya es un usuario activo debidamente vinculado a una empresa puede crear nuevos perfiles de empresa desde la opción correspondiente en el menú principal de su sesión.
        o Por medio de una invitación: el usuario puede unirse a un perfil de empresa al recibir una invitación enviada por un usuario de otra empresa. La invitación es enviada al correo electrónico del usuario invitado y para aceptar dicha invitación el usuario podrá hacerlo desde el correo electrónico. También se puede aceptar una invitación desde el formulario de creación de perfil personal cuando el usuario crea su perfil el sistema mostrará si el usuario cuenta con alguna invitación, la cuál se aceptará de forma automática al finalizar su proceso de creación de perfil personal.
        Los usuarios con perfil personal vinculado a diferentes perfiles de empresas solo podrán iniciar sesión en una empresa a la vez, por lo que al iniciar sesión deben elegir el perfil de empresa al que desea ingresar. Podrán cambiar el perfil posteriormente y cuantas veces sea necesario, toda vez que el usuario haya ingresado previamente sus credenciales (correo electrónico y contraseña) correctamente.
        Los usuarios podrán crear su perfil personal y crear un perfil de empresa, sin embargo, para que el perfil de la empresa recién creado esté activo el usuario debe verificar su correo electrónico con el correo de “confirmación de email” que el sistema envió a su buzón cuando creó su usuario en el sistema, de lo contrario el perfil de empresa no podrá activarse. De igual manera si el correo del usuario no ha sido verificado, las cuentas del usuario no estarán activas para ninguna de las empresas a las que haya sido invitado por lo que no podrá hacer uso de su perfil en ninguna empresa y no podrá ver la información de ninguna de sus empresas hasta verificar su correo. En el caso de no recibir el correo de verificación de parte del sistema, el usuario puede enviar un correo electrónico desde la dirección de correo electrónico que registro en el sistema a la siguiente dirección soporte@orbinetwork.com solicitando la verificación de su correo electrónico para permitir la activación de su o sus cuentas. Nuestro departamento de soporte técnico atenderá su solicitud y se pondrá en contacto con el usuario para concluir el procedimiento.
        </Paragraph>

        <Paragraph>
        Credenciales de acceso: Son el correo el correo electrónico verificado que el usuario proporciono como su nombre de usuario y la contraseña con los requisitos de seguridad que el sistema exige. Sirven para que el usuario pueda iniciar sesión y las mismas credenciales aplican para todas las sesiones en cualquiera de las cuentas de empresa en las que su perfil personal esté vinculado.
        </Paragraph>

        <Paragraph>
        Bloqueo de credenciales de acceso: Si un usuario en un lapso de 24hrs realiza 3 intentos de ingresar al sistema con una contraseña errónea, su cuenta será bloqueada durante 10 minutos, periodo en el cuál no podrá ingresar. Si al vencer el periodo de bloqueo el usuario realiza 3 intentos con contraseña incorrecta, su cuenta se bloqueará 1 hora. Si después de ese bloqueo realiza 3 nuevos intentos fallidos la cuenta se bloqueará 8 horas. Para eliminar cualquier periodo de bloqueo el usuario podrá solicitar una nueva contraseña a través de la función de “¿Olvidaste tu contraseña?” la cuál solo aplica para usuarios registrados.
        </Paragraph>

        <Paragraph>
        Reutilización de contraseña: los usuarios podrán cambiar su contraseña cada que lo requieran desde el módulo de “perfil personal” donde podrán actualizar su contraseña, sin embargo, no podrán reutilizar contraseñas utilizadas anteriormente por el usuario, solo se le permitirá utilizar contraseñas nuevas.
        </Paragraph>

        <Paragraph>
        Perfil personal: Es el perfil donde se registra la siguiente información personal de un usuario para que los demás usuarios puedan reconocerlos:
         Nombre y apellido
         Foto de perfil
        </Paragraph>

        <Paragraph>
        Perfil de empresa: Es el perfil donde se registra la siguiente información de una empresa:
         Datos generales: Razón social, domicilio, ciudad, estado, país, teléfono, sitio web.
         Orbi Id: Es el nombre corto con el cuál las demás empresas y usuarios pueden identificar
        el perfil de empresa.
         Tipo de cuenta: Existen diferentes tipos de cuenta con herramientas y servicios
        específicos propios del giro o actividad de la empresa/usuario
        El sistema está diseñado como una red de empresas (perfiles de empresas) donde las empresas creadas por usuarios verificados se conectan con otras empresas (clientes y/o proveedores) para gestionar y compartir información, pedidos y embarques utilizando las herramientas del sistema.
        </Paragraph>
        
        <Paragraph>
        Tipos de cuenta: El sistema tiene los siguientes tipos de cuenta los cuales son de uso exclusivo para los perfiles de empresa, cada perfil de empresa debe tener uno de los siguientes tipos de cuenta el cuál se elige al crear el perfil de empresa:
         Dueños de carga: son personas físicas o morales que se dedican a la producción, distribución, compra o venta de cualquier tipo de productos, explotación, transformación de materias primas y a la transportación de sus productos o insumos en transporte privado. Utilizan el sistema para crear pedidos entre las instalaciones de su perfil de empresa, o entre instalaciones de otras empresas dueños de carga con las que se vincularon en el sistema. También solicitan embarques a los diferentes tipos de transportistas que existen en el sistema para la ejecución y visualización de dichos embarques, entre otras funciones.
         Transportistas: personas físicas o morales que se dedican a brindar servicios de transporte de carga público federal o privado para alguna empresa específica. Utilizan el sistema para recibir solicitudes de servicio (reservaciones) de parte de dueños de carga y ejecutar los viajes brindándoles visibilidad entre otras funciones.
         Navieras: empresas que se brindan servicio de transporte de carga marítima contenerizada, en todo el mundo. Utilizan el sistema para recibir solicitudes de servicio (reservaciones) de parte de dueños de carga y ejecutar los viajes brindándoles visibilidad entre otras funciones.
         Terminales Marítimas: empresas que operan terminales de contenedores marítimas y prestan servicio de maniobras de contenedores a las navieras. Utilizan el sistema para recibir instrucciones de las navieras y solicitudes de citas de maniobras, así como dar visibilidad de las confirmaciones de citas agendadas para las maniobras y estatus de éstas a todos los involucrados en los embarques.
         Ferrocarriles: empresas que se brindan servicio de transporte de carga contenerizada en ferrocarril. Utilizan el sistema para recibir solicitudes de servicio (reservaciones) de parte de dueños de carga o navieras y ejecutar los viajes brindándoles visibilidad a
        todas las partes entre otras funciones.
         Terminales Intermodales: empresas que operan terminales intermodales de
        contenedores ferroviarias y prestan servicio de maniobras de contenedores a los ferrocarriles. Utilizan el sistema para recibir instrucciones de los ferrocarriles y solicitudes de citas de maniobras, así como dar visibilidad de las confirmaciones de citas agendadas para las maniobras y estatus de éstas a todos los involucrados en los embarques.
         Patios de Contenedores: empresas que operan almacenes o patios de contenedores y prestan servicio de maniobras de contenedores a navieras y ferrocarriles. Utilizan el sistema para recibir instrucciones de las navieras y ferrocarriles, así como solicitudes de citas de maniobras. Brindan visibilidad de las confirmaciones de citas agendadas para las maniobras y estatus de éstas a todos los involucrados en los embarques.
         Agentes Aduanales: empresas que brindan servicio de despacho aduanal y trámites en terminales portuarias e intermodales para dueños de carga, navieras y ferrocarriles. Utilizan el sistema para recibir instrucciones de sus clientes, visualizar estatus de los embarques, solicitar citas, maniobras, liberaciones, documentaciones, transportes, etc. a los diferentes proveedores de la cadena con los que interactúan en la terminal donde operan y brindan visibilidad de estatus de cada trámite en los que ellos se ven involucrados.
         Almacenes (Orbiwarehouse): empresas que brindan servicio de almacén y centro de distribución a dueños de carga, reciben mercancías para almacenar y coordinar servicios de entrega a otros puntos de transferencia, puntos de venta y/o clientes finales (consumidores), los cuales pueden ser otros dueños de carga o usuarios de orbipeople.
         Orbipeople: Son las personas consumidoras que adquieres productos de dueños de carga que tengan habilitada la función para vender desde sus instalaciones a consumidores finales.
         Repartidores Orbidelivery: Son personas que prestan servicio para entregar pedidos realizados por los usuarios de Orbipeople ofreciendo transporte a través de algún tipo de vehículo, camioneta, motocicleta, bicicleta, o cualquier otro medio de transporte para repartir pedidos en el domicilio del consumidor.
        </Paragraph>

        <Paragraph>
        Tipo de usuario: Cada tipo de cuenta tiene diferentes tipos de usuarios, los cuales se refieren a las facultades que tienen por defecto en el sistema cada uno de los perfiles personales de los usuarios vinculados a un perfil de empresa. Los tipos de usuario solo a aplican a un perfil de un usuario en una empresa, es decir, que un usuario puede tener diferentes tipos de usuarios en las diferentes empresas en las que se encuentre invitado o vinculado. A continuación, los tipos de usuarios que aplican a cada tipo de empresa:
         Dueños de carga, Terminales marítimas, Terminales intermodales, Patios de contenedores, Agentes aduanales, Almacenes (Orbiwarehouse):
        o Propietario (Owner)
        o Administrador (Admin)
        o Almacenista (Warehouser)
         Transportistas, Navieras, Ferrocarriles: o Propietario (Owner)
        o Administrador (Admin)  Orbipeople:
        o Propietario (Owner)  Repartidores Orbidelivery:
        o Propietario (Owner)
        </Paragraph>
        <Paragraph>Propietario (owner): Toda cuenta de empresa o perfil de empresa solo puede ser creada por un usuario, ese usuario es el propietario (owner) y por defecto (default) es quién tiene todos los permisos, es decir, las facultades técnicas y prácticas para visualizar, crear, editar o eliminar toda o cualquier información de la cuenta y configurar todas las funciones, módulos, herramientas y suscripciones de ésta misma. También es quien define el tipo de usuario y las facultades (permisos) que tendrán usuarios que el mismo propietario invite a su perfil de empresa. El propietario o el usuario con facultades que haga la invitación define las facultades y permisos que el usuario invitado tendrá dentro del perfil de empresa, por lo que se entiende que la persona o personas que pueden decidir a quién se le va a dar acceso a la cuenta (perfil) de la empresa son los propietarios o los que usuarios asignados por los mismos propietarios.
        Solo el propietario tiene la facultad de nombrar a otros usuarios como propietarios de la cuenta. Un perfil de empresa puede tener varios propietarios asignados solamente por otros propietarios de la misma cuenta, sin embargo, un perfil de empresa no puede quedarse sin propietario.
        El proveedor reconoce al propietario o a los propietarios de cada perfil de empresa de cada tipo, como las únicas personas además del mismo proveedor con la autoridad legal suficiente para decidir o girar instrucciones sobre el uso y destino de la información de la cuenta de la empresa, así como para recibir avisos y notificaciones de cualquier situación o eventualidad donde su información o la cuenta misma se vean involucradas. Los perfiles de empresas y su información se encuentran protegida por nuestra política de privacidad que se describe más adelante en éste mismo documento.</Paragraph>
        <Paragraph>Administrador (admin): Son aquellos usuarios que fueron invitados por un propietario o por otro administrador con las facultades correspondientes para enviar invitaciones. La principal característica es que los administradores pueden tener visibilidad de la información y controles de diferentes instalaciones o sucursales de su empresa en la misma sesión.</Paragraph>
        <Paragraph>Almacenista (warehouser): Son aquellos usuarios que fueron invitados por un propietario o por otro administrador con las facultades correspondientes para enviar invitaciones. La principal característica es que los almacenistas solo pueden tener visibilidad de la información y controles de una instalación o sucursal de su empresa a la vez por sesión, es decir, sus vistas son simplificadas y solo ven controles de una instalación (la que seleccionaron al iniciar la sesión) por lo que, si su perfil tiene vinculadas diferentes instalaciones o sucursales, debe cambiar la sesión para ver los controles de las otras instalaciones. Este perfil es ideal para el personal que labora en una sola instalación o sucursal de la empresa, aunque sistema permita al usuario tener varias instalaciones o sucursales.</Paragraph>
        <Paragraph>Rol de permisos: Cada perfil de empresa tiene la opción de crear una configuración de permisos específicos los cuales se encuentran dentro del sistema en el “perfil de la empresa”, en la pestaña de “permisos”, donde se encuentra una opción para “crear rol” que consiste en una lista de permisos específicos que aplican a módulos o funciones específicos del tipo de cuenta. Todos los permisos tienen 4 facultades independientes: Creación, Lectura, Edición y Eliminación, de manera que se pueden otorgar o restringir permisos para una o varias facultades para una función o módulo específico de la cuenta. La combinación de ciertos permisos se puede guardar y asignar un nombre que el mismo usuario decidirá antes de guardar. Dicha combinación de permisos es lo que se conoce como un “Rol de Permisos”, el cuál puede ser asignado a un usuario al momento de enviar su invitación para unirse a la empresa o bien se puede cambiar en cualquier momento posteriormente. Cabe mencionar que el sistema tiene un rol (conjunto de permiso por defecto) para cada tipo de usuario de cada tipo de cuenta. Los propietarios de todos los tipos de cuenta por defecto tienen todos los permisos y facultades para todos las funciones y módulos de su cuenta. Existe un rol por defecto para los “admin” y otro para los “warehousers” de cada tipo de cuenta, en ambos casos anteriores, los roles por defecto no permiten hacer creaciones, ediciones o eliminaciones de datos maestros de la cuenta, solo permite lectura de información, para qué otro usuario tenga facultades de creación, edición o eliminación es necesario que el propietario asigne los permisos específicos o roles correspondientes para los módulos, funciones y facultades específicas a los usuarios que así designe.</Paragraph>
        <Paragraph>Catálogos: Son los módulos que se encuentran en la sección de “configuración” en todos los tipos de cuenta, se consideran datos maestros y cada tipo de cuenta tiene diferentes necesidades y por lo tanto diferentes catálogos. El acceso a estos catálogos lo controla el propietario quien define el tipo de usuario que tiene cada perfil personal vinculado a su cuenta (perfil de empresa).</Paragraph>
        <Paragraph>Controles: Esta sección se encuentra en el menú principal de todos los tipos de cuentas y contiene las principales herramientas que se utilizan en la operación cotidiana de las empresas. El acceso a estos catálogos lo controla también propietario o quien el propietario haya empoderado para definir el tipo de usuario que tiene cada perfil personal vinculado a su cuenta (perfil de empresa).</Paragraph>
        <Paragraph>Reportes: Es la información generada por la operación de los controles del perfil de la empresa, los cuales son descargables y sirven para mostrar indicadores y gráficas estadísticas propias de la inteligencia de negocio.</Paragraph>
        <Paragraph>Orbi_ID: Es el nombre corto comercial que las empresas utilizan para que su perfil de empresa pueda ser identificado por otros usuarios del sistema.</Paragraph>
        </Content>

        <SubtTitle>
        Política de Gestión de Credenciales Seguras de Usuarios del Sistema
        </SubtTitle>

        <Content>
        El sistema permite el acceso todos los usuarios que cuenten con credenciales verificadas para iniciar sesión en su perfil personal, sin embargo, la sesión de un usuario puede estar vinculada o no a un perfil de empresa. Las únicas personas autorizadas para controlar la vinculación de un perfil personal con perfil de una empresa son los propietarios de esta última o los usuarios a quien los propietarios otorgaron dicha facultad. La única manera para que una persona pueda acceder a la información de una cuenta (perfil) de empresa es iniciando sesión en el sistema con sus credenciales y que el usuario tenga su perfil personal vinculado al perfil de la empresa. La única manera de que un perfil personal pueda unirse a perfil de empresa es a través de una “invitación para unir un usuario al perfil de la empresa”, la cual solo puede ser enviada a usuarios que tengan correo electrónico verificado y dicha invitación solo puede ser enviada por el propietario o por usuarios con el permiso correspondiente asignado por el o los propietarios.
        Las sesiones de los usuarios que ingresen al sistema con sus credenciales están sujetas a las siguientes reglas:
         La duración puede permanecer activa de forma indefinida mientras no pase un lapso de más de 8hrs sin realizar consultas o peticiones de información. Es decir, cada que realiza una consulta o petición de información el sistema vuelve a refrescar la sesión la cuál permanecerá activa durante las siguientes 8 hrs y se reiniciará sucesivamente mientras esté realizando peticiones y consultas dentro de ese tiempo.
         El límite de tiempo de una sesión sin actividad es de 8 hrs, después de este tiempo por seguridad el sistema cerrara la sesión y pedirá al usuario que ingrese de nuevo sus credenciales al intentar realizar cualquier consulta nueva de información.
         Todas las contraseñas de acceso de los usuarios son encriptadas y ningún personal de Orbinetwork puede desencriptarla, por lo que las contraseñas no pueden ser recuperadas solo sustituidas por una nueva.
         El límite de intentos para el inicio de sesión será de 3 intentos. Al 3er intento se bloqueará la cuenta del usuario por 10minutos, si posteriormente el usuario reincide con otros 3 intentos el bloqueo será de 1 hora y si vuelve a reincidir 3 intentos más el bloqueo será de 8 horas.
         Para recuperar contraseña el sistema no tiene manera de recuperar la contraseña de un usuario, solo se le puede permitir generar una contraseña nueva. La única manera para generar una contraseña nueva es a través de la función de “¿Olvidaste tu contraseña?” en la página de inicio de sesión, la cual pedirá la dirección del correo electrónico que desea restablecer la contraseña, debe ser un correo de un usuario verificado y entonces se le enviará un correo electrónico con una liga de donde el usuario podrá crear una nueva contraseña. No se permite al usuario utilizar contraseñas usadas previamente, solo contraseñas nuevas. Esta liga es de uso único y caducará después de utilizarse o al transcurrir 24 hrs de haberse generado. El usuario podrá solicitar otra liga ingresando su correo electrónico de nueva cuenta en la vista de ¿Olvidaste tu contraseña?
        </Content> 

        <SubtTitle>
        Política de Bitácoras
        </SubtTitle>

        <Content>
        El sistema lleva un control de registro de los movimientos realizados por los usuarios al inicio y
        durante cada sesión. La bitácora se puede consultar de 2 maneras:
        La bitácora simple en el catálogo de usuarios en la sección de “histórico”, la cual puede
        ser consultada por el propietario y administradores de la cuenta de la empresa. Las
        bitácoras son actualizadas en tiempo real por lo que pueden ser consultado bajo
        demanda por los usuarios con los permisos correspondientes en cualquier momento y
        descargar dicho reporte en archivo CSV.
        Por solicitud del propietario a través de su cuenta de correo electrónico registrada como
        propietario enviando a soporte@orbinetwork.com la solicitud de la bitácora de accesos
        indicando el Orbi_Id del perfil de su empresa que desea el reporte, tendrá respuesta a
        través del mismo correo en un lapso no mayor a 72hrs.
        Los eventos que se generan registro en la bitácora son:
        Inicios de sesión
        Creación de registros en cualquiera de los catálogos del perfil de la empresa (datos maestros).
        Edición de registros en cualquiera de los catálogos del perfil de la empresa.
        Eliminación de registros en cualquiera de los catálogos del perfil de la empresa.
        Creación de pedidos, embarques, ordenes de servicio, etc. en los controles del perfil de
        empresa
        Edición de pedidos, embarques, ordenes de servicio, etc. en los controles del perfil de
        empresa.
        Eliminación de pedidos, embarques, ordenes de servicio, etc. en los controles del perfil de
        empresa.
        Cada registro va acompañado de la siguiente información:
        Fecha y hora (en UTC traducida a la hora local) en que se realizó el registro del evento
        Usuario: nombre del usuario que lo realizo
        Acción realizada: exitosa y rechazada*
        Dirección IP*
        Resultado*
        Información (detalle de la acción) *
        Comentarios: detalles del navegador*
        *Esta información solo está disponible en los reportes en formato CSV que se entregan a solicitud
        escrita por correo electrónico del propietario de la cuenta.
        </Content> 

        <SubtTitle>
        Servicios del sistema
        </SubtTitle>

        <Content>
        <Paragraph>
        El sistema opera y está disponible para sus usuarios las 24hrs del día los 365 días del año a excepción de los mantenimientos programados y los cuales son avisados con anticipación, o de los eventos que por seguridad sea necesario desconectar el servicio cuando la información se vea comprometida.
        </Paragraph>

        <Paragraph>
        La plataforma presta servicio a sus usuarios a través de las herramientas gratuitas y herramientas que tienen un costo para su utilización, las cuales son de uso opcional para los usuarios. El sistema es una plataforma web y cuenta también con aplicaciones móviles. Se puede acceder en todo momento a través de cualquier navegador de internet Google Chrome, Safari, Firefox de cualquier computadora, pc, dispositivo móvil, tableta o teléfono inteligente con acceso a internet, de preferencia de alta velocidad o banda ancha para una mejor experiencia de usuario. Para ello los usuarios deben tener credenciales creadas a través de la página web https://orbinetwork.com. Una vez registrado el usuario podrá acceder a las herramientas mismas que podrá utilizar para los fines que él convenga. Los servicios y tarifas del sistema se encuentran publicados en la dirección de la página web del sistema anteriormente mencionada.
        </Paragraph>
        </Content> 

        <SubtTitle>
        Requerimientos técnicos y recomendaciones para el correcto funcionamiento del sistema
        </SubtTitle>

        <Content>
        Orbinetwork es una plataforma web disponible a través de cualquier computadora, pc o dispositivo móvil (tableta o smartphone) con acceso a internet, de preferencia de alta velocidad o banda ancha para una mejor experiencia de usuario. Compatible con cualquier navegador: Google Chrome, Safari, Firefox* de cualquier dispositivo: PC, Tableta o Smartphone. (*Recomendado)
        Los operadores de camiones (choferes) necesitan:
        ● Smartphone de gama baja, media** o alta** con Android Versión 5 en adelante
        ● Descargar la app OrbiDelivery disponible gratis en Google Play.
        ● 1gb mínimo mensual de servicio de datos móviles.
        ● Dispositivo móvil con 1GB de RAM, 16GB de memoria de almacenamiento y procesador
        de 4 núcleos como mínimo.
        Recomendamos celulares de gama media o alta para un mejor desempeño y experiencia de usuario
        </Content>

        <SubtTitle>
        Mantenimiento y Actualizaciones
        </SubtTitle>

        <Content>
        El proveedor se compromete a velar por el mantenimiento del sistema, servidores, bases de datos y seguridad de información, así como de la implementación constante de nuevas medidas de seguridad y actualizaciones al sistema para mejorar y/o agregar nuevas funciones, herramientas o corrección de fallas y mejoras de funcionalidad, diseño, procesos, etc. El proveedor podrá interrumpir el servicio del sistema de forma parcial o total durante un periodo de tiempo previamente programado para realizar tareas de mantenimiento. Cuando esto suceda se les informará a los usuarios con anticipación para que tomen sus debidas precauciones. Sin embargo, el proveedor podrá interrumpir el servicio de forma total o parcial, por tiempo indefinido y sin previo aviso ante algún ataque cibernético o cuando las condiciones de seguridad o funcionamiento pongan en peligro la confidencialidad de la información de nuestras bases de datos. Es nuestro compromiso implementar las medidas correctivas ante cualquier situación de ésta naturaleza para restablecer el servicio lo antes y en la medida posible toda vez que las condiciones lo permitan de forma que podamos mantener segura la información y funcionamiento del sistema.
        </Content> 

        <SubtTitle>
        Soporte y Atención a usuarios
        </SubtTitle>

        <Content>
        Orbinetwork pone a disposición de los usuarios el área de soporte que cuenta con diversos canales de comunicación para atender preguntas, incidentes, problemas o solicitudes de los usuarios las 24hrs del día los 7 días de semana:
        Términos y Condiciones

        Chat en línea en el menú principal de su cuenta de orbinetwork. Correo electrónico Soporte Técnico: soporte@orbinetwork.com Correo electrónico otros asuntos: contacto@orbinetwork.com, Facebook https://www.facebook.com/orbinetwork
        Twitter @orbinetwork (https://twitter.com/orbinetwork) Whatsapp +52 1 33 1138 3620 (Solo chat)
        Política de Privacidad de la Información de los Usuarios.
        Para ver los tiempos de respuesta comprometidos (SLAs aplicables) para la atención de tickets de
        soporte favor de ingresar a orbinetwork.com en al final de la página en la sección de SLA ́s
        </Content> 

        <SubtTitle>
        Política de Privacidad de la Información de los Usuarios.
        </SubtTitle>

        <Content>
        A) Toda la información nuestros usuarios: credenciales, perfiles personales, perfiles de empresa, catálogos y controles de los perfiles de empresa se resguardada en nuestras bases de datos de nuestra infraestructura la cual cumple con los estándares de seguridad establecidos en la política de resguardo de información y respaldos y el acceso a dicha infraestructura es completamente confidencial y controlada por la política de privacidad de infraestructura del sistema.

        <Paragraph>
        B) Todo el personal que labora en nuestra empresa y los proveedores con acceso a la infraestructura de nuestras bases de datos tienen estrictamente prohibido bajo contrato de confidencialidad compartir cualquier información de nuestros usuarios con ninguna empresa o persona ajena a nuestra empresa.
        </Paragraph>

        <Paragraph>
        C) Los usuarios son los propietarios y responsable de la información de su perfil personal y del o los perfiles de empresas que tengan creados en el sistema. El proveedor no utilizará dicha información para ningún fin de lucro con terceros o ajenos a los propietarios de la información, ni publicidad o mercadotecnia.
        </Paragraph>

        <Paragraph>
        D) El usuario es el único dueño de la información de su perfil personal y del perfil de su empresa. Los usuarios solo podrán compartir la información de sus operaciones con los usuarios con los que mantiene una relación establecida bajo consentimiento y aprobación de ambas partes. La información de la operación de los usuarios que se encuentra en las herramientas de controles y reportes es propiedad y responsabilidad de los usuarios que generaron dicha información, Orbinetwork no es responsable de la legitimidad de dicha información que los usuarios proporcionan.
        </Paragraph>

        <Paragraph>
        E) La información relativa a las operaciones de los usuarios (controles y reportes) solo podrá ser consultada por las empresas que participaron en dicha operación. Ningún usuario podrá ver las operaciones de otros usuarios donde no tuvo participación alguna, aun cuando tenga una relación establecida con el usuario.
        </Paragraph>

        <Paragraph>
        F) Orbinetwork no creará ninguna herramienta que les permita a los usuarios acceder a ninguna información de otros usuarios sin su autorización.
        </Paragraph>

        <Paragraph>
        G) En caso de recibir algún requerimiento oficial o de alguna entidad gubernamental o judicial para entregar información de los usuarios el proveedor tiene la obligación de notificar por correo electrónico al propietario de la información acerca de dicho requerimiento y el usuario está de acuerdo que el proveedor se deslinda de cualquier responsabilidad del uso que la autoridad de a esa información. El área legal de Orbinetwork deberá notificar al propietario dentro de las 72horas posteriores a la recepción de dicho requerimiento oficial o gubernamental y a su vez se entregará la información a la entidad correspondiente dentro de las 72 horas posteriores a la notificación del propietario.
        </Paragraph>
        </Content> 

        <SubtTitle>
        Plan de Recuperación de Desastres (DRP Disaster recovery plan)
        </SubtTitle>

        <Content>
        El proveedor cuenta con un plan de recuperación de la información y del servicio necesarios ante una situación de desastre o destrucción del sitio donde se encuentra la infraestructura por desastre natural, guerra, pandemia, conflicto político, religioso, terrorismo o causas de fuerza
        mayor, incluidos ataques cibernéticos que deriven en el robo de información.

        <Paragraph>
        Tiempo máximo de pérdidas 24 horas,
        Tiempo de última 12 horas
        </Paragraph>

        <Paragraph>
        El plan cuenta con los procedimientos estándares que aquí se mencionan y los cuales se detallan
        en la documentación y procedimientos internos del proveedor:
        </Paragraph>

        <Paragraph>
        1.- Respaldo de la BDD: Procedimiento para recuperar el respaldo más reciente de la Base de Datos.
        </Paragraph>

        <Paragraph>
        2.- Respaldo de HDD: Procedimiento para recuperar el respaldo más reciente del Disco Duro.
        </Paragraph>

        <Paragraph>
        3.- Alta de servidor: Procedimiento detallado de la instalación del sistema operativo del servidor.
        </Paragraph>

        <Paragraph>
        4.- Restauración de respaldo de BDD: Procedimiento para restaurar el último respaldo de bdd.
        </Paragraph>

        <Paragraph>
        5.- Restauración de respaldo de HDD: Procedimiento para restaurar el último respaldo del disco duro.
        </Paragraph>

        <Paragraph>
        6.- Procedimientos adicionales: Procedimiento para implementar los procesos adicionales necesarios para dar de alta nuevamente el servicio.
        </Paragraph>

        <Paragraph>
        7.- Pruebas: Procedimiento para probar la implementación.
        </Paragraph>

        <Paragraph>
        8.- Rollback: Procedimiento para regresar todo a como estaba antes del ejercicio en caso de alguna falla.
        </Paragraph>
        

        <Paragraph>
        Los procedimientos internos del proveedor establecen que todos los procesos se someten a una revisión anual por lo menos.
        </Paragraph>
        </Content> 

        <SubtTitle>
        Política de respuesta a incidentes
        </SubtTitle>

        <Content>
        En caso de que se detecte un incidente de seguridad informática, el área de seguridad e infraestructura del proveedor informará por correo electrónico a los propietarios de los perfiles de empresa afectados en un lapso no mayor a 8hrs después de haberse perpetrado el incidente.
        Se deberá contactar a través de medios seguros de comunicación a los resguardantes de las credenciales necesarias, de ser necesario a los segundos resguardantes.
        En caso de que se sospeche que el incidente está relacionado a un ataque, se deberá contactar inmediatamente a algún proveedor de servicios de informática forense y notificar a los demás miembros del grupo de respuesta a incidentes sobre esta acción.
        Una vez controlado el incidente, se deberá estudiar la causa de este en búsqueda de prevenir que este incidente vuelva a ocurrir.
        Esta política deberá ser revisada, actualizada y autorizada anualmente.
        Cada 3 meses se deberá revisar y actualizar el listado de medios de contacto de los proveedores de informática forense.
        Cada 30 días se deberá revisar el listado del grupo de respuesta a incidentes y verificar que todos tengan comunicación a través de los medios definidos en esta política.
        </Content> 

        <SubtTitle>
        Política de Resguardo de Información
        </SubtTitle>

        <Content>
        Nuestra política de resguardo de información establece diferentes parámetros de resguardo para los diferentes tipos de información que se gestionan en el sistema:
         Bases de datos: Periodicidad de respaldos es diariamente los 365 del año de toda la base de datos. Se tendrán los respaldos de los últimos 30 días solamente.
         Respaldos de archivos de imágenes y archivos adjuntos: Estos documentos se respaldan diariamente y dichos respaldos actualizan el último respaldo existente. Estos respaldos son permanentes, es decir que todos los archivos históricos prevalecen resguardados siempre.
         Logs: de accesos, actividad de usuarios y de intercambio de información con otros sistemas estarán disponibles los últimos 6 meses.
        Esta política establece los tiempos de respaldo para la data y documentos que se requieren para recuperación y/o restablecimiento de la operación caso de eventualidades o contingencias.
        </Content> 


        <SubtTitle>
        Declaración de Descargo de Responsabilidades
        </SubtTitle>

        <Content>
          <Paragraph>
          A) Orbinetwork no se hace responsable de la información que proporcionan los usuarios al crear sus cuentas. Por eso le recomendamos que verifique con sus contactos que el Orbi_Id (nombre del usuario) de la empresa que pretende agregar a su red de contactos pertenezca realmente a la empresa que usted ya conoce. Orbinetwork No es una red para conocer nuevas empresas, le recomendamos utilizarlo para conectarse con las empresas que usted ya conoce. No agregue a su red a empresas que no conozca, no es responsabilidad de Orbinetwork si
          llega a autorizar cuentas apócrifas de empresas aparentemente conocidas.
          </Paragraph>

          <Paragraph>
          B) Orbinetwork no es responsable de la legitimidad, veracidad o precisión de la información de ninguno de los catálogos de las cuentas de las empresas de los usuarios. Orbinetwork no verifica los domicilios ni ubicación de las instalaciones que los usuarios registran en sus catálogos de Instalaciones. Es responsabilidad de los usuarios registrar debidamente los domicilios y ubicaciones de sus instalaciones. Tampoco es nuestra responsabilidad la información de los catálogos de productos, flotilla vehicular, equipos de transporte o la información de los choferes. Toda la información es responsabilidad del dueño de la cuenta,
          no de Orbinetwork.
          </Paragraph>

          <Paragraph>
          C) Orbinetwork no vende, no fabrica, no distribuye ningún tipo de producto o materia prima, ni
          ofrece ningún tipo de servicio de logística. Los servicios que prestan los usuarios son totalmente ajenos a orbinetwork cualquier duda, aclaración o reclamo con respecto algún servicio prestado por algún usuario deberá realizarse con el usuario directamente a través de los medios que para ello dispongan. Orbinetwork es un sistema que ofrece herramientas que sirven como canal de comunicación para transmitir a otros usuarios la información que los interesados desean compartir. El contenido de dicha información es totalmente ajena a nuestra empresa y es responsabilidad de los usuarios, por lo que cualquier solicitud, queja o sugerencia relacionada a la calidad de los productos o servicios prestados por los usuarios no será atendida, no es nuestra responsabilidad y le sugerimos que contacte directamente al usuario involucrado.
          </Paragraph>

          <Paragraph>
          D) Orbinetwork no garantiza la seguridad de la información ante ataques cibernéticos, por lo que sugerimos no compartir información que sea de carácter confidencial que ponga en riesgo la integridad de su negocio, por lo que le sugerimos que no utilice las mismas contraseñas que utiliza para otros fines y que puedan ser sensibles para su empresa.
          </Paragraph>

          <Paragraph>
          E) Orbinetwork no reembolsará el costo de los créditos en embarques ya realizados, o cancelados, incluso si los embarques no fueron concluidos por alguna razón ajena a orbinetwork. el desempeño de los transportistas ni de ninguno de los proveedores de servicios registrados en orbinetwork. Solamente podrá solicitar el reembolso del monto pagado por los créditos no utilizados si el usuario desea cancelar su cuenta dentro de los primeros 60 días naturales posteriores a la fecha de la última compra de créditos. Las membresías mensuales no son reembolsables, solo las anuales y se reembolsará solamente el monto correspondiente de los meses por venir. El mes en curso y los transcurridos no son reembolsables.
          </Paragraph>

          <Paragraph>
          F) El desempeño del sistema depende en gran medida de la velocidad de la conexión de internet, el navegador, así como del dispositivo o computadora que utilice el usuario para acceder al sistema, por lo tanto, es probable que la experiencia del usuario se vea afectado dichos factores los cuales no son responsabilidad de Orbinetwork. De cualquier manera, es importante reportar cualquier anomalía que los usuarios detecten, favor de contactarnos a través de los medios de atención a usuarios.
          </Paragraph>

          <Paragraph>
          G) Orbinetwork no es un portal para buscar nuevos proveedores o clientes, no es un lugar de encuentro para buscar nuevos productos o servicios, Orbinetwork es una red que te permite conectarte con otros usuarios-empresas que ya conoces y con los que tu empresa ya tiene una relación comercial actualmente, no recomendamos que busques hacer nuevos contactos.
          </Paragraph>

          <Paragraph>
          H) Tampoco es un portal que ofrece servicios de logística, solo te conecta con tus proveedores actuales que te brindan algún servicio dentro de tu cadena de logística y abastecimiento, Orbinetwork solo ofrece las herramientas para conectar a las empresas que actualmente forman parte de tu cadena de abastecimiento y que participan en tus operaciones regularmente.
          </Paragraph>

        </Content> 

      </MainContent>
    );
  }
}

export default TermsConditionsView;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
`;

const MainTitle = styled.div`
  color: white;
  font-size: 60px;
  margin-top: 50px;
  text-align: center;
`;

const MainSubtTitle = styled.div`
  color: white;
  font-size: 21px;
  margin-top: 50px;
`;

const SubtTitle = styled.div`
  color: white;
  font-size: 21px;
  margin-top: 50px;
  text-align: center;
`;

const Content = styled.div`
  color: white;
  font-size: 17px;
  margin-top: 20px;
`;

const Paragraph = styled.div`
  margin-top: 10px;
`;

const TextLink = styled.div`
  color: blue;
  cursor: pointer;
  font-size: 21px;
  text-decoration: underline;
`;