// @flow
import React from "react";
import { TableCollapsible } from "./styled";
import { AssembleModalOperatorRow } from "./assemble-modal-operator-row";
import { Flex } from "../../../shared/styled/flex";

type Props = {
  onlyOne: boolean,
  tableClassification: Array<{
    id: number,
    title: string,
    icon: any,
    description: any,
  }>,
  headers: Array<any>,
  data: Array<any>,
  onChange: Function,
  initialStateCollapsed: boolean,
};

const AssembleTableOperatorRowBlock = ({
  tableClassification,
  data,
  headers,
  onChange,
  onlyOne,
  initialStateCollapsed,
}: Props) => {
  return tableClassification.map(
    (classification: {
      id: number,
      title: string,
      icon: any,
      description: any,
    }) => (
      <TableCollapsible
        initialStateCollapsed={initialStateCollapsed}
        key={classification.id}
        headStyle={{
          backgroundColor: "rgb(244,246,248)",
        }}
        contentStyle={{ display: "flex", flexDirection: "column" }}
        title={
          <Flex>
            {classification.icon}
            {classification.description}
          </Flex>
        }
      >
        {data
          .filter((f) =>
            f.licenseType.find((lt) => lt === classification.abbreviation)
              ? true
              : false
          )
          .map((operator) => {
            return (
              <AssembleModalOperatorRow
                {...{ operator, headers, onChange, onlyOne }}
              />
            );
          })}
      </TableCollapsible>
    )
  );
};

export default AssembleTableOperatorRowBlock;
