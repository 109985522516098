import React from "react";
import Button from "../controls/button";
import PropTypes from "prop-types";

export const GRID = "grid";
export const LIST = "list";

class CatalogHeader extends React.Component {
  handleToggleView = (viewMode) => {
    if (this.props.viewMode !== viewMode) {
      this.props.toggleView(viewMode);
    }
  };

  render() {
    return (
      <div className="header">
        <div className="title">{this.props.title}</div>
        <div className="view-mode">
          {this.props.showViewMode ? (
            <>
              <div
                className={
                  this.props.viewMode === GRID ? "grid active" : "grid"
                }
                onClick={() => this.handleToggleView(GRID)}
              ></div>
              <div
                className={
                  this.props.viewMode === LIST ? "list active" : "list"
                }
                onClick={() => this.handleToggleView(LIST)}
              ></div>
            </>
          ) : (
            ""
          )}
        </div>
        {this.props.children}
        <div className="action">
          {this.props.actionButtons.map((buttonSettings, index) => {
            return <Button {...buttonSettings} key={index} />;
          })}
        </div>
      </div>
    );
  }
}

export default CatalogHeader;

CatalogHeader.propTypes = {
  viewMode: PropTypes.oneOf([GRID, LIST]),
  title: PropTypes.string,
  actionButtonSettings: PropTypes.object,
  toggleView: PropTypes.func,
};

CatalogHeader.defaultProps = {
  showViewMode: true,
  toggleView: () => {},
  actionButtons: [],
  viewMode: LIST,
};
