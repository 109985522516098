import React from 'react';
import Button from '../../shared/controls/button';
import Checkbox from '../../shared/controls/checkbox';
import TextInput from '../../shared/controls/text-input';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import CollapsableParentContainer from './collapsable-parent-container';
import ProductRow from './product-row';

class OrdersControlDetailProductTab extends React.Component {
  state = {
    selectedProducts: [],
    showPalletModal: false,
    showConfirmModal: false,
    showConfirmDeleteModal: false,
    palletName: '',
    selectedPackage: 0
  }

  

  // handleSelectProduct = (productId) => {
  //   // debugger;
  //   if(!this.state.selectedProducts.includes(productId)){
  //     let ids = [...this.state.selectedProducts];
  //     ids.push(productId);
  //     this.setState({selectedProducts: ids})
  //   } else {
  //     let ids = this.state.selectedProducts.filter(i => i !== productId);
  //     this.setState({selectedProducts: ids});
  //   }
  // }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  closePalletModal = () => {
    this.props.resetModalData();
    this.setState({
      // palletName: "",
      showPalletModal: false,
      showConfirmModal: false,
      showConfirmDeleteModal: false,
      selectedPackage: 0
    })
  }
  
  createPallet = () => {
    this.props.checkPalletCode(this.closePalletModal);
    // this.props.updatePackages();
    // this.props.resetModalData();
    // this.setState({
    //   // selectedProducts: [],
    //   // palletName: "",
    //   showPalletModal: false,
    //   showConfirmModal: false
    // })
  }
  
  evaluatePallets = () => {
    let products = this.props.products.filter(p => this.props.selectedProducts.includes(p.id));
    return products.some(p => p.pallet);
  }

  deletePackage = () => {
    this.props.deletePackage(this.state.selectedPackage);
    this.closePalletModal();
  }

  getOrderRows = () => {
    // let pallets = [];
    let pallets = (this.props.pallets || []).map(pallet => {
      let skus = [];
      (pallet.package_pallet || []).forEach(p => {
        let index = skus.findIndex(sku => sku.id === p.product.id);
        if(index === -1){
          skus.push({
            id: p.product.id,
            code: p.product.description,
            sku: p.product.sku,
            units: 1,
            package: null,
            net_weight: p.product.net_weight,
            gross_weight: p.product.gross_weight,
            volume: p.product.volume,
            original_net_weight: p.product.net_weight,
            original_gross_weight: p.product.gross_weight,
            original_volume: p.product.volume,
            childrenElements: [
              <div key={p.id} className={"row-container ".concat((this.props.selectedProducts.includes(p.id) && p.pallet) ? ' conflict' : '')}>
                <Checkbox
                  onChange={() => {this.props.handleSelectProduct(p.id)}}
                  item={{
                    description: "",
                    // active: false,
                    id: p.id,
                  }}
                  name="multiSelect"
                  active={this.props.selectedProducts.includes(p.id)}
                />
                <ProductRow
                  productInfo={{...p, description: 1}}
                  qty={''}
                  // unitCatalog={[]}
                  className=""
                  removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
                />
              </div>
            ]
          })
        } else {
          skus[index] = {
            ...skus[index],
            units: skus[index].units + 1,
            net_weight: skus[index].original_net_weight * (skus[index].units + 1),
            gross_weight: skus[index].original_gross_weight * (skus[index].units + 1),
            volume: skus[index].original_volume * (skus[index].units + 1),
            childrenElements: skus[index].childrenElements.concat([
              <div key={p.id} className={"row-container ".concat((this.props.selectedProducts.includes(p.id) && p.pallet) ? ' conflict' : '')}>
                <Checkbox
                  onChange={() => {this.props.handleSelectProduct(p.id)}}
                  item={{
                    description: "",
                    // active: false,
                    id: p.id,
                  }}
                  name="multiSelect"
                  active={this.props.selectedProducts.includes(p.id)}
                />
                <ProductRow
                  productInfo={{...p, description: skus[index].units + 1}}
                  qty={''}
                  // unitCatalog={[]}
                  className=""
                  removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
                />
              </div>
            ])
          }
        }
      })
      skus = skus.map(sku => (<CollapsableParentContainer 
        childrenElements={sku.childrenElements}
        className='collapsable-order-row'
      >
        <ProductRow
          emptyImage={true}
          emptyActions={true}
          qty={sku.units}
          productInfo={{
            product: {
              description: sku.code,
              sku: sku.sku,
              gross_weight: sku.gross_weight,
              net_weight: sku.net_weight,
              volume: sku.volume
            }
          }}
          // qty={<div><b>{p.pallet?.code || ''}</b></div>}
          // unitCatalog={[]}
          // className=""
          // removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
        />
      </CollapsableParentContainer>))
      return (<CollapsableParentContainer 
        childrenElements={skus}
        className='collapsable-order-row'
      >
        <ProductRow
          emptyImage={true}
          emptyActions={true}
          qty={pallet.package_pallet.length}
          productInfo={{
            product: {
              description: pallet.code,
              sku: '',
              gross_weight: pallet.gross_weight,
              net_weight: pallet.net_weight,
              volume: pallet.volume
            }
          }}
          // qty={<div><b>{p.pallet?.code || ''}</b></div>}
          // unitCatalog={[]}
          // className=""
          // removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
        />
      </CollapsableParentContainer>)
    })
    if(this.props.products && this.props.products.length){
      let skus = [];
      (this.props.products || []).forEach(p => {
        let index = skus.findIndex(sku => sku.id === p.product.id);
        if(index === -1){
          skus.push({
            id: p.product.id,
            code: p.product.description,
            sku: p.product.sku,
            units: 1,
            package: null,
            net_weight: p.product.net_weight,
            gross_weight: p.product.gross_weight,
            volume: p.product.volume,
            original_net_weight: p.product.net_weight,
            original_gross_weight: p.product.gross_weight,
            original_volume: p.product.volume,
            childrenElements: [
              <div key={p.id} className={"row-container ".concat((this.props.selectedProducts.includes(p.id) && p.pallet) ? ' conflict' : '')}>
                <Checkbox
                  onChange={() => {this.props.handleSelectProduct(p.id)}}
                  item={{
                    description: "",
                    // active: false,
                    id: p.id,
                  }}
                  name="multiSelect"
                  active={this.props.selectedProducts.includes(p.id)}
                />
                <ProductRow
                  productInfo={{...p, description: 1}}
                  qty={''}
                  // unitCatalog={[]}
                  className=""
                  removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
                />
              </div>
            ]
          })
        } else {
          skus[index] = {
            ...skus[index],
            units: skus[index].units + 1,
            net_weight: skus[index].original_net_weight * (skus[index].units + 1),
            gross_weight: skus[index].original_gross_weight * (skus[index].units + 1),
            volume: skus[index].original_volume * (skus[index].units + 1),
            childrenElements: skus[index].childrenElements.concat([
              <div key={p.id} className={"row-container ".concat((this.props.selectedProducts.includes(p.id) && p.pallet) ? ' conflict' : '')}>
                <Checkbox
                  onChange={() => {this.props.handleSelectProduct(p.id)}}
                  item={{
                    description: "",
                    // active: false,
                    id: p.id,
                  }}
                  name="multiSelect"
                  active={this.props.selectedProducts.includes(p.id)}
                />
                <ProductRow
                  productInfo={{...p, description: skus[index].units + 1}}
                  qty={''}
                  // unitCatalog={[]}
                  className=""
                  removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
                />
              </div>
            ])
          }
        }
      })
      skus = skus.map(sku => (<CollapsableParentContainer 
        childrenElements={sku.childrenElements}
        className='collapsable-order-row'
      >
        <ProductRow
          emptyImage={true}
          emptyActions={true}
          qty={sku.units}
          productInfo={{
            product: {
              description: sku.code,
              sku: sku.sku,
              gross_weight: sku.gross_weight,
              net_weight: sku.net_weight,
              volume: sku.volume
            }
          }}
          // qty={<div><b>{p.pallet?.code || ''}</b></div>}
          // unitCatalog={[]}
          // className=""
          // removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
        />
      </CollapsableParentContainer>))
      pallets = pallets.concat([
        <CollapsableParentContainer 
          childrenElements={skus}
          className='collapsable-order-row'
        >
          <ProductRow
            emptyImage={true}
            emptyActions={true}
            qty={this.props.products.length}
            productInfo={{
              product: {
                description: 'Sin tarima',
                sku: '',
                gross_weight: '',
                net_weight: '',
                volume: ''
              }
            }}
            // qty={<div><b>{p.pallet?.code || ''}</b></div>}
            // unitCatalog={[]}
            // className=""
            // removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
          />
        </CollapsableParentContainer>
      ])
    }
    return pallets;
  }

  render() {
    return (
      <div className="products tab-body">
        <div className="header">
          Productos
        </div>
        <div>
          Carrito de compra: verifica la selección de tus productos y finaliza la órden de venta.
        </div>
        <div className="table-header" style={{paddingLeft: "30px"}}>
          <div style={{width: "30%"}}>{this.props.isOrder ? 'Tarima' : 'Producto'}</div>
          <div style={{width: "23%"}}>SKU</div>
          {/* <div style={{width: "10%"}}>Tarima</div> */}
          <div style={{width: "9%"}}>{this.props.isOrder ? 'Bultos' : 'Tarima'}</div>
          <div style={{width: "12%"}}>Peso Neto</div>
          <div style={{width: "12%"}}>Peso Total</div>
          <div style={{width: "12%"}}>Volumen</div>
          <div style={{width: "2%"}}></div>
        </div>
        <div className="table-body">
          
          {
            this.props.isOrder 
            ? this.getOrderRows()
            : this.props.products.map(p => (
              <div key={p.id} className={"row-container ".concat((this.props.selectedProducts.includes(p.id) && p.pallet) ? ' conflict' : '')}>
                <Checkbox
                  onChange={() => {this.props.handleSelectProduct(p.id)}}
                  item={{
                    description: "",
                    // active: false,
                    id: p.id,
                  }}
                  name="multiSelect"
                  active={this.props.selectedProducts.includes(p.id)}
                />
                <ProductRow
                  productInfo={p}
                  qty={<div><b>{p.pallet?.code || ''}</b></div>}
                  unitCatalog={[]}
                  className=""
                  removeProduct={(id) => {this.setState({showConfirmDeleteModal: true, selectedPackage: id})}}
                />
              </div>
            ))
          }
        </div>
        <div className="resume">
          <div style={{width: "30%"}}>Total</div>
          <div style={{width: "23%"}}></div>
          {/* <div style={{width: "10%"}}>this.props.selectedProducts.reduce((acum, value) => {return (acum + Number(value.qty))}, 0)</div> */}
          <div style={{width: "9%"}}></div>
          <div style={{width: "12%"}}>{}</div>
          <div style={{width: "12%"}}>{}</div>
          <div style={{width: "12%"}}>{}</div>
          <div style={{width: "2%"}}></div>
        </div>
        <div className="actions">
          {
            !this.props.isOrder &&
            <Button 
              type="primary"
              text="Entarimar"
              onClick={this.evaluatePallets() ? (() => {this.setState({showConfirmModal: true})}) : (() => {this.setState({showPalletModal: true})})}
              disabled={!this.props.selectedProducts.length}
            />
          }
        </div>
        <ConfirmDialog
          closeAction={this.closePalletModal}
          open={this.state.showPalletModal}
          title={'Entarimar'}
          message={''}
          acceptText={'Guardar'}
          acceptAction={this.createPallet}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          disabledAccept={!this.props.palletName}
          contentObject={
            <div style={{width: "100%"}}>
              <div>
                <TextInput
                  label="Código de tarima"
                  name="palletName"
                  value={this.props.palletName}
                  className="item"
                  // integer={true}
                  onChange={this.props.handleChange}
                  // disabled={!this.props.group.edit}
                  // errors={this.props.alert.id === 4 ? [] : this.getDurationError(level, i, a, this.props.group.id)}
                />
              </div>
              <div>
                Agregar {this.props.selectedProducts.length} bulto(s) a la tarima
              </div>
              {
                this.props.errorMsg &&
                <div style={{
                  marginTop: '10px',
                  fontSize: '13px',
                  color: '#ff0909',
                  textAlign: 'center',
                }}>
                  {this.props.errorMsg}
                </div>
              }
            </div>
          }
        />
        <ConfirmDialog
          closeAction={() => {this.setState({showConfirmModal: false})}}
          open={this.state.showConfirmModal}
          title={'Entarimar'}
          message={'Seleccionaste bultos que ya están en otra tarima, ¿deseas continuar?'}
          acceptText={'Confirmar'}
          acceptAction={() => {this.setState({showPalletModal: true, showConfirmModal: false})}}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          // disabledAccept={!this.state.palletName}
          // contentObject={}
        />
        <ConfirmDialog
          closeAction={this.closePalletModal}
          open={this.state.showConfirmDeleteModal}
          title={'Eliminar'}
          message={'¿Seguro que deseas eliminar este bulto del pedido?'}
          acceptText={'Confirmar'}
          acceptAction={this.deletePackage}
          showCancel={true}
          cancelText={'Cancelar'}
          class='no-bottom-margin'
          // disabledAccept={!this.state.palletName}
          // contentObject={}
        />
      </div>
    )
  }
}

export default OrdersControlDetailProductTab;

const PRODUCTS = [
  {
    id: 1,
    description: 'Modelo especial',
    sku: '1236289101820',
    gross_weight: 1029,
    net_weight: 1111,
    volume: 25,
    pallet: null
  },
  {
    id: 2,
    description: 'Modelo especial',
    sku: '1236289101820',
    gross_weight: 1029,
    net_weight: 1111,
    volume: 25,
    pallet: '98765'
  },
]