import React, { Component } from 'react';
import { getUrlsEnv } from '../backend-api';

let filesPath = getUrlsEnv().files;

class TruckIcon extends Component {

  render() {
    return(
      <div className={'truck-icon'}>

        {
          (this.props.image === '') ?
          <div className={'icon-image'} style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
            <img src={'/images/modal-factory-blue-icon.svg'} alt={''} onError={(e)=>{e.target.onerror = null; e.target.src='/images/modal-factory-blue-icon.svg'}}/>
          </div>
          :
          <img className={'icon-image'} src={filesPath.concat(this.props.image)} alt={''} onError={(e)=>{e.target.onerror = null; e.target.src='/images/modal-factory-blue-icon.svg'}}/>
        }
        
        <div className={'text'}>{this.props.title}</div>

      </div>
    );
  }

}

export default TruckIcon;
