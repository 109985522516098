import React, { Component } from "react";
import { connect } from "react-redux";
import Panel from "../shared/component/panel";
import TextInput from "../shared/controls/text-input";
import Button from "../shared/controls/button";
import InputProfileImage from "../shared/controls/input-profile-image";
import SelectImage from "../shared/controls/select-image";
import {
  getSuggestedCompanies,
  patchRegisterAccount,
  getAcceptedCompanies,
  sendInvitationCompany,
} from "../redux/reducers/register-account.reducer";
import ErrorDialog from "../shared/dialog/error-dialog";
import { getUrlsEnv } from "../shared/backend-api";
import {
  loadSession,
  setSessionUser,
  setSessionCompany,
} from "../redux/reducers/global-catalog.reducer";
import { validators } from "../shared/validate";
import { withRouter } from "react-router-dom";
import InvitationDialog from "../shared/dialog/send-invitation-modal";
import ReactTooltip from "react-tooltip";
import ConfirmDialog from "../shared/dialog/confirm-dialog";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

let profileImage;
let user = null;
// FIX: NO utilizar funciones inline, extraerlas a nivel del componente
// FIX: NO hay necesidad de renderizar el segundo panel fuera del metodo render
// FIX: este componente no recibe props name, lastname, companyselected ni profileimage

class RegisterAccountView extends Component {
  constructor(props) {
    super(props);

    this.userLoaded = false;

    let storage = JSON.parse(
      window.sessionStorage.getItem("orbinetwork_session")
    );
    user = storage.user;

    const { files } = getUrlsEnv();
    profileImage = `${files}images/users/pictures/${user.id}.jpg`;

    this.state = {
      name: props.name || "",
      lastName: props.lastName || "",
      companySelected: props.companySelected || [],
      profileImage: props.profileImage || null,
      // validForm: false,
      invitationSent: false,
      showError: false,
      showInvitationModal: false,
      onFocused: false,
      validSendRequest: true,

      crop: {
        unit: "%",
        width: 300,
        aspect: 1 / 1,
      },
      editImage: false,
      imageToCrop: null,
    };
  }

  isValid(url) {
    fetch(url).then((response) =>
      this.setState({
        profileImage: response.status === 200 ? profileImage : null,
      })
    );
  }

  componentDidMount() {
    this.isValid(profileImage);
  }

  componentDidUpdate() {
    if (this.props.userId && !profileImage) {
      const { files } = getUrlsEnv();
      profileImage = `${files}images/users/pictures/${this.props.userId}.jpg`;
    }
    if (this.props.companyId && this.state.companySelected.length === 0)
      this.handleChange({
        target: {
          name: "companySelected",
          value: [this.props.companyId],
        },
      });

    // if (this.validateForm() !== this.state.validForm)
    //   this.setState({ validForm: !this.state.validForm });

    if (!this.userLoaded && this.props.user) {
      this.userLoaded = true;
      this.setState({
        name: this.props.user.first_name,
        lastName: this.props.user.last_name,
      });
    }

    this.validateButtonInvitation();
  }

  validateButtonInvitation = () => {
    if (
      this.state.name !== "" &&
      this.state.lastName !== "" &&
      this.state.validSendRequest === true
    ) {
      this.setState({
        validSendRequest: false,
      });
    } else if (
      (this.state.name === "" || this.state.lastName === "") &&
      this.state.validSendRequest === false
    ) {
      this.setState({
        validSendRequest: true,
      });
    }
  };

  validateForm = () => {
    return (this.props.isSentInvitation ||
      this.props.acceptedCompanies.length > 0) &&
      this.state.name !== "" &&
      this.state.lastName !== ""
      ? true
      : false;
  };

  isCompanyIdSelected = (companyId) => {
    const { companySelected } = this.state;
    return companySelected.includes(companyId);
  };

  handleClickCompany = (company, newId) => {
    const { companySelected } = this.state;

    if (this.isCompanyIdSelected(company.id)) {
      this.setState({
        companySelected: companySelected.filter((id) => id !== company.id),
      });
    } else {
      this.setState({
        companySelected: companySelected.concat(newId),
      });
    }
  };

  handlePicture = () => {
    this.refs.fileUploader.value = "";
    this.refs.fileUploader.click();
  };

  actionCloseDialog = () => {
    this.setState({
      openDialog: false,
      showError: false,
      showInvitationModal: false,
    });
  };

  handleSave = (route = "/configurations") => {
    const { name, lastName, companySelected, profileImage } = this.state;
    const { user_name, email, userId } = this.props;
    this.props
      .registerAccount(
        name,
        lastName,
        companySelected,
        profileImage,
        user_name,
        email,
        userId
      )
      .then(
        (response) => {
          if (response.type === "RECEIVE_REGISTER_ACCOUNT") {
            this.props.setSessionUser({
              first_name: name,
              last_name: lastName,
            });

            if (
              this.props.acceptedCompanies.length > 1 &&
              route !== `/register/company`
            ) {
              let storage = JSON.parse(
                window.sessionStorage.getItem("orbinetwork_session")
              );

              storage.listCompanies = this.props.acceptedCompanies;

              window.sessionStorage.setItem(
                "orbinetwork_session",
                JSON.stringify(storage)
              );
              this.props.history.push("/company/select");
            } else {
              if (Array.isArray(companySelected) && companySelected.length > 0)
                this.props.setSessionCompany({ id: companySelected[0] });
              this.props.history.push(route);
            }
          } else {
            this.setState({
              showError: true,
              showInvitationModal: false,
              msgError: "Comprueba los datos",
            });
          }
        },
        (error) =>
          this.setState({
            showError: true,
            showInvitationModal: false,
            msgError: "Falló la conexión",
          })
      );
  };

  handleLeave = () => {
    this.setState({ onFocused: true });
  };

  getErrors = () => {
    let errors =
      this.state.name === "" || this.state.lastName === ""
        ? "Por favor completa los siguientes campos:<br/>"
        : "";
    errors += this.state.name === "" ? `<b>Nombre<b/><br/>` : "";
    errors += this.state.lastName === "" ? `<b>Apellidos<b/><br/>` : "";

    if (
      this.props.acceptedCompanies.length === 0 &&
      this.props.suggestedCompanies.length > 0 &&
      !this.props.isSentInvitation
    ) {
      if (errors === "")
        errors += `Por favor completa los siguientes campos:<br/><b>Enviar solicitud<b/>`;
      else errors += `<b>Enviar solicitud<b/>`;
    }

    return errors;
  };

  dragAndDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let dt = e.dataTransfer;
    let files = dt.files;

    let pictureFrame = document.getElementsByClassName("pictureFrame");
    let pictureButton = document.getElementsByClassName("btnPicture");

    switch (e.type) {
      case "dragenter":
      case "dragover":
        pictureFrame[0].style.transform = "scale(1.1, 1.1)";

        pictureButton[0].style.color = "#3D77F7";
        pictureButton[0].style.border = "2px solid #ffffff";
        pictureButton[0].style.backgroundColor = "#ffffff";
        break;
      default:
        pictureFrame[0].style.transform = "scale(1.0, 1.0)";

        pictureButton[0].style.color = "rgb(232, 240, 255)";
        pictureButton[0].style.border = "2px solid white";
        pictureButton[0].style.background = "none";

        var reader = new FileReader();
        reader.addEventListener("load", () => {
          this.setState({
            imageToCrop: reader.result,
            editImage: true,
          });
        });
        reader.readAsDataURL(files[0]);
        break;
    }
  };

  handleChange = (event) => {
    let property = event.target.name;
    let value = event.target.value;
    this.setState({ [property]: value });
  };

  renderAccountPanel = () => {
    let suggestedCompanies = this.props.suggestedCompanies;
    if (this.props.acceptedCompanies.length && suggestedCompanies.length) {
      suggestedCompanies = suggestedCompanies.filter((item) => {
        return item.id !== this.props.acceptedCompanies[0].id;
      });
    }

    return (
      <React.Fragment>
        <div className="companies" style={{ display: "flex" }}>
          {suggestedCompanies.length > 0 && (
            <div
              className="suggested"
              style={{
                width: this.props.acceptedCompanies.length > 0 ? "34%" : "99%",
              }}
            >
              <div className="title-suggested-company">Mi organización</div>

              {suggestedCompanies.map((c, index) => {
                const { files } = getUrlsEnv();

                let newCompany = {
                  id: c.id,
                  name: c.corporation_name,
                  img: `${files}${c.logo}`,
                };

                return (
                  <SelectImage
                    data={newCompany}
                    key={index}
                    isSelected={false}
                    handleClick={(id) => () => {}}
                  />
                );
              })}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "center",
                  justifyContent: "center",
                  marginTop: "-30px",
                }}
              >
                <Button
                  text={
                    this.props.isSentInvitation
                      ? "Solicitud enviada"
                      : "Enviar solicitud"
                  }
                  type={"primary"}
                  disabled={this.state.validSendRequest}
                  onClick={() => {
                    if (
                      suggestedCompanies &&
                      suggestedCompanies.length > 0 &&
                      suggestedCompanies[0].id &&
                      user.id
                    ) {
                      this.props
                        .sendInvitation(user.id, suggestedCompanies[0].id)
                        .then((response) => {
                          if (
                            !this.props.isFetchingEmail &&
                            this.props.isSentInvitation
                          ) {
                            this.setState({
                              showInvitationModal: true,
                              showError: false,
                            });
                          }
                        });
                    }
                  }}
                />
              </div>
            </div>
          )}

          {suggestedCompanies.length > 0 &&
            this.props.acceptedCompanies.length > 0 && (
              <div className="line_seperator" />
            )}

          {this.props.acceptedCompanies.length > 0 && (
            <div className="invited">
              <div className="title-invited-company">
                Invitaciones de otras organizaciones
              </div>

              <div className="container">
                {this.props.acceptedCompanies.map((c, index) => {
                  const { files } = getUrlsEnv();
                  let imageToShow = "";

                  if (c.logo) {
                    imageToShow = `${files}${c.logo}`;
                  } else {
                    imageToShow = "images/factory-bckgrnd.png";
                  }

                  let newCompany = {
                    id: c.id,
                    name: c.corporation_name,
                    img: imageToShow,
                  };
                  return (
                    <SelectImage
                      className={
                        this.props.acceptedCompanies.length > 7 ? "mini" : ""
                      }
                      data={newCompany}
                      key={index}
                      isSelected={true}
                      handleClick={(id) => () => {}}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="register">
        <input
          accept="image/*"
          type="file"
          id="file"
          ref="fileUploader"
          style={{ display: "none" }}
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
            var reader = new FileReader();
            reader.addEventListener("load", () => {
              this.setState({
                imageToCrop: reader.result,
                editImage: true,
              });
            });
            reader.readAsDataURL(event.target.files[0]);
          }}
        />

        <img alt="" className="logo" src={"/images/logo-white.svg"} />

        <h1 className="title">¡Bienvenido!</h1>
        <h1 className="subtitle">Vamos a configurar tu cuenta</h1>

        <Panel title="Tus datos" number="01" className="panel">
          <div className="form">
            <div className="data">
              <TextInput
                type="text"
                label="*¿Cuál es tu nombre?"
                name="name"
                className="full"
                onChange={this.handleChange}
                onFocused={this.state.onFocused}
                value={this.state.name}
                validate={[validators.required]}
              />

              <TextInput
                type="text"
                label="*¿Cuales son tus apellidos?"
                name="lastName"
                className="full"
                onChange={this.handleChange}
                onFocused={this.state.onFocused}
                value={this.state.lastName}
                validate={[validators.required]}
              />
            </div>

            <InputProfileImage
              dragAndDrop={this.dragAndDrop}
              handlePicture={this.handlePicture}
              profileImage={this.state.profileImage}
              caption="Dale clic al cuadro para agregar tu foto o bien, arrástrala desde tu computadora:"
            />
          </div>
        </Panel>

        {this.props.suggestedCompanies.length === 0 &&
        this.props.acceptedCompanies.length === 0 ? (
          <Panel title="" number="02">
            <div className="no-companies-title">
              No encontramos ninguna empresa relacionada con tu correo
              <Button
                text={"Crear Empresa"}
                type={"secondary"}
                onClick={() => this.handleSave(`/register/company`)}
              />
            </div>
          </Panel>
        ) : (
          <React.Fragment>
            <Panel title="Administra tu cuenta" number="02">
              {this.renderAccountPanel()}

              <div className="companies-panel">
                <p>
                  Si no es ninguna de las empresas anteriores, entonces es
                  necesario{" "}
                </p>
                <p
                  className="create_company"
                  // onClick={() => this.handleSave(`/register/company`)}
                  style={{
                    cursor:
                      this.state.lastName.length === 0 ||
                      this.state.name.length === 0
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => {
                    this.state.lastName.length > 0 &&
                      this.state.name.length > 0 &&
                      this.handleSave(`/register/company`);
                  }}
                >
                  crear tu empresa
                </p>
              </div>
            </Panel>

            <div className="contentButton">
              <Button
                text={"Seguir"}
                type={"primary"}
                disabled={!this.validateForm()}
                onMouseLeave={() => this.handleLeave()}
                onClick={() => this.handleSave()}
                dataTip={this.getErrors()}
                dataFor="orbi-tooltip"
              />

              <ReactTooltip
                id={"orbi-tooltip"}
                place="left"
                type="light"
                effect="solid"
                html={true}
                event="mouseover"
                eventOff="mouseleave"
              />
            </div>
          </React.Fragment>
        )}

        <InvitationDialog
          open={this.state.showInvitationModal}
          title="¡Enviado!"
          message={"Hemos enviado una solicitud"}
          acceptText="Continuar"
          acceptAction={() => {
            this.setState({ showInvitationModal: false, showError: false });
          }}
          closeAction={() => {
            this.setState({ showInvitationModal: false, showError: false });
          }}
        />

        <ErrorDialog
          open={this.state.showError}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={this.actionCloseDialog}
          closeAction={this.actionCloseDialog}
        />

        <ConfirmDialog
          closeAction={this.closeImageEditModal}
          open={this.state.editImage}
          title={"Edita tu imagen"}
          message={"Edita tu imagen"}
          acceptText={"Guardar"}
          acceptAction={this.AcceptImageEdit}
          showCancel={true}
          cancelText={"Cancelar"}
          class="no-bottom-margin"
          contentObject={
            <ReactCrop
              src={this.state.imageToCrop}
              crop={this.state.crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              crossorigin="anonymous"
              style={{ maxHeight: "340px", overflow: "auto", margin: "10px" }}
            />
          }
        />
      </div>
    );
  }

  closeImageEditModal = () => {
    this.setState({ editImage: false });
  };
  AcceptImageEdit = () => {
    this.setState({
      profileImage: this.state.croppedImageUrl,
      editImage: false,
    });
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        var f = new File([blob], "image.jpeg");
        resolve(f);
      }, "image/jpeg");
    });
  }
}

const mapStateToProps = (state) => {
  return {
    acceptedCompanies: state.registerAccount.acceptedCompanies,
    suggestedCompanies:
      state.registerAccount.suggestedCompanies &&
      state.registerAccount.suggestedCompanies.length > 0
        ? [state.registerAccount.suggestedCompanies[0]]
        : [],
    userId: state.globalCatalog.session.user.id,
    companyId: state.globalCatalog.session.company.id || null,
    isFetchingEmail: state.registerAccount.isFetchingEmail,
    isSentInvitation: state.registerAccount.emailSent,
    error: state.registerAccount.error,
    user: state.globalCatalog.session.user,
    user_name: state.globalCatalog.session.user.username,
    email: state.globalCatalog.session.user.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(getSuggestedCompanies());
  dispatch(getAcceptedCompanies());

  return {
    sendInvitation: (userId, companyId) =>
      dispatch(sendInvitationCompany(userId, companyId)),
    registerAccount: (
      first_name,
      last_name,
      companies,
      profile_image,
      user_name,
      email,
      userId
    ) =>
      dispatch(
        patchRegisterAccount({
          first_name,
          last_name,
          companies,
          profile_image,
          user_name,
          email,
          userId,
        })
      ),
    setSessionUser: (user) =>
      dispatch(setSessionUser(Object.assign(user, { welcome: true }))),
    setSessionCompany: (data) => dispatch(setSessionCompany(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterAccountView)
);
