import React from 'react';
import styled from 'styled-components'
import Tag from '../../shared/controls/tag';
import AppointmentCard from './appointment-card';

class AppointmentSpaceRow extends React.Component {
  state = {
    spaces: []
  }

  spaceWidth = 100
  // Diff between end and begin of range
  diffRanges = this.props.range[1] - this.props.range[0]
  // Total of spaces
  totalAvailableSpaces = (this.diffRanges + this.spaceWidth) / this.spaceWidth
  // Size of one space
  widthSpaces = this.spaceWidth / this.totalAvailableSpaces - 0

  render () {
    const {
      className,
      rowIndex,
      loadManeuver,
      loadManeuverIcon,
      unloadManeuver,
      unloadManeuverIcon,
      unloadLoadManeuver,
      unloadLoadManeuverIcon,
      tag,
      spaceIndex,
      searchStr
    } = this.props
    return (
      <Wrapper className={className} {...this.props}>
        <LabelColumn>
          <CounterWrapper>{spaceIndex}</CounterWrapper>
          <LabelWrapper>
            <ManouverWrapper>
              {loadManeuver && loadManeuverIcon}
              {unloadManeuver && unloadManeuverIcon}
              {unloadLoadManeuver && unloadLoadManeuverIcon}
            </ManouverWrapper>
            {/* <OrbitNetLabels
              type="multiple"
              textColor="#436EA9"
              labels={tags}
              rowIndex={rowIndex}
            /> */}
            <Tag 
              color={tag.color}
              title={tag.title}
            />
          </LabelWrapper>
        </LabelColumn>
        <div
          className="scroll-test-traffic-row"
          style={{ width: '85%', overflow: 'hidden', zIndex: '9' }}
        >
          <SpaceWrapper>
            {this.getCards().map((space, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <AppointmentCard
                  spaceId={this.props.id}
                  id={i}
                  {...space}
                  key={i}
                />
              )
            })}
          </SpaceWrapper>
        </div>
      </Wrapper>
    )
  }

  getCards = () => {
    const { range, windows, conflicts } = this.props
    const winds = [...windows]
    let appoints = [...conflicts]
    // appoints = appoints.concat(conflicts);

    let result = []
    winds.sort((a, b) => a.begin - b.begin)
    appoints.sort((a, b) => a.begin - b.begin)
    // conflict.sort((a, b) => a.begin - b.begin)

    result = result.concat(
      this.getClosedCards(
        range[0],
        winds[0]?.begin >= 0 ? winds[0].begin : range[1] + this.spaceWidth
      )
    )
    winds.forEach((wnd, index) => {
      const windowAppoints = appoints.filter(
        ap => ap.begin >= wnd.begin && ap.end <= wnd.end
      )

      if (windowAppoints.length) {
        windowAppoints.forEach((app, indexWa, source) => {
          if (indexWa === 0) {
            result = result.concat(this.getFreeCards(wnd.begin, app.begin, wnd))
          }
        
          result = result.concat(this.getAppointmentCard(app))
          result = result.concat(
            this.getFreeCards(
              app.end,
              source[indexWa + 1] ? source[indexWa + 1].begin : wnd.end,
              wnd
            )
          )
        })
      } else {
        result = result.concat(this.getFreeCards(wnd.begin, wnd.end, wnd))
      }

      if (wnd.end <= range[1]) {
        result = result.concat(
          this.getClosedCards(wnd.end, winds[index + 1]?.begin || range[1] + this.spaceWidth)
        )
      }
    })

    // if (winds.pop()?.end <= range[1]) {
    //   result.push({
    //     close: true,
    //     margin: '0 0.2rem 0 0',
    //     width: `${this.widthSpaces}%`,
    //     end: range[1] + this.spaceWidth
    //   })
    // }
    return result
  }

  getClosedCards = (begin, end) => {
    // debugger;
    const beginFreeSpaces = Math.abs(begin - end) / this.spaceWidth
    const result = []
    const initialFraction = 1 - (begin % this.spaceWidth) / 100
    const endingFraction = (end % this.spaceWidth) / 100
    // debugger;
    if (initialFraction < 1) {
      result.push({
        close: true,
        margin: '0 0.2rem 0 0',
        width: `${this.widthSpaces * initialFraction}%`
      })
    }
    for (let space = 1; space <= beginFreeSpaces; space++) {
      result.push({
        close: true,
        margin: '0 0.2rem 0 0',
        width: `${this.widthSpaces}%`
      })
    }
    if (endingFraction < 1) {
      result.push({
        close: true,
        margin: '0 0.2rem 0 0',
        width: `${this.widthSpaces * endingFraction}%`
      })
    }
    // debugger;
    return result.filter(r => r.width !== '0%')
  }

  getFreeCards = (begin, end, wnd) => {
    // debugger;
    if (begin === end) return []
    const result = []
    let freeSpace = (end - begin) / this.spaceWidth
    const initialFraction = 1 - (begin % this.spaceWidth) / 100
    const endingFraction = (end % this.spaceWidth) / 100
    if (freeSpace < 1) {
      result.push({
        x: '1',
        free: true,
        width: `${this.widthSpaces * freeSpace}%`,
        begin: begin,
        margin: '0',
        // unused: this.validateCardWithCurrentTime(begin),
        // dropOnWindow: wnd.dropOnWindow
      })
      return result
    }
    if (initialFraction > 0 && initialFraction < 1) {
      result.push({
        x: '2',
        free: true,
        width: `${this.widthSpaces * initialFraction}%`,
        begin: begin,
        end: begin + initialFraction * 100,
        margin: '0',
        // unused: this.validateCardWithCurrentTime(begin),
        // dropOnWindow: wnd.dropOnWindow
      })
      freeSpace -= initialFraction
    }
    let lastSpaceEnd = 0
    freeSpace += .00000000001;
    //let space = initialFraction < 1 ? 1 : 0;
    for (let space = (initialFraction < 1 ? 1 : 0); freeSpace-- >= 1; space++) {
      lastSpaceEnd = begin + initialFraction * 100 + this.spaceWidth * space
      result.push({
        x: '3',
        free: true,
        width: `${this.widthSpaces}%`,
        begin: lastSpaceEnd - this.spaceWidth,
        end: lastSpaceEnd,
        margin: lastSpaceEnd % this.spaceWidth === 0 ? '0 0.2rem 0 0' : '0',
        // unused: this.validateCardWithCurrentTime(lastSpaceEnd),
        // dropOnWindow: wnd.dropOnWindow
      })
    }
    if (freeSpace && endingFraction) {
      result.push({
        x: '4',
        free: true,
        width: `${this.widthSpaces * endingFraction}%`,
        begin: end - this.spaceWidth * endingFraction,
        end: end,
        margin: '0 0.2rem 0 0',
        // unused: this.validateCardWithCurrentTime(end),
        // dropOnWindow: wnd.dropOnWindow
      })
    }
    // debugger;
    return result
  }

  getAppointmentCard = appointment => {
    const result = []
    const appointBegin = appointment.begin
    // const appointBegin = convertTimeToWidthSpace(appointment.begin)
    const appointEnd = appointment.end
    // const appointEnd = convertTimeToWidthSpace(appointment.end)
    const spaceAppointment = appointEnd - appointBegin
    const width = this.calculateWidthSpace(spaceAppointment)
    const orders = [];
    const searchStr = this.props.searchStr;

    if (appointment.orders) {
      appointment.orders.forEach(order => {
        const { begin, end } = this.getPosition(
          appointment,
          this.setTimetoPercent(order.begin),
          this.setTimetoPercent(order.end)
        )
        orders.push({
          ...order,
          searched: !this.props.searchStr || order.code.includes(searchStr),
          begin,
          end
        })
      })
    }

    result.push({
      ...appointment,
      orders,
      height: '100%',
      width,
      //   begin: appointBegin,
      //   end: appointEnd,
      margin: '0 0.2rem 0 0',
      borderRadius: appointEnd % this.spaceWidth !== 0 && '0.2rem',
      borderRight: appointEnd % this.spaceWidth !== 0 && '1px gray solid'
    })
    return result
  }
  
  calculateWidthSpace = appointmentInterval => {
    return `${(this.widthSpaces * appointmentInterval) / this.spaceWidth}%`
  }
  
  getPorcentage = (position, total) => {
    return (position * 100) / total
  }

  getPosition = (appointment, begin, end) => {
    const totalPx = appointment.end - appointment.begin
    const childBegin = begin - appointment.begin
    const childEnd = appointment.end - end

    return {
      begin: String(this.getPorcentage(childBegin, totalPx)).concat('%'),
      end: String(this.getPorcentage(childEnd, totalPx)).concat('%')
    }
  }

  setTimetoPercent = value => {
    return parseInt(value / 100) * 100 + (Math.min(value % 100, 59) * 100) / 60
  }
}

export default AppointmentSpaceRow

AppointmentSpaceRow.defaultProps = {
  id: null,
  loadManeuver: false,
  unloadManeuver: false,
  unloadLoadManeuver: false,
  loadManeuverIcon: null,
  unloadManeuverIcon: null,
  unloadLoadManeuverIcon: null,
  range: [600, 1900],
  available: true,
  className: null,
  tags: [],
  appointments: [],
  conflicts: []
}


const Wrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  background: #3d4a69;
  justify-content: space-between;
  padding: 0.1rem 0;
`

const LabelColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: 15%;
`

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1rem;
  font-weight: bold;
  color: white;
  width: 20%;
`

const ManouverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.2rem;
`

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const SpaceWrapper = styled.div`
  // width: 85%;
  height: 100%;
  min-width: 2000px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`