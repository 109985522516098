import axios from "axios";
// import { BACKEND } from "../../shared/backend-api";
import * as API from "./api-urls";
import { cloneDeep } from "lodash";

export const CONTENT_TYPES = {
  JSON: "application/json",
};

export const RESPONSE_TYPES = {
  JSON: "json",
  BLOB: "blob",
};

const apiCall = (method, entityName, params = {}, options = {}) => {
  const {
    contentType = "JSON",
    responseType = null,
    requiresAuth = true,
    cancelToken = undefined,
    isForm = false,
    prefix = null
  } = options;
  // debugger;
  const url = getPath(entityName, method, params, prefix);
  method = method === "LIST" ? "GET" : method;
  const headers = Object.assign(
    {
      "Content-Type": CONTENT_TYPES[contentType],
      // "ResponseType": RESPONSE_TYPES[responseType]
    },
    requiresAuth
      ? {
          Authorization:
            "Bearer " +
            (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
              .access,
        }
      : {}
  );

  

  let config = { method, url, headers, cancelToken };
  if(responseType){
    config.responseType = RESPONSE_TYPES[responseType];
  }
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    if(isForm){
      let formData = new FormData();
      Object.entries(params).forEach(i => {
        formData.append(i[0], i[1]);
      });
      config.data = formData;
    } else {
      config.data = params;
    }
  }
  return axios
    .request(config)

    .then((response) => response)
    .catch((error) => {
      if (error.status === 401) {
        window.location.replace("/login?expiration=true");
      } else {
        throw error;
      }
    });
};

// const responseEvaluator = (response, resolve, reject) => {
//   if (response.ok) {
//     return resolve(response);
//   } else if (response.status === 401) {
//     window.location.replace("/");
//   } else {
//     return reject(response.json());
//   }
// };

const getPath = (entityName, method, params, prefix) => {
  let backendPath = cloneDeep(API.URLS);
  entityName.split(".").forEach((p) => (backendPath = backendPath[p]));
  if(!prefix)
    return API.SERVER.concat(API.PATH, interpolate(backendPath[method], params));
  return API.getUrlsEnv(prefix).api.concat(API.PATH, interpolate(backendPath[method], params))
};

export const interpolate = function (str, params) {
  const names = Object.keys(params);
  const values = Object.values(params);
  names.forEach((p, index) => {
    str = str.replace(`{${p}}`, values[index]);
  });
  return str;
};

export default apiCall;
