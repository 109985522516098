import React from "react";
import MenuFaqsDetailsCards from "./details-cards";
import { FaqsNoFound } from "./styled";


export default class MenuFaqsDetails extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this.setState({
    });
  }

 
  render() {
    let cards = this.props.faqs;
    return (
      <React.Fragment>
        {cards && cards.length ? (
          (cards).map((card) => {
            return <MenuFaqsDetailsCards card={card} key={card.id} />;
          })
        ) : (
          <FaqsNoFound>No Existen FAQ's</FaqsNoFound>
        )}
      </React.Fragment>
    );
  }
}


