// @flow
import * as React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const ActionsTricolonStyled: React.ComponentType<{}> = styled.a`
  color: white;
  font-size: 22px;
  width: 25px;
  height: 25px;
  font-weight: 900;
  text-align: center;
  align-self: center;
  opacity: 1;
  transition: 0.2s ease-in-out;
  border-radius: 12px;
  & :hover {
    cursor: pointer;
    background-color: #98bcdf;
  }
`;
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionStyled = styled.span`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  ${({ hover }) => (hover ? "cursor: pointer;background-color: #98bcdf;" : "")}
`;

const ActionStyledDisable = styled.span`
  width: 100%;
  //color: #98bcdf;
  opacity: .3;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const Tricolon = styled.div`
  transform: rotate(90deg);
  font-weight: 100;
  line-height: 17px;
  padding-bottom: 7px;
`;

type Props = {
  actions: Array<{ description: string, action: Function }>,
  id?: any,
  tooltipClassName: string,
};

const buildAction = (action, index, hoverIndex, setHoverIndex) => {

  let hover = action.hover || '';
  let actionToShow;

  if (action.disable) {
    actionToShow =
      <ActionStyledDisable
        id={'action-container'}
        key={index}
        hover={index === hoverIndex}
        onClick={(e) => { /* e.stopPropagation(); action.action(action.additional); */ }}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(-1)}

        data-place="left"
        data-tip
        data-for={`custom-hover-${index}-${hoverIndex}`}
      >
        {action.description}
      </ActionStyledDisable>
    
  } else {
    actionToShow =
      <ActionStyled  
        id={'action-container'}
        key={index}
        hover={index === hoverIndex}
        onClick={(e) => { 
          e.stopPropagation(); 
          action.action && action.action(action.additional); 
        }}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(-1)}

        data-place="left"
        data-tip
        data-for={`custom-hover-${index}-${hoverIndex}`}
      >
        {action.description}
      </ActionStyled>;
  }

  return(
    <>
      {
        (hover !== '') &&
        <ReactTooltip
          id={`custom-hover-${index}-${hoverIndex}`}
        >
          <div style={{
            width: 200,
            textAlign: 'center'
          }}>{hover}</div>
        </ReactTooltip>
      }
      {actionToShow}
    </>
  );

}

export const ActionsTricolon = ({
  actions = [],
  id = Math.random(),
  tooltipClassName,
  tooltipPlacement = "right"
}: Props) => {
  const [hoverIndex, setHoverIndex] = React.useState(-1);

  return (
    <ActionsTricolonStyled data-for={`actions-${id}`} data-tip>
      <Tricolon>...</Tricolon>
      <ReactTooltip
        id={`actions-${id}`}
        clickable={true}
        effect="solid"
        place={tooltipPlacement}
        type="light"
        delayHide={200}
        data-class={tooltipClassName}
        className={tooltipClassName}
      > 
        <ActionContainer
          id={'action-container'}
        >
          {actions.map((action, index) => (
            buildAction(action, index, hoverIndex, setHoverIndex)
          ))}
        </ActionContainer>
      </ReactTooltip>
    </ActionsTricolonStyled>
  );
};
