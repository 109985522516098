import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import Chip from '../controls/chip';
import SuggestionItemRow from '../component/suggestion-item-row';
// import InputSearch from '../controls/input-search';
import Checkbox from '../controls/checkbox';

class TagsSuggestionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.items,
            newTags: [],
            showAddTagsTooltip: false,
            sharedTags: {}
        }
    }
    onClose = () => {
        this.setState({ items: [], sharedTags: {}, showAddTagsTooltip: false, newTags: [] });
        this.props.closeAction && this.props.closeAction();
    }
    static getSharedTags = (items, newTags) => {
        let generalTags = {};
        items.map(item => {
            item.originalTags && item.originalTags.map(tag => {
                if(!item.removedTags.includes(tag)){
                    if(generalTags[tag]){
                        generalTags[tag]++;
                    } else{
                        generalTags[tag] = 1;
                    }
                } else {
                    if(!generalTags[tag]){
                        generalTags[tag] = 0;
                    }
                }
                return null;
            })
            item.addedTags && item.addedTags.map(tag => {
                if(generalTags[tag]){
                    generalTags[tag]++;
                } else{
                    generalTags[tag] = 1;
                }
                return null;
            })
            return null;
        });
        newTags.forEach(tag => {
            if(!generalTags[tag.title || tag.description] && tag.selected){
                generalTags[tag.title || tag.description] = 0;
            }
        });
        return generalTags;
    }
    applyButtonSettings = {
        text: 'Aplicar',
        onClick: (() => {})
    }
    swapTag = (facilityId, tag, isAdded) => {
        let updatedItems = this.state.items.map(item => {
            if(item.id === facilityId){
                if(isAdded){
                    if(item.originalTags.includes(tag)){
                        item.removedTags.splice(item.removedTags.findIndex(t => t === tag), 1);
                    } else {
                        item.addedTags.push(tag);
                    }
                } else {
                    if(item.originalTags.includes(tag)){
                        item.removedTags.push(tag);
                    } else {
                        item.addedTags.splice(item.addedTags.findIndex(t => t === tag), 1);
                    }
                }
            }
            return item;
        });
        let sharedTags = TagsSuggestionModal.getSharedTags(updatedItems, this.state.newTags);
        this.setState({items: updatedItems, sharedTags: sharedTags});
    }
    swapTagsGlobal = e => {
        let updatedItems = this.state.items.map(item => {
            if(e.target.value === false){ //Agregar tag
                if(item.originalTags.includes(e.target.name)){
                    item.removedTags.splice(item.removedTags.findIndex(t => t === e.target.name), 1);
                } else if(!item.addedTags.includes(e.target.name)){
                    item.addedTags.push(e.target.name);
                }
            } else { // quitar tag
                if(item.originalTags.includes(e.target.name) && !item.removedTags.includes(e.target.name)){
                    item.removedTags.push(e.target.name);
                } else {
                    item.addedTags.splice(item.addedTags.findIndex(t => t === e.target.name), 1);
                }
            }
            return item;
        });
        let sharedTags = TagsSuggestionModal.getSharedTags(updatedItems, this.state.newTags);
        this.setState({items: updatedItems, sharedTags: sharedTags});
    }
    selectNewTag = e => {
        let tags = this.state.newTags.map(tag => ('' + tag.id) === e.target.value ? { ...tag, selected: !tag.selected} : tag);
        let sharedTags = TagsSuggestionModal.getSharedTags(this.state.items, tags);
        this.setState({newTags: tags, sharedTags: sharedTags});
    }
    render() {
        return (
            <Modal
                isOpen = {this.props.open}
                portalClassName="dialog suggestions-modal"
                overlayClassName="overlay"
                className="content"
                shouldCloseOnOverlayClick = {true}
            >
                <div className="title">Editar etiquetas</div>
                <div className="close" onClick={this.onClose}></div>
                <div className="message">
                    <p>Estas son todas las etiquetas de los <b>{this.props.items.length} elementos</b> seleccionados:</p>
                    <div className="shared-tags">
                        {
                            Object.keys(this.state.sharedTags).map(tag => {
                                let isFull = false;
                                let isMid = false;
                                if(this.state.sharedTags[tag] === this.props.items.length){
                                    isFull = true;
                                } else if (this.state.sharedTags[tag] > 0) {
                                    isMid = true;
                                }
                                return <Chip id={tag} description={tag} active={isFull} isMid={isMid} onClick={this.swapTagsGlobal}/>;
                            })
                        }
                    </div>
                    <div className="add-tags">
                        ¿Deseas añadirles otra etiqueta? 
                        <div className="add-tags-container">
                            <div className="add-icon" onClick={() => this.setState({showAddTagsTooltip: !this.state.showAddTagsTooltip})}>
                                +
                            </div>
                            <div 
                                id="addTagsTootltip" 
                                className={"tags-tooltip".concat(this.state.showAddTagsTooltip ? '' : ' hidden')}
                            >
                                {/* <div>
                                <InputSearch
                                    type='text'
                                    label='Buscar etiqueta'
                                    name='tag-name'
                                    className="full"
                                />
                                </div> */}
                                <div className="tags-list">
                                    {
                                        this.state.newTags.map(tag => {
                                            return(
                                                <Checkbox 
                                                    name="tags-checkbox"  
                                                    key={tag.id} 
                                                    onChange={this.selectNewTag}  
                                                    item={{id: tag.id, description: tag.title || tag.description, active: tag.selected}}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item-rows">
                        {
                            this.state.items.map(item => {
                                return (
                                <SuggestionItemRow 
                                        key={item.id} 
                                        item={item} 
                                        sharedTags={this.state.sharedTags} 
                                        totalItems={this.state.items.length} 
                                        swapTag={this.swapTag}
                                />);
                            })
                        }
                    </div>
                </div>
                <div className="actions">
                    <Button settings={this.applyButtonSettings}/>
                </div>
            </Modal>
        )
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.tagCatalog.length !== prevState.newTags.length){
            return {
                newTags: nextProps.tagCatalog.map(tag => {return { ...tag, selected: false}})
            }
        }
        if(nextProps.items.length !== prevState.items.length){
            let newItems = JSON.parse(JSON.stringify(nextProps.items));
            Array.isArray(newItems) && newItems.forEach(item => {
                item.originalTags = [];
                item.tags.forEach(tag => {
                    item.originalTags.push(tag.title || tag.description || tag.tag.title);
                })
                item.addedTags = [];
                item.removedTags = [];
            });
            let sharedTags = TagsSuggestionModal.getSharedTags(newItems, prevState.newTags);

            return {
                items: newItems,
                sharedTags: sharedTags
            }
        }
        return null;
    }
}

export default TagsSuggestionModal;

TagsSuggestionModal = {
    items: [],
    closeAction: () => {console.log('Not yet implemented')}
}