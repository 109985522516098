import React from "react";
const LocationPin = (props) => {
  return (
    <svg
      viewBox="0 0 14 18"
      width="14px"
      height="18px"
      fill="#AFC8FF"
      {...props}
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="perfil-Instalacion" transform="translate(-157.000000, -275.000000)">
              <g id="Group-2" transform="translate(140.000000, 64.000000)">
                  <g id="Group-12" transform="translate(14.000000, 209.000000)">
                      <g id="users-copy-icon-/-location" transform="translate(0.000000, 2.000000)">
                          <g id="color" transform="translate(3.333333, 0.833333)">
                              <mask id="mask-2" fill="white">
                              <path d="M6.66666667,0 C10.348565,0 13.3333333,2.98476833 13.3333333,6.66666667 C13.3333333,9.85354641 11.8409934,13.0404261 8.85631346,16.2273059 C8.81153328,16.2751197 8.76519899,16.321454 8.71738513,16.3662342 C7.50807488,17.4988163 5.60959606,17.4366158 4.477014,16.2273055 L4.477014,16.2273055 L4.1735806,15.8976257 C1.39119353,12.8206393 0,9.743653 0,6.66666667 C0,2.98476833 2.98476833,0 6.66666667,0 Z M6.66666667,4.16666667 C4.8257175,4.16666667 3.33333333,5.65905083 3.33333333,7.5 C3.33333333,9.34094917 4.8257175,10.8333333 6.66666667,10.8333333 C8.50761583,10.8333333 10,9.34094917 10,7.5 C10,5.65905083 8.50761583,4.16666667 6.66666667,4.16666667 Z M6.66666667,5.83333333 C7.58714125,5.83333333 8.33333333,6.57952542 8.33333333,7.5 C8.33333333,8.42047458 7.58714125,9.16666667 6.66666667,9.16666667 C5.74619208,9.16666667 5,8.42047458 5,7.5 C5,6.57952542 5.74619208,5.83333333 6.66666667,5.83333333 Z" id="path-1"></path>
                              </mask>
                              {/* <use id="Mask" fill="#454F63" fill-rule="nonzero" xlink:href="#path-1"></use> */}
                              <path d="M6.66666667,0 C10.348565,0 13.3333333,2.98476833 13.3333333,6.66666667 C13.3333333,9.85354641 11.8409934,13.0404261 8.85631346,16.2273059 C8.81153328,16.2751197 8.76519899,16.321454 8.71738513,16.3662342 C7.50807488,17.4988163 5.60959606,17.4366158 4.477014,16.2273055 L4.477014,16.2273055 L4.1735806,15.8976257 C1.39119353,12.8206393 0,9.743653 0,6.66666667 C0,2.98476833 2.98476833,0 6.66666667,0 Z M6.66666667,4.16666667 C4.8257175,4.16666667 3.33333333,5.65905083 3.33333333,7.5 C3.33333333,9.34094917 4.8257175,10.8333333 6.66666667,10.8333333 C8.50761583,10.8333333 10,9.34094917 10,7.5 C10,5.65905083 8.50761583,4.16666667 6.66666667,4.16666667 Z M6.66666667,5.83333333 C7.58714125,5.83333333 8.33333333,6.57952542 8.33333333,7.5 C8.33333333,8.42047458 7.58714125,9.16666667 6.66666667,9.16666667 C5.74619208,9.16666667 5,8.42047458 5,7.5 C5,6.57952542 5.74619208,5.83333333 6.66666667,5.83333333 Z" id="path-1"></path>
                              <g id="color-color/blue-light-2" mask="url(#mask-2)" fill="#AFC8FF">
                                  <g transform="translate(-3.333333, -0.833333)" id="Rectangle">
                                      <rect x="0" y="0" width="20" height="20"></rect>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

export default LocationPin;