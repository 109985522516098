// @flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import { selectModalContent } from "../assemble-utils";
import type { Assemble } from "../types";
import type { SimpleEvent } from "../../../shared/types";
import Loader from "../../../shared/icons/tat/loader";

type Props = {
  showAddModal: boolean,
  assembleSummaryResources: any,
  assemble: Assemble,
  companyId: number,
  companyFleets: any,
  addType: number,
  fleetVehicleTypesClasses: any,
  unasembledVehicles: any,
  unasembledEquipments: any,
  unasembledOperators: any,
  operatorLicenseTypes: any,
  // fleetDatas: any,
  companyTags: any,
  onChange: Function,
  // listFleetOperators: Function,
  listFleetResourceCatalog: Function,
  // listFleetData: Function,
  createAssembleVehicle: Function,
  createAssembleOperator: Function,
  createAssembleEquipment: Function,
  onSearch: Function,
  // data: any,
};
type State = {
  selectedItem: { id: number, schedule: Date },
  selectedDate: number,
  vehiclesData: Array<any>,
  equipmentsData: Array<any>,
  operators: Array<any>,
  modalSearchText: string,
};

class AssembleAddResource extends React.Component<Props, State> {
  static defaultProps = {
    manageError: () => {},
  };
  modalOpeningDate: number = Date.parse(new Date().toISOString());
  state = {
    loading: false,
    selectedDate: this.modalOpeningDate,
    vehiclesData: [],
    equipmentsData: [],
    operators: [],
    // selectedItem: { id: 0, schedule: new Date() },
    selectedItem: {},
    modalSearchText: "",
  };

  // componentDidUpdate(prevProps: Props) {
  //   if (prevProps.showAddModal === false && this.props.showAddModal === true) {
  //     this.manageAddModal({
  //       target: { name: "addResource", value: "" },
  //     });
  //   }
  // }
  componentDidMount() {
    this.setState({ selectedDate: this.modalOpeningDate });
    this.manageAddModal({
      target: { name: "addResource", value: "" },
    });
  }

  render() {
    const {
      showAddModal,
      fleetVehicleTypesClasses,
      operatorLicenseTypes,
      unasembledVehicles,
      unasembledEquipments,
      unasembledOperators,
    } = this.props;
    const { vehiclesData, equipmentsData, operators } = this.state;
    return (
      <BaseDialog
        open={showAddModal}
        className="create-assemble-dialog"
        closeAction={this.manageAddModal}
        acceptAction={() =>
          this.manageAddModal({
            target: { name: "add", value: "" },
          })
        }
        title="Agregar recurso"
        contentObject={selectModalContent({
          vehiclesData: vehiclesData,
          equipmentsData: equipmentsData,
          operators: operators,

          fleetVehicleTypesClasses: fleetVehicleTypesClasses,
          unasembledEquipments: unasembledEquipments,
          unasembledVehicles: unasembledVehicles,
          unasembledOperators: unasembledOperators,
          // unasembledEquipments: this.excludeCurrentResources(),
          // unasembledVehicles: this.excludeCurrentResources(),
          // unasembledOperators: this.excludeCurrentResources(),
          operatorLicenseTypes: operatorLicenseTypes,
          onlyOne: false,
          // fleetDatas: this.props.fleetDatas,
          companyTags: this.props.companyTags,
          step: this.props.addType,
          addingDolly: this.props.addingDolly,
          isEditing: true,
          handleChange: this.handleChange,
          modalOpeningDate: this.modalOpeningDate,
          modalSearchText: this.state.modalSearchText,
          selectedDate: this.state.selectedDate,
          onSearch: this.handleSearch,
        })}
        style={{ content: { width: "1100px", marginLeft: "-550px" } }}
        showCancel={true}
        cancelText="Cancelar"
        acceptText={this.state.loading ? <Loader /> : "Guardar"}
        acceptButtonType="primary"
        disabledAccept={
          !(
            this.state.selectedItem &&
            this.state.selectedItem?.id !== this.props.assemble?.resourceId
          ) || this.state.loading
        }
      />
    );
  }
  handleSearch = (modalSearchText: string) => {
    this.setState({ modalSearchText: modalSearchText });
    this.props.onSearch(modalSearchText, this.props.addType);
  };

  excludeCurrentResources = () => {
    const {
      addType,
      unasembledVehicles,
      unasembledEquipments,
      unasembledOperators,
    } = this.props;
    let result = [];
    if (addType === 1) {
      result = unasembledVehicles.items.filter((item) =>
        this.state.vehiclesData?.find((vehicle) => vehicle.id === item.id)
          ? false
          : true
      );
    } else if (addType === 2) {
      result = unasembledEquipments.items.filter((item) =>
        this.state.equipmentsData?.find((equipment) => equipment.id === item.id)
          ? false
          : true
      );
    } else if (addType === 3) {
      result = unasembledOperators.items.filter((item) =>
        this.state.operators?.find((equipment) => equipment.id === item.id)
          ? false
          : true
      );
    }
    return { items: result };
  };

  manageAddModal = (e: any) => {
    const { name } = e.target;
    const {
      assembleSummaryResources,
      // listFleetData,
      // listFleetOperators,
      listFleetResourceCatalog,
      companyId,
      // companyFleets,
      onChange,
    } = this.props;
    switch (name) {
      case "addResource":
        // listFleetData(companyId, companyFleets[0].id);
        // listFleetOperators(companyId, companyFleets[0].id);
        [1, 2, 3].forEach((number) =>
          listFleetResourceCatalog(
            companyId,
            number,
            "unassembled",
            "",
            "",
            "",
            "",
            {
              alias: `catalogType${number}Counter`,
            }
          )
        );
        this.setState({
          vehiclesData: assembleSummaryResources.vehicles,
          equipmentsData: assembleSummaryResources.equipments,
          operators: assembleSummaryResources.operators,
        });
        break;
      case "close":
        onChange({
          target: { name: "showAddModal", value: false },
        });
        break;
      case "cancel":
        onChange({
          target: { name: "showAddModal", value: false },
        });
        break;
      case "add":
        this.addAssembleResource();
        break;
      case "saved":
        this.setState({
          vehiclesData: undefined,
          equipmentsData: undefined,
          operators: undefined,
        });
        onChange({
          target: { name: "saved", value: false },
        });
        break;
      default:
    }
  };

  addAssembleResource() {
    this.setState({loading: true});
    const { selectedItem } = this.state;
    const { assemble, companyId, companyFleets, addType } = this.props;

    switch (addType) {
      case 1:
        this.props.createAssembleVehicle(
          companyId,
          companyFleets[0].id,
          assemble.id,
          selectedItem.id,
          new Date(selectedItem.schedule).toISOString(),
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "saved" } });
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      case 2:
        this.props.createAssembleEquipment(
          companyId,
          companyFleets[0].id,
          assemble.id,
          selectedItem.id,
          new Date(selectedItem.schedule)?.toISOString(),
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "saved" } })
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      case 3:
        this.props.createAssembleOperator(
          companyId,
          companyFleets[0].id,
          assemble.id,
          selectedItem.id,
          new Date(selectedItem.schedule)?.toISOString(),
          {
            onSuccess: () => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "saved" } });
            },
            onError: (e) => {
              this.setState({loading: false});
              this.manageAddModal({ target: { name: "cancel" } });
              let err = e.response?.data?.errors || []; 
              this.props.manageError(err);
            }
          }
        );
        break;
      default:
    }
  }

  handleChange = (e: SimpleEvent) => {
    const { name, value } = e.target;

    const {
      operators,
      equipmentsData,
      vehiclesData,
      selectedDate,
      selectedItem,
    } = this.state;
    const { assemble, fleetVehicleTypesClasses } = this.props;
    switch (name) {
      case "operators":
        const operator = {
          ...value,
          schedule: selectedDate,
        };
        this.setState({
          operators: operators
            .filter(
              (o) => (o.fleet_operator_id || o.id) !== assemble.resourceId
            )
            .filter((o) => (o.fleet_operator_id || o.id) !== value.id)
            .filter((o) => (o.fleet_operator_id || o.id) !== selectedItem?.id)
            .concat([value]),
          selectedItem: operator,
        });
        break;
      case "equipmentsData":
        const equipment = {
          ...value,
          schedule: selectedDate,
          description: `${value.number}/${value.plates}`,
        };

        // if (
        //   // solo 1 dolly
        //   value.type === 7 &&
        //   equipmentsData.filter((e) => e.type === 7).length > 0
        // )
        //   return;
        // if (
        //   // solo dos remolques
        //   value.type === 8 &&
        //   equipmentsData.filter((e) => e.type === 8).length > 1
        // )
        //   return;
        // if (
        //   // solo dos semis
        //   value.type === 9 &&
        //   equipmentsData.filter((e) => e.type === 9).length > 1
        // )
        //   return;

        this.setState({
          equipmentsData: equipmentsData
            .filter((e) => e.id !== assemble.resourceId)
            .filter((e) => e.id !== value.id)
            .filter((e) => e.id !== selectedItem?.id)
            .concat([equipment]),
          selectedItem: equipment,
        });
        break;
      case "vehiclesData":
        const vcl = fleetVehicleTypesClasses
          .filter((f) => f.type === 1)
          .find((f) => f.classes.find((c) => c.id === value.class))
          .classes.find((c) => c.id === value.class);
        const vehicle = {
          ...value,
          schedule: selectedDate,
          description: `${vcl.description}/${value.number}/${value.plates}`,
        };
        this.setState({
          vehiclesData: vehiclesData
            .filter((e) => (e.fleet_data_id || e.id) !== assemble.resourceId)
            .filter((e) => (e.fleet_data_id || e.id) !== value.id)
            .filter((e) => (e.fleet_data_id || e.id) !== selectedItem?.id)
            .concat([vehicle]),
          selectedItem: vehicle,
        });
        break;
      case "selectedDate":
        const si: { id: number, schedule: date } = { ...selectedItem };
        const dateNumber = Date.parse(value.toISOString());
        this.setState({ selectedDate: dateNumber });
        if (si.schedule) {
          si.schedule = value;
          this.setState({
            selectedItem: si,
          });
        }
        break;
      default:
        this.setState({ [name]: value });
    }
  };
}

export default AssembleAddResource;
