import React, { Component } from 'react';
// import Chip from '../controls/chip';
import PropTypes from 'prop-types';
import Tag from '../controls/tag';


class ChipContainer extends Component {

  handleClick = (event) => {
    this.props.onChange(event);
  }

  getChips = () => {
    return this.props.items.map(chip =>
      <Tag
        key={chip.id}
        id={chip.id}
        color={chip.color}
        title={chip.title}
        isFull={chip.isFull}
        isActive={chip.isActive}
        parentBackground={this.props.background}
        onClick={this.handleClick}
      />
    );
  }

  render() {
    return (
      <div className={"chip-container ".concat(this.props.className || '')}>
        <div className='title'>{this.props.title || ''}</div>
        <div className='chips'>
          {this.getChips()}
        </div>
      </div>
    );
  }
}

export default ChipContainer;


ChipContainer.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      title: PropTypes.string,
      isFull: PropTypes.boolean,
      isActive: PropTypes.boolean,
    })
  ),
  background: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string
};

ChipContainer.defaultProps = {
  onChange: () => { console.log('Not implemented yet!') },
  items: [],
  title: '',
};