import React, { Component } from "react";
import PropTypes from "prop-types";
import Checkbox from "./checkbox";

export const ORBI_SELECTOR_LISTS = {
  SIMPLE_LIST: 1,
  CHECKLIST: 2,
  CHECKLISTRECURSIVE: 3,
  // chips: 1,
  // checkList: 2,
  // checkListTextSearch: 3,
  // checkListAll: 4,
  // checkListTextSearchAll: 5,
  // checkExclusive: 6,
};

class OrbiSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      isHovered: false
    };
  }

  manageView = (e) => {
    if (this.props.manageByState) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  };

  componentDidUpdate(prevProps) {
    // if (
    //   this.props.isOpen !== undefined &&
    //   this.props.isOpen !== prevProps.isOpen
    // )
    if (
      this.props.manageByState === false &&
      this.props.isOpen !== prevProps.isOpen
    )
      this.setState({
        isOpen: this.props.isOpen,
      });
  }

  handleChange = (e) => {
    this.manageView(e);
    this.props.onChange(e);
  };

  render() {
    return (
      <div
        ref={(node) => (this.node = node)}
        className={"orbi-selector ".concat(
          this.state.isOpen ? "open " : "",
          this.props.className || ""
        )}
        style={{cursor: "pointer"}}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false})}
        onClick={this.manageView}
      >
        <div className="orbi-selector-title">{this.props.title || ""} </div>
        {this.props.showCaret ? (
          <div className="orbi-selector-caret">
            <img 
              width={this.state.isHovered ? "21px" : "16px"}
              height={this.state.isHovered ? "15px" : "10px"}
              alt="" 
              src="/images/caret.svg" />
          </div>
        ) : (
          <React.Fragment />
        )}
        <div
          className={"orbi-selector-content"}
          onClick={(e) => e.stopPropagation()}
        >
          {this.getContent()}
        </div>
      </div>
    );
  }

  getContent = () => {
    switch (this.props.listType) {
      case ORBI_SELECTOR_LISTS.SIMPLE_LIST:
        return this.getSimpleList();
      case ORBI_SELECTOR_LISTS.CHECKLIST:
        return this.getCheckList();
      case ORBI_SELECTOR_LISTS.CHECKLISTRECURSIVE:
        return this.checkListRecursive();
      // case FILTER_LIST_TYPES.chips:
      //   return this.getChipsList(c.items, c.name);
      // case FILTER_LIST_TYPES.checkList:
      //   return this.getCheckList(c.items, c.name);
      // case FILTER_LIST_TYPES.checkListTextSearch:
      //   return this.getCheckList(c.items, c.name, true);
      // case FILTER_LIST_TYPES.checkListAll:
      //   return this.getCheckList(c.items, c.name, false, true);
      // case FILTER_LIST_TYPES.checkListTextSearchAll:
      //   return this.getCheckList(c.items, c.name, true, true);
      // case FILTER_LIST_TYPES.checkExclusive:
      //   return this.getCheckList(c.items, c.name, true, true);
      default:
        return <React.Fragment />;
    }
  };
  getSimpleList = () => {
    return (
      <ul className="simple-list">
        {this.props.items.map((item) => (
          <li
            value={item.id}
            name={this.props.name}
            key={item.id}
            className="simple-list-item"
            onClick={this.handleChange}
          >
            {item.description}
          </li>
        ))}
      </ul>
    );
  };
  getCheckList = () => {
    return this.props.items.map((item) => (
      <Checkbox
        key={item.id}
        item={item}
        //         {item.description}
        onChange={this.props.onChange}
        // }).isRequired,
        name={this.props.name}
      />
    ));
  };

  checkListRecursive = () => {
    const { items, name } = this.props;
    let counter = -9999;
    return (
      <div className="items checks">
        {(items || []).map((i) => {
          let l = [];
          l.push(
            <Checkbox
              key={counter++}
              onChange={(e) => {
                i.items
                  ? i.items.forEach((child) =>
                      this.props.onChange({
                        target: {
                          name: e.target.name,
                          value: child.id,
                          checked: i.items.some((child) => !child.active),
                        },
                      })
                    )
                  : i.id === -1
                  ? this.switchAll(items, name)
                  : this.props.onChange(e);
              }}
              item={{
                id: i.id,
                description: i.description,
                active:
                  i.id === -1
                    ? this.isAllSelected(items)
                    : i.items
                    ? i.items.filter((child) => !child.active).length === 0
                    : i.active,
              }}
              name={name}
              className={(i.items || []).length ? "bold" : ""}
            />
          );
          if ((i.items || []).length) {
            i.items.forEach((ci) =>
              l.push(
                <Checkbox
                  key={counter++}
                  className={"sub-item"}
                  onChange={(e) => this.props.onChange(e)}
                  item={{
                    id: ci.id,
                    description: ci.description,
                    active: ci.active,
                  }}
                  name={name}
                />
              )
            );
            l.push(<div className="separator" key={counter++} />);
          }
          return l;
        })}
      </div>
    );
  };

  switchAll = (items, name) => {
    const currentState = items.some((item) =>
      item.items ? !item.items.some((it) => !it.active) : item.active
    );
    items.forEach((item) =>
      items.forEach((lvl1) =>
        lvl1.items
          ? lvl1.items.forEach((lvl2) =>
              this.props.onChange({
                target: { name: name, value: lvl2.id, checked: !currentState },
              })
            )
          : this.props.onChange({
              target: { name: name, value: lvl1.id, checked: !currentState },
            })
      )
    );
  };

  isAllSelected = (items) => {
    // const currentState = items.some((item) =>
    //   item.items ? !item.items.some((it) => !it.active) : !item.active
    // );
    return !items
      .filter((i) => i.id !== -1)
      .some((item) =>
        item.items ? item.items.some((it) => !it.active) : !item.active
      );
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = (e) => {
    if (this.node.contains(e.target)) return;
    this.setState({ isOpen: false });
  };
}
export default OrbiSelector;

OrbiSelector.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  selected: PropTypes.array,
  listType: PropTypes.oneOf([1, 2, 3]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showCaret: PropTypes.bool,
  manageByState: PropTypes.bool,
  onClose: PropTypes.func,
};
OrbiSelector.defaultProps = {
  className: "",
  isOpen: undefined,
  title: "",
  onChange: () => {
    console.log("Not implemented yet!");
  },
  showCaret: false,
  manageByState: true,
};
