//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import styled from "styled-components";
import * as Types from "../types";
import ShipmentReserveDialogContent from "./shipment-reserve-dialog-content";

type Props = {
  open: boolean,
  onChange: Function,
  trip: Types.UnassignedShipmentRowType,
  fleetProviders: Types.BookingFleetProviderRow[],
  assemblies: Types.BookingAssemblyRow[],
};

class ShipmentReserveDialog extends React.Component<Props> {
  render() {
    return (
      <BaseDialogStyled
        open={this.props.open}
        title={<Title {...this.props} />}
        contentObject={<ShipmentReserveDialogContent {...this.props} />}
        closeAction={() =>
          this.props.onChange({ target: { name: "close", value: "" } })
        }
        showCancel={true}
        cancelText="Cerrar"
        cancelButtonType="outline primary dark"
        isAcceptLoading={this.props.isLoadingBooking}
        acceptAction={() => 
          this.props.onChange({ target: { name: "save", value: "" } })
        }
        acceptText="Reservar viaje"
        disabledAccept={!this.props.enabledAction}
        //   acceptButtonType: PropTypes.string,
      />
    );
  }
}

export default ShipmentReserveDialog;

const Title = ({ trip }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleHead>{`Selecciona un transportista para el viaje ${trip.tripNumber}`}</TitleHead>
      </div>
    </div>
  );
};

const TitleHead = styled.span`
  color: #20293d;
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 55px;
`;
const TitleMessage = styled.span`
  color: #4a4a4a;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
`;

const BaseDialogStyled: React.ComponentType<{}> = styled(BaseDialog)`
  width: 1350px !important;
  margin-left: -675px !important;
`;
