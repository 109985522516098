import React from "react";
import { AccountContainer } from "./style";
import Select from "react-select";
import DownloadImage from "../../../shared/icons/routes/downloadIcon";

class PaymentsAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { years, bills, onChange } = this.props.data;
    return (
      <AccountContainer>
        <div className="title">
          <div>Estado de cuenta</div>
          <div>
            <Select
              defaultValue={{ value: years[0], label: years[0] }}
              styles={CustomStyle}
              // value={selectedYear}
              onChange={(e) => {onChange({target: {name:"selectedYear", value: e.value}})}}
              name="selectedYear"
              options={years.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              // items={
              //   years.map((item) => {
              //       return {
              //         value: item,
              //         label: item,
              //       };
              //     })
              // }
            />
          </div>
        </div>
        <div className="details">
          <div className="title">
            <div className="month"></div>
            {/* <div className="download">PDF</div> */}
            <div className="preview">XLS</div>
          </div>
          {bills.map((item) => {
            return (
              <div className="row">
                <div className="month">{item.month}</div>
                {/* <div className="download">
                  <img src="/images/pdf.png" alt="" width="30px" onClick={item.bill}/>
                </div> */}
                <div className="preview">
                  {/* <img src="" alt="" width="30px" onClick={item.preview}/> */}
                  <DownloadImage height="43px" onClick={item.preview}/>
                </div>
              </div>
            );
          })}
        </div>
      </AccountContainer>
    );
  }
}

const CustomStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#37435D",
    fontSize: "14px",
    padding: "0px 12px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    color: "#ffffff",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(47,59,83)",
    margin: "0",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "#37435D",
    width: 100,
    border: 0,
    boxShadow: "",
  }),
};

export default PaymentsAccount;
