import React from 'react';
import UserChip from '../controls/usermail-chip';

class UserChipContainer extends React.Component {
    render () {
        return (
            <div className="user-chip-container">
                {
                    this.props.items.map(item => 
                        <UserChip 
                            key={item.id} 
                            mail={item.name} 
                            avatar={item.avatar} 
                            avatarType={item.avatarType} 
                            deleteTag={() => this.props.deleteTag(item.id)}
                        />
                    )
                }
            </div>
        )
    }
}

export default UserChipContainer;