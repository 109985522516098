// @flow
import apiCall from "./api";
import type { ApiReducerAction } from "./types";
import Axios from "axios";

const apiMiddleware = (store: any) => (next: any) => (
  action: ApiReducerAction
) => {
  const { type } = action;
  const actionType = type.split("_");
  if (actionType.shift() === "API") {
    if (actionType.shift() === "REQUEST") {
      let method = null;
      let isForm = false;
      switch (actionType.shift()) {
        case "READ":
          method = "GET";
          break;
        case "CATALOG":
          method = "LIST";
          break;
        case "LIST":
          method = "LIST";
          break;
        case "CREATE":
          method = "POST";
          if(action.isForm) isForm = action.isForm;
          break;
        case "UPDATE":
          method = "PUT";
          if(action.isForm) isForm = action.isForm;
          break;
        case "PARTIAL":
          method = "PATCH";
          if(action.isForm) isForm = action.isForm;
          break;
        case "DELETE":
          method = "DELETE";
          break;
        case "CANCEL":
          method = "CANCEL";
          break;
        default:
      }
      const entityPath = actionType.shift();
      if (method) {
        // if(entityPath === "COMPANIES.FACILITIES.TRIPSCONTROL")
        //   console.log("METHOD", method)
        if (method === "CANCEL") {
          // let tempState = store.getState();
          // debugger;
          if (store.getState().api[entityPath] && store.getState().api[entityPath].cancel)
            store.getState().api[entityPath].cancel();

          if (type === 'API_REQUEST_CANCEL_MULTIPLE_REQUESTS') {
            let multipleCancels = store.getState().api?.cancels ?? [];
            
            multipleCancels.map( cancel => {
              cancel();
            });

          }

        } else { 
          // debugger;
          const CancelToken = Axios.CancelToken;
          const source = CancelToken.source();
          action.cancel = source.cancel;
          // debugger;
          apiCall(method, entityPath, action.params, {
            cancelToken: source.token,
            isForm,
            responseType: action.responseType,
            prefix: action.prefix
          })
            .then((response) => {
              store.dispatch({
                type: action.type.replace("REQUEST", "SUCCESS"),
                payload: response.data,
                // meta: action.meta,
                strategy: action.strategy,
                entityPath,
                args: action.args,
                alias: action.alias,
              });
              return response;
            })
            .then((response) => {
              if (action.onSuccess) {
                action.onSuccess(response.data, response.status);
              }
            })
            .catch((error, b, c, d) => {
              store.dispatch({
                type: action.type.replace("REQUEST", "FAIL"),
                error: error.response,
                // meta: action.meta,
                strategy: action.strategy,
                entityPath,
              });
              if (action.onError) {
                action.onError(error);
              }
            });
        }
      }
    }
  }
  return next(action);
};
export default apiMiddleware;
