import React from "react";
const TATTruck = (props) => {
  return (
    <svg 
      width="24px" 
      height="22px" 
      viewBox="0 0 24 22" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      {...props}
    >
      <g id="vistas" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M9.5,15.5 C9.5,13.5670034 7.93299662,12 6,12 C4.06700338,12 2.5,13.5670034 2.5,15.5 L0.69687964,15.5 L0.69687964,0.416851387 L17,0.416851387 L17,4.85472026 L21.0118946,4.85472026 C21.5641794,4.85472026 22.0118946,5.30243551 22.0118946,5.85472026 L22.0118946,14.5 C22.0118946,15.0522847 21.5641794,15.5 21.0118946,15.5 L19,15.5 C19,13.5670034 17.4329966,12 15.5,12 C13.5670034,12 12,13.5670034 12,15.5 L9.5,15.5 Z M17,6 L17,9 L20.5,9 L20.5,6 L17,6 Z M6,18 C4.61928813,18 3.5,16.8807119 3.5,15.5 C3.5,14.1192881 4.61928813,13 6,13 C7.38071187,13 8.5,14.1192881 8.5,15.5 C8.5,16.8807119 7.38071187,18 6,18 Z M15.5,18 C14.1192881,18 13,16.8807119 13,15.5 C13,14.1192881 14.1192881,13 15.5,13 C16.8807119,13 18,14.1192881 18,15.5 C18,16.8807119 16.8807119,18 15.5,18 Z" id="ic/camion_ida_nar" fill="currentColor" fill-rule="nonzero">
        </path>
      </g>
    </svg>
  );
};

export default TATTruck;