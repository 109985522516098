import React from "react";
const DoubleEquipment = (props) => {
  return (
    <svg
      width="46px"
      height="20px"
      fill="#AFC8FF"
      stroke="#AFC8FF"
      viewBox="-1 0 46 14"
      {...props}
    >
      <g>
        <g id="Group-2">
          <g id="Group" transform="translate(23.000000, 1.000000)">
            <polygon
              id="Rectangle"
              strokeWidth="1.5"
              // fill="#AFC8FF"
              points="0 0 20.3414221 0 20.3414221 8 14.5 8 0 8"
            />
            <ellipse
              id="Oval"
              strokeWidth="1.5"
              cx="1.27133888"
              cy="10.3068182"
              rx="1.27133888"
              ry="1.32954545"
            />
            <ellipse
              id="Oval"
              strokeWidth="1.5"
              cx="6.35669441"
              cy="10.3068182"
              rx="1.27133888"
              ry="1.32954545"
            />
            <line
              x1="16.5137556"
              y1="9.14772727"
              x2="16.5"
              y2="11.5"
              id="Line"
              // stroke-linecap="square"
            />
          </g>
        </g>
        <path
          d="M39.5,11.9718253 L38.8830952,13 L40.1169048,13 L39.5,11.9718253 Z"
          id="Triangle"
        />
        <g id="Group" strokeWidth="1.5">
          <g id="Group-20">
            <g id="Group-58" transform="translate(0.125071, 0.295455)">
              <polygon
                id="Rectangle"
                // fill="#AFC8FF"
                points="0 0.704545455 20.3414221 0.704545455 20.3414221 6.52272727 17.4355047 6.52272727 10.1707111 6.52272727 10.1707111 8.70454545 0 8.70454545"
              />
              <ellipse
                id="Oval"
                cx="1.27133888"
                cy="11.3068182"
                rx="1.27133888"
                ry="1.32954545"
              />
              <ellipse
                id="Oval"
                cx="6.35669441"
                cy="11.3068182"
                rx="1.27133888"
                ry="1.32954545"
              />
              <ellipse
                id="Oval"
                cx="13.1371685"
                cy="11.3068182"
                rx="1.27133888"
                ry="1.32954545"
              />
              <ellipse
                id="Oval"
                cx="18.222524"
                cy="11.3068182"
                rx="1.27133888"
                ry="1.32954545"
              />
              <line
                x1="12.7133888"
                y1="8.53409091"
                x2="20.3414221"
                y2="8.53409091"
                id="Line-6"
                // stroke-linecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DoubleEquipment;
