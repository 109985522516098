import React from 'react';
import Modal from 'react-modal';
import Button from '../controls/button';
import { connect } from 'react-redux';
import TextInput from '../controls/text-input';
import { patchEditEmail, ERROR_EDIT_EMAIL } from '../../redux/reducers/home.reducer';
import { loadSession, clearSession } from '../../redux/reducers/global-catalog.reducer';
import { handleWSError } from '../utils';

Modal.setAppElement('#root');

class ChangeEmailDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            response: this.props.response || '',
            error: this.props.error || false
        }
    }

    onClose = () => {
        this.setState({response: '', email: '', error: false});
        if (this.props.closeAction)
            this.props.closeAction();
    }

    isValid = () => {
        return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9-.]+$/.test(this.state.email);
    }

    render() {
        return (
        <Modal
            isOpen={this.props.open}
            portalClassName="dialog email-dialog"
            overlayClassName="overlay"
            className="content"
        >
            <div className="title">{this.props.title}</div>
            <div className="close" onClick={this.onClose}></div>
            <div className="message">{ this.state.response === '' ? this.props.message : this.state.response}</div>
            
            {
                (this.state.response === '') ?
                <React.Fragment>
                    <div style={{width: 'calc(99% - 160px)', paddingLeft: '80px', paddingRight: '80px'}}>
                        <TextInput
                            type='text'
                            label='Correo'
                            name='email'
                            className='full'
                            value={this.state.email}
                            onChange={ (event) => this.setState({email: event.target.value}) }
                            />
                    </div>

                    <div className="button_container">
                        <Button 
                            disabled = { !this.isValid() }
                            text= { this.props.acceptText }
                            onClick={ () => {

                                this.props.editEmail(this.state.email, this.props.id).then( (response) => {

                                    if (response.type === ERROR_EDIT_EMAIL) {

                                        const error = handleWSError(response.error);

                                        this.setState({
                                            response: error,
                                            error: true
                                        });

                                    } else {
                                        this.setState({
                                            response: 'Se actualizó correctamente tu correo, inicia sesión de nuevo',
                                            error: false
                                        });
                                    }

                                });

                            }}
                            />
                    </div>
                </React.Fragment>
                :
                <div className="button_container">
                    <Button 
                        disabled = { false }
                        text= { 'Continuar' }
                        onClick= { () => {

                            if (!this.state.error) {
                                this.props.clearSession();
                                window.location.href = '/login.html';
                            } else {
                                this.props.acceptAction();
                                this.setState({response: '', email: '', error: false});
                            }

                        }}/>
                </div>
            }

        </Modal>
    );
  }
}

const mapStateToProps = state => {
    return {
        editEmailError: state.home.editEmailError,
        editEmailResponse: state.home.editEmailResponse,
        isEditEmailFetching: state.home.isEditEmailFetching,
        email: state.globalCatalog.session.user.email || '',
        id: state.globalCatalog.session.user.id || ''
    };
}

const mapDispatchToProps = dispatch => {
    dispatch(loadSession());
    
    return ({
      editEmail: (newEmail, userId) => dispatch(patchEditEmail({ email: newEmail, username: newEmail, id: userId })),
      clearSession: () => dispatch(clearSession())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailDialog);