import React from "react";
const CellphoneIcon = (props) => {
  return (
    <svg 
      width="49px" 
      height="44px"
      viewBox="0 0 49 44"
      stroke-width="1"
      opacity="0.5"
      fill="#252E47"
      {...props}
    >
      <path d="M16,7 L16,37 L34,37 L34,7 L16,7 Z M24.5,8 C24.7853064,8 25,8.21375143 25,8.5 C25,8.78530643 24.7862486,9 24.5,9 C24.2146936,9 24,8.78624857 24,8.5 C24,8.21469357 24.2146793,8 24.5,8 Z M25,35 C24.4470638,35 24,34.5529362 24,34 C24,33.4470638 24.4470638,33 25,33 C25.5529362,33 26,33.4470638 26,34 C26,34.5519101 25.5529362,35 25,35 Z M32,31 L18,31 L18,10 L32,10 L32,31 Z" ></path>
    </svg>
  );
};

export default CellphoneIcon;