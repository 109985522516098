// @flow

import React, { createRef, useEffect } from "react";
import styled from "styled-components";
import SVGIcon from "../../shared/svg-icon";
import { icons } from "../../shared/svg-icon";
import { AssembleGridContainer } from "orbi_v2_frontend_components";
import MultiDataGridRow from "orbi_v2_frontend_components/lib/MultiDataGridRow";
import ExceedPlus from "../../shared/controls/exceed-plus";
import { Flex, flexOpts } from "../../shared/styled/flex";
import { AssembleAvatar } from "./assemble-avatar";
import type { GridConfig } from "./types";
import {
  Operator,
  DoubleOperator,
  TruckEquipment,
  TruckDoubleEquipment,
  DoubleEquipment,
  Equipment,
} from "../../shared/icons/assemble";
import HeaderSelect from "./header-select-styled";
// import { useState } from "react";
import { FILES_SERVER } from "../../redux/api";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  counters: { operators: number, vehicles: number, equipments: number },
  gridConfig: GridConfig,
  summary: Function,
  rows: Array<any>,
  dateChange: Function,
  gridConfigChange: Function,
  hasInfiniteScroll: Boolean,
  scrollCallback: Function,
  itemCount: number
};

const scrollRef = createRef()

export const AssembleGrid = ({
  counters = {},
  gridConfig,
  summary = () => {},
  rows = [],
  dateChange = () => {},
  gridConfigChange = () => {},
  hasInfiniteScroll = false,
  scrollCallback = () => {},
  itemCount = 0,
}: Props) => {
  // const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    // debugger;
    if(scrollRef.current){
      scrollRef.current.scrollLeft = 900;
      const r = document.getElementsByClassName(
        'scroll-test-traffic-row'
      )
      // console.log("SCROLL", scrollRef.current.scrollLeft);
      Array.from(r).forEach(
        a => (a.scrollLeft = scrollRef.current?.scrollLeft || 0)
      );
    }
  }, [rows]);

  return (
    // <AssembleGridStyled
    // // style={{
    // //   marginTop: "12px",
    // // }}
    // >
    <React.Fragment>
      <AssembleGridContainer
        headerTitle={
          <AssembleGridHeader
            {...{
              counters,
              dateChange,
              gridConfigChange,
              columnQuantity: gridConfig.columnQuantity,
            }}
          />
        }
        {...gridConfig}
        onDivisionSummary={summary}
      />
      {
        hasInfiniteScroll 
        ? <div id="scrollableDiv" style={{height: "calc(100% - 115px)", overflowY: "scroll"}}>
            <InfiniteScroll
              dataLength={rows.length}
              next={scrollCallback}
              hasMore={itemCount > rows.length}
              loader={<h4>Cargando...</h4>}
              // height={'100%'}
              scrollableTarget="scrollableDiv"
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Yay! You have seen it all</b>
              //   </p>
              // }
            >
              {rows.map((fa, i) => {
                if(fa.contract && !fa.contract.fleet_provider){
                  fa.contract.fleet_provider = fa.contract.cargo_owner;
                }
                return (
                <MultiDataGridRow
                  key={i}
                  scrollable={gridConfig.scrollable}
                  row={{
                    ...fa,
                    actions: undefined,
                  }}
                  header={{
                    width: "20%",
                    tooltipClassName: "one-damn-tooltip",
                    caption: (
                      <div
                        style={{
                          width: "100%",
                          marginTop: fa.contract ? "6px" : "0"
                        }}
                      >
                      <Flex
                        alignItems={flexOpts.alignItems.center}
                        justifyContent={flexOpts.justifyContent.spaceAround}
                        style={{
                          color: "#FFFFFF",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {
                          fa.contract &&
                          <CompanyAvatar>
                            <img
                              src={fa.contract.fleet_provider?.logo ? FILES_SERVER.concat(fa.contract.fleet_provider.logo) : "/images/interrogacion.svg"}
                              alt=""
                            />
                            {
                              fa.contract &&
                              <div style={{
                                // lineHeight: "35px",
                                // fontSize: "13px",
                                // paddingLeft: "10px"
                                fontSize: "12px",
                                paddingLeft: "6px",
                                paddingTop: "2px",
                                // height: "18px",
                                // display: "flex",
                                // alignItems: "flex-end"
                                maxWidth: "35px"
                              }}>
                                <div style={{width: "200px", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis"}}>
                                {fa.contract.facility?.name}
                                </div>
                                {/* {fa.contract.fleet_provider?.orbi_id} {' - '} */} 
                              </div>
                            }
                          </CompanyAvatar>
                        }

                        {getAssembleIcon(fa.resources.filter((f) => f.assembled))}

                        <ExceedPlus
                          name={fa.id}
                          className="hide-first-icon"
                          tooltipOpts={{
                            delayHide: 20,
                            clickable: false,
                          }}
                          face={
                            <span>
                              {
                                fa.resources.find((r) => r.assembled)
                                  .shortDescription
                              }
                            </span>
                          }
                        >
                          {fa.resources
                            .filter((f) => f.assembled)
                            .map((resource) => (
                              <div key={resource.id}>
                                <AssembleAvatar
                                  icon={resource.icon}
                                  fleetClass={resource.classification}
                                  key={resource.id}
                                  description={resource.shortDescription}
                                  fleetTypeId={resource.type}
                                  avatarUrl={resource.profile_image}
                                />
                              </div>
                            ))}
                        </ExceedPlus>
                      </Flex>
                      
                      </div>
                    ),
                    hoverCallback: fa.hoverCallback,
                    actions: fa.actions,
                  }}
                />
              )})}
            </InfiniteScroll>
          </div>
        : <div
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              // maxHeight: `${contentHeight}px`,
              maxHeight: "calc(100% - 115px)",
            }}
          >
            {rows.map((fa, i) => {
              if(fa.contract && !fa.contract.fleet_provider){
                fa.contract.fleet_provider = fa.contract.cargo_owner;
              }
              // if(i === 0){
              //   console.log("ROWS")
              // }
              // console.log(rows, fa);
              return (
              <MultiDataGridRow
                key={i}
                scrollable={gridConfig.scrollable}
                row={{
                  ...fa,
                  actions: undefined,
                }}
                header={{
                  width: "20%",
                  tooltipClassName: "one-damn-tooltip",
                  caption: (
                    <div
                      style={{
                        width: "100%",
                        marginTop: fa.contract ? "6px" : "0"
                      }}
                    >
                    <Flex
                      alignItems={flexOpts.alignItems.center}
                      justifyContent={flexOpts.justifyContent.spaceAround}
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        width: "100%",
                      }}
                    >
                      {
                        fa.contract &&
                        <CompanyAvatar>
                          <img
                            src={fa.contract.fleet_provider?.logo ? FILES_SERVER.concat(fa.contract.fleet_provider.logo) : "/images/interrogacion.svg"}
                            alt=""
                          />
                          {
                            fa.contract &&
                            <div style={{
                              // lineHeight: "35px",
                              // fontSize: "13px",
                              // paddingLeft: "10px"
                              fontSize: "12px",
                              paddingLeft: "6px",
                              paddingTop: "2px",
                              // height: "18px",
                              // display: "flex",
                              // alignItems: "flex-end"
                              maxWidth: "35px"
                            }}>
                              <div style={{width: "200px", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis"}}>
                               {fa.contract.facility?.name}
                              </div>
                              {/* {fa.contract.fleet_provider?.orbi_id} {' - '} */} 
                            </div>
                          }
                        </CompanyAvatar>
                      }

                      {getAssembleIcon(fa.resources.filter((f) => f.assembled))}

                      <ExceedPlus
                        name={fa.id}
                        className="hide-first-icon"
                        tooltipOpts={{
                          delayHide: 20,
                          clickable: false,
                        }}
                        face={
                          <span>
                            {
                              fa.resources.find((r) => r.assembled)
                                .shortDescription
                            }
                          </span>
                        }
                      >
                        {fa.resources
                          .filter((f) => f.assembled)
                          .map((resource) => (
                            <div key={resource.id}>
                              <AssembleAvatar
                                icon={resource.icon}
                                fleetClass={resource.classification}
                                key={resource.id}
                                description={resource.shortDescription}
                                fleetTypeId={resource.type}
                                avatarUrl={resource.profile_image}
                              />
                            </div>
                          ))}
                      </ExceedPlus>
                    </Flex>
                    </div>
                  ),
                  actions: fa.actions,
                }}
              />
            )})}
          </div>
      }
      {
        gridConfig.scrollable &&
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row'/* , position: 'absolute', bottom: '-40px'  */}}>
          <div style={{ minWidth: '20%', width: '20%' }}></div>
          <div
            ref={scrollRef}
            onScroll={e => {
              const r = document.getElementsByClassName(
                'scroll-test-traffic-row'
              )
              // console.log("SCROLL", scrollRef.current.scrollLeft);
              Array.from(r).forEach(
                a => (a.scrollLeft = scrollRef.current.scrollLeft)
              )
            }}
            style={{ width: '85%', overflowY: 'scroll' }}
          >
            <div
              style={{
                width: '4000px',
                height: '1px'
              }}
            ></div>
          </div>
        </div>
      }
    </React.Fragment>
    // </AssembleGridStyled>
  );
};

const getAssembleIcon = (resources) => {
  const operators = resources.filter((o) => o.type === 3);
  const vehicles = resources.filter((o) => o.type === 1);
  const equipments = resources.filter((o) => o.type === 2 && !o.isDolly);

  let operatorIcon = null;
  if (operators.length === 0) operatorIcon = null;
  else if (operators.length === 1) operatorIcon = <Operator />;
  else operatorIcon = <DoubleOperator />;

  let vehicleIcon = null;
  if (vehicles.length > 0) {
    vehicleIcon = vehicles[0]?.icon();
    if (equipments.length === 1) vehicleIcon = <TruckEquipment />;
    else if (equipments.length > 1) vehicleIcon = <TruckDoubleEquipment />;
  } else {
    if (equipments.length === 1) vehicleIcon = <Equipment />;
    else if (equipments.length > 1) vehicleIcon = <DoubleEquipment />;
  }

  return (
    <div
      style={{
        width: "100px",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "30px",
          height: "100%",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "flex-end",
          // margin: "0 6px",
        }}
      ></div>
      <div
        style={{
          width: "20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // margin: "0 6px",
        }}
      >
        {" "}
        {operatorIcon}
      </div>
      <div
        style={{
          width: "50px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          // margin: "0 6px",
        }}
      >
        {vehicleIcon}
      </div>
    </div>
  );
};

const DateControl = styled.div`
  transform: rotate(
    ${({ position }) => (position === "prev" ? "270deg" : "90deg")}
  );
  cursor: pointer;
  margin: 0 20px;
  & .svg {
    fill: "#AFC8FF";
  }
`;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: column;
  & img {
    height: 35px;
    width: 35px;
    // min-height: 48px;
    border-radius: 24px;
    // margin-right: 6px;
  }
`;

const AssembleGridHeader = ({
  counters,
  dateChange,
  gridConfigChange,
  columnQuantity,
}) => (
  <AssembleGridHeaderStyled>
    <AssembleGridHeaderTitle>
      <div>Flotilla</div>
      <HeaderSelect
        isSearchable={false}
        defaultValue={{ value: "12H", label: "12 Horas" }}
        //defaultValue={{ value: "24H", label: "24 Horas" }}
        options={GRID_OPTIONS}
        // menuIsOpen={true}
        maxMenuHeight={120}
        onChange={(a) =>
          gridConfigChange({
            target: { name: "gridConfigSelect", value: a.value },
          })
        }
        name="gridConfigSelect"
      />
    </AssembleGridHeaderTitle>
    <AssembleGridHeaderContent>
      <DateControl
        position="prev"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          viewBox="0 -200 1000 1000"
          onClick={() =>
            dateChange({
              target: { name: "gridDateChange", value: -columnQuantity },
            })
          }
        />
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          viewBox="0 200 1000 1000"
          onClick={() =>
            dateChange({
              target: { name: "gridDateChange", value: -columnQuantity },
            })
          }
        />
      </DateControl>
      <DateControl position="prev">
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          onClick={() =>
            dateChange({ target: { name: "gridDateChange", value: -1 } })
          }
        />
      </DateControl>
      {`Recursos libres:`}
      <div style={{ display: "flex", margin: "0 8px" }}>
        <SVGIcon
          name={icons.user}
          height="24px"
          width="24px"
          fill="#AFC8FF"
          viewBox="-10 0 32 32"
        />
        {`${counters.operators || "0"}  `}
      </div>
      <div style={{ display: "flex", margin: "0 8px" }}>
        <SVGIcon
          name={icons.truck}
          height="24px"
          width="24px"
          fill="#AFC8FF"
          viewBox="-2 -2 22 22"
        />{" "}
        {`${counters.vehicles || "0"}  `}
      </div>
      <div style={{ display: "flex", margin: "0 8px" }}>
        <SVGIcon
          name={icons.equipment}
          height="24px"
          width="24px"
          fill="#AFC8FF"
          viewBox="0 -5 32 32"
        />{" "}
        {`${counters.equipments || "0"}  `}
      </div>
      <DateControl position="next">
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          onClick={() =>
            dateChange({ target: { name: "gridDateChange", value: 1 } })
          }
        />
      </DateControl>
      <DateControl
        position="next"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          viewBox="0 -200 1000 1000"
          onClick={() =>
            dateChange({
              target: { name: "gridDateChange", value: columnQuantity },
            })
          }
        />
        <SVGIcon
          name={icons.caretDown}
          fill="#AFC8FF"
          viewBox="0 200 1000 1000"
          onClick={() =>
            dateChange({
              target: { name: "gridDateChange", value: columnQuantity },
            })
          }
        />
      </DateControl>
    </AssembleGridHeaderContent>
  </AssembleGridHeaderStyled>
);

const AssembleGridHeaderStyled = styled.div`
  margin: 20px 32px 15px 32px;
  width: 100%;
  display: flex;
  border-radius: 12px 12px 0 0;
`;

const AssembleGridHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  height: 300%;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 37px;
  align-self: flex-start;
`;

const AssembleGridHeaderContent = styled.div`
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin: auto;
`;

// const AssembleGridStyled = styled.div`
//   margin-top: 12px;
// `;

const GRID_OPTIONS = [
  { value: "6H", label: "6 Horas" },
  { value: "12H", label: "12 Horas" },
  { value: "24H", label: "24 Horas" },
  { value: "2D", label: "48 Horas" },
  { value: "4D", label: "4 Días" },
  { value: "8D", label: "8 Días" },
];
