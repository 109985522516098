import React from "react";
import TagCategoryContainer from "../../shared/component/tag-category-container";
import FilterSearchComponent from "../../shared/component/filter/search";
import { connect } from "react-redux";
import {
  loadSession,
  validateObjPerms,
} from "../../redux/reducers/global-catalog.reducer";
import {
  loadFleetClasses,
  loadVehicleTypes,
  loadOperatorTypes,
  loadEquipmentTypes,
} from "../../redux/reducers/fleet-reducer";
import { loadTags } from "../../redux/reducers/tags.reducer";
import Tag from "../../shared/controls/tag";
import {
  createExpeditionClass,
  listExpeditionClasses,
  updateExpeditionClass,
  createExpeditionClassTags,
  listExpeditionClassTags,
  deleteExpeditionClassTags,
} from "../../redux/reducers/company-reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import ValidatePerms from "../../shared/component/validate-perms";
// import ReactTooltip from "react-tooltip";

import { addToast } from "../../redux/reducers/global-catalog.reducer";
import { toast } from "react-toastify";
import { removeToast } from "../../redux/reducers/global-catalog.reducer";
import GroupContainer from "./group-container";
import { normalizeStr } from "../../shared/utils";
import { Flex } from "../../shared/styled/flex";
import {
  readEntities,
  createEntity,
  deleteEntity,
} from "../../redux/api/actions";
import { getEntityItems } from "../../redux/api/helpers";
import { StyledTag } from "./styled-tag";
import CreateExpeditionClassModal from "./create-expeditionclass-modal";
import EditExpeditionClassModal from "./edit-expeditionclass-modal";
import Checkbox from "../../shared/controls/checkbox";
import EditMultipleExpeditionClassModal from "./edit-multiple-expeditionclass-modal";
import { SaveItemCounter } from "../../redux/api/save-item-counter";

// let dollyId = null;

const cleanClass = {
  code: "",
  tag: 0,
  vehicle: {},
  equipment1: {},
  equipmentSubType1: 0,
  equipment2: {},
  equipmentSubType2: 0,
  equipment3: {},
  equipmentSubType3: 0,
};

class ExpeditionsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "ftl",
      selectionModeOn: false,
      selectedItems: [],
      selectedTag: [],

      showCreateExpeditionClassModal: false,
      showEditExpeditionClassModal: false,
      showEditMultipleExpeditionClassModal: false,
      showDeleteModal: false,

      current: cleanClass,

      onFocused: false,
      secondEquipment: false,
      addEquipment: false,
      errorText: "",
    };

    this.showToast = (text) => {
      toast.error(<div>{text}</div>, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        className: "error-toast",
      });
    };
  }
  componentDidMount() {
    this.loadExpeditionClasses();
  }
  componentDidUpdate() {
    if (this.props.toast) {
      this.props.removeToast();
      this.showToast(this.props.toast.text);
    }
  }
  showDeleteModal = (show) => {
    this.setState({ showDeleteModal: show });
  };
  toggleSelectionMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedItems: [],
    });
  };

  getEquipmentValidation = () => {
    // if (this.state.current.vehicleType) {
    //   let vehicleType = this.props.vehicleTypes.find(
    //     (vT) =>
    //       String(vT.id) === String(this.state.current.expeditionVehicleType)
    //   );
    //   if (vehicleType.description.toLowerCase() === "tracto") {
    //     return [validators.required];
    //   }
    // }
    return [];
  };

  deleteClasses = () => {
    const counter = new SaveItemCounter(() => {
      this.props.loadExpeditionClasses();
      this.setState({ showDeleteModal: false, selectedItems: [] });
    });
    this.state.selectedItems.forEach((eClass, index) => {
      counter.add();
      this.props.deleteExpeditionClass(
        {
          company_id: this.props.companyId,
          id: eClass,
        },
        {
          onSuccess: () => {
            counter.done();
          },
          onError: () => {
            counter.done();
          },
        }
      );
    });
  };

  selectAllClasses = () => {
    if (this.state.selectedItems.length < this.props.expeditionClasses.length) {
      this.setState({
        selectedItems: this.props.expeditionClasses.map((eClass) => eClass.id),
      });
    } else {
      this.setState({ selectedItems: [] });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let current = { ...this.state.current };
    this.setState({ errorText: "" });
    switch (name) {
      case "expedition":
        current.code = value.toUpperCase();
        this.setState({ current });
        break;
      case "addEquipment":
        current.equipment1 = {};
        current.equipmentSubType1 = 0;
        current.equipment2 = {};
        current.equipmentSubType2 = 0;
        current.equipment3 = {};
        current.equipmentSubType3 = 0;

        this.setState({
          current,
          addEquipment: !this.state.addEquipment,
        });
        break;
      case "secondEquipment":
        current.equipment2 = 0;
        current.equipmentSubType2 = 0;
        current.equipment3 = 0;
        current.equipmentSubType3 = 0;
        this.setState({
          current,
          secondEquipment: !this.state.secondEquipment,
        });
        break;
      case "selectModalTag":
        current.tag = e.target.value;
        this.setState({ current });
        break;
      case "vehicleType":
        const vehicle = this.props.compoundVehicleTypes.find(
          (eq) => eq.id.toString() === value.toString()
        );
        if (vehicle) {
          current.vehicle = vehicle;
          current.equipment1 = 0;
          current.equipmentSubType1 = 0;
          current.equipment2 = 0;
          current.equipmentSubType2 = 0;
          current.equipment3 = 0;
          current.equipmentSubType3 = 0;
          this.setState({
            current,
            nomenclatureVehicle: vehicle.abbreviation.substring(0, 2),
          });
        }
        break;
      case "equipmentType":
        const equipment = this.props.compoundEquipmentTypes.find(
          (e) => e.id.toString() === value?.toString()
        );
        if (equipment) {
          current.equipment1 = equipment;
          current.equipmentSubType1 = 0;
          current.equipment2 = 0;
          current.equipmentSubType2 = 0;
          current.equipment3 = 0;
          current.equipmentSubType3 = 0;
          this.setState({
            current,
          });
        }
        break;
      case "equipmentSubType":
        current.equipmentSubType1 = value;
        this.setState({
          current,
        });
        break;

      case "equipmentType2":
        const equipment2 = this.props.compoundEquipmentTypes.find(
          (e) => e.id.toString() === value.toString()
        );
        current.equipment2 = equipment2;
        current.equipmentSubType2 = 0;
        this.setState({
          current,
          // nomenclatureEquipment2: equipment2.upload_description.substring(0, 2),
        });
        break;
      case "equipmentSubType2":
        current.equipmentSubType2 = value;
        this.setState({
          current,
        });
        break;

      case "dollyType":
        const dolly = this.props.compoundEquipmentTypes.find(
          (e) => e.id.toString() === value?.toString()
        );
        if (dolly) {
          current.equipment3 = dolly;
          current.equipmentSubType3 = 0;
          // current.equipment2 = 0;
          // current.equipmentSubType2 = 0;

          this.setState({
            current,
          });
        }
        break;
      case "dollySubType":
        current.equipmentSubType3 = value;
        this.setState({
          current,
        });
        break;

      case "selectionMode":
        this.setState({ selectionModeOn: !this.state.selectionModeOn });
        break;
      case "multiSelect":
        let items = this.state.selectedItems.filter((x) => x !== value);
        if (e.target.checked) items = items.concat(value);
        this.setState({ selectedItems: items });
        break;
      default:
    }
  };

  handletagClick = (e) => {
    if (e.target.value) {
      this.setState({
        current: {
          ...this.state.current,
          tag: this.state.current.tag.filter((r) => r !== e.target.id),
        },
      });
    } else {
      this.setState({
        current: {
          ...this.state.current,
          tag: this.state.current.tag.concat(e.target.id),
        },
      });
    }
  };
  setEquipmentQty = (qty) => {
    this.setState({
      current: { ...this.state.current, equipmentQuantity: qty },
    });
  };
  setOperatorQty = (qty) => {
    this.setState({
      current: { ...this.state.current, expeditionOperatorQuantity: qty },
    });
  };
  handleLeave = () => {
    this.setState({ onFocused: true });
  };
  getErrors = () => {
    let errors = "";
    let vehicleType = "";

    if (this.state.current.vehicleType) {
      vehicleType = this.props.vehicleTypes
        .find(
          (vT) =>
            String(vT.id) === String(this.state.current.expeditionVehicleType)
        )
        .description.toLowerCase();
    }

    if (
      this.state.current.code === "" ||
      this.state.current.vehicleType === 0 ||
      this.state.current.expeditionLicenceType === 0 ||
      (vehicleType === "tracto" && this.state.current.equipmentType === 0)
    ) {
      errors = "Por favor completa los siguientes campos:<br/>";
    }

    errors +=
      this.state.current.code === "" ? `<b>Código de la clase<b/><br/>` : "";
    errors +=
      this.state.current.vehicleType === 0
        ? `<b>Tipo de Vehículo<b/><br/>`
        : "";
    errors +=
      vehicleType === "tracto" && this.state.current.equipmentType === 0
        ? `<b>Tipo de equipo<b/><br/>`
        : "";
    errors +=
      this.state.current.expeditionLicenceType === 0
        ? `<b>Tipo de licencia del operador<b/><br/>`
        : "";

    return errors;
  };

  handleSaveExpeditionClass = () => {
    const { current } = this.state;

    this.props.createClass(
      {
        company: this.props.companyId,
        company_id: this.props.companyId,
        code: current.code,
        equipment_quantity: [
          current.equipment1,
          current.equipment2,
          current.equipment3,
        ].filter((i) => i).length,

        vehicle_type: current.vehicle.typeID,
        fleet_class_vehicle: current.vehicle.classID,

        equipment_type:
          current.equipmentSubType1 !== 0
            ? current.equipmentSubType1
            : undefined,

        fleet_class_equipment: current.equipment1?.classID,

        equipment_type2:
          current.equipmentSubType2 !== 0
            ? current.equipmentSubType2
            : undefined,
        fleet_class_equipment2: current.equipment2?.classID,

        equipment_type3: current.equipment3
          ? current.equipmentSubType3
          : undefined,
        fleet_class_equipment3: current.equipment3?.classID,

        rutas_tipo_vehiculo: this.props.validNomenclatures.find(
          (n) => n.configuracion === this.getNomenclature()
        ).id,
      },
      {
        onSuccess: (newClass) => {
          if (current.tag) {
            this.createExpeditiontag(newClass.id, {
              onSuccess: () => {
                this.loadExpeditionClasses();
                this.setState({
                  showCreateExpeditionClassModal: false,
                  current: cleanClass,
                });
              },
            });
          } else {
            this.loadExpeditionClasses();
            this.setState({
              showCreateExpeditionClassModal: false,
              current: cleanClass,
            });
          }
        },
        onError: (e) => {
          this.setState({ errorText: "Ocurrió un error al guardar, el nombre de la etiqueta ya existe" });
          console.error(e.response);
        },
      }
    );
  };

  loadExpeditionClasses = () => {
    this.props.loadExpeditionClasses();
  };

  createExpeditiontag = (classId, opts = {}) => {
    const { current } = this.state;
    this.props.createClassTag(
      {
        company_id: this.props.companyId,
        traclass_id: classId,
        transport_class: classId,
        tags: current.tag,
      },
      opts
    );
  };

  UpdateExpeditiontag = (classTags = []) => {
    const { current } = this.state;

    classTags.forEach((t) =>
      this.props.deleteClassTag({
        company_id: this.props.companyId,
        traclass_id: current.id,
        transport_class: current.id,
        id: t.id,
      })
    );
    this.createExpeditiontag(current.id, {
      onSuccess: () => {
        this.loadExpeditionClasses();
        this.setState({
          showEditExpeditionClassModal: false,
          current: cleanClass,
        });
      },
    });
  };

  getNomenclature = () => {
    const { current } = this.state;
    const nom = [
      current.vehicle.abbreviation?.substring(0, 2) || "",
      current.equipment1.upload_description?.substring(0, 2) || "",
      current.equipment2.upload_description?.substring(0, 2) || "",
    ].filter((x) => x);

    if (current.equipment3?.id) {
      const last = nom.pop();
      const n =
        parseInt(last.substring(1, 2)) +
        parseInt(current.equipment3.upload_description.substring(1, 2));
      nom.push("R".concat(n));
    }
    return nom.join("-");
  };

  render() {
    return (
      <ValidatePerms perms={this.props.permsRead}>
        <div className="expeditions-tab">
          <div className="header">
            <div className="sorts"></div>
            <div className="search">
              <FilterSearchComponent />
            </div>
          </div>

          <TagCategoryContainer
            key={"ftl"}
            id={"ftl"}
            title={"FTL"}
            selectedTags={this.state.selectedItems}
            selectedCategory={this.state.selectedCategory}
            // handleTagClick={this.handleChange}
            handleCreateClick={() =>
              this.manageCreateModal({ target: { name: "open", value: "" } })
            }
            selectTag={this.handleChange}
            selectionModeOn={
              this.state.selectionModeOn &&
              this.state.selectedCategory === "ftl"
            }
            toggleSelectionMode={() =>
              this.handleChange({
                target: { name: "selectionMode", value: "" },
              })
            }
            evalTabs={this.props.evalTabs}
            permsTabs={this.props.permsTabs}
            permsAdd={this.props.permsAdd}
            permsUpdate={this.props.permsUpdate}
            validateObjPerms={this.props.validateObjPerms}
          >
            <Flex style={{ flexFlow: "wrap" }}>
              {this.props.expeditionClasses
                .filter((x) => x.tags.length === 0)
                .map((exp) => (
                  <React.Fragment>
                    {this.state.selectionModeOn && (
                      <Checkbox
                        onChange={() => {}}
                        item={{
                          description: "",
                          // active: false,
                          id: exp.id,
                        }}
                        name="multiSelect"
                        active={this.state.selectedItems.find(
                          (x) => x.toString() === exp.id.toString()
                        )}
                      />
                    )}
                    <StyledTag
                      key={exp.id}
                      id={exp.id}
                      title={exp.code}
                      onClick={() =>
                        this.manageEditModal({
                          target: { name: "open", value: exp },
                        })
                      }
                    />
                  </React.Fragment>
                ))}
              {this.props.tags.map((tag) => (
                <GroupContainer key={tag.id} title={`${tag.title}:`}>
                  {this.props.expeditionClasses
                    .filter((x) => x.tags[0]?.id === tag.id)
                    .map((exp) => (
                      <React.Fragment>
                        {this.state.selectionModeOn && (
                          <Checkbox
                            onChange={() => {}}
                            item={{
                              description: "",
                              // active: true,
                              id: exp.id,
                            }}
                            name="multiSelect"
                            active={this.state.selectedItems.find(
                              (x) => x.toString() === exp.id.toString()
                            )}
                          />
                        )}
                        <StyledTag
                          key={exp.id}
                          id={exp.id}
                          title={exp.code}
                          onClick={() =>
                            this.manageEditModal({
                              target: { name: "open", value: exp },
                            })
                          }
                        />
                      </React.Fragment>
                    ))}
                </GroupContainer>
              ))}
            </Flex>
          </TagCategoryContainer>
          {this.state.showCreateExpeditionClassModal && (
            <CreateExpeditionClassModal
              errorText={this.state.errorText}
              show={this.state.showCreateExpeditionClassModal}
              manageModal={this.manageCreateModal}
              onChange={this.handleChange}
              expClass={this.state.current}
              tags={this.props.tags}
              compoundEquipments={this.props.compoundEquipmentTypes}
              compoundVehicles={this.props.compoundVehicleTypes}
              equipmentSubTypes={this.props.equipmentSubtypes}
              nomenclatures={this.props.validNomenclatures}
              addEquipment={this.state.addEquipment}
              secondEquipment={this.state.secondEquipment}
            />
          )}
          {this.state.showEditExpeditionClassModal && (
            <EditExpeditionClassModal
              show={this.state.showEditExpeditionClassModal}
              manageModal={this.manageEditModal}
              onChange={this.handleChange}
              tags={this.props.tags}
              expClass={this.state.current}
              compoundEquipments={this.props.compoundEquipmentTypes}
              compoundVehicles={this.props.compoundVehicleTypes}
              equipmentSubTypes={this.props.equipmentSubtypes}
              nomenclatures={this.props.validNomenclatures}
              addEquipment={this.state.addEquipment}
              secondEquipment={this.state.secondEquipment}
            />
          )}
          {this.state.showEditMultipleExpeditionClassModal && (
            <EditMultipleExpeditionClassModal
              show={this.state.showEditMultipleExpeditionClassModal}
              manageModal={this.manageMultipleEditModal}
              onChange={this.handleChange}
              tags={this.props.tags}
            />
          )}
          <ConfirmDialog
            open={this.state.showDeleteModal}
            title="Eliminar clases"
            message={"¿Estás seguro que deseas eliminar estas clases?"}
            contentObject={
              <div>
                {this.props.expeditionClasses
                  .filter((t) => this.state.selectedItems.includes(t.id))
                  .map((tag) => (
                    <Tag key={tag.id} id={tag.id} title={tag.code} />
                  ))}
              </div>
            }
            acceptText="Eliminar"
            class="delete-tags"
            showCancel={true}
            cancelText="Cancelar"
            cancelButtonType="secondary"
            closeAction={() => this.showDeleteModal(false)}
            acceptAction={this.deleteClasses}
          />
          <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedItems.length}
            selectAll={this.selectAllClasses}
            actions={[
              this.props.validateObjPerms(
                [
                  {
                    id: "15",
                    perm: "d",
                  },
                ],
                {
                  description: "Eliminar",
                  f: () => this.showDeleteModal(true),
                }
              ),
            ]}
          />
        </div>
      </ValidatePerms>
    );
  }

  manageCreateModal = (e) => {
    const { name } = e.target;
    switch (name) {
      case "open":
        this.setState({
          current: cleanClass,
          showCreateExpeditionClassModal: true,
        });
        break;
      case "close":
        this.setState({ showCreateExpeditionClassModal: false });
        break;
      case "cancel":
        this.setState({ showCreateExpeditionClassModal: false });
        break;
      case "save":
        this.handleSaveExpeditionClass();
        // this.setState({ showCreateExpeditionClassModal: false });
        break;
      default:
    }
  };

  manageMultipleEditModal = (e) => {
    const { name } = e.target;
    switch (name) {
      case "open":
        this.setState({
          current: cleanClass,
          showEditMultipleExpeditionClassModal: true,
        });
        break;
      case "close":
        this.setState({ showEditMultipleExpeditionClassModal: false });
        break;
      case "cancel":
        this.setState({ showEditMultipleExpeditionClassModal: false });
        break;
      case "update":
        break;
      default:
    }
  };

  manageEditModal = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        const current = {
          id: value.id,
          original: value,
          code: value.code,
          equipmentSubType1: value.equipment_type,
          equipmentSubType2: value.equipment_type2,
          equipmentSubType3: value.equipment_type3,
          equipment1: this.props.compoundEquipmentTypes.find(
            (c) => c.classID === value.fleet_class_equipment?.toString()
          ),
          equipment2: this.props.compoundEquipmentTypes.find(
            (c) => c.classID === value.fleet_class_equipment2?.toString()
          ),
          equipment3: this.props.compoundEquipmentTypes.find(
            (c) => c.classID === value.fleet_class_equipment3?.toString()
          ),
          vehicle: this.props.compoundVehicleTypes.find(
            (v) => v.classID === value.fleet_class_vehicle.toString()
          ),
          nomenclatureId: value.rutas_tipo_vehiculo,
          tag: value.tags[0]?.id,
        };
        this.setState({
          current,
          showEditExpeditionClassModal: true,
        });
        break;
      case "close":
        this.setState({
          showEditExpeditionClassModal: false,
        });
        break;
      case "cancel":
        this.setState({
          showEditExpeditionClassModal: false,
        });
        break;
      case "update":
        this.props.readClassTags(
          {
            company_id: this.props.companyId,
            traclass_id: this.state.current.id,
          },
          {
            onSuccess: (tags) => {
              this.UpdateExpeditiontag(tags);
            },
          }
        );
        break;
      default:
    }
  };
}

// export default ExpeditionsTab;

const mapStateToProps = (state) => {
  const validNomenclatures = getEntityItems(state, "RUTAS.TIPOVEHICULO");
  const vehicleTypes = Array.isArray(state.fleetReducer.vehicleType.data)
    ? state.fleetReducer.vehicleType.data
    : [];
  const fleetClasses = Array.isArray(state.fleetReducer.fleetClass.data)
    ? state.fleetReducer.fleetClass.data
    : [];
  let vehicleRelatedArray = [];
  if (vehicleTypes.length > 0 && fleetClasses.length > 0) {
    vehicleRelatedArray = joinRelatedArraysVehicle(
      vehicleTypes,
      fleetClasses,
      "type"
    );
  }

  const equipmentTypes = Array.isArray(state.fleetReducer.vehicleType.data)
    ? state.fleetReducer.vehicleType.data
    : [];
  let equipmentRelatedArray = [];
  if (equipmentTypes.length > 0 && fleetClasses.length > 0) {
    equipmentRelatedArray = joinRelatedArraysEquipment(
      equipmentTypes,
      fleetClasses,
      "type"
    );
  }

  return {
    validNomenclatures,
    toast: state.globalCatalog.toast,
    tags: (state.tags.tags || []).filter(
      (tag) =>
        tag.type ===
        (
          (state.tags.tagTypes.data || []).find(
            (type) =>
              normalizeStr(type.description.toLowerCase()) ===
              normalizeStr("Clases de expedición".toLowerCase())
          ) || { id: 0 }
        ).id
    ),
    compoundVehicleTypes: vehicleRelatedArray,
    compoundEquipmentTypes: equipmentRelatedArray,
    licenceTypes: Array.isArray(state.fleetReducer.operatorType.data)
      ? state.fleetReducer.operatorType.data
      : [],
    equipmentTypes,
    vehicleTypes,
    fleetClasses,
    equipmentSubtypes: state.fleetReducer.equipmentType.data,
    expeditionClasses: state.companyReducer.companyExpeditionClasses.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(loadFleetClasses());
  dispatch(loadTags());
  dispatch(loadVehicleTypes());
  dispatch(loadEquipmentTypes());
  dispatch(loadOperatorTypes());
  // dispatch(listExpeditionClasses());
  dispatch(readEntities("RUTAS.TIPOVEHICULO"));
  return {
    createClass: (params, opts) =>
      dispatch(createEntity("COMPANIES.TRANSPORTCLASS", params, opts)),

    createClassTag: (params, opts) =>
      dispatch(createEntity("COMPANIES.TRANSPORTCLASS.TAGS", params, opts)),
    deleteClassTag: (params, opts) =>
      dispatch(deleteEntity("COMPANIES.TRANSPORTCLASS.TAGS", params, opts)),
    readClassTags: (params, opts) =>
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS.TAGS", params, opts)),
    deleteExpeditionClass: (params, opts) =>
      dispatch(deleteEntity("COMPANIES.TRANSPORTCLASS", params, opts)),

    showToast: (payload) => dispatch(addToast(payload)),
    removeToast: () => dispatch(removeToast()),
    createExpeditionClass: (params) => dispatch(createExpeditionClass(params)),
    updateExpeditionClass: (params) => dispatch(updateExpeditionClass(params)),
    loadExpeditionClasses: () => dispatch(listExpeditionClasses()),
    listExpeditionClassTags: (class_id) =>
      dispatch(listExpeditionClassTags(class_id)),
    deleteExpeditionClassTags: (class_id, tag_id) =>
      dispatch(deleteExpeditionClassTags(class_id, tag_id)),
    createExpeditionClassTags: (class_id, tag_id) =>
      dispatch(createExpeditionClassTags(class_id, tag_id)),
    validateObjPerms: (perms, obj, evalPerm) =>
      validateObjPerms(perms, obj, evalPerm),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(ExpeditionsTab);

const joinRelatedArraysVehicle = (arr1 = [], arr2 = [], relatedField) => {
  let count = 1;
  let newArray = [];
  arr1.forEach((a1) => {
    let matchingItems = arr2.filter((a2) => a1.id === a2[relatedField]);
    let typeID = `${a1.id}`;
    if (matchingItems.length && `${a1.type}` === "1") {
      matchingItems.forEach((a2) =>
        newArray.push({
          id: count++,
          description: a1.description.concat(" - ", a2.description),
          classID: `${a2.id}`,
          typeID,
          abbreviation: a2.description,
        })
      );
    }
  });
  return newArray;
};

const joinRelatedArraysEquipment = (arr1 = [], arr2 = [], relatedField) => {
  let count = -99999;
  let x = [];
  arr1.forEach((a1) => {
    let t = arr2.filter((a2) => a1.id === a2[relatedField]);
    // debugger

    let typeID = `${a1.id}`;

    if (`${a1.type}` === "2") {
      if (t.length) {
        t.forEach((a2) => {
          const idToAssing = count++;
          // if (String(a1.description).toLowerCase().includes('dolly'))
          // dollyId = idToAssing;
          x.push({
            id: idToAssing,
            description: a1.description.concat(" - ", a2.description),
            classID: `${a2.id}`,
            typeID,
            upload_description: a2.upload_description,
          });
        });
      } else {
        const idToAssing = count++;
        // if (String(a1.description).toLowerCase().includes('dolly'))
        // dollyId = idToAssing;
        x.push({ id: idToAssing, description: a1.description, typeID });
      }
    }
  });
  return x;
};
