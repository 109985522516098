import styled from "styled-components";

export const SummaryPanel = styled.div`
  border-radius: 12px;
  background: linear-gradient(180deg, #404d6e 0%, #2f3b52 100%);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.24);
  margin: 10px 0;

  overflow: hidden;
`;
