// @flow
import { union } from "lodash";
import type { ApiReducerAction } from "./types";

export const isValidAction = (action: ApiReducerAction) => {
  const { type } = action;
  if (
    type.startsWith(`API_REQUEST_`) ||
    type.startsWith(`API_SUCCESS_`) ||
    type.startsWith(`API_FAIL_`)
  ) {
    return true;
  }
  return false;
};

export const requestStatus = (state: any, action: ApiReducerAction, cleanCancels) => {
  const current = state[action.alias || action.entityPath] || { items: [] };
  const isMultipleCancels = action?.params?.isMultipleCancels ?? false;

  const cancels = cleanCancels ? [] : state.cancels || [];

  if (isMultipleCancels) {
    cancels.push(action.cancel);
  }

  return {
    ...state,
    [action.alias || action.entityPath]: {
      args: current.args,
      items: current.items,
      status: {
        isFetching: true,
        error: null,
      },
      cancel: action.cancel,
    },
    cancels
  };
};

export const receiveStatus = (state: any, action: ApiReducerAction) => {
  const current = state[action.alias || action.entityPath] || { items: [] };
  return {
    ...state,
    [action.alias || action.entityPath]: {
      args: action.args,
      items: STRATEGIES[action.strategy](current.items, action.payload),
      status: {
        isFetching: false,
        error: null,
      },
      cancel: undefined,
    },
  };
};

export const failStatus = (state: any, action: ApiReducerAction) => ({
  ...state,
  [action.alias || action.entityPath]: {
    ...state[action.entityPath],
    args: action.args,
    status: {
      isFetching: false,
      error: action.error,
    },
    cancel: undefined,
  },
});

export const createdStatus = (state: any, action: ApiReducerAction) => {
  const current = state[action.alias || action.entityPath] || { items: [] };
  return {
    ...state,
    [action.alias || action.entityPath]: {
      args: action.args,
      items: STRATEGIES[action.strategy](current.items, action.payload),
      // items: action.payload,
      status: {
        isFetching: false,
        error: null,
      },
      cancel: undefined,
    },
  };
};

export const deletedStatus = (state: any, action: ApiReducerAction) => {
  const current = state[action.alias || action.entityPath] || { items: [] };
  return {
    ...state,
    [action.alias || action.entityPath]: {
      args: action.args,
      items: STRATEGIES[action.strategy](current.items, action.payload),
      // items: action.payload,
      status: {
        isFetching: false,
        error: null,
      },
      cancel: undefined,
    },
  };
};

export const receiveCatalogStatus = (state: any, action: ApiReducerAction) => {
  const current = state[action.alias || action.entityPath] || { items: [] };
  return {
    ...state,
    [action.alias || action.entityPath]: {
      args: action.args,
      count: action.payload.count,
      // items: action.payload.results,
      items: STRATEGIES[action.strategy](current.items, action.payload.results),
      status: {
        isFetching: false,
        error: null,
      },
      cancel: undefined,
    },
  };
};

export const getEntityItems = (state: any, entityPath: string) => {
  return (
    state.api[entityPath] || {
      items: [],
    }
  ).items;
};

export const getEntityCount = (state: any, entityPath: string) => {
  return (
    state.api[entityPath] || {
      count: 0,
    }
  ).count;
};

export const getEntityArgs = (state: any, entityPath: string) => {
  return (
    (
      state.api[entityPath] || {
        args: {},
      }
    ).args || {}
  );
};

const STRATEGIES = {
  REPLACE: (original = [], received = []) => received,
  MERGE: (original = [], received = []) => union(original, received),
  ADD: (original = [], received = []) => original.concat(received),
  IGNORE: (original = [], received = []) => original,
};

export const API_REQUEST_STRATEGY_OPTIONS = {
  DEFAULT: "REPLACE",
  REPLACE: "REPLACE",
  ADD: "ADD",
  MERGE: "MERGE",
  IGNORE: "IGNORE",
};
