import React from "react";
const TruckBackward = (props) => {
  return (
    <svg
      width="28px"
      height="30px"
      viewBox="0 0 70 30"
      fill="currentColor"
      {...props}
    >
      <path
        d="M47.2538138,15.3236951 L41.7611297,5.12322328 C41.364946,4.41808904 40.6228152,3.94799956 39.7821407,3.94799956 L32.5098507,3.94799956 L32.5098507,2.20904452 C32.5098507,0.98605971 31.4700899,0 30.1849344,0 L2.82491681,0 C1.53778234,0 0.5,0.987940068 0.5,2.20904452 L0.5,25.1498816 C0.5,26.3728664 1.53976084,27.3589261 2.82491681,27.3589261 L5.14983263,27.3589261 C5.44745279,30.5081496 8.1685825,33 11.5310828,33 C14.8957599,33 17.6649593,30.5081496 17.9123329,27.3589261 L29.8847218,27.3589261 C30.1823419,30.5081496 32.9034717,33 36.2659719,33 C39.6306491,33 42.3998484,30.5081496 42.647222,27.3589261 L45.2736373,27.3589261 C46.5105055,27.3589261 47.5,26.4187472 47.5,25.2435234 L47.5,16.3118232 C47.5,15.9831272 47.4014365,15.65445 47.2526264,15.3238831 L47.2538138,15.3236951 Z M34.0714286,8.8 L39.6604506,8.8 L43.0238095,15.4 L34.0717127,15.4 L34.0714286,8.8 Z M11.6904762,28.6 C10.4635529,28.6 9.45238095,27.6060395 9.45238095,26.4 C9.45238095,25.1939605 10.4635529,24.2 11.6904762,24.2 C12.9173995,24.2 13.9285714,25.1939605 13.9285714,26.4 C13.9285714,27.6060395 12.9173995,28.6 11.6904762,28.6 Z M36.3095231,28.6 C35.0826002,28.6 34.0714286,27.6060395 34.0714286,26.4 C34.0714286,25.1939605 35.0826002,24.2 36.3095231,24.2 C37.536446,24.2 38.547619,25.1939605 38.547619,26.4 C38.5490292,27.6060395 37.537854,28.6 36.3095231,28.6 Z"
        id="Fill-1-Copy-4"
      ></path>
      <path
        d="M50.2894737,39.0833333 C50.7468452,39.0833333 51.0586722,39.0833333 51.9189319,39.0833333 C55.5253016,39.0833333 57.745614,37.0185186 57.745614,34.3333333 C57.745614,31.6481481 55.5253016,29.5833333 51.9189319,29.5833333 L42.3947368,29.5833333 L42.3947368,28 L51.9189319,28 C56.5061874,28 59.5,30.7841436 59.5,34.3333333 C59.5,37.8825231 56.5061874,40.6666667 51.9189319,40.6666667 L50.2894737,40.6666667 L50.2894737,47 L34.5,39.875 L50.2894737,32.75 L50.2894737,39.0833333 L50.2894737,39.0833333 Z"
        id="Path-4-Copy-3"
      ></path>
    </svg>
  );
};

export default TruckBackward;
