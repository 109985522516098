// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { View, ViewTitle } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import { readEntities, createEntity, partialEntity, deleteEntity } from "../../redux/api/actions";
import { getEntityItems } from "../../redux/api/helpers";
import { GridConfig } from "../assemble/types";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import { ResourcesGrid } from "./resources-styled";
import Modal from 'react-modal';
import Button from '../../shared/controls/button';
import SelectInput from '../../shared/controls/select-input';
import { AssembleAvatar } from "../assemble/assemble-avatar";

import {
  getFilterParamsInitialDateTime as addValueToFilterParamsDateTime, /* normalizeStr, */
} from "../../shared/utils";
import TextInput from "../../shared/controls/text-input";
import SVGIcon, { icons } from "../../shared/svg-icon";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { loadVehicleTypes, loadEquipmentTypes, loadOperatorTypes } from "../../redux/reducers/fleet-reducer";
import TextExceedPlus from "../../shared/controls/text-exceed-plus";
import * as moment from "moment";
import { setHours, setMinutes } from "date-fns";
import { VerticalLineTooltipStyled } from "../assemble/assemble-edit-modal/styled";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";

type Props = {};
type State = {
  +textSearch: string,
  +selectedTags: array,
  +selectedStatus: array,
  +selectedType: array,
  
  +gridConfig: GridConfig,
};

const fleetTypes = [
  { id: 1, description: "Vehículos" },
  { id: 2, description: "Equipos" },
  { id: 3, description: "Operadores" }
];

const today = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
const hour = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate(),
  new Date().getHours()
);
const week = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() - new Date().getDay() + 1,
  new Date().getHours()
);
const month = new Date(new Date().getFullYear(), new Date().getMonth());

const defaultGridConfig: GridConfig = {
  columnQuantity: 4,
  columnRangeType: "WEEK",
  columnSections: 4,
  startDatetime: today,
  extended: true,
};

let typingTimer;

class ResourcesView extends Component<Props, State> {
  state = {
    fleetTypeItem: fleetTypes[0],
    buttonFilterName: "Aplicar",
    textSearch: '',
    selectedType: [],
    selectedTags: [],
    selectedStatus: [],
    start_date: today.toISOString(),
    end_date: addValueToFilterParamsDateTime(
        today,
        defaultGridConfig.columnQuantity,
        defaultGridConfig.columnRangeType
      ).toISOString(), 
    gridConfig: defaultGridConfig,

    showCreateExemptionModal: false,

    limit: 10,
    offset: 1,

    selectedMotive: 0,
    selectedExemption: 0,
    selectedFleetId: 0,
    selectedOperatorId: 0,
    selectedFleetDescription: '',
    selectedFleetImage: '',
    selectedFleetExemptions: [],
    selectedFleetExemptionStart: null,
    selectedFleetExemptionEnd: null,
  };

  isLoadingCounter = false;
  counterValue = null;

  componentDidMount() {
    this.props.listCompanyFleets(this.props.companyId, {
      onSuccess: this.loadResources,
    });
    this.props.loadCompanyTags(this.props.companyId);  
    this.props.listResourcesOperatorMotives(this.props.companyId);
    this.props.listResourcesEquipmentMotives(this.props.companyId);
    this.props.listResourcesVehicleMotives(this.props.companyId);
    this.props.setNavbarAction("resources-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("resources-control");
  }

  switchFleetType = e => {
    if (e.target.attributes["name"]) {
      let ftId = e.target.attributes["name"].value;
      let type = fleetTypes.find(ft => ft.id.toString() === ftId);
      this.setState({
        textSearch: '',
        selectedType: [],
        selectedTags: [],
        selectedStatus: [],
        offset: 1,

        fleetTypeItem: type,
        buttonFilterName: "Aplicar"
      }, this.loadResources);

    }
  };

  showCreateExemptionModal = show => {
    if(show)
      this.setState({showCreateExemptionModal: show})
    else
      this.setState({
        showCreateExemptionModal: show,
        selectedMotive: 0,
        selectedExemption: 0,
        selectedFleetId: 0,
        selectedOperatorId: 0,
        selectedFleetDescription: '',
        selectedFleetImage: '',
        selectedFleetExemptions: [],
        selectedFleetExemptionStart: null,
        selectedFleetExemptionEnd: null,
      })
  }

  getMaxDate = () => {
    if(!this.state.selectedFleetExemptionStart || !this.state.selectedFleetExemptions.length){
      return null;
    } else {
      let exemptions = this.state.selectedFleetExemptions.filter(e => e.id !== this.state.selectedExemption);
      if(!exemptions.length) return null;
      exemptions = exemptions.map(e => Date.parse(e.exception_from)).sort((a, b) => a-b);
      let start = Date.parse(this.state.selectedFleetExemptionStart);
      // debugger;
      exemptions = exemptions.filter(e => e > start);
      if(!exemptions.length) return null;
      return exemptions[0];
    }
  }

  filterBusyDates = (day) => {
    let i = 0;
    let valid = true;
    day = Date.parse(day.toISOString());
    while(valid && i < this.state.selectedFleetExemptions.length){
      if(this.state.selectedFleetExemptions[i].id !== this.state.selectedExemption){
        let eStart = Date.parse(this.state.selectedFleetExemptions[i].exception_from);
        let eEnd = Date.parse(this.state.selectedFleetExemptions[i].exception_to);
        if(day >= eStart && day <= eEnd)
          valid = false;
      }
      i++;
    }
    return valid;
  }

  getTimeLine = (exemptions, editFunc) => {
    if(!exemptions.length)
      return [{
          available: true,
          caption: '',
          id: '',
          visible: true,
          width: "100%"
      }]
    let timeLine = [];
    let prevPeriodEnd = Date.parse(this.state.start_date);
    const start = Date.parse(this.state.start_date);
    const end = Date.parse(this.state.end_date);
    const totalLength = end - start;
    exemptions.filter((e, i, a) => {
      let eStart = Date.parse(e.exception_from);
      let eEnd = Date.parse(e.exception_to);
      return eStart > end || eEnd < start ? false : true;
    }).sort((a, b) => {
      let aStart = (Date.parse(a.exception_from));
      let bStart = (Date.parse(b.exception_from));
      if(aStart > bStart)
        return 1
      if(aStart < bStart)
        return -1
      return 0;
    }).forEach((e) => {
      timeLine.push({
        available: true,
        caption: '',
        id: '',
        visible: true,
        width: this.getLineWidth(prevPeriodEnd, Date.parse(e.exception_from), totalLength)
      });
      timeLine.push({
        available: false,
        caption:<div style={{width: "100%", transform:"translateY(-10px)", textAlign: "center"}}>
                  <TextExceedPlus
                    name={"exemption-".concat(e.id)}
                    tooltipClassName="exemption-tooltip"
                  > 
                    {[<span onClick={() => editFunc(e)}>{this.getExemptionCaption(e.motive_id)}</span>]}
                  </TextExceedPlus>
                </div>,
        id: '',
        visible: true,
        width: this.getLineWidth(Math.max(Date.parse(e.exception_from), start), Math.min(Date.parse(e.exception_to), end), totalLength)
      });
      prevPeriodEnd = Math.min(Date.parse(e.exception_to), end);
    });
    timeLine.push({
      available: true,
      caption: '',
      id: '',
      visible: true,
      width: this.getLineWidth(prevPeriodEnd, end, totalLength)
    })

    return timeLine.filter(tL => tL.width !== '0%');
  }

  getExemptionCaption = (e_id) => {
    switch(this.state.fleetTypeItem.id) {
      case 1: 
        return (this.props.companyResourcesVehicleMotives.find(m => m.id === e_id) || {description: ''}).description;
      case 2: 
      return (this.props.companyResourcesEquipmentMotives.find(m => m.id === e_id) || {description: ''}).description;
      case 3: 
      return (this.props.companyResourcesOperatorMotives.find(m => m.id === e_id) || {description: ''}).description;
      default:
        return '';
    }
  }

  getLineWidth = (start, end, totalLength) => {
    let length = (100 * (end - start)) / totalLength;
    if (length < 0) length = 0;
    return `${length}%`;
  };

  getRows = () => {
    switch(this.state.fleetTypeItem.id){
      case 1:
        return Array.isArray(this.props.companyResourcesVehicles) && this.props.companyResourcesVehicles/* .filter(ve => 
            normalizeStr(ve.economic_number).includes(normalizeStr(this.state.textSearch))
          ) */.map(ve => {
          return {
            style: {zIndex: 9998},
            id: ve.fleet_data,
            resources: [{
              id: ve.fleet_data,
              type: this.state.fleetTypeItem.id,
              profile_image: ve.photo_url,
              shortDescription: ve.economic_number,
              longDescription: ve.economic_number,
              //classification:,
              //item: ,
              //logId: ,
              normativityStatusId: ve.status 
            }],
            detail: [],
            timeLine: this.getTimeLine(ve.resource_control, (ex) => {
              this.setState({
                showCreateExemptionModal: true,
                selectedMotive: ex.motive_id,
                selectedExemption: ex.id,
                selectedFleetExemptionStart: ex.exception_from,
                selectedFleetExemptionEnd: ex.exception_to,
                selectedFleetId: ve.fleet_data,
                selectedFleetDescription: ve.economic_number,
                selectedFleetImage: ve.photo_url,
                selectedFleetExemptions: ve.resource_control,
              })
            }),
            header_actions: [{
              description: 'Programar salida de operacion',
              action: () => {
                this.setState({
                  showCreateExemptionModal: true,
                  // selectedMotive: motive.id,
                  selectedFleetId: ve.fleet_data,
                  selectedFleetDescription: ve.economic_number,
                  selectedFleetImage: ve.photo_url,
                  selectedFleetExemptions: ve.resource_control,
                })
              }
            }]
          }
        });
      case 2:
        return Array.isArray(this.props.companyResourcesEquipments) && this.props.companyResourcesEquipments/* .filter(eq => 
            normalizeStr(eq.economic_number).includes(normalizeStr(this.state.textSearch))
          ) */.map(eq => {
          return {
            style: {zIndex: 9998},
            id: eq.fleet_data,
            resources: [{
              id: eq.fleet_data,
              type: this.state.fleetTypeItem.id,
              profile_image: eq.photo_url,
              shortDescription: eq.economic_number,
              longDescription: eq.economic_number,
              //classification:,
              //item: ,
              //logId: ,
              normativityStatusId: eq.status 
            }],
            detail: [],
            timeLine: this.getTimeLine(eq.resource_control, (ex) => {
              this.setState({
                showCreateExemptionModal: true,
                selectedMotive: ex.motive_id,
                selectedExemption: ex.id,
                selectedFleetExemptionStart: ex.exception_from,
                selectedFleetExemptionEnd: ex.exception_to,
                selectedFleetId: eq.fleet_data,
                selectedFleetDescription: eq.economic_number,
                selectedFleetImage: eq.photo_url,
                selectedFleetExemptions: eq.resource_control,
              })
            }),
            header_actions: [{
              description: 'Programar salida de operacion',
              action: () => {
                this.setState({
                  showCreateExemptionModal: true,
                  // selectedMotive: motive.id,
                  selectedFleetId: eq.fleet_data,
                  selectedFleetDescription: eq.economic_number,
                  selectedFleetImage: eq.photo_url,
                  selectedFleetExemptions: eq.resource_control,
                })
              }
            }]
          }
        });
      case 3:
        return Array.isArray(this.props.companyResourcesOperators) && this.props.companyResourcesOperators/* .filter(op => 
            normalizeStr(op.first_name).includes(normalizeStr(this.state.textSearch))
            || normalizeStr(op.last_name).includes(normalizeStr(this.state.textSearch))
          ) */.map(op => {
          return {
            style: {zIndex: 9998},
            id: op.fleet_operator,
            resources: [{
              id: op.fleet_operator,
              type: this.state.fleetTypeItem.id,
              profile_image: op.profile_image,
              shortDescription: op.first_name.concat(' ', op.last_name),
              longDescription: op.first_name.concat(' ', op.last_name),
              classification: op.license_type,
              //item: ,
              //logId: ,
              normativityStatusId: op.status
            }],
            detail: [],
            timeLine: this.getTimeLine(op.resource_control, (ex) => {
              this.setState({
                showCreateExemptionModal: true,
                selectedMotive: ex.motive_id,
                selectedExemption: ex.id,
                selectedFleetExemptionStart: ex.exception_from,
                selectedFleetExemptionEnd: ex.exception_to,
                selectedFleetId: op.fleet_operator,
                selectedOperatorId: op.operator_id,
                selectedFleetDescription: op.first_name.concat(' ', op.last_name),
                selectedFleetImage: op.profile_image,
                selectedFleetExemptions: op.resource_control,
              })
            }),
            header_actions: [{
              description: 'Programar salida de operacion',
              action: () => {
                this.setState({
                  showCreateExemptionModal: true,
                  // selectedMotive: motive.id,
                  selectedFleetId: op.fleet_operator,
                  selectedOperatorId: op.operator_id,
                  selectedFleetDescription: op.first_name.concat(' ', op.last_name),
                  selectedFleetImage: op.profile_image,
                  selectedFleetExemptions: op.resource_control,
                })
              }
            }]
          }
        });
      default:
        return [];
    }
  }

  loadResources = () => {
    // debugger
    switch(this.state.fleetTypeItem.id){
      case 1:
        this.props.listResourcesVehicles(
          this.props.companyId, 
          (this.props.companyFleets[0] || {id: 0}).id, 
          this.state.start_date,
          this.state.end_date,
          this.state.limit,
          this.state.offset,
          {
            search: this.state.textSearch,
            tags: this.state.selectedTags,
            status: this.state.selectedStatus,
            vehicle_class: this.state.selectedType
          },
          {args: {}}
        );
        break;
      case 2:
        this.props.listResourcesEquipments(
          this.props.companyId, 
          (this.props.companyFleets[0] || {id: 0}).id, 
          this.state.start_date,
          this.state.end_date,
          this.state.limit,
          this.state.offset,
          {
            search: this.state.textSearch,
            tags: this.state.selectedTags,
            status: this.state.selectedStatus,
            equipment_class: this.state.selectedType
          },
          {args: {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
          }}
        );
        break;
      case 3:
        this.props.listResourcesOperators(
          this.props.companyId, 
          (this.props.companyFleets[0] || {id: 0}).id, 
          this.state.start_date,
          this.state.end_date,
          this.state.limit,
          this.state.offset,
          {
            search: this.state.textSearch,
            tags: this.state.selectedTags,
            status: this.state.selectedStatus,
            operator_license_type : this.state.selectedType
          },
          {args: {}}
        );
        break;
      default:
        return
    }
  }

  getExemptionList = () => {
    switch(this.state.fleetTypeItem.id){
      case 1:
        return this.props.companyResourcesVehicleMotives;
      case 2:
        return this.props.companyResourcesEquipmentMotives;
      case 3:
        return this.props.companyResourcesOperatorMotives;
      default:
        return []
    }
  }

  //FILTERS
  doSearch = () => {
    this.setState({limit: 10, offset: 1});
    this.loadResources();
  }
  setSearchStr = (e) => {
    this.setState({ textSearch: e.target.value || "" });
    clearTimeout(typingTimer);
    typingTimer = setTimeout(this.loadResources, 1000);
  };

  getFilterContent= () => {
    let types = [];
    switch(this.state.fleetTypeItem.id){
      case 1: 
        types = this.props.vehicle_types;
        break;
      case 2:
        types = this.props.equipment_types;
        break;
      case 3:
        types = this.props.operator_types;
        break;
      default:
    }
    return [
      {
        title: "Etiquetas",
        items: (this.props.companyTags || []).filter((f) => f.type === 3),
        name: "selectedTags",
        listType: 1,
      },
      {
        title: "Estatus",
        items: [
          { id: 1, description: "Pendiente" },
          { id: 2, description: "Validado" },
          { id: 3, description: "Rechazado" },
          { id: 4, description: "Incompleto" },
          { id: 5, description: "Vencido" },
        ],
        name: "selectedStatus",
        listType: 3,
      },
      {
        title: "Tipo",
        items: types,
        name: "selectedType",
        listType: 5,
      }
    ]
  }

  manageFilter = (e) => {
    this.setState({[e.target.name]: e.target.value, buttonFilterName: "Aplicar" });
  }

  handleNewExemptionInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (e) => {
    const { value } = e.target;

    switch (e.target.name) {
      case "setOrder":
        this.setState({
          order: value,
        });
        break;
      case "gridConfigSelect":
        this.setState(
          {
            gridConfig: {
              columnQuantity: parseInt(value),
              columnRangeType: value.includes("H")
                ? "HOUR"
                : value.includes("D")
                ? "DAY"
                : value.includes("W")
                ? "WEEK"
                : "MONTH",
              columnSections: 4,
              startDatetime: value.includes("H")
                ? hour
                : value.includes("D")
                ? today
                : value.includes("W")
                ? week
                : month,
              className: "",
              extended: true,
            },
          },
          () => {
            this.handleChange({
              target: {
                name: "gridDateChange",
                // value: value.includes("H") ? parseInt(value) : 0,
                value: 0,
              },
            });
          }
        );
        break;
      case "gridDateChange":
        let c = { ...this.state.gridConfig };
        c.startDatetime = addValueToFilterParamsDateTime(
          c.startDatetime,
          e.target.value,
          c.columnRangeType
        );
        let start_date = c.startDatetime.toISOString();
        let end_date = addValueToFilterParamsDateTime(
          c.startDatetime,
          c.columnQuantity,
          c.columnRangeType
        ).toISOString();
        this.setState({ gridConfig: c });
        this.setState({ start_date, end_date }, () => this.loadResources());
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleCreateExemption = () => {
    let start = new Date(this.state.selectedFleetExemptionStart);
    let end = new Date(this.state.selectedFleetExemptionEnd);
    switch(this.state.fleetTypeItem.id){
      case 1: 
        this.props.createVehicleExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            }
          }
        )
        break;
      case 2:
        this.props.createEquipmentExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            }
          }
        )
        break;
      case 3:
        this.props.createOperatorExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedOperatorId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            }
          }
        )
        break;
      default: 
        return;
    }
  }

  handleEditExemption = () => {
    let start = new Date(this.state.selectedFleetExemptionStart);
    let end = new Date(this.state.selectedFleetExemptionEnd);
    switch(this.state.fleetTypeItem.id){
      case 1: 
        this.props.editVehicleExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      case 2:
        this.props.editEquipmentExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      case 3:
        this.props.editOperatorExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedOperatorId,
          start.toISOString(),
          end.toISOString(),
          this.state.selectedFleetId,
          this.state.selectedMotive,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      default: 
        return;
    }
  }

  handleDeleteExemption = () => {
    switch(this.state.fleetTypeItem.id){
      case 1: 
        this.props.deleteVehicleExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      case 2:
        this.props.deleteEquipmentExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedFleetId,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      case 3:
        this.props.deleteOperatorExemption(
          this.props.companyId,
          this.props.companyFleets[0].id,
          this.state.selectedOperatorId,
          this.state.selectedExemption,
          {
            onSuccess: () => {
              this.showCreateExemptionModal(false);
              this.loadResources();
            },
            strategy: 'IGNORE'
          }
        )
        break;
      default: 
        return;
    }
  }

  //PAGINATOR
  getTotalElements = () => {
    switch(this.state.fleetTypeItem.id){
      case 1:
        return this.props.companyResourcesVehiclesCount;
      case 2:
        return this.props.companyResourcesEquipmentsCount;
      case 3:
        return this.props.companyResourcesOperatorsCount;
      default:
        return 0;
    }
  };

  getDatesBetween = (start, end) => {

    var dates = [];
    const diffHours = Math.abs(moment(start).diff(end, 'hours'));
    const diffMinutes = Math.abs(moment(start).diff(end, 'minutes'));
    const minStart = start.minutes();
    const minEnd = end.minutes();

    if (diffHours === 0 && diffMinutes === 0) {
      return [];
    }

    if (diffHours === 0) {

      const newDate = start.clone();
      
      if (minStart === 30) {
        newDate.add(1, 'hours');
      } else {
        newDate.add(30, 'minutes');
      }

      dates = dates.concat(start.toDate());
      dates = dates.concat(newDate.toDate());
      dates = dates.concat(end.toDate());

    } else {

      dates = dates.concat(start.toDate());

      if (minStart === 0) {
        const startDate = start.clone();
        startDate.add(30, 'minutes');
        dates = dates.concat(startDate.toDate());  
      }

      for (var i = 1; i <= diffHours; i++) {

        const newDate = start.clone();
        newDate.add(i, 'hours');

        const newDate2 = newDate.clone();
        if (minStart === 30) {
          newDate2.add(-30, 'minutes');
        } else {
          newDate2.add(-30, 'minutes');
        }

        dates = dates.concat(newDate.toDate());
        dates = dates.concat(newDate2.toDate());

      }

      if (minEnd === 30) {
        const endDate = end.clone();
        endDate.add(-30, 'minutes');
        dates = dates.concat(endDate.toDate());  
      }

      dates = dates.concat(end.toDate());

    }

    return dates;

  }

  getExcludedTime = (filterDate) => {

    var timeToExclude = [];

    for (var i = 0; i < this.state.selectedFleetExemptions.length; i++) {

      const { exception_from, exception_to } = this.state.selectedFleetExemptions[i];

      const exceptionFrom = moment(exception_from);
      const exceptionTo = moment(exception_to);

      const results = this.getDatesBetween(exceptionFrom, exceptionTo);
      const startMoment = moment(setHours(setMinutes(new Date(), 0), 0));

      timeToExclude = [...timeToExclude, ...results];
      timeToExclude = [...this.getDatesBetween(startMoment, moment(Date.now())), ...timeToExclude];
    }

    timeToExclude = timeToExclude.filter( (item) => {

      const filterDateToEvaluate = moment( filterDate !== null ? filterDate : Date.now());
      const dateToEvaluate = moment(item);

      return filterDateToEvaluate.day() === dateToEvaluate.day() && filterDateToEvaluate.month() === dateToEvaluate.month();

    });

    timeToExclude = timeToExclude.sort((a, b) => {
      return a - b;
    });

    return timeToExclude;

  }

  getMaxDateEndDate = (excludedTimeArray) => {
    if (excludedTimeArray.length === 0) return null;
    return excludedTimeArray[0];
  }

  getAllDates = () => {

    if (this.state.selectedFleetExemptionStart === null) return [];

    const pivotDateMoment = moment(this.state.selectedFleetExemptionStart);
    var startDateMoment = null;

    const exemptions = this.state.selectedFleetExemptions.sort( (item1, item2) => {
      const item1Date = Date.parse(item1.exception_from);
      const item2Date = Date.parse(item2.exception_from);

      return item1Date - item2Date;
    });

    for (var i = 0; i < exemptions.length; i++) {

      const { exception_from } = exemptions[i];
      const exceptionFrom = moment(exception_from);

      if (exceptionFrom.day() === pivotDateMoment.day() && exceptionFrom.month() === pivotDateMoment.month() && pivotDateMoment.toDate() < exceptionFrom.toDate() && startDateMoment === null) {
        startDateMoment = exceptionFrom;
      }

    }

    if (startDateMoment !== null) {
      const endMoment = moment(setHours(setMinutes(startDateMoment.toDate(), 0), 24));
      const startMoment = moment(setHours(setMinutes(startDateMoment.toDate(), 0), 0));

      return [...this.getDatesBetween(startMoment, pivotDateMoment), ...this.getDatesBetween(startDateMoment, endMoment)];
    } 

    return [];

  }

  getDateString = (date) => {
    const datesDivided = date.split(' ');
    if (datesDivided.length > 0) {
      const dates = datesDivided[0].split('/');
      if (dates.length > 2) {
        return `${dates[2]}-${dates[1]}-${dates[0]}T05:00:00.000Z`;
      }
    }
    return '';
  }

  getMomentDate = (date) => {
    const datesDivided = date.split(' ');
    if (datesDivided.length > 0) {
      const dates = datesDivided[0].split('/');
      if (dates.length > 2) {
        return `${dates[1]}-${dates[0]}-${dates[2]}`;
      }
    }
    return '';
  }

  setCounterInfo = (value) => {
    const date_counter = this.getDateString(value);
    const fleet_id = (this.props.companyFleets[0] || {id: 0}).id;
    const company_id = this.props.companyId;
    const { textSearch, selectedTags, selectedStatus, selectedType, fleetTypeItem } = this.state;
    const fleetTypeItemId = fleetTypeItem?.id || 0

    if (this.isLoadingCounter === false) {
      this.isLoadingCounter = true
      this.counterValue = null;

      this.props.loadCounterData(
        {...this.state, date_counter, fleet_id, company_id, search: textSearch, tags: selectedTags, status: selectedStatus, vehicle_class: selectedType, type: fleetTypeItemId},
        { onSuccess: () => { this.isLoadingCounter = false; }}
      );
    }

    var tooltipTitle = '';

    if (`${fleetTypeItemId}` === '1') {
      tooltipTitle = 'Vehículos disponibles'
    } else if (`${fleetTypeItemId}` === '2') {
      tooltipTitle = 'Equipos disponibles'
    } else if (`${fleetTypeItemId}` === '3') {
      tooltipTitle = 'Operadores disponibles'
    }

    const dateMoment = moment(this.getMomentDate(value));
    const unavailaleObject = this.props.counterData?.unavailbe || {};

    return(
      (this.props.counterData && !this.props.isCounterDataLoading) ?
      <div style={{width: '100%', marginRight: 40}}>
        <p style={{fontWeight: 'bold', fontSize: 14}}>{tooltipTitle}</p>
        <p style={{marginTop: -11, fontSize: 13}}>{dateMoment.format('dddd DD MMMM YYYY')}</p>
        <div style={{display: 'inline-block', marginTop: -11}}>
          <p style={{fontWeight: 'bold', fontSize: 14, width: '100%', textAlign: 'left'}}>{this.props.counterData?.available || 0} disponibles</p>
          {
            (typeof unavailaleObject === 'object' && unavailaleObject !== null) && 
            Object.keys(unavailaleObject).map((keyName, i) => {
              if (keyName === 'total') return null;

              var motiveValue = ''

              if (`${fleetTypeItemId}` === '1') {
                for (var i = 0; i < this.props.companyResourcesVehicleMotives.length; i++) {
                  const motive = this.props.companyResourcesVehicleMotives[i];
                  if (`${motive.id}` === `${keyName}`) {
                    motiveValue = motive?.description || '';
                  }
                }
              } else if (`${fleetTypeItemId}` === '2') {

                for (var j = 0; j < this.props.companyResourcesEquipmentMotives.length; j++) {
                  const motive = this.props.companyResourcesEquipmentMotives[j];
                  if (`${motive.id}` === `${keyName}`) {
                    motiveValue = motive?.description || '';
                  }
                }

              } else if (`${fleetTypeItemId}` === '3') {

                for (var k = 0; k < this.props.companyResourcesOperatorMotives.length; k++) {
                  const motive = this.props.companyResourcesOperatorMotives[k];
                  if (`${motive.id}` === `${keyName}`) {
                    motiveValue = motive?.description || '';
                  }
                }

              }

              return (
                <p key={i} style={{marginTop: -11, fontSize: 13, width: '100%', textAlign: 'left'}}>  {`${unavailaleObject[keyName]} ${motiveValue}`}</p> 
              );
            })
          }
          <p style={{fontWeight: 'bold', marginTop: -11, fontSize: 14, width: '100%', textAlign: 'left'}}>Total {this.props.counterData.total}</p>
        </div>
      </div>
      :
      <div style={{width: '100%'}}>
        <p>Cargando...</p>
      </div>
    );
  }

  render () {
    const {
      gridConfig
    } = this.state;

    var excludedTimeArray = this.getExcludedTime(this.state.selectedFleetExemptionStart);

    return (
      <View>
        <ViewTitle>
          <span>
            Control de recursos
          </span>
          <FilterBarCompact
            filtered={false}
            searchAction={this.setSearchStr}
            searchStr={this.state.textSearch}
            content={this.getFilterContent()}
            filterAction={() => {
              if(this.state.buttonFilterName === "Aplicar"){
                this.setState({ buttonFilterName: "Restablecer" });
              } else {
                this.setState({
                  selectedType: [],
                  selectedTags: [],
                  selectedStatus: [],
                  buttonFilterName: "Aplicar"
                });
              }
            }}
            selectedItems={{
              selectedTags: this.state.selectedTags,
              selectedStatus: this.state.selectedStatus,
              selectedType: this.state.selectedType
            }}
            buttonFilterName={this.state.buttonFilterName}
            onChange={this.manageFilter}
            onSearch={this.doSearch}
          />
        </ViewTitle>
        <TabSelectorRibbon
          items={fleetTypes}
          onChange={this.switchFleetType}
          activeId={this.state.fleetTypeItem.id}
          className="control-ribbon"
        />
        <ResourcesGrid 
          dateChange={this.handleChange}
          gridConfigChange={this.handleChange}
          {...{ gridConfig }} 
          rows={this.getRows()}
          summary={this.setCounterInfo}
        />
        <PaginatorControlled
          itemCount={this.getTotalElements()}
          onChange={(e) =>{
            this.setState(
              { [e.target.name]: e.target.value },
              () => {this.loadResources()}
            )}
          }
          limit={this.state.limit}
          offset={this.state.offset}
        />
        <Modal 
          isOpen={this.state.showCreateExemptionModal}
          portalClassName="dialog emails-modal create-exemption-modal"
          overlayClassName="overlay"
          className="content"
        >
          <div className="title">{this.getExemptionCaption(parseInt(this.state.selectedMotive)) }</div>
          <div className="close" onClick={() => this.showCreateExemptionModal(false)}></div>
          <div className="message">
            <div className="id-area">
              <div className="avatar">
                <AssembleAvatar
                  description={this.state.selectedFleetDescription}
                  fleetTypeId={this.state.fleetTypeItem.id}
                  avatarUrl={this.state.selectedFleetImage}
                />
              </div>
              <div className="motive">
                <SelectInput
                  label='Selecciona el motivo'
                  className="full"
                  name="selectedMotive"
                  items={this.getExemptionList()}
                  value={this.state.selectedMotive}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy HH:mm"
                minDate={today}
                // maxDate={this.state.selectedFleetExemptionEnd || null}
                value={this.state.selectedFleetExemptionStart}
                dateAdjust={false}
                label="Fecha de inicio"
                name="selectedFleetExemptionStart"
                onChange={this.handleNewExemptionInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
                showTimeSelect={true}
                customHeader={false}
                timeValueFormat={"US_HOURLY_FORMAT"}
                //filterDate={this.filterBusyDates} 
                selectsStart={true}
                startDate={this.state.selectedFleetExemptionStart}
                endDate={this.state.selectedFleetExemptionEnd}
                excludedTime={excludedTimeArray}
              />
            </div>
            <div className="date">
              <TextInput
                type="date"
                format="dd/MMM/yyyy HH:mm"
                minDate={this.state.selectedFleetExemptionStart || today}
                dateAdjust={false}
                timeValidationEnable={true}
                // maxDate={this.getMaxDate()}
                // maxDate={this.getMaxDateEndDate(excludedTimeArray)}
                value={this.state.selectedFleetExemptionEnd}
                label="Fecha de fin"
                name="selectedFleetExemptionEnd"
                onChange={this.handleNewExemptionInput}
                suffix={{
                  element: <SVGIcon name={icons.calendar} fill="#3D77F7" />,
                }}
                showTimeSelect={true}
                timeValueFormat={"US_HOURLY_FORMAT"}
                //filterDate={this.filterBusyDates}
                selectsEnd={true}
                startDate={this.state.selectedFleetExemptionStart}
                endDate={this.state.selectedFleetExemptionEnd}
                disabled={!this.state.selectedFleetExemptionStart}
                excludedTime={this.getAllDates()}
              />
            </div>
          </div>
          <div className="actions">
            <Button text="Cancelar salida de operación" type="secondary" onClick={() => {if(this.state.selectedExemption)this.handleDeleteExemption(); else this.showCreateExemptionModal(false)}}/>
            <Button 
              text="Guardar" 
              disabled={this.state.selectedFleetExemptionEnd && this.state.selectedFleetExemptionStart && this.state.selectedMotive ? false : true} 
              type="primary" 
              onClick={this.state.selectedExemption ? this.handleEditExemption : this.handleCreateExemption}
            />
          </div>
        </Modal>
      </View>
    )
  }
}

const mapStateToProps = (state) => { 
  const isCounterDataLoading = state?.api['COMPANIES.FLEETS.RESOURCES.COUNTER']?.status?.isFetching || false;
  const counterData = state?.api['COMPANIES.FLEETS.RESOURCES.COUNTER']?.items || {total: 0, unavailbe: 0, available: 0};

  const companyResourcesOperatorMotives = getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.OPERATORS.MOTIVES").results || [];
  const companyResourcesVehicleMotives = getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.VEHICLES.MOTIVES").results || [];
  const companyResourcesEquipmentMotives = getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS.MOTIVES").results || [];

  let vehicleType = state.fleetReducer.vehicleType.data || [];
  let operatorType = state.fleetReducer.operatorType.data || [];
  let equipmentType = state.fleetReducer.equipmentType.data || [];

  let equipmentTypeCategories = equipmentType
    .map((type) => type.type)
    .filter((value, index, self) => self.indexOf(value) === index); //Obtener las categorías de tipos de equipos
  equipmentType = equipmentTypeCategories.map((category, index) => {
    return {
      id: index,
      description: category,
      items: equipmentType
        .filter((t) => t.type === category)
        .map((t) => ({ id: t.id, description: t.description, active: false })),
      active: false,
      contentType: "equipmentType",
      status: "full",
    };
  });

  return {
    isCounterDataLoading,
    counterData,
    companyId: state.globalCatalog.session.company.id,
    companyFleets: getEntityItems(state, "COMPANIES.FLEETS"),
    companyTags: getEntityItems(state, "COMPANIES.TAGS"),
    
    equipment_types: equipmentType,
    vehicle_types: vehicleType,
    operator_types: operatorType,

    companyResourcesOperators: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.OPERATORS").results || [],
    companyResourcesVehicles: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.VEHICLES").results || [],
    companyResourcesEquipments: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS").results || [],

    companyResourcesOperatorsCount: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.OPERATORS").count || 0,
    companyResourcesVehiclesCount: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.VEHICLES").count || 0,
    companyResourcesEquipmentsCount: getEntityItems(state, "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS").count || 0,

    companyResourcesOperatorMotives,
    companyResourcesVehicleMotives,
    companyResourcesEquipmentMotives,
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.VEHICLETYPES"));
  dispatch(readEntities("COMPANIES.CATALOGS.FLEETCLASSES"));
  dispatch(readEntities("COMPANIES.CATALOGS.OPERATORLICENSETYPES"));
  dispatch(loadVehicleTypes());
  dispatch(loadEquipmentTypes());
  dispatch(loadOperatorTypes());
  return {
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
    loadCounterData: (data, opt) => dispatch(readEntities("COMPANIES.FLEETS.RESOURCES.COUNTER", data, opt)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    listCompanyFleets: (company_id, opt) => {
      dispatch(readEntities("COMPANIES.FLEETS", { company_id }, opt));
    },
    listResourcesOperatorMotives: (
      company_id
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.OPERATORS.MOTIVES",
          {
            company_id
          }
        )
      )
    },
    listResourcesEquipmentMotives: (
      company_id
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS.MOTIVES",
          {
            company_id
          }
        )
      )
    },
    listResourcesVehicleMotives: (
      company_id
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.VEHICLES.MOTIVES",
          {
            company_id
          }
        )
      )
    },
    listResourcesOperators: (
      company_id,
      fleet_id,
      start_date,
      end_date,
      limit,
      offset,
      params = {},
      opt
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.OPERATORS",
          {
            company_id,
            fleet_id,
            start_date,
            end_date,
            limit,
            offset,
            ...params,
          },
          opt
        )
      )
    },
    listResourcesVehicles: (
      company_id,
      fleet_id,
      start_date,
      end_date,
      limit,
      offset,
      params = {},
      opt
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.VEHICLES",
          {
            company_id,
            fleet_id,
            start_date,
            end_date,
            limit,
            offset,
            ...params,
          },
          opt
        )
      )
    },
    listResourcesEquipments: (
      company_id,
      fleet_id,
      start_date,
      end_date,
      limit,
      offset,
      params = {},
      opt
    ) => {
      dispatch(
        readEntities(
          "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS",
          {
            company_id,
            fleet_id,
            start_date,
            end_date,
            limit,
            offset,
            ...params,
          },
          opt
        )
      )
    },
    createOperatorExemption: (
      company_id,
      fleet_id,
      operator_id,
      exception_from,
      exception_to,
      fleet_operator,
      motive,
      opt
    ) => {
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.RESOURCES.OPERATORS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            operator_id,
            exception_from,
            exception_to,
            fleet_operator,
            motive,
          },
          opt
        )
      )
    },
    editOperatorExemption: (
      company_id,
      fleet_id,
      operator_id,
      exception_from,
      exception_to,
      fleet_operator,
      motive,
      exemption_id,
      opt
    ) => {
      dispatch(
        partialEntity(
          "COMPANIES.FLEETS.RESOURCES.OPERATORS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            operator_id,
            exemption_id,
            exception_from,
            exception_to,
            fleet_operator,
            motive,
          },
          opt
        )
      )
    },
    deleteOperatorExemption: (
      company_id,
      fleet_id,
      operator_id,
      exemption_id,
      opt
    ) => {
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.RESOURCES.OPERATORS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            operator_id,
            exemption_id,
          },
          opt
        )
      )
    },
    createVehicleExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exception_from,
      exception_to,
      fleet_vehicle,
      motive,
      opt
    ) => {
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.RESOURCES.VEHICLES.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exception_from,
            exception_to,
            fleet_vehicle,
            motive,
          },
          opt
        )
      )
    },
    editVehicleExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exception_from,
      exception_to,
      fleet_vehicle,
      motive,
      exemption_id,
      opt
    ) => {
      dispatch(
        partialEntity(
          "COMPANIES.FLEETS.RESOURCES.VEHICLES.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exemption_id,
            exception_from,
            exception_to,
            fleet_vehicle,
            motive,
          },
          opt
        )
      )
    },
    deleteVehicleExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exemption_id,
      opt
    ) => {
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.RESOURCES.VEHICLES.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exemption_id,
          },
          opt
        )
      )
    },
    createEquipmentExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exception_from,
      exception_to,
      fleet_equipment,
      motive,
      opt
    ) => {
      dispatch(
        createEntity(
          "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exception_from,
            exception_to,
            fleet_equipment,
            motive,
          },
          opt
        )
      )
    },
    editEquipmentExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exception_from,
      exception_to,
      fleet_equipment,
      motive,
      exemption_id,
      opt
    ) => {
      dispatch(
        partialEntity(
          "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exemption_id,
            exception_from,
            exception_to,
            fleet_equipment,
            motive,
          },
          opt
        )
      )
    },
    deleteEquipmentExemption: (
      company_id,
      fleet_id,
      fleet_data_id,
      exemption_id,
      opt
    ) => {
      dispatch(
        deleteEntity(
          "COMPANIES.FLEETS.RESOURCES.EQUIPMENTS.EXEMPTIONS",
          {
            company_id,
            fleet_id,
            fleet_data_id,
            exemption_id,
          },
          opt
        )
      )
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesView);