import React from "react";
import MenuCategoryList from "./menu-category-list";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default class MenuCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubcategory: null,
    };
  }
  setActiveSubcategory = (id) => {
    this.setState({ activeSubcategory: id });
  };

  // componentDidUpdate(nextProps) {
  //   if (
  //     this.state.activeSubcategory !== null &&
  //     this.props.isMenuOpen === true &&
  //     nextProps.isMenuOpen === false
  //   ) {
  //     this.setState({
  //       activeSubcategory: null
  //     });
  //   }
  // }
  render() {
    return (
      <React.Fragment>
        {this.props.menuobj.map((item, key) => {
          return item.submenu.length > 0 ? (
            <MenuCategoryList
              item={item}
              key={key}
              subcategoryId={key}
              openMenu={this.props.openMenu}
              isMenuOpen={this.props.isMenuOpen}
              setActiveSubcategory={this.setActiveSubcategory}
              activeSubcategoryId={this.state.activeSubcategory}
            />
          ) : (
            <div
              className="item closed"
              key={key}
              onClick={this.props.openMenu}
            >
              {item.isIcon ? (
                <React.Fragment>
                  <div className="avatar-container">
                    <img
                      className={item.icon.class || ""}
                      src={item.icon.url}
                      alt=""
                      width={item.icon.width}
                    />
                  </div>
                  <Link to={item.link || "#"}>
                    <span>{item.title}</span>
                  </Link>
                  <div
                    className="actions"
                    data-tip
                    data-for={"tooltip-facility-selection"}
                  >
                    <div className="ellipsis">
                      <b></b>
                    </div>
                  </div>
                  <ReactTooltip
                    id={"tooltip-facility-selection"}
                    clickable={true}
                    event="click"
                    globalEventOff="click"
                    delayHide={200}
                    delayShow={200}
                    delayUpdate={200}
                    place={"right"}
                    border={true}
                    type={"light"}
                    className="facility-tooltip"
                  >
                    <Link to={"/facility/select"}>Cambiar de instalación</Link>
                  </ReactTooltip>
                </React.Fragment>
              ) : (
                <Link to={item.link || "#"}>
                  <img
                    className={item.icon.class || ""}
                    src={item.icon.url}
                    alt=""
                    width={item.icon.width}
                  />
                  <span>{item.title}</span>
                </Link>
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
  static getDerivedStateFromProps(nextProps) {
    if (!nextProps.isMenuOpen) {
      return {
        activeSubcategory: null,
      };
    }
    return null;
  }
}
