import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Button from '../controls/button';
import ModalSelectInput from '../../control/shipment/modal-select-input';
import { partialEntity } from '../../redux/api';

class ChangeUserTypeModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      successAction: false,
      typeSelected: null,
      showError: false
    }

    this.isLoaded = false;

  }

  componentDidUpdate(nextProps) {

    if (!nextProps.open && this.isLoaded) {
      this.setState({
        successAction: false,
        typeSelected: null,
        showError: false
      });
    }

  }

  getSuccesContent = () => {
    return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{height: 20}}/>
      { 'Se cambió correctamente el tipo de usuario' }
      <div style={{height: 40}}/>
      <Button
        text={'Aceptar'}
        type={'primary'}
        onClick={() => {
          this.isLoaded = true;
          this.props.onClose();
        }}
      />
      <div style={{height: 20}}/>
    </div>
  }

  getContent = () => {

    let companyTypeId = this.props.companyTypeId || 0;

    return(
      <>
        <div className='content-select'>
          <ModalSelectInput
            placeholder={'Seleccione un tipo de usuario'}
            isSearchable={false}
            name="typeSelected"
            options={
              companyTypeId === 1 ? [
                { value: 1, label: 'Administrador' },
                { value: 3, label: 'Owner' }
              ] : [
                { value: 1, label: 'Administrador' },
                { value: 2, label: 'Warehouser' },
                { value: 3, label: 'Owner' }
              ]
            }
            onChange={ (value) => {
              this.setState({typeSelected: value});
            }}
          />
          {
            this.state.showError && <div className='error'>{'* Ocurrió un error, intente mas tarde'}</div>
          }
        </div>
        <div className='content-buttons'>

          <Button
            text={'Cancelar'}
            type={'primary outline dark'}
            onClick={() => { this.isLoaded = true; this.props.onClose() }}
          />

          <Button
            disabled={this.state.typeSelected === null || this.props.loading}
            text={ this.props.loading ? 'Cargando...' : 'Guardar'}
            type={'primary'}
            onClick={() => {

              const { companyId, userData } = this.props;
              var typeToChange = '';

              if (`${this.state.typeSelected.value}` === '1') {
                typeToChange = 'turn_into_admin';
              } else if (`${this.state.typeSelected.value}` === '2') {
                typeToChange = 'turn_into_warehourser';
              } else if (`${this.state.typeSelected.value}` === '3') {
                typeToChange = 'turn_into_owner';
              }

              this.props.changeUserType({company_id: userData.companies, id: userData.id, action: typeToChange}, {
                onSuccess: () => { 
                  this.props.success();
                  this.setState({
                    successAction: true,
                    showError: false
                  });
                },
                onError: () => {
                  this.setState({
                    showError: true
                  });
                }
              });

            }}
          />
        </div>
      </>
    );
  }

  render() {

    return(
      <Modal
        isOpen={this.props.open}
        portalClassName="dialog change-user-type-modal"
        overlayClassName="overlay"
        className="content"
        shouldCloseOnOverlayClick={true}
      >
        <div className="title">{'Cambiar tipo de usuario'}</div>
        <div className="close" onClick={() => { this.isLoaded = true;   this.props.onClose()} }></div>

        {
          this.state.successAction ? this.getSuccesContent() : this.getContent()
        }

      </Modal>
    );
  }
}

const mapStateToProps = (state) => {

  const loading = state.api["COMPANIES.USERTYPE"]?.status?.isFetching || false;

  return {
    loading,
    companyId: state.globalCatalog?.session?.company?.id || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserType: (params, opts) => dispatch(partialEntity("COMPANIES.USERTYPE", params, opts)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeUserTypeModal);