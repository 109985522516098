//@flow
import * as React from "react";
import { createRef }  from "react";
import styled from "styled-components";
import ReactTooltip from 'react-tooltip';
import TextInput from "../controls/text-input";
import { normalizeStr } from "../utils";
import Checkbox from '../controls/checkbox';
import { cloneDeep } from 'lodash';

export type TableDConfig = {
  rowStyle?: any,
  headerStyle?: any,
  contentStyle?: any,
  columns: {
    text: string,
    width: string,
    headerItemStyle?: any,
    cellStyle?: any,
  }[],
};

type Props = {
  tableConfig: TableDConfig,
  children?: any,
  onScroll?: any
};

let scrollItem = React.createRef();
const tooltipRef = createRef();

class TableD extends React.Component<Props> {
  static defaultProps = {
    tableConfig: {
      headerStyle: {},
      rowStyle: {},
      contentStyle: {},
      columns: [],
    },
  };

  state ={
    selectedColumnIndex: null,
    menuHovered: null,
    menuSelected: null,
    topPosition: 0,
    rightPosition: 0,
    itemsToShow: [],
    search: ''
  };

  constructor(props) {
    super(props);

    this.refInputs = Array((props.tableConfig.columns || []).length).fill().map(i=> React.createRef());   
    this.refTitles = Array((props.tableConfig.columns || []).length).fill().map(i=> React.createRef());   
    this.tooltipActivations = Array((props.tableConfig.columns || []).length).fill().map(i => false);   
  }
  
  headerBeingResized = null;

  componentDidUpdate(prevProps) {
    if (this.props.toScroll && prevProps.toScroll !== this.props.toScroll) {
      scrollItem.current.scrollTo(0, this.props.toScroll); 
    }

    if (this.props.tableConfig.columns !== prevProps.tableConfig.columns) {
      this.refInputs = Array((this.props.tableConfig.columns || []).length).fill().map(i=> React.createRef());  
      this.refTitles = Array((this.props.tableConfig.columns || []).length).fill().map(i=> React.createRef());  

      if (this.tooltipActivations && this.tooltipActivations.length && this.tooltipActivations.length !== this.props.tableConfig.columns.length) {
        this.tooltipActivations = Array((this.props.tableConfig.columns || []).length).fill().map(i => false);   
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
  }

  handlePageContentScroll = (e) => {
    if (this.state.menuSelected !== null && e.detail.target.clientHeight > 250) {
      this.setState({
        menuSelected: null,
        rightPosition: 0,
        topPosition: 0,
        search: ''
      });
    }
  }

  handleClickOutside = (event) => {
    if (tooltipRef && tooltipRef.current && !tooltipRef.current.contains(event.target) && !this.refInputs.some(rI => rI.current && rI.current.contains(event.target))) {
      setTimeout(function() {
        this.setState({
          menuSelected: null,
          rightPosition: 0,
          topPosition: 0,
          search: ''
        });
      }.bind(this), 50);
    }
  }

  initResize = (e, index) => {
    let target = e.target;
    console.log('initResize');
  
    this.headerBeingResized = target.parentNode;
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
    this.headerBeingResized.classList.add('header--being-resized');
    this.setState({selectedColumnIndex: index})
  };

  onMouseUp = () => {
    console.log('onMouseUp');
  
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
    this.headerBeingResized.classList.remove('header--being-resized');
    this.headerBeingResized = null;
    this.setState({selectedColumnIndex: null})
  };

  onMouseMove = e => requestAnimationFrame(() => {
    if(!this.headerBeingResized) return;
    // console.log('onMouseMove');

    for (let i = 0; i < this.refTitles.length; i++) {
      let refTemp = this.refTitles[i];
      let clientWidth = refTemp?.current?.clientWidth ?? 0;
      let scrollWidth = refTemp?.current?.scrollWidth ?? 0;

      if (clientWidth !== 0 && scrollWidth !== 0) {
        this.tooltipActivations[i] = clientWidth !== scrollWidth;
      }
    }
  
    // Calculate the desired width
    let horizontalScrollOffset = document.documentElement.scrollLeft;
    // let horizontalScrollOffset = this.props.horizontalScrollRef.current.scrollLeft;
    
    let headerOffset = (this.headerBeingResized?.offsetLeft || 0) - (this.props.horizontalScrollRef.current.scrollLeft);
    const width = horizontalScrollOffset + e.clientX - headerOffset + 'px';

    // console.log({
    //   horizontalScrollOffset, 
    //   clientX: e.clientX, 
    //   headerOffset: this.headerBeingResized?.offsetLeft || 0, 
    //   insideScroll: (this.props.horizontalScrollRef?.current?.scrollLeft || 0),
    //   width,
    //   name: this.state.selectedColumnIndex
    // });

    this.props.onColumnWidthUpdate({target: {name: this.state.selectedColumnIndex, value: width}})
  });

  onClickMenu = (index) => {
    const currentScroll = this.props.horizontalScrollRef;
    
    if (this.refInputs[index].current !== undefined && this.refInputs[index].current !== null) {
      const { offsetTop, offsetLeft, offsetWidth } = this.refInputs[index].current;
      const { innerWidth: width } = window;
      const { top } = this.refInputs[index].current.getBoundingClientRect();
      
      let rightPosition = offsetLeft + offsetWidth - 20 - (currentScroll?.current?.scrollLeft || 0);

      if ((rightPosition + 280) > width) {
        rightPosition = width - 300
      } else {
        rightPosition = rightPosition - 50
      }

      let menuIndex = this.state.menuSelected === index ? null : index 

      if (menuIndex === null) {
        this.state.itemsToShow = [];
      } else {
        this.state.itemsToShow = this.getValuesFromIdConfig(this.props.tableConfig.columns[menuIndex].idConfig);
      }

      this.setState({
        topPosition: top + 30,
        rightPosition,
        menuSelected: menuIndex
      });
    }
  }

  getEquipmentStatus = (status) => {

    let statusToShow = [];

    if (status) {
      Object.entries(status).map( ([key, value]) => {

        if (value && value.length) {
          let statusId = value[value.length - 1].container_status_id;

          if (!statusToShow.includes(statusId)) {
            statusToShow.push(statusId);
          }
        }

      });

    }

    return statusToShow;

  }

  getContainerStatusDescription = (statusId) => {
    let status = this.props.containerStatusCatalog.find(s => s.id === statusId);
    if(!status) return '-';
    return status.event_description;
  }

  getValuesFromIdConfig = (idConfig) => {
    let values = [];

    this.props.orders.map( order => {

      if (idConfig === 4) {
        if (this.props.companyType === 1 ) {
          values.push(order.from_company?.orbi_id);
        } else {
          values.push(order.secondary_company?.secondary_alias ?? '');
        }
      } else if (idConfig === 25) {

        let operatorValues = order.assembly?.operators ?? null;

        if (operatorValues !== null) {
          let operator = Object.values(operatorValues)
          .flat().map(v => `${v.first_name || ''} ${v.last_name || ''}`);

          values.push(operator.join(' | '));
        }

      } else if (idConfig === 8) {

        let vehicleValues = order.assembly?.vehicles ?? null;

        if (vehicleValues !== null) {
          let value = Object.values(vehicleValues).flat().map(v => v.economic_number);
          values.push(value.join(' | '));
        }

      } else if (idConfig === 9) {

        let equipmentValues = order.assembly?.equipments ?? null;

        if (equipmentValues !== null) {
          let value = Object.values(equipmentValues).flat().map(v => v.economic_number);
          values.push(value.join(' | '));
        }

      } else if (idConfig === 13) { 

        let value = order.status?.event_description ?? '';
        values.push(value);

      } else if (idConfig === 10) {

        let value = '';

        let objects = this.getEquipmentStatus(order.containers_status ?? {});

        if (objects.length > 0) {
          value = this.getContainerStatusDescription(objects[0]);
          values.push(value);
        }

      } else if (idConfig === 12) {

        let value = order.transport_class?.code ?? ''
        values.push(value);

      } else if (idConfig === 14) {
        
        let value = order.priority ?? '';
        values.push(value);

      } else if (idConfig === 7) {

        let value = '';

        if ((order.order_tags ?? []).length > 0) {
          let companyTags = this.props.companyTags.find((t) => t.id === order.order_tags[0].tag);

          if (companyTags) {
            value = companyTags.title;
          }

        }

        values.push(value);

      }

    });

    return values;
  }

  getTextSearch = () => {
    return (
      <div className="search-list">
        <TextInput
          value={this.state.search}
          label="Buscar"
          name="searchText"
          onChange={(input) => {
            let valueToUpdate = input?.target?.value ?? '';

            this.setState({
              search: valueToUpdate
            });
          }}
        />
      </div>
    );
  }

  getValues = () => {
    let idConfig = this.state.menuSelected === null ? -1 : this.props.tableConfig.columns[this.state.menuSelected].idConfig;
    let values = [];

    for (let i = 0; i < this.props.orderFilter.length; i++) {
      let tempFilter = this.props.orderFilter[i];

      if (tempFilter.idConfig === idConfig) {
        values = tempFilter.words;
      }
    }

    return values;
  }

  buildOrderFilters = (order, text, id) => {
    let idConfig = this.state.menuSelected === null ? -1 : this.props.tableConfig.columns[this.state.menuSelected].idConfig;
    let indexFound = -1;

    for (let i = 0; i < this.props.orderFilter.length; i++) {
      let tempFilter = this.props.orderFilter[i];

      if (tempFilter.idConfig === idConfig) {
        indexFound = i;
      }
    }

    let tempFilterOrders = cloneDeep(this.props.orderFilter);

    if (indexFound === -1) {

      if (order === null && text === null) {
        return tempFilterOrders;
      }

      tempFilterOrders.push({
        order,
        idConfig,
        words: text === null ? [] : [text],
        ids: [id]
      });

    } else {

      if (order === null && text === null) {
        tempFilterOrders[indexFound].words = [];
        tempFilterOrders[indexFound].ids = [];
        tempFilterOrders[indexFound].order = 0;

        return tempFilterOrders;
      }

      if (text !== null) {

        let tempWords = tempFilterOrders[indexFound].words ?? [];
        let tempIds = tempFilterOrders[indexFound].ids ?? [];
        if (tempWords.includes(text)) {
          tempWords = tempWords.filter( item => item != text)
          tempIds = tempIds.filter( item => item != id)
        } else {

          if (idConfig === 21 || idConfig === 17) {
            tempWords = []
            tempIds = []
          }

          tempWords.push(text);
          tempIds.push(id);
        }

        tempFilterOrders[indexFound].words = tempWords;
        tempFilterOrders[indexFound].ids = tempIds;

      } else {
        tempFilterOrders[indexFound].order = order;
      }

    }


    if (idConfig === 17) {

      let indexFoundTemp = -1;

      for (let i = 0; i < tempFilterOrders.length; i++) {
        let tempFilter = tempFilterOrders[i];

        if (tempFilter.idConfig === 21) {
          indexFoundTemp = i;
        }
      }

      if (indexFoundTemp !== -1) {
        tempFilterOrders[indexFoundTemp].words = [];
        tempFilterOrders[indexFoundTemp].ids = [];
      }

    }

    if (idConfig === 21) {

      let indexFoundTemp = -1;

      for (let i = 0; i < tempFilterOrders.length; i++) {
        let tempFilter = tempFilterOrders[i];

        if (tempFilter.idConfig === 17) {
          indexFoundTemp = i;
        }
      }

      if (indexFoundTemp !== -1) {
        tempFilterOrders[indexFoundTemp].words = [];
        tempFilterOrders[indexFoundTemp].ids = [];
      }

    }

    return tempFilterOrders;
  }

  addNewWord = (text, id) => {
    if (this.props.setOrder) {
      let newOrder = this.buildOrderFilters(0, text, id);

      this.setState({
        menuSelected: null,
        rightPosition: 0,
        topPosition: 0,
        search: ''
      }, () => {
        this.props.setOrder(newOrder);
      });

    }
  }

  getCheckbox = (data, filterItems) => {

    return <div/>;

    let menuIndex = this.state.menuSelected;
    let idConfig = menuIndex === null ? -1 : this.props.tableConfig.columns[menuIndex]?.idConfig ?? -1;
    let filters = this.props.orderFilter.filter((item) => item.idConfig === idConfig);
    let order = null;

    if (filters.length > 0) {
      order = filters[0].order;
    }

    if (idConfig === 21 || idConfig === 17) {
      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div style={{cursor: 'pointer'}} className='checkbox' onClick={() => {
            if (this.props.setOrder) {
              let newOrder = this.buildOrderFilters(order === 1 ? 0 : 1, null);
              this.props.setOrder(newOrder);
            }
          }}>
            <img alt="" src={order === 1 ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Mayor a menor
          </div>
          <div style={{cursor: 'pointer'}} className='checkbox' onClick={() => {
            if (this.props.setOrder) {
              let newOrder = this.buildOrderFilters(order === 2 ? 0 : 2, null);
              this.props.setOrder(newOrder);
            }
          }}>
            <img alt="" src={order === 2 ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Menor a mayor
          </div>
          <div style={{cursor: 'pointer'}} className='checkbox' onClick={() => {
            if (this.props.setOrder) {
              let newOrder = this.buildOrderFilters(order === 3 ? 0 : 3, null);
              this.props.setOrder(newOrder);
            }
          }}>
            <img alt="" src={order === 3 ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Sin fecha
          </div>
        </div>
      );

    } else {

      return (
        <div className='checkbox-content' style={{marginTop: filterItems.length === 0 ? 10 : 0}}>
          <div style={{cursor: 'pointer'}} className='checkbox' onClick={() => {
            if (this.props.setOrder) {
              let newOrder = this.buildOrderFilters(order === 1 ? 0 : 1, null);
              this.props.setOrder(newOrder);
            }
          }}>
            <img alt="" src={order === 1 ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Ordenar A - Z
          </div>
          <div style={{cursor: 'pointer'}} className='checkbox' onClick={() => {
            if (this.props.setOrder) {
              let newOrder = this.buildOrderFilters(order === 2 ? 0 : 2, null);
              this.props.setOrder(newOrder);
            }
          }}>
            <img alt="" src={order === 2 ? '/images/radio_on.png' : '/images/radio_off.png'} style={{ width: 20, height: 20, marginRight: 10 }}/>
            Ordenar Z - A
          </div>
        </div>
      );

    }

  }

  handleFilterListSearch = (items, field, value = '') => {
    return value.length > 0
      ? items
          .map((i) =>
            (i.items || []).length &&
            !normalizeStr(i[field] || '').includes(normalizeStr(value))
              ? {
                  ...i,
                  items: this.handleFilterListSearch(
                    i.items,
                    'description',
                    this.state.search
                  ),
                }
              : i
          )
          .filter(
            (i) =>
              normalizeStr(i[field] || '').includes(normalizeStr(value)) ||
              (i.items || []).length
          )
      : items;
  };

  transformItemsToFilter = () => {
    let filterToReturn = [];

    for (let i = 0; i < this.state.itemsToShow.length; i++) {

      let valueToSet = this.state.itemsToShow[i];

      if (valueToSet !== '' && valueToSet !== null && !filterToReturn.find((item) => { return valueToSet === item.value })) {
        filterToReturn = filterToReturn.concat(
          {
            id: valueToSet,
            description: valueToSet,
            value: valueToSet,
          }
        );
      }

    }

    return filterToReturn;
  }

  checkList = () => {

    let items = [];
    let configId = this.props.tableConfig.columns[this.state.menuSelected].idConfig;

    if (`${configId}` === '4' &&  this.props.filters['4']) {
      let lts = this.props.filters['4'];
      for (var i = 0; i < lts.length; i++) {
        items.push(
          {
            id: lts[i]['id'],
            description: lts[i]['description'],
            value: lts[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '14' &&  this.props.filters['14']) { 
      let item = this.props.filters['14'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['priority'],
            description: item[i]['priority'],
            value: item[i]['priority'],
          }
        );
      }
    } else if (`${configId}` === '13' &&  this.props.filters['13']) { 
      let item = this.props.filters['13'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: item[i]['description'],
            value: item[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '7' &&  this.props.filters['7']) { 
      let item = this.props.filters['7'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: item[i]['description'],
            value: item[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '10' &&  this.props.filters['10']) { 
      let item = this.props.filters['10'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: this.getContainerStatusDescription(item[i]['id']),
            value: item[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '12' &&  this.props.filters['12']) { 
      let item = this.props.filters['12'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: item[i]['description'],
            value: item[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '21' &&  this.props.filters['21']) { 
      let item = this.props.filters['21'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: item[i]['description'],
            value: item[i]['id'],
          }
        );
      }
    } else if (`${configId}` === '17' &&  this.props.filters['17']) { 
      let item = this.props.filters['17'];
      for (var i = 0; i < item.length; i++) {
        items.push(
          {
            id: item[i]['id'],
            description: item[i]['description'],
            value: item[i]['id'],
          }
        );
      }
    }

    let name = 'test';
    let counter = -9999;

    return (
      <div className='items checks'>
        {
          this.handleFilterListSearch(
            items || [],
            'description',
            this.state.search
          ).map((i) => {
            let l = [];
            l.push(
              <Checkbox
                key={'item-'.concat(i.description)}
                onChange={(e) => this.addNewWord(i.value, i.id)}
                item={{
                  id: i.id,
                  description: i.description,
                  active: (this.getValues() || []).find(
                    (si) => (si ?? '').toString() === (i?.id ?? '').toString()
                  )
                    ? true
                    : false,
                }}
                name={name}
                className={(i.items || []).length ? 'bold' : ''}
              />
            );
            if ((i.items || []).length) {
              i.items.forEach((ci) =>
                l.push(
                  <Checkbox
                    key={'subitem-'.concat(ci.description)}
                    className={'sub-item'}
                    onChange={(e) => this.addNewWord(i.value, i.id)}
                    item={{
                      id: ci.id,
                      description: ci.description,
                      active: (this.getValues() || []).find(
                        (si) => si.toString() === ci.id.toString()
                      )
                        ? true
                        : false,
                    }}
                    name={name}
                  />
                )
              );
              l.push(<div className='separator' key={counter++} />);
              let full = true;
              i.items.forEach((fe) =>
                (this.getValues() || []).find(
                  (si) => si.toString() === fe.id.toString()
                ) && full
                  ? (full = true)
                  : (full = false)
              );
              l[0].props.item.active = full;
            }
            return l;
          })
        }
      </div>
    );
  };

  clearFilter = () => {
    if (this.props.setOrder) {
      let newOrder = this.buildOrderFilters(null, null, null)

      this.setState({
        menuSelected: null,
        rightPosition: 0,
        topPosition: 0,
        search: ''
      }, () => {
        this.props.setOrder(newOrder);
      });
    }
  }

  isHighlighted = (idConfig) => {
    let isHighlighted = false;

    let order = (this.props.orderFilter || []).filter( item => item.idConfig === idConfig);

    if (order.length > 0 && order[0].ids && order[0].ids.length > 0) {
      isHighlighted = true;
    }

    return isHighlighted;
  }

  render() {
    let el = React.Children.toArray(this.props.children).map((e) =>
      React.cloneElement(e, {
        tableConfig: this.props.tableConfig,
      })
    );
    return (
      <>
      {
        (this.state.menuSelected !== null && this.state.topPosition !== 0 && this.state.rightPosition !== 0) ?
        <div ref={tooltipRef} className='orders-column-filter' onClick={(e) => e.stopPropagation()} style={{top: this.state.topPosition, left: this.state.rightPosition}}>
          <div className="deselector">
            <span onClick={() => {
              this.clearFilter();
            }}>
              Limpiar filtro
            </span>
          </div>
          {this.state.itemsToShow.length > 0 && this.getTextSearch()}
          {this.getCheckbox({}, this.state.itemsToShow)}
          {/* {
            this.state.itemsToShow.length > 0 &&
            <div className='separator' style={{marginTop: 15, marginBottom: 15}}/>
          } */}
          {this.checkList()} 
        </div> : ''
      }
      <TableDStyled style={{ ...this.props.tableConfig.tableStyle }}>
        <Header style={{...this.props.tableConfig.headerStyle}}>
          {this.props.tableConfig.columns.map((col, index) => (
            <>
              <ReactTooltip
                id={`column-name-${index}`}
                disable={ this.props.onColumnWidthUpdate ? !this.tooltipActivations[index] : true}
              >
                <div>
                  {col.text}
                </div>
              </ReactTooltip>
              <HeaderItem
                ref={this.refInputs[index]} 
                className="header-item"
                style={{ alignItems: 'center', display: 'flex', width: col.width, ...col.headerItemStyle, backgroundColor: this.isHighlighted(col.idConfig || -1) ? 'rgba(164, 180, 206, 0.4)' : null}}
                key={index}
              >
                <div
                  ref={this.refTitles[index]} 
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: col.menuType === 1 ? 'pointer' : 'auto'
                  }}
                  data-place="left"
                  data-tip
                  data-for={`column-name-${index}`}
                  onMouseOver={() => {
                    if (col.menuType === 1) this.setState({ menuHovered: index });
                  }}
                  onMouseOut={() => {
                    if (col.menuType === 1) this.setState({ menuHovered: null });
                  }}
                  onClick={() => {
                    if (col.menuType === 1) this.onClickMenu(index);
                  }}
                >
                  {col.text} 
                </div>
                {
                  col.menuType === 1 &&
                  <img
                    style={this.state.menuSelected === index ? {transform: `rotate(180deg)`, marginLeft: 10, height: this.state.menuHovered === index ? 16 : 10, cursor: 'pointer' } : { marginLeft: 10, height: this.state.menuHovered === index ? 16 : 10, cursor: 'pointer'}}
                    alt=""
                    src="/images/caret.svg" 
                    onMouseOver={() => this.setState({ menuHovered: index }) }
                    onMouseOut={() => this.setState({ menuHovered: null }) }
                    onClick={() => this.onClickMenu(index)}
                  />
                }
                {
                  this.props.onColumnWidthUpdate
                  ? <ResizeHandle 
                      className="resize-handle"
                      onMouseDown={(e) => {this.initResize(e, index)}}
                      // onMouseUp={this.onMouseUp}
                    >&#x25CF;{' '}&#x25CF;{' '}&#x25CF;</ResizeHandle>
                  : ''
                }
              </HeaderItem>
            </>
          ))}
        </Header>
        <Content ref={scrollItem} style={{ ...this.props.tableConfig.contentStyle }} onScroll={this.props.onScroll}>
          {el}
        </Content>
      </TableDStyled>
      </>
    );
  }
}

export default TableD;

const TableDStyled: React.ComponentType<{}> = styled.div``;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

const HeaderItem = styled.div`
  margin: auto;
  height: auto;
  position: relative;
  &:hover,
  &.header--being-resized {
    & .resize-handle {
      opacity: 0.6;
    }
  }

  &.header--being-resized {
    background-color: #5a75ad;
  }
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: 40%;
  right: 0;
  // bottom: 0;
  // background: black;
  opacity: 0;
  // width: 4px;
  cursor: col-resize; 

  transform: rotate(90deg);
  font-size: 10px;
  line-height: 10px;
  
  &:hover {
    opacity: 0.6;
  }
`;
