//@flow
import * as React from "react";
import RowD from "../../shared/component/row-d";

import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import RadioInput from "../../shared/controls/radio-input";
import ExceedPlus from "../../shared/controls/exceed-plus";
import Tag from "../../shared/controls/tag";
import moment from 'moment';
import TextExceedPlus from "../../shared/controls/text-exceed-plus";

type Props = {
  selectedAssembly: number,
  id: number,
  onChange: () => void,
  tags: any[],
  vehicles: any[],
  equipments: any[],
  operators: any[],
  availabilityDate: Date,
  alert: number,
  contracts: any[],
  controlActions: any,
};
const AssembliesAvailabilityRow = (props: Props) => {

  const { operators = [] } = props;
  var locked_from = '';

  var blocked = 0;

  if (operators.length > 0 && operators[0].sanctions !== null && operators[0].sanctions !== undefined) {
    locked_from = operators[0].sanctions.locked_from;
    blocked = operators[0].sanctions.locked === true ? 2 : 1;
  }

  return (
    <React.Fragment>
      <RowD
        {...props}
        selected={props.selectedAssembly.toString() === props.id.toString()}
      >
        {
          (blocked !== 2) ?
          <RadioInput
            title=""
            onClick={props.onChange}
            selected={props.selectedAssembly}
            value={props.id}
            name="selectedAssembly"
          /> :
          <div/>
        }
        <ExceedPlus
          name={`tag-${props.id}`}
          tooltipOpts={{
            delayHide: 20,
            clickable: false,
          }}
        >
          {props.tags.map((t) => (
            <Tag {...t} />
          ))}
        </ExceedPlus>
        <ExceedPlus
          name={`vehicle-${props.id}`}
          tooltipOpts={{
            delayHide: 20,
            clickable: false,
          }}
        >
          {props.vehicles.map((v) => (
            <Vehicle {...v} />
          ))}
        </ExceedPlus>
        <ExceedPlus
          name={`equipment-${props.id}`}
          tooltipOpts={{
            delayHide: 20,
            clickable: false,
          }}
        >
          {props.equipments.map((e) => (
            <Equipment {...e} />
          ))}
        </ExceedPlus>
        {
          /* props.operators.length > 1 
          ? */ <ExceedPlus
              className={'limit'}
              name={`operator-${props.id}`}
              tooltipOpts={{
                delayHide: 20,
                clickable: false,
              }}
            >
              {props.operators.map((o) => {
                o.hasMultiple = props.operators.length > 1;
                o.id = props.id;
                return <Operator  {...o} />
              })}
            </ExceedPlus>
          // : <TextExceedPlus
          //     name={"operator-".concat(props.id)}
          //     tooltipClassName="user-facilities-tooltip"
          //   >
          //     {props.operators.map((o) => (
          //       <Operator {...o} />
          //     ))}
          //    {/* {cardItem.facilities.map((f, index, arr) => {
          //      return (
          //        <span key={index}>
          //          {f.name}
          //          {index + 1 === arr.length ? "" : ", "}
          //        </span>
          //      );
          //    })} */}
          //  {/* </TextExceedPlus> */}
        }
        <ExceedPlus
          name={`contract-${props.id}`}
          tooltipOpts={{
            delayHide: 20,
            clickable: false,
          }}
        >
          {props.contracts.length
            ? props.contracts.map((c, i) => 
              <React.Fragment>
                <div
                  data-place="right"
                  data-tip
                  data-for={`contract-tooltip-${props.id}-`}
                >
                  <Contract 
                    {...c} 
                  />
                  <ReactTooltip id={`contract-tooltip-${props.id}-`}>
                    {c.tag ? (
                      <Tag
                        key={c.tag.id}
                        id={c.tag.id}
                        color={c.tag.color}
                        title={c.tag.title}
                        type={c.tag.type}
                        // isFull={this.state.contractBase === c.tag.id}
                        // onClick={this.handleTagClick}
                        // parentBackground='dark'
                      />
                    ) : (
                      <></>
                    )}
                  </ReactTooltip>
                </div>
              </React.Fragment>)
            : "-"}
        </ExceedPlus>
        <Text>{props.availabilityDate.toLocaleString("es-MX")}</Text>
        <Marker
          active={props.alert > 0}
          data-place="right"
          data-tip
          data-for={props.id.toString()}
        >
          {"!"}
        </Marker>
      </RowD>
      {props.alert > 0 && (
        <ReactTooltip
          id={props.id.toString()}
        >{`${props.alert} elemento(s) con alertas`}</ReactTooltip>
      )}
    </React.Fragment>
  );
};

export default AssembliesAvailabilityRow;

const Vehicle = (v) => {
  let tooltipText = `${v.economic_number}/${v.plates}`;

  return (
    <div>

    <div
      data-tip
      data-for={`${v.economic_number}/${v.plates}`}
    >
      {v.icon}
      <Text>{` ${v.economic_number}`}</Text>
    </div>

      <ReactTooltip
        id={`${v.economic_number}/${v.plates}`}
        clickable={true}
        delayHide={100}
        place={"right"}
        isCapture={true}
        border={true}
        type={"light"}
        effect={"solid"}
      >
        <div>
          <TextData>{tooltipText}</TextData>
        </div>
      </ReactTooltip>
    </div>
  );
};
const Operator = (o) => {

  var locked_from = '';
  var locked_to = '';
  var lock_motive = '';
  var incidence_week = '';

  var blocked = 0;

  if (o.sanctions !== null) {
    locked_from = o.sanctions.locked_from;
    locked_to = o.sanctions.locked_to;
    lock_motive = o.sanctions.lock_motive;
    incidence_week = o.sanctions.incidence_week;

    blocked = o.sanctions.locked === true ? 2 : 1;
  }

  return (
    <OperatorContainer>
      <div 
        data-tip
        data-for={`operator-${o.id}-blocked`}
        style={{position: 'relative'}}
      >
        <DriverLicense>{o.icon}</DriverLicense>
        {
          blocked !== 0 && 
          <div style={{width: 20, height: 20, position: 'absolute', top: 0, left: 0, cursor: 'pointer'}}>
            <img
              alt=""
              src="/images/blocked.png"
              style={{ width: 20, height: 20, filter: blocked === 1 ? 'sepia(100%) saturate(300%) brightness(70%) hue-rotate(180deg)' : null }}
            />
          </div>
        }
      </div>
      <Avatar src={o.avatar} alt="" />
      {/* <Text>{`${o.name} ${o.licenseType}`}</Text> */}
      {
        o.hasMultiple 
        ? <Text>{`${o.name} ${o.licenseType}`}</Text>
        : <TextExceedPlus
            name={"operator-".concat(o.id)}
            tooltipClassName="user-facilities-tooltip"
          >
            {[`${o.name} ${o.licenseType}`]}
          </TextExceedPlus>
      }

      <ReactTooltip
        className={`tooltip_operator_block ${blocked === 1 ? 'blue' : 'red'}`}
        disable={blocked === 0}
        id={`operator-${o.id}-blocked`}
        clickable={true}
        delayHide={100}
        place={"right"}
        isCapture={true}
        border={true}
        type={"light"}
        effect={"solid"}
      >
        <div>
          <TextTitle style={{color: blocked === 1 ? 'blue' : 'red'}}>{ blocked === 1 ? 'Proxima Capacitación' : 'Operador en Capacitación'}</TextTitle>
        </div>
        <div>
          <TextTitle style={{color: blocked === 1 ? 'blue' : 'red'}}>{'Motivo: '}</TextTitle>
          <TextData style={{color: blocked === 1 ? 'blue' : 'red'}}>{lock_motive}</TextData>
        </div>
        <div>
          <TextTitle style={{color: blocked === 1 ? 'blue' : 'red'}}>{'Incidencia de la semana: '}</TextTitle>
          <TextData style={{color: blocked === 1 ? 'blue' : 'red'}}>{incidence_week}</TextData>
        </div>
        <div>
          <TextTitle style={{color: blocked === 1 ? 'blue' : 'red'}}>{blocked === 1 ? 'Fecha inicio: ' : 'Fecha de inicio de capacitación: '}</TextTitle>
          <TextData style={{color: blocked === 1 ? 'blue' : 'red'}}>{moment(locked_from).format('YYYY/MM/DD HH:mm')}</TextData>
        </div>
        <div>
          <TextTitle style={{color: blocked === 1 ? 'blue' : 'red'}}>{blocked === 1 ? 'Fecha fin: ' : 'Fecha fin de capacitación: '}</TextTitle>
          <TextData style={{color: blocked === 1 ? 'blue' : 'red'}}>{moment(locked_to).format('YYYY/MM/DD HH:mm')}</TextData>
        </div>
      </ReactTooltip>

    </OperatorContainer>
  );
};
const Equipment = (e) => {
  return (
    <div>
      {e.icon}
      <Text>{`${e.economic_number}/${e.plates}`}</Text>
    </div>
  );
};
const Contract = (c) => {
  return (
    <div>
      <Avatar src={c.companyLogo} alt="" />
      <Text>{`${c.facilityName}`}</Text>
    </div>
  );
};

const Text = styled.span`
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextTitle = styled.span`
  color: black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: bold;
`;

const TextData = styled.span`
  color: black;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: normal;
`;

const Marker = styled.span`
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  background-color: rgb(64, 116, 137);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 22px;
  color: white;
  font-weight: 900;
`;

const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 2px;
`;

const DriverLicense = styled.div`
  width: 20px;
  height: 20px;
`;

const OperatorContainer = styled.div`
  display: flex;
  overflow: hidden;

  .text-exceed {
    width: calc(100% - 38px);
  }
`;