// @flow
import * as React from "react";
import SVGIcon from "../../svg-icon";
import { icons } from "../../svg-icon";
import TextInput from "../../controls/text-input";
import FilterBarContent from "./filter-bar-content";
import Button from "../../controls/button";
import InputTagger from "../../controls/inputTagger";
import FilterFavorites from "./filter-favorites";
import { Fragment } from "react";
import styled from "styled-components";

type Props = {
  // filtered: boolean,
  filterAction: Function,
  onChange: Function,
  searchStr: string,
  searchAction: Function,
  className: string,
  content: {
    title: string,
    items: {
      id: Number,
      description: string,
      items?: { id: Number, description: string }[],
    }[],
    name: string,
    listType: number,
  }[],
  selectedItems: { name: string, value: string }[],
  searchOnly: boolean,
};
type State = {|
  collapsed: boolean,
  isOpen: boolean,
  current: string,
|};

class FilterBarCompact extends React.Component<Props, State> {
  static defaultProps = {
    // filtered: false,
    searchStr: "",
    className: "",
    buttonFilterName: "Aplicar",
    content: [],
    selectedItems: {},
    searchAction: () => {},
    filterAction: () => {},
    onChange: () => {},
    onSearch: () => {},
    searchOnly: false,
    multiText: false,
    showTextSearch: true
  };
  state = {
    collapsed: true,
    isOpen: false,
    current: "",
    showLimitError: false
  };
  filterBarNode: any;

  handleOutsideClick = (e: any) => {
    if (!this.filterBarNode.contains(e.target))
      this.setState({ collapsed: true, isOpen: false });
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  handleInput = (e: any) => {};
  handleChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { collapsed } = this.state;
    const { searchStr, searchAction, className, limit, limitErrorItem } = this.props;
    return (
      <div className={"filter-bar-compact ".concat(className)}>
        <div
          ref={(node) => (this.filterBarNode = node)}
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            className="filter-content"
            style={{
              height: "48px",
              width: "auto",
              display: collapsed ? "none" : "flex",
              backgroundColor: "#3D5074",
              boxShadow:
                "0 2px 10px 1px rgba(0,0,0,0.5), 0 1px 2px 0 rgba(0,0,0,0.5)",
              borderRadius: "12px",
              position: "absolute",
              zIndex: "1000",
              right: "100%",
            }}
          >
            <div className="filter">
              <FilterBarContent
                {...this.props}
                {...this.state}
                handleChange={this.handleChange}
              />
            </div>
            <Button
              text={this.props.buttonFilterName}
              type={this.props.buttonFilterName === "Aplicar" ? "primary" : "primary outline"}
              className=""
              onClick={() => {
                this.props.filterAction();
                this.props.onSearch();
                this.setState({ collapsed: true, isOpen: false });
              }}
            />
            {/* <FilterFavorites add={true}></FilterFavorites> */}
          </div>
          {!this.props.searchOnly && (
            // ? (
            //   <Button
            //     text="Aplicar"
            //     type="primary small"
            //     className=""
            //     onClick={() =>
            //       searchAction({ target: { name: "doSearch", value: "" } })
            //     }
            //   />
            // ) : (
            <SVGIcon
              name={icons.filter}
              fill="#b0cdfb"
              height="25px"
              width="25px"
              onClick={() => this.setState({ collapsed: !collapsed })}
            />
          )}
          <div
            style={{
              display: Object.values(this.props.selectedItems).some(
                (s: any) => (s || []).length > 0
              )
                ? "block"
                : "none",
              right: "10px",
              bottom: "10px",
              borderRadius: "12px",
              height: "10px",
              width: "10px",
              backgroundColor: "#FF5A60",
              position: "relative",
              lineHeight: "1.2",
              fontSize: "10px",
              textAlignLast: "center",
            }}
          >
            {
              Object.values(this.props.selectedItems).filter(
                (s: any) => (s || []).length > 0
              ).length
            }
          </div>
          {
            this.props.showTextSearch 
            ? <div style={{ width: "180px", /*position: "relative"*/ display: "contents" }}>
                {
                  this.props.multiText ?
                  <Fragment>
                    { searchStr.length > 0 &&
                      <Button
                        text="Restablecer"
                        type="primary outline"
                        className=""
                        onClick={() => {
                          if (this.state.showLimitError) {
                            this.setState({
                              showLimitError: false
                            });
                          }
                          this.props.clearMultiText();
                        }}
                      />
                    }
                    <div>
                      <InputTagger
                        placeholder={ (this.props.searchPlaceholder) ? this.props.searchPlaceholder : "Buscar"}
                        tags={searchStr}
                        onChange={ e => {
                          if (limit && limit > 0 && (e?.target?.value || []).length > limit) {
                            searchAction({
                              target: {
                                name: e.target.name,
                                value: e.target.value.slice(0, limit)
                              }
                            });

                            this.setState({
                              showLimitError: true
                            });

                          } else {
                            searchAction(e);

                            if (this.state.showLimitError) {
                              this.setState({
                                showLimitError: false
                              });
                            }
                          }
                          
                        }}
                        skipMailValidation={true}
                      />
                      {
                        (this.state.showLimitError && searchStr.length > 0) && 
                        <ErrorText>
                          {`Máximo ${limit} ${limitErrorItem ?? ''}`}
                        </ErrorText>
                      }
                    </div>
                  </Fragment>
                  : <>
                    <TextInput
                      value={searchStr}
                      label={ (this.props.searchPlaceholder) ? this.props.searchPlaceholder : "Buscar"}
                      name="searchStr"
                      onChange={searchAction}
                      suffix={{
                        element: (
                          <SVGIcon
                            name={icons.search}
                            fill="#AFC8FF"
                            style={{ right: "0%", position: "absolute" }}
                          />
                        ),
                      }}
                    />
                    {/* <FilterFavorites add={false}></FilterFavorites> */}
                  </>
                }
              </div>
            : ''
          }
          
        </div>
      </div>
    );
  }
}
export default FilterBarCompact;

const ErrorText = styled.span`
  color: red;
  font-size: 16px;
  height: 13px;
  line-height: 14px;
  margin-top: 30px;
  display: inline-block;
  margin-bottom: -20px;
`;