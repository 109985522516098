// @flow
import React from "react";
import styled from "styled-components";

export const flexOpts = {
  direction: {
    default: "row",
    row: "row",
    column: "column",
    reverseRow: "row-reverse",
    reverseColumn: "column-reverse",
  },
  alignContent: {
    default: "stretch",
    stretch: "stretch",
    center: "center",
    flexStart: "flex-start",
    flexEnd: "flex-end",
    spaceBetween: "space-between",
    spaceAround: "space-around",
    initial: "initial",
    inherit: "inherit",
  },
  alignItems: {
    default: "stretch",
    stretch: "stretch",
    center: "center",
    flexStart: "flex-start",
    flexEnd: "flex-end",
    initial: "initial",
    inherit: "inherit",
  },
  justifyContent: {
    default: "flex-start",
    flexStart: "flex-start",
    flexEnd: "flex-end",
    center: "center",
    spaceBetween: "space-between",
    spaceAround: "space-around",
    initial: "initial",
    inherit: "inherit",
    spaceEvenly: "space-evenly",
  },
  wrap: {
    default: "nowrap",
    nowrap: "nowrap",
    wrap: "wrap",
    wrapReverse: "wrap-reverse",
    initial: "initial",
    inherit: "inherit",
  },
};
type Wrap = "nowrap" | "wrap" | "wrap-reverse" | "initial" | "inherit";

type Direction = "row" | "column" | "row-reverse" | "column-reverse";

type AlignContent =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "initial"
  | "inherit";

type AlignItems =
  | "stretch"
  | "center"
  | "flex-start"
  | "flex-end"
  | "baseline"
  | "initial"
  | "inherit";

type JustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "initial"
  | "inherit"
  | "space-evenly";

type Props = {
  inline?: boolean,
  direction?: Direction,
  alignContet?: AlignContent,
  alignItems?: AlignItems,
  justifyContent?: JustifyContent,
  wrap?: Wrap,
};

export const Flex = (props: Props) => {
  return <FlexStyled {...props} />;
};

const FlexStyled = styled.div`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  flex-direction: ${({ direction }) => direction || flexOpts.direction.default};
  align-content: ${({ alignContent = flexOpts.alignContent.default }) =>
    alignContent};
  align-items: ${({ alignItems = flexOpts.alignItems.default }) => alignItems};
  justify-content: ${({ justifyContent = flexOpts.justifyContent.default }) =>
    justifyContent};
  flex-wrap: ${({ wrap = flexOpts.wrap.default }) => wrap};
`;
