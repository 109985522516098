//@flow
import * as React from "react";
import Caret from "../../shared/component/caret";
import styled from "styled-components";
import Button from "../../shared/controls/button";
import * as Types from "./types";
import RowD from "../../shared/component/row-d";
// import UnassignedShipmentDetailTable from "./unassigned-shipment-detail-table";
// import Tag from "../../shared/controls/tag";
// import ExceedPlus from "../../shared/controls/exceed-plus";
import ReactTooltip from "react-tooltip";
// import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
import TableD from "../../shared/component/table-d";
import type { TableDConfig } from "../../shared/component/table-d";
import AssembleShipmentListRowDetail from "./assemble-shipment-list-row-detail";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
import Axios from "axios";
import { isArray } from "lodash";
import PDFIcon from "../../shared/icons/pdf-icon";
import XMLIcon from "../../shared/icons/xml-icon";
import CopyIcon from '../../shared/icons/copy-icon';
import ImageWithCheckMark from "../../shared/component/image-with-check-mark";

type Props = {
  ...Types.AssembleShipmentListRowType,
};

type State = {
  collapsed: boolean,
};

class AssembleShipmentListRow extends React.Component<Props, State> {
  state = {
    collapsed: true,
    tripIdValidated: null,
    errorMessage: '',
    cfdiErrors: [],
    isHovered: false
  };

  getErrors = (errorsArray) => {
    let errors = '';

    if (isArray(errorsArray)) {
      for (var i = 0; i < errorsArray.length; i++) {
        errors += `<b>*${errorsArray[i]}<b/><br/>`;
      }
    }

    return errors;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    //CFDI COMMENT
    this.validateCFDI();
    // if (this.state.tripIdValidated !== this.props.tripId) {
    //   this.setState({ tripIdValidated: this.props.tripId })
    //   this.props.validateCFDI({
    //       onSuccess: (r) => {
    //         this.setState({errorMessage: '*'});
    //       },
    //       onError: (e) => {
    //         let err = e.response?.data?.errors || []; 
    //         this.setState({errorMessage: this.getErrors(err)});
    //       }
    //     });
    // }

  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && e.target.id.includes('container-eta')) {
      return;
    }
    ReactTooltip.hide();
  }

  componentDidUpdate(prevProps) {

    if(prevProps.collapsed !== this.props.collapsed){
      this.setState({collapsed: this.props.collapsed});
    }
  }

  validateCFDI = () => {
    // if (this.state.tripIdValidated !== this.props.tripId) {
      // this.setState({ tripIdValidated: this.props.tripId })
      this.props.validateCFDI({
          onSuccess: (r) => {
            // this.setState({errorMessage: '*'});
            // r = [
            //   {
            //     trip_id: Number(this.props.tripId),
            //     errors: []
            //   }
            // ];
            // r = r.concat(this.props.details.map(d => ({order_id: d.id, errors: []})));
            // if(this.props.tripId === "21790") debugger;
            let cfdiErrors = (r || []).find(e => e.trip_id === Number(this.props.tripId)) || {};
            this.setState({errorMessage: cfdiErrors.errors, cfdiErrors: r});
          },
          onError: (e) => {
            let err = e.response?.data?.errors || []; 
            this.setState({errorMessage: this.getErrors(err), cfdiErrors: []});
          }
        });
    // }
  }

  getPrimaryAction = (primaryAction) => {

    if (primaryAction) {

      // if (primaryAction.isValidDate !== null && primaryAction.isValidDate !== undefined && !primaryAction.isValidDate) {
      //   return(
      //     <div
      //       data-place="left"
      //       data-tip
      //       data-for={'primary-button'+this.props.id.toString()}
      //     >
      //       <Button
      //         onClick={primaryAction.action}
      //         type={`${primaryAction.className} small`}
      //         text={primaryAction.description}
      //         name="primary"
      //         disabled = { (primaryAction.isValidDate !== null && primaryAction.isValidDate !== undefined) ? !primaryAction.isValidDate : false}
      //       />
      //     </div>
      //   );
      // } else {

        if (primaryAction.hover !== null && primaryAction.hover !== undefined) {
          return(
              <>
                <div
                    data-place="left"
                    data-tip
                    data-for={`actions-${this.props.id}-accept-assing`}
                  >
                    <Button
                      onClick={primaryAction.action}
                      type={`${primaryAction.className} small`}
                      text={primaryAction.description}
                      name="primary"
                      disabled={primaryAction.disable}
                    />
                  </div>

                  <ReactTooltip
                    html={true}
                    id={`actions-${this.props.id}-accept-assing`}
                  >
                    {primaryAction.hover}
                  </ReactTooltip>
              </>
          );

        } else {
          return(
            <>
              <Button
                onClick={primaryAction.action}
                type={`${primaryAction.className} small`}
                text={primaryAction.description}
                name="primary"
                disabled={primaryAction.disable}
                // disabled = { (primaryAction.isValidDate !== null && primaryAction.isValidDate !== undefined) ? !primaryAction.isValidDate : false}
              />
            </>
          );
        }
      // }

    } else {
      return <div/>
    }

  }

  getExtraActions = (primaryAction, extraActions) => {
    
    // if (primaryAction && primaryAction.isValidDate !== null && primaryAction.isValidDate !== undefined && !primaryAction.isValidDate && extraActions && extraActions.length) {
    //   let newActions = extraActions.filter( data => (data.description || '') !== 'Cambiar ensamble')
    //   return <ActionsTricolon actions={newActions} tooltipPlacement={"left"}/>
    // }

    return extraActions && extraActions.length ? <ActionsTricolon actions={extraActions} tooltipPlacement={"left"}/> : ''
  }

  openDocument = (url) => {
    // window.open(url, "_blank");
    // const link = document.createElement("a");
    // link.href = url;
    let array = url.split("/");
    let lastSegment = array.length > 0 ? array[array.length - 1] : ".";
    // link.setAttribute("download", lastSegment);
    // document.body.appendChild(link);
    // link.click();

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = lastSegment;
          link.click();
      })
      .catch(console.error);
  };

  getToken = () => {
    return {
      Authorization: "Bearer " + (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {}).access,
    };
  };

  renderCreateCFDI = () => {

    if (/* this.state.tripIdValidated === null || */ !this.state.errorMessage ) {
      return(<div></div>);
    } else {
      return(<div></div>);
      // if (!this.state.errorMessage.length) {
      //   return(
      //     <Button
      //       onClick={() => this.props.history.push(`/company/billing/${this.props.tripId}`)}
      //       type={`btn primary small`}
      //       text={'Crear CFDI'}
      //       name="secondary"
      //     />
      //   );
      // } else {
      //   return(
      //     <>

      //       <div
      //         data-place="left"
      //         data-tip
      //         data-for={`actions-${this.props.tripId}-cfdi`}
      //       >
      //         <Button
      //           onClick={() => {}}
      //           type={`btn primary small`}
      //           text={'Crear CFDI'}
      //           name="secondary"
      //           disabled={true}
      //         />
      //       </div>

      //       <ReactTooltip
      //         html={true}
      //         id={`actions-${this.props.tripId}-cfdi`}
      //       >
      //         {this.state.errorMessage}
      //       </ReactTooltip>

      //     </>
      //   );
      // }
    }

  }
  
  render() {
    const primaryAction = this.props.actions?.find((a) => a.type === "primary");

    const secondaryAction = this.props.actions?.find(
      (a) => a.type === "secondary"
    );
    const extraActions = this.props.actions?.filter((a) => a.type === "extra");
    const detailActions = this.props.actions?.filter((a) => a.type.includes("detail"));
    return (
      <React.Fragment>
        <ReactTooltip
          id={this.props.id.toString()}
        >{`viaje ${this.props.id}`}</ReactTooltip>
        {
          (primaryAction && primaryAction.isValidDate !== null && primaryAction.isValidDate !== undefined && !primaryAction.isValidDate) &&
          <ReactTooltip
            id={'primary-button'+this.props.id.toString()}
          >
            <div style={{
              width: 200,
              textAlign: 'center'
            }}>
              {'No se permite asignar si la hora de cita ya pasó. Si la cita no es correcta, solicita a tu cliente la reprogramación.'}
            </div>
          </ReactTooltip>

        }
        <RowD {...this.props}>
          <CaretContainer
            onClick={() => this.setState({ collapsed: !this.state.collapsed })}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <Caret up={this.state.collapsed} fill="#AFC8FF" isHovered={this.state.isHovered}/>
          </CaretContainer>
          <CompanyAvatar
            data-place="right"
            data-tip
            data-for={this.props.id.toString()}
          >
            <ImageWithCheckMark
              src={this.props.companyLogo}
              width={48}
              isCheckMark={this.props.is_company_verified}
              customStyle={{
                width: 48, height: 48, borderRadius: '50%', position: 'relative', marginRight: 8
              }}
            />
            <Text>{this.props.companyName}</Text>
          </CompanyAvatar>
          {
            <div style={{display: 'flex', alignItems: 'center', margin: 'auto'}}>
              <Text
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.history.push(
                    `/company/controls/shipment/orderdetail/${this.props.id}`
                  )
                }
              >{this.props.tripNumber}</Text>
              {
                this.props.tripNumber !== "" ?
                <CopyIcon className="copy-icon-style" fill={"#fff"} height="16px" width="16px" onClick={() => {navigator.clipboard.writeText(this.props.tripNumber);}}/> : ""
              }
            </div>
          }
          <Text>{this.props.tickets}</Text>
          <Text>{this.props.class}</Text>
          <Text>{this.props.assembly}</Text>
          <Text>{this.props.status.description}</Text>

          <div style={{display: 'flex', justifyContent: "space-around"}}>
            <div style={{width: '20px', height: '20px', backgroundColor: 'transparent'}}></div>

            {
              (this.props.cfdi && this.props.cfdi.pdf_file && this.props.cfdi.status > 1) && 
              <div
                style={{marginRight: '8px', cursor: "pointer"}}
                onClick={() => {
                  this.openDocument(this.props.cfdi.pdf_file);
                }}
              >
                {/* <img
                  alt=""
                  src="/images/pdf_icon.png"
                  style={{ width: '17px', height: '20px',}}
                /> */}
                <PDFIcon 
                  height="23" 
                  width="23"
                  fill={this.props.cfdi.status === 2 ? "#dcdcdc" : "#3d77f7"}
                />
              </div>
            }

            {
              (this.props.cfdi && this.props.cfdi.xml_file && this.props.cfdi.status > 1) && 
              <div 
                style={{ cursor: "pointer"}} 
                onClick={() => {
                  this.openDocument(this.props.cfdi.xml_file);
                }}
              >
                {/* <img
                  alt=""
                  src="/images/xml_icon.png"
                  style={{ width: '17px', height: '23px',}}
                /> */}
                <XMLIcon  
                  height="23" 
                  width="23"
                  fill={this.props.cfdi.status === 2 ? "#dcdcdc" : "#3d77f7"}
                />
              </div>
            }

          </div>

          <ActionsContainer>

            {/* CFDI COMMENT */}
            {this.renderCreateCFDI()}
            
            {secondaryAction && (
              <Button
                onClick={secondaryAction.action}
                type={`${secondaryAction.className} small`}
                text={secondaryAction.description}
                name="secondary"
              />
            )}
            {this.getPrimaryAction(primaryAction)} 
            {this.getExtraActions(primaryAction, extraActions)}
          </ActionsContainer>
        </RowD>
        {!this.state.collapsed && (
          <TableD tableConfig={Config}>
            {this.props.details.map((d) => {
              let errors = ((this.state.cfdiErrors || []).find(e => e.order_id === d.id) || {}).errors || []
              let actions = detailActions.concat([{
                type: "detail xtra-".concat(d.id),
                description: "Crear CFDI",
                disable: errors.length || ((d.cfdi?.status ?? 0) === 3) ? true : false,
                action: () => this.props.history.push(`/company/billing/${d.id}/?isOrder=1&status=3`)
              }])
              return ( 
                <AssembleShipmentListRowDetail 
                  {...d} 
                  tripId={this.props.id} 
                  cfdiErrors={errors} 
                  actions={actions} 
                  history={this.props.history}
                  setOrderDetail={orderToSelect => {
                    if (this.props.setOrderDetail) {
                      this.props.setOrderDetail(orderToSelect);
                    }
                  }}
                />
              )
            })}
          </TableD>
        )}
      </React.Fragment>
    );
  }
}

export default AssembleShipmentListRow;

const CompanyAvatar = styled.div`
  display: flex;
  flex-direction: row;
  & img {
    width: 48px;
    border-radius: 24px;
    margin-right: 6px;
  }
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-around;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const CaretContainer = styled.div`
  align-self: center;
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

// const TagsContainer = styled(ExceedPlus)`
//   justify-content: center;
//   width: 100%;
// `;

const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
  justifyContent: "center"
};

const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  margin: "auto",
  overflow: "auto",
  maxHeight: "100%",
};

const Config: TableDConfig = {
  rowStyle: {
    backgroundColor: "rgb(34,42,62)",
    height: "63px",
  },
  headerStyle: {
    backgroundColor: "rgb(34,42,62)",
    borderBottom: "2px solid #A4B4CE",
  },
  columns: [
    {
      text: "Folio",
      width: "9%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Origen/Cita",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Destino/Cita",
      width: "15%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus de Remolque",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "6%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Ensamble",
      width: "7%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus de Orden",
      width: "9%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tipo",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Nivel",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Volumen",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "CFDI",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    // {
    //   text: "Cantidad",
    //   width: "7%",
    //   headerItemStyle,
    //   cellStyle,
    // },
    {
      text: "",
      width: "3%",
      headerItemStyle,
      cellStyle: {
        ...cellStyle,
        overflow: "hidden"
      },
    },
  ],
};
