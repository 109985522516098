import * as React from "react";
import SVGIcon, { icons } from "../svg-icon";
import styled from "styled-components";

const Caret = (props) => {
  Caret.defaultProps = {
    up: false,
    isHovered: false
  };
  return (
    <CaretStyled
      up={props.up}
      name={icons.caretDown}
      fill="#1F8EFA"
      height={props.isHovered ? "24px" : "20px"}
      width={props.isHovered ? "24px" : "20px"}
      {...props}
    />
  );
};

export default Caret;

const CaretStyled = styled(SVGIcon)`
  transition: 0.2s ease-in-out;
  transform: ${({ up }) => (up ? "rotate(180deg)" : "rotate(0deg)")};
  cursor: pointer;
`;
