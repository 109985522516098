import React, { Component } from "react";
import PropTypes from "prop-types";
import RadioButtons from "../../controls/radio";
import SVGIcon, { figures } from "../../svg-icon";

export const units = {
  systems: ["METRIC", "USA"],
  unitTypes: {
    length: "length",
    weight: "weight",
    load: "load",
  },
  length: {
    METRIC: "m",
    USA: "ft",
  },
  weight: {
    METRIC: "ton",
    USA: "lb",
  },
  load: {
    METRIC: "ton",
    USA: "ton",
  },
  loadCapacity: {
    METRIC: "nm",
    USA: "lb-ft",
  },
  horsePower: {
    METRIC: "hp",
    USA: "hp",
  },
};

class FleetSpecificationVehicle extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    length: PropTypes.string,
    weight: PropTypes.string,
    singleMaxLoad: PropTypes.string,
    doubleMaxLoad: PropTypes.string,
    unitSystem: PropTypes.oneOf(units.systems),
  };
  static defaultProps = {
    className: "",
    height: "",
    width: "",
    length: "",
    weight: "",
    singleMaxLoad: "",
    doubleMaxLoad: "",
    unitSystem: units.systems[0],
  };

  handleInput = (e) => {
    const value = parseFloat(e.target.value);

    if (value < 0 || value > 99.99) {
      return false;
    }
    this.props.onChange(e);
  };

  validate = (e) => {
    var t = e.target.value;

    if (t.includes('.')) {
      let pointsSegments = t.split('.');

      if (pointsSegments.length > 1 && pointsSegments[1].length < 3) {
        e.target.value = t;
      } else {
        e.target.value = t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3);
      }
    }
  };

  render() {
    return (
      <div className="specification-card">
        <div className="unit-systems" style={{ display: "none" }}>
          {units.systems.map((us) => (
            <RadioButtons
              value={us}
              name={"unitSystem"}
              className={"unitSystem"}
              key={us}
              onChange={this.handleInput}
              checked={us === this.props.unitSystem}
            />
          ))}
        </div>
        <div className="container">
          <div className="title">{this.props.title}</div>
          <div className="content">
            <div className="column">
              <div className="rows">
                <div>
                  <SVGIcon
                    name={figures.vehicleSide}
                    fill="none"
                    stroke="#D5DEEC"
                  />
                  <div className="line-container-horizontal">
                    <div className="line-vertical"></div>
                    <div className="line-horizontal"></div>
                    <div className="line-vertical"></div>
                  </div>
                </div>
                <div
                  className="line-container-vertical"
                  style={{ marginLeft: "10px" }}
                >
                  <div className="line-horizontal"></div>
                  <div className="line-vertical"></div>
                  <div
                    className="line-horizontal"
                    style={{ marginBottom: "10px" }}
                  ></div>
                </div>
              </div>
              <div className="measures">
                {this.getMeasures(this.props.length, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="measures" style={{ marginTop: "100%" }}>
                {this.getMeasures(this.props.height, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="rows">
                <div
                  className="line-container-vertical"
                  style={{ marginRight: "10px" }}
                >
                  <div className="line-horizontal"></div>
                  <div className="line-vertical"></div>
                  <div
                    className="line-horizontal"
                    style={{ marginBottom: "10px" }}
                  ></div>
                </div>
                <div>
                  <SVGIcon
                    name={figures.vehicleFront}
                    fill="none"
                    stroke="#D5DEEC"
                    viewBox="14 0 90 84"
                  />
                  <div className="line-container-horizontal">
                    <div className="line-vertical"></div>
                    <div className="line-horizontal"></div>
                    <div className="line-vertical"></div>
                  </div>
                </div>
              </div>
              <div className="measures">
                {this.getMeasures(this.props.width, units.unitTypes.length)}
              </div>
            </div>
            <div className="column">
              <div className="measure-input">
                <label>Altura</label>
                <div className="input-block  text-without-arrow">
                  <input
                    type="number"
                    name="height"
                    value={this.props.height}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.length)}</label>
                </div>
              </div>
              <div className="measure-input">
                <label>Anchura</label>
                <div className="input-block  text-without-arrow">
                  <input
                    type="number"
                    name="width"
                    value={this.props.width}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.length)}</label>
                </div>
              </div>
              <div className="measure-input">
                <label>Largo</label>
                <div className="input-block  text-without-arrow">
                  <input
                    type="number"
                    name="length"
                    value={this.props.length}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.length)}</label>
                </div>
              </div>
              <div className="measure-input">
                <label>Peso</label>
                <div className="input-block  text-without-arrow">
                  <input
                    type="number"
                    name="weight"
                    value={this.props.weight}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.weight)}</label>
                </div>
              </div>
            </div>
            {/* <div className="column">
              <div className="image">
                <SVGIcon name={icons.dumbbell} fill="none" stroke="#D5DEEC" />
              </div>
              <div className="image">
                <SVGIcon
                  name={figures.equipmentSide}
                  fill="none"
                  width="126px"
                  height="90px"
                  stroke="#D5DEEC"
                  viewBox="0 0 126 73"
                />
              </div>
            </div> */}
            {/* <div className="column">
              <div className="measure-block">
                <label>Carga MAX. Sencillo</label>
                <div className="input-block  text-without-arrow">
                  <input
                    type="number"
                    name="singleMaxLoad"
                    value={this.props.singleMaxLoad}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.load)}</label>
                </div>
              </div>
              <div className="measure-block  text-without-arrow">
                <label>Carga MAX. Doble</label>
                <div className="input-block">
                  <input
                    type="number"
                    name="doubleMaxLoad"
                    value={this.props.doubleMaxLoad}
                    onChange={this.handleInput}
                    onInput={this.validate}
                  />
                  <label>{this.getUnitType(units.unitTypes.load)}</label>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  getMeasures = (value, unitType) => {
    value = value > 0 ? value : "??";
    unitType = this.getUnitType(unitType);
    return value.toString().concat(" ", unitType);
  };

  getUnitType = (unitType) => {
    return units[unitType][this.props.unitSystem];
  };
}

export default FleetSpecificationVehicle;
