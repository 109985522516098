import React from 'react';
import WifiIcon from '../../shared/icons/gps/wifi-icon';

export default function GpsResumeComponent(props) {

  const { name = '', width, height, icon, firstValue = 0, secondValue = 0} = props;

  return(
    <div className={'gps-resume-container'}
      style={{
        width,
        height
      }}
    >
      {
        (name === '' && icon !== null) ? 
          <div
            style={{
              marginTop: 8
            }}
          >
            {icon}
          </div>

        : 
          <div
            style={{
              color: 'white',
              fontSize: 13
            }}
          >
            {name}
          </div>
      }

      <div
        style={{
          marginBottom: 10,
          marginRight: 8
        }}
      >
        <WifiIcon
          height={"20px"}
          width="20px"
          fill={"#B1F06C"}
        />
      </div>
      <div
        style={{
          color: 'rgb(165, 189, 240)',
          fontSize: 13
        }}
      >
        {firstValue}
      </div>
      <div
        style={{
          marginBottom: 10,
          marginRight: 8
        }}
      >
        <WifiIcon
          height={"20px"}
          width="20px"
          fill={"#70889E"}
        />
      </div>
      <div
        style={{
          color: 'rgb(165, 189, 240)',
          fontSize: 13
        }}
      >
      {secondValue}
      </div>
    </div>
  );

}