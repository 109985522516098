import React from 'react';

const BlockedLicense = (props) => {
  return(
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1471 6H4.06342C2.93572 6 2 6.91079 2 8.00845V16.6231C2 17.7208 2.93572 18.6316 4.06342 18.6316H20.1471C21.2748 18.6316 22.2105 17.7208 22.2105 16.6231V8.00845C22.2105 6.88978 21.2748 6 20.1471 6ZM7.85043 8.62963C8.85015 8.62963 9.68035 9.41667 9.68035 10.4108C9.68035 11.3839 8.87176 12.192 7.85043 12.192C6.8291 12.192 6.02052 11.4049 6.02052 10.4108C6.02052 9.43691 6.8291 8.62963 7.85043 8.62963ZM5.00012 15.1937C5.00012 13.9723 5.46798 12.8947 6.19181 12.2323C6.63891 12.6052 7.21314 12.8535 7.85137 12.8535C8.48959 12.8535 9.06383 12.6254 9.51092 12.2323C10.2339 12.8948 10.7026 13.9714 10.7026 15.1937C10.6802 16.2703 5.00008 16.2703 5.00008 15.1937H5.00012ZM18.8492 15.8561H13.2756C12.9988 15.8561 12.7653 15.628 12.7653 15.3595C12.7653 15.0901 12.9997 14.8628 13.2756 14.8628H18.8492C19.1259 14.8628 19.3594 15.091 19.3594 15.3595C19.3602 15.6288 19.1475 15.8561 18.8492 15.8561ZM18.8492 12.8124H13.2756C12.9988 12.8124 12.7653 12.5842 12.7653 12.3157C12.7653 12.0463 12.9997 11.8191 13.2756 11.8191H18.8492C19.1259 11.8191 19.3594 12.0472 19.3594 12.3157C19.3602 12.5843 19.1475 12.8124 18.8492 12.8124ZM18.8492 9.78866H13.2756C12.9988 9.78866 12.7653 9.56055 12.7653 9.29202C12.7653 9.02265 12.9997 8.79537 13.2756 8.79537H18.8492C19.1259 8.79537 19.3594 9.02348 19.3594 9.29202C19.3602 9.56138 19.1475 9.78866 18.8492 9.78866Z" fill="#98BCDF" />
      <g opacity="0.8">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 3C13.98 3 15.78 3.63 17.25 4.68L4.68 17.25C3.63 15.78 3 13.98 3 12C3 7.02 7.02 3 12 3ZM19.32 6.75C20.37 8.22 21 10.02 21 12C21 16.98 16.98 21 12 21C10.02 21 8.22 20.37 6.75 19.32L19.32 6.75Z" fill="#DF2425" />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 3C13.98 3 15.78 3.63 17.25 4.68L4.68 17.25C3.63 15.78 3 13.98 3 12C3 7.02 7.02 3 12 3ZM19.32 6.75C20.37 8.22 21 10.02 21 12C21 16.98 16.98 21 12 21C10.02 21 8.22 20.37 6.75 19.32L19.32 6.75Z" fill="white" />
        </mask>
        <g mask="url(#mask0)">
        </g>
      </g>
    </svg>
  );
}

export default BlockedLicense;