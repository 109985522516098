import { cloneDeep } from 'lodash';
import React from 'react';
import Button from '../../shared/controls/button';
import PrevSwitch from '../../shared/controls/prev-switch';
import SelectInputStyled from '../../shared/controls/select-input-styled';
import TextInput from '../../shared/controls/text-input';

const TIME_OPTIONS = [
  {
    id: 1,
    description: "Minutos",
    time_value: 1
  },
  {
    id: 2,
    description: "Horas",
    time_value: 60//minutos en una hora
  },
  {
    id: 3,
    description: "Dias",
    time_value: 1440//minutos en un dia
  },
]

class AlertGroup extends React.Component {
  state = {
    inputErrors: [],
    mainSwitchModified: false
  }

  static defaultProps = {
    alert: {},
    group: {},
    facilities: [],
    takenFacilities: [],
    users:[],

    onAlertGroupDataChange: () => {}
  }

  componentDidMount() {
    this.setState({
      mainSwitchModified: !this.props.mainSwitch
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.mainSwitch !== this.props.mainSwitch) {
      this.setState({
        mainSwitchModified: !this.props.mainSwitch
      });
    }
  }

  getDurationError = (level, index, arr, group_id) => {
    let errors = cloneDeep(this.state.inputErrors);
    if (Number(level.duration) === 0 && level.is_active) {
      errors[index] = true;
      if(this.state.inputErrors[index] !== true){
        this.setState({inputErrors: errors});
      }
      
      return ["Debe ser un valor diferente a 0"];
    }

    if(!arr[index - 1] || !level.is_active){ 
      errors[index] = false;
      if(this.state.inputErrors[index] !== false){
        this.setState({inputErrors: errors});
      }
      return [];
    }
    let prevLevel = arr[index - 1];
    let currentValue = Number(level.duration) * (TIME_OPTIONS.find(o => o.id === Number(level.measurement)) || {time_value: 1}).time_value;
    let previousValue = Number(prevLevel.duration) * (TIME_OPTIONS.find(o => o.id === Number(prevLevel.measurement)) || {time_value: 1}).time_value;
    if(currentValue <= previousValue){ 
      errors[index] = true;
      if(this.state.inputErrors[index] !== true){
        this.setState({inputErrors: errors});
      }
      return ["Menor"];
    } else {
      errors[index] = false;
      if(this.state.inputErrors[index] !== false){
        this.setState({inputErrors: errors});
      }
      return []
    }
  }

  render() {
    return (
      <div className={"company-alert-group".concat(this.props.group.edit ? "" : " blocked")}>
        <div className="group-facility">
          {
            this.props.companyType === 2 && (this.props.alert?.id === 22 || this.props.alert?.id === 23 || this.props.alert?.id === 24)
            ? ''
            : <SelectInputStyled
                selectedItems={this.props.companyType === 1 
                  ? (this.props.group.bases || []).map(u => u.toString()) 
                  : (this.props.group.facilities || []).map(u => u.toString())
                }
                items={this.props.companyType === 1 
                  ? this.props.bases.filter(base => !this.props.takenBases.map(tB => tB.toString()).includes(base.id.toString())).map(base => (
                      {
                        id: base.id,
                        description: base.title
                      }
                    ))
                  : this.props.facilities.map(tag => (
                      {
                        ...tag, 
                        items: (tag.items || []).filter(f => !this.props.takenFacilities.includes(f.id))
                      }
                    ))
                }//quitar las instalaciones ya seleccionadas en otros grupos
                onChange={(e) => this.props.onAlertGroupDataChange(e, this.props.companyType === 1 ? 'base' : 'facility', this.props.alert.id, this.props.group.id)}
                className={""}
                name={"selectedFacility"}
                label={"Agrega al menos una ".concat(this.props.companyType === 1 ? 'base' : 'instalación')}
                multiple={true}
                disabled={!this.props.group.edit}
              />
          }
        </div>
        <div
          className="edit-btn"
          onClick={(e) => {
            this.props.onAlertGroupDataChange(e, "edit", this.props.alert.id, this.props.group.id)
          }}
        >
          <img alt="" src="/images/edit-transparent.svg" />
        </div>
        {
          this.props.group.group_detail.map((level, i, a) => (
            <div className="company-alert-level">
              <PrevSwitch
                name="active"
                checked={ this.state.mainSwitchModified ? false : level.is_active}
                handleClick={e => this.props.onAlertGroupDataChange({
                    target: {
                      name: "active",
                      value: this.props.alert.id,
                      checked: e
                    }
                  }, "active", this.props.alert.id, this.props.group.id, level.level)
                }
                disabled={!this.props.group.edit}
              />{" "}
              Nivel {level.level}
              <div className="alert-minutes">
                A partir de:
                <TextInput
                  label=""
                  name="duration"
                  value={level.duration}
                  className="item"
                  integer={true}
                  onChange={ e => this.props.onAlertGroupDataChange(e, "duration", this.props.alert.id, this.props.group.id, level.level)}
                  disabled={!this.props.group.edit}
                  errors={this.props.alert.id === 4 ? [] : this.getDurationError(level, i, a, this.props.group.id)}
                />
                <SelectInputStyled
                  selectedItems={[level.measurement.toString()]}
                  items={TIME_OPTIONS}
                  onChange={(e) => this.props.onAlertGroupDataChange(e, "unit", this.props.alert.id, this.props.group.id, level.level)}
                  className={""}
                  name={"selectedUnit"}
                  label={""}
                  multiple={false}
                  showSearch={false}
                  showDeselector={false}
                  disabled={!this.props.group.edit}
                />
              </div>
              <div className="alert-users">
                <SelectInputStyled
                  selectedItems={level.user_detail.users.map(u => u.toString())}
                  items={this.props.users}
                  onChange={(e) => this.props.onAlertGroupDataChange(e, "user", this.props.alert.id, this.props.group.id, level.level)}
                  className={""}
                  name={"selectedUsers"}
                  label={"Selecciona los usuarios que recibirán esta alerta"}
                  multiple={true}
                  disabled={!this.props.group.edit}
                />
              </div>
            </div>
          ))
        }{
          this.props.group.edit &&
          <div className="action-buttons">
            <Button 
              text={"Cancelar"}
              type={"primary outline"}
              className={"cancel-changes-button"}
              onClick={(e) => {
                this.props.onAlertGroupDataChange(e, "cancel", this.props.alert.id, this.props.group.id)
              }}
            />
            <Button 
              text={"Eliminar"}
              type={"secondary"}
              className={"delete-changes-button"}
              onClick={(e) => {
                this.props.onAlertGroupDataChange(e, "delete", this.props.alert.id, this.props.group.id)
              }}
            />
            <Button 
              text={"Guardar"}
              type={"primary"}
              className={"save-changes-button"}
              disabled={
                (this.props.companyType === 2 && this.props.alert?.id !== 22 && this.props.alert?.id !== 23 && this.props.alert?.id !== 24 && !(this.props.group.facilities || []).length) 
                || (this.props.companyType === 1 && !(this.props.group.bases || []).length) 
                || this.state.inputErrors.some(e => e)
              }
              onClick={(e) => {
                this.props.onAlertGroupDataChange(e, "save", this.props.alert.id, this.props.group.id)
              }}
            />
          </div>
        }
      </div>
    )
  }
}

export default AlertGroup;