import React, { Component } from 'react';
import InputSearch from '../../controls/input-search';

class FilterSearchComponent extends Component {

  // handleSearch = () => {
  //     this.props.onSearch();
  // }

  handleChange = () => {
    this.props.onChange()
  }

  render() {
    return (
      <div className="right">
        <InputSearch
          type='text'
          label='Buscar'
          name='facilitySearch'
          className="full"
          onChange={this.props.onChange}
        />
        <img
          src="/images/search.svg"
          alt=""
          className="icono"
        // onClick={this.handleSearch}
        />
      </div>
    )
  };

};

export default FilterSearchComponent;

FilterSearchComponent.defaultProps = {
  // onSearch: () => {console.log('Not yet implemented')},
  onChange: () => { console.log('Not yet implemented') },
}