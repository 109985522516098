import styled from "styled-components";
import ArrowLeft from "../../../shared/icons/routes/ArrowLeft";
import Button from "../../../shared/controls/button";
import SelectInput from "../../../shared/controls/select-input";
import TextInput from "../../../shared/controls/text-input";
import ReactTooltip from "react-tooltip";

export const RoutesContainer = styled.div`
  background: linear-gradient(180deg, #6a7caf 0%, #242e42 40%);
  background-color: #242e42;
  padding: 0;
  height: 100%;
  padding: 50 0;
`;

export const Header = styled.div`
  display: flex;
  text-align: center;
  height: 100px;
  align-items: center;
  margin: 0 50;
`;

export const HeaderTitle = styled.div`
  color: #ffffff;
  font-size: 36px;
  font-weight: 800;
  line-height: 55px;
`;

export const Content = styled.div`
  position: relative;
  margin: 0 50;
  border-radius: 12px;
  background-color: #2f3b52;
  margin-bottom: 20px;
`;

export const PanelHeader = styled.div`
  margin: 0 45px;
  color: #ffffff;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  flex-direction: column;
`;

export const PanelTitle = styled.div`
  margin-top: 16px;
  & a {
    margin-left: 20px;
  }
`;

export const Line = styled.div`
  background: linear-gradient(139.24deg, #2f4ff1 0%, #4184f9 100%);
  width: 30px;
  height: 4px;
`;

export const FieldsMaps = styled.div`
  margin: 30px 46px 0 46px;
  display: flex;
`;

export const FieldsMapsLeft = styled.div`
  width: 70%;
`;

export const FieldsMapsRight = styled.div`
  width: 30%;

  & .map {
    & .map-view {
      border-radius: 12px;
      height: 100%;
      min-width: 345px;
      min-height: 345px;
    }
  }
`;

export const ParentContainer = styled.div`
  margin-right: 10px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

export const FieldsArrow = styled(ArrowLeft)`
  margin-top: 24px;
`;

export const TagsAndFilesContainer = styled.div`
  //   border: 1px solid red;
  display: flex;
  margin-top: 30px;
`;

export const TagsContainer = styled.div`
  //   border: 1px solid blue;
  width: 60%;
`;

export const TitleLabel = styled.div`
  width: 100%;
  color: #9ba1ac;
  margin-bottom: 10px;
`;

export const FilesContainer = styled.div`
  //   border: 1px solid white;
  width: 40%;
  display: flex;
`;

export const Nomenclature = styled.div`
  width: 50%;
`;

export const NomenclatureItem = styled.div`
  width: 100%;
  color: white;
  font-size: 12px;
`;

export const Permissions = styled.div`
  width: 50%;
`;

export const UploadChipsContainer = styled.div`
  margin-top: 20px;
`;

export const UploadButton = styled(Button)`
  margin-top: 15px;
  width: 130px !important;
`;

export const ClassesContainer = styled.div`
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
  background-color: #3d5074;
  color: #a4b4ce;

  & > div {
    display: flex;
    height: 60px;
  }
`;

export const ClassesHeader = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #a4b4ce;
  }
`;

export const ClassItem = styled.div`
  & img {
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
    &.open {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in-out;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(164, 180, 206, 0.4);

    & > div {
      font-size: 12px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &:last-of-type {
      position: relative;
    }
  }
`;

export const ClassItemSelect = styled(SelectInput)`
  padding-top: 0;
  width: 80%;
  & select {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .caret {
    top: 35%;
  }
`;

export const ClassItemInput = styled(TextInput)`
  padding-top: 1px;
  padding-bottom: 0;
  width: 50%;

  input {
    width: 100%;
  }
`;

export const ClassItemInputDouble = styled(TextInput)`
  padding-top: 1px;
  padding-bottom: 0;
  width: 20px;

  input {
    width: 100%;
  }

  &.text-input {
    margin: 0;

    & input {
      padding-top: 0;
      padding-bottom: 0;
      line-height: 0;
      font-size: 12px;
    }

    &.separate {
      margin-left: 10px !important;
    }
  }
`;

// export const ClassItemMap = styled.div`
//   width: 25%;
//   text-align: center;

//   & a {
//     text-decoration: none;
//     color: #6186bf;
//   }
// `;

export const ClassItemButton = styled.div`
  width: 90%;
  & button {
    margin: auto;
  }
`;

export const ClassItemActions = styled.div`
  width: 10%;
`;

export const ClassAddItem = styled.div`
  padding: 40px 30px;
  & > img {
    width: 35px;

    &.disabled {
      opacity: 0.5;
    }
  }
`;

export const Ellipsis = styled.div`
  cursor: pointer;
  & b {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #ccc;
    display: block;

    &:before {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #ccc;
      display: block;
      content: "";
      position: absolute;
      top: 17px;
    }

    &:after {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background-color: #ccc;
      display: block;
      content: "";
      position: absolute;
      bottom: 17px;
    }
  }
`;

export const EllipsisToolTip = styled(ReactTooltip)`
  & > div {
    cursor: pointer;
    color: #2f4ff1;
  }
`;

export const ClassItemDetails = styled.div`
  display: none !important;
  flex-direction: column;
  height: auto !important;

  &.open {
    display: flex !important;
  }

  & .header {
    background-color: #2f3b52;
    display: flex;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }
  }

  & .container {
    background-color: #2f3b52;
    display: flex;
    flex-direction: column;
    width: 100%;
    & > div {
      display: flex;
      border-top: 1px solid rgba(164,180,206,0.1);
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;

        &.nombre_tramo {
          justify-content: left;

          & span {
            margin-left 30px;
          }
        }
      }
    }
  }
`;

export const ClassItemzones = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  & span {
    margin-left: 2px;
  }
`;

export const Footer = styled.div`
  margin: 0 50 50 50;
  display: flex;
  justify-content: flex-end;

  & button {
    margin-right: 30px;
  }
`;

export const base = styled.div``;
