import React from "react";
const Store = (props) => {
  return (
    <svg viewBox="0 0 49 64" {...props}>
      <path d="M 45 20 L 45 46.4808 C 45 46.6187 44.9446 46.7505 44.8462 46.8479 C 44.7478 46.9452 44.6145 47 44.475 47 L 44.475 47 L 19.275 47 C 19.1355 47 19.0022 46.9452 18.9038 46.8479 C 18.8054 46.7505 18.75 46.6187 18.75 46.4808 L 18.75 46.4808 L 18.75 27.7885 C 18.75 27.6505 18.6946 27.5187 18.5962 27.4214 C 18.4978 27.324 18.3645 27.2692 18.225 27.2692 L 18.225 27.2692 L 8.25 27.2692 C 7.96084 27.2692 7.725 27.5025 7.725 27.7885 L 7.725 27.7885 L 7.725 46.4808 C 7.725 46.6187 7.66963 46.7505 7.57119 46.8479 C 7.47275 46.9452 7.33945 47 7.2 47 L 7.2 47 L 3.525 47 C 3.38555 47 3.25225 46.9452 3.15381 46.8479 C 3.05537 46.7505 3 46.6187 3 46.4808 L 3 46.4808 L 3 20 L 45 20 Z M 39.7479 30.3847 L 24.0021 30.3847 C 23.8626 30.3847 23.7272 30.4394 23.6288 30.5368 C 23.5304 30.6341 23.475 30.768 23.475 30.9059 L 23.475 30.9059 L 23.475 41.8056 C 23.475 41.9435 23.5304 42.0774 23.6288 42.1748 C 23.7272 42.2721 23.8626 42.3269 24.0021 42.3269 L 24.0021 42.3269 L 39.7479 42.3269 C 39.8873 42.3269 40.0227 42.2721 40.1211 42.1748 C 40.2195 42.0774 40.2749 41.9435 40.2749 41.8056 L 40.2749 41.8056 L 40.2749 30.9059 C 40.2749 30.768 40.2195 30.6341 40.1211 30.5368 C 40.0227 30.4394 39.8873 30.3847 39.7479 30.3847 L 39.7479 30.3847 Z" />
      <path d="M 1.16779 16.227 C 3.848 17.7724 9.2103 16.9997 9.2103 13.9088 C 9.2103 18.03 18.742 18.03 18.742 13.9088 C 18.742 18.03 28.2738 18.03 28.2738 13.9088 C 28.2738 18.03 37.8055 18.03 37.8055 13.9088 C 37.8055 16.9313 42.9355 17.7382 45.6689 16.3256 C 46.2951 16.0036 46.752 15.4361 46.9254 14.762 C 47.0967 14.0879 46.9702 13.3735 46.5745 12.798 L 38.7378 1.36029 C 38.1544 0.509116 37.1814 9.09495e-013 36.1412 9.09495e-013 L 10.8748 9.09495e-013 C 9.83451 9.09495e-013 8.86155 0.509106 8.27818 1.36029 L 0.435142 12.8042 C 0.0516685 13.3475 -0.0849894 14.0237 0.0516685 14.6716 C 0.190368 15.3195 0.594258 15.883 1.16739 16.2271 L 1.16779 16.227 Z" />
    </svg>
  );
};
export default Store;
