import styled from "styled-components";

export const FaqsContainer = styled.div`
  overflow: hidden;
  position: absolute;
  margin-left: 80px;
  transition: margin 0.3s ease-in-out;
  width: 400px;
  background-color: #f4f6f9;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.open {
    margin-left: 235px;
  }
`;

export const FaqsHeader = styled.div`
  margin: 20px 20px 0 20px;
  span {
    cursor: pointer;
    font-size: 18px;
  }
`;

export const FaqsOptions = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
    border-bottom: 2px solid #0077e2;

    span {
      margin-left: 30px;
      font-size: 20px;
      color: color: rgba(0, 0, 0, 1);
    }
  }
`;

export const FaqsContent = styled.div`
  flex: auto;
  margin: 20px;
  font-size: 14px;
  color: #757575;
  display: flex;
  flex-direction: column;
`;

export const DetailsCards = styled.div`
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #e6f4ff;
    cursor: pointer;
  }
`;

export const DetailsCardsHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 10px;

  .icon {
    display: flex;
    align-items: center;
  }

  .title {
    display: flex;
    align-items: center;
    flex: auto;
    font-weight: bold;
    margin: 0 10px;
  }

  .time {
    display: flex;
    align-items: center;
    font-size: 10px;
  }
`;

export const DetailsCardsContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 10px 10px 10px;

  .description {
    flex: auto;
    font-size: 12px;
  }

  .dot {
    font-size: 20px;
    font-weight: bold;
  }

  .icon {
    margin-left: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    filter: grayscale(100%);
  }

`;

export const FaqsNoFound = styled.div`
  margin: 10px;
`;
