//@flow
import * as React from "react";
import RowD from "../../../shared/component/row-d";
import RadioInput from "../../../shared/controls/radio-input";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Tag from "../../../shared/controls/tag";

const AssembliesAvailabilityRow = (props) => {
  return (
    <React.Fragment>
      <RowD {...props}>
        <RadioInput
          title=""
          onClick={(e) => {
            props.onChange({
              target: {
                name: "selectedAssembly",
                value: { id: props.id, provider: props.fleetProvider.id },
              },
            });
          }}
          selected={props.selectedAssembly}
          value={props.id}
          name="selectedAssembly"
        />
        <React.Fragment>
          <Logo src={props.fleetProvider.logo} alt="" />
          <Text>{props.fleetProvider.name}</Text>
        </React.Fragment>
        <div>
          <Text>{props.truck.name}</Text>
          {props.truck.icon}
        </div>
        <Text style={{ lineHeight: "18px" }}>
          {props.maneuverIcon}
          {props.operationTags}
        </Text>
        {props.orderTag}
        <Text>{props.expClass}</Text>
        <Text
          data-place="right"
          data-tip
          data-for={`contract-${props.id.toString()}`}
        >
          {props.contract ? props.contract.facility?.name : "-"}
        </Text>
        <Text>{props.ticketNumber}</Text>
        <Text>
          {props.availabilityDate
            ? new Date(props.availabilityDate).toLocaleString("es-MX")
            : "-"}
        </Text>
        {props.alert > 0 ? (
          <Marker
            active={props.alert > 0}
            data-place="right"
            data-tip
            data-for={`alert-${props.id.toString()}`}
          >
            {"!"}
          </Marker>
        ) : (
          ""
        )}
      </RowD>
      {props.alert > 0 && (
        <ReactTooltip
          id={`alert-${props.id.toString()}`}
        >{`${props.alert} elemento(s) con alertas`}</ReactTooltip>
      )}
      {props.contract && (
        <ReactTooltip id={`contract-${props.id.toString()}`} type="light">
          {
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <Text>{"Base"}</Text>
                <Tag {...props.contract.base} />
              </div>
              <div style={{ display: "flex" }}>
                <Logo src={props.fleetProvider.logo} alt="" />
                <Text>{props.fleetProvider.name}</Text>
              </div>
            </React.Fragment>
          }
        </ReactTooltip>
      )}
    </React.Fragment>
  );
};

export default AssembliesAvailabilityRow;

const Logo = styled.img`
  width: 27px;
  height: 27px;
  margin-right: 10px;
  border-radius: 50%;
`;

const Text = styled.span`
  color: #484848;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
`;

const Marker = styled.span`
  background-color: ${({ active }) => (active ? "rgb(64,116,137)" : "#A7B6D6")};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 22px;
  color: white;
  font-weight: 900;
`;
