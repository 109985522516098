//@flow
import * as React from "react";
import BaseDialog from "../../../shared/dialog/base-dialog";
import * as SharedTypes from "../../../shared/types";
import { Flex, flexOpts } from "../../../shared/styled/flex";
import styled from "styled-components";
// import SVGIcon from "../../../shared/svg-icon";
// import * as Types from "../types";
// import RadioInput from "../radio-input";
// import SelectInput from "../../../shared/controls/select-input";
import ModalSelectInput from "../modal-select-input";

type Props = {
  show: boolean,
  onChange: (SharedTypes.SimpleEvent) => void,
  data: { logo: string, name: String, trip: string },
  reasons: { id: number, description: string }[],
  reasonSelect: number,
};

const RejectBookingModal = ({
  show,
  onChange,
  data,
  reasons,
  reasonSelect,
}: Props) => {
  return (
    <BaseDialog
      open={show}
      title="Rechazar reservación"
      message="Esta acción quitará este viaje a la linea transportista"
      acceptText="Rechazar"
      cancelText="Cerrar"
      showCancel={true}
      cancelButtonType="outline dark primary"
      acceptButtonType="secondary"
      acceptAction={() =>
        onChange({ target: { name: "rejectBooking", value: true } })
      }
      closeAction={(a) => onChange(a)}
      contentObject={
        <ModalContent
          {...data}
          onChange={onChange}
          reasons={reasons}
          // appointmentAction={appointmentAction}
          reasonSelect={reasonSelect}
        />
      }
      style={{ content: { width: "450px", marginLeft: "-200px" } }}
      //   disabledAccept: PropTypes.bool,
    />
  );
};
export default RejectBookingModal;

const ModalContent = (props) => {
  return (
    <div>
      <Avatar alignItems={flexOpts.alignItems.flexEnd}>
        <Logo src={props.logo} alt="" />
        <Flex direction={flexOpts.direction.column}>
          <Trip>{props.name}</Trip>
          <Trip>{`N° viaje: ${props.trip}`}</Trip>
        </Flex>
      </Avatar>
      <Flex justifyContent={flexOpts.justifyContent.spaceAround}></Flex>
      <Avatar>
        <ModalSelectInput
          isSearchable={false}
          defaultValue={props.reasons[0]}
          onChange={(a) =>
            props.onChange({
              target: { name: "reasonSelect", value: a.value },
            })
          }
          name="reasonSelect"
          options={props.reasons}
        />
      </Avatar>
    </div>
  );
};

// const formatDate = (date: Date) => {
//   const today = new Date(
//     new Date().getFullYear(),
//     new Date().getMonth(),
//     new Date().getDate()
//   );
//   const tomorrow = new Date(
//     new Date().getFullYear(),
//     new Date().getMonth(),
//     new Date().getDate() + 1
//   );
//   date = new Date(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate(),
//     date.getHours(),
//     date.getMinutes()
//   );

//   let result =
//     date.toDateString() === today.toDateString()
//       ? "Hoy, "
//       : date.toDateString() === tomorrow.toDateString()
//       ? "Mañana, "
//       : date.toUTCString().substring(4, 16);

//   result = result.concat(
//     " ",
//     date.toLocaleTimeString([], {
//       hour: "2-digit",
//       minute: "2-digit",
//       hour12: false,
//     }),
//     " hrs"
//   );
//   return result;
// };

const Logo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 17px;
  margin: 5px;
`;

const Trip = styled.span`
  color: #20293d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`;

// const Maneuver = styled.span`
//   color: #20293d;
//   font-size: 12px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 14px;
// `;

// const Appointment = styled.span`
//   color: #20293d;
//   font-size: 14px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 22px;
// `;

// const AppointmentDate = styled(Flex)`
//   color: #2d364c;
//   font-size: 12px;
//   letter-spacing: 0;
//   line-height: 18px;
// `;

const Avatar = styled(Flex)`
  margin: 20px;
`;

// const Icon = styled(SVGIcon)`
//   margin-right: 5px;
// `;
