import React, { Component } from "react";
import Loader from '../icons/tat/loader';

class OptionsList extends Component {

  buildList = () => {

    if (this.props.loading && this.props.visible) {
      return (
        <div
          className='option-list-component'
          style={{
            height: 45, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'white', 
            width: this.props.width || 50,
            position: 'absolute', 
            borderRadius: 5,
          }}
        >
          <Loader circleColor={'black'}/>
        </div>
      );

    } else if ((this.props.items || []).length > 3 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: 'white', width: this.props.width || 50, position: 'absolute', borderRadius: 5, height: 150, overflowY: 'scroll'}}>
          {
            (this.props.items || []).map( item => {
              return(
                <div className='option-list-component'>
                  <div 
                    className='option-list-component'
                    style={{
                      color: 'black', 
                      height: 45, 
                      display: 'flex', 
                      alignItems: 'center', 
                      flexDirection: 'column', 
                      fontSize: 15, 
                      justifyContent: 'center', 
                      fontWeight: 'normal', 
                      whiteSpace: 'normal', 
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.props.onClick(item)}
                  >
                    {item}
                  </div>
                  <div style={{height: 0.5, backgroundColor: 'black', width: '100%'}}/>
                </div>
              );
            })
          }
        </div>
      );
    } else if ((this.props.items || []).length > 0 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: 'white', width: this.props.width || 50, position: 'absolute', borderRadius: 5}}>
          {
            (this.props.items || []).map( item => {
              return(
                <div className='option-list-component'>
                  <div 
                    className='option-list-component'
                    style={{
                      color: 'black', 
                      height: 45, 
                      display: 'flex', 
                      alignItems: 'center', 
                      flexDirection: 'column', 
                      fontSize: 15, 
                      justifyContent: 'center', 
                      fontWeight: 'normal', 
                      whiteSpace: 'normal', 
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.props.onClick(item)}
                  >
                    {item}
                  </div>
                  <div style={{height: 0.5, backgroundColor: 'black', width: '100%'}}/>
                </div>
              );
            })
          }
        </div>
      );
    } else {
      return (<div/>);
    }

  }

  render() {
    return (
      this.buildList()
    );
  }
}
export default OptionsList;
