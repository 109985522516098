import moment from "moment";
import React from "react";
import { connect } from 'react-redux';
import { cancelRequest, getEntityItems, readEntities } from "../../redux/api";
// import TableD from "../../shared/component/table-d";
// import PaginatorControlled from "../../shared/controls/paginator-controlled";
// import PaginatorControlled from "../../shared/controls/paginator-controlled";
import { View, ViewTitle } from "../../shared/styled/view";
import TableSortable from '../../shared/component/table-sortable/table-sortable';
import { DownloadIcon } from "../../company/routes/style";
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import Loader from "../../shared/icons/tat/loader";
import * as XLSX from 'xlsx/xlsx.mjs';
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { removeNavbarAction, setNavbarAction } from '../../redux/reducers/navbar-reducer';

class TransactionDetailView extends React.Component {
  state = {
    isLoading: false,
    limit: 10,
    offset: 1,
    columnsData: [],
    serviceName: '',
    loading: false
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // debugger;
    let service = urlParams.get('service');
    this.setState({serviceName: service, loading: true});
    this.props.setNavbarAction("services-report-transaction", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

    this.props.loadTransactionInfo({
      company_id: this.props.companyId,
      bridge_name: service || 0,
      limit: this.state.limit,
      offset: this.state.offset,
      transaction_id: this.props.match?.params?.transactionId || 0
    }, {
      onSuccess: (response) => {
        // debugger;
        if(!response.length || (Array.isArray(response[0].data) && !response[0].data?.length) || (response[0].data === '')) {
          this.setState({loading: false})
          return
        }
        let sampleData = {};
        if(typeof response[0].data === 'object' &&
          !Array.isArray(response[0].data) &&
          response[0].data !== null
        ){
          sampleData = response[0].data;
        } else {
          sampleData = response[0].data[0];
        }
        let objectProperties = Object.entries(sampleData);
        let columns = objectProperties.map(property => {
          return {
            key: property[0],
            title: property[0],
            isArray: Array.isArray(property[1]),
            isObject: (typeof property[1] === 'object' &&
            !Array.isArray(property[1]) &&
            property[1] !== null)
          }
        });
        // debugger;
        this.setState({columnsData: columns, loading: false})
      }
    })
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("services-report-transaction");
  }

  downloadFile = (e) => {
    e.stopPropagation();
    const jsonData = JSON.stringify(this.props.serviceRows || []);
    const file = new Blob([jsonData], { type: "text/plain" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(file));
    link.setAttribute("download", this.props.serviceInfo?.file || "data.json");
    link.click();
  }

  downloadXlsx = (e) => {
    e.stopPropagation();
    let filename= (this.props.serviceInfo?.file || 'data') + '.xlsx';
    let data = this.props.serviceRows || [];
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    XLSX.writeFile(wb,filename);
  }

  render() {
    return(
      <View className="web-service-report-view">
        <ViewTitle>
          <div>
            {this.state.serviceName} 
            <span style={{marginLeft: "40px"}}>{moment(this.props.serviceInfo?.created).format('DD/MM/YYYY HH:mm:ss (utc Z)')}</span>
            <br/>
            <span style={{fontSize: "16px", lineHeight: "16px"}}>
              {this.props.serviceInfo?.file}
            </span>
          </div>
          <div style={{display: "flex"}}>
            <div style={{cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center"}}>
              <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                onClick={this.downloadFile}
              />
              <div style={{fontSize: "12px", lineHeight: "12px"}}>JSON</div>
            </div>
            <div style={{cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center"}}>
              <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                onClick={this.downloadXlsx}
              />
              <div style={{fontSize: "12px", lineHeight: "12px"}}>XLSX</div>
            </div>
          </div>
        </ViewTitle>
        <div className="content">
          {
            this.state.loading 
            ? <div style={{height: "300px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader height="100px" width="100px"/>
              </div>
            : <TableSortable
                isLoading={this.state.isLoading}
                itemsToHide={[]}
                didSelectRow={(data) => {
                }}
                columns={this.state.columnsData}
                items={(this.props.serviceRows || [])}
              />
          }
          {/* <PaginatorControlled
            itemCount={this.props.serviceItemCount || 0}
            limit={this.state.limit}
            offset={this.state.offset}
            onChange={(e) =>
              this.setState({ [e.target.name]: e.target.value }, () =>{
                
                // if (this.state.getTripsCalled === true) {
                  // console.log("SEARCHING-FILTER-PAGINATOR");
                  // this.loadTrips();
                // }
              })
            }
          /> */}
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  let companyId = state.globalCatalog.session.company.id;
  // let serviceItemCount = DUMMY.length;
  // let serviceRows = DUMMY;
  let serviceRows = getEntityItems(state, "COMPANIES.BRIDGE.TRANSACTIONS");
  serviceRows = parseServiceRows(serviceRows);
  let serviceItemCount = serviceRows.length;
  return {
    companyId,
    serviceItemCount,
    serviceRows,
    serviceInfo: state.configuration.ServiceDetailConfig,
  };
}

const mapDispatchToProps = (dispatch) => {
  
  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadTransactionInfo: (params, opt) => {
      dispatch(
        readEntities("COMPANIES.BRIDGE.TRANSACTIONS", params, opt) 
      );
    },
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  }

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDetailView);

const parseServiceRows = (data) => {
  if(!data.length) return [];
  if(typeof data[0].data === 'object' &&
  !Array.isArray(data[0].data) &&
  data[0].data !== null) return [data[0].data];
  return data[0].data || [];
}