import React from "react";
import MenuCategory from "./menu-category";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {
  loadSession,
  getRequest,
  removeToast,
  validateObjPerms
  // loadPermissionsUser
} from "../../redux/reducers/global-catalog.reducer";
import { getUrlsEnv } from "../backend-api";
import SocketClient from "../sockets";
import MenuAlerts from "../../control/alerts/alerts";
import MenuFaqs from "../../control/faqs/faqs";
import { readEntities, getEntityItems, readEntity/* partialEntity, createEntity, deleteEntity */ } from '../../redux/api';
import { ToastContainer, toast } from 'react-toastify';
import { setConfig } from "../../redux/reducers/configuration-reducer";
import SocketIO from "../socket-io";
import store from "../../redux/store";
import { isPremiumSubscriptionCo, isValidationCompanyNotified, setValidationCompanyNotified } from "../utils";
import ImageWithCheckMark from "../component/image-with-check-mark";
import GenericDialog from '../dialog/generic-modal';


// TODO: usar el router para salir de la sesión, evitar el uso de anchors <a>
// TODO: Solicitar una confirmacion antes de salir
// TODO: limpiar el storage de sesion al salir

const ORBI_SESSION = "orbinetwork_session"; 

class Menu extends React.Component {
  hasSession = false;
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      userData: JSON.parse(sessionStorage.getItem(ORBI_SESSION)).user,
      companyData: JSON.parse(sessionStorage.getItem(ORBI_SESSION)).company,
      subscription: "",
      showAlerts: false,
      showFaqs: false,
      alert_counter: 0,
      toast_notification:false,
      toast_message: "",
      notifications_counter: 0,
      verificationStatus: null,
      showPendingInfo: false
    };
    
    this.showToastCompanyVerificationNotVerified = (action, pending_action) => {
      toast.success(
        <div>
          <div>
            {'Tu perfil de empresa no esta verificado.'}
            <span
              style={{textDecoration: 'underline', marginLeft: 10}}
              onClick={() => {
                if (action) {
                  action()
                }
                toast.dismiss()
              }}
            >{'Verificalo aquí'}</span>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 'fit-content',
              marginTop: 12
            }}
          >
            <img
              alt=""
              src="/images/help-icon.png"
              style={{ width: 15, height: 15, marginRight: 8 }}
            />
            <span
              style={{textDecoration: 'underline', fontSize: 12}}
              onClick={() => {
                if (pending_action) {
                  pending_action();
                }
              }}
            >{'¿para que debo verificar mi perfil de empresa?'}</span>
          </div>
          
        </div>,
        {
          autoClose: false,
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class-pending",
        }
      );
    }

    this.showToastCompanyVerificationPending = () => {
      toast.success(
        <div>
          <span>{'El perfil de tu empresa esta en proceso de verificación.'}</span>
        </div>,
        {
          autoClose: false,
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
        }
      );
    };

    this.showToastCompanyVerificationRejected = (action) => {
      toast.success(
        <div>
          <span>{'La solicitud de verificación de tu perfil de empresa fue rechazada.'}</span>
          <span
              style={{textDecoration: 'underline', marginLeft: 10}}
              onClick={() => {
                if (action) {
                  action()
                }
                toast.dismiss()
              }}
            >{'Inténtalo de nuevo aquí'}</span>
        </div>,
        {
          autoClose: false,
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class-rejected",
        }
      );
    };

    this.showToast = (text, action) => {
      toast.success(
        <div>
          <img
            alt=""
            src="/images/white-check.svg"
            style={{ marginRight: "8px", marginLeft: "4px" }}
          />
          {text}
        </div>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          className: "my-toast-class",
          onClick: () => {
            if (action) {
              action();
            }
          }
        }
      );
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentGracefulUnmount);
    document.addEventListener("mousedown", this.handleClick, false);
    document.addEventListener('user-update', this.handleSocketMessage, false);
    this.checkWarehouserUser();
    this.loadSession();
    if(this.state.companyData && this.state.companyData.companyuser){
      //debugger;
      this.props.loadCompanyFaqs(this.state.companyData.id);
      this.props.loadAlertCounter(this.state.companyData.id,this.state.companyData.companyuser.id,"alert_counter", {
        onSuccess: (r) => {
          this.setState({alertCounter: this.state.alertCounter + r.alert_counter});
        }
      });
    }
    // debugger;
  }

  validateVerificationInfo = () => {
    if (this.state.companyData && this.state.companyData.companyuser) {
      this.props.getCompany({
        company_id: this.state.companyData.id
      }, {
        onSuccess: (r) => {
          this.setState({
            verificationStatus: r.verification_status
          });

          // Se valida si el usuario tiene permisos para editar perfil de la compañia
          let response = this.props.validateObjPerms(
            [
              {
                id: "10",
                perm: "u"
              }
            ], 
            {
              display: true,
            }
          );

          let is_notified = isValidationCompanyNotified(this.state.companyData.id, r.verification_status);

          if (Object.keys(response).length && !is_notified) {

            if (r.verification_status === 0) {
              this.showToastCompanyVerificationNotVerified(() => {
                this.props.history.push(`/company/data/${this.state.companyData.orbi_id}`);
              },
              () => {
                this.setState({showPendingInfo: true});
              });
            } else if (r.verification_status === 1) {
              this.showToastCompanyVerificationPending()
            } else if (r.verification_status === 2) {
              this.showToastCompanyVerificationRejected(() => {
                this.props.history.push(`/company/data/${this.state.companyData.orbi_id}`);
              });
            }

            setValidationCompanyNotified(this.state.companyData.id, r.verification_status);

          }
        }
      });
    }
  }

  handleSocketMessage = (e) => {

    if (e.detail && (e.detail.notification_id === 46 || e.detail.notification_id === 47)) {
      this.refreshNotificationsBadge();
      
      if (e.detail.notification_id && e.detail.message) {
        this.showToast(e.detail.message);
        this.props.removeToast();
      }
    } else if (e.detail && e.detail.notification_id === 45) {
      this.refreshNotificationsBadge();

      if (e.detail.code) {
        this.showToast(`Fuiste mencionado en un cambio de cita en la orden ${e.detail.code}`);
        this.props.removeToast();
      }
    }
  }

  componentGracefulUnmount = () => {
    SocketIO.disconnect();
    document.removeEventListener('user-update', this.handleSocketMessage, false);
    window.removeEventListener('beforeunload', this.componentGracefulUnmount);
  }

  increaseCounter = (response) => {
    this.setState({alert_counter: this.state.alert_counter + 1});
    this.setState({toast_notification: true, toast_message:response.data.message});
    // debugger;
  }

  componentDidUpdate() {
    if (
      this.props.subscription !== "" &&
      this.props.subscription !== this.state.subscription
    ) {
      this.setState({
        subscription: this.props.subscription,
      });

      let user = store.getState().globalCatalog.session.user.id;
      let company = store.getState().globalCatalog.session.company.id;
      SocketIO.disconnect();
      SocketIO.connect(`${user}-${company}`);

      this.refreshNotificationsBadge();
      this.validateVerificationInfo();
      
      //let sc = new SocketClient();
      // sc.connect(this.props.subscription);
      //sc.connect(this.props.subscription, this.increaseCounter);
      // debugger;
    }
  }

  refreshNotificationsBadge = () => {
    this.props.getNotificationsCounter({
      company_id: this.props.companyId,
      view: 'counter',
      user_id: this.props.userId
    }, {
      onSuccess: r => {
        this.setState({
          notifications_counter: r.un_read || 0
        })
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('user-update', this.handleSocketMessage, false);
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = (e) => {
    if (e.target.id === "tooltipLink") {
      window.location.href = "/register/company";
      if (this.state.menuOpen !== false) {
        this.setState({ menuOpen: false });
      }
      return;
    }
    if (this.node.contains(e.target)) return;
    if (this.state.menuOpen !== false) {
      this.setState({ menuOpen: false });
    }
  };

  openMenu = () => {
    this.setState({ menuOpen: true });
  };

  getCompanyLogo = () => {
    if (this.state.companyData.logo) {
      return (
        getUrlsEnv().files.concat(this.state.companyData.logo, '')
      );
    } else if (this.state.companyData.type) {
      return "/".concat(this.state.companyData.type.avatar_image);
    } else {
      return "/images/logo-mini-menu-white.svg";
    }
  };

  readNotifications=() => {
    if(!this.menuValidation()){
      this.setState({alert_counter: 0});
    }
  }

  closeSession = () => {
    sessionStorage.clear();
    window.location.href = "/login.html";
  };
  loadSession() {
    if (
      !this.state.companyData.id &&
      !this.hasSession &&
      JSON.parse(sessionStorage.getItem(ORBI_SESSION)).company.id > 0
    ) {
      this.setState({
        userData: JSON.parse(sessionStorage.getItem(ORBI_SESSION)).user,
        companyData: JSON.parse(sessionStorage.getItem(ORBI_SESSION)).company,
      });
      this.hasSession = true;
    }
  }

  menuValidation = () => {
    const {
      companyData: { id = -1 },
    } = this.state;
    if (id === -1) {
      return true;
    }

    return this.props.inRegister || id === 0 || !this.props.verificatedEmail;
  };

  getMenu = (userData, companyData, facilityData) => {
    switch (companyData.type.id) {
      case 1:
        return MENU_LT(userData, this.props);
      default:
        switch (this.props.userType) {
          case "Warehouser":
            return MENU_WAREHOUSE(userData, facilityData);
          default:
            return this.props.isPremiumSubscriptionCo ? MENU_OWNER(userData, companyData) : MENU_OWNER_FREE(userData, companyData);
        }
    }
  };

  checkWarehouserUser = () => {
    // console.log(
    //   this.props.userType,
    //   Object.keys(this.props.facilityData).length
    // );
    if (
      (this.props.userType === "Warehouser" &&
        Object.keys(this.props.facilityData || {}).length) === 0
    ) {
      this.props.history.push("/facility/select");
    }
  };

  showAlerts = () => {

    if (!this.state.showAlerts === true) {
      this.validateVerificationInfo();
    }

    this.setState({
      showAlerts: !this.state.showAlerts,
      showFaqs: false,
    });
  };

  showFaqs = () => {
    this.setState({
      showFaqs: !this.state.showFaqs,
      showAlerts: false,
    });
  };

  render() {
    // console.log(this.props.userType);
    // let alert_notification_counter = this.props.alert_counter_back.alert_counter? this.state.alert_counter+this.props.alert_counter_back.alert_counter : this.state.alert_counter
    return (
      <React.Fragment>
        {this.state.toast_notification? toast.success(
          <div>
            <img
              alt=""
              src="/images/white-check.svg"
              style={{ marginRight: "8px", marginLeft: "4px" }}
            />
            {this.state.toast_message}
          </div>,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: "my-toast-class",
          }
        ):true}
        <ToastContainer  className="global-toast-container"  />
        {this.state.toast_notification?(this.setState({ toast_notification: false})):true}
        <div
          className={"menu " + (this.state.menuOpen ? "open" : "closed")}
          ref={(node) => (this.node = node)}
        >
          <div ref="logo" className="logo" onClick={this.openMenu}>
            <div className="logo-2">
              <ImageWithCheckMark
                src={this.getCompanyLogo()}
                isCheckMark={this.props.isCompanyVerified}
                customStyle={{
                  width: 40, height: 40, borderRadius: '50%', position: 'relative', marginRight: 10
                }}
              />
              {this.state.menuOpen ? (
                <>
                  <div className="company-name">
                    {this.props.verificatedEmail ? (
                      <Link
                        to={`/company/profile/${this.state.companyData?.orbi_id ?? ''}`}
                      >
                        <span>{this.state.companyData.orbi_id}</span>
                      </Link>
                    ) : (
                      <span>{this.state.companyData.orbi_id}</span>
                    )}
                  </div>
                  <div
                    className="actions"
                    data-tip
                    data-for={"tooltip-company-selection"}
                  >
                    <div className="ellipsis">
                      <b></b>
                    </div>
                  </div>
                  <ReactTooltip
                    id={"tooltip-company-selection"}
                    clickable={true}
                    event="click"
                    globalEventOff="click"
                    delayHide={200}
                    delayShow={200}
                    delayUpdate={200}
                    place={"right"}
                    border={true}
                    type={"light"}
                  >
                    {this.props.companiesList.length ? (
                      <div className="margin-bottom">
                        <Link to={"/company/select"}>
                          <span className="change-company">
                            Cambiar de compañía
                          </span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.userData.first_name.length > 0 &&
                      this.props.userData.last_name.length > 0 && (
                        <Link to={"/register/company"}>
                          <span className="change-company" id="tooltipLink">
                            Crear nueva compañía
                          </span>
                        </Link>
                      )}
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="content">
            {/* {this.loadSession()} */}
            {/* {console.log(this.props.facilityData)} */}
            <MenuCategory
              menuobj={
                this.menuValidation()
                  ? MENU_REGISTER(this.props.userData)
                  : this.getMenu( 
                      this.props.userData,
                      this.state.companyData,
                      this.props.facilityData
                    )
              } 
              openMenu={this.openMenu}
              isMenuOpen={this.state.menuOpen}
              permissions={this.props.permissions}
            />
          </div>
          <div style={{ textDecoration: "none" }}>
            <div
              ref={node => (this.helpButton = node)}
              className="logOut"
              onClick={() => {
                const win = window.open(
                  "https://wa.me/+523313402015",
                  "_blank"
                );
                if (win != null) {
                  win.focus();
                }
              }}
            >
              <img
                src="/images/menu/whatsapp_icon.png"
                alt=""
                style={{height: 24, minHeight: 24, width: 24}}
                className="icon"
              />
              {this.state.menuOpen && (
                <div className="text">Soporte</div>
              )}
            </div>
          </div>
          <div style={{ textDecoration: "none" }}>
            <div
              ref={node => (this.helpButton = node)}
              className="logOut"
              onClick={() => {
                this.showFaqs();
              }}
            >
              <img
                src="/images/menu/Help.png"
                alt=""
                style={{height: 24, minHeight: 24, width: 24}}
                className="icon"
              />
              {this.state.menuOpen && (
                <div className="text">FAQ's</div>
              )}
            </div>
          </div>
          <div style={{ textDecoration: "none" }}>
            {
              !this.menuValidation() &&
              <div
                ref={node => (this.alertsButton = node)}
                className="logOut"
                onClick={() => {
                  this.showAlerts();
                }}
              >
                <div className="notification" onClick={this.readNotifications}>
                  <span><img
                    src="/images/notification-bell.svg"
                    alt=""
                    width="24"
                    className="icon"
                  /></span>
                  {
                    this.state.notifications_counter > 0 ?
                    <div
                      style={{
                        display: 'flex',
                        align: 'center',
                        alignItems: 'center',
                        backgroundColor: 'red',
                        color: 'white',
                        position: 'absolute',
                        top: 8,
                        right: -10,
                        width: 22,
                        height: 22,
                        borderRadius: '50%',
                        justifyContent: 'center'
                      }}
                    >
                      {this.state.notifications_counter}
                    </div>:
                    <span className></span>
                  }
                </div>
                {this.state.menuOpen && (
                  <div className="text" onClick={this.readNotifications}>Notificaciones</div>
                )}
              </div>
            }
            <div className="logOut" onClick={this.closeSession}>
              <img
                src="/images/logout-icon.svg"
                alt=""
                width="24"
                className="icon"
              />
              {this.state.menuOpen && <div className="text">Cerrar sesión</div>}
            </div>
          </div>
        </div>
        <MenuFaqs
          show={this.state.showFaqs}
          faqs={this.props.faqs}
          open={this.state.menuOpen}
          closeClick={this.showFaqs}
          helpButton={this.helpButton}
        />
        <MenuAlerts
          show={this.state.showAlerts}
          open={this.state.menuOpen}
          closeClick={this.showAlerts}
          // alert_details={this.props.alert_details}
          history={this.props.history}
          helpButton={this.alertsButton}
        />
        <GenericDialog
          open={this.state.showPendingInfo}
          title={'Verificación de empresa'}
          message={
            <>
              <div style={{marginBottom: 15}}>{'Verificar tu empresa es necesario para:'}</div>
              <div style={{marginBottom: 8}}>{'1. Para que otras empresas y usuarios te identifiquen como la cuenta oficial de tu empresa.'}</div>
              <div style={{marginBottom: 8}}>{'2. Para habilitar la forma de pago de transferencias interbancarias referenciadas por medio de una cuenta clabe exclusiva para tu empresa.'}</div>
              <div style={{marginBottom: 8}}>{'3. Al verificar tu empresa todos los usuarios que te enviaron recomendaciones recibiran beneficios y descuentos.'}</div>
            </>
          }
          acceptAction={() => this.setState({showPendingInfo: false})}
          closeAction={() => this.setState({showPendingInfo: false})}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let storage = JSON.parse(
    window.sessionStorage.getItem("orbinetwork_session") || {}
  );

  const companiesList = storage.listCompanies || [];

  let userType = ((storage.company || {}).companyuser || {}).type || "";
  let facilityData = storage.facility;
  let faqs = getEntityItems(state, "COMPANIES.CATALOGS.FAQS");
  let company_info = getEntityItems(state, "COMPANIES.RETRIEVE");
  // let alert_counter_back = getEntityItems(state, "COMPANIES.CATALOGS.ALERTCOUNTER");

  return {
    faqs,
    // alert_counter_back,
    userData: state.globalCatalog.session.user,
    companiesList,
    requestCompany: state.globalCatalog.requestCompany,
    verificatedEmail: state.globalCatalog.session.verified_email,
    subscription:
      ((state.globalCatalog.session.company || "").companyuser || "")
        .subscription || "",
    userType,
    facilityData,
    companyId: state.globalCatalog?.session?.company?.id ?? 0,
    userId: state.globalCatalog?.session?.user?.id ?? 0,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session),
    isCompanyVerified: company_info.verification_status === 3
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  dispatch(getRequest());
  return {
    setBaseConfig: (alias, config) => dispatch(setConfig(alias, config)),
    loadCompanyFaqs: (company_id) => {
      dispatch(
        readEntities("COMPANIES.CATALOGS.FAQS", { company_id })
      );
    },
    loadAlertCounter: (company_id,user_company,action, opt) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.ALERTCOUNTER", { company_id, user_company,action }, opt)
      );
    },
    removeToast: () => dispatch(removeToast()),
    getNotificationsCounter: (params, opt) => dispatch(readEntities("COMPANIES.NOTIFICATIONS.CONTROL.COUNTER", params, opt)),
    getCompany: (params, opt) => dispatch(readEntity("COMPANIES.RETRIEVE", params, opt)),
    validateObjPerms: (perms, obj, evalPerm) => validateObjPerms(perms, obj, evalPerm)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

const MENU_REGISTER = (userData) => [
  {
    id: 0,
    title:
      (userData.first_name || "").concat(" " + userData.last_name).trim() ||
      "Mi perfil",
    icon: {
      url: userData.profile_image
        ? getUrlsEnv().files.concat(validate_profile_image_url(userData.profile_image))
        : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: userData.profile_image ? "user-avatar" : "",
    },
    submenu: [],
  },
];

const MENU_OWNER = (userData) => [
  {
    title: (userData.first_name || "").concat(" " + userData.last_name),
    // link: '/perfilPersonal.html?userid='.concat(userData.id),
    link: "/user/profile",
    icon: {
      url: userData.profile_image
        ? getUrlsEnv().files.concat(validate_profile_image_url(userData.profile_image))
        : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: userData.profile_image ? "user-avatar" : "",
    },
    submenu: [],
  },
  {
    title: "Configuraciones",
    icon: {
      url: "/images/menu/gear.svg",
      width: "20px",
    },
    link: "/configurations",
    submenu: [
      {
        // link: "/catalogoUsuarios.html",
        perms: [
          {
            id: "19",
            perm: "r",
          },
        ],
        link: "/company/user",
        title: "Usuarios",
      },
      {
        perms: [
          {
            id: "19",
            perm: "r",
          },
        ],
        link: "/company/products",
        title: "Productos",
      },
      {
        perms: [
          {
            id: "27",
            perm: "r",
          },
        ],
        link: "/company/facility",
        title: "Instalaciones",
      },
      {
        perms: [
          {
            id: "35",
            perm: "r",
          },
        ],
        link: "/company/fleet/summary",
        title: "Flotilla",
      },
      {
        perms: [
          {
            id: "39",
            perm: "r",
          },
        ],
        link: "/company/network",
        title: "Red",
      },
      {
        link: "/company/routes",
        title: "Rutas",
      },
    ],
  },
  {
    title: "Controles",
    icon: {
      url: "/images/menu/truck.svg",
      width: "24px",
    },
    link: "/command",
    submenu: [
      // {
      //   perms: [],
      //   link: "/company/controls/assemble",
      //   title: "Ensambles",
      // },
      {
        perms: [ 
          {
            id: "61",
            perm: "r",
          }
        ],
        link: "/company/controls/shipment/summary",
        title: "Embarques",
      },
      {
        perms: [],
        link: "/company/controls/traffic",
        title: "Tráfico",
      },
      // {
      //   perms: [],
      //   // link: "#",
      //   link: "/company/controls/gps",
      //   title: "GPS",
      // },
      {
        perms: [],
        link: "/company/controls/facility",
        title: "Instalaciones",
      },
      {
        perms: [
          {
            id: "53",
            perm: "r",
          },
        ],
        link: "/company/controls/contract",
        title: "Contratos",
      },
      {
        perms: [],
        link: "/company/controls/orders",
        title: "Órdenes",
      },
      // {
      //   perms: [],
      //   link: "#",
      //   title: "Flotilla",
      // },
      // {
      //   perms: [],
      //   // link: "/company/control/resources",
      //   link: "#",
      //   title: "Recursos",
      // },
    ],
  },
  {
    title: "Reportes",
    link: "#",
    icon: {
      url: "/images/reports-icon.svg",
      width: "24px",
    },
    submenu: [
      {
        perms: [],
        link: "/company/reports/tat",
        // link: "#",
        title: "TAT",
      },
      {
        perms: [],
        link: "/company/reports/utilization",
        // link: "#",
        title: "Utilización",
      },
      {
        perms: [],
        link: "/company/reports/alerts",
        // link: "#",
        title: "Alertas",
      },
      {
       perms: [],
       link: "/company/reports/equipments",
        // link: "#",
       title: "Remolques",
      },
      {
       perms: [],
       link: "/company/reports/availability",
        // link: "#",
       title: "Disponibilidad",
      },
      {
        perms: [],
        // link: "#",
        // link: "/company/controls/kpi",
        link: "/company/reports/indicators",
        title: "KPIs",
      },
      {
        perms: [],
        link: "/company/reports/platesActivity",
        title: "Actividad de GPS",
      },
      // {
      // perms: [],
      // link: "/company/reports/indicators",
      //   // link: "#",
      // title: "Mapa de indicadores",
      // },
      {
        perms: [],
        link: "/company/reports/services",
         // link: "#",
        title: "Servicios web",
       }
    ],
  },
];

const MENU_OWNER_FREE = (userData) => [
  {
    title: (userData.first_name || "").concat(" " + userData.last_name),
    // link: '/perfilPersonal.html?userid='.concat(userData.id),
    link: "/user/profile",
    icon: {
      url: userData.profile_image
        ? getUrlsEnv().files.concat(validate_profile_image_url(userData.profile_image))
        : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: userData.profile_image ? "user-avatar" : "",
    },
    submenu: [],
  },
  {
    title: "Configuraciones",
    icon: {
      url: "/images/menu/gear.svg",
      width: "20px",
    },
    link: "/configurations",
    submenu: [
      {
        // link: "/catalogoUsuarios.html",
        perms: [
          {
            id: "19",
            perm: "r",
          },
        ],
        link: "/company/user",
        title: "Usuarios",
      },
      {
        perms: [
          {
            id: "19",
            perm: "r",
          },
        ],
        link: "/company/products",
        title: "Productos",
      },
      {
        perms: [
          {
            id: "27",
            perm: "r",
          },
        ],
        link: "/company/facility",
        title: "Instalaciones",
      },
      {
        perms: [
          {
            id: "39",
            perm: "r",
          },
        ],
        link: "/company/network",
        title: "Red",
      },
      {
        link: "/company/routes",
        title: "Rutas",
      },
    ],
  },
  {
    title: "Controles",
    icon: {
      url: "/images/menu/truck.svg",
      width: "24px",
    },
    link: "/command",
    submenu: [
      {
        perms: [ 
          {
            id: "61",
            perm: "r",
          }
        ],
        link: "/company/controls/shipment/summary",
        title: "Embarques",
      },
      {
        perms: [],
        link: "/company/controls/traffic",
        title: "Tráfico",
      }
    ],
  }
];

const validate_profile_image_url = (profile_image) => {

  if (typeof profile_image === 'string' && profile_image.length > 0 && profile_image[0] === '/') {
    return profile_image.substring(1);
  } else {
    return profile_image;
  }

}

const MENU_LT = (userData, props) => [
  {
    title: (userData.first_name || "").concat(" " + userData.last_name),
    // link: '/perfilPersonal.html?userid='.concat(userData.id),
    link: "/user/profile",
    icon: {
      url: userData.profile_image
        ? getUrlsEnv().files.concat(validate_profile_image_url(userData.profile_image))
        : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: userData.profile_image ? "user-avatar" : "",
    },
    submenu: [],
  },
  {
    title: "Configuraciones", 
    icon: {
      url: "/images/menu/gear.svg",
      width: "20px",
    },
    link: "/configurations",
    submenu: [
      {
        // link: "/catalogoUsuarios.html",
        perms: [
          {
            id: "19",
            perm: "r",
          },
        ],
        link: "/company/user",
        title: "Usuarios",
      },
      {
        perms: [
          {
            id: "27",
            perm: "r",
          },
        ],
        link: "/company/facility",
        title: "Instalaciones",
      },
      {
        perms: [
          {
            id: "35",
            perm: "r",
          },
        ],
        link: "/company/fleet/summary",
        title: "Flotilla",
      },
      {
        perms: [
          {
            id: "39",
            perm: "r",
          },
        ],
        link: "/company/network",
        title: "Red",
      },
      {
        link: "/company/routes",
        title: "Rutas",
      },
    ],
  },
  {
    title: "Controles",
    icon: {
      url: "/images/menu/truck.svg",
      width: "24px",
    },
    link: "/command",
    submenu: [
      {
        perms: [
          {
            id: "56",
            perm: "r",
          },
        ],
        link: "/company/controls/assemble",
        title: "Ensambles",
      },
      {
        perms: [],
        link: "/company/controls/shipment/assemble/summary",
        // link: "/company/controls/shipment/assemble",
        title: "Embarques",
        onClick: () => {
          // props.setBaseConfig("AssembleShipmentList", {});
          // props.setBaseConfig("AssembleShipmentView", null);
        }
      },
      // {
      //   perms: [],
      //   // link: "#",
      //   link: "/company/controls/gps",
      //   title: "GPS",
      // },
      {
        perms: [
          {
            id: "54",
            perm: "r",
          },
          {
            id: "55",
            perm: "r",
          },
        ],
        link: "/company/controls/contract",
        title: "Contratos",
      },
      // {
      //   perms: [],
      //   link: "#",
      //   title: "Flotilla",
      // },
      {
        perms: [],
        link: "/company/control/resources",
        // link: "#",
        title: "Recursos",
      },
    ],
  },
  {
    title: "Reportes",
    link: "#",
    icon: {
      url: "/images/reports-icon.svg",
      width: "24px",
    },
    submenu: [
      {
        perms: [],
        link: "/company/reports/tat",
        // link: "#",
        title: "TAT",
      },
      {
        perms: [],
        link: "/company/reports/utilization",
        // link: "#",
        title: "Utilización",
      },
      {
       perms: [],
       link: "/company/reports/equipments",
        // link: "#",
       title: "Remolques",
      },
      {
        perms: [],
        link: "/company/reports/availability",
         // link: "#",
        title: "Disponibilidad",
      },
      {
        perms: [],
        link: "/company/reports/platesActivity",
        title: "Actividad de GPS",
      },
       {
         perms: [],
         link: "/company/controls/billing",
          // link: "#",
         title: "Facturaciòn",
        },
      //  {
      //   perms: [],
      //   link: "/company/reports/indicators",
      //     // link: "#",
      //   title: "Mapa de indicadores",
      //   },
      {
        perms: [],
        link: "/company/reports/services",
         // link: "#",
        title: "Servicios web",
       },
       {
        perms: [],
        link: "/company/reports/businessIntelligence",
        title: "BI",
      },
    ],
  },
];

const MENU_WAREHOUSE = (userData, facilityData) => [
  {
    title: facilityData.name || "",
    link:
      Object.keys(facilityData).length > 0
        ? "/company/facility/profile/" + facilityData.id
        : "#",
    icon: {
      url:
        Object.keys(facilityData).length > 0 && facilityData.avatar.image
          ? getUrlsEnv().files.concat(validate_profile_image_url(facilityData.avatar.image))
          : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: "",
    },
    submenu: [],
    isIcon: Object.keys(facilityData).length > 0 ? true : false,
    ellipsis: Object.keys(facilityData).length > 0 ? true : false,
  },
  {
    title: (userData.first_name || "").concat(" " + userData.last_name),
    // link: '/perfilPersonal.html?userid='.concat(userData.id),
    link: "/user/profile",
    icon: {
      url: userData.profile_image
        ? getUrlsEnv().files.concat(validate_profile_image_url(userData.profile_image)) 
        : "/images/menu/user-no-circle.svg",
      width: userData.profile_image ? "30px" : "24px",
      class: userData.profile_image ? "user-avatar" : "",
    },
    submenu: [],
  },
  // {
  //   title: "Configuraciones",
  //   icon: {
  //     url: "/images/menu/gear.svg",
  //     width: "20px",
  //   },
  //   submenu: [
  //     {
  //       // link: "/catalogoUsuarios.html",
  //       perms: [
  //         {
  //           id: "19",
  //           perm: "r",
  //         },
  //       ],
  //       link: "/company/user",
  //       title: "Usuarios",
  //     },
  //     {
  //       perms: [
  //         {
  //           id: "27",
  //           perm: "r",
  //         },
  //       ],
  //       link: "/company/facility",
  //       title: "Instalaciones",
  //     },
  //     {
  //       perms: [
  //         {
  //           id: "35",
  //           perm: "r",
  //         },
  //       ],
  //       link: "/company/fleet/summary",
  //       title: "Flotilla",
  //     },
  //     {
  //       perms: [
  //         {
  //           id: "39",
  //           perm: "r",
  //         },
  //       ],
  //       link: "/company/network",
  //       title: "Red",
  //     },
  //   ],
  // },
  {
    title: "Controles",
    icon: {
      url: "/images/menu/truck.svg",
      width: "24px",
    },
    link: "/command",
    submenu: [
      // {
      //   perms: [],
      //   link: "/company/controls/assemble",
      //   title: "Ensambles",
      // },
      {
        perms: [],
        link: "/company/controls/shipment/summary",
        title: "Embarques",
      },
      {
        perms: [],
        link: "#",
        title: "Tráfico",
      },
      // {
      //   perms: [],
      //   link: "#",
      //   title: "GPS",
      // },
      {
        perms: [],
        link: "/company/controls/facility",
        title: "Instalaciones",
      },
      {
        perms: [
          {
            id: "53",
            perm: "r",
          },
        ],
        link: "/company/controls/contract",
        title: "Contratos",
      },
      // {
      //   perms: [],
      //   link: "#",
      //   title: "Flotilla",
      // },
      // {
      //   perms: [],
      //   // link: "/company/control/resources",
      //   link: "#",
      //   title: "Recursos",
      // },
    ],
  },
  {
    title: "Reportes",
    link: "#",
    icon: {
      url: "/images/reports-icon.svg",
      width: "24px",
    },
    submenu: [],
  },
];

// const MENULT = (userData) => [
//   {
//     title: userData.first_name.concat(" " + userData.last_name),
//     // link: '/perfilPersonal.html?userid='.concat(userData.id),
//     link: `/user/profile/${userData.id}`,
//     icon: {
//       url: userData.profile_image
//         ? getUrlsEnv().files.concat(userData.profile_image) +
//           "?" +
//           Date.now()
//         : "/images/menu/user-no-circle.svg",
//       width: userData.profile_image ? "30px" : "24px",
//       class: userData.profile_image ? "user-avatar" : ""
//     },
//     submenu: []
//   },
//   {
//     title: "Configuraciones",
//     icon: {
//       url: "/images/menu/gear.svg",
//       width: "20px"
//     },
//     submenu: [
//       // {
//       //   perms: [
//       //     {
//       //       id: "32",
//       //       perm: "r"
//       //     }
//       //   ],
//       //   link: `/company/profile/${companyData.orbi_id}`,
//       //   title: "Perfil empresa"
//       // },
//       {
//         // link: "/catalogoUsuarios.html",
//         perms: [
//           {
//             id: "39",
//             perm: "r"
//           }
//         ],
//         link: "/company/user",
//         title: "Usuarios"
//       },
//       {
//         perms: [
//           {
//             id: "30",
//             perm: "r"
//           }
//         ],
//         link: "/company/fleet/summary",
//         title: "Flotilla"
//       },
//       {
//         // perms: [
//         //   {
//         //     id: "24",
//         //     perm: "r"
//         //   }
//         // ],
//         link: "/company/network",
//         title: "Red"
//       }
//     ]
//   },
//   {
//     title: "Controles",
//     link: "#",
//     icon: {
//       url: "/images/menu/truck.svg",
//       width: "24px"
//     },
//     submenu: []
//   },
//   {
//     title: "Ticket",
//     link: "#",
//     icon: {
//       url: "/images/menu/ticket.svg",
//       width: "24px"
//     },
//     submenu: []
//   },
//   {
//     title: "Clock",
//     link: "#",
//     icon: {
//       url: "/images/menu/clock.svg",
//       width: "24px"
//     },
//     submenu: []
//   }
// ];
