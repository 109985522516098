import React from "react";
import SwitchElementModal from "../dialog/swicth-element-modal";
import PrevSwitch from "../controls/prev-switch";
import Button from "../controls/button";
import ValidatePerms from "./validate-perms";

class PermissionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSwitchModal: false,
      selectedPermission: "",
      selectedSubPermission: "",
      propsActivePermissions: props.activePermissions,
      permissionsMatrix: props.activePermissions,
    };
  }
  handleSave = () => {
    this.props.onSave(this.state.permissionsMatrix);
    //this.props.onSave();
  };
  setPermission = (permissionId, permissionType, value) => {
    this.multipleSetPermission(permissionId, permissionType, value);
  };

  updatePermission = (permissionId, permissionType, value) => {
    let newPermissions = this.state.permissionsMatrix.map((p) => {
      if (p.module_permission === permissionId) {
        p[permissionType] = value;
      }
      return p;
    });

    this.setState({ permissionsMatrix: newPermissions });
  };

  getInmutableElements = (row, col) => {
    // console.log(row);
    // let perms = {
    //   c: "i",
    //   r: "m",
    //   u: "m",
    //   d: "n"
    // };

    switch (col.property_name) {
      case "p_create":
        return this.validInmutableElements(row.f_create);
      case "p_read":
        return this.validInmutableElements(row.f_read);
      case "p_update":
        return this.validInmutableElements(row.f_update);
      case "p_delete":
        return this.validInmutableElements(row.f_delete);
      default:
    }
  };

  validInmutableElements = (element) => {
    switch (element) {
      case "i":
        return {
          valid: false,
          element: <div>✓</div>,
        };
      case "m":
        return {
          valid: true,
          element: "",
        };
      case "n":
        return {
          valid: false,
          element: "",
        };
      default:
        return {
          valid: false,
          element: "",
        };
    }
  };

  multipleSetPermission = (permissionId, permissionType, value) => {
    let currentPermission = this.state.permissionsMatrix.find(
      (item) => item.module_permission === permissionId
    );

    let setPermission = [];
    let setPermissionRow = [];

    switch (currentPermission.internal_permission_id) {
      case 41:
        setPermission = [41, 43, 42];
        break;
      case 43:
        setPermission = [43, 42];
        break;
      case 56:
        setPermissionRow = ["p_create", "p_update", "p_delete"];
        break;
      default:
        break;
    }

    if (setPermission.length) {
      setPermission.forEach((item) => {
        let selectPermission = this.state.permissionsMatrix.find(
          (permission) => permission.internal_permission_id === item
        );
        this.updatePermission(
          selectPermission.module_permission,
          permissionType,
          value
        );
      });
    } else if (setPermissionRow.length) {
      setPermissionRow.forEach((item) => {
        this.updatePermission(permissionId, item, value);
      });
    } else {
      this.updatePermission(permissionId, permissionType, value);
    }
  };

  render() {
    return (
      <div className="permissions-table">
        <div className="table-header">
          <div>Sección</div>
          <div>Crear</div>
          <div>Leer</div>
          <div>Editar</div>
          <div>Borrar</div>
        </div>
        {this.props.permissionsCatalog.map((category) => {
          // {
          //   console.log(category.internal_permission_id);
          // }
          return category.f_create === "m" ||
            category.f_read === "m" ||
            category.f_update === "m" ||
            category.f_delete === "m" ? (
            <div className="table-row" key={category.id}>
              <div className="permission-title">{category.permission}</div>
              {PERMISSIONS.map((permission) => {
                let validation = this.getInmutableElements(
                  category,
                  permission
                );
                return (
                  <div
                    key={permission.title}
                    className={"permission-control-container"}
                  >
                    {this.props.isIndividual ? (
                      validation.valid ? (
                        <ValidatePerms
                          perms={[
                            {
                              id: "5",
                              perm: "u",
                            },
                          ]}
                          optionalElement={
                            ((this.state.permissionsMatrix || []).find(
                              (p) => p.module_permission === category.id
                            ) || {})[permission.property_name] ? (
                              <div>✓</div>
                            ) : (
                              <div>x</div>
                            )
                          }
                        >
                          <PrevSwitch
                            handleClick={(value) =>
                              this.setPermission(
                                category.id,
                                permission.property_name,
                                value
                              )
                            }
                            checked={
                              ((this.state.permissionsMatrix || []).find(
                                (p) => p.module_permission === category.id
                              ) || {})[permission.property_name]
                            }
                          />
                        </ValidatePerms>
                      ) : (
                        validation.element
                      )
                    ) : (
                      <>
                        10
                        <img
                          alt=""
                          src="/images/edit-transparent.svg"
                          onClick={() =>
                            this.setState({
                              openSwitchModal: true,
                              selectedPermission: category.id,
                              selectedSubPermission: permission.title,
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          );
        })}
        <div className="table-actions">
          {this.props.isIndividual ? (
            <Button text="Guardar" type="primary" onClick={this.handleSave} />
          ) : (
            ""
          )}
        </div>
        <SwitchElementModal
          open={this.state.openSwitchModal}
          title={"Permisos de ".concat(this.state.selectedPermission)}
          intro={INTRO + this.state.selectedSubPermission + " son:"}
          closeAction={() => this.setState({ openSwitchModal: false })}
        />
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activePermissions !== prevState.propsActivePermissions) {
      if (nextProps.activePermissions.length) {
        return {
          propsActivePermissions: nextProps.activePermissions,
          permissionsMatrix: nextProps.activePermissions,
        };
      }
      return {
        propsActivePermissions: nextProps.activePermissions,
        permissionsMatrix: [],
      };
    }
    return null;
  }
}

export default PermissionsTable;

PermissionsTable.defaultProps = {
  permissionsCatalog: [],
  activePermissions: [],
  onSave: () => {
    console.log("Not yet implemented.");
  },
};

const INTRO = "Las personas que pueden ";

const PERMISSIONS = [
  { title: "crear", property_name: "p_create" },
  { title: "leer", property_name: "p_read" },
  { title: "editar", property_name: "p_update" },
  { title: "borrar", property_name: "p_delete" },
];
