import React from 'react';
import { DownloadIcon } from '../../company/routes/style';
import DownloadImage from '../../shared/icons/routes/downloadIcon';
import SVGIcon, { icons }from '../../shared/svg-icon';
import GpsAssembleRow from './gps-assemble-row';
import GpsResumeComponent from './gps-resume-component';
import TitleArrow from './title-arrow';

export default class UnitsTableView extends React.Component {

  constructor(props) {
    super(props);

    const { items = [], resume = {} } = props;
    const { assemblies_1 = 0, assemblies_2 = 0, assemblies_3 = 0, orbidelivery_1 = 0, orbidelivery_2 = 0, orbidelivery_3 = 0, gps_1 = 0, gps_2 = 0, gps_3 = 0 } = resume;

    this.state = {
      isLtOpen: false,
      isContractOpen: false,
      isTripsOpen: false,
      items,
      assemblies_status_1: assemblies_1,
      assemblies_status_2: assemblies_2 + assemblies_3,
      orbidelivery_status_1: orbidelivery_1,
      orbidelivery_status_2: orbidelivery_2 + orbidelivery_3,
      motum_status_1: gps_1,
      motum_status_2: gps_2 + gps_3,
      maxElementsToShow: 50,
    };

  }

  

  componentDidUpdate(nextProps) {

    const { items = [], resume = {} } = nextProps;
    const { assemblies_1 = 0, assemblies_2 = 0, assemblies_3 = 0, orbidelivery_1 = 0, orbidelivery_2 = 0, orbidelivery_3 = 0, gps_1 = 0, gps_2 = 0, gps_3 = 0 } = resume;

    if (nextProps && items.length !== this.state.items.length) {
      this.setState({
        isLtOpen: false,
        isContractOpen: false,
        isTripsOpen: false,
        items,
        assemblies_status_1: assemblies_1,
        assemblies_status_2: assemblies_2 + assemblies_3,
        orbidelivery_status_1: orbidelivery_1,
        orbidelivery_status_2: orbidelivery_2 + orbidelivery_3,
        motum_status_1: gps_1,
        motum_status_2: gps_2 + gps_3,
        maxElementsToShow: 50,
      });
    }

  }

  render() {

    return (
      <div className={'gps-units-table-view'} onScroll={this.handleScroll}>
        <div className={'gps-units-table-view-header'}>
          {this.getTopResume()}
          {this.getTopTitle()}
        </div>
        <div className={'content-units'} >
          {this.getContent()}
        </div>
      </div>
    );
  }

  getContent() {

    const { selectTruck = () => {} } = this.props;
    const { maxElementsToShow } = this.state;

    if (this.state.items.length > 0) {

      if (this.state.isLtOpen) {

        return this.state.items.sort( (item1, item2) => {
          var corporationName1 = (item1.contract !== null && item1.contract !== undefined && item1.contract.fleet_provider !== null && item1.contract.fleet_provider !== undefined && item1.contract.fleet_provider.corporation_name !== null && item1.contract.fleet_provider.corporation_name !== undefined) ? item1.contract.fleet_provider.corporation_name.toLowerCase() : '';
          var corporationName2 = (item2.contract !== null && item2.contract !== undefined && item2.contract.fleet_provider !== null && item2.contract.fleet_provider !== undefined && item2.contract.fleet_provider.corporation_name !== null && item2.contract.fleet_provider.corporation_name !== undefined) ? item2.contract.fleet_provider.corporation_name.toLowerCase() : '';

          return corporationName1.localeCompare(corporationName2);
        }).slice(0, maxElementsToShow).map( (item, index) => <GpsAssembleRow truck={item} key={index} selectTruck={ (truck) => selectTruck(truck) }/>);

      } else if (this.state.isContractOpen) {

        return this.state.items.sort( (item1, item2) => {
          var corporationName1 = (item1.contract !== null && item1.contract !== undefined && item1.contract.facility !== null && item1.contract.facility !== undefined && item1.contract.facility.name !== null && item1.contract.facility.name !== undefined) ? item1.contract.facility.name.toLowerCase() : '';
          var corporationName2 = (item2.contract !== null && item2.contract !== undefined && item2.contract.facility !== null && item2.contract.facility !== undefined && item2.contract.facility.name !== null && item2.contract.facility.name !== undefined) ? item2.contract.facility.name.toLowerCase() : '';

          return corporationName1.localeCompare(corporationName2);
        }).slice(0, maxElementsToShow).map( (item, index) => <GpsAssembleRow truck={item} key={index} selectTruck={ (truck) => selectTruck(truck) }/>);

      } else if (this.state.isTripsOpen) {

        return this.state.items.sort( (item1, item2) => {
          var tripsNumber1 = (item1.trips !== null && item1.trips.length > 0) ? item1.trips.length : 0;
          var tripsNumber2 = (item2.trips !== null && item2.trips.length > 0) ? item2.trips.length : 0;
          return tripsNumber2 - tripsNumber1;
        }).slice(0, maxElementsToShow).map( (item, index) => <GpsAssembleRow truck={item} key={index} selectTruck={ (truck) => selectTruck(truck) }/>);

      } else {
        return this.state.items.slice(0, maxElementsToShow).map( (item, index) => <GpsAssembleRow truck={item} key={index} selectTruck={ (truck) => selectTruck(truck) }/>);
      }

    } else {
      return [];
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      this.setState({maxElementsToShow: this.state.maxElementsToShow + 50});
    }
  }

  getTopResume() {

    const { assemblies_status_1, assemblies_status_2, orbidelivery_status_1, orbidelivery_status_2, motum_status_1, motum_status_2 } = this.state;
    const { downloadDocument = () => {} } = this.props;

    return(
      <div className={'gps-units-table-top-resume'}>
        <div className={'gps-units-table-top-resume-container'}>
          <GpsResumeComponent
            icon={
              <SVGIcon
                name={icons.truck}
                fill="white"
                width="20px"
                height="20px"
                viewBox="0 0 18 18"
              />
            }
            width={'30%'}
            height={'30px'}
            firstValue={assemblies_status_1}
            secondValue={assemblies_status_2}
          />
          <GpsResumeComponent
            name={'APP'}
            width={'30%'}
            height={'30px'}
            firstValue={orbidelivery_status_1}
            secondValue={orbidelivery_status_2}
          />
          <GpsResumeComponent
            name={'GPS'}
            width={'30%'}
            height={'30px'}
            firstValue={motum_status_1}
            secondValue={motum_status_2}
          />
        </div>
        <div
          style={{
            width: 40,
            height: '100%'
          }}
        >
          <DownloadIcon>
            <DownloadImage
              fill={"#FFFFFF"}
              width={"40px"}
              height={"40px"}
              onClick={(e) => { 
                e.stopPropagation(); 
                downloadDocument();
              }}
            />
          </DownloadIcon>
        </div>
      </div>
    );
  }

  getTopTitle() {
    return (
      <div className={'gps-units-table-top-title'}>

        <div style={{
          height: 50,
          width: '10%',
        }}>
          <TitleArrow
            text={'LT'}
            isOpen={this.state.isLtOpen}
            showArrow={true}
            onClick={(value) => this.setState({isLtOpen: value, isContractOpen: false, isTripsOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '23.3%',
        }}>
          <TitleArrow
            text={'Unidad'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '23.3%',
        }}>
          <TitleArrow
            text={'Contrato'}
            isOpen={this.state.isContractOpen}
            showArrow={true}
            onClick={(value) => this.setState({isContractOpen: value, isTripsOpen: false, isLtOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '19%',
        }}>
          <TitleArrow
            text={'Viajes'}
            isOpen={this.state.isTripsOpen}
            showArrow={true}
            onClick={(value) => this.setState({isTripsOpen: value, isLtOpen: false, isContractOpen: false})}
          />
        </div>

        <div style={{
          height: 50,
          width: '12.1%',
        }}>
          <TitleArrow
            text={'APP'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

        <div style={{
          height: 50,
          width: '12.1%',
        }}>
          <TitleArrow
            text={'GPS'}
            showArrow={false}
            onClick={(value) => {}}
          />
        </div>

      </div>
    );
  }

}