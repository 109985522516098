import * as React from "react";
// import type TableDConfig from "../../../shared/component/table-d";
// import TableD from "../../../shared/component/table-d";
// import RowD from "../../../shared/component/row-d";
import styled from "styled-components";
import RowD from "../../shared/component/row-d";
// import * as Types from "../types";
// import { ActionsTricolon } from "../../../shared/controls/actions-tricolon";
// import Button from "../../../shared/controls/button";
// import * as moment from "moment";
// import "moment/locale/es";
import TableD from "../../shared/component/table-d";
import CollapsableParentContainer from "./collapsable-parent-container";
// import ExceedPlus from "../../../shared/controls/exceed-plus";
// import Tag from "../../../shared/controls/tag";

class OrderRowDetailTable extends React.Component {
  static defaultProps = {
    details: [],
    actions: [],
  };

  getConfig = () => {
    if(this.props.itemType === 1) return Config;
    if(this.props.itemType === 3) return UnitConfig;
    let columns = [...Config.columns];
    columns[0] = {...columns[0], text: "Pedido"};
    return {...Config, columns}
  }

  getRows = () => {
    let rows = [];
    if(this.props.itemType === 1){
      rows = (this.props.package_requisition || []).map((p, i) => (
        <OrderDetailRow 
          key={i} 
          {...this.props} 
          code={p.product.description}
          gross_weight={p.product.gross_weight}
          net_weight={p.product.net_weight}
          volume={p.product.volume}
        />
      ))
      let totals = {code: 'Total', gross_weight: 0, net_weight: 0, volume: 0};
      (this.props.package_requisition || []).forEach((p, i) => {
        totals.gross_weight += p.product.gross_weight;
        totals.net_weight += p.product.net_weight;
        totals.volume += p.product.volume;
      })
        // return 
      rows.push(<OrderDetailRow 
        key={(this.props.package_requisition || []).length} 
        {...this.props} 
        className="last"
        {...totals} 
      />)
    } else if (this.props.itemType === 2){
      rows = ( this.props.packages_order ||  []).map((p, i) => {
        // let total = {gross_weight: 0, net_weight: 0, volume: 0}
        // p.package_requisition.forEach(r => {
        //   total.gross_weight += r.product.gross_weight;
        //   total.net_weight += r.product.net_weight;
        //   total.volume += r.product.volume;
        // })
        return <OrderDetailRow 
          key={i} 
          {...this.props} 
          code={p.product.description}
          gross_weight={p.product.gross_weight}
          net_weight={p.product.net_weight}
          volume={p.product.volume}
          // {...total}
        />
      })
      let totals = {
        code: 'Total', 
        gross_weight: this.props.gross_weight, 
        net_weight: this.props.net_weight, 
        volume: this.props.volume
      };
      // (this.props.requisition_order || []).forEach((p, i) => {
      //   p.package_requisition.forEach(r => {
      //     totals.gross_weight += r.product.gross_weight;
      //     totals.net_weight += r.product.net_weight;
      //     totals.volume += r.product.volume;
      //   })
      // })
        // return 
      rows.push(<OrderDetailRow 
        key={(this.props.package_requisition || []).length} 
        {...this.props} 
        className="last"
        {...totals} 
      />)
    } else if(this.props.itemType === 3){
      let skus = []; //id, description, sku, units, net_weight, gross_weight, volume
      //children: id, index, requisition, net_weight, gross_weight, volume
      (this.props.package_pallet || []).forEach(p => {
        let index = skus.findIndex(sku => sku.id === p.product.id);
        if(index === -1){
          skus.push({
            id: p.product.id,
            code: p.product.description,
            sku: p.product.sku,
            units: 1,
            package: null,
            net_weight: p.product.net_weight,
            gross_weight: p.product.gross_weight,
            volume: p.product.volume,
            original_net_weight: p.product.net_weight,
            original_gross_weight: p.product.gross_weight,
            original_volume: p.product.volume,
            childrenElements: [
              <PalletRow 
                tableConfig={UnitConfig}
                code={1}
                sku={''}
                package={p.requisition}
                units={''}
                gross_weight={p.product.gross_weight}
                net_weight={p.product.net_weight}
                volume={p.product.volume}
              />
            ]
          })
        } else {
          skus[index] = {
            ...skus[index],
            units: skus[index].units + 1,
            net_weight: skus[index].original_net_weight * (skus[index].units + 1),
            gross_weight: skus[index].original_gross_weight * (skus[index].units + 1),
            volume: skus[index].original_volume * (skus[index].units + 1),
            childrenElements: skus[index].childrenElements.concat([<PalletRow 
              tableConfig={UnitConfig}
              code={skus[index].units + 1}
              sku={''}
              package={p.requisition}
              units={''}
              gross_weight={p.product.gross_weight}
              net_weight={p.product.net_weight}
              volume={p.product.volume}
            />])
          }
        }
      })
      rows = skus.map(sku => (
        <CollapsableParentContainer 
          childrenElements={sku.childrenElements}
          className='collapsable-order-row'
        >
          <PalletRow 
            tableConfig={UnitConfig}
            {...sku}
          />
        </CollapsableParentContainer>
      ))
      // rows = (this.props.package_pallet || []).map((p, i) => (
      //   <OrderDetailRow 
      //     key={i} 
      //     {...this.props} 
      //     code={p.product.description}
      //     gross_weight={p.product.gross_weight}
      //     net_weight={p.product.net_weight}
      //     volume={p.product.volume}
      //   />
      // ))
      let totals = {
        code: 'Total', 
        gross_weight: this.props.gross_weight, 
        net_weight: this.props.net_weight, 
        volume: this.props.volume
      };
      // (this.props.package_requisition || []).forEach((p, i) => {
      //   totals.gross_weight += p.product.gross_weight;
      //   totals.net_weight += p.product.net_weight;
      //   totals.volume += p.product.volume;
      // })
        // return 
      rows.push(<PalletRow 
        key={(this.props.package_requisition || []).length} 
        {...this.props} 
        className="last"
        {...totals} 
      />)
    }
    return rows;
  }

  render() {
    return (
      <TableD tableConfig={this.getConfig()}>
          {
            this.getRows()
          }
      </TableD>
    );
  }
}

export default OrderRowDetailTable;

const OrderDetailRow = (props) => {
  return (
    <RowD {...props}>
      {/* <Text>{
        props.orderproduct_order?.length 
        ? props.orderproduct_order
          .map(p => p.quantity)
          .reduce((total, value) => total + value)
        : 0
        }
      </Text> */}
      <Text>{props.code}</Text>
      <Text>{props.gross_weight} kg</Text>
      <Text>{props.net_weight} kg</Text>
      <Text>{props.volume} cbm</Text>
      <Text>{'-'}</Text>
      <Text>{'-'}</Text>
    </RowD>
  );
};

const PalletRow = (props) => {
  return (
    <RowD {...props}>
      <Text>{props.code}</Text>
      <Text>{props.sku}</Text>
      <Text>{props.package}</Text>
      <Text>{props.units}</Text>
      <Text>{props.gross_weight} kg</Text>
      <Text>{props.net_weight} kg</Text>
      <Text>{props.volume} cbm</Text>
    </RowD>
  );
};

const Text = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  position: relative;
  & > img {
    position: absolute;
    top: 0;
    right: -25px;
    cursor: pointer;
  }
`;

const headerItemStyle = {
  color: "#A4B4CE",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  textAlign: "center",
};

const cellStyle = {
  color: "#FFFFFF",
  fontSize: "16px",
  fontWeight: "bold",
  letterSpacing: "0",
  //   textAlign: "center",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  margin: "auto",
  // overflow: "auto",
  maxHeight: "100%",
};

const Config = {
  rowStyle: {
    backgroundColor: "rgb(34,42,62)",
    height: "80px",
  },
  headerStyle: {
    backgroundColor: "rgb(34,42,62)",
    borderBottom: "2px solid #A4B4CE",
  },
  columns: [
    {
      text: "Producto",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Bruto",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Neto",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Volumen",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Incoterm",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Compartido con",
      width: "16.5%",
      headerItemStyle,
      cellStyle,
    },
  ],
};

const UnitConfig = {
  rowStyle: {
    backgroundColor: "rgb(34,42,62)",
    height: "80px",
  },
  headerStyle: {
    backgroundColor: "rgb(34,42,62)",
    borderBottom: "2px solid #A4B4CE",
  },
  columns: [
    {
      text: "Producto",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "SKU",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Pedido",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Unidades",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Neto",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Peso Bruto",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Volumen",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
  ],
};
