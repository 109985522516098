import React from "react";
import { connect } from "react-redux";

import { pullAll } from "lodash";

import AlertRow from "./alert-row";
import { restrictInteger } from "../../shared/utils";
import ConfirmDialog from "../dialog/confirm-dialog";
import InputTagger from "../controls/inputTagger";
import { getUrlsEnv } from "../backend-api";

import { loadCompanyUsers } from "../../redux/reducers/company-reducer";
import { facilityAlertConfigActions } from "../../redux/reducers/facility-reducer";
import { getUserByEmail } from "../../redux/reducers/user-reducer";
import {
  listFacilityAlerts,
  ListCompanyAlerts,
  setFacilityAlert,
  createFacilityAlertUser,
  createFacilityAlertEmail,
  removeFacilityAlertEmail,
  removeFacilityAlertUser,
  listFacilityAlertEmailsAndUsers
} from "../../redux/reducers/alerts-reducer";

class AlertList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInviteModal: false
    };
  }

  manageModal = (lvl = {}, alert = {}) => {
    const { tags, level } = this.state;
    if (lvl === true) {
      pullAll(
        tags.filter(t => t.avatarType === "contained").map(t => t.email),
        level.emails.email_list.map(em => em[1])
      ).forEach(em =>
        this.props.createFacilityAlertEmail(this.props.facilityId, level.id, em)
      );

      pullAll(
        level.emails.email_list.map(t => t[1]),
        tags.filter(t => t.avatarType === "contained").map(t => t.email)
      ).forEach(em =>
        this.props.removeFacilityAlertEmail(
          this.props.facilityId,
          level.id,
          level.emails.email_list.find(xy => xy[1] === em)[0]
        )
      );

      pullAll(
        tags.filter(t => t.avatarType === "full").map(t => t.email),
        level.emails.user_list.map(em => em[1])
      ).forEach(em => {
        const userCompany = this.props.companyUsers.find(
          u => u.users === tags.find(xy => xy.email === em).userId
        );
        if (userCompany) {
          this.props.createFacilityAlertUser(
            this.props.facilityId,
            level.id,
            true,
            userCompany.id,
            em
          );
        } else {
          this.props.createFacilityAlertEmail(
            this.props.facilityId,
            level.id,
            em
          );
        }
      });

      pullAll(
        level.user_list,
        tags.filter(t => t.avatarType === "full").map(t => t.email)
      );
      pullAll(
        level.emails.user_list.map(t => t[1]),
        tags.filter(t => t.avatarType === "full").map(t => t.email)
      ).forEach(em =>
        this.props.removeFacilityAlertUser(
          this.props.facilityId,
          level.id,
          level.emails.user_list.find(xy => xy[1] === em)[0]
        )
      );
    } else if (lvl) {
      lvl.emails.email_list.forEach(em => this.getEmailTag(em[1]));
      lvl.emails.user_list.forEach(em => this.getEmailTag(em[1]));
    }
    this.setState({
      openInviteModal: !this.state.openInviteModal,
      level: lvl,
      alert,
      tags: []
    });
  };

  handleAlertDataChange = e => {
    const { name, value, checked = false } = e.target;

    let currentAlert = this.props.alerts.find(al => al.id === value);
    const instructions = name.split("-");
    switch (instructions[0]) {
      case "active":
        currentAlert.active = checked;
        if (!checked)
          Object.values(currentAlert.levels).forEach((lvl, ind) => {
            if (lvl.status) {
              lvl.status = false;
              this.setFacilityLevel(lvl);
            }
          });
        break;
      default:
        return;
    }

    this.props.setAlert(currentAlert);
  };

  setFacilityLevel(level) {
    this.props.setFacilityAlert(
      this.props.facilityId,
      level.minutes,
      level.level,
      level.status,
      level.alerts,
      level.id,
      this.props.facilityId
    );
  }

  handleLevelDataChange = e => {
    const { name, value, checked = false } = e.target;
    const instructions = name.split("-");
    const action = instructions[0];
    const level = parseInt(instructions[1]) - 1;
    const alert = instructions[3];
    let currentAlert = this.props.alerts.find(al => al.id.toString() === alert);

    switch (action) {
      case "activeLevel":
        Object.values(currentAlert.levels).forEach((lvl, ind) => {
          if (lvl.status !== (ind < level ? true : false)) {
            lvl.status = !lvl.status;
            this.setFacilityLevel(lvl);
          }
        });
        currentAlert.levels[level].status = checked;
        if (checked) {
          currentAlert.active = checked;
        }
        this.setFacilityLevel(currentAlert.levels[level]);
        break;
      case "minutesLevel":
        if (restrictInteger(value) && value.length < 4) {
          currentAlert.levels[level].minutes = value;
        }
        this.setFacilityLevel(currentAlert.levels[level]);
        break;
      case "emailsLevel":
        this.manageModal(currentAlert.levels[level], currentAlert);
        break;
      case "setEmailsLevel":
        this.props.postValidateDomain();
        currentAlert.levels[level].emails = value;
        break;
      case "clearLevel":
        currentAlert.levels[level].minutes = 0;
        currentAlert.levels[level].emails.user_list.forEach(em =>
          this.props.removeFacilityAlertUser(
            this.props.facilityId,
            currentAlert.levels[level].id,
            em[0]
          )
        );
        currentAlert.levels[level].emails.email_list.forEach(em =>
          this.props.removeFacilityAlertEmail(
            this.props.facilityId,
            currentAlert.levels[level].id,
            em[0]
          )
        );
        this.setFacilityLevel(currentAlert.levels[level]);
        break;
      default:
        return;
    }
    this.props.setAlert(currentAlert);
  };

  componentDidMount() {
    this.props.loadFacilityAlerts(this.props.facilityId);
  }

  getEmailTag = email => {
    this.props.getUserByEmail(email, "", "").then(() => {
      let tag = {
        email: email,
        avatar: "/images/mail.svg",
        avatarType: "contained",
        userId: null,
        processed: true
      };
      if (this.props.searchedUser.length) {
        tag = {
          email: email,
          avatar: this.props.searchedUser[0].profile_image,
          avatarType: "full",
          userId: this.props.searchedUser[0].id
        };
        tag.avatar = getUrlsEnv().files.concat(tag.avatar);
      }
      this.setState({
        tags: (this.state.tags || []).concat([tag])
      });
    });
  };

  render() {
    const { level = {}, alert = {} } = this.state;
    return (
      <React.Fragment>
        {this.props.alerts.map(al => {
          return (
            <AlertRow
              alert={al}
              key={al.id}
              loadEmailList={levelId => {
                this.props.listFacilityAlertEmailsAndUsers(
                  this.props.facilityId,
                  levelId
                );
              }}
              onAlertDataChange={this.handleAlertDataChange}
              onLevelDataChange={this.handleLevelDataChange}
              mode={"alerts"}
            />
          );
        })}
        <ConfirmDialog
          open={this.state.openInviteModal}
          title={`${alert.name} nivel ${level.level}`}
          message="Las personas que recibirán esta alerta son:"
          acceptText="Guardar"
          showCancel={true}
          contentObject={this.getLevelUserList()}
          acceptAction={this.manageModal}
          closeAction={this.manageModal}
          className="facility-alert-users-dialog"
        />
      </React.Fragment>
    );
  }

  handleEmailsChange = e => {
    const { tags } = this.state;
    const toAdd = pullAll(
      e.target.value.map(t => t.email),
      tags.map(l => l.email)
    );
    const toRemove = pullAll(
      tags.map(l => l.email),
      e.target.value.map(t => t.email)
    );
    toAdd.forEach(a => this.getEmailTag(a));
    toRemove.forEach(a =>
      this.setState({ tags: tags.filter(t => t.email !== a) })
    );
  };

  getLevelUserList = () => {
    const { tags } = this.state;
    return (
      <React.Fragment>
        <InputTagger
          onlyLowerCase
          placeholder='Ingresa el o los correos separados por "enter"'
          tags={tags}
          onChange={this.handleEmailsChange}
        />
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => {
  const alerts = (state.alerts.companyAlerts.data || []).map(compAl => {
    const facilityAlerts = (state.alerts.facilityAlerts.data || []).filter(
      facAl => facAl.alerts === compAl.id
    );
    const levels = Array(compAl.levels)
      .fill()
      .map((e, i) => {
        const level = facilityAlerts.find(x => x.level === i + 1);
        return level
          ? Object.assign(level, {
              emails: state.alerts.facilityAlertEmails.data[level.id] || []
            })
          : {
              alerts: compAl.id,
              id: undefined,
              status: false,
              minutes: 0,
              emails: [],
              level: i + 1,
              facilities: undefined
            };
      });

    return {
      id: compAl.id,
      name: compAl.name,
      description: compAl.description,
      active: levels.find(l => l.status) ? true : false,
      levels
    };
  });
  return {
    alerts,
    searchedUser: state.userReducer.searchedUser.data,
    facilityAlertEmails: state.alerts.facilityAlertEmails.data,
    companyUsers: state.companyReducer.companyUsers.data
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  dispatch(ListCompanyAlerts());
  return {
    setAlert: alertItem => dispatch(facilityAlertConfigActions(alertItem)),
    loadFacilityAlerts: facilityId => dispatch(listFacilityAlerts(facilityId)),
    setFacilityAlert: (facilityId, minutes, level, status, alertId, id) =>
      dispatch(
        setFacilityAlert(facilityId, minutes, level, status, alertId, id)
      ),
    getUserByEmail: email => dispatch(getUserByEmail(email, "", "")),
    createFacilityAlertEmail: (facilityId, facilityAlertId, email) =>
      dispatch(createFacilityAlertEmail(facilityId, facilityAlertId, email)),
    createFacilityAlertUser: (
      facilityId,
      facilityAlertId,
      email_notification,
      userCompanyId,
      email
    ) =>
      dispatch(
        createFacilityAlertUser(
          facilityId,
          facilityAlertId,
          email_notification,
          userCompanyId,
          email
        )
      ),
    removeFacilityAlertEmail: (
      facilityId,
      facilityAlertId,
      facilityAlertEmailId
    ) =>
      dispatch(
        removeFacilityAlertEmail(
          facilityId,
          facilityAlertId,
          facilityAlertEmailId
        )
      ),
    removeFacilityAlertUser: (
      facilityId,
      facilityAlertId,
      facilityAlertEmailId
    ) =>
      dispatch(
        removeFacilityAlertUser(
          facilityId,
          facilityAlertId,
          facilityAlertEmailId
        )
      ),
    listFacilityAlertEmailsAndUsers: (facilityId, facilityAlertId) =>
      dispatch(listFacilityAlertEmailsAndUsers(facilityId, facilityAlertId))
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(AlertList);
