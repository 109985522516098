import React from "react";
// import Button from "../../shared/controls/button";
import PrevSwitch from "../controls/prev-switch";

class AlertLevel extends React.Component {
  render() {
    return (
      <div
        className={"alert-level".concat(
          this.props.level.status ? "" : " disabled"
        )}
      >
        <PrevSwitch
          handleClick={e =>
            this.props.onChange({
              target: {
                name: `activeLevel-${this.props.level.level}-alert-${this.props.level.alerts}`,
                value: this.props.level.alerts,
                checked: e
              }
            })
          }
          checked={this.props.level.status}
        />{" "}
        Nivel {this.props.level.level}
        <button
          className={"btn primary"}
          name={`emailsLevel-${this.props.level.level}-alert-${this.props.level.alerts}`}
          onClick={this.props.onChange}
        >
          {(this.props.level.emails.user_list || []).length +
            (this.props.level.emails.email_list || []).length}
          <img alt="" src="/images/user-white.svg" />
          Agregar
        </button>
        <span className="alert-minutes">
          A partir de:
          <input
            type="text"
            name={`minutesLevel-${this.props.level.level}-alert-${this.props.level.alerts}`}
            value={this.props.level.minutes}
            onChange={this.props.onChange}
          />
          minutos
        </span>
        <img
          alt=""
          src="/images/bin.png"
          onClick={() =>
            this.props.onChange({
              target: {
                // name: `clearLevel-${this.props.level.name}-alert-${this.props.alert.id}`,
                name: `clearLevel-${this.props.level.level}-alert-${this.props.level.alerts}`,
                value: ""
              }
            })
          }
        />
      </div>
    );
  }
}

export default AlertLevel;
