import * as React from "react";
import styled from "styled-components";
import Caret from "../../shared/component/caret";
import RowD from "../../shared/component/row-d";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";

class ServiceReportRow extends React.Component {
  state = {
    collapsed: true,
    isHovered: false
  }

  componentDidUpdate(prevProps) {
    if(prevProps.collapsed !== this.props.collapsed){
      this.setState({collapsed: this.props.collapsed});
    }
  }

  render() {
    return(
      <>
        <RowD {...this.props} onClick={this.props.openDetail}>
            <CaretContainer
              onClick={(e) => {e.stopPropagation(); this.setState({ collapsed: !this.state.collapsed })}}
              onMouseEnter={() => this.setState({ isHovered: true })}
              onMouseLeave={() => this.setState({ isHovered: false })}
            >
              <Caret up={this.state.collapsed} fill="#AFC8FF" isHovered={this.state.isHovered}/>
            </CaretContainer>
            <Text >
              {this.props.data?.bridge?.name || '-'}<br/>
              <span style={{color: "#41b0ff"}}>{this.props.data?.bridge?.url || '-'}</span>
            </Text>
            <Text >
              {this.props.data?.expiry_date || '-'}
            </Text>
            <Text >
              {this.props.data?.status || '-'}
            </Text>
            <Text >
              {this.props.data?.last_activity || '-'}
            </Text>
            <ActionsContainer onClick={(e) => e.stopPropagation()}>
              <ActionsTricolon actions={this.props.actions || []} tooltipPlacement={"left"}/>
            </ActionsContainer>
        </RowD>
        {
          !this.state.collapsed &&
          <DetailTable>
            <TableTitle>
              Tablas de sincronización
            </TableTitle>
            <div>
              {
                (this.props.data?.bridge_sync_tables || []).map((table, i) => (
                  <TableRow key={`table-${i}`} onClick={() => {this.props.openTable(table.id)}}>
                    <span /* style={{cursor: "pointer"}} */>{table.sync_model?.name || '-'}</span>
                  </TableRow>
                ))
              }
            </div>
          </DetailTable>
        }
      </>
    )
  }
}

export default ServiceReportRow;

const DetailTable = styled.div`
  background-color: #242e42;
  color: #fff;
`;

const TableTitle = styled.div`
  color: #a4b4ce;
  padding: 15px 20px;
  border-bottom: 1px solid #ffffff80;
  font-weight: bold;
`;

const TableRow = styled.div`
  padding: 8px 20px;
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-around;
  & .btn {
    width: 100px;
    margin: 3px;
  }
`;

const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

const Text = styled.div`
  align-self: center;
  width: 100%;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;