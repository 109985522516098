import { cloneDeep } from "lodash";
import store from "../store";
import { getApiUrl } from "../../shared/backend-api";
import { BACKEND } from "../../shared/backend-api";
import { putApiUrl } from "../../shared/backend-api";
import { postApiUrl } from "../../shared/backend-api";
import { deleteApiUrl } from "../../shared/backend-api";

const ACTIONS = {
  COMPANY: {
    ALERT: {
      REQUEST: {
        TYPE: "ACTIONS_COMPANY_ALERTS_REQUEST",
        ACTION: () => ({
          type: ACTIONS.COMPANY.ALERT.REQUEST.TYPE
        })
      },
      ERROR: {
        TYPE: "ACTIONS_COMPANY_ALERTS_ERROR",
        ACTION: error => ({
          type: ACTIONS.COMPANY.ALERT.ERROR.TYPE,
          error
        })
      },
      LIST: {
        SUCCESS: {
          TYPE: "ACTIONS_COMPANY_ALERTS_LIST_SUCCESS",
          ACTION: response => ({
            type: ACTIONS.COMPANY.ALERT.LIST.SUCCESS.TYPE,
            response
          })
        }
      }
    },
    USER: {
      ALERT_FACILITY: {
        REQUEST: {
          TYPE: "COMPANY_USER_ALERT_FACILITY_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.USER.ALERT_FACILITY.REQUEST.TYPE
          })
        },
        ERROR: {
          TYPE: "COMPANY_USER_ALERT_FACILITY_ERROR",
          ACTION: error => ({
            type: ACTIONS.COMPANY.USER.ALERT_FACILITY.ERROR.TYPE,
            error
          })
        },
        LIST: {
          SUCCESS: {
            TYPE: "COMPANY_USER_ALERT_FACILITY_LIST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.USER.ALERT_FACILITY.LIST.SUCCESS.TYPE,
              response
            })
          }
        }
      }
    },
    FACILITY: {
      ALERT: {
        REQUEST: {
          TYPE: "COMPANY_FACILITY_ALERT_REQUEST",
          ACTION: () => ({
            type: ACTIONS.COMPANY.FACILITY.ALERT.REQUEST.TYPE
          })
        },
        ERROR: {
          TYPE: "COMPANY_FACILITY_ALERT_ERROR",
          ACTION: error => ({
            type: ACTIONS.COMPANY.FACILITY.ALERT.ERROR.TYPE,
            error
          })
        },
        GET: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_ALERT_GET_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.GET.SUCCESS.TYPE,
              response
            })
          }
        },
        LIST: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_ALERT_LIST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.LIST.SUCCESS.TYPE,
              response
            })
          }
        },
        POST: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_ALERT_POST_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.POST.SUCCESS.TYPE,
              response
            })
          }
        },
        PUT: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_ALERT_PUT_SUCCESS",
            ACTION: response => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.PUT.SUCCESS.TYPE,
              response
            })
          }
        },
        LIST_MAILS: {
          SUCCESS: {
            TYPE: "COMPANY_FACILITY_ALERT_LIST_MAILS_SUCCESS",
            ACTION: (response, facilityAlertId) => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.LIST_MAILS.SUCCESS.TYPE,
              response,
              facilityAlertId
            })
          }
        },
        EMAIL: {
          REQUEST: {
            TYPE: "COMPANY_FACILITY_ALERT_EMAIL_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.REQUEST.TYPE
            })
          },
          ERROR: {
            TYPE: "COMPANY_FACILITY_ALERT_EMAIL_ERROR",
            ACTION: error => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.ERROR.TYPE,
              error
            })
          },
          POST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_EMAIL_POST_SUCCESS",
              ACTION: (response, listType) => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.POST.SUCCESS.TYPE,
                response,
                listType
              })
            }
          },
          DELETE: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_EMAIL_DELETE_SUCCESS",
              ACTION: (id, alert_facility_id) => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.DELETE.SUCCESS.TYPE,
                id,
                alert_facility_id
              })
            }
          },
          LIST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_EMAIL_LIST_SUCCESS",
              ACTION: response => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.LIST.SUCCESS.TYPE,
                response
              })
            }
          }
        },
        USER: {
          REQUEST: {
            TYPE: "COMPANY_FACILITY_ALERT_USER_REQUEST",
            ACTION: () => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.USER.REQUEST.TYPE
            })
          },
          ERROR: {
            TYPE: "COMPANY_FACILITY_ALERT_USER_ERROR",
            ACTION: error => ({
              type: ACTIONS.COMPANY.FACILITY.ALERT.USER.ERROR.TYPE,
              error
            })
          },
          POST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_USER_POST_SUCCESS",
              ACTION: (response, email) => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.USER.POST.SUCCESS.TYPE,
                response,
                email
              })
            }
          },
          DELETE: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_USER_DELETE_SUCCESS",
              ACTION: (id, alert_facility_id) => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.USER.DELETE.SUCCESS.TYPE,
                id,
                alert_facility_id
              })
            }
          },
          LIST: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_USER_LIST_SUCCESS",
              ACTION: response => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.USER.LIST.SUCCESS.TYPE,
                response
              })
            }
          },
          PUT: {
            SUCCESS: {
              TYPE: "COMPANY_FACILITY_ALERT_USER_PUT_SUCCESS",
              ACTION: response => ({
                type: ACTIONS.COMPANY.FACILITY.ALERT.USER.PUT.SUCCESS.TYPE,
                response
              })
            }
          }
        }
      }
    }
  }
};

const initialState = {
  companyAlerts: {
    data: [],
    isFetching: false,
    error: {}
  },
  facilityAlerts: {
    data: [],
    isFetching: false,
    error: {}
  },
  facilityAlertEmails: {
    data: {},
    isFetching: false,
    error: {}
  },
  userFacilityAlerts: {
    data: {},
    isFetching: false,
    error: {}
  }
};

const setEntity = (entity, identity, entityList) => {
  return entityList
    .filter(
      e =>
        (identity ? e[identity] : e) !== (identity ? entity[identity] : entity)
    )
    .concat([entity]);
};

export default function(state = initialState, action) {
  let {
    facilityAlerts,
    companyAlerts,
    facilityAlertEmails,
    userFacilityAlerts
  } = cloneDeep(state);
  switch (action.type) {
    //Company alerts
    case ACTIONS.COMPANY.ALERT.REQUEST.TYPE:
      companyAlerts.isFetching = true;
      return Object.assign({}, state, companyAlerts);
    case ACTIONS.COMPANY.ALERT.ERROR.TYPE:
      companyAlerts.isFetching = false;
      companyAlerts.error = action.error;
      return Object.assign({}, state, companyAlerts);
    case ACTIONS.COMPANY.ALERT.LIST.SUCCESS.TYPE:
      companyAlerts.isFetching = false;
      companyAlerts.data = action.response;
      return Object.assign({}, state, { companyAlerts });

    // Facility alerts
    case ACTIONS.COMPANY.FACILITY.ALERT.REQUEST.TYPE:
      facilityAlerts.isFetching = true;
      return Object.assign({}, state, facilityAlerts);
    case ACTIONS.COMPANY.FACILITY.ALERT.ERROR.TYPE:
      facilityAlerts.isFetching = false;
      facilityAlerts.error = action.error;
      return Object.assign({}, state, facilityAlerts);
    case ACTIONS.COMPANY.FACILITY.ALERT.PUT.SUCCESS.TYPE:
    case ACTIONS.COMPANY.FACILITY.ALERT.POST.SUCCESS.TYPE:
    case ACTIONS.COMPANY.FACILITY.ALERT.GET.SUCCESS.TYPE:
      facilityAlerts.isFetching = false;
      facilityAlerts.data = setEntity(
        action.response,
        "id",
        facilityAlerts.data
      );
      return Object.assign({}, state, { facilityAlerts });
    case ACTIONS.COMPANY.FACILITY.ALERT.LIST.SUCCESS.TYPE:
      facilityAlerts.isFetching = false;
      facilityAlerts.data = action.response;
      return Object.assign({}, state, { facilityAlerts });

    // Facility alert emails
    case ACTIONS.COMPANY.FACILITY.ALERT.LIST_MAILS.SUCCESS.TYPE:
      facilityAlertEmails.data[action.facilityAlertId] = action.response;
      return Object.assign({}, state, { facilityAlertEmails });

    case ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.REQUEST.TYPE:
      facilityAlertEmails.isFetching = true;
      return Object.assign({}, state, { facilityAlertEmails });
    case ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.ERROR.TYPE:
      facilityAlertEmails.isFetching = false;
      facilityAlertEmails.error = action.error;
      return Object.assign({}, state, { facilityAlertEmails });

    // Facility alert user/email list
    case ACTIONS.COMPANY.FACILITY.ALERT.USER.POST.SUCCESS.TYPE:
      facilityAlertEmails.isFetching = false;
      facilityAlertEmails.data[action.response.alert_facility].user_list = (
        facilityAlertEmails.data[action.response.alert_facility].user_list || []
      ).concat([[action.response.id, action.email]]);
      return Object.assign({}, state, { facilityAlertEmails });
    case ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.POST.SUCCESS.TYPE:
      facilityAlertEmails.isFetching = false;
      facilityAlertEmails.data[action.response.alert_facility].email_list = (
        facilityAlertEmails.data[action.response.alert_facility].email_list ||
        []
      ).concat([[action.response.id, action.response.email]]);
      return Object.assign({}, state, { facilityAlertEmails });

    case ACTIONS.COMPANY.FACILITY.ALERT.USER.LIST.SUCCESS.TYPE:
      facilityAlertEmails.isFetching = false;
      if (action.response.length > 0)
        facilityAlertEmails.data[
          action.response[0].alert_facility
        ] = action.response.map(r => ({
          id: r.id,
          email: r.email,
          userCompany: r.user_company
        }));
      return Object.assign({}, state, { facilityAlertEmails });

    case ACTIONS.COMPANY.FACILITY.ALERT.USER.DELETE.SUCCESS.TYPE:
      facilityAlertEmails.isFetching = false;
      facilityAlertEmails.data[
        action.alert_facility_id
      ].user_list = facilityAlertEmails.data[
        action.alert_facility_id
      ].user_list.filter(e => e[0] !== action.id);
      return Object.assign({}, state, { facilityAlertEmails });

    case ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.DELETE.SUCCESS.TYPE:
      facilityAlertEmails.isFetching = false;
      facilityAlertEmails.data[
        action.alert_facility_id
      ].email_list = facilityAlertEmails.data[
        action.alert_facility_id
      ].email_list.filter(e => e[0] !== action.id);
      return Object.assign({}, state, { facilityAlertEmails });

    // facilityAlertEmails.isFetching = false;
    // facilityAlertEmails.data[
    //   action.response.alert_facility
    // ].email_list = setEntity(
    //   action.response.email,
    //   "id",
    //   facilityAlertEmails.data[action.response.alert_facility].email_list
    // );
    // return Object.assign({}, state, { facilityAlertEmails });

    case ACTIONS.COMPANY.USER.ALERT_FACILITY.REQUEST.TYPE:
      userFacilityAlerts.isFetching = true;
      return Object.assign({}, state, { userFacilityAlerts });
    case ACTIONS.COMPANY.USER.ALERT_FACILITY.ERROR.TYPE:
      userFacilityAlerts.isFetching = false;
      userFacilityAlerts.error = action.error;
      return Object.assign({}, state, { userFacilityAlerts });
    case ACTIONS.COMPANY.USER.ALERT_FACILITY.LIST.SUCCESS.TYPE:
      userFacilityAlerts.isFetching = false;
      userFacilityAlerts.data = action.response;
      return Object.assign({}, state, { userFacilityAlerts });
    case ACTIONS.COMPANY.FACILITY.ALERT.USER.PUT.SUCCESS.TYPE:
      userFacilityAlerts.isFetching = false;
      userFacilityAlerts.data = userFacilityAlerts.data
        .filter(ufa => ufa.id !== action.response.id)
        .concat([action.response]);
      return Object.assign({}, state, { userFacilityAlerts });
    default:
      return state;
  }
}

export const ListCompanyAlerts = () => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.ALERT.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.ALERTS.LIST, { company_id })
      .then(response => response.json())
      .then(response =>
        dispatch(ACTIONS.COMPANY.ALERT.LIST.SUCCESS.ACTION(response))
      )
      .catch(error => dispatch(ACTIONS.COMPANY.ALERT.ERROR.ACTION(error)));
  };
};

export const listFacilityAlerts = facility_id => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.ALERTS.LIST, { company_id, facility_id })
      .then(response => response.json())
      .then(response =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.LIST.SUCCESS.ACTION(response))
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.ERROR.ACTION(error))
      );
  };
};

export const setFacilityAlert = (
  facility_id,
  minutes,
  level,
  status,
  alerts,
  id,
  facilities
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.REQUEST.ACTION());
    return id
      ? putApiUrl(BACKEND.FACILITY.ALERTS.PUT, {
          company_id,
          facility_id,
          minutes,
          level,
          status,
          alerts,
          id,
          facilities
        })
      : postApiUrl(BACKEND.FACILITY.ALERTS.POST, {
          company_id,
          facility_id,
          minutes,
          level,
          status,
          alerts
        })
          .then(response => response.json())
          .then(response =>
            dispatch(
              ACTIONS.COMPANY.FACILITY.ALERT.POST.SUCCESS.ACTION(response)
            )
          )
          .catch(error =>
            dispatch(ACTIONS.COMPANY.FACILITY.ALERT.ERROR.ACTION(error))
          );
  };
};

export const listFacilityAlertEmailsAndUsers = (
  facility_id,
  alert_facility_id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.ALERTS.LIST_MAILS, {
      company_id,
      facility_id,
      alert_facility_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.LIST_MAILS.SUCCESS.ACTION(
            response,
            alert_facility_id
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.LIST_MAILS.ERROR.ACTION(error))
      );
  };
};

// export const listFacilityAlertEmails = (facility_id, alert_facility_id) => {
//   return getFacilityAlertEmailLists(facility_id, alert_facility_id, "EMAIL");
// };

export const listFacilityAlertUsers = (facility_id, alert_facility_id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.REQUEST.ACTION());
    return getApiUrl(BACKEND.FACILITY.ALERTS.USER.LIST, {
      company_id,
      facility_id,
      alert_facility_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.USER.LIST.SUCCESS.ACTION(response)
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.ERROR.ACTION(error))
      );
  };
};

export const createFacilityAlertUser = (
  facility_id,
  alert_facility_id,
  email_notification = true,
  user_company,
  email
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.ALERTS.USER.POST, {
      company_id,
      facility_id,
      alert_facility_id,
      alert_facility: alert_facility_id,
      user_company,
      email_notification
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.USER.POST.SUCCESS.ACTION(
            response,
            email
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.ERROR.ACTION(error))
      );
  };
};

export const createFacilityAlertEmail = (
  facility_id,
  alert_facility_id,
  email
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.REQUEST.ACTION());
    return postApiUrl(BACKEND.FACILITY.ALERTS.EMAIL.POST, {
      company_id,
      facility_id,
      alert_facility_id,
      email
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.POST.SUCCESS.ACTION(
            response,
            "email_list"
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.ERROR.ACTION(error))
      );
  };
};

export const removeFacilityAlertEmail = (
  facility_id,
  alert_facility_id,
  id
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.ALERTS.EMAIL.DELETE, {
      company_id,
      facility_id,
      alert_facility_id,
      id
    })
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.DELETE.SUCCESS.ACTION(
            id,
            alert_facility_id
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.EMAIL.ERROR.ACTION(error))
      );
  };
};

export const removeFacilityAlertUser = (facility_id, alert_facility_id, id) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.REQUEST.ACTION());
    return deleteApiUrl(BACKEND.FACILITY.ALERTS.USER.DELETE, {
      company_id,
      facility_id,
      alert_facility_id,
      id
    })
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.USER.DELETE.SUCCESS.ACTION(
            id,
            alert_facility_id
          )
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.ERROR.ACTION(error))
      );
  };
};

export const listUserAlertFacilities = user_id => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.USER.ALERT_FACILITY.REQUEST.ACTION());
    return getApiUrl(BACKEND.COMPANY.USERS.ALERT_FACILITY, {
      company_id,
      user_id
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.USER.ALERT_FACILITY.LIST.SUCCESS.ACTION(response)
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.USER.ALERT_FACILITY.ERROR.ACTION(error))
      );
  };
};

export const updateUserAlertFacility = (
  facility_id,
  alert_facility_id,
  id,
  email_notifications,
  push_notifications,
  user_company
) => {
  let company_id = store.getState().globalCatalog.session.company.id;
  return function(dispatch) {
    dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.REQUEST.ACTION());
    return putApiUrl(BACKEND.FACILITY.ALERTS.USER.PUT, {
      company_id,
      facility_id,
      alert_facility_id,
      id,
      email_notifications,
      push_notifications,
      alert_facility: alert_facility_id,
      user_company
    })
      .then(response => response.json())
      .then(response =>
        dispatch(
          ACTIONS.COMPANY.FACILITY.ALERT.USER.PUT.SUCCESS.ACTION(response)
        )
      )
      .catch(error =>
        dispatch(ACTIONS.COMPANY.FACILITY.ALERT.USER.ERROR.ACTION(error))
      );
  };
};
